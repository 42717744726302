import React, { useMemo } from 'react';
import {
    GivelifyModal,
    GivelifyLabel,
    GivelifyLabelStyles,
    GivelifyLoading,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { webConfig } from 'webConfig';
import { useCampusesContext } from '../../CampusesProvider';
import useStyles from './styles';

interface ConfirmModalProps {
    onOk: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ onOk }) => {
    const { body1 } = GivelifyLabelStyles({});
    const { content, link, text, modal } = useStyles();

    const {
        showConfirmModal,
        setShowConfirmModal,
        addCampusRequestState,
    } = useCampusesContext();

    const loading = addCampusRequestState.type === 'REQUEST_START';

    const onCancel = () => setShowConfirmModal(false);

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.confirmModal',
    });

    const copy = useMemo(
        () => ({
            understand: scopedTranslate('understand'),
            confirmNewCampus: scopedTranslate('confirmNewCampus'),
            description: scopedTranslate('description'),
            linkText1: scopedTranslate('linkText1'),
            linkText2: scopedTranslate('linkText2'),
        }),
        [scopedTranslate],
    );

    return (
        <GivelifyModal
            autoFullscreen
            centerButtons
            className={modal}
            onClose={() => {
                if (!loading) onCancel();
            }}
            onPrimaryClick={!loading && onOk}
            onSecondaryClick={!loading && onCancel}
            open={showConfirmModal}
            primaryButtonText={copy.understand}
            showCloseButton={!loading}
        >
            {loading ? (
                <div>
                    <GivelifyLoading type="circular" />
                </div>
            ) : (
                <div className={content}>
                    <GivelifyLabel
                        bold
                        text={copy.confirmNewCampus}
                        variant="heading2"
                    />
                    <GivelifyLabel
                        className={text}
                        text={copy.description}
                        variant="body1"
                    />
                    <div className={body1}>
                        {`${copy.linkText1} `}
                        <a
                            className={link}
                            href={webConfig.signupUrl}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {copy.linkText2}
                        </a>
                    </div>
                </div>
            )}
        </GivelifyModal>
    );
};

export default ConfirmModal;
