import React from 'react';

export interface WithDownloadableProps {
  filename: string;
  fileurl: string;
  extension?: string;
}

export default function withDownloadable<
  T extends WithDownloadableProps = WithDownloadableProps
>(WrappedComponent: React.ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithDownloadable = (props: T) => {
    const { filename, fileurl } = props;
    let { extension = 'png' } = props;

    const handleDownloadClick = async () => {
      const link = document.createElement('a');
      const blob = await fetch(fileurl).then(r => r.blob());

      // try to get a file extension from blob type
      extension = blob.type.split('/').pop() || extension;

      link.download = `${filename}.${extension}`;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    // props comes afterwards so the can override the default ones.
    return (
      <WrappedComponent
        {...props}
        onClick={async () => await handleDownloadClick()}
      />
    );
  };

  ComponentWithDownloadable.displayName = `withDownloadable(${displayName})`;

  return ComponentWithDownloadable;
}
