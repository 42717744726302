import React, { useMemo } from 'react';
import { Report } from 'store/report/types';
import MatchingDonorModal from './MatchingDonorModal';
import MatchingEnvelopeModal from './MatchingEnvelopeModal';

export type MatchItemType = 'donor' | 'envelope';
export interface ModalInfo {
    type: MatchItemType;
    reportId: number;
}

const MatchingModal = ({
    modalInfo,
    reports,
    handleClose,
}: {
    modalInfo: ModalInfo;
    reports: Report[];
    handleClose: () => void;
}) => {
    const report = useMemo(
        () => reports.find(r => r.id === modalInfo?.reportId),
        [modalInfo, reports],
    );

    const { id, startDate, endDate } = report || {};

    if (!modalInfo || !report) return null;

    if (modalInfo && modalInfo.type === 'donor')
        return (
            <MatchingDonorModal
                donorId={id}
                endDate={endDate}
                handleClose={handleClose}
                open={true}
                startDate={startDate}
            />
        );

    if (modalInfo && modalInfo.type === 'envelope')
        return (
            <MatchingEnvelopeModal
                endDate={endDate}
                handleClose={handleClose}
                open={true}
                reportId={id}
                startDate={startDate}
            />
        );
};

export default MatchingModal;
