import React, { useMemo, useRef, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    SlidingPanel,
    GivelifyListTile,
    GivelifyLabelStyles,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { Link } from 'react-router-dom';
import { ValidationError, object, string } from 'yup';
import {
    FaithLeaderEditorInfo,
    ImageWithDimensions,
    RepresentativeWithImage,
} from '../../../@types/assets/onboarding';
import { SaveCancelButtons } from '../../../components/SaveCancelButton';
import { I18N_NAMESPACE } from '../../../consts';
import { ImageButton, ImageButtonRef } from '../../../imageCropper/ImageButton';
import { CustomizeProfileFaithLeader } from '../../../imageCropper/ImageUploadButtons';
import FaithLeaderEditor from './FaithLeaderEditor';
import ListItem from './ListItem';
import useStyles from './styles';
import { FaithLeaderValidationResult } from './types';

interface FaithLeaderSectionProps {
    userId: number;
    representatives: RepresentativeWithImage[];
    faithLeader: FaithLeaderEditorInfo | undefined;
    onChangeFaithLeader: (faithLeader?: FaithLeaderEditorInfo) => unknown;
    faithLeaderImage: ImageWithDimensions | undefined;
    setFaithLeaderImage: (image?: ImageWithDimensions) => unknown;
    doNotDisplayFaithLeader: boolean | undefined;
    onDoNotDisplayFaithLeaderChange: (
        doNotDisplayFaithLeader: boolean,
    ) => unknown;
    settingsUsersPath: string;
    initialFaithLeader: FaithLeaderEditorInfo | undefined;
    initialfaithLeaderImage: ImageWithDimensions | undefined;
    submitProps?: {
        onSave: () => void;
        isSaving?: boolean;
    };
}

const DEFAULT_ITEM_COUNT = 5;

export const ValidateFaithLeader = (formData: {
    firstName: string;
    lastName: string;
    title: string;
}) => {
    const faithLeaderSchema = object({
        firstName: string().min(1).max(55),
        lastName: string().min(1).max(55),
        title: string().max(25),
    });

    try {
        faithLeaderSchema.validateSync(formData, {
            abortEarly: false,
        });

        return {
            isValid: true,
        };
    } catch (err) {
        const errors: Record<string, string> = {};
        if ((err as ValidationError).inner) {
            (err as ValidationError).inner.forEach((e: ValidationError) => {
                if (e.path) {
                    errors[e.path] = e.message;
                }
            });
        }

        return {
            isValid: false,
            errors,
        };
    }
};

const FaithLeaderSection: React.FC<FaithLeaderSectionProps> = ({
    userId,
    representatives,
    faithLeader,
    onChangeFaithLeader,
    faithLeaderImage,
    setFaithLeaderImage,
    doNotDisplayFaithLeader,
    onDoNotDisplayFaithLeaderChange,
    settingsUsersPath,
    initialFaithLeader,
    initialfaithLeaderImage,
    submitProps,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'customizeProfile.faithLeaderSection',
        namespace: I18N_NAMESPACE,
    });
    const classes = useStyles();
    const { body1 } = GivelifyLabelStyles({});

    const imageButton1Ref = useRef<ImageButtonRef>(null);
    const imageButton2Ref = useRef<ImageButtonRef>(null);
    const [viewAll, setViewAll] = useState(false);
    const [addNewFaithLeader, setAddNewFaithLeader] = useState(false);
    const [dirty, setDirty] = useState(false);

    const onDoNotDisplayFaithLeaderClick = () => {
        onChangeFaithLeader(undefined);
        setAddNewFaithLeader(false);
        imageButton2Ref.current?.clear();
        onDoNotDisplayFaithLeaderChange(true);
        if (initialFaithLeader !== undefined) {
            setDirty(true);
        } else {
            setDirty(false);
        }
    };

    const onAddNewFaithLeaderSelect = () => {
        if (addNewFaithLeader) return;

        onChangeFaithLeader(undefined);
        setAddNewFaithLeader(true);
        imageButton1Ref.current?.clear();
        onDoNotDisplayFaithLeaderChange(false);
    };

    const onSelectListItem = React.useCallback(
        (faithLeader: FaithLeaderEditorInfo | undefined) => {
            onChangeFaithLeader(faithLeader);
            setAddNewFaithLeader(false);
            imageButton2Ref.current?.clear();
            onDoNotDisplayFaithLeaderChange(false);
            setFaithLeaderImage({
                croppedUrl: faithLeader?.avatarOriginal || '',
                url: faithLeader?.avatarOriginal || '',
                dimensions: {
                    croppedArea: {
                        height: 200,
                        width: 200,
                        x: 0,
                        y: 0,
                    },
                    croppedAreaPixels: {
                        height: 200,
                        width: 200,
                        x: 0,
                        y: 0,
                    },
                },
            });
        },
        [
            imageButton2Ref,
            onChangeFaithLeader,
            setAddNewFaithLeader,
            onDoNotDisplayFaithLeaderChange,
            setFaithLeaderImage,
        ],
    );

    React.useEffect(() => {
        if (faithLeader?.userId === initialFaithLeader?.userId) {
            setFaithLeaderImage(initialfaithLeaderImage);
            imageButton1Ref.current?.reset();
            setDirty(false);
        } else {
            setDirty(true);
        }
        //eslint-disable-next-line
    }, [faithLeader, initialFaithLeader]);

    React.useEffect(() => {
        if (faithLeaderImage?.url === initialfaithLeaderImage?.url) {
            setDirty(false);
        } else {
            setDirty(true);
        }
        //eslint-disable-next-line
    }, [faithLeaderImage, initialfaithLeaderImage]);

    const onCancel = React.useCallback(() => {
        onSelectListItem(initialFaithLeader);
        setFaithLeaderImage(initialfaithLeaderImage);
        imageButton1Ref.current?.reset();
    }, [
        imageButton1Ref,
        initialfaithLeaderImage,
        initialFaithLeader,
        onSelectListItem,
        setFaithLeaderImage,
    ]);

    const {
        headingText,
        descriptionText,
        userSettings,
        sliderBottom,
        doNotDisplayFaithLeader: doNotDisplayFaithLeaderText,
        viewLess,
        viewAll: viewAllText,
    } = useMemo(
        () => ({
            headingText: scopedTranslate('heading'),
            descriptionText: scopedTranslate('description'),
            userSettings: scopedTranslate('userSettings'),
            sliderBottom: scopedTranslate('sliderBottom'),
            doNotDisplayFaithLeader: scopedTranslate('doNotDisplayFaithLeader'),
            viewLess: scopedTranslate('viewLess'),
            viewAll: scopedTranslate('viewAll'),
        }),
        [scopedTranslate],
    );

    const itemsToDisplay = useMemo(() => {
        // 1. Get list
        let items = representatives.map<FaithLeaderEditorInfo>(
            (representative) => ({
                userId: representative.officialId,
                firstName: representative.firstName,
                lastName: representative.lastName,
                title: representative.title,
                avatar: representative.avatar,
                avatarCroppedCoordinates:
                    representative.avatarCroppedCoordinates,
                avatarOriginal: representative.avatarOriginal,
            }),
        );

        // 2. Move user representative to the first position
        const userRepresentative = items.find((r) => r.userId === userId);
        if (userRepresentative) {
            items = items.filter(
                (faithLeader) => faithLeader !== userRepresentative,
            );
            items.unshift({ ...userRepresentative, isYou: true });
        }

        // 3. Remove selected representative
        items = items.filter(
            (representative) => representative.userId !== faithLeader?.userId,
        );

        // 4.Display first n items
        if (!viewAll) items = items.slice(0, DEFAULT_ITEM_COUNT);

        return items;
    }, [representatives, viewAll, faithLeader, userId]);

    const validationResult: FaithLeaderValidationResult = useMemo(
        () =>
            addNewFaithLeader
                ? ValidateFaithLeader({
                      firstName: faithLeader?.firstName || '',
                      lastName: faithLeader?.lastName || '',
                      title: faithLeader?.title || '',
                  })
                : {
                      isValid: true,
                  },
        [
            addNewFaithLeader,
            faithLeader?.firstName,
            faithLeader?.lastName,
            faithLeader?.title,
        ],
    );

    return (
        <div>
            <GivelifyLabel
                marginBottom={16}
                text={headingText}
                variant="heading3"
            />
            <div className={body1}>
                {`${descriptionText} `}
                {process.env.STORYBOOK ? (
                    <a
                        className={classes.link}
                        href={'/settings/users'}
                        title={userSettings}
                    >
                        {userSettings}
                    </a>
                ) : (
                    <Link
                        className={classes.link}
                        title={userSettings}
                        to={settingsUsersPath}
                    >
                        {userSettings}
                    </Link>
                )}
                .
            </div>
            <div className={classes.contentWrapper}>
                {!!faithLeader?.userId && (
                    <ListItem
                        selected
                        faithLeader={faithLeader}
                        onSelect={() => {
                            /* do nothing */
                        }}
                        uploadButton={
                            <ImageButton
                                ref={imageButton1Ref}
                                isRound
                                Component={CustomizeProfileFaithLeader}
                                aspectRatio={1}
                                id="upload-faith-avatar"
                                initialImage={faithLeaderImage}
                                onChange={setFaithLeaderImage}
                                title={headingText}
                            />
                        }
                    />
                )}
                <div
                    className={
                        viewAll ? classes.listWrapperAll : classes.listWrapper
                    }
                >
                    <GivelifyListTile
                        heading={doNotDisplayFaithLeaderText}
                        id="do-not-show"
                        onSelect={onDoNotDisplayFaithLeaderClick}
                        selected={!!doNotDisplayFaithLeader}
                    />
                    {itemsToDisplay.map((representative, index) => (
                        <div key={index}>
                            <ListItem
                                faithLeader={representative}
                                onSelect={() =>
                                    onSelectListItem(representative)
                                }
                                selected={representative === faithLeader}
                            />
                        </div>
                    ))}
                </div>
                {representatives.length > DEFAULT_ITEM_COUNT && (
                    <GivelifyButton
                        id="view-all-faithleader"
                        onClick={() => setViewAll(!viewAll)}
                        text={
                            viewAll
                                ? viewLess
                                : `${viewAllText} (${representatives.length})`
                        }
                        variant="link"
                    />
                )}
                <SlidingPanel
                    heading={sliderBottom}
                    onClick={onAddNewFaithLeaderSelect}
                    open={addNewFaithLeader}
                    selectId="add-new-faith-leader"
                >
                    <FaithLeaderEditor
                        addNewFaithLeader={addNewFaithLeader}
                        faithLeader={faithLeader}
                        onChangeFaithLeader={onChangeFaithLeader}
                        validationResult={validationResult}
                    />
                    <div className={classes.addPhotoButton}>
                        <ImageButton
                            ref={imageButton2Ref}
                            isRound
                            Component={CustomizeProfileFaithLeader}
                            aspectRatio={1}
                            id="faith-new-avatar"
                            initialImage={faithLeaderImage}
                            onChange={setFaithLeaderImage}
                            title={headingText}
                        />
                    </div>
                </SlidingPanel>
            </div>
            {submitProps && (dirty || (addNewFaithLeader && faithLeader)) ? (
                <SaveCancelButtons
                    onCancel={onCancel}
                    onSave={submitProps.onSave}
                    saving={submitProps.isSaving}
                    submitDisabled={!validationResult.isValid}
                />
            ) : null}
        </div>
    );
};

export default React.memo(FaithLeaderSection);
