import React from 'react';
import { Box } from '@material-ui/core';
import IntegrationsStep, { IntegrationsStepProps } from './IntegrationsStep';

export interface IntegrationStepsViewProps {
    steps: IntegrationsStepProps[];
}

const IntegrationStepsDesktop: React.FC<IntegrationStepsViewProps> = (
    props: IntegrationStepsViewProps,
) => {
    return (
        <Box display="flex" justifyContent="spaceAround">
            {props.steps.map((step, index) => (
                <IntegrationsStep
                    key={index}
                    description={step.description}
                    title={step.title}
                >
                    {step.children}
                </IntegrationsStep>
            ))}
        </Box>
    );
};

export default IntegrationStepsDesktop;
