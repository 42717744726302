import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    padding: '56px 5% 100px',
    [theme.breakpoints.down('mobile')]: {
        paddingBottom: '64px',
    },
}));
export const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(7),
    marginBottom: theme.spacing(4),
}));
export const InputBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
}));
export const InputRow = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
}));
export const CampusBox = styled('div')({
    width: '100%',
    maxWidth: 456,
});
export const ExtraInputRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginTop: theme.spacing(10),
}));
export const Footer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(10),
}));
