import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyButton,
} from '@givelify/givelify-ui';
import { useNavigate } from 'react-router-dom';
import { MenuTitleInfo } from '../dashboardMenu/types';
import { useStyle } from './style';

type Props = MenuTitleInfo;

function DashboardMenuTile(props: Props) {
    const cs = useStyle();
    const navigate = useNavigate();
    const { icon, title, description, link } = props;

    const handleLinkClick = () => {
        if (link) {
            navigate(link);
        }
    };
    return (
        <div className={cs.container} onClick={handleLinkClick}>
            <GivelifyIcon variant={icon} />
            <GivelifyButton
                className={cs.title}
                endIconVariant="chevron-right"
                name={title}
                text={title}
                variant="link"
            />
            <GivelifyLabel text={description} variant="body1" />
        </div>
    );
}

export default DashboardMenuTile;
