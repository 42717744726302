import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyErrorBanner,
    GivelifyLabel,
    GivelifyModal,
    GivelifyNotification,
} from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { EditUserInfo } from 'types/userTypes';
import { confirmationModalStyle } from './ConfirmationModalStyle';

interface DeleteUserModalProps {
    onDeleteUserModalClose: () => void;
    onDelete: () => void;
    userInfo: EditUserInfo;
    userAvatar: string;
    deleteError?: string;
    isDeleting?: boolean;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = (
    props: DeleteUserModalProps,
) => {
    const { userInfo, userAvatar } = props;

    const style = confirmationModalStyle();

    const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            deleteUser: t('pages.settings.users.delete-user'),
            deleteConfirm: t(
                'pages.settings.users.delete-modal.delete-confirm',
            ),
            deleteConfirmTip: t(
                'pages.settings.users.delete-modal.delete-confirm-tip',
            ),
            deleteConfirmDesc: t(
                'pages.settings.users.delete-modal.delete-confirm-desc',
            ),
            cancel: t('labels.cancel'),
            delete: t('pages.settings.users.delete-modal.delete'),
        }),
        [t],
    );
    return (
        <GivelifyModal
            autoFullscreen
            open
            showCloseButton
            contentClassName={style.confirmationModal}
            name="delete-user-modal"
            onClose={props.onDeleteUserModalClose}
            size="small"
        >
            <div className={style.modalContainer}>
                <GivelifyLabel
                    className={style.statement}
                    marginTop={32}
                    text={copy.deleteConfirm}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={style.desc}
                    marginTop={16}
                    text={copy.deleteConfirmDesc}
                    variant="body1"
                />
                {props.deleteError && (
                    <GivelifyErrorBanner
                        className={style.errorBanner}
                        marginTop={32}
                        text={props.deleteError}
                    />
                )}
                <div aria-label="User" className={style.usernameContainer}>
                    <div className={style.userAvatarWrapper}>
                        <GivelifyAvatar
                            color="grey"
                            size="medium"
                            src={userAvatar}
                        />
                    </div>
                    <div className={style.userNameContent}>
                        <GivelifyLabel
                            bold
                            className={style.fullName}
                            text={fullName}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={style.title}
                            text={userInfo.title}
                            variant="small"
                        />
                    </div>
                </div>

                <GivelifyNotification
                    show
                    className={style.notification}
                    text={copy.deleteConfirmTip}
                    variant="warning"
                />

                <div className={style.modalFooter}>
                    <GivelifyButton
                        className={style.cancelButton}
                        disabled={props.isDeleting}
                        marginRight={8}
                        name="cancelDeleteUser"
                        onClick={props.onDeleteUserModalClose}
                        size="xLarge"
                        text={copy.cancel}
                        variant="secondary"
                    />
                    <GivelifyButton
                        className={style.deleteButton}
                        disabled={props.isDeleting}
                        isLoading={props.isDeleting}
                        marginLeft={8}
                        name="saveDeleteUser"
                        onClick={props.onDelete}
                        size="xLarge"
                        text={copy.delete}
                        variant="primary"
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default DeleteUserModal;
