export const SET_ACCESS_TOKEN = 'ACCESS_TOKEN';

export const removeAccessTokenLocalStorage = (): void =>
    localStorage.removeItem(SET_ACCESS_TOKEN);

export const setAccessTokenLocalStorage = (accessToken: string): void =>
    localStorage.setItem(SET_ACCESS_TOKEN, JSON.stringify(accessToken));

export const getAccessTokenLocalStorage = (): string | null => {
    const token = localStorage.getItem(SET_ACCESS_TOKEN);
    if (token === null) return null;
    return JSON.parse(token);
};
