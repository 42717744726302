import React, { useContext, useMemo, useEffect } from 'react';
import {
    TrackingProvider,
    PAGE_NAME,
    useTrackingContext,
} from '@givelify/utils';
import { Box, Paper } from '@material-ui/core';
import { Envelope } from 'api/models';
import Heading from 'components/Heading';
import { BackLink } from 'components/links/BackLink';
import PageTitle from 'components/PageTitle';
import { BookTab, BookTabs } from 'components/tabs/BookTab';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { DonationNote, DonationNoteResponse } from 'types/donationTypes';
import {
    DONOR_PROFILE_PAGE_CLICK_DONATIONS_HISTORY_LINK,
    DONOR_PROFILE_PAGE_CLICK_MESSAGE_HISTORY_LINK,
} from 'utils/clevertapEvents';
import {
    DonorContextProvider,
    DonorProfileContext,
} from '../DonorProfileContext';
import { DonorDetails } from './DonorDetails';
import { DonorDonationHistory } from './DonorDonationHistory';
import DonorMemoHistory from './DonorMemoHistory';
import useStyles from './styles';

export interface DonationResponseData {
    id: number;
    isRefund: boolean;
    refundDate: string;
    status: string;
    latestStatusAt: string;
    receiptNumber: number;
    doneeName: string;
    amount: number;
    createdAt: string | Date;
    envelopes?: Envelope[];
    donationNote?: DonationNoteResponse;
    fees?: number;
    donationTotal: number;
    donationFees: number;
}

export interface Donation {
    id: number;
    receiptNumber: number;
    amount: number;
    createdAt: Date;
    envelopes?: Envelope[];
    note?: DonationNote;
    fees?: number;
    total: number;
    doneeName: string;
    isRefund: boolean;
    refundDate: string;
    status: string;
    latestStatusAt: string;
    donationTotal: number;
    donationFees: number;
}

const baseTranslation = 'pages.donor_details';

const DonorProfile: React.FC = () => {
    const { PATH } = useGasRouterContext();
    const { trackEvent } = useTrackingContext();
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { donor, doneeId, donorProfileRequestState } =
        useContext(DonorProfileContext);
    const hasLoadedDonor = donorProfileRequestState.type === 'REQUEST_SUCCESS';

    useEffect(() => {
        if (donorProfileRequestState.type === 'REQUEST_ERROR') {
            navigate(PATH.NOT_FOUND);
        }
    }, [donorProfileRequestState, navigate, PATH.NOT_FOUND]);

    const copy = useMemo(
        () => ({
            title: t(`menu.donors.title`),
            donationHistory: t(`${baseTranslation}.donation_history`),
            memoHistory: t(`${baseTranslation}.memo_history`),
        }),
        [t],
    );

    const [currentTab, setCurrentTab] = React.useState(0);

    const handleChange = (_event, newValue) => {
        if (newValue === 0) {
            trackEvent(
                `${PAGE_NAME.DonorProfile} ${DONOR_PROFILE_PAGE_CLICK_DONATIONS_HISTORY_LINK}`,
            );
        } else if (newValue === 1) {
            trackEvent(
                `${PAGE_NAME.DonorProfile} ${DONOR_PROFILE_PAGE_CLICK_MESSAGE_HISTORY_LINK}`,
            );
        }

        setCurrentTab(newValue);
    };

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.DonorProfile}>
            <PageTitle description="Donor Profile" title={donor.name} />
            <div className={classes.topWrapper}>
                <BackLink
                    className={classes.desktopHidden}
                    id="back-button-mobile"
                />
                <Heading text={copy.title} />
            </div>
            <Box
                alignItems="baseline"
                display="flex"
                justifyContent="space-between"
            >
                <BackLink
                    className={classes.mobileHidden}
                    id="back-button-desktop"
                />
                <BookTabs onChange={handleChange} value={currentTab}>
                    <BookTab label={copy.donationHistory} />
                    <BookTab label={copy.memoHistory} />
                </BookTabs>
            </Box>

            <Paper className={classes.wrapper}>
                <Box display="flex" flexWrap="wrap">
                    <DonorDetails
                        className={
                            currentTab === 1 ? classes.memoTabDonorDetails : ''
                        }
                        doneeId={doneeId}
                        donor={donor}
                        hasLoadedDonor={hasLoadedDonor}
                    />
                    {currentTab === 0 && <DonorDonationHistory />}
                    {currentTab === 1 && (
                        <DonorMemoHistory
                            doneeId={doneeId}
                            donorId={donor.id}
                        />
                    )}
                </Box>
            </Paper>
        </TrackingProvider>
    );
};

const DonorProfileWrapped = () => (
    <DonorContextProvider>
        <DonorProfile />
    </DonorContextProvider>
);

export default DonorProfileWrapped;
