import React from 'react';
import { DesignTokens } from '@givelify/ui';
import {
    BUTTON_IDENTIFIER,
    mergeClassNames,
    useTrackingContext,
} from '@givelify/utils';
import {
    styled,
    TableCell,
    Table,
    TableBody,
    TableRow,
    Skeleton,
} from '@mui/material';
import { DataTableControl, DataTableControlProps } from './DataTableControl';
import { DataTableColumn, DataTableHeader } from './DataTableHeader';
import {
    DataTablePagination,
    DataTablePaginationProps,
} from './DataTablePagination';

const TableBox = styled('div')(({ theme }) => ({
    backgroundColor: DesignTokens.color.backgroundSecondary,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    border: `1px solid ${DesignTokens.color.dividerPrimaryLight}`,
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('mobile')]: {
        borderRadius: 0,
        border: 'none',
    },
}));

const TableContainer = styled('div')(({ theme }) => ({
    maxWidth: `calc(100vw - ${theme.spacing(4)})`,
    overflowX: 'auto',
    [theme.breakpoints.down('mobile')]: {
        maxWidth: '100vw',
    },
}));

const StyledTable = styled(Table)({
    minWidth: '520px',
    overflowX: 'scroll',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    height: '96px',
    [theme.breakpoints.down('tablet')]: {
        height: '78px',
    },
}));

const StyledTableCell = styled(TableCell)(({ align, theme }) => ({
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 0,
    color: DesignTokens.color.textPrimary,
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
    paddingLeft: align === 'left' ? '12px' : 0,
    paddingRight: align === 'right' ? '12px' : 0,
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalData1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalData1.font.lineHeight}px`,
    },
}));

const NoResultWrapper = styled('div')({
    padding: '24px 0 48px',
});

const StyledSkeleton = styled(Skeleton)({
    height: '27px',
});

export type DataTableSortDirection = 'asc' | 'desc';

export type DataTableSortArrow = 'up-arrow' | 'down-arrow';

export type DataTableProps<T> = Omit<
    DataTableControlProps,
    'className' | 'loading' | 'sortValue' | 'onSortChange'
> & {
    rows?: T[];
    columns?: DataTableColumn<T>[];
    sortColumn?: keyof T;
    sortColumnDirection?: DataTableSortDirection;
    onSortClick?: (field: keyof T, direction: DataTableSortDirection) => void;
    perPage?: number;
    loading?: boolean;
    pagination: DataTablePaginationProps;
    className?: string;
    showNoResults?: boolean;
    columnDirectionArrow: DataTableSortArrow;
};

export const DataTable = <T,>({
    rows,
    columns,
    sortColumn,
    sortColumnDirection,
    onSortClick,
    NoResultComponent,
    loading,
    perPage = 10,
    pagination,
    className,
    columnDirectionArrow,
    showNoResults,
    ...controlProps
}: DataTableProps<T>): JSX.Element => {
    const { trackEvent } = useTrackingContext();
    const rowsToRender = React.useMemo(() => {
        if (loading) {
            return Array.from(Array(perPage).keys());
        } else {
            return rows;
        }
    }, [loading, perPage, rows]);
    const sortValue = React.useMemo(
        () => `${sortColumn.toString()}-${sortColumnDirection}`,
        [sortColumn, sortColumnDirection],
    );
    const sortDropdownCallback = React.useCallback(
        (sort: string) => {
            const sortSplit = sort.split('-');
            onSortClick(
                sortSplit[0] as keyof T,
                sortSplit[1] as DataTableSortDirection,
            );
        },
        [onSortClick],
    );
    const sortHeaderCallback = React.useCallback(
        (field: keyof T) => {
            const direction =
                field === sortColumn
                    ? sortColumnDirection === 'asc'
                        ? 'desc'
                        : 'asc'
                    : 'asc';
            onSortClick(field, direction);
            const selectedSortOption = controlProps.sortOptions.find(
                (option) => option.id === `${field as string}-${direction}`,
            );
            trackEvent(`<Sort table header>_${BUTTON_IDENTIFIER}`, {
                sortOption: controlProps.setRenderValueText(selectedSortOption),
            });
        },
        [
            sortColumn,
            sortColumnDirection,
            onSortClick,
            controlProps,
            trackEvent,
        ],
    );
    return (
        <TableBox className={className}>
            <DataTableControl
                {...controlProps}
                className="data-table-control"
                loading={loading}
                onSortChange={sortDropdownCallback}
                sortValue={sortValue}
            />
            {showNoResults ? (
                <NoResultWrapper>{NoResultComponent}</NoResultWrapper>
            ) : (
                <TableContainer>
                    <StyledTable className="data-table-root">
                        <DataTableHeader
                            className="data-table-header"
                            columnDirectionArrow={columnDirectionArrow}
                            columns={columns}
                            loading={loading}
                            onSortClick={sortHeaderCallback}
                            sortColumn={sortColumn}
                        />
                        <TableBody className="data-table-body">
                            {rowsToRender.map((item, index) => (
                                <StyledTableRow
                                    key={`rw-${index}`}
                                    className="data-table-row"
                                >
                                    {columns.map((col, colIndex) => (
                                        <StyledTableCell
                                            key={`${col.field.toString()}-${index}`}
                                            align={col.align}
                                            className={mergeClassNames(
                                                'td-cell',
                                                `td-cell-${col.field.toString()}`,
                                            )}
                                            data-testid={`val-${col.field.toString()}-${index}`}
                                        >
                                            <span
                                                data-testid={`table-row-${index}-cell-${colIndex}`}
                                                id={`table-row-${index}-cell-${colIndex}`}
                                            >
                                                {col.onRender ? (
                                                    col.onRender(item, loading)
                                                ) : (
                                                    <span data-testid="cell-text">
                                                        {loading ? (
                                                            <StyledSkeleton
                                                                animation="wave"
                                                                className={mergeClassNames(
                                                                    'td-cell-skel',
                                                                    `td-cell-skel-${col.field.toString()}`,
                                                                )}
                                                            />
                                                        ) : col.format ? (
                                                            col.format(item)
                                                        ) : item[col.field] ? (
                                                            item[
                                                                col.field
                                                            ].toString()
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                )}
                                            </span>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            )}
            {pagination.resultCount > 15 ? (
                <DataTablePagination {...pagination} />
            ) : null}
        </TableBox>
    );
};
