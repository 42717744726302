// import { makeStyles, Theme } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles<Theme>(theme => ({
    dialog: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        width: '100%',
        margin: '0',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6, 6, 0),
            gridTemplateColumns: '1fr 1fr',
            position: 'absolute',
            left: `calc(260px + ${theme.spacing(5)}px)`,
            width: '70%',
            maxWidth: '1000px',
        },
    },
    closeDialog: {
        position: 'sticky',
        zIndex: 1,
        top: '0',
        right: '0',
        alignSelf: 'start',
        justifySelf: 'end',
        [theme.breakpoints.up('md')]: {
            gridArea: '1 / 2 / 2 / 3',
            position: 'absolute',
            top: `-${theme.spacing(5)}px`,
            right: `-${theme.spacing(5)}px`,
        },
    },
    ccbWrapper: {
        gridColumnStart: 1,
        gridColumnEnd: 3,
        paddingBottom: theme.spacing(6),
    },
}));

export const useF1Styles = makeStyles((theme: Theme) => {
    return {
        f1Dialog: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 952,
            width: '100%',
            margin: '0',
            padding: theme.spacing(4),
            textAlign: 'center',
        },
        f1CloseDialog: {
            position: 'sticky',
            zIndex: 1,
            top: '0',
            right: '0',
            alignSelf: 'start',
            justifySelf: 'end',
            [theme.breakpoints.up('md')]: {
                gridArea: '1 / 2 / 2 / 3',
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
            },
        },
        f1Title: {
            textAlign: 'center',
        },
        f1Content: {
            padding: theme.spacing(4, 7, 0),
            marginBottom: theme.spacing(5),
        },
        f1Continue: {
            marginBottom: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
        },
        f1LoginContent: {},
        connect: {
            width: '55%',
            margin: '0 auto 48px',
        },
        loginForm: {
            '& label': {
                zIndex: '10',
                '& + div': {
                    margin: 0,
                },
            },
        },
        findKey: {
            textAlign: 'left',
            paddingLeft: theme.spacing(7),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
            },
        },
        findKeyTitle: {
            marginBottom: theme.spacing(5),
        },
        findKeyStep: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(5),
        },
        stepNum: {
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.primary.main,
            color: theme.palette.background.paper,
            borderRadius: '50%',
            marginRight: theme.spacing(4),
            fontSize: 16,
            fontWeight: 700,
        },
        notification: {
            marginBottom: theme.spacing(3),
        },
    };
});
