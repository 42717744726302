import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const MenuSeparator = styled('div')(({ theme }) => ({
    width: '100%',
    height: '1px',
    background: DesignTokens.color.dividerPrimaryLight,
    marginTop: '8px',
    [theme.breakpoints.down('mobile')]: {
        margin: '8px auto 0',
        width: 'calc(100% - 32px)',
    },
}));
