import { makeStyles } from '@material-ui/core';

export const invitationModalsStyles = makeStyles(() => ({
    modalContent: {
        padding: '8px 32px 32px 32px',
    },
    header: {
        marginBottom: 8,
    },
    description: {
        marginBottom: 24,
    },
}));
