import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const ChartHeaderWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('smallTablet')]: {
        alignItems: 'flex-start',
    },
}));
export const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: theme.spacing(2),
    [theme.breakpoints.down('smallTablet')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: 0,
    },
    [theme.breakpoints.down('mobile')]: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        gap: theme.spacing(1),
    },
}));
export const TotalAmountWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
});
export const TotalAmount = styled(GivelifyLabel)(({ theme }) => ({
    color: DesignTokens.color.textPrimary,
    fontSize: '42px',
    lineHeight: '64px',
    fontWeight: 800,
    [theme.breakpoints.down('smallTablet')]: {
        fontSize: '36px',
        lineHeight: '48px',
        fontWeight: 600,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: '32px',
        lineHeight: '40px',
    },
})) as typeof GivelifyLabel;
export const DollarSign = styled('span')(({ theme }) => ({
    fontSize: '21px',
    lineHeight: '43px',
    fontWeight: 800,
    [theme.breakpoints.down('smallTablet')]: {
        fontSize: '36px',
        lineHeight: '48px',
        fontWeight: 600,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: '15px',
        lineHeight: '31px',
    },
}));
export const InfoWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('mobile')]: {
        padding: 0,
    },
}));
export const TotalText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        lineHeight: '18.5px',
        fontWeight: 800,
    },
}));
export const DatesText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        lineHeight: '18px',
    },
}));
export const AvgDonations = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 1.5),
    gap: theme.spacing(0.5),
    borderRadius: DesignTokens.measurement.borderRadiusS,
    backgroundColor: DesignTokens.color.backgroundPrimary,
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
}));
