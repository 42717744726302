import { ThunkDispatch } from 'redux-thunk';

import { AppState, AppActions } from '../..';
import { getStatisticsThunk } from '../thunk';
import {
    setLifeTimeFailed,
    setLifeTimeSuccess,
    setLifeTimeLoading,
} from './actions';

export const lifeTimeTotalEndPoint = (doneeId: number) =>
    `/donees/${doneeId}/donations/statistics?sum`;

export const getLifeTimeTotal = (
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setLifeTimeLoading,
        success: setLifeTimeSuccess,
        error: setLifeTimeFailed,
        url: lifeTimeTotalEndPoint(doneeId),
    });
