import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    inline: {
        display: 'inline-block',
    },
    upDownPercentage: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 16,
        },
        marginLeft: 32,
    },
}));
