import { DesignTokens } from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const QrBlockWrapper = styled('div')({
    borderRadius: DesignTokens.measurement.borderRadiusS,
    backgroundColor: DesignTokens.color.backgroundComponentHighlight,
    padding: '16px 24px',
    marginTop: '20px',
});

export const QrListWrapper = styled(Grid)(({ theme }) => ({
    marginTop: '16px',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
    },
}));

export const Steps = styled(Grid)(({ theme }) => ({
    paddingLeft: '16px',
    [theme.breakpoints.down('mobile')]: {
        paddingLeft: '0',
    },
}));

export const StepNumber = styled('div')({
    fontSize: '12px',
    lineHeight: '18px',
    backgroundColor: DesignTokens.color.globalGuidingViolet400,
    color: DesignTokens.color.textWhite,
    flex: '0 0 20px',
    width: '20px',
    height: '20px',
    margin: '2px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
