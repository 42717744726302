import React from 'react';
import { GivelifyNotificationProps, GivelifyTabOption } from '@givelify/ui';
import {
    isFailed,
    PAGE_NAME,
    responseOrUndefined,
    TimeFrameValue,
    TimeFrameValues,
    TrackingProvider,
    useApiRequest,
    useTrackingContext,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import {
    FilterProvider,
    usePaginationFilter,
    useTimeframeFilter,
} from 'api/hooks';
import {
    GetDonorDonationsStatisticResponse,
    GetDonorProfileResponse,
} from 'api/services/responses';
import { getDonorsService } from 'api/utils/serviceProvider';
import NotFound from 'pages/NotFound';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { decryptString } from 'utils/hashUtils';
import { getRangeDateType } from 'utils/timeFrameUtils';
import {
    DONOR_PROFILE_PAGE_CLICK_DONATIONS_HISTORY_LINK,
    DONOR_PROFILE_PAGE_CLICK_MESSAGE_HISTORY_LINK,
} from '../../utils/clevertapEvents';
import { DonorProfileContext } from './DonorProfileContext';
import { DonorProfileView } from './DonorProfileView';

const DonorProfilePage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [previousLocation] = React.useState(location.state);
    const { trackEvent } = useTrackingContext();
    const { PATH } = useGasRouterContext();
    const { encryptedDonorId } = useParams<{ encryptedDonorId: string }>();
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const [timeFrame, setTimeFrame] = useTimeframeFilter(
        TimeFrameValues['lifetime'],
    );
    const setPaginationFilter = usePaginationFilter({
        page: 1,
    })[1];
    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const [notification, setNotification] =
        React.useState<GivelifyNotificationProps>(null);

    const [getDonorProfileRequest, makeGetDonorProfileRequest] =
        useApiRequest<GetDonorProfileResponse>();
    const [getDonationDataRequest, makeGetDonationDataRequest] =
        useApiRequest<GetDonorDonationsStatisticResponse>();

    const donorId = decryptString(encryptedDonorId);
    const donorService = getDonorsService();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const rangeDateType = getRangeDateType(timeFrame, isMobile);
    const donorProfile = responseOrUndefined(getDonorProfileRequest)?.data;

    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            donationHistoryTitle: t('pages.donor_details.donation_history'),
            messageHistoryTitle: t('pages.donor_details.memo_history'),
        }),
        [t],
    );
    const goBack = React.useCallback(() => {
        if (previousLocation) {
            navigate(`${previousLocation.pathname}${previousLocation.search}`);
        } else {
            navigate(PATH.DONORS.ROOT(''));
        }
    }, [PATH.DONORS, navigate, previousLocation]);
    const tabsOptions: GivelifyTabOption[] = React.useMemo(
        () => [
            {
                tabHref: PATH.DONORS.PROFILE_DONATION_HISTORY({
                    donorId: encryptedDonorId,
                    params: {
                        timeFrame: timeFrame.selector,
                        startDate: timeFrame.start.format('YYYY-MM-DD'),
                        endDate: timeFrame.end.format('YYYY-MM-DD'),
                    },
                }),
                label: copy.donationHistoryTitle,
                id: 'donation-history-tab',
            },
            {
                tabHref: PATH.DONORS.PROFILE_MESSAGE_HISTORY({
                    donorId: encryptedDonorId,
                    params: {
                        timeFrame: timeFrame.selector,
                        startDate: timeFrame.start.format('YYYY-MM-DD'),
                        endDate: timeFrame.end.format('YYYY-MM-DD'),
                    },
                }),
                label: copy.messageHistoryTitle,
                id: 'message-history-tab',
            },
        ],
        [PATH.DONORS, encryptedDonorId, copy, timeFrame],
    );
    const onTimeFrameChange = React.useCallback(
        (timeFrame: TimeFrameValue) => {
            setTimeFrame(timeFrame);
            setPaginationFilter({ page: 1 });
        },
        [setPaginationFilter, setTimeFrame],
    );
    const handleTabChange = React.useCallback(
        (value: number) => {
            trackEvent(
                value === 0
                    ? DONOR_PROFILE_PAGE_CLICK_DONATIONS_HISTORY_LINK
                    : DONOR_PROFILE_PAGE_CLICK_MESSAGE_HISTORY_LINK,
            );
            setCurrentTab(value);
            navigate(tabsOptions[value].tabHref);
        },
        [navigate, tabsOptions, trackEvent],
    );

    React.useEffect(() => {
        if (donorId) {
            void makeGetDonorProfileRequest(
                donorService.getDonorProfile(doneeId, donorId),
            );
        }
    }, [doneeId, donorId, donorService, makeGetDonorProfileRequest]);
    React.useEffect(() => {
        if (
            isFailed(getDonorProfileRequest) &&
            getDonorProfileRequest.error?.status === 404
        ) {
            navigate(PATH.DONORS.ROOT(''));
        }
    }, [PATH.DONORS, getDonorProfileRequest, navigate]);
    React.useEffect(() => {
        if (donorId) {
            void makeGetDonationDataRequest(
                donorService.getDonorDonationsStatistic(
                    doneeId,
                    donorId,
                    timeFrame.start,
                    timeFrame.end,
                    rangeDateType,
                ),
            );
        }
    }, [
        doneeId,
        donorId,
        donorService,
        makeGetDonationDataRequest,
        rangeDateType,
        timeFrame.end,
        timeFrame.start,
    ]);
    React.useEffect(() => {
        if (
            location.pathname.startsWith(
                PATH.DONORS.PROFILE_DONATION_HISTORY({
                    donorId: encryptedDonorId,
                }),
            )
        ) {
            setCurrentTab(0);
        } else if (
            location.pathname.startsWith(
                PATH.DONORS.PROFILE_MESSAGE_HISTORY({
                    donorId: encryptedDonorId,
                }),
            )
        ) {
            setCurrentTab(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!donorId || isFailed(getDonorProfileRequest)) {
        return <NotFound />;
    }
    return (
        <DonorProfileContext.Provider
            value={{
                doneeId,
                donor: donorProfile,
                donorId,
                setNotification,
                timeFrame,
            }}
        >
            <DonorProfileView
                currentTab={currentTab}
                donationsRequest={getDonationDataRequest}
                donorCardProps={{
                    address: donorProfile
                        ? `${donorProfile.address}. ${donorProfile.city}, ${donorProfile.state}, ${donorProfile.zip}`
                        : '',
                    email: donorProfile ? donorProfile.email : '',
                    givingStyle: donorProfile
                        ? donorProfile.donationStatus
                        : '',
                    avatar: donorProfile ? donorProfile.picture : '',
                    name: donorProfile ? donorProfile.name : '',
                    phone: donorProfile ? donorProfile.phone : '',
                    country: donorProfile ? donorProfile.country : '',
                    loading: getDonorProfileRequest.type !== 'REQUEST_SUCCESS',
                }}
                handleTabChange={handleTabChange}
                notification={notification}
                onBackClick={goBack}
                rangeDateType={rangeDateType}
                setNotification={setNotification}
                setTimeFrame={onTimeFrameChange}
                tabsOptions={tabsOptions}
            />
        </DonorProfileContext.Provider>
    );
};

export const DonorProfile: React.FC = () => {
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.DonorProfile}>
            <FilterProvider>
                <DonorProfilePage />
            </FilterProvider>
        </TrackingProvider>
    );
};
