import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    campusItemWrapper: {
        display: 'grid',
        gap: '5px',
    },
    iconWrapper: {
        minWidth: 'auto',
        marginRight: 20,
        width: 18,
    },
    icon: { width: 18 },
    menuItem: {
        padding: '11px 20px',
    },
    paper: { maxHeight: 300, maxWidth: 300 },
    rootTextWrapper: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 150,
    },
    campuseNameWrapper: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        wordBreak: 'break-word',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
    },
    campusAddressWrapper: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    filterHeader: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
}));
