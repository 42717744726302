export const SETTINGS_ASSETS_PATH = {
    USER_ICON: '/settingsIcons/user.svg',
    USERS_ICON: '/settingsIcons/users.svg',
    PIGGY_BANK: '/settingsIcons/piggy-bank.svg',
    BANK_INFO: '/settingsIcons/bank-info.svg',
    ORGANIZATION_INFO: '/settingsIcons/organization.svg',
    ENVELOPES: '/settingsIcons/envelope.svg',
    LOCATIONS: '/settingsIcons/locations.svg',
    MISSION_STATEMENT: '/settingsIcons/mission.svg',
    GIVE_HAND: '/settingsIcons/quick-give.svg',
    QUESTION_MARK: '/settingsIcons/question-mark.svg',
    BANK_CHECK: {
        MAIN: '/bankCheck/bank-check.png',
        OUTLINE_1: '/bankCheck/bank-check-outline-1.svg',
        OUTLINE_1_ARROW: '/bankCheck/bank-check-outline-1-arrow.svg',
        OUTLINE_DOT: '/bankCheck/bank-check-outline-dot.svg',
        OUTLINE_2: '/bankCheck/bank-check-outline-2.svg',
        OUTLINE_2_ARROW: '/bankCheck/bank-check-outline-2-arrow.svg',
    },
    UPLOAD_ICON: '/commonIcons/upload-icon.svg',
    EDIT_ICON: '/commonIcons/edit-icon.svg',
    FACEBOOK_ICON: '/socialIcons/fb.svg',
    INSTAGRAM_ICON: '/socialIcons/insta.svg',
    TWITTER_ICON: '/socialIcons/twitter.svg',
    WEB_ICON: '/socialIcons/web.svg',
};
