import React, { useCallback, useMemo, useState } from 'react';
import {
    GivelifyLabel,
    GivelifyLink,
    GivelifyForm,
    GivelifyNotification,
} from '@givelify/ui';
import { Stack, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    newCCBIntegration,
    newF1GoIntegration,
} from 'store/automatedIntegration/thunks';
import { handleErrorMessages } from 'store/thunks';
import { useAdvancedTranslation } from 'utils/i18n';
import * as Yup from 'yup';
import { getCcbUrl } from '../../../IntegrationContent/TabsContent/AutoIntegrationContent/types';
import { IntegrationTypes } from '../../../types';
import { CbbF1GoAuthForm } from './CbbF1GoAuthForm';

type Screen2Props = {
    //eslint-disable-next-line
    t: (key: any, d?: any) => string;
    type: IntegrationTypes;
    doneCallBack: () => unknown;
};

export type SetupCCBFormData = {
    url: string;
    username: string;
    password: string;
};

export const Screen2: React.FC<Screen2Props> = ({ type, doneCallBack }) => {
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const translationPrefix = type.integrationType.toLowerCase();
    const { t } = useAdvancedTranslation();
    const TRANSLATION_KEY = 'pages.integrations.content.setup-dialog';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${translationPrefix}.${key}`),
        [t, translationPrefix],
    );
    const dispatch = useDispatch();
    const text = useMemo(
        () => ({
            heading: t(
                'pages.integrations.content.setup-dialog.title.heading',
                {
                    type: type && (type.integrationSystem || type.name),
                },
            ),
            line: scopedTranslate('screen2.line'),
            clickHere: scopedTranslate('screen2.clickHere'),
            line1: scopedTranslate('screen2.line1'),
            line2: scopedTranslate('screen2.line2'),
            url: t('pages.integrations.settings.ccb.url'),
            username: t('pages.integrations.settings.ccb.username'),
            password: t('pages.integrations.settings.ccb.password'),
            urlRequired: scopedTranslate('screen2.urlRequired'),
            usernameRequired: scopedTranslate('screen2.usernameRequired'),
            passwordRequired: scopedTranslate('screen2.passwordRequired'),
            submit: t('labels.submit'),
        }),
        [t, type, scopedTranslate],
    );

    const schema = Yup.object<Record<keyof SetupCCBFormData, Yup.AnySchema>>({
        url: Yup.string().required(text.urlRequired),
        username: Yup.string().required(text.usernameRequired),
        password: Yup.string().required(text.passwordRequired),
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const handleSubmit = useCallback(
        async (value: SetupCCBFormData) => {
            try {
                setLoading(true);
                setErrorMessage('');
                if (type.integrationType === 'F1GO') {
                    await newF1GoIntegration(value, doneeId)(dispatch);
                } else {
                    await newCCBIntegration(value, doneeId)(dispatch);
                }
                if (doneCallBack) {
                    doneCallBack();
                }
            } catch (e) {
                const translatedError = handleErrorMessages(e, t);
                setErrorMessage(translatedError.message);
                setLoading(false);
            }
        },
        [type.integrationType, doneCallBack, doneeId, dispatch, t],
    );

    return (
        <Stack alignItems="center">
            <GivelifyLabel
                marginBottom={2}
                text={text.heading}
                textAlign="center"
                variant={isMobile ? 'heading1S' : 'heading1M'}
            />
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Stack alignItems="center">
                        <GivelifyLabel
                            maxWidth={600}
                            textAlign="center"
                            variant="body1"
                        >
                            {text.line1}
                            <GivelifyLink
                                href={getCcbUrl}
                                rel="noopener"
                                target="_blank"
                                text={text.clickHere}
                                variant="body1"
                            />
                            {text.line2}
                        </GivelifyLabel>
                    </Stack>
                </Grid>
                <Grid item md={6} paddingBottom={6} paddingTop={6} xs={11}>
                    <Stack gap={4}>
                        <GivelifyNotification
                            open={!!errorMessage}
                            text={errorMessage}
                            variant="error"
                        />
                        <GivelifyForm<SetupCCBFormData>
                            onSubmit={handleSubmit}
                            schema={schema}
                        >
                            <CbbF1GoAuthForm loading={loading} />
                        </GivelifyForm>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
};
