import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    overflowX: 'hidden',
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
        borderRadius: 0,
    },
}));

export const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(3),
}));

export const LockRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
}));

export const Divider = styled('div')(({ theme }) => ({
    boxShadow: 'inset 0px -1px 0px #C2C0CD',
    width: '100%',
    height: 1,
    order: 2,
    marginTop: theme.spacing(3),
}));
