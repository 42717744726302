import React from 'react';
import { GivelifyModal } from '@givelify/givelify-ui';
import YodleeErrorContent from './YodleeErrorContent';
import YodleeOpen from './YodleeOpen';
interface YodleeLoginModalProps {
    doneeId: number;
    providerId: number;
    accessToken: string | undefined;
    onClose: () => unknown;
    onManualEntry: () => unknown;
    onRefresh: () => unknown;
    onSubmit: (accountNumber: number, routingNumber: number) => unknown;
    onError: (errorKey: string) => unknown;
}

const YodleeLoginModal: React.FC<YodleeLoginModalProps> = ({
    doneeId,
    providerId,
    accessToken,
    onClose,
    onSubmit,
    onError,
    onManualEntry,
    onRefresh,
}) => {
    return (
        <GivelifyModal
            autoFullscreen
            disableBackdropClick
            showCloseButton
            onClose={onClose}
            open={true}
        >
            {window.fastlink !== undefined && accessToken ? (
                <YodleeOpen
                    accessToken={accessToken}
                    doneeId={doneeId}
                    onClose={onClose}
                    onError={onError}
                    onSubmit={onSubmit}
                    providerId={providerId}
                />
            ) : (
                <YodleeErrorContent
                    onManualEntry={onManualEntry}
                    onRefresh={onRefresh}
                />
            )}
        </GivelifyModal>
    );
};

export default React.memo(YodleeLoginModal);
