import { TimeFrameValue } from '@givelify/utils';
import { Dayjs } from 'dayjs';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from '../../..';
import { httpGet } from '../../../thunks';
import { legacyEnvelopesEndPoint } from '../thunks';
import {
    setGivingTrendEnvelopesStatisticsFailedAtIndex,
    setGivingTrendEnvelopesStatisticsLoadingAtIndex,
    setGivingTrendEnvelopesStatisticsSuccessAtIndex,
    setInitialGivingTrendState,
} from './actions';

export interface GivingTrendStatisticsThunkProps {
    timeframes: [Dayjs, Dayjs][];
    envelopeIds: number[];
    doneeId: number;
}

export interface GivingTrendStatisticThunkProps {
    loading: () => AppActions;
    //eslint-disable-next-line
    success: (data: object) => AppActions;
    //eslint-disable-next-line
    error: (error: object) => AppActions;
    timeFrame: TimeFrameValue;
    envelopeIds: number[];
    doneeId: number;
}

export const getGivingTrendEnvelopeStatisticThunk =
    (
        props: GivingTrendStatisticThunkProps,
    ): ThunkDispatch<AppState, undefined, AppActions> =>
    async (dispatch) => {
        dispatch(props.loading);

        try {
            const url = legacyEnvelopesEndPoint(
                props.timeFrame,
                props.doneeId,
                props.envelopeIds,
            );
            const response = await httpGet(url)(dispatch);
            dispatch(props.success(response.data));
        } catch (e) {
            dispatch(props.error(e));
        }
    };

export const getGivingTrendEnvelopesStatisticsThunk =
    (
        props: GivingTrendStatisticsThunkProps,
    ): ThunkDispatch<AppState, undefined, AppActions> =>
    async (dispatch) => {
        // set up intial state
        dispatch(setInitialGivingTrendState(props.timeframes.length));

        props.timeframes.map((element, index) =>
            getGivingTrendEnvelopeStatisticThunk({
                loading: setGivingTrendEnvelopesStatisticsLoadingAtIndex(index),
                success: setGivingTrendEnvelopesStatisticsSuccessAtIndex(index),
                error: setGivingTrendEnvelopesStatisticsFailedAtIndex(index),
                timeFrame: {
                    selector: 'custom',
                    start: element[0],
                    end: element[1],
                },
                envelopeIds: props.envelopeIds,
                doneeId: props.doneeId,
            })(dispatch),
        );
    };
