import React, { useMemo } from 'react';
import {
    GivelifyEmailField,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { invitationModalsStyles } from './invitationModalsStyles';

interface SendNewInvitationModalProps {
    open: boolean;
    onClose: () => void;
    email: string;
    onSend: () => void;
    handleEmailChange: (email: string) => void;
    emailError: boolean;
}

const SendNewInvitationModal: React.FC<SendNewInvitationModalProps> = props => {
    const {
        onClose,
        open,
        email,
        onSend,
        handleEmailChange,
        emailError,
    } = props;
    const styles = invitationModalsStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            sendNewInvitation: t('pages.settings.users.sendNewInvitation'),
            willEmailUser: t('pages.settings.users.willEmailUser'),
            cancel: t('labels.cancel'),
            send: t('labels.send'),
            email: t('pages.settings.users.add-new-modal.form.email'),
        }),
        [t],
    );
    return (
        <GivelifyModal
            showCloseButton
            onClose={onClose}
            onPrimaryClick={onSend}
            onSecondaryClick={onClose}
            open={open}
            primaryButtonText={copy.send}
            secondaryButtonText={copy.cancel}
        >
            <div className={styles.modalContent}>
                <GivelifyLabel
                    className={styles.header}
                    text={copy.sendNewInvitation}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={styles.description}
                    color="neutralGrey"
                    text={copy.willEmailUser}
                    variant="body1"
                />
                <GivelifyEmailField
                    boxSize="large"
                    id="email"
                    label={copy.email}
                    name="email"
                    onChange={(event, value) => {
                        handleEmailChange(value);
                    }}
                    onEnterSubmit={handleEmailChange}
                    placeholder={copy.email}
                    state={emailError ? 'error' : 'normal'}
                    value={email || ''}
                />
            </div>
        </GivelifyModal>
    );
};

export default SendNewInvitationModal;
