import React, { useMemo } from 'react';
import { GivelifyTab } from '@givelify/givelify-ui';
import { PAGE_NAME } from '@givelify/utils';
import { useTabFilter } from 'api/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGasRouterContext } from 'router/GasRouterProvider';
import RoleTypes from '../../../constants/roleTypes';
import { AppState } from '../../../store';
import SettingsPage from '../SettingsPage';
import { CustomizeAccountTab } from './CustomizeAccountTab';
import { PrimaryRepresentativeTabV1 } from './PrimaryRepresentativeTabV1';

const TAB_PATH = {
    account: 'account',
    rep: 'rep',
} as const;

const BankInfoV1: React.FC = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useTabFilter([TAB_PATH.account, TAB_PATH.rep], {
        tab: TAB_PATH.account,
    });
    const copy = useMemo(
        () => ({
            description: t('pages.settings.bank-info.caption'),
            accountTab: t(
                'pages.settings.bank-info.customize-account-tab.title',
            ),
            repTab: t('pages.settings.bank-info.customize-rep-tab.title'),
        }),
        [t],
    );
    const { user, donee, campuses } = useSelector((state: AppState) => ({
        user: state.User.user,
        donee: state.Donee.donee,
        campuses: state.Donee.campuses,
    }));

    const { PATH } = useGasRouterContext();
    const isAdmin = user.role === RoleTypes.ADMIN;
    const isHQ = donee.id === campuses[0]?.id && !donee.parentCampus;
    const showPrimaryRepTab = isAdmin && isHQ;
    const handleChange = (newValue: number) => {
        setTab({ tab: newValue === 1 ? TAB_PATH.rep : TAB_PATH.account });
    };
    return (
        <SettingsPage
            safeAndSecure
            description={copy.description}
            pageName={PAGE_NAME.SettingsBankInfo}
            title={tab.tab === TAB_PATH.account ? copy.accountTab : copy.repTab}
            trackPageVisit={false}
        >
            <GivelifyTab
                marginTop={30}
                onChange={handleChange}
                options={[
                    {
                        label: copy.accountTab,
                        tabHref: PATH.SETTINGS.BANK_INFO(TAB_PATH.account),
                    },
                    ...(showPrimaryRepTab
                        ? [
                              {
                                  label: copy.repTab,
                                  tabHref: PATH.SETTINGS.BANK_INFO(
                                      TAB_PATH.rep,
                                  ),
                              },
                          ]
                        : []),
                ]}
                value={tab.tab === TAB_PATH.account ? 0 : 1}
            />
            {tab.tab === TAB_PATH.account && <CustomizeAccountTab />}
            {tab.tab === TAB_PATH.rep && showPrimaryRepTab ? (
                <PrimaryRepresentativeTabV1 />
            ) : null}
        </SettingsPage>
    );
};

export default BankInfoV1;
