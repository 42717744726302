import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    link: {
        display: 'inline-block',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            '& > *': {
                textDecoration: 'underline',
            },
        },
    },
}));
