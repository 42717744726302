import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const AccountDetailStyles = makeStyles((theme: Theme) => {
    return createStyles({
        yodleeContainer: {
            marginTop: 40,
        },
        accountSuccess: {
            '& .header': {
                marginTop: 40,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
        },
        accountPending: {
            '& .header': {
                marginTop: 40,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
        },
        accountRetry: {
            '& .header': {
                marginTop: 40,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
            '& .info': {
                fontSize: 16,
                lineHeight: '22px',
                fontWeight: 400,
                fontFamily: 'Roboto',
                '& .button': {
                    cursor: 'pointer',
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                    '&:hover': {
                        color:
                            theme.colors?.primary || theme.palette.primary.main,
                        textDecoration: 'underline',
                    },
                    '&:active': {
                        color:
                            theme.colors?.accentDarkBlue ||
                            theme.palette.primary.dark,
                        textDecoration: 'underline',
                    },
                },
            },
        },
        accountWarning: {
            '& .header': {
                marginTop: 40,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
        },
    });
});
