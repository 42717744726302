import { IntegrationPaginatedResponse } from '@givelify/givelify-ui';
import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { TRANSACTION_FILTER_VALUE } from 'components/filters/transactionFilter';
import { getDonationQueryString } from 'store/donations/donation/types';
import { DonationsStatus } from './BankDepositRow';

export const getDataForInfiniteLoader = async (
    doneeId: number,
    pageNumber: number,
    timeFrame: TimeFrameValue,
    transactionFilter: TRANSACTION_FILTER_VALUE,
    onlyDate?: boolean,
): Promise<ApiResponse<IntegrationPaginatedResponse<DonationsStatus>>> => {
    const requests: Promise<
        ApiResponse<
            IntegrationPaginatedResponse<DonationsStatus> | DonationsStatus[]
        >
    >[] = [];
    if (
        transactionFilter === TRANSACTION_FILTER_VALUE.ALL &&
        pageNumber === 1
    ) {
        const pendingQueryString = getDonationQueryString({
            timeFrame,
            transactionFilter: TRANSACTION_FILTER_VALUE.PENDING,
            onlyDate,
        });
        const pendingUrl = `/donees/${doneeId}/deposits${pendingQueryString}`;

        const pendingHttpRequest = getAxiosClient().get(pendingUrl);
        const pendingHttpResponse =
            makeApiRequest<IntegrationPaginatedResponse<DonationsStatus>>(
                pendingHttpRequest,
            );

        requests.push(pendingHttpResponse);
    }

    const donationsQueryString = getDonationQueryString({
        timeFrame,
        transactionFilter,
        page: pageNumber,
        onlyDate,
    });
    const donationsUrl = `/donees/${doneeId}/deposits${donationsQueryString}`;

    const donationsHttpRequest = getAxiosClient().get(donationsUrl);
    const donationsHttpResponse =
        makeApiRequest<IntegrationPaginatedResponse<DonationsStatus>>(
            donationsHttpRequest,
        );

    requests.push(donationsHttpResponse);

    const responseArray = await Promise.all(requests);
    const failedResponse = responseArray.find((r) => !r.success) as ApiResponse<
        IntegrationPaginatedResponse<DonationsStatus>
    >;
    if (failedResponse)
        return {
            success: false,
            error: failedResponse.success === false && failedResponse.error,
        };

    const rowsMerged = responseArray.flatMap((r) =>
        r.success
            ? Array.isArray(r.response)
                ? r.response
                : r.response.data
            : null,
    );
    const lastItem = responseArray[responseArray.length - 1] as ApiResponse<
        IntegrationPaginatedResponse<DonationsStatus>
    >;
    const resultData: IntegrationPaginatedResponse<DonationsStatus> = {
        data: rowsMerged,
        meta: lastItem.success && lastItem.response.meta,
    };

    return {
        success: true,
        response: resultData,
    };
};
