import * as React from 'react';
import {
  ConnectorState,
  ConnectorDispatch,
  ConnectorAction,
  ConnectorProviderProps,
} from '../types';

const defaultState: ConnectorState = {
  user: null,
  donee: null,
  accessToken: '',
  routePaths: {
    root: '/fundraising-tools',
    socialMediaGiving: '/fundraising-tools/social-media-giving',
    snapGive: '/fundraising-tools/snap-give',
    onlineGiving: '/fundraising-tools/online-giving',
    givelithon: '/fundraising-tools/givelithon',
    notFound: '/not-found',
    givelifyButton: '/fundraising-tools/online-giving/givelify-button',
    socialMediaGivingFacebook: '/fundraising-tools/social-media-giving/facebook',
    socialMediaGivingInstagram: '/fundraising-tools/social-media-giving/instagram',
    socialMediaGivingTwitter: '/fundraising-tools/social-media-giving/twitter',
    socialMediaGivingYoutube: '/fundraising-tools/social-media-giving/youtube'
  },
  routeUrls:{
    root: '/fundraising-tools',
    socialMediaGiving: '/fundraising-tools/social-media-giving',
    snapGive: '/fundraising-tools/snap-give',
    onlineGiving: '/fundraising-tools/online-giving',
    givelithon: '/fundraising-tools/givelithon',
    notFound: '/not-found',
    givelifyButton: '/fundraising-tools/online-giving/givelify-button',
    socialMediaGivingFacebook: '/fundraising-tools/social-media-giving/facebook',
    socialMediaGivingInstagram: '/fundraising-tools/social-media-giving/instagram',
    socialMediaGivingTwitter: '/fundraising-tools/social-media-giving/twitter',
    socialMediaGivingYoutube: '/fundraising-tools/social-media-giving/youtube'
  },
  socialMediaFeatureFlag: {
    facebook: true,
    instagram: true,
    youtube: true,
    twitter: true,
  },
  givelithonEnabled: false,
  snapGiveEnabled: false,
  snapGiveQRCodes: [],
  onlineGivingURLs: [],
  reduxActions: {},
};

const ConnectorStateContext = React.createContext<ConnectorState | undefined>(
  undefined
);
const ConnectorDispatchContext = React.createContext<
  ConnectorDispatch | undefined
>(undefined);

function connectorReducer(state: ConnectorState, action: ConnectorAction) {
  switch (action.type) {
    case 'setRoutePath': {
      return { ...state, routePaths: action.payload };
    }

    case 'setSocialMediaFeatureFlag': {
      return { ...state, socialMediaFeatureFlag: action.payload };
    }

    case 'setAccessToken': {
      return { ...state, accessToken: action.payload };
    }

    case 'setGivelithonEnabled': {
      return { ...state, givelithonEnabled: action.payload };
    }

    case 'setSnapGiveEnabled': {
      return { ...state, snapGiveEnabled: action.payload };
    }

    case 'setSnapGiveQRCodes': {
      return { ...state, snapGiveQRCodes: action.payload };
    }

    case 'setDonee': {
      return { ...state, donee: action.payload };
    }

    case 'setOnlineGivingURLs': {
      return { ...state, onlineGivingURLs: action.payload };
    }

    case 'setReduxAction': {
      const { name, handler } = action.payload;
      return {
        ...state,
        reduxActions: {
          ...state.reduxActions,
          [name]: handler,
        },
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action['type']}`);
    }
  }
}

function ConnectorProvider({ children, initialState }: ConnectorProviderProps) {
  const combinedState = {
    ...defaultState,
    ...initialState,
  };

  if (
    !combinedState.user ||
    !combinedState.donee ||
    !combinedState.accessToken
  ) {
    throw new Error(
      'Cannot start fundraising-tools because user or access token is not assigned'
    );
  }

  const [state, dispatch] = React.useReducer(connectorReducer, combinedState);

  return (
    <ConnectorStateContext.Provider value={state}>
      <ConnectorDispatchContext.Provider value={dispatch}>
        {children}
      </ConnectorDispatchContext.Provider>
    </ConnectorStateContext.Provider>
  );
}

function useConnectorState(usingDefault?: boolean) {
  const context = React.useContext(ConnectorStateContext);

  if (usingDefault) return defaultState;
  if (context === undefined) {
    throw new Error(
      'useConnectorState must be used within a ConnectorProvider'
    );
  }
  return context;
}

function useConnectorDispatch() {
  const context = React.useContext(ConnectorDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useConnectorDispatch must be used within a ConnectorProvider'
    );
  }
  return context;
}

function useDonee() {
  const context = React.useContext(ConnectorStateContext);
  if (context === undefined) {
    throw new Error('useDonee must be used within a ConnectorProvider');
  }

  if (!context.donee) {
    throw new Error(
      'donee must be passed over when fundraising-tools is attached'
    );
  }
  return context.donee;
}

export { ConnectorProvider, useConnectorState, useDonee, useConnectorDispatch };
