import { combineReducers } from 'redux';
import DonationsReducer from './donations/reducer';
import DonorsReducer from './donors/reducer';
import EnvelopesReducer from './envelopes/reducer';
import LifeTimeReducer from './lifeTime/reducer';
import NewLifeTimeReducer from './newLifeTime/reducer';

export default combineReducers({
    LifeTime: LifeTimeReducer,
    // TODO: change naming after package @givelify/utils is used everywhere
    NewLifeTime: NewLifeTimeReducer,
    Donations: DonationsReducer,
    Donors: DonorsReducer,
    Envelopes: EnvelopesReducer,
});
