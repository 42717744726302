import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyLabel } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';

export const ResetPasswordDone: React.FC = () => {
    const classes = styles({});
    const { t } = useTranslation();

    const text = useMemo(
        () => ({
            confirmation: t('login.recover.confirmation'),
            pleaseCheckInbox: t('login.recover.pleaseCheckInbox'),
        }),
        [t],
    );

    return (
        <div className={classes.modalContainer}>
            <GivelifyIcon size={72} variant="insititution-new-checkmark" />
            <GivelifyLabel
                className={classes.confirmationText}
                text={text.confirmation}
                variant="heading3"
            />
            <GivelifyLabel
                className={classes.description}
                text={text.pleaseCheckInbox}
                variant="body1"
            />
        </div>
    );
};

export default ResetPasswordDone;
