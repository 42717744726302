import {
    enTranslations as ftENTranslations,
    // esTranslations as ftESTranslations,
    I18N_NAMESPACE as FTI18nNamespace,
} from '@givelify/fundraising-tools';
import {
    enTranslations as GivelithonENTranslations,
    // esTranslations as GivelithonESTranslations,
    I18N_NAMESPACE as GivelithonNamespace,
} from '@givelify/givelithon-ui';
import {
    onboardingEn,
    // onboardingEs,
    I18N_NAMESPACE as ONI18nNamespace,
} from '@givelify/onboarding';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonEn from './translations/en/common.json';
// import commonEs from './translations/es/common.json';




i18n.use(detector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',

        interpolation: {
            escapeValue: false, // React does this automatically
        },
        returnObjects: true,

        resources: {
            en: {
                common: commonEn,
                [FTI18nNamespace]: ftENTranslations,
                [GivelithonNamespace]: GivelithonENTranslations,
                [ONI18nNamespace]: onboardingEn,
            },
            // es: {
            //     common: commonEs,
            //     [FTI18nNamespace]: ftESTranslations,
            //     [GivelithonNamespace]: GivelithonESTranslations,
            // },
            // Reference: https://github.com/Givelify/donee-portal-ui/pull/812
        },

        react: {
            useSuspense: false,
        },

        defaultNS: 'common',
    });

export default i18n;
