import { RoutePath, RouteUrls, SocialMediaName } from '../../types';

type SocialMedia = {
    [name in SocialMediaName]: {
        title: string;
        description: string;
        path: string;
        url: string;
    };
};

export const getSocialMediaCards = (
    routePaths: RoutePath,
    routeUrls: RouteUrls,
): SocialMedia => ({
    facebook: {
        title: 'Facebook',
        description:
            'Optimize your Facebook to increase your reach and donations.',
        path: routePaths.socialMediaGivingFacebook || '',
        url: routeUrls.socialMediaGivingFacebook || '',
    },
    twitter: {
        title: 'Twitter',
        description:
            'Use Twitter’s quick updates to connect with potential donors.',
        path: routePaths.socialMediaGivingTwitter || '',
        url: routeUrls.socialMediaGivingTwitter || '',
    },

    instagram: {
        title: 'Instagram',
        description:
            'Tell your organization’s story with photos. Show donors where their donations go.',
        path: routePaths.socialMediaGivingInstagram || '',
        url: routeUrls.socialMediaGivingInstagram || '',
    },

    youtube: {
        title: 'YouTube',
        description:
            'The world’s biggest video and live streaming site is also the second-biggest search engine.',
        path: routePaths.socialMediaGivingYoutube || '',
        url: routeUrls.socialMediaGivingYoutube || '',
    },
});
