import { ClassNameMap, createStyles, makeStyles } from '@mui/styles';

export interface GivelifyBoxMarginProps {
    /**
     * Left margin.
     */
    marginLeft?: number | 'auto';
    /**
     * Right margin.
     */
    marginRight?: number | 'auto';
    /**
     * Bottom margin.
     */
    marginBottom?: number | 'auto';
    /**
     * Top margin.
     */
    marginTop?: number | 'auto';
    /**
     * Margin.
     */
    margin?: number | 'auto';
}

type GivelifyLoadingStyleProps = GivelifyBoxMarginProps;
export const GivelifyLoadingStyles: (
    props: GivelifyBoxMarginProps,
) => ClassNameMap<'boxMargin' | 'progressBar' | 'blurOverly'> = makeStyles(() =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyLoadingStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyLoadingStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyLoadingStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyLoadingStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        progressBar: {
            backgroundColor: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100%',
        },
        blurOverly: {
            filter: 'blur(10px)',
            backgroundColor: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100%',
        },
    }),
);
