import { Donation } from 'pages/donors/DonorProfile';
import { Donor } from 'types/donorTypes';

export interface DonorResponseData {
    id: number;
    name: string;
    email: string;
    phone: string;
    picture: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
    lastDonationDate: string | Date;
    externalId: string;
    lastDonationAmount: number;
    lifetimeDonations: number;
    donationStatus: string;
    doneeDonorStatusTypesId: number;
    givelifyNumber?: string;
    donations?: Donation[];
}

export const mapDonorResponseDataToDonor = (donor: DonorResponseData) => {
    const mappedDonor: Donor = {
        id: donor.id,
        givelifyNumber: donor.givelifyNumber,
        externalId: donor.externalId,
        name: donor.name,
        donorType: {
            id: donor.doneeDonorStatusTypesId,
            statusType: donor.donationStatus,
        },
        phone: donor.phone,
        email: donor.email,
        address: donor.address,
        city: donor.city,
        state: donor.state,
        country: donor.country,
        zip: donor.zip,
        picture: donor.picture,
        lastDonationDate: donor.lastDonationDate,
        lastDonationAmount: donor.lastDonationAmount,
        lifetimeDonations: donor.lifetimeDonations,
    };
    return mappedDonor;
};

export enum DONOR_TYPE {
    UNDEFINED = 'UNDEFINED',
    REGULAR = 'REGULAR',
    RECURRING = 'RECURRING',
    NEW = 'NEW',
    SECOND_TIME = 'SECOND_TIME',
    OCCASIONAL = 'OCCASIONAL',
    AT_RISK = 'AT_RISK',
    ALL = 'ALL',
}

export enum DONOR_TYPE_ID {
    UNDEFINED = 1,
    REGULAR = 2,
    RECURRING = 3,
    NEW = 4,
    SECOND_TIME = 5,
    OCCASIONAL = 6,
    AT_RISK = 7,
}

export interface DonorMatchingTableProps {
    reportType: string;
    doneeId: number;
    onSuccess: (id: number) => void;
    pageSize: number;
}

export interface MetaMatchedDonorProgress {
    meta: {
        totalMatchedDonors: number;
        totalDonors: number;
    };
}

export interface DonorMatchingProgressResponse
    extends MetaMatchedDonorProgress {
    data: DonorResponseData[];
}
