import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    barChartContainer: {
        '& .recharts-surface': {
            maxHeight: '400px',
        },
        '& .recharts-legend-wrapper': {
            bottom: '-5px !important',
            '& .recharts-legend-item': {
                marginRight: theme.spacing(4) + 'px !important',
            },
        },
        '& .recharts-cartesian-axis-tick': {},
    },
}));
