import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyTableStyleProps = GivelifyBoxMarginProps;

export const GivelifyTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyTableStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyTableStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyTableStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyTableStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        tbTable: {
            width: '100%',
            boxSizing: 'border-box',
            tableLayout: 'fixed',
            borderSpacing: 0,
            backgroundColor: theme.colors ? theme.colors.primaryWhite : '#FFF',
        },
        tbScroller: {
            '&::-webkit-scrollbar': {
                width: 0,
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgb(216, 215, 224)',
            },
        },
    }),
);

type GivelifyTableDynamicStyleProps = {
    width?: number;
    offset?: number;
    fixed?: boolean;
};

export const GivelifyTableDynamicStyles = makeStyles<
    Theme,
    GivelifyTableDynamicStyleProps
>(_theme =>
    createStyles({
        tbCellWidth: {
            width: props => (props.width ? props.width : undefined),
            minWidth: props => (props.width ? props.width : undefined),
            maxWidth: props => (props.width ? props.width : undefined),
            position: props => (props.fixed ? 'sticky' : undefined),
            left: props =>
                Number.isInteger(props.offset) ? props.offset : undefined,
            zIndex: props => (props.fixed ? 1 : undefined), // Need zIndex here to corretly display GivelifyTextField
        },
    }),
);
