import React from 'react';
import {
    GivelifyLabel,
    GivelifyLabelStyles,
    GivelifyModal,
    mergeClassNames,
} from '@givelify/givelify-ui';
import Grid from '@material-ui/core/Grid/Grid';
import { useTranslation } from 'react-i18next';
import CheckMarkIcon from '../../../assets/check-mark-circle.svg';
import ErrorMarkIcon from '../../../assets/error-mark-circle.svg';
import { I18N_NAMESPACE } from '../../../consts';
import useStyles from './styles';

export interface PopupTipProps {
    open: boolean;
    onClose: () => unknown;
}

const PopupTip: React.FC<PopupTipProps> = ({ open, onClose }) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const title = t('imageUploadTooltip.title');
    const { gridStyles } = useStyles();
    const { body1 } = GivelifyLabelStyles({});
    return (
        <GivelifyModal
            autoFullscreen
            showCloseButton
            onClose={onClose}
            open={open}
        >
            <GivelifyLabel
                bold
                marginBottom={40}
                marginLeft="auto"
                marginRight="auto"
                text={title}
                variant="heading2"
            />
            <Grid container className={gridStyles}>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <div className="check-image">
                        <img
                            alt="Check-mark"
                            src={CheckMarkIcon}
                            title="Check-mark"
                        />
                    </div>
                    <div className="border-right">
                        <div className="box-container border-bottom">
                            <div className={mergeClassNames('left-box', body1)}>
                                {t('imageUploadTooltip.dos.first')}
                            </div>
                        </div>
                        <div className="box-container border-bottom">
                            <div className={mergeClassNames('left-box', body1)}>
                                {t('imageUploadTooltip.dos.second')}
                            </div>
                        </div>
                        <div className="box-container">
                            <div className={mergeClassNames('left-box', body1)}>
                                {t('imageUploadTooltip.dos.third')}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <div className="error-image">
                        <img
                            alt="Error-mark"
                            src={ErrorMarkIcon}
                            title="Error-mark"
                        />
                    </div>
                    <div className="box-container border-bottom">
                        <div className={mergeClassNames('right-box', body1)}>
                            {t('imageUploadTooltip.donts.first')}
                        </div>
                    </div>
                    <div className="box-container border-bottom">
                        <div className={mergeClassNames('right-box', body1)}>
                            {t('imageUploadTooltip.donts.second')}
                        </div>
                    </div>
                    <div className="box-container">
                        <div className={mergeClassNames('right-box', body1)}>
                            {t('imageUploadTooltip.donts.third')}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </GivelifyModal>
    );
};

export default React.memo(PopupTip);
