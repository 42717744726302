import { useMemo, useState } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import ResetPasswordDone from 'components/ResetPasswordDone/ResetPasswordDone';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import {
    LOGIN_PAGE_CLICK_FORGOT_PASSWORD,
    LOGIN_PAGE_CLICK_FORGOT_PASSWORD_CLOSE,
} from 'utils/clevertapEvents';
import { webConfig } from 'webConfig';
import LoginForm from '../../../components/forms/LoginForm';
import RecoverPassword from '../../../components/forms/recoverPassword/RecoverPassword';
import PageTitle from '../../../components/PageTitle';
import GivelifyModal from '../../../theme/components/GivelifyModal';
import { useStyles } from '../useStyles';

const View = () => {
    const { trackEvent } = useTrackingContext();
    const classes = useStyles({});
    const { t } = useTranslation();

    const loginText = useMemo(
        () => ({
            text: {
                page: t('login.text.page'),
                loginTitle: t('login.text.login_title'),
                announcementStart: t('login.text.announcementStart'),
                announcementEnd: t('login.text.announcementEnd'),
            },
            link: {
                forgotPassword: t('login.link.forgot_password'),
                learnMore: t('login.link.learnMore'),
            },
        }),
        [t],
    );

    const [resetPasswordModalOpen, setResetPasswordModalOpen] =
        useState<boolean>(false);
    const [resetSuccessedModalOpen, setResetSuccessedModalOpen] =
        useState<boolean>(false);

    const handleResetPasswordModalOpen = () => {
        trackEvent(LOGIN_PAGE_CLICK_FORGOT_PASSWORD);
        setResetPasswordModalOpen(true);
    };

    const handleResetPasswordModalClose = () => {
        trackEvent(LOGIN_PAGE_CLICK_FORGOT_PASSWORD_CLOSE);
        setResetPasswordModalOpen(false);
    };

    const handleResetPasswordSuccessedModalOpen = () => {
        setResetSuccessedModalOpen(true);
    };

    const handleResetPasswordSuccessedModalClose = () => {
        setResetSuccessedModalOpen(false);
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey={webConfig.googleSiteKey}>
            <PageTitle
                description={loginText.text.page}
                title={loginText.text.page}
            />
            <GivelifyLabel
                bold
                className={classes.loginTitle}
                text={loginText.text.loginTitle}
                variant="heading3"
            />
            <LoginForm handleResetPasswordOpen={handleResetPasswordModalOpen} />
            <GivelifyModal
                handleClose={handleResetPasswordModalClose}
                isOpen={resetPasswordModalOpen}
                label="Reset Password"
            >
                <RecoverPassword
                    handleClose={handleResetPasswordModalClose}
                    handleResetPasswordSucceededModalOpen={
                        handleResetPasswordSuccessedModalOpen
                    }
                />
            </GivelifyModal>
            <GivelifyModal
                handleClose={handleResetPasswordSuccessedModalClose}
                isOpen={resetSuccessedModalOpen}
                label="Reset Password Done"
            >
                <ResetPasswordDone />
            </GivelifyModal>
        </GoogleReCaptchaProvider>
    );
};

export default View;
