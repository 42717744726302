import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    GivelifyLabel,
    GivelifySelect,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store';
import { PaginatedResponse } from '../../../../../../../types/paginationTypes';
import { CCBEnvelope } from '../../types';
import { getSubFund } from '../automatedIntegrationRequest';
import { useCCBRowStyles } from './AutoIntegrationEnvelopeUnmatchRow';

interface F1SelectFundProps {
    listEnvelope: CCBEnvelope[];
    onChangeEnvelope: (value) => void;
    onChangeSubFund: (value) => void;
}

const F1SelectFund: React.FC<F1SelectFundProps> = ({
    listEnvelope,
    onChangeEnvelope,
    onChangeSubFund,
}) => {
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));

    const styles = useCCBRowStyles();
    const TRANSLATION_KEY = 'pages.integration_envelope_matching.content.f1';
    const { t, scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: TRANSLATION_KEY,
    });
    const copy = useMemo(
        () => ({
            selectPrimary: scopedTranslate('selectPrimary'),
            selectSub: scopedTranslate('selectSub'),
            fund: scopedTranslate('fund'),
            fundId: t('f1EnvelopePage.fundId'),
        }),
        [t, scopedTranslate],
    );
    const [requestSubFunds, makeRequestSubFunc] = useApiRequest<
        PaginatedResponse<CCBEnvelope>
    >();
    const [subFundList, setSubFundList] = useState<CCBEnvelope[]>([]);
    const subFund = useCallback(
        (parentId: number) => {
            makeRequestSubFunc(getSubFund(userDoneeId, parentId));
        },
        [userDoneeId, makeRequestSubFunc],
    );
    const changeChmsEnvelope = value => {
        onChangeEnvelope(value);
        setSubFundList([]);
        if (value.childrenCount > 0) {
            subFund(value.id);
        }
    };
    useEffect(() => {
        if (requestSubFunds.type === 'REQUEST_SUCCESS') {
            setSubFundList(requestSubFunds.response.data);
        }
    }, [requestSubFunds]);
    const renderOption = (option: { key: number; value: string }) => {
        return (
            <div className={styles.selectFund}>
                <GivelifyLabel
                    bold
                    className={styles.nowrap}
                    marginBottom={4}
                    text={option.value}
                    variant="body2"
                />
                <GivelifyLabel
                    className={styles.nowrap}
                    text={`${copy.fundId}: ${option.key}`}
                    variant="small"
                />
            </div>
        );
    };

    return (
        <Grid container>
            <Grid item xs={6}>
                <GivelifySelect
                    fullWidth
                    ariaLabel="f1Fund"
                    className={styles.selectEnvelope}
                    inputLabel="f1Fund"
                    onChange={changeChmsEnvelope}
                    options={listEnvelope?.map(envelope => ({
                        key: envelope.chmsInternalId,
                        value: envelope.name,
                        id: envelope.id ? envelope.id : '',
                        childrenCount: envelope.childrenCount,
                    }))}
                    placeholder={copy.selectPrimary}
                    renderOption={renderOption}
                />
            </Grid>
            <Grid item xs={6}>
                {subFundList.length > 0 && (
                    <GivelifySelect
                        fullWidth
                        ariaLabel="f1SubFund"
                        className={styles.selectEnvelope}
                        inputLabel="f1SubFund"
                        onChange={onChangeSubFund}
                        options={subFundList?.map(envelope => ({
                            key: envelope.chmsInternalId,
                            value: envelope.name,
                        }))}
                        placeholder={copy.selectSub}
                        renderOption={renderOption}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default F1SelectFund;
