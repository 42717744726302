import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import Config from '../../../../config';
import { useDonee } from '../../../../provider/Connector';
import { I18N_NAMESPACE } from '../../../../types/const';
import { encodeDoneeId } from '../../../../utils/donee';
import { useTranslations } from '../../../../utils/translations';

export type FormInput = {
    name: string;
    email: string;
};

export default function useComponentData() {
    const [errorMessage, setErrorMessage] = React.useState('');
    const { id: doneeId } = useDonee();
    const { t } = useTranslation(I18N_NAMESPACE);
    const { shareModal } = useTranslations('onlineGiving.givelifyButton', {
        shareModal: [
            'title',
            'copyLink',
            'or',
            'email',
            'back',
            'send',
            'receiptName',
            'receiptEmail',
        ],
    });
    const { validation } = useTranslations('general.errors', {
        validation: ['required', 'email'],
    }) as { validation: { required: string; email: string } };

    // Form registration
    const formMethods = useForm<FormInput>({
        resolver: yupResolver(
            object({
                name: string()
                    .required(validation.required)
                    .min(
                        3,
                        t('general.errors.validation.minLength', { count: 3 }),
                    )
                    .max(
                        101,
                        t('general.errors.validation.minLength', {
                            count: 101,
                        }),
                    ),
                email: string()
                    .required(validation.required)
                    .email(validation.email)
                    .max(
                        255,
                        t('general.errors.validation.maxLength', {
                            count: 255,
                        }),
                    ),
            }),
        ),
    });

    // Get Givelify button public page
    const publicPageLink = React.useMemo(
        () =>
            `${Config.analyticsBaseUrl}/givelify-button/${encodeDoneeId(
                doneeId,
            )}`,
        [doneeId],
    );

    React.useEffect(() => {
        // pushEvent(ONLINE_GIVING_LANDED);

        return () => {
            setErrorMessage('');
        };
    }, []);

    return {
        publicPageLink,
        shareModal,
        formMethods,
        errorMessage,
        setErrorMessage,
    };
}
