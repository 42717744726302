import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

const DESKTOP_DRAWER_WIDTH = 288;
const DESKTOP_HEADER_HEIGHT = 96;

const TABLET_DRAWER_WIDTH = 96;
const TABLET_HEADER_HEIGHT = 64;

const MOBILE_FOOTER_SPACE = 86;
const MOBILE_HEADER_HEIGHT = 70;

export const Layout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    position: 'relative',
});

export const ContentRoot = styled('div', {
    shouldForwardProp: (propName) => propName !== 'extraMargin',
})<{
    extraMargin: number;
}>(({ extraMargin, theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: DESKTOP_DRAWER_WIDTH,
    marginTop: DESKTOP_HEADER_HEIGHT + extraMargin,
    padding:
        extraMargin > 0 ? theme.spacing(3, 3, 2, 4) : theme.spacing(6, 3, 2, 4),
    [theme.breakpoints.down('tablet')]: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(0),
        marginLeft: TABLET_DRAWER_WIDTH,
        marginTop: TABLET_HEADER_HEIGHT + extraMargin,
    },
    [theme.breakpoints.down('smallTablet')]: {
        marginTop: TABLET_HEADER_HEIGHT + extraMargin,
    },
    [theme.breakpoints.down('mobile')]: {
        padding: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: MOBILE_FOOTER_SPACE,
        marginLeft: 0,
        marginTop: MOBILE_HEADER_HEIGHT + extraMargin,
    },
}));

export const MessageContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1088,
});

export const MainContent = styled('main', {
    shouldForwardProp: (propName) => propName !== 'isLegacyPage',
})<{ isLegacyPage: boolean }>(({ isLegacyPage, theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1088,
    ...(isLegacyPage && {
        padding: theme.spacing(3, 2),
        borderRadius: 20,
        backgroundColor: DesignTokens.color.backgroundSecondary,
        boxShadow: DesignTokens.shadow.shadowLowHigh,
    }),
}));

export const CaptivePortalLayout = styled('main')(({ theme }) => ({
    marginTop: DESKTOP_HEADER_HEIGHT,
    marginLeft: 76,
    '& > div': {
        marginTop: 48,
    },
    [theme.breakpoints.down('tablet')]: {
        margin: `${TABLET_HEADER_HEIGHT}px 32px 0 32px`,
        '& > div': {
            marginTop: 24,
        },
    },
    [theme.breakpoints.down('mobile')]: {
        paddingBottom: MOBILE_FOOTER_SPACE,
        margin: `${MOBILE_HEADER_HEIGHT}px 16px 0 16px`,
        '& > div': {
            marginTop: 24,
        },
    },
}));
