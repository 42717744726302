import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { getPrimaryRepresentativeApiEndpoint } from 'api/client/endpoints';
import { IPrimaryRepService } from '../interfaces/IPrimaryRepService';
import { UpdatePrimaryRepRequest } from '../requests';

export class PrimaryRepService implements IPrimaryRepService {
    public async updatePrimaryRepresentative(
        doneeId: number,
        payload: UpdatePrimaryRepRequest,
    ): Promise<ApiResponse<unknown>> {
        const url = getPrimaryRepresentativeApiEndpoint(doneeId);
        const formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formData.append(key, value.toString());
            }
        });
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = await makeApiRequest<unknown>(httpRequest);
        return result;
    }
}
