import React, { useMemo } from 'react';
import { PAGE_NAME } from '@givelify/utils';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAdvancedTranslation } from 'utils/i18n';
import { webConfig } from 'webConfig';
import { AppState } from '../../../store';
import { isFeatureEnabled, Features } from '../../../utils/featureGating';
import { SETTINGS_ASSETS_PATH } from '../SettingsAssetsPath';
import SettingsPage from '../SettingsPage';
import SettingsTile from './components/SettingsTile';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: 40,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
}));

const SettingsDashboard: React.FC = () => {
    const { PATH } = useGasRouterContext();
    const { container } = useStyles();
    const isSynagogue = useSelector(
        (state: AppState) => state.User.user?.isSynagogue,
    );
    const { enabledFeatures, donee } = useSelector((state: AppState) => ({
        enabledFeatures: state.System.enabledFeatures,
        donee: state.Donee.donee,
    }));

    const isChurch = donee?.type === 'church';

    const { t } = useAdvancedTranslation();
    const navigate = useNavigate();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.title'),
            appProfile: t('pages.settings.app-profile.title'),
            appProfileCaption: t('pages.settings.app-profile.caption'),
            bankInfo: t('pages.settings.bank-info.title'),
            bankInfoCaption: t('pages.settings.bank-info.caption'),
            users: t('pages.settings.users.title'),
            usersCaption: t('pages.settings.users.caption'),
            organizationInfo: t('pages.settings.organization-info.title'),
            organizationInfoCaption: t(
                'pages.settings.organization-info.caption',
            ),
            campuses: t('pages.settings.campuses.title'),
            campusesCaption: t('pages.settings.campuses.caption'),
            missionStatement: t('pages.settings.mission-statement.title'),
            missionStatementCaption: t(
                'pages.settings.mission-statement.caption',
            ),
            quickGive: t('pages.settings.quick-give.title'),
            quickGiveCaption: t('pages.settings.quick-give.caption'),
            needHelp: t('pages.settings.need-help.title'),
            needHelpCaption: t('pages.settings.need-help.caption'),
        }),
        [t],
    );
    const onTileClick = (actionUrl: string) => {
        navigate(actionUrl);
    };
    const features = useMemo(
        () => ({
            allSettings: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_ALL,
            ),
            appProfile: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_APP_PROFILE,
            ),
            orgInfo: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_ORGANIZATION_INFO,
            ),
            mission: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_MISSION,
            ),
            bankInfo: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_BANK_INFO,
            ),
            quickGive: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_QUICK_GIVE,
            ),
            users: isFeatureEnabled(enabledFeatures, Features.SETTINGS_USERS),
            campuses: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_CAMPUSES,
            ),
            needHelp: isFeatureEnabled(
                enabledFeatures,
                Features.SETTINGS_NEED_HELP,
            ),
        }),
        [enabledFeatures],
    );

    return (
        <SettingsPage
            trackPageVisit
            useDivider
            fullWidth={true}
            pageName={PAGE_NAME.Settings}
            title={copy.title}
        >
            <Grid container className={container} spacing={3}>
                {features.appProfile && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.APP_PROFILE('profile')}
                            badgeUrl={SETTINGS_ASSETS_PATH.USER_ICON}
                            description={copy.appProfileCaption}
                            onClick={onTileClick}
                            title={copy.appProfile}
                        />
                    </Grid>
                )}
                {features.bankInfo && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.BANK_INFO('account')}
                            badgeUrl={SETTINGS_ASSETS_PATH.BANK_INFO}
                            description={copy.bankInfoCaption}
                            onClick={onTileClick}
                            title={copy.bankInfo}
                        />
                    </Grid>
                )}
                {features.users && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.USERS()}
                            badgeUrl={SETTINGS_ASSETS_PATH.USERS_ICON}
                            description={copy.usersCaption}
                            onClick={onTileClick}
                            title={copy.users}
                        />
                    </Grid>
                )}
                {features.orgInfo && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.ORG_INFO}
                            badgeUrl={SETTINGS_ASSETS_PATH.ORGANIZATION_INFO}
                            description={copy.organizationInfoCaption}
                            onClick={onTileClick}
                            title={copy.organizationInfo}
                        />
                    </Grid>
                )}
                {features.campuses && isChurch && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.CAMPUSES}
                            badgeUrl={SETTINGS_ASSETS_PATH.LOCATIONS}
                            description={copy.campusesCaption}
                            onClick={onTileClick}
                            title={copy.campuses}
                        />
                    </Grid>
                )}
                {features.mission && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.MISSION_STATEMENT}
                            badgeUrl={SETTINGS_ASSETS_PATH.MISSION_STATEMENT}
                            description={copy.missionStatementCaption}
                            onClick={onTileClick}
                            title={copy.missionStatement}
                        />
                    </Grid>
                )}
                {features.quickGive && isSynagogue ? (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            actionUrl={PATH.SETTINGS.QUICK_GIVE}
                            badgeUrl={SETTINGS_ASSETS_PATH.GIVE_HAND}
                            description={copy.quickGiveCaption}
                            onClick={onTileClick}
                            title={copy.quickGive}
                        />
                    </Grid>
                ) : (
                    ''
                )}
                {features.needHelp && (
                    <Grid item md={4} sm={6} xs={12}>
                        <SettingsTile
                            isLink
                            actionUrl={webConfig.supportUrl}
                            badgeUrl={SETTINGS_ASSETS_PATH.QUESTION_MARK}
                            description={copy.needHelpCaption}
                            onClick={onTileClick}
                            title={copy.needHelp}
                        />
                    </Grid>
                )}
            </Grid>
        </SettingsPage>
    );
};

export default SettingsDashboard;
