import React, { useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyFormTextField,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
    GivelifyLoading,
} from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { useCaptivePortalContext } from '../../../../CaptivePortalProvider';
import { OnboardingNote } from '../../../components/OnboardingNote';
import { OnboardingActions } from '../../../OnboardingActions';
import OnboardingPage from '../../../OnboardingPage';
import { UseTaxIdContext } from '../TaxIdContext';
import { ApplyForTaxIdModal } from './ApplyForTaxId';
import { NonProfitApplyForTaxIdModal } from './NonProfitApplyForTaxId';
import { TaxIdInfoHelp } from './TaxIdInfoHelp';
import { FormSchema } from './types';

const StyledForm = styled('div')(({ theme }) => ({
    '& .page-description': {
        color: DesignTokens.color.textSecondary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '14px',
        },
    },
    '& .input-field': {
        marginTop: '24px',
    },
}));

const TaxIdInfoForm: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const { t } = useTranslation(I18N_NAMESPACE);
    const { formState } = useFormContext();
    const { inviteOfficialEnabled, navigateToUsers } =
        useCaptivePortalContext();

    const {
        onBackClick,
        onCancelClick,
        organizationType,
        uploadFileInsteadLinkClick,
    } = UseTaxIdContext();

    const copy = useMemo(
        () => ({
            ein: t('taxId.ein'),
            einDescription: t('taxId.einDescription'),
            einPlaceholder:
                organizationType === 'church'
                    ? t('taxId.taxIdPlaceholder')
                    : t('taxId.einPlaceholder'),
            nonprofitHint: t('taxId.nonprofitHint'),
            nonprofitUploadDocument: t('taxId.nonprofitUploadDocument'),
            inviteOfficialNote: t('taxId.inviteOfficialNote'),
            inviteOfficialLink: t('taxId.inviteOfficialLink'),
        }),
        [t, organizationType],
    );

    const {
        formState: { isSubmitting, isDirty },
    } = useFormContext();

    return (
        <StyledForm>
            <GivelifyLabel
                style={{
                    marginBottom: isMobile ? 8 : 12,
                    display: 'flex',
                    alignItems: 'center',
                }}
                variant="heading1S"
            >
                <span>{copy.ein}</span>
                <GivelifyLink
                    fontSize={24}
                    marginLeft="8px"
                    onClick={() => setShowHelp(true)}
                    text={<GivelifyIcon variant="help" />}
                />
            </GivelifyLabel>

            <GivelifyLabel
                style={{
                    color: DesignTokens.color.textSecondary,
                }}
                text={copy.einDescription}
                variant="body1"
            />

            {inviteOfficialEnabled && (
                <OnboardingNote
                    linkAction={() => navigateToUsers(true)}
                    linkName="Invite Official"
                    linkText={copy.inviteOfficialLink}
                    text={copy.inviteOfficialNote}
                />
            )}

            <div>
                <GivelifyFormTextField<FormSchema>
                    fullWidth
                    InputProps={{
                        endAdornment: isSubmitting ? (
                            <GivelifyLoading size={24} type="circular" />
                        ) : null,
                    }}
                    className="input-field"
                    id="taxId"
                    label={copy.einPlaceholder}
                    maskOptions={{ mask: '00-0000000' }}
                    name="taxId"
                    placeholder={copy.einPlaceholder}
                    size="large"
                />
                {organizationType === 'nonprofit' && (
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        fontWeight={600}
                        marginTop="12px"
                        variant="body2"
                    >
                        {copy.nonprofitHint}
                        <GivelifyLink
                            fontWeight={600}
                            onClick={uploadFileInsteadLinkClick}
                            text={copy.nonprofitUploadDocument}
                        />
                    </GivelifyLabel>
                )}
            </div>
            {organizationType === 'church' ? (
                <ApplyForTaxIdModal
                    onClose={() => setShowHelp(false)}
                    open={showHelp}
                />
            ) : (
                <NonProfitApplyForTaxIdModal
                    onClose={() => setShowHelp(false)}
                    open={showHelp}
                />
            )}
            <OnboardingActions
                disableContinue={!formState.isValid}
                onBack={onBackClick}
                onCancel={() => onCancelClick(!isDirty)}
            />
        </StyledForm>
    );
};

const TaxIdInfoWrapper: React.FC = () => {
    return (
        <TrackingProvider trackPageVisit pageName="EIN Federal Tax Id">
            <OnboardingPage
                content={<TaxIdInfoForm />}
                informationContent={<TaxIdInfoHelp />}
            />
        </TrackingProvider>
    );
};

export default TaxIdInfoWrapper;
