import { useFilterParams } from 'api/hooks/useFilterParams';

export type SortFilter = {
    sort: string;
};

const paramNames = ['sort'] as const;

export const useSortFilter = (defaultValue?: SortFilter) => {
    const [filter, setFilter] = useFilterParams<SortFilter, typeof paramNames>(
        defaultValue
            ? defaultValue
            : {
                  sort: '',
              },
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'sort':
                default:
                    return state.sort;
            }
        },
        (values) => {
            return {
                sort: values['sort'],
            };
        },
    );
    return [filter, setFilter] as const;
};
