import React, { CSSProperties } from 'react';
import { SvgIcon, styled } from '@mui/material';
import * as Icons from '../assets/icons';
import { DesignTokens } from '../specify';

export const GivelifyIconVariants = [
    'bank',
    'bars',
    'calendar',
    'campuses',
    'check',
    'circle',
    'close',
    'close-outlined',
    'donations',
    'donors',
    'down-arrow',
    'down-arrow-circle',
    'down-chevron',
    'ellipsis',
    'envelopes',
    'file',
    'file-alt',
    'help',
    'info',
    'integrations',
    'left-arrow',
    'left-chevron',
    'lock',
    'more',
    'overview',
    'password-hidden',
    'password-visible',
    'quick-give',
    'regenerate',
    'reminder',
    'reports',
    'right-arrow',
    'right-chevron',
    'select',
    'settings',
    'trash',
    'up-arrow',
    'up-chevron',
    'left-chevron-double',
    'right-chevron-double',
    'bank-deposits',
    'by-donor-fill',
    'by-envelope-fill',
    'data-exports',
    'donors-old',
    'hand-holding-usd-fill',
    'import-friendly',
    'quickbooks',
    'poll',
    'report-colored',
    'file-export',
    'tax-id',
    'reminder-colored',
    'error-warning-colored',
    'success-colored',
    'bank-colored',
    'primary-rep-colored',
    'organization',
    'facebook',
    'twitter',
    'warning',
    'verifyIcon',
    'success-box-icon',
    'info-box-icon',
    'error-box-icon',
    'warning-box-icon',
    'upload',
    'alert',
    'info-warning',
    'lock-check',
    'summary',
    'envelope',
    'users',
    'avatar',
    'image',
    'lock-old',
    'success',
    'givelify',
    'earthGlobe',
    'phone',
    'edit',
    'email',
    'phone-new',
    'location',
    'square-usa',
    'square-bahamas',
    'square-no-flag',
] as const;

export type GivelifyIconVariant = (typeof GivelifyIconVariants)[number];

const getIconSvg: Record<GivelifyIconVariant, React.ComponentType> = {
    bank: Icons.SvgBank,
    'bank-colored': Icons.SvgBankColored,
    'bank-deposits': Icons.SvgBankDeposits,
    bars: Icons.SvgBars,
    'by-donor-fill': Icons.SvgByDonorFill,
    'by-envelope-fill': Icons.SvgByEnvelopeFill,
    calendar: Icons.SvgCalendar,
    campuses: Icons.SvgCampuses,
    check: Icons.SvgCheck,
    circle: Icons.SvgCircle,
    close: Icons.SvgClose,
    'close-outlined': Icons.SvgCloseOutlined,
    'data-exports': Icons.SvgDataExports,
    donations: Icons.SvgDonations,
    donors: Icons.SvgDonors,
    'donors-old': Icons.SvgDonorsOld,
    'down-arrow': Icons.SvgDownArrow,
    'down-arrow-circle': Icons.SvgDownArrowCircle,
    'down-chevron': Icons.SvgDownChevron,
    ellipsis: Icons.SvgEllipsis,
    envelopes: Icons.SvgEnvelopes,
    'error-warning-colored': Icons.SvgErrorWarningColored,
    file: Icons.SvgFile,
    'file-alt': Icons.SvgFileAlt,
    'file-export': Icons.SvgFileExport,
    'hand-holding-usd-fill': Icons.SvgHandHoldingUsdFill,
    help: Icons.SvgHelp,
    'import-friendly': Icons.SvgImportFriendly,
    info: Icons.SvgInfo,
    integrations: Icons.SvgIntegrations,
    'left-arrow': Icons.SvgLeftArrow,
    'left-chevron': Icons.SvgLeftChevronBig,
    'left-chevron-double': Icons.SvgLeftChevronDouble,
    lock: Icons.SvgLock,
    more: Icons.SvgMore,
    organization: Icons.SvgOrganization,
    overview: Icons.SvgOverview,
    'password-hidden': Icons.SvgPasswordHidden,
    'password-visible': Icons.SvgPasswordVisible,
    poll: Icons.SvgPoll,
    'primary-rep-colored': Icons.SvgPrimaryRepColored,
    'quick-give': Icons.SvgQuickGive,
    quickbooks: Icons.SvgQuickbooks,
    regenerate: Icons.SvgRegenerate,
    reminder: Icons.SvgReminder,
    'reminder-colored': Icons.SvgReminderColored,
    'report-colored': Icons.SvgReportColored,
    reports: Icons.SvgReports,
    'right-arrow': Icons.SvgRightArrow,
    'right-chevron': Icons.SvgRightChevronBig,
    'right-chevron-double': Icons.SvgRightChevronDouble,
    select: Icons.SvgSelect,
    settings: Icons.SvgSettings,
    'success-colored': Icons.SvgSuccessColored,
    'tax-id': Icons.SvgTaxId,
    trash: Icons.SvgTrash,
    'up-arrow': Icons.SvgUpArrow,
    'up-chevron': Icons.SvgUpChevron,
    facebook: Icons.SvgFacebook,
    twitter: Icons.SvgTwitter,
    warning: Icons.SvgWarning,
    verifyIcon: Icons.SvgVerify,
    'success-box-icon': Icons.SvgSuccessBoxIcon,
    'info-box-icon': Icons.SvgInfoBoxIcon,
    'error-box-icon': Icons.SvgErrorBoxIcon,
    'warning-box-icon': Icons.SvgWarningBoxIcon,
    upload: Icons.UploadIcon,
    alert: Icons.AlertIcon,
    'info-warning': Icons.InfoWarningIcon,
    'lock-check': Icons.LockCheckIcon,
    summary: Icons.SvgGivingSummary,
    envelope: Icons.EnvelopeIcon,
    users: Icons.UsersIcon,
    avatar: Icons.SvgAvatar,
    image: Icons.SvgImage,
    'lock-old': Icons.SvgLockOld,
    success: Icons.SvgSuccessIcon,
    givelify: Icons.SvgGivelifyLogoIcon,
    earthGlobe: Icons.SvgEarthGlobeIcon,
    phone: Icons.SvgPhoneIcon,
    edit: Icons.SvgEdit,
    email: Icons.SvgEmail,
    'phone-new': Icons.SvgPhoneNew,
    location: Icons.SvgLocation,
    'square-usa': Icons.SvgSquareUSA,
    'square-bahamas': Icons.SvgSquareBahamas,
    'square-no-flag': Icons.SvgSquareNoFlag,
};

type StyleIconProp = {
    fontSize?: 'inherit' | number;
    color?: 'inherit' | string;
};

const StyleIcon = styled('span')<StyleIconProp>((props) => ({
    fontSize: props.fontSize
        ? props.fontSize === 'inherit'
            ? 'inherit'
            : `${props.fontSize}px`
        : DesignTokens.measurement.iconDefaultSize,
    lineHeight: props.fontSize
        ? props.fontSize === 'inherit'
            ? 'inherit'
            : `${props.fontSize}px`
        : DesignTokens.measurement.iconDefaultSize,
    color: props.color ? props.color : DesignTokens.color.iconPrimary,
    display: 'inline-flex',
}));

export type GivelifyIconProps = {
    id?: string;
    variant: GivelifyIconVariant;
    fontSize?: 'inherit' | number;
    color?: 'inherit' | string;
    className?: string;
    ['data-testid']?: string;
    style?: CSSProperties;
};

export const GivelifyIcon = ({
    id,
    variant,
    fontSize = 'inherit',
    color = 'inherit',
    className,
    'data-testid': dataTestId,
    style,
}: GivelifyIconProps) => {
    const iconComponent = React.useMemo(() => {
        return getIconSvg[variant];
    }, [variant]);
    return (
        <StyleIcon
            className={className}
            color={color}
            data-testid={dataTestId}
            fontSize={fontSize}
            id={id}
            style={style}
        >
            <SvgIcon component={iconComponent} />
        </StyleIcon>
    );
};
