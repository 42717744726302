import { DesignTokens, GivelifyButton, GivelifyTextField } from '@givelify/ui';
import { styled } from '@mui/material';

export const SearchButton = styled(GivelifyButton)(({ theme }) => ({
    padding: 0,
    color: DesignTokens.color.globalInfo400,
    fontSize: DesignTokens.textStyle.globalHeadingM2.font.size,
    marginRight: theme.spacing(1),
    marginBottom: '1px',
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
    },
}));

export const ClearButton = styled(SearchButton)({
    color: DesignTokens.color.iconPrimary,
});

export const SearchWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'width',
})<{ width: number }>(({ width, theme }) => ({
    width: width > 12 ? `calc(${width}ch + 116px)` : '230px',
    minWidth: '230px',
    maxWidth: '230px',
    marginRight: 'auto',
    [theme.breakpoints.down('tablet')]: {
        width: width > 12 ? `calc(${width}ch + 116px)` : '169px',
        minWidth: '169px',
        maxWidth: '169px',
    },
}));

export const SearchIconWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isEngaged',
})<{ isEngaged: boolean }>(({ isEngaged, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
    color: isEngaged
        ? DesignTokens.color.globalInfo400
        : DesignTokens.color.iconPrimary,
    [theme.breakpoints.down('tablet')]: {
        fontSize: '18px',
    },
}));

export const SearchInput = styled(GivelifyTextField, {
    shouldForwardProp: (prop) => prop !== 'isEngaged',
})<{ isEngaged: boolean }>(({ isEngaged, theme }) => ({
    '& .MuiInputBase-input': {
        color: DesignTokens.color.textPrimary,
        paddingRight: '8px',
        fontSize: DesignTokens.textStyle.globalBody1.font.size,
        [theme.breakpoints.down('tablet')]: {
            fontSize: DesignTokens.textStyle.globalBody1.font.size,
            paddingRight: '2px',
        },
        [theme.breakpoints.down('mobile')]: {
            fontSize: DesignTokens.textStyle.globalCaption1.font.size,
            paddingRight: '2px',
        },
        '&::selection': {
            background: DesignTokens.color.globalInfo100,
        },
        '&::placeholder': {
            color: DesignTokens.color.textSecondary,
            fontWeight: 700,
            fontSize: DesignTokens.textStyle.globalBody1.font.size,
            [theme.breakpoints.down('tablet')]: {
                fontSize: DesignTokens.textStyle.globalBody2.font.size,
            },
            [theme.breakpoints.down('mobile')]: {
                fontSize: DesignTokens.textStyle.globalCaption1.font.size,
            },
        },
    },
    '& .MuiOutlinedInput-root': {
        height: '48px',
        paddingLeft: '20px',
        borderColor: DesignTokens.color.borderComponentDefault,
        [theme.breakpoints.down('tablet')]: {
            height: '40px',
            paddingLeft: '11px',
        },
        [theme.breakpoints.down('mobile')]: {
            height: '32px',
            paddingLeft: '11px',
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: DesignTokens.color.globalInfo400,
            },
        },
        '& .MuiOutlinedInput-notchedOutline': isEngaged
            ? {
                  borderColor: DesignTokens.color.globalInfo400,
              }
            : null,
    },
}));
