import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoBox: {
            maxWidth: 336,
            borderStyle: 'solid',
            borderColor: theme.colors
                ? theme.colors.neutralPlatinum
                : '#E3E3E3',
            borderWidth: 1,
            borderTopColor: theme.colors ? theme.colors.primary : '#0376BB',
            borderTopWidth: 8,
            borderRadius: 8,
            paddingTop: 16,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 32,
            boxSizing: 'border-box',
        },
    }),
);

export const InfoBox: React.FC = (props) => {
    const { infoBox } = useStyles();
    return <div className={infoBox}>{props.children}</div>;
};
