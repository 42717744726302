import React, { useMemo } from 'react';
import { GivelifyTextDivider } from '@givelify/givelify-ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import roleTypes from 'constants/roleTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

import SettingsPage from '../SettingsPage';
import AddressSection from './address/AddressSection';
import MailSection from './address/MailSection';
import ContactSection from './contact/ContactSection';
import SocialSection from './social/SocialSection';

import { aboutStyle } from './style';

const SettingsAbout: React.FC = () => {
    const { donee, userRole } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
        userRole: state.User.user.role,
    }));
    const classes = aboutStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.organization-info.title'),
        }),
        [t],
    );
    const isReadOnly =
        userRole === roleTypes.BASIC || userRole === roleTypes.ADMIN_PANEL_USER;

    return (
        <SettingsPage
            trackPageVisit
            useDivider
            pageName={PAGE_NAME.SettingsOrganizationInfo}
            title={copy.title}
        >
            <div className={classes.page}>
                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoMailingAddress}
                    trackPageVisit={false}
                >
                    <AddressSection donee={donee} isReadOnly={isReadOnly} />
                </TrackingProvider>

                <GivelifyTextDivider />

                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoMailingAddress}
                    trackPageVisit={false}
                >
                    <MailSection donee={donee} isReadOnly={isReadOnly} />
                </TrackingProvider>

                <GivelifyTextDivider />

                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoContactInfo}
                    trackPageVisit={false}
                >
                    <ContactSection donee={donee} isReadOnly={isReadOnly} />
                </TrackingProvider>

                <GivelifyTextDivider />

                <TrackingProvider
                    pageName={PAGE_NAME.SettingsOrganizationInfoSocialInfo}
                    trackPageVisit={false}
                >
                    <SocialSection donee={donee} isReadOnly={isReadOnly} />
                </TrackingProvider>
            </div>
        </SettingsPage>
    );
};

export default SettingsAbout;
