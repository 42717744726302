import React from 'react';
import { Grid } from '@material-ui/core';

import Label from '../../components/Label';
import Page from '../../components/Page';
import SocialMediaCard from '../../components/SocialMediaCard';
import { useConnectorState } from '../../provider/Connector';
import { SocialMediaName } from '../../types';
import { SOCIAL_GIVING_LOADED } from '../../utils/cleverTapEvents';
import { pushEvent } from '../../utils/cleverTapUtils';
import { getSocialMediaCards } from './const';
import { usePageStyle } from './style';

export const SocialMediaGiving: React.FC = () => {
  const { labelContainer, girdItemContainer } = usePageStyle();
  const {
    routePaths,
    routeUrls,
    socialMediaFeatureFlag: featureFlag,
  } = useConnectorState();
  const socialMediaCards = getSocialMediaCards(routePaths, routeUrls);

  React.useEffect(() => {
    pushEvent(SOCIAL_GIVING_LOADED);
  }, []);

  return (
    <Page useDivider title="Social Media Giving">
      <Label className={labelContainer} variant="body1">
        Social media isn’t just for cat pictures anymore. By being present where
        your donors are, you can increase awareness of your mission. By
        including your Givelify social giving link, you’ll also increase your
        donations. Choose a service to find out how.
      </Label>
      <Grid container spacing={3}>
        {Object.entries(socialMediaCards)
          .filter(([name]) => featureFlag[name as SocialMediaName])
          .map(([name, item]) => (
            <Grid
              key={name}
              item
              className={girdItemContainer}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SocialMediaCard
                actionUrl={item.url}
                assetName={name as SocialMediaName}
                {...item}
              />
            </Grid>
          ))}
      </Grid>
    </Page>
  );
};
