import React from 'react';
import {
    faArrowUp,
    faArrowDown,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GasDefaultPalette, GivelifyLabel } from '@givelify/givelify-ui';
import useStyles from './styles';

interface UpDownPercentageProps {
    total: number;
    className?: string;
}

const UpDownPercentage: React.FC<UpDownPercentageProps> = ({
    total,
    className,
}) => {
    const classes = useStyles();
    let color: string;
    let icon: IconDefinition;
    if (total > 0) {
        color = GasDefaultPalette.palette.state.success.text;
        icon = faArrowUp;
    } else {
        color = GasDefaultPalette.palette.state.error.text;
        icon = faArrowDown;
        total = total * -1; // make it +ve
    }
    return (
        <div className={`${classes.upDownPercentage} ${className}`}>
            <FontAwesomeIcon color={color} icon={icon} size="lg" />
            <GivelifyLabel
                color="success300"
                text={`${total}%`}
                variant="body3"
            />
        </div>
    );
};

export default UpDownPercentage;
