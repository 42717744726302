import { Donee, MidStatus, RequiredCardState } from '@givelify/givelify-ui';
import { User } from '../store/user/types';

const getTrackedMidStatus = (midStatus?: MidStatus) => {
    if (!midStatus) return 'No';

    let result: string;

    switch (midStatus) {
        case 'pending':
            result = 'Pending';
            break;
        case 'rejected':
            result = 'Rejected';
            break;
        case 'active':
            result = 'Active';
            break;
    }

    return result || 'No';
};

const getTrackedEinStatus = (einStatus?: RequiredCardState) => {
    if (!einStatus) return 'EIN not provided';

    let result: string;

    switch (einStatus) {
        case 'unchecked':
            result = 'EIN not provided';
            break;
        case 'in_progress':
            result = 'EIN/Tax ID provided';
            break;
        case 'request_callback':
            result = 'EIN/Tax ID provided';
            break;
        case 'require_information':
            result = 'EIN/Tax ID provided';
            break;
        case 'verified':
            result = 'EIN/Tax ID verified';
            break;
    }

    return result || 'EIN not provided';
};

export const getEventTrackingData = (user: User, donee?: Donee) => {
    if (!user) return {};

    // we dont want to track certain properties, so exclude them
    const {
        avatar,
        avatarOriginal,
        createdDateUser,
        id,
        notify,
        officialId,
        phone,
        profilePicUrl,
        ...restUser
    } = user;

    return {
        ...restUser,
        hasBannerImage: !!donee?.onboarding?.appProfile?.coverPhoto?.coverPhoto,
        onboardingCompleted: donee?.onboarding?.hasCompleted,
        'MID Status': getTrackedMidStatus(donee?.onboarding.midStatus),
        'Org Verification Sub status': getTrackedEinStatus(
            donee?.onboarding.ein?.status,
        ),
        // eslint-disable-next-line no-restricted-globals
        pageUrl: location.href,
        orgType: donee?.type,
        hasMID: donee?.onboarding?.hasMid,
    };
};
