import React, { useEffect, useState } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { IntegrationTypes } from '../../../types';
import {
    MATCHED,
    matchSelect,
    UNMATCH,
} from '../../../utils/integrationFormatFilterUtils';
import AutoIntegrationSearchFilterHeader from './components/AutoIntegrationSearchFilterHeader';
import AutoIntegrationMatchEnvelopeContent from './components/EnvelopeTabContent/AutoIntegrationMatchEnvelopeContent';
import AutoIntegrationUnmatchEnvelopeContent from './components/EnvelopeTabContent/AutoIntegrationUnmatchEnvelopeContent';
import { envelopeTabStyle } from './style';

interface EnvelopeTabContentProps {
    defaultMatchStatus?: matchSelect;
    isReadOnly: boolean;
    integration: IntegrationTypes;
}

const AutoIntegrationEnvelopeTabContent: React.FC<EnvelopeTabContentProps> = ({
    defaultMatchStatus,
    integration,
    isReadOnly,
}) => {
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));

    const classes = envelopeTabStyle();

    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState<SortingDirection>('asc');
    const onSortingChange = (sorting: Sorting[]) => {
        setSortColumn(sorting[0].columnName);
        setSortDirection(sorting[0].direction);
    };
    const [campusSelected, setCampusSelected] = useState<number>(
        doneeId === userDoneeId ? 0 : doneeId,
    );
    useEffect(() => {
        if (doneeId === userDoneeId) {
            setCampusSelected(0);
        } else {
            setCampusSelected(doneeId);
        }
    }, [doneeId, userDoneeId]);
    const onCampusChange = (value: number) => {
        setCampusSelected(value);
    };

    const [matchFilter, setMatchFilter] = useState<matchSelect>(
        defaultMatchStatus || UNMATCH,
    );
    const onChangeMatchFilter = (value: matchSelect) => {
        setMatchFilter(value);
    };

    return (
        <div className={classes.envelopeTabContent}>
            <AutoIntegrationSearchFilterHeader
                campusSelected={campusSelected}
                defaultMatch={matchFilter}
                onCampusChange={onCampusChange}
                onMatchChange={onChangeMatchFilter}
            />
            <Paper className={classes.envelopeTableContent}>
                {matchFilter === UNMATCH && (
                    <AutoIntegrationUnmatchEnvelopeContent
                        campusSelected={campusSelected}
                        integration={integration}
                        isReadOnly={isReadOnly}
                        match={matchFilter}
                        onSortingChange={onSortingChange}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                )}
                {matchFilter === MATCHED && (
                    <AutoIntegrationMatchEnvelopeContent
                        campusSelected={campusSelected}
                        integration={integration}
                        isReadOnly={isReadOnly}
                        match={matchFilter}
                        onSortingChange={onSortingChange}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                )}
            </Paper>
        </div>
    );
};

export default AutoIntegrationEnvelopeTabContent;
