import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        marginTop: 32,
    },
    listWrapper: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    listWrapperAll: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: '1fr',
    },
    addPhotoButton: {
        marginTop: 18,
    },
    listItemHeadingWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        textDecoration: 'none',
        color: `${theme.colors?.primary} !important`,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            textDecoration: 'underline',
        },
    },
}));
