import { Component } from 'react';

type BasicErrorBoundaryProps = {
    fallback: JSX.Element;
    children: JSX.Element;
};

type BasicErrorBoundaryState = {
    hasError: boolean;
};

export class BasicErrorBoundary extends Component<
    BasicErrorBoundaryProps,
    BasicErrorBoundaryState
> {
    constructor(props: BasicErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }
        return this.props.children;
    }
}
