import React, { useCallback, useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyTable } from '@givelify/givelify-ui';
import {
    MODAL_NAME,
    formatWithTimezone,
    useTrackingContext,
} from '@givelify/utils';
import { Link, makeStyles } from '@material-ui/core';
import { DonationRow, Donor } from 'api/models';
import envelopeNameSummary from 'pages/donors/DonorProfile/envelopeNameSummary';
import { DONOR_PROFILE_PAGE_CLICK_DONATION_AMOUNT } from 'utils/clevertapEvents';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { useAdvancedTranslation } from 'utils/i18n';
import { DonationDetailsModal } from '../donationDetailsModal';

interface DonationHistoryTableProps {
    donations: DonationRow[];
    currentPage: number;
    totalItems: number;
    rowsPerPage: number;
    donor: Donor;
}

const useStyle = makeStyles({
    link: {
        cursor: 'pointer',
    },
});

const DonorDonationHistoryTableComponent: React.FC<
    DonationHistoryTableProps
> = ({ donor, donations, currentPage, totalItems, rowsPerPage }) => {
    const { trackEvent, trackOpenCloseEvent } = useTrackingContext();
    const classes = useStyle({});
    const { t, at } = useAdvancedTranslation();

    if (!donations) {
        donations = [];
    }

    const copy = useMemo(
        () => ({
            date: t('pages.donor_details.date'),
            envelopeFund: at('pages.donor_details.envelope_fund'),
            amount: t('pages.donor_details.amount'),
            noRecords: t('pages.donor_details.no_records'),
        }),
        [t, at],
    );

    const [open, setOpen] = useState(false);
    const [modalDonation, setModalDonation] = useState<DonationRow>();
    const donationRowLinkClick = useCallback(
        (row: DonationRow) => {
            trackEvent(DONOR_PROFILE_PAGE_CLICK_DONATION_AMOUNT);
            setModalDonation(row);
            setOpen(true);
            trackOpenCloseEvent(true, MODAL_NAME.DonationDetails);
        },
        [trackEvent, trackOpenCloseEvent],
    );

    const detailsModalClose = useCallback(() => {
        trackOpenCloseEvent(false, MODAL_NAME.DonationDetails);
        setOpen(false);
    }, [trackOpenCloseEvent]);

    return (
        <>
            <GivelifyTable
                columns={[
                    {
                        name: 'createdAt',
                        label: copy.date,
                        renderCell: ({ cellValue, row }) => (
                            <GivelifyLabel
                                dataTestId={`donation-date-${row.receiptNumber}`}
                                id={`donation-date-${row.receiptNumber}`}
                                text={formatWithTimezone(
                                    cellValue,
                                    'MMM. D, YYYY',
                                )}
                                variant="body2"
                            />
                        ),
                    },
                    {
                        name: 'envelopes',
                        label: copy.envelopeFund,
                        cellAlign: 'center',
                        headerAlign: 'center',
                        renderCell: ({ row }) => (
                            <GivelifyLabel
                                dataTestId={`donation-envelopes-${row.receiptNumber}`}
                                text={envelopeNameSummary(row.envelopes)}
                                variant="body2"
                            />
                        ),
                    },
                    {
                        name: 'amount',
                        label: copy.amount,
                        cellAlign: 'right',
                        headerAlign: 'right',
                        renderCell: ({ cellValue, row }) => {
                            return (
                                <Link
                                    className={classes.link}
                                    data-testid={`donation-receipt-${row.receiptNumber}`}
                                    id={`donation-receipt-${row.receiptNumber}`}
                                    onClick={() => donationRowLinkClick(row)}
                                >
                                    ${formatMoneyWithCommas(cellValue)}
                                </Link>
                            );
                        },
                    },
                ]}
                data={donations}
                keySelector={(i) => i.id}
            />
            <DonationDetailsModal
                donation={modalDonation}
                donor={donor}
                onClose={detailsModalClose}
                open={open}
            />
        </>
    );
};

export const DonorDonationHistoryTable = React.memo(
    DonorDonationHistoryTableComponent,
);
