import React from 'react';
import { GivelifyTheme } from '@givelify/givelify-ui';
import { GivelifyThemeProvider } from '@givelify/ui';
import { Logger } from '@givelify/utils';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import LoadingBar from './components/system/LoadingBar';
import FullStory from './fullStory/FullStory';
import { Error500 } from './theme/components/ErrorPage';

type AppWrapperProps = {
    store: Store<unknown, AnyAction>;
};

export const ThemeWrapper: React.FC = ({ children }) => (
    <>
        <CssBaseline />
        <MuiThemeProvider theme={GivelifyTheme}>
            <GivelifyThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {children}
                </LocalizationProvider>
            </GivelifyThemeProvider>
        </MuiThemeProvider>
    </>
);

export const DataWrapper: React.FC<AppWrapperProps> = ({ store, children }) => (
    <ThemeWrapper>
        <Logger.BasicErrorBoundary fallback={<Error500 />}>
            <Provider store={store}>
                <React.Suspense fallback={<LoadingBar show />}>
                    <HelmetProvider>
                        {children}
                    </HelmetProvider>
                </React.Suspense>
                <FullStory />
            </Provider>
        </Logger.BasicErrorBoundary>
    </ThemeWrapper>
);

const AppWrapper: React.FC<AppWrapperProps> = ({ store, children }) => (
    <DataWrapper store={store}>{children}</DataWrapper>
);

export default AppWrapper;
