import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    campusListItemWrapper: {
        border: '1px solid #E3E3E3',
        borderRadius: 10,
        boxShadow: '0px 0px 8px rgba(131, 131, 131, 0.3)',
    },
    borderHover: {
        '&:hover': {
            border: `1px solid ${theme.colors.neutralGrey75}`,
        },
    },
    listItemContent: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
        },
        padding: '24px 16px 16px',
        height: '100%',
    },
    listItemText: {
        flex: 1,
    },
    listItemHeader: {
        marginBottom: 8,
    },
    listItemAction: {
        alignSelf: 'end',
        [theme.breakpoints.up('md')]: {
            marginTop: 32,
            alignSelf: 'end',
        },
    },
    chip: {
        borderRadius: 20,
        padding: theme.spacing('3px', '8px'),
        color: theme.colors.neutralGrey,
    },
    yourCampusChip: {
        backgroundColor: '#DCF1E5',
    },
    accountClaimedChip: {
        backgroundColor: 'rgba(202, 210, 219, 0.3)',
    },
    cantFindListItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        border: '1px dashed #E3E3E3',
        borderRadius: 10,
    },
}));

export default useStyles;
