import React from 'react';
import { GivelifyTextDivider } from '@givelify/givelify-ui';
import { Trans } from 'react-i18next';

import Label from '../../../components/Label';
import Link from '../../../components/Label/Link';
import Page from '../../../components/Page';
import { useConnectorState } from '../../../provider/Connector';
import { GiveNowButtonTypes } from '../../../types';
import { I18N_NAMESPACE } from '../../../types/const';
import { ONLINE_GIVING_LANDED } from '../../../utils/cleverTapEvents';
import { pushEvent } from '../../../utils/cleverTapUtils';
import { useTranslations } from '../../../utils/translations';
import useCustomModal from '../Modals/useCustomModal';
import useInstructModal from '../Modals/useInstructModal';
import useShareModal from '../Modals/useShareModal';
import { ButtonPreview } from './ButtonPreview';
import { usePageStyle } from './style';

export const GivelifyButtonPage: React.FC = () => {
    const cs = usePageStyle();

    const { givelifyButton: gb } = useTranslations('onlineGiving', {
        givelifyButton: [
            'title',
            'needHelp',
            'preview',
            'lightGive',
            'darkGive',
            'primaryGive',
            'copyCode',
            'sharePage.preText',
            'sharePage.postText',
            'sharePage.link',
        ],
    });

    React.useEffect(() => {
        pushEvent(ONLINE_GIVING_LANDED);
    }, []);

    const [openShareModal, ShareModal] = useShareModal();
    const [openCustomModal, CustomModal] = useCustomModal();
    const [openInstructModal, InstructModal] = useInstructModal();

    const { onlineGivingURLs } = useConnectorState();

    const givingLink =
        onlineGivingURLs.find((x) => x.channel === 'OGA')?.shortLink || '';

    return (
        <Page
            useBreadcrumb
            useDivider
            addHelpNote={false}
            landingPageName="onlineGiving"
            name="Givelify button"
            title="Givelify button"
        >
            <ShareModal />
            <CustomModal />
            <InstructModal givingLink={givingLink} />
            <div className={cs.container} data-testid="givelify-button-content">
                <div>
                    <Label
                        data-testid="givelify-button-title"
                        variant="heading4"
                    >
                        {gb.title}
                    </Label>
                    <Label
                        className={cs.givelifyBtnDesc}
                        data-testid="givelify-button-desc"
                        variant="body1"
                    >
                        <Trans
                            i18nKey="onlineGiving.givelifyButton.desc"
                            ns={I18N_NAMESPACE}
                        >
                            Copy and paste the code into your HTML code. If you
                            use a website builder like Wix or Squarespace
                            <Link
                                data-testid="instruct-button"
                                onClick={openInstructModal}
                            >
                                click here
                            </Link>
                            for instructions.
                        </Trans>
                    </Label>
                    <Link
                        data-testid="givelify-help-link"
                        onClick={openCustomModal}
                    >
                        {gb.needHelp}
                    </Link>
                </div>

                {GiveNowButtonTypes.map((type) => [
                    <GivelifyTextDivider
                        key={`${type}-divider`}
                        className={cs.divider}
                    />,
                    <ButtonPreview
                        key={`${type}-preview`}
                        givingLink={givingLink}
                        type={type}
                    />,
                ])}
            </div>
            <Label className={cs.bottomDesc} variant="body1">
                {gb['sharePage.preText']}
                <Link
                    data-testid="givelify-share-modal-button"
                    onClick={openShareModal}
                >
                    {gb['sharePage.link']}
                </Link>
                {gb['sharePage.postText']}
            </Label>
        </Page>
    );
};
