import React from 'react';
import { GivelifyTab } from '@givelify/givelify-ui';
import { ReportType } from 'store/report/types';
import AutoIntegrationTabsHeader from './AutoIntegrationTabsHeader';
import CommonTabsHeader from './CommonTabsHeader';
import { TabStyles } from './styles';

interface IntegrationTabHeaderProps {
    value: number;
    onChange: (newValue: number) => void;
    integrationNoDonor: boolean;
    integrationNoEnvelope?: boolean;
    integrationType: ReportType;
}

const TabsHeader: React.FC<IntegrationTabHeaderProps> = ({
    value,
    onChange,
    integrationNoDonor,
    integrationNoEnvelope,
    integrationType,
}) => {
    const styles = TabStyles();
    const showAutoIntegrationTabs = [
        'CCB',
        'F1',
        'PLANNING_CENTER',
        'F1GO',
    ].includes(integrationType);
    return (
        <div className={styles.tabRoot}>
            <GivelifyTab
                classes={{
                    defaultTab: styles.tabDefault,
                    selectedTab: styles.tabDefault,
                }}
                onChange={onChange}
                options={
                    showAutoIntegrationTabs
                        ? AutoIntegrationTabsHeader()
                        : CommonTabsHeader({
                              integrationNoDonor,
                              integrationNoEnvelope,
                          })
                }
                value={value}
            />
        </div>
    );
};

export default TabsHeader;
