import { ErrorResponse } from '@givelify/givelify-ui';
import { EnvelopeDistribution } from '../types';

export const SET_ENVELOPES_PREVIOUS_LOADING = 'SET_ENVELOPES_PREVIOUS_LOADING';
export const SET_ENVELOPES_PREVIOUS_SUCCESS = 'SET_ENVELOPES_PREVIOUS_SUCCESS';
export const SET_ENVELOPES_PREVIOUS_FAILED = 'SET_ENVELOPES_PREVIOUS_FAILED';

export interface SetEnvelopesPreviousLoading {
    type: typeof SET_ENVELOPES_PREVIOUS_LOADING;
}

export interface SetEnvelopesPreviousSuccess {
    type: typeof SET_ENVELOPES_PREVIOUS_SUCCESS;
    data: {
        data: EnvelopeDistribution[];
    };
}

export interface SetEnvelopesPreviousFailed {
    type: typeof SET_ENVELOPES_PREVIOUS_FAILED;
    error: ErrorResponse;
}
