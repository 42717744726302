/* eslint-disable-next-line */
import { Reducer } from 'redux';
import {
    IntegrationState,
    IntegrationActions,
    SET_SCHEDULED_REPORT,
    DAILY,
    NAME,
    ScheduledReport,
} from './types';

export const initialIntegrationState: IntegrationState = {
    scheduledReport: {
        name: 'ACS',
        id: 0,
        doneeId: 0,
        frequency: DAILY,
        scheduledDate: null,
        scheduledDay: null,
        receivesNotifications: false,
        reportType: 'ACS',
        reportTypeVersion: 'ACS People',
        nextRunDate: new Date(),
        dateRangeType: 'donation',
        matchBy: NAME,
    },
};
export const initialScheduledReport: ScheduledReport = {
    name: null,
    id: null,
    doneeId: null,
    frequency: null,
    scheduledDate: null,
    scheduledDay: null,
    receivesNotifications: null,
    reportType: null,
    reportTypeVersion: null,
    nextRunDate: null,
    dateRangeType: null,
    matchBy: null,
};

export const IntegrationReducer: Reducer<
    IntegrationState,
    IntegrationActions
> = (state = initialIntegrationState, action) => {
    switch (action.type) {
        case SET_SCHEDULED_REPORT:
            return {
                ...state,
                scheduledReport: action.data,
            };
        default:
            return state;
    }
};

/**
 * Factory for easily producing a IntegrationReducer with a different initial state
 * Ideal for testing and cosmos
 *
 * @param initialState
 */
export const IntegrationReducerFactory = (initialState: IntegrationState) => (
    state = initialState,
    action,
) => IntegrationReducer(state, action);

export default IntegrationReducer;
