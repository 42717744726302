import { styled } from '@mui/material';

export const GraphTooltip = styled('div')({
    backgroundColor: '#4a4a4a',
    color: '#fefefe',
    padding: '2px 20px',
    borderRadius: 5,
    fontSize: '14px',
    cursor: 'pointer',
});

export const PieChartWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: '0 16px',
    },
}));

export const ChartWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '32px',
    [theme.breakpoints.down('tablet')]: {
        justifyContent: 'center',
    },
    [theme.breakpoints.down('mobile')]: {
        flexWrap: 'wrap',
    },
}));

export const ChartItem = styled('div')(({ theme }) => ({
    '&:first-of-type': {
        flex: 2,
    },
    '&:last-child': {
        flex: 1,
        wordBreak: 'break-word',
    },
    [theme.breakpoints.down('mobile')]: {
        '&:first-of-type': {
            width: '100%',
            flex: 'none',
        },
        '&:last-child': {
            width: '100%',
            flex: 'none',
        },
    },
}));

export const AdditionalText = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '32px',
    bottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    [theme.breakpoints.down('mobile')]: {
        position: 'unset',
    },
}));
