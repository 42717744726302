import React from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from '../../../../types';
import { GraphTooltip } from '../SimplePieChart/styles';

export const CustomizedTooltip: React.FC<
    TooltipProps<ValueType, NameType>
> = (props: {
    payload: { name: string; value: string }[];
    children?: React.ReactNode;
}) => {
    if (props.payload.length > 0) {
        const data = props.payload[0];
        return (
            <GraphTooltip data-testid="graph-tooltip">
                <p data-testid="graph-tooltip-name">{data.name}</p>
                <p data-testid="graph-tooltip-value">{data.value}</p>
            </GraphTooltip>
        );
    }
    return null;
};
