import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="currentColor"
        height="12"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 12 14"
    >
        <path
            d="M6.0013 6.68815C6.36797 6.68815 6.68197 6.55748 6.9433 6.29615C7.20419 6.03526 7.33464 5.72148 7.33464 5.35482C7.33464 4.98815 7.20419 4.67415 6.9433 4.41282C6.68197 4.15193 6.36797 4.02148 6.0013 4.02148C5.63464 4.02148 5.32086 4.15193 5.05997 4.41282C4.79864 4.67415 4.66797 4.98815 4.66797 5.35482C4.66797 5.72148 4.79864 6.03526 5.05997 6.29615C5.32086 6.55748 5.63464 6.68815 6.0013 6.68815ZM6.0013 11.5882C7.35686 10.3437 8.36241 9.21304 9.01797 8.19615C9.67352 7.17971 10.0013 6.27704 10.0013 5.48815C10.0013 4.27704 9.61508 3.28526 8.84264 2.51282C8.07063 1.74082 7.12352 1.35482 6.0013 1.35482C4.87908 1.35482 3.93175 1.74082 3.1593 2.51282C2.3873 3.28526 2.0013 4.27704 2.0013 5.48815C2.0013 6.27704 2.32908 7.17971 2.98464 8.19615C3.64019 9.21304 4.64575 10.3437 6.0013 11.5882ZM6.0013 13.3548C4.21241 11.8326 2.87641 10.4186 1.9933 9.11282C1.10975 7.80748 0.667969 6.59926 0.667969 5.48815C0.667969 3.82148 1.20419 2.49371 2.27664 1.50482C3.34864 0.515929 4.59019 0.0214844 6.0013 0.0214844C7.41241 0.0214844 8.65397 0.515929 9.72597 1.50482C10.7984 2.49371 11.3346 3.82148 11.3346 5.48815C11.3346 6.59926 10.8931 7.80748 10.01 9.11282C9.12641 10.4186 7.79019 11.8326 6.0013 13.3548Z"
            fill="current"
        />
    </svg>
);

export const LocationIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgLocation}
        fontSize={fontSize}
    />
);
