import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyCheckboxStyleProps = {
    variant: 'primary' | 'secondary' | 'special';
    labelIsClickable?: boolean;
} & GivelifyBoxMarginProps;

export const checkboxStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyCheckboxStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyCheckboxStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyCheckboxStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyCheckboxStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        radioContainer: {
            display: 'flex',
            alignItems: 'center',
            cursor: (props: GivelifyCheckboxStyleProps) =>
                props.labelIsClickable ? 'pointer' : 'initial',
        },
        labelBefore: {
            marginRight: 8,
        },
        labelAfter: {
            marginLeft: 8,
        },
        rootStyle: {
            padding: 0,
            width: 18,
            height: 18,
        },
        checkedStyle: {
            color: (props: GivelifyCheckboxStyleProps) =>
                props.variant === 'primary'
                    ? theme.colors.primary
                    : props.variant === 'special'
                    ? theme.colors.error300
                    : theme.colors.grey600,
            fontSize: 14,
            lineHeight: '14px',
        },
    }),
);
