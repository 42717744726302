import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes placeHolderShimmer': {
      '0%': {
        backgroundPosition: '-468px 0',
      },
      '100%': {
        backgroundPosition: '468px 0',
      },
    },
    animating: {
      animation: `$placeHolderShimmer 1s`,
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      background:
        'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%) !important',
      width: '100%',
      paddingBottom: 4,
    },

    linkContainer: {
      background: '#e3e3e34c',
      borderRadius: '6px',
      paddingLeft: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },

    link: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        background: '#e3e3e34c',
        borderRadius: '6px',
        padding: '15px 16px',
        marginBottom: '4px',
      },
    },

    copyLinkButton: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  })
);
