import {
    DATE_RANGE_TYPE,
    MONDAY,
    ScheduledReport,
    WEEKLY,
    NAME,
} from 'store/integration/types';
import { ReportType as ReportTypes } from 'store/report/types';

const scheduledReportDefaultValues = (
    scheduledReport: ScheduledReport | unknown,
    doneeId: number,
    reportType: ReportTypes,
) => ({
    ...(scheduledReport as ScheduledReport),
    doneeId,
    receivesNotifications: false,
    frequency: WEEKLY,
    reportType,
    scheduledDate: null,
    scheduledDay: MONDAY,
    nextRunDate: null,
    dateRangeType: DATE_RANGE_TYPE.DONATION,
    matchBy: NAME,
});

export default scheduledReportDefaultValues;
