import { DirectDepositDTO, PrimaryRepresentativeDTO } from './requests/api';
import { TaxIdInfoUpdateRequest } from './requests/donee/TaxIdInfoUpdateRequest';

export const toFormData = (
    request:
        | TaxIdInfoUpdateRequest
        | DirectDepositDTO
        | PrimaryRepresentativeDTO,
) => {
    const formData = new FormData();
    for (const key in request) {
        const value =
            request[
                key as keyof (
                    | TaxIdInfoUpdateRequest
                    | DirectDepositDTO
                    | PrimaryRepresentativeDTO
                )
            ];
        if (value) {
            formData.append(key, value as string | Blob);
        }
    }
    return formData;
};
