import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyLabel } from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import {
    allowedExtension,
    FileType,
    getSingleFile,
} from '../../../utils/fileState';

export const MAX_CHEQUE_FILE_SIZE_IN_MB = 15;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadDocumentStyles: {
            cursor: 'pointer',
            display: 'inline-flex',
            '& .icon': {
                width: 14,
                height: 17,
                marginRight: theme.spacing(2),
            },
            '& .upload-text': {
                marginBottom: theme.spacing(1),
            },
            '& .hidden-input': {
                display: 'none',
            },
        },
    }),
);

interface UploadDocumentProps {
    status: 'success' | 'idle' | 'error';
    docInputRef: React.RefObject<HTMLInputElement>;
    onChange: (file: FileType) => unknown;
}

const allowedExtensions = ['png', 'jpeg', 'jpg', 'pdf', 'heic'];
export const UploadDocument: React.FC<UploadDocumentProps> = ({
    status,
    docInputRef,
    onChange,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { uploadDocumentStyles } = useStyles();

    const {
        uploadText,
        replaceText,
        uploadRequirementText,
        uploadRequirementFailedText,
        fileNotSupported,
    } = useMemo(() => {
        const maxSizeObject = { maxSize: MAX_CHEQUE_FILE_SIZE_IN_MB };
        return {
            uploadText: t('labels.uploadDocument'),
            replaceText: t('labels.replaceDocument'),
            uploadRequirementText: t(
                'directDepositManualEntry.uploadRequirementText',
                maxSizeObject,
            ),
            uploadRequirementFailedText: t(
                'directDepositManualEntry.uploadRequirementFailedText',
                maxSizeObject,
            ),
            fileNotSupported: t('labels.fileNotSupported'),
        };
    }, [t]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file && allowedExtension(allowedExtensions, file)) {
            onChange(
                getSingleFile({
                    maxSizeInMB: MAX_CHEQUE_FILE_SIZE_IN_MB,
                    errorText: uploadRequirementFailedText,
                    file,
                    allowedExtensions,
                }),
            );
        } else {
            onChange({ status: 'error', file: fileNotSupported });
        }
    };
    const onClick = () => {
        docInputRef.current?.click();
    };
    return (
        <div className={uploadDocumentStyles} onClick={onClick}>
            <input
                ref={docInputRef}
                accept="image/x-png,image/jpg,.HEIC,image/jpeg,application/pdf,.pdf"
                className="hidden-input"
                onChange={handleChange}
                type="file"
            />
            <GivelifyIcon className="icon" color="primary" variant="upload" />
            <div>
                <GivelifyLabel
                    className="upload-text"
                    color="primary"
                    text={status === 'success' ? replaceText : uploadText}
                    variant="body2"
                />
                <GivelifyLabel
                    color="neutralGrey"
                    text={uploadRequirementText}
                    variant="body2"
                />
            </div>
        </div>
    );
};
