import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import ccbIcon from 'assets/integration-icons/ccb.svg';
import f1Icon from 'assets/integration-icons/f1.svg';
import givelifyIcon from 'assets/integration-icons/givelify-logo.svg';
import planningCenterIcon from 'assets/integration-icons/planning-center.svg';
import { addPhoneNumberFormatting } from '../../../../../../editProfile/components/EditProfileForm';
import { IntegrationTypes } from '../../../../../types';
import { donorTabStyle } from '../../style';

interface F1CCBMatchedDonorInfoProps {
    donorName: string;
    donorEmail: string;
    phoneNumber: string;
    address: string;
    donorImage?: string;
    chmsDonorName: string;
    chmsDonorId: number;
    chmsDonorEmail: string;
    chmsPhoneNumber: string;
    chmsAddress: string;
    integration: IntegrationTypes;
}

const F1CCBMatchedDonorInfo: React.FC<F1CCBMatchedDonorInfoProps> = ({
    donorName,
    donorEmail,
    phoneNumber,
    address,
    donorImage,
    chmsDonorName,
    chmsDonorId,
    chmsDonorEmail,
    chmsPhoneNumber,
    chmsAddress,
    integration,
}) => {
    const classes = donorTabStyle();
    return (
        <div className={classes.donorRow}>
            <Grid container className={classes.donorContainer}>
                <Grid
                    item
                    className={
                        classes.matchDonorLogo + ' ' + classes.matchDonorItem
                    }
                >
                    <img
                        alt="Givelify"
                        className={classes.givelifyLogo}
                        src={givelifyIcon}
                        width={30}
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem + ' ' + classes.matchDonorName
                    }
                >
                    <div className={classes.givelifyAvatar}>
                        <GivelifyAvatar
                            color="eucalyptus"
                            size="xSmall"
                            src={donorImage}
                            text={getInitialsFromName(donorName)}
                        />
                    </div>
                    <GivelifyLabel bold text={donorName} variant="body1" />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem + ' ' + classes.matchDonorEmail
                    }
                >
                    <GivelifyLabel
                        text={donorEmail?.length > 1 ? donorEmail : '---'}
                        variant="body1"
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem + ' ' + classes.matchDonorPhone
                    }
                >
                    <GivelifyLabel
                        text={
                            phoneNumber?.length > 1
                                ? addPhoneNumberFormatting(phoneNumber)
                                : '---'
                        }
                        variant="body1"
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem + ' ' + classes.matchDonorAddress
                    }
                >
                    <GivelifyLabel
                        className={classes.donorAddress}
                        text={address?.length > 1 ? address : '---'}
                        variant="body1"
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.donorContainer}>
                <Grid
                    item
                    className={
                        classes.matchDonorItem +
                        ' ' +
                        classes.matchDonorLogo +
                        ' ' +
                        classes.chmsItem
                    }
                >
                    <img
                        alt="Integration"
                        src={
                            integration?.integrationType === 'CCB'
                                ? ccbIcon
                                : integration?.integrationType === 'F1'
                                ? f1Icon
                                : planningCenterIcon
                        }
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem +
                        ' ' +
                        classes.matchDonorName +
                        ' ' +
                        classes.chmsItem
                    }
                >
                    <div className={classes.givelifyAvatar} />
                    <div className={classes.f1Donor}>
                        <GivelifyLabel
                            bold
                            className={classes.f1DonorName}
                            text={chmsDonorName}
                            variant="body1"
                        />
                        <GivelifyLabel
                            className={classes.f1DonorId}
                            text={chmsDonorId}
                            variant="body3"
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem +
                        ' ' +
                        classes.matchDonorEmail +
                        ' ' +
                        classes.chmsItem
                    }
                >
                    <GivelifyLabel
                        text={
                            chmsDonorEmail?.length > 1 ? chmsDonorEmail : '---'
                        }
                        variant="body1"
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem +
                        ' ' +
                        classes.matchDonorPhone +
                        ' ' +
                        classes.chmsItem
                    }
                >
                    <GivelifyLabel
                        text={
                            chmsPhoneNumber?.length > 1
                                ? addPhoneNumberFormatting(chmsPhoneNumber)
                                : '---'
                        }
                        variant="body1"
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchDonorItem +
                        ' ' +
                        classes.matchDonorAddress +
                        ' ' +
                        classes.chmsItem
                    }
                >
                    <GivelifyLabel
                        className={classes.donorAddress}
                        text={chmsAddress?.length > 1 ? chmsAddress : '---'}
                        variant="body1"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default F1CCBMatchedDonorInfo;
