import { TimeFrameId } from '@givelify/utils';
import { PopoverOrigin } from '@mui/material';
import { DateCalendarProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export type CalendarComponentProps = {
    themeMode?: ThemeMode;
};
type GivelifyCalendarDateProps = {
    pickerMode?: 'date';
    value: Dayjs | null;
    onDateChange: (date: Dayjs | null) => void;
    start?: Dayjs | null;
    end?: Dayjs | null;
    range?: TimeFrameId;
    onRangeChange?: (
        start: Dayjs | null,
        end: Dayjs | null,
        range: TimeFrameId,
    ) => void;
};
type GivelifyCalendarRangeProps = {
    pickerMode: 'range';
    value?: Dayjs | null;
    onDateChange?: (date: Dayjs | null) => void;
    start: Dayjs | null;
    end: Dayjs | null;
    range?: TimeFrameId;
    onRangeChange: (
        start: Dayjs | null,
        end: Dayjs | null,
        range: TimeFrameId,
    ) => void;
};
export type GivelifyDatePickerTextProps = {
    cancel: string;
    submit: string;
    rangeFieldLabel: string;
};
export type GivelifyCalendarProps = CalendarComponentProps &
    Omit<
        DateCalendarProps<Dayjs>,
        'slots' | 'slotProps' | 'value' | 'onChange' | 'classes'
    > &
    (GivelifyCalendarDateProps | GivelifyCalendarRangeProps) & {
        format?: string;
        step?: 'start' | 'end';
        labelForSelectDate?: string;
    };

export type ThemeMode = 'light' | 'dark';
export type PickerMode = 'date' | 'range';
export type GivelifyDatePickerProps = Omit<
    GivelifyCalendarProps,
    'labelForSelectDate'
> & {
    dataTestId?: string;
    id?: string;
    label?: string;
    format?: string;
    popoverProps?: {
        anchorOrigin: PopoverOrigin;
        transformOrigin: PopoverOrigin;
    };
    formatRangeInputValue?: (
        start: Dayjs | null | undefined,
        end: Dayjs | null | undefined,
    ) => string;
    fullwidth?: boolean;
    state?: 'idle' | 'error' | 'success' | 'warning';
    size?: 'large' | 'medium';
    width?: 'default' | 'compact';
    name?: string;
    ariaLabel?: string;
    clearable?: boolean;
    helperText?: string;
    placeholder?: string;
    rangePickerShortcuts?: {
        label: string;
        key: TimeFrameId;
    }[];
    texts?: GivelifyDatePickerTextProps;
    open?: boolean;
    onClose?: (reason: 'submit' | 'cancel' | 'dismiss') => void;
    calendarAnchorRef?: React.RefObject<HTMLElement>;
    hideTextInput?: boolean;
    onOpenTrackingName?: string;
};

export const GivelifyDatePickerShortcuts: {
    label: string;
    key: TimeFrameId;
}[] = [
    {
        label: 'Today',
        key: 'today',
    },
    {
        label: 'Yesterday',
        key: 'yesterday',
    },
    {
        label: 'This week',
        key: 'thisWeek',
    },
    {
        label: 'Last week',
        key: 'lastWeek',
    },
    {
        label: 'This month',
        key: 'thisMonth',
    },
    {
        label: 'Last month',
        key: 'lastMonth',
    },
    {
        label: 'This year',
        key: 'thisYear',
    },
    {
        label: 'Last year',
        key: 'lastYear',
    },
    {
        label: 'Last 30 days',
        key: 'last30Days',
    },
    {
        label: 'Last 90 days',
        key: 'last90Days',
    },
    {
        label: 'Last 12 months',
        key: 'last12Months',
    },
    {
        label: 'Lifetime',
        key: 'lifetime',
    },
];
