import permissionTypes from '../constants/permissionTypes';
import RoleTypes from '../constants/roleTypes';
import { PATH } from '../router/routes';

export default {
    [PATH.DONATIONS.DONATIONS_ACTIVITY]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.DONORS.ROOT]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.DONORS.PROFILE]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.DONORS.PROFILE_DONATION_HISTORY]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.DONORS.PROFILE_MESSAGE_HISTORY]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.FUNDRAISING_TOOLS.ROOT]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.FULL_ACCESS,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.FULL_ACCESS,
    },
    [PATH.FUNDRAISING_TOOLS.GIVELITHON]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.FULL_ACCESS,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.FULL_ACCESS,
    },
    [PATH.FUNDRAISING_TOOLS.LAUNCH_GIVELITHON]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.FULL_ACCESS,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.FULL_ACCESS,
    },
    [PATH.REPORTS.ROOT]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.FULL_ACCESS,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.FULL_ACCESS,
    },
    [PATH.SETTINGS.APP_PROFILE]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.SETTINGS.ENVELOPES]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.SETTINGS.MISSION_STATEMENT]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.DATA.INTEGRATIONS_SETUP]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.NO_ACCESS,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.DATA.INTEGRATION_CONTENT]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.FULL_ACCESS,
        [RoleTypes.BASIC]: permissionTypes.NO_ACCESS,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.SETTINGS.USERS]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.READ_ONLY,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
    [PATH.SETTINGS.QUICK_GIVE]: {
        [RoleTypes.ADMIN]: permissionTypes.FULL_ACCESS,
        [RoleTypes.FINANCIAL]: permissionTypes.READ_ONLY,
        [RoleTypes.BASIC]: permissionTypes.READ_ONLY,
        [RoleTypes.ADMIN_PANEL_USER]: permissionTypes.READ_ONLY,
    },
};
