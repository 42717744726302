import React, { useMemo } from 'react';
import { GivelifyRadio, useAdvancedTranslation } from '@givelify/givelify-ui';
import { RADIO_BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { reportItemStyles } from 'pages/reports/LegacyReports/components/style';
import {
    FORMAT,
    getFormatFilter,
    LegacyXlsFormatVisible,
} from 'pages/reports/utils/reportFormatFilterUtils';

interface LegacyFileFormatProps {
    type: 'custom' | 'quickbooks';
    onChange: (value: boolean) => unknown;
    fileType: string;
    className?: string;
    pageName?: string;
}

const LegacyFileFormat: React.FC<LegacyFileFormatProps> = ({
    type,
    onChange,
    fileType,
    pageName,
}) => {
    const { fileFormatLabel, formatLabel } = reportItemStyles();
    const { t } = useAdvancedTranslation();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            csv: t('reports.formatFile.csv'),
            excel: t('reports.formatFile.excel'),
        }),
        [t],
    );

    const handleFormat = (format: FORMAT) => {
        onChange(format === FORMAT.CSV);
        trackEvent(
            format === FORMAT.CSV
                ? `<CSV>_${RADIO_BUTTON_IDENTIFIER}`
                : `<XLSX>_${RADIO_BUTTON_IDENTIFIER}`,
        );
    };

    return (
        <div id="select-format">
            {type === 'custom' && LegacyXlsFormatVisible.includes(pageName) && (
                <GivelifyRadio
                    ariaLabel="Format"
                    checked={fileType === getFormatFilter(FORMAT.EXCEL)}
                    className={fileFormatLabel}
                    classNameLabel={formatLabel}
                    id="select-format-xlsx"
                    label={copy.excel}
                    name="format"
                    onChange={() => handleFormat(FORMAT.EXCEL)}
                    testId="select-format-xlsx"
                    value="excel"
                    variant="primary"
                />
            )}
            <GivelifyRadio
                ariaLabel="Format"
                checked={fileType === getFormatFilter(FORMAT.CSV)}
                className={fileFormatLabel}
                classNameLabel={formatLabel}
                id="select-format-csv"
                label={copy.csv}
                name="format"
                onChange={() => handleFormat(FORMAT.CSV)}
                testId="select-format-csv"
                value="csv"
                variant="primary"
            />
        </div>
    );
};

export default LegacyFileFormat;
