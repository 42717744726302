import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import {
    Address,
    AddressWithPhone,
    getStateName,
    isValidAddress,
} from '../utils/addressUtils';
import { debounce } from '@material-ui/core';
import { StreetField } from './StreetField';
import { CityField } from './CityField';
import { StateField } from './StateField';
import { ZipField } from './ZipField';
import { PhoneNumberField } from './PhoneNumberField';
import { GivelifyLabel } from '../label';
import { phoneFormatter, phoneValidation } from '../utils/phoneNumberUtils';
import { stringToCamelCase } from '../utils/stringToCamelCase';

type BaseAddress = Address & Partial<AddressWithPhone>;

interface AddressFieldRef {
    streetRef?: React.RefObject<HTMLInputElement>;
    cityRef?: React.RefObject<HTMLInputElement>;
    stateRef?: React.RefObject<HTMLInputElement>;
    zipRef?: React.RefObject<HTMLInputElement>;
    phoneRef?: React.RefObject<HTMLInputElement>;
}

interface AddressPlaceholders {
    streetPlaceholder: string;
    cityPlaceholder: string;
    statePlaceholder: string;
    zipPlaceholder: string;
    /**
     * undefined here AND `phone` in address object, will not show Phone Number field
     */
    phonePlaceholder: string | undefined;
    /**
     * undefined will not show
     */
    poBoxNotAllowedText: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editAddressStyles: {
            marginTop: theme.spacing(1),
            '& .street-field, & .city-state-field, & #zip-root, & #phone-root':
                {
                    marginBottom: theme.spacing(3),
                },
            '& .city-state-field': {
                display: 'flex',
            },
            '& .state': {
                width: '100%',
                marginLeft: theme.spacing(2),
            },
            '& .city': {
                width: '100%',
                marginRight: theme.spacing(2),
            },
            '& .change-button': {
                marginRight: theme.spacing(3),
            },
        },
        inlineStyle: {
            display: 'inline-block',
        },
    }),
);

const ShowAddress: React.FC<{
    address: BaseAddress;
}> = ({ address }) => {
    const { inlineStyle } = useStyles();
    const { phone } = address;
    return (
        <>
            <GivelifyLabel
                text={address.street}
                variant="body1"
                className={inlineStyle}
            />
            {(address.city || address.state || address.zip) && ','}
            <div>
                <GivelifyLabel
                    text={address.city}
                    variant="body1"
                    className={inlineStyle}
                />
                {address.city && (address.state || address.zip) && ', '}
                <GivelifyLabel
                    text={getStateName(address.state)}
                    variant="body1"
                    className={inlineStyle}
                />
                {address.state && ' '}
                <GivelifyLabel
                    text={address.zip}
                    variant="body1"
                    className={inlineStyle}
                />
            </div>
            {phone && (
                <GivelifyLabel
                    text={phoneFormatter(phone) || phone}
                    variant="body1"
                />
            )}
        </>
    );
};

interface EditAddressProps extends AddressFieldRef, AddressPlaceholders {
    name?: string;
    address: BaseAddress;
    onChange: (address: Address | AddressWithPhone) => unknown;
    setIsAddressValid?: (isValid: boolean) => unknown;
    zipErrorMessage?: string;
    streetErrorMessage?: string;
    cityErrorMessage?: string;
    phoneErrorMessage?: string;
}

export interface AddressEditorRef {
    validate: () => void;
    isValid: () => boolean;
}

const EditAddress = forwardRef<AddressEditorRef, EditAddressProps>(
    (
        {
            name,
            address: passedAddress,
            onChange,
            streetRef,
            cityRef,
            stateRef,
            zipRef,
            phoneRef,
            streetPlaceholder,
            cityPlaceholder,
            statePlaceholder,
            zipPlaceholder,
            phonePlaceholder,
            poBoxNotAllowedText,
            setIsAddressValid,
            zipErrorMessage,
            streetErrorMessage,
            cityErrorMessage,
            phoneErrorMessage,
        },
        forwardRef,
    ) => {
        const { editAddressStyles } = useStyles();
        const [address, setAddress] = useState(passedAddress);
        const [showState, setShowState] = useState(false);
        const isValid = React.useCallback(() => {
            return (
                isValidAddress(address, poBoxNotAllowedText !== undefined) &&
                (address.phone === undefined || phoneValidation(address.phone))
            );
        }, [address, poBoxNotAllowedText]);

        const validate = React.useCallback(() => {
            setShowState(true);
        }, [setShowState]);

        const setStreet = (street: string) => {
            setAddress({ ...address, street });
        };
        const setState = (state: string) => {
            setAddress({ ...address, state });
        };
        const setZip = (zip: string) => {
            setAddress({ ...address, zip });
        };
        const setCity = (city: string) => {
            setAddress({ ...address, city });
        };
        const setPhone = (phone: string) => {
            setAddress({ ...address, phone });
        };

        const handleChangeWithDebounce = debounce(() => {
            const valid = isValid();
            if (valid) {
                onChange(address);
            }

            setIsAddressValid && setIsAddressValid(valid);
        }, 500);

        useEffect(() => {
            handleChangeWithDebounce();
        }, [address, handleChangeWithDebounce]);

        useImperativeHandle(forwardRef, () => ({
            isValid: isValid,
            validate: validate,
        }));

        return (
            <div className={editAddressStyles}>
                <StreetField
                    name={`${name}_street`}
                    inputRef={streetRef}
                    street={address.street}
                    onChange={setStreet}
                    className="street-field"
                    placeholder={streetPlaceholder}
                    poBoxNotAllowedText={poBoxNotAllowedText}
                    showState={showState}
                    streetErrorMessage={streetErrorMessage}
                />
                <div className="city-state-field">
                    <div className="city">
                        <CityField
                            name={`${name}_city`}
                            inputRef={cityRef}
                            city={address.city}
                            onChange={setCity}
                            placeholder={cityPlaceholder}
                            showState={showState}
                            cityErrorMessage={cityErrorMessage}
                        />
                    </div>
                    <div className="state">
                        <StateField
                            name={`${name}_state`}
                            inputRef={stateRef}
                            state={address.state}
                            onChange={setState}
                            placeholder={statePlaceholder}
                            showState={showState}
                        />
                    </div>
                </div>
                <ZipField
                    name={`${name}_zip`}
                    inputRef={zipRef}
                    zip={address.zip}
                    onChange={setZip}
                    placeholder={zipPlaceholder}
                    showState={showState}
                    zipErrorMessage={zipErrorMessage}
                />
                {address.phone !== undefined &&
                    phonePlaceholder !== undefined && (
                        <PhoneNumberField
                            name={`${name}_phone`}
                            inputRef={phoneRef}
                            phone={phoneFormatter(address.phone)}
                            onChange={setPhone}
                            placeholder={phonePlaceholder}
                            showState={showState}
                            phoneErrorMessage={phoneErrorMessage}
                        />
                    )}
            </div>
        );
    },
);

export interface ShowAndEditAddressProps
    extends AddressFieldRef,
        AddressPlaceholders {
    name: string;
    address: BaseAddress;
    onChange?: (address: Address | AddressWithPhone) => unknown;
    isEdit: boolean;
    setIsAddressValid?: (isValid: boolean) => unknown;
    zipErrorMessage?: string;
    streetErrorMessage?: string;
    cityErrorMessage?: string;
    phoneErrorMessage?: string;
}

export const ShowAndEditAddress = React.memo(
    forwardRef<AddressEditorRef, ShowAndEditAddressProps>(
        (
            {
                name,
                address,
                isEdit,
                onChange,
                streetRef,
                cityRef,
                stateRef,
                zipRef,
                phoneRef,
                streetPlaceholder,
                cityPlaceholder,
                statePlaceholder,
                zipPlaceholder,
                phonePlaceholder,
                poBoxNotAllowedText,
                setIsAddressValid,
                zipErrorMessage,
                streetErrorMessage,
                cityErrorMessage,
                phoneErrorMessage,
            },
            forwardRef,
        ) => {
            const editorRef = React.useRef<AddressEditorRef>(null);
            const handleChange = React.useCallback(
                (address: Address | AddressWithPhone) => {
                    address.street = stringToCamelCase(address.street);
                    address.city = stringToCamelCase(address.city);
                    if (onChange) onChange(address);
                },
                [onChange],
            );
            const isValid = React.useCallback(() => {
                if (editorRef.current) {
                    return editorRef.current.isValid();
                }
                return false;
            }, [editorRef]);
            const validate = React.useCallback(() => {
                if (editorRef.current) {
                    editorRef.current.validate();
                }
            }, [editorRef]);
            useImperativeHandle(forwardRef, () => ({
                isValid: isValid,
                validate: validate,
            }));
            if (isEdit) {
                return (
                    <EditAddress
                        ref={editorRef}
                        name={name}
                        address={address}
                        onChange={handleChange}
                        streetRef={streetRef}
                        cityRef={cityRef}
                        stateRef={stateRef}
                        zipRef={zipRef}
                        phoneRef={phoneRef}
                        streetPlaceholder={streetPlaceholder}
                        cityPlaceholder={cityPlaceholder}
                        statePlaceholder={statePlaceholder}
                        zipPlaceholder={zipPlaceholder}
                        phonePlaceholder={phonePlaceholder}
                        poBoxNotAllowedText={poBoxNotAllowedText}
                        setIsAddressValid={setIsAddressValid}
                        zipErrorMessage={zipErrorMessage}
                        streetErrorMessage={streetErrorMessage}
                        cityErrorMessage={cityErrorMessage}
                        phoneErrorMessage={phoneErrorMessage}
                    />
                );
            }
            return <ShowAddress address={address} />;
        },
    ),
);
