import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgGivingSummary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clip-rule="evenodd"
            d="M3.5 3.74805H20V20.248H3.5L3.5 7.49805H17C17.4142 7.49805 17.75 7.16226 17.75 6.74805C17.75 6.33383 17.4142 5.99805 17 5.99805H3.5V3.74805ZM2 3.74805C2 2.91962 2.67157 2.24805 3.5 2.24805H20C20.8284 2.24805 21.5 2.91962 21.5 3.74805V20.248C21.5 21.0765 20.8284 21.748 20 21.748H3.5C2.67157 21.748 2 21.0765 2 20.248V3.74805ZM13.955 11.248C14.7275 11.248 15.5 11.7055 15.5 12.733C15.5 14.4655 11.75 16.873 11.75 16.873C11.75 16.873 8 14.4655 8 12.733C8 11.7055 8.7725 11.248 9.545 11.248C10.175 11.248 10.595 11.608 10.9925 11.983C11.39 12.358 11.75 12.733 11.75 12.733C11.75 12.733 12.11 12.358 12.5075 11.983C12.905 11.608 13.3325 11.248 13.955 11.248ZM13.955 9.74805C12.9125 9.74805 12.185 10.288 11.75 10.648C11.315 10.288 10.58 9.74805 9.545 9.74805C7.7825 9.74805 6.5 11.008 6.5 12.733C6.5 13.3555 6.83 15.8455 11.0675 18.0205L11.75 18.373L12.4325 18.0205C16.67 15.8455 17 13.3555 17 12.733C17 11.008 15.7175 9.74805 13.955 9.74805Z"
            fill="#6A6873"
            fill-rule="evenodd"
        />
    </svg>
);

export const GivingSummaryIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgGivingSummary}
        fontSize={fontSize}
    />
);
