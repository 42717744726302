import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyModal,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useConfirmModalStyles } from './style';

interface F1CCBConfirmModalProps {
    confirmTitle: string;
    confirmText: string;
    confirmFunction: () => void;
    open: boolean;
    onClose: () => void;
    btnLoading: boolean;
}

const AutoIntegrationConfirmModal: React.FC<F1CCBConfirmModalProps> = ({
    confirmTitle,
    confirmText,
    confirmFunction,
    open,
    onClose,
    btnLoading,
}) => {
    const classes = useConfirmModalStyles();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            confirm: t('pages.integrations.confirmModal.confirm'),
            cancel: t('pages.integrations.confirmModal.cancel'),
        }),
        [t],
    );
    return (
        <GivelifyModal
            showCloseButton
            name="confirmModal"
            onClose={onClose}
            open={open}
        >
            <div className={classes.confirmModalContent}>
                <GivelifyLabel
                    bold
                    className={classes.modalTitle}
                    text={confirmTitle}
                    variant="heading3"
                />
                <GivelifyLabel text={confirmText} variant="body1" />
                <div className={classes.confirmModalGroupButton}>
                    <GivelifyButton
                        disabled={btnLoading}
                        name="cancel"
                        onClick={onClose}
                        size="xLarge"
                        text={copy.cancel}
                        variant="secondary"
                    />
                    <GivelifyButton
                        disabled={btnLoading}
                        isLoading={btnLoading}
                        name="confirm"
                        onClick={confirmFunction}
                        size="xLarge"
                        text={copy.confirm}
                        variant="primary"
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default AutoIntegrationConfirmModal;
