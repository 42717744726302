import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgEnvelopes = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>evnelopes</title>
        <path
            clipRule="evenodd"
            d="M12.937 2.03963C12.3892 1.60137 11.6108 1.60137 11.0629 2.03963L7.98747 4.5H6.75C5.92157 4.5 5.25 5.17157 5.25 6V6.85225L2.52249 9.10448C2.34994 9.24696 2.25003 9.45903 2.25003 9.6828V11.9877C2.24991 11.9955 2.24991 12.0033 2.25003 12.0111V20.2511C2.25003 20.6489 2.40807 21.0305 2.68937 21.3118C2.97068 21.5931 3.35221 21.7511 3.75003 21.7511H20.25C20.6479 21.7511 21.0294 21.5931 21.3107 21.3118C21.592 21.0305 21.75 20.6489 21.75 20.2511V12.007V11.9918V9.6828C21.75 9.45903 21.6501 9.24696 21.4776 9.10448L18.75 6.85219V6C18.75 5.17157 18.0784 4.5 17.25 4.5H16.0125L12.937 2.03963ZM15.7221 6C15.7404 6.00066 15.7586 6.00066 15.7768 6H17.25V13.7096L13.2636 16.5001H13.1353H13.1156H10.8845H10.8647H10.7364L6.75 13.7096V6H8.22309C8.2413 6.00066 8.25955 6.00066 8.27779 6H15.7221ZM13.6113 4.5L12 3.21094L10.3886 4.5H13.6113ZM5.25 12.6596V8.79754L3.75003 10.0361V11.6096L5.25 12.6596ZM18.75 12.6596L20.25 11.6096V10.0361L18.75 8.79749V12.6596ZM14.8644 17.2105L20.25 13.4405V19.634L14.8644 17.2105ZM6.03398 20.2511L11.0363 18.0001H12.9638L17.9661 20.2511H6.03398ZM3.75003 13.4406L9.13561 17.2105L3.75003 19.634V13.4406ZM12 9C11.5858 9 11.25 9.33579 11.25 9.75C11.25 10.1642 11.5858 10.5 12 10.5L12.0026 10.5C13.244 10.5014 14.25 11.5082 14.25 12.75C14.25 13.7297 13.6239 14.5631 12.75 14.872V15C12.75 15.4142 12.4142 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15V14.872C10.3761 14.5631 9.75 13.7297 9.75 12.75C9.75 12.3358 10.0858 12 10.5 12C10.9142 12 11.25 12.3358 11.25 12.75C11.25 13.1642 11.5858 13.5 12 13.5C12.4142 13.5 12.75 13.1642 12.75 12.75C12.75 12.3358 12.4142 12 12 12L11.9976 12C10.756 11.9987 9.75 10.9918 9.75 9.75C9.75 8.77034 10.3761 7.93691 11.25 7.62803V7.5C11.25 7.08579 11.5858 6.75 12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5V7.62803C13.6239 7.93691 14.25 8.77034 14.25 9.75C14.25 10.1642 13.9142 10.5 13.5 10.5C13.0858 10.5 12.75 10.1642 12.75 9.75C12.75 9.33579 12.4142 9 12 9Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const EnvelopesIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgEnvelopes}
        fontSize={fontSize}
    />
);
