import React, { useState, useEffect } from 'react';
import toCamelCase from 'camelcase-keys';
import InfiniteScroll from 'react-infinite-scroller';
import { useInvokeApi } from '../../../hooks/useInvokeApi';
import {
    PaginatedResponse,
    initialPaginatedResponse,
} from '../../../types/paginationTypes';

interface InfiniteLoaderProps<T> {
    LoadingComponent: JSX.Element;
    ErrorComponent: JSX.Element;
    ZerothComponent: JSX.Element;
    reset: number;
    onReset: () => unknown;
    url: (pageNumber: number) => string;
    renderData: (data: PaginatedResponse<T>) => JSX.Element | JSX.Element[];
    className?: string;
    useWindow?: boolean;
    getScrollParent?: () => HTMLElement;
    pauseLoading?: boolean;
}

const Loading = <h4 key="loading">Loading...</h4>;
const InfiniteLoader = <T,>(props: InfiniteLoaderProps<T>) => {
    const { url, reset, pauseLoading } = props;
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [dataSet, setData] = useState<PaginatedResponse<T>>(
        initialPaginatedResponse,
    );
    const [requestState, makeRequest] = useInvokeApi<PaginatedResponse<T>>();

    const increasePageNumber = () => {
        if (loading) return;
        setLoading(true);
        return setPageNumber((p) => p + 1);
    };

    useEffect(() => {
        if (requestState.type !== 'REQUEST_SUCCESS') return;

        const data = toCamelCase(requestState.data.data, {
            deep: true,
        }) as unknown as T[];
        const newData = {
            data,
            pagination: requestState.data.pagination,
        };
        if (newData.pagination.current_page === 1) {
            setData(newData);
        } else {
            setData((prevDataSet) => ({
                ...prevDataSet,
                data: [...prevDataSet.data, ...newData.data],
            }));
        }
        setLoading(false);
    }, [requestState]);

    useEffect(() => {
        setPageNumber(1);
        setLoading(false);
    }, [reset, url]);

    useEffect(() => {
        setLoading(true);
        if (pauseLoading) return;
        makeRequest('GET', url(pageNumber));
        //watch only page number and reset
        //eslint-disable-next-line
    }, [pageNumber, reset, url, pauseLoading]);

    if (requestState.type === 'REQUEST_ERROR') return props.ErrorComponent;
    if (
        (requestState.type === 'REQUEST_START' && pageNumber === 1) ||
        requestState.type === 'REQUEST_INIT'
    )
        return props.LoadingComponent;
    if (
        requestState.type === 'REQUEST_SUCCESS' ||
        (requestState.type === 'REQUEST_START' && pageNumber !== 1)
    )
        return dataSet.data.length === 0 ? (
            props.ZerothComponent
        ) : (
            <div className={props.className}>
                <InfiniteScroll
                    getScrollParent={props.getScrollParent}
                    hasMore={pageNumber < dataSet.pagination.total_pages}
                    loadMore={() => increasePageNumber()}
                    loader={Loading}
                    pageStart={0}
                    useWindow={props.useWindow}
                >
                    {props.renderData(dataSet)}
                </InfiniteScroll>
            </div>
        );
};

export default InfiniteLoader;
