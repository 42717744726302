import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { styled } from '@mui/material';

export const ChartHeaderWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginBottom: '8px',
    [theme.breakpoints.down('mobile')]: {
        gap: 8,
    },
}));

export const TotalAmountWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
});
export const TotalAmount = styled(GivelifyLabel)(({ theme }) => ({
    color: DesignTokens.color.textPrimary,
    fontSize: '54px',
    lineHeight: '64px',
    fontWeight: 800,
    [theme.breakpoints.down('tablet')]: {
        fontSize: '44px',
        lineHeight: '52px',
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: '36px',
        lineHeight: '48px',
    },
})) as typeof GivelifyLabel;

export const DollarSign = styled('span')(({ theme }) => ({
    fontSize: '32px',
    fontWeight: 800,
    [theme.breakpoints.down('tablet')]: {
        fontSize: '24px',
    },
}));

export const DonorsLink = styled(GivelifyLink)({
    fontSize: '16px',
    color: DesignTokens.color.textAccentDefault,
    textDecoration: 'none',
});
export const InfoWrapper = styled('div')({});

export const TotalText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        lineHeight: '18px',
    },
}));

export const DatesText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        lineHeight: '18px',
    },
}));
