import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ThunkAction } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import {
    setOfficers,
    loadOfficersStart,
    loadOfficersFinish,
    setOfficerTitles,
    loadOfficerTitlesStart,
    loadOfficerTitlesFinish,
} from './actions';
import { Officer } from './types';

export const getOfficers =
    (
        doneeId: number,
    ): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch) => {
        dispatch(loadOfficersStart());
        const httpRequest = getAxiosClient().get(`/donees/${doneeId}/users`);

        const officersResponse = await makeApiRequest<{ data: Officer[] }>(
            httpRequest,
        );

        if (officersResponse.success) {
            dispatch(setOfficers(officersResponse.response.data));
        }

        dispatch(loadOfficersFinish());

        return officersResponse.success;
    };

/**
 * Dispatcher to get officer titles suggestion sets
 */

export const getOfficerTitles =
    (): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch) => {
        dispatch(loadOfficerTitlesStart());

        const httpRequest = getAxiosClient().get(`/titles`);
        const officersResponse = await makeApiRequest<{
            data: { id: number; name: string }[];
        }>(httpRequest);

        if (officersResponse.success) {
            const result = officersResponse.response.data.map(
                (title) => title.name,
            );
            const titles: string[] = Array.from(new Set(result));
            dispatch(
                setOfficerTitles(titles.sort((a, b) => a.localeCompare(b))),
            );
        }

        dispatch(loadOfficerTitlesFinish());
        return officersResponse.success;
    };
