import { GivelifyTab } from '@givelify/ui';
import { styled } from '@mui/material';
import { DatePickersWidget } from 'components';

export const HistoryTabs = styled(GivelifyTab)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const Page = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(0, 2),
        borderRadius: 0,
    },
}));

export const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '32px',
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '24px',
    },
    '& .back-button': {
        padding: 0,
    },
}));

export const InfoRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
}));

export const ChartBox = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    maxHeight: '360px',
}));

export const DatePickersWidgetNoWrap = styled(DatePickersWidget)(
    ({ theme }) => ({
        [theme.breakpoints.up('smallTablet')]: {
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('smallTablet')]: {
            flexWrap: 'wrap',
            '& .dates-wrapper': {
                justifyContent: 'center',
            },
        },
    }),
);
