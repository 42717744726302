import parsePhoneNumber from 'libphonenumber-js/max';

const PHONE_FORMATTER_COUNTRY = 'US' as const;

export const formatPhoneNumber = (value: string): string | undefined => {
    if (value.length < 4) return value;

    const result = parsePhoneNumber(value, PHONE_FORMATTER_COUNTRY);

    return result ? result.formatNational() : value;
};

export const isValidPhoneNumber = (
    value: string | undefined | null,
): boolean => {
    if (!value || value.length < 4) return false;

    return parsePhoneNumber(value, PHONE_FORMATTER_COUNTRY)?.isValid() || false;
};
