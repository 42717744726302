import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>(theme => ({
    titleIntro: {
        textAlign: 'center',
        alignSelf: 'start',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            padding: theme.spacing(0, 5, 0, 0),
        },
    },
    integrationTitle: {
        margin: '5px auto 0',
    },
    titleContent: {
        margin: theme.spacing(5, 0),
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
    },
    titleCheckbox: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > li': {
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                paddingLeft: 0,
                justifyContent: 'flex-start',
            },
        },
    },
    dialogContent: {
        textAlign: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            gridArea: '1 / 2 / 2 / 3',
            textAlign: 'left',
            marginTop: '40px',
        },
    },
    closeDialog: {
        position: 'sticky',
        zIndex: 1,
        top: '0',
        right: '0',
        alignSelf: 'start',
        justifySelf: 'end',
        [theme.breakpoints.up('md')]: {
            gridArea: '1 / 2 / 2 / 3',
            position: 'absolute',
            top: `-${theme.spacing(5)}px`,
            right: `-${theme.spacing(5)}px`,
        },
    },
    checkIcon: {
        color: '#85919F',
    },
    expect: {
        color: theme.palette.text.primary,
    },
}));
