import React, { useMemo, useRef } from 'react';
import { PAGE_NAME } from '@givelify/utils';
import SettingsPage from 'pages/settings/SettingsPage';
import { useAdvancedTranslation } from 'utils/i18n';
import Content from './Content';
import { EnvelopesProvider } from './context/EnvelopesProvider';

const Envelopes: React.FC = () => {
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            title: at('pages.settings.envelopes2.title'),
            description: t('pages.settings.envelopes2.caption'),
            activeEnvelopesDescription: at(
                'pages.settings.envelopes2.active-envelopes-tab.description',
            ),
        }),
        [t, at],
    );

    // We will put create envelope button here
    const divRef = useRef();

    return (
        <SettingsPage
            trackPageVisit
            useDivider
            description={copy.description}
            pageName={PAGE_NAME.SettingsEnvelopes}
            rightColumnComponent={<div ref={divRef} />}
            title={copy.title}
            titleId="mainLabel"
            titleSecondRow={copy.activeEnvelopesDescription}
        >
            <EnvelopesProvider>
                <Content ref={divRef} />
            </EnvelopesProvider>
        </SettingsPage>
    );
};

export default Envelopes;
