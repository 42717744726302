import React from 'react';
import { useMountedState } from '@givelify/utils';
import { SliceInnerWrapper, SliceNumber, SliceWrapper } from './styles';
import { getArr } from './utils';

interface SliceProps {
    id?: string;
    className?: string;
    digit: number;
    height: number;
    width: number;
    delay: number;
}

const SliceComponent: React.FC<SliceProps> = ({
    className,
    id,
    delay,
    height,
    width,
    digit,
}) => {
    const [offset, setOffset] = React.useState<number>(0);
    const [isRolling, setIsRolling] = React.useState<boolean>(false);
    const [prevDigit, setPrevDigit] = React.useState<number>(0);
    const isMounted = useMountedState();

    const reset = React.useCallback((prevDigit: number) => {
        setOffset(0);
        setIsRolling(false);
        setPrevDigit(prevDigit);
    }, []);

    React.useEffect(() => {
        const offset = -digit * height;
        setTimeout(() => {
            if (isMounted()) {
                setOffset(offset);
                setIsRolling(true);
            }
        }, 100);
    }, [digit, height, isMounted]);

    React.useEffect(() => {
        const offset = -digit * height;
        setTimeout(() => {
            if (isMounted()) {
                setOffset(offset);
                setIsRolling(true);
            }
        }, 100);

        if (prevDigit !== digit) {
            reset(digit);
            //slice move in animation
            const diff = prevDigit - digit;
            const offset =
                diff > 0
                    ? -digit * height
                    : prevDigit < digit
                    ? -digit * height
                    : -(diff + 10) * height;
            setTimeout(() => {
                if (isMounted()) {
                    setOffset(offset);
                    setIsRolling(true);
                }
            }, 100);
        }
    }, [reset, digit, height, isMounted, prevDigit]);

    let arr = getArr(prevDigit.toString(), digit.toString());
    if (arr && arr[0] > 0) {
        arr = getArr('0', digit.toString());
    } else {
        arr = getArr(prevDigit.toString(), digit.toString());
    }

    return (
        <SliceWrapper className={className}>
            <SliceInnerWrapper
                delay={delay}
                id={id}
                isRolling={isRolling}
                offset={offset}
            >
                {arr &&
                    arr.map((d, index) => (
                        <SliceNumber
                            key={index}
                            data-testid={
                                digit === d && isRolling ? 'active-digit' : ''
                            }
                            height={height}
                            width={width}
                        >
                            {d}
                        </SliceNumber>
                    ))}
            </SliceInnerWrapper>
        </SliceWrapper>
    );
};

export const Slice = React.memo(SliceComponent);
