import clevertap from 'clevertap-web-sdk';
import mixpanel from 'mixpanel-browser';
import { Logger } from '../logger';
import { LogLevel } from '../logger/models';
import { ClevertapDonee, ClevertapUser } from './types';

const errMsg = 'Tracking context is not initialized';

export const isTest = process.env.NODE_ENV === 'test';

export const pushEvent = (
    eventName: string,
    payload: Record<string, unknown> = {},
) => {
    if (!clevertap?.event) {
        if (!isTest) {
            console.warn(errMsg);
        }
        return;
    }

    clevertap.event.push(eventName, payload);
    if (window.mixpanel?.initialized) {
        mixpanel.track(eventName, payload);
    }
};

export const getUserTrackingData = (
    user: ClevertapUser,
    donee?: ClevertapDonee,
    appVersion: string | undefined = undefined,
) => {
    let extraPayload;
    try {
        const bankInfo = donee?.onboarding?.bankInfo;
        const bankAccountOnboardingStatus = bankInfo?.status || 'unchecked';
        const bankDocSubmitted = !!bankInfo?.fileSubmitted;
        const bankInfoSubmitted =
            bankAccountOnboardingStatus !== 'unchecked' && !bankDocSubmitted;

        const ein = donee?.onboarding?.ein;
        const taxInformationStatus = ein?.status || 'unchecked';
        const einTaxIdDocSubmitted = !!ein?.fileSubmitted;
        const einTaxIdInfoSubmitted =
            taxInformationStatus !== 'unchecked' && !einTaxIdDocSubmitted;

        const primaryRepresentative = donee?.onboarding?.primaryRepresentative;
        const primaryRepresentativeStatus =
            primaryRepresentative?.status || 'unchecked';
        const primaryRepSubmitted = primaryRepresentativeStatus !== 'unchecked';
        const primaryRepVerified = primaryRepresentativeStatus === 'verified';

        const appProfile = donee?.onboarding?.appProfile;
        const hasBannerImage = !!appProfile?.coverPhoto?.coverPhoto;
        const hasFaithLeadImg = !!appProfile?.faithLeader?.avatar;
        const hasLogoImg = !!appProfile?.organizationLogo?.logo;
        const hasProfileCompleted =
            hasBannerImage && hasFaithLeadImg && hasLogoImg;

        const signUpDate = donee?.signupDate
            ?.toISOString()
            ?.slice(0, 19)
            ?.replace('T', ' ');

        const MIDApprovedDate = donee?.onboarding?.midDateAdded
            ?.toISOString()
            ?.slice(0, 19)
            ?.replace('T', ' ');

        extraPayload = {
            hasMID: !!donee?.onboarding?.hasMid,
            hasDonations: !!donee?.lastDonationDate,
            verified: !!donee?.onboarding?.hasCompleted,
            orgType: donee?.type,
            hasBannerImage,
            hasFaithLeadImg,
            hasLogoImg,
            hasProfileCompleted,
            signUpDate,
            MIDApprovedDate,
            bankAccountOnboardingStatus,
            bankDocSubmitted,
            bankInfoSubmitted,
            taxInformationStatus,
            einTaxIdDocSubmitted,
            einTaxIdInfoSubmitted,
            primaryRepresentativeStatus,
            primaryRepSubmitted,
            primaryRepVerified,
        };
    } catch (e: any) {
        extraPayload = {};
        Logger.log(
            e?.toString() || 'getUserTrackingData error',
            {},
            LogLevel.ERROR,
        );
    }

    const result = {
        Site: {
            Identity: user.id?.toString(),
            Name: user.fullName,
            Email: user.username,
            //eslint-disable-next-line
            Donee_Official_id: user.officialId?.toString(),
            //eslint-disable-next-line
            Donee_id: user.doneeId?.toString(),
            appVersion,
            ...extraPayload,
        },
    };

    return result;
};

// todo: make this strictly typed with User type
/**
 * Call this method when user logs in
 */
export const onLogin = (
    user: ClevertapUser,
    appVersion: string | undefined = undefined,
) => {
    if (!clevertap?.onUserLogin) {
        if (!isTest) {
            console.warn(errMsg);
        }
        return;
    }

    const trackData = getUserTrackingData(user, undefined, appVersion);
    clevertap.onUserLogin.push(trackData);
    if (window.mixpanel?.initialized) {
        mixpanel.identify(user.id?.toString());
        mixpanel.people.set({
            $name: trackData.Site.Name,
            $email: trackData.Site.Email,
            doneeId: trackData.Site.Donee_id,
            appVersion: trackData.Site.appVersion,
        });
    }
};

// todo: make this strictly typed with User type
/**
 * call this method to resume user session
 */
export const addProfile = (
    user: ClevertapUser,
    donee: ClevertapDonee,
    appVersion: string | undefined = undefined,
) => {
    if (!clevertap?.profile) {
        if (!isTest) {
            console.warn(errMsg);
        }
        return;
    }

    const data = getUserTrackingData(user, donee, appVersion);
    clevertap.profile.push(data);
};
