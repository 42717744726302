import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyNotification,
    GivelifyTextField,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import Space from 'components/Space';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FellowshipOneFormData } from 'store/integrations/thunks';
import * as yup from 'yup';
import { AppState } from '../../../../../../../store';
import { updateF1Credential } from '../../../AutoIntegrationContent/components/automatedIntegrationRequest';
import { F1CredentialUpdate } from '../../../AutoIntegrationContent/types';
import { useCCBSettingStyles } from '../../../IntegrationSettings/F1GoAndCCBIntegrationSettings';
import { settingTabStyle } from '../../style';

interface CredentialFormProps {
    updateCredential: string;
    username: string;
    isReadOnly?: boolean;
}

const CredentialForm: React.FC<CredentialFormProps> = ({
    updateCredential,
    username,
    isReadOnly,
}) => {
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));
    const classes = settingTabStyle();
    const { passwordField } = useCCBSettingStyles();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            username: t(
                'pages.integrations.content.setup-dialog.f1IntegrationSetup.username',
            ),
            password: t('login.title.password'),
            usernameRequired: t('f1SettingPage.usernameRequired'),
            passwordRequired: t('f1SettingPage.passwordRequired'),
            updateSuccessful: t(
                'pages.integrations.settings.ccb.updateSuccessful',
            ),
        }),
        [t],
    );

    const form = useForm<FellowshipOneFormData>({
        mode: 'onChange',
        reValidateMode: 'onBlur' || 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(
            yup.object<Record<keyof FellowshipOneFormData, yup.AnySchema>>({
                username: yup.string().required(copy.usernameRequired),
                password: yup.string().required(copy.passwordRequired),
            }),
        ),
    });
    const { errors, control } = form;

    const [requestUpdateCredential, makeRequestUpdateCredential] =
        useApiRequest();
    const [message, setMessage] = useState<string>('');
    const [pass, setPass] = useState<React.ChangeEvent<HTMLInputElement>>();

    const handleSubmit = () => {
        const formValues = form.getValues();
        setMessage('');
        const data: F1CredentialUpdate = {
            credentials: {
                username: formValues.username,
                password: formValues.password,
            },
        };
        makeRequestUpdateCredential(updateF1Credential(userDoneeId, data));
    };
    useEffect(() => {
        if (requestUpdateCredential.type === 'REQUEST_SUCCESS') {
            setMessage('');
        } else if (requestUpdateCredential.type === 'REQUEST_ERROR') {
            setMessage(requestUpdateCredential.error.message);
        }
    }, [requestUpdateCredential]);

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Space gap={2} />
            <div className={classes.betaSignUpField}>
                <GivelifyNotification
                    className={classes.errorNotification}
                    show={requestUpdateCredential.type === 'REQUEST_ERROR'}
                    text={message}
                    variant="error"
                />
                <GivelifyNotification
                    className={classes.errorNotification}
                    show={requestUpdateCredential.type === 'REQUEST_SUCCESS'}
                    text={copy.updateSuccessful}
                    variant="success"
                />
                <Controller
                    control={control}
                    name="username"
                    render={({ name, onBlur, value, onChange, ref }) => (
                        <GivelifyTextField
                            ariaLabel="user-name-input"
                            className={classes.formControl2}
                            defaultValue={username}
                            disabled={isReadOnly}
                            id="user-name-input"
                            inputRef={ref}
                            label={copy.username}
                            leftHelperText={errors.username?.message}
                            marginBottom={32}
                            name={name}
                            onBlur={onBlur}
                            onChange={(value) => {
                                onChange(value);
                            }}
                            state={errors.username ? 'error' : 'normal'}
                            value={value}
                        />
                    )}
                />
            </div>
            <div className={classes.betaSignUpField}>
                <Controller
                    control={control}
                    name="password"
                    render={({ name, onBlur, value, onChange, ref }) => (
                        <GivelifyTextField
                            fakeFocus
                            ariaLabel="password"
                            className={passwordField}
                            classes={{
                                labelFocusedRoot: 'labelFocusedRoot',
                                labelFocused: 'labelFocused',
                            }}
                            id="password"
                            inputRef={ref}
                            label={copy.password}
                            leftHelperText={errors.password?.message}
                            marginBottom={32}
                            name={name}
                            onBlur={onBlur}
                            onChange={(value) => {
                                setPass(value);
                                onChange(value);
                            }}
                            placeholder="********"
                            state={errors.password ? 'error' : 'normal'}
                            type="password"
                            value={value}
                        />
                    )}
                />
            </div>
            <div className={classes.betaSignUpField}>
                <GivelifyButton
                    disabled={
                        isReadOnly ||
                        Object.keys(form.errors).length > 0 ||
                        requestUpdateCredential.type === 'REQUEST_START' ||
                        !pass
                    }
                    isLoading={requestUpdateCredential.type === 'REQUEST_START'}
                    name="submit"
                    onClick={handleSubmit}
                    size="xLarge"
                    text={updateCredential}
                    type="submit"
                    variant="primary"
                />
            </div>
        </form>
    );
};

export default CredentialForm;
