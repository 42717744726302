import { Reducer } from 'redux';
import { Features } from 'utils/featureGating';
import {
    SET_ENABLED_FEATURES,
    SET_MENU_ITEMS,
    SET_ONLINE_GIVING_URLS,
    SET_SNAP_GIVE_QR_CODES,
    SHOW_LOADER,
    SystemActions,
    SystemState,
    TOGGLE_NESTED_MENU_ITEM,
} from './types';

export const initialSystemState: SystemState = {
    menuItems: [],
    enabledFeatures: [],
    loading: false,
    onboardingEnabled: false,
    yodleeEnabled: false,
    snapGiveEnabled: false,
    snapGiveQRCodes: [],
    onlineGivingURLs: [],
};

const SystemReducer: Reducer<SystemState, SystemActions> = (
    state = initialSystemState,
    action,
) => {
    switch (action.type) {
        case SET_MENU_ITEMS: {
            const activeMenu = action.menuItems.find(
                (item) => item.childIsActive,
            );

            return {
                ...state,
                menuItems: action.menuItems.map((menuItem, i) => ({
                    ...menuItem,
                    isExpanded:
                        state.menuItems[i]?.isExpanded === undefined
                            ? activeMenu
                                ? menuItem.id === activeMenu.id
                                : menuItem.defaultExpanded
                            : state.menuItems[i].isExpanded,
                })),
            };
        }
        case TOGGLE_NESTED_MENU_ITEM: {
            const item = state.menuItems.find((item) => item.id === action.id);

            if (!item?.nested) return state;

            return {
                ...state,
                menuItems: state.menuItems.map((menuItem) => ({
                    ...menuItem,
                    isExpanded:
                        menuItem.id === action.id
                            ? !menuItem.isExpanded
                            : false,
                })),
            };
        }
        case SET_ENABLED_FEATURES: {
            const onboardingEnabled = action.enabledFeatures.find(
                (x) => x.label === Features.ONBOARDING,
            );
            const yodleeEnabled = action.enabledFeatures.find(
                (x) => x.label === Features.SHOW_YODLEE,
            );
            return {
                ...state,
                enabledFeatures: [...action.enabledFeatures],
                onboardingEnabled:
                    onboardingEnabled !== undefined &&
                    onboardingEnabled.isEnabled,
                yodleeEnabled:
                    yodleeEnabled !== undefined && yodleeEnabled.isEnabled,
            };
        }
        case SHOW_LOADER:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_SNAP_GIVE_QR_CODES:
            return {
                ...state,
                snapGiveQRCodes: action.qrCodes,
                snapGiveEnabled: action.qrCodes?.length > 0,
            };
        case SET_ONLINE_GIVING_URLS:
            return {
                ...state,
                onlineGivingURLs: action.onlineGivingURLs,
            };
        default:
            return state;
    }
};

export default SystemReducer;
