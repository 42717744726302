import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgPhoneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M10.8561 13.4803C9.68615 12.3103 8.80415 11.0003 8.21815 9.6713C8.09415 9.3903 8.16715 9.0613 8.38415 8.8443L9.20315 8.0263C9.87415 7.3553 9.87415 6.4063 9.28815 5.8203L8.11415 4.6463C7.33315 3.8653 6.06715 3.8653 5.28615 4.6463L4.63415 5.2983C3.89315 6.0393 3.58415 7.1083 3.78415 8.1683C4.27815 10.7813 5.79615 13.6423 8.24515 16.0913C10.6941 18.5403 13.5551 20.0583 16.1681 20.5523C17.2281 20.7523 18.2971 20.4433 19.0381 19.7023L19.6891 19.0513C20.4701 18.2703 20.4701 17.0043 19.6891 16.2233L18.5161 15.0503C17.9301 14.4643 16.9801 14.4643 16.3951 15.0503L15.4921 15.9543C15.2751 16.1713 14.9461 16.2443 14.6651 16.1203C13.3361 15.5333 12.0261 14.6503 10.8561 13.4803Z"
            fillRule="evenodd"
            stroke="#6A6873"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

export const PhoneIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgPhoneIcon}
        fontSize={fontSize}
    />
);
