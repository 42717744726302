import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
    Grid,
    Table,
    TableFixedColumns,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { TableCellBaseProps } from '@material-ui/core';
import { HeaderContentComponent } from 'components/Tables/CellFormatters/CurrencyFormatterProvider';
import { DateRangeCell } from 'components/Tables/CellFormatters/DateRangeFormatterProvider';
import NADefaultValueTypeProvider from 'components/Tables/CellFormatters/NADefaultValueTypeProvider';
import { TableColumnProps } from 'components/Tables/TableColumnTitle';
import { IntegrationTypes } from 'pages/integrations/types';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Report } from 'store/report/types';
import { useTableClasses } from 'styles/tableClasses';
import {
    INTEGRATIONS_MATCH_DONOR_MODAL_CLOSE,
    INTEGRATIONS_MATCH_DONOR_MODAL_OPEN,
    INTEGRATIONS_MATCH_ENVELOPE_MODAL_CLOSE,
    INTEGRATIONS_MATCH_ENVELOPE_MODAL_OPEN,
} from 'utils/clevertapEvents';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { useAdvancedTranslation } from 'utils/i18n';
import CampusCell from './Cells/CampusCell';
import CreatedAtCell from './Cells/CreatedAtCell';
import DownloadedByCell from './Cells/DownloadedByCell';
import MatchCell from './Cells/MatchCell';
import ReportActionsCell from './Cells/ReportActionsCell';
import MatchingModal, { MatchItemType, ModalInfo } from './MatchingModal';

const TableComponent = ({ ...restProps }: TableCellBaseProps) => {
    const classes = useTableClasses();
    return <Table.Table {...restProps} className={classes.table} />;
};

const fixedLeftColumns: string[] = ['reportDate'];
const NADefaultValueColumns: string[] = ['reportDate'];

const IntegrationReportsTable = ({
    pageNumber,
    rows,
    integration,
    isReadOnly,
}: {
    pageNumber: number;
    rows: Report[];
    isReadOnly: boolean;
    integration: IntegrationTypes;
}) => {
    const [modalInfo, setModalInfo] = useState<ModalInfo>();
    useEffect(() => setModalInfo(undefined), [pageNumber]);

    const { t } = useAdvancedTranslation();
    const { trackEvent } = useTrackingContext();
    const { hasCampuses } = useSelector((state: AppState) => ({
        hasCampuses: state.Donee.campuses?.length > 1,
    }));

    const TRANSLATION_KEY = 'pages.integrations.table';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const copy = useMemo(
        () => ({
            reportDate: scopedTranslate('reportDate'),
            campus: scopedTranslate('campus'),
            totalDonation: scopedTranslate('totalDonation'),
            donors: scopedTranslate('donors'),
            toMatch: scopedTranslate('toMatch'),
            envelopes: scopedTranslate('envelopes'),
            date: scopedTranslate('date'),
            generated: scopedTranslate('generated'),
            report: scopedTranslate('report'),
            downloadedBy: scopedTranslate('downloadedBy'),
        }),
        [scopedTranslate],
    );

    const columns: TableColumnProps[] = useMemo(() => {
        let cols = [
            {
                name: 'reportDate',
                upperTitle: copy.reportDate,
            },
            {
                name: 'campus',
                upperTitle: copy.campus,
            },
            {
                name: 'totalAmount',
                upperTitle: copy.totalDonation,
            },
            {
                name: 'totalUnmatchedDonors',
                upperTitle: copy.donors,
                lowerTitle: copy.toMatch,
            },
            {
                name: 'totalUnmatchedEnvelopes',
                upperTitle: copy.envelopes,
                lowerTitle: copy.toMatch,
            },
            {
                name: 'createdAt',
                upperTitle: copy.date,
                lowerTitle: copy.generated,
            },
            {
                name: 'downloadReport',
                upperTitle: copy.report,
            },
            {
                name: 'lastDownloadedByName',
                upperTitle: copy.downloadedBy,
            },
        ];

        if (
            !integration.memberIdLabel ||
            integration.integrationType === 'ACSREALM'
        ) {
            cols = cols.filter((col) => {
                return col.name !== 'totalUnmatchedDonors';
            });
        }

        if (!hasCampuses) {
            cols = cols.filter((col) => {
                return col.name !== 'campus';
            });
        }
        return cols;
    }, [copy, integration, hasCampuses]);

    const openMatchModal = useCallback(
        (reportId: number, type: MatchItemType) => {
            setModalInfo({
                reportId,
                type,
            });

            type === 'donor'
                ? trackEvent(INTEGRATIONS_MATCH_DONOR_MODAL_OPEN, {
                      reportId,
                  })
                : trackEvent(INTEGRATIONS_MATCH_ENVELOPE_MODAL_OPEN, {
                      reportId,
                  });
        },
        [trackEvent],
    );

    const Cell = useCallback(
        (props: Table.DataCellProps) => {
            const { column } = props;
            switch (column.name) {
                case 'reportDate':
                    return <DateRangeCell {...props} />;
                case 'campus':
                    return <CampusCell {...props} />;
                case 'downloadReport':
                    return (
                        <ReportActionsCell
                            {...props}
                            isReadOnly={isReadOnly}
                            openMatchModal={openMatchModal}
                        />
                    );
                case 'totalUnmatchedDonors':
                case 'totalUnmatchedEnvelopes':
                    return (
                        <MatchCell
                            {...props}
                            columnName={column.name}
                            openMatchModal={openMatchModal}
                        />
                    );
                case 'createdAt':
                    return <CreatedAtCell {...props} />;
                case 'lastDownloadedByName':
                    return <DownloadedByCell {...props} />;

                case 'totalAmount':
                    return (
                        <Table.Cell {...props}>
                            <GivelifyLabel
                                text={`$${formatMoneyWithCommas(props.value)}`}
                                variant="body2"
                            />
                        </Table.Cell>
                    );
                default:
                    return <Table.Cell {...props} />;
            }
        },
        [openMatchModal, isReadOnly],
    );

    const tableColumnExtensions: Table.ColumnExtension[] = useMemo(
        () => [
            { columnName: 'reportDate', width: 194 },
            { columnName: 'campus' },
            {
                columnName: 'totalAmount',
                width: hasCampuses ? 126 : 161,
                align: 'right',
            },
            {
                columnName: 'totalUnmatchedDonors',
                width: hasCampuses ? 75 : 95,
            },
            {
                columnName: 'totalUnmatchedEnvelopes',
                width: hasCampuses ? 75 : 95,
            },
            { columnName: 'createdAt', width: 109 },
            {
                columnName: 'downloadReport',
                width: hasCampuses ? 138 : 198,
                align: 'center',
            },
            {
                columnName: 'lastDownloadedByName',
                width: hasCampuses ? 137 : 194,
            },
        ],
        [hasCampuses],
    );

    return (
        <>
            <Grid columns={columns} rows={rows}>
                <Table
                    cellComponent={Cell}
                    columnExtensions={tableColumnExtensions}
                    tableComponent={TableComponent}
                />
                <NADefaultValueTypeProvider for={NADefaultValueColumns} />

                <TableHeaderRow contentComponent={HeaderContentComponent} />
                <TableFixedColumns leftColumns={fixedLeftColumns} />
            </Grid>
            <MatchingModal
                handleClose={() => {
                    modalInfo.type === 'donor'
                        ? trackEvent(INTEGRATIONS_MATCH_DONOR_MODAL_CLOSE, {
                              reportId: modalInfo.reportId,
                          })
                        : trackEvent(INTEGRATIONS_MATCH_ENVELOPE_MODAL_CLOSE, {
                              reportId: modalInfo.reportId,
                          });

                    setModalInfo(undefined);
                }}
                modalInfo={modalInfo}
                reports={rows}
            />
        </>
    );
};
export default IntegrationReportsTable;
