import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="64"
        viewBox="0 0 64 64"
        width="64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="32" cy="32" fill="#DBD6FF" r="32" />
        <path
            d="M30.1701 7.44731C30.9987 6.06632 33.0002 6.06631 33.8288 7.4473L55.6609 43.8342C56.514 45.2561 55.4898 47.0651 53.8316 47.0651H10.1673C8.50906 47.0651 7.48482 45.2561 8.33797 43.8342L30.1701 7.44731Z"
            fill="#715AFF"
        />
        <ellipse
            cx="32.0003"
            cy="40.3797"
            fill="#FED05D"
            rx="2.66716"
            ry="2.66716"
            transform="rotate(-180 32.0003 40.3797)"
        />
        <path
            clipRule="evenodd"
            d="M32.0004 35.5859C31.1167 35.5859 30.4004 34.9233 30.4004 34.1059L30.4004 17.3326C30.4004 16.5152 31.1167 15.8526 32.0004 15.8526C32.884 15.8526 33.6004 16.5152 33.6004 17.3326L33.6004 34.1059C33.6004 34.9233 32.884 35.5859 32.0004 35.5859Z"
            fill="#FED05D"
            fillRule="evenodd"
        />
    </svg>
);

export const AlertIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgAlert}
        fontSize={fontSize}
    />
);
