import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyDropDownDoubleLineOptionStyles } from './styles';
import { GivelifyIcon } from '../components/GivelifyIcon';
import { GivelifyLabel } from '../label';
import { GivelifyRadio } from '../radio';
import { GivelifyCheckbox } from '../checkbox';

export interface DoubleLineOptionProps {
    value: string;
    label: string;
    description: string;
    onSelect: (value: string) => void;
    selected?: boolean;
    active?: boolean;
    checkMarkVariant?: 'mark' | 'radio' | 'checkbox';
    hideCheckMark?: boolean;
    classes?: {
        root?: string;
        checkRoot?: string;
        checkIcon?: string;
        contentRoot?: string;
        activeOption?: string;
        label?: string;
        description?: string;
    };
    disableAutoScroll?: boolean;
}

export function DoubleLineOption(props: DoubleLineOptionProps) {
    const {
        value,
        label,
        selected,
        active,
        description,
        checkMarkVariant = 'mark',
        hideCheckMark,
        onSelect,
        disableAutoScroll,
        classes,
    } = props;
    const innerRef = React.useRef<HTMLDivElement | null>(null);
    const {
        drdOptionRoot,
        drdCheckIcon,
        drdCheckRoot,
        drdContentRoot,
        drdActiveOption,
        drdLabel,
        drdDescription,
        drdOptionContentHideMark,
    } = GivelifyDropDownDoubleLineOptionStyles();
    const activeOptionClassName = mergeClassNames(
        drdActiveOption,
        classes ? (classes.activeOption ? classes.activeOption : '') : '',
    );
    const rootClassName = mergeClassNames(
        drdOptionRoot,
        active && activeOptionClassName,
        classes ? (classes.root ? classes.root : '') : '',
    );
    const checkIconClassName = mergeClassNames(
        drdCheckIcon,
        classes ? (classes.checkIcon ? classes.checkIcon : '') : '',
    );
    const contentRootClassName = mergeClassNames(
        drdContentRoot,
        hideCheckMark && drdOptionContentHideMark,
        classes ? (classes.contentRoot ? classes.contentRoot : '') : '',
    );
    const labelClassName = mergeClassNames(
        drdLabel,
        classes ? (classes.label ? classes.label : '') : '',
    );
    const decriptionClassName = mergeClassNames(
        drdDescription,
        classes ? (classes.description ? classes.description : '') : '',
    );
    const checkRootClassName = mergeClassNames(
        drdCheckRoot,
        classes ? (classes.checkRoot ? classes.checkRoot : '') : '',
    );
    const onClick = React.useCallback(() => {
        onSelect(value);
    }, [onSelect, value]);

    React.useEffect(() => {
        if (active) {
            innerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [active, innerRef]);

    React.useEffect(() => {
        if (!disableAutoScroll && selected) {
            innerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [selected, innerRef, disableAutoScroll]);

    return (
        <div
            id={`option-${value}`}
            className={rootClassName}
            onClick={onClick}
            tabIndex={1}
            ref={innerRef}
        >
            {hideCheckMark ? null : (
                <div className={checkRootClassName}>
                    {checkMarkVariant === 'checkbox' ? (
                        <GivelifyCheckbox
                            name={`option-${value}-radio`}
                            ariaLabel={`option-${value}-radio`}
                            checked={selected ? selected : false}
                            variant="primary"
                        />
                    ) : checkMarkVariant === 'radio' ? (
                        <GivelifyRadio
                            name={`option-${value}-radio`}
                            ariaLabel={`option-${value}-radio`}
                            checked={selected ? selected : false}
                            variant="primary"
                        />
                    ) : selected ? (
                        <GivelifyIcon
                            variant="check"
                            className={checkIconClassName}
                        />
                    ) : null}
                </div>
            )}
            <div className={contentRootClassName}>
                <GivelifyLabel
                    text={label}
                    variant="body2"
                    className={labelClassName}
                />
                <GivelifyLabel
                    text={description}
                    variant="body2"
                    className={decriptionClassName}
                />
            </div>
        </div>
    );
}
