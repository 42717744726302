import React from 'react';
import { DropdownOption } from '@givelify/ui';
import { Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { DataTableControl, DataTableSortDirection } from 'components';
import { useTranslation } from 'react-i18next';
import { formatCountWithCommas } from 'utils/formatMoneyWithCommas';
import {
    DataTableControlWrapper,
    DropdownListItem,
    MainLabel,
    TitleLine,
} from './styles';

type DonationTableHeaderComponentProps = {
    sort: string;
    onSortRequest: (field: string, direction: DataTableSortDirection) => void;
    loading: boolean;
    totalUnfilteredDonations: number;
    totalCount: number;
    hasFilters: boolean;
};

const DonationTableHeaderComponent: React.FC<
    DonationTableHeaderComponentProps
> = ({
    sort,
    onSortRequest,
    loading,
    hasFilters,
    totalUnfilteredDonations,
    totalCount,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            sortBy: t('components.dataTable.sortBy'),
            showing: t('components.dataTable.showing'),
            all: t('components.dataTable.all'),
            of: t('components.dataTable.of'),
            donations: t('donationsActivity.donations'),
            filterLabelName: t('donationsActivity.filterLabels.name'),
            filterLabelDate: t('donationsActivity.filterLabels.date'),
            filterLabelAtoZ: t('donationsActivity.filterLabels.AtoZ'),
            filterLabelZtoA: t('donationsActivity.filterLabels.ZtoA'),
            filterLabelNewToOld: t('donationsActivity.filterLabels.newToOld'),
            filterLabelOldToNew: t('donationsActivity.filterLabels.oldToNew'),
        }),
        [t],
    );

    const sortOptions: DropdownOption[] = React.useMemo(
        () => [
            {
                id: 'date-desc',
                label: copy.filterLabelDate,
                secondaryLabel: copy.filterLabelNewToOld,
            },
            {
                id: 'date-asc',
                label: copy.filterLabelDate,
                secondaryLabel: copy.filterLabelOldToNew,
            },
            {
                id: 'donor_name-asc',
                label: copy.filterLabelName,
                secondaryLabel: copy.filterLabelAtoZ,
            },
            {
                id: 'donor_name-desc',
                label: copy.filterLabelName,
                secondaryLabel: copy.filterLabelZtoA,
            },
        ],
        [copy],
    );

    const onMenuItemRender = React.useCallback((option: DropdownOption) => {
        return (
            <DropdownListItem color="inherit" data-testid="dropdown-menu-item">
                <MainLabel>{option.label}</MainLabel> {option.secondaryLabel}
            </DropdownListItem>
        );
    }, []);

    const setRenderValueText = React.useCallback(
        (option: DropdownOption) => {
            return isMobile
                ? copy.sortBy
                : `${option.label} ${option.secondaryLabel}`;
        },
        [copy.sortBy, isMobile],
    );

    const Title = React.useMemo(() => {
        if (loading) {
            return (
                <Stack alignItems="center" data-testid="donations-table-loader" direction="row">
                    <Skeleton animation="wave" height={27} width="120px" />
                </Stack>
            );
        }
        return (
            <TitleLine data-testid="donations-table-title">
                {copy.showing}{' '}
                <strong id="number-of-donors">
                    {isMobile ? (
                        formatCountWithCommas(totalCount)
                    ) : hasFilters ? (
                        <>
                            {formatCountWithCommas(totalCount)} {copy.of}{' '}
                            {formatCountWithCommas(totalUnfilteredDonations)}
                        </>
                    ) : (
                        <>
                            {copy.all}{' '}
                            {formatCountWithCommas(totalUnfilteredDonations)}
                        </>
                    )}{' '}
                    {copy.donations}
                </strong>
            </TitleLine>
        );
    }, [
        loading,
        totalCount,
        hasFilters,
        totalUnfilteredDonations,
        copy,
        isMobile,
    ]);

    const onSortChange = React.useCallback(
        (sort: string) => {
            const [sortColumn, sortDirection] = sort.split('-');
            onSortRequest(sortColumn, sortDirection === 'asc' ? 'asc' : 'desc');
        },
        [onSortRequest],
    );

    return (
        <DataTableControlWrapper>
            <DataTableControl
                className="data-table-control"
                loading={loading}
                onMenuItemRender={onMenuItemRender}
                onSortChange={onSortChange}
                setRenderValueText={setRenderValueText}
                sortOptions={sortOptions}
                sortValue={sort}
                title={Title}
            />
        </DataTableControlWrapper>
    );
};

export const DonationTableHeader = React.memo(DonationTableHeaderComponent);
