import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgPasswordHidden = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>password-hidden</title>
        <path
            clipRule="evenodd"
            d="M5.408 4.348a.75.75 0 0 0-1.067 1.054L5.85 6.93C4.439 8.14 3.23 9.758 2.322 11.68L2.17 12l.152.32C4.374 16.666 8.01 19.5 12 19.5c1.734 0 3.41-.54 4.913-1.506l1.685 1.665a.75.75 0 0 0 1.054-1.068l-2.122-2.096-.004-.003L7.504 6.47 5.408 4.348ZM3.834 12c.826-1.648 1.885-3.002 3.076-4.003l2.433 2.434a3.75 3.75 0 0 0 4.976 4.976l1.5 1.5C14.61 17.622 13.311 18 12 18c-3.145 0-6.259-2.195-8.165-6Zm9.296 2.218-2.598-2.599a2.25 2.25 0 0 0 2.598 2.598ZM9.966 6.304A7.048 7.048 0 0 1 12 6c3.146 0 6.26 2.195 8.166 6-.427.85-.916 1.624-1.453 2.31a.75.75 0 0 0 1.181.925 15.369 15.369 0 0 0 1.784-2.915l.151-.32-.151-.32C19.626 7.334 15.989 4.5 12 4.5c-.839 0-1.665.126-2.466.367a.75.75 0 0 0 .432 1.437Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const PasswordHiddenIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgPasswordHidden}
        fontSize={fontSize}
    />
);
