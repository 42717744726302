import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getPollAnswerApiEndpoint,
    getPollsApiEndpoint,
} from 'api/client/endpoints';
import { PollAnswer } from '../../models';
import { IPollService } from '../interfaces';
import {
    GetActivePollsListResponse,
    PostPollAnswerResponse,
} from '../responses/poll';

export class PollService implements IPollService {
    public async getActivePollsList(
        givingPartnerId: number,
    ): Promise<ApiResponse<GetActivePollsListResponse>> {
        const url = getPollsApiEndpoint(givingPartnerId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetActivePollsListResponse>(
            httpRequest,
        );
        return result;
    }

    public async postPollAnswer(
        doneeId: number,
        pollId: number,
        answer: PollAnswer,
    ): Promise<ApiResponse<PostPollAnswerResponse>> {
        const url = getPollAnswerApiEndpoint(doneeId, pollId);
        const requestPayload = {
            answers: [
                {
                    id: answer.id,
                    custom_answer: answer.isCustomAnswer
                        ? answer.answer
                        : undefined,
                },
            ],
        };
        const httpRequest = getAxiosClient().post(url, requestPayload);
        const result = await makeApiRequest<PostPollAnswerResponse>(
            httpRequest,
        );
        return result;
    }
}
