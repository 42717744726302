import React from 'react';
import { Hidden } from '@material-ui/core';

import FacebookImageArrow from '../../../assets/social-media-preview/facebook-image-arrow.svg';
import FacebookLinkArrow from '../../../assets/social-media-preview/facebook-link-arrow.svg';
import FacebookPost from '../../../assets/social-media-preview/facebook.png';

import Label from '../../../components/Label';

import { useTranslations } from '../../../utils/translations';
import { useStyle } from './style';

const Preview: React.FC = () => {
  const cs = useStyle();

  const { preview } = useTranslations('socialMediaGiving.facebook.page', {
    preview: ['title', 'imageDesc', 'linkDesc'],
  });

  return (
    <div className={cs.previewContainer}>
      <div>
        <Label className={cs.previewTitle} variant="body3">
          {preview.title}
        </Label>
        <img
          alt="facebook"
          className={cs.previewImage}
          src={FacebookPost}
          title="facebook"
        />
      </div>

      <div className={cs.previewArrowsContainer}>
        <Hidden xsDown>
          <Label
            className={cs.previewArrowText + ' ' + cs.imgArrowText}
            variant="body3"
          >
            {preview.imageDesc}
          </Label>
          <img
            alt={preview.imageDesc}
            className={cs.imgArrow}
            src={FacebookImageArrow}
            title="indicator arrow"
          />
          <img
            alt={preview.linkDesc}
            className={cs.linkArrow}
            src={FacebookLinkArrow}
            title="indicator arrow"
          />
          <Label
            className={cs.previewArrowText + ' ' + cs.linkArrowText}
            variant="body3"
          >
            {preview.linkDesc}
          </Label>
        </Hidden>
      </div>
    </div>
  );
};
export default Preview;
