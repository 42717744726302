import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    infoWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'space-between',
        gap: 4,
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    mobileListWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    divider: {
        height: 1,
        width: '100%',
        margin: 0,
        backgroundColor: theme.colors.grey200,
    },
}));

const RefundRowLoadingMobile = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.infoWrapper}>
                <FacebookLoading
                    borderRadius={4}
                    height={12}
                    marginBottom={1}
                    marginTop={1}
                    width={100}
                />
                <FacebookLoading
                    borderRadius={4}
                    height={12}
                    marginBottom={1}
                    marginTop={1}
                    width={100}
                />
                <FacebookLoading
                    borderRadius={4}
                    height={14}
                    marginBottom={5}
                    marginTop={5}
                    width={100}
                />
                <FacebookLoading
                    borderRadius={4}
                    height={14}
                    marginBottom={5}
                    marginTop={5}
                    width={100}
                />
            </div>
            <div className={classes.buttonWrapper}>
                <FacebookLoading
                    borderRadius={4}
                    height={16}
                    marginBottom={4}
                    width={150}
                />
            </div>
        </div>
    );
};

const RefundsRowLoading: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.mobileListWrapper}>
            <RefundRowLoadingMobile />
            <div className={classes.divider} />
            <RefundRowLoadingMobile />
            <div className={classes.divider} />
            <RefundRowLoadingMobile />
        </div>
    );
};

export default RefundsRowLoading;
