import { Pagination } from '../../types/paginationTypes';

export type ReportType =
    | 'ACS'
    | 'POWERCHURCH'
    | 'SERVANTKEEPER'
    | 'ACSREALM'
    | 'SHELBY'
    | 'SHELBYARENA'
    | 'SHELBYNEXT'
    | 'F1'
    | 'CCB'
    | 'RECONCILIATION'
    | 'PLANNING_CENTER'
    | 'F1GO';

export type ReportState =
    | 'requested'
    | 'sent'
    | 'processing'
    | 'failed'
    | 'complete'
    | 'regenerate';

export type ReportHistory = {
    id: number;
    requested_by: number;
    requested_by_name: string;
    file_type: string;
    file_name: string;
    report_type: ReportType;
    report_friendly_name: string;
    report_type_version: string;
    donee_id: number;
    start_date: string;
    end_date: string;
    report_state: ReportState;
    s3_key: string;
    download_link: string;
    started_at: string;
    completed_at: string;
    total_amount: number;
    imported_by: number;
    imported: boolean;
    created_at: string;
    total_unmatched_donors: number;
    total_donors: number;
    total_unmatched_envelopes: number;
    total_envelopes: number;
    last_downloaded_by_name: string;
    downloaded_at: string;
    all_campuses: number;
    donor_name?: string;
};

export type IntegrationMatchingProgress = {
    total_donors: number;
    total_envelopes: number;
    total_matched_donors: number;
    total_matched_envelopes: number;
};

export type ReportHistoryResponse = {
    data: ReportHistory[];
    pagination: Pagination;
    meta: IntegrationMatchingProgress;
};

export type Report = {
    id: number;
    requestedBy: ReportHistory['requested_by'];
    requestedByName: ReportHistory['requested_by_name'];
    fileType: ReportHistory['file_type'];
    fileName: ReportHistory['file_name'];
    doneeId: ReportHistory['donee_id'];
    reportType: ReportHistory['report_type'];
    reportTypeFriendlyName: ReportHistory['report_friendly_name'];
    reportTypeVersion: ReportHistory['report_type_version'];
    startDate: ReportHistory['start_date'];
    endDate: ReportHistory['end_date'];
    reportState: ReportHistory['report_state'];
    s3Key: ReportHistory['s3_key'];
    downloadLink: ReportHistory['download_link'];
    totalAmount: ReportHistory['total_amount'];
    imported: ReportHistory['imported'];
    importedBy: ReportHistory['imported_by'];
    startedAt: ReportHistory['started_at'];
    completedAt: ReportHistory['completed_at'];
    createdAt: ReportHistory['created_at'];
    totalUnmatchedDonors: ReportHistory['total_unmatched_donors'];
    totalDonors: ReportHistory['total_donors'];
    totalUnmatchedEnvelopes: ReportHistory['total_unmatched_envelopes'];
    totalEnvelopes: ReportHistory['total_envelopes'];
    lastDownloadedByName: ReportHistory['last_downloaded_by_name'];
    downloadedAt: ReportHistory['downloaded_at'];
    allCampuses: ReportHistory['all_campuses'];
    donorName?: ReportHistory['donor_name'];
};

export interface ReportStoreState {
    reportHistory: Report[];
    reportTotalDonationSinceLastExport: number;

    matchingProgress: IntegrationMatchingProgress;
}

export const reportTypes: ReportType[] = [
    'ACS',
    'POWERCHURCH',
    'SERVANTKEEPER',
    'ACSREALM',
    'SHELBYARENA',
    'SHELBYNEXT',
];
export const SET_REQUEST_REPORT_ID = 'SET_REQUEST_REPORT_ID';
export const SET_REQUEST_REPORT_URL = 'SET_REQUEST_REPORT_URL';

export const SET_REPORT_HISTORY_TOTAL_DONATION =
    'SET_REPORT_HISTORY_TOTAL_DONATION';

export const SET_INTEGRATION_MATCHING_PROGRESS =
    'SET_INTEGRATION_MATCHING_PROGRESS';

export interface SetReportHistoryTotalDonation {
    type: typeof SET_REPORT_HISTORY_TOTAL_DONATION;
    count: number;
}

export interface SetIntegrationMatchingProgress {
    type: typeof SET_INTEGRATION_MATCHING_PROGRESS;
    data: IntegrationMatchingProgress;
}

export type ReportActions =
    | SetReportHistoryTotalDonation
    | SetIntegrationMatchingProgress;

export const mapReportHistoryDataToReport = (report: ReportHistory) => {
    const mappedReport: Report = {
        id: report.id,
        requestedBy: report.requested_by,
        requestedByName: report.requested_by_name,
        fileType: report.file_type,
        fileName: report.file_name,
        reportType: report.report_type,
        reportTypeFriendlyName: report.report_friendly_name,
        reportTypeVersion: report.report_type_version,
        doneeId: report.donee_id,
        startDate: report.start_date,
        endDate: report.end_date,
        reportState: report.report_state,
        s3Key: report.s3_key,
        downloadLink: report.download_link,
        startedAt: report.started_at,
        completedAt: report.completed_at,
        totalAmount: report.total_amount,
        importedBy: report.imported_by,
        imported: report.imported,
        createdAt: report.created_at,
        totalUnmatchedDonors: report.total_unmatched_donors,
        totalDonors: report.total_donors,
        totalUnmatchedEnvelopes: report.total_unmatched_envelopes,
        totalEnvelopes: report.total_envelopes,
        lastDownloadedByName: report.last_downloaded_by_name,
        downloadedAt: report.downloaded_at,
        allCampuses: report.all_campuses,
    };

    return mappedReport;
};

export type ReportInfo = {
    data: Report;
};
