import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgArrowRightSearch = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={32}
        width={32}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 32 32"
    >
        <title>arrow-right-search</title>
        <rect fill="current" height="32" rx="16" width="32" />
        <path
            clipRule="evenodd"
            d="M17.0607 8.99994L23.7803 15.4696C24.0732 15.7625 24.0732 16.2373 23.7803 16.5302L17.0607 22.9999C16.7678 23.2928 16.2929 23.2928 16 22.9999C15.7071 22.707 15.7071 22.2322 16 21.9393L21.4393 16.7499H8.75C8.33579 16.7499 8 16.4141 8 15.9999C8 15.5857 8.33579 15.2499 8.75 15.2499L21.4393 15.2499L16 10.0606C15.7071 9.76771 15.7071 9.29284 16 8.99994C16.2929 8.70705 16.7678 8.70705 17.0607 8.99994Z"
            fill="white"
            fillRule="evenodd"
        />
    </svg>
);

export const ArrowRightSearchIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgArrowRightSearch}
        fontSize={fontSize}
    />
);
