import { createStyles, makeStyles } from '@material-ui/core';

export const progressBadgeStyles = makeStyles(() =>
    createStyles({
        circle: {
            width: '100%',
            height: '100%',
        },
        circleCover: {
            width: 160,
            height: 160,
            transform: 'rotate(270deg)',
        },
        circleCoverSm: {
            width: 125,
            height: 125,
            transform: 'rotate(270deg)',
        },
        progressValue: {
            fontSize: 36,
            lineHeight: '26px',
        },
        progressDescription: {
            fontSize: 16,
            lineHeight: '22px',
        },
    }),
);
