import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgGivelifyLogoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.3692 18.4806C11.1897 18.4806 9.28205 17.1716 8.34188 16.3976C7.00855 15.304 5.42222 13.5911 5.42222 11.6495C5.42222 9.22321 7.3094 7.39595 9.80855 7.39595C10.8991 7.39595 11.8291 7.83678 12.4684 8.25405C13.1111 7.84014 14.041 7.39931 15.0769 7.39931C15.4872 7.39931 16.735 7.39931 17.8462 8.42231C18.359 8.89679 18.3863 9.69096 17.9043 10.1924C17.4222 10.6971 16.6154 10.724 16.106 10.2496C15.7778 9.94671 15.4188 9.90296 15.0803 9.90296C14.4 9.90296 13.5761 10.5289 13.3436 10.7476L12.4239 11.6091L11.5385 10.7106C11.3162 10.4885 10.5573 9.89623 9.81197 9.89623C8.89573 9.89623 7.97265 10.4346 7.97265 11.6427C7.97265 13.2714 11.2376 15.59 12.3385 15.9366C12.5333 15.8424 12.9162 15.627 13.959 14.7285C14.4889 14.2742 15.2923 14.3247 15.7573 14.8463C16.2188 15.3679 16.1675 16.1587 15.6376 16.6164C13.6547 18.3292 12.9641 18.4806 12.3692 18.4806ZM12.4342 24.3359C5.71282 24.3359 0 18.9551 0 12.3359C0 5.72012 5.47009 0.335938 12.1915 0.335938C15.9932 0.335938 19.5077 2.03532 21.8359 4.99662C22.2667 5.54514 22.1641 6.33257 21.6068 6.75658C21.0496 7.18058 20.2496 7.07963 19.8188 6.53111C17.9795 4.18899 15.2 2.84295 12.1915 2.84295C6.87179 2.84295 2.54701 7.10318 2.54701 12.3359C2.54701 17.5721 7.11453 21.8289 12.4342 21.8289C17.8872 21.8289 21.1761 17.5148 21.1761 14.3516C20.1709 14.3584 19.1692 14.3752 18.1641 14.3752C17.4667 14.3752 16.8957 13.82 16.8889 13.1301C16.8821 12.4369 17.4496 11.8716 18.1538 11.8648C18.3214 11.8648 21.3641 11.8312 22.2974 11.8312C23.2308 11.8312 24 12.4133 24 13.5608C24 18.2653 20.1812 24.3359 12.4342 24.3359Z"
            fill="#F85520"
        />
    </svg>
);

export const GivelifyLogoIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgGivelifyLogoIcon}
        fontSize={fontSize}
    />
);
