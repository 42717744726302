import { DesignTokens } from '@givelify/ui';
import { makeStyles, Theme } from '@material-ui/core';

const LEFT_LIMIT = 15;
const RIGHT_LIMIT = 85;
export default makeStyles<Theme, { disabled: boolean; position: number }>(
    () => ({
        wrapper: {
            height: 26,
            position: 'relative',
        },
        progressTooltipContainer: {
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            paddingLeft: 4,
            paddingRight: 4,
            whiteSpace: 'nowrap',
            position: 'absolute',
            top: 2,
            backgroundColor: props =>
                props.disabled
                    ? DesignTokens.color.backgroundComponentSecondary
                    : DesignTokens.color.backgroundComponentTertiary,

            left: props =>
                `${
                    props.position < LEFT_LIMIT
                        ? 0
                        : props.position > RIGHT_LIMIT
                        ? 100
                        : props.position
                }%`,
            transform: props =>
                props.position < LEFT_LIMIT
                    ? undefined
                    : props.position > RIGHT_LIMIT
                    ? 'translate(-100%, 0)'
                    : 'translate(-50%, 0)',
        },
        envelopeProgressFooterArrow: {
            position: 'absolute',
            left: '50%',
            top: -9,
            transform: 'translate(-50%, 0)',
        },
    }),
);
