/**
 * Importing Node Modules
 */
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    progressBar: {
        backgroundColor: 'rgba(0,0,0,0)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100%',
    },
    blurOverly: {
        filter: 'blur(10px)',
        backgroundColor: 'rgba(0,0,0,0)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100%',
    },
});

// TODO: get rid of this component, use common lib component
const LoadingBar: React.FC<{
    show: boolean;
    color?: 'primary' | 'secondary';
}> = ({ show, color = 'primary' }) => {
    const { blurOverly, progressBar } = useStyles();
    return show ? (
        <>
            <LinearProgress className={blurOverly} color={color} />
            <LinearProgress className={progressBar} color={color} />
        </>
    ) : null;
};

export default LoadingBar;
