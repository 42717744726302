import { makeStyles } from '@material-ui/core';

export const inviteDoneModalStyle = makeStyles(() => ({
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
    },
}));
