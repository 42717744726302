import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { MenuItem, styled } from '@mui/material';
import { GivelifyCheckbox } from '../checkbox';
import { GivelifyRadio } from '../radio';
import { DesignTokens } from '../specify';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'block',
    padding: theme.spacing(1.5, 4),
    '&:hover': {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryHover,
    },
    '&.Mui-selected': {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryHover,
        '&:hover': {
            backgroundColor: DesignTokens.color.backgroundComponentPrimaryHover,
        },
    },
    '& .gv-menu-content': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        overflow: 'hidden',
        maxWidth: '100%',
    },
    '& .gv-menu-content-v': {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    '& .gv-menu-item-main': {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '& .gv-menu-item-text': {
        color: DesignTokens.color.textPrimary,
        fontSize: DesignTokens.textStyle.globalBody1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
        fontWeight: DesignTokens.textStyle.globalBody1.font.weight,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'inline-block',
    },
    '& .gv-menu-item-secondary-text': {
        color: DesignTokens.color.textSecondary,
        fontSize: DesignTokens.textStyle.globalBody1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
        fontWeight: DesignTokens.textStyle.globalBody1.font.weight,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'inline-block',
    },
    '& .gv-menu-l-p': {
        paddingLeft: theme.spacing(4),
    },
}));

export type GivelifyMenuItemProps = Omit<
    React.ComponentProps<typeof MenuItem>,
    'disableRipple' | 'disableGutters'
> & {
    text: string;
    secondaryText?: string;
    secondaryTextPosition?: 'horizontal' | 'vertical';
    variant?: 'default' | 'checkbox' | 'radio';
    indeterminate?: boolean;
};

export const GivelifyMenuItem: React.FC<GivelifyMenuItemProps> = ({
    text,
    secondaryText,
    secondaryTextPosition = 'horizontal',
    variant = 'default',
    selected = false,
    onClick,
    indeterminate,
    ...props
}) => {
    const onCheckboxClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (onClick)
                onClick(
                    e as unknown as React.MouseEvent<HTMLLIElement, MouseEvent>,
                );
        },
        [onClick],
    );
    return (
        <StyledMenuItem
            {...props}
            disableGutters
            disableRipple
            onClick={onClick}
            selected={selected}
        >
            <span
                className={mergeClassNames(
                    'gv-menu-content',
                    secondaryTextPosition === 'vertical' && 'gv-menu-content-v',
                )}
            >
                <span className="gv-menu-item-main">
                    {variant === 'checkbox' ? (
                        <GivelifyCheckbox
                            checked={selected}
                            indeterminate={indeterminate}
                            onClick={onCheckboxClick}
                        />
                    ) : variant === 'radio' ? (
                        <GivelifyRadio
                            checked={selected}
                            onClick={onCheckboxClick}
                        />
                    ) : null}
                    <span className="gv-menu-item-text">{text}</span>
                </span>
                {secondaryText ? (
                    <span
                        className={mergeClassNames(
                            'gv-menu-item-secondary-text',
                            variant !== 'default' &&
                                secondaryTextPosition === 'vertical' &&
                                'gv-menu-l-p',
                        )}
                    >
                        {secondaryText}
                    </span>
                ) : null}
            </span>
        </StyledMenuItem>
    );
};
