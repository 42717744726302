import { ErrorResponse } from '@givelify/givelify-ui';
import {
    LifeTimeData,
    SET_LIFE_TIME_FAILED,
    SET_LIFE_TIME_LOADING,
    SET_LIFE_TIME_SUCCESS,
    SetLifeTimeFailed,
    SetLifeTimeLoading,
    SetLifeTimeSuccess,
} from './types';

export const setLifeTimeLoading: SetLifeTimeLoading = {
    type: SET_LIFE_TIME_LOADING,
};

export const setLifeTimeSuccess = (data: LifeTimeData): SetLifeTimeSuccess => ({
    type: SET_LIFE_TIME_SUCCESS,
    data,
});

export const setLifeTimeFailed = (error: ErrorResponse): SetLifeTimeFailed => ({
    type: SET_LIFE_TIME_FAILED,
    error,
});
