import React from 'react';
import {
    GivelifyLabel,
    initialIntegrationPaginatedResponse,
    IntegrationPaginatedResponse,
    GivelifyInfiniteLoader,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { TRANSACTION_FILTER_VALUE } from 'components/filters/transactionFilter';
import { useTranslation } from 'react-i18next';
import DonationsRowTable, {
    donationsRowTableStyles,
} from '../../components/DonationsRowTable';
import NoResultsScreen from '../../components/NoResultsScreen';
import BankDepositLoading from './BankDepositLoading';
import BankDepositRow, { DonationsStatus } from './BankDepositRow';
import { getDataForInfiniteLoader } from './service';

interface BankDepositInfiniteLoaderProps {
    timeFrame: TimeFrameValue;
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    doneeId: number;
    handleOpen: (donation: DonationsStatus) => unknown;
}

export const BankDepositRowLoading = (
    <>
        <BankDepositLoading />
        <BankDepositLoading />
        <BankDepositLoading />
    </>
);

const BankDepositInfiniteLoader: React.FC<BankDepositInfiniteLoaderProps> = ({
    timeFrame,
    transactionFilter,
    doneeId,
    handleOpen,
}) => {
    const { t } = useTranslation();
    const { row } = donationsRowTableStyles();
    const copy = React.useMemo(
        () => ({
            bankDepositsError: t('error.errorBankDeposits'),
            noBankDeposits: t('error.noBankDeposits'),
        }),
        [t],
    );

    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [isLoading, setIsLoading] = React.useState(true);
    const [dataSet, setData] = React.useState<
        IntegrationPaginatedResponse<DonationsStatus>
    >(initialIntegrationPaginatedResponse);

    const [requestState, makeRequest] =
        useApiRequest<IntegrationPaginatedResponse<DonationsStatus>>();

    React.useEffect(() => {
        setPageNumber(1);
    }, [transactionFilter, timeFrame, setPageNumber]);
    React.useEffect(() => {
        if (requestState.type !== 'REQUEST_SUCCESS') return;
        const newData: IntegrationPaginatedResponse<DonationsStatus> = {
            data: requestState.response.data,
            meta: requestState.response.meta
                ? requestState.response.meta
                : {
                      count: requestState.response.data.length,
                      perPage: requestState.response.data.length.toString(),
                      currentPage: 1,
                      total: 1,
                      lastPage: 1,
                  },
        };
        if (newData.meta.currentPage === 1) {
            setData(newData);
        } else {
            setData((prevDataSet) => ({
                ...prevDataSet,
                data: [...prevDataSet.data, ...newData.data],
            }));
        }
        setIsLoading(false);
    }, [requestState]);

    const [requestData, setRequestData] = React.useState<{
        doneeId: number;
        pageNumber: number;
        timeFrame: TimeFrameValue;
        transactionFilter?: TRANSACTION_FILTER_VALUE;
    }>({
        doneeId,
        pageNumber,
        transactionFilter,
        timeFrame: timeFrame,
    });

    React.useEffect(() => {
        setRequestData({
            doneeId,
            timeFrame: timeFrame,
            transactionFilter,
            pageNumber: 1,
        });
    }, [doneeId, timeFrame, transactionFilter]);

    React.useEffect(() => {
        setRequestData((prev) => ({
            ...prev,
            pageNumber,
        }));
    }, [pageNumber]);

    React.useEffect(() => {
        setIsLoading(true);
        makeRequest(
            getDataForInfiniteLoader(
                requestData.doneeId,
                requestData.pageNumber,
                requestData.timeFrame,
                requestData.transactionFilter,
                true,
            ),
        );
    }, [
        makeRequest,
        requestData.doneeId,
        requestData.pageNumber,
        requestData.timeFrame,
        requestData.transactionFilter,
    ]);

    const Error = (
        <GivelifyLabel
            bold
            marginTop={16}
            text={copy.bankDepositsError}
            variant="heading5"
        />
    );

    const loadNextPage = () => {
        if (isLoading) return;
        setIsLoading(true);
        setPageNumber((p) => p + 1);
    };

    return (
        <GivelifyInfiniteLoader
            ErrorComponent={Error}
            LoadingComponent={BankDepositRowLoading}
            NoDataComponent={<NoResultsScreen pageName="bankDeposit" />}
            data={dataSet}
            isError={false}
            isLoading={isLoading}
            loadNextPage={loadNextPage}
            pageNumber={requestData.pageNumber}
            renderData={(
                dataSet: IntegrationPaginatedResponse<DonationsStatus>,
            ) => (
                <>
                    <DonationsRowTable />
                    {dataSet.data.map((d, i) => (
                        <BankDepositRow
                            key={i}
                            className={row}
                            donation={d}
                            onOpenDetail={handleOpen}
                        />
                    ))}
                </>
            )}
            reset={undefined}
        />
    );
};

export default React.memo(BankDepositInfiniteLoader);
