import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import DashboardMenuTile from '../DashboardMenuTile';
import Page from '../Page';
import { MenuTitleInfo } from './types';

interface Props {
    title: string;
    description: string;
    menuItems: MenuTitleInfo[];
}

function DashboardMenu(props: Props) {
    const { title, description, menuItems } = props;

    return (
        <Page useDivider addHelpNote={false} title={title}>
            <GivelifyLabel
                marginBottom={32}
                text={description}
                variant="body1"
            />

            <Grid container spacing={3}>
                {menuItems.map((item, index) => (
                    <Grid key={index} item sm={4} xs={12}>
                        <DashboardMenuTile {...item} />
                    </Grid>
                ))}
            </Grid>
        </Page>
    );
}

export default DashboardMenu;
