import React, { useMemo, useState } from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { TimeFrameValue } from '@givelify/utils';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAdvancedTranslation } from 'utils/i18n';
import { FORMAT, getFormatFilter } from '../../utils/reportFormatFilterUtils';
import ReportItemContext from './ReportItemContext';

const ReportBankDeposits: React.FC = () => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            reportName:
                t('reports.item.bankDeposits') + ' ' + t('reports.report'),
            name: t('reports.item.bankDeposits'),
            reportInclude: t('reports.include.bankDepositsInclude'),
            definition: t('reports.include.bankDeposits'),
        }),
        [t],
    );
    const [format, setFormat] = useState<string>(getFormatFilter(FORMAT.CSV));
    const onFormatFileChange = (value: boolean) => {
        if (value) {
            setFormat(getFormatFilter(FORMAT.CSV));
        } else {
            setFormat(getFormatFilter(FORMAT.EXCEL));
        }
    };

    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();
    const onSubmit = (timeFrame: TimeFrameValue) => {
        navigate(PATH.REPORTS.GENERATE_REPORTS(true), {
            state: {
                reportType: copy.reportName,
                format,
                timeFrameId: timeFrame.selector,
                startDate: timeFrame.start.format('YYYY-MM-DD'),
                endDate: timeFrame.end.format('YYYY-MM-DD'),
                report: 'RECONCILIATION',
            },
        });
    };

    return (
        <ReportItemContext
            definition={copy.definition}
            format={format}
            hasFormatFile={false}
            image={
                <GivelifyIcon size={75} variant="insititution-new-checkmark" />
            }
            name={copy.name}
            onFormatFileChange={onFormatFileChange}
            onSubmit={onSubmit}
            reportInclude={copy.reportInclude}
            reportName={copy.reportName}
            type="custom"
        />
    );
};

export default ReportBankDeposits;
