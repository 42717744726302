import {
    SetMenuItems,
    SET_MENU_ITEMS,
    SetEnabledFeatures,
    SystemState,
    SET_ENABLED_FEATURES,
    EnableFeature,
    SHOW_LOADER,
    ShowLoader,
    ToggleNestedMenuItem,
    SET_SNAP_GIVE_QR_CODES,
    TOGGLE_NESTED_MENU_ITEM,
    SET_ONLINE_GIVING_URLS,
} from './types';

export const setMenuItems = (
    menuItems: SystemState['menuItems'],
): SetMenuItems => {
    return {
        type: SET_MENU_ITEMS,
        menuItems,
    };
};

export const setEnabledFeatures = (
    enabledFeatures: EnableFeature[],
): SetEnabledFeatures => ({
    type: SET_ENABLED_FEATURES,
    enabledFeatures: enabledFeatures,
});

export const showLoader = (loading: boolean): ShowLoader => ({
    type: SHOW_LOADER,
    loading,
});

export const toggleNestedMenuItem = (id: string): ToggleNestedMenuItem => ({
    type: TOGGLE_NESTED_MENU_ITEM,
    id,
});

export const setSnapGiveQRCodes = response => ({
    type: SET_SNAP_GIVE_QR_CODES,
    qrCodes: response.qrCodes,
});

export const setOnlineGivingURLs = response => ({
    type: SET_ONLINE_GIVING_URLS,
    onlineGivingURLs: response.data,
});
