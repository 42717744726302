import React, { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';
import { I18N_NAMESPACE } from '../../../../consts';

const InfoWrapper = styled('div')(() => ({
    '& .icon': {
        textAlign: 'center',
        marginBottom: '16px',
    },
}));

export const AddressInfoWrapper: React.FC = () => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.primaryRepresentative.address.infoWrapper`,
    });

    const copy = useMemo(
        () => ({
            header: scopedTranslate('header'),
            description: scopedTranslate('description'),
        }),
        [scopedTranslate],
    );

    return (
        <InfoWrapper>
            <div className="icon">
                <GivelifyIcon fontSize={72} variant="primary-rep-colored" />
            </div>
            <GivelifyLabel
                style={{
                    marginBottom: 16,
                }}
                text={copy.header}
                variant="heading2S"
            />
            <GivelifyLabel text={copy.description} variant="body2" />
        </InfoWrapper>
    );
};
