import { GivelifyIconVariant } from '@givelify/ui';

export interface MenuItem {
    id: string;
    label: string;
    labelShort?: string;
    alternativeLable?: string;
    active?: boolean;
    pathname: string;
    url: string;
    nested?: MenuItem[];
    defaultExpanded?: boolean;
    isExpanded?: boolean;
    useLink?: boolean;
    childIsActive?: boolean;
    hideInMenu?: boolean;
    activateIfChildIsActive?: boolean;
    icon?: GivelifyIconVariant;
    hasDivider?: boolean;
    matchPaths?: string[];
    buttonId?: string;
}

export interface OnlineGivingURLsInfo {
    channel: string;
    link: string;
    type: string;
    shortLink: string;
    dynamicLink: string;
}

export interface QRCodeInfo {
    id: number;
    createdAt: string;
    updatedAt: string;
    doneeId: number;
    downloadCount: number;
    uploadCount: number;
    qrFilenameOriginal: string;
}
export interface SnapGiveDonee {
    createdAt: string;
    doneeId: number;
    id: number;
    updatedAt: string;
    qrCodes: QRCodeInfo[];
}
export interface SnapGiveDoneeResponse {
    msg: string;
    qrCodesCount: number;
    status: string;
    donee: SnapGiveDonee;
}

export interface EnableFeature {
    label: string;
    isEnabled: boolean;
}

export interface SystemState {
    menuItems: MenuItem[];
    enabledFeatures: EnableFeature[];
    loading: boolean;
    onboardingEnabled: boolean;
    yodleeEnabled: boolean;
    snapGiveEnabled: boolean;
    snapGiveQRCodes: QRCodeInfo[];
    onlineGivingURLs: OnlineGivingURLsInfo[];
}

export const SET_MENU_ITEMS = 'SYSTEM_SET_MENU_ITEMS';
export const SET_ENABLED_FEATURES = 'SET_ENABLED_FEATURES';
export const TOGGLE_NESTED_MENU_ITEM = 'TOGGLE_NESTED_MENU_ITEM';
export const SET_SNAP_GIVE_QR_CODES = 'SET_SNAP_GIVE_QR_CODES' as const;
export const SET_ONLINE_GIVING_URLS = 'SET_ONLINE_GIVING_URLS';

export interface SetMenuItems {
    type: typeof SET_MENU_ITEMS;
    menuItems: SystemState['menuItems'];
}

export interface SetEnabledFeatures {
    type: typeof SET_ENABLED_FEATURES;
    enabledFeatures: SystemState['enabledFeatures'];
}

export interface ToggleNestedMenuItem {
    type: typeof TOGGLE_NESTED_MENU_ITEM;
    id: string;
}

export const SHOW_LOADER = 'SHOW_LOADER';
export interface ShowLoader {
    type: 'SHOW_LOADER';
    loading: boolean;
}

export interface SetSnapGiveQRCodes {
    type: typeof SET_SNAP_GIVE_QR_CODES;
    qrCodes: QRCodeInfo[];
}

export interface SetOnlineGivingURLs {
    type: typeof SET_ONLINE_GIVING_URLS;
    onlineGivingURLs: OnlineGivingURLsInfo[];
}

export type SystemActions =
    | SetMenuItems
    | SetEnabledFeatures
    | SetSnapGiveQRCodes
    | SetOnlineGivingURLs
    | ShowLoader
    | ToggleNestedMenuItem;
