import React from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { Stack } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box, Description } from './styles';

export type PrimaryRepBoxProps = {
    name: string;
    onReplaceClick?: () => void;
};

export const PrimaryRepBox: React.FC<PrimaryRepBoxProps> = ({
    name,
    onReplaceClick,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            replace: t(
                'pages.settings.bank-info.customize-rep-tab.box.replace',
            ),
            status: t('pages.settings.bank-info.customize-rep-tab.box.status'),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.box.description',
            ),
            success: t(
                'pages.settings.bank-info.customize-rep-tab.statuses.success',
            ),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        <Box>
            <Stack alignItems="center" flexDirection="row">
                <GivelifyLabel
                    marginRight="auto"
                    variant={isMobile ? 'heading3S' : 'heading2S'}
                >
                    {name}
                </GivelifyLabel>
                {onReplaceClick && !isMobile ? (
                    <GivelifyButton
                        disableRipple
                        isNarrow
                        name="replace"
                        onClick={onReplaceClick}
                        text={copy.replace}
                        variant="ghost"
                    />
                ) : null}
            </Stack>
            <Stack alignItems="center" flexDirection="row" gap={0.5}>
                <GivelifyLabel variant={isMobile ? 'body2' : 'body1'}>
                    {copy.status}{' '}
                </GivelifyLabel>
                <GivelifyLabel
                    color={DesignTokens.color.textSuccessDefault}
                    variant={isMobile ? 'body2' : 'body1'}
                >
                    {copy.success}
                </GivelifyLabel>
                <GivelifyIcon fontSize={20} variant="success-box-icon" />
            </Stack>
            <Description variant="body2">{copy.description}</Description>
            {onReplaceClick && isMobile ? (
                <GivelifyButton
                    disableRipple
                    isNarrow
                    name="replace"
                    onClick={onReplaceClick}
                    text={copy.replace}
                    variant="ghost"
                />
            ) : null}
        </Box>
    );
};
