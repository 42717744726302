import { useMemo } from 'react';
import { GivelifyForm } from '@givelify/ui';
import * as yup from 'yup';
import { UseTaxIdContext } from '../TaxIdContext';
import { NonprofitEINFormSchema } from './types';
import View from './view';

const ConfirmOrgInfo = () => {
    const nonprofitEINFormSchema: yup.SchemaOf<NonprofitEINFormSchema> = yup
        .object()
        .shape({
            informationAccurate: yup.string().required(''),
        });

    const { onConfirmOrgInfoContinue, informationIsAccurate } =
        UseTaxIdContext();

    const infoAccurateDefault = useMemo(
        () =>
            informationIsAccurate === undefined
                ? ''
                : informationIsAccurate
                ? '1'
                : '0',
        [informationIsAccurate],
    );

    return (
        <GivelifyForm<NonprofitEINFormSchema>
            key="nonprofit"
            defaultValues={{
                informationAccurate: infoAccurateDefault,
            }}
            onSubmit={({ informationAccurate }, form) => {
                onConfirmOrgInfoContinue(Boolean(+informationAccurate));
            }}
            schema={nonprofitEINFormSchema}
        >
            <View />
        </GivelifyForm>
    );
};

export default ConfirmOrgInfo;
