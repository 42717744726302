import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectDepositInfo } from '../../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../../consts';
import { OnboardingModal } from '../OnboardingModal';
import AccountDetails from './AccountDetails';
import AdditionalInfo from './AdditionalInfo';

interface RequiredBankInfoProps {
    onClose: () => void;
    onSubmit: (d: DirectDepositInfo) => void;
    data: DirectDepositInfo;
    doneeId: number;
    requireAdditionalInfo: boolean;
}

export const RequiredBankInfo: React.FC<RequiredBankInfoProps> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            title: t('bankInfo.modalTitle'),
            lockText: t('directDeposit.safeAndSecure'),
            back: t('captivePortal.back'),
            cancel: t('captivePortal.cancel'),
            continue: t('captivePortal.continue'),
        }),
        [t],
    );

    return (
        <OnboardingModal
            iconVariant="bank-colored"
            lockText={copy.lockText}
            title={copy.title}
        >
            {props.requireAdditionalInfo ? (
                <AdditionalInfo
                    data={props.data}
                    doneeId={props.doneeId}
                    onClose={props.onClose}
                    onSuccess={props.onSubmit}
                />
            ) : (
                <AccountDetails {...props} />
            )}
        </OnboardingModal>
    );
};
