import React from 'react';
import { DesignTokens, GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName, useTrackingContext } from '@givelify/utils';
import { styled, useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { NEW_DONORS_PAGE_CLICK_DONOR_NAME_LINK } from 'utils/clevertapEvents';

const NameBox = styled('a')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(2),
    textDecoration: 'none',
    color: DesignTokens.color.globalGuidingViolet700,
    '& a:link': {
        color: DesignTokens.color.globalGuidingViolet700,
        textDecoration: 'none',
    },
    '& a:hover': {
        color: DesignTokens.color.globalGuidingViolet700,
        textDecoration: 'none',
    },
    '& a:active': {
        color: DesignTokens.color.globalGuidingViolet700,
        textDecoration: 'none',
    },
    '& a:visited': {
        color: DesignTokens.color.globalGuidingViolet700,
        textDecoration: 'none',
    },
    wordBreak: 'break-all',
    fontSize: DesignTokens.textStyle.globalHeadingS2.font.size,
    lineHeight: `${DesignTokens.textStyle.globalHeadingS2.font.lineHeight}px`,
    fontWeight: DesignTokens.textStyle.globalHeadingS2.font.weight,
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2B.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody2B.font.lineHeight}px`,
        fontWeight: DesignTokens.textStyle.globalBody2B.font.weight,
    },
    '& .skell': {
        width: '72%',
        height: '27px',
        [theme.breakpoints.down('mobile')]: {
            width: '90%',
        },
    },
}));

const StyledSkeleton = styled(Skeleton)({
    height: '27px',
});

export type DonorNameCellProps = {
    name: string;
    avatar?: string;
    href?: string;
    loading?: boolean;
    onClick?: (href: string) => void;
};

export const DonorNameCell: React.FC<DonorNameCellProps> = ({
    name,
    avatar,
    href,
    loading,
    onClick,
}) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { trackEvent } = useTrackingContext();
    const onClickCallback = React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            if (onClick) {
                event.preventDefault();
                trackEvent(NEW_DONORS_PAGE_CLICK_DONOR_NAME_LINK);
                onClick(href);
            }
        },
        [onClick, trackEvent, href],
    );
    if (loading) {
        return (
            <NameBox>
                {isMobile ? null : (
                    <Skeleton
                        height={isTablet ? 40 : 56}
                        variant="circular"
                        width={isTablet ? 40 : 56}
                    />
                )}
                <StyledSkeleton animation="wave" className="skell" />
            </NameBox>
        );
    }
    return (
        <NameBox href={href} onClick={onClickCallback}>
            {isMobile ? null : (
                <GivelifyAvatar
                    size={isTablet ? 'small' : 'mediumL'}
                    src={avatar}
                    text={getInitialsFromName(name)}
                />
            )}
            <span data-testid="cell-text">{name}</span>
        </NameBox>
    );
};
