import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError,
    InternalAxiosRequestConfig,
} from 'axios';
import applyCaseMiddleware, {
    ApplyCaseMiddlewareOptions,
} from 'axios-case-converter';
import axiosRetry from 'axios-retry';
import { getAccessTokenLocalStorage } from '../utils';

const startTimeRequestInterceptor = (req: InternalAxiosRequestConfig<unknown>) => {
    req.metadata = req.metadata || {};
    req.metadata.start = new Date();
    return req;
};

const setEndTimeAndDuration = (res?: AxiosResponse<unknown>) => {
    if (res?.config.metadata?.start) {
        res.config.metadata.end = new Date();

        const milliseconds =
            res.config.metadata.end.getTime() -
            res.config.metadata.start.getTime();

        res.duration = milliseconds;
    }

    return res;
};

const endTimeSuccessRequestInterceptor = (res: AxiosResponse<unknown>) => {
    setEndTimeAndDuration(res);
    return res;
};

const endTimeFailedRequestInterceptor = (err: AxiosError) => {
    setEndTimeAndDuration(err.response);
    return Promise.reject(err);
};

const unauthorizedCallbacks: (() => void)[] = [];

export const registerUnauthorizedInterceptor = (callback: () => void) => {
    unauthorizedCallbacks.push(callback);
};

interface ExtendedApplyCaseMiddlewareOptions
    extends ApplyCaseMiddlewareOptions {
    ignoreCaseTransform?: boolean;
}

export const getAxiosClient = (
    axiosConfig?: AxiosRequestConfig | undefined,
    caseMiddlewareConfig?: ExtendedApplyCaseMiddlewareOptions,
): AxiosInstance => {
    const apiUrl =
        axiosConfig && axiosConfig.baseURL
            ? axiosConfig.baseURL
            : process.env.REACT_APP_API_BASE_URL;

    if (!apiUrl) {
        throw new Error(
            `Provide baseUrl as argument in axiosConfig or set REACT_APP_API_BASE_URL in the environment`,
        );
    }

    const axiosClientRaw = axios.create({
        baseURL: new URL('api', apiUrl).toString(),
        withCredentials: true,
        ...axiosConfig,
    });

    const axiosClient = caseMiddlewareConfig?.ignoreCaseTransform
        ? axiosClientRaw
        : applyCaseMiddleware(axiosClientRaw, {
              preservedKeys: ['_method'],
              ...caseMiddlewareConfig,
          });

    axiosRetry(axiosClient);

    axiosClient.interceptors.request.use(startTimeRequestInterceptor);
    axiosClient.interceptors.response.use(
        endTimeSuccessRequestInterceptor,
        endTimeFailedRequestInterceptor,
    );

    if (unauthorizedCallbacks.length > 0) {
        axiosClient.interceptors.response.use(undefined, (error) => {
            if (error.response?.status === 401) {
                unauthorizedCallbacks.forEach((callback) => callback());
            }
            return Promise.reject(error);
        });
    }
    if (axiosConfig && !axiosConfig.withCredentials) return axiosClient;

    axiosClient.interceptors.request.use((req) => {
        if (req.headers && !req.headers.authorization) {
            const token = getAccessTokenLocalStorage();

            req.headers.authorization = token?.includes('Bearer')
                ? token
                : `Bearer ${token}`;
        }
        return req;
    });

    return axiosClient;
};
