import React, { useMemo } from 'react';
import { GivelifySelect } from '@givelify/givelify-ui';
import {
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/ui';
import { Grid } from '@material-ui/core';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { matchSorter } from 'match-sorter';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import useStyles from './styles';

const FaithLeaderInfoEditor: React.FC = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            faithLeader: t('overview.confirmContactInfoModal.faithLeader'),
            pastor: t('overview.confirmContactInfoModal.pastor'),
            allFieldsrequired: t(
                'pages.settings.users.add-new-modal.form.allRequired',
            ),
            firstName: t('pages.settings.users.add-new-modal.form.firstName'),
            lastName: t('pages.settings.users.add-new-modal.form.lastName'),
            title: t('pages.settings.users.add-new-modal.form.title'),
            email: t('pages.settings.users.add-new-modal.form.email'),
            titleExample: t(
                'pages.settings.users.add-new-modal.form.titleExample',
            ),
            willSendLink: t(
                'pages.settings.users.add-new-modal.form.willSendLink',
            ),
        }),
        [t],
    );

    const { officerTitles } = useSelector((state: AppState) => ({
        officerTitles: state.Settings.Officers.officerTitles,
    }));

    const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const formValues = watch(['firstName', 'lastName', 'email', 'title']);

    const options = useMemo(() => {
        let result = officerTitles;

        const title = formValues?.title;
        if (title?.length) {
            result = matchSorter(officerTitles, title);

            const lowerCaseItems = result.map((x) => x?.toLowerCase());
            const hasDuplicate =
                lowerCaseItems.indexOf(title?.toLowerCase()) > -1;
            if (!hasDuplicate) result = [title, ...result];
        }

        return result.map((title) => ({
            key: title,
            value: title,
        }));
    }, [officerTitles, formValues?.title]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const apiError = errors?.apiError?.message;

    return (
        <div style={{ maxWidth: 688 }}>
            {apiError && (
                <GivelifyNotification open text={apiError} variant="error" />
            )}
            <GivelifyLabel className="headingTitle" text={copy.faithLeader} />
            {mediumScreen && (
                <GivelifyLabel
                    className={'allFieldText'}
                    text={copy.allFieldsrequired}
                />
            )}
            <Grid container spacing={3}>
                <Grid item className={styles.firstNameBlock} sm={6} xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyFormTextField
                            countLength
                            id="first-name-input"
                            label={copy.firstName}
                            maxLength={55}
                            name="firstName"
                            placeholder={copy.firstName}
                        />
                    </Box>
                </Grid>
                <Grid item className={styles.lastNameBlock} sm={6} xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyFormTextField
                            countLength
                            id="last-name-input"
                            label={copy.lastName}
                            maxLength={55}
                            name="lastName"
                            placeholder={copy.lastName}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="title"
                        render={({ name, onBlur, value, onChange, ref }) => (
                            <GivelifySelect
                                freeSolo
                                fullWidth
                                ariaLabel="title"
                                className={styles.titleSelect}
                                defaultValue={value}
                                error={errors.title?.message.length > 0}
                                id="title-input"
                                inputLabel="user-title"
                                inputRef={ref}
                                label={copy.title}
                                maxLength={25}
                                name={name}
                                onBlur={onBlur}
                                onChange={(data) => {
                                    if (typeof data === 'string') {
                                        onChange(data);
                                    } else {
                                        onChange(data?.value || '');
                                    }
                                }}
                                options={options}
                                placeholder={copy.title}
                            />
                        )}
                    />
                    <div className={styles.titleSelectHelperText}>
                        <GivelifyLabel
                            className={
                                errors.title?.message && styles.textError
                            }
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={errors.title?.message || copy.titleExample}
                        />
                        <GivelifyLabel
                            className={
                                errors.title?.message && styles.textError
                            }
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={`${formValues?.title?.length || 0}/25`}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyFormTextField
                            id="email-input"
                            label={copy.email}
                            name="email"
                            placeholder={copy.email}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default FaithLeaderInfoEditor;
