import React from 'react';
import { BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import {
    ButtonPropsColorOverrides,
    ButtonPropsVariantOverrides,
    ButtonTypeMap,
    IconButton,
    IconButtonProps,
    styled,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { DesignTokens } from '../specify';
import { GivelifyButtonProps } from './types';

type CustomButtonProps = {
    isNarrow?: boolean;
    dark?: boolean;
    rivert?: boolean;
};

const getButtonPaddingBySize = (size: ButtonTypeMap['props']['size']) => {
    switch (size) {
        case 'small':
            return DesignTokens.measurement.buttonPaddingSmallX;
        case 'medium':
            return DesignTokens.measurement.buttonPaddingMediumX;
        case 'large':
        default:
            return DesignTokens.measurement.buttonPaddingLargeX;
    }
};

const ExtendedLoadingButton = styled(LoadingButton, {
    shouldForwardProp: (prop) =>
        prop !== 'isNarrow' && prop !== 'dark' && prop !== 'rivert',
})<CustomButtonProps>(({ isNarrow, size }) => ({
    paddingLeft: isNarrow
        ? DesignTokens.measurement.buttonPaddingNarrowX
        : getButtonPaddingBySize(size),
    paddingRight: isNarrow
        ? DesignTokens.measurement.buttonPaddingNarrowX
        : getButtonPaddingBySize(size),
}));

const LoadingButtonDefault = styled(ExtendedLoadingButton)({
    '& .MuiCircularProgress-svg': {
        color: DesignTokens.color.textWhite,
    },
});

const LoadingButtonPrimary = styled(ExtendedLoadingButton)<LoadingButtonProps>(
    ({ loading }) => ({
        '&.Mui-disabled': {
            color: loading ? 'transparent' : DesignTokens.color.textDisabled,
            backgroundColor: loading
                ? DesignTokens.color.backgroundButtonPrimaryDefault
                : DesignTokens.color.backgroundButtonDisabled,
        },
        '& .MuiCircularProgress-svg': {
            color: DesignTokens.color.textWhite,
        },
    }),
);

const LoadingButtonPrimaryAlt = styled(
    ExtendedLoadingButton,
)<LoadingButtonProps>(({ loading }) => ({
    color: DesignTokens.color.textWhite,
    '&.Mui-disabled': {
        color: loading ? 'transparent' : DesignTokens.color.textDisabled,
        backgroundColor: loading
            ? DesignTokens.color.backgroundButtonPrimaryAltDefault
            : DesignTokens.color.backgroundButtonDisabled,
    },
    '& .MuiCircularProgress-svg': {
        color: DesignTokens.color.textWhite,
    },
}));

const LoadingButtonDanger = styled(ExtendedLoadingButton)<LoadingButtonProps>(
    ({ loading }) => ({
        '&.Mui-disabled': {
            color: loading ? 'transparent' : DesignTokens.color.textDisabled,
            backgroundColor: loading
                ? DesignTokens.color.backgroundButtonDestructiveDefault
                : DesignTokens.color.backgroundButtonDisabled,
        },
        '& .MuiCircularProgress-svg': {
            color: DesignTokens.color.textWhite,
        },
    }),
);

const LoadingButtonSticky = styled(ExtendedLoadingButton)<LoadingButtonProps>(
    ({ loading }) => ({
        '&.Mui-disabled': {
            color: loading ? 'transparent' : DesignTokens.color.textDisabled,
            backgroundColor: loading
                ? DesignTokens.color.backgroundButtonSticky
                : DesignTokens.color.backgroundButtonDisabled,
        },
        '& .MuiCircularProgress-svg': {
            color: DesignTokens.color.textWhite,
        },
    }),
);

const LoadingButtonSecondary = styled(
    ExtendedLoadingButton,
)<LoadingButtonProps>(({ loading }) => ({
    '&.Mui-disabled': {
        color: loading ? 'transparent' : DesignTokens.color.textDisabled,
        backgroundColor: DesignTokens.color.backgroundButtonSecondaryDefault,
        borderColor: loading
            ? DesignTokens.color.borderButtonDefault
            : DesignTokens.color.borderButtonDisabled,
    },
    '& .MuiCircularProgress-svg': {
        color: DesignTokens.color.textAccentDefault,
    },
}));

const LoadingButtonDangerAlt = styled(
    ExtendedLoadingButton,
)<LoadingButtonProps>(({ loading }) => ({
    '&.Mui-disabled': {
        color: loading ? 'transparent' : DesignTokens.color.textDisabled,
        backgroundColor: DesignTokens.color.backgroundButtonSecondaryDefault,
        borderColor: loading
            ? DesignTokens.color.borderButtonDestructiveDefault
            : DesignTokens.color.borderButtonDisabled,
    },
    '& .MuiCircularProgress-svg': {
        color: DesignTokens.color.borderButtonDestructiveDefault,
    },
}));

const LoadingButtonGhost = styled(ExtendedLoadingButton)<LoadingButtonProps>(
    ({ loading, dark, rivert, disableRipple }) => ({
        '&.Mui-disabled': {
            color: loading ? 'transparent' : DesignTokens.color.textDisabled,
            backgroundColor: loading
                ? DesignTokens.color.backgroundButtonSecondaryDefault
                : DesignTokens.color.backgroundButtonDisabled,
        },
        '& .MuiCircularProgress-svg': {
            color: DesignTokens.color.textAccentDefault,
        },
        ...(disableRipple && {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }),
        ...(rivert && {
            color: DesignTokens.color.textWhite,
            '&:hover': {
                backgroundColor: DesignTokens.color.globalNeutral700,
            },
            '&:active': {
                backgroundColor: DesignTokens.color.globalNeutral600,
            },
        }),
        ...(dark && {
            color: DesignTokens.color.textPrimary,
            '&:hover': {
                backgroundColor: DesignTokens.color.globalNeutral700,
            },
            '&:active': {
                backgroundColor: DesignTokens.color.globalNeutral600,
            },
        }),
    }),
);
export const GivelifyButton = React.forwardRef<
    HTMLButtonElement,
    GivelifyButtonProps
>(
    (
        {
            text,
            variant = 'secondary',
            isNarrow,
            isLoading,
            track = true,
            ...props
        },
        ref,
    ) => {
        const { trackEvent } = useTrackingContext();
        const propsInternal = {
            ...props,
            onClick: (
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
                if (props.name && track) {
                    trackEvent(`<${props.name}>_${BUTTON_IDENTIFIER}`);
                }
                props.onClick?.(event);
            },
        };

        const buttonVariant: OverridableStringUnion<
            'text' | 'outlined' | 'contained',
            ButtonPropsVariantOverrides
        > = React.useMemo(() => {
            switch (variant) {
                case 'secondary':
                case 'danger-secondary':
                case 'warning-secondary':
                    return 'outlined';
                case 'primary':
                case 'primaryAlt':
                case 'danger':
                case 'sticky':
                case 'warning':
                    return 'contained';
                case 'ghost':
                    return 'text';
                default:
                    return 'text';
            }
        }, [variant]);
        const buttonColor: OverridableStringUnion<
            | 'inherit'
            | 'primary'
            | 'primaryAlt'
            | 'secondary'
            | 'success'
            | 'error'
            | 'info'
            | 'warning',
            ButtonPropsColorOverrides
        > = React.useMemo(() => {
            switch (variant) {
                case 'secondary':
                case 'primary':
                case 'ghost':
                    return 'primary';
                case 'ghost-rivert':
                case 'ghost-dark':
                    return 'inherit';
                case 'primaryAlt':
                    return 'primaryAlt';
                case 'warning':
                case 'warning-secondary':
                    return 'warning';
                case 'danger':
                case 'danger-secondary':
                    return 'error';
                default:
                    return 'secondary';
            }
        }, [variant]);

        if (variant === 'icon') {
            return <IconButton {...propsInternal as IconButtonProps} />;
        }
        if (variant === 'primary') {
            return (
                <LoadingButtonPrimary
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonPrimary>
            );
        }
        if (variant === 'primaryAlt') {
            return (
                <LoadingButtonPrimaryAlt
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonPrimaryAlt>
            );
        }
        if (variant === 'secondary') {
            return (
                <LoadingButtonSecondary
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonSecondary>
            );
        }
        if (variant === 'sticky') {
            return (
                <LoadingButtonSticky
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonSticky>
            );
        }
        if (
            variant === 'ghost' ||
            variant === 'ghost-rivert' ||
            variant === 'ghost-dark'
        ) {
            return (
                <LoadingButtonGhost
                    disableRipple={variant !== 'ghost'}
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    dark={variant === 'ghost-dark'}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    rivert={variant === 'ghost-rivert'}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonGhost>
            );
        }
        if (variant === 'danger') {
            return (
                <LoadingButtonDanger
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonDanger>
            );
        }
        if (variant === 'danger-secondary') {
            return (
                <LoadingButtonDangerAlt
                    {...propsInternal}
                    ref={ref}
                    color={buttonColor}
                    isNarrow={isNarrow}
                    loading={isLoading}
                    variant={buttonVariant}
                >
                    {text}
                </LoadingButtonDangerAlt>
            );
        }
        return (
            <LoadingButtonDefault
                {...propsInternal}
                ref={ref}
                color={buttonColor}
                isNarrow={isNarrow}
                loading={isLoading}
                variant={buttonVariant}
            >
                {text}
            </LoadingButtonDefault>
        );
    },
);
