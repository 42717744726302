import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { Report } from 'store/report/types';
import { PaginatedResponse } from 'types/paginationTypes';

export const getReportHistory = (
    doneeId: number,
    pageSize: string,
    pageNumber: number,
): Promise<ApiResponse<PaginatedResponse<Report>>> => {
    const url = `/donees/${doneeId}/reports?&is_report&pageSize=${pageSize}&page=${pageNumber +
        1}`;
    const httpRequest = getAxiosClient().get(url);
    return makeApiRequest<PaginatedResponse<Report>>(httpRequest);
};
