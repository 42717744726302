import React, { useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyModal } from '@givelify/givelify-ui';
import { PAGE_NAME } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useCfroContext } from '../context';
import { invitationModalsStyles } from './styles';

interface ResendInviteModalViewProps {
    email: string;
    open: boolean;
    onSubmit: () => Promise<void>;
    onClose: () => void;
}

const View: React.FC<ResendInviteModalViewProps> = ({
    email,
    open,
    onSubmit,
    onClose,
}) => {
    const styles = invitationModalsStyles();
    const { t } = useTranslation();
    const { currentStep } = useCfroContext();

    const copy = useMemo(
        () => ({
            resendInvitation: t('pages.settings.users.resendInvitation'),
            cancel: t('labels.cancel'),
            send: t('labels.send'),
            email: t('pages.settings.users.add-new-modal.form.email'),
            sendNewActivation: t('pages.settings.users.sendNewActivation', {
                emailAddress: email,
            }),
        }),
        [t, email],
    );

    const [isLoading, setIsLoading] = useState(false);

    const onOk = async () => {
        setIsLoading(true);
        await onSubmit();
    };

    const modalName = `${PAGE_NAME.CfroResendModal}_${currentStep}`;

    return (
        <GivelifyModal
            autoFullscreen
            showCloseButton
            trackPageVisit
            isLoading={isLoading}
            name={modalName}
            onClose={onClose}
            onPrimaryClick={onOk}
            onSecondaryClick={onClose}
            open={open}
            primaryButtonText={copy.send}
            secondaryButtonText={copy.cancel}
            testId="resendInvite"
        >
            <div className={styles.modalContent}>
                <GivelifyLabel
                    className={styles.header}
                    text={copy.resendInvitation}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={styles.description}
                    color="neutralGrey"
                    text={copy.sendNewActivation}
                    variant="body1"
                />
            </div>
        </GivelifyModal>
    );
};

export default View;
