import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="0 0 24 24"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>check</title>
        <path
            clipRule="evenodd"
            d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const CheckIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgCheck}
        fontSize={fontSize}
    />
);
