import { TimeFrameValue } from '@givelify/utils';
import { Dayjs } from 'dayjs';
import { ThunkDispatch } from 'redux-thunk';
import { startAndEndDateQS } from 'utils/timeFrameUtils';
import { AppState, AppActions } from '../..';
import { legacyStartAndEndDateQS } from '../donations/thunks';
import { getStatisticsBaseUrl, getStatisticsThunk } from '../thunk';
import {
    setEnvelopesCurrentLoading,
    setEnvelopesCurrentSuccess,
    setEnvelopesCurrentFailed,
} from './current/actions';
import { getGivingTrendEnvelopesStatisticsThunk } from './givingTrend/thunks';
import {
    setEnvelopesPreviousLoading,
    setEnvelopesPreviousSuccess,
    setEnvelopesPreviousFailed,
} from './previous/actions';

const reduceEnvelopeIdsToQueryParams = (envelopeIds: number[]) =>
    envelopeIds.reduce((acc, envelopeId) => {
        const param =
            typeof envelopeId === 'number'
                ? `&envelopeIds[]=${envelopeId}`
                : '';
        return acc + param;
    }, '');

export const legacyEnvelopesEndPoint = (
    timeFrame: TimeFrameValue,
    doneeId: number,
    envelopeIds?: number[],
): string => {
    const endpoint =
        getStatisticsBaseUrl('envelopes', doneeId) +
        legacyStartAndEndDateQS(timeFrame, '?') +
        (envelopeIds ? reduceEnvelopeIdsToQueryParams(envelopeIds) : '');
    return endpoint;
};

export const envelopesEndPoint = (
    timeFrame: TimeFrameValue,
    doneeId: number,
    envelopeIds?: number[],
): string => {
    const endpoint =
        getStatisticsBaseUrl('envelopes', doneeId) +
        startAndEndDateQS(timeFrame, '?') +
        (envelopeIds ? reduceEnvelopeIdsToQueryParams(envelopeIds) : '');
    return endpoint;
};

export const getEnvelopesCurrent = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setEnvelopesCurrentLoading,
        success: setEnvelopesCurrentSuccess,
        error: setEnvelopesCurrentFailed,
        url: legacyEnvelopesEndPoint(timeFrame, doneeId),
    });

export const getEnvelopesPrevious = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setEnvelopesPreviousLoading,
        success: setEnvelopesPreviousSuccess,
        error: setEnvelopesPreviousFailed,
        url: legacyEnvelopesEndPoint(timeFrame, doneeId),
    });

export const getGivingTrendEnvelopes = (
    timeframes: [Dayjs, Dayjs][],
    envelopeIds: number[],
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return getGivingTrendEnvelopesStatisticsThunk({
        timeframes,
        doneeId,
        envelopeIds,
    });
};
