import React, { useState, useEffect } from 'react';
import { GivelifyLoading } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import InfiniteScroll from 'react-infinite-scroller';
import {
    initialIntegrationPaginatedResponse,
    IntegrationPaginatedResponse,
} from '../types';
import { getDataForInfiniteLoader } from './automatedIntegrationRequest';

interface InfiniteLoaderProps<T> {
    LoadingComponent: JSX.Element;
    ErrorComponent: JSX.Element;
    ZerothComponent: JSX.Element;
    reset: true | undefined;
    onReset: () => unknown;
    reload?: boolean;
    onReloaded?: () => unknown;
    selectedPageNumber?: number;
    url: (pageNumber: number) => string;
    renderData: (
        data: IntegrationPaginatedResponse<T>,
    ) => JSX.Element | JSX.Element[];
    className?: string;
    useWindow?: boolean;
    hasData?: (value: boolean) => void;
}

const Loading = <GivelifyLoading key={0} margin={16} type="circular" />;
const AutoIntegrationInfiniteLoader = <T,>(props: InfiniteLoaderProps<T>) => {
    const { url } = props;
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [loadingSemaphore, setLoadingSemaphore] =
        useState<boolean>(undefined);
    const [dataSet, setData] = useState<IntegrationPaginatedResponse<T>>(
        initialIntegrationPaginatedResponse,
    );

    const [requestState, makeRequest] =
        useApiRequest<IntegrationPaginatedResponse<T>>();

    const increasePageNumber = () => {
        if (
            requestState.type === 'REQUEST_START' ||
            requestState.type === 'REQUEST_INIT' ||
            loadingSemaphore
        )
            return;

        setLoadingSemaphore(true);

        return setPageNumber((p) => p + 1);
    };

    useEffect(() => {
        if (
            requestState.type === 'REQUEST_SUCCESS' ||
            requestState.type === 'REQUEST_ERROR'
        ) {
            setLoadingSemaphore(false);
        }
    }, [requestState]);

    useEffect(() => {
        if (requestState.type !== 'REQUEST_SUCCESS') return;

        const data = requestState.response.data as T[];
        const newData = {
            data,
            meta: requestState.response.meta,
            pageNumbers: data.map(() => requestState.response.meta.currentPage),
        };
        if (newData.meta.currentPage === 1) {
            setData(newData);
        } else {
            setData((prevDataSet) => ({
                ...prevDataSet,
                data: [...prevDataSet.data, ...newData.data],
                pageNumbers: [
                    ...prevDataSet.pageNumbers,
                    ...newData.pageNumbers,
                ],
            }));
        }
    }, [requestState, setData]);

    useEffect(() => {
        makeRequest(getDataForInfiniteLoader(url(pageNumber)));
    }, [pageNumber, url, makeRequest]);

    useEffect(() => {
        if (!props.reload) return;

        const selectedPageNumber =
            props.selectedPageNumber >= 0 ? props.selectedPageNumber : 1;

        makeRequest(getDataForInfiniteLoader(url(selectedPageNumber)));

        setData((prevState) => ({
            ...prevState,
            data: prevState.data.slice(
                0,
                parseInt(prevState.meta.perPage) * (selectedPageNumber - 1),
            ),
        }));

        setPageNumber(selectedPageNumber);
        props.onReloaded();
        //listen only to reload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reload]);

    useEffect(() => {
        setPageNumber(1);
    }, [props.url, props.reset, setPageNumber]);

    if (requestState.type === 'REQUEST_ERROR') return props.ErrorComponent;
    if (
        (requestState.type === 'REQUEST_START' && pageNumber === 1) ||
        requestState.type === 'REQUEST_INIT'
    )
        return props.LoadingComponent;
    if (
        requestState.type === 'REQUEST_SUCCESS' ||
        (requestState.type === 'REQUEST_START' && pageNumber !== 1)
    ) {
        if (dataSet.data.length === 0) {
            if (props.hasData) props.hasData(false);
            return props.ZerothComponent;
        } else {
            if (props.hasData) props.hasData(true);
        }
        return (
            <div className={props.className}>
                <InfiniteScroll
                    hasMore={pageNumber < dataSet.meta.lastPage}
                    loadMore={increasePageNumber}
                    loader={Loading}
                    pageStart={0}
                    useWindow={props.useWindow}
                >
                    {props.renderData(dataSet)}
                </InfiniteScroll>
            </div>
        );
    }
};

export default AutoIntegrationInfiniteLoader;
