import { makeStyles, Theme } from '@material-ui/core';

export const settingsStyle = makeStyles((theme: Theme) => ({
    tabContent: {
        zIndex: 1,
        position: 'fixed',
        width: '100%',
    },
    tabWithFixedHeader: {
        marginTop: 41,
        background: theme.palette.background.paper,
        width: 'calc(100% - 254px)',
        maxWidth: 1048,
        marginLeft: '-4px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 26,
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 24px)',
            marginTop: 49,
        },
    },
    settingsTitleRow: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    settingsSecondTitle: {
        width: '100%',
    },
    settingsTitle: {
        display: 'block',
        '&:focus': {
            outline: 'none',
        },
    },
    settingsRightColumn: {
        alignSelf: 'center',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            order: 1,
            marginTop: theme.spacing(3),
            width: '100%',
        },
    },
    divider: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    showOnSm: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    hideOnSm: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    submitButtonsHolderSingle: {
        marginTop: 16,
        textAlign: 'right',
        float: 'right',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 72,
            justifyContent: 'center',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: '#FFFFFF',
        },
    },
    submitButtonsHolderMultiple: {
        marginTop: 16,
        textAlign: 'right',
        float: 'right',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    submitButton: {
        marginLeft: 24,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 'auto',
            margin: 12,
        },
    },
    submitButtonMargin: {
        margin: 12,
    },
    tabsContent: {
        marginTop: 30,
        borderBottom: '1px solid #c1c1c1',
        '& .MuiTabs-indicator': {
            height: 4,
        },
        '& .Mui-selected': {
            '& .MuiTab-wrapper': {
                color: theme.palette.primary.main,
                fontWeight: 700,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiTabs-scroller': {
                overflow: 'auto !important',
            },
            marginRight: 16,
            marginTop: 26,
        },
    },
    tabButton: {
        '&.MuiTab-root': {
            minWidth: 0,
            padding: '6px 0',
            marginRight: theme.spacing(6),
            position: 'relative',
            overflow: 'initial',
        },
    },
    descriptionBold: {
        lineHeight: '20px',
        marginBottom: 8,
    },
    fullWidth: {
        width: '100%',
        maxWidth: 1088,
        overflow: 'hidden',
    },
    titleRowWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleLabelWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));
