import React from 'react';
import { IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import closeIcon from 'assets/close-button.svg';
import {
    useStyles,
    modalContentStyles,
    ModelContentStylesProps,
} from '../../pages/login/useStyles';

interface GivelifyModalProps {
    label: string;
    isOpen: boolean;
    children: React.ReactNode;
    handleClose: () => unknown;
    modalStyles?: ModelContentStylesProps;
    className?: string;
}

const GivelifyModal: React.FC<GivelifyModalProps> = ({
    label,
    isOpen,
    modalStyles,
    handleClose,
    children,
    className,
}) => {
    const { modalContent } = modalContentStyles(modalStyles);
    const { closeModal } = useStyles();
    return (
        <Modal
            aria-labelledby={label}
            className={className}
            onClose={handleClose}
            open={isOpen}
        >
            <div className={modalContent}>
                <IconButton className={closeModal} onClick={handleClose}>
                    <img alt="Close" src={closeIcon} title="Close" />
                </IconButton>
                {children}
            </div>
        </Modal>
    );
};

export default GivelifyModal;
