import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ContinueOnDesktop from 'pages/integrations/ContinueOnMobile';
import { IntegrationContextProvider } from 'pages/integrations/IntegrationContext';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { setHideContinueOnMobileScreen } from 'store/user/actions';
import PageTitle from '../components/PageTitle';
import Space from '../components/Space';

import { AppState } from '../store';
import { PATH } from './routes';

const Loading = (
    <>
        <FacebookLoading borderRadius={0} height={69} width="100%" />
        <Space gap={4} />
        <FacebookLoading borderRadius={0} height={69} width="100%" />
        <Space gap={2} />
        <FacebookLoading borderRadius={0} height={69} width="100%" />
    </>
);

const IntegrationRoutes: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        ReportIntegration: { scheduledReport },
        hasIntegration,
        hasF1CCBIntegration,
        hideContinueOnMobileScreen,
    } = useSelector((state: AppState) => ({
        ReportIntegration: state.ReportIntegration,
        hasIntegration: !!state.ReportIntegration.scheduledReport.id,
        hasF1CCBIntegration: !!state.F1CCBIntegration.data.data,
        hideContinueOnMobileScreen: state.User.hideContinueOnMobileScreen,
    }));

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const hasAnyIntegration = hasIntegration || hasF1CCBIntegration;
    if (
        hasAnyIntegration &&
        location.pathname !== PATH.DATA.INTEGRATION_CONTENT
    ) {
        return <Navigate replace to={PATH.DATA.INTEGRATION_CONTENT} />;
    }

    if (
        !hasAnyIntegration &&
        location.pathname !== PATH.DATA.INTEGRATIONS_SETUP
    ) {
        return <Navigate replace to={PATH.DATA.INTEGRATIONS_SETUP} />;
    }

    if (isMobile && !hideContinueOnMobileScreen)
        return (
            <ContinueOnDesktop
                onContinueClick={() => {
                    dispatch(setHideContinueOnMobileScreen());
                }}
            />
        );

    if (scheduledReport.id === 0 && scheduledReport.doneeId === 0) {
        return Loading;
    }

    return <Outlet />;
};

const IntegrationRoutesWrapped = () => (
    <TrackingProvider pageName={PAGE_NAME.Integrations} trackPageVisit={false}>
        <PageTitle description="Integrations" title="Integrations" />
        <IntegrationContextProvider>
            <IntegrationRoutes />
        </IntegrationContextProvider>
    </TrackingProvider>
);

export default IntegrationRoutesWrapped;
