import React, { useMemo } from 'react';
import { FullWidthModal } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import {
    UserInfo,
    UserInfoErrorMessages,
    UserInfoErrors,
} from 'types/userTypes';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

export interface InviteNewUserModalProps {
    onInviteNewUserModalClose: () => void;
    secondAction: () => void;
    ctaSecondActionText: string;
    currentStep: number;
    onUserInfoChange: ({ name, value }) => void;
    userInfo: UserInfo;
    userInfoErrors: UserInfoErrors;
    disabledSubmit: boolean;
    errorMessage?: string;
    userInfoErrorMessages: UserInfoErrorMessages;
    onStepClick: (step: number) => void;
    isAnOfficial: boolean;
}

const InviteNewUserModal: React.FC<InviteNewUserModalProps> = (
    props: InviteNewUserModalProps,
) => {
    const {
        currentStep,
        userInfo,
        onUserInfoChange,
        userInfoErrors,
        disabledSubmit,
        onInviteNewUserModalClose,
        secondAction,
        ctaSecondActionText,
        onStepClick,
        errorMessage,
        userInfoErrorMessages,
        isAnOfficial,
    } = props;
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            inviteNewUser: t('pages.settings.users.invite-new-user'),
            inviteAnOfficial: t('pages.settings.users.invite-an-official'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const steps = { totalSteps: 2, currentStep };
    return (
        <div id="modal">
            <FullWidthModal
                open
                footer={{
                    onCancel: onInviteNewUserModalClose,
                    onSubmit: secondAction,
                    cancelText: copy.cancel,
                    submitText: ctaSecondActionText,
                    disableSubmit: disabledSubmit,
                    isLoading: false,
                }}
                heading={
                    isAnOfficial ? copy.inviteAnOfficial : copy.inviteNewUser
                }
                id="invite-new-user-modal"
                name="invite-new-user"
                onClose={onInviteNewUserModalClose}
                onStepClick={onStepClick}
                retryMessage={currentStep === 1 && errorMessage}
                steps={steps}
                testId="invite-new-user"
            >
                {currentStep === 1 && (
                    <StepOne
                        onUserInfoChange={onUserInfoChange}
                        userInfo={userInfo}
                        userInfoErrorMessages={userInfoErrorMessages}
                        userInfoErrors={userInfoErrors}
                    />
                )}
                {currentStep === 2 && (
                    <StepTwo
                        isAnOfficial={isAnOfficial}
                        onUserInfoChange={onUserInfoChange}
                        userInfo={userInfo}
                    />
                )}
            </FullWidthModal>
        </div>
    );
};

export default InviteNewUserModal;
