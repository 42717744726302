import React, { useMemo, useState } from 'react';
import { GivelifyErrorBanner, GivelifyButton } from '@givelify/givelify-ui';
import { GivelifyLabel, DesignTokens } from '@givelify/ui';
import { useAdvancedTranslation } from 'utils/i18n';
import { envelopesPageStyle } from '../../components/envelopesPageStyle';
import NoItemsPanel from '../../components/NoItemsPanel';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';
import { SortableActiveEnvelopeList } from './SortableActiveEnvelopesList';

const ActiveEnvelopesPage: React.FC = () => {
    const { envelopesContainer, hideOnSm, btnReorder, showTitle } =
        envelopesPageStyle();
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            title: at('pages.settings.envelopes2.active-envelopes-tab.title'),
            pageLoadFail: t('labels.page-load-fail'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
            showToGiver: t('pages.settings.envelopes2.showToGiver'),
            dragDescription: at('pages.settings.envelopes2.dragDescription'),
            filterBy: t('pages.settings.envelopes2.filterBy'),
            hideToGiver: t('pages.settings.envelopes2.hideToGiver'),
            shown: t('pages.settings.envelopes2.shown'),
            hidden: t('pages.settings.envelopes2.hidden'),
            reorder: t('pages.settings.envelopes2.reorder'),
        }),
        [t, at],
    );

    const { activeEnvelopes, getActiveEnvelopesRequestState } =
        useEnvelopesContext();

    const [openSortModal, setOpenSortModal] = useState<boolean>(false);
    const onOpenSortModal = () => setOpenSortModal(true);
    const onCloseSortModal = () => setOpenSortModal(false);

    return (
        <div className={envelopesContainer}>
            <div className={showTitle}>
                <GivelifyLabel
                    id="shownLabel"
                    text={copy.showToGiver}
                    variant="heading3S"
                />
                <GivelifyButton
                    className={btnReorder}
                    id="reorderHandlerMobile"
                    onClick={onOpenSortModal}
                    startIconVariant="arrange"
                    text={copy.reorder}
                    variant="ghost"
                />
            </div>
            <GivelifyLabel
                className={hideOnSm}
                color={DesignTokens.color.textSecondary}
                marginBottom={2}
                text={copy.dragDescription}
                variant="body1"
            />
            {getActiveEnvelopesRequestState === 'REQUEST_ERROR' ? (
                <GivelifyErrorBanner text={copy.pageLoadFail} />
            ) : activeEnvelopes.length === 0 ? (
                <NoItemsPanel type="shown" />
            ) : (
                <SortableActiveEnvelopeList
                    closeSortModal={onCloseSortModal}
                    isOpenSortModal={openSortModal}
                />
            )}
        </div>
    );
};

export default ActiveEnvelopesPage;
