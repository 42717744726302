// Money formatting routines
export const formatMoneyWithCommas = (money: number | string): string => {
    const valueToFormat = typeof money === 'string' ? parseFloat(money) : money;
    const formattedValue =
        valueToFormat > 99999999
            ? valueToFormat.toFixed(0)
            : valueToFormat.toFixed(2);
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatMoneyWithCommasAndDolarSign = (
    money: number | string,
): string => {
    return `$${formatMoneyWithCommas(money)}`;
};

export const formatMoney = (money: number | string) => {
    const stringMoney = money.toString();
    const formattedMoney =
        stringMoney.indexOf('.') > -1 ? stringMoney.split('.')[0] : stringMoney;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    }).format(BigInt(formattedMoney));
};

export const formatCountWithCommas = (count: number): string =>
    count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// It does not handle negative numbers, link below does
// https://stackoverflow.com/a/9461657
export const formatMoneyWithKAndM = (
    value: number | string,
): string | number => {
    const money = Number(value);

    if (!money) return value;
    return money > 999
        ? money > 999999
            ? (money / 1000000).toFixed(1) + 'M'
            : (money / 1000).toFixed(1) + 'K'
        : money;
};
