import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { givelithonLaunchModalStyle } from '../style';

export interface GivelithonProgressBarProps {
    value: number;
    goal: number;
}

const useStyle = makeStyles<unknown, { percent: number }>(() => ({
    progressInside: {
        height: ({ percent }) => `${percent > 100 ? 100 : percent}%`,
        background: 'linear-gradient(180deg, #00AC31 0%, #73C997 100%)',
    },
}));

export const GivelithonProgressBar: React.FC<GivelithonProgressBarProps> = (
    props,
) => {
    const percent = (90 * props.value) / props.goal;
    const classes = givelithonLaunchModalStyle();
    const progressClass = useStyle({ percent });
    let finalGoal;
    let goalNumber;
    if (props.goal && props.goal >= 1000) {
        let baseNum = 1000;
        let baseName = 'k';
        let baseFixed = 0;
        if (props.goal >= 1000000) {
            baseNum = 1000000;
            baseName = 'm';
            baseFixed = 0;
        }
        goalNumber = props.goal / baseNum;
        if (goalNumber % 1 === 0) {
            finalGoal = goalNumber.toFixed(baseFixed) + baseName;
        } else {
            finalGoal = parseFloat(goalNumber.toFixed(1)) + baseName;
        }
    } else if (props.goal && props.goal < 1000) {
        finalGoal = props.goal;
    } else {
        finalGoal = 0;
    }

    return (
        <div
            className={classes.progressBarContent}
            data-testid="givelithon-progress-bar"
        >
            <div className={classes.progressGoalBar}>
                <div className={classes.progressBar}>
                    <div
                        className={progressClass.progressInside}
                        data-testid="progress-inside"
                    />
                </div>
            </div>
            <div className={classes.progressGoal}>
                <GivelifyLabel
                    className={classes.goalNumber}
                    dataTestId="goal-amount"
                    id="goal-amount"
                    marginTop={12}
                    text={`$${finalGoal}`}
                    variant="heading4"
                />
                <div className={classes.goalLine} />
            </div>
        </div>
    );
};
