import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>(theme => {
    const border = `1px solid ${theme.colors.grey200}`;
    return {
        wrapper: {
            borderTop: border,
            paddingTop: 24,
            marginBottom: 24,
        },
        borderTop: {
            borderTop: border,
        },
        headerRowWrapper: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 16,
        },
        firstRowWrapper: {
            display: 'flex',
            flexDirection: 'row',
        },
        secondRowWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 48,
            marginTop: 8,
        },
        messengerContainer: { flex: 1, marginTop: 48, marginBottom: 48 },
        openDetail: {
            '& svg': {
                marginLeft: theme.spacing(2),
            },
        },
        btnDetail: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            cursor: 'pointer',
        },
        column1: {
            flex: 1,
            textAlign: 'right',
        },
        column2: {
            flex: 2,
        },
        column5: {
            flex: 5,
        },
        donationDetailsWrapper: {
            marginTop: 32,
            marginBottom: 32,
        },
    };
});
