import { TimeFrameValue } from '@givelify/utils';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DonationsStatisticType } from '../api/client/endpoints';
dayjs.extend(customParseFormat);

const customRanges = ['day', 'week', 'month', 'year', 'lifetime'] as const;

type CustomRanges = (typeof customRanges)[number];
export const getCustomRangeAmount = (
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
): CustomRanges => {
    const timeDifference = (end.valueOf() - start.valueOf()) / 1000;
    const dayInSeconds = 24 * 60 * 60;
    const weekInSeconds = 7 * 24 * 60 * 60;
    const monthInSeconds = 31 * 24 * 60 * 60;
    const yearInSeconds = 12 * monthInSeconds;

    if (timeDifference <= dayInSeconds) {
        return 'day';
    } else if (timeDifference <= weekInSeconds) {
        return 'week';
    } else if (timeDifference <= monthInSeconds) {
        return 'month';
    } else if (timeDifference <= yearInSeconds) {
        return 'year';
    } else {
        return 'lifetime';
    }
};

export const splitDateRange = (timeFrame: TimeFrameValue) => {
    return getDateTuples(
        getCustomRangeAmount(timeFrame.start, timeFrame.end),
        timeFrame.start,
        timeFrame.end,
    );
};

export const getDateLabel = (
    index: number,
    timeFrame: TimeFrameValue,
    dates: [dayjs.Dayjs, dayjs.Dayjs][],
): string => {
    if (dates[index] === undefined) {
        return '';
    }

    const rangeAmount = getCustomRangeAmount(timeFrame.start, timeFrame.end);

    switch (rangeAmount) {
        case 'day':
            return dates[index][0].format('h A');
        case 'week':
            return dates[index][0].format('dddd');
        case 'month':
            return dates[index][0].format('MMM D');
        case 'year':
            return dates[index][0].format('MMM');
        case 'lifetime':
            return dates[index][0].year().toString();
        default: {
            console.warn('Unrecognized range amount', rangeAmount);
            return dates[index][0].toString();
        }
    }
};

export const getDateTuples = (
    selector: CustomRanges,
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
): [dayjs.Dayjs, dayjs.Dayjs][] => {
    const selections: [dayjs.Dayjs, dayjs.Dayjs][] = [];

    if (selector === 'week' || selector === 'month') {
        // daily
        let dayIterator = start;
        while (dayIterator <= end) {
            selections.push([dayIterator, dayIterator]);
            dayIterator = dayIterator.add(1, 'day');
        }
    }

    if (selector === 'year') {
        // monthly
        let startDate = start.startOf('month');
        let endDate = startDate.endOf('month');

        while (startDate <= end) {
            selections.push([startDate, endDate]);
            startDate = startDate.add(1, 'month').startOf('month');
            endDate = startDate.endOf('month');
        }
    }

    if (selector === 'lifetime') {
        // yearly
        let startDate = start.startOf('year');

        while (startDate < end) {
            const endDate = startDate.endOf('year');
            selections.push([startDate, endDate]);
            startDate = startDate.add(1, 'year').startOf('year');
        }
    }

    return selections;
};

export const startAndEndDateQS = (
    timeFrame: TimeFrameValue,
    prefix: '?' | '&',
): string => {
    const startDate = timeFrame.start.tz().startOf('day');
    const endDate = timeFrame.end.tz().endOf('day');

    return timeFrame.selector !== 'lifetime'
        ? `${prefix}start=${startDate.toISOString()}&end=${endDate.toISOString()}`
        : prefix;
};

export const getRangeDateTypeCustomDate = (
    timeFrame: TimeFrameValue,
    isMobile: boolean,
): DonationsStatisticType => {
    const daysDiff = timeFrame.end.diff(timeFrame.start, 'day');

    if (daysDiff === 0) {
        return 'hourly';
    }
    if (daysDiff >= 1 && daysDiff <= (isMobile ? 14 : 30)) {
        return 'daily';
    }
    if (daysDiff >= (isMobile ? 15 : 31) && daysDiff <= 60) {
        return 'weekly';
    }
    if (daysDiff >= 61 && daysDiff < 730) {
        return 'monthly';
    }
    return 'yearly';
};

export const getRangeDateType = (
    timeframe: TimeFrameValue,
    isMobile: boolean,
): DonationsStatisticType => {
    switch (timeframe.selector) {
        case 'today':
        case 'yesterday':
            return 'hourly';
        case 'thisWeek':
        case 'lastWeek':
        case 'lastMonth':
        case 'last30Days':
            return isMobile
                ? getRangeDateTypeCustomDate(timeframe, isMobile)
                : 'daily';
        case 'thisMonth':
            return isMobile ? 'weekly' : 'daily';
        case 'thisYear':
        case 'lastYear':
        case 'last90Days':
        case 'last12Months':
            return 'monthly';
        case 'lifetime':
            return 'yearly';
        default:
            return getRangeDateTypeCustomDate(timeframe, isMobile);
    }
};
