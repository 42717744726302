import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
    GivelifyColor,
    GivelifyColorOrString,
} from '../style/givelifyDefaultTheme';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

export const loadingStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@keyframes rotation': {
            from: {
                transform: 'rotate(0deg)',
            },
            to: {
                transform: 'rotate(359deg)',
            },
        },
        '@keyframes bounce': {
            '0%': {
                transform: 'translateY(0px)',
            },
            '25%': {
                transform: 'translateY(10px)',
            },
            '50%': {
                transform: 'translateY(10px)',
            },
            '100%': {
                transform: 'translateY(0px)',
            },
        },
        loadingRing: {
            animationName: '$rotation',
            animationDuration: '2000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            transformOrigin: 'center center',
        },
        dots: {
            display: 'flex',
        },
        dot: {
            fontSize: 6,
            margin: 2,
            animationName: '$bounce',
            animationDuration: '1000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',
            transformOrigin: 'center center',
            width: 8,
            height: 8,
            borderRadius: '50%',
        },
        dot1: {
            animationDelay: '0s',
        },
        dot2: {
            animationDelay: '0.1s',
        },
        dot3: {
            animationDelay: '0.2s',
        },
        primary: {
            color: theme.palette.primary.contrastText,
        },
        secondary: {
            color: theme.palette.primary.main,
        },
        alt: {
            color: theme.colors.primaryAlt,
        },
        primaryBackground: {
            backgroundColor: theme.palette.primary.contrastText,
        },
        secondaryBackground: {
            backgroundColor: theme.palette.primary.main,
        },
        altBackground: {
            backgroundColor: theme.colors.primaryAlt,
        },
    }),
);

type GivelifyIconStyleProps = {
    size?: string | number;
    color?: GivelifyColorOrString;
} & GivelifyBoxMarginProps;

export const iconStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyIconStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyIconStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyIconStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyIconStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        icon: {
            fontSize: (props: GivelifyIconStyleProps) =>
                props.size ? props.size : '24px',
            color: (props: GivelifyIconStyleProps) => {
                if (!props.color) return '#676E77';
                if (theme.colors) {
                    const cKey: GivelifyColor = props.color as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.color;
            },
        },
        spinIcon: {
            animation: `$spinner 2s linear infinite`,
        },
        '@keyframes spinner': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '100%': {
                transform: 'rotate(360deg)',
            },
        },
    }),
);
