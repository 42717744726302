import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSquareBahamas = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="22"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 30 22"
    >
        <g clipPath="url(#clip0_4496_40368)">
            <path
                d="M29.9999 7.33338H0V0.578973C0 0.259252 0.231621 0 0.517265 0H29.4827C29.7684 0 30 0.259252 30 0.578973L29.9999 7.33338Z"
                fill="#82AFFF"
            />
            <path
                d="M29.4827 21.9998H0.517265C0.231621 21.9998 0 21.7406 0 21.4208V14.6665H29.9999V21.4208C29.9999 21.7406 29.7683 21.9998 29.4827 21.9998Z"
                fill="#82AFFF"
            />
            <path
                d="M29.9999 7.3335H0V14.6664H29.9999V7.3335Z"
                fill="#FFE15A"
            />
            <path
                d="M0.190781 21.8602L12.076 11.3002C12.2486 11.1469 12.2486 10.8534 12.076 10.7L0.190781 0.140137C0.0768163 0.246317 0 0.400176 0 0.579088V21.4211C0 21.6001 0.0768163 21.754 0.190781 21.8602Z"
                fill="#464655"
            />
        </g>
        <defs>
            <clipPath id="clip0_4496_40368">
                <rect fill="white" height="22" rx="2" width="30" />
            </clipPath>
        </defs>
    </svg>
);

export const SquareBahamasIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSquareBahamas}
        fontSize={fontSize}
    />
);
