import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { GivelifyButton } from '../button';
import { GivelifyLabel } from '../label';
import { mergeClassNames } from '../utils/classNameUtils';

type Colors = {
    background: keyof Theme['colors'];
    border: keyof Theme['colors'];
};
export type StateType = 'info' | 'success' | 'warning' | 'error';

const infoColors: Colors = {
    background: 'info100',
    border: 'info200',
};
const successColors: Colors = {
    background: 'success100',
    border: 'success200',
};
const warningColors: Colors = {
    background: 'warning100',
    border: 'warning200',
};
const errorColors: Colors = { background: 'error100', border: 'error200' };

const getColors = (variant: StateType): Colors => {
    switch (variant) {
        case 'info':
            return infoColors;
        case 'success':
            return successColors;
        case 'warning':
            return warningColors;
        case 'error':
            return errorColors;
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bannerStyles: (props: { colors: Colors; closeButton: boolean }) => ({
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.colors[props.colors.background],
            borderColor: theme.colors[props.colors.border],
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 6,
            paddingLeft: 16,
            paddingTop: 16,
            paddingBottom: 18,
            paddingRight: props.closeButton ? 2 : 16,
        }),
    }),
);

export interface GivelifyNotificationProps {
    name?: string;
    show: boolean;
    variant: StateType;
    text?: string;
    /**
     * Close icon on the right will not appear if this is undefined
     */
    onClose?: () => unknown;
    children?: React.ReactChildren | React.ReactChild;
    className?: string;
}

export const GivelifyNotification: React.FC<GivelifyNotificationProps> =
    React.memo(
        ({ name, text, variant, show, onClose, className, children }) => {
            const { bannerStyles } = useStyles({
                colors: getColors(variant),
                closeButton: onClose !== undefined,
            });
            if (!show) return null;
            return (
                <div className={mergeClassNames(bannerStyles, className)}>
                    {text ? <GivelifyLabel text={text} /> : children}
                    {onClose && (
                        <GivelifyButton
                            name={`${name}_close`}
                            iconVariant="close"
                            variant="ghost"
                            fontSize={14}
                            color="grey600"
                            padding={0}
                            minWidth="initial"
                            minHeight="initial"
                            onClick={onClose}
                        />
                    )}
                </div>
            );
        },
    );
