import { AxiosPromise } from 'axios';
import { ApiResponse } from '../responses/ApiResponse';

export async function makeApiRequest<T>(
    httpPromise: AxiosPromise,
): Promise<ApiResponse<T>> {
    try {
        const response = await httpPromise;
        if (
            response.status === 200 ||
            response.status === 204 ||
            response.status === 201
        ) {
            const body = response.data as T;
            return {
                success: true,
                data: body,
            };
        } else {
            //eslint-disable-next-line
            const resp = response as any;
            const message = resp
                ? resp.data
                    ? resp.data.message
                    : ''
                : resp.statusText;
            return {
                success: false,
                error: {
                    isAxiosError: false,
                    response: {
                        status: response.status,
                        data: {
                            message: message,
                            errors: resp?.data?.errors,
                        },
                    },
                    name: response.status.toString(),
                    message: message,
                },
            };
        }
    } catch (err) {
        //eslint-disable-next-line
        const error = err as any;
        if (error.response) {
            const resp = error.response;
            const message = resp
                ? resp.data
                    ? resp.data.message
                    : ''
                : resp.statusText;
            return {
                success: false,
                error: {
                    isAxiosError: false,
                    response: {
                        status: error.response.status,
                        data: {
                            message: message,
                            errors: resp?.data?.errors,
                        },
                    },
                    name: error.response.status.toString(),
                    message: message,
                },
            };
        } else {
            return {
                success: false,
                error: {
                    isAxiosError: true,
                    response: {
                        status: 500,
                        data: {
                            message:
                                'ERR_CONNECTION_REFUSED. Possible network problem',
                        },
                    },
                    name: '500',
                    message: 'ERR_CONNECTION_REFUSED. Possible network problem',
                },
            };
        }
    }
}
