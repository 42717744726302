import React, { ReactChildren } from 'react';
import {
    Box,
    makeStyles,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core';
import { FontWeightProperty } from '@material-ui/styles/node_modules/csstype';

const useStyle = makeStyles((theme: Theme) => ({
    borderBottom: {
        borderBottom: '1px solid ' + theme.palette.divider,
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    },
    description: {
        maxWidth: '20rem',
        minHeight: '4rem',
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    additionalInfo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

export interface IntegrationsStepProps {
    title: string;
    description: string;
    children?: ReactChildren | JSX.Element;
}

const IntegrationsStep: React.FC<IntegrationsStepProps> = (
    props: IntegrationsStepProps,
) => {
    const theme = useTheme();
    const classes = useStyle({});
    return (
        <Box flex={1} paddingX={theme.spacing(1)} textAlign="center">
            <Box paddingBottom={4}>
                <Box alignSelf="center" paddingBottom={3}>
                    {props.children}
                </Box>
                <Typography className={classes.bold} variant="subtitle2">
                    {props.title}
                </Typography>
                <Box display="flex" justifyContent="center" paddingTop={1}>
                    <Typography className={classes.description} variant="body2">
                        {props.description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default IntegrationsStep;
