import React from 'react';
import { GivelifyBox } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import Space from 'components/Space';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import NotificationItem from './NotificationItem';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'grid',
        gap: '8px',
    },
}));

const AllItemsMatchedNotification = () => {
    const classes = useStyles();

    const { notifications } = useSelector((state: AppState) => ({
        notifications: state.Integrations.allItemsMatchedNotifications,
    }));

    if (!notifications.length) return null;

    return (
        <>
            <GivelifyBox className={classes.wrapper}>
                {notifications.map(n => (
                    <NotificationItem key={n.id} item={n} />
                ))}
            </GivelifyBox>
            <Space gap={3} />
        </>
    );
};

export default AllItemsMatchedNotification;
