import { useMemo } from 'react';
import permissionTypes from 'constants/permissionTypes';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import permissionsByPath from 'utils/permissionsByPath';
import { PATH } from './routes';

const ROUTES_FEATURES = {
    [PATH.REPORTS.REPORT_DONATIONS]: Features.REPORTS_DONATIONS,
    [PATH.REPORTS.REPORT_BANK_DEPOSITS]: Features.REPORTS_BANK_DEPOSITS,
    [PATH.REPORTS.REPORT_DONORS]: Features.REPORTS_DONORS,
    [PATH.REPORTS.REPORT_DISBURSEMENTS]: Features.REPORTS_DISBURSEMENTS,
    [PATH.REPORTS.DONATION_BY_ENVELOPE]: Features.REPORTS_QUICKBOOKS_ENVELOPE,
    [PATH.REPORTS.DONATIONS_BY_DONOR]: Features.REPORTS_QUICKBOOKS_DONOR,
    [PATH.SETTINGS.APP_PROFILE]: Features.SETTINGS_APP_PROFILE,
    [PATH.SETTINGS.QUICK_GIVE]: Features.SETTINGS_QUICK_GIVE,
    [PATH.SETTINGS.ENVELOPES]: Features.SETTINGS_ENVELOPES,
    [PATH.SETTINGS.USERS]: Features.SETTINGS_USERS,
    [PATH.SETTINGS.BANK_INFO]: Features.SETTINGS_BANK_INFO,
    [PATH.SETTINGS.ORG_INFO]: Features.SETTINGS_ORGANIZATION_INFO,
    [PATH.SETTINGS.CAMPUSES]: Features.SETTINGS_CAMPUSES,
    [PATH.SETTINGS.MISSION_STATEMENT]: Features.SETTINGS_MISSION,
    [PATH.SETTINGS.ROOT]: Features.SETTINGS_ALL,
};

const useRouterBlocker = () => {
    const location = useLocation();

    const { role, enabledFeatures } = useSelector((state: AppState) => ({
        role: state.User.user && state.User.user.role,
        mainDoneeId: state.Donee.campuses ? state.Donee.campuses[0].id : null,
        enabledFeatures: state.System.enabledFeatures,
    }));

    const isBlocked = useMemo(() => {
        const path = location.pathname;
        const canAccessByRole =
            !permissionsByPath[path] ||
            permissionsByPath[path][role] !== permissionTypes.NO_ACCESS;

        const canAccessByFeature =
            !ROUTES_FEATURES[path] ||
            isFeatureEnabled(enabledFeatures, ROUTES_FEATURES[path]);

        return !canAccessByRole || !canAccessByFeature;
    }, [enabledFeatures, location.pathname, role]);

    return { isBlocked };
};

export { useRouterBlocker };
