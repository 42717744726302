import {
    requestInit,
    requestStart,
    requestSuccess,
    requestError,
} from '@givelify/givelify-ui';
import { Reducer } from 'redux';
import {
    SET_ENVELOPES_CURRENT_LOADING,
    SET_ENVELOPES_CURRENT_SUCCESS,
    SET_ENVELOPES_CURRENT_FAILED,
} from './current/types';
import {
    SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX,
    SET_INITIAL_GIVING_TREND_STATISTICS_STATE,
    SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX,
    SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX,
} from './givingTrend/types';
import {
    SET_ENVELOPES_PREVIOUS_LOADING,
    SET_ENVELOPES_PREVIOUS_SUCCESS,
    SET_ENVELOPES_PREVIOUS_FAILED,
} from './previous/types';
import { EnvelopesState, EnvelopesActions } from './types';

const initialState = {
    current: requestInit(),
    previous: requestInit(),
    givingTrend: [],
};

const EnvelopesReducer: Reducer<EnvelopesState, EnvelopesActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case SET_INITIAL_GIVING_TREND_STATISTICS_STATE:
            return {
                ...state,
                givingTrend: Array.from({ length: action.count }).map(() =>
                    requestInit(),
                ),
            };
        case SET_ENVELOPES_CURRENT_LOADING:
            return { ...state, current: requestStart() };
        case SET_ENVELOPES_PREVIOUS_LOADING:
            return { ...state, previous: requestStart() };
        case SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX:
            return {
                ...state,
                givingTrend: state.givingTrend.map((trend, index) =>
                    action.index === index ? requestStart() : trend,
                ),
            };
        case SET_ENVELOPES_CURRENT_SUCCESS:
            return { ...state, current: requestSuccess(action.data) };
        case SET_ENVELOPES_PREVIOUS_SUCCESS:
            return { ...state, previous: requestSuccess(action.data) };
        case SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX:
            return {
                ...state,
                givingTrend: state.givingTrend.map((trend, index) =>
                    index === action.index
                        ? requestSuccess(action.data)
                        : trend,
                ),
            };
        case SET_ENVELOPES_CURRENT_FAILED:
            return { ...state, current: requestError(action.error) };
        case SET_ENVELOPES_PREVIOUS_FAILED:
            return { ...state, previous: requestError(action.error) };
        case SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX:
            return {
                ...state,
                givingTrend: state.givingTrend.map((trend, index) =>
                    index === action.index ? requestError(action.error) : trend,
                ),
            };
        default:
            return state;
    }
};

export default EnvelopesReducer;
