import React, { useEffect, useMemo } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyForm,
    GivelifyFormTextField,
    GivelifyTooltip,
} from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Envelope } from 'api/models';
import { useFormContext } from 'react-hook-form';
import { useAdvancedTranslation } from 'utils/i18n';
import * as yup from 'yup';
import { useGivingTueasdayContext } from './context/GivingTuesdayContext';
import { ReactComponent as EnvelopeIconSvg } from './img/envelopeIcon.svg';

type FormData = {
    name: string;
};

const EnvelopeIcon = styled(EnvelopeIconSvg)(({ theme }) => ({
    width: 87,
    [theme.breakpoints.down('mobile')]: {
        width: 73,
    },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
    },
}));

const testDuplicatedEnvelopeName = (title: string, envelopes: Envelope[]) => {
    if (!title) return true;
    const lowerTitle = title.toLowerCase();

    const activeEnvelopeNames = envelopes.map((e) => e.name.toLowerCase());
    if (activeEnvelopeNames.indexOf(lowerTitle) >= 0) return false;

    return true;
};

const View = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { backClick } = useGivingTueasdayContext();
    const {
        formState: { isValid, isSubmitting },
    } = useFormContext();

    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'givingTuesday.createEnvelope',
    );

    const copy = useMemo(
        () => ({
            header: scopedATranslate('header'),
            description1: scopedATranslate('description1'),
            description2: scopedTranslate('description2'),
            description3: scopedTranslate('description3'),
            description4: scopedTranslate('description4'),
            namePlaceholder: scopedTranslate('namePlaceholder'),
            nameHelper: scopedATranslate('nameHelper'),
            backButtonText: scopedTranslate('backButtonText'),
            createButtonText: scopedATranslate('createButtonText'),
            tooltipText: scopedTranslate('tooltipText'),
        }),
        [scopedTranslate, scopedATranslate],
    );

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <EnvelopeIcon />
                <GivelifyLabel
                    align="center"
                    style={{
                        marginTop: 16,
                        marginBottom: 16,
                    }}
                    text={copy.header}
                    variant={isMobile ? 'body1B' : 'heading2S'}
                />
                <GivelifyLabel
                    align="center"
                    style={{
                        color: DesignTokens.color.textSecondary,
                    }}
                    variant={isMobile ? 'body2' : 'body1'}
                >
                    {`${copy.description1} `}
                    <GivelifyLabel
                        style={{
                            color: DesignTokens.color.textAccentDefault,
                            display: 'inline',
                        }}
                        variant={isMobile ? 'body2' : 'body1'}
                    >
                        <GivelifyTooltip title={copy.tooltipText}>
                            <span>{copy.description2}</span>
                        </GivelifyTooltip>
                    </GivelifyLabel>
                    {` ${copy.description3} `}
                    <GivelifyLabel variant={isMobile ? 'body2B' : 'body1B'}>
                        {copy.description4}
                    </GivelifyLabel>
                </GivelifyLabel>
            </div>
            <div
                style={{
                    marginTop: 16,
                    marginBottom: 32,
                }}
            >
                <GivelifyFormTextField<FormData>
                    countLength
                    fullWidth
                    leftHelperText={copy.nameHelper}
                    maxLength={55}
                    name="name"
                    placeholder={copy.namePlaceholder}
                />
            </div>
            <ButtonsWrapper>
                <GivelifyButton
                    onClick={backClick}
                    text={copy.backButtonText}
                    variant="ghost"
                />
                <GivelifyButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    text={copy.createButtonText}
                    type="submit"
                    variant="primary"
                />
            </ButtonsWrapper>
        </>
    );
};

const EnvelopesListScreen: React.FC = () => {
    const { allEnvelopes, createNewEnvelopeClick } = useGivingTueasdayContext();

    const { scopedATranslate } = useAdvancedTranslation(
        'givingTuesday.createEnvelope',
    );

    const copy = useMemo(
        () => ({
            nameInUse: scopedATranslate('nameInUse'),
        }),
        [scopedATranslate],
    );

    const formSchema: yup.SchemaOf<FormData> = yup.object().shape({
        name: yup
            .string()
            .test('duplicate', copy.nameInUse, (value) =>
                testDuplicatedEnvelopeName(value, allEnvelopes),
            ),
    });

    useEffect(() => {
        pushEvent('View GT23 Create a new envelope modal');
    }, []);

    const onSubmit = (data: FormData) => {
        pushEvent('Click GT23 Create a new modal Create the envelope_btn')
        return createNewEnvelopeClick(data.name);
    }

    return (
        <GivelifyForm onSubmit={onSubmit} schema={formSchema}>
            <View />
        </GivelifyForm>
    );
};

export default EnvelopesListScreen;
