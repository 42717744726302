import React from 'react';
import { PieLabelRenderProps } from 'recharts';

const RADIAN = Math.PI / 180;

export const RenderCustomizedLabel: React.FC<PieLabelRenderProps> = (props) => {
    const iRadius = Number(props.innerRadius) || 0;
    const oRadius = Number(props.outerRadius) || 0;
    const mAngle = Number(props.midAngle) || 0;
    const chartX = Number(props.cx) || 0;
    const chartY = Number(props.cy) || 0;
    let percentage = Number(props.percent);

    const radius =
        iRadius + (oRadius - iRadius) * (percentage === 1 ? 0.65 : 0.45);
    let x = chartX + radius * Math.cos(-mAngle * RADIAN);
    const y = chartY + radius * Math.sin(-mAngle * RADIAN);

    if (!percentage) return null;
    if (percentage === 1) x += 10;
    percentage *= 100;
    if (percentage < 4) return null;

    return (
        <text
            cursor="pointer"
            dominantBaseline="central"
            fill="#000000"
            pointerEvents="none"
            style={{
                fontWeight: 'bold',
                fontSize: 14,
                lineHeight: '24px',
            }}
            textAnchor="middle"
            x={x}
            y={y}
        >
            {`${percentage.toFixed(0)}%`}
        </text>
    );
};
