export const replies = [
    'donationsActivity.replies.thankYouForYourDonation',
    'donationsActivity.replies.thankYou',
    'donationsActivity.replies.yourGiftIsAppreciated',
    'donationsActivity.replies.weAppreciateYourGenerousGift',
    'donationsActivity.replies.thankYouForJoiningUsToday',
    'donationsActivity.replies.muchAppreciated',
    'donationsActivity.replies.seeYouNextTime',
];

export interface MessengerTextFieldProps {
    donationNoteId: number;
    onPressEnter: (text: string) => unknown;
}

export const ENTER_KEY_CODE = 13;
export const transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
} as const;
export const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
} as const;
