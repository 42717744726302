import React, { useMemo, useState } from 'react';
import { GivelifyForm } from '@givelify/ui';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCfroContext } from '../context';
import { UserFormData } from './types';
import View from './view';

interface EditUserContactInfoModalProps {
    ctaSecondActionText: string;
}

const EditUserContactInfoModal: React.FC<
    EditUserContactInfoModalProps
> = () => {
    const { faithLeaderInfo, onSaveUserInfo, currentStep } = useCfroContext();

    const [currentStepInternal] = useState(currentStep);

    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            emailRequired: t('error.signup.email'),
            titleRequired: t('editProfile.errorMessage.titleRequired'),
            firstNameRequired: t('editProfile.errorMessage.firstNameRequired'),
            lastNameRequired: t('editProfile.errorMessage.lastNameRequired'),
            titleLength: t('editProfile.errorMessage.titleLength'),
            firstNameLength: t('editProfile.errorMessage.firstNameLength'),
            lastNameLength: t('editProfile.errorMessage.lastNameLength'),
        }),
        [t],
    );

    const updateContactInfoChema: yup.SchemaOf<UserFormData> = yup
        .object()
        .shape({
            id: yup.number(),
            firstName: yup
                .string()
                .required(copy.firstNameRequired)
                .max(55, copy.firstNameLength),
            lastName: yup
                .string()
                .required(copy.lastNameRequired)
                .max(55, copy.lastNameLength),
            title: yup
                .string()
                .required(copy.titleRequired)
                .max(25, copy.titleLength),
            email: yup
                .string()
                .required(copy.emailRequired)
                .email(copy.emailRequired),
            role: yup.string(),
        });

    const onSubmit = async (
        data: UserFormData,
        form: UseFormMethods<UserFormData>,
    ) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form.clearErrors('apiError');

        const result = await onSaveUserInfo(data);
        if (result && result.success === false) {
            const emailError = result.error?.errors?.email;
            if (emailError) {
                form.setError('email', {
                    message: result.error.errors.email[0],
                });
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                form.setError('apiError', {
                    message: result.error.message,
                });
            }
        }
    };

    return (
        <GivelifyForm
            defaultValues={faithLeaderInfo}
            onSubmit={onSubmit}
            schema={updateContactInfoChema}
        >
            <View currentStep={currentStepInternal} onSubmit={onSubmit} />
        </GivelifyForm>
    );
};

export default EditUserContactInfoModal;
