import { toISODate } from '@givelify/utils';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from '..';
import { httpPost } from '../thunks';
import { ReportType } from './types';

export const noDoneeIdError = new Error('Donee Id not found');
export const noReportIdError = new Error('Report id not found');
export interface ReportRequestData {
    reportType: ReportType;
    doneeId: number;
    startDate: string;
    endDate: string;
    allCampuses: string;
}

export interface ReportCustomData {
    startDate: Date;
    endDate: Date;
}

/**
 * Dispatcher to Get Report
 */
export const generateCustomReport = (
    data: ReportRequestData,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return async (dispatch) => {
        data.doneeId = parseInt(data.doneeId.toString(), 10);
        data.startDate = toISODate(data.startDate);
        data.endDate = toISODate(data.endDate);
        await httpPost('/reports/generate', data, {
            caseMiddlewareConfig: {
                caseFunctions: {
                    snake: (input) => input,
                },
            },
        })(dispatch);
    };
};
