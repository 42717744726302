import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { useApiRequest, TimeFrameValues } from '@givelify/utils';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material';
import { useTimeframeFilter } from 'api/hooks';
import { GetDonationsStatisticResponse } from 'api/services/responses/donations';
import {
    getDonationService,
    getEnvelopesService,
} from 'api/utils/serviceProvider';
import { DatePickersWidget, OverviewTrendChart } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    EnvelopeDistributionData,
    DonationStatisticByEnvelopes,
} from 'store/dashboard/envelopes/types';
import { getRangeDateType } from 'utils/timeFrameUtils';
import { Features, isFeatureEnabled } from '../../utils/featureGating';
import { OverviewPoll } from './overviewPoll';
import {
    ChartsWrapper,
    Content,
    TitleWrapper,
    TrendChartWrapper,
} from './overviewStyles';
import { BarChart } from './widgets/barChartWidget';
import { PieCharts } from './widgets/pieChartsWidget';

export const OverviewMain: React.FC = () => {
    const { t } = useTranslation();
    const [timeFrame, setTimeFrame] = useTimeframeFilter(
        TimeFrameValues['thisMonth'],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const rangeDateType = getRangeDateType(timeFrame, isMobile);
    const lifetimeTimeFrame = TimeFrameValues['lifetime'];
    const [getEnvelopesDataRequest, makeGetEnvelopesDataRequest] =
        useApiRequest<EnvelopeDistributionData>();
    const [getDonationDataRequest, makeGetDonationDataRequest] =
        useApiRequest<GetDonationsStatisticResponse>();
    const [
        getDonationStatisticByEnvelopes,
        makeGetDonationStatisticByEnvelopes,
    ] = useApiRequest<DonationStatisticByEnvelopes>();

    const { donee, onboardingEnabled, onboardingCompleted, enabledFeatures } =
        useSelector((state: AppState) => ({
            donee: state.Donee.donee,
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
            enabledFeatures: state.System.enabledFeatures,
        }));
    const envelopeService = getEnvelopesService();
    const donationService = getDonationService();

    const doneeId = donee.id;

    const copy = React.useMemo(
        () => ({
            title: t('overview.title'),
            analytics: t('overview.analytics'),
        }),
        [t],
    );

    const isGivingTrendFeaturesAvailable = useMemo(() => {
        return isFeatureEnabled(
            enabledFeatures,
            Features.GIVING_TREND_CHART,
            false,
        );
    }, [enabledFeatures]);

    const isDonorsChartDrillDownEnabled = useMemo(() => {
        return isFeatureEnabled(
            enabledFeatures,
            Features.DONORS_CHART_DRILLDOWN,
            false,
        );
    }, [enabledFeatures]);

    React.useEffect(() => {
        void makeGetEnvelopesDataRequest(
            envelopeService.getEnvelopesDataByTime(timeFrame, doneeId),
        );
        void makeGetDonationDataRequest(
            donationService.getDonationsStatistic(
                doneeId,
                timeFrame.start,
                timeFrame.end,
                rangeDateType,
            ),
        );
        if (
            timeFrame.selector === 'today' ||
            timeFrame.selector === 'yesterday'
        ) {
            void makeGetDonationStatisticByEnvelopes(
                envelopeService.getDonationsStatisticByEnvelopes(
                    doneeId,
                    timeFrame.start,
                    timeFrame.end,
                    'hourly',
                ),
            );
        } else if (timeFrame.selector === 'custom') {
            if (rangeDateType === 'weekly') {
                void makeGetDonationStatisticByEnvelopes(
                    envelopeService.getDonationsStatisticByEnvelopes(
                        doneeId,
                        timeFrame.start,
                        timeFrame.end,
                        'monthly',
                    ),
                );
            } else {
                void makeGetDonationStatisticByEnvelopes(
                    envelopeService.getDonationsStatisticByEnvelopes(
                        doneeId,
                        timeFrame.start,
                        timeFrame.end,
                        rangeDateType,
                    ),
                );
            }
        } else {
            void makeGetDonationStatisticByEnvelopes(
                envelopeService.getDonationsStatisticByEnvelopes(
                    doneeId,
                    timeFrame.start,
                    timeFrame.end,
                    rangeDateType,
                ),
            );
        }

        //listen only to rangeDateType, timeFrame, doneeId changes
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeDateType, timeFrame, doneeId]);

    return (
        <Content data-testid="overview-page-content">
            <TitleWrapper>
                <DatePickersWidget
                    setTimeFrame={setTimeFrame}
                    timeFrame={timeFrame}
                    title={copy.title}
                />
            </TitleWrapper>
            <TrendChartWrapper>
                <OverviewTrendChart
                    donationsDataRequest={getDonationDataRequest}
                    rangeDateType={rangeDateType}
                    timeFrame={timeFrame}
                />
            </TrendChartWrapper>
            {!isMobile ? <OverviewPoll /> : null}
            <TitleWrapper>
                <GivelifyLabel
                    data-testid="analytics-title"
                    role="heading"
                    text={copy.analytics}
                    variant="heading2M"
                />
            </TitleWrapper>
            <ChartsWrapper>
                <PieCharts
                    doneeId={doneeId}
                    envelopesDataRequest={getEnvelopesDataRequest}
                    isDonorsChartDrillDownEnabled={
                        isDonorsChartDrillDownEnabled
                    }
                    lifetimeTimeFrame={lifetimeTimeFrame}
                    onboardingCompleted={onboardingCompleted}
                    onboardingEnabled={onboardingEnabled}
                    timeFrame={timeFrame}
                />
                <BarChart
                    donationStatisticByEnvelopesRequest={
                        getDonationStatisticByEnvelopes
                    }
                    doneeId={doneeId}
                    envelopesDataRequest={getEnvelopesDataRequest}
                    isGivingTrendFeaturesAvailable={
                        isGivingTrendFeaturesAvailable
                    }
                    onboardingCompleted={onboardingCompleted}
                    onboardingEnabled={onboardingEnabled}
                    rangeDateType={rangeDateType}
                    timeFrame={timeFrame}
                />
            </ChartsWrapper>
        </Content>
    );
};
