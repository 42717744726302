import { DesignTokens } from '@givelify/ui';
import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
    const smallScreen = theme.breakpoints.down('sm');
    const largeScreen = theme.breakpoints.up('md');

    return {
        formWrapper: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 856,
        },
        textFieldsWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            marginBottom: 40,
        },
        dateFieldsWrapper: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto 1fr',
            alignItems: 'center',
            gap: 8,
            [smallScreen]: {
                gridTemplateColumns: 'auto 1fr',
            },
        },
        dateFieldLabelWrapper: {
            marginBottom: 26,
        },
        datesWrapper: {
            display: 'flex',
            flexDirection: 'row',
            gap: 24,
            marginBottom: 24,
            marginTop: 16,
            '& > *': {
                flex: 1,
            },
            [smallScreen]: {
                flexDirection: 'column',
            },
        },
        extraMarginBottom: {
            [largeScreen]: {
                marginBottom: 2,
            },
        },
        amountControlsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 16,
            gap: 24,
            '& > *': {
                flex: 1,
                width: '100%',
            },
            [smallScreen]: {
                flexDirection: 'column',
                alignItems: 'start',
            },
        },
        radioButtonsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& > *': {
                flex: 1,
                width: '100%',
            },
            [smallScreen]: {
                flexDirection: 'column',
                alignItems: 'start',
            },
            [largeScreen]: {
                marginBottom: 24,
            },
        },
        buttonsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: 32,
            marginTop: 56,
            [smallScreen]: {
                flexDirection: 'column-reverse',
                gap: 16,
                marginTop: 40,
            },
        },
        deleteButtonWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            [largeScreen]: {
                marginBottom: 32,
            },
            [smallScreen]: {
                justifyContent: 'center',
                order: 100,
                marginTop: 32,
            },
        },
        goalHintWrapper: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5,
            marginBottom: 5,
            paddingLeft: 16,
            paddingRight: 16,
        },
        goalHintLink: {
            color: DesignTokens.color.textAccentPressed,
        },
        goalHintTooltip: {
            height: 14,
        },
        goalHelperText: {
            whiteSpace: 'pre',
        },
    };
});
