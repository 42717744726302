import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

export const givelithonMainPageStyle = makeStyles((theme: Theme) => ({
    givelithonTitle: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 20,
        },
    },
    pageName: {
        '&:focus': {
            outline: 'none',
        },
    },
    inline: {
        display: 'inline-block',
    },
    givelithonHeader: {
        marginTop: theme.spacing(3),
    },
    givelithonNav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& $sortMenu': {
                marginLeft: 'auto',
            },
        },
    },
    sortMenu: {},
    divider: {
        width: '100%',
    },
    givelithonListEnvelopes: {
        marginTop: theme.spacing(4),
    },
    givelithonSelect: {
        '& .MuiInputBase-formControl ': {
            borderRadius: 16,
            width: 160,
            '& > div': {
                lineHeight: '16px',
            },
        },
        '& input': {
            height: 32,
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'center',
            borderColor: GivelifyColorPalette.neutralGrey75,
            borderWidth: 1,
            padding: '0 40px 0 16px',
            fontSize: 16,
            '& + div': {
                right: 10,
            },
        },
    },
    notification: {
        marginBottom: '34px',
    },
    notificationBox: {
        display: 'flex',
        alignItems: 'baseline',
    },
    notificationContent: {
        flexGrow: 1,
        paddingRight: theme.spacing(2),
    },
    sortSelect: {
        '& .MuiSelect-select': {
            height: 32,
            width: 240,
            padding: '0 40px 0 16px',
            lineHeight: '32px',
        },
    },
}));
