import React from 'react';
import {
    isFailed,
    isLoading,
    isSucceeded,
    responseOrUndefined,
    useApiRequest,
    useTimeFrameEffect,
} from '@givelify/utils';
import { usePaginationFilter } from 'api/hooks';
import { DonationRow, initialPagination, Pagination } from 'api/models';
import { GetPagedDonationsByStartEndDateResponse } from 'api/services/responses/donations';
import { getDonationsService } from 'api/utils/serviceProvider';
import { useDonorProfileContext } from 'pages/newDonorProfile/DonorProfileContext';
import { useTranslation } from 'react-i18next';
import { DonationHistoryTable } from '../donationHistoryTable';
import { ExportDonations } from '../exportDonations';
import { Wrapper, Title, TableWrapper } from './styles';

const HistoryTabComponent = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            donationHistory: t('pages.donor_details.donation_history'),
        }),
        [t],
    );

    const { doneeId, donor, donorId, timeFrame, setNotification } =
        useDonorProfileContext();

    const [getDonationsRequest, makeGetDonationsRequest] =
        useApiRequest<GetPagedDonationsByStartEndDateResponse>();

    const [donations, setDonations] = React.useState<DonationRow[]>([]);
    const [pagination, setPagination] =
        React.useState<Partial<Pagination>>(initialPagination);
    const [paginationFilter, setPaginationFilter] = usePaginationFilter({
        page: 1,
    });

    const donationsService = getDonationsService();

    React.useEffect(() => {
        if (isSucceeded(getDonationsRequest)) {
            const donations = responseOrUndefined(getDonationsRequest);
            setDonations(donations.data);
            setPagination(donations.pagination);
        } else if (isFailed(getDonationsRequest)) {
            setDonations([]);
            setPagination(initialPagination);
        }
    }, [getDonationsRequest]);

    useTimeFrameEffect(
        () => {
            void makeGetDonationsRequest(
                donationsService.getDonations(
                    doneeId,
                    donorId,
                    timeFrame.start,
                    timeFrame.end,
                    paginationFilter.page,
                ),
            );
        },
        timeFrame,
        [
            doneeId,
            donorId,
            paginationFilter.page,
            makeGetDonationsRequest,
            donationsService,
        ],
    );

    const onPageChange = React.useCallback(
        (page: number) => {
            setPaginationFilter({ page });
        },
        [setPaginationFilter],
    );

    return (
        <TableWrapper
            data-testid="history-tab-content"
            isLoading={isLoading(getDonationsRequest)}
        >
            <Wrapper
                container
                alignItems="center"
                justifyContent="space-between"
            >
                <Title text={copy.donationHistory} variant="heading1S" />
                <ExportDonations
                    disabled={
                        donations.length === 0 || isLoading(getDonationsRequest)
                    }
                    doneeId={doneeId}
                    donorId={donorId}
                    setNotification={setNotification}
                    timeFrame={timeFrame}
                />
            </Wrapper>
            <DonationHistoryTable
                donationPagination={pagination}
                donations={donations}
                donor={donor}
                isLoadingDonationsPage={isLoading(getDonationsRequest)}
                onPageChange={onPageChange}
                timeFrame={timeFrame}
            />
        </TableWrapper>
    );
};

export const HistoryTab = React.memo(HistoryTabComponent);
