import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyNotification,
    GivelifySelect,
    GivelifyTextField,
    GivelifyTheme,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { ThemeProvider } from '@material-ui/styles';
import { IntegrationTypes } from 'pages/integrations/types';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { Donor } from '../../../../../../../types/donorTypes';
import {
    CreatDonorOptionsProps,
    CreateDonorForm,
    mapCreateDonorFormToData,
    OptionProps,
} from '../../types';
import {
    createNewF1Donor,
    getCreatOptions,
} from '../automatedIntegrationRequest';
import { unMatchDonorTabStyle } from '../style';

interface AutoIntegrationCreateProfileProps {
    doneeId: number;
    donorId: number;
    donor?: Donor;
    handleClose: () => void;
    integration: IntegrationTypes;
}

const AutoIntegrationCreateProfile: React.FC<
    AutoIntegrationCreateProfileProps
> = ({ doneeId, donorId, donor, handleClose, integration }) => {
    const classes = unMatchDonorTabStyle();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            createF1Profile: t('f1DonorPage.createF1Profile'),
            status: t('f1DonorPage.status'),
            memberType: t('f1DonorPage.memberType'),
            addressType: t('f1DonorPage.addressType'),
            emailType: t('f1DonorPage.emailType'),
            phoneType: t('f1DonorPage.phoneType'),
            firstName: t('f1DonorPage.firstName'),
            lastName: t('f1DonorPage.lastName'),
            address: t('f1DonorPage.address'),
            email: t('f1DonorPage.email'),
            phone: t('f1DonorPage.phone'),
            createDonorProfile: t('f1DonorPage.createDonorProfile'),
            createPlanningCenterProfile: t(
                'pages.integrations.planningCenter.createPlanningCenterProfile',
            ),
        }),
        [t],
    );

    const { isF1 } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    const [addressTypeList, setAddressTypeList] = useState<OptionProps[]>([]);
    const [emailTypeList, setEmailTypeList] = useState<OptionProps[]>([]);
    const [phoneTypeList, setPhoneTypeList] = useState<OptionProps[]>([]);
    const [memberTypeList, setMemberTypeList] = useState<OptionProps[]>([]);
    const [statusTypeList, setStatusTypeList] = useState<OptionProps[]>([]);
    const [dataType, requestDataType] = useApiRequest<CreatDonorOptionsProps>();

    useEffect(() => {
        requestDataType(getCreatOptions(doneeId));
    }, [doneeId, requestDataType]);

    useEffect(() => {
        if (dataType.type === 'REQUEST_SUCCESS') {
            setAddressTypeList(dataType.response.addressTypeOptions);
            setEmailTypeList(dataType.response.emailTypeOptions);
            setPhoneTypeList(dataType.response.phoneTypeOptions);
            setMemberTypeList(dataType.response.familyPositionOptions);
            setStatusTypeList(dataType.response.membershipStatusOptions);
        }
    }, [dataType]);

    const [statusSelected, setStatusSelected] = useState<number>();
    const [emailTypeSelected, setEmailTypeSelected] = useState<number>();
    const [phoneTypeSelected, setPhoneTypeSelected] = useState<number>();
    const [addressTypeSelected, setAddressTypeSelected] = useState<number>();
    const [memberTypeSelected, setMemberTypeSelected] = useState<number>();
    const onChangeStatus = (value) => setStatusSelected(parseInt(value.key));
    const onChangeEmail = (value) => setEmailTypeSelected(parseInt(value.key));
    const onChangePhone = (value) => setPhoneTypeSelected(parseInt(value.key));
    const onChangeAddress = (value) =>
        setAddressTypeSelected(parseInt(value.key));
    const onChangeMember = (value) =>
        setMemberTypeSelected(parseInt(value.key));

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [createDonorType, requestCreateF1Donor] = useApiRequest();

    const handleSubmit = () => {
        setErrorMessage('');
        const dataPreSubmit: CreateDonorForm = {
            givelifyDonorId: donorId,
            familyPositionId: memberTypeSelected,
            addressTypeId: addressTypeSelected,
            phoneTypeId: phoneTypeSelected,
            emailTypeId: emailTypeSelected,
            membershipStatusId: statusSelected,
        };
        const data = mapCreateDonorFormToData(dataPreSubmit);
        requestCreateF1Donor(createNewF1Donor(doneeId, data));
    };

    useEffect(() => {
        if (createDonorType.type === 'REQUEST_SUCCESS') {
            handleClose();
        } else if (createDonorType.type === 'REQUEST_ERROR') {
            setErrorMessage(createDonorType.error.message);
        }
    }, [createDonorType, handleClose]);

    const firstName = donor?.name.split(' ')[0];
    const lastName = donor?.name.split(' ')[1];

    return (
        <ThemeProvider theme={GivelifyTheme}>
            <div className={classes.createProfileContent}>
                <GivelifyLabel
                    bold
                    text={
                        isF1
                            ? copy.createF1Profile
                            : copy.createPlanningCenterProfile
                    }
                    variant="body1"
                />
                <GivelifyNotification
                    show={errorMessage.length > 0}
                    text={errorMessage}
                    variant="error"
                />
                <div className={classes.createProfileForm}>
                    <div className={classes.createProfileRow}>
                        {isF1 && (
                            <div className={classes.selectTypeCol}>
                                <GivelifySelect
                                    ariaLabel="select-status"
                                    className={classes.selectType}
                                    inputLabel="select-status"
                                    label={copy.status}
                                    onChange={onChangeStatus}
                                    options={statusTypeList.map((status) => ({
                                        key: status.id,
                                        value: status.name,
                                    }))}
                                    size="dense"
                                    width={400}
                                />
                            </div>
                        )}
                        <div
                            className={
                                isF1 ? classes.infoCol : classes.fullWidth
                            }
                        >
                            <GivelifyTextField
                                disabled
                                className={classes.createInput}
                                defaultValue={firstName}
                                id="first-name"
                                label={copy.firstName}
                                value={firstName}
                                width="100%"
                            />
                        </div>
                    </div>
                    <div className={classes.createProfileRow}>
                        {isF1 && (
                            <div className={classes.selectTypeCol}>
                                <GivelifySelect
                                    ariaLabel="select-member-type"
                                    className={classes.selectType}
                                    inputLabel="select-member-type"
                                    label={copy.memberType}
                                    onChange={onChangeMember}
                                    options={memberTypeList.map((member) => ({
                                        key: member.id,
                                        value: member.name,
                                    }))}
                                    size="dense"
                                    width={400}
                                />
                            </div>
                        )}
                        <div
                            className={
                                isF1 ? classes.infoCol : classes.fullWidth
                            }
                        >
                            <GivelifyTextField
                                disabled
                                className={classes.createInput}
                                defaultValue={lastName}
                                id="first-name"
                                label={copy.lastName}
                                value={lastName}
                            />
                        </div>
                    </div>
                    <div className={classes.createProfileRow}>
                        <div className={classes.selectTypeCol}>
                            <GivelifySelect
                                ariaLabel="select-address-type"
                                className={classes.selectType}
                                inputLabel="select-address-type"
                                label={copy.addressType}
                                onChange={onChangeAddress}
                                options={addressTypeList.map((address) => ({
                                    key: address.id,
                                    value: address.name,
                                }))}
                                size="dense"
                                width={400}
                            />
                        </div>
                        <div className={classes.infoCol}>
                            <GivelifyTextField
                                disabled
                                className={classes.createInput}
                                defaultValue={donor?.address}
                                id="first-name"
                                label={copy.address}
                                value={donor?.address}
                            />
                        </div>
                    </div>
                    <div className={classes.createProfileRow}>
                        <div className={classes.selectTypeCol}>
                            <GivelifySelect
                                ariaLabel="select-email-type"
                                className={classes.selectType}
                                inputLabel="select-email-type"
                                label={copy.emailType}
                                onChange={onChangeEmail}
                                options={emailTypeList.map((email) => ({
                                    key: email.id,
                                    value: email.name,
                                }))}
                                size="dense"
                                width={400}
                            />
                        </div>
                        <div className={classes.infoCol}>
                            <GivelifyTextField
                                disabled
                                className={classes.createInput}
                                defaultValue={donor?.email}
                                id="first-name"
                                label={copy.email}
                                value={donor?.email}
                            />
                        </div>
                    </div>
                    <div className={classes.createProfileRow}>
                        <div className={classes.selectTypeCol}>
                            <GivelifySelect
                                ariaLabel="select-phone-type"
                                className={classes.selectType}
                                inputLabel="select-phone-type"
                                label={copy.phoneType}
                                onChange={onChangePhone}
                                options={phoneTypeList.map((phone) => ({
                                    key: phone.id,
                                    value: phone.name,
                                }))}
                                size="dense"
                                width={400}
                            />
                        </div>
                        <div className={classes.infoCol}>
                            <GivelifyTextField
                                disabled
                                className={classes.createInput}
                                defaultValue={donor?.phone}
                                id="first-name"
                                label={copy.phone}
                                value={donor?.phone}
                            />
                        </div>
                    </div>
                    <div className={classes.submitProfile}>
                        <GivelifyButton
                            disabled={createDonorType.type === 'REQUEST_START'}
                            isLoading={createDonorType.type === 'REQUEST_START'}
                            onClick={handleSubmit}
                            size="large"
                            text={copy.createDonorProfile}
                            variant="primary"
                        />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default AutoIntegrationCreateProfile;
