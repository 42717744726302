import { styled } from '@mui/material';
import { DesignTokens } from '../specify';
import { GivelifyTextField } from '../textField';

export const StyledTextField = styled(GivelifyTextField)(() => ({
    '& .MuiInputLabel-root:not(.Mui-focused)': {
        color: DesignTokens.color.textSecondary,
        fontWeight: 700,
    },
    '& .MuiAutocomplete-endAdornment': {
        marginRight: '10px',
    },
    '& .MuiAutocomplete-popupIndicator': {
        transition: 'all .3s ease',
    },
}));
