import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { formatWithLocaltime, TimeFrameId } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import {
    formatMoneyWithCommas,
    formatMoneyWithKAndM,
} from 'utils/formatMoneyWithCommas';
import {
    DollarSign,
    TotalAmount,
    TotalAmountWrapper,
    InfoWrapper,
    ChartHeaderWrapper,
    DonorsLink,
    DatesText,
    TotalText,
} from './styles';

type ChartHeaderComponentProps = {
    total: number;
    donationsCount: number;
    dateStart: dayjs.Dayjs;
    dateEnd: dayjs.Dayjs;
    timeFrameSelector: TimeFrameId;
};

const ChartHeaderComponent: React.FC<ChartHeaderComponentProps> = ({
    total,
    donationsCount,
    dateStart,
    dateEnd,
    timeFrameSelector,
}) => {
    const { PATH } = useGasRouterContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { t } = useTranslation();
    const navigate = useNavigate();
    const copy = React.useMemo(
        () => ({
            totalReceived: t('overview.totalReceived'),
            donation: t('overview.donation'),
            donations: t('overview.donations'),
        }),
        [t],
    );

    return (
        <ChartHeaderWrapper>
            <TotalAmountWrapper>
                <DollarSign>$</DollarSign>
                <TotalAmount
                    data-testid="chart-total-donation"
                    text={
                        isMobile
                            ? formatMoneyWithKAndM(total)
                            : formatMoneyWithCommas(total)
                    }
                />
            </TotalAmountWrapper>
            <InfoWrapper>
                <TotalText
                    color={DesignTokens.color.textPrimary}
                    data-testid="chart-total-received"
                    variant="body1B"
                >
                    {copy.totalReceived}{' '}
                    <DonorsLink
                        name="Trend chart total received donations"
                        onClick={() => {
                            navigate(
                                PATH.DONATIONS.DONATIONS_ACTIVITY({
                                    params: {
                                        startDate:
                                            dateStart.format('YYYY-MM-DD'),
                                        endDate: dateEnd.format('YYYY-MM-DD'),
                                        timeFrame: timeFrameSelector,
                                    },
                                }),
                            );
                        }}
                        text={`${donationsCount} ${
                            donationsCount === 1
                                ? copy.donation
                                : copy.donations
                        }`}
                    />
                </TotalText>
                <DatesText
                    color={DesignTokens.color.textSecondary}
                    data-testid="chart-date-range-text"
                    variant="body1"
                >
                    {formatWithLocaltime(dateStart, 'MMM D, YYYY')} -{' '}
                    {formatWithLocaltime(dateEnd, 'MMM D, YYYY')}
                </DatesText>
            </InfoWrapper>
        </ChartHeaderWrapper>
    );
};

export const ChartHeader = React.memo(ChartHeaderComponent);
