import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="48"
        viewBox="0 0 48 48"
        width="49"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect fill="#1877F2" height="48" rx="24" width="48" x="0.5" />
        <path
            d="M33.624 31.4345L34.7123 24.699H27.9041V20.3301C27.9041 18.487 28.8534 16.6893 31.9028 16.6893H35V10.9551C35 10.9551 32.1904 10.5 29.5055 10.5C23.896 10.5 20.2329 13.7266 20.2329 19.5655V24.699H14V31.4345H20.2329V47.7179C21.4842 47.9045 22.7644 48 24.0685 48C25.3726 48 26.6528 47.9045 27.9041 47.7179V31.4345H33.624Z"
            fill="white"
        />
    </svg>
);

export const FacebookIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgFacebook} fontSize={fontSize} viewBox="0 0 48 48" />
);
