import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { useSubmittedByName } from '../hooks/useSubmittedByName';
import { VerificationCardVariantProps } from './model';
import { VerificationCard } from './verificationCard';

export const TaxIdVerification: React.FC<VerificationCardVariantProps> = (
    props,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const submittedByName = useSubmittedByName(
        props.doneeId,
        props.submittedBy,
    );
    const copy = React.useMemo(
        () => ({
            title: t('requiredInfo.taxIdVerification.title'),
            description: t('requiredInfo.taxIdVerification.description'),
            actionButtonText: t('requiredInfo.taxIdVerification.buttonText'),
            pendingDescription: t(
                'requiredInfo.taxIdVerification.pendingDescription',
            ),
            warningDescription: t(
                'requiredInfo.taxIdVerification.warningDescription',
            ),
            successDescription: t(
                'requiredInfo.taxIdVerification.successDescription',
            ),
            retryButtonText: t(
                'requiredInfo.taxIdVerification.retryButtonText',
            ),
        }),
        [t],
    );

    return (
        <VerificationCard
            actionButtonText={copy.actionButtonText}
            badgeVariant="organization"
            buttonName={props.buttonName}
            description={copy.description}
            estimatedTime={2}
            onClick={props.onClick}
            pendingDescription={copy.pendingDescription}
            retryButtonText={copy.retryButtonText}
            state={props.state ? props.state : 'unchecked'}
            submittedAt={props.submittedAt}
            submittedBy={submittedByName}
            successDescription={copy.successDescription}
            testId="taxIdCard"
            title={copy.title}
            warningDescription={copy.warningDescription}
        />
    );
};
