import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    modal: {
        textAlign: 'center',
    },
    icon: {
        fontSize: 106,
        margin: '0 auto',
    },
    text1: {
        marginTop: 17,
    },
    text2: {
        marginBottom: 17,
    },
}));

export default useStyles;
