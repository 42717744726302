import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgBank = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>bank</title>
        <path
            clipRule="evenodd"
            d="M11.7115 2.30769C11.8962 2.23077 12.1038 2.23077 12.2885 2.30769L21.2885 6.05769C21.5679 6.17414 21.75 6.44722 21.75 6.75V8.25C21.75 8.66421 21.4142 9 21 9H20.25V19.5H21C21.4142 19.5 21.75 19.8358 21.75 20.25C21.75 20.6642 21.4142 21 21 21H18.75H17.25H6.75H5.25H3C2.58579 21 2.25 20.6642 2.25 20.25C2.25 19.8358 2.58579 19.5 3 19.5H3.75V9H3C2.58579 9 2.25 8.66421 2.25 8.25V6.75C2.25 6.44722 2.43205 6.17414 2.71154 6.05769L11.7115 2.30769ZM5.25 19.5H6.75V9H5.25V19.5ZM8.25 19.5H15.75V9H8.25V19.5ZM17.25 19.5H18.75V9H17.25V19.5ZM20.25 7.5H18.75H17.25H6.75H5.25H3.75V7.25L12 3.8125L20.25 7.25V7.5ZM11.25 12.75C11.25 12.3358 11.5858 12 12 12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 13.0858 13.5 13.5 13.5C13.9142 13.5 14.25 13.1642 14.25 12.75C14.25 11.7703 13.6239 10.9369 12.75 10.628V10.5C12.75 10.0858 12.4142 9.75 12 9.75C11.5858 9.75 11.25 10.0858 11.25 10.5V10.628C10.3761 10.9369 9.75 11.7703 9.75 12.75C9.75 13.9926 10.7574 15 12 15C12.4142 15 12.75 15.3358 12.75 15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75C11.25 15.3358 10.9142 15 10.5 15C10.0858 15 9.75 15.3358 9.75 15.75C9.75 16.7297 10.3761 17.5631 11.25 17.872V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.872C13.6239 17.5631 14.25 16.7297 14.25 15.75C14.25 14.5074 13.2426 13.5 12 13.5C11.5858 13.5 11.25 13.1642 11.25 12.75Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const BankIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgBank} fontSize={fontSize} />
);
