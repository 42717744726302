import { SortDirection } from '@givelify/givelify-ui';

type CompareUsersProp = { firstName: string; lastName: string };

export const compareUsers = (
    a: CompareUsersProp,
    b: CompareUsersProp,
    sortDirection: SortDirection,
): number => {
    const fullNameA = `${a.firstName} ${a.lastName}`;
    const fullNameB = `${b.firstName} ${b.lastName}`;

    return sortDirection === 'asc'
        ? fullNameA.localeCompare(fullNameB)
        : -1 * fullNameA.localeCompare(fullNameB);
};
