import React, { useContext } from 'react';
import Portal from 'pages/settings/envelopes/Portal';
import { Context } from './MainLayoutContext';

export const MainWrapperTopSection: React.FC = ({ children }) => {
    const { topSectionDivRef } = useContext(Context);

    return (
        <Portal ref={topSectionDivRef}>
            {children}
        </Portal>
    );
};
