import React, { useEffect } from 'react';
import { useApiRequest } from '@givelify/utils';
import { LinearProgress } from '@material-ui/core';
import {
    SignInResponse,
    mapSignedUserToLocalUser,
} from 'api/services/responses/auth';
import { getAuthService } from 'api/utils/serviceProvider';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { setUser, setAccessToken } from '../../store/user/actions';

const AdminLoginComponent: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useParams<{ token: string }>();
    const dispatch = useDispatch();
    const authService = getAuthService();
    const [loginRequest, makeRequest] = useApiRequest<SignInResponse>();
    const { PATH } = useGasRouterContext();

    useEffect(() => {
        switch (loginRequest.type) {
            case 'REQUEST_INIT':
                makeRequest(authService.signInAdmin({ token }));
                break;
            case 'REQUEST_SUCCESS':
                dispatch(
                    setUser(
                        mapSignedUserToLocalUser(loginRequest.response.user),
                    ),
                );
                dispatch(setAccessToken(loginRequest.response.accessToken));
                navigate(PATH.OVERVIEW);
                break;
            default:
                break;
        }
        // listen only to token, loginRequest, dispatch, makeRequest, push
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginRequest, dispatch, makeRequest, token, navigate]);

    if (loginRequest.type === 'REQUEST_ERROR') {
        return (
            <div>
                Sorry, we are unable to verify you right now. Please contact the
                donee team - <b>{loginRequest.error.message}</b>
            </div>
        );
    }
    return <LinearProgress />;
};

export const AdminLogin = React.memo(AdminLoginComponent);
