import React, { useEffect } from 'react';
import {
    ConnectorProvider,
    SocialMediaFeatureFlag,
    SocialMediaName,
    useConnectorDispatch,
} from '@givelify/fundraising-tools';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppState } from 'store';
import { loadOnlineGivingURLs } from 'store/system/thunks';
import { setAccessToken } from 'store/user/actions';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import PageTitle from '../components/PageTitle';
import { useGasRouterContext } from './GasRouterProvider';
import { PATH as ROUTES } from './routes';

const routePaths = {
    root: ROUTES.FUNDRAISING_TOOLS.ROOT,
    socialMediaGiving: ROUTES.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING,
    socialMediaGivingFacebook:
        ROUTES.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_FACEBOOK,
    socialMediaGivingTwitter:
        ROUTES.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_TWITTER,
    socialMediaGivingInstagram:
        ROUTES.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_INSTAGRAM,
    socialMediaGivingYoutube:
        ROUTES.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_YOUTUBE,
    snapGive: ROUTES.FUNDRAISING_TOOLS.SNAP_GIVE,
    onlineGiving: ROUTES.FUNDRAISING_TOOLS.ONLINE_GIVING,
    givelifyButton: ROUTES.FUNDRAISING_TOOLS.GIVELIFY_BUTTON,
    givelithon: ROUTES.FUNDRAISING_TOOLS.GIVELITHON,
    launchGivelithon: ROUTES.FUNDRAISING_TOOLS.LAUNCH_GIVELITHON,
    notFound: ROUTES.NOT_FOUND,
};

const smFeatureFlagName: { [name in SocialMediaName]: Features } = {
    facebook: Features.SOCIAL_MEDIA_FACEBOOK,
    instagram: Features.SOCIAL_MEDIA_INSTAGRAM,
    youtube: Features.SOCIAL_MEDIA_YOUTUBE,
    twitter: Features.SOCIAL_MEDIA_TWITTER,
};

const ConnectedFTRoutes = () => {
    const dispatch = useConnectorDispatch();
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const {
        enabledFeatures,
        donee,
        snapGiveEnabled,
        snapGiveQRCodes,
        onlineGivingURLs,
    } = useSelector((state: AppState) => ({
        enabledFeatures: state.System.enabledFeatures,
        snapGiveEnabled: state.System.snapGiveEnabled,
        snapGiveQRCodes: state.System.snapGiveQRCodes,
        onlineGivingURLs: state.System.onlineGivingURLs,
        donee: state.Donee.donee,
    }));

    useEffect(() => {
        dispatch({
            type: 'setDonee',
            payload: donee,
        });
        reduxDispatch(loadOnlineGivingURLs(donee.id));
    }, [donee, dispatch, reduxDispatch]);

    React.useEffect(() => {
        const featureFlag = {};
        for (const name of Object.keys(smFeatureFlagName)) {
            featureFlag[name] = isFeatureEnabled(
                enabledFeatures,
                smFeatureFlagName[name],
            );
        }

        dispatch({
            type: 'setSocialMediaFeatureFlag',
            payload: featureFlag as SocialMediaFeatureFlag,
        });

        dispatch({
            type: 'setReduxAction',
            payload: {
                name: 'removeAccessToken',
                handler: () => reduxDispatch(setAccessToken(undefined)),
            },
        });

        dispatch({
            type: 'setReduxAction',
            payload: {
                name: 'logout',
                handler: () => navigate(ROUTES.DONEE.LOGIN),
            },
        });

        dispatch({
            type: 'setGivelithonEnabled',
            payload: isFeatureEnabled(
                enabledFeatures,
                Features.FUNDRAISING_TOOLS_GIVELITHON,
            ),
        });

        dispatch({
            type: 'setSnapGiveEnabled',
            payload:
                snapGiveEnabled &&
                isFeatureEnabled(
                    enabledFeatures,
                    Features.FUNDRAISING_TOOLS_SNAP_GIVE,
                ),
        });

        dispatch({
            type: 'setSnapGiveQRCodes',
            payload: snapGiveQRCodes,
        });

        dispatch({
            type: 'setOnlineGivingURLs',
            payload: onlineGivingURLs,
        });
    }, [
        enabledFeatures,
        snapGiveEnabled,
        snapGiveQRCodes,
        onlineGivingURLs,
        dispatch,
        reduxDispatch,
        navigate,
    ]);

    return <Outlet />;
};

const FundraisingToolsRoutes = () => {
    const userInfo = useSelector((state: AppState) => ({
        user: state.User.user,
        donee: state.Donee.donee,
        accessToken: state.User.accessToken,
    }));

    const { PATH } = useGasRouterContext();

    const routeUrls = {
        root: PATH.FUNDRAISING_TOOLS.ROOT,
        socialMediaGiving: PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING,
        socialMediaGivingFacebook:
            PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_FACEBOOK,
        socialMediaGivingTwitter:
            PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_TWITTER,
        socialMediaGivingInstagram:
            PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_INSTAGRAM,
        socialMediaGivingYoutube:
            PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING_YOUTUBE,
        snapGive: PATH.FUNDRAISING_TOOLS.SNAP_GIVE,
        onlineGiving: PATH.FUNDRAISING_TOOLS.ONLINE_GIVING,
        givelifyButton: PATH.FUNDRAISING_TOOLS.GIVELIFY_BUTTON,
        givelithon: PATH.FUNDRAISING_TOOLS.GIVELITHON,
        notFound: PATH.NOT_FOUND,
    };

    return (
        <>
            <PageTitle description="Giving Tools" title="Giving Tools" />
            <ConnectorProvider
                initialState={{ routePaths, routeUrls, ...userInfo }}
            >
                <ConnectedFTRoutes />
            </ConnectorProvider>
        </>
    );
};

export default FundraisingToolsRoutes;
