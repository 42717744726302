import './mockSetup';
import { datadogRum } from '@datadog/browser-rum';
import { Logger } from '@givelify/utils';
import * as Sentry from '@sentry/react';
import clevertap from 'clevertap-web-sdk';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom';
import {
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { Routes } from './router';
import * as serviceWorker from './serviceWorkerRegistration';
import getStore from './store';
import './i18n';
import { webConfig } from './webConfig';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
console.log(webConfig.appVersion);

if (webConfig.environment !== 'development') {
    Logger.configure({
        service: 'donee-portal-ui',
        logUnhandledExceptions: true,
        userType: Logger.UserType.GIVING_PARTNER,
    });

    Sentry.init({
        environment: webConfig.environment,
        dsn: webConfig.sentryUrl,
    });
}

clevertap.privacy.push({ optOut: false });
clevertap.privacy.push({ useIP: false });
clevertap.init(webConfig.clevertapAccountId);
clevertap.spa = true;

datadogRum.init({
    applicationId: webConfig.datadogRumApplicationId,
    clientToken: webConfig.datadogRumClientToken,
    site: 'datadoghq.com',
    service: webConfig.datadogRumService,
    env: webConfig.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: webConfig.appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

mixpanel.init(webConfig.mixpanelToken, {
    debug: false,
    track_pageview: false,
    persistence: 'localStorage',
    loaded: () => {
        window.mixpanel = {
            initialized: true,
        };
    },
});

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={
                <AppWrapper store={getStore()}>
                    <ScrollRestoration />
                    <Outlet />
                </AppWrapper>
            }
        >
            {Routes}
        </Route>,
    ),
);

ReactDOM.render(
    <RouterProvider router={router} />,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
