import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>calendar</title>
        <path
            clipRule="evenodd"
            d="M7.75 2.125a.75.75 0 0 1 .75.75v1.25h7v-1.25a.75.75 0 0 1 1.5 0v1.25h3a1.5 1.5 0 0 1 1.5 1.5v14.5a1.5 1.5 0 0 1-1.5 1.5H4a1.5 1.5 0 0 1-1.5-1.5v-14.5a1.5 1.5 0 0 1 1.5-1.5h3v-1.25a.75.75 0 0 1 .75-.75Zm7.75 3.5v.75a.75.75 0 0 0 1.5 0v-.75h3v3.002H4V5.625h3v.75a.75.75 0 0 0 1.5 0v-.75h7ZM4 10.127v9.998h16v-9.998H4Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const CalendarIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgCalendar}
        fontSize={fontSize}
    />
);
