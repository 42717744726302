import React, { useMemo } from 'react';
import { PageNotFound } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { useGasRouterContext } from 'router/GasRouterProvider';
import PageTitle from '../components/PageTitle';

const NotFound = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useTranslation();
    const { heading, text, goToOverview, goToPreviousPage } = useMemo(
        () => ({
            heading: t('error.404.heading'),
            text: t('error.404.text'),
            goToOverview: t('error.404.goToOverview'),
            goToPreviousPage: t('error.404.goToPreviousPage'),
        }),
        [t],
    );
    return (
        <>
            <PageTitle description="Page Not Found" title="Page Not Found" />
            <PageNotFound
                showReturnToPreviousButton
                descriptionText={text}
                overviewButtonText={goToOverview}
                overviewButtonUrl={PATH.OVERVIEW}
                pageNotFoundText={heading}
                returnButtonText={goToPreviousPage}
            />
        </>
    );
};

export default NotFound;
