import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyTextField, GivelifyTextFieldBaseProps } from '../textField';
import { GivelifyDropDownStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyButton } from '../button';
import { DropDownOptions } from './DropDownOptions';
import { mergeRefs } from '../utils/mergeRefs';
import { debounce, Popover } from '@material-ui/core';

interface OptionItem {
    value: string;
    label: string;
    description?: string;
}

export interface GivelifyDropDownProps extends GivelifyBoxMarginProps {
    id: string;
    ariaLabel: string;
    label?: string;
    value?: string;
    onChange?: (value: string, item: OptionItem | null) => void;
    optionsHeader?: string;
    name?: string;
    placeholder?: string;
    size?: 'large' | 'medium' | 'dense';
    state?: 'normal' | 'error' | 'warning' | 'success';
    leftHelperText?: string;
    rightHelperText?: string;
    richHelperText?: boolean;
    countLength?: boolean;
    maxLength?: number;
    defaultValue?: string;
    inputProps?: Partial<GivelifyTextFieldBaseProps>;
    options: OptionItem[];
    classes?: {
        root?: string;
        optionsRoot?: string;
        controlsRoot?: string;
        clearIcon?: string;
        arrowIcon?: string;
        clearButton?: string;
        arrowButton?: string;
        checkRoot?: string;
        checkIcon?: string;
        option?: {
            root?: string;
            checkRoot?: string;
            checkIcon?: string;
            contentRoot?: string;
            activeOption?: string;
            label?: string;
            description?: string;
        };
        input?: {
            root?: string;
            inputWrapper?: string;
            input?: string;
            helperRoot?: string;
            helperLeft?: string;
            helperRight?: string;
            sendButton?: string;
            sendIcon?: string;
            passwordButton?: string;
            endAdornmentRoot?: string;
            passwordIcon?: string;
            prefix?: string;
            label?: string;
            labelFocused?: string;
            labelFocusedRoot?: string;
            labelTop?: string;
        };
    };
    hideCheckMark?: boolean;
    className?: string;
    width?: number | 'auto' | '100%';
    optionsWidth?: number | 'auto' | '100%';
    disableAutoScroll?: boolean;
    disableKeyboardInput?: boolean;
    disableClearButton?: boolean;
    hideSelectedItem?: boolean;
}

export const GivelifyDropDown: React.FC<GivelifyDropDownProps> = props => {
    const {
        id,
        ariaLabel,
        label,
        name,
        options,
        optionsHeader,
        value,
        placeholder,
        size,
        margin,
        marginLeft,
        marginBottom,
        marginRight,
        marginTop,
        width = 'auto',
        className,
        classes,
        leftHelperText,
        defaultValue,
        richHelperText,
        rightHelperText,
        countLength,
        maxLength,
        state,
        hideCheckMark,
        inputProps,
        onChange,
        disableAutoScroll,
        disableKeyboardInput = false,
        disableClearButton = false,
        optionsWidth = width,
        hideSelectedItem,
    } = props;
    const [open, setOpen] = React.useState(false);
    const {
        boxMargin,
        drdRoot,
        drdControls,
        drdClearIcon,
        drdOpenIcon,
        drdInputPadding,
    } = GivelifyDropDownStyles({
        margin,
        marginLeft,
        marginBottom,
        marginRight,
        marginTop,
        width,
    });
    const rootClassName = mergeClassNames(
        boxMargin,
        drdRoot,
        classes ? (classes.root ? classes.root : '') : '',
        className,
    );
    const controllsClassName = mergeClassNames(
        drdControls,
        classes ? (classes.controlsRoot ? classes.controlsRoot : '') : '',
    );
    const clearButtonClassName = mergeClassNames(
        classes ? (classes.clearButton ? classes.clearButton : '') : '',
    );
    const openButtonClassName = mergeClassNames(
        classes ? (classes.arrowButton ? classes.arrowButton : '') : '',
    );
    const clearClassName = mergeClassNames(
        drdClearIcon,
        classes ? (classes.clearIcon ? classes.clearIcon : '') : '',
    );
    const openClassName = mergeClassNames(
        drdOpenIcon,
        classes ? (classes.arrowIcon ? classes.arrowIcon : '') : '',
    );
    const [menuWidth, setMenuWidth] = React.useState(optionsWidth);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState({
        value: '',
        label: '',
    });
    const [activeOptionIndex, setActiveOptionIndex] = React.useState(-1);
    const [optionsToRender, setOptionsToRender] = React.useState<
        {
            value: string;
            label: string;
            selected: boolean;
            active: boolean;
            description?: string;
        }[]
    >([]);

    const closeWithDebounce = React.useCallback(() => {
        const call = debounce(() => {
            inputRef.current?.blur();
            setOpen(false);
        }, 80);
        call();
    }, [setOpen, inputRef]);

    React.useEffect(() => {
        if (value !== undefined) {
            const selected = options.find(x => x.value === value);
            if (selected) {
                setSelectedValue(selected);
                setInputValue(selected.label);
            } else {
                setSelectedValue({
                    value: '',
                    label: '',
                });
                setInputValue('');
            }
        }
    }, [value, options, setSelectedValue, setInputValue]);

    React.useEffect(() => {
        if (defaultValue !== undefined && value === undefined) {
            const selected = options.find(x => x.value === defaultValue);
            if (selected) {
                setSelectedValue(selected);
                setInputValue(selected.label);
            } else {
                setSelectedValue({
                    value: '',
                    label: '',
                });
                setInputValue('');
            }
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        const needsFilter = inputValue && inputValue !== selectedValue.label;
        const opts = options
            .filter(
                x =>
                    (!needsFilter ||
                        x.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())) &&
                    (hideSelectedItem ? x.value !== selectedValue.value : true),
            )
            .map((item, index) => {
                return {
                    value: item.value,
                    label: item.label,
                    selected: item.value === selectedValue.value,
                    active: activeOptionIndex === index,
                    description: item.description,
                };
            });
        setOptionsToRender(opts);
    }, [
        options,
        selectedValue,
        setOptionsToRender,
        setActiveOptionIndex,
        activeOptionIndex,
        inputValue,
        hideSelectedItem
    ]);
    React.useEffect(() => {
        if (!open) {
            if (activeOptionIndex < 0) {
                //enter was on input
                if (inputValue !== selectedValue.label) {
                    if (inputValue === '') {
                        setSelectedValue({
                            value: '',
                            label: '',
                        });
                    } else {
                        setInputValue(selectedValue.label);
                    }
                }
            } else {
                //enter was on option
                setInputValue(selectedValue.label);
                setActiveOptionIndex(-1);
            }
        }
        // eslint-disable-next-line
    }, [open, setInputValue, setActiveOptionIndex]);
    const onItemSelect = (value: string) => {
        const selected = options.find(x => x.value === value);
        if (selected) {
            if (onChange) {
                onChange(selected.value, selected);
            } else {
                setSelectedValue(selected);
                setInputValue(selected.label);
            }
        } else {
            if (onChange) {
                onChange('', null);
            } else {
                setSelectedValue({ value: '', label: '' });
                setInputValue('');
            }
        }
        closeWithDebounce();
    };
    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setOpen(true);
        if (inputProps && inputProps.onFocus) {
            inputProps.onFocus(event);
        }
    };
    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'ArrowUp') {
                let nextActive = activeOptionIndex;
                if (nextActive < 0) {
                    nextActive = 0;
                    if (selectedValue.value) {
                        for (
                            let index = 0;
                            index < optionsToRender.length;
                            index++
                        ) {
                            if (optionsToRender[index].selected) {
                                nextActive = index;
                                break;
                            }
                        }
                    }
                }
                nextActive =
                    (nextActive - 1 + optionsToRender.length) %
                    optionsToRender.length;
                setActiveOptionIndex(nextActive);
            } else if (event.key === 'ArrowDown') {
                let nextActive = activeOptionIndex;
                if (nextActive < 0) {
                    nextActive = -1;
                    if (selectedValue.value) {
                        for (
                            let index = 0;
                            index < optionsToRender.length;
                            index++
                        ) {
                            if (optionsToRender[index].selected) {
                                nextActive = index;
                                break;
                            }
                        }
                    }
                }
                nextActive = (nextActive + 1) % optionsToRender.length;
                setActiveOptionIndex(nextActive);
            } else if (event.key === 'Enter') {
                if (activeOptionIndex < 0) return;
                const selectedItem = optionsToRender[activeOptionIndex];
                if (onChange) {
                    onChange(selectedItem.value, {
                        value: selectedItem.value,
                        label: selectedItem.label,
                        description: selectedItem.description,
                    });
                } else {
                    setSelectedValue({
                        value: selectedItem.value,
                        label: selectedItem.label,
                    });
                }

                closeWithDebounce();
            }
        },
        [
            activeOptionIndex,
            optionsToRender,
            selectedValue,
            setActiveOptionIndex,
            setSelectedValue,
            onChange,
            closeWithDebounce,
        ],
    );
    const onInputChange = React.useCallback(
        (_event, value?: string) => {
            if (disableKeyboardInput) return;
            if (value) {
                setInputValue(value);
            } else {
                setInputValue('');
            }
        },
        [setInputValue, disableKeyboardInput],
    );

    const toggleOpen = React.useCallback(() => {
        if (open) {
            setOpen(false);
            inputRef.current?.blur();
        } else {
            inputRef.current?.focus();
        }
    }, [open, inputRef]);

    const clearContent = React.useCallback(() => {
        if (onChange) {
            onChange('', null);
        } else {
            setSelectedValue({ value: '', label: '' });
            setInputValue('');
        }
        closeWithDebounce();
    }, [setSelectedValue, setInputValue, onChange, closeWithDebounce]);
    const onMenuClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    React.useEffect(() => {
        if (optionsWidth == width && inputRef && inputRef.current) {
            if (width === '100%') {
                const rect = inputRef.current.getBoundingClientRect();
                setMenuWidth(rect.width);
            } else if (width !== 'auto') {
                setMenuWidth(width);
            }
        }
    }, [inputRef, width, optionsWidth, setMenuWidth]);
    return (
        <div id={`${id}-select`} className={rootClassName}>
            <GivelifyTextField
                {...inputProps}
                id={id}
                name={name}
                ariaLabel={ariaLabel}
                label={label}
                value={inputValue}
                onChange={onInputChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                inputRef={
                    inputProps?.inputRef
                        ? mergeRefs([inputRef, inputProps?.inputRef])
                        : inputRef
                }
                boxSize={size}
                placeholder={placeholder}
                leftHelperText={leftHelperText}
                richHelperText={richHelperText}
                rightHelperText={rightHelperText}
                countLength={countLength}
                maxLength={maxLength}
                state={state}
                classes={
                    classes?.input
                        ? {
                              ...classes?.input,
                              input: mergeClassNames(
                                  drdInputPadding,
                                  classes?.input.input,
                              ),
                          }
                        : { input: drdInputPadding }
                }
                endAdornment={
                    <div className={controllsClassName}>
                        {inputValue &&
                        !(disableClearButton || disableKeyboardInput) ? (
                            <GivelifyButton
                                name={`${name}_clear`}
                                iconVariant="close"
                                variant="icon"
                                classes={{ icon: clearClassName }}
                                className={clearButtonClassName}
                                onClick={clearContent}
                                padding={1}
                            />
                        ) : null}
                        <GivelifyButton
                            name={open ? `${name}_close` : `${name}_open`}
                            iconVariant={open ? 'chevron-up' : 'chevron-down'}
                            variant="icon"
                            classes={{ icon: openClassName }}
                            className={openButtonClassName}
                            onClick={toggleOpen}
                        />
                    </div>
                }
            />
            <Popover
                open={open}
                anchorEl={inputRef.current}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                onClose={onMenuClose}
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
            >
                <DropDownOptions
                    id={`${id}-options`}
                    options={optionsToRender}
                    onSelect={onItemSelect}
                    header={optionsHeader}
                    className={props.classes ? props.classes.optionsRoot : ''}
                    hasPadding={
                        rightHelperText !== undefined ||
                        leftHelperText !== undefined ||
                        countLength
                    }
                    hideCheckMark={hideCheckMark}
                    optionClasses={classes?.option}
                    disableAutoScroll={disableAutoScroll}
                    width={menuWidth}
                />
            </Popover>
        </div>
    );
};

export type GivelifyFormDropDownProps = GivelifyDropDownProps & {
    name: string;
    formInputRef: React.Ref<HTMLInputElement>;
};

export const GivelifyFormDropDown: React.FC<GivelifyFormDropDownProps> = props => {
    const {
        name,
        defaultValue,
        value,
        onChange,
        formInputRef,
        ...inputProps
    } = props;
    const [curValue, setCurValue] = React.useState(
        value ? value : defaultValue ? defaultValue : '',
    );
    const onChangeEvent = React.useCallback(
        (
            newValue: string,
            item: { value: string; label: string; description?: string } | null,
        ) => {
            if (value && onChange) {
                onChange(newValue, item);
            } else {
                setCurValue(newValue);
            }
        },
        [setCurValue, onChange, value],
    );
    React.useEffect(() => {
        if (value !== undefined) {
            setCurValue(value);
        }
    }, [value]);
    return (
        <>
            <input
                id={props.id}
                hidden
                name={name}
                value={curValue}
                // eslint-disable-next-line
                onChange={() => {}}
                ref={formInputRef}
            />
            <GivelifyDropDown
                {...inputProps}
                id={`${props.id}-controll`}
                name={name}
                value={curValue}
                onChange={onChangeEvent}
            />
        </>
    );
};
