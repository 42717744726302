import React from 'react';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { Donor } from '../../../../../../../types/donorTypes';
import { IntegrationTypes } from '../../../../../types';
import AutoIntegrationCreateProfile from './AutoIntegrationCreateProfile';
import { AutoIntegrationF1GoCreateProfile } from './AutoIntegrationF1GoCreateProfile';
import AutoIntegrationPotentialMatches from './AutoIntegrationPotentialMatches';
import EnterIntegrationDonorId from './EnterIntegrationDonorId';

interface F1CCBUnMatchDonorTabsContentProps {
    currentTab: number;
    donor?: Donor;
    doneeId: number;
    donorId: number;
    isLoading: boolean;
    matchDonor: (donorId: number, f1DonorId: number) => void;
    isReadOnly: boolean;
    integration: IntegrationTypes;
    errorMessage: string;
    handleClose: (isDonorMatched?: boolean) => void;
}

const AutoIntegrationUnMatchDonorTabsContent: React.FC<
    F1CCBUnMatchDonorTabsContentProps
> = ({
    currentTab,
    donor,
    doneeId,
    donorId,
    isLoading,
    matchDonor,
    isReadOnly,
    integration,
    errorMessage,
    handleClose,
}) => {
    const { isF1Go } = React.useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );
    return (
        <>
            {currentTab === 0 && (
                <AutoIntegrationPotentialMatches
                    donor={donor}
                    donorId={donorId}
                    errorMessage={errorMessage}
                    integration={integration}
                    isLoading={isLoading}
                    isReadOnly={isReadOnly}
                    matchDonor={matchDonor}
                />
            )}
            {currentTab === 1 && (
                <EnterIntegrationDonorId
                    donor={donor}
                    errorMessage={errorMessage}
                    integration={integration}
                    isLoading={isLoading}
                    isReadOnly={isReadOnly}
                    matchDonor={matchDonor}
                />
            )}
            {currentTab === 2 ? (
                isF1Go ? (
                    <AutoIntegrationF1GoCreateProfile
                        doneeId={doneeId}
                        donor={donor}
                        donorId={donorId}
                        handleClose={handleClose}
                    />
                ) : (
                    <AutoIntegrationCreateProfile
                        doneeId={doneeId}
                        donor={donor}
                        donorId={donorId}
                        handleClose={handleClose}
                        integration={integration}
                    />
                )
            ) : null}
        </>
    );
};

export default AutoIntegrationUnMatchDonorTabsContent;
