import { useCallback, useEffect, useMemo, useState } from 'react';
import permissionTypes from 'constants/permissionTypes';
import { useAllIntegrations } from 'pages/integrations/IntegrationSetup';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { appendAdditionalSearchParams } from 'router/GasRouterProvider';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { EnableFeature, MenuItem } from 'store/system/types';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { useAdvancedTranslation } from 'utils/i18n';
import permissionsByPath from 'utils/permissionsByPath';
import { RecursiveObjectArrayHandler } from 'utils/recursiveObjectHandler';
import { getGoogleTagManagerTag } from '../../utils/getGoogleTagManagerTag';

export const useMenu = () => {
    const { user, enabledFeatures } = useSelector((state: AppState) => ({
        user: state.User.user,
        enabledFeatures: state.System.enabledFeatures,
    }));

    const { role } = user;

    const location = useLocation();

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const initialMenuItems = useInitialMenuItems();

    useEffect(() => {
        const newMenuItems = buildMenuItems(
            initialMenuItems,
            role,
            enabledFeatures,
            location.pathname,
        );
        setMenuItems(newMenuItems);
    }, [enabledFeatures, initialMenuItems, location.pathname, role]);

    const toggleMenuItem = useCallback(
        (id: string) => {
            const item = menuItems.find((item) => item.id === id);
            if (!item?.nested) return;

            const newMenuItems = menuItems.map((menuItem) => ({
                ...menuItem,
                isExpanded: menuItem.id === id ? !menuItem.isExpanded : false,
            }));
            setMenuItems(newMenuItems);
        },
        [menuItems],
    );

    return { menuItems, toggleMenuItem };
};

const useInitialMenuItems = () => {
    const { at, t } = useAdvancedTranslation();

    const {
        mainDoneeId,
        donee,
        user,
        reportType,
        snapGiveEnabled,
        automatedIntegration,
    } = useSelector((state: AppState) => ({
        mainDoneeId: state.Donee.campuses?.[0].id,
        donee: state.Donee.donee,
        user: state.User.user,
        snapGiveEnabled: state.System.snapGiveEnabled,
        reportType: state.ReportIntegration.scheduledReport?.reportType,
        automatedIntegration: state.F1CCBIntegration.data?.data,
    }));

    const { id: doneeId, type: doneeType } = donee;
    const { isSynagogue } = user;

    const allIntegrations = useAllIntegrations();

    const automatedIntegrationType =
        automatedIntegration?.doneeId > 0
            ? automatedIntegration?.type
            : undefined;

    const integration = allIntegrations.find(
        (i) => i.integrationType === (automatedIntegrationType || reportType),
    );

    const integrationAbbreviation = integration?.abbreviation;

    type MenuItemWithoutUrl = Omit<MenuItem, 'url' | 'nested'> & {
        nested?: Omit<MenuItem, 'url' | 'nested'>[];
    };

    const menuItemsWithoutUrls: MenuItemWithoutUrl[] = useMemo(
        () => [
            {
                id: Features.OVERVIEW,
                label: t('menu.overview.title', 'Overview'),
                pathname: PATH.OVERVIEW,
                icon: 'overview',
            },
            {
                id: Features.DONATIONS,
                label: t('menu.donations.title', 'Donations'),
                pathname: PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES,
                useLink: true,
                icon: 'donations',
                matchPaths: [
                    PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES,
                    PATH.DONATIONS.DONATION_SUMMARY.BY_DATE,
                    PATH.DONATIONS.DONATIONS_ACTIVITY,
                ],
            },
            {
                id: Features.DONORS,
                label: t('menu.donors.title'),
                pathname: PATH.DONORS.ROOT,
                icon: 'donors',
                matchPaths: [
                    PATH.DONORS.PROFILE,
                    PATH.DONORS.PROFILE_DONATION_HISTORY,
                    PATH.DONORS.PROFILE_MESSAGE_HISTORY,
                ],
            },
            {
                id: Features.DONATIONS_BANK_DEPOSITS,
                label: t('menu.donations.bankDeposits', 'Bank deposits'),
                labelShort: t('menu.donations.bankDepositsShort', 'deposits'),
                pathname: PATH.DONATIONS.BANK_DEPOSITS,
                icon: 'bank',
                matchPaths: [
                    PATH.DONATIONS.BANK_DEPOSITS,
                    PATH.DONATIONS.REFUNDS,
                ],
            },
            {
                id: Features.REPORTS,
                label: t('menu.reports.title'),
                pathname: PATH.REPORTS.GENERATE_REPORTS,
                useLink: true,
                icon: 'reports',
                matchPaths: [
                    PATH.REPORTS.GENERATE_REPORTS,
                    PATH.REPORTS.LEGACY_REPORTS,
                    PATH.REPORTS.REPORT_HISTORY,
                ],
            },
            {
                id: Features.INTEGRATIONS,
                label: integrationAbbreviation
                    ? `${integrationAbbreviation} ${t(
                          'menu.data.integrations.integration',
                      )}`
                    : t('menu.data.title', 'Integrations'),
                alternativeLable: t('menu.data.title'),
                pathname: integrationAbbreviation
                    ? PATH.DATA.INTEGRATION_CONTENT
                    : PATH.DATA.INTEGRATIONS_SETUP,
                hasDivider: true,
                icon: 'integrations',
                useLink: true,
                matchPaths: [
                    PATH.DATA.INTEGRATIONS_SETUP,
                    PATH.DATA.INTEGRATION_CONTENT,
                ],
            },
            {
                id: Features.SETTINGS_ENVELOPES,
                label: at('menu.settings.envelopes', 'Envelopes'),
                pathname: PATH.SETTINGS.ENVELOPES,
                useLink: true,
                icon: 'envelopes',
            },
            {
                id: Features.FUNDRAISING_TOOLS,
                label: t('menu.fundraisingTools.title'),
                pathname: PATH.FUNDRAISING_TOOLS.ROOT,
                useLink: true,
                icon: 'quick-give',
                buttonId: 'giving-tools-submenu',
                nested: [
                    {
                        id: Features.FUNDRAISING_TOOLS_ONLINE_GIVING,
                        label: t(
                            'menu.fundraisingTools.onlineGiving',
                            'Online giving',
                        ),
                        pathname: PATH.FUNDRAISING_TOOLS.ONLINE_GIVING,
                    },
                    {
                        id: Features.FUNDRAISING_TOOLS_SM_GIVING,
                        label: t(
                            'menu.fundraisingTools.socialMediaGiving',
                            'Social media giving',
                        ),
                        pathname: PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING,
                    },
                    {
                        id: Features.FUNDRAISING_TOOLS_SNAP_GIVE,
                        label: t(
                            'menu.fundraisingTools.snapToGive',
                            'Snap-to-Give',
                        ),
                        pathname: PATH.FUNDRAISING_TOOLS.SNAP_GIVE,
                        hideInMenu: !snapGiveEnabled,
                    },
                    {
                        id: Features.FUNDRAISING_TOOLS_GIVELITHON,
                        label: t(
                            'menu.fundraisingTools.givelithon',
                            'Givelithon',
                        ),
                        pathname: PATH.FUNDRAISING_TOOLS.GIVELITHON,
                    },
                ],
            },
            {
                id: Features.SETTINGS_ALL,
                label: t('menu.settings.title', 'Settings'),
                pathname: PATH.SETTINGS.ROOT,
                useLink: true,
                icon: 'settings',
                buttonId: 'settings-submenu',
                nested: [
                    {
                        id: Features.SETTINGS_APP_PROFILE,
                        label: t('menu.settings.appProfile', 'App profile'),
                        pathname: PATH.SETTINGS.APP_PROFILE,
                    },
                    {
                        id: Features.SETTINGS_BANK_INFO,
                        label: t('menu.settings.banking', 'Bank Info'),
                        pathname: PATH.SETTINGS.BANK_INFO,
                    },
                    {
                        id: Features.SETTINGS_USERS,
                        label: t('menu.settings.users', 'Users'),
                        pathname: PATH.SETTINGS.USERS,
                    },
                    {
                        id: Features.SETTINGS_ORGANIZATION_INFO,
                        label: t('menu.settings.orgInfo', 'Organization info'),
                        pathname: PATH.SETTINGS.ORG_INFO,
                    },
                    {
                        id: Features.SETTINGS_CAMPUSES,
                        label: t('menu.settings.campuses', 'Campuses'),
                        pathname: PATH.SETTINGS.CAMPUSES,
                        hideInMenu: doneeType !== 'church',
                    },
                    {
                        id: Features.SETTINGS_MISSION,
                        label: t('menu.settings.mission', 'Mission statement'),
                        pathname: PATH.SETTINGS.MISSION_STATEMENT,
                    },
                    {
                        id: Features.SETTINGS_QUICK_GIVE,
                        label: t('menu.settings.quickgive', 'Quick give'),
                        pathname: PATH.SETTINGS.QUICK_GIVE,
                        hideInMenu: !isSynagogue,
                    },
                ],
            },
        ],
        [
            at,
            doneeType,
            integrationAbbreviation,
            isSynagogue,
            snapGiveEnabled,
            t,
        ],
    );

    const doneeIdForUrl = doneeId !== mainDoneeId ? doneeId : undefined;
    const tag = useMemo(
        () =>
            donee
                ? getGoogleTagManagerTag(
                      donee.onboarding?.hasMid,
                      donee.lastDonationDate,
                  )
                : null,
        [donee],
    );

    const menuItemsWithUrls: MenuItem[] = useMemo(
        () =>
            menuItemsWithoutUrls.map((item) => ({
                ...item,
                url: appendAdditionalSearchParams(
                    item.pathname,
                    doneeIdForUrl,
                    tag,
                ),
                nested: item.nested?.map((nestedItem) => ({
                    ...nestedItem,
                    url: appendAdditionalSearchParams(
                        nestedItem.pathname,
                        doneeIdForUrl,
                        tag,
                    ),
                })),
            })),
        [doneeIdForUrl, menuItemsWithoutUrls, tag],
    );

    return menuItemsWithUrls;
};

const buildMenuItems = (
    menuItems: MenuItem[],
    role: string,
    enabledFeatures: EnableFeature[],
    pathname: string,
) => {
    const flaggedMenuItems = flagActiveMenuItems(pathname, menuItems);

    const filteredByFeatureFlagMenuItems = featureFlagMenu(
        flaggedMenuItems,
        enabledFeatures,
    );

    const filteredByPermissionFlagMenuItems = permissionFlagMenu(
        filteredByFeatureFlagMenuItems,
        role,
    );

    const activeMenu = filteredByPermissionFlagMenuItems.find(
        (item) => item.childIsActive,
    );

    return filteredByPermissionFlagMenuItems.map((menuItem, i) => ({
        ...menuItem,
        isExpanded:
            filteredByPermissionFlagMenuItems[i]?.isExpanded === undefined
                ? activeMenu
                    ? menuItem.id === activeMenu.id
                    : menuItem.defaultExpanded
                : filteredByPermissionFlagMenuItems[i].isExpanded,
    }));
};

const flagActiveMenuItems = (pathname: string, menuItems: MenuItem[]) => {
    const resetMenuItems = new RecursiveObjectArrayHandler<MenuItem>(
        menuItems,
        'nested',
    ).map((item) => {
        item.childIsActive = false;
        item.active = false;
        return item;
    });
    const newMenuItems = new RecursiveObjectArrayHandler<MenuItem>(
        resetMenuItems,
        'nested',
    ).mapWithParent((item, parent) => {
        if (
            matchPath({ path: item.pathname, end: true }, pathname) ||
            item.matchPaths?.some((path) =>
                matchPath({ path, end: true }, pathname),
            )
        ) {
            if (parent) {
                parent.childIsActive = true;
            }
            if (!item.childIsActive || item.activateIfChildIsActive) {
                item.active = true;
            }
        } else {
            if (item.childIsActive && item.activateIfChildIsActive) {
                item.active = true;
                if (parent) {
                    parent.childIsActive = true;
                }
            }
        }
        return item;
    });
    return newMenuItems;
};

const featureFlagMenu = (
    menuItems: MenuItem[],
    enabledFeatures: EnableFeature[],
) => {
    const func = (menuItems: MenuItem[]) => {
        const newItems = [];
        for (const menuItem of menuItems) {
            const enabled = isFeatureEnabled(enabledFeatures, menuItem.id);
            if (enabled && menuItem.nested && menuItem.nested.length > 0) {
                const nested = func(menuItem.nested);
                newItems.push({
                    ...menuItem,
                    nested,
                });
            } else if (enabled) {
                newItems.push(menuItem);
            }
        }
        return newItems;
    };

    return func([...menuItems]);
};

const permissionFlagMenu = (menuItems: MenuItem[], role: string) => {
    const func = (menuItems: MenuItem[]) => {
        const newItems = [];
        for (const menuItem of menuItems) {
            const enabled =
                !permissionsByPath[menuItem.pathname] ||
                permissionsByPath[menuItem.pathname][role] !==
                    permissionTypes.NO_ACCESS;
            if (enabled && menuItem.nested && menuItem.nested.length > 0) {
                const nested = func(menuItem.nested);
                newItems.push({
                    ...menuItem,
                    nested,
                });
            } else if (enabled) {
                newItems.push(menuItem);
            }
        }
        return newItems;
    };

    return func([...menuItems]);
};
