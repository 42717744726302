import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    container: {
        overflow: 'visible',
    },
    profileStyles: {
        display: 'flex',
        flexDirection: 'row',
        '& .link': {
            color: theme.colors?.primary || theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
            '&:active': {
                color:
                    theme.colors?.accentDarkBlue || theme.palette.primary.dark,
                textDecoration: 'underline',
            },
        },
        '& .form-section': {
            maxWidth: 600,
        },
        '& .image-section': {
            marginLeft: 100,
            marginRight: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '& .border-bottom': {
            borderBottom: '1px solid #CAD2DB',
        },
    },
    contentWrapper: {
        marginTop: 32,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 64,
        '& > :not(:first-child)::before': {
            content: `''`,
            backgroundColor: '#CAD2DB',
            height: 1,
            width: '100%',
            position: 'absolute',
            left: 0,
            transform: 'translateY(-32px)',
        },
    },
}));
