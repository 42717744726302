import { getAxiosClient, makeApiRequest, ApiResponse } from '@givelify/utils';
import dayjs from 'dayjs';
import { DonorResponse } from '../../../../../../types/donorTypes';
import { PaginatedResponse } from '../../../../../../types/paginationTypes';
import {
    CCBEnvelope,
    IntegrationCampusesResponse,
    CCBCredentialUpdate,
    AutoInteExportedDonationsResponse,
    AutoIntegrationMatchNumberResponse,
    AutoIntegrationSettingData,
    IntegrationPaginatedResponse,
    integrationUrl,
    AutomatedIntegrationDonorResponseData,
    F1EnableRequest,
    F1CredentialUpdate,
    CreatDonorOptionsProps,
    CreateDonorData,
} from '../types';

export const getDataForInfiniteLoader = <T>(
    url: string,
): Promise<ApiResponse<IntegrationPaginatedResponse<T>>> => {
    const httpRequest = getAxiosClient().get(url);
    return makeApiRequest<IntegrationPaginatedResponse<T>>(httpRequest);
};

export const deleteMatchedEnvelope = (doneeId: number, envelopeId: number) => {
    const httpRequest = getAxiosClient().delete(
        integrationUrl(doneeId, `envelopes/${envelopeId}/match`),
    );
    return makeApiRequest(httpRequest);
};

export const getIntegrationEnvelope = (
    doneeId: number,
): Promise<ApiResponse<PaginatedResponse<CCBEnvelope>>> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(doneeId, `chms/envelopes?per_page=999999`),
    );
    return makeApiRequest(httpRequest);
};

export const requestMatchEnvelope = (
    doneeId: number,
    envelopeId: number,
    chmsEnvelopeId: number,
) => {
    const httpRequest = getAxiosClient().post(
        integrationUrl(
            doneeId,
            `envelopes/${envelopeId}/match?chms_internal_id=${chmsEnvelopeId}`,
        ),
    );
    return makeApiRequest(httpRequest);
};

export const matchDonor = (
    doneeId: number,
    donorId: number,
    chmsDonorId: number,
) => {
    const httpRequest = getAxiosClient().post(
        integrationUrl(
            doneeId,
            `donors/${donorId}/match?chms_internal_id=${chmsDonorId}`,
        ),
    );
    return makeApiRequest(httpRequest);
};

export const getCampuses = (
    doneeId: number,
): Promise<ApiResponse<IntegrationCampusesResponse>> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(doneeId, 'campuses'),
    );
    return makeApiRequest(httpRequest);
};

export const getIntegrationDetail = (
    doneeId: number,
): Promise<ApiResponse<AutoIntegrationMatchNumberResponse>> => {
    const httpRequest = getAxiosClient().get(integrationUrl(doneeId, `stats`));
    return makeApiRequest<AutoIntegrationMatchNumberResponse>(httpRequest);
};

export const getIntegrationSettings = (
    doneeId: number,
): Promise<ApiResponse<AutoIntegrationSettingData>> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(doneeId, `details`),
    );
    return makeApiRequest<AutoIntegrationSettingData>(httpRequest);
};

export const updateIntegrationAutoExport = (
    doneeId: number,
    exportStatus: boolean,
    exportStartDate?: dayjs.Dayjs | null,
) => {
    const httpRequest = getAxiosClient().put(
        integrationUrl(doneeId, 'details'),
        {
            settings: {
                donations_autoexport: exportStatus,
                export_start_date: exportStartDate
                    ? exportStartDate.format('YYYY-MM-DD')
                    : null,
            },
        },
    );
    return makeApiRequest(httpRequest);
};

export const updateCcbCredential = (
    doneeId: number,
    data: CCBCredentialUpdate,
) => {
    const httpRequest = getAxiosClient().put(
        integrationUrl(doneeId, 'details'),
        data,
    );
    return makeApiRequest(httpRequest);
};

export const getPotentialDonors = (
    doneeId: number,
    donorId: number,
): Promise<
    ApiResponse<PaginatedResponse<AutomatedIntegrationDonorResponseData>>
> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(doneeId, `donors/${donorId}/match-suggestions`),
    );
    return makeApiRequest(httpRequest);
};

export const deleteMatchDonor = (doneeId: number, donorId: number) => {
    const httpRequest = getAxiosClient().delete(
        integrationUrl(doneeId, `donors/${donorId}/match`),
    );
    return makeApiRequest(httpRequest);
};

export const getDonorInformation = (
    doneeId: number,
    donorId: number,
    isAllCampuses?: boolean,
): Promise<ApiResponse<DonorResponse>> => {
    let url = `/donees/${doneeId}/donors/${donorId}`;
    if (isAllCampuses) {
        url += `?isAllCampuses=${isAllCampuses}`;
    }
    const httpRequest = getAxiosClient().get(url);
    return makeApiRequest(httpRequest);
};

export const getExportedDonation = (
    doneeId: number,
    pageNumber: number,
    pageSize: string,
): Promise<
    ApiResponse<IntegrationPaginatedResponse<AutoInteExportedDonationsResponse>>
> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(
            doneeId,
            `exported-donations?page=${pageNumber + 1}&per_page=${pageSize}`,
        ),
    );
    return makeApiRequest(httpRequest);
};

export const downloadExportDonation = (
    doneeId: number,
    startDate: string,
    endDate: string,
): Promise<ApiResponse<unknown>> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(
            doneeId,
            `exported-donations/download?start_date=${startDate}&end_date=${endDate}`,
        ),
    );
    return makeApiRequest(httpRequest);
};

export const getSubFund = (
    doneeId: number,
    envelopeParentId: number,
): Promise<ApiResponse<PaginatedResponse<CCBEnvelope>>> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(
            doneeId,
            `chms/envelopes?per_page=999999&parent_id=${envelopeParentId}`,
        ),
    );
    return makeApiRequest(httpRequest);
};

export const matchEnvelope = (
    doneeId: number,
    envelopeId: number,
    chmsEnvelopeId: number,
) => {
    const httpRequest = getAxiosClient().post(
        integrationUrl(
            doneeId,
            `envelopes/${envelopeId}/match?chms_internal_id=${chmsEnvelopeId}`,
        ),
    );
    return makeApiRequest(httpRequest);
};

export const enableF1 = (doneeId: number, data: F1EnableRequest) => {
    const httpRequest = getAxiosClient().post(
        integrationUrl(doneeId, 'registration'),
        data,
    );
    return makeApiRequest(httpRequest);
};

export const updateF1Credential = (
    doneeId: number,
    data: F1CredentialUpdate,
) => {
    const httpRequest = getAxiosClient().put(
        integrationUrl(doneeId, 'details'),
        data,
    );
    return makeApiRequest(httpRequest);
};

export const getCreatOptions = (
    doneeId: number,
): Promise<ApiResponse<CreatDonorOptionsProps>> => {
    const httpRequest = getAxiosClient().get(
        integrationUrl(doneeId, `chms/donors/options`),
    );
    return makeApiRequest(httpRequest);
};

export const createNewF1Donor = (doneeId: number, data: CreateDonorData) => {
    const httpRequest = getAxiosClient().post(
        integrationUrl(doneeId, 'chms/donors'),
        data,
    );
    return makeApiRequest(httpRequest);
};
