import { useFilterParams } from 'api/hooks/useFilterParams';

export type SearchFilter = {
    search: string;
};

const paramNames = ['search'] as const;

export const useSearchFilter = () => {
    const [filter, setFilter] = useFilterParams<
        SearchFilter,
        typeof paramNames
    >(
        {
            search: '',
        },
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'search':
                default:
                    return state.search;
            }
        },
        (values) => {
            return {
                search: values['search'],
            };
        },
    );
    return [filter, setFilter] as const;
};
