import { IntegrationPaginatedResponse } from '@givelify/givelify-ui';
import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { DonationRow } from 'api/models';
import axios, { CancelTokenSource } from 'axios';
import {
    MESSAGE_FILTER_VALUE,
    TRANSACTION_FILTER_VALUE,
} from 'components/filters';
import { getDonationQueryString } from 'store/donations/donation/types';

const sortValues = [
    'date-asc',
    'date-desc',
    'donor_name-asc',
    'donor_name-desc',
];

let infiniteLoaderDataCancelTokenSource: CancelTokenSource;

export const getDataForInfiniteLoader = async (
    doneeId: number,
    pageNumber: number,
    timeFrame: TimeFrameValue,
    transactionFilter: TRANSACTION_FILTER_VALUE,
    envelopeIds: number[],
    messageFilter: MESSAGE_FILTER_VALUE[],
    search?: string,
    sortField?: string,
): Promise<ApiResponse<IntegrationPaginatedResponse<DonationRow>>> => {
    infiniteLoaderDataCancelTokenSource?.cancel();
    infiniteLoaderDataCancelTokenSource = axios.CancelToken.source();
    const sort = sortValues.includes(sortField)
        ? sortField.split('-')[0]
        : 'date';
    const order = sortField.split('-')[1];
    const donationsQueryString = getDonationQueryString({
        timeFrame,
        transactionFilter,
        page: pageNumber,
        envelopeIds,
        messageFilter,
        search,
        sort,
        order,
    });
    const donationsUrl = `/donees/${doneeId}/donations${donationsQueryString}`;

    const donationsHttpRequest = getAxiosClient().get(donationsUrl, {
        cancelToken: infiniteLoaderDataCancelTokenSource.token,
    });
    return makeApiRequest<IntegrationPaginatedResponse<DonationRow>>(
        donationsHttpRequest,
    );
};
