import { AxiosPromise } from 'axios';
import getStore from 'store';
import { setAccessToken } from 'store/user/actions';
//eslint-disable-next-line
export async function makeApiRequest<T>(httpPromise: AxiosPromise<any>) {
    try {
        const response = await httpPromise;
        if (
            response.status === 200 ||
            response.status === 204 ||
            response.status === 201
        ) {
            const body = response.data as T;
            return {
                success: true,
                response: body,
            };
        } else {
            return {
                success: false,
                error: {
                    error: response.status,
                    errorDescription: response.data.message
                        ? response.data.message
                        : response.statusText,
                },
            };
        }
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                getStore().dispatch(setAccessToken(undefined));
            }

            return {
                success: false,
                error: {
                    error: err.response.status,
                    errorDescription: err.response.data.message
                        ? err.response.data.message
                        : err.response.statusText,
                },
            };
        } else {
            return {
                success: false,
                error: {
                    error: 'Failed to fetch',
                    errorDescription:
                        'ERR_CONNECTION_REFUSED. Possible network problem',
                },
            };
        }
    }
}
