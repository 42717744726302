import React, { useMemo } from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { GivelifyLabel, DesignTokens } from '@givelify/ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle';
import { settingsStyle } from './style';

export interface SettingsPageProps {
    title: string;
    titleId?: string;
    titleSecondRow?: string;
    description?: string;
    useDivider?: boolean;
    pageName: PAGE_NAME;
    trackPageVisit: boolean;
    fullWidth?: boolean;
    safeAndSecure?: boolean;
    rightColumnComponent?: React.ReactNode;
}

const SettingsPage: React.FC<SettingsPageProps> = (props) => {
    const {
        settingsTitle,
        settingsTitleRow,
        settingsSecondTitle,
        settingsRightColumn,
        titleRowWrapper,
        titleLabelWrapper,
        divider,
    } = settingsStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            secure: t('labels.secure'),
        }),
        [t],
    );
    return (
        <TrackingProvider
            pageName={props.pageName}
            trackPageVisit={props.trackPageVisit}
        >
            <PageTitle description={props.description} title={props.title} />
            <div className={settingsTitleRow}>
                <div className={titleRowWrapper}>
                    <div className={titleLabelWrapper}>
                        <GivelifyLabel
                            className={settingsTitle}
                            data-testid={props.titleId}
                            id={props.titleId}
                            text={props.title}
                            variant="heading1M"
                        />
                        {props.safeAndSecure ? (
                            <>
                                <GivelifyIcon
                                    marginLeft={16}
                                    marginRight={8}
                                    size="21px"
                                    variant="lock"
                                />
                                <GivelifyLabel
                                    color="neutralGrey"
                                    data-testid="safe-and-secure"
                                    text={copy.secure}
                                    variant="body2"
                                />
                            </>
                        ) : null}
                    </div>
                </div>
                <div className={settingsRightColumn}>
                    {props.rightColumnComponent}
                </div>

                {props.titleSecondRow && (
                    <GivelifyLabel
                        className={settingsSecondTitle}
                        color={DesignTokens.color.textSecondary}
                        marginTop={1}
                        text={props.titleSecondRow}
                        variant="heading3S"
                    />
                )}

                {props.useDivider && (
                    <div
                        className={
                            props.pageName === PAGE_NAME.SettingsEnvelopes
                                ? divider
                                : ''
                        }
                        style={{
                            boxShadow: 'inset 0px -1px 0px #C2C0CD',
                            width: '100%',
                            height: 1,
                            marginTop: 24,
                            order: 2,
                        }}
                    />
                )}
            </div>
            <div>{props.children}</div>
        </TrackingProvider>
    );
};

export default SettingsPage;
