import { TimeFrameId } from '@givelify/utils';
import { DonationStatistic } from 'api/models';
import dayjs from 'dayjs';
import { TrendData } from './types';

export function transformToHourlyTrendData(
    data: DonationStatistic[] | undefined,
): TrendData {
    if (!data) return [];
    return data.map((d) => ({
        sum: d.sum,
        startDate: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(d.endDateTime, 'YYYY-MM-DD HH:mm:ss'),
        name: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('h A'),
    }));
}

export function transformToDailyTrendData(
    data: DonationStatistic[] | undefined,
    timeFrameType: TimeFrameId,
): TrendData {
    if (!data) return [];
    const transformedData: TrendData = data.map((d) => ({
        sum: d.sum,
        startDate: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(d.endDateTime, 'YYYY-MM-DD HH:mm:ss'),
        name: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss')
            .get('date')
            .toString(),
    }));

    if (timeFrameType === 'thisMonth') {
        const lastDay = transformedData[transformedData.length - 1].endDate;
        const lastDayDate = lastDay.date();
        const daysInMonth = lastDay.daysInMonth();
        if (lastDayDate < daysInMonth) {
            for (let i = lastDayDate + 1; i <= daysInMonth; i++) {
                const nextDay = dayjs(lastDay).date(i);
                transformedData.push({
                    sum: 0,
                    startDate: nextDay.startOf('day'),
                    endDate: nextDay.endOf('day'),
                    name: i.toString(),
                });
            }
        }
    } else if (timeFrameType === 'thisWeek') {
        const lastDay = transformedData[transformedData.length - 1].endDate;
        const lastDayDay = lastDay.day();
        if (lastDayDay < 7) {
            for (let i = 1; i < 7 - lastDayDay; i++) {
                const nextDay = dayjs(lastDay).add(i, 'day');
                transformedData.push({
                    sum: 0,
                    startDate: nextDay.startOf('day'),
                    endDate: nextDay.endOf('day'),
                    name: nextDay.date().toString(),
                });
            }
        }
    }
    return transformedData;
}

export function transformToWeeklyTrendData(
    data: DonationStatistic[] | undefined,
): TrendData {
    if (!data) return [];
    return data.map((d) => {
        const startDate = dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss');
        const endDate = dayjs(d.endDateTime, 'YYYY-MM-DD HH:mm:ss');
        return {
            sum: d.sum,
            startDate,
            endDate,
            name: `${startDate.format('MMM DD')} - ${endDate.format('MMM DD')}`,
        };
    });
}

export function transformToMonthlyTrendData(
    data: DonationStatistic[] | undefined,
    timeFrameType: TimeFrameId,
): TrendData {
    if (!data) return [];
    const transformedData: TrendData = data.map((d) => ({
        sum: d.sum,
        startDate: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(d.endDateTime, 'YYYY-MM-DD HH:mm:ss'),
        name: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM'),
    }));

    if (timeFrameType === 'thisYear') {
        const lastMonth =
            transformedData[transformedData.length - 1].endDate.month();
        if (lastMonth < 11) {
            for (let i = lastMonth + 1; i < 12; i++) {
                transformedData.push({
                    sum: 0,
                    startDate: dayjs().month(i).startOf('month'),
                    endDate: dayjs().month(i).endOf('month'),
                    name: dayjs().month(i).format('MMM'),
                });
            }
        }
    }
    return transformedData;
}
export function transformToYearlyTrendData(
    data: DonationStatistic[] | undefined,
): TrendData {
    if (!data) return [];
    return data.map((d) => ({
        sum: d.sum,
        startDate: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(d.endDateTime, 'YYYY-MM-DD HH:mm:ss'),
        name: dayjs(d.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY'),
    }));
}
