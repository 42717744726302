import React from 'react';
import { ApiResponse } from '@givelify/utils';
import { UpdateContactInfo } from 'types/userTypes';
import { CurrentStep, UserInfo } from './types';

interface CfroContextProps {
    showCfroPanel: boolean;
    cfroModalOpen: boolean;
    onOpenCfroModal: () => void;
    onCloseCfroModal: () => void;
    faithLeaderInfo: UserInfo;
    accountOwnerInfo: UserInfo;
    onSaveUserInfo: (
        contactInfo: UpdateContactInfo,
    ) => Promise<ApiResponse<unknown> | void>;
    currentStep: CurrentStep;
    resendModalOpen: boolean;
    onOpenResendModal: () => void;
    onSubmitResendModal: () => Promise<void>;
    onCloseResendModal: () => void;
    onCloseInvitePanel: () => void;
}

const NOT_INITIALIZED = () => {
    throw new Error('Context not initialized');
};

export const CfroContext = React.createContext<CfroContextProps>({
    showCfroPanel: false,
    cfroModalOpen: false,
    onOpenCfroModal: NOT_INITIALIZED,
    onCloseCfroModal: NOT_INITIALIZED,
    faithLeaderInfo: undefined,
    accountOwnerInfo: undefined,
    onSaveUserInfo: NOT_INITIALIZED,
    currentStep: 'Confirm',
    resendModalOpen: false,
    onOpenResendModal: NOT_INITIALIZED,
    onSubmitResendModal: NOT_INITIALIZED,
    onCloseResendModal: NOT_INITIALIZED,
    onCloseInvitePanel: NOT_INITIALIZED,
});
