import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import useStyles from './styles';

const DonationActivityRowLoading = () => {
    const {
        rowWrapper,
        headerWrapper,
        avatar,
        total,
        dateText,
        secondRowWrapper,
        btnDetail,
    } = useStyles();

    return (
        <div className={rowWrapper}>
            <div className={headerWrapper}>
                <FacebookLoading
                    borderRadius={24}
                    className={avatar}
                    height={48}
                    width={48}
                />
                <FacebookLoading
                    borderRadius={4}
                    height={18}
                    marginBottom={3}
                    width={170}
                />
                <FacebookLoading
                    borderRadius={4}
                    className={total}
                    height={28}
                    width={100}
                />
                <FacebookLoading
                    borderRadius={4}
                    className={dateText}
                    height={14}
                    marginTop={5}
                    width={170}
                />
            </div>
            <div className={secondRowWrapper}>
                <FacebookLoading
                    borderRadius={4}
                    className={btnDetail}
                    height={16}
                    marginTop={5}
                    width={170}
                />
            </div>
        </div>
    );
};

const DonationActivityRowsLoading = () => {
    const { rowsWrapper, divider } = useStyles();
    return (
        <div className={rowsWrapper}>
            <DonationActivityRowLoading />
            <div className={divider} />
            <DonationActivityRowLoading />
            <div className={divider} />
            <DonationActivityRowLoading />
            <div className={divider} />
            <DonationActivityRowLoading />
        </div>
    );
};

export default DonationActivityRowsLoading;
