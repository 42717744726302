import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgInfoBoxIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        height="24"
        viewBox="0 0 24 24"
        width="24"
    >
        <circle cx="12" cy="12" fill="#E5F8FF" r="11.25" />
        <path
            clipRule="evenodd"
            d="M12.1 9.2C12.7075 9.2 13.2 8.70751 13.2 8.1C13.2 7.49249 12.7075 7 12.1 7C11.4925 7 11 7.49249 11 8.1C11 8.70751 11.4925 9.2 12.1 9.2ZM12.925 11.125C12.925 10.6694 12.5556 10.3 12.1 10.3C11.6444 10.3 11.275 10.6694 11.275 11.125L11.275 17.175C11.275 17.6306 11.6444 18 12.1 18C12.5556 18 12.925 17.6306 12.925 17.175L12.925 11.125Z"
            fill="#2BADDD"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M12 1.84615C6.39219 1.84615 1.84615 6.39219 1.84615 12C1.84615 17.6078 6.39219 22.1538 12 22.1538C17.6078 22.1538 22.1538 17.6078 22.1538 12C22.1538 6.39219 17.6078 1.84615 12 1.84615ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill="#2BADDD"
            fillRule="evenodd"
        />
    </svg>
);

export const InfoBoxIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgInfoBoxIcon}
        fontSize={fontSize}
    />
);
