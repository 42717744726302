import React from 'react';
import { GivelifyNotificationProps } from '@givelify/ui';
import { SnackbarCloseReason } from '@mui/material';

export const NotificationContext = React.createContext<{
    showNotification: (content: GivelifyNotificationProps | null) => void;
    closeNotification: (_: any, reason: SnackbarCloseReason) => void;
    notification: GivelifyNotificationProps | null;
    notificationRef?: React.MutableRefObject<HTMLDivElement>;
    notificationId?: string;
    notificationHeight: number;
}>({
    notification: null,
    notificationHeight: 0,
    showNotification: () => {
        console.log('showNotification not implemented');
    },
    closeNotification: () => {
        console.log('closeNotification not implemented');
    },
});

export const useNotification = () => React.useContext(NotificationContext);
