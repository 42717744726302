import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { useTranslation } from 'react-i18next';

export const donationsRowTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            '& .header': {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
        },
        leftColumn: {
            paddingLeft: theme.spacing(5),
        },
        middle: {
            textAlign: 'right',
        },
        rightColumn: {
            textAlign: 'right',
            paddingRight: theme.spacing(5),
        },
        row: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            borderBottom: '1px solid #ccc',
            background: '#ffffff',
            '&:hover': {
                background: '#F6F6F6',
            },
            color: theme.colors.primaryDarkGray,
        },
        textEllipsis: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        firstColumn: {
            paddingLeft: theme.spacing(6),
        },
        lastColumn: {
            paddingRight: theme.spacing(6),
        },
    }),
);

const DonationsRowTable: React.FC = () => {
    const { t } = useTranslation();
    const copy = {
        date: t('bankDeposits.headers.date'),
        amount: t('bankDeposits.headers.amount'),
        status: t('bankDeposits.headers.status'),
    };
    const { table, leftColumn, middle, rightColumn } =
        donationsRowTableStyles();
    return (
        <div className={table}>
            <Grid container className="header" justifyContent="space-between">
                <Grid
                    item
                    className={leftColumn}
                    lg={4}
                    md={4}
                    sm={4}
                    xl={4}
                    xs={4}
                >
                    <GivelifyLabel bold text={copy.date} variant="heading5" />
                </Grid>
                <Grid
                    item
                    className={middle}
                    lg={4}
                    md={4}
                    sm={4}
                    xl={4}
                    xs={4}
                >
                    <GivelifyLabel bold text={copy.amount} variant="heading5" />
                </Grid>
                <Grid
                    item
                    className={rightColumn}
                    lg={4}
                    md={4}
                    sm={4}
                    xl={4}
                    xs={4}
                >
                    <GivelifyLabel bold text={copy.status} variant="heading5" />
                </Grid>
            </Grid>
        </div>
    );
};

export default React.memo(DonationsRowTable);
