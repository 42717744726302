import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import {
    GivelifyColor,
    GivelifyColorOrString,
} from '../style/givelifyDefaultTheme';

type GivelifyLabelStyleProps = {
    color?: GivelifyColorOrString;
    fontSize?: number;
    lineHeight?: number;
    fontWeight?: number;
    whiteSpace?:
        | '-moz-pre-wrap'
        | 'break-spaces'
        | 'normal'
        | 'nowrap'
        | 'pre'
        | 'pre-line'
        | 'pre-wrap';
    wordBreak?: boolean;
    overflowWrap?: 
        | 'normal' 
        | 'break-word' 
        | 'anywhere';
} & GivelifyBoxMarginProps;

export const GivelifyLabelStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyLabelStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyLabelStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyLabelStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyLabelStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        text: {
            fontFamily: theme.gas.font,
            textTransform: 'none',
            color: theme.gas.palette.label.primary,
            wordWrap: 'normal',
            letterSpacing: 0,
            whiteSpace: (props: GivelifyLabelStyleProps) =>
                props.whiteSpace ? props.whiteSpace : 'initial',
            wordBreak: (props: GivelifyLabelStyleProps) =>
                props.wordBreak ? 'break-all' : 'inherit',
            overflowWrap: (props: GivelifyLabelStyleProps) =>
                props.overflowWrap ? props.overflowWrap : 'normal',
        },
        heading1: {
            fontSize: 36,
            lineHeight: '48px',
            fontWeight: 600,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
            [theme.breakpoints.down('xs')]: {
                fontSize: 24,
                lineHeight: '28px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        heading2: {
            fontSize: 28,
            lineHeight: '34px',
            fontWeight: 600,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,

            [theme.breakpoints.down('xs')]: {
                fontSize: 18,
                lineHeight: '21px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        heading3: {
            fontSize: 22,
            lineHeight: '30px',
            fontWeight: 800,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,

            [theme.breakpoints.down('xs')]: {
                fontSize: 18,
                lineHeight: '24px',
                fontWeight: 700,
            },
            '&:focus': {
                outline: 'none',
            },
        },
        heading4: {
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 800,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,

            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                lineHeight: '18px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        heading5: {
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 700,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,

            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                lineHeight: '18px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
        caption1: {
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 600,
            color: theme.gas.palette.label.secondary,
        },
        caption2: {
            fontSize: 14,
            lineHeight: '16px',
            color: theme.gas.palette.label.light,
            fontWeight: 600,
        },
        body1: {
            fontSize: 16,
            lineHeight: '22px',
            fontWeight: 700,
        },
        body2: {
            fontSize: 14,
            lineHeight: '24px',
            fontWeight: 600,
        },
        body3: {
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 600,
            color: theme.gas.palette.label.secondary,
        },
        button1: {
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 800,
        },
        button2: {
            fontSize: 15,
            lineHeight: '15px',
            fontWeight: 600,
        },
        small: {
            fontSize: 12,
            lineHeight: '14px',
            fontWeight: 600,
            color: theme.gas.palette.label.light,
        },
        h1Mobile: {
            fontSize: 24,
            lineHeight: '28px',
            fontWeight: 600,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h2Mobile: {
            fontSize: 18,
            lineHeight: '21px',
            fontWeight: 600,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h3Mobile: {
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 700,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        h4Mobile: {
            fontSize: 16,
            lineHeight: '18px',
            fontWeight: 800,

            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        bold: {
            fontWeight: 800,
        },
        colored: {
            color: (props: GivelifyLabelStyleProps) => {
                if (!props.color) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.color as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.color;
            },
        },
        custom: {
            fontSize: (props: GivelifyLabelStyleProps) => props.fontSize,
            fontWeight: (props: GivelifyLabelStyleProps) => props.fontWeight,
            lineHeight: (props: GivelifyLabelStyleProps) =>
                props.lineHeight ? `${props.lineHeight}px` : undefined,
        },
    }),
);
