import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'types/userTypes';
import { inviteUserModalStyle } from './inviteUserModalStyle';
import PermissionCard from './PermissionCard';

interface StepTwoProps {
    onUserInfoChange?: ({ name, value }) => void;
    userInfo: UserInfo;
    withStep?: boolean;
    isAnOfficial?: boolean;
}

const StepTwo: React.FC<StepTwoProps> = (props: StepTwoProps) => {
    const { userInfo, onUserInfoChange, withStep = true, isAnOfficial } = props;
    const styles = inviteUserModalStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            step2: t('labels.step2'),
            permissions: t(
                'pages.settings.users.add-new-modal.form.permissions.label',
            ),
            allPrivilegesOf: t(
                'pages.settings.users.add-new-modal.form.permissions.allPrivilegesOf',
            ),
            basic: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.title',
            ),
            basicSubTitle: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.subTitle',
            ),
            basicViewAppProfile: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.viewAppProfile',
            ),
            basicViewEnvelopes: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.viewEnvelopes',
            ),
            basicUserTools: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.userTools',
            ),
            basicViewFinancial: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.viewFinancial',
            ),
            basicGenerateReports: t(
                'pages.settings.users.add-new-modal.form.permissions.basic.generateReports',
            ),
            financial: t(
                'pages.settings.users.add-new-modal.form.permissions.financial.title',
            ),
            financialSubTitle: t(
                'pages.settings.users.add-new-modal.form.permissions.financial.subTitle',
            ),
            financialExportData: t(
                'pages.settings.users.add-new-modal.form.permissions.financial.exportData',
            ),
            financialManageProfiles: t(
                'pages.settings.users.add-new-modal.form.permissions.financial.manageProfiles',
            ),
            financialCommunicateWithDonor: t(
                'pages.settings.users.add-new-modal.form.permissions.financial.communicateWithDonor',
            ),
            financialNoAccess: t(
                'pages.settings.users.add-new-modal.form.permissions.financial.noAccess',
            ),
            admin: t(
                'pages.settings.users.add-new-modal.form.permissions.admin.title',
            ),
            adminSubTitle: t(
                'pages.settings.users.add-new-modal.form.permissions.admin.subTitle',
            ),
            adminFinancialCoordinator: t(
                'pages.settings.users.add-new-modal.form.permissions.admin.financialCoordinator',
            ),
            adminManageBankAccount: t(
                'pages.settings.users.add-new-modal.form.permissions.admin.manageBankAccount',
            ),
            adminAddUsers: t(
                'pages.settings.users.add-new-modal.form.permissions.admin.addUsers',
            ),
            adminFullAccess: t(
                'pages.settings.users.add-new-modal.form.permissions.admin.fullAccess',
            ),
        }),
        [t],
    );
    const handlePermissionSelected = (role: string) => {
        if (isAnOfficial && ['Basic', 'Financial'].includes(role)) {
            return;
        }

        if (onUserInfoChange) onUserInfoChange({ name: 'role', value: role });
    };

    return (
        <div>
            {withStep && (
                <GivelifyLabel
                    className={styles.stepHeader}
                    text={copy.step2}
                    variant="heading2"
                />
            )}
            <GivelifyLabel
                className={styles.marginBottom16}
                text={copy.permissions}
                variant="heading3"
            />
            <Grid container spacing={3}>
                <Grid item className={styles.permissionCard} md={4} sm={12}>
                    <PermissionCard
                        data-testid="permission-basic"
                        details={[
                            <GivelifyLabel
                                key={copy.basicViewAppProfile}
                                text={copy.basicViewAppProfile}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicViewEnvelopes}
                                text={copy.basicViewEnvelopes}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicUserTools}
                                text={copy.basicUserTools}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicViewFinancial}
                                text={copy.basicViewFinancial}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicGenerateReports}
                                text={copy.basicGenerateReports}
                                variant="body1"
                            />,
                        ]}
                        disabled={!onUserInfoChange || isAnOfficial}
                        id="permission-basic"
                        onSelected={() => {
                            handlePermissionSelected('Basic');
                        }}
                        selected={userInfo?.role === 'Basic'}
                        subTitle={copy.basicSubTitle}
                        title={copy.basic}
                    />
                </Grid>
                <Grid item className={styles.permissionCard} md={4} sm={12}>
                    <PermissionCard
                        data-testid="permission-financial"
                        details={[
                            <div
                                key={`${copy.allPrivilegesOf} ${copy.basic}`}
                                className={styles.detailText}
                            >
                                {copy.allPrivilegesOf}{' '}
                                <strong>{copy.basic}</strong>
                            </div>,
                            <GivelifyLabel
                                key={copy.financialManageProfiles}
                                text={copy.financialManageProfiles}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.financialExportData}
                                text={copy.financialExportData}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.financialCommunicateWithDonor}
                                text={copy.financialCommunicateWithDonor}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.financialNoAccess}
                                text={copy.financialNoAccess}
                                variant="body1"
                            />,
                        ]}
                        disabled={!onUserInfoChange || isAnOfficial}
                        id="permission-financial"
                        onSelected={() => {
                            handlePermissionSelected('Financial');
                        }}
                        selected={userInfo?.role === 'Financial'}
                        subTitle={copy.financialSubTitle}
                        title={copy.financial}
                    />
                </Grid>
                <Grid item className={styles.permissionCard} md={4} sm={12}>
                    <PermissionCard
                        data-testid="permission-admin"
                        details={[
                            <div
                                key={`${copy.allPrivilegesOf} ${copy.adminFinancialCoordinator}`}
                                className={styles.detailText}
                            >
                                {copy.allPrivilegesOf}{' '}
                                <strong>
                                    {copy.adminFinancialCoordinator}
                                </strong>
                            </div>,
                            <GivelifyLabel
                                key={copy.adminManageBankAccount}
                                text={copy.adminManageBankAccount}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.adminAddUsers}
                                text={copy.adminAddUsers}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.adminFullAccess}
                                text={copy.adminFullAccess}
                                variant="body1"
                            />,
                        ]}
                        disabled={!onUserInfoChange}
                        id="permission-admin"
                        onSelected={() => {
                            handlePermissionSelected('Admin');
                        }}
                        selected={userInfo?.role === 'Admin'}
                        subTitle={copy.adminSubTitle}
                        title={copy.admin}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default StepTwo;
