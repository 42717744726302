import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyModal } from '@givelify/givelify-ui';
import generateFile from 'assets/integration-icons/generate-file.svg';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

interface Props {
    open?: boolean;
    handleClose: () => unknown;
}

const ReportGeneratingDialog: React.FC<Props> = ({
    open = false,
    handleClose,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            generateTitle: t('pages.integrations_custom_report.generateTitle'),
            generateFile: t('pages.integrations_custom_report.generateFile'),
            generateText: t('pages.integrations_custom_report.generateText'),
        }),
        [t],
    );

    return (
        <GivelifyModal
            centerButtons
            showCloseButton
            name="reportGenerating"
            onClose={handleClose}
            onPrimaryClick={handleClose}
            open={open}
        >
            <div className={classes.generateContent}>
                <div>
                    <img alt={copy.generateFile} src={generateFile} />
                </div>
                <GivelifyLabel
                    className={classes.generateTitle}
                    text={copy.generateTitle}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={classes.generateText}
                    text={copy.generateText}
                    variant="body1"
                />
            </div>
        </GivelifyModal>
    );
};

export default ReportGeneratingDialog;
