import React, { useMemo, useState, useEffect } from 'react';
import {
    GivelifyLabel,
    isSucceeded,
    isFailed,
    Donee,
} from '@givelify/givelify-ui';
import { formatPhoneNumber } from '@givelify/utils';
import { makeStyles, Grid } from '@material-ui/core';
import { useInvokeApi, InvokeApiErrorResponse } from 'hooks/useInvokeApi';
import isEqual from 'lodash/isEqual';
import { EditOption } from 'pages/settings/components/EditOption';
import { useDispatch } from 'react-redux';
import { setDoneeContact } from 'store/donee/actions';

import { ContactInfo } from 'types/orgInfoTypes';

import { useAdvancedTranslation } from 'utils/i18n';
import { aboutStyle } from '../style';

import ContactSectionEditor from './ContactSectionEditor';

type ContactUpdateResponse = {
    phone: string;
    email: string;
    established: string;
    congregation_size: string;
    denomination: string;
    updatedAt: string;
};

type ContactGetResponse = {
    data?: { name: string }[];
};

interface ContactSectionProps {
    donee: Donee;
    isReadOnly: boolean;
}

const useStyles = makeStyles(() => ({
    contactDetail: {
        marginBottom: 24,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    },
}));

const ContactSection: React.FC<ContactSectionProps> = ({
    donee,
    isReadOnly,
}) => {
    const commonStyle = aboutStyle();
    const style = useStyles();
    const { at, t } = useAdvancedTranslation();

    const copy = useMemo(
        () => ({
            phoneNumber: t(
                'pages.settings.organization-info.contact.phoneNumber',
            ),
            email: t('pages.settings.organization-info.contact.email'),
            size: at('pages.settings.organization-info.contact.size'),
            denomination: t(
                'pages.settings.organization-info.contact.denomination',
            ),
            established: t(
                'pages.settings.organization-info.contact.established',
            ),
        }),
        [t, at],
    );

    const dispatch = useDispatch();
    const [editorEnabledState, setEditorEnabledState] = useState(false);
    const [contactData, setContactData] = useState({
        phone: '',
        email: '',
        established: '',
        denomination: '',
        congregationSize: '',
    });
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const [denominations, setDenominations] = useState<
        { id: string; name: string }[]
    >([]);

    useEffect(() => {
        setContactData({
            phone: donee.phone || '',
            email: donee.email || '',
            established: donee.established || '',
            denomination: donee.denomination || null,
            congregationSize: donee.congregationSize || null,
        });
    }, [donee]);

    const [updateRequestState, updateRequest] =
        useInvokeApi<ContactUpdateResponse>();
    const [getRequestState, getRequest] = useInvokeApi<ContactGetResponse>();

    const onSubmit = (newData: ContactInfo) => {
        if (!isEqual(contactData, newData)) {
            const denominationId = denominations.find(
                (denom) => denom.name === newData?.denomination,
            )?.id;
            updateRequest('PATCH', `/donees/${donee.id.toString()}`, {
                phone: newData?.phone,
                // eslint-disable-next-line
                congregation_size: newData?.congregationSize,
                established: newData?.established,
                // eslint-disable-next-line
                denomination_id: denominationId,
                email: newData?.email,
            });
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        getRequest('GET', `/denominations`);
    }, [getRequest]);

    useEffect(() => {
        if (isSucceeded(getRequestState)) {
            if (getRequestState.data && getRequestState.data.data?.length) {
                const denoms = getRequestState.data.data.filter(
                    (denom) => denom?.name?.length > 0,
                );
                denoms.sort((a, b) => a.name.localeCompare(b.name));

                setDenominations(
                    denoms.reduce(
                        (acc, current) =>
                            acc.find((item) => item.name === current.name)
                                ? acc
                                : [...acc, current],
                        [],
                    ),
                );
            } else {
                setDenominations([]);
            }
        }
        if (isFailed(getRequestState)) {
            setDenominations([]);
        }
    }, [getRequestState]);

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            dispatch(
                setDoneeContact(
                    donee.id,
                    updateRequestState.data.phone,
                    updateRequestState.data.email,
                    updateRequestState.data.established,
                    updateRequestState.data.congregation_size,
                    updateRequestState.data.denomination,
                    new Date(updateRequestState.data.updatedAt),
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, donee.id]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
        setSaveError(undefined);
    };

    const saving = updateRequestState.type === 'REQUEST_START';
    const loadingDenominations = getRequestState.type === 'REQUEST_START';
    const isChurch = donee?.type === 'church';

    return (
        <div className={commonStyle.section}>
            {editorEnabledState ? (
                <ContactSectionEditor
                    data={contactData}
                    denominations={denominations}
                    error={saveError}
                    isChurch={isChurch}
                    loadingDenominations={loadingDenominations}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            ) : (
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <GivelifyLabel
                            className={commonStyle.subtitle}
                            text={copy.phoneNumber}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={style.contactDetail}
                            text={formatPhoneNumber(contactData.phone)}
                            variant="body1"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <GivelifyLabel
                            className={commonStyle.subtitle}
                            text={copy.email}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={style.contactDetail}
                            text={contactData.email}
                            variant="body1"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <GivelifyLabel
                            className={commonStyle.subtitle}
                            text={copy.established}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={style.contactDetail}
                            text={contactData.established}
                            variant="body1"
                        />
                    </Grid>
                    {isChurch && (
                        <>
                            <Grid item md={6} sm={12} xs={12}>
                                <GivelifyLabel
                                    className={commonStyle.subtitle}
                                    text={copy.denomination}
                                    variant="heading5"
                                />
                                <GivelifyLabel
                                    className={style.contactDetail}
                                    text={contactData.denomination}
                                    variant="body1"
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <GivelifyLabel
                                    className={commonStyle.subtitle}
                                    text={copy.size}
                                    variant="heading5"
                                />
                                <GivelifyLabel
                                    className={style.contactDetail}
                                    text={contactData.congregationSize}
                                    variant="body1"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            <div className={commonStyle.editButtonContainer}>
                {!isReadOnly && !editorEnabledState && (
                    <EditOption onClick={onEditClick} />
                )}
            </div>
        </div>
    );
};

export default ContactSection;
