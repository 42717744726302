import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 32,
    },
    listWrapper: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(248px, 1fr))',
        gridAutoRows: '1fr',
    },
    cantFindListItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        border: '1px dashed #E3E3E3',
        borderRadius: 10,
    },
    link: {
        textDecoration: 'none',
        color: theme.colors?.primary || theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.colors?.accentDarkBlue || theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
