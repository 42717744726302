import React from 'react';
import { styled } from '@mui/material';
import { DesignTokens } from '../specify';
import { GivelifyTooltip } from '../tooltip/GivelifyTooltip';
import { GivelifyTooltipProps } from '../tooltip/types';

export type GivelifyTooltipModalProps = Partial<GivelifyTooltipProps> & {
    open: boolean;
    content: React.ReactElement;
    children: React.ReactElement;
    size?: 'small' | 'default';
};

const GivelifyTooltipModalBackdrop = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: DesignTokens.color.overlayModal,
    zIndex: 1100,
});

const StyledTooltip = styled(GivelifyTooltip, {
    shouldForwardProp: (propName) => propName !== 'size',
})<{ size: 'small' | 'default' }>(({ size, theme }) => ({
    '& .MuiTooltip-tooltip': {
        borderRadius: '8px',
        padding: size === 'small' ? '24px' : '32px',
        minWidth: 'fit-content',
        maxWidth: 'none',
        [theme.breakpoints.down('mobile')]: {
            padding: size === 'small' ? '16px' : '24px',
        },
    },
}));

export const GivelifyTooltipModal: React.FC<GivelifyTooltipModalProps> = ({
    open,
    children,
    content,
    placement,
    size = 'default',
}) => {
    return children ? (
        <>
            {open ? <GivelifyTooltipModalBackdrop /> : null}

            <StyledTooltip
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={open}
                placement={placement}
                size={size}
                title={content}
            >
                {children}
            </StyledTooltip>
        </>
    ) : null;
};
