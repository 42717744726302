import { SvgIcon, Tooltip } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import React from 'react';
import { ReactComponent as InfoSvg } from '../assets/icons/info-filled.svg';
import { GivelifyInfoStyles } from './styles';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyLabel } from '../label';

export interface GivelifyInfoProps extends GivelifyBoxMarginProps {
    /**
     * Tooltip title
     */
    title?: string;
    /**
     * Tooltip text.
     */
    text: string;
    /**
     * Tooltip placement.
     */
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    /**
     * Info icon size (in fontSize px).
     */
    size?: number;
    /**
     * Provided classes
     */
    classes?: {
        root?: string;
        icon?: string;
        text?: string;
        title?: string;
        titleRoot?: string;
        tooltip?: string;
        arrow?: string;
    };
    renderIcon?: React.ReactElement;
    id?: string;
}

export const GivelifyInfo: React.FunctionComponent<GivelifyInfoProps> = props => {
    const {
        boxMargin,
        tooltipContainer,
        tooltipArrow,
        infoIconSize,
        infoContainer,
    } = GivelifyInfoStyles({
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        marginRight: props.marginRight,
        marginTop: props.marginTop,
        size: props.size,
    });
    const rootClassName = mergeClassNames(
        infoContainer,
        boxMargin,
        props.classes?.root,
    );
    const iconClassName = mergeClassNames(infoIconSize, props.classes?.icon);
    const tooltipClassName = mergeClassNames(
        tooltipContainer,
        props.classes?.tooltip,
    );
    const arrowClassName = mergeClassNames(tooltipArrow, props.classes?.arrow);
    const titleClassName = mergeClassNames(props.classes?.title);
    const titleRootClassName = mergeClassNames(props.classes?.titleRoot);
    const textClassName = mergeClassNames(props.classes?.text);
    return (
        <Tooltip
            title={
                <React.Fragment>
                    {props.title && (
                        <div className={titleRootClassName}>
                            <GivelifyLabel
                                text={props.title}
                                variant="body1"
                                color="white"
                                marginBottom={8}
                                className={titleClassName}
                            />
                        </div>
                    )}
                    <GivelifyLabel
                        text={props.text}
                        variant="body2"
                        color="white"
                        className={textClassName}
                    />
                </React.Fragment>
            }
            arrow
            placement={props.placement ? props.placement : 'top'}
            classes={{ tooltip: tooltipClassName, arrow: arrowClassName }}
        >
            <button id={props.id} className={rootClassName} tabIndex={-1} type="button">
                {props.renderIcon ? (
                    props.renderIcon
                ) : (
                    <SvgIcon
                        component={InfoSvg}
                        viewBox="0 0 14 14"
                        className={iconClassName}
                    />
                )}
            </button>
        </Tooltip>
    );
};
