import { ReportType } from '../report/types';

export const SET_INTEGRATION = 'SET_INTEGRATION';
export const CLEAR_INTEGRATION = 'CLEAR_INTEGRATION';

export type IntegrationRequest = {
    data: {
        type: ReportType;
        doneeId: number;
        initialSetupStatus: string;
    };
};

export type AutoIntegration = {
    data: {
        doneeId: number;
        type: ReportType;
        initialSetupStatus: string;
    };
    unmatchedEnvelopes?: number;
    unmatchedDonors?: number;
};

export type AutoIntegrationUnmatched = {
    type: 'donors' | 'envelopes';
    count: number;
};

export interface AutoIntegrationState {
    data?: AutoIntegration;
    unmatchedEnvelopes?: number;
    unmatchedDonors?: number;
}

export interface LoadTotalUnmatchedStateStart {
    type: 'LOAD_TOTAL_UNMATCHED_STATE_START';
}

export interface LoadTotalUnmatchedDonorsStateSuccess {
    type: 'LOAD_TOTAL_UNMATCHED_DONORS_STATE_SUCCESS';
    data: {
        type: 'donors';
        count: number;
    };
}

export interface LoadTotalUnmatchedEnvelopesStateSuccess {
    type: 'LOAD_TOTAL_UNMATCHED_ENVELOPES_STATE_SUCCESS';
    data: {
        type: 'envelopes';
        count: number;
    };
}

export interface SetIntegration {
    type: typeof SET_INTEGRATION;
    data: AutoIntegrationState['data'];
}

export interface ClearIntegration {
    type: typeof CLEAR_INTEGRATION;
}

export type IntegrationSecondActions =
    | SetIntegration
    | ClearIntegration
    | LoadTotalUnmatchedStateStart
    | LoadTotalUnmatchedDonorsStateSuccess
    | LoadTotalUnmatchedEnvelopesStateSuccess;
