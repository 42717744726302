import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { FieldName, UseFormMethods } from 'react-hook-form';

//eslint-disable-next-line
interface Props<Form = {}> {
    name: FieldName<Form>;
    label?: string;
    formRef: UseFormMethods<Form>;
    defaultValue?: boolean;
    //eslint-disable-next-line
    toggleStates?: [any, any];
    disabled?: boolean;
    onClickChange?: () => void;
    size?: 'small';
}

//eslint-disable-next-line
const CheckBox = <Form extends {}>({
    name,
    label,
    formRef,
    defaultValue,
    toggleStates,
    disabled,
    onClickChange,
    size,
}: Props<Form>) => {
    const [check, setCheck] = useState(defaultValue);

    useEffect(() => {
        if (!Object.keys(formRef.formState.touched).length) {
            setCheck(defaultValue);
        }
    }, [defaultValue, formRef.formState.touched]);

    const handleChange = e => {
        setCheck(e.target.checked);
        onClickChange && onClickChange();
        formRef.setValue(
            name,
            toggleStates
                ? e.target.checked
                    ? toggleStates[0]
                    : toggleStates[1]
                : e.target.checked,
            {
                shouldDirty: true,
                shouldValidate: true,
            },
        );
    };
    return (
        <>
            <input
                ref={formRef.register}
                name={name.toString()}
                type="hidden"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={check}
                        color="primary"
                        disabled={disabled || false}
                        onChange={handleChange}
                        size={size || 'medium'}
                    />
                }
                label={label}
            />
        </>
    );
};

export default CheckBox;
