import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCampuses = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>campuses</title>
        <path
            clipRule="evenodd"
            d="M12 3C10.3116 3 9 4.30772 9 5.82762C9 6.60112 9.28517 7.3281 9.89579 8.22701C10.4014 8.97129 11.0843 9.77097 11.9389 10.7716L11.9957 10.8381L12.2231 10.5688L12.2231 10.5687L12.2231 10.5687C12.9897 9.66104 13.6146 8.92095 14.091 8.22486C14.7087 7.32233 15 6.59683 15 5.82762C15 4.31266 13.6546 3 12 3ZM7.5 5.82762C7.5 3.41607 9.54781 1.5 12 1.5C14.4079 1.5 16.5 3.41113 16.5 5.82762C16.5 7.02414 16.0296 8.04814 15.3289 9.07201C15.122 9.37438 14.8882 9.68579 14.6328 10.0091C14.8925 10.0794 15.1666 10.0787 15.4264 10.0065L19.8485 8.77817C20.8043 8.51267 21.75 9.23147 21.75 10.2234V19.1688C21.75 19.8145 21.3369 20.3877 20.7243 20.5919L15.4595 22.3468C15.1608 22.4464 14.8383 22.4494 14.5377 22.3555L9.42244 20.757C9.14669 20.6708 8.85192 20.6661 8.57356 20.7434L4.15147 21.9718C3.19567 22.2373 2.25 21.5185 2.25 20.5265V11.5811C2.25 10.9355 2.66314 10.3623 3.27566 10.1581L8.2871 8.48762C7.80083 7.65047 7.5 6.7949 7.5 5.82762ZM13.3286 11.5851C13.4213 11.4753 13.5129 11.3669 13.603 11.26L14.1301 11.4247C14.1699 11.4371 14.2099 11.4487 14.25 11.4594L14.25 20.6941L9.86985 19.3253C9.83009 19.3129 9.79013 19.3013 9.75 19.2905L9.75 10.5047C10.0816 10.9074 10.442 11.3292 10.8235 11.7756L10.8235 11.7757L10.8242 11.7764L10.825 11.7775C11.0208 12.0065 11.2221 12.2421 11.4278 12.4849C11.5711 12.6539 11.7817 12.7509 12.0032 12.75C12.2247 12.749 12.4345 12.6502 12.5763 12.48C12.8339 12.1707 13.0854 11.8729 13.3286 11.5851ZM15.75 11.4723L15.75 20.6688L20.25 19.1688L20.25 10.2234L15.8279 11.4518C15.802 11.459 15.776 11.4658 15.75 11.4723ZM8.25 19.2776C8.22397 19.2841 8.198 19.291 8.1721 19.2982L8.25 19.5786L8.17209 19.2982L3.75 20.5265L3.75 11.5811L8.25 10.0811L8.25 19.2776ZM12 7.125C12.6213 7.125 13.125 6.62132 13.125 6C13.125 5.37868 12.6213 4.875 12 4.875C11.3787 4.875 10.875 5.37868 10.875 6C10.875 6.62132 11.3787 7.125 12 7.125Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const CampusesIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgCampuses}
        fontSize={fontSize}
    />
);
