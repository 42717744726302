import React from 'react';
import { DesignTokens, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { Grid } from '@mui/material';
import Heart from 'assets/heart.svg';
import NoDonationsQrCodeData from 'assets/no-donations-qr.svg';
import NoDonationsData from 'assets/no-donations.svg';
import { useTranslation } from 'react-i18next';
import { QrBlockWrapper, QrListWrapper, StepNumber, Steps } from './styles';

type NoDataProps = {
    documentsSubmitted: boolean;
    hasMid: boolean;
    orgName: string;
    city: string;
};

const NoDataComponent: React.FC<NoDataProps> = ({
    documentsSubmitted,
    hasMid,
    orgName,
    city,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t('donationsActivity.noDonations.title'),
            description: t('donationsActivity.noDonations.description'),
            link: t('donationsActivity.noDonations.link'),
            url: t('donationsActivity.noDonations.url'),
            linkPart: t('donationsActivity.noDonations.linkPart'),
            QRTitle: t('donationsActivity.noDonations.QRTitle'),
            step1: t('donationsActivity.noDonations.step1'),
            step2: t('donationsActivity.noDonations.step2'),
            step3: t('donationsActivity.noDonations.step3'),
        }),
        [t],
    );

    return (
        <Grid
            container
            alignItems="center"
            data-testid="no-donations-data"
            flexDirection="column"
            gap="12px"
            justifyContent="center"
            paddingLeft="16px"
            paddingRight="16px"
        >
            <img alt="No donations data" src={NoDonationsData} />
            <GivelifyLabel text={copy.title} variant="heading1S" />
            <GivelifyLabel text={copy.description} variant="body1" />
            {documentsSubmitted && !hasMid && (
                <GivelifyLabel data-testid="no-donations-data-link">
                    <GivelifyLink
                        href={copy.url}
                        target="_blank"
                        text={copy.link}
                        variant="body1"
                    />{' '}
                    {copy.linkPart}
                </GivelifyLabel>
            )}
            {hasMid ? (
                <QrBlockWrapper data-testid="no-donations-qr-block">
                    <GivelifyLabel text={copy.QRTitle} variant="body1B" />
                    <br />
                    <GivelifyLabel
                        data-testid="no-donations-org-name"
                        text={orgName}
                        variant="body1B"
                    />
                    <QrListWrapper
                        container
                        alignItems="center"
                        gap="32px"
                        justifyContent="space-between"
                        wrap="nowrap"
                    >
                        <Steps container direction="column" gap="12px">
                            <Grid
                                container
                                alignItems="flex-start"
                                gap="8px"
                                wrap="nowrap"
                            >
                                <StepNumber>1</StepNumber>
                                <GivelifyLabel
                                    text={copy.step1}
                                    variant="body2"
                                />
                            </Grid>
                            <Grid
                                container
                                alignItems="flex-start"
                                gap="8px"
                                wrap="nowrap"
                            >
                                <StepNumber>2</StepNumber>
                                <GivelifyLabel
                                    data-testid="no-donations-qr-step2"
                                    variant="body2"
                                >
                                    {copy.step2}{' '}
                                    <GivelifyLabel
                                        color={DesignTokens.color.textSecondary}
                                        display="inline"
                                        variant="body2"
                                    >
                                        {orgName} {city}
                                    </GivelifyLabel>
                                </GivelifyLabel>
                            </Grid>
                            <Grid
                                container
                                alignItems="flex-start"
                                gap="8px"
                                wrap="nowrap"
                            >
                                <StepNumber>3</StepNumber>
                                <GivelifyLabel
                                    text={copy.step3}
                                    variant="body2"
                                />
                                <img alt="<3" src={Heart} />
                            </Grid>
                        </Steps>
                        <img alt="QR" src={NoDonationsQrCodeData} />
                    </QrListWrapper>
                </QrBlockWrapper>
            ) : null}
        </Grid>
    );
};

export const NoData = React.memo(NoDataComponent);
