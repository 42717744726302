import React, { useCallback, useState } from 'react';
import { GivelifyInputState, GivelifyTextField } from '../textField';
import { phoneValidation } from '../utils/phoneNumberUtils';
import InputMask from 'react-input-mask';

const phoneMask = '(999) 999-9999';

export interface PhoneNumberFieldProps {
    name?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    phone: string | undefined;
    onChange?: (phone: string) => unknown;
    className?: string;
    placeholder: string;
    showState?: boolean;
    phoneErrorMessage?: string;
}

export const PhoneNumberField: React.FC<PhoneNumberFieldProps> = React.memo(
    ({
        name,
        phone,
        inputRef,
        className,
        placeholder,
        onChange,
        showState = false,
        phoneErrorMessage,
    }) => {
        const [newPhone, setPhone] = useState<string>(phone || '');
        const [inputState, setInputState] = React.useState<GivelifyInputState>(
            'normal',
        );
        const handleChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(event.currentTarget.value);
                onChange && onChange(event.currentTarget.value);
                setInputState(
                    phoneValidation(event.currentTarget.value)
                        ? 'normal'
                        : 'error',
                );
            },
            [setPhone, onChange, setInputState],
        );
        React.useEffect(() => {
            if (showState) {
                setInputState(phoneValidation(newPhone) ? 'normal' : 'error');
            }
            //eslint-disable-next-line
        }, [showState]);
        return (
            <InputMask
                mask={phoneMask}
                onChange={handleChange}
                value={newPhone}
            >
                {() => (
                    <GivelifyTextField
                        name={name}
                        inputRef={inputRef}
                        id="phone"
                        ariaLabel={placeholder}
                        label={placeholder}
                        placeholder={placeholder}
                        className={className}
                        state={inputState}
                        leftHelperText={
                            inputState === 'error'
                                ? phoneErrorMessage
                                : undefined
                        }
                    />
                )}
            </InputMask>
        );
    },
);
