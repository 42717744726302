import React, { useCallback, useMemo } from 'react';
import {
    GivelifyBox,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/givelify-ui';
import { formatWithTimezone } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CloseMatchNotification } from 'store/integrations/actions';
import { AllItemsMatchedNotification } from 'store/integrations/types';
import { useRegenerateRequest } from '../useRegenerateRequest';

interface NotificationItemProps {
    item: AllItemsMatchedNotification;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ item }) => {
    const dispatch = useDispatch();
    const regenerateReportRequest = useRegenerateRequest(item.id)[1];

    const notification = useMemo(
        () => ({
            id: item.id,
            date: `${formatWithTimezone(item.startDate)} - ${formatWithTimezone(
                item.endDate,
            )}`,
        }),
        [item],
    );

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integrations.notifications';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const copy = useMemo(
        () => ({
            allItemsMatched: scopedTranslate('allItemsMatched'),
            regenerateReport: scopedTranslate('regenerateReport'),
        }),
        [scopedTranslate],
    );

    const onCloseClick = () => dispatch(CloseMatchNotification(item.id));

    const onDownloadClick = () => {
        regenerateReportRequest();
        dispatch(CloseMatchNotification(item.id));
    };

    return (
        <GivelifyNotification
            show
            name="allItemsMatchedNotification"
            variant="info"
        >
            <GivelifyBox
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <GivelifyBox alignItems="center" display="flex">
                    <GivelifyLabel
                        bold
                        marginRight={8}
                        text={copy.allItemsMatched}
                        variant="body2"
                    />
                    <GivelifyLabel text={notification.date} variant="body2" />
                    <GivelifyButton
                        marginLeft={24}
                        name="regenerateReport"
                        onClick={onDownloadClick}
                        text={copy.regenerateReport}
                        variant="link"
                    />
                </GivelifyBox>
                <GivelifyButton
                    icon={<GivelifyIcon variant="bigClose" />}
                    name="close"
                    onClick={onCloseClick}
                    size="shrink"
                    variant="ghost"
                />
            </GivelifyBox>
        </GivelifyNotification>
    );
};

export default NotificationItem;
