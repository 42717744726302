import { DesignTokens, GivelifyButton } from '@givelify/ui';
import { Stack, styled } from '@mui/material';

export const PageFilterRowWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: theme.spacing(3),
    width: '100%',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('tablet')]: {
        padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(0, 2),
    },
}));

export const PageFilterRowContainer = styled(Stack)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('tablet')]: {
        marginBottom: theme.spacing(2),
    },
}));

export const FilterButton = styled(GivelifyButton, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ isActive, theme }) => ({
    flex: isActive ? '0 0 108px' : '0 0 84px',
    height: '48px',
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    lineHeight: '24px',
    border: `1px solid ${
        isActive
            ? DesignTokens.color.globalInfo300
            : DesignTokens.border.borderComponentDefault.color
    }`,
    background: DesignTokens.color.backgroundSecondary,
    color: isActive
        ? DesignTokens.color.globalInfo400
        : DesignTokens.color.textPrimary,
    position: 'relative',
    paddingRight: isActive ? '40px' : '16px',
    '&:after': isActive
        ? {
              content: '""',
              position: 'absolute',
              width: '8px',
              height: '8px',
              background: DesignTokens.color.globalInfo400,
              right: '16px',
              top: '50%',
              transform: 'translateY(-50%)',
              borderRadius: '50%',
          }
        : null,
    '&:hover': {
        border: `1px solid ${
            isActive
                ? DesignTokens.color.globalInfo400
                : DesignTokens.color.globalNeutral700
        }`,
        background: DesignTokens.color.backgroundSecondary,
        color: isActive
            ? DesignTokens.color.globalInfo400
            : DesignTokens.color.textPrimary,
    },
    '&:active': {
        border: `1px solid ${
            isActive
                ? DesignTokens.color.globalInfo400
                : DesignTokens.color.globalNeutral700
        }`,
        background: DesignTokens.color.backgroundSecondary,
        color: isActive
            ? DesignTokens.color.globalInfo400
            : DesignTokens.color.textPrimary,
    },
    '&:disabled': {
        border: `1px solid ${DesignTokens.color.borderButtonDisabled}`,
        background: DesignTokens.color.backgroundSecondary,
        color: DesignTokens.color.textDisabled,
    },
    [theme.breakpoints.down('tablet')]: {
        flex: 'initial',
        height: '40px',
        fontSize: DesignTokens.textStyle.globalBody2.font,
    },
    [theme.breakpoints.down('mobile')]: {
        height: '32px',
        paddingRight: isActive ? '20px' : '16px',
        color: isActive
            ? DesignTokens.color.globalInfo400
            : DesignTokens.color.textPrimary,
        '&:after': {
            width: '4px',
            height: '4px',
            right: '12px',
        },
    },
}));
