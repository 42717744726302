import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgPhoneNew = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="currentColor"
        height="12"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 12 13"
    >
        <path
            d="M0 1.3875C0 1.1875 0.0666656 1.02083 0.199999 0.8875C0.333332 0.754167 0.499999 0.6875 0.699999 0.6875L3.4 0.6875C3.55556 0.6875 3.69178 0.7375 3.80867 0.8375C3.92511 0.9375 4 1.06528 4.03333 1.22083L4.5 3.52083C4.52222 3.67639 4.50844 3.83483 4.45867 3.99617C4.40844 4.15706 4.33333 4.2875 4.23333 4.3875L2.66667 5.95417C3.17778 6.79861 3.78333 7.57372 4.48333 8.2795C5.18333 8.98483 5.93333 9.57083 6.73333 10.0375L8.36667 8.42083C8.46667 8.32083 8.58067 8.25683 8.70867 8.22883C8.83622 8.20128 8.97778 8.19861 9.13333 8.22083L11.4667 8.65417C11.6222 8.67639 11.75 8.74861 11.85 8.87083C11.95 8.99306 12 9.13194 12 9.2875V11.9875C12 12.1875 11.9333 12.3542 11.8 12.4875C11.6667 12.6208 11.5 12.6875 11.3 12.6875C9.86667 12.6875 8.46956 12.3679 7.10867 11.7288C5.74733 11.0902 4.54178 10.2457 3.492 9.1955C2.44178 8.14572 1.59733 6.94039 0.958666 5.5795C0.319555 4.21817 0 2.82083 0 1.3875ZM8 10.6708C8.43333 10.8597 8.87222 11.0042 9.31667 11.1042C9.76111 11.2042 10.2111 11.2819 10.6667 11.3375V9.85417L9.1 9.57083L8 10.6708ZM2.03333 4.70417L3.15 3.5875L2.83333 2.02083H1.36667C1.4 2.47639 1.47222 2.92928 1.58333 3.3795C1.69444 3.82928 1.84444 4.27083 2.03333 4.70417Z"
            fill="current"
        />
    </svg>
);

export const PhoneNewIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgPhoneNew}
        fontSize={fontSize}
    />
);
