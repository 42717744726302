export type Pagination = {
    total: number;
    count: number;
    perPage: string;
    currentPage: number;
    totalPages: number;
    from: number;
    to: number;
};

export type PaginatedResponse<T> = {
    data: T[];
    pagination: Partial<Pagination>;
};

export const initialPaginatedResponse = {
    data: [],
    pagination: {},
};

export const initialPagination: Pagination = {
    count: 0,
    currentPage: 0,
    perPage: '0',
    total: 0,
    totalPages: 0,
    from: 0,
    to: 0,
};
