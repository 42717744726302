import React, { useMemo, useState } from 'react';
import {
    Address,
    STATE_DROPDOWN_OPTIONS,
    isContainsPOBox,
    isValidCity,
    isValidStreet,
    isValidZip,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import {
    GivelifyButton,
    GivelifyForm,
    GivelifyFormDropDown,
    GivelifyFormRadio,
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyLink,
    GivelifyModal,
} from '@givelify/ui';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { I18N_NAMESPACE } from '../../../../../consts';
import { OnboardingActions } from '../../../OnboardingActions';

interface MailingAddressProps {
    address: Address;
    addressIsSame?: boolean;
    bankingAddress?: Address;
    onContinueClick: (address: Address, addressIsSame: boolean) => void;
    onCancelClick: (skipModal?: boolean) => void;
    onBackClick: () => void;
}

type FormProps = Address & {
    addressIsSame: boolean;
};

const MailingAddress: React.FC<MailingAddressProps> = ({
    address,
    addressIsSame,
    bankingAddress,
    onContinueClick,
    onCancelClick,
    onBackClick,
}) => {
    const formSchema: yup.SchemaOf<Partial<FormProps>> = yup.object().shape({
        addressIsSame: yup.bool(),
        city: yup.string().when('addressIsSame', {
            is: false,
            then: (schema) =>
                schema
                    .required()
                    .test('validCity', 'Invalid city', (value) =>
                        isValidCity(value || ''),
                    ),
        }),
        state: yup.string().when('addressIsSame', {
            is: false,
            then: (schema) => schema.required(),
        }),
        street: yup.string().when('addressIsSame', {
            is: false,
            then: (schema) =>
                schema.required().test(
                    'validStreet',
                    ({ value }) =>
                        isContainsPOBox(value)
                            ? 'PO Box not allowed'
                            : 'Invalid street',
                    (value) => isValidStreet(value || '', true),
                ),
        }),
        zip: yup.string().when('addressIsSame', {
            is: false,
            then: (schema) =>
                schema
                    .required()
                    .test('validZip', 'Invalid zip', (value) =>
                        isValidZip(value || ''),
                    ),
        }),
        country: yup.string(),
    });

    const onSubmit = (data: Partial<FormProps>) => {
        const { addressIsSame, ...restAddress } = data;
        onContinueClick(restAddress as Address, addressIsSame as boolean);
    };

    return (
        <GivelifyForm<Partial<FormProps>>
            defaultValues={{
                city: addressIsSame ? '' : bankingAddress?.city,
                state: addressIsSame ? '' : bankingAddress?.state,
                street: addressIsSame ? '' : bankingAddress?.street,
                zip: addressIsSame ? '' : bankingAddress?.zip,
                country: addressIsSame ? '' : bankingAddress?.country,
                addressIsSame,
            }}
            onSubmit={onSubmit}
            schema={formSchema}
        >
            <AddressForm
                address={address}
                onBackClick={onBackClick}
                onCancelClick={onCancelClick}
            />
        </GivelifyForm>
    );
};

const FormWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
}));

const FormRow = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('mobile')]: {
        gridTemplateColumns: '1fr',
    },
    gap: 16,
}));

const AddressForm: React.FC<{
    address: Address;
    onCancelClick: (skipModal?: boolean) => void;
    onBackClick: () => void;
}> = ({ address, onCancelClick, onBackClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: 'captivePortal.checkingAccount.mailingAddress',
    });

    const {
        header,
        yes,
        no,
        learnMore,
        modalTitle,
        modalDescription,
        modalClose,
        addresPlaceholder,
        zipPlaceholder,
        statePlaceholder,
        cityPlaceholder,
    } = useMemo(
        () => ({
            header: scopedTranslate('header'),
            yes: scopedTranslate('yes'),
            no: scopedTranslate('no'),
            learnMore: scopedTranslate('learnMore'),
            modalTitle: scopedTranslate('modalTitle'),
            modalDescription: scopedTranslate('modalDescription'),
            modalClose: scopedTranslate('modalClose'),
            addresPlaceholder: scopedTranslate(
                'addressForm.address1Placeholder',
            ),
            zipPlaceholder: scopedTranslate('addressForm.zipPlaceholder'),
            statePlaceholder: scopedTranslate('addressForm.statePlaceholder'),
            cityPlaceholder: scopedTranslate('addressForm.cityPlaceholder'),
            countryPlaceholder: scopedTranslate(
                'addressForm.countryPlaceholder',
            ),
        }),
        [scopedTranslate],
    );

    const addressFirstLine = address.street;
    const addressSecondLine = `${address.city}, ${address.state} ${address.zip}`;

    const {
        watch,
        formState: { isValid },
    } = useFormContext<FormProps>();

    const { addressIsSame } = watch(['addressIsSame']);

    const STATES_LIST = useMemo(
        () =>
            STATE_DROPDOWN_OPTIONS.map((i) => ({
                id: i.value,
                label: i.label,
            })),
        [],
    );

    const onCancelCLickInternal = () => {
        onCancelClick(false);
    };

    const [showDetailsModal, setShowDetailsModal] = useState(false);

    return (
        <>
            <div
                style={{
                    marginBottom: 24,
                    ...(isMobile && { maxWidth: 500 }),
                }}
            >
                <GivelifyLabel
                    text={header}
                    variant={isMobile ? 'heading2S' : 'heading1S'}
                />
                {isMobile && (
                    <GivelifyLink
                        onClick={() => setShowDetailsModal(true)}
                        style={{
                            marginTop: 8,
                        }}
                        text={learnMore}
                        variant="body2"
                    />
                )}
            </div>
            <GivelifyLabel text={addressFirstLine} variant="body1" />
            <GivelifyLabel
                marginBottom={2}
                text={addressSecondLine}
                variant="body1"
            />
            <div
                style={{
                    marginBottom: 32,
                    display: 'grid',
                    gridTemplateColumns: '130px 130px',
                }}
            >
                <GivelifyFormRadio<FormProps>
                    label={yes}
                    name="addressIsSame"
                    trackName="yes"
                    value={true}
                />
                <GivelifyFormRadio<FormProps>
                    label={no}
                    name="addressIsSame"
                    trackName="no"
                    value={false}
                />
            </div>
            {!addressIsSame && (
                <FormWrapper>
                    <GivelifyFormTextField<FormProps>
                        fullWidth
                        label={addresPlaceholder}
                        name="street"
                    />
                    <GivelifyFormTextField<FormProps>
                        fullWidth
                        label={zipPlaceholder}
                        name="zip"
                    />
                    <FormRow>
                        <GivelifyFormDropDown<FormProps>
                            label={statePlaceholder}
                            name="state"
                            options={STATES_LIST}
                            placeholder={statePlaceholder}
                            shape="input"
                            width="fullwidth"
                        />
                        <GivelifyFormTextField<FormProps>
                            fullWidth
                            label={cityPlaceholder}
                            name="city"
                        />
                    </FormRow>
                </FormWrapper>
            )}
            <OnboardingActions
                disableContinue={!isValid}
                onBack={onBackClick}
                onCancel={onCancelCLickInternal}
            />
            <GivelifyModal
                fullWidth
                showCloseButton
                onClose={() => setShowDetailsModal(false)}
                open={showDetailsModal}
            >
                <GivelifyLabel
                    align="center"
                    text={modalTitle}
                    variant="heading2S"
                />
                <GivelifyLabel
                    align="center"
                    style={{
                        marginTop: 16,
                        marginBottom: 32,
                    }}
                    text={modalDescription}
                    variant="body2"
                />
                <GivelifyButton
                    fullWidth
                    onClick={() => setShowDetailsModal(false)}
                    text={modalClose}
                    variant="primary"
                />
            </GivelifyModal>
        </>
    );
};

export default MailingAddress;
