import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgRegenerate = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>regenerate</title>
        <path
            clipRule="evenodd"
            d="M15.839 2.944a.75.75 0 1 1 1.449-.388l.776 2.898a.75.75 0 0 1-.53.918l-2.898.777a.75.75 0 1 1-.388-1.449l1.093-.293a8.25 8.25 0 1 0 4.663 7.506.75.75 0 1 1 1.5.014 9.77 9.77 0 0 1-.334 2.434c-1.394 5.201-6.74 8.288-11.941 6.894-5.201-1.394-8.288-6.74-6.894-11.941 1.393-5.201 6.74-8.288 11.94-6.894a9.79 9.79 0 0 1 1.883.718l-.32-1.194Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const RegenerateIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgRegenerate}
        fontSize={fontSize}
    />
);
