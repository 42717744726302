import React from 'react';
import View from './view';

const CardFirstDonation: React.FC = () => {
    return (
        <View />
    );
};

export default CardFirstDonation;
