import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSinglePolls = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            fill="#715AFF"
            height="16.0067"
            rx="2"
            stroke="#715AFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            width="5.33556"
            x="13.3322"
            y="11.9984"
        />
        <rect
            fill="#715AFF"
            height="24.01"
            rx="2"
            stroke="#715AFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            width="5.33556"
            x="22.6695"
            y="3.995"
        />
        <path
            clipRule="evenodd"
            d="M6.6628 28.005C8.13618 28.005 9.33058 26.8106 9.33058 25.3372C9.33058 23.8638 8.13618 22.6694 6.6628 22.6694C5.18943 22.6694 3.99503 23.8638 3.99503 25.3372C3.99503 26.8106 5.18943 28.005 6.6628 28.005Z"
            fill="#715AFF"
            fillRule="evenodd"
            stroke="#715AFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

export const SinglePollsIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSinglePolls}
        fontSize={fontSize}
    />
);
