import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    autoSuggestStyles: {
        '& .MuiAutocomplete-endAdornment': {
            display: 'none',
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 0,
        },
        '& input': {
            borderColor: 'transparent',
        },
        '& .image': {
            padding: theme.spacing(0, 2),
        },
    },
    inputBaseRoot: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        backgroundColor: 'white',
    },
    input: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        fontSize: 16,
        fontWeight: 400,
        minHeight: 56,
        [theme.breakpoints.down('sm')]: {
            minHeight: 40,
        },
    },
    icon: {
        marginLeft: 20,
        marginRight: 8,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 6,
            fontSize: 15,
        },
    },
    notchedOutline: {
        borderColor: theme.colors.neutralPlatinum,
    },
}));
