import React from 'react';

import { GivelifyRadio } from '@givelify/givelify-ui';
import { GivelifyButton, GivelifyButtonProps } from '@givelify/givelify-ui';
import DarkGivelifyButton from '../../../../assets/online-giving/dark-givelify-button.svg';
import LightGivelifyButton from '../../../../assets/online-giving/light-givelify-button.svg';
import PrimaryGivelifyButton from '../../../../assets/online-giving/primary-givelify-button.svg';
import CopyGivingLink from '../../../../components/CopyGivingLink';
import Label from '../../../../components/Label';
import withDownloadable, {
    WithDownloadableProps,
} from '../../../../components/withDownloadable';
import { GiveNowButtonType, GiveNowButtonTypes } from '../../../../types';
import { GIVENOW_BUTTON_URLS } from '../../../../types/const';
import { useTranslations } from '../../../../utils/translations';
import { useStyle } from './style';

const DownloadableButton = withDownloadable<
    GivelifyButtonProps & WithDownloadableProps
>(GivelifyButton);

export const InstructModalContent: React.FC<{ givingLink: string }> = ({
    givingLink,
}) => {
    const cs = useStyle();
    const [selectedButtonType, setSelectedButtonType] =
        React.useState<GiveNowButtonType>('lightGive');

    const { instructModal: im } = useTranslations(
        'onlineGiving.givelifyButton',
        {
            instructModal: [
                'title',
                'desc',
                'downloadButton',
                'goToBuilder',
                'addLink',
                'download',
                'preview',
                ...GiveNowButtonTypes,
            ],
        },
    );

    return (
        <div className={cs.content} data-testid="instruct-modal-content">
            <Label variant="heading3">{im.title}</Label>

            <div className={cs.mainBox}>
                <Label isBold className={cs.description} variant="body1">
                    {im.desc}
                </Label>

                {/* 1. Download image button */}
                <div>
                    <div>
                        <Label
                            isInline
                            className={cs.number}
                            color="primary-blue"
                            variant="heading4"
                        >
                            1.
                        </Label>
                        <Label isInline variant="body1">
                            {im.downloadButton}
                        </Label>
                    </div>
                    <div className={cs.radioBox}>
                        {GiveNowButtonTypes.map((type) => (
                            <GivelifyRadio
                                key={type}
                                labelBold
                                ariaLabel={type}
                                checked={selectedButtonType === type}
                                className={cs.radioButton}
                                label={im[type]}
                                labelPosition="after"
                                name={type}
                                onChange={(t) =>
                                    t ? setSelectedButtonType(type) : {}
                                }
                                testId={`${type}-radio`}
                                variant="primary"
                            />
                        ))}
                    </div>
                    <Label className={cs.preview} variant="body3">
                        {im.preview}
                    </Label>
                    <div className={cs.previewBox}>
                        <img
                            alt={selectedButtonType}
                            className={cs.previewImage}
                            data-testid="button-preview-image"
                            src={
                                selectedButtonType === 'primaryGive'
                                    ? PrimaryGivelifyButton
                                    : selectedButtonType === 'lightGive'
                                    ? LightGivelifyButton
                                    : DarkGivelifyButton
                            }
                        />
                    </div>

                    <DownloadableButton
                        className={cs.downloadButton}
                        filename={`${selectedButtonType}-button`}
                        fileurl={GIVENOW_BUTTON_URLS[selectedButtonType]}
                        text={im.download}
                        variant="primary"
                    />
                </div>

                {/* 2. Go to website builder */}
                <div className={cs.instruction}>
                    <Label
                        isInline
                        className={cs.number}
                        color="primary-blue"
                        variant="heading4"
                    >
                        2.
                    </Label>
                    <Label isInline variant="body1">
                        {im.goToBuilder}
                    </Label>
                </div>

                {/* 3. Add organization giving link */}
                <div>
                    <div className={cs.instruction}>
                        <Label
                            isInline
                            className={cs.number}
                            color="primary-blue"
                            variant="heading4"
                        >
                            3.
                        </Label>
                        <Label isInline variant="body1">
                            {im.addLink}
                        </Label>
                    </div>
                    <CopyGivingLink givingLink={givingLink} />
                </div>
            </div>
        </div>
    );
};
