import { makeStyles, Theme } from '@material-ui/core';

const TABLE_CELL_BACKGROUND_COLOR = 'white';

export default makeStyles((theme: Theme) => ({
    tbHead: {
        boxSizing: 'border-box',
        textAlign: 'left',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 20,
        paddingBottom: 20,
        color: `${theme.colors.grey600} !important`,
        backgroundColor: TABLE_CELL_BACKGROUND_COLOR,
    },
    tbHeadSorted: {
        color: theme.colors.grey600,
    },
    tbHeadSortable: {
        '&:hover': {
            cursor: 'pointer',
            '& $tbHeadIconSortable': {
                display: 'initial',
            },
        },
    },
    tbHeadIconSorted: {},
    tbHeadIconSortable: {
        display: 'none',
    },
    tbTextCenter: {
        textAlign: 'center',
    },
    tbTextRight: {
        textAlign: 'right',
    },
}));
