import React from 'react';
import { GivelifyLabel } from '../../../label';
import { mergeClassNames } from '../../../utils/classNameUtils';
import { GivelifyTableDynamicStyles } from '../../styles';
import { GivelifyTableColumn } from '../../types';
import useStyles from './styles';

interface RenderCellProps<T> {
    row?: T;
    column: GivelifyTableColumn<T>;
    columnIndex: number;
    offset: number[];
    loading?: boolean;
    className?: string;
}

const RenderCell = <T,>({
    row,
    column,
    columnIndex,
    offset,
    loading,
    className,
}: RenderCellProps<T>) => {
    const { tbCell, tbTextCenter, tbTextRight } = useStyles();
    const widthCss =
        column.width &&
        GivelifyTableDynamicStyles({
            width: column.width,
            fixed: column.fixed,
            offset: offset[columnIndex],
        }).tbCellWidth;

    const cellClassName = mergeClassNames(tbCell, widthCss, className);

    if (loading && column.renderLoading) {
        const LoadingComponent = column.renderLoading;
        return (
            <td className={cellClassName}>
                <LoadingComponent align={column.cellAlign || 'left'} />
            </td>
        );
    }

    if (!row) return null;

    if (column.renderCell) {
        const CellComponent = column.renderCell;
        return (
            <td
                className={mergeClassNames(
                    cellClassName,
                    column.cellAlign === 'center' && tbTextCenter,
                    column.cellAlign === 'right' && tbTextRight,
                )}
            >
                <CellComponent
                    align={column.cellAlign || 'left'}
                    cellValue={column.name ? row[column.name] : undefined}
                    row={row}
                />
            </td>
        );
    }

    return (
        <td className={cellClassName}>
            <div
                className={mergeClassNames(
                    column.cellAlign === 'center' && tbTextCenter,
                    column.cellAlign === 'right' && tbTextRight,
                )}
            >
                {column.name && row[column.name] && (
                    <GivelifyLabel
                        variant="body2"
                        text={String(row[column.name])}
                    />
                )}
            </div>
        </td>
    );
};

export default RenderCell;
