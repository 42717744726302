import { GivenAmountFilter } from 'api/hooks';
import * as yup from 'yup';

// ex: 999,999,999 (9 digits + 2 commas)
export const MAX_CHAR_AMOUNT = 11;

export const filterPanelSchema: (
    t: (key: string, obj?: { [key: string]: number }) => string,
) => yup.SchemaOf<GivenAmountFilter> = (t) =>
    yup.object().shape(
        {
            totalGivenMin: yup
                .string()
                .transform((val) => val.replaceAll(',', ''))
                .test({
                    test: (val) => val.length <= MAX_CHAR_AMOUNT,
                    message: t('donors.maxCharactersLimit', {
                        limit: MAX_CHAR_AMOUNT,
                    }),
                })
                .when('totalGivenMax', (totalGivenMax, schema) => {
                    return totalGivenMax
                        ? schema.test(
                              'totalMin',
                              t('donors.minMoreThanMax'),
                              function (value) {
                                  if (!value) return true;
                                  return BigInt(value) <= BigInt(totalGivenMax);
                              },
                          )
                        : schema;
                }),
            totalGivenMax: yup
                .string()
                .transform((val) => val.replaceAll(',', ''))
                .test({
                    test: (val) => val.length <= MAX_CHAR_AMOUNT,
                    message: t('donors.maxCharactersLimit', {
                        limit: MAX_CHAR_AMOUNT,
                    }),
                })
                .when('totalGivenMin', function (totalGivenMin, schema) {
                    return totalGivenMin
                        ? schema.test(
                              'totalMax',
                              t('donors.maxMoreThanMin'),
                              function (value) {
                                  if (!value) return true;
                                  return BigInt(value) >= BigInt(totalGivenMin);
                              },
                          )
                        : schema;
                }),
            lastGivenMin: yup
                .string()
                .transform((val) => val.replaceAll(',', ''))
                .test({
                    test: (val) => val.length <= MAX_CHAR_AMOUNT,
                    message: t('donors.maxCharactersLimit', {
                        limit: MAX_CHAR_AMOUNT,
                    }),
                })
                .when('lastGivenMax', (lastGivenMax, schema) => {
                    return lastGivenMax
                        ? schema.test(
                              'lastMin',
                              t('donors.minMoreThanMax'),
                              function (value) {
                                  if (!value) return true;
                                  return BigInt(value) <= BigInt(lastGivenMax);
                              },
                          )
                        : schema;
                }),
            lastGivenMax: yup
                .string()
                .transform((val) => val.replaceAll(',', ''))
                .test({
                    test: (val) => val.length <= MAX_CHAR_AMOUNT,
                    message: t('donors.maxCharactersLimit', {
                        limit: MAX_CHAR_AMOUNT,
                    }),
                })
                .when('lastGivenMin', function (lastGivenMin, schema) {
                    return lastGivenMin
                        ? schema.test(
                              'lastMax',
                              t('donors.maxMoreThanMin'),
                              function (value) {
                                  if (!value) return true;
                                  return BigInt(value) >= BigInt(lastGivenMin);
                              },
                          )
                        : schema;
                }),
            givingStyleNew: yup.boolean(),
            givingStyleConsistent: yup.boolean(),
            givingStyleOccasional: yup.boolean(),
            givingStyleDecreasing: yup.boolean(),
            givingStyleInactive: yup.boolean(),
            lastGivenOnStart: yup.string().nullable(),
            lastGivenOnEnd: yup.string().nullable(),
        },
        [
            ['lastGivenMin', 'lastGivenMax'],
            ['totalGivenMin', 'totalGivenMax'],
        ],
    );
