import React, { useEffect, useState } from 'react';
import { Address } from '@givelify/givelify-ui';
import { DirectDepositInfo } from '../../../../@types/assets/onboarding';
import CancelModal from '../../components/CancelModal';
import OnboardingTopSection from '../../OnboardingTopSection';
import { AccountDetailsBreadcrumbs } from './AccountDetailsBreadcrumbs';
import AccountInfo from './AccountInfo';
import MailingAddress from './MailingAddress';
import { BankInfoSummary } from './Summary/BankInfoSummary';

interface RequiredBankInfoProps {
    onClose: () => void;
    onSubmit: (d: DirectDepositInfo) => void;
    data: DirectDepositInfo;
    doneeId: number;
    requireAdditionalInfo: boolean;
}

export type RequiredBankInfoStep = 'accountInfo' | 'mailingAddress' | 'summary';

const AccountDetails: React.FC<RequiredBankInfoProps> = (props) => {
    const [data, setData] = useState(props.data);
    const [addressIsSame, setAddressIsSame] = useState(true);
    const [bankingAddress, setBankingAddress] = useState<Address>();
    const [addressIsDirty, setAddressIsDirty] = useState(false);
    const [bankName, setBankName] = useState<string>('');

    const [currentStep, setCurrentStep] =
        useState<RequiredBankInfoStep>('accountInfo');
    const [summaryReached, setSummaryReached] = useState<boolean>(false);

    const [showCancelModal, setShowCancelModal] = useState(false);
    const onCancelClick = (skipModal?: boolean) => {
        if (skipModal) {
            props.onClose();
        } else {
            setShowCancelModal(true);
        }
    };
    const onCancelSubmit = () => props.onClose();
    const onCancelClose = () => setShowCancelModal(false);

    const navigate = (step: RequiredBankInfoStep) => {
        setCurrentStep(summaryReached ? 'summary' : step);
    };

    useEffect(() => {
        if (currentStep === 'summary') {
            setSummaryReached(true);
        }
    }, [currentStep]);

    return (
        <>
            <OnboardingTopSection>
                <AccountDetailsBreadcrumbs
                    accountInfoCompleted={
                        !!data.routingNumber && !!data.accountNumber
                    }
                    active={currentStep}
                    disableNavigation={!summaryReached}
                    mailingAddressCompleted={addressIsDirty}
                    onClick={(value) =>
                        setCurrentStep(value as RequiredBankInfoStep)
                    }
                    summaryCompleted={summaryReached}
                />
            </OnboardingTopSection>
            {currentStep === 'accountInfo' && (
                <AccountInfo
                    bankName={bankName}
                    data={data}
                    onCancelClick={onCancelClick}
                    onContinueClick={(
                        routingNumber,
                        accountNumber,
                        bankName,
                    ) => {
                        setData((prev) => ({
                            ...prev,
                            routingNumber,
                            accountNumber,
                        }));
                        setBankName(bankName);
                        navigate('mailingAddress');
                    }}
                />
            )}
            {currentStep === 'mailingAddress' && (
                <MailingAddress
                    address={data.bankingAddress}
                    addressIsSame={addressIsSame}
                    bankingAddress={bankingAddress}
                    onBackClick={() => setCurrentStep('accountInfo')}
                    onCancelClick={onCancelClick}
                    onContinueClick={(address, addressIsSame) => {
                        setAddressIsDirty(true);
                        setBankingAddress(addressIsSame ? undefined : address);
                        setAddressIsSame(addressIsSame);
                        navigate('summary');
                    }}
                />
            )}
            {currentStep === 'summary' && (
                <BankInfoSummary
                    addressIsSame={addressIsSame}
                    bankName={bankName}
                    bankingAddress={bankingAddress}
                    data={data}
                    doneeId={props.doneeId}
                    onAction={(step) =>
                        setCurrentStep(step as RequiredBankInfoStep)
                    }
                    onBackClick={() => setCurrentStep('mailingAddress')}
                    onCancelClick={onCancelClick}
                    onContinueClick={props.onSubmit}
                />
            )}
            {showCancelModal && (
                <CancelModal
                    onCancel={onCancelClose}
                    onSubmit={onCancelSubmit}
                    page="bankVerification"
                />
            )}
        </>
    );
};

export default AccountDetails;
