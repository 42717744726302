import React, { useMemo } from 'react';
import {
    GivelifyEmailField,
    GivelifyLabel,
    GivelifySelect,
    GivelifyTextField,
} from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    UserInfo,
    UserInfoErrorMessages,
    UserInfoErrors,
} from 'types/userTypes';
import { inviteUserModalStyle } from './inviteUserModalStyle';

interface StepOneProps {
    onUserInfoChange: ({ name, value }) => void;
    userInfo: UserInfo;
    userInfoErrors: UserInfoErrors;
    userInfoErrorMessages?: UserInfoErrorMessages;
    withStep?: boolean;
    isEdit?: boolean;
}

const StepOne: React.FC<StepOneProps> = (props: StepOneProps) => {
    const { officerTitles } = useSelector((state: AppState) => ({
        officerTitles: state.Settings.Officers.officerTitles,
    }));
    const {
        userInfo,
        onUserInfoChange,
        userInfoErrors,
        userInfoErrorMessages,
        withStep = true,
    } = props;
    const styles = inviteUserModalStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            step1: t('labels.step1'),
            userInformation: t('pages.settings.users.add-new-modal.title'),
            allFieldsrequired: t(
                'pages.settings.users.add-new-modal.form.allRequired',
            ),
            firstName: t('pages.settings.users.add-new-modal.form.firstName'),
            lastName: t('pages.settings.users.add-new-modal.form.lastName'),
            title: t('pages.settings.users.add-new-modal.form.title'),
            email: t('pages.settings.users.add-new-modal.form.email'),
            titleExample: t(
                'pages.settings.users.add-new-modal.form.titleExample',
            ),
            willSendLink: t(
                'pages.settings.users.add-new-modal.form.willSendLink',
            ),
        }),
        [t],
    );

    const options = useMemo(() => {
        let result = officerTitles;

        const title = userInfo?.title;
        if (title?.length) {
            result = matchSorter(officerTitles, title);

            const lowerCaseItems = result.map((x) => x?.toLocaleLowerCase());
            const hasDuplicate =
                lowerCaseItems.indexOf(title?.toLocaleLowerCase()) > -1;
            if (!hasDuplicate) result = [title, ...result];
        }

        return result.map((title) => ({
            key: title,
            value: title,
        }));
    }, [officerTitles, userInfo?.title]);

    const handleInputChange = (name, value) => {
        onUserInfoChange({ name, value });
    };

    const onTitleChange = (value) => {
        if (typeof value !== 'string') {
            onUserInfoChange({ name: 'title', value: value?.value || '' });
        } else {
            onUserInfoChange({ name: 'title', value: value });
        }
    };

    return (
        <div style={{ maxWidth: 688 }}>
            {withStep && (
                <GivelifyLabel
                    className={styles.stepHeader}
                    text={copy.step1}
                    variant="heading2"
                />
            )}
            <GivelifyLabel
                className={styles.marginBottom16}
                text={copy.userInformation}
                variant="heading3"
            />
            <GivelifyLabel
                className={styles.allFieldText}
                text={copy.allFieldsrequired}
            />
            <Grid container spacing={3}>
                <Grid item className={styles.firstNameBlock} sm={6} xs={12}>
                    <GivelifyTextField
                        countLength
                        id="first-name"
                        label={copy.firstName}
                        maxLength={55}
                        minLength={1}
                        name="firstName"
                        onChange={(event, value) => {
                            handleInputChange('firstName', value);
                        }}
                        placeholder={copy.firstName}
                        state={userInfoErrors.firstName ? 'error' : 'normal'}
                        value={userInfo?.firstName || ''}
                    />
                </Grid>
                <Grid item className={styles.lastNameBlock} sm={6} xs={12}>
                    <GivelifyTextField
                        countLength
                        id="last-name"
                        label={copy.lastName}
                        maxLength={55}
                        minLength={1}
                        name="lastName"
                        onChange={(event, value) => {
                            handleInputChange('lastName', value);
                        }}
                        placeholder={copy.lastName}
                        state={userInfoErrors.lastName ? 'error' : 'normal'}
                        value={userInfo?.lastName || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GivelifySelect
                        freeSolo
                        fullWidth
                        ariaLabel="title"
                        className={styles.titleSelect}
                        defaultValue={userInfo?.title}
                        error={userInfoErrors.title}
                        id="title"
                        inputLabel="user-title"
                        label={userInfo?.title ? copy.title : null}
                        name="title"
                        onChange={onTitleChange}
                        options={options}
                        placeholder={copy.title}
                    />
                    <div className={styles.titleSelectHelperText}>
                        <GivelifyLabel
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={copy.titleExample}
                        />
                        <GivelifyLabel
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={`${userInfo?.title?.length || 0}/25`}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <GivelifyEmailField
                        boxSize="large"
                        id="email"
                        label={copy.email}
                        leftHelperText={
                            userInfoErrors?.email &&
                            userInfoErrorMessages?.email
                                ? userInfoErrorMessages.email
                                : props.isEdit
                                ? ''
                                : copy.willSendLink
                        }
                        name="email"
                        onChange={(event, value) => {
                            handleInputChange('email', value);
                        }}
                        onEnterSubmit={(value) => {
                            handleInputChange('email', value);
                        }}
                        placeholder={copy.email}
                        state={userInfoErrors.email ? 'error' : 'normal'}
                        value={userInfo?.email || ''}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default StepOne;
