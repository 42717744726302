import React from 'react';
import {
    GivelifyLabel,
    GivelifyButton,
    mergeClassNames,
    RequiredCardState,
} from '@givelify/givelify-ui';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as CompleteSvg } from '../../assets/rc-assets/complete.svg';
import { ReactComponent as SentSvg } from '../../assets/rc-assets/sent.svg';
import { ReactComponent as WarningSvg } from '../../assets/rc-assets/warning.svg';
import { requiredCardStyles } from './requiredCardStyles';
export interface RequiredCardProps {
    testId: string;
    title: string;
    description: string;
    actionButtonText: string;
    retryButtonText: string;
    pendingCaption: string;
    pendingDescription: string;
    warningCaption: string;
    warningDescription: string;
    retryCaption: string;
    retryDescription: string;
    successCaption: string;
    successDescription: string;
    onClick?: () => void;
    badge: JSX.Element;
    className?: string;
    state?: RequiredCardState;
}

export interface RequiredCardVariantProps {
    className?: string;
    state?: RequiredCardState;
    onClick?: () => void;
}

export const RequiredCard: React.FC<RequiredCardProps> = (props) => {
    const { requiredCard } = requiredCardStyles();
    const badge =
        props.state === 'in_progress' ? (
            <SvgIcon
                className="rc-pending-badge"
                component={SentSvg}
                viewBox="0 0 40 34"
            />
        ) : props.state === 'request_callback' ? (
            <SvgIcon
                className="rc-warning-badge"
                component={WarningSvg}
                viewBox="0 0 40 40"
            />
        ) : props.state === 'require_information' ? (
            <SvgIcon
                className="rc-retry-badge"
                component={WarningSvg}
                viewBox="0 0 40 40"
            />
        ) : props.state === 'verified' ? (
            <SvgIcon
                className="rc-complete-badge"
                component={CompleteSvg}
                viewBox="0 0 40 40"
            />
        ) : (
            props.badge
        );
    const caption =
        !props.state || props.state === 'unchecked' ? null : (
            <GivelifyLabel
                className={
                    props.state === 'require_information'
                        ? 'rc-caption-retry'
                        : 'rc-caption'
                }
                color="neutralGrey"
                id="rcCaption"
                text={
                    props.state === 'in_progress'
                        ? props.pendingCaption
                        : props.state === 'request_callback'
                        ? props.warningCaption
                        : props.state === 'require_information'
                        ? props.retryCaption
                        : props.successCaption
                }
                variant="heading5"
            />
        );
    const rcTitleClassName = mergeClassNames(
        'rc-title',
        props.state && props.state !== 'unchecked' && 'rc-title-pending',
    );
    const rcActionClassName = mergeClassNames(
        props.state &&
            props.state !== 'unchecked' &&
            props.state !== 'require_information' &&
            'rc-hidden',
    );
    const rootClassName = mergeClassNames(requiredCard, props.className);
    return (
        <div className={rootClassName} data-testid={props.testId} id="rcBox">
            <div className={rcTitleClassName} id="rcTitleBox">
                <GivelifyLabel
                    id="rcTitle"
                    text={props.title}
                    variant="heading4"
                />
            </div>
            <div className="rc-content" id="rcContent">
                {badge}
                {caption}
                <GivelifyLabel
                    color="neutralGrey"
                    id="rcDesc"
                    text={
                        props.state === 'in_progress'
                            ? props.pendingDescription
                            : props.state === 'request_callback'
                            ? props.warningDescription
                            : props.state === 'require_information'
                            ? props.retryDescription
                            : props.state === 'verified'
                            ? props.successDescription
                            : props.description
                    }
                    variant="body1"
                />
                <GivelifyButton
                    className={rcActionClassName}
                    data-testid="action"
                    id="rcAction"
                    marginTop="auto"
                    onClick={props.onClick}
                    size={props.state === 'unchecked' ? 'large' : 'small'}
                    text={
                        props.state === 'unchecked'
                            ? props.actionButtonText
                            : props.retryButtonText
                    }
                    variant={
                        props.state === 'unchecked' ? 'primary' : 'secondary'
                    }
                    width={248}
                />
            </div>
        </div>
    );
};
