import { TimeFrameValue } from '@givelify/utils';
import { ThunkDispatch } from 'redux-thunk';
import { startAndEndDateQS } from 'utils/timeFrameUtils';
import { AppState, AppActions } from '../..';
import { legacyStartAndEndDateQS } from '../donations/thunks';
import { getStatisticsBaseUrl, getStatisticsThunk } from '../thunk';
import {
    setDonorsCurrentLoading,
    setDonorsCurrentSuccess,
    setDonorsCurrentFailed,
} from './current/actions';
import {
    setDonorsPreviousLoading,
    setDonorsPreviousSuccess,
    setDonorsPreviousFailed,
} from './previous/actions';

export const legacyDonorsEndPoint = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): string =>
    getStatisticsBaseUrl('donors', doneeId) +
    `?distribution&count${legacyStartAndEndDateQS(timeFrame, '&')}`;

export const donorsEndPoint = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): string =>
    getStatisticsBaseUrl('donors', doneeId) +
    `?distribution&count${startAndEndDateQS(timeFrame, '&')}`;

export const getDonorsCurrentTotal = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setDonorsCurrentLoading,
        success: setDonorsCurrentSuccess,
        error: setDonorsCurrentFailed,
        url: legacyDonorsEndPoint(timeFrame, doneeId),
    });

export const getDonorsPreviousTotal = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setDonorsPreviousLoading,
        success: setDonorsPreviousSuccess,
        error: setDonorsPreviousFailed,
        url: legacyDonorsEndPoint(timeFrame, doneeId),
    });
