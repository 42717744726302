import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { matchRowStyle } from 'pages/integrations/components/style';
import { integrationWithoutEnvelope } from 'pages/integrations/IntegrationContent';
import { IntegrationTypes } from 'pages/integrations/types';
import { useAdvancedTranslation } from 'utils/i18n';

const TRANSLATION_KEY = 'pages.integration_envelope_matching';

interface EnvelopeMatchingHeaderProps {
    integration: IntegrationTypes;
}

const EnvelopeMatchingHeader: React.FC<EnvelopeMatchingHeaderProps> = ({
    integration,
}) => {
    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        TRANSLATION_KEY,
    );
    const classes = matchRowStyle();
    const copy = useMemo(
        () => ({
            donorName: scopedATranslate(
                `content.table.heading.name`,
                undefined,
            ),
            memberId: scopedTranslate(`content.table.heading.code`, {
                reportType: !integrationWithoutEnvelope.includes(
                    integration.integrationType,
                )
                    ? integration.abbreviation
                    : '',
                fundCodeLabel: integration.fundCodeLabel,
            }),
        }),
        [scopedTranslate, scopedATranslate, integration],
    );
    return (
        <>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={9}
                md={9}
                sm={6}
                xl={9}
                xs={6}
            >
                <GivelifyLabel bold text={copy.donorName} variant="body2" />
            </Grid>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={3}
                md={3}
                sm={6}
                xl={3}
                xs={6}
            >
                <GivelifyLabel
                    bold
                    className={classes.headerItemRight}
                    text={copy.memberId}
                    variant="body2"
                />
            </Grid>
        </>
    );
};

export default EnvelopeMatchingHeader;
