import React, { MutableRefObject, useMemo, useState } from 'react';
import { GivelifyButton, GivelifyIcon } from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { ClickAwayListener, IconButton, MenuItem } from '@material-ui/core';
import moreIcon from '../../assets/images/more.svg';
import { EVENTS } from '../../utils/clevertap';
import { useTranslation } from '../../utils/i18n';
import { givelithonLaunchModalStyle } from '../style';

interface GivelithonSettingProps {
    onFullScreen: () => void;
    exitFullScreen: () => void;
    fullScreen: boolean;
    onHideDonors: () => void;
    onShowDonors: () => void;
    hideDonors: boolean;
}

export const GivelithonSetting: React.FC<GivelithonSettingProps> = (props) => {
    const classes = givelithonLaunchModalStyle();
    const [open, isOpen] = useState<boolean>(false);
    const handleOpen = () => isOpen(true);
    const handleClose = () => isOpen(false);
    const anchorRef: MutableRefObject<HTMLButtonElement | null> =
        React.useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            hideDonors: t('launchGivelithon.hideDonors'),
            showDonors: t('launchGivelithon.showDonors'),
        }),
        [t],
    );
    const onHideDonor = () => {
        trackEvent(EVENTS.HIDE_DONORS);

        props.onHideDonors();
        handleClose();
    };
    const onShowDonor = () => {
        trackEvent(EVENTS.SHOW_DONORS);

        props.onShowDonors();
        handleClose();
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.settingLaunch}>
                <GivelifyButton
                    className={classes.settingButton}
                    data-testid="toggle-full-screen"
                    icon={
                        props.fullScreen ? (
                            <GivelifyIcon
                                dataTestId="exit-full-screen"
                                variant="exit-full-screen"
                            />
                        ) : (
                            <GivelifyIcon
                                dataTestId="enter-full-screen"
                                variant="full-screen"
                            />
                        )
                    }
                    name="toggleFullScreen"
                    onClick={
                        props.fullScreen
                            ? props.exitFullScreen
                            : props.onFullScreen
                    }
                    variant="icon"
                />
                <div className={classes.launchSettingContainer}>
                    <IconButton
                        ref={anchorRef}
                        className={classes.settingButton}
                        data-testid="givelithon-more-button"
                        id="more-button"
                        onClick={open ? handleClose : handleOpen}
                        size="small"
                    >
                        <img alt="More Setting" src={moreIcon} />
                    </IconButton>
                    {open && (
                        <div className={classes.menuLaunch}>
                            {props.hideDonors && (
                                <MenuItem
                                    className={classes.menuLaunchItem}
                                    data-testid="givelithon-show-donors"
                                    onClick={onShowDonor}
                                >
                                    {copy.showDonors}
                                </MenuItem>
                            )}
                            {!props.hideDonors && (
                                <MenuItem
                                    className={classes.menuLaunchItem}
                                    data-testid="givelithon-hide-donors"
                                    onClick={onHideDonor}
                                >
                                    {copy.hideDonors}
                                </MenuItem>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </ClickAwayListener>
    );
};
