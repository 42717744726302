import React, { useMemo } from 'react';
import {
    GivelifyButton,
    CloseOutlinedIcon,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/ui';
import { useAdvancedTranslation } from 'utils/i18n';
import {
    CloseModal,
    DoneButtonGroup,
    Info,
    LabelInline,
    ModalContent,
    ModalTitle,
    Step,
    WorkSteps,
} from './style';

interface GivingStyleHelpModalProps {
    open: boolean;
    onClose: () => void;
}

const steps = [1, 2, 3, 4, 5];

const GivingStyleHelpModalComponent = (props: GivingStyleHelpModalProps) => {
    const { scopedTranslate: t } = useAdvancedTranslation(
        'dashboard.donorStatus.help',
    );
    const copy = useMemo(
        () => ({
            title: t('title'),
            info: t('info'),
            ok: t('ok'),
            step1: {
                definition: t('step1.definition'),
                description: t('step1.description'),
            },
            step2: {
                definition: t('step2.definition'),
                description: t('step2.description'),
            },
            step3: {
                definition: t('step3.definition'),
                description: t('step3.description'),
            },
            step4: {
                definition: t('step4.definition'),
                description: t('step4.description'),
            },
            step5: {
                definition: t('step5.definition'),
                description: t('step5.description'),
            },
        }),
        [t],
    );

    return (
        <GivelifyModal
            fullWidth
            noPadding
            PaperProps={{
                sx: {
                    maxWidth: '952px',
                },
            }}
            data-testid="help-modal"
            onClose={props.onClose}
            open={props.open}
            sx={{
                zIndex: 1301,
            }}
        >
            <ModalContent>
                <ModalTitle>
                    <GivelifyLabel
                        color="#FFF"
                        text={copy.title}
                        variant={'heading1M'}
                    />
                </ModalTitle>
                <Info>
                    <CloseModal
                        data-testid="close-info-modal"
                        onClick={props.onClose}
                        size={'small'}
                        variant="icon"
                    >
                        <CloseOutlinedIcon />
                    </CloseModal>
                    <GivelifyLabel
                        marginBottom="40px"
                        text={copy.info}
                        variant="body1"
                    />
                    <WorkSteps>
                        {steps.map((step) => (
                            <Step key={step}>
                                <LabelInline
                                    bold
                                    text={copy[`step${step}`].definition}
                                />
                                <LabelInline
                                    text={
                                        ': ' + copy[`step${step}`].description
                                    }
                                />
                            </Step>
                        ))}
                    </WorkSteps>
                    <DoneButtonGroup>
                        <GivelifyButton
                            fullWidth
                            onClick={props.onClose}
                            text={copy.ok}
                            variant="primary"
                        />
                    </DoneButtonGroup>
                </Info>
            </ModalContent>
        </GivelifyModal>
    );
};

export const GivingStyleHelpModal = React.memo(GivingStyleHelpModalComponent);
