import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { greyBorder } from '../../overview/components/styles';

const lightBlueColor = '#f8fdff';
const blueColor = '#cce2ef';

// TODO: Clean up everything here

export const menuStyles = makeStyles(() => {
    return createStyles({
        menuDropdownOptions: {
            width: 'max-content',
        },
        selectRoot: {
            width: 235,
        },
    });
});

export const selectorStyles = makeStyles((theme) => {
    const spacing2 = theme.spacing(2);
    const spacing3 = theme.spacing(3);
    return createStyles({
        multiSelectorMenuOptions: {
            padding: 0,
            '& .Mui-selected': {
                backgroundColor: 'inherit',
            },
            '& .MuiListItem-button:hover': {
                backgroundColor: lightBlueColor,
            },
            '& .MuiMenuItem-root': {
                paddingRight: 50,
                paddingLeft: 4,
                borderBottom: greyBorder,
            },
        },
        normalSelectorMenuOptions: {
            padding: 0,
            '& .Mui-selected': {
                backgroundColor: blueColor,
            },
            '& .MuiListItem-button:hover': {
                backgroundColor: lightBlueColor,
            },
            '& .MuiMenuItem-root': {
                paddingRight: 50,
                paddingLeft: spacing3,
                borderBottom: greyBorder,
            },
        },
        menuOptions: {
            '&, & .MuiMenu-list': {
                padding: 0,
            },
            '& .Mui-selected': {
                backgroundColor: blueColor,
            },
            '& .MuiListItem-button:hover': {
                backgroundColor: lightBlueColor,
            },
            '& .MuiListItem-button': {
                paddingRight: 50,
            },
        },
        newMenuOptions: {
            marginTop: 8,
            '&, & .MuiMenu-list': {
                padding: '26px 0',
                [theme.breakpoints.down(768)]: {
                    padding: '20px 0',
                },
            },
            '& .Mui-selected': {
                backgroundColor: blueColor,
            },
            '& .MuiListItem-button:hover': {
                backgroundColor: lightBlueColor,
            },
            '& .MuiListItem-button': {
                paddingRight: 50,
            },
        },
        userMenuOptions: {
            '& .MuiListItem-button': {
                padding: spacing3,
            },
        },
        newUserMenuOptions: {
            '& .MuiPaper-root': {
                minWidth: '400px',
                '@media (max-width:768px)': {
                    minWidth: '186px',
                },
            },
            '& .MuiListItem-button': {
                padding: spacing3,
            },
        },
        multiSelect: {
            marginRight: spacing2,
        },
        label: {
            marginTop: spacing3,
            marginBottom: spacing2,
            marginLeft: '10px',
            marginRight: spacing2,
        },
        select: {
            borderColor: '#408fda',
            '&:focus-within, &:active, &:focus': {
                borderColor: theme.palette.primary.main,
                boxShadow: 'rgba(64, 143, 218, 0.25) 0 0 0 0.2rem',
            },
            borderRadius: '2rem',
            '& .MuiInputBase-input': {
                paddingLeft: spacing3,
            },
        },
        selector: {
            '& .MuiSelect-icon': {
                marginRight: theme.spacing(2),
            },
        },
    });
});

export const scrollBarStyles = () => ({
    '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-track-piece:start': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-track-piece:end': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#cacaca',
        borderRadius: '25px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#cacaca',
    },
});

export const messengerStyles = makeStyles((theme: Theme) => {
    const spacing2 = theme.spacing(2);
    const spacing3 = theme.spacing(3);
    const spacing5 = theme.spacing(5);
    return createStyles({
        messengerTextBoxStyles: {
            display: 'flex',
            marginTop: spacing2,
            '& .text-area': {
                marginLeft: spacing3,
                minWidth: '50%',
                maxWidth: 700,
            },
            '& .text-field': {
                maxWidth: '100%',
                width: '100%',
                '& .MuiInputBase-root': {
                    backgroundColor: theme.palette.background.paper,
                    minHeight: '32px',
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: spacing3,
                    paddingRight: spacing3,
                    borderRadius: spacing5,
                },
                '& .MuiInputBase-input': {
                    backgroundColor: theme.palette.background.paper,
                    border: 'none',
                    boxShadow: 'none',
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 16,
                    color: theme.colors.navigationTextDefault,
                    paddingRight: 24,
                },
                '& .Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: theme.gas.palette.input.focused.border,
                    },
                },
            },
            '& .error': {
                fontSize: 12,
                color: theme.palette.error.main,
            },
            '& .instruction': {
                fontSize: 12,
                color: '#ccc',
            },
        },
        quickReplyStyles: {
            '& .padding': {
                paddingTop: spacing2,
                paddingBottom: spacing2,
                paddingLeft: spacing3,
                paddingRight: spacing5,
            },
        },
        headingStyles: {
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        repliesStyle: {
            ...{
                maxHeight: '150px',
                overflowY: 'scroll',
                '& .reply': {
                    cursor: 'pointer',
                },
                '& .reply:hover': {
                    backgroundColor: `${theme.palette.primary.main}40`,
                },
            },
            ...scrollBarStyles(),
        },
        listReply: {
            maxHeight: '150px !important',
            padding: '0 !important',
            '& .MuiAutocomplete-option': {
                paddingTop: spacing2,
                paddingBottom: spacing2,
                paddingLeft: spacing3,
                paddingRight: spacing5,
                borderBottom: '1px solid #d8d8d8',
                '&:hover': {
                    background: lightBlueColor,
                },
                '&[data-focus=true]': {
                    background: '#f6f6f6',
                },
                '&[aria-selected="true"]': {
                    background: blueColor,
                },
            },
        },
        replyItem: {
            width: '100%',
        },
    });
});

export const useMessageStyles = makeStyles((theme: Theme) => {
    const spacing3 = theme.spacing(3);
    return createStyles({
        messageStyles: {
            display: 'flex',
            alignItems: 'center',
        },
        inline: {
            backgroundColor: theme.palette.background.paper,
            display: 'inline-flex',
            alignItems: 'center',
            minHeight: '48px',
            padding: '12px 16px',
            marginLeft: spacing3,
            borderRadius: theme.spacing(5),
            border: '1px solid #ccc',
            maxWidth: '100%',
            '& .word-break': {
                wordBreak: 'break-word',
            },
            '& .margin-right': {
                marginRight: spacing3,
            },
            '& *': {
                display: 'inline-block',
            },
        },
    });
});
