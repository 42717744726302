import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { Stack, styled } from '@mui/material';
import {
    ChartButton,
    ChartHeader,
    ChartInfoRibon,
    ChartGraph,
    ChartLegend,
    ChartInfoRibonProps,
} from './components';

const ChartBox = styled(Stack)({
    padding: '32px',
    background: DesignTokens.color.globalGuidingViolet100,
    borderRadius: DesignTokens.measurement.borderRadiusM,
});

export type MainChartData = {
    label: string;
    description: string;
    shortDescription: string;
    misc: ChartInfoRibonProps;
    values: { label: string; value: number }[];
};

export type MainChartViewProps = {
    selected: number;
    onClick: (index: number) => void;
    data: MainChartData[];
};

export const MainChartView: React.FC<MainChartViewProps> = ({
    selected,
    data,
    onClick,
}) => {
    return (
        <ChartBox flexDirection="row" gap={5.25}>
            <Stack gap={3}>
                {data.map((item, index) => (
                    <ChartButton
                        key={`chart-switch-${index}`}
                        active={index === selected}
                        description={item.shortDescription}
                        index={index}
                        onClick={onClick}
                        value={item.label}
                    />
                ))}
            </Stack>
            <Stack alignItems="center" gap={1} justifyContent="center">
                <ChartHeader
                    description={data[selected].description}
                    title={data[selected].label}
                />
                <ChartInfoRibon {...data[selected].misc} />
                <ChartGraph data={data[selected].values} />
                <ChartLegend data={data[selected].values} />
            </Stack>
        </ChartBox>
    );
};
