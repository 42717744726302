import React from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { DirectDepositInfo } from '@givelify/onboarding';
import { useTranslation } from 'react-i18next';
import { AccountDetailStyles } from './accountDetailStyles';
import { AccountInfo } from './AccountInfo';

interface AccountSuccessProps {
    bankingInfo: Omit<
        DirectDepositInfo,
        'bankChequeFile' | 'addressIsSame' | 'status'
    >;
    onReplaceClick: () => void;
    disableReplace?: boolean;
}

export const AccountSuccess: React.FC<AccountSuccessProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newAccount: t(
                'pages.settings.bank-info.customize-account-tab.active-account',
            ),
            success: t(
                'pages.settings.bank-info.customize-account-tab.success',
            ),
            replace: t(
                'pages.settings.bank-info.customize-account-tab.replace',
            ),
        }),
        [t],
    );
    const { bankingInfo, onReplaceClick, disableReplace } = props;
    const { accountSuccess } = AccountDetailStyles();
    return (
        <div className={accountSuccess}>
            <div className="header">
                <GivelifyLabel text={copy.newAccount} variant="heading3" />
                <GivelifyIcon
                    color="#73C997"
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="check-circle"
                />
                <GivelifyLabel className="submitted" text={copy.success} />
                <GivelifyButton
                    disabled={disableReplace}
                    fontSize={16}
                    lineHeight={18}
                    marginLeft="auto"
                    onClick={onReplaceClick}
                    text={copy.replace}
                    variant="link"
                />
            </div>
            <AccountInfo bankingInfo={bankingInfo} className="detailes" />
            <GivelifyTextDivider />
        </div>
    );
};
