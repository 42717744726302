import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSquareUSA = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="22"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 30 22"
    >
        <g clipPath="url(#clip0_4496_40364)">
            <path
                d="M29.4827 22H0.517265C0.231621 22 0 21.7407 0 21.421V0.578973C0 0.259252 0.231621 0 0.517265 0H29.4827C29.7684 0 30 0.259252 30 0.578973V21.421C29.9999 21.7408 29.7683 22 29.4827 22Z"
                fill="#F5F5F5"
            />
            <path
                d="M29.9999 1.69239H0V0.578973C0 0.259252 0.231621 0 0.517265 0H29.4827C29.7684 0 30 0.259252 30 0.578973L29.9999 1.69239Z"
                fill="#FF4B55"
            />
            <path
                d="M29.9999 6.76904H0V8.46137H29.9999V6.76904Z"
                fill="#FF4B55"
            />
            <path
                d="M29.9999 3.38428H0V5.0766H29.9999V3.38428Z"
                fill="#FF4B55"
            />
            <path
                d="M29.9999 11.8462H0.517265C0.231621 11.8462 0 11.5869 0 11.2672V10.1538H29.9999V11.8462Z"
                fill="#FF4B55"
            />
            <path
                d="M29.9999 16.9233H0V18.6157H29.9999V16.9233Z"
                fill="#FF4B55"
            />
            <path
                d="M29.4827 21.9999H0.517265C0.231621 21.9999 0 21.7407 0 21.421V20.3076H29.9999V21.421C29.9999 21.7408 29.7683 21.9999 29.4827 21.9999Z"
                fill="#FF4B55"
            />
            <path
                d="M29.9999 13.5386H0V15.2309H29.9999V13.5386Z"
                fill="#FF4B55"
            />
            <path
                d="M13.4482 0H0.517265C0.231621 0 0 0.259187 0 0.578907V11.2671C0 11.5868 0.231621 11.8461 0.517265 11.8461H13.4483C13.7339 11.8461 13.9656 11.5868 13.9656 11.2671V0.578907C13.9655 0.259187 13.7339 0 13.4482 0Z"
                fill="#41479B"
            />
            <path
                d="M1.45306 1.32883L1.56755 1.71315L1.92948 1.71623C1.97636 1.71669 1.99575 1.78359 1.95808 1.81474L1.66692 2.05543L1.77614 2.44165C1.79032 2.49163 1.7394 2.53308 1.70126 2.50251L1.40683 2.267L1.11239 2.50258C1.07425 2.53308 1.02345 2.49169 1.03751 2.44172L1.14673 2.0555L0.855578 1.8148C0.817902 1.78359 0.837296 1.71676 0.884171 1.7163L1.2461 1.71322L1.3606 1.32889C1.37536 1.27905 1.43817 1.27905 1.45306 1.32883Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.45257 3.33903L1.56706 3.72336L1.92899 3.72644C1.97587 3.7269 1.99526 3.79379 1.95759 3.82494L1.66644 4.06564L1.77565 4.45186C1.78983 4.50183 1.73892 4.54328 1.70077 4.51272L1.40634 4.27721L1.11191 4.51279C1.07376 4.54328 1.02296 4.5019 1.03702 4.45192L1.14624 4.0657L0.855089 3.82501C0.817414 3.79379 0.836808 3.72696 0.883683 3.7265L1.24562 3.72342L1.36011 3.3391C1.37487 3.28932 1.43769 3.28932 1.45257 3.33903Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.45257 5.34978L1.56706 5.7341L1.92899 5.73718C1.97587 5.73764 1.99526 5.80453 1.95759 5.83569L1.66644 6.07638L1.77565 6.4626C1.78983 6.51257 1.73892 6.55402 1.70077 6.52346L1.40634 6.28795L1.11191 6.52353C1.07376 6.55402 1.02296 6.51264 1.03702 6.46267L1.14624 6.07644L0.855089 5.83575C0.817414 5.80453 0.836808 5.7377 0.883683 5.73724L1.24562 5.73416L1.36011 5.34984C1.37487 5.30006 1.43769 5.30006 1.45257 5.34978Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.45257 7.36008L1.56706 7.7444L1.92899 7.74748C1.97587 7.74794 1.99526 7.81484 1.95759 7.84599L1.66644 8.08668L1.77565 8.4729C1.78983 8.52288 1.73892 8.56433 1.70077 8.53377L1.40634 8.29825L1.11191 8.53383C1.07376 8.56433 1.02296 8.52294 1.03702 8.47297L1.14624 8.08675L0.855089 7.84605C0.817414 7.81484 0.836808 7.74801 0.883683 7.74755L1.24562 7.74447L1.36011 7.36014C1.37487 7.3103 1.43769 7.3103 1.45257 7.36008Z"
                fill="#F5F5F5"
            />
            <path
                d="M1.45257 9.37038L1.56706 9.7547L1.92899 9.75778C1.97587 9.75824 1.99526 9.82514 1.95759 9.85629L1.66644 10.097L1.77565 10.4832C1.78983 10.5332 1.73892 10.5746 1.70077 10.5441L1.40634 10.3086L1.11191 10.5441C1.07376 10.5746 1.02296 10.5332 1.03702 10.4833L1.14624 10.097L0.855089 9.85636C0.817414 9.82514 0.836808 9.75831 0.883683 9.75785L1.24562 9.75477L1.36011 9.37045C1.37487 9.32054 1.43769 9.32054 1.45257 9.37038Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.84661 2.31463L2.9611 2.69895L3.32304 2.70203C3.36991 2.70249 3.38931 2.76939 3.35163 2.80054L3.06048 3.04123L3.1697 3.42745C3.18388 3.47743 3.13296 3.51888 3.09481 3.48831L2.80038 3.25274L2.50595 3.48831C2.4678 3.51881 2.417 3.47743 2.43107 3.42745L2.54028 3.04123L2.24913 2.80054C2.21146 2.76932 2.23085 2.70249 2.27773 2.70203L2.63966 2.69895L2.75415 2.31463C2.76886 2.26492 2.83179 2.26492 2.84661 2.31463Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.84661 4.32493L2.9611 4.70925L3.32304 4.71233C3.36991 4.71279 3.38931 4.77969 3.35163 4.81084L3.06048 5.05153L3.1697 5.43775C3.18388 5.48773 3.13296 5.52918 3.09481 5.49862L2.80038 5.26304L2.50595 5.49862C2.4678 5.52911 2.417 5.48779 2.43107 5.43775L2.54028 5.05153L2.24913 4.81084C2.21146 4.77962 2.23085 4.71279 2.27773 4.71233L2.63966 4.70925L2.75415 4.32493C2.76886 4.27515 2.83179 4.27515 2.84661 4.32493Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.84661 6.33519L2.9611 6.71951L3.32304 6.72259C3.36991 6.72305 3.38931 6.78994 3.35163 6.8211L3.06048 7.06179L3.1697 7.44801C3.18388 7.49798 3.13296 7.53943 3.09481 7.50887L2.80038 7.27329L2.50595 7.50887C2.4678 7.53937 2.417 7.49798 2.43107 7.44801L2.54028 7.06179L2.24913 6.8211C2.21146 6.78988 2.23085 6.72305 2.27773 6.72259L2.63966 6.71951L2.75415 6.33519C2.76886 6.28541 2.83179 6.28541 2.84661 6.33519Z"
                fill="#F5F5F5"
            />
            <path
                d="M2.84661 8.34588L2.9611 8.7302L3.32304 8.73328C3.36991 8.73374 3.38931 8.80064 3.35163 8.83179L3.06048 9.07248L3.1697 9.4587C3.18388 9.50868 3.13296 9.55013 3.09481 9.51956L2.80038 9.28399L2.50595 9.51956C2.4678 9.55006 2.417 9.50868 2.43107 9.4587L2.54028 9.07248L2.24913 8.83179C2.21146 8.80057 2.23085 8.73374 2.27773 8.73328L2.63966 8.7302L2.75415 8.34588C2.76886 8.29617 2.83179 8.29617 2.84661 8.34588Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.2409 1.32884L4.35539 1.71316L4.71732 1.71624C4.7642 1.7167 4.78359 1.78359 4.74592 1.81475L4.45477 2.05544L4.56398 2.44166C4.57816 2.49164 4.52725 2.53308 4.4891 2.50252L4.19467 2.26695L3.90024 2.50252C3.86209 2.53302 3.81129 2.49164 3.82535 2.44166L3.93457 2.05544L3.64342 1.81475C3.60574 1.78353 3.62514 1.7167 3.67201 1.71624L4.03395 1.71316L4.14844 1.32884C4.1632 1.27906 4.22607 1.27906 4.2409 1.32884Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.2409 3.33904L4.35539 3.72336L4.71732 3.72644C4.7642 3.7269 4.78359 3.7938 4.74592 3.82495L4.45477 4.06564L4.56398 4.45187C4.57816 4.50184 4.52725 4.54329 4.4891 4.51273L4.19467 4.27715L3.90024 4.51273C3.86209 4.54322 3.81129 4.50184 3.82535 4.45187L3.93457 4.06564L3.64342 3.82495C3.60574 3.79373 3.62514 3.7269 3.67201 3.72644L4.03395 3.72336L4.14844 3.33904C4.1632 3.28933 4.22607 3.28933 4.2409 3.33904Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.2409 5.34978L4.35539 5.73411L4.71732 5.73719C4.7642 5.73765 4.78359 5.80454 4.74592 5.8357L4.45477 6.07639L4.56398 6.46261C4.57816 6.51259 4.52725 6.55403 4.4891 6.52347L4.19467 6.2879L3.90024 6.52347C3.86209 6.55397 3.81129 6.51259 3.82535 6.46261L3.93457 6.07639L3.64342 5.8357C3.60574 5.80448 3.62514 5.73765 3.67201 5.73719L4.03395 5.73411L4.14844 5.34978C4.1632 5.30007 4.22607 5.30007 4.2409 5.34978Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.2409 7.36009L4.35539 7.74441L4.71732 7.74749C4.7642 7.74795 4.78359 7.81484 4.74592 7.846L4.45477 8.08669L4.56398 8.47291C4.57816 8.52289 4.52725 8.56434 4.4891 8.53377L4.19467 8.2982L3.90024 8.53377C3.86209 8.56427 3.81129 8.52289 3.82535 8.47291L3.93457 8.08669L3.64342 7.846C3.60574 7.81478 3.62514 7.74795 3.67201 7.74749L4.03395 7.74441L4.14844 7.36009C4.1632 7.31031 4.22607 7.31031 4.2409 7.36009Z"
                fill="#F5F5F5"
            />
            <path
                d="M4.2409 9.37039L4.35539 9.75471L4.71732 9.75779C4.7642 9.75825 4.78359 9.82515 4.74592 9.8563L4.45477 10.097L4.56398 10.4832C4.57816 10.5332 4.52725 10.5746 4.4891 10.5441L4.19467 10.3085L3.90024 10.5441C3.86209 10.5746 3.81129 10.5332 3.82535 10.4832L3.93457 10.097L3.64342 9.8563C3.60574 9.82508 3.62514 9.75825 3.67201 9.75779L4.03395 9.75471L4.14844 9.37039C4.1632 9.32055 4.22607 9.32055 4.2409 9.37039Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.63519 2.31463L5.74968 2.69895L6.11161 2.70203C6.15849 2.70249 6.17788 2.76939 6.1402 2.80054L5.84905 3.04123L5.95827 3.42745C5.97245 3.47743 5.92153 3.51888 5.88339 3.48831L5.58896 3.25274L5.29452 3.48831C5.25638 3.51881 5.20558 3.47743 5.21964 3.42745L5.32886 3.04123L5.03771 2.80054C5.00003 2.76932 5.01943 2.70249 5.0663 2.70203L5.42823 2.69895L5.54273 2.31463C5.55743 2.26492 5.62036 2.26492 5.63519 2.31463Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.63519 4.32493L5.74968 4.70925L6.11161 4.71233C6.15849 4.71279 6.17788 4.77969 6.1402 4.81084L5.84905 5.05153L5.95827 5.43775C5.97245 5.48773 5.92153 5.52918 5.88339 5.49862L5.58896 5.26304L5.29452 5.49862C5.25638 5.52911 5.20558 5.48779 5.21964 5.43775L5.32886 5.05153L5.03771 4.81084C5.00003 4.77962 5.01943 4.71279 5.0663 4.71233L5.42823 4.70925L5.54273 4.32493C5.55743 4.27515 5.62036 4.27515 5.63519 4.32493Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.63519 6.33519L5.74968 6.71951L6.11161 6.72259C6.15849 6.72305 6.17788 6.78994 6.1402 6.8211L5.84905 7.06179L5.95827 7.44801C5.97245 7.49798 5.92153 7.53943 5.88339 7.50887L5.58896 7.27329L5.29452 7.50887C5.25638 7.53937 5.20558 7.49798 5.21964 7.44801L5.32886 7.06179L5.03771 6.8211C5.00003 6.78988 5.01943 6.72305 5.0663 6.72259L5.42823 6.71951L5.54273 6.33519C5.55743 6.28541 5.62036 6.28541 5.63519 6.33519Z"
                fill="#F5F5F5"
            />
            <path
                d="M5.63519 8.34588L5.74968 8.7302L6.11161 8.73328C6.15849 8.73374 6.17788 8.80064 6.1402 8.83179L5.84905 9.07248L5.95827 9.4587C5.97245 9.50868 5.92153 9.55013 5.88339 9.51956L5.58896 9.28399L5.29452 9.51956C5.25638 9.55006 5.20558 9.50868 5.21964 9.4587L5.32886 9.07248L5.03771 8.83179C5.00003 8.80057 5.01943 8.73374 5.0663 8.73328L5.42823 8.7302L5.54273 8.34588C5.55743 8.29617 5.62036 8.29617 5.63519 8.34588Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.02929 1.32884L7.14378 1.71316L7.50571 1.71624C7.55259 1.7167 7.57198 1.78359 7.5343 1.81475L7.24315 2.05544L7.35237 2.44166C7.36655 2.49164 7.31563 2.53308 7.27749 2.50252L6.983 2.26695L6.68857 2.50252C6.65042 2.53302 6.59962 2.49164 6.61368 2.44166L6.7229 2.05544L6.43175 1.81475C6.39407 1.78353 6.41347 1.7167 6.46034 1.71624L6.82228 1.71316L6.93677 1.32884C6.95159 1.27906 7.01446 1.27906 7.02929 1.32884Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.02929 3.33904L7.14378 3.72336L7.50571 3.72644C7.55259 3.7269 7.57198 3.7938 7.5343 3.82495L7.24315 4.06564L7.35237 4.45187C7.36655 4.50184 7.31563 4.54329 7.27749 4.51273L6.983 4.27715L6.68857 4.51273C6.65042 4.54322 6.59962 4.50184 6.61368 4.45187L6.7229 4.06564L6.43175 3.82495C6.39407 3.79373 6.41347 3.7269 6.46034 3.72644L6.82228 3.72336L6.93677 3.33904C6.95159 3.28933 7.01446 3.28933 7.02929 3.33904Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.02929 5.34978L7.14378 5.73411L7.50571 5.73719C7.55259 5.73765 7.57198 5.80454 7.5343 5.8357L7.24315 6.07639L7.35237 6.46261C7.36655 6.51259 7.31563 6.55403 7.27749 6.52347L6.983 6.2879L6.68857 6.52347C6.65042 6.55397 6.59962 6.51259 6.61368 6.46261L6.7229 6.07639L6.43175 5.8357C6.39407 5.80448 6.41347 5.73765 6.46034 5.73719L6.82228 5.73411L6.93677 5.34978C6.95159 5.30007 7.01446 5.30007 7.02929 5.34978Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.02929 7.36009L7.14378 7.74441L7.50571 7.74749C7.55259 7.74795 7.57198 7.81484 7.5343 7.846L7.24315 8.08669L7.35237 8.47291C7.36655 8.52289 7.31563 8.56434 7.27749 8.53377L6.983 8.2982L6.68857 8.53377C6.65042 8.56427 6.59962 8.52289 6.61368 8.47291L6.7229 8.08669L6.43175 7.846C6.39407 7.81478 6.41347 7.74795 6.46034 7.74749L6.82228 7.74441L6.93677 7.36009C6.95159 7.31031 7.01446 7.31031 7.02929 7.36009Z"
                fill="#F5F5F5"
            />
            <path
                d="M7.02929 9.37039L7.14378 9.75471L7.50571 9.75779C7.55259 9.75825 7.57198 9.82515 7.5343 9.8563L7.24315 10.097L7.35237 10.4832C7.36655 10.5332 7.31563 10.5746 7.27749 10.5441L6.983 10.3085L6.68857 10.5441C6.65042 10.5746 6.59962 10.5332 6.61368 10.4832L6.7229 10.097L6.43175 9.8563C6.39407 9.82508 6.41347 9.75825 6.46034 9.75779L6.82228 9.75471L6.93677 9.37039C6.95159 9.32055 7.01446 9.32055 7.02929 9.37039Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.42327 2.31463L8.53776 2.69895L8.8997 2.70203C8.94657 2.70249 8.96597 2.76939 8.92829 2.80054L8.63714 3.04123L8.74636 3.42745C8.76054 3.47743 8.70962 3.51888 8.67148 3.48831L8.37704 3.25274L8.08261 3.48831C8.04446 3.51881 7.99366 3.47743 8.00773 3.42745L8.11694 3.04123L7.82579 2.80054C7.78812 2.76932 7.80751 2.70249 7.85439 2.70203L8.21632 2.69895L8.33081 2.31463C8.34564 2.26492 8.40845 2.26492 8.42327 2.31463Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.42327 4.32493L8.53776 4.70925L8.8997 4.71233C8.94657 4.71279 8.96597 4.77969 8.92829 4.81084L8.63714 5.05153L8.74636 5.43775C8.76054 5.48773 8.70962 5.52918 8.67148 5.49862L8.37704 5.26304L8.08261 5.49862C8.04446 5.52911 7.99366 5.48779 8.00773 5.43775L8.11694 5.05153L7.82579 4.81084C7.78812 4.77962 7.80751 4.71279 7.85439 4.71233L8.21632 4.70925L8.33081 4.32493C8.34564 4.27515 8.40845 4.27515 8.42327 4.32493Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.42327 6.33519L8.53776 6.71951L8.8997 6.72259C8.94657 6.72305 8.96597 6.78994 8.92829 6.8211L8.63714 7.06179L8.74636 7.44801C8.76054 7.49798 8.70962 7.53943 8.67148 7.50887L8.37704 7.27329L8.08261 7.50887C8.04446 7.53937 7.99366 7.49798 8.00773 7.44801L8.11694 7.06179L7.82579 6.8211C7.78812 6.78988 7.80751 6.72305 7.85439 6.72259L8.21632 6.71951L8.33081 6.33519C8.34564 6.28541 8.40845 6.28541 8.42327 6.33519Z"
                fill="#F5F5F5"
            />
            <path
                d="M8.42327 8.34588L8.53776 8.7302L8.8997 8.73328C8.94657 8.73374 8.96597 8.80064 8.92829 8.83179L8.63714 9.07248L8.74636 9.4587C8.76054 9.50868 8.70962 9.55013 8.67148 9.51956L8.37704 9.28399L8.08261 9.51956C8.04446 9.55006 7.99366 9.50868 8.00773 9.4587L8.11694 9.07248L7.82579 8.83179C7.78812 8.80057 7.80751 8.73374 7.85439 8.73328L8.21632 8.7302L8.33081 8.34588C8.34564 8.29617 8.40845 8.29617 8.42327 8.34588Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.81756 1.32884L9.93205 1.71316L10.294 1.71624C10.3409 1.7167 10.3603 1.78359 10.3226 1.81475L10.0314 2.05544L10.1406 2.44166C10.1548 2.49164 10.1039 2.53308 10.0658 2.50252L9.77133 2.26695L9.4769 2.50252C9.43875 2.53302 9.38795 2.49164 9.40201 2.44166L9.51123 2.05544L9.22008 1.81475C9.1824 1.78353 9.2018 1.7167 9.24867 1.71624L9.61061 1.71316L9.7251 1.32884C9.73981 1.27906 9.80273 1.27906 9.81756 1.32884Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.81756 3.33904L9.93205 3.72336L10.294 3.72644C10.3409 3.7269 10.3603 3.7938 10.3226 3.82495L10.0314 4.06564L10.1406 4.45187C10.1548 4.50184 10.1039 4.54329 10.0658 4.51273L9.77133 4.27715L9.4769 4.51273C9.43875 4.54322 9.38795 4.50184 9.40201 4.45187L9.51123 4.06564L9.22008 3.82495C9.1824 3.79373 9.2018 3.7269 9.24867 3.72644L9.61061 3.72336L9.7251 3.33904C9.73981 3.28933 9.80273 3.28933 9.81756 3.33904Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.81756 5.34978L9.93205 5.73411L10.294 5.73719C10.3409 5.73765 10.3603 5.80454 10.3226 5.8357L10.0314 6.07639L10.1406 6.46261C10.1548 6.51259 10.1039 6.55403 10.0658 6.52347L9.77133 6.2879L9.4769 6.52347C9.43875 6.55397 9.38795 6.51259 9.40201 6.46261L9.51123 6.07639L9.22008 5.8357C9.1824 5.80448 9.2018 5.73765 9.24867 5.73719L9.61061 5.73411L9.7251 5.34978C9.73981 5.30007 9.80273 5.30007 9.81756 5.34978Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.81756 7.36009L9.93205 7.74441L10.294 7.74749C10.3409 7.74795 10.3603 7.81484 10.3226 7.846L10.0314 8.08669L10.1406 8.47291C10.1548 8.52289 10.1039 8.56434 10.0658 8.53377L9.77133 8.2982L9.4769 8.53377C9.43875 8.56427 9.38795 8.52289 9.40201 8.47291L9.51123 8.08669L9.22008 7.846C9.1824 7.81478 9.2018 7.74795 9.24867 7.74749L9.61061 7.74441L9.7251 7.36009C9.73981 7.31031 9.80273 7.31031 9.81756 7.36009Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.81756 9.37039L9.93205 9.75471L10.294 9.75779C10.3409 9.75825 10.3603 9.82515 10.3226 9.8563L10.0314 10.097L10.1406 10.4832C10.1548 10.5332 10.1039 10.5746 10.0658 10.5441L9.77133 10.3085L9.4769 10.5441C9.43875 10.5746 9.38795 10.5332 9.40201 10.4832L9.51123 10.097L9.22008 9.8563C9.1824 9.82508 9.2018 9.75825 9.24867 9.75779L9.61061 9.75471L9.7251 9.37039C9.73981 9.32055 9.80273 9.32055 9.81756 9.37039Z"
                fill="#F5F5F5"
            />
            <path
                d="M11.2114 2.31463L11.3258 2.69895L11.6878 2.70203C11.7347 2.70249 11.7541 2.76939 11.7164 2.80054L11.4252 3.04123L11.5344 3.42745C11.5486 3.47743 11.4977 3.51888 11.4596 3.48831L11.1651 3.25274L10.8707 3.48831C10.8325 3.51881 10.7817 3.47743 10.7958 3.42745L10.905 3.04123L10.6139 2.80054C10.5762 2.76932 10.5956 2.70249 10.6425 2.70203L11.0044 2.69895L11.1189 2.31463C11.1337 2.26492 11.1966 2.26492 11.2114 2.31463Z"
                fill="#F5F5F5"
            />
            <path
                d="M11.2114 4.32493L11.3258 4.70925L11.6878 4.71233C11.7347 4.71279 11.7541 4.77969 11.7164 4.81084L11.4252 5.05153L11.5344 5.43775C11.5486 5.48773 11.4977 5.52918 11.4596 5.49862L11.1651 5.26304L10.8707 5.49862C10.8325 5.52911 10.7817 5.48779 10.7958 5.43775L10.905 5.05153L10.6139 4.81084C10.5762 4.77962 10.5956 4.71279 10.6425 4.71233L11.0044 4.70925L11.1189 4.32493C11.1337 4.27515 11.1966 4.27515 11.2114 4.32493Z"
                fill="#F5F5F5"
            />
            <path
                d="M11.2114 6.33519L11.3258 6.71951L11.6878 6.72259C11.7347 6.72305 11.7541 6.78994 11.7164 6.8211L11.4252 7.06179L11.5344 7.44801C11.5486 7.49798 11.4977 7.53943 11.4596 7.50887L11.1651 7.27329L10.8707 7.50887C10.8325 7.53937 10.7817 7.49798 10.7958 7.44801L10.905 7.06179L10.6139 6.8211C10.5762 6.78988 10.5956 6.72305 10.6425 6.72259L11.0044 6.71951L11.1189 6.33519C11.1337 6.28541 11.1966 6.28541 11.2114 6.33519Z"
                fill="#F5F5F5"
            />
            <path
                d="M11.2114 8.34588L11.3258 8.7302L11.6878 8.73328C11.7347 8.73374 11.7541 8.80064 11.7164 8.83179L11.4252 9.07248L11.5344 9.4587C11.5486 9.50868 11.4977 9.55013 11.4596 9.51956L11.1651 9.28399L10.8707 9.51956C10.8325 9.55006 10.7817 9.50868 10.7958 9.4587L10.905 9.07248L10.6139 8.83179C10.5762 8.80057 10.5956 8.73374 10.6425 8.73328L11.0044 8.7302L11.1189 8.34588C11.1337 8.29617 11.1966 8.29617 11.2114 8.34588Z"
                fill="#F5F5F5"
            />
            <path
                d="M12.6056 1.32884L12.7201 1.71316L13.0821 1.71624C13.1289 1.7167 13.1483 1.78359 13.1107 1.81475L12.8195 2.05544L12.9287 2.44166C12.9429 2.49164 12.892 2.53308 12.8538 2.50252L12.5594 2.26695L12.265 2.50252C12.2268 2.53302 12.176 2.49164 12.1901 2.44166L12.2993 2.05544L12.0082 1.81475C11.9705 1.78353 11.9899 1.7167 12.0368 1.71624L12.3987 1.71316L12.5132 1.32884C12.5279 1.27906 12.5908 1.27906 12.6056 1.32884Z"
                fill="#F5F5F5"
            />
            <path
                d="M12.6056 3.33904L12.7201 3.72336L13.0821 3.72644C13.1289 3.7269 13.1483 3.7938 13.1107 3.82495L12.8195 4.06564L12.9287 4.45187C12.9429 4.50184 12.892 4.54329 12.8538 4.51273L12.5594 4.27715L12.265 4.51273C12.2268 4.54322 12.176 4.50184 12.1901 4.45187L12.2993 4.06564L12.0082 3.82495C11.9705 3.79373 11.9899 3.7269 12.0368 3.72644L12.3987 3.72336L12.5132 3.33904C12.5279 3.28933 12.5908 3.28933 12.6056 3.33904Z"
                fill="#F5F5F5"
            />
            <path
                d="M12.6056 5.34978L12.7201 5.73411L13.0821 5.73719C13.1289 5.73765 13.1483 5.80454 13.1107 5.8357L12.8195 6.07639L12.9287 6.46261C12.9429 6.51259 12.892 6.55403 12.8538 6.52347L12.5594 6.2879L12.265 6.52347C12.2268 6.55397 12.176 6.51259 12.1901 6.46261L12.2993 6.07639L12.0082 5.8357C11.9705 5.80448 11.9899 5.73765 12.0368 5.73719L12.3987 5.73411L12.5132 5.34978C12.5279 5.30007 12.5908 5.30007 12.6056 5.34978Z"
                fill="#F5F5F5"
            />
            <path
                d="M12.6056 7.36009L12.7201 7.74441L13.0821 7.74749C13.1289 7.74795 13.1483 7.81484 13.1107 7.846L12.8195 8.08669L12.9287 8.47291C12.9429 8.52289 12.892 8.56434 12.8538 8.53377L12.5594 8.2982L12.265 8.53377C12.2268 8.56427 12.176 8.52289 12.1901 8.47291L12.2993 8.08669L12.0082 7.846C11.9705 7.81478 11.9899 7.74795 12.0368 7.74749L12.3987 7.74441L12.5132 7.36009C12.5279 7.31031 12.5908 7.31031 12.6056 7.36009Z"
                fill="#F5F5F5"
            />
            <path
                d="M12.6056 9.37039L12.7201 9.75471L13.0821 9.75779C13.1289 9.75825 13.1483 9.82515 13.1107 9.8563L12.8195 10.097L12.9287 10.4832C12.9429 10.5332 12.892 10.5746 12.8538 10.5441L12.5594 10.3085L12.265 10.5441C12.2268 10.5746 12.176 10.5332 12.1901 10.4832L12.2993 10.097L12.0082 9.8563C11.9705 9.82508 11.9899 9.75825 12.0368 9.75779L12.3987 9.75471L12.5132 9.37039C12.5279 9.32055 12.5908 9.32055 12.6056 9.37039Z"
                fill="#F5F5F5"
            />
        </g>
        <defs>
            <clipPath id="clip0_4496_40364">
                <rect fill="white" height="22" rx="2" width="30" />
            </clipPath>
        </defs>
    </svg>
);

export const SquareUSAIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSquareUSA}
        fontSize={fontSize}
    />
);
