import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const MessagesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 56px)',
    [theme.breakpoints.down('mobile')]: {
        maxWidth: '100%',
    },
}));

export const BlueBorderBottom = styled('div')(({ theme }) => ({
    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: DesignTokens.color.globalGuidingViolet100,
}));
