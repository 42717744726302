import React, { useMemo } from 'react';
import {
    DesignTokens,
    GivelifyFormTextField,
    GivelifyFormRadio,
    GivelifyLabel,
    GivelifyFormAutocomplete,
} from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { OnboardingActions } from '../../../OnboardingActions';
import OnboardingPage from '../../../OnboardingPage';
import { UseTaxIdContext } from '../TaxIdContext';
import { StyledForm } from './style';
import { OrgInfoFormSchema } from './types';

const congregationSizeList: string[] = [
    '1 - 99',
    '100 - 499',
    '500 - 1,499',
    '1,500 - 4,999',
    '5,000 - 9,999',
    '10,000 +',
];

const OrgInfoFormView: React.FC = () => {
    const { formState } = useFormContext();
    const { t } = useTranslation(I18N_NAMESPACE);

    const copy = useMemo(
        () => ({
            header: t('taxId.organizationInfoPage.header'),
            orgYear: t('taxId.organizationInfoPage.orgYear'),
            denomination: t('taxId.organizationInfoPage.denomination'),
            membersCount: t('taxId.organizationInfoPage.membersCount'),
        }),
        [t],
    );

    const {
        denominationOptions,
        organizationType,
        onBackClick,
        onCancelClick,
    } = UseTaxIdContext();

    return (
        <StyledForm>
            <GivelifyLabel text={copy.header} variant="heading1S" />

            <div>
                <GivelifyFormTextField<OrgInfoFormSchema>
                    fullWidth
                    autoComplete="off"
                    className="input-field"
                    id="organizationYear"
                    label={copy.orgYear}
                    maskOptions={{ mask: '0000' }}
                    name="organizationYear"
                    placeholder={copy.orgYear}
                    size="large"
                />
            </div>
            {organizationType === 'church' ? (
                <>
                    <div>
                        <GivelifyFormAutocomplete
                            allowCustomInput
                            autoSelect
                            fullWidth
                            id="religiousAffiliation"
                            label={copy.denomination}
                            name="religiousAffiliation"
                            options={denominationOptions}
                            placeholder={copy.denomination}
                        />
                    </div>
                    <div>
                        <GivelifyLabel
                            color={DesignTokens.color.textSecondary}
                            fontSize={16}
                            fontWeight={700}
                            margin="24px 0 12px 0"
                            text={copy.membersCount}
                            variant="body2"
                        />
                        <div className="congregationSize-container">
                            {congregationSizeList.map((size) => (
                                <div
                                    key={size}
                                    className="congregationSize-item"
                                >
                                    <GivelifyFormRadio
                                        classes={{
                                            root: 'congregationSize-root',
                                            checked: 'congregationSize-checked',
                                        }}
                                        label={size}
                                        name="congregationSize"
                                        value={size}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
            <OnboardingActions
                disableContinue={!formState.isValid}
                onBack={onBackClick}
                onCancel={() => onCancelClick(false)}
            />
        </StyledForm>
    );
};

const OrgInfoWrapper: React.FC = () => {
    return (
        <TrackingProvider trackPageVisit pageName="EIN Federal Tax Id Org Info">
            <OnboardingPage
                content={<OrgInfoFormView />}
                informationContent={null}
            />
        </TrackingProvider>
    );
};

export default OrgInfoWrapper;
