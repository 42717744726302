import { makeStyles, Theme } from '@material-ui/core/styles';

export const useTableClasses = makeStyles((theme: Theme) => ({
    table: {
        '& th, & td': {
            borderLeft: 0,
            borderRight: 0,
        },
        '& > thead': {
            '& > tr > td': {
                fontWeight: 900,
            },
            '& > tr > th': {
                paddingLeft: theme.spacing(2),
                fontSize: 14,
            },
            '& .MuiTableRow-head:first-child': {
                '& > th': {
                    padding: theme.spacing(3),
                },
            },
        },
        '& > tbody': {
            '& > tr:nth-child(odd) td': {
                backgroundColor: '#ffffff',
            },
            '& > tr:nth-child(even) td': {
                background: '#f9f9f9',
            },
            '& > tr ': {
                '& > td': {
                    padding: theme.spacing(4, 3),
                    whiteSpace: 'nowrap',
                    lineHeight: '16px',
                    '& p': {
                        margin: 0,
                    },
                },
                '& > td:first-child': {
                    position: 'sticky',
                    whiteSpace: 'nowrap',
                },
            },
        },
    },
}));
