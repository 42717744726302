import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
    reportLinkWrapper: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        height: 24,
        color: theme.palette.primary.main,
        '& svg': {
            width: 24,
            height: 24,
            marginRight: 0,
        },
    },
    loader: {
        width: '16px !important',
        height: '16px !important',
        '& svg': {
            width: 16,
            height: 16,
        },
        margin: '0 4px !important',
    },
    iconWithLabel: {
        marginRight: '2px !important',
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
    waitIcon: {
        width: '14px !important',
        height: '14px !important',
        margin: '0 5px !important',
    },
}));
