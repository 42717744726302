import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isValidAddress } from '@givelify/givelify-ui';
import {
    AppProfile,
    DirectDepositInfo,
    PrimaryRepresentativeInfo,
    TaxIdInfo,
} from '../@types/assets/onboarding';
import { Step } from '../customizeProfile/CustomizeProfileModalComponents';
import { convertDoneeToOnboardingInfo } from '../utils/convertDoneeToOnboardingInfo';
import { getBankingAddress } from '../utils/getBankingAddress';
import { OnboardingProps } from './OnboardingProps';
import { OnboardingView } from './OnboardingView';

export const OnboardingContainer: React.FC<OnboardingProps> = React.memo(
    ({
        donee,
        cardProps,
        user,
        showYodlee,
        settingsUsersPath,
        appProfileFormProps,
        newOnboarding,
        onVerifiedClose,
        onProfileClose,
        hideProfile,
        hideVerified,
        startedOnboardingV2,
        onOnboardingV2Start,
    }) => {
        useEffect(() => {
            if (!newOnboarding || startedOnboardingV2) return;

            onOnboardingV2Start?.();
            // listen to changes in newOnboarding and startedOnboardingV2
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [newOnboarding, startedOnboardingV2]);

        const onboarding = convertDoneeToOnboardingInfo(donee);
        const [openReqInfoModal, setOpenReqInfoModal] = useState(false);
        const [openReqInfoPage, setOpenReqInfoPage] = useState<
            'banking' | 'taxId' | 'rep' | undefined
        >(undefined);
        const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);
        const [appProfile, setAppProfile] = useState<AppProfile>(
            onboarding.appProfile,
        );
        const [directDeposit, setDirectDeposit] = useState<DirectDepositInfo>(
            getBankingAddress(
                onboarding.directDeposit,
                onboarding.appProfile.organizationAddress,
            ),
        );
        const [taxId, setTaxId] = useState<TaxIdInfo>(onboarding.taxId);
        const [primaryRepresentative, setPrimaryRepresentative] =
            useState<PrimaryRepresentativeInfo>(
                onboarding.primaryRepresentative,
            );

        const [card1Dismissed, setCard1Dismissed] = useState(
            newOnboarding && donee.onboarding.hasMid ? hideVerified : false,
        );
        const [card2Dismissed, setCard2Dismissed] = useState(
            newOnboarding ? hideProfile : false,
        );

        const onCompleteCardActionClick = useCallback(() => {
            setOpenReqInfoModal(true);
        }, [setOpenReqInfoModal]);

        const onCustomizeCardActionClick = useCallback(() => {
            setOpenProfileInfoModal(true);
        }, [setOpenProfileInfoModal]);

        const onRequiredInfoModalClose = useCallback(() => {
            setOpenReqInfoModal(false);
            setOpenReqInfoPage(undefined);
        }, [setOpenReqInfoModal]);

        const onCustomizeProfileModalClose = useCallback(() => {
            setOpenProfileInfoModal(false);
        }, [setOpenProfileInfoModal]);

        const onCard1CloseClick = useCallback(() => {
            setCard1Dismissed(true);
            if (newOnboarding && donee.onboarding.hasMid) {
                onVerifiedClose?.();
            }
        }, [donee.onboarding.hasMid, newOnboarding, onVerifiedClose]);

        const onCard2CloseClick = useCallback(() => {
            setCard2Dismissed(true);
            if (newOnboarding) {
                onProfileClose?.();
            }
        }, [newOnboarding, onProfileClose]);

        const getProgress = useCallback(() => {
            let progress = 0;
            if (taxId.status === 'in_progress' || taxId.status === 'verified') {
                progress += 33;
            }
            if (
                directDeposit.status === 'in_progress' ||
                directDeposit.status === 'verified'
            ) {
                progress += 33;
            }
            if (
                primaryRepresentative.status === 'in_progress' ||
                primaryRepresentative.status === 'verified'
            ) {
                progress += 33;
            }
            return progress === 99 ? 100 : progress;
        }, [taxId, directDeposit, primaryRepresentative]);

        const showReview = useCallback(() => {
            if (
                taxId.status === 'require_information' ||
                directDeposit.status === 'require_information' ||
                primaryRepresentative.status === 'require_information'
            ) {
                return true;
            }
            return false;
        }, [taxId, directDeposit, primaryRepresentative]);

        const onReviewClick = useCallback(() => {
            if (directDeposit.status === 'require_information') {
                setOpenReqInfoPage('banking');
            } else if (taxId.status === 'require_information') {
                setOpenReqInfoPage('taxId');
            } else if (primaryRepresentative.status === 'require_information') {
                setOpenReqInfoPage('rep');
            }
            setOpenReqInfoModal(true);
        }, [
            taxId,
            directDeposit,
            primaryRepresentative,
            setOpenReqInfoModal,
            setOpenReqInfoPage,
        ]);

        const getCustomizeCardProgress = useCallback(() => {
            if (!appProfile.bannerImage) return false;
            if (
                onboarding.organizationType === 'church' &&
                !appProfile.faithLeaderImage
            )
                return false;
            if (!appProfile.organizationLogo) return false;
            if (!isValidAddress(appProfile.organizationAddress, true))
                return false;
            return true;
        }, [appProfile, onboarding.organizationType]);

        const custmizeProfileInitialStep = useMemo((): Step => {
            if (!newOnboarding) return 'main';

            const step1Finished =
                !!appProfile.bannerImage &&
                (onboarding.organizationType === 'nonprofit' ||
                    !!appProfile.faithLeaderImage) &&
                isValidAddress(appProfile.organizationAddress, true);
            return step1Finished ? 'mission' : 'main';
        }, [newOnboarding, appProfile, onboarding.organizationType]);

        const customizeProfileStarted = useMemo(
            () =>
                !!appProfile.bannerImage ||
                !!appProfile.faithLeaderImage ||
                !!appProfile.organizationLogo ||
                isValidAddress(appProfile.organizationAddress, true),
            [appProfile],
        );

        return (
            <OnboardingView
                customizeProfileProps={{
                    userId: user.id,
                    doneeId: onboarding.doneeId,
                    settingsUsersPath,
                    step: 'main',
                    open: openProfileInfoModal,
                    onClose: onCustomizeProfileModalClose,
                    organizationName: onboarding.organizationName,
                    organizationType: onboarding.organizationType,
                    appProfile,
                    onSubmit: setAppProfile,
                    formProps: appProfileFormProps,
                    initialStep: custmizeProfileInitialStep,
                }}
                requiredInfoPageProps={{
                    open: openReqInfoModal,
                    onClose: onRequiredInfoModalClose,
                    requiredInfoPageProps: {
                        doneeId: onboarding.doneeId,
                        organizationName: donee.name,
                        organizationType: onboarding.organizationType,
                        user,
                        directDeposit,
                        taxId,
                        primaryRepresentative,
                        onSubmitDirectDeposit: setDirectDeposit,
                        onSubmitTaxId: setTaxId,
                        onSubmitPrimaryRepresentative: setPrimaryRepresentative,
                        openPage: openReqInfoPage,
                        showYodlee,
                        accountOwnerFullName: '',
                    },
                }}
                swipeCardsProps={{
                    ...cardProps,
                    onCompleteCardActionClick: onCompleteCardActionClick,
                    onCustomizeCardActionClick: onCustomizeCardActionClick,
                    onCard1CloseClick: onCard1CloseClick,
                    onCard2CloseClick: onCard2CloseClick,
                    card1Dismissed: card1Dismissed,
                    card2Dismissed: card2Dismissed,
                    progress: getProgress(),
                    requiredPartIsComplete: getCustomizeCardProgress(),
                    card1ShowRetry: showReview(),
                    card1OnReviewClick: onReviewClick,
                    newOnboarding,
                    customizeProfileStarted,
                    onboardingCompleted: onboarding.onboardingCompleted,
                    organizationType: onboarding.organizationType,
                    hasMid: donee.onboarding.hasMid,
                }}
            />
        );
    },
);

OnboardingContainer.displayName = 'OnboardingContainer';
