import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgClear = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>clear</title>
        <path
            d="M17.7189 8.11485C18.1016 7.72442 18.0985 7.09855 17.7119 6.71193C17.3199 6.31986 16.6832 6.32301 16.2951 6.71894L12.4999 10.5899L8.61484 6.78098C8.22442 6.39821 7.59855 6.4013 7.21193 6.78792C6.81986 7.17999 6.82301 7.81663 7.21894 8.2048L11.0899 11.9999L7.28102 15.8851C6.89825 16.2755 6.90135 16.9014 7.28796 17.288C7.68004 17.68 8.31668 17.6769 8.70484 17.281L12.4999 13.4099L16.2951 17.281C16.6832 17.6769 17.3199 17.68 17.7119 17.288C18.0985 16.9014 18.1016 16.2755 17.7189 15.8851L13.9099 11.9999L17.7189 8.11485Z"
            fill="current"
        />
    </svg>
);

export const ClearIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgClear}
        fontSize={fontSize}
    />
);
