import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgBankColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="72"
        width="72"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 72 72"
    >
        <g clipPath="url(#clip0_3810_1694)">
            <rect fill="#BEB3FF" height="28" width="10" x="10.0002" y="35" />
            <rect fill="#BEB3FF" height="28" width="10" x="31.0002" y="35" />
            <rect fill="#BEB3FF" height="28" width="10" x="52.0002" y="35" />
            <rect fill="#715AFF" height="5" rx="1" width="14" x="8" y="30" />
            <rect
                fill="#715AFF"
                height="5"
                rx="0.9232"
                width="14"
                x="29"
                y="30"
            />
            <rect fill="#715AFF" height="5" rx="1" width="14" x="50" y="30" />
            <rect fill="#715AFF" height="5" rx="1" width="14" x="8" y="62" />
            <rect fill="#715AFF" height="5" rx="1" width="14" x="29" y="62" />
            <rect fill="#715AFF" height="5" rx="1" width="14" x="50" y="62" />
            <rect fill="#BEB3FF" height="5" rx="1" width="72" y="67" />
            <path
                d="M2.23707 21.0217L2.23795 21.0212C2.23766 21.0214 2.23736 21.0215 2.23707 21.0217L2 20.5814L2.23707 21.0217ZM2.5 20.8801L36 2.83947L69.5 20.8801V27.5H2.5V20.8801Z"
                fill="#715AFF"
                stroke="#BEB3FF"
                strokeWidth="5"
            />
        </g>
        <defs>
            <clipPath id="clip0_3810_1694">
                <rect fill="white" height="72" width="72" />
            </clipPath>
        </defs>
    </svg>
);

export const BankColoredIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgBankColored} fontSize={fontSize} />
);
