import { useFilterParams } from 'api/hooks/useFilterParams';

export enum MESSAGE_FILTER_VALUE {
    REPLIED = 'REPLIED',
    UN_REPLIED = 'UN_REPLIED',
    NO_MESSAGES = 'NO_MESSAGES',
}

const paramNames = [
    'no_messages',
    'replied_messages',
    'un_replied_messages',
] as const;

export const useAllMessagesFilter = () => {
    const [filter, setFilter] = useFilterParams<
        MESSAGE_FILTER_VALUE[],
        typeof paramNames
    >(
        [],
        paramNames,
        (state, paramName) => {
            if (state.length === 0) return '';
            switch (paramName) {
                case 'no_messages':
                    return state.includes(MESSAGE_FILTER_VALUE.NO_MESSAGES)
                        ? 'true'
                        : '';
                case 'replied_messages':
                    return state.includes(MESSAGE_FILTER_VALUE.REPLIED)
                        ? 'true'
                        : '';
                case 'un_replied_messages':
                    return state.includes(MESSAGE_FILTER_VALUE.UN_REPLIED)
                        ? 'true'
                        : '';
            }
        },
        (values) => {
            const selectedParams: MESSAGE_FILTER_VALUE[] = [];
            if (
                values['no_messages'] &&
                (values['no_messages'] === 'true' ||
                    values['no_messages'] === '1')
            ) {
                selectedParams.push(MESSAGE_FILTER_VALUE.NO_MESSAGES);
            }
            if (
                values['replied_messages'] &&
                (values['replied_messages'] === 'true' ||
                    values['replied_messages'] === '1')
            ) {
                selectedParams.push(MESSAGE_FILTER_VALUE.REPLIED);
            }
            if (
                values['un_replied_messages'] &&
                (values['un_replied_messages'] === 'true' ||
                    values['un_replied_messages'] === '1')
            ) {
                selectedParams.push(MESSAGE_FILTER_VALUE.UN_REPLIED);
            }
            return selectedParams;
        },
    );
    return [filter, setFilter] as const;
};
