import { CSSProperties, useMemo } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { UseTaxIdContext } from '../../../TaxIdContext';
import { useUploadDocumentTranslation } from '../useUploadDocumentTranslation';

type PreviewModalProps = {
    onClose: () => void;
    open: boolean;
    modalType: 'example' | 'uploaded';
    fileExtension: string;
    uploadedFile?: File;
    exampleImage: string;
    fileContent: string;
};

const PreviewModal: React.FC<PreviewModalProps> = ({
    onClose,
    open,
    modalType,
    exampleImage,
    uploadedFile,
    fileExtension,
    fileContent,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { selectedDocumentType } = UseTaxIdContext();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { text, copy } = useUploadDocumentTranslation(selectedDocumentType!);

    const imageStyle: CSSProperties = {
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    };

    const objectUrl = useMemo(
        () => (uploadedFile ? URL.createObjectURL(uploadedFile) : ''),
        [uploadedFile],
    );

    return (
        <GivelifyModal
            fullWidth
            showCloseButton
            maxWidth="mobile"
            name='View Uploaded doc'
            onClose={onClose}
            open={open}
        >
            <div
                style={{
                    marginTop: 24,
                    marginBottom: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                }}
            >
                {!isMobile && (
                    <GivelifyLabel
                        align="center"
                        style={{ marginBottom: 32 }}
                        text={
                            modalType === 'example'
                                ? text?.exampleModalHeader
                                : text?.uploadedModalHeader
                        }
                        variant="heading1S"
                    />
                )}
                {modalType === 'example' ? (
                    <img
                        alt={text?.imageAlt}
                        src={exampleImage}
                        style={imageStyle}
                    />
                ) : fileExtension === 'pdf' ? (
                    uploadedFile && (
                        <iframe
                            src={objectUrl}
                            style={{
                                height: '100vh',
                            }}
                            title="Preview"
                        ></iframe>
                    )
                ) : (
                    <img
                        alt={text?.documentType}
                        src={fileContent}
                        style={imageStyle}
                    />
                )}
                {modalType === 'example' && (
                    <GivelifyLabel
                        align="center"
                        color={DesignTokens.color.textSecondary}
                        style={{
                            marginTop: isMobile ? 4 : 8,
                        }}
                        text={copy.locationMayVary}
                        variant="caption1"
                    />
                )}
            </div>
            <GivelifyButton
                onClick={onClose}
                style={
                    isMobile
                        ? {}
                        : {
                              alignSelf: 'center',
                          }
                }
                text={copy.close}
                variant="primary"
            />
        </GivelifyModal>
    );
};

export default PreviewModal;
