import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyNotification } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { LoadTotalMatchStateAsync } from 'store/integrations/thunks';
import { ReportType } from 'store/report/types';
import { isFeatureEnabled, Features } from 'utils/featureGating';
import { LoadTotalUnmatchedStateAsync } from '../../../store/automatedIntegration/thunks';
import { useIntegrationContext } from '../IntegrationContext';
import { useAllIntegrations } from '../IntegrationSetup';
import { matchSelect, UNMATCH } from '../utils/integrationFormatFilterUtils';
import { IntegrationSetupCongratulationsBanner } from './IntegrationSetupCongratulationsBanner';
import IntegrationTabsContent from './TabsContent';
import { getIntegrationSettings } from './TabsContent/AutoIntegrationContent/components/automatedIntegrationRequest';
import CustomReports from './TabsContent/CustomReports';
import IntegrationTabHeader from './TabsHeader';

const PCConnectionSuccessMessage = styled(GivelifyNotification)(() => ({
    marginBottom: '16px',
    '& button': {
        minHeight: 'unset',
    },
}));

export const integrationWithoutEnvelope: ReportType[] = [
    'SHELBYNEXT',
    'SERVANTKEEPER',
];

const IntegrationContent: React.FC = (props) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    const dispatch = useDispatch();
    const {
        ReportIntegration: { scheduledReport },
        F1CCBIntegration: { data },
        enabledFeatures,
        doneeId,
    } = useSelector((state: AppState) => ({
        ReportIntegration: state.ReportIntegration,
        F1CCBIntegration: state.F1CCBIntegration
            ? state.F1CCBIntegration
            : null,
        enabledFeatures: state.System.enabledFeatures,
        doneeId: state.Donee.donee.id,
    }));

    const allIntegrations = useAllIntegrations();
    const integration = useMemo(() => {
        const type =
            (data?.data?.doneeId || 0) !== 0
                ? data.data.type
                : scheduledReport.reportType;
        return allIntegrations.find((i) => i.integrationType === type);
    }, [data, scheduledReport, allIntegrations]);
    const [integrationSync, setIntegrationSync] = useState<string>(
        data.data !== undefined ? data.data.initialSetupStatus : '',
    );
    const [closeSync, setCloseSync] = useState<boolean>(false);
    const handleCloseSync = () => {
        setCloseSync(true);
        localStorage.setItem('closeSync', 'closed');
    };

    const { integrationTitle, ...copy } = useMemo(
        () => ({
            integrationTitle: `${integration.name} ${t(
                'menu.data.integrations.integration',
            )}`,
            planningCenterSuccessMessage: t(
                'pages.integrations.content.setup-dialog.planningCenterSetup.success',
            ),
        }),
        [integration, t],
    );

    useEffect(() => {
        dispatch(LoadTotalMatchStateAsync());
        if (data.data) {
            dispatch(LoadTotalUnmatchedStateAsync());
        }
    }, [data, dispatch]);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    const goToFirstTab = () => {
        setCurrentTab(0);
    };

    const integrationNoDonor = useMemo(() => {
        if (integration.integrationType !== 'ACSREALM') {
            return true;
        }
        if (
            scheduledReport.matchBy === 'envelope_number' &&
            isFeatureEnabled(enabledFeatures, Features.INTEGRATION_ACS_REALM)
        ) {
            setCurrentTab(3);
            return true;
        } else {
            setCurrentTab(2);
            return false;
        }
    }, [integration, scheduledReport.matchBy, enabledFeatures]);

    const [matchStatus, seMatchStatus] = useState<matchSelect>(UNMATCH);

    const goToTab = (tab: number, matchStatus?: matchSelect) => {
        setCurrentTab(tab);
        seMatchStatus(matchStatus);
    };

    const [showCustomReport, setShowCustomReport] = useState(false);

    React.useEffect(() => {
        setIntegrationSync(
            data.data !== undefined ? data.data.initialSetupStatus : '',
        );
    }, [data]);

    React.useEffect(() => {
        const refreshData = async () => {
            const response = await getIntegrationSettings(doneeId);
            if (response.success) {
                setIntegrationSync(
                    response.response.data !== undefined
                        ? response.response.data.initialSetupStatus
                        : '',
                );
                return;
            }
        };
        const intervalId =
            integrationSync && integrationSync !== 'INITIAL_SETUP_COMPLETED'
                ? setInterval(refreshData, 5000)
                : null;

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // listen only to integrationSync
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrationSync]);

    const { haveBanner, setHaveBanner } = useIntegrationContext();
    const setCloseBanner = () => setHaveBanner(false);

    return (
        <>
            <GivelifyLabel
                marginBottom={16}
                text={integrationTitle}
                variant="heading1"
            />
            <Box paddingBottom={2}>
                {haveBanner &&
                integration.integrationType !== 'PLANNING_CENTER' ? (
                    <IntegrationSetupCongratulationsBanner
                        integrationReportType={integration}
                        onClose={setCloseBanner}
                    />
                ) : showCustomReport ? (
                    <CustomReports
                        closeCustomReportForm={() => setShowCustomReport(false)}
                        integration={integration}
                    />
                ) : (
                    <>
                        {integration.integrationType === 'PLANNING_CENTER' &&
                            haveBanner && (
                                <PCConnectionSuccessMessage
                                    onClose={setCloseBanner}
                                    show={true}
                                    text={copy.planningCenterSuccessMessage}
                                    variant="success"
                                />
                            )}
                        <IntegrationTabHeader
                            integrationNoDonor={integrationNoDonor}
                            integrationType={integration.integrationType}
                            onChange={handleChange}
                            value={currentTab}
                        />
                        <IntegrationTabsContent
                            buildCustomReportClick={() =>
                                setShowCustomReport(true)
                            }
                            closeSync={closeSync}
                            currentTab={currentTab}
                            defaultMatchStatus={matchStatus}
                            goToFirstTab={goToFirstTab}
                            goToTab={goToTab}
                            handleCloseSync={handleCloseSync}
                            integration={integration}
                            integrationNoDonor={integrationNoDonor}
                            integrationSync={integrationSync}
                        />
                    </>
                )}
            </Box>
        </>
    );
};

export default IntegrationContent;
