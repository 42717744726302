import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyTextField } from '@givelify/givelify-ui';
import { useDebounce } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonMatchingStyles from '../styles';
import { MatchingHeaderProps, MatchOption } from '../types';
import DesktopControls from './DesktopControls';
import MobileControls from './MobileControls';
import useStyles from './styles';

const CommonMatchingHeader: React.FC<MatchingHeaderProps> = (props) => {
    const {
        integrationType,
        acsInstructions,
        instructions,
        reportId,
        searchPlaceholderText,
        onChangeFilter,
        matchEnvelope,
        columns,
        onSortingChange,
        sortColumn,
        sortDirection,
        matchFilter,
        onMatchFilterChange,
        campusesFilter,
        onCampusesFilterChange,
        campusesOptions,
        hasCampuses,
    } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const commonMatchingClasses = useCommonMatchingStyles();

    const TRANSLATION_KEY = 'pages.integration_envelope_matching.content.ccb';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const copy = useMemo(
        () => ({
            placeHolderText: t('pages.integration.searchText', 'Search'),
            unmatched: scopedTranslate('unmatched'),
            matched: scopedTranslate('matched'),
            allCampuses: scopedTranslate('allCampuses'),
            matchFilterHeaderText: scopedTranslate('matchFilter'),
            campusFilterHeaderText: scopedTranslate('campusFilter'),
        }),
        [t, scopedTranslate],
    );

    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce<string>(searchText, 500);

    useEffect(() => {
        onChangeFilter(debouncedSearchText);
        //listen only to debouncedSearchText
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText]);

    const matchOptions: MatchOption[] = [
        { label: copy.unmatched, value: 'Unmatched' },
        { label: copy.matched, value: 'Matched' },
    ];

    const searchInputChanged = (_, value) => {
        if (value) {
            setSearchText(typeof value === 'string' ? value : value.name);
        } else {
            setSearchText('');
        }
    };

    return (
        <>
            <Grid
                container
                alignItems="center"
                className={commonMatchingClasses.matchHeader}
            >
                <MobileControls
                    campusFilterHeaderText={copy.campusFilterHeaderText}
                    campusesFilter={campusesFilter}
                    campusesOptions={campusesOptions}
                    columns={columns}
                    hasCampuses={hasCampuses}
                    matchFilter={matchFilter}
                    matchFilterHeaderText={copy.matchFilterHeaderText}
                    matchOptions={matchOptions}
                    onCampusesFilterChange={onCampusesFilterChange}
                    onMatchFilterChange={onMatchFilterChange}
                    onSortingChange={onSortingChange}
                    reportId={reportId}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />

                <DesktopControls
                    campusesFilter={campusesFilter}
                    campusesOptions={campusesOptions}
                    hasCampuses={hasCampuses}
                    matchFilter={matchFilter}
                    matchOptions={matchOptions}
                    onCampusesFilterChange={onCampusesFilterChange}
                    onMatchFilterChange={onMatchFilterChange}
                    reportId={reportId}
                />

                <Grid item className={classes.searchContent} md={4} xs={12}>
                    <GivelifyTextField
                        ariaLabel="search"
                        className={classes.autocompleteInput}
                        id="search"
                        onChange={searchInputChanged}
                        placeholder={
                            searchPlaceholderText
                                ? searchPlaceholderText
                                : copy.placeHolderText
                        }
                        value={searchText}
                        variant="search"
                    />
                </Grid>
            </Grid>
            <GivelifyLabel
                className={commonMatchingClasses.descriptionLabel}
                text={
                    matchEnvelope
                        ? integrationType === 'ACS' ||
                          integrationType === 'ACSREALM'
                            ? acsInstructions
                            : instructions
                        : instructions
                }
                variant="body2"
            />
        </>
    );
};

export default CommonMatchingHeader;
