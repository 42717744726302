import React from 'react';
import { GivelifyModal } from '@givelify/givelify-ui';
import { InstructModalContent } from './InstructModalContent';

export default function useInstructModal() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const modalToRender = (props: { givingLink: string }) => (
        <GivelifyModal
            disableBackdropClick
            fullscreen
            showCloseButton
            onClose={() => {
                setIsModalOpen(false);
            }}
            open={isModalOpen}
            size="large"
            testId="instruct-modal"
        >
            <InstructModalContent {...props} />
        </GivelifyModal>
    );

    return [() => setIsModalOpen(true), modalToRender] as const;
}
