import React, { useEffect, useState, useMemo, useContext } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { TimeFrameValue } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { DonationRow } from 'api/models';
import {
    TRANSACTION_FILTER_VALUE,
    MESSAGE_FILTER_VALUE,
} from 'components/filters';
import { useTranslation } from 'react-i18next';
import { Context } from '../../../../layout/MainLayoutContext';
import { getDonationQueryString } from '../../../../store/donations/donation/types';
import { PaginatedResponse } from '../../../../types/paginationTypes';
import InfiniteLoader from '../../components/InfiniteLoader';
import NoResultsScreen from '../../components/NoResultsScreen';
import RefundRowLoading from './RefundRowLoading';
import RefundsList from './RefundsList';
import useStyles from './styles';

type DonationsRowWrapperProps = {
    doneeId: number;
    timeFrame: TimeFrameValue;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    envelopesLoading?: boolean;
};

const DonationsRowWrapper: React.FC<DonationsRowWrapperProps> = (
    { doneeId, timeFrame, envelopeIds, messageFilter, envelopesLoading },
    props,
) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { mainHolderDivRef } = useContext(Context);
    const { t } = useTranslation();
    const classes = useStyles(props);

    const DonationRowText = useMemo(
        () => ({
            detail: t('bankDeposits.text.detail'),
            activityError: t('error.errorDonationsActivity'),
            refundsError: t('error.errorRefunds'),
            noActivity: t('error.noDonationsActivity'),
            noRefunds: t('error.noRefunds'),
        }),
        [t],
    );

    const [reset, setReset] = useState(0);
    useEffect(() => {
        setReset((val) => val + 1);
    }, [timeFrame, envelopeIds, messageFilter, doneeId]);

    const Error = (
        <GivelifyLabel
            bold
            marginTop={16}
            text={DonationRowText.refundsError}
            variant="heading5"
        />
    );

    const Wrapper = (Component: JSX.Element) => (
        <div className={classes.borderTop}>{React.cloneElement(Component)}</div>
    );

    const url = (pageNumber: number) => {
        return (
            `/donees/${doneeId}/donations` +
            getDonationQueryString({
                timeFrame,
                envelopeIds,
                messageFilter,
                transactionFilter: TRANSACTION_FILTER_VALUE.REFUNDED,
                page: pageNumber,
            })
        );
    };

    const onReset = () => setReset(0);

    return (
        <InfiniteLoader
            ErrorComponent={Wrapper(Error)}
            LoadingComponent={<RefundRowLoading />}
            ZerothComponent={<NoResultsScreen pageName="refund" />}
            className={classes.fullRowCell}
            onReset={onReset}
            reset={reset}
            url={url}
            {...(isMobile && mainHolderDivRef
                ? {
                      getScrollParent: () => mainHolderDivRef.current,
                      useWindow: false,
                  }
                : {})}
            pauseLoading={envelopesLoading}
            renderData={(dataSet: PaginatedResponse<DonationRow>) => (
                <RefundsList data={dataSet} />
            )}
        />
    );
};

export default DonationsRowWrapper;
