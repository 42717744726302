import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { DesktopNavigationMenuWithDonations } from './desktopNavigationMenu';
import {
    MobileNavigationMenu,
    MobileNavigationMenuProps,
} from './mobileNavigationMenu';
import { TabletNavigationMenu } from './tabletNavigationMenu';

type NavigationMenuProps = MobileNavigationMenuProps;

export const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const NavigationWrapper = React.useMemo(() => {
        if (isMobile) {
            return (
                <MobileNavigationMenu
                    navigationProps={props.navigationProps}
                    onMoreToggle={props.onMoreToggle}
                    openMoreMenu={props.openMoreMenu}
                />
            );
        }
        if (isTablet) {
            return (
                <TabletNavigationMenu navigationProps={props.navigationProps} />
            );
        }
        return (
            <DesktopNavigationMenuWithDonations
                navigationProps={props.navigationProps}
            />
        );
    }, [isTablet, isMobile, props]);

    return NavigationWrapper;
};
