import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    marginBottom24: {
        marginBottom: 24,
    },
    marginBottom16: {
        marginBottom: 16,
    },
    addressWrapper: {
        border: '1px solid #E3E3E3',
        borderRadius: 8,
        padding: '14px 32px',
        marginTop: 24,
        marginBottom: 8,
        '& > *': {
            lineHeight: '24px',
            '&:not(:first-child)': {
                fontWeight: 400,
            },
        },
    },
    campusNicknameLabel: {
        marginTop: 24,
        marginBottom: 8,
    },
    nicknameField: {
        marginTop: 24,
    },
    checkboxRow: {
        marginTop: 35,
        marginBottom: 33,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
        color: theme.colors?.primary || theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.colors?.accentDarkBlue || theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
