import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

export const givelithonModalStyle = makeStyles((theme: Theme) => ({
    modalContent: {
        display: 'flex',
        alignItems: 'stretch',
    },
    modalTitle: {
        background: GivelifyColorPalette.primary,
        padding: '96px 40px',
        minWidth: 352,
        boxSizing: 'border-box',
    },
    info: {
        padding: '96px 56px 48px 48px',
        flexGrow: 1,
    },
    closeModal: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    workSteps: {
        paddingLeft: 20,
        margin: 0,
    },
    step: {
        marginBottom: theme.spacing(3),
        '&::marker': {
            color: GivelifyColorPalette.primary,
            fontSize: 18,
            fontWeight: 700,
            fontFamily: theme.gas?.font,
        },
    },
    doneButtonGroup: {
        textAlign: 'center',
        marginTop: theme.spacing(4),

        '& > button': {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    [theme.breakpoints.down('sm')]: {
        modalContent: {
            display: 'block',
            height: '100vh',
            overflow: 'auto',
        },
        modalTitle: {
            maxWidth: '100%',
        },
        info: {
            padding: 48,
        },
        closeModal: {
            color: GivelifyColorPalette.primaryWhite + '!important',
        },
    },
}));
