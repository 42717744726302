import React, { useEffect } from 'react';
import { isSucceeded } from '@givelify/givelify-ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import permissionTypes from 'constants/permissionTypes';
import { useSelector, useDispatch } from 'react-redux';
import { PATH } from 'router/routes';
import permissionsByPath from 'utils/permissionsByPath';
import { useInvokeApi } from '../../../../hooks/useInvokeApi';
import { AppState } from '../../../../store';
import { setDoneeCustomReceipt } from '../../../../store/donee/actions';
import ReceiptTabPage from './ReceiptTabPage';

type DoneeResponse = {
    custom_receipt: boolean;
    updatedAt: string;
};

export const CustomizeReceiptTab: React.FC = () => {
    const dispatch = useDispatch();
    const [doneeRequestState, makeDoneeRequest] = useInvokeApi<DoneeResponse>();
    const { doneeId, donee, userRole, useCustomReceipt } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            donee: state.Donee.donee,
            userRole: state.User.user.role,
            useCustomReceipt: state.Donee.donee.useCustomReceipt,
        }),
    );
    const [value, setValue] = React.useState(useCustomReceipt);
    const handleChange = (isOn: boolean) => {
        setValue(isOn);
    };

    useEffect(() => {
        setValue(useCustomReceipt);
    }, [doneeId, useCustomReceipt]);

    const onCancelClick = () => {
        setValue(donee.useCustomReceipt);
    };
    const onSaveClick = async () => {
        if (value !== donee.useCustomReceipt) {
            makeDoneeRequest('PATCH', `donees/${donee.id}`, {
                // eslint-disable-next-line
                custom_receipt: value,
            });
        }
    };
    useEffect(() => {
        if (isSucceeded(doneeRequestState)) {
            dispatch(
                setDoneeCustomReceipt(
                    doneeId,
                    doneeRequestState.data.custom_receipt,
                    new Date(doneeRequestState.data.updatedAt),
                ),
            );
        }
    }, [doneeRequestState, dispatch, doneeId]);
    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.APP_PROFILE][userRole] ===
        permissionTypes.FULL_ACCESS;

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsAppProfileCustomizeReceipt}
        >
            <ReceiptTabPage
                dirty={value !== donee.useCustomReceipt}
                disableActions={!hasFullAccess}
                doneeName={donee.name}
                error={
                    doneeRequestState.type === 'REQUEST_ERROR'
                        ? doneeRequestState.error.message
                        : undefined
                }
                isOn={value}
                onCancel={onCancelClick}
                onChange={handleChange}
                onSave={onSaveClick}
                saving={doneeRequestState.type === 'REQUEST_START'}
            />
        </TrackingProvider>
    );
};
