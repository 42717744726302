import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    mobileHidden: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    desktopHidden: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    wrapper: {
        overflow: 'hidden',
    },
    topWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr 1fr',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
    },
    memoTabDonorDetails: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
