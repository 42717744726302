import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { FacebookLoading } from '../facebookLoading';
import { GivelifyLabel } from '../label';
import { mergeClassNames } from '../utils/classNameUtils';

export type GivelifyLifetimeBannerSize = 'normal' | 'small';

export interface GivelifyLifetimeBannerProps {
    leftText: string;
    leftBottomText?: string;
    rightText: string;
    isLoading: boolean;
    loadingStyles?: CSSProperties;
    isDisabled?: boolean;
    size?: GivelifyLifetimeBannerSize;
}

const useStyles = makeStyles<Theme, { size: GivelifyLifetimeBannerSize }>(
    (theme) => ({
        lifetimeStyle: ({ size }) => ({
            background: theme.colors.accentAvatarBlue,
            borderRadius: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: size === 'normal' ? '15px 24px' : '12px 16px',
            minHeight: size === 'normal' ? 75 : 56,
            boxSizing: 'border-box',
            '& .left-column': {
                paddingRight: size === 'normal' ? 12 : 8,
                borderRight: `1px solid ${theme.colors.accentViolet100}`,
                '& div': {
                    fontSize: size === 'small' && 12,
                    lineHeight: size === 'small' && '16px',
                },
            },
            '& .right-column': {
                paddingLeft: size === 'normal' ? 12 : 8,
                alignSelf: 'stretch',
                display: 'flex',
                alignItems: 'center',
            },
            '& .right-font': {
                fontWeight: 700,
                fontSize: size === 'normal' ? 28 : 24,
                lineHeight: size === 'normal' ? '34px' : '32px',
            },
            [theme.breakpoints.down('xs')]: {
                minHeight: 55,
                padding: '12px 18px',
                '& .left-column div': {
                    paddingRight: 10,
                    fontSize: 12,
                    lineHeight: '15px',
                },
                '& .right-column': {
                    paddingLeft: 10,
                },
                '& .right-font': {
                    fontSize: 20,
                    lineHeight: '20px',
                },
            },
        }),
        boxDisabled: {
            backgroundColor: theme.colors.disabled,
        },
    }),
);

export const GivelifyLifetimeBanner: React.FC<GivelifyLifetimeBannerProps> = ({
    leftText,
    leftBottomText,
    rightText,
    isLoading,
    loadingStyles,
    isDisabled = false,
    size = 'normal',
}) => {
    const { lifetimeStyle, boxDisabled } = useStyles({ size });

    if (isLoading) {
        return (
            <FacebookLoading
                width={200}
                height={size === 'normal' ? 43 : 26}
                marginTop={16}
                marginBottom={16}
                borderRadius={5}
                style={loadingStyles}
            />
        );
    }

    return (
        <div
            className={mergeClassNames(
                lifetimeStyle,
                isDisabled && boxDisabled,
            )}
        >
            <div className="left-column">
                <GivelifyLabel text={leftText} color="primary" />
                {leftBottomText && (
                    <GivelifyLabel text={leftBottomText} color="primary" />
                )}
            </div>
            <div className="right-column">
                <div className="right-font" id="banner-total-donations">
                    {rightText}
                </div>
            </div>
        </div>
    );
};
