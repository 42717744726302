import { DesignTokens, GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { TimeFrameId } from '@givelify/utils';
import { Grid, styled } from '@mui/material';

export const DatePill = styled(GivelifyButton, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean; id: TimeFrameId }>(({ theme, isActive }) => ({
    fontSize: '14px',
    height: '32px',
    padding: '0 18px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    minWidth: '116px',
    backgroundColor: isActive
        ? DesignTokens.color.backgroundButtonPrimaryDefault
        : DesignTokens.color.backgroundButtonSecondaryHover,
    color: isActive
        ? DesignTokens.color.textWhite
        : DesignTokens.color.textSecondary,
    '&:hover': {
        color: DesignTokens.color.textWhite,
    },
    [theme.breakpoints.down('smallTablet')]: {
        fontSize: '12px',
        lineHeight: '24px',
    },
    [theme.breakpoints.down('mobile')]: {
        height: '24px',
        backgroundColor: isActive
            ? DesignTokens.color.backgroundButtonPrimaryDefault
            : DesignTokens.color.backgroundButtonSecondaryHover,
        color: isActive
            ? DesignTokens.color.textWhite
            : DesignTokens.color.textSecondary,
        fontSize: '10px',
        padding: '0 10px',
        minWidth: '76px',
    },
}));

export const DatePickerWidgetWrapper = styled(Grid)(({ theme }) => ({
    flexWrap: 'nowrap',
    [theme.breakpoints.down('tablet')]: {
        flexWrap: 'wrap',
    },
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('tablet')]: {
        width: '100%',
    },
}));

export const DatesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    [theme.breakpoints.down('tablet')]: {
        width: '100%',
    },
}));

export const DatePickerWidgetTitle = styled(GivelifyLabel)(({ theme }) => ({
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '24px',
        lineHeight: '32px',
        marginRight: 0,
    },
}));
