import {
    AddressWithPhone,
    ApiResponse,
    axiosClient,
    makeApiRequest,
} from '@givelify/givelify-ui';
import { toISODate } from '@givelify/utils';
import { Area } from 'react-easy-crop/types';
import {
    AppProfile,
    DirectDepositInfo,
    FaithLeaderEditorInfo,
    ImageWithDimensions,
    PrimaryRepresentativeInfo,
    RepresentativeWithImage,
} from '../../@types/assets/onboarding';
import { YodleeBankAccountInfo } from '../../yodlee/yodleeResponseInterfaces';
import {
    directDepositUrl,
    customizeProfileUrl,
    getDoneeUpdateApiEndpoint,
    getPrimaryRepresentativeApiEndpoint,
    getRepresentativesUrl,
    yodleeAccessTokenUrl,
    yodleeAccountsUrl,
    updateAppProfileUrl,
    updateFaithLeadereUrl,
} from '../consts/endpoints';
import { toFormData } from '../utils';

if (!process.env.STORYBOOK) {
    axiosClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                window.location.href = '/';
            }
            return Promise.reject(error);
        },
    );
}

export interface DirectDepositDTO {
    accountNumber: string | number;
    routingNumber: string | number;
    billingAddress: string;
    billingCity: string;
    billingCountry: string;
    billingState: string;
    billingZip: string;
    voidedCheckFile: File | undefined;
}

const directDepositDTO = (data: DirectDepositInfo) => {
    const values: DirectDepositDTO = {
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        billingAddress: data.bankingAddress.street,
        billingCity: data.bankingAddress.city,
        billingCountry: 'US',
        billingState: data.bankingAddress.state,
        billingZip: data.bankingAddress.zip,
        voidedCheckFile: data.bankChequeFile,
    };
    return values;
};

export const directDepositAPI = (doneeId: number, data: DirectDepositInfo) => {
    const url = directDepositUrl(doneeId);
    const formData = toFormData(directDepositDTO(data));
    const httpRequest = axiosClient.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

const customizeProfileDTO = (data: AppProfile) => {
    const isNewFaithLeader =
        !data.doNotDisplayFaithLeader && Number(data.faithLeader?.userId) === 0;
    const faithLeaderId = data.doNotDisplayFaithLeader
        ? undefined
        : isNewFaithLeader
        ? null
        : data.faithLeader?.userId;

    return {
        organizationPhone: data.organizationAddress.phone,
        organizationZip: data.organizationAddress.zip,
        organizationState: data.organizationAddress.state,
        organizationCity: data.organizationAddress.city,
        organizationStreet: data.organizationAddress.street,
        faithLeaderId,
        ...(isNewFaithLeader && {
            faithLeaderFirstName: data.faithLeader?.firstName,
        }),
        ...(isNewFaithLeader && {
            faithLeaderLastName: data.faithLeader?.lastName,
        }),
        ...(isNewFaithLeader && {
            faithLeaderTitle: data.faithLeader?.title,
        }),
        photo: data.bannerImage?.url || null,
        photoCroppedCoordinates: data.bannerImage?.dimensions.croppedAreaPixels,
        avatar: data.faithLeaderImage?.url,
        avatarCroppedCoordinates:
            data.faithLeaderImage?.dimensions.croppedAreaPixels,
        logo: data.organizationLogo?.url,
        logoCroppedCoordinates:
            data.organizationLogo?.dimensions.croppedAreaPixels,
    };
};

const toFaithLeaderRequest = (data: {
    faithLeader: FaithLeaderEditorInfo;
    faithLeaderImage: ImageWithDimensions | undefined;
}) => {
    const isNewFaithLeader = Number(data.faithLeader?.userId) === 0;
    const faithLeaderId = isNewFaithLeader
        ? undefined
        : data.faithLeader?.userId;

    return {
        userId: faithLeaderId,
        ...(isNewFaithLeader && {
            firstName: data.faithLeader?.firstName,
        }),
        ...(isNewFaithLeader && {
            lastName: data.faithLeader?.lastName,
        }),
        ...(isNewFaithLeader && {
            title: data.faithLeader?.title,
        }),
        avatar: data.faithLeaderImage?.url,
        avatarCroppedCoordinates:
            data.faithLeaderImage?.dimensions.croppedAreaPixels,
    };
};

export const yodleeAccessTokenAPI = (
    doneeId: number,
): Promise<ApiResponse<string>> => {
    const httpRequest = axiosClient.get(yodleeAccessTokenUrl(doneeId));
    const result = makeApiRequest<string>(httpRequest);
    return result;
};

export const yodleeAccountsAPI = (
    doneeId: number,
    accountId: string,
    providerAccountId: string,
    yodleeAccessToken: string,
): Promise<ApiResponse<YodleeBankAccountInfo>> => {
    const httpRequest = axiosClient.get(
        yodleeAccountsUrl(
            doneeId,
            accountId,
            providerAccountId,
            yodleeAccessToken,
        ),
    );
    const result = makeApiRequest<YodleeBankAccountInfo>(httpRequest);
    return result;
};

export const customizeProfileAPI = (
    doneeId: number,
    data: AppProfile,
): Promise<ApiResponse<unknown>> => {
    const httpRequest = axiosClient.post(
        customizeProfileUrl(doneeId),
        customizeProfileDTO(data),
    );

    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const saveMissionStatementAPI = (
    doneeId: number,
    data: {
        missionStatement: string;
        organizationLogo?: {
            url: string;
            croppedUrl: string;
            dimensions: {
                croppedArea: Area;
                croppedAreaPixels: Area;
            };
            logoCroppedCoordinates?: Area;
        };
    },
): Promise<ApiResponse<unknown>> => {
    const httpRequest = axiosClient.patch(getDoneeUpdateApiEndpoint(doneeId), {
        missionStatement: data.missionStatement,
        logo: data.organizationLogo?.url,
        logoCroppedCoordinates:
            data.organizationLogo?.dimensions.croppedAreaPixels,
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export interface PrimaryRepresentativeDTO {
    phoneNumber: string | undefined;
    state: string | undefined;
    city: string | undefined;
    zip: string | undefined;
    address: string | undefined;
    ssn: string | undefined;
    dateOfBirth: string | undefined;
    title: string | undefined;
    lastName: string | undefined;
    firstName: string | undefined;
    beneficiaryIdFile: File | undefined;
}

const primaryRepresentativeDTO = (
    data: PrimaryRepresentativeInfo,
): PrimaryRepresentativeDTO => ({
    phoneNumber: data.primaryRepresentativeAddress.phone,
    state: data.primaryRepresentativeAddress.state,
    city: data.primaryRepresentativeAddress.city,
    zip: data.primaryRepresentativeAddress.zip,
    address: data.primaryRepresentativeAddress.street,
    ssn: data.socialSecurityNumber,
    dateOfBirth: data.birthDate ? toISODate(data.birthDate) : undefined,
    title: data.title,
    lastName: data.lastName,
    firstName: data.firstName,
    beneficiaryIdFile: data.beneficiaryIdFile,
});

export const primaryRepresentativeAPI = (
    doneeId: number,
    data: PrimaryRepresentativeInfo,
): Promise<ApiResponse<unknown>> => {
    const url = getPrimaryRepresentativeApiEndpoint(doneeId);
    const formData = toFormData(primaryRepresentativeDTO(data));
    const httpRequest = axiosClient.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const getRepresentativesAPI = (
    doneeId: number,
): Promise<ApiResponse<{ data: RepresentativeWithImage[] }>> => {
    const url = getRepresentativesUrl(doneeId);
    const httpRequest = axiosClient.get(url);
    const result = makeApiRequest<{ data: RepresentativeWithImage[] }>(
        httpRequest,
    );
    return result;
};

export const appProfileUpdateOrganizationLogoApi = (
    doneeId: number,
    data: {
        organizationLogo: {
            url?: string;
            croppedCoordinates?: Area;
        };
    },
): Promise<ApiResponse<unknown>> => {
    const httpRequest = axiosClient.patch(updateAppProfileUrl(doneeId), {
        logo: data.organizationLogo?.url || null,
        logoCroppedCoordinates: data.organizationLogo?.croppedCoordinates,
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const appProfileUpdateMissionStatementApi = (
    doneeId: number,
    data: {
        missionStatement: string;
    },
): Promise<ApiResponse<unknown>> => {
    const httpRequest = axiosClient.patch(updateAppProfileUrl(doneeId), data);
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const appProfileUpdateOrganizationAddressApi = (
    doneeId: number,
    data: {
        organizationAddress: AddressWithPhone;
    },
): Promise<ApiResponse<unknown>> => {
    const httpRequest = axiosClient.patch(updateAppProfileUrl(doneeId), {
        phone: data.organizationAddress.phone,
        physicalAddress: {
            postal: data.organizationAddress.zip,
            state: data.organizationAddress.state,
            city: data.organizationAddress.city,
            street: data.organizationAddress.street,
        },
    });

    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const appProfileUpdateBannerImageApi = (
    doneeId: number,
    data: {
        bannerImage: {
            url?: string;
            croppedCoordinates?: Area;
        };
    },
): Promise<ApiResponse<unknown>> => {
    const httpRequest = axiosClient.patch(updateAppProfileUrl(doneeId), {
        photo: data.bannerImage?.url || null,
        photoCroppedCoordinates: data.bannerImage?.croppedCoordinates,
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const appProfileUpdateFaithLeaderApi = (
    doneeId: number,
    data: {
        faithLeader: FaithLeaderEditorInfo;
        faithLeaderImage: ImageWithDimensions | undefined;
        doNotDisplayFaithLeader: boolean;
    },
): Promise<ApiResponse<unknown>> => {
    if (data.doNotDisplayFaithLeader) {
        const httpRequest = axiosClient.delete(updateFaithLeadereUrl(doneeId));
        const result = makeApiRequest<unknown>(httpRequest);
        return result;
    } else {
        const httpRequest = axiosClient.put(
            updateFaithLeadereUrl(doneeId),
            toFaithLeaderRequest(data),
        );
        const result = makeApiRequest<unknown>(httpRequest);
        return result;
    }
};
