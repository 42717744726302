import { Donee } from '@givelify/givelify-ui';
import dayjs from 'dayjs';
export const NEW_GAS_SIGNUP_DATE = '2023-03-01';

export const enableOldDahsboard = (campuses?: Donee[]) => {
    if (campuses && campuses.length > 0) {
        const mainCampus = campuses[0];
        if (
            mainCampus &&
            dayjs(mainCampus.signupDate).isBefore(NEW_GAS_SIGNUP_DATE)
        ) {
            return true;
        }
    }
    return false;
};
