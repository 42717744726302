import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { RepDetailStyles } from './repDetailStyles';

interface RepRetryProps {
    firstName: string;
    lastName: string;
    onRetryClick: () => void;
}

export const RepRetry: React.FC<RepRetryProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newRep: t('pages.settings.bank-info.customize-rep-tab.new-rep'),
            caption: t('pages.settings.bank-info.customize-rep-tab.caption'),
            primaryRep: t('pages.settings.bank-info.customize-rep-tab.title'),
            status: t('pages.settings.bank-info.customize-rep-tab.retry'),
            info: t('pages.settings.bank-info.customize-rep-tab.retry-info-1'),
            retry: t('pages.settings.bank-info.customize-rep-tab.retry-info-2'),
        }),
        [t],
    );
    const { firstName, lastName, onRetryClick } = props;
    const { repRetry } = RepDetailStyles();
    return (
        <div className={repRetry}>
            <GivelifyLabel marginTop={40} text={copy.caption} />
            <div className="header">
                <GivelifyLabel text={copy.newRep} variant="heading3" />
                <GivelifyIcon
                    color="#DC9F00"
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="info-circle"
                />
                <GivelifyLabel className="submitted" text={copy.status} />
            </div>
            <GivelifyNotification show variant="warning">
                <span className="info">
                    {copy.info}{' '}
                    <span className="button" onClick={onRetryClick}>
                        {copy.retry}
                    </span>
                </span>
            </GivelifyNotification>
            <GivelifyLabel
                color="neutralGrey75"
                marginBottom={6}
                marginTop={24}
                text={copy.primaryRep}
                variant="heading5"
            />
            <GivelifyLabel
                color="neutralGrey75"
                marginBottom={16}
                text={`${firstName} ${lastName}`}
                variant="body1"
            />
        </div>
    );
};
