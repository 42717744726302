import React from 'react';
import { GivelifyLabel, GivelifyLabelStyles } from '@givelify/givelify-ui';
import useStyles from './styles';
import UpDownPercentage from './UpDownPercentage';

export interface DonationsStatusWidgetProps {
    cardId?: string;
    total: number | string;
    totalPercentage?: number;
    footerLeftNumber: {
        value: string;
        label: string;
    };
    footerRightNumber?: {
        value: string;
        label: string;
    };
}

const DonationsStatusWidget: React.FC<DonationsStatusWidgetProps> = ({
    cardId,
    totalPercentage,
    footerLeftNumber,
    footerRightNumber,
    ...props
}) => {
    const classes = useStyles();
    const { heading4, body1 } = GivelifyLabelStyles({});
    return (
        <>
            <div className={classes.totalAmount}>
                <GivelifyLabel
                    id={`${cardId}-total`}
                    text={props.total}
                    variant="heading1"
                />
                {totalPercentage && (
                    <UpDownPercentage
                        className={classes.floatRight}
                        total={totalPercentage}
                    />
                )}
            </div>
            <div className={classes.footer}>
                <div>
                    <span className={heading4} id={`${cardId}-left`}>
                        {footerLeftNumber.label}&nbsp;
                    </span>
                    <span className={body1} color="red">
                        {footerLeftNumber.value}
                    </span>
                </div>
                {footerRightNumber && (
                    <div>
                        <span className={heading4} id={`${cardId}-right`}>
                            {footerRightNumber.label}&nbsp;
                        </span>
                        <span className={body1}>{footerRightNumber.value}</span>
                    </div>
                )}
            </div>
        </>
    );
};

export default DonationsStatusWidget;
