import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getPagedDonationsByDateApiEndpoint,
    getPagedDonationsByStartEndDateEndpoint,
    getPagedDonationsMessagesEndpoint,
} from 'api/client/endpoints';
import { Dayjs } from 'dayjs';
import { IDonationsService } from '../interfaces';
import {
    GetPagedDonationsByDateResponse,
    GetPagedDonationsByStartEndDateResponse,
} from '../responses/donations';
import { GetPagedDonationsMessagesResponse } from '../responses/donations/GetPagedDonationsMessagesResponse';

export class DonationsService implements IDonationsService {
    public async getDonations(
        doneeId: number,
        donorId: string,
        startDate: Dayjs,
        endDate: Dayjs,
        page: number,
    ): Promise<ApiResponse<GetPagedDonationsByStartEndDateResponse>> {
        const url = getPagedDonationsByStartEndDateEndpoint(
            doneeId,
            donorId,
            startDate,
            endDate,
            page,
        );
        const httpRequest = getAxiosClient().get(url);
        const result =
            await makeApiRequest<GetPagedDonationsByStartEndDateResponse>(
                httpRequest,
            );
        return result;
    }

    public async getDonationsMessages(
        doneeId: number,
        donorId: string,
        startDate: Dayjs,
        endDate: Dayjs,
        page: number,
    ): Promise<ApiResponse<GetPagedDonationsMessagesResponse>> {
        const url = getPagedDonationsMessagesEndpoint(
            doneeId,
            donorId,
            startDate,
            endDate,
            page,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetPagedDonationsMessagesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getPagedDonationsByDate(
        doneeId: number,
        date: string,
        page: number,
        pending?: boolean,
    ): Promise<ApiResponse<GetPagedDonationsByDateResponse>> {
        const url = getPagedDonationsByDateApiEndpoint(
            doneeId,
            date,
            page,
            pending,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetPagedDonationsByDateResponse>(
            httpRequest,
        );
        return result;
    }
}
