import React, { createContext, RefObject, useContext } from 'react';
import { GivelifyLabel, GivelifyTabOption } from '@givelify/ui';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH as CONSTANT_PATH } from 'router/routes';
import {
    DonationAmount,
    DonationHeaderWrapper,
    DonationsPageTab,
    DonationTitleWrapper,
} from './styles';

const DonationsPageComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const totalDonationAmountRef = React.useRef<HTMLDivElement>(null);
    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const { PATH } = useGasRouterContext();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            donationsTitle: t('donationsRoot.title'),
            donationsDescription: t('donationsRoot.description'),
            donationSummaryTitle: t('donationsRoot.donationSummary.title'),
            donationsActivityTitle: t('donationsRoot.donationActivity.title'),
        }),
        [t],
    );

    const tabsOptions: GivelifyTabOption[] = React.useMemo(
        () => [
            {
                tabHref: PATH.DONATIONS.DONATION_SUMMARY_ENVELOPES(),
                label: copy.donationSummaryTitle,
                id: 'donation-summary-tab',
            },
            {
                tabHref: PATH.DONATIONS.DONATIONS_ACTIVITY(),
                label: copy.donationsActivityTitle,
                id: 'donation-activity-tab',
            },
        ],
        [PATH.DONATIONS, copy],
    );

    const handleTabChange = React.useCallback(
        (value: number) => {
            setCurrentTab(value);
            navigate(tabsOptions[value].tabHref);
        },
        [navigate, tabsOptions],
    );

    React.useEffect(() => {
        if (
            location.pathname.startsWith(
                CONSTANT_PATH.DONATIONS.DONATION_SUMMARY.BY_DATE,
            ) ||
            location.pathname.startsWith(
                CONSTANT_PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES,
            )
        ) {
            setCurrentTab(0);
        } else if (
            location.pathname.startsWith(
                CONSTANT_PATH.DONATIONS.DONATIONS_ACTIVITY,
            )
        ) {
            setCurrentTab(1);
        }
    }, [location, tabsOptions]);

    return (
        <TrackingProvider pageName={PAGE_NAME.Donations} trackPageVisit={false}>
            <PageTitle
                description={copy.donationsDescription}
                title={copy.donationsTitle}
            />
            <DonationHeaderWrapper>
                <DonationTitleWrapper>
                    <GivelifyLabel
                        data-testid="donation-page-heading"
                        role="heading"
                        text={copy.donationsTitle}
                        variant="heading2"
                    />
                    <DonationAmount ref={totalDonationAmountRef} />
                </DonationTitleWrapper>
                <DonationsPageTab
                    onChange={handleTabChange}
                    options={tabsOptions}
                    value={currentTab}
                />
            </DonationHeaderWrapper>
            <DonationsContext.Provider value={totalDonationAmountRef}>
                <Outlet />
            </DonationsContext.Provider>
        </TrackingProvider>
    );
};

const DonationsContext = createContext<RefObject<HTMLDivElement>>(undefined);
export const useDonationsContext = () => useContext(DonationsContext);

export const DonationsPage = React.memo(DonationsPageComponent);
