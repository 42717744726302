import React from 'react';
import {
    GivelifyButton,
    GivelifyErrorBanner,
    GivelifyInput,
} from '@givelify/givelify-ui';
import { copyToClipboard } from '@givelify/utils';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Controller } from 'react-hook-form';

import { useInvokeApi } from '../../../../api/useInvokeApi';
import EmailIcon from '../../../../assets/icon/email.svg';
import LinkIcon from '../../../../assets/icon/link.svg';
import Label from '../../../../components/Label';
import Link from '../../../../components/Label/Link';
import useComponentData, { FormInput } from './hooks';
import { useStyle } from './style';

export type ShareActionResult = 'CopyLink' | 'SendEmail';

interface Props {
    onClose: (result: ShareActionResult) => void;
}

export const ShareModalContent: React.FC<Props> = ({ onClose }: Props) => {
    const cs = useStyle();
    const isXSMatched = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs'),
    );
    const [isFormOpen, setIsFormOpen] = React.useState(false);
    const {
        publicPageLink,
        shareModal: sm,
        formMethods,
        errorMessage,
        setErrorMessage,
    } = useComponentData();
    const {
        control,
        handleSubmit,
        errors,
        formState: { isDirty },
    } = formMethods;
    const [, makeRequest] = useInvokeApi<Record<string, unknown>>({
        onSuccess: () => onClose('SendEmail'),
        onError: (t) => {
            setErrorMessage(t.message);
        },
    });

    const handleCopyLinkClick = () => {
        copyToClipboard(publicPageLink).then((isSuccess) => {
            if (isSuccess) onClose('CopyLink');
        });
    };

    const handleEmailClick = () => {
        setErrorMessage('');
        setIsFormOpen(true);
    };

    const onSubmit = (form: FormInput) => {
        makeRequest('POST', '/givelify-button/send-email', {
            recipientName: form.name,
            recipientEmail: form.email,
        });
    };

    const isValid = React.useMemo(
        () => Object.keys(errors).filter((e) => !!e).length >= 0,
        [errors],
    );

    return (
        <div
            className={cs.content}
            data-testid="online-giving-share-modal"
            id="online-giving-share-modal"
        >
            <Label className={cs.title} variant="heading3">
                {sm.title}
            </Label>

            {!isFormOpen ? (
                <div className={cs.optionContainer} id="share-online-giving">
                    <div
                        className={cs.roundBox}
                        data-testid="copy-link-button"
                        onClick={handleCopyLinkClick}
                    >
                        <img alt="" className={cs.icon} src={LinkIcon} />
                        <Link className={cs.link} id="copy-link" tag="a">
                            {sm.copyLink}
                        </Link>
                    </div>

                    <Label className={cs.orBox} variant="body1">
                        {sm.or}
                    </Label>

                    <div
                        className={cs.roundBox}
                        data-testid="email-share"
                        onClick={handleEmailClick}
                    >
                        <img alt="" className={cs.icon} src={EmailIcon} />
                        <Link className={cs.link} id="email-share" tag="a">
                            {sm.email}
                        </Link>
                    </div>
                </div>
            ) : (
                <form
                    className={cs.formContainer}
                    data-testid="share-email-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Controller
                        control={control}
                        defaultValue=""
                        name="name"
                        render={({ ref, ...restProps }) => (
                            <GivelifyInput
                                ariaLabel={sm.receiptName}
                                className={cs.nameInput}
                                error={!!errors['name']}
                                helperText={errors['name']?.message}
                                id="name"
                                inputLabel={sm.receiptName}
                                inputProps={{
                                    'data-testid': 'receipt-name-input',
                                }}
                                inputRef={ref}
                                placeholder={sm.receiptName}
                                size="medium"
                                {...restProps}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        defaultValue=""
                        name="email"
                        render={({ ref, ...restProps }) => (
                            <GivelifyInput
                                ariaLabel={sm.receiptEmail}
                                className={cs.emailInput}
                                error={!!errors['email']}
                                helperText={errors['email']?.message}
                                id="email"
                                inputLabel={sm.receiptEmail}
                                inputProps={{
                                    'data-testid': 'email-input',
                                }}
                                inputRef={ref}
                                placeholder={sm.receiptEmail}
                                size="medium"
                                type="email"
                                {...restProps}
                            />
                        )}
                    />

                    {errorMessage ? (
                        <GivelifyErrorBanner text={errorMessage} />
                    ) : null}

                    <div className={cs.formFooter}>
                        <GivelifyButton
                            autoWidth={isXSMatched}
                            data-testid="back-to-main-button"
                            fullWidth={isXSMatched}
                            name="backToMain"
                            onClick={() => {
                                setIsFormOpen(false);
                            }}
                            text={sm.back}
                            variant="secondary"
                        />
                        <GivelifyButton
                            autoWidth={isXSMatched}
                            className={cs.submitButton}
                            data-testid="submit-button"
                            disabled={!isValid || !isDirty}
                            fullWidth={isXSMatched}
                            name="sendEmail"
                            text={sm.send}
                            type="submit"
                            variant="primary"
                        />
                    </div>
                </form>
            )}
        </div>
    );
};
