import { useState } from 'react';
import { TFunction } from 'i18next';
export interface ErrorResponse extends Error {
  isAxiosError: boolean;
  response: {
    status: number;
    data?: {
      message?: string;
    };
  };
}
interface RequestStateSuccess<T> {
  type: 'REQUEST_SUCCESS';
  data: T;
}

export const isSucceeded = <T>(state: RequestState<T>): boolean =>
  state.type === 'REQUEST_SUCCESS';
export const isLoading = <T>(state: RequestState<T>): boolean =>
  state.type === 'REQUEST_START';
export const isFailed = <T>(state: RequestState<T>): boolean =>
  state.type === 'REQUEST_ERROR';

export const requestInit = () =>
  ({ type: 'REQUEST_INIT', data: null } as const);
export const requestStart = () =>
  ({ type: 'REQUEST_START', data: null } as const);
export const requestSuccess = <T>(data: T): RequestStateSuccess<T> =>
  ({ type: 'REQUEST_SUCCESS', data } as const);

export const requestError = (error: ErrorResponse) =>
  ({ type: 'REQUEST_ERROR', error, data: null } as const);

export const dataOrUndefined = <T>(request: RequestState<T>): T | undefined =>
  request.type === 'REQUEST_SUCCESS' ? request.data : undefined;

export type RequestState<T> =
  | ReturnType<typeof requestInit | typeof requestStart | typeof requestError>
  | RequestStateSuccess<T>;

export const useRequestState = <T>() => {
  const [requestState, setRequestState] = useState<RequestState<T>>(
    requestInit()
  );
  return [requestState, setRequestState] as const;
};

export const handleErrorMessages = (
  e: ErrorResponse,
  t: TFunction
): ErrorResponse => {
  if (!e.isAxiosError || !e.response) return e;
  const { status } = e.response;
  if (status === 404) {
    e.message = t('general.errors.status.404');
  } else if (status === 422) {
    e.message = t('general.errors.status.422');
  } else if (status === 429) {
    e.message = t('general.errors.status.429');
  } else if (status === 409) {
    e.message = t('general.errors.status.409');
  } else if (status === 500) {
    e.message = t('general.errors.status.500');
  } else if (status === 401) {
    e.message = t('general.errors.status.401');
  } else if (e.response.data?.message) {
    e.message = e.response.data.message;
  }
  return e;
};
