import { useFilterParams } from 'api/hooks/useFilterParams';

export type GivenAmountFilter = {
    lastGivenMin: string;
    lastGivenMax: string;
    totalGivenMin: string;
    totalGivenMax: string;
};

const paramNames = [
    'lastGivenMin',
    'lastGivenMax',
    'totalGivenMin',
    'totalGivenMax',
] as const;

export const useGivenAmountFilter = () => {
    const [filter, setFilter] = useFilterParams<
        GivenAmountFilter,
        typeof paramNames
    >(
        {
            lastGivenMin: '',
            lastGivenMax: '',
            totalGivenMin: '',
            totalGivenMax: '',
        },
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'totalGivenMin':
                    return state.totalGivenMin;
                case 'totalGivenMax':
                    return state.totalGivenMax;
                case 'lastGivenMin':
                    return state.lastGivenMin;
                case 'lastGivenMax':
                    return state.lastGivenMax;
            }
        },
        (values) => {
            const selectedParams: GivenAmountFilter = {
                totalGivenMin: values['totalGivenMin']
                    ? values['totalGivenMin']
                    : '',
                totalGivenMax: values['totalGivenMax']
                    ? values['totalGivenMax']
                    : '',
                lastGivenMin: values['lastGivenMin']
                    ? values['lastGivenMin']
                    : '',
                lastGivenMax: values['lastGivenMax']
                    ? values['lastGivenMax']
                    : '',
            };
            return selectedParams;
        },
    );
    return [filter, setFilter] as const;
};
