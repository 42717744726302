import React, { useCallback, useEffect, useState } from 'react';
import {
    GivelifyTablePaginatedProps,
    GivelifyTableInfiniteLoaderProps,
} from './types';
import TableInner from './TableInner';
import { GivelifyInfiniteLoader } from '../InfiniteLoader';
import { GivelifyLabel } from '../label';
import { IntegrationPaginatedResponse } from '../InfiniteLoader/types';

export * from './types';

const Error = (
    <GivelifyLabel bold text="Error" variant="heading5" margin={16} />
);

export function GivelifyTable<T>(
    props: GivelifyTablePaginatedProps<T> | GivelifyTableInfiniteLoaderProps<T>,
) {
    const { keySelector, loading, useVirtualList } = props;

    const paginatedProps = props as GivelifyTablePaginatedProps<T>;
    const isPaginatedTable = !!paginatedProps?.data;

    const infiniteLoaderProps = props as GivelifyTableInfiniteLoaderProps<T>;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const keySelectorCallback = useCallback(keySelector, []);

    // In most cases we load data with useApiReqest hook and consume its result with useEffect
    // If rendering happens before useEffect executed - we will see some flickering
    // when react first renders loading state then previous state for a moment and then another render comes with updated data
    // Thats why we need this flag here.
    // Would be nice to update useApiRequest hook to provide successful flag and set it to true after some onSuccess callback or something like that
    const [dataLoaded, setDataLoaded] = useState(!loading);
    useEffect(() => {
        setDataLoaded(!loading);
    }, [loading]);

    if (isPaginatedTable)
        return (
            <TableInner
                {...props}
                keySelector={keySelectorCallback}
                dataLoaded={dataLoaded}
                data={paginatedProps.data}
                useVirtualList={useVirtualList}
                rowId={props.rowId}
            />
        );

    return (
        <GivelifyInfiniteLoader
            LoadingComponent={
                <TableInner
                    {...props}
                    keySelector={keySelectorCallback}
                    dataLoaded={false}
                    data={[]}
                />
            }
            NoDataComponent={infiniteLoaderProps.noDataComponent || <div />}
            ErrorComponent={Error}
            reset={undefined}
            isError={infiniteLoaderProps.isError}
            isLoading={infiniteLoaderProps.isLoading}
            loadNextPage={infiniteLoaderProps.loadNextPage}
            data={infiniteLoaderProps.data}
            pageNumber={infiniteLoaderProps.pageNumber}
            renderData={(data: IntegrationPaginatedResponse<any>) => (
                <GivelifyTable<T>
                    {...props}
                    keySelector={keySelector}
                    data={
                        infiniteLoaderProps.mapData
                            ? infiniteLoaderProps.mapData(data.data)
                            : data.data
                    }
                    loading={false}
                    rowId={props.rowId}
                />
            )}
        />
    );
}
