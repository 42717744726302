import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { pageNoFoundSvg } from './svgComponent';
import { GivelifyLabel } from '../label';
import { GivelifyButton } from '../button';
import { ErrorPageProps, useErrorPageStyles } from './utils';

const useStyles = makeStyles(() =>
    createStyles({
        '@keyframes rainRow1': {
            '0%': {
                transform: 'translateY(-40px)',
                opacity: 1,
            },
            '90%': {
                opacity: 1,
            },
            '100%': {
                transform: 'translateY(86px)',
                opacity: 0,
            },
        },
        '@keyframes rainRow2': {
            '0%': {
                transform: 'translateY(-60px)',
                opacity: 1,
            },
            '90%': {
                opacity: 1,
            },
            '100%': {
                transform: 'translateY(70px)',
                opacity: 0,
            },
        },
        '@keyframes rainRow3': {
            '0%': {
                transform: 'translateY(-60px)',
                opacity: 1,
            },
            '90%': {
                opacity: 1,
            },
            '100%': {
                transform: 'translateY(60px)',
                opacity: 0,
            },
        },
        '@keyframes rainRow4': {
            '0%': {
                transform: 'translateY(-60px)',
                opacity: 1,
            },
            '90%': {
                opacity: 1,
            },
            '100%': {
                transform: 'translateY(45px)',
                opacity: 0,
            },
        },
        '@keyframes stem': {
            '0%': {
                transform: 'scaleY(0)',
                opacity: 1,
            },
            '100%': {
                transform: 'scaleY(1)',
                opacity: 1,
            },
        },
        '@keyframes leaf': {
            '0%': {
                transform: 'scale(0)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(1)',
                opacity: 1,
            },
        },
        '@keyframes flower': {
            '0%': {
                transform: 'scale(0)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(1)',
                opacity: 1,
            },
        },
        svgStyles: {
            '& #flower, & #leaf, & #stem, & #rain1, & #rain2, & #rain3, & #rain4':
                {
                    opacity: 0,
                },
            '& #flower': {
                animation: '$flower 2s linear',
                animationDelay: '3.8s',
                transformOrigin: 'bottom center',
                transformBox: 'fill-box',
                animationFillMode: 'forwards',
            },
            '& #leaf': {
                animation: '$leaf 1s linear',
                animationDelay: '2s',
                transformOrigin: 'bottom left',
                transformBox: 'fill-box',
                animationFillMode: 'forwards',
            },
            '& #stem': {
                animation: '$stem 3s linear',
                animationDelay: '1s',
                transformOrigin: 'bottom',
                transformBox: 'fill-box',
                animationFillMode: 'forwards',
            },
            '& #rain1': {
                animation: '$rainRow1 2s linear 2',
                transformBox: 'fill-box',
            },
            '& #rain2': {
                animation: '$rainRow2 2s linear 2',
                animationDelay: '0.5s',
                transformBox: 'fill-box',
            },
            '& #rain3': {
                animation: '$rainRow3 2s linear 2',
                animationDelay: '1s',
                transformBox: 'fill-box',
            },
            '& #rain4': {
                animation: '$rainRow4 2s linear 2',
                animationDelay: '1.8s',
                transformBox: 'fill-box',
            },
        },
    }),
);

export const PageNotFound: React.FC<ErrorPageProps> = ({
    pageNotFoundText,
    descriptionText,
    overviewButtonText,
    returnButtonText,
    overviewButtonUrl,
    showReturnToPreviousButton,
    marginTopDesktop,
    marginTopMobile,
}) => {
    const { svgStyles } = useStyles();
    const { styles } = useErrorPageStyles({
        showReturnToPreviousButton,
        svgWidth: 240,
        marginTopDesktop,
        marginTopMobile,
    });
    const goBack = () => window.history.go(-1);
    return (
        <div className={styles} data-testid="page-not-found">
            <div className={svgStyles}>{pageNoFoundSvg}</div>
            <h1>{pageNotFoundText || 'Page not found'}</h1>
            <GivelifyLabel
                text={
                    descriptionText ||
                    'The page you requested could not be found.'
                }
                variant="heading2"
                className="label"
            />
            <div className="buttons">
                {overviewButtonUrl && (
                    <GivelifyButton
                        size="xLarge"
                        variant="primary"
                        text={overviewButtonText || 'Go To Overview'}
                        className="main-button"
                        onClick={() =>
                            (window.location.href = overviewButtonUrl)
                        }
                    />
                )}
                {showReturnToPreviousButton && (
                    <GivelifyButton
                        variant="secondary"
                        text={returnButtonText || 'Return To Previous Page'}
                        size="xLarge"
                        className="previous-button"
                        onClick={goBack}
                    />
                )}
            </div>
        </div>
    );
};
