import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AvatarIcon from '../../../assets/icon/avatar.svg';
import Config from '../../../config';
import { GIVELIFY_LOGO_URL } from '../../../types/const';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            '& a': {
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        },
        appBar: {
            background: '#fefbfa',
            '-webkit-box-shadow': '0px 0px 10px 3px #ccc',
            '-moz-box-shadow': '0px 0px 10px 3px #ccc',
            boxShadow: '0px 0px 10px 3px #ccc',
        },
        toolBar: {
            paddingLeft: '57px',
            paddingRight: '57px',
            minHeight: '80px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        menuButton: {
            marginLeft: 'auto',
        },
        logoImg: {
            maxWidth: '142px',
            width: '100%',

            [theme.breakpoints.down('sm')]: {
                maxWidth: '100px',
            },
        },
        joinNowLink: {
            background: '#715AFF',
            borderRadius: '60px',
            textAlign: 'center',
            padding: '15px 25px',
            '&:hover': {
                background: '#5F48F0',
            },
            color: 'white',
        },
        signInLink: {
            color: '#715AFF',
            marginLeft: '24px',

            '& > *': {
                verticalAlign: 'middle',
            },
            '& $linkFont': {
                marginRight: '8px',
            },
        },
        linkFont: {
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '16px',
            textTransform: 'uppercase',
        },
    }),
);

export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} elevation={1} position="fixed">
                <Toolbar className={classes.toolBar}>
                    <a
                        href={Config.givelifyBaseUrl}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <img
                            alt="Givelify"
                            className={classes.logoImg}
                            data-testid="givelify-logo"
                            src={GIVELIFY_LOGO_URL}
                        />
                    </a>

                    <div className={classes.menuButton}>
                        <a
                            className={classes.joinNowLink}
                            data-testid="join-now-link"
                            href={Config.signupBaseUrl}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span className={classes.linkFont}>JOIN NOW</span>
                        </a>
                        <a
                            className={classes.signInLink}
                            data-testid="sign-in-link"
                            href={Config.analyticsBaseUrl}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span className={classes.linkFont}>SIGN IN</span>
                            <img alt="" src={AvatarIcon} />
                        </a>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
