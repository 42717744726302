import React from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { Grid } from '@material-ui/core';
import { matchRowStyle } from 'pages/integrations/components/style';
import { IntegrationTypes } from 'pages/integrations/types';
import CCBEnvelopeMatchingHeader from './CCBEnvelopeMatchingHeader';
import CommonEnvelopeMatchingHeader from './CommonEnvelopeMatchingHeader';

interface EnvelopeMatchingHeaderProps {
    integration: IntegrationTypes;
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: (sorting: Sorting[]) => void;
    copy: {
        sort: string;
    };
}

const EnvelopeMatchingHeader: React.FC<EnvelopeMatchingHeaderProps> = ({
    integration,
    copy,
    onOrderClick,
    sortColumn,
    sortDirection,
}) => {
    const classes = matchRowStyle();

    return (
        <Grid container className={classes.matchHeader}>
            {integration.integrationType === 'CCB' ? (
                <CCBEnvelopeMatchingHeader
                    copy={copy}
                    onOrderClick={onOrderClick}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            ) : (
                <CommonEnvelopeMatchingHeader integration={integration} />
            )}
        </Grid>
    );
};

export default EnvelopeMatchingHeader;
