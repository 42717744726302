import React, { useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { reportItemStyles } from '../../../components/style';

interface ReportLeftColumnProps {
    type?: 'custom' | 'quickbooks';
    image: JSX.Element | string;
    name?: string;
    reportInclude: string;
    definition?: string;
}

const ReportInformation: React.FC<ReportLeftColumnProps> = (props) => {
    const classes = reportItemStyles();
    const { t } = useAdvancedTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const { timezoneLong, timezoneShort, timezoneEnabled } = useSelector(
        (state: AppState) => {
            return {
                timezoneLong: state.Donee.donee.timezoneLong,
                timezoneShort: state.Donee.donee.timezoneShort,
                timezoneEnabled:
                    isFeatureEnabled(
                        state.System.enabledFeatures,
                        Features.TIMEZONES,
                        false,
                    ) && state.Donee.donee.timezone !== null,
            };
        },
    );
    const onShowInfo = () => {
        setShowInfo(true);
    };
    const onHideInfo = () => {
        setShowInfo(false);
    };
    const copy = useMemo(
        () => ({
            viewDetails: t('reports.viewDetails'),
            closeDetails: t('reports.closeDetails'),
            timezoneNote: t('timezones.notes.reports'),
        }),
        [t],
    );

    return (
        <div className={classes.reportInformationContent}>
            <div className={classes.reportImage}>{props.image}</div>
            <div className={classes.reportDefinition}>
                <GivelifyLabel
                    bold
                    className={classes.defineBold}
                    text={props.reportInclude}
                    variant="body1"
                />{' '}
                {isMobile && showInfo && (
                    <Stack gap={2}>
                        <GivelifyLabel
                            className={classes.defineNormal}
                            text={props.definition}
                            variant="body1"
                        />
                        {timezoneEnabled ? (
                            <Stack gap={0.5}>
                                <GivelifyLabel
                                    className={classes.defineNormal}
                                    text={copy.timezoneNote}
                                    variant="body1"
                                />
                                <GivelifyLabel
                                    bold
                                    className={classes.defineNormal}
                                    text={`${timezoneLong} - ${timezoneShort}`}
                                    variant="body1"
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                )}
                {!isMobile && (
                    <Stack gap={2}>
                        <GivelifyLabel
                            className={classes.defineNormal}
                            text={props.definition}
                            variant="body1"
                        />
                        {timezoneEnabled ? (
                            <Stack gap={0.5}>
                                <GivelifyLabel
                                    className={classes.defineNormal}
                                    text={copy.timezoneNote}
                                    variant="body1"
                                />
                                <GivelifyLabel
                                    bold
                                    className={classes.defineNormal}
                                    text={`${timezoneLong} - ${timezoneShort}`}
                                    variant="body1"
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                )}
                {isMobile && !!props.definition && (
                    <GivelifyButton
                        className={classes.detailBtn}
                        endIconVariant={
                            showInfo ? 'chevron-up' : 'chevron-down'
                        }
                        onClick={showInfo ? onHideInfo : onShowInfo}
                        text={showInfo ? copy.closeDetails : copy.viewDetails}
                        variant="ghost"
                    />
                )}
            </div>
        </div>
    );
};

export default ReportInformation;
