import { styled } from '@mui/material';
import { DonationPaper } from '../donationsPage/styles';

export const DonationActivityPaper = styled(DonationPaper, {
    shouldForwardProp: (prop) => prop !== 'hasFiltersAndSearch',
})<{ hasFiltersAndSearch: boolean }>(
    ({ theme }) =>
        ({ hasFiltersAndSearch }) => ({
            padding: 0,
            paddingBottom: theme.spacing(4),
            boxShadow: 'none',
            ...(hasFiltersAndSearch
                ? {}
                : { paddingTop: '8px', background: 'none' }),
            [theme.breakpoints.down('mobile')]: {
                padding: 0,
                paddingBottom: theme.spacing(4),
            },
        }),
);
