import dayjs from 'dayjs';

export type FormEnvelope = {
    id: number;
    name: string;
    description: string;
    externalId: string;
    hasExternalId: boolean;
    isDefault: boolean;
    isAlways: IsAlwaysValue;
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    hasGoal: HasGoalValue;
    goal: number;
};

export enum isAlwaysValues {
    ALWAYS = 'Always',
    TIMED = 'Timed',
}
export type IsAlwaysValue = 'Always' | 'Timed';

export enum hasGoalValues {
    NO = 'no',
    YES = 'yes',
}
export type HasGoalValue = 'no' | 'yes';
