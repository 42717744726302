import { DonationResponseData } from 'pages/donors/DonorProfile';
import { PaginatedResponse } from './paginationTypes';

export interface DonationsResponseMeta {
    count: number;
    sum: number;
    average: number;
}

export interface DonationsResponse
    extends PaginatedResponse<DonationResponseData> {
    meta: DonationsResponseMeta;
}

export interface DonationNoteBase {
    id: number;
    memo: string;
}

export interface DonationNote extends DonationNoteBase {
    donationId: number;
    reply: {
        text: string;
        userName: string;
        userPicture: string;
    };
}

export interface DonationNoteResponse extends DonationNoteBase {
    donationId: number;
    reply: {
        text: string;
        userName: string;
        userPicture: string;
    };
}

export const mapDonationNoteResponseDataToDonationNote = (
    note: DonationNoteResponse,
): DonationNote => {
    if (!note) return null;

    const mappedDonation: DonationNote = {
        id: note.id,
        donationId: note.donationId,
        memo: note.memo,
        reply: {
            text: note.reply.text,
            userName: note.reply.userName,
            userPicture: note.reply.userPicture,
        },
    };
    return mappedDonation;
};
