import { useEffect, useRef, useState } from 'react';
import { useApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { useFilterParams } from 'api/hooks';
import { GetEnvelopesResponse } from 'api/services/responses/envelopes';
import { getEnvelopesService } from 'api/utils/serviceProvider';
import dayjs from 'dayjs';
import { ListItem } from './types';

const paramNames = ['envelopeIds'] as const;

export type FilterState = {
    loading: boolean;
    options: ListItem[];
    values: number[];
};

export const useEnvelopesFilter = (
    doneeId: number,
    timeFrame: TimeFrameValue,
) => {
    const [prevTimeFrame, setPrevTimeFrame] = useState<
        TimeFrameValue | undefined
    >(undefined);
    const [defaultValues, setDefaultValues] = useState<number[] | undefined>(
        undefined,
    );
    const [filterState, setFilterState] = useState<FilterState>({
        loading: true,
        options: [],
        values: [],
    });
    const service = getEnvelopesService();
    const [getRequest, makeGetRequest] = useApiRequest<GetEnvelopesResponse>();
    const isFirsyLoadRef = useRef(true);
    const [filter, setFilter] = useFilterParams<
        typeof defaultValues,
        typeof paramNames
    >(
        defaultValues,
        paramNames,
        (state, _paramName) => {
            if (
                state === undefined ||
                state.length === filterState.options.length
            ) {
                return '';
            }
            if (state.length > 0) {
                return state.join('_');
            } else if (state?.length === 0) {
                return 'none';
            }
            return '';
        },
        (values) => {
            if (values['envelopeIds'] === 'none') {
                return [];
            }
            const splited = values['envelopeIds'].split('_');
            if (splited.length > 0) {
                const newValues = splited
                    .map((value) => parseInt(value))
                    .filter((x) => !isNaN(x))
                    .sort();
                return newValues;
            }
            return undefined;
        },
    );
    useEffect(() => {
        if (
            prevTimeFrame === undefined ||
            prevTimeFrame.selector !== timeFrame.selector ||
            !dayjs(prevTimeFrame.start).isSame(dayjs(timeFrame.start), 'day') ||
            !dayjs(prevTimeFrame.end).isSame(dayjs(timeFrame.end), 'day')
        ) {
            void makeGetRequest(
                service.getEnvelopesData(doneeId, {
                    timeFrame: timeFrame,
                    sort: 'priority',
                    pageSize: 9999,
                }),
            );
            if (prevTimeFrame !== undefined) {
                setFilter(undefined);
            }
            setPrevTimeFrame(timeFrame);
            setFilterState({
                options: [],
                values: [],
                loading: true,
            });
        }
        // listen only to timeFrame and doneeId change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeFrame, doneeId]);

    useEffect(() => {
        if (getRequest.type === 'REQUEST_SUCCESS') {
            const options = getRequest.response.data.map((envelope) => ({
                id: envelope.id,
                label: envelope.name,
            }));
            const isFirstLoad = isFirsyLoadRef.current;
            const values =
                isFirstLoad && filter
                    ? filter
                    : options.map((option) => option.id);
            const newDefaultValues = options.map((option) => option.id);
            setFilterState({
                options,
                values,
                loading: false,
            });
            setDefaultValues(newDefaultValues);
            isFirsyLoadRef.current = false;
        }
        //listen only to getRequest change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRequest]);

    const onChange = (values: number[]) => {
        setFilter(values);
        setFilterState({
            ...filterState,
            values,
            loading: false,
        });
    };

    return [filterState, onChange] as const;
};
