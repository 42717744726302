import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import ZeroState from '../../ZeroState';
import DonationsStatusWidget from '../components/DonationsStatusWidget';

interface TotalDonorsProps {
    newDonorsCount: number | undefined;
    totalDonorsCount: number | undefined;
}

const src = '/overview/total-donor.svg';
const srcGrey = '/overview/total-donor-grey.svg';

const TotalDonors: React.FC<TotalDonorsProps> = ({
    newDonorsCount,
    totalDonorsCount,
}) => {
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            unknown: t('Unknown'),
            newDonorsCount: t('dashboard.totalDonors.newDonors'),
            donors: t('dashboard.totalDonors.donors'),
        }),
        [t],
    );

    const footerLeftNumber = useMemo(
        () => ({
            label: newDonorsCount >= 0 ? String(newDonorsCount) : copy.unknown,
            value: copy.newDonorsCount,
        }),
        [newDonorsCount, copy],
    );

    const { onboardingEnabled, onboardingCompleted } = useSelector(
        (state: AppState) => ({
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
        }),
    );

    if (totalDonorsCount === 0) {
        return (
            <ZeroState
                src={onboardingEnabled && onboardingCompleted ? src : srcGrey}
                type={copy.donors}
            />
        );
    }

    return (
        <DonationsStatusWidget
            cardId="donors"
            footerLeftNumber={footerLeftNumber}
            total={totalDonorsCount}
            totalPercentage={undefined}
        />
    );
};

export default TotalDonors;
