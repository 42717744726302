import React from 'react';
import { BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { styled } from '@mui/material';
import {
    LeftChevronBarIcon,
    LeftChevronBigIcon,
    RightChevronBarIcon,
    RightChevronBigIcon,
} from '../assets';
import { GivelifyButton } from '../button';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { GivelifyTextField } from '../textField';

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('tablet')]: {
        gap: '8px',
    },
}));

const CenterWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
});

const PageInput = styled(GivelifyTextField, {
    shouldForwardProp: (prop) => prop !== 'inputWidth',
})<{ inputWidth: number }>(({ inputWidth }) => ({
    width: `${inputWidth}px`,
    height: '24px',
    borderRadius: '4px',
    '& .MuiInputBase-root': {
        padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${DesignTokens.border.borderComponentDefault.color}`,
    },
    '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: DesignTokens.color.globalNeutral600,
        },
    },
    '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${DesignTokens.color.globalNeutral700}`,
        },
    },
    '& .MuiOutlinedInput-input': {
        boxSizing: 'border-box',
        padding: '0 6px',
        textAlign: 'center',
    },
}));

const ArrowsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('tablet')]: {
        gap: '4px',
    },
}));

const ArrowButton = styled(GivelifyButton)({
    padding: 0,
    color: DesignTokens.color.iconAccentDefault,
    '&.Mui-disabled': {
        color: DesignTokens.color.iconSecondary,
    },
});

export type GivelifyPaginationComponentProps = {
    pageNumber: number;
    maxPages: number;
    onPageChange: (pageNumber: number) => void;
};

const GivelifyPaginationComponent: React.FC<
    GivelifyPaginationComponentProps
> = ({ pageNumber, maxPages, onPageChange }) => {
    const { trackEvent } = useTrackingContext();
    const [pageNumberValue, setPageNumberValue] = React.useState(pageNumber);
    const [pageInputValue, setPageInputValue] = React.useState(
        pageNumber.toString(),
    );
    const FONT_SIZE = 16;
    const DEFAULT_INPUT_WIDTH = 40;

    const [inputWidth, setInputWidth] = React.useState(DEFAULT_INPUT_WIDTH);

    React.useEffect(() => {
        if (
            pageNumberValue.toString().length * FONT_SIZE >
            DEFAULT_INPUT_WIDTH
        ) {
            setInputWidth(pageNumberValue.toString().length * FONT_SIZE);
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH);
        }
    }, [pageNumberValue]);

    React.useEffect(() => {
        setPageNumberValue(pageNumber);
        setPageInputValue(pageNumber.toString());
    }, [pageNumber]);

    const onPrevClick = React.useCallback(() => {
        const prevPage = Number(pageNumberValue) - 1;
        if (prevPage < 1) return;
        trackEvent(
            `<Pagination>_${BUTTON_IDENTIFIER}`,
            { action: 'Previous' },
            'click',
        );
        onPageChange(prevPage);
        setPageNumberValue(prevPage);
        setPageInputValue(prevPage.toString());
    }, [onPageChange, pageNumberValue, trackEvent]);

    const onNextClick = React.useCallback(() => {
        const nextPage = Number(pageNumberValue) + 1;
        if (nextPage > maxPages) return;
        trackEvent(
            `<Pagination>_${BUTTON_IDENTIFIER}`,
            { action: 'Next' },
            'click',
        );
        onPageChange(nextPage);
        setPageNumberValue(nextPage);
        setPageInputValue(nextPage.toString());
    }, [onPageChange, pageNumberValue, maxPages, trackEvent]);

    const onGoToFirstClick = React.useCallback(() => {
        onPageChange(1);
        setPageNumberValue(1);
        setPageInputValue('1');
        trackEvent(
            `<Pagination>_${BUTTON_IDENTIFIER}`,
            { action: 'First' },
            'click',
        );
    }, [onPageChange, trackEvent]);

    const onGoToLastClick = React.useCallback(() => {
        onPageChange(maxPages);
        setPageNumberValue(maxPages);
        setPageInputValue(maxPages.toString());
        trackEvent(
            `<Pagination>_${BUTTON_IDENTIFIER}`,
            { action: 'Last' },
            'click',
        );
    }, [onPageChange, maxPages, trackEvent]);

    const onBlurHandler = React.useCallback(() => {
        if (pageNumberValue !== pageNumber) {
            onPageChange(Number(pageNumberValue));
        } else if (pageInputValue === '') {
            setPageInputValue(pageNumber.toString());
        }
    }, [onPageChange, pageInputValue, pageNumber, pageNumberValue]);

    const onInputChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setPageInputValue(e.target.value);
            if (e.target.value) {
                setPageNumberValue(Number(e.target.value));
            }
        },
        [],
    );

    const onKeyDownHandler = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                onPageChange(Number(pageNumberValue));
            }
        },
        [onPageChange, pageNumberValue],
    );

    return (
        <PaginationWrapper data-testid="pagination-wrapper">
            <ArrowsWrapper>
                <ArrowButton
                    data-testid="pagination-first-button"
                    disabled={pageNumberValue === 1}
                    onClick={onGoToFirstClick}
                    size="small"
                    variant="icon"
                >
                    <LeftChevronBarIcon />
                </ArrowButton>
                <ArrowButton
                    data-testid="pagination-prev-button"
                    disabled={pageNumberValue === 1}
                    onClick={onPrevClick}
                    size="small"
                    variant="icon"
                >
                    <LeftChevronBigIcon />
                </ArrowButton>
            </ArrowsWrapper>
            <CenterWrapper id="pagination-center-wrapper">
                <PageInput
                    fullWidth
                    inputProps={{
                        'data-testid': 'pagination-page-input',
                    }}
                    inputWidth={inputWidth}
                    maskOptions={{
                        mask: Number,
                        scale: 2,
                        max: maxPages,
                        min: 1,
                    }}
                    onBlur={onBlurHandler}
                    onChange={onInputChange}
                    onKeyDown={onKeyDownHandler}
                    value={pageInputValue}
                />
                <GivelifyLabel
                    data-testid="pagination-max-pages"
                    text={`of ${maxPages}`}
                    variant="body1"
                />
            </CenterWrapper>
            <ArrowsWrapper>
                <ArrowButton
                    data-testid="pagination-next-button"
                    disabled={pageNumberValue === maxPages}
                    onClick={onNextClick}
                    size="small"
                    variant="icon"
                >
                    <RightChevronBigIcon />
                </ArrowButton>
                <ArrowButton
                    data-testid="pagination-last-button"
                    disabled={pageNumberValue === maxPages}
                    onClick={onGoToLastClick}
                    size="small"
                    variant="icon"
                >
                    <RightChevronBarIcon />
                </ArrowButton>
            </ArrowsWrapper>
        </PaginationWrapper>
    );
};

export const GivelifyPagination = React.memo(GivelifyPaginationComponent);
