import React, { useMemo } from 'react';
import {
    GivelifyModal,
    GivelifyIcon,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useCampusesContext } from '../../CampusesProvider';
import useStyles from './styles';

const CampusSubmittedModal = () => {
    const { modal, icon, text1, text2 } = useStyles();
    const {
        showCampusSubmittedModal,
        setShowCampusSubmittedModal,
        setShowAddCampusModal,
    } = useCampusesContext();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY:
            'pages.settings.campuses.addCampusModal.campusSubmittedModal',
    });

    const copy = useMemo(
        () => ({
            addAnotherCampus: scopedTranslate('addAnotherCampus'),
            campusSubmitted: scopedTranslate('campusSubmitted'),
            description1: scopedTranslate('description1'),
            description2: scopedTranslate('description2'),
        }),
        [scopedTranslate],
    );

    return (
        <GivelifyModal
            autoFullscreen
            centerButtons
            showCloseButton
            className={modal}
            onClose={() => setShowCampusSubmittedModal(false)}
            onSecondaryClick={() => {
                setShowCampusSubmittedModal(false);
                setShowAddCampusModal(true);
            }}
            open={showCampusSubmittedModal}
            secondaryButtonText={copy.addAnotherCampus}
        >
            <GivelifyIcon className={icon} variant="institution" />
            <GivelifyLabel text={copy.campusSubmitted} variant="heading1" />
            <GivelifyLabel
                className={text1}
                text={copy.description1}
                variant="body1"
            />
            <GivelifyLabel
                className={text2}
                text={copy.description2}
                variant="body1"
            />
        </GivelifyModal>
    );
};

export default CampusSubmittedModal;
