import { Reducer } from 'redux';
import { OfficersState, OfficersActions, OFFICERS, Officer } from './types';

const officers: Officer[] = [];
const officerTitles: string[] = [];

const initialState = {
    officers,
    officersLoading: false,
    officerTitles,
    officerTitlesLoading: false,
};

const OfficersReducer: Reducer<OfficersState, OfficersActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case OFFICERS.SET:
            return {
                ...state,
                officers: action.data,
            };
        case OFFICERS.LOAD_OFFICERS_START:
            return {
                ...state,
                officersLoading: true,
            };
        case OFFICERS.LOAD_OFFICERS_FINISH:
            return {
                ...state,
                officersLoading: false,
            };
        case OFFICERS.SET_OFFICER_TITLES:
            return {
                ...state,
                officerTitles: action.data,
            };
        case OFFICERS.LOAD_OFFICER_TITLES_START: {
            return {
                ...state,
                officerTitlesLoading: true,
            };
        }
        case OFFICERS.LOAD_OFFICER_TITLES_FINISH: {
            return {
                ...state,
                officerTitlesLoading: false,
            };
        }
        default:
            return state;
    }
};

export default OfficersReducer;
