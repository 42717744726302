export const reportList = [
    {
        label: 'ACS',
        value: 'ACS',
    },
    {
        label: 'POWERCHURCH',
        value: 'POWERCHURCH',
    },
    {
        label: 'SERVANTKEEPER',
        value: 'SERVANTKEEPER',
    },
    {
        label: 'SHELBY',
        value: 'SHELBY',
    },
    {
        label: 'ACSREALM',
        value: 'ACSREALM',
    },
    {
        label: 'SHELBYARENA',
        value: 'SHELBYARENA',
    },
    {
        label: 'SHELBYNEXT',
        value: 'SHELBYNEXT',
    },
];
