import { ImageWithDimensions } from '@givelify/onboarding';
import { AppState } from '..';

export interface User {
    id: number;
    doneeId: number;
    username: string;
    fullName: string;
    firstName: string;
    lastName: string;
    profilePicUrl: string;
    isSuper?: boolean;
    avatarOriginal?: string;
    avatar: string;
    createdDateUser: Date;
    doneeName: string;
    notify: boolean;
    phone: string;
    title: string;
    email: string;
    officialId: number;
    isSynagogue?: boolean;
    role: string;
    roleId: number;
}

export type HeaderUserData = Pick<User, 'avatar' | 'avatarOriginal' | 'fullName'>;

export interface UserInfoRequest {
    email?: string;
    first_name: string;
    last_name: string;
    title: string;
    // avatar: string;
    role_id: number;
}

export interface ContactInfoRequest {
    first_name: string;
    last_name: string;
    title: string;
    email: string;
    is_contact_info_confirmation: boolean;
}

export const UserPermissions = {
    Basic: 1,
    Financial: 2,
    Admin: 3,
};

export const isLoggedIn = (state: AppState): boolean =>
    !!state.User.accessToken;

export interface UserState {
    accessToken?: string;
    email?: User['email'];
    user: User | null;
    hideContinueOnMobileScreen?: boolean;
}

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const SET_EMAIL = 'SET_EMAIL';
export const HIDE_CONTINUE_ON_MOBILE_SCREEN = 'HIDE_CONTINUE_ON_MOBILE_SCREEN';

export interface SetAccessToken {
    type: typeof SET_ACCESS_TOKEN;
    accessToken: UserState['accessToken'];
}

export interface SetUser {
    type: typeof SET_USER;
    user: UserState['user'];
}

export interface SetUserAvatar {
    type: typeof SET_USER_AVATAR;
    avatar: ImageWithDimensions;
}

export interface SetUserEmail {
    type: typeof SET_EMAIL;
    email?: UserState['email'];
}

export interface SetHideContinueOnMobileScreen {
    type: typeof HIDE_CONTINUE_ON_MOBILE_SCREEN;
}

export type UserActions =
    | SetAccessToken
    | SetUser
    | SetUserEmail
    | SetUserAvatar
    | SetHideContinueOnMobileScreen;
