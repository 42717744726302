import { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { I18N_NAMESPACE } from '../../../../../consts';
import { DocumentType } from '../types';

export const useUploadDocumentTranslation = (documentType: DocumentType) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.checkingAccount.uploadDocument`,
    });

    const { scopedTranslate: scopedTranslateByType, t } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.checkingAccount.uploadDocument.${documentType}`,
    });

    const copy = useMemo(
        () => ({
            delete: scopedTranslate('delete'),
            close: scopedTranslate('close'),
            viewLarger: scopedTranslate('viewLarger'),
            submit: scopedTranslate('submit'),
            loading: t('captivePortal.documentUploadLoading'),
            submitSuccess: t('captivePortal.documentUploadSuccess'),
        }),
        [scopedTranslate, t],
    );

    const text = useMemo(
        () => ({
            notificationText: scopedTranslateByType(`notificationText`),
            heading: scopedTranslateByType(`heading`),
            description: scopedTranslateByType(`description`),
            descriptionMobile1: scopedTranslateByType(`descriptionMobile1`),
            descriptionMobile2: scopedTranslateByType(`descriptionMobile2`),
            descriptionMobile3: scopedTranslateByType(`descriptionMobile3`),
            listItems: [
                scopedTranslateByType(`listItem1`),
                scopedTranslateByType(`listItem2`),
                (documentType === 'verificationLetter' ||
                    documentType === 'voidedCheck') &&
                    scopedTranslateByType(`listItem3`),
                (documentType === 'verificationLetter' ||
                    documentType === 'voidedCheck') &&
                    scopedTranslateByType(`listItem4`),
                documentType === 'verificationLetter' &&
                    scopedTranslateByType(`listItem5`),
            ].filter((i) => i),
            buttonText: scopedTranslateByType(`buttonText`),
            imageAlt: scopedTranslateByType(`imageAlt`),
            documentType: scopedTranslateByType(`documentType`),
            informationHeader: scopedTranslateByType(`informationHeader`),
            exampleModalHeader: scopedTranslateByType(`exampleModalHeader`),
            uploadedModalHeader: scopedTranslateByType(`uploadedModalHeader`),
        }),
        [documentType, scopedTranslateByType],
    );

    return { copy, text };
};
