import React, { ReactNode } from 'react';
import { GivelifyLabelStyles } from '@givelify/givelify-ui';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { moreLabelStyles } from './style';
import { LabelVariant } from './types';

// 'a' means default anchor tag
// 'link' means react-router's link tag

type LinkType = 'a' | 'link';
type LabelLinkProps<T> = T extends 'link'
    ? LinkProps
    : React.AnchorHTMLAttributes<HTMLAnchorElement>;
type ExtendedLabelLinkProps<T extends LinkType = 'a'> = LabelLinkProps<T> & {
    variant?: LabelVariant;
    tag?: T;
    children: ReactNode;
    isInline?: boolean;
};

// Link component
export default function Link<T extends LinkType = 'a'>({
    variant = 'body1',
    children,
    isInline = false,
    tag,
    className,
    ...restProps
}: ExtendedLabelLinkProps<T>) {
    const { bold, ...styles } = GivelifyLabelStyles({});
    const { link, inline } = moreLabelStyles();
    const variantStyle = styles[variant as keyof typeof styles];

    const classes = `${className || ''} ${variantStyle} ${link} ${
        isInline ? inline : ''
    }`;

    return tag === 'link' && 'to' in restProps ? (
        <RouterLink  {...restProps} className={classes} to={restProps.to as string}>
            {children}
        </RouterLink>
    ) : (
        <a {...restProps} className={classes}>
            {children}
        </a>
    );
}
