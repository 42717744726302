import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyPaper,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';
import Slick from 'react-slick';

export const DismissButton = styled(GivelifyButton)(({ theme }) => ({
    '&&': {
        padding: 0,
        background: 'none',
    },
    [theme.breakpoints.down('mobile')]: {
        marginTop: '8px',
        '& p': {
            fontSize: '14px',
            lineHeight: '24px',
        },
    },
}));

export const ThumbnailImg = styled('img')(({ theme }) => ({
    width: '100%',
    marginBottom: '16px',
    borderRadius: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('mobile')]: {
        height: 'auto',
        minHeight: '142px',
        objectFit: 'cover',
    },
}));

export const VideoIframeWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    '& iframe': {
        borderRadius: '20px',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

export const VideoModalPaper = styled(GivelifyPaper, {
    shouldForwardProp: (prop) => prop !== 'hasTopPadding',
})<{ hasTopPadding: boolean }>(({ theme, hasTopPadding }) => ({
    '&&': {
        width: '100%',
        height: '732px',
        maxWidth: '989px',
        padding: '24px 32px 32px',
        paddingTop: hasTopPadding ? '77px' : '24px',
        '& .modal-close-btn': {
            position: 'absolute',
            right: 28,
            top: 20,
        },
    },
    [theme.breakpoints.down('tablet')]: {
        margin: '0 90px',
        '&&': {
            paddingTop: '64px',
            height: 'auto',
        },
    },
    [theme.breakpoints.down('mobile')]: {
        margin: '0 8px',
        '&&': {
            padding: '24px',
            paddingTop: '64px',
            height: 'auto',
            '& .modal-close-btn': {
                position: 'absolute',
                right: 24,
                '& img': {
                    width: '24px',
                    height: '24px',
                },
            },
        },
    },
}));

export const VideoModalDescription = styled(GivelifyLabel)(({ theme }) => ({
    '& strong': {
        fontWeight: 800,
    },
    [theme.breakpoints.down('tablet')]: {
        fontSize: '18px',
        lineHeight: '32px',
    },
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const VideoModalBackBtn = styled(GivelifyButton)(({ theme }) => ({
    flex: '0 0 234px',
    padding: '12px 24px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('mobile')]: {
        flex: '100%',
    },
}));

export const VideoModalBottomGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        marginTop: '16px',
    },
}));

export const WorshipModalTitleText = styled(GivelifyLabel)({
    marginBottom: '21px',
    '& strong': {
        fontWeight: 800,
    },
});

export const StyledSlick = styled(Slick)(({ theme }) => ({
    width: 'calc(100% - 65px)',
    padding: '0 15px',
    margin: '0 auto',
    '& .slick-arrow': {
        '&.slick-disabled': {
            display: 'none !important',
        },
    },
    '& .slick-prev': {
        width: '32px',
        height: '32px',
        left: '-10px',
    },
    '& .slick-next': {
        width: '32px',
        height: '32px',
        right: '-10px',
    },
    '& .slick-dots': {
        height: 'auto',
        lineHeight: '1',
        bottom: '-8px',
        transform: 'translateX(-50%)',
        left: '50%',
        li: {
            width: '8px',
            height: '8px',
            margin: '0 8px',
            button: {
                width: '8px',
                height: '8px',
                lineHeight: '1',
                padding: 0,
                '&:before': {
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    opacity: 1,
                    content: '""',
                    backgroundColor: DesignTokens.color.globalNeutral200,
                },
            },
            '&.slick-active': {
                button: {
                    '&:before': {
                        backgroundColor: DesignTokens.color.textAccentDefault,
                    },
                },
            },
        },
    },
}));

export const VideoThumbnailWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: '16px',
    },
}));

export const TitleGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: '0 16px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
}));

export const MainGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        gap: '8px',
    },
}));

export const MainWrapperGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '48px',
    },
}));
export const ThumbnailTitle = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '14px',
        lineHeight: '24px',
    },
}));

export const ThumbnailDescription = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '10px',
        lineHeight: '16px',
        marginTop: 0,
    },
}));
export const ThumbnailSubtitle = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '14px',
        lineHeight: '24px',
        marginTop: 0,
    },
}));

export const ThumbnailsPaperWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('tablet')]: {
        gap: '12px',
    },
}));
