import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    rowsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
    },
    divider: {
        width: '100%',
        margin: 0,
        height: 1,
        boxShadow: 'inset 0px -1px 0px #D8D7E0',
    },
    rowWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        padding: '0 24px',
    },
    headerWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        rowGap: 4,
        columnGap: 16,
    },
    avatar: {
        gridRow: 'span 2',
        alignSelf: 'center',
    },
    total: {
        gridRow: 'span 2',
    },
    dateText: {
        color: theme.colors.navigationTextDefault,
    },
    secondRowWrapper: {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) auto',
        marginLeft: 64,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
        },
    },
    messenger: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    btnDetail: {
        alignSelf: 'end',
        justifySelf: 'end',
        minWidth: 115,
    },
    borderTop: {
        borderTop: `1px solid ${theme.colors.grey200}`,
    },
    donationDetailRow: {
        height: '100%',
        maxHeight: '50vh',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'auto',
        },
    },
}));
