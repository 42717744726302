import { GivelifyLabel, GivelifyPaper } from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const TableWrapper = styled(GivelifyPaper, {
    shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading: boolean }>(({ theme, isLoading }) => ({
    paddingBottom: theme.spacing(isLoading ? 3 : 0),
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down('smallMobile')]: {
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
export const Title = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
        display: 'none',
    },
}));
