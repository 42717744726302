import { DesignTokens, GivelifyIcon } from '@givelify/ui';
import { styled, MenuItem, Menu } from '@mui/material';
import { CSSProperties } from '@mui/styles';

export const DesktopMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
        paddingTop: 16,
        paddingBottom: 16,
    },
    '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

const commonStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
};

// No click and no hover
export const DesktopSimpleMenuItem = styled('div')(() => ({
    ...commonStyles,
}));

export const DesktopMenuItem = styled(MenuItem)(() => ({
    ...commonStyles,
}));

export const DesktopMenuItemIcon = styled(GivelifyIcon)(() => ({
    fontSize: 24,
    color: DesignTokens.color.iconPrimary,

    '&.isMobile': {
        color: DesignTokens.color.textAccentDefault,
    }
}));
