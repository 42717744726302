import React, { forwardRef, useImperativeHandle } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { PrimaryRepresentativeStepOne } from './PrimaryRepresentativeStepOne';
import { PrimaryRepresentativeStepTwo } from './PrimaryRepresentativeStepTwo';
import { PrimaryRepresentativeTip } from './PrimaryRepresentativeTip';
import {
    PrimaryRepresentativePageProps,
    PrimaryRepresentativePageRef,
    PrimaryRepresentativeStepRef,
} from './utils';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adminContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        adminForm: {
            maxWidth: 600,
            flex: 1,
            [theme.breakpoints.down('xs')]: {
                maxWidth: 'initial',
            },
        },
    }),
);

export const PrimaryRepresentativePage = forwardRef<
    PrimaryRepresentativePageRef,
    PrimaryRepresentativePageProps
>(
    (
        {
            data,
            onValidation,
            termsOfUseHRefClick,
            step,
            user,
        }: PrimaryRepresentativePageProps,
        forwardRef,
    ) => {
        const stepOneRef = React.useRef<PrimaryRepresentativeStepRef>(null);
        const stepTwoRef = React.useRef<PrimaryRepresentativeStepRef>(null);
        const { adminContainer, adminForm } = useStyles();

        const submitStep1 = React.useCallback(
            () => (stepOneRef.current ? stepOneRef.current.submit() : data),
            [data, stepOneRef],
        );
        const submitStep2 = React.useCallback(
            () => (stepTwoRef.current ? stepTwoRef.current.submit() : data),
            [data, stepTwoRef],
        );
        useImperativeHandle(forwardRef, () => ({
            submitStep1: submitStep1,
            submitStep2: submitStep2,
        }));

        return (
            <div className={adminContainer} id="primary-rep-page">
                <div className={adminForm} id="admin-form">
                    {step === 1 ? (
                        <PrimaryRepresentativeStepOne
                            ref={stepOneRef}
                            data={data}
                            onValidation={onValidation}
                            user={user}
                        />
                    ) : (
                        <PrimaryRepresentativeStepTwo
                            ref={stepTwoRef}
                            data={data}
                            onValidation={onValidation}
                            termsOfUseHRefClick={termsOfUseHRefClick}
                        />
                    )}
                </div>
                <PrimaryRepresentativeTip />
            </div>
        );
    },
);

PrimaryRepresentativePage.displayName = 'PrimaryRepresentativePage';
