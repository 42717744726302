import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import {
    getlegacyEnvelopesEndPoint,
    getUpdatePrioritiesEndpoint,
    getEnvelopesUpdateEndpoint,
    getEnvelopesCreateEndpoint,
    getEnvelopesDeleteEndpoint,
    getEnvelopesStatsEndpoint,
    getEnvelopesDonorsEndpoint,
    getEnvelopesDataEndpoint,
    DonationsStatisticType,
    getDonationsStatisticByEnvelopesApiEndpoint,
    getAllEnvelopesDataEndpoint,
} from 'api/client/endpoints';
import {
    CreateEnvelopeRequest,
    UpdateEnvelopePriorityRequest,
    UpdateEnvelopeRequest,
} from 'api/requests';
import { IEnvelopesService } from 'api/services/interfaces';
import { GetActiveEnvelopesResponse } from 'api/services/responses/envelopes/GetActiveEnvelopesResponse';
import { GetInactiveEnvelopesResponse } from 'api/services/responses/envelopes/GetInactiveEnvelopesResponse';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import {
    LaunchGivelithonDataResponse,
    LaunchGivelithonDonorsDataResponse,
} from 'pages/givelithon/types';
import {
    envelopesEndPoint,
    legacyEnvelopesEndPoint,
} from 'store/dashboard/envelopes/thunks';
import {
    DonationStatisticByEnvelopes,
    EnvelopeDistributionData,
} from 'store/dashboard/envelopes/types';
import { EnvelopesQueryString } from 'store/donations/donation/types';
import { webConfig } from 'webConfig';
import {
    CreateEnvelopesResponse,
    GetAllEnvelopesResponse,
    GetEnvelopesResponse,
    UpdateActiveEnvelopePrioritiesResponse,
    UpdateEnvelopesResponse,
} from '../responses/envelopes';

export class EnvelopesService implements IEnvelopesService {
    private getEnvelopesDataByTimeCancelTokenSource: CancelTokenSource;
    private getDonationsStatisticByEnvelopesCancelTokenSource: CancelTokenSource;

    public async getAllActiveEnvelopes(
        doneeId: number,
    ): Promise<ApiResponse<GetActiveEnvelopesResponse>> {
        const url = getlegacyEnvelopesEndPoint(
            doneeId,
            'active',
            1,
            true,
            'priority',
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetActiveEnvelopesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getAllInactiveEnvelopes(
        doneeId: number,
    ): Promise<ApiResponse<GetInactiveEnvelopesResponse>> {
        const url = getlegacyEnvelopesEndPoint(doneeId, 'inactive', 1, true);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetInactiveEnvelopesResponse>(
            httpRequest,
        );
        return result;
    }

    public async updateActiveEnvelopePriorities(
        doneeId: number,
        priorities: UpdateEnvelopePriorityRequest,
    ): Promise<ApiResponse<UpdateActiveEnvelopePrioritiesResponse>> {
        const url = getUpdatePrioritiesEndpoint(doneeId);
        const httpRequest = getAxiosClient().patch(url, priorities);
        const result =
            await makeApiRequest<UpdateActiveEnvelopePrioritiesResponse>(
                httpRequest,
            );
        return result;
    }

    public async updateEnvelope(
        doneeId: number,
        data: UpdateEnvelopeRequest,
    ): Promise<ApiResponse<UpdateEnvelopesResponse>> {
        const url = getEnvelopesUpdateEndpoint(doneeId, data.id);
        const httpRequest = getAxiosClient().patch(
            url,
            data.isDefault
                ? {
                      externalFundNameOrNumber: data.externalId,
                      active: data.active,
                  }
                : {
                      name: data.name,
                      detail: data.detail,
                      externalFundNameOrNumber: data.externalId,
                      goal: data.goal,
                      active: data.active,
                      isDetailPublic: data.isDetailPublic,
                      startDate: data.start || undefined,
                      endDate: data.end || undefined,
                      alwaysOn: data.alwaysOn,
                  },
        );
        const result = await makeApiRequest<UpdateEnvelopesResponse>(
            httpRequest,
        );
        return result;
    }

    public async createEnvelope(
        doneeId: number,
        data: CreateEnvelopeRequest,
    ): Promise<ApiResponse<CreateEnvelopesResponse>> {
        const url = getEnvelopesCreateEndpoint(doneeId);
        const httpRequest = getAxiosClient().post(url, {
            active: data.active,
            alwaysOn: data.alwaysOn,
            name: data.name,
            detail: data.detail,
            externalFundNameOrNumber: data.externalId,
            goal: data.goal,
            isDetailPublic: data.isDetailPublic,
            startDate: data.start || undefined,
            endDate: data.end || undefined,
            priority: 'lowest',
        });
        const result = await makeApiRequest<CreateEnvelopesResponse>(
            httpRequest,
        );
        return result;
    }

    public async deleteEnvelope(
        doneeId: number,
        envelopeId: number,
    ): Promise<ApiResponse<unknown>> {
        const url = getEnvelopesDeleteEndpoint(doneeId, envelopeId);
        const httpRequest = getAxiosClient().delete(url);
        const result = await makeApiRequest<unknown>(httpRequest);
        return result;
    }

    public async showToGiverEnvelope(
        doneeId: number,
        envelopeId: number,
    ): Promise<ApiResponse<UpdateEnvelopesResponse>> {
        const url = getEnvelopesUpdateEndpoint(doneeId, envelopeId);
        const httpRequest = getAxiosClient().patch(url, {
            active: true,
            priority: 'lowest',
        });
        const result = await makeApiRequest<UpdateEnvelopesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getEnvelopeStats(
        doneeId: number,
        envelopeId: number,
    ): Promise<ApiResponse<LaunchGivelithonDataResponse>> {
        const url = getEnvelopesStatsEndpoint(doneeId, envelopeId);
        const httpRequest = getAxiosClient({
            baseURL: webConfig.givelithonApiBaseUrl,
        }).get(url, {
            headers: {
                'api-secret': webConfig.givelithonApiSecret,
                'api-token': webConfig.givelithonApiToken,
            },
        });

        // We dont have logging currently for givelithon api so we have to log on FE side
        return makeApiRequest(httpRequest, { shouldLogRequest: true });
    }

    public async getEnvelopeDonors(
        doneeId: number,
        envelopeId: number,
    ): Promise<ApiResponse<LaunchGivelithonDonorsDataResponse>> {
        const url = getEnvelopesDonorsEndpoint(doneeId, envelopeId);
        const httpRequest = getAxiosClient({
            baseURL: webConfig.givelithonApiBaseUrl,
        }).get(url, {
            headers: {
                'api-secret': webConfig.givelithonApiSecret,
                'api-token': webConfig.givelithonApiToken,
            },
        });

        // We dont have logging currently for givelithon api so we have to log on FE side
        return makeApiRequest(httpRequest, { shouldLogRequest: true });
    }

    public async getEnvelopesData(
        doneeId: number,
        donationQuery: EnvelopesQueryString,
    ): Promise<ApiResponse<GetEnvelopesResponse>> {
        const url = getEnvelopesDataEndpoint(doneeId, donationQuery);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest(httpRequest);
    }

    public async getAllEnvelopesData(
        doneeId: number,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs,
    ): Promise<ApiResponse<GetAllEnvelopesResponse>> {
        const url = getAllEnvelopesDataEndpoint(doneeId, startDate, endDate);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest(httpRequest);
    }

    public async getEnvelopesDataByTime(
        timeFrame: TimeFrameValue,
        doneeId: number,
    ): Promise<ApiResponse<EnvelopeDistributionData>> {
        if (this.getEnvelopesDataByTimeCancelTokenSource) {
            this.getEnvelopesDataByTimeCancelTokenSource.cancel();
        }

        this.getEnvelopesDataByTimeCancelTokenSource =
            axios.CancelToken.source();

        const url = envelopesEndPoint(timeFrame, doneeId);
        const httpRequest = getAxiosClient().get(url, {
            cancelToken: this.getEnvelopesDataByTimeCancelTokenSource.token,
        });
        return makeApiRequest(httpRequest);
    }

    public async getGivingTrendEnvelopes(
        timeFrame: TimeFrameValue,
        doneeId: number,
        envelopeIds: number[],
    ): Promise<ApiResponse<EnvelopeDistributionData>> {
        const url = legacyEnvelopesEndPoint(timeFrame, doneeId, envelopeIds);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest(httpRequest);
    }

    public async getDonationsStatisticByEnvelopes(
        doneeId: number,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs,
        type: DonationsStatisticType,
    ): Promise<ApiResponse<DonationStatisticByEnvelopes>> {
        this.getDonationsStatisticByEnvelopesCancelTokenSource?.cancel();
        this.getDonationsStatisticByEnvelopesCancelTokenSource =
            axios.CancelToken.source();

        const url = getDonationsStatisticByEnvelopesApiEndpoint(
            doneeId,
            startDate,
            endDate,
            type,
        );
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest<DonationStatisticByEnvelopes>(httpRequest);
    }
}
