type RecursiveProps<T> = {
    [K in keyof T]: T[K] extends T[] ? K : never;
}[keyof T];

type IObjectT<T> = {
    [K in RecursiveProps<T>]?: (T & IObjectT<T>)[];
};

export class RecursiveObjectArrayHandler<ObjectT extends IObjectT<ObjectT>> {
    private items: ObjectT[];
    private childrenKey: RecursiveProps<ObjectT>;

    constructor(items: ObjectT[], childrenKey: RecursiveProps<ObjectT>) {
        this.items = items;
        this.childrenKey = childrenKey;
    }

    private recursiveMapHandler(
        items: ObjectT[],
        handler: (item: ObjectT) => ObjectT,
    ): ObjectT[] {
        return items.map(item => {
            if (item[this.childrenKey]) {
                this.recursiveMapHandler(item[this.childrenKey], handler);
            }
            return handler(item);
        });
    }

    public map(handler: (item: ObjectT) => ObjectT): ObjectT[] {
        return this.recursiveMapHandler(this.items, handler);
    }

    private recursiveMapHandlerWithParent(
        items: ObjectT[],
        handler: (item: ObjectT, parent?: ObjectT) => ObjectT,
        parent?: ObjectT,
    ): ObjectT[] {
        return items.map(item => {
            if (item[this.childrenKey]) {
                this.recursiveMapHandlerWithParent(
                    item[this.childrenKey],
                    handler,
                    item,
                );
            }
            return handler(item, parent);
        });
    }

    public mapWithParent(
        handler: (item: ObjectT, parent: ObjectT) => ObjectT,
    ): ObjectT[] {
        return this.recursiveMapHandlerWithParent(this.items, handler);
    }
}
