import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    useAdvancedTranslation,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { formatWithLocaltime } from '@givelify/utils';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DonorMatching from '../../../../DonorMatching';

interface MatchingDonorModalProps {
    handleClose: () => void;
    donorId: number;
    startDate: string;
    endDate: string;
    open: boolean;
}

export const useModalTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {
            '& .no-data': {
                padding: 10,
            },
            '& .header': {
                padding: theme.spacing(0, 3),
            },
        },
        forReport: {
            padding: theme.spacing(2, 0, 3),
            display: 'flex',
            alignItems: 'center',
        },
        forReportHead: {
            paddingRight: theme.spacing(1),
        },
    }),
);

const MatchingDonorModal: React.FC<MatchingDonorModalProps> = ({
    handleClose,
    donorId,
    startDate,
    endDate,
    open,
}) => {
    const { modalContent, forReport, forReportHead } = useModalTableStyles();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            forReport: t('pages.integrations.forReport'),
            matchTitle: t('pages.integration_donor_matching.matchTitle'),
        }),
        [t],
    );

    return (
        <GivelifyModal
            autoFullscreen
            showCloseButton
            onClose={handleClose}
            open={open}
            size="xlarge"
        >
            <div className={modalContent}>
                <div className="header">
                    <GivelifyLabel
                        bold
                        text={copy.matchTitle}
                        variant="heading2"
                    />
                    <div className={forReport}>
                        <GivelifyLabel
                            bold
                            className={forReportHead}
                            text={copy.forReport}
                            variant="body2"
                        />
                        <GivelifyLabel
                            text={`${formatWithLocaltime(
                                startDate,
                                'MM/DD/YYYY',
                            )} - ${formatWithLocaltime(endDate, 'MM/DD/YYYY')}`}
                            variant="body2"
                        />
                    </div>
                </div>
                <div className="body">
                    <DonorMatching
                        handleClose={handleClose}
                        reportId={donorId}
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default MatchingDonorModal;
