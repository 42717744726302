import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyTextDividerStyleProps = GivelifyBoxMarginProps;

export const textDividerStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyTextDividerStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyTextDividerStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyTextDividerStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyTextDividerStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        textDivider: {
            lineHeight: '1em',
            position: 'relative',
            outline: '0',
            border: '0',
            color: 'black',
            textAlign: 'center',
            height: '1.5em',
            opacity: '1',

            '&::after': {
                content: 'attr(data-content)',
                position: 'relative',
                display: 'inline-block',

                padding: '0 .5em',
                lineHeight: '1.5em',
                // this is really the only tricky part, you need to specify the background color of the container element...
                color: theme.gas.palette.label.secondary,
                backgroundColor: theme.gas.palette.background.primary,
            },
            '&::before': {
                content: '""',
                // use the linear-gradient for the fading effect
                // use a solid background color for a solid bar
                background: theme.gas.palette.misc.separator,
                position: 'absolute',
                left: '0px',
                top: '50%',
                width: '100%',
                height: '1px',
            },
        },
    }),
);
