import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { Poll } from 'api/models/Poll';

export const getPolls = (doneeId: number) => {
    const httpRequest = getAxiosClient().get(`/donees/${doneeId}/polls`);
    const result = makeApiRequest<{ data: Poll[] }>(httpRequest);
    return result;
};

export const answerPoll = (
    doneeId: number,
    pollId: number,
    answer: { id: number; customAnswer?: string },
) => {
    const httpRequest = getAxiosClient().post(
        `/donees/${doneeId}/polls/${pollId}`,
        {
            answers: [answer],
        },
    );
    const result = makeApiRequest<{ data: Poll[] }>(httpRequest);
    return result;
};
