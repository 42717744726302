import { GivelifyLoading } from '@givelify/ui';
import { PopupModal } from 'react-calendly';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { webConfig } from 'webConfig';
import { CalendlyGlobalStyles, LoaderWrapper } from './styles';
import { CurrentPage } from './types';

const LoaderComponent = () => (
    <LoaderWrapper>
        <GivelifyLoading type="circular" />
    </LoaderWrapper>
);

const SchedulePopup: React.FC<{
    onClose: () => void;
    currentPage: CurrentPage;
}> = ({ currentPage, onClose }) => {
    const { email, firstName, lastName, phone, doneeId, doneeName } =
        useSelector((state: AppState) => ({
            email: state.User.user.email,
            firstName: state.User.user?.firstName,
            lastName: state.User.user?.lastName,
            phone: state.User.user?.phone,
            doneeId: state.Donee.donee?.id,
            doneeName: state.Donee.donee?.name,
        }));

    const calendlyUrl = webConfig.calendlyUrl;

    return (
        <>
            <CalendlyGlobalStyles currentPage={currentPage} />
            <PopupModal
                LoadingSpinner={LoaderComponent}
                onModalClose={onClose}
                open={true}
                prefill={{
                    firstName,
                    lastName,
                    email,
                    customAnswers: {
                        a1: `+1 ${phone}`,
                        a2: doneeName,
                    },
                }}
                rootElement={document.body}
                url={calendlyUrl}
                utm={{
                    utmSource: doneeId?.toString(),
                }}
            />
        </>
    );
};

export default SchedulePopup;
