import React from 'react';
import { DesignTokens, GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { ClickAwayListener, Collapse, Stack, styled } from '@mui/material';
import { LegacyDashboardLink } from 'layout/components/LegacyDashboardLink';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
    TABLET_HEADER_HEIGHT,
    TABLET_DRAWER_WIDTH_EXPANDED,
    TABLET_DRAWER_WIDTH_COLLAPSED,
} from 'theme/layoutMeaserments';
import { enableOldDahsboard } from 'utils/enableOldDahsboard';
import { NavMenuItem, MenuSeparator } from '../components';
import { NavigationProps } from '../types';

const TabletNavigationDrawer = styled('div', {
    shouldForwardProp: (propName) => propName !== 'expanded',
})<{ expanded?: boolean }>(({ theme, expanded }) => ({
    backgroundColor: DesignTokens.color.backgroundSecondary,
    position: 'fixed',
    top: TABLET_HEADER_HEIGHT,
    left: 0,
    bottom: 0,
    width: expanded
        ? TABLET_DRAWER_WIDTH_EXPANDED
        : TABLET_DRAWER_WIDTH_COLLAPSED,
    display: 'none',
    transition: 'width 0.2s ease-out',
    [theme.breakpoints.down('tablet')]: {
        display: 'flex',
        flexDirection: 'column',
    },
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
    zIndex: theme.zIndex.appBar,
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRight: `1px solid ${DesignTokens.color.globalNeutral100}`,
    '.legacy-dashboard-link': {
        display: expanded ? 'block' : 'none',
        marginBottom: theme.spacing(2),
    },
}));

const TabletMenuRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingRight: theme.spacing(0.25),
    paddingLeft: theme.spacing(2),
    overflowY: 'auto',
}));

const TabletMenuList = styled('div')(({ theme }) => ({
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(1),
    '&:hover': {
        scrollbarWidth: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: DesignTokens.color.backgroundSecondary,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: DesignTokens.color.backgroundComponentQuaternary,
        },
    },
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        backgroundColor: DesignTokens.color.backgroundSecondary,
    },
    overflowY: 'scroll',
}));

const TabletMenuFooter = styled('button')(({ theme }) => ({
    marginTop: 'auto',
    height: '56px',
    border: 'none',
    outline: 'none',
    background: DesignTokens.color.backgroundSecondary,
    boxShadow: DesignTokens.shadow.shadowMediumHigh,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(3),
    gap: theme.spacing(1),
    color: DesignTokens.color.iconPrimary,
    fontSize: DesignTokens.measurement.fontDefaultSize,
    cursor: 'pointer',
    '&:hover': {
        color: DesignTokens.color.iconHover,
    },
    overflowX: 'hidden',
}));

type TabletNavigationMenuProps = {
    navigationProps: NavigationProps;
};

export const TabletNavigationMenu: React.FC<TabletNavigationMenuProps> = ({
    navigationProps,
}) => {
    const { items, onClick, onExpandClick } = navigationProps;
    const campuses = useSelector((state: AppState) => state.Donee.campuses);
    const hasOldDashboard = React.useMemo(() => {
        return enableOldDahsboard(campuses);
    }, [campuses]);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const onExpand = React.useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, [setIsExpanded]);
    const collapseDrawer = React.useCallback(() => {
        setIsExpanded(false);
    }, [setIsExpanded]);
    const onNavMenuClick = React.useCallback(
        (link: string, label: string) => {
            collapseDrawer();
            onClick(link, label);
        },
        [onClick, collapseDrawer],
    );
    return (
        <ClickAwayListener onClickAway={collapseDrawer}>
            <TabletNavigationDrawer
                expanded={isExpanded}
                id="tablet-nav-drawer"
            >
                <TabletMenuRoot id="tablet-nav-menu">
                    <TabletMenuList id="tablet-nav-menu-list">
                        {items.map((item, index) => (
                            <div
                                key={`nav-item-${index}`}
                                className="nav-item-holder"
                            >
                                <NavMenuItem
                                    key={`nav-item-${item.label}`}
                                    item={item}
                                    onClick={onNavMenuClick}
                                    onExpandClick={onExpandClick}
                                    variant={
                                        isExpanded
                                            ? 'tablet-expanded'
                                            : 'tablet-collapsed'
                                    }
                                />
                                {item.hasDivider ? <MenuSeparator /> : null}
                                {item.nested ? (
                                    <Collapse
                                        unmountOnExit
                                        in={item.isExpanded && isExpanded}
                                        timeout="auto"
                                    >
                                        <Stack paddingTop={1} spacing={1}>
                                            {item.nested.map(
                                                (subItem, subIndex) => (
                                                    <NavMenuItem
                                                        key={`nav-item-${index}-${subIndex}`}
                                                        item={subItem}
                                                        onClick={onNavMenuClick}
                                                        onExpandClick={
                                                            onExpandClick
                                                        }
                                                    />
                                                ),
                                            )}
                                        </Stack>
                                    </Collapse>
                                ) : null}
                            </div>
                        ))}
                    </TabletMenuList>
                    {hasOldDashboard ? (
                        <LegacyDashboardLink onClick={() => collapseDrawer()} />
                    ) : null}
                </TabletMenuRoot>
                <TabletMenuFooter
                    id="tablet-nav-menu-expand-btn"
                    onClick={onExpand}
                >
                    <GivelifyIcon
                        variant={
                            isExpanded
                                ? 'left-chevron-double'
                                : 'right-chevron-double'
                        }
                    />
                    <GivelifyLabel
                        color="inherit"
                        text={isExpanded ? 'Collapse' : ''}
                        variant="body2B"
                    />
                </TabletMenuFooter>
            </TabletNavigationDrawer>
        </ClickAwayListener>
    );
};
