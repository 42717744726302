import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useF1Styles } from '../../IntegrationSetup/IntegrationSetupDialog/styles';

interface F1FindKeyStepProps {
    stepNumber: number;
    stepTitle: string;
}

const F1FindKeyStep: React.FC<F1FindKeyStepProps> = ({
    stepNumber,
    stepTitle,
}) => {
    const classes = useF1Styles();
    return (
        <div className={classes.findKeyStep}>
            <span className={classes.stepNum}>{stepNumber}</span>
            <GivelifyLabel text={stepTitle} variant="body1" />
        </div>
    );
};

export default F1FindKeyStep;
