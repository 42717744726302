import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const RepDetailStyles = makeStyles((theme: Theme) =>
    createStyles({
        repEditor: {
            marginTop: 40,
            display: 'flex',
            justifyContent: 'space-between',
            '& .rep-form': {
                maxWidth: 600,
                flex: 1,
                [theme.breakpoints.down('xs')]: {
                    maxWidth: 'initial',
                },
            },
            '& .submit-footer': {
                display: 'flex',
            },
            '& .tip': {
                marginLeft: 'auto',
            },
        },
        repPending: {
            '& .header': {
                marginTop: 32,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
        },
        repRetry: {
            '& .header': {
                marginTop: 32,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
            '& .info': {
                fontSize: 16,
                lineHeight: '22px',
                fontWeight: 400,
                fontFamily: 'Roboto',
                '& .button': {
                    cursor: 'pointer',
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                    '&:hover': {
                        color:
                            theme.colors?.primary || theme.palette.primary.main,
                        textDecoration: 'underline',
                    },
                    '&:active': {
                        color:
                            theme.colors?.accentDarkBlue ||
                            theme.palette.primary.dark,
                        textDecoration: 'underline',
                    },
                },
            },
        },
        repSuccess: {
            '& .header': {
                marginTop: 32,
                marginBottom: 19,
                display: 'flex',
                alignItems: 'center',
                height: 26,
                '& .submitted': {
                    color: theme.colors.neutralGrey,
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '22px',
                },
            },
            '& .detailes': {
                marginTop: 24,
            },
        },
    }),
);
