import React, { useMemo, useState } from 'react';
import { GivelifyLabel, GivelifyModal } from '@givelify/givelify-ui';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VoidCheckImage from '../../../assets/retry-void-check.jpg';
import { I18N_NAMESPACE } from '../../../consts';
import {
    DocumentUploadSection,
    DocumentUploadSectionProps,
} from './DocumentUploadSection';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        descriptionStyles: {
            marginBottom: 34,
            '& .inline': {
                display: 'inline',
            },
            '& .cursor': {
                cursor: 'pointer',
            },
        },
        contentStyles: {
            paddingLeft: 52,
            paddingRight: 76,
            paddingBottom: 50,
            '& .void-check': {
                textAlign: 'center',
                marginTop: 30,
            },
            '& h2': {
                textAlign: 'center',
                marginBottom: 20,
            },
            '& .inline': {
                display: 'inline',
            },
            [`@media (max-width:790px)`]: {
                paddingRight: 52,
                '& .void-check-image': {
                    width: '100%',
                },
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
    }),
);

export const ChequeUploadSection: React.FunctionComponent<
    Omit<DocumentUploadSectionProps, 'title' | 'description'>
> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const [open, setOpen] = useState(false);
    const { contentStyles, descriptionStyles } = useStyles();
    const copy = React.useMemo(
        () => ({
            heading: t('directDepositManualEntry.stepTwoStaticText.heading'),
            caption: t('directDepositManualEntry.stepTwoStaticText.caption'),
            example: t('directDepositManualEntry.stepTwoStaticText.example'),
            modalHeading: t('directDepositManualEntry.retryBox.heading'),
            descriptionOne: t(
                'directDepositManualEntry.retryBox.descriptionOne',
            ),
            descriptionTwo: t(
                'directDepositManualEntry.retryBox.descriptionTwo',
            ),
        }),
        [t],
    );

    const title = useMemo(
        () => (
            <GivelifyLabel
                bold
                marginBottom={6}
                text={copy.heading}
                variant="heading5"
            />
        ),
        [copy],
    );

    const description = useMemo(
        () => (
            <div className={descriptionStyles}>
                <GivelifyLabel
                    className="inline"
                    text={copy.caption}
                    variant="body1"
                />
                <GivelifyLabel
                    className="inline cursor"
                    color="primary"
                    onClick={() => setOpen(true)}
                    text={copy.example}
                    variant="body1"
                />
            </div>
        ),
        [copy, descriptionStyles],
    );
    return (
        <>
            <DocumentUploadSection
                {...props}
                description={description}
                title={title}
            />
            <GivelifyModal
                autoFullscreen
                showCloseButton
                onClose={() => setOpen(false)}
                open={open}
            >
                <div className={contentStyles}>
                    <GivelifyLabel
                        text={copy.modalHeading}
                        variant="heading2"
                    />
                    <div>
                        <GivelifyLabel
                            className="inline"
                            text={copy.descriptionOne}
                            variant="body1"
                        />
                        <GivelifyLabel
                            bold
                            className="inline"
                            text={copy.descriptionTwo}
                            variant="body1"
                        />
                    </div>
                    <div className="void-check">
                        <img
                            alt="Void Check"
                            className="void-check-image"
                            src={VoidCheckImage}
                            title="Void Check"
                        />
                    </div>
                </div>
            </GivelifyModal>
        </>
    );
};
