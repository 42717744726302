import { Link, TypographyProps } from '@mui/material';

export type GivelifyLinkProps = Omit<
    React.ComponentProps<typeof Link>,
    'variant'
> & {
    text: string | number | JSX.Element;
    variant?: CustomVariantsType | TypographyProps['variant'];
    track?: boolean;
    name?: string;
};

export const CustomVariants = ['small', 'default', 'medium', 'large'] as const;
type CustomVariantsType = (typeof CustomVariants)[number];
