import React from 'react';
import { useApiRequest } from '@givelify/givelify-ui';
import { directDepositAPI, DirectDepositInfo } from '@givelify/onboarding';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useApiRequest as useApiRequestUtils } from '@givelify/utils';
import { BankAccount } from 'api/models/BankAccount';
import { getBankAccountsApi } from 'api/requests/BankingRequests';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import {
    AccountContent,
    CustomizeAccountTabScreen,
} from './components/AccountContent';

export const CustomizeAccountTab: React.FC = () => {
    const { donee, yodleeEnabled } = useSelector((state: AppState) => {
        return {
            donee: state.Donee.campuses[0],
            yodleeEnabled: state.System.yodleeEnabled,
        };
    });
    const [getRequest, makeGetRequest] = useApiRequestUtils<{
        data: BankAccount[];
    }>();
    const [updateRequest, makeUpdateRequest] = useApiRequest<unknown>();
    const [bankingScreen, setBankingScreen] =
        React.useState<CustomizeAccountTabScreen>('list');
    const [lastBankingScreen, setLastBankingScreen] =
        React.useState<CustomizeAccountTabScreen>('list');
    React.useEffect(() => {
        makeGetRequest(getBankAccountsApi(donee.id));
        // eslint-disable-next-line
    }, []);
    const onBankingInfoChange = React.useCallback(
        (data: DirectDepositInfo) => {
            if (data) {
                makeUpdateRequest(directDepositAPI(donee.id, data));
            }
        },
        [makeUpdateRequest, donee],
    );
    const onRetryClick = React.useCallback(() => {
        setLastBankingScreen(bankingScreen);
        setBankingScreen('retry');
    }, [setBankingScreen, setLastBankingScreen, bankingScreen]);
    const onReplaceClick = React.useCallback(() => {
        setLastBankingScreen(bankingScreen);
        setBankingScreen('replace');
    }, [setBankingScreen, setLastBankingScreen, bankingScreen]);
    const onRetryCancel = React.useCallback(() => {
        setBankingScreen(lastBankingScreen);
    }, [lastBankingScreen, setBankingScreen]);
    React.useEffect(() => {
        if (updateRequest.type === 'REQUEST_SUCCESS') {
            if (
                bankingScreen !== 'list' ||
                getRequest.response.data.length === 0
            ) {
                makeGetRequest(getBankAccountsApi(donee.id));
                setBankingScreen('list');
                setLastBankingScreen('list');
            }
        }
        //eslint-disable-next-line
    }, [updateRequest]);
    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsBankInfoBankAccountTab}
        >
            <AccountContent
                donee={donee}
                error={
                    updateRequest.type === 'REQUEST_ERROR'
                        ? updateRequest.error.message || undefined
                        : undefined
                }
                getRequest={getRequest}
                isUpdating={updateRequest.type === 'REQUEST_START'}
                onBankingInfoChange={onBankingInfoChange}
                onReplaceClick={onReplaceClick}
                onRetryCancel={onRetryCancel}
                onRetryClick={onRetryClick}
                screen={bankingScreen}
                updateHasError={updateRequest.type === 'REQUEST_ERROR'}
                yodleeEnabled={yodleeEnabled}
            />
        </TrackingProvider>
    );
};
