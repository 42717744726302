import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme: Theme) => ({
    modalContainer: {
        '& input': {
            background: '#fff',
            borderColor: '#adadad',
            padding: theme.spacing(3),
        },
    },
    loginTitle: {
        color: '#4a4a4a',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '26px',
        fontSize: 24,
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
    },
    forgotText: {
        color: '#676e77',
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    button: {
        paddingTop: 10,
        paddingBottom: 10,
        margin: '0px auto 48px auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: 40,
        },
    },
    borderGrey: {
        borderBottom: '1px solid #d8d8d8',
    },
    recoverFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    marginRight: {
        marginRight: 10,
    },
    marginLeft: {
        marginLeft: 10,
    },
    forgotAlert: {
        border: '1px solid #5D8DB7',
        background: '#D1E6F0',
        fontSize: 12,
        height: 53,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRadius: 4,
    },
    hasVal: {
        '& label': {
            display: 'inline-block',
            position: 'absolute',
            top: 16,
            left: 12,
            paddingLeft: 5,
            paddingRight: 5,
            background: '#fff',
            zIndex: 2,
        },
    },
}));
