import React, { useState } from 'react';
import { TrackingProvider } from '@givelify/utils';
import OnboardingPage from '../../../../OnboardingPage';
import { UseTaxIdContext } from '../../TaxIdContext';
import formPng from './img/990form.png';
import bankStatementPng from './img/bankStatement.png';
import einPng from './img/ein.png';
import PageContent from './PageContent';
import SidebarContent from './SidebarContent';

const UploadDocument: React.FC = () => {
    const { selectedDocumentType } = UseTaxIdContext();

    const exampleImage =
        (selectedDocumentType === '990Form' && formPng) ||
        (selectedDocumentType === 'bankStatement' && bankStatementPng) ||
        (selectedDocumentType === 'confirmationLetter' && einPng) ||
        '';

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState<'example' | 'uploaded'>(
        'example',
    );
    const showExampleModal = () => {
        setModalType('example');
        setShowModal(true);
    };
    const showUploadedModal = () => {
        setModalType('uploaded');
        setShowModal(true);
    };

    return (
        <TrackingProvider
            trackPageVisit
            pageName={`EIN Federal Tax Id ${selectedDocumentType}`}
        >
            <OnboardingPage
                content={
                    <PageContent
                        exampleImage={exampleImage}
                        modalType={modalType}
                        onModalClose={() => setShowModal(false)}
                        showExampleModal={showExampleModal}
                        showModal={showModal}
                        showUploadedModal={showUploadedModal}
                    />
                }
                header={null}
                informationContent={
                    <SidebarContent
                        exampleImage={exampleImage}
                        showExampleModal={showExampleModal}
                    />
                }
            />
        </TrackingProvider>
    );
};

export default UploadDocument;
