import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { Box, styled, Stack } from '@mui/material';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';

import { GivelifyTabProps } from './types';

const GivelifyTabView = styled(Box)(() => ({
    '&.MuiBox-root': {
        boxShadow: DesignTokens.shadow.divider2,
        overflow: 'auto',
        '& .MuiBox-root': {
            position: 'relative',
            boxSizing: 'border-box',
            minHeight: 40,
            cursor: 'pointer',
            '& .tab-label': {
                ...DesignTokens.textStyle.globalBody1B,
                color: DesignTokens.color.textSecondary,
                whiteSpace: 'nowrap',
                textDecoration: 'none',
            },
            '& .badge-container': {
                position: 'absolute',
                top: 0,
                right: 0,
                transform: 'translate(100%, -100%)',
                backgroundColor: DesignTokens.color.textAccentDefault,
                padding: `2px 4px`,
                borderRadius: 16,
                height: 16,
                minWidth: 12,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .badge-label': {
                    fontSize: 12,
                    fontWeight: 800,
                    color: DesignTokens.color.textWhite,
                    textAlign: 'center',
                    lineHeight: '12px',
                },
            },
        },
        '& .tab-selected': {
            color: DesignTokens.color.textAccentDefault,
            '&::before': {
                left: 0,
                right: 0,
                bottom: 0,
                height: 4,
                width: '100%',
                content: '""',
                position: 'absolute',
                backgroundColor:
                    DesignTokens.color.backgroundComponentPrimaryAccent,
                borderRadius: '4px 4px 0px 0px',
            },
            '& .tab-label': {
                color: DesignTokens.color.textAccentDefault,
            },
        },
    },
}));

export const GivelifyTab: React.FC<GivelifyTabProps> = ({
    value = 0,
    onChange,
    options,
    className,
}) => {
    return (
        <GivelifyTabView className={className}>
            <Stack className="tab-stack" direction="row" gap="40px">
                {options.map((item, index) => (
                    <Box
                        key={`tab-${index}`}
                        className={mergeClassNames(
                            index === value ? 'tab-selected' : '',
                            'tab-root',
                        )}
                        data-testid={item.id || 'tab'}
                        id={item.id || ''}
                        onClick={() => onChange(index)}
                    >
                        {item.tabHref && (
                            <a
                                className="tab-label"
                                data-testid={`${item.id || 'tab'}-link`}
                                href={item.tabHref}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <GivelifyLabel
                                    className="tab-label"
                                    text={item.label}
                                />
                                {item.badgeText && (
                                    <div className="badge-container">
                                        <GivelifyLabel
                                            className="badge-label"
                                            text={item.badgeText}
                                        />
                                    </div>
                                )}
                            </a>
                        )}
                        {!item.tabHref && (
                            <>
                                <GivelifyLabel
                                    className="tab-label"
                                    text={item.label}
                                />
                                {item.badgeText && (
                                    <div className="badge-container">
                                        <GivelifyLabel
                                            className="badge-label"
                                            text={item.badgeText}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </Box>
                ))}
            </Stack>
        </GivelifyTabView>
    );
};
