import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyInfoStyleProps = {
    size?: number;
} & GivelifyBoxMarginProps;

export const GivelifyInfoStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoContainer: {
            padding: 0,
            fontSize: 0,
            lineHeight: 0,
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'none',
            outline: 'none',
            '&:hover': {
                background: 'none',
                outline: 'none',
                border: 'none',
            },
            '&:disabled': {
                background: 'none',
                outline: 'none',
                border: 'none',
            },
            color: theme.colors.primary,
        },
        boxMargin: {
            marginLeft: (props: GivelifyInfoStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyInfoStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyInfoStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyInfoStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        tooltipContainer: {
            maxWidth: 266,
            padding: 16,
            backgroundColor: theme.colors.black,
        },
        tooltipArrow: {
            color: theme.colors.black,
            fontSize: 14,
        },
        infoIconSize: {
            fontSize: (props: GivelifyInfoStyleProps) =>
                props.size ? props.size : 20,
        },
    }),
);
