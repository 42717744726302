import { createStyles, makeStyles } from '@material-ui/core';
import { MOCKUP_PATH } from './paths';

export const quickGivePreviewStyle = makeStyles(() => {
    return createStyles({
        mobilePreviewBase: {
            backgroundImage: `url(${MOCKUP_PATH.BASE})`,
            width: 302,
            height: 605,
            position: 'relative',
        },
        mobilePreviewContent: {
            width: 375,
            height: 667,
            backgroundColor: '#FFFFFF',
            transform: 'scale(0.7547)',
            position: 'absolute',
            transformOrigin: 'top left',
            left: 11,
            top: 53,
            border: '1px solid #EEEEEE',
        },
        mobilePreviewStatusBar: {
            backgroundImage: `url(${MOCKUP_PATH.STATUS_BAR})`,
            backgroundSize: 'cover',
            width: 375,
            height: 20,
        },
        mobilePreviewTitleBar: {
            backgroundSize: 'cover',
            width: 375,
            height: 45,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        mobilePreviewBackArrow: {
            position: 'absolute',
            left: 12,
        },
        mobilePreviewCover: {
            backgroundImage: `url(${MOCKUP_PATH.COVER_NARROW})`,
            backgroundSize: 'cover',
            width: 375,
            height: 142,
        },
        mobilePreviewPaginationDots: {
            width: 375,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        mobilePreviewContentTitle: {
            lineHeight: '19px',
            textAlign: 'center',
            marginBottom: 25,
        },
        mobilePreviewQuickGiveOptionsHolder: {
            width: 375,
        },
        mobilePreviewQuickGiveOption: {
            height: 50,
            minWidth: 90,
            borderRadius: 8,
            boxShadow:
                '0px -1px 4px rgba(132, 151, 178, 0.1), 0px 2px 4px rgba(132, 151, 178, 0.330966), 0px 7px 12px rgba(237, 242, 248, 0.496258), inset 0px -3px 4px rgba(195, 205, 220, 0.158463), inset 0px 2px 3px rgba(255, 255, 255, 0.5)',
            background: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginLeft: 12.5,
            marginRight: 12.5,
            marginBottom: 12.5,
        },
        mobilePreviewQuickGiveOptionText: {
            color: 'rgb(170, 170, 170)',
        },
    });
});
