import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgReportColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={64}
        width={64}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 64 64"
    >
        <rect fill="#DBD6FF" height="64.0001" rx="32" width="64.0001" />
        <path
            d="M13.8662 12.7999C13.8662 11.6217 14.8213 10.6666 15.9995 10.6666H37.3329L51.1996 24.5332V51.1999C51.1996 52.3781 50.2444 53.3333 49.0662 53.3333H15.9995C14.8213 53.3333 13.8662 52.3781 13.8662 51.1999V12.7999Z"
            fill="white"
        />
        <path
            d="M37.334 22.3999V10.6666L51.2007 24.5332H39.4673C38.2891 24.5332 37.334 23.5781 37.334 22.3999Z"
            fill="#715AFF"
        />
        <rect
            fill="#FED05D"
            height="3.2"
            rx="1.6"
            width="22.4"
            x="21.334"
            y="27.7334"
        />
        <rect
            fill="#FED05D"
            height="3.2"
            rx="1.6"
            width="22.4"
            x="21.334"
            y="34.1333"
        />
        <rect
            fill="#FED05D"
            height="3.2"
            rx="1.6"
            width="22.4"
            x="21.334"
            y="40.5333"
        />
    </svg>
);

export const ReportColoredIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgReportColored}
        fontSize={fontSize}
    />
);
