import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    tbRow: {
        boxSizing: 'border-box',
        '&:hover > th, &:hover > td': {
            backgroundColor: theme.colors
                ? theme.colors.neutralHoverGrey
                : '#F6F6F6',
        },
    },
}));
