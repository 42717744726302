import { GivelifyPaper } from '@givelify/ui';
import { styled } from '@mui/material';

export const WidgetBox = styled(GivelifyPaper)(({ theme }) => ({
    padding: theme.spacing(3),
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'initial',
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(3, 3, 4, 3),
    },
}));

export const BarChartWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    marginTop: theme.spacing(2),
    '& .recharts-surface': {
        overflow: 'initial',
    },
    [theme.breakpoints.down('tablet')]: {
        marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('mobile')]: {
        marginTop: theme.spacing(4),
    },
}));
