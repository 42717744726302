import React, { useMemo, useState } from 'react';
import {
    GivelifyCheckbox,
    GivelifyInfo,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { Grid } from '@mui/material';
import {
    legacyReportStyles,
    legacyReportsAccentColor,
} from 'pages/reports/LegacyReports/components/style';
import { useTranslation } from 'react-i18next';

interface ExcludeEmptyEnvelopesProps {
    onChange: (value: boolean) => void;
}

export const ExcludeEmptyEnvelopes: React.FC<ExcludeEmptyEnvelopesProps> = ({
    onChange,
}) => {
    const [exclude, setExclude] = useState(false);

    const classes = legacyReportStyles();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            label: t('reports.legacyReportsDashboard.excludeEmptyEnvelopes'),
            description: t(
                'reports.legacyReportsDashboard.excludeEmptyEnvelopesDescription',
            ),
            new: t('reports.legacyReportsDashboard.new'),
        }),
        [t],
    );

    const handleChange = () => {
        onChange(!exclude);
        setExclude(!exclude);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <GivelifyLabel
                    color={legacyReportsAccentColor}
                    fontWeight={800}
                    text={copy.new}
                />
            </Grid>
            <Grid item>
                <GivelifyCheckbox
                    ariaLabel="exclude-empty-envelopes"
                    checked={exclude}
                    className={classes.excludeEnvelopes}
                    classNameLabel={classes.excludeEnvelopesLabel}
                    label={copy.label}
                    name="exclude-empty-envelopes"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item>
                <GivelifyInfo
                    classes={{
                        icon: classes.infoIcon,
                        tooltip: classes.reportTooltip,
                        text: classes.reportTooltipText,
                    }}
                    placement="top"
                    text={copy.description}
                />
            </Grid>
        </Grid>
    );
};
