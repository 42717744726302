import React from 'react';
import { TrackingProvider } from '@givelify/utils';
import { Divider } from '@material-ui/core';
import { useFundraisingToolsStyle } from '../style';
import Breadcrumbs, { LandingPageName } from './Breadcrumbs';
import Label from './Label';

export interface PageProps {
    title: string;
    useDivider?: boolean;
    useBreadcrumb?: boolean;
    addHelpNote?: boolean;
    pageEventKey?: string;
    name?: string;
    landingPageName?: LandingPageName;
}

const Page: React.FC<PageProps> = (props) => {
    const {
        title,
        contentDivider,
        contentContainer,
        helpNoteContainer,
        noBreadcrumb,
        link,
    } = useFundraisingToolsStyle();

    const { addHelpNote = true } = props;

    return (
        <TrackingProvider trackPageVisit pageName={props.title}>
            <Label
                className={title}
                data-testid="page-title"
                variant="heading1"
            >
                {props.title}
            </Label>

            <div className={contentContainer}>
                {props.useDivider ? (
                    <Divider
                        className={
                            contentDivider +
                            (!props.useBreadcrumb || !props.name
                                ? ' ' + noBreadcrumb
                                : '')
                        }
                    />
                ) : null}

                {props.useBreadcrumb && props.name && props.landingPageName ? (
                    <Breadcrumbs
                        currentLinkLabel={props.name}
                        previousPath={props.landingPageName}
                    />
                ) : null}

                {props.children}
            </div>

            {addHelpNote ? (
                <div className={helpNoteContainer}>
                    <a
                        className={link}
                        href="https://support.givelify.com"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Need help? Contact support
                    </a>
                </div>
            ) : null}
        </TrackingProvider>
    );
};

export default Page;
