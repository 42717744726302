import React from 'react';
import { useCfroContext } from '../context';
import View from './view';

const ResendInvitationModal: React.FC = () => {
    const {
        faithLeaderInfo,
        resendModalOpen,
        onSubmitResendModal,
        onCloseResendModal,
    } = useCfroContext();

    return (
        <View
            email={faithLeaderInfo?.email}
            onClose={onCloseResendModal}
            onSubmit={onSubmitResendModal}
            open={resendModalOpen}
        />
    );
};

export default ResendInvitationModal;
