import { TimeFrameValue } from '@givelify/utils';
import { DonationRow } from 'api/models';
import {
    MESSAGE_FILTER_VALUE,
    TRANSACTION_FILTER_VALUE,
    getMessageFilterQuery,
} from 'components';
import { PaginatedResponse } from '../../../types/paginationTypes';
import { legacyStartAndEndDateQS } from '../../dashboard/donations/thunks';

export interface DonationQueryString {
    timeFrame: TimeFrameValue;
    page?: number;
    pageSize?: number;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    sort?: string;
    order?: string;
    onlyDate?: boolean;
    search?: string;
}

export interface EnvelopesQueryString {
    timeFrame: TimeFrameValue;
    page?: number;
    pageSize?: number;
    envelopeIds?: number[];
    messageFilter?: MESSAGE_FILTER_VALUE[];
    transactionFilter?: TRANSACTION_FILTER_VALUE;
    sort?: 'name' | 'priority';
    onlyDate?: boolean;
    search?: string;
}

export const getEnvelopesQueryString = ({
    timeFrame,
    envelopeIds,
    messageFilter,
    transactionFilter,
    onlyDate,
    ...params
}: EnvelopesQueryString) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined),
    ) as Record<string, string>;

    return `${legacyStartAndEndDateQS(timeFrame, '?', onlyDate)}${
        envelopeIds
            ? envelopeIds.map((id) => `&envelopes[]=${id}`).join('')
            : ''
    }${getMessageFilterQuery(messageFilter)}${
        !transactionFilter || transactionFilter === TRANSACTION_FILTER_VALUE.ALL
            ? ''
            : `&${transactionFilter.toLowerCase()}=1`
    }&${new URLSearchParams(
        filteredParams as Record<string, string>,
    ).toString()}`;
};

export const getDonationQueryString = ({
    timeFrame,
    envelopeIds,
    messageFilter,
    transactionFilter,
    onlyDate,
    ...params
}: DonationQueryString) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
            ([_, value]) => value !== undefined && value !== '',
        ),
    ) as Record<string, string>;

    return `${legacyStartAndEndDateQS(timeFrame, '?', onlyDate)}${
        envelopeIds
            ? envelopeIds.map((id) => `&envelopes[]=${id}`).join('')
            : ''
    }${getMessageFilterQuery(messageFilter)}${
        !transactionFilter || transactionFilter === TRANSACTION_FILTER_VALUE.ALL
            ? ''
            : `&${transactionFilter.toLowerCase()}=1`
    }&${new URLSearchParams(
        filteredParams as Record<string, string>,
    ).toString()}`;
};

export enum DONATIONS {
    SET = 'DONATIONS_SET',
    APPEND = 'DONATIONS_APPEND',
    CLEAR = 'DONATIONS_CLEAR',
}

export interface DonationsSet {
    data: PaginatedResponse<DonationRow>;
    type: DONATIONS.SET;
}

export interface DonationsAppend {
    data: PaginatedResponse<DonationRow>;
    type: DONATIONS.APPEND;
}

export interface DonationsClear {
    type: DONATIONS.CLEAR;
}
export type DonationsPageActions =
    | DonationsSet
    | DonationsAppend
    | DonationsClear;
