import React, { useMemo } from 'react';
import {
    isContainsPOBox,
    GivelifyFormInput,
    GivelifyFormSelect,
} from '@givelify/givelify-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Grid } from '@material-ui/core';
import { ErrorText } from 'components/ErrorText';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressInfo } from 'types/orgInfoTypes';
import { STATES_DICTIONARY } from 'utils/state';

import { zipRegExp } from 'utils/validationRegExp';
import { object, string, AnySchema } from 'yup';
import EditorButtons from '../EditorButtons';
import { TimeZoneNote } from './TimeZoneNote';

const useStyles = makeStyles(() => ({
    editorContainer: {
        width: '100%',
        marginTop: 16,
    },
    editorInputContainer: {
        width: '100%',
        marginBottom: 8,
        marginTop: 8,
    },
    stateSelector: {
        '& input': {
            height: 16,
            padding: '15px 16px',
        },
    },
}));

interface AddressSectionEditorProps {
    data?: AddressInfo;
    onCancel: () => void;
    onSubmit: (data: AddressInfo) => void;
    saving?: boolean;
    error?: string;
}

const AddressSectionEditor: React.FC<AddressSectionEditorProps> = (
    props: AddressSectionEditorProps,
) => {
    const { editorContainer, editorInputContainer, stateSelector } =
        useStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            addressPlaceholder: t(
                'pages.settings.organization-info.physical.editor.addressPlaceholder',
            ),
            cityPlaceholder: t(
                'pages.settings.organization-info.physical.editor.cityPlaceholder',
            ),
            statePlaceholder: t(
                'pages.settings.organization-info.physical.editor.statePlaceholder',
            ),
            zipPlaceholder: t(
                'pages.settings.organization-info.physical.editor.zipPlaceholder',
            ),
            zipValid: t(
                'pages.settings.organization-info.physical.editor.zipValid',
            ),
            addressValid: t(
                'pages.settings.organization-info.physical.editor.addressValid',
            ),
            addressMaxlen: t(
                'pages.settings.organization-info.physical.editor.addressMaxlen',
            ),
            cityMaxlen: t(
                'pages.settings.organization-info.physical.editor.cityMaxlen',
            ),
            required: t('labels.required'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const form = useForm<AddressInfo>({
        mode: 'onBlur',
        resolver: yupResolver(
            object<Record<keyof AddressInfo, AnySchema>>({
                address: string()
                    .test('PO Box', copy.addressValid, (value) => {
                        return !isContainsPOBox(value);
                    })
                    .required(copy.required)
                    .max(200, copy.addressMaxlen),
                zip: string()
                    .max(5)
                    .matches(zipRegExp, copy.zipValid)
                    .required(copy.required),
                state: string().required(copy.required),
                city: string().required(copy.required).max(55, copy.cityMaxlen),
            }),
        ),
        defaultValues: {
            address: props.data.address || '',
            zip: props.data.zip || '',
            state: props.data.state || '',
            city: props.data.city || '',
        },
    });

    const handleSubmit = () => {
        const formValues = form.getValues();
        props.onSubmit(formValues);
    };

    return (
        <form
            className={editorContainer}
            onSubmit={form.handleSubmit(handleSubmit)}
        >
            <Grid container spacing={3}>
                <Grid
                    item
                    className={editorInputContainer}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <GivelifyFormInput
                        fullWidth
                        ariaLabel="address-input"
                        disabled={props.saving}
                        formRef={form}
                        id="address"
                        inputLabel="address-input"
                        maxLength={200}
                        name="address"
                        placeholder={copy.addressPlaceholder}
                    />
                </Grid>
                <Grid
                    item
                    className={editorInputContainer}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <GivelifyFormInput
                        fullWidth
                        ariaLabel="city-input"
                        disabled={props.saving}
                        formRef={form}
                        id="city"
                        inputLabel="city-input"
                        maxLength={55}
                        name="city"
                        placeholder={copy.cityPlaceholder}
                    />
                </Grid>
                <Grid
                    item
                    className={editorInputContainer}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <GivelifyFormSelect
                        fullWidth
                        ariaLabel="stateSelector"
                        className={stateSelector}
                        defaultValue={props.data.state || null}
                        disabled={props.saving}
                        formRef={form}
                        id="stateSelector"
                        inputLabel="stateSelector"
                        maxLength={50}
                        name="state"
                        options={STATES_DICTIONARY}
                        placeholder={copy.statePlaceholder}
                    />
                </Grid>
                <Grid item className={editorInputContainer} md={6} sm={12}>
                    <GivelifyFormInput
                        fullWidth
                        ariaLabel="zip-input"
                        disabled={props.saving}
                        formRef={form}
                        id="zip"
                        inputLabel="zip-input"
                        maxLength={5}
                        name="zip"
                        placeholder={copy.zipPlaceholder}
                    />
                </Grid>
            </Grid>
            <TimeZoneNote />
            {props.error && <ErrorText text={props.error} />}
            <EditorButtons onCancel={props.onCancel} saving={props.saving} />
        </form>
    );
};

export default AddressSectionEditor;
