import React from 'react';
import { Grid } from '@material-ui/core';

import CopyGivingLink from '../../../components/CopyGivingLink';
import Label from '../../../components/Label';
import Page from '../../../components/Page';

import SMGivingTip from '../../../components/SMGivingTip';

import { useConnectorState } from '../../../provider/Connector';
import { YT_GIVING_LOADED } from '../../../utils/cleverTapEvents';
import { pushEvent } from '../../../utils/cleverTapUtils';
import { useTranslations } from '../../../utils/translations';
import Preview from './Preview';
import { useStyle } from './style';

export const YouTubeGiving: React.FC = () => {
    const cs = useStyle();
    const { onlineGivingURLs } = useConnectorState();

    const givingLink =
        onlineGivingURLs.find((x) => x.channel === 'YTB')?.shortLink || '';

    const { pageTitle, increaseGiving, copyOrgLink } = useTranslations(
        'socialMediaGiving.youtube.page',
        {
            pageTitle: 'title',
            increaseGiving: ['title', 'desc'],
            copyOrgLink: ['title', 'desc1', 'desc2'],
        },
    );

    React.useEffect(() => {
        pushEvent(YT_GIVING_LOADED);
    }, []);

    return (
        <Page
            useBreadcrumb
            useDivider
            landingPageName="socialMediaGiving"
            name="YouTube"
            title={pageTitle}
        >
            <div className={cs.container} data-testid="youtube-giving">
                <Grid container spacing={5}>
                    <Grid item className={cs.fullContent} md={6} sm={12}>
                        <Label variant="heading4">{increaseGiving.title}</Label>
                        <Label className={cs.shareBody} variant="body1">
                            {increaseGiving.desc}
                        </Label>

                        <Label
                            className={cs.givingLinkTitle}
                            variant="heading4"
                        >
                            {copyOrgLink.title}
                        </Label>
                        <Label className={cs.shareBody} variant="body1">
                            {copyOrgLink.desc1}
                        </Label>
                        <Label className={cs.shareBody} variant="body1">
                            {copyOrgLink.desc2}
                        </Label>

                        <CopyGivingLink givingLink={givingLink} />
                    </Grid>

                    <Grid item className={cs.fullContent} md={6} sm={12}>
                        <Preview />
                    </Grid>
                </Grid>

                <SMGivingTip
                    className={cs.tipContainer}
                    link="https://support.google.com/youtube/answer/2474026?co=GENIE.Platform%3DAndroid&hl=en"
                    media="youtube"
                />
            </div>
        </Page>
    );
};
