import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import RefundRowLoadingDesktop from './RefundRowLoadingDesktop';
import RefundRowLoadingMobile from './RefundRowLoadingMobile';

const RefundRowLoading = () => {
    const isMobile = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs'),
    );

    if (isMobile) return <RefundRowLoadingMobile />;

    return <RefundRowLoadingDesktop />;
};

export default RefundRowLoading;
