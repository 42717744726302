import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLeftChevronDouble = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={30}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>left chevren double</title>
        <path
            clipRule="evenodd"
            d="M19.7071 16.7071C20.0976 16.3166 20.0976 15.6834 19.7071 15.2929L16.4142 12L19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289C19.3166 6.90237 18.6834 6.90237 18.2929 7.29289L14.2929 11.2929C13.9024 11.6834 13.9024 12.3166 14.2929 12.7071L18.2929 16.7071C18.6834 17.0976 19.3166 17.0976 19.7071 16.7071Z"
            fill="current"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M14.7071 16.7071C15.0976 16.3166 15.0976 15.6834 14.7071 15.2929L11.4142 12L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L9.29289 11.2929C8.90237 11.6834 8.90237 12.3166 9.29289 12.7071L13.2929 16.7071C13.6834 17.0976 14.3166 17.0976 14.7071 16.7071Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const LeftChevronDoubleIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgLeftChevronDouble}
        fontSize={fontSize}
    />
);
