import { ErrorResponse } from '@givelify/givelify-ui';
import { DonorsTimeFrameData } from '../types';

export const SET_DONORS_CURRENT_LOADING = 'SET_DONORS_CURRENT_LOADING';
export const SET_DONORS_CURRENT_SUCCESS = 'SET_DONORS_CURRENT_SUCCESS';
export const SET_DONORS_CURRENT_FAILED = 'SET_DONORS_CURRENT_FAILED';

export interface SetDonorsCurrentLoading {
    type: typeof SET_DONORS_CURRENT_LOADING;
}

export interface SetDonorsCurrentSuccess {
    type: typeof SET_DONORS_CURRENT_SUCCESS;
    data: DonorsTimeFrameData;
}

export interface SetDonorsCurrentFailed {
    type: typeof SET_DONORS_CURRENT_FAILED;
    error: ErrorResponse;
}
