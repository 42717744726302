import React, { useMemo } from 'react';
import { GivelifyTextField, GivelifyLabel } from '@givelify/ui';
import { Grid } from '@material-ui/core';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useCfroContext } from '../context';
import useStyles from './styles';

interface AccountOwnerInfoProps {
    isEdit?: boolean;
}

const AccountOwnerInfo: React.FC<AccountOwnerInfoProps> = (
    props: AccountOwnerInfoProps,
) => {
    const { isEdit } = props;
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            accountOwner: t('overview.confirmContactInfoModal.accountOwner'),
            contactYourAccountOwner: t(
                'overview.confirmContactInfoModal.contactYourAccountOwner',
            ),
            firstName: t('pages.settings.users.add-new-modal.form.firstName'),
            lastName: t('pages.settings.users.add-new-modal.form.lastName'),
            title: t('pages.settings.users.add-new-modal.form.title'),
            email: t('pages.settings.users.add-new-modal.form.email'),
            titleExample: t(
                'pages.settings.users.add-new-modal.form.titleExample',
            ),
            allFieldsrequired: t(
                'pages.settings.users.add-new-modal.form.allRequired',
            ),
        }),
        [t],
    );

    const { accountOwnerInfo } = useCfroContext();

    const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div style={{ maxWidth: 688 }}>
            <GivelifyLabel
                className={'headingTitle'}
                text={copy.accountOwner}
                variant="heading3"
            />
            <GivelifyLabel
                className="contactAccountOwner"
                text={copy.contactYourAccountOwner}
            />
            {mediumScreen && (
                <GivelifyLabel
                    className={'allFieldText'}
                    text={copy.allFieldsrequired}
                />
            )}
            <Grid container spacing={3}>
                <Grid item className={styles.firstNameBlock} sm={6} xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyTextField
                            countLength
                            defaultValue={accountOwnerInfo?.firstName}
                            disabled={!isEdit}
                            id="first-name"
                            label={copy.firstName}
                            maxLength={55}
                            name="firstName"
                            placeholder={copy.firstName}
                            value={accountOwnerInfo?.firstName || ''}
                        />
                    </Box>
                </Grid>
                <Grid item className={styles.lastNameBlock} sm={6} xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyTextField
                            countLength
                            defaultValue={accountOwnerInfo?.lastName}
                            disabled={!isEdit}
                            id="last-name"
                            label={copy.lastName}
                            maxLength={55}
                            name="lastName"
                            placeholder={copy.lastName}
                            value={accountOwnerInfo?.lastName || ''}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyTextField
                            defaultValue={accountOwnerInfo?.title}
                            disabled={!isEdit}
                            id="title"
                            label={copy.title}
                            name="title"
                            placeholder={copy.title}
                        />
                    </Box>
                    <div className={styles.titleSelectHelperText}>
                        <GivelifyLabel
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={copy.titleExample}
                        />
                        <GivelifyLabel
                            color="neutralGrey"
                            fontSize={12}
                            lineHeight={20}
                            text={`${accountOwnerInfo?.title?.length || 0}/25`}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            '& > div': {
                                width: '100%',
                            },
                        }}
                    >
                        <GivelifyTextField
                            disabled={!isEdit}
                            id="email"
                            label={copy.email}
                            leftHelperText={''}
                            name="email"
                            placeholder={copy.email}
                            value={accountOwnerInfo?.email || ''}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountOwnerInfo;
