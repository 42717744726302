import { makeStyles, Theme } from '@material-ui/core/styles';

export interface ModelContentStylesProps {
    width?: number;
    textAlign?: 'left' | 'right';
    padding?: string | number;
    heightOnSmallScreens?: string | number;
    breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const modalContentStyles = makeStyles((theme: Theme) => ({
    modalContent: (props: ModelContentStylesProps) => ({
        outline: 'none',
        background: '#fff',
        position: 'absolute',
        width: props.width || 690,
        padding: props.padding || '48px 88px 0px 88px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        borderRadius: 10,
        textAlign: props.textAlign || 'center',
        '& button': {
            '& svg': {
                margin: 0,
            },
        },
        [theme.breakpoints.down(props.breakPoint || 'xs')]: {
            width: '90%',
            height: props.heightOnSmallScreens,
            padding: '48px 16px 0px 16px',
            top: 'auto',
            bottom: 0,
            left: 0,
            transform: 'translateX(5%) translateY(0%)',
        },
        '@media (max-width:320px)': {
            transform: 'translateX(5%) translateY(0%)',
        },
    }),
}));

export const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'inline-grid',
        gap: '10px',
    },
    loginTitle: {
        color: theme.colors.primaryDarkGray,
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: '24px',
            display: 'flex',
        },
    },
    loginText: {
        color: '#676E77',
        fontSize: 16,
    },
    forgotRow: {
        fontSize: 16,
        '& a': {
            display: 'inline-block',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    leftLogin: {
        maxWidth: 512,
    },
    closeModal: {
        position: 'absolute',
        top: 10,
        right: 10,
        border: '1px solid #85919F',
        borderRadius: '50%',
        width: 24,
        height: 24,
        maxWidth: 24,
        minWidth: 24,
        padding: 0,
        lineHeight: '100%',
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        color: '#85919F',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            width: 24,
            maxWidth: 24,
            minWidth: 24,
            height: 24,
            fontSize: 15,
        },
    },
    learnMoreRow: {
        textAlign: 'left',
        marginTop: 16,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
        position: 'relative',
    },
    greenLeaf: {
        position: 'absolute',
        display: 'inline-block',
        top: '24px',
        left: '-12px',

        [theme.breakpoints.down('sm')]: {
            top: '16px',
        },

        '&::before': {
            content: '""',
            width: 55,
            height: 16,
            background: '#ffffff',
            position: 'absolute',
            right: 0,
            zIndex: 3,
            animation: '$leafShow 5s infinite normal',
        },
        '& > img': {
            position: 'relative',
        },
    },
    '@keyframes leafShow': {
        '0%': {
            width: 55,
        },
        '30%': {
            width: 0,
        },
        '100%': {
            width: 0,
        },
    },
    inlineBlock: {
        display: 'inline-block',
    },
}));
