import { getAxiosClient, makeApiRequest, ApiResponse } from '@givelify/utils';
import { getTaxIdDetailsUrl } from '../../consts/endpoints';

export type TaxIdDetails = {
    address: string;
    city: string;
    ein: string;
    name: string;
    state: string;
    zip: string;
};

export const getTaxIdDetails = async (
    taxId: string,
): Promise<ApiResponse<TaxIdDetails>> => {
    const httpRequest = getAxiosClient().get<TaxIdDetails>(getTaxIdDetailsUrl(taxId));
    const result = makeApiRequest<TaxIdDetails>(httpRequest);
    return result;
};
