import { TimeFrameValue } from '@givelify/utils';
import { DonationsStatisticType } from 'api/client/endpoints';
import dayjs from 'dayjs';
import { getDateLabel } from '../timeFrameUtils';

export const getRangeBasedDateShort = (
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    rangeDateType: DonationsStatisticType,
) => {
    switch (rangeDateType) {
        case 'daily':
            return startDate.format('D MMM');
        case 'monthly':
            return startDate.format('MMM');
        case 'yearly':
            return startDate.format('YYYY');
        default:
            return startDate.format('D MMM');
    }
};

export const getBarChartRangeDate = (
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    timeFrame: TimeFrameValue,
) => {
    switch (timeFrame.selector) {
        case 'today':
        case 'yesterday':
            return startDate.format('h A');
        case 'thisWeek':
        case 'lastWeek':
            return startDate.format('dddd');
        case 'thisMonth':
        case 'lastMonth':
        case 'last30Days':
            return startDate.format('D MMM');
        case 'last90Days':
        case 'last12Months':
        case 'thisYear':
        case 'lastYear':
            return startDate.format('MMM');
        case 'lifetime':
            return startDate.format('YYYY');
        case 'custom': {
            return getDateLabel(0, timeFrame, [[startDate, endDate]]);
        }
        default:
            return startDate.format('D MMM');
    }
};

export const getRangeBasedDate = (
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    rangeDateType: DonationsStatisticType,
) => {
    switch (rangeDateType) {
        case 'hourly':
            return startDate.format('h A');
        case 'daily':
            return startDate.format('D MMM');
        case 'weekly':
            return `${startDate.format('DD MMM')} - ${endDate.format(
                'DD MMM',
            )}`;
        case 'monthly':
            return startDate.format('MMM');
        case 'yearly':
            return startDate.format('YYYY');
    }
};
