import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TooltipProps } from 'recharts';
import { AppState } from 'store';
import { DonorDistribution } from 'store/dashboard/donors/types';
import ZeroState from '../../ZeroState';
import PieChartWrapper, {
    NameType,
    ValueType,
} from '../components/PieChartWrapper';
import { tooltipStyle } from '../components/PieChartWrapper/SimplePieChart';

const DonorStatusTooltip: React.FC<TooltipProps<ValueType, NameType>> = (
    props,
) => {
    if (props.payload.length === 0) return null;

    const data = props.payload[0];
    return (
        <div style={tooltipStyle}>
            <p>
                <strong>{data.name}</strong>
            </p>
            <Box
                display="flex"
                justifyContent="space-between"
                pb={3}
                width="100%"
            >
                <Box fontWeight="bold" pr={2}>
                    Total:
                </Box>
                <span>{data.payload.donorsCount}</span>
            </Box>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        position: 'absolute',
        right: 24,
        bottom: 24,
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
        },
    },
}));

const src = '/overview/often-donor-give.svg';
const srcGrey = '/overview/often-donor-give-grey.svg';

const DonorsStatus: React.FC<{ distribution: DonorDistribution[] }> = ({
    distribution,
}) => {
    const { t } = useTranslation();
    const cs = useStyles();
    const copy = React.useMemo(
        () => ({
            legendTitle: t('dashboard.donorStatus.legendTitle'),
            description1: t('dashboard.donorStatus.description1'),
            description2: t('dashboard.donorStatus.description2'),
        }),
        [t],
    );

    const { onboardingEnabled, onboardingCompleted } = useSelector(
        (state: AppState) => ({
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
        }),
    );

    if (distribution.length === 0) {
        return (
            <ZeroState
                src={onboardingEnabled && onboardingCompleted ? src : srcGrey}
                type={t('donationData')}
            />
        );
    }

    return (
        <>
            <PieChartWrapper
                chart={{
                    data: distribution,
                    dataKey: 'donorsCount',
                    nameKey: 'name',
                    tooltip: DonorStatusTooltip,
                }}
                legend={{
                    heading: copy.legendTitle,
                    legends: distribution,
                    nameKey: 'name',
                }}
            />

            <div className={cs.description}>
                <GivelifyLabel
                    color="neutralGrey"
                    text={copy.description1}
                    variant="small"
                />
                <GivelifyLabel
                    color="neutralGrey"
                    text={copy.description2}
                    variant="small"
                />
            </div>
        </>
    );
};

export default DonorsStatus;
