import React from 'react';

import DashboardMenu from '../../components/DashboardMenu';
import { MenuTitleInfo } from '../../components/DashboardMenu/types';
import { useConnectorState } from '../../provider/Connector';
import { useTranslations } from '../../utils/translations';

const useTranslatedMenuItems = () => {
    const { routeUrls, givelithonEnabled, snapGiveEnabled } =
        useConnectorState();
    const copy = useTranslations('root', {
        onlineGiving: ['title', 'description'],
        socialMediaGiving: ['title', 'description'],
        givelithon: ['title', 'description'],
        snapGive: ['title', 'description'],
    });

    const menuItems: MenuTitleInfo[] = [
        {
            icon: 'gift-card',
            title: 'onlineGiving',
            description: 'onlineGiving',
            link: routeUrls.onlineGiving || '',
        },
        {
            icon: 'thumb-up',
            title: 'socialMediaGiving',
            description: 'socialMediaGiving',
            link: routeUrls.socialMediaGiving || '',
        },
    ];

    if (givelithonEnabled) {
        menuItems.push({
            icon: 'star',
            title: 'givelithon',
            description: 'givelithon',
            link: routeUrls.givelithon || '',
        });
    }

    if (snapGiveEnabled) {
        menuItems.push({
            icon: 'star',
            title: 'snapGive',
            description: 'snapGive',
            link: routeUrls.snapGive || '',
        });
    }

    return menuItems.map((item) => ({
        ...item,
        title: copy[item.title].title,
        description: copy[item.title].description,
    }));
};

function DonationsRoot() {
    const copy = useTranslations('root', ['title', 'description']);

    const menuItems = useTranslatedMenuItems();

    return (
        <DashboardMenu
            description={copy.description}
            menuItems={menuItems}
            title={copy.title}
        />
    );
}

export default DonationsRoot;
