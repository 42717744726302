import React, { useMemo } from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { isBase64Image } from '@givelify/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import View from './view';

const UserDropdownMobileComponent: React.FC = () => {
    const { user, doneeCountry } = useSelector((state: AppState) => ({
        user: state.User.user,
        doneeCountry: state.Donee.donee?.country,
    }));

    const { showCaptivePortal } = useCaptivePortalContext();

    const avatarImage = useMemo(
        () =>
            // we need to correctly display cropped image upon user selection
            user
                ? isBase64Image(user.avatar)
                    ? user.avatarOriginal
                    : user.avatar
                : '',
        [user],
    );

    return (
        <View
            country={doneeCountry}
            hideEditProfile={showCaptivePortal}
            userAvatar={avatarImage}
            userName={user?.fullName}
        />
    );
};

export const UserDropdownMobile = React.memo(UserDropdownMobileComponent);
