import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    maxWidth: 484,
    '& .title': {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    '& .descr': {
        padding: theme.spacing(0, 8),
        textAlign: 'center',
    },
    '& .footer': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(3),
        padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 348,
        gap: theme.spacing(1),
        '& .descr': {
            padding: theme.spacing(0, 0),
        },
        '& .footer': {
            padding: 0,
            marginTop: theme.spacing(1),
            flexDirection: 'column',
            gap: theme.spacing(1.5),
        },
    },
}));
