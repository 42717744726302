import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(0, 4),
    width: '100%',
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(0, 3),
    },
}));
