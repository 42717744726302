import React, { useMemo, useState } from 'react';
import { DesignTokens, GivelifyAvatar, GivelifyLabel } from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { isBase64Image } from '@givelify/utils';
import { ClickAwayListener, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { HeaderUserData } from 'store/user/types';
import { logoutUser } from '../../../../../store/user/thunks';
import {
    IconButtonBlock,
    UserAvatarWrapper,
    UserDropdownBlock,
} from '../../../../../styles/headerLayout';
import {
    GO_TO_EDIT_PROFILE,
    USER_LOGOUT,
} from '../../../../../utils/clevertapEvents';
import { DesktopMenu, DesktopMenuItem, DesktopSimpleMenuItem } from '../styles';

// TODO: remove this interface after new header is used
interface UserDropdownProps {
    userName?: string;
    hideEditProfile: boolean;
    userData: Partial<HeaderUserData>;
}

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
} as const;

const transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
} as const;

export const DonorNameAvatar = styled(GivelifyAvatar)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

const View: React.FC<UserDropdownProps> = ({ hideEditProfile, userData }) => {
    const { trackEvent } = useTrackingContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { PATH } = useGasRouterContext();

    const [open, isOpen] = useState<boolean>(false);
    const handleOpen = React.useCallback(() => {
        isOpen(true);
    }, []);
    const handleClose = React.useCallback(() => isOpen(false), []);
    const pushEditProfilePage = React.useCallback(() => {
        trackEvent(GO_TO_EDIT_PROFILE);
        navigate(PATH.EDIT_PROFILE);
        handleClose();
    }, [handleClose, navigate, trackEvent, PATH.EDIT_PROFILE]);
    const dispatch = useDispatch();

    const logoutDonee = React.useCallback(() => {
        trackEvent(USER_LOGOUT);
        logoutUser()(dispatch);
        handleClose();
    }, [trackEvent, dispatch, handleClose]);

    const avatarImage = useMemo(
        () =>
            // we need to correctly display cropped image upon user selection
            isBase64Image(userData.avatar)
                ? userData.avatarOriginal
                : userData.avatar,
        [userData],
    );

    const anchorRef = React.useRef<HTMLDivElement>(null);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <UserDropdownBlock>
                <UserAvatarWrapper ref={anchorRef}>
                    <IconButtonBlock
                        data-testid="open-dropdown-button"
                        id="user-setting"
                        isOpen={open}
                        name="Avatar"
                        onClick={handleOpen}
                        size="small"
                        variant="icon"
                    >
                        <div>
                            <DonorNameAvatar
                                data-testid="donor-name-avatar"
                                size="xSmall"
                                src={avatarImage}
                                text={
                                    userData.avatar
                                        ? ''
                                        : userData?.fullName?.charAt(0) ?? ''
                                }
                            />
                        </div>
                    </IconButtonBlock>
                </UserAvatarWrapper>
                <DesktopMenu
                    anchorEl={anchorRef.current}
                    anchorOrigin={anchorOrigin}
                    data-testid="dropdown-menu"
                    onClose={handleClose}
                    open={open}
                    transformOrigin={transformOrigin}
                >
                    <DesktopSimpleMenuItem tabIndex={-1}>
                        <div>
                            <GivelifyLabel
                                color={DesignTokens.color.textSecondary}
                                data-testid="user-fullname"
                                text={t('userDropdown.signedIn')}
                                variant="body1"
                            />
                            <GivelifyLabel
                                color={DesignTokens.color.textPrimary}
                                text={userData.fullName}
                                variant="body1"
                            />
                        </div>
                    </DesktopSimpleMenuItem>
                    {!hideEditProfile && (
                        <DesktopMenuItem
                            data-testid="edit-profile-item"
                            onClick={pushEditProfilePage}
                            tabIndex={0}
                        >
                            <GivelifyLabel
                                text={t('userDropdown.editProfile')}
                                variant="body1"
                            />
                        </DesktopMenuItem>
                    )}
                    <DesktopMenuItem
                        data-testid="logout-donee-item"
                        onClick={logoutDonee}
                        tabIndex={0}
                    >
                        <GivelifyLabel
                            text={t('userDropdown.signOut')}
                            variant="body1"
                        />
                    </DesktopMenuItem>
                </DesktopMenu>
            </UserDropdownBlock>
        </ClickAwayListener>
    );
};

export default View;
