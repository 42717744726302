import React from 'react';
import {
    DesignTokens,
    GivelifyCheckbox,
    GivelifyDatePicker,
    GivelifyIcon,
} from '@givelify/ui';
import {
    CHECKBOX_IDENTIFIER,
    INPUT_IDENTIFIER,
    TimeFrameId,
    TimeFrameValue,
    useTrackingContext,
} from '@givelify/utils';
import { EnvelopesFilterState, MESSAGE_FILTER_VALUE } from 'api/hooks';
import {
    FilterPanelCheckboxWrapperFull,
    FilterPanelDateInputsWrapper,
    FilterPanelInputBlock,
    FilterPanelInputLabel,
    FilterPanelInputsWrapper,
    FilterPanelMessageInputsWrapper,
    FilterPanelMessageFilterWrapper,
    FilterExpandButton,
} from 'components/filters/filterPanel/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export type DonationsFilter = {
    timeFrame: TimeFrameValue;
    envelopes: EnvelopesFilterState;
    messages: MESSAGE_FILTER_VALUE[];
};

type DonationsFilterFormProps = {
    filter: DonationsFilter;
    setFilter: (filter: DonationsFilter) => void;
    doneeSignupDate?: Date;
    expand?: boolean;
    setExpand?: (expand: boolean) => void;
};

export const DonationsFilterForm: React.FC<DonationsFilterFormProps> = ({
    filter,
    setFilter,
    doneeSignupDate,
    expand,
    setExpand,
}) => {
    const { trackEvent } = useTrackingContext();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            dateRange: t('filters.dateRange'),
            envelopes: t('donationsActivity.text.fund'),
            messages: t('donationsActivity.messages.messages'),
            noMessages: t('donationsActivity.messages.noMessages'),
            replied: t('donationsActivity.messages.repliedShort'),
            unReplied: t('donationsActivity.messages.unRepliedShort'),
            showLess: t('donationsActivity.messages.showLess'),
            showMore: t('donationsActivity.messages.showMore'),
        }),
        [t],
    );
    const onRangeChange = React.useCallback(
        (start: dayjs.Dayjs, end: dayjs.Dayjs, range: TimeFrameId) => {
            const update: DonationsFilter = {
                ...filter,
                timeFrame:
                    start === null && end === null
                        ? undefined
                        : {
                              start,
                              end,
                              selector: range,
                          },
            };
            setFilter(update);
        },
        [filter, setFilter],
    );
    const onEvnelopeToggle = React.useCallback(
        (id: number, checked: boolean, envelopeName: string) => {
            let checkedEnvelopes: number[] = [];
            if (checked) {
                checkedEnvelopes = [...filter.envelopes.values, id].sort();
            } else {
                checkedEnvelopes = filter.envelopes.values
                    .filter((envId) => envId !== id)
                    .sort();
            }
            setFilter({
                ...filter,
                envelopes: {
                    ...filter.envelopes,
                    values: checkedEnvelopes,
                },
            });

            trackEvent(`<Envelope>_${CHECKBOX_IDENTIFIER}`, {
                envelopeName,
            });
        },
        [filter, setFilter, trackEvent],
    );
    const onMessagesToggle = React.useCallback(
        (id: MESSAGE_FILTER_VALUE, checked: boolean, messageFilter: string) => {
            if (checked) {
                const checkedMessages = [...filter.messages, id].sort();
                setFilter({
                    ...filter,
                    messages: checkedMessages,
                });
            } else {
                const checkedMessages = filter.messages
                    .filter((mess) => mess !== id)
                    .sort();
                setFilter({
                    ...filter,
                    messages: checkedMessages,
                });
            }

            trackEvent(`<Message>_${CHECKBOX_IDENTIFIER}`, {
                messageFilter,
            });
        },
        [filter, setFilter, trackEvent],
    );
    const envList = React.useMemo(() => {
        if (filter.envelopes.options.length > 10 && !expand) {
            return filter.envelopes.options.slice(0, 10);
        } else return filter.envelopes.options;
    }, [expand, filter.envelopes.options]);
    const ExpandButton = React.useMemo(() => {
        if (filter.envelopes.options.length > 10) {
            return (
                <FilterExpandButton
                    disableRipple
                    endIcon={
                        expand ? (
                            <GivelifyIcon variant="up-chevron" />
                        ) : (
                            <GivelifyIcon variant="down-chevron" />
                        )
                    }
                    name={expand ? 'Close envelopes' : 'Expand envelopes'}
                    onClick={() => setExpand(!expand)}
                    text={expand ? copy.showLess : copy.showMore}
                    variant="ghost"
                />
            );
        }
        return null;
    }, [
        copy.showLess,
        copy.showMore,
        expand,
        filter.envelopes.options.length,
        setExpand,
    ]);
    return (
        <div>
            <FilterPanelInputBlock id="date-range-block">
                <FilterPanelInputLabel color={DesignTokens.color.textWhite}>
                    {copy.dateRange}
                </FilterPanelInputLabel>
                <FilterPanelDateInputsWrapper>
                    <GivelifyDatePicker
                        disableFuture
                        fullwidth
                        dataTestId="dateRange"
                        end={
                            filter.timeFrame &&
                            filter.timeFrame.end &&
                            dayjs(filter.timeFrame.end).isValid()
                                ? dayjs(filter.timeFrame.end)
                                : null
                        }
                        id="donations-date-range"
                        minDate={dayjs.tz(doneeSignupDate)}
                        name="dateRange"
                        onOpenTrackingName={`<Date range>_${INPUT_IDENTIFIER}`}
                        onRangeChange={onRangeChange}
                        pickerMode="range"
                        popoverProps={{
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'bottom',
                            },
                            transformOrigin: {
                                horizontal: 'right',
                                vertical: 'top',
                            },
                        }}
                        range={filter.timeFrame?.selector}
                        start={
                            filter.timeFrame &&
                            filter.timeFrame.start &&
                            dayjs(filter.timeFrame.start).isValid()
                                ? dayjs(filter.timeFrame.start)
                                : null
                        }
                        themeMode="dark"
                    />
                </FilterPanelDateInputsWrapper>
            </FilterPanelInputBlock>
            <FilterPanelInputBlock>
                <FilterPanelInputLabel
                    color={DesignTokens.color.textWhite}
                    id="envelopes-title"
                >
                    {copy.envelopes}
                </FilterPanelInputLabel>
                <FilterPanelInputsWrapper>
                    <FilterPanelCheckboxWrapperFull>
                        {envList.map((option) => (
                            <GivelifyCheckbox
                                key={option.id}
                                darkMode
                                checked={filter.envelopes.values.includes(
                                    option.id,
                                )}
                                id={`env-${option.id}`}
                                inputProps={{
                                    'data-testid': `env-${option.id}`,
                                }}
                                label={option.label}
                                name="envelope"
                                onChange={(_ev, checked) =>
                                    onEvnelopeToggle(
                                        option.id,
                                        checked,
                                        option.label,
                                    )
                                }
                            />
                        ))}
                        {ExpandButton}
                    </FilterPanelCheckboxWrapperFull>
                </FilterPanelInputsWrapper>
            </FilterPanelInputBlock>
            <FilterPanelInputBlock>
                <FilterPanelInputLabel
                    color={DesignTokens.color.textWhite}
                    id="messages-title"
                >
                    {copy.messages}
                </FilterPanelInputLabel>
                <FilterPanelMessageInputsWrapper>
                    <FilterPanelMessageFilterWrapper className="filter-r1-c1">
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.messages.includes(
                                MESSAGE_FILTER_VALUE.UN_REPLIED,
                            )}
                            id="message-filter-unreplied"
                            inputProps={{
                                'data-testid': 'message-filter-unreplied',
                            }}
                            label={copy.unReplied}
                            name="messages"
                            onChange={(_ev, checked) =>
                                onMessagesToggle(
                                    MESSAGE_FILTER_VALUE.UN_REPLIED,
                                    checked,
                                    copy.unReplied,
                                )
                            }
                        />
                    </FilterPanelMessageFilterWrapper>
                    <FilterPanelMessageFilterWrapper className="filter-r1-c2">
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.messages.includes(
                                MESSAGE_FILTER_VALUE.REPLIED,
                            )}
                            id="message-filter-replied"
                            inputProps={{
                                'data-testid': 'message-filter-replied',
                            }}
                            label={copy.replied}
                            name="messages"
                            onChange={(_ev, checked) =>
                                onMessagesToggle(
                                    MESSAGE_FILTER_VALUE.REPLIED,
                                    checked,
                                    copy.replied,
                                )
                            }
                        />
                    </FilterPanelMessageFilterWrapper>
                    <FilterPanelMessageFilterWrapper className="filter-r2-c2">
                        <GivelifyCheckbox
                            darkMode
                            checked={filter.messages.includes(
                                MESSAGE_FILTER_VALUE.NO_MESSAGES,
                            )}
                            id="message-filter-no-messages"
                            inputProps={{
                                'data-testid': 'message-filter-no-messages',
                            }}
                            label={copy.noMessages}
                            name="messages"
                            onChange={(_ev, checked) =>
                                onMessagesToggle(
                                    MESSAGE_FILTER_VALUE.NO_MESSAGES,
                                    checked,
                                    copy.noMessages,
                                )
                            }
                        />
                    </FilterPanelMessageFilterWrapper>
                </FilterPanelMessageInputsWrapper>
            </FilterPanelInputBlock>
        </div>
    );
};
