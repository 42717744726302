import React, { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IntegrationSetupButtonProps {
    reportExists: boolean;
    formChange: boolean;
    cancel: () => void;
    isReadOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
    const spacing2 = theme.spacing(2);
    return {
        submitButton: {
            marginRight: spacing2,
            marginBottom: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                margin: '0 auto 48px',
            },
            padding: 'unset',
        },
        cancelButton: {
            marginRight: theme.spacing(4),
            alignSelf: 'flex-start',
            padding: 'unset',
        },
    };
});

const IntegrationSetupButton: React.FC<IntegrationSetupButtonProps> = ({
    reportExists,
    formChange,
    cancel,
    isReadOnly,
}) => {
    const { cancelButton, submitButton } = useStyles();

    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            save: t('labels.save'),
            cancel: t('labels.cancel'),
            continue: t('labels.continue'),
        }),
        [t],
    );

    return (
        <Box display="flex" justifyContent="flex-end">
            {reportExists && formChange && (
                <GivelifyButton
                    className={cancelButton}
                    disabled={isReadOnly}
                    name="cancel"
                    onClick={cancel}
                    text={copy.cancel}
                    variant="secondary"
                />
            )}
            {(!reportExists || formChange) && (
                <GivelifyButton
                    className={submitButton}
                    disabled={isReadOnly}
                    name="submit"
                    text={reportExists ? copy.save : copy.continue}
                    type="submit"
                    variant="primary"
                />
            )}
        </Box>
    );
};

export default IntegrationSetupButton;
