import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';

interface RequiredBankInfoHelpProps {
    imgSrc: string;
}

const InfoContainer = styled('div')(({ theme }) => ({
    '& img': {
        marginTop: 16,
    },
}));

export const RequiredBankInfoHelp: React.FC<RequiredBankInfoHelpProps> = (
    props,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            infoHeading: t('bankInfo.infoHeading'),
        }),
        [t],
    );

    return (
        <InfoContainer>
            <GivelifyLabel text={copy.infoHeading} variant="heading2S" />
            <img alt='check' src={props.imgSrc} width="100%" />
        </InfoContainer>
    );
};
