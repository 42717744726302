import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    totalAmount: {
        marginTop: 32,
        marginBottom: 32,
        display: 'flex',
    },
    floatRight: {
        float: 'right',
    },
    footer: {
        display: 'flex',
        '& > div > :nth-child(even)': {
            color: theme.colors.grey600,
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 8,
        },
    },
}));
