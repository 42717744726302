import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    FacebookLoading,
    useAdvancedTranslation,
    GivelifyBreadcrumbs,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import accessibleFeaturesByRole from 'utils/accessibleFeaturesByRole';
import { Features } from 'utils/featureGating';
import { useCampusesContext } from '../CampusesProvider';
import EmptyList from './EmptyList';
import ListItem from './ListItem';
import useStyles from './styles';

const CampusList = () => {
    const { header, wrapper } = useStyles();
    const { campuses, isLoading, isEditable, setShowAddCampusModal } =
        useCampusesContext();

    const { userRole } = useSelector((state: AppState) => ({
        userRole: state.User.user?.role,
    }));

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list',
    });
    const { t } = useTranslation();

    const { title, addCampus, settings, campusesText } = useMemo(
        () => ({
            title: scopedTranslate('title'),
            addCampus: scopedTranslate('addCampus'),
            settings: t('menu.settings.title'),
            campusesText: t('menu.settings.campuses'),
        }),
        [scopedTranslate, t],
    );

    const Loader = (
        <div className={wrapper}>
            <FacebookLoading borderRadius={10} height={104} width="100%" />
            <FacebookLoading borderRadius={10} height={104} width="100%" />
            <FacebookLoading borderRadius={10} height={104} width="100%" />
        </div>
    );

    const {
        usersAvailable,
        appProfileAvailable,
        envelopesAvailable,
        donationsAvailable,
    } = useMemo(
        () => ({
            usersAvailable: accessibleFeaturesByRole[userRole]?.includes(
                Features.SETTINGS_USERS,
            ),
            appProfileAvailable: accessibleFeaturesByRole[userRole]?.includes(
                Features.SETTINGS_APP_PROFILE,
            ),
            envelopesAvailable: accessibleFeaturesByRole[userRole]?.includes(
                Features.SETTINGS_ENVELOPES,
            ),
            donationsAvailable: accessibleFeaturesByRole[userRole]?.includes(
                Features.DONATIONS,
            ),
        }),
        [userRole],
    );

    const { PATH } = useGasRouterContext();
    const navigate = useNavigate();
    const backToSettings = React.useCallback(() => {
        navigate(PATH.SETTINGS.ROOT);
    }, [navigate, PATH.SETTINGS.ROOT]);

    if (isLoading) return Loader;
    if (campuses?.length === 0) return <EmptyList />;

    return (
        <>
            <GivelifyBreadcrumbs
                options={[
                    { label: settings, onClick: backToSettings },
                    { label: campusesText },
                ]}
            />
            {campuses?.length > 0 && (
                <>
                    {isEditable && (
                        <div className={header}>
                            <GivelifyLabel
                                className="text"
                                text={title}
                                variant="body1"
                            />
                            <GivelifyButton
                                className="button"
                                data-testid="add-campus"
                                onClick={() => setShowAddCampusModal(true)}
                                text={addCampus}
                                variant="secondary"
                            />
                        </div>
                    )}
                    <div className={wrapper}>
                        {campuses.map((campus) => (
                            <ListItem
                                key={campus.id}
                                appProfileAvailable={appProfileAvailable}
                                donationsAvailable={donationsAvailable}
                                envelopesAvailable={envelopesAvailable}
                                item={campus}
                                usersAvailable={usersAvailable}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default CampusList;
