import { TFunction } from 'i18next';
import { LEGACY_REPORT_TYPE } from '../GenerateReports/legacy/models';

export enum REPORT {
    ALL_REPORT = 'ALL_REPORT',
    DE_DONATIONS = 'DONATIONS',
    BANK_DEPOSITS = 'BANK_DEPOSITS',
    DONATIONS_BY_ENVELOPE = 'DONATIONS_BY_ENVELOPE',
    DONATIONS_BY_DONOR = 'DONATIONS_BY_DONOR',
    LR_DONATIONS = 'DONATIONS_LEGACY',
    DISBURSEMENTS = 'DISBURSEMENTS',
    DONORS = 'DONORS',
}

export const reportOptions = [
    REPORT.ALL_REPORT,
    REPORT.DE_DONATIONS,
    REPORT.BANK_DEPOSITS,
    REPORT.DONATIONS_BY_ENVELOPE,
    REPORT.DONATIONS_BY_DONOR,
    REPORT.LR_DONATIONS,
    REPORT.DISBURSEMENTS,
    REPORT.DONORS,
];

export const reportFilter = (value: REPORT): string => {
    let result = '';
    switch (value) {
        case REPORT.DE_DONATIONS:
            result += '&reportType=donations';
            break;
        case REPORT.BANK_DEPOSITS:
            result += '&reportType=bankDeposits';
            break;
        case REPORT.DONATIONS_BY_DONOR:
            result += '&reportType=donationsByDonors';
            break;
        case REPORT.DONATIONS_BY_ENVELOPE:
            result += '&reportType=donationsByEnvelopes';
            break;
        case REPORT.LR_DONATIONS:
            result += '&reportType=lrDonations';
            break;
        case REPORT.DISBURSEMENTS:
            result += '&reportType=disbursements';
            break;
        case REPORT.DONORS:
            result += '&reportType=donors';
            break;
        case REPORT.ALL_REPORT:
            result += '&reportType=all';
            break;
        default:
            break;
    }
    return result;
};

export const toReportTypeStringText = (value: REPORT, t: TFunction): string => {
    switch (value) {
        case REPORT.ALL_REPORT:
            return t('reports.item.allTypes');
        case REPORT.DE_DONATIONS:
            return t('reports.item.donations');
        case REPORT.BANK_DEPOSITS:
            return t('reports.item.bankDeposits');
        case REPORT.DONATIONS_BY_DONOR:
            return t('reports.item.quickbooksDonor');
        case REPORT.DONATIONS_BY_ENVELOPE:
            return t('reports.item.quickbooksEnvelope.church');
        case REPORT.LR_DONATIONS:
            return t('reports.item.donationsLegacy');
        case REPORT.DISBURSEMENTS:
            return t('reports.item.disbursements');
        case REPORT.DONORS:
            return t('reports.item.donors');
    }
};

export const reportName = (value: string, t: TFunction, extra?: string) => {
    switch (value) {
        case 'DONATIONS':
            return t('reports.item.donationsLegacy');
        case 'RECONCILIATION':
            return t('reports.item.bankDeposits');
        case 'DONORS':
            return t('reports.item.donors');
        case 'IMPORT_FRIENDLY':
            return t('reports.item.donationsImportFriendly');
        case 'QUICKBOOKS_DONORS':
            return t('reports.item.quickbooksDonor');
        case 'QUICKBOOKS_ENVELOPES':
            return t('reports.item.quickbooksEnvelope.church');
        case 'DISBURSEMENTS':
            return t('reports.item.disbursements');
        case LEGACY_REPORT_TYPE.donations:
            return extra
                ? `${t(
                      'reports.legacyReportsDashboard.item.typeDonationsNew',
                  )} (${extra})`
                : t('reports.legacyReportsDashboard.item.typeDonations');
        case LEGACY_REPORT_TYPE.disbursments:
            return t('reports.legacyReportsDashboard.item.typeDisbursements');
        case LEGACY_REPORT_TYPE.donors:
            return t('reports.legacyReportsDashboard.item.typeDonors');
        case LEGACY_REPORT_TYPE['by-envelope']:
            return t(
                'reports.legacyReportsDashboard.item.typeQuickBooksEnvelope',
            );
        case LEGACY_REPORT_TYPE['by-donor']:
            return t('reports.legacyReportsDashboard.item.typeQuickBooksDonor');
        case LEGACY_REPORT_TYPE['import-friendly']:
            return t('reports.legacyReportsDashboard.item.typeImportFriendly');
    }
};
