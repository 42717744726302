import React from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { DonationRow } from 'api/models';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { PaginatedResponse } from 'types/paginationTypes';
import { mapDonationRowToDonationItemRowProps } from '../../../utils/DonationsRowUtils';
import RefundHeaderRow from '../RefundHeaderRow';
import RefundItemRowDesktop from './RefundItemRow/RefundItemRowDesktop';
import RefundItemRowMobile from './RefundItemRow/RefundItemRowMobile';

interface RefundsListProps {
    data: PaginatedResponse<DonationRow>;
}

const useStyles = makeStyles((theme: Theme) => ({
    mobileListWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    divider: {
        height: 1,
        width: '100%',
        margin: 0,
        backgroundColor: theme.colors.grey200,
    },
}));

const RefundsList: React.FC<RefundsListProps> = ({ data }) => {
    const isMobile = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down('xs'),
    );

    const { mobileListWrapper, divider } = useStyles();

    const doneeName = useSelector(
        (state: AppState) => state.User.user.fullName,
    );

    if (isMobile)
        return (
            <div className={mobileListWrapper}>
                {data.data.map((r, i) => {
                    const data = mapDonationRowToDonationItemRowProps(r);
                    return data && data.id ? (
                        <React.Fragment key={data.id}>
                            {i !== 0 && <div className={divider} />}
                            <RefundItemRowMobile
                                {...data}
                                doneeName={doneeName}
                            />
                        </React.Fragment>
                    ) : null;
                })}
            </div>
        );

    return (
        <>
            <RefundHeaderRow />
            {data.data.map((r) => {
                const data = mapDonationRowToDonationItemRowProps(r);
                return data && data.id ? (
                    <div key={data.id}>
                        <RefundItemRowDesktop {...data} doneeName={doneeName} />
                    </div>
                ) : null;
            })}
        </>
    );
};

export default RefundsList;
