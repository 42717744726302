import React from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { RepDetailStyles } from './repDetailStyles';

interface RepSuccessProps {
    firstName: string;
    lastName: string;
    onReplaceClick: () => void;
}

export const RepSuccess: React.FC<RepSuccessProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newRep: t('pages.settings.bank-info.customize-rep-tab.new-rep'),
            success: t('pages.settings.bank-info.customize-rep-tab.success'),
            replace: t('pages.settings.bank-info.customize-rep-tab.replace'),
            caption: t('pages.settings.bank-info.customize-rep-tab.caption'),
            primaryRep: t('pages.settings.bank-info.customize-rep-tab.title'),
        }),
        [t],
    );
    const { firstName, lastName, onReplaceClick } = props;
    const { repSuccess } = RepDetailStyles();
    return (
        <div className={repSuccess}>
            <GivelifyLabel marginTop={40} text={copy.caption} />
            <div className="header">
                <GivelifyLabel text={copy.newRep} variant="heading3" />
                <GivelifyIcon
                    color="#73C997"
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="check-circle"
                />
                <GivelifyLabel className="submitted" text={copy.success} />
                <GivelifyButton
                    fontSize={16}
                    lineHeight={18}
                    marginLeft="auto"
                    onClick={onReplaceClick}
                    text={copy.replace}
                    variant="link"
                />
            </div>
            <GivelifyLabel
                marginBottom={6}
                marginTop={24}
                text={copy.primaryRep}
                variant="heading5"
            />
            <GivelifyLabel
                id="bank-primary-rep"
                marginBottom={16}
                text={`${firstName} ${lastName}`}
                variant="body1"
            />
        </div>
    );
};
