import { TimeFrameValue } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import dayjs from 'dayjs';
import { ThunkDispatch } from 'redux-thunk';
import { AppState, AppActions } from '../..';
import { getStatisticsThunk, getStatisticsBaseUrl } from '../thunk';
import {
    setDonationsCurrentLoading,
    setDonationsCurrentSuccess,
    setDonationsCurrentFailed,
} from './current/actions';
import {
    setDonationsPreviousLoading,
    setDonationsPreviousSuccess,
    setDonationsPreviousFailed,
} from './previous/actions';
import { DonationsTimeFrameData } from './types';

export const legacyStartAndEndDateQS = (
    timeFrame: TimeFrameValue,
    prefix: '?' | '&',
    onlyDate?: boolean,
): string => {
    const startDate = onlyDate
        ? dayjs(timeFrame.start)
        : dayjs.tz(timeFrame.start.format('YYYY-MM-DD')).startOf('day');
    const endDate = onlyDate
        ? dayjs(timeFrame.end)
        : dayjs.tz(timeFrame.end.format('YYYY-MM-DD')).endOf('day');
    if (timeFrame.selector !== 'lifetime') {
        return `${prefix}start=${
            onlyDate ? startDate.format('YYYY-MM-DD') : startDate.toISOString()
        }&end=${
            onlyDate ? endDate.format('YYYY-MM-DD') : endDate.toISOString()
        }`;
    }
    return prefix;
};

const donationsEndPoint = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): string =>
    getStatisticsBaseUrl('donations', doneeId) +
    legacyStartAndEndDateQS(timeFrame, '?');

const donationCurrentSuccess = (data: DonationsTimeFrameData) =>
    setDonationsCurrentSuccess(toCamelCase(data, { deep: true }));

export const getDonationsCurrentTotal = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setDonationsCurrentLoading,
        success: donationCurrentSuccess,
        error: setDonationsCurrentFailed,
        url: donationsEndPoint(timeFrame, doneeId),
    });

export const getDonationsPreviousTotal = (
    timeFrame: TimeFrameValue,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> =>
    getStatisticsThunk({
        loading: setDonationsPreviousLoading,
        success: setDonationsPreviousSuccess,
        error: setDonationsPreviousFailed,
        url: donationsEndPoint(timeFrame, doneeId),
    });
