import * as React from 'react';
import { dataOrUndefined } from '@givelify/givelify-ui';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import DefaultLoading from '../../components/DefaultLoading';
import Loading from '../components/Loading';
import View, { donationsErrorObject } from './view';

const TotalDonationsDataWrapper: React.FC = () => {
    const { currentDonations } = useSelector((state: AppState) => ({
        currentDonations: state.Dashboard.Donations.current,
    }));

    const totalDonationsCount = React.useMemo(() => {
        const data = dataOrUndefined(currentDonations);
        return data ? data.data.count : 0;
    }, [currentDonations]);

    return (
        <DefaultLoading
            error={donationsErrorObject}
            loading={Loading}
            requestState={currentDonations}
        >
            {({ data: { sum, average } }) => (
                <View
                    averageDonation={average}
                    sumDonationsCurrent={sum}
                    totalDonationsCount={totalDonationsCount}
                />
            )}
        </DefaultLoading>
    );
};

export default TotalDonationsDataWrapper;
