import React, { useMemo, useState, useEffect } from 'react';
import { GivelifyLabel, isSucceeded } from '@givelify/givelify-ui';
import { PAGE_NAME } from '@givelify/utils';
import { Grid, Paper, Radio } from '@material-ui/core';
import permissionTypes from 'constants/permissionTypes';
import { replace } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import permissionsByPath from 'utils/permissionsByPath';
import { ErrorText } from '../../../components/ErrorText';
import QuickGiveMobilePreview from '../../../components/mobilePreviews/QuickGiveMobilePreview';
import LoadingBar from '../../../components/system/LoadingBar';
import { useInvokeApi } from '../../../hooks/useInvokeApi';
import { AppState } from '../../../store';
import HandleServerErrorText from '../../../theme/components/HandleServerErrorText';
import SettingsPage from '../SettingsPage';
import { settingsStyle } from '../style';
import { quickGiveStyle } from './style';

const quickGiveTypeDefault = 'Default';
const qucikGiveTypeChai = 'Chai';
const quickGiveEndpoint = '/donees/{doneeId}/quickgive';

const QuickGive: React.FC = () => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.quick-give.page-title'),
            caption: t('pages.settings.quick-give.caption'),
            descriptionLong: t('pages.settings.quick-give.description-long'),
            descriptionShort: t('pages.settings.quick-give.description-short'),
            defualtTitle: t('pages.settings.quick-give.default-title'),
            defaultCaption: t('pages.settings.quick-give.default-caption'),
            chaiTitle: t('pages.settings.quick-give.chai-title'),
            chaiCaption: t('pages.settings.quick-give.chai-caption'),
            other: t('pages.settings.quick-give.other'),
            selectGift: t('pages.settings.quick-give.select-gift'),
            pageLoadFail: t('labels.page-load-fail'),
        }),
        [t],
    );

    const { user, doneeId, doneeName, imageUrl } = useSelector(
        (state: AppState) => ({
            user: state.User.user,
            doneeId: state.Donee.donee.id,
            doneeName: state.Donee.donee.name,
            imageUrl: state.Donee.donee.photo,
        }),
    );

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.QUICK_GIVE][user?.role] ===
        permissionTypes.FULL_ACCESS;

    const {
        quickGiveContainer,
        quickGiveEditorSide,
        quickGivePreviewSide,
        quickGiveEditorContainer,
        quickGiveOptionsContainer,
        quickGiveOptionsContainerSelected,
        quickGiveOptionsTitle,
        quickGiveOptionsCaption,
        quickGiveOptionsHolder,
        quickGiveOption,
        quickGiveOptionCheck,
    } = quickGiveStyle(hasFullAccess);
    const { descriptionBold } = settingsStyle();
    const quickGiveDefaultOptions = useMemo(
        () => [
            '$10',
            '$25',
            '$50',
            '$100',
            '$250',
            '$500',
            '$1,000',
            '$2,500',
            copy.other,
        ],
        [copy],
    );
    const quickGiveChaiOptions = useMemo(
        () => [
            '$18',
            '$36',
            '$72',
            '$180',
            '$450',
            '$900',
            '$1,800',
            '$3,600',
            copy.other,
        ],
        [copy],
    );
    const [selectedValue, setSelectedValue] = useState(quickGiveTypeDefault);
    const [getQuickGiveState, getQuickGiveRequest] = useInvokeApi<{
        data: {
            quickgive_id: number;
        };
    }>();
    const [updateQuickGiveState, updateQuickGiveRequest] =
        useInvokeApi<unknown>();
    useEffect(() => {
        getQuickGiveRequest(
            'GET',
            replace(quickGiveEndpoint, '{doneeId}', doneeId.toString()),
        );
    }, [getQuickGiveRequest, doneeId]);
    const onDefaultOptionSelect = () => {
        if (selectedValue !== quickGiveTypeDefault) {
            setSelectedValue(quickGiveTypeDefault);
            updateQuickGiveRequest(
                'PUT',
                replace(quickGiveEndpoint, '{doneeId}', doneeId.toString()),
                {
                    quickgive_id: 1,
                },
            );
        }
    };

    const onChaiOptionSelect = () => {
        if (!hasFullAccess) return;
        if (selectedValue !== qucikGiveTypeChai) {
            setSelectedValue(qucikGiveTypeChai);
            updateQuickGiveRequest(
                'PUT',
                replace(quickGiveEndpoint, '{doneeId}', doneeId.toString()),
                {
                    quickgive_id: 3,
                },
            );
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === quickGiveTypeDefault)
            onDefaultOptionSelect();
        else onChaiOptionSelect();
    };

    useEffect(() => {
        if (
            isSucceeded(getQuickGiveState) &&
            getQuickGiveState.data.data.quickgive_id === 3
        ) {
            setSelectedValue(qucikGiveTypeChai);
        }
    }, [getQuickGiveState]);

    if (
        getQuickGiveState.type === 'REQUEST_INIT' ||
        getQuickGiveState.type === 'REQUEST_START'
    ) {
        return <LoadingBar show />;
    }

    return (
        <SettingsPage
            trackPageVisit
            useDivider
            description={copy.caption}
            pageName={PAGE_NAME.SettingsQuickGive}
            title={copy.title}
        >
            {getQuickGiveState.type === 'REQUEST_ERROR' ? (
                <ErrorText text={copy.pageLoadFail} />
            ) : (
                <>
                    <HandleServerErrorText
                        requestState={updateQuickGiveState}
                    />
                    <div className={quickGiveContainer}>
                        <div className={quickGiveEditorSide}>
                            <div>
                                <GivelifyLabel
                                    bold
                                    className={descriptionBold}
                                    text={copy.descriptionShort}
                                    variant="heading5"
                                />
                                <GivelifyLabel
                                    text={copy.descriptionLong}
                                    variant="body1"
                                />
                            </div>
                            <div className={quickGiveEditorContainer}>
                                <div
                                    className={
                                        selectedValue === quickGiveTypeDefault
                                            ? quickGiveOptionsContainerSelected
                                            : quickGiveOptionsContainer
                                    }
                                    data-testid="defaultOptionSelect"
                                    onClick={onDefaultOptionSelect}
                                >
                                    <GivelifyLabel
                                        bold
                                        className={quickGiveOptionsTitle}
                                        text={copy.defualtTitle}
                                        variant="heading5"
                                    />
                                    <GivelifyLabel
                                        className={quickGiveOptionsCaption}
                                        text={copy.defaultCaption}
                                        variant="body1"
                                    />
                                    <Grid
                                        container
                                        className={quickGiveOptionsHolder}
                                        justifyContent="center"
                                        spacing={3}
                                    >
                                        {quickGiveDefaultOptions.map(
                                            (value) => (
                                                <Grid
                                                    key={'default_' + value}
                                                    item
                                                    xs={4}
                                                >
                                                    <Paper
                                                        className={
                                                            quickGiveOption
                                                        }
                                                    >
                                                        <GivelifyLabel
                                                            text={value}
                                                            variant="caption1"
                                                        />
                                                    </Paper>
                                                </Grid>
                                            ),
                                        )}
                                    </Grid>
                                    {hasFullAccess && (
                                        <div
                                            className={quickGiveOptionCheck}
                                            data-testid="radio-select"
                                        >
                                            <Radio
                                                checked={
                                                    selectedValue ===
                                                    quickGiveTypeDefault
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'QuickGive Default',
                                                }}
                                                name="quick-give-default"
                                                onChange={handleChange}
                                                value={quickGiveTypeDefault}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={
                                        selectedValue === qucikGiveTypeChai
                                            ? quickGiveOptionsContainerSelected
                                            : quickGiveOptionsContainer
                                    }
                                    data-testid="chaiOptionSelect"
                                    onClick={onChaiOptionSelect}
                                >
                                    <GivelifyLabel
                                        bold
                                        className={quickGiveOptionsTitle}
                                        text={copy.chaiTitle}
                                        variant="heading5"
                                    />
                                    <GivelifyLabel
                                        className={quickGiveOptionsCaption}
                                        text={copy.chaiCaption}
                                        variant="body1"
                                    />
                                    <Grid
                                        container
                                        className={quickGiveOptionsHolder}
                                        justifyContent="center"
                                        spacing={3}
                                    >
                                        {quickGiveChaiOptions.map((value) => (
                                            <Grid
                                                key={'chai' + value}
                                                item
                                                xs={4}
                                            >
                                                <Paper
                                                    className={quickGiveOption}
                                                >
                                                    <GivelifyLabel
                                                        text={value}
                                                        variant="caption1"
                                                    />
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {hasFullAccess && (
                                        <div
                                            className={quickGiveOptionCheck}
                                            data-testid="radio-select2"
                                        >
                                            <Radio
                                                checked={
                                                    selectedValue ===
                                                    qucikGiveTypeChai
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'QuickGive Chai',
                                                }}
                                                name="quick-give-chai"
                                                onChange={handleChange}
                                                value={qucikGiveTypeChai}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={quickGivePreviewSide}>
                            <QuickGiveMobilePreview
                                contentTitle={copy.selectGift}
                                doneeName={doneeName}
                                imageUrl={imageUrl}
                                options={
                                    selectedValue === quickGiveTypeDefault
                                        ? quickGiveDefaultOptions
                                        : quickGiveChaiOptions
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </SettingsPage>
    );
};

export default QuickGive;
