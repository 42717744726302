import { RequestState } from '@givelify/utils';
import { GetLifeTimeDonationsResponse } from '../../../api/services/responses/widgets';

export type LifeTimeState = RequestState<GetLifeTimeDonationsResponse>;

export const SET_LIFE_TIME = 'SET_LIFE_TIME';
export type SetLifeTime = {
    type: typeof SET_LIFE_TIME;
    payload: {
        doneeId: number;
        response: RequestState<GetLifeTimeDonationsResponse>;
    };
};

export type LifeTimeActions = SetLifeTime;
