import React from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    GivelifyNotificationProps,
} from '@givelify/ui';
import {
    TimeFrameValue,
    isFailed,
    isSucceeded,
    toISODate,
    useApiRequest,
} from '@givelify/utils';
import { GenerateReportResponse } from 'api/services/responses/reports';
import { getReportsService } from 'api/utils/serviceProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'router/routes';
import { webConfig } from 'webConfig';

type ExportDonationsProps = {
    timeFrame: TimeFrameValue;
    donorId: string;
    doneeId: number;
    disabled: boolean;
    setNotification: (notification: GivelifyNotificationProps) => void;
};

const ExportDonationsComponent: React.FC<ExportDonationsProps> = ({
    timeFrame,
    donorId,
    doneeId,
    disabled,
    setNotification,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            generateReportButton: t('pages.donor_details.generateReportButton'),
            generateReportMessage1: t(
                'pages.donor_details.generateReportMessage1',
            ),
            generateReportMessage2: t(
                'pages.donor_details.generateReportMessage2',
            ),
            generateReportMessageError1: t(
                'pages.donor_details.generateReportMessageError1',
            ),
            generateReportMessageError2: t(
                'pages.donor_details.generateReportMessageError2',
            ),
            generateReportMessageError3: t(
                'pages.donor_details.generateReportMessageError3',
            ),
        }),
        [t],
    );
    const reportsService = getReportsService();

    const [postReportsRequest, makePostReportsRequest] =
        useApiRequest<GenerateReportResponse>();

    const navigateToHistory = React.useCallback(
        (
            event: React.MouseEvent<HTMLAnchorElement> &
                React.MouseEvent<HTMLSpanElement>,
        ) => {
            event.preventDefault();
            navigate(PATH.REPORTS.REPORT_HISTORY);
            setNotification(null);
        },
        [navigate, setNotification],
    );

    const showSuccessNotification = React.useCallback(() => {
        setNotification({
            text: (
                <GivelifyLabel variant="body1B">
                    {`${copy.generateReportMessage1} `}
                    <GivelifyLink
                        href={PATH.REPORTS.REPORT_HISTORY}
                        onClick={navigateToHistory}
                        text={copy.generateReportMessage2}
                        variant="body1B"
                    />
                </GivelifyLabel>
            ),
            showDismiss: true,
            variant: 'info',
        });
    }, [
        setNotification,
        copy.generateReportMessage1,
        copy.generateReportMessage2,
        navigateToHistory,
    ]);

    const showErrorNotification = React.useCallback(() => {
        setNotification({
            text: (
                <GivelifyLabel variant="body1B">
                    {`${copy.generateReportMessageError1} `}
                    <GivelifyLink
                        href={webConfig.supportUrl}
                        target="_blank"
                        text={copy.generateReportMessageError2}
                        variant="body1B"
                    />
                    {` ${copy.generateReportMessageError3} `}
                </GivelifyLabel>
            ),
            variant: 'error',
        });
    }, [
        setNotification,
        copy.generateReportMessageError1,
        copy.generateReportMessageError2,
        copy.generateReportMessageError3,
    ]);

    React.useEffect(() => {
        if (isSucceeded(postReportsRequest)) {
            showSuccessNotification();
        } else if (isFailed(postReportsRequest)) {
            showErrorNotification();
        }
    }, [postReportsRequest, showErrorNotification, showSuccessNotification]);

    const exportDonationData = React.useCallback(() => {
        void makePostReportsRequest(
            reportsService.generateDonorLifetimeReport({
                doneeId,
                donorId: Number(donorId),
                startDate: toISODate(timeFrame.start),
                endDate: toISODate(timeFrame.end),
            }),
        );
    }, [doneeId, donorId, makePostReportsRequest, reportsService, timeFrame]);

    return (
        <GivelifyButton
            disabled={disabled}
            isLoading={postReportsRequest.type === 'REQUEST_START'}
            name="Export Donation Data"
            onClick={exportDonationData}
            size="large"
            text={copy.generateReportButton}
        />
    );
};

export const ExportDonations = React.memo(ExportDonationsComponent);
