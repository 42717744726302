import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const aboutStyle = makeStyles((theme: Theme) => {
    return createStyles({
        page: {
            padding: '12px 0',
        },
        section: {
            position: 'relative',
            margin: '20px 0',
            '& .MuiInputBase-root input': {
                border: 'none',
            },
            '& input:valid + fieldset': {
                border: '1px solid #ADADAD',
            },
        },
        sectionContent: {
            flexGrow: 1,
        },
        subtitle: {
            lineHeight: '20px',
            marginBottom: 16,
            paddingRight: '65px',
            whiteSpace: 'normal',
        },
        body: {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
        },
        editButtonContainer: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 0,
            '& button': {
                height: 20,
            },
        },
        submitButtonsHolder: {
            marginTop: 24,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& button': {
                minWidth: 140,
                height: 48,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                '& button': {
                    width: '100%',
                    height: 40,
                },
            },
        },
        submitButton: {
            marginLeft: 12,
        },
    });
});
