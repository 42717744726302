import { DesignTokens } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    progressContainer: {
        width: '100%',
        position: 'relative',
    },
    progressHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    progressHeaderLeft: {
        marginRight: 'auto',
    },
    progressHeaderRight: {
        marginLeft: 'auto',
    },
    progressPrimary: {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryAccent,
    },
    progressPrimaryDisabled: {
        backgroundColor: DesignTokens.color.backgroundComponentQuaternary,
    },
    progressSecondary: {
        backgroundColor: DesignTokens.color.backgroundComponentTertiaryAccent,
    },
    progressSecondaryDisabled: {
        backgroundColor: DesignTokens.color.backgroundComponentTertiary,
    },
    root: {
        borderRadius: 4,
    },
    bar: {
        borderRadius: 4,
    },
}));
