import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="29"
        viewBox="0 0 25 29"
        width="25"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3791_39239)">
            <path
                d="M19.7916 11.9036H15.625V19.0465H9.37498V11.9036H5.20831L12.5 3.57031L19.7916 11.9036ZM5.20831 21.4275V23.8084H19.7916V21.4275H5.20831Z"
                fill="#5F47F6"
            />
        </g>
        <defs>
            <clipPath id="clip0_3791_39239">
                <rect fill="white" height="28.5714" width="25" />
            </clipPath>
        </defs>
    </svg>
);

export const UploadIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgUpload}
        fontSize={fontSize}
    />
);
