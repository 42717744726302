import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

const blueColor = '#cce2ef';
export const useFilterModalStyle = makeStyles((theme: Theme) => ({
    filterTop: {
        padding: theme.spacing(3, 4),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '3px solid ' + blueColor,
    },
    filterLink: {
        color: theme.palette.primary.main,
    },
    buttonText: {
        paddingLeft: theme.spacing(2),
        fontWeight: 'bold',
    },
    iconButton: {
        fontSize: '16px',
        borderRadius: '3px',
    },
    title: {
        paddingLeft: theme.spacing(4),
        fontSize: '16px',
        fontWeight: 'bold',
    },
    dialog: {
        '& .MuiExpansionPanel-root:before': {
            opacity: 0,
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-scrollPaper': {
                alignItems: 'flex-end',
            },

            '& .MuiDialog-paperWidthSm': {
                maxWidth: '100%',
                width: '100%',
                margin: 'unset',
                height: 'calc(100vh - 50px)',
            },
        },
    },
    divider: {
        border: '2px solid #D7EBF4',
        width: '100%',
        marginBottom: 'unset',
        marginTop: 'unset',
    },
    checkmarkRadio: {
        padding: '12px 24px',
        borderBottom: `1px solid ${GivelifyColorPalette.grey500}`,
        justifyContent: 'space-between',
        '& .MuiRadio-checked': {
            background: 'hotpink',
        },
        '& .MuiRadio-root': {
            order: 1,
            marginLeft: 'auto',
            padding: 0,
        },
        '&:last-child': {
            borderBottom: 0,
        },
    },
    selectedLabel: {
        background: '#F0F8FD',
    },
    checkIcon: {
        fontSize: 14,
    },
    radioGroup: {
        width: '100%',
    },
    expansionTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    expansionDetails: {
        padding: 0,
        '& .MuiFormGroup-root': {
            width: '100%',
        },
    },
    expansionPanel: {
        borderBottom: '2px solid ' + blueColor,
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
        },
        '& .MuiExpansionPanelSummary-root': {
            padding: theme.spacing(0, 4),
            '&.Mui-expanded': {
                minHeight: 0,
            },
            '& .MuiExpansionPanelSummary-content': {
                '&.Mui-expanded': {
                    margin: '12px 0',
                },
            },
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiExpansionPanelDetails-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '14px',
        },
    },
    expandIcon: {
        fontSize: 14,
    },
}));
