import parsePhoneNumber from 'libphonenumber-js/max';

const PHONE_FORMATTER_COUNTRY = 'US' as const;

export const phoneFormatter = (value: string): string | undefined => {
    if (value.length < 4) return value;

    return parsePhoneNumber(value, PHONE_FORMATTER_COUNTRY)?.formatNational();
};

export const phoneValidation = (value: string | undefined): boolean => {
    if (!value || value.length < 4) return false;

    return parsePhoneNumber(value, PHONE_FORMATTER_COUNTRY)?.isValid() || false;
};
