import React from 'react';
import { OptionItem, RequestState, useApiRequest } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { OrganizationType, TaxIdInfo } from '../@types/assets/onboarding';
import { GetDenominationsResponse } from '../api/responses/denomination/GetDenominationsResponse';
import { DoneePatchResponse } from '../api/responses/donee/DoneePatchResponse';
import { updateTaxIdInfoRequest } from '../api/services/donee/DoneeService';
import { I18N_NAMESPACE } from '../consts';
import { FullWidthModalOnboarding } from '../modal';
import { TaxIdPage, TaxIdPageRef } from './TaxIdPage';

interface TaxIdModalProps {
    isOpen: boolean;
    doneeId: number;
    organizationName: string;
    organizationType: OrganizationType;
    data: TaxIdInfo;
    accountOwnerFullName: string;
    onCancel: () => unknown;
    onSubmit: (data: TaxIdInfo) => unknown;
    getDenominationsRequestState: RequestState<GetDenominationsResponse>;
}

const TaxIdModal: React.FC<TaxIdModalProps> = ({
    data,
    isOpen,
    onSubmit,
    organizationName,
    organizationType,
    onCancel,
    doneeId,
    getDenominationsRequestState,
    accountOwnerFullName,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { errorMessage, taxModalTitle, defaultModalRetryText } =
        React.useMemo(
            () => ({
                errorMessage: t('errors.generic'),
                taxModalTitle: t('taxIdPage.title'),
                defaultModalRetryText: t('labels.defaultModalRetryText'),
            }),
            [t],
        );
    const taxIdPageRef = React.useRef<TaxIdPageRef>(null);

    const [denominationOptions, setDenominationOptions] = React.useState<
        OptionItem[]
    >([]);
    const [taxIdIsValid, setTaxIdIsValid] = React.useState(false);
    const [updateTaxIdRequestState, makeUpdateTaxIdRequest] =
        useApiRequest<DoneePatchResponse>();

    const onTaxIdSubmit = React.useCallback(() => {
        if (taxIdPageRef.current) {
            const data = taxIdPageRef.current.submit();
            if (data) {
                if (updateTaxIdRequestState.type !== 'REQUEST_START') {
                    makeUpdateTaxIdRequest(
                        updateTaxIdInfoRequest(doneeId, {
                            ein: data.taxId,
                            congregationSize: data.congregationSize,
                            denominationId: data.religiousAffiliation
                                ? parseInt(data.religiousAffiliation)
                                : undefined,
                            established: parseInt(data.organizationYear),
                            einFile: data.einFile,
                        }),
                    );
                }
            }
        }
    }, [
        taxIdPageRef,
        doneeId,
        updateTaxIdRequestState.type,
        makeUpdateTaxIdRequest,
    ]);
    React.useEffect(() => {
        if (updateTaxIdRequestState.type === 'REQUEST_SUCCESS') {
            if (onSubmit) {
                onSubmit({
                    ...data,
                    organizationYear:
                        updateTaxIdRequestState.data.established?.toString() ||
                        data.organizationYear,
                    congregationSize:
                        updateTaxIdRequestState.data.congregationSize ||
                        data.congregationSize,
                    religiousAffiliation:
                        updateTaxIdRequestState.data.denomination ||
                        data.religiousAffiliation,
                    status: 'in_progress',
                });
            }
        }
        //eslint-disable-next-line
    }, [updateTaxIdRequestState, onSubmit]);
    React.useEffect(() => {
        if (getDenominationsRequestState.type === 'REQUEST_SUCCESS') {
            const options: OptionItem[] =
                getDenominationsRequestState.data.data.map((item) => {
                    return {
                        value: item.id.toString(),
                        label: item.name,
                    };
                });
            setDenominationOptions(options);
        }
    }, [getDenominationsRequestState, setDenominationOptions]);
    return (
        <FullWidthModalOnboarding
            useBackArrow
            errorMessage={
                updateTaxIdRequestState.type === 'REQUEST_ERROR'
                    ? updateTaxIdRequestState.error.message || errorMessage
                    : undefined
            }
            footer={{
                onCancel,
                onSubmit: onTaxIdSubmit,
                disableSubmit: !taxIdIsValid,
                isLoading: updateTaxIdRequestState.type === 'REQUEST_START',
            }}
            heading={taxModalTitle}
            name="Tax Id Modal"
            onClose={onCancel}
            open={isOpen && updateTaxIdRequestState.type !== 'REQUEST_SUCCESS'}
            retryMessage={
                data.status === 'require_information'
                    ? data.retryMessage || defaultModalRetryText
                    : undefined
            }
            steps={undefined}
        >
            <TaxIdPage
                ref={taxIdPageRef}
                accountOwnerFullName={accountOwnerFullName}
                data={data}
                denominationOptions={denominationOptions}
                onValidation={(isValid) => {
                    setTaxIdIsValid(isValid);
                }}
                organizationName={organizationName}
                organizationType={organizationType}
            />
        </FullWidthModalOnboarding>
    );
};

export default React.memo(TaxIdModal);
