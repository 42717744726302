import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: theme.spacing(8),
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  illustration: {
    margin: '41px auto 19px auto',
  },
  sectionTitle: {
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
  },
  divider: {
    marginTop: 32,
    marginBottom: 32,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
}));
