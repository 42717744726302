import { SET_USER } from './types';

const SET_ACCESS_TOKEN = 'ACCESS_TOKEN';
const USER_EMAIL = 'USER_EMAIL';

export const removeAccessTokenLocalStorage = (): void =>
    localStorage.removeItem(SET_ACCESS_TOKEN);

export const setAccessTokenLocalStorage = (accessToken: string): void =>
    localStorage.setItem(SET_ACCESS_TOKEN, JSON.stringify(accessToken));

export const getAccessTokenLocalStorage = (): string | undefined =>
    JSON.parse(localStorage.getItem(SET_ACCESS_TOKEN));

export const setUserEmailLocalStorage = (email: string) =>
    localStorage.setItem(USER_EMAIL, email);

export const getUserEmailLocalStorage = (): string | undefined =>
    localStorage.getItem(USER_EMAIL);

export const clearLocalStorage = () => {
    let userEmail = localStorage.getItem(USER_EMAIL);
    if (!userEmail) {
        try {
            // As a fallback we try read user email from an old storage item
            // This code will be executed only once
            // when user first logs in after release
            const user = JSON.parse(localStorage.getItem(SET_USER));
            if (user) userEmail = user?.email;
            // eslint-disable-next-line no-empty
        } catch {}
    }

    localStorage.removeItem(SET_ACCESS_TOKEN);
    localStorage.removeItem(SET_USER)

    // we keep user email in local storage so we can fill text field on login screen
    if (userEmail) {
        localStorage.setItem(USER_EMAIL, userEmail);
    }
};
