import React from 'react';
import { TimeFrameValue } from '@givelify/utils';
import { Skeleton } from '@mui/material';
import { Pagination } from 'api/models';
import { DataTablePagination } from 'components';
import { Donation } from 'pages/donors/DonorProfile';
import { useTranslation } from 'react-i18next';
import { Donor } from 'types/donorTypes';

import { DonationMessage } from '../donationMessage';
import { TableEmptyState } from '../tableEmptyState';

interface PaginatedMemosProps {
    donor: Donor;
    donations: Donation[];
    pagination: Partial<Pagination>;
    timeFrame: TimeFrameValue;
    isLoading: boolean;
    pageChange: (pageNumber: number) => void;
}

const DonorDonationMessageHistoryTableComponent: React.FC<
    PaginatedMemosProps
> = ({ donations, pagination, donor, isLoading, timeFrame, pageChange }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            noMessages: t('pages.donor_details.noMessages'),
        }),
        [t],
    );
    return isLoading ? (
        <Skeleton
            animation="wave"
            height="50vh"
            variant="rectangular"
            width="100%"
        />
    ) : (
        <>
            {!donations.length ? (
                <TableEmptyState text={copy.noMessages} timeFrame={timeFrame} />
            ) : null}

            {donations.map((donation) => (
                <DonationMessage
                    key={donation.id}
                    donation={donation}
                    donor={donor}
                    note={donation.note}
                />
            ))}
            <DataTablePagination
                from={pagination.from}
                maxPages={pagination.totalPages}
                onPageChange={pageChange}
                pageNumber={pagination.currentPage}
                resultCount={pagination.total}
                to={pagination.to}
            />
        </>
    );
};

export const DonorDonationMessageHistoryTable = React.memo(
    DonorDonationMessageHistoryTableComponent,
);
