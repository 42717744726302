import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgMore = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>more</title>
        <path
            clipRule="evenodd"
            d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125ZM17.25 12C17.25 12.6213 16.7463 13.125 16.125 13.125C15.5037 13.125 15 12.6213 15 12C15 11.3787 15.5037 10.875 16.125 10.875C16.7463 10.875 17.25 11.3787 17.25 12ZM7.875 13.125C8.49632 13.125 9 12.6213 9 12C9 11.3787 8.49632 10.875 7.875 10.875C7.25368 10.875 6.75 11.3787 6.75 12C6.75 12.6213 7.25368 13.125 7.875 13.125Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const MoreIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgMore} fontSize={fontSize} />
);
