import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgReminderColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="39"
        width="38"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 38 39"
    >
        <circle
            cx="19"
            cy="19.5"
            fill="#E5F8FF"
            r="17.8125"
            stroke="white"
            strokeWidth="1.1875"
        />
        <path
            clipRule="evenodd"
            d="M10.6946 11.1916C9.83652 12.0497 9.7624 13.3948 10.4722 14.337C11.3078 12.9619 12.4666 11.8051 13.8432 10.9717C12.9008 10.2593 11.5536 10.3326 10.6946 11.1916ZM9.41918 15.868C9.48973 15.9386 9.56224 16.0062 9.63655 16.0707C9.24305 17.1423 9.02825 18.3001 9.02825 19.5081C9.02825 22.2366 10.1242 24.7092 11.8998 26.5096L10.0495 28.3599C9.69732 28.7121 9.69732 29.2831 10.0495 29.6353C10.4017 29.9875 10.9727 29.9875 11.3249 29.6353L13.2819 27.6783C14.9007 28.8134 16.8724 29.4795 18.9997 29.4795C21.127 29.4795 23.0987 28.8133 24.7176 27.6782L26.6747 29.6353C27.0269 29.9875 27.5979 29.9875 27.9501 29.6353C28.3023 29.2831 28.3023 28.7121 27.9501 28.3599L26.0996 26.5095C27.8752 24.709 28.9711 22.2365 28.9711 19.5081C28.9711 18.2855 28.7511 17.1143 28.3484 16.032C28.4074 15.9792 28.4653 15.9246 28.5218 15.868C30.1654 14.2245 30.1654 11.5598 28.5218 9.91625C26.8783 8.27271 24.2136 8.27271 22.5701 9.91625C22.5029 9.98344 22.4384 10.0524 22.3766 10.123C21.322 9.74349 20.185 9.53667 18.9997 9.53667C17.7977 9.53667 16.6454 9.74933 15.5784 10.1391C15.5124 10.0629 15.4432 9.98853 15.371 9.91625C13.7274 8.27271 11.0627 8.27271 9.41918 9.91625C7.77564 11.5598 7.77564 14.2245 9.41918 15.868ZM27.5007 14.2937C28.1762 13.354 28.0914 12.0366 27.2465 11.1916C26.3953 10.3405 25.0649 10.2607 24.1239 10.9523C25.5001 11.7783 26.6606 12.927 27.5007 14.2937ZM27.3206 19.5081C27.3206 24.1036 23.5952 27.8291 18.9997 27.8291C14.4041 27.8291 10.6787 24.1036 10.6787 19.5081C10.6787 14.9125 14.4041 11.1871 18.9997 11.1871C23.5952 11.1871 27.3206 14.9125 27.3206 19.5081ZM19.9015 14.4463C19.9015 13.9482 19.4977 13.5445 18.9997 13.5445C18.5016 13.5445 18.0978 13.9482 18.0978 14.4463V19.5017C18.0978 19.7415 18.1933 19.9714 18.3631 20.1406L20.7381 22.507C21.0909 22.8586 21.6619 22.8575 22.0135 22.5047C22.3651 22.1519 22.364 21.5809 22.0112 21.2293L19.9015 19.1272V14.4463Z"
            fill="#0C769C"
            fillRule="evenodd"
        />
    </svg>
);

export const ReminderColoredIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgReminderColored}
        fontSize={fontSize}
    />
);
