import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDarkCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>dark-checkbox-checked</title>
        <rect fill="current" height="20" rx="2" width="20" x="2" y="2" />
        <path
            d="M7 11.7818L10.1621 15.5636L17 8"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
        />
    </svg>
);

export const DarkCheckboxCheckedIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDarkCheckboxChecked}
        fontSize={fontSize}
    />
);
