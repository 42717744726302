import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgBahamas = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="16" cy="16" fill="#06B6D5" r="16" />
        <mask
            height="32"
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            width="32"
            x="0"
            y="0"
        >
            <circle cx="16" cy="16" fill="white" r="16" />
        </mask>
        <g mask="url(#mask0)">
            <rect fill="#FFD85B" height="10" width="40" x="-4" y="11" />
            <path d="M16 16L-2.75 28.1244L-2.75 3.87564L16 16Z" fill="black" />
        </g>
    </svg>
);

export const BahamasIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgBahamas}
        fontSize={fontSize}
    />
);
