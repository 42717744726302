import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../types/const';

type Keys =
    | string[]
    | {
          [x in string]: string | string[];
      };

export const useTranslations = (area: string, trKeys: Keys) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const translateKeyArray = React.useCallback(
        (section: string, keys: string[]) => {
            return keys.reduce(
                (
                    accumulator: {
                        [key in (typeof keys)[number]]: string;
                    },
                    currentKey,
                ) => {
                    accumulator[currentKey] = t(`${section}.${currentKey}`);
                    return accumulator;
                },
                {},
            );
        },
        [t],
    );

    const translations = React.useMemo(() => {
        const translateKeyObject = (section: string, keys: Keys) => {
            if (Array.isArray(keys)) return translateKeyArray(section, keys);
            //TODO: needs refactoring
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: { [x: string]: any } = {};
            for (const [key, value] of Object.entries(trKeys)) {
                res[key] = Array.isArray(value)
                    ? translateKeyObject(`${area}.${key}`, value)
                    : t(`${section}.${value}`);
            }

            return res;
        };

        return translateKeyObject(area, trKeys);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translateKeyArray, area, trKeys]);

    return translations;
};
