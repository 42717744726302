import Config from '../config';
import { GiveNowButtonType } from './index';

export const I18N_NAMESPACE = 'fundraisingTools';

export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID || '356550661098841';

export const GIVELIFY_LOGO_URL = `${Config.imageBaseUrl}/logo.svg`;

export const SNACKBAR_TIMEOUT = 3000;

export const GIVENOW_BUTTON_URLS: { [x in GiveNowButtonType]: string } = {
  darkGive: `${Config.imageBaseUrl}/DarkGiveButton2xImg.png`,
  primaryGive: `${Config.imageBaseUrl}/PrimaryGiveButton2xImg.png`,
  lightGive: `${Config.imageBaseUrl}/LightGiveButton2xImg.png`,
};
