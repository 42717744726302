import React from 'react';
import {
    GivelifyDatePicker,
    GivelifyLabel,
    GivelifyRadio,
    GivelifyButton,
} from '@givelify/ui';
import { TimeFrameValue } from '@givelify/utils';
import { styled } from '@mui/material';
import { TimeFrameFilter } from 'components';
import dayjs from 'dayjs';
import {
    FORMAT,
    getFormatFilter,
} from 'pages/reports/utils/reportFormatFilterUtils';
import { useTranslation } from 'react-i18next';
import { useDoneeStartDate } from '../../../../../../hooks/useDoneeStartDate';
import { ReportFormProps } from './types';

const Container = styled('div')(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const InputRow = styled('div')(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const PickerRow = styled('div')(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
}));

const RadioRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(1),
}));

const FooterRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
}));

const PickerSlot = styled('div')(({ theme }) => ({
    flexGrow: 1,
}));

export const ReportForm: React.FC<ReportFormProps> = ({
    type,
    format,
    onFormatFileChange,
    hasFormatFile,
    onSubmit,
    isLoading,
    name,
    backToReports,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            startDateMessage: t(
                'pages.integrations_custom_report.start_date_message',
            ),
            endDateMessage: t(
                'pages.integrations_custom_report.end_date_message',
            ),
            startDateRequired: t(
                'pages.integrations_custom_report.startDateRequired',
            ),
            endDateRequired: t(
                'pages.integrations_custom_report.endDateRequired',
            ),
            invalidStartDate: t(
                'pages.integrations_custom_report.invalidStartDate',
            ),
            invalidEndDate: t(
                'pages.integrations_custom_report.invalidEndDate',
            ),
            selectDateRange: t('reports.text.selectDateRange'),
            to: t('reports.text.to'),
            fileFormat: t('reports.text.fileFormat'),
            generateReport: t('reports.text.generateReport'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );
    const [timeFrame, setTimeFrame] = React.useState<TimeFrameValue>({
        start: undefined,
        end: undefined,
        selector: 'custom',
    });
    const [startDateChanged, setStartDateChanged] = React.useState(false);
    const [endDateChanged, setEndDateChanged] = React.useState(false);

    const onChangeStartDate = React.useCallback((date: dayjs.Dayjs) => {
        setTimeFrame((cur) => {
            return {
                ...cur,
                start: date,
            };
        });
        setStartDateChanged(true);
    }, []);
    const onChangeEndDate = (date: dayjs.Dayjs) => {
        setTimeFrame((cur) => {
            return {
                ...cur,
                end: date,
            };
        });
        setEndDateChanged(true);
    };

    const { errorStartDate, errorEndDate } = React.useMemo(() => {
        let errorStartDate: string | undefined;
        let errorEndDate: string | undefined;

        if (timeFrame.selector !== 'custom') {
            return { errorStartDate, errorEndDate };
        }

        const startDateIsDate = timeFrame.start?.isValid();
        const endDateIsDate = timeFrame.end?.isValid();

        if (!startDateIsDate) {
            errorStartDate = copy.startDateRequired;
        }

        if (!endDateIsDate) {
            errorEndDate = copy.endDateRequired;
        }

        if (
            startDateIsDate &&
            endDateIsDate &&
            timeFrame.end.isBefore(timeFrame.start)
        ) {
            errorStartDate = copy.startDateMessage;
            errorEndDate = copy.endDateMessage;
        }

        return {
            errorStartDate,
            errorEndDate,
        };
    }, [
        copy.endDateMessage,
        copy.endDateRequired,
        copy.startDateMessage,
        copy.startDateRequired,
        timeFrame,
    ]);

    const minStartDate = dayjs.tz(useDoneeStartDate());
    const maxEndDate =
        name === 'Bank deposits'
            ? dayjs().tz().subtract(1, 'day')
            : dayjs().tz();

    const maxStartDate = React.useMemo(
        () => (timeFrame.end?.isValid() ? timeFrame.end : maxEndDate),
        [timeFrame.end, maxEndDate],
    );
    const endDateError = () => {
        const todayDate = dayjs().tz().startOf('day');
        if (
            name === 'Bank deposits' &&
            timeFrame.end?.startOf('day').isSameOrAfter(todayDate)
        ) {
            return true;
        } else if (todayDate.isBefore(timeFrame.end?.startOf('day'))) {
            return true;
        } else {
            return false;
        }
    };

    const minEndDate = React.useMemo(
        () => (timeFrame.start?.isValid() ? timeFrame.start : minStartDate),
        [timeFrame.start, minStartDate],
    );

    const handleSubmit = React.useCallback(() => {
        onSubmit(timeFrame);
    }, [onSubmit, timeFrame]);

    const onTimeFrameChange = React.useCallback(
        (value: TimeFrameValue) => {
            if (
                value.selector === 'custom' &&
                timeFrame.selector !== 'custom'
            ) {
                setTimeFrame({
                    selector: 'custom',
                    start: undefined,
                    end: undefined,
                });
            } else {
                setTimeFrame(value);
            }
        },
        [timeFrame.selector],
    );

    return (
        <Container>
            <GivelifyLabel
                fontSize={18}
                lineHeight="24px"
                text={copy.selectDateRange}
                variant="heading3S"
            />
            <InputRow>
                <TimeFrameFilter
                    excludeToday={name === 'Bank deposits'}
                    mode="list"
                    onChange={onTimeFrameChange}
                    value={timeFrame}
                    variant="input"
                    width="fullwidth"
                />
                {timeFrame.selector === 'custom' ? (
                    <PickerRow>
                        <PickerSlot>
                            <GivelifyDatePicker
                                fullwidth
                                disabled={isLoading}
                                helperText={startDateChanged && errorStartDate}
                                id="start-date"
                                label=""
                                maxDate={maxStartDate}
                                minDate={minStartDate}
                                name="startDate"
                                onDateChange={onChangeStartDate}
                                onOpenTrackingName="<Start Date_picker>"
                                state={
                                    startDateChanged && errorStartDate
                                        ? 'error'
                                        : 'idle'
                                }
                                value={
                                    timeFrame.start
                                        ? dayjs(timeFrame.start)
                                        : undefined
                                }
                            />
                        </PickerSlot>
                        <GivelifyLabel text={copy.to} variant="body1" />
                        <PickerSlot>
                            <GivelifyDatePicker
                                fullwidth
                                disabled={isLoading}
                                helperText={endDateChanged && errorEndDate}
                                id="end-date"
                                label=""
                                maxDate={maxEndDate}
                                minDate={minEndDate}
                                name="endDate"
                                onDateChange={onChangeEndDate}
                                onOpenTrackingName="<End Date_picker>"
                                state={
                                    endDateChanged && errorEndDate
                                        ? 'error'
                                        : 'idle'
                                }
                                value={
                                    timeFrame.end
                                        ? dayjs(timeFrame.end)
                                        : undefined
                                }
                            />
                        </PickerSlot>
                    </PickerRow>
                ) : null}
            </InputRow>
            {hasFormatFile && (
                <div>
                    <GivelifyLabel
                        fontSize={18}
                        lineHeight="24px"
                        marginBottom={2}
                        text={copy.fileFormat}
                        variant="heading3S"
                    />
                    <RadioRow>
                        <GivelifyRadio
                            aria-label="Comma-Separated (.csv) format"
                            checked={format === getFormatFilter(FORMAT.CSV)}
                            id="select-format-csv"
                            label="Comma-Separated Values (.csv)"
                            name="CSV"
                            onChange={() => onFormatFileChange(true)}
                        />
                        {type === 'custom' ? (
                            <GivelifyRadio
                                aria-label="Excel (.xlsx) format"
                                checked={
                                    format === getFormatFilter(FORMAT.EXCEL)
                                }
                                id="select-format-xlsx"
                                label="Excel (.xlsx)"
                                name="XLSX"
                                onChange={() => onFormatFileChange(false)}
                            />
                        ) : null}
                    </RadioRow>
                </div>
            )}
            <FooterRow>
                <GivelifyButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    name="Cancel"
                    onClick={backToReports}
                    size="large"
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    disabled={
                        isLoading ||
                        !!errorStartDate ||
                        !!errorEndDate ||
                        (timeFrame.selector === 'custom' && endDateError())
                    }
                    isLoading={isLoading}
                    name="Generate Report"
                    onClick={handleSubmit}
                    size="large"
                    text={copy.generateReport}
                    variant="primary"
                />
            </FooterRow>
        </Container>
    );
};
