import React from 'react';
import { GivelifyFilter, GivelifyLink, GivelifyMenuItem } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import {
    FilterFooter,
    FilterList,
    FilterMenuItem,
} from '../components/filterMenuItemStyles';
import {
    MESSAGE_FILTER_VALUE,
    MessageFilterProps,
    messageFilterOptions,
    toMessageStringText,
} from './types';

export const MessageFilter: React.FC<MessageFilterProps> = ({
    onChange,
    values,
}) => {
    const { t } = useTranslation();
    const options = React.useMemo(
        () =>
            messageFilterOptions.map((opt) => ({
                label: toMessageStringText(opt, t),
                id: opt,
            })),
        [t],
    );
    const copy = React.useMemo(
        () => ({
            messages: t('donationsActivity.messages.messages'),
            selectAllDonations: t('donationsActivity.text.selectAllDonations'),
            clearAll: t('donationsActivity.text.clearAll'),
        }),
        [t],
    );
    const [open, setOpen] = React.useState(false);
    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const toggleAll = React.useCallback(() => {
        if (values.length === 3) {
            onChange([]);
        } else {
            onChange([...messageFilterOptions]);
        }
    }, [onChange, values]);
    const clearAll = React.useCallback(() => {
        onChange([]);
    }, [onChange]);
    const onItemClick = React.useCallback(
        (id: MESSAGE_FILTER_VALUE) => {
            if (values.findIndex((item) => item === id) === -1) {
                onChange([...values, id]);
            } else {
                const newValues = values.filter((opt) => opt !== id);
                onChange(newValues);
            }
        },
        [onChange, values],
    );
    const getFilterText = React.useCallback(() => {
        if (values === undefined || values.length === 3) {
            return `${copy.messages}: All`;
        }
        return `${copy.messages}: ${values.length}`;
    }, [copy.messages, values]);
    const onRenderMenuContent = React.useCallback(() => {
        return [
            <FilterList key="list" id="message-filter-options">
                <FilterMenuItem
                    key="all"
                    data-testid="message-filter-select-all"
                    id="select-all"
                    indeterminate={values?.length > 0 && values?.length < 3}
                    onClick={toggleAll}
                    selected={values === undefined || values.length === 3}
                    text={copy.selectAllDonations}
                    variant="checkbox"
                />
                {options.map((opt) => (
                    <GivelifyMenuItem
                        key={opt.id}
                        data-testid="message-filter-option"
                        id={`checkbox-${opt.label
                            .toLowerCase()
                            .replace(/\s/g, '-')}`}
                        onClick={() => onItemClick(opt.id)}
                        selected={
                            values === undefined || values.includes(opt.id)
                        }
                        text={opt.label}
                        variant="checkbox"
                    />
                ))}
            </FilterList>,
            <FilterFooter key="footer">
                <GivelifyLink
                    data-testid="filter-clear-all-button"
                    fontSize={16}
                    onClick={clearAll}
                    text={copy.clearAll}
                />
            </FilterFooter>,
        ];
    }, [
        copy.selectAllDonations,
        copy.clearAll,
        toggleAll,
        values,
        options,
        clearAll,
        onItemClick,
    ]);
    return (
        <GivelifyFilter
            id="messages-filter"
            menuProps={{
                sx: {
                    '& .MuiPaper-root': {
                        paddingBottom: '0px',
                    },
                },
            }}
            name="Messages"
            onClick={onOpen}
            onClose={onClose}
            onRenderMenuContent={onRenderMenuContent}
            open={open}
            text={getFilterText()}
        />
    );
};
