import { createStyles, makeStyles } from '@material-ui/core';

export const tooltipStyle = makeStyles({
    envelopeProgressFooter: {
        position: 'absolute',
        left: (props: { position: number }) => props.position + '%',
        top: 16,
        transform: 'translate(-50%, 0)',
    },
    envelopeProgressFooterArrow: {
        position: 'absolute',
        left: '50%',
        top: -9,
        transform: 'translate(-50%, 0)',
    },
});
export const progressStyle = makeStyles(() =>
    createStyles({
        progressContainer: {
            width: '100%',
            position: 'relative',
        },
        progressHeader: {
            display: 'flex',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 16,
        },
        progressHeaderLeft: {
            marginRight: 'auto',
        },
        progressHeaderRight: {
            marginLeft: 'auto',
        },
        progressTooltipContainer: {
            background: '#FFFFFF',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 5,
            paddingRight: 5,
            whiteSpace: 'nowrap',
        },
        progressTooltipText: {
            color: '#5A6061',
        },
        progressTooltipTextSplit: {
            color: '#E3E3E3',
            marginLeft: 3,
            marginRight: 3,
        },
    }),
);
