import React from 'react';
import { TimeFrameValue } from '../timeFrameUtils';

export const useTimeFrameEffect = (
    callback: React.EffectCallback,
    timeFrame: TimeFrameValue,
    dependencies: React.DependencyList,
) => {
    const prevTimeFrameHashRef = React.useRef<string | null>(null);
    const prevDependencies = React.useRef<React.DependencyList>(dependencies);

    React.useEffect(() => {
        let hasTimeFrameChanged = false;
        if (!timeFrame) {
            if (prevTimeFrameHashRef.current) {
                hasTimeFrameChanged = true;
                prevTimeFrameHashRef.current = null;
            }
        } else {
            const currentHash =
                timeFrame.selector === 'custom'
                    ? `${timeFrame.start.format(
                          'DD-MM-YYYY',
                      )}-${timeFrame.end.format('DD-MM-YYYY')}`
                    : timeFrame.selector;

            if (currentHash !== prevTimeFrameHashRef.current) {
                hasTimeFrameChanged = true;
                prevTimeFrameHashRef.current = currentHash;
            }
        }

        const hasOtherDependencyChanged = dependencies.some(
            (dep, index) => dep !== prevDependencies.current[index],
        );

        if (hasTimeFrameChanged || hasOtherDependencyChanged) {
            callback();
            prevDependencies.current = dependencies;
        }
    }, [timeFrame, callback, dependencies]);
};
