import {
    SetNotificationFlag,
    LoadNotificationsSuccess,
    NotificationsState,
    NotificationType,
} from './types';

export const loadNotificationsSuccess = (
    notifications: NotificationsState,
): LoadNotificationsSuccess => ({
    type: 'LOAD_NOTIFICATIONS_SUCCESS',
    data: notifications,
});

export const setNotificationFlag = (
    notification: NotificationType,
    value: boolean,
): SetNotificationFlag => ({
    type: 'SET_NOTIFICATION_FLAG',
    data: {
        type: notification,
        value,
    },
});
