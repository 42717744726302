import { useEffect } from 'react';
import { UseTaxIdContext } from '../TaxIdContext';
import form990 from './church/img/990form.png';
import bankStatement from './church/img/bankStatement.png';
import ein from './church/img/ein.png';
import dbaDocument from './nonprofit/img/dbaDocument.png';
import determinationLetter from './nonprofit/img/determinationLetter.png';

export const usePreloadImages = () => {
    const { organizationType } = UseTaxIdContext();

    useEffect(() => {
        if (organizationType === 'church') {
            new Image().src = form990;
            new Image().src = bankStatement;
            new Image().src = ein;
        } else {
            new Image().src = dbaDocument;
            new Image().src = determinationLetter;
        }
    }, [organizationType]);
};
