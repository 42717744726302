import React, { useCallback, useState } from 'react';
import { GivelifyInputState, GivelifyTextField } from '../textField';
import { isValidCity } from '../utils/addressUtils';

export interface CityFieldProps {
    name?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    city: string | undefined;
    onChange?: (city: string) => unknown;
    className?: string;
    placeholder: string;
    showState?: boolean;
    cityErrorMessage?: string;
}

export const CityField: React.FC<CityFieldProps> = React.memo(
    ({
        name,
        city,
        inputRef,
        className,
        placeholder,
        onChange,
        showState = false,
        cityErrorMessage,
    }) => {
        const [newCity, setCity] = useState<string>(city || '');
        const [inputState, setInputState] = React.useState<GivelifyInputState>(
            'normal',
        );
        const handleChange = useCallback(
            (_e: unknown, value: string) => {
                setCity(value);
                onChange && onChange(value);
                setInputState(isValidCity(value) ? 'normal' : 'error');
            },
            [setCity, onChange, setInputState],
        );
        React.useEffect(() => {
            if (showState) {
                setInputState(isValidCity(newCity) ? 'normal' : 'error');
            }
            //eslint-disable-next-line
        }, [showState]);
        return (
            <GivelifyTextField
                name={name}
                inputRef={inputRef}
                id="city"
                value={newCity}
                onChange={handleChange}
                ariaLabel={placeholder}
                label={placeholder}
                placeholder={placeholder}
                className={className}
                state={inputState}
                leftHelperText={
                    inputState === 'error' ? cityErrorMessage : undefined
                }
            />
        );
    },
);
