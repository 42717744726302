import React from 'react';
import { SortingDirection } from '@devexpress/dx-react-grid';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { useFilterModalStyle } from '../useFilterModalStyle';

interface OwnProps {
    sortOrder: SortingDirection;
    sortOrderOptions: {
        name: string;
        value: SortingDirection;
    }[];
    handleSortOrderChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortOrderLabel: string;
}

const SortOrderPanel: React.FC<OwnProps> = ({
    sortOrder,
    sortOrderOptions,
    handleSortOrderChange,
    sortOrderLabel,
}) => {
    const classes = useFilterModalStyle();

    const SortOrderItem = ({
        option,
    }: {
        option: {
            name: string;
            value: SortingDirection;
        };
    }) => (
        <FormControlLabel
            className={classes.checkmarkRadio}
            control={
                <Radio
                    checkedIcon={
                        <FontAwesomeIcon
                            className={classes.checkIcon}
                            color="#52545B"
                            icon={faCheck}
                            size="xs"
                        />
                    }
                    icon={
                        <FontAwesomeIcon
                            className={classes.checkIcon}
                            color="transparent"
                            icon={faCheck}
                            size="xs"
                        />
                    }
                />
            }
            label={option.name}
            value={option.value}
        />
    );

    return (
        <ExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" />}
            >
                <div className={classes.expansionTitle}>{sortOrderLabel}</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <RadioGroup
                    className={classes.radioGroup}
                    onChange={handleSortOrderChange}
                    value={sortOrder}
                >
                    {sortOrderOptions.map((option, i) => (
                        <SortOrderItem key={i} option={option} />
                    ))}
                </RadioGroup>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default SortOrderPanel;
