import React, { useMemo } from 'react';
import { GivelifyFilter, GivelifyMenuItem } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { FilterList } from '../components/filterMenuItemStyles';
import {
    TRANSACTION_FILTER_VALUE,
    TransactionFilterProps,
    capitalize,
    toLabelText,
    toTransactionValueText,
    transactionFilterOptions,
} from './types';

export const TransactionFilter: React.FC<TransactionFilterProps> = ({
    onChange,
    value,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleSelectChange = React.useCallback(
        (value: TRANSACTION_FILTER_VALUE) => {
            setOpen(false);
            onChange(value);
        },
        [onChange],
    );
    const TransactionText = useMemo(
        () => ({
            transactionTitle: t('bankDeposits.transaction.title'),
        }),
        [t],
    );
    const getFilterText = React.useCallback(() => {
        return `${TransactionText.transactionTitle}: ${capitalize(
            toTransactionValueText(value, t),
        )}`;
    }, [TransactionText.transactionTitle, value, t]);
    const onRenderMenuContent = React.useCallback(() => {
        return [
            <FilterList key="list" id="transaction-filter-options">
                {transactionFilterOptions.map((opt) => (
                    <GivelifyMenuItem
                        key={opt}
                        id={`option-${opt}`}
                        onClick={() => handleSelectChange(opt)}
                        selected={opt === value}
                        text={toLabelText(opt, t)}
                    />
                ))}
            </FilterList>,
        ];
    }, [t, value, handleSelectChange]);
    return (
        <GivelifyFilter
            aria-label={TransactionText.transactionTitle}
            id="transaction-filter"
            name="Transactions"
            onClick={onOpen}
            onClose={onClose}
            onRenderMenuContent={onRenderMenuContent}
            open={open}
            text={getFilterText()}
        />
    );
};
