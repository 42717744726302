import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSelf = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={64}
        width={64}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 64 64"
    >
        <title>self</title>
        <g clipPath="url(#clip0_25_551)">
            <rect fill="#E8F9ED" height="64.0001" rx="32" width="64.0001" />
            <path
                d="M53.8669 73.5938C53.8669 76.0731 53.102 78.0564 51.4767 79.8415C48.0347 83.114 39.908 84.7999 31.6857 84.7999C23.559 84.7999 15.4323 83.2132 11.9904 79.8415C10.365 78.0564 9.60016 76.0731 9.60016 73.5938C9.60016 53.3634 22.4117 41.0665 31.6857 41.0665C41.0553 41.0665 53.8669 53.3634 53.8669 73.5938Z"
                fill="#2BADDD"
            />
            <path
                d="M31.6893 17.0665C37.7487 17.0665 42.6665 21.9647 42.6665 27.9999C42.6665 34.0351 37.7487 38.9332 31.6893 38.9332C25.6298 38.9332 20.7998 34.0351 20.7998 27.9999C20.7998 21.9647 25.542 17.0665 31.6893 17.0665Z"
                fill="#2BADDD"
            />
        </g>
        <defs>
            <clipPath id="clip0_25_551">
                <rect fill="white" height="64.0001" rx="32" width="64.0001" />
            </clipPath>
        </defs>
    </svg>
);

export const SelfIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgSelf} fontSize={fontSize} />
);
