import React from 'react';
import { GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { SUPPORT_WHY_PRIMARY_REP_URL } from 'api/constants';
import { InfoBox } from 'components';
import { useTranslation } from 'react-i18next';
import { Box } from './styles';

export const RepInfoBox: React.FC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.title',
            ),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.description',
            ),
            learnMore: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.learnMore',
            ),
        }),
        [t],
    );
    const onLearnMorClick = React.useCallback((event) => {
        event.preventDefault();
        window.open(SUPPORT_WHY_PRIMARY_REP_URL, '_blank');
    }, []);
    return (
        <InfoBox>
            <Box>
                <GivelifyLabel variant="heading1S">{copy.title}</GivelifyLabel>
                <GivelifyLabel className="content" variant="body2">
                    {copy.description}
                </GivelifyLabel>
                <GivelifyLink
                    href={SUPPORT_WHY_PRIMARY_REP_URL}
                    name="Learn more"
                    onClick={onLearnMorClick}
                    text={copy.learnMore}
                    variant="body1B"
                />
            </Box>
        </InfoBox>
    );
};
