import { useFilterParams } from 'api/hooks/useFilterParams';

export type GivingStylesFilter = {
    new: boolean;
    occasional: boolean;
    consistent: boolean;
    decreasing: boolean;
    inactive: boolean;
};

const paramNames = [
    'givingStyleNew',
    'givingStyleOccasional',
    'givingStyleConsistent',
    'givingStyleDecreasing',
    'givingStyleInactive',
] as const;

export const useGivingStylesFilter = () => {
    const [filter, setFilter] = useFilterParams<
        GivingStylesFilter,
        typeof paramNames
    >(
        {
            new: false,
            occasional: false,
            consistent: false,
            decreasing: false,
            inactive: false,
        },
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'givingStyleNew':
                    return state.new ? 'true' : '';
                case 'givingStyleOccasional':
                    return state.occasional ? 'true' : '';
                case 'givingStyleConsistent':
                    return state.consistent ? 'true' : '';
                case 'givingStyleDecreasing':
                    return state.decreasing ? 'true' : '';
                case 'givingStyleInactive':
                    return state.inactive ? 'true' : '';
            }
        },
        (values) => {
            const selectedParams: GivingStylesFilter = {
                new: Boolean(
                    values['givingStyleNew'] &&
                        (values['givingStyleNew'] === 'true' ||
                            values['givingStyleNew'] === '1'),
                ),
                occasional: Boolean(
                    values['givingStyleOccasional'] &&
                        (values['givingStyleOccasional'] === 'true' ||
                            values['givingStyleOccasional'] === '1'),
                ),
                consistent: Boolean(
                    values['givingStyleConsistent'] &&
                        (values['givingStyleConsistent'] === 'true' ||
                            values['givingStyleConsistent'] === '1'),
                ),
                decreasing: Boolean(
                    values['givingStyleDecreasing'] &&
                        (values['givingStyleDecreasing'] === 'true' ||
                            values['givingStyleDecreasing'] === '1'),
                ),
                inactive: Boolean(
                    values['givingStyleInactive'] &&
                        (values['givingStyleInactive'] === 'true' ||
                            values['givingStyleInactive'] === '1'),
                ),
            };
            return selectedParams;
        },
    );
    return [filter, setFilter] as const;
};
