import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyLoading,
    GivelifyNotification,
    GivelifyButton,
} from '@givelify/givelify-ui';
import { GivelifyLabel } from '@givelify/ui';
import { GivelifyTab } from '@givelify/ui';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import permissionTypes from 'constants/permissionTypes';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH as ROUTES } from 'router/routes';
import { AppState } from 'store';
import { useAdvancedTranslation } from 'utils/i18n';
import permissionsByPath from 'utils/permissionsByPath';
import ActiveEnvelopesTab from './ActiveEnvelopesTab';
import EnvelopeEditor from './components/EnvelopeEditor';
import { useEnvelopesContext } from './context/EnvelopesProvider';
import InactiveEnvelopesPage from './InactiveEnvelopes';
import Portal from './Portal';

const useStyles = makeStyles((theme: Theme) => ({
    notificationWrapper: {
        marginTop: 32,
    },
    createEnvelopeButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const TAB_PATH = {
    active: 'active',
    inactive: 'inactive',
} as const;

const Content = React.forwardRef<HTMLDivElement>((_, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, at } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            hideToGiver: t('pages.settings.envelopes2.hideToGiver'),
            notificationText: t('pages.settings.envelopes2.notificationText'),
            activeEnvelpesTab: t(
                'pages.settings.envelopes2.active-envelopes-tab.shown',
            ),
            inactiveEnvelpesTab: t(
                'pages.settings.envelopes2.inactive-envelopes-tab.hidden',
            ),
            addNewEnvelope: at(
                'pages.settings.envelopes2.active-envelopes-tab.add-new-envelope',
            ),
        }),
        [t, at],
    );

    const user = useSelector((state: AppState) => state.User.user);
    const { notificationWrapper, createEnvelopeButton } = useStyles();

    const {
        activeEnvelopesLoading,
        inactiveEnvelopesLoading,
        showNotification,
        hideNotificationClick,
        createEnvelopeClick,
        updatePrioritiesRequestState,
    } = useEnvelopesContext();

    const [showPageLoader, setShowPageLoader] = useState(true);
    useEffect(() => {
        if (activeEnvelopesLoading && inactiveEnvelopesLoading)
            setShowPageLoader(true);
        if (!activeEnvelopesLoading && !inactiveEnvelopesLoading)
            setShowPageLoader(false);
    }, [activeEnvelopesLoading, inactiveEnvelopesLoading]);

    const navigate = useNavigate();
    const location = useLocation();
    const { PATH } = useGasRouterContext();
    const [currentTab, setCurrentTab] = useState<number | undefined>(undefined);
    const handleChange = (newValue: number) => {
        navigate(
            PATH.SETTINGS.ENVELOPES(
                newValue === 1 ? TAB_PATH.inactive : TAB_PATH.active,
            ),
        );
    };

    useEffect(() => {
        if (
            location.search.startsWith(`?${TAB_PATH.inactive}`) &&
            currentTab !== 1
        ) {
            setCurrentTab(1);
        } else if (
            (!location.search ||
                !location.search.startsWith(`?${TAB_PATH.inactive}`)) &&
            currentTab !== 0
        ) {
            setCurrentTab(0);
        }
    }, [location, currentTab]);

    const hasFullAccess =
        permissionsByPath[ROUTES.SETTINGS.ENVELOPES][user?.role] ===
        permissionTypes.FULL_ACCESS;

    if (showPageLoader) return <GivelifyLoading type="topLoadingBar" />;

    return (
        <>
            {showNotification && (
                <div className={notificationWrapper}>
                    <GivelifyNotification
                        show
                        onClose={hideNotificationClick}
                        text={copy.notificationText}
                        variant="info"
                    />
                </div>
            )}
            {hasFullAccess && (
                <Portal ref={ref}>
                    <div>
                        <GivelifyButton
                            className={createEnvelopeButton}
                            disabled={
                                updatePrioritiesRequestState !== 'REQUEST_INIT'
                            }
                            name="addEnvelope"
                            onClick={createEnvelopeClick}
                            text={copy.addNewEnvelope}
                            variant="primary"
                        />
                    </div>
                </Portal>
            )}
            {!isMobile && (
                <>
                    <ActiveEnvelopesTab />
                    <GivelifyLabel
                        id="hiddenlabel"
                        text={copy.hideToGiver}
                        variant="heading3S"
                    />
                    <InactiveEnvelopesPage />
                </>
            )}
            {isMobile && (
                <>
                    <div style={{ marginTop: 24 }}>
                        <GivelifyTab
                            onChange={handleChange}
                            options={[
                                {
                                    label: copy.activeEnvelpesTab,
                                    tabHref: PATH.SETTINGS.ENVELOPES(
                                        TAB_PATH.active,
                                    ),
                                    id: 'shownTabLabelMobile',
                                },
                                {
                                    label: copy.inactiveEnvelpesTab,
                                    tabHref: PATH.SETTINGS.ENVELOPES(
                                        TAB_PATH.inactive,
                                    ),
                                    id: 'hiddenTabLabelMobile',
                                },
                            ]}
                            value={currentTab}
                        />
                    </div>
                    {currentTab === 0 && <ActiveEnvelopesTab />}
                    {currentTab === 1 && <InactiveEnvelopesPage />}
                </>
            )}
            <EnvelopeEditor />
        </>
    );
});
Content.displayName = 'Content';

export default Content;
