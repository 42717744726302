import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={21}
        width={20}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>undo</title>
        <path
            clipRule="evenodd"
            d="M13.8387 0.9443C13.7315 0.544201 13.9689 0.132948 14.369 0.0257418C14.7691 -0.0814646 15.1804 0.155972 15.2876 0.556072L16.0641 3.45385C16.1713 3.85395 15.9338 4.2652 15.5337 4.37241L12.6359 5.14886C12.2358 5.25607 11.8246 5.01863 11.7174 4.61853C11.6102 4.21844 11.8476 3.80718 12.2477 3.69998L13.3412 3.40698C12.8832 3.18645 12.3977 3.00512 11.8877 2.86846C7.48658 1.68919 2.9628 4.30099 1.78353 8.70209C0.604261 13.1032 3.21607 17.627 7.61716 18.8062C12.0183 19.9855 16.542 17.3737 17.7213 12.9726C17.9059 12.2836 17.9975 11.5926 18.0043 10.9126C18.0083 10.4984 18.3474 10.1659 18.7616 10.17C19.1758 10.1741 19.5083 10.5132 19.5042 10.9274C19.4962 11.7325 19.3877 12.549 19.1702 13.3608C17.7765 18.5621 12.4302 21.6488 7.22893 20.2551C2.02764 18.8614 -1.05904 13.5152 0.334643 8.31386C1.72833 3.11256 7.07461 0.0258843 12.2759 1.41957C12.9389 1.59721 13.5681 1.83936 14.1585 2.13764L13.8387 0.9443Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const UndoIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgUndo} fontSize={fontSize} />
);
