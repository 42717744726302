import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme: Theme) => ({
    outerBox: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    wrapper: {
        width: '240px',
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    donorDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
        },
    },
    title: {
        fontWeight: 'bold',
        fontSize: '18px',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
    },
    label: {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '0px',
        },
    },
    detail: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(4),
        fontSize: '14px',
        minWidth: '50%',
        wordBreak: 'break-all',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    imageWrapper: {
        width: '240px',
        height: '240px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& .MuiAvatar-root': {
            width: 'inherit',
            height: 'inherit',
        },
        [theme.breakpoints.down('sm')]: {
            width: '175px',
            height: '175px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '140px',
        },
    },
    verticalRule: {
        borderRight: `1px solid ${GivelifyColorPalette.grey500}`,
        marginBottom: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
