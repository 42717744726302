import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GvlLabelVariant } from './types';
import { GivelifyLabelStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyColorOrString } from '../style/givelifyDefaultTheme';

/**
 * Givelify Label Props
 */
export interface GivelifyLabelProps extends GivelifyBoxMarginProps {
    id?: string;
    /**
     * Text to display
     */
    text: string | number;
    /**
     * The variant to use.
     */
    variant?: GvlLabelVariant;
    /**
     * Use bold text. 'font-weight: bold'.
     */
    bold?: boolean;
    /**
     * Color override.
     */
    color?: GivelifyColorOrString;
    /**
     * Font size override.
     */
    fontSize?: number;
    /**
     * Line height override.
     */
    lineHeight?: number;
    /**
     * Font weight override.
     */
    fontWeight?: number;
    /**
     * Provided classname.
     */
    className?: string;
    /**
     * onClick Event handler
     */
    onClick?: () => unknown;
    /**
     * Depreciated. actually does nothing
     */
    wrap?: boolean;
    wordBreak?: boolean;
    overflowWrap?: 'normal' | 'break-word' | 'anywhere';
    /**
     * override `word-break: break-all`
     */
    whiteSpace?:
        | '-moz-pre-wrap'
        | 'break-spaces'
        | 'normal'
        | 'nowrap'
        | 'pre'
        | 'pre-line'
        | 'pre-wrap';
    dataTestId?: string;
}

/**
 * Givelify Label
 */
export const GivelifyLabel: React.FC<GivelifyLabelProps> = (props) => {
    const {
        boxMargin,
        text,
        bold,
        heading1,
        heading2,
        heading3,
        heading4,
        heading5,
        caption1,
        caption2,
        body1,
        body2,
        body3,
        button1,
        button2,
        small,
        h1Mobile,
        h2Mobile,
        h3Mobile,
        h4Mobile,
        colored,
        custom,
    } = GivelifyLabelStyles({
        color: props.color,
        fontSize: props.fontSize,
        lineHeight: props.lineHeight,
        margin: props.margin,
        marginBottom: props.marginBottom,
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        marginTop: props.marginTop,
        whiteSpace: props.whiteSpace,
        wordBreak: props.wordBreak,
        overflowWrap: props.overflowWrap,
        fontWeight: props.fontWeight,
    });
    const className = mergeClassNames(
        boxMargin,
        text,
        props.variant === 'heading1' && heading1,
        props.variant === 'heading2' && heading2,
        props.variant === 'heading3' && heading3,
        props.variant === 'heading4' && heading4,
        props.variant === 'heading5' && heading5,
        props.variant === 'caption1' && caption1,
        props.variant === 'caption2' && caption2,
        props.variant === 'body1' && body1,
        props.variant === 'body2' && body2,
        props.variant === 'body3' && body3,
        props.variant === 'button1' && button1,
        props.variant === 'button2' && button2,
        props.variant === 'small' && small,
        props.variant === 'h1Mobile' && h1Mobile,
        props.variant === 'h2Mobile' && h2Mobile,
        props.variant === 'h3Mobile' && h3Mobile,
        props.variant === 'h4Mobile' && h4Mobile,
        !props.variant && body1,
        props.bold && bold,
        props.color && colored,
        (props.fontSize || props.lineHeight || props.fontWeight) && custom,
        props.className,
    );
    switch (props.variant) {
        case 'heading1':
        case 'h1Mobile':
            return (
                <h1
                    onClick={props.onClick}
                    id={props.id}
                    className={className}
                    data-testid={props.dataTestId}
                >
                    {props.text}
                </h1>
            );
        case 'heading2':
        case 'h2Mobile':
            return (
                <h2
                    onClick={props.onClick}
                    id={props.id}
                    className={className}
                    data-testid={props.dataTestId}
                >
                    {props.text}
                </h2>
            );
        case 'heading3':
        case 'h3Mobile':
            return (
                <h3
                    onClick={props.onClick}
                    id={props.id}
                    className={className}
                    data-testid={props.dataTestId}
                >
                    {props.text}
                </h3>
            );
        case 'heading4':
        case 'h4Mobile':
            return (
                <h4
                    onClick={props.onClick}
                    id={props.id}
                    className={className}
                    data-testid={props.dataTestId}
                >
                    {props.text}
                </h4>
            );
        case 'heading5':
            return (
                <h5
                    onClick={props.onClick}
                    id={props.id}
                    className={className}
                    data-testid={props.dataTestId}
                >
                    {props.text}
                </h5>
            );
        default:
            return (
                <div
                    onClick={props.onClick}
                    id={props.id}
                    className={className}
                    data-testid={props.dataTestId}
                >
                    {props.text}
                </div>
            );
    }
};
