import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyIcon,
    GivelifyButton,
    GivelifyLabelStyles,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { mergeClassNames } from '@givelify/utils';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useCampusesContext } from '../../CampusesProvider';
import useStyles from './styles';

const EmptyList = () => {
    const { wrapper, icon, text } = useStyles();
    const { body1 } = GivelifyLabelStyles({});
    const isDesktop = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));

    const { setShowAddCampusModal, isEditable } = useCampusesContext();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list',
    });

    const { addCampus, noCampuses, manage, note } = useMemo(
        () => ({
            addCampus: scopedTranslate('addCampus'),
            noCampuses: scopedTranslate('emptyList.noCampuses'),
            manage: scopedTranslate('emptyList.manage'),
            note: scopedTranslate('emptyList.note'),
        }),
        [scopedTranslate],
    );

    return (
        <div className={wrapper}>
            <GivelifyIcon className={icon} variant="location-map" />
            <GivelifyLabel
                marginBottom={16}
                text={noCampuses}
                variant="heading3"
            />
            <div className={mergeClassNames(body1, text)}>
                {manage}
                {isDesktop ? <br /> : <span>&nbsp;</span>}
                {note}
            </div>
            {isEditable && (
                <GivelifyButton
                    onClick={() => setShowAddCampusModal(true)}
                    size={isDesktop ? 'xLarge' : 'small'}
                    text={addCampus}
                    variant="primary"
                />
            )}
        </div>
    );
};

export default EmptyList;
