import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { DesignTokens, GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { styled, IconButton, MenuItem } from '@mui/material';

export const UserAvatarWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
});

export const UserDropdownBlock = styled('div')(({ theme }) => ({
    height: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const UserDropdownBlockMobile = styled('div')(({ theme }) => ({
    color: '#494A50',
    display: 'none',
    [theme.breakpoints.down('mobile')]: {
        display: 'inherit',
        height: '100%',
    },
}));

export const HelpIconWrapper = styled('div')(({ theme }) => ({
    fontSize: '40px',
    [theme.breakpoints.down('tablet')]: {
        fontSize: '32px',
    },
}));

export const IconButtonBlock = styled(GivelifyButton, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen?: boolean }>(({ isOpen }) => ({
    boxSizing: 'border-box',
    padding: '2px',
    width: 38,
    minWidth: 38,
    height: 38,
    border: isOpen ? `2px solid ${DesignTokens.color.globalInfo400}` : null,
}));

export const CurrencyAnnotationWrapper = styled('div')(({ theme }) => ({
    padding: '0 24px',
    [theme.breakpoints.down('mobile')]: {
        marginTop: '16px',
    },
}));

export const ToolbarContent = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
        flexGrow: 0,
    },
    [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 70px)',
    },
}));

export const DonorInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    height: '100%',
    [theme.breakpoints.down('tablet')]: {
        marginLeft: theme.spacing(2),
    },
}));

export const UserName = styled(GivelifyLabel)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: DesignTokens.textStyle.globalCaption1.font.size,
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontSize: 22,
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

export const MenuOptionItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1.5, 4),
    fontSize: DesignTokens.textStyle.globalBody2.font.size,
    lineHeight: '22px',
    minWidth: '230px',
    color: GivelifyColorPalette.primaryDarkGray,
    '&:hover': {
        backgroundColor: `${GivelifyColorPalette.neutralHoverGrey} !important`,
    },
    '&.Mui-disabled': {
        opacity: 1,
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent !important',
    },
    '& .markSelected': {
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
        minWidth: 'auto',
    },
}));

export const NewMenuOptionItem = styled(MenuItem)(({ theme }) => ({
    padding: '6px 32px',
    minHeight: '44px !important',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontSize: 18,
    lineHeight: '32px',
    minWidth: '230px',
    color: GivelifyColorPalette.primaryDarkGray,
    '&:hover': {
        backgroundColor: `${GivelifyColorPalette.neutralHoverGrey} !important`,
    },
    '&.Mui-disabled': {
        opacity: 1,
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent !important',
    },
    '& .markSelected': {
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
        minWidth: 'auto',
    },
    [theme.breakpoints.down('mobile')]: {
        minWidth: '220px',
        padding: '4px 24px',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 800,
        minHeight: 'auto',
        '&:first-of-type': {
            marginBottom: '8px',
        },
    },
}));
