import React, { useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyTextDivider,
    GivelifyTheme,
} from '@givelify/givelify-ui';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { SvgIcon } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ReactComponent as infoIcon } from '../assets/images/info-blue.svg';
import { EnvelopeProps, GivelithonEnvelope } from '../GivelithonEnvelope';
import { GivelithonHeader } from '../GivelithonLaunch/components/GivelithonHeader';
import { GivelithonWorkModal } from '../GivelithonWorkModal';
import { useTranslation } from '../utils/i18n';
import { LoadingState } from './LoadingState';
import { givelithonMainPageStyle } from './style';

interface GivelithonMainPageProps {
    onSort: (
        value: string | { key: string | number; value: string } | null,
    ) => void;
    envelopes: EnvelopeProps[];
    loading: boolean;
    doneeName: string;
    doneeType?: string;
    links: {
        toSettings: () => void;
        toGivelithon: (envelopeId: number) => void;
        toEditGivelithon: (envelopeId: number) => void;
    };
}

export const GivelithonMainPage: React.FC<GivelithonMainPageProps> = (
    props,
) => {
    const { t } = useTranslation(undefined, props.doneeType);
    const classes = givelithonMainPageStyle();
    const [open, setOpen] = useState<boolean>(false);
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const copy = useMemo(
        () => ({
            givelithon: t('givelithonPage.givelithon'),
        }),
        [t],
    );

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.Givelithon}>
            <ThemeProvider theme={GivelifyTheme}>
                <div className={classes.givelithonTitle}>
                    <GivelifyLabel
                        className={classes.pageName}
                        marginRight={8}
                        text={copy.givelithon}
                        variant="heading1"
                    />
                    <GivelifyButton
                        data-testid="givelithon-help-button"
                        icon={
                            <SvgIcon component={infoIcon} viewBox="0 0 20 20" />
                        }
                        name="openHelp"
                        onClick={onOpen}
                        variant="ghost"
                    />
                </div>
                <GivelifyTextDivider className={classes.divider} />
                <GivelithonHeader
                    linkToSettings={props.links.toSettings}
                    onSort={props.onSort}
                />
                <div className={classes.givelithonListEnvelopes}>
                    {props.loading && <LoadingState />}
                    {!props.loading &&
                        props.envelopes?.map((envelope, i) => (
                            <GivelithonEnvelope
                                key={i}
                                active={envelope.active}
                                amount={envelope.amount}
                                description={envelope.description}
                                doneeId={envelope.doneeId}
                                doneeName={props.doneeName}
                                endDate={envelope.endDate}
                                fundId={envelope.fundId}
                                goal={envelope.goal}
                                howLong={envelope.howLong}
                                id={envelope.id}
                                linkToEditGivelithon={
                                    props.links.toEditGivelithon
                                }
                                linkToGivelithon={props.links.toGivelithon}
                                name={envelope.name}
                                startDate={envelope.startDate}
                                time={envelope.time}
                            />
                        ))}
                    {!props.loading && props.envelopes.length === 0 && (
                        <GivelifyLabel
                            dataTestId="givelithon-no-envelopes"
                            text="No Envelope"
                        />
                    )}
                </div>
                <GivelithonWorkModal onClose={onClose} open={open} />
            </ThemeProvider>
        </TrackingProvider>
    );
};
