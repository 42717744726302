import React from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { donorTabStyle } from '../../style';

interface AutoIntegrationUnmatchDonorRowButtonProps {
    donorId: number;
    pageNumber: number;
    openUnMatchModal: (donorId: number, pageNumber: number) => void;
    isReadOnly: boolean;
}

const AutoIntegrationUnmatchDonorRowButton: React.FC<AutoIntegrationUnmatchDonorRowButtonProps> = ({
    donorId,
    pageNumber,
    openUnMatchModal,
    isReadOnly,
}) => {
    const classes = donorTabStyle();
    const openModal = () => {
        openUnMatchModal(donorId, pageNumber);
    };
    return (
        <div className={classes.unmatchButtonItem}>
            <GivelifyButton
                className={classes.donorMatchBtn}
                disabled={isReadOnly}
                loadingIndicator="ring"
                marginLeft="auto"
                marginRight="auto"
                name="match"
                onClick={openModal}
                size="small"
                text="Match"
                variant="secondary"
            />
        </div>
    );
};

export default AutoIntegrationUnmatchDonorRowButton;
