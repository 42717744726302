import * as React from 'react';
import {
    GivelifyBox,
    GivelifyBoxMarginProps,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { webConfig } from '../../utils/webConfig';
import { ProgressBadge } from '../components/ProgressBadge';
import { CardCompleteStyles } from './cardCompleteStyles';

export interface CardCompleteProps extends GivelifyBoxMarginProps {
    progress?: number;
    onCompleteClick?: () => void;
    onCloseClick?: () => void;
    disable?: boolean;
    width?: number;
    dismissed?: boolean;
    showRetry?: boolean;
    onReviewClick?: () => void;
}

const cardStyleBreakpoint = 742;

const ReviewBar: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    return (
        <div className="review-bar">
            <GivelifyIcon className="review-icon" variant="info-circle" />
            <div>
                {t('onboardingCards.completeToRecieve.reviewTip')}{' '}
                <span className="review-link" onClick={onClick}>
                    {t('onboardingCards.completeToRecieve.review')}
                </span>
            </div>
        </div>
    );
};

const CompleteBar: React.FC = () => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = {
        tipDone1: t('onboardingCards.completeToRecieve.tipDone1'),
        tipDone2: t('onboardingCards.completeToRecieve.tipDone2'),
    };
    return (
        <div className="complete-bar">
            <div className="complete-link">
                {copy.tipDone1}{' '}
                <a href={webConfig.supportArticleLink} target="blank">
                    {copy.tipDone2}
                </a>
            </div>
        </div>
    );
};

const ReviewBarMemo = React.memo(ReviewBar);
const CompleteBarMemo = React.memo(CompleteBar);

export const CardComplete: React.FC<CardCompleteProps> = ({
    disable,
    dismissed,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    onCloseClick,
    onCompleteClick,
    progress = 0,
    width = 1040,
    showRetry = false,
    onReviewClick,
}) => {
    const { card, cardDismissal } = CardCompleteStyles({ width });
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            title: t('onboardingCards.completeToRecieve.title'),
            description: t('onboardingCards.completeToRecieve.description'),
            complete: t('onboardingCards.completeToRecieve.complete'),
            required: t('labels.required'),
            titleDone: t('onboardingCards.completeToRecieve.titleDone'),
            descriptionDone: t(
                'onboardingCards.completeToRecieve.descriptionDone',
            ),
            tipDone1: t('onboardingCards.completeToRecieve.tipDone1'),
            tipDone2: t('onboardingCards.completeToRecieve.tipDone2'),
        }),
        [t],
    );
    return (
        <GivelifyBox
            className={mergeClassNames(card, dismissed && cardDismissal)}
            margin={margin}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginTop={marginTop}
            opacity={disable ? 0.6 : 1}
        >
            <div className="header">
                {progress < 100 ? (
                    <GivelifyLabel
                        className="label"
                        color="neutralGrey"
                        text={copy.required}
                        variant="body2"
                    />
                ) : (
                    <GivelifyButton
                        disabled={disable}
                        iconVariant="close"
                        marginLeft="auto"
                        onClick={onCloseClick}
                        variant="icon"
                    />
                )}
            </div>
            <div
                className={mergeClassNames(
                    'content',
                    width > cardStyleBreakpoint ? 'content-lg' : 'content-xs',
                )}
            >
                <div className="main">
                    <GivelifyLabel
                        bold
                        className="title"
                        text={progress < 100 ? copy.title : copy.titleDone}
                        variant="heading1"
                    />
                    <GivelifyLabel
                        className="description"
                        fontSize={18}
                        lineHeight={24}
                        text={
                            progress < 100
                                ? copy.description
                                : copy.descriptionDone
                        }
                    />
                    {progress < 100 ? (
                        <GivelifyButton
                            className={mergeClassNames(
                                'complete',
                                showRetry && 'complete-sm-margin',
                                !showRetry && 'complete-lg-margin',
                            )}
                            id="complete-onboarding-btn"
                            onClick={onCompleteClick}
                            size="large"
                            text={copy.complete}
                            variant="primary"
                        />
                    ) : null}
                </div>
                <div className="misc">
                    <ProgressBadge
                        size="normal"
                        value={progress ? Math.min(100, progress) : 0}
                    />
                </div>
            </div>
            {showRetry && progress < 100 ? (
                <ReviewBarMemo onClick={onReviewClick} />
            ) : null}
            {progress >= 100 ? <CompleteBarMemo /> : null}
        </GivelifyBox>
    );
};
