import React from 'react';
import { GivelifyTextField, GivelifyTextFieldProps } from '../textField';
import { emailValidation } from '../utils/emailUtils';

export type GivelifyEmailFieldProps = GivelifyTextFieldProps & {
    onValidation?: (valid: boolean) => void;
};

export const GivelifyEmailField: React.FC<GivelifyEmailFieldProps> = props => {
    const {
        value: passedValue,
        onChange,
        onEnterSubmit,
        submitting,
        defaultValue,
        onValidation,
        ...inputProps
    } = props;
    const [value, setValue] = React.useState<string>(
        defaultValue ? defaultValue : '',
    );

    const onInputValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            if (submitting) return;
            setValue(value);
            if (onChange) {
                onChange(event, value)
            }
            if (value) {
                if (onValidation) onValidation(emailValidation(value));
            } else {
                if (onValidation) onValidation(false);
            }
        },
        [submitting, onChange, setValue, onValidation],
    );
    React.useEffect(() => {
        if (passedValue !== undefined) {
            setValue(passedValue.toString());
        }
    }, [passedValue, setValue]);

    return (
        <GivelifyTextField
            {...inputProps}
            type="text"
            inputMode="email"
            submitting={submitting}
            value={value}
            defaultValue={defaultValue ? defaultValue.toString() : undefined}
            onChange={onInputValueChange}
        />
    );
};

export type GivelifyFormEmailFieldProps = GivelifyEmailFieldProps & {
    name: string;
    formInputRef: React.Ref<HTMLInputElement>;
};

export const GivelifyFormEmailField: React.FC<GivelifyFormEmailFieldProps> = props => {
    const {
        name,
        defaultValue,
        value,
        onChange,
        formInputRef,
        required,
        ...inputProps
    } = props;
    const [curValue, setCurValue] = React.useState(
        value ? value : defaultValue ? defaultValue : '',
    );
    const onChangeEvent = React.useCallback(
        (event, newValue) => {
            if (value && onChange) {
                onChange(event, newValue);
            } else {
                setCurValue(newValue);
            }
        },
        [setCurValue, onChange, value],
    );
    React.useEffect(() => {
        if (value !== undefined) {
            setCurValue(value);
        }
    }, [value]);
    return (
        <>
            <input
                id={props.id}
                hidden
                name={name}
                value={curValue}
                // eslint-disable-next-line
                onChange={() => {}}
                ref={formInputRef}
                required={required}
                type="text"
            />
            <GivelifyEmailField
                {...inputProps}
                id={`${props.id}-controll`}
                name={name}
                required={false}
                value={curValue}
                onChange={onChangeEvent}
            />
        </>
    );
};
