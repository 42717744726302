import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgImage = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="currentColor"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M17.25 10.5C18.4922 10.5 19.5 9.49219 19.5 8.25C19.5 7.00781 18.4922 6 17.25 6C16.0078 6 15 7.00781 15 8.25C15 9.49219 16.0078 10.5 17.25 10.5Z" />
        <path d="M21.1875 3H2.8125C2.08125 3 1.5 3.59531 1.5 4.32656V19.6734C1.5 20.4047 2.08125 21 2.8125 21H21.1875C21.9188 21 22.5 20.4047 22.5 19.6734V4.32656C22.5 3.59531 21.9188 3 21.1875 3ZM16.3547 12.2672C16.2141 12.1031 15.9984 11.9766 15.7547 11.9766C15.5156 11.9766 15.3469 12.0891 15.1547 12.2438L14.2781 12.9844C14.0953 13.1156 13.95 13.2047 13.7391 13.2047C13.5375 13.2047 13.3547 13.1297 13.2234 13.0125C13.1766 12.9703 13.0922 12.8906 13.0219 12.8203L10.5 10.0922C10.3125 9.87656 10.0312 9.74063 9.71719 9.74063C9.40313 9.74063 9.1125 9.89531 8.92969 10.1063L3 17.2594V5.04844C3.04688 4.72969 3.29531 4.5 3.61406 4.5H20.3812C20.7047 4.5 20.9672 4.73906 20.9859 5.0625L21 17.2687L16.3547 12.2672Z" />
    </svg>
);

export const ImageIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgImage}
        fontSize={fontSize}
    />
);
