import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';
import { FullWidthModalOnboarding } from '../modal';
import { RequiredInfoPage, RequiredInfoPageProps } from './RequiredInfoPage';

export interface RequiredInfoModalProps {
    open: boolean;
    onClose: () => void;
    requiredInfoPageProps: RequiredInfoPageProps;
}

export const RequiredInfoModal: React.FC<RequiredInfoModalProps> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            title: t('requiredInfo.title'),
            titleSmall: t('requiredInfo.titleSmall'),
        }),
        [t],
    );
    const { open, onClose, requiredInfoPageProps } = props;
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <FullWidthModalOnboarding
            errorMessage={undefined}
            footer={undefined}
            heading={smScreen ? copy.titleSmall : copy.title}
            name="Info Modal"
            onClose={onClose}
            open={open}
            retryMessage={undefined}
            steps={undefined}
            testId="infoModal"
        >
            <RequiredInfoPage {...requiredInfoPageProps} />
        </FullWidthModalOnboarding>
    );
};
