import { useEffect } from 'react';
import Heart from 'assets/heart.svg';
import NoDonationsQrCodeData from 'assets/no-donations-qr.svg';
import NoDonationsData from 'assets/no-donations.svg';

export const usePreloadNoDataImages = () => {
    useEffect(() => {
        new Image().src = Heart;
        new Image().src = NoDonationsQrCodeData;
        new Image().src = NoDonationsData;
    }, []);
};
