import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDownArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>down-arrow</title>
        <path
            d="M12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.92893 13.3431C4.53841 12.9526 4.53841 12.3195 4.92893 11.9289C5.31946 11.5384 5.95262 11.5384 6.34315 11.9289L12 17.5858L17.6569 11.9289C18.0474 11.5384 18.6805 11.5384 19.0711 11.9289C19.4616 12.3195 19.4616 12.9526 19.0711 13.3431L12.7071 19.7071ZM13 4L13 19L11 19L11 4L13 4Z"
            fill="current"
        />
    </svg>
);

export const DownArrowIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDownArrow}
        fontSize={fontSize}
    />
);
