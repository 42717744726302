import React, { useState, useEffect } from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { GetPagedDonationsByDateResponse } from 'api/services/responses/donations';
import { getDonationsService } from 'api/utils/serviceProvider';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import { greyBorder } from '../../../../overview/components/styles';
import { DonationsStatus } from '../../BankDepositInfiniteLoader/BankDepositRow';
import BankDepositTable from './BankDepositTable';

const style = { border: greyBorder };
const Loading = (
    <>
        <FacebookLoading
            borderRadius={0}
            height={52.8}
            marginTop={4}
            width="100%"
        />
        <div style={style} />
        <FacebookLoading
            borderRadius={0}
            height={52.8}
            marginTop={2}
            width="100%"
        />
        <div style={style} />
        <FacebookLoading
            borderRadius={0}
            height={52.8}
            marginTop={2}
            width="100%"
        />
    </>
);

type BankDepositModalPaginationProps = {
    donation: DonationsStatus;
};

const BankDepositModalPaginationComponent: React.FC<
    BankDepositModalPaginationProps
> = ({ donation }) => {
    const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const handleChangePage = (e: unknown, pageNumber: number) =>
        setPageNumber(pageNumber);
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const service = getDonationsService();
    const [requestState, makeRequest] =
        useApiRequest<GetPagedDonationsByDateResponse>();

    useEffect(() => {
        makeRequest(
            service.getPagedDonationsByDate(
                doneeId,
                donation.depositDate,
                pageNumber + 1,
                donation.status.toLocaleLowerCase() === 'pending',
            ),
        );
        // eslint-disable-next-line
    }, [pageNumber]);
    if (requestState.type === 'REQUEST_SUCCESS') {
        if (requestState.response.data.length > 0) {
            return (
                <BankDepositTable
                    currentPage={pageNumber}
                    donationRows={requestState.response.data}
                    handleChangePage={handleChangePage}
                    total={requestState.response.pagination.total}
                />
            );
        }
    } else if (requestState.type === 'REQUEST_ERROR')
        return <div className="no-data">{t('bankDeposits.noData')}</div>;
    return Loading;
};

export const BankDepositModalPagination = React.memo(
    BankDepositModalPaginationComponent,
);
