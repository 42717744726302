import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyModal } from '@givelify/givelify-ui';
import { GivelifyLabel, DesignTokens } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';
import { useAdvancedTranslation } from 'utils/i18n';
import { useEnvelopesContext } from '../../../../context/EnvelopesProvider';

interface DeleteConfirmModalProps {
    onCancel: () => void;
    onOk: () => void;
    open: boolean;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 64px 32px',
        textAlign: 'center',
    },
    label: {
        marginTop: '32px !important',
        marginBottom: '16px !important',
    },
}));

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
    onCancel,
    onOk,
    open,
}) => {
    const { label, wrapper } = useStyles();

    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'pages.settings.envelopes2.envelopes-editor.delete-confirm-modal',
    );

    const copy = useMemo(
        () => ({
            firstLabel: scopedATranslate('firstLabel'),
            secondLabel: scopedTranslate('secondLabel'),
            cancel: scopedTranslate('cancel'),
            confirm: scopedATranslate('confirm'),
        }),
        [scopedATranslate, scopedTranslate],
    );

    const { deleteEnvelopeRequestState } = useEnvelopesContext();
    const isLoading = deleteEnvelopeRequestState === 'REQUEST_START';

    return (
        <GivelifyModal
            autoFullscreen
            centerButtons
            showCloseButton
            autoFullscreenBreakpoint="xs"
            isLoading={isLoading}
            onClose={onCancel}
            onPrimaryClick={onOk}
            onSecondaryClick={onCancel}
            open={open}
            primaryButtonText={copy.confirm}
            secondaryButtonText={copy.cancel}
            size="small"
        >
            <div className={wrapper}>
                <GivelifyIcon size={64} variant="alert" />
                <div className={label}>
                    <GivelifyLabel text={copy.firstLabel} variant="heading2S" />
                </div>
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    text={copy.secondLabel}
                    variant="body1"
                />
            </div>
        </GivelifyModal>
    );
};

export default DeleteConfirmModal;
