import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import {
    GivelifyColor,
    GivelifyColorOrString,
} from '../style/givelifyDefaultTheme';

export interface GivelifyBoxStyleProps extends GivelifyBoxMarginProps {
    display?: 'block' | 'flex' | 'none';
    alignItems?:
        | 'stretch'
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'baseline'
        | 'initial'
        | 'inherit';
    justifyContent?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'initial'
        | 'inherit';
    flexDirection?:
        | 'row'
        | 'row-reverse'
        | 'column'
        | 'column-reverse'
        | 'initial'
        | 'inherit';
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
    boxShadow?: string;
    width?: number | 'auto' | '100%';
    height?: number | 'auto' | '100%';
    minWidth?: number | 'auto' | '100%';
    minHeight?: number | 'auto' | '100%';
    maxWidth?: number | 'auto' | '100%';
    maxHeight?: number | 'auto' | '100%';
    backgroundColor?: GivelifyColorOrString;
    color?: GivelifyColorOrString;
    borderWidth?: number;
    borderStyle?:
        | 'none'
        | 'hidden'
        | 'dotted'
        | 'dashed'
        | 'solid'
        | 'double'
        | 'groove'
        | 'ridge'
        | 'inset'
        | 'outset'
        | 'initial'
        | 'inherit';
    borderColor?: GivelifyColorOrString;
    borderRadius?: number | string;
    position?: 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';
    zIndex?: number;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    opacity?: number;
    paddingTop?: number | 'auto' | 'none';
    paddingBottom?: number | 'auto' | 'none';
    paddingLeft?: number | 'auto' | 'none';
    paddingRight?: number | 'auto' | 'none';
}

export const GivelifyBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyBoxStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyBoxStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyBoxStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyBoxStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        container: {
            opacity: (props: GivelifyBoxStyleProps) => props.opacity,
            display: (props: GivelifyBoxStyleProps) => props.display,
            justifyContent: (props: GivelifyBoxStyleProps) =>
                props.justifyContent,
            alignItems: (props: GivelifyBoxStyleProps) => props.alignItems,
            flexDirection: (props: GivelifyBoxStyleProps) =>
                props.flexDirection,
            overflow: (props: GivelifyBoxStyleProps) => props.overflow,
            boxShadow: (props: GivelifyBoxStyleProps) => props.boxShadow,
            width: (props: GivelifyBoxStyleProps) => props.width,
            height: (props: GivelifyBoxStyleProps) => props.height,
            minWidth: (props: GivelifyBoxStyleProps) => props.minWidth,
            minHeight: (props: GivelifyBoxStyleProps) => props.minHeight,
            maxWidth: (props: GivelifyBoxStyleProps) => props.maxWidth,
            maxHeight: (props: GivelifyBoxStyleProps) => props.maxHeight,
            borderWidth: (props: GivelifyBoxStyleProps) => props.borderWidth,
            borderRadius: (props: GivelifyBoxStyleProps) => props.borderRadius,
            borderStyle: (props: GivelifyBoxStyleProps) => props.borderStyle,
            position: (props: GivelifyBoxStyleProps) => props.position,
            zIndex: (props: GivelifyBoxStyleProps) => props.zIndex,
            top: (props: GivelifyBoxStyleProps) => props.top,
            bottom: (props: GivelifyBoxStyleProps) => props.bottom,
            left: (props: GivelifyBoxStyleProps) => props.left,
            right: (props: GivelifyBoxStyleProps) => props.right,
            backgroundColor: (props: GivelifyBoxStyleProps) => {
                if (!props.backgroundColor) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.backgroundColor as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.backgroundColor;
            },
            color: (props: GivelifyBoxStyleProps) => {
                if (!props.color) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.color as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.color;
            },
            borderColor: (props: GivelifyBoxStyleProps) => {
                if (!props.borderColor) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.borderColor as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.borderColor;
            },
            paddingTop: (props: GivelifyBoxStyleProps) => props.paddingTop,
            paddingBottom: (props: GivelifyBoxStyleProps) =>
                props.paddingBottom,
            paddingLeft: (props: GivelifyBoxStyleProps) => props.paddingLeft,
            paddingRight: (props: GivelifyBoxStyleProps) => props.paddingRight,
        },
    }),
);

export const GivelifyBoxMobileStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMarginSm: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: (props: GivelifyBoxStyleProps) =>
                    props.marginLeft ? props.marginLeft : props.margin,
                marginTop: (props: GivelifyBoxStyleProps) =>
                    props.marginTop ? props.marginTop : props.margin,
                marginRight: (props: GivelifyBoxStyleProps) =>
                    props.marginRight ? props.marginRight : props.margin,
                marginBottom: (props: GivelifyBoxStyleProps) =>
                    props.marginBottom ? props.marginBottom : props.margin,
            },
        },
        containerSm: {
            [theme.breakpoints.down('sm')]: {
                opacity: (props: GivelifyBoxStyleProps) => props.opacity,
                display: (props: GivelifyBoxStyleProps) => props.display,
                justifyContent: (props: GivelifyBoxStyleProps) =>
                    props.justifyContent,
                alignItems: (props: GivelifyBoxStyleProps) => props.alignItems,
                flexDirection: (props: GivelifyBoxStyleProps) =>
                    props.flexDirection,
                overflow: (props: GivelifyBoxStyleProps) => props.overflow,
                boxShadow: (props: GivelifyBoxStyleProps) => props.boxShadow,
                width: (props: GivelifyBoxStyleProps) => props.width,
                height: (props: GivelifyBoxStyleProps) => props.height,
                minWidth: (props: GivelifyBoxStyleProps) => props.minWidth,
                minHeight: (props: GivelifyBoxStyleProps) => props.minHeight,
                maxWidth: (props: GivelifyBoxStyleProps) => props.maxWidth,
                maxHeight: (props: GivelifyBoxStyleProps) => props.maxHeight,
                borderWidth: (props: GivelifyBoxStyleProps) =>
                    props.borderWidth,
                borderRadius: (props: GivelifyBoxStyleProps) =>
                    props.borderRadius,
                borderStyle: (props: GivelifyBoxStyleProps) =>
                    props.borderStyle,
                position: (props: GivelifyBoxStyleProps) => props.position,
                zIndex: (props: GivelifyBoxStyleProps) => props.zIndex,
                top: (props: GivelifyBoxStyleProps) => props.top,
                bottom: (props: GivelifyBoxStyleProps) => props.bottom,
                left: (props: GivelifyBoxStyleProps) => props.left,
                right: (props: GivelifyBoxStyleProps) => props.right,
                backgroundColor: (props: GivelifyBoxStyleProps) => {
                    if (!props.backgroundColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.backgroundColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.backgroundColor;
                },
                color: (props: GivelifyBoxStyleProps) => {
                    if (!props.color) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.color as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.color;
                },
                borderColor: (props: GivelifyBoxStyleProps) => {
                    if (!props.borderColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.borderColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.borderColor;
                },
                paddingTop: (props: GivelifyBoxStyleProps) => props.paddingTop,
                paddingBottom: (props: GivelifyBoxStyleProps) =>
                    props.paddingBottom,
                paddingLeft: (props: GivelifyBoxStyleProps) =>
                    props.paddingLeft,
                paddingRight: (props: GivelifyBoxStyleProps) =>
                    props.paddingRight,
            },
        },
    }),
);
