import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const missionStyle = makeStyles((theme: Theme) => {
    return createStyles({
        missionContainer: {
            display: 'flex',
            marginTop: 40,
        },
        missionEditorSide: {
            maxWidth: 600,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginBottom: 75,
            },
        },
        missionTitleContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
        },
        missionTipContainer: { marginTop: 56, display: 'flex' },
        missionTip: {
            width: 52,
            height: 22,
            background: '#373739',
            color: '#FFFFFF',
            borderRadius: '31px',
            textAlign: 'center',
            paddingTop: 4,
            marginRight: 16,
        },
        missionDescriptionHolder: {
            maxWidth: '532px',
        },
        missionExample: {
            marginTop: 12,
            color: '#676E77',
            maxWidth: 435,
            backgroundImage: 'url(/caption-open.svg)',
            backgroundPosition: 'left top',
            backgroundRepeat: 'no-repeat, no-repeat',
            padding: '6px 10px 6px 20px',
            fontSize: '14px',
            lineHeight: '18px',
        },
        missionExampleCaptionClosed: {
            marginLeft: 3,
            marginBottom: 3,
        },
        missionEditor: {
            marginBottom: 56,
        },
        missionPreviewSide: {
            marginLeft: 'auto',
            marginRight: 32,
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    });
});
