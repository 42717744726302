import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { formatWithTimezone, getInitialsFromName } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { donorTabStyle } from '../../style';

interface AutoIntegrationUnmatchDonorRowInfoProps {
    donorName: string;
    lastDonation: number;
    numberOfMatches: number;
    lastDonationDate: string;
    avatar?: string;
}
const AutoIntegrationUnmatchDonorRowInfo: React.FC<
    AutoIntegrationUnmatchDonorRowInfoProps
> = ({
    donorName,
    lastDonation,
    numberOfMatches,
    lastDonationDate,
    avatar,
}) => {
    const classes = donorTabStyle();
    return (
        <div className={classes.donorRow}>
            <Grid container className={classes.unmatchDonorRow}>
                <Grid
                    item
                    className={
                        classes.matchItem + ' ' + classes.unmatchDonorName
                    }
                >
                    <GivelifyAvatar
                        className={classes.donorAvatar}
                        color="eucalyptus"
                        size="xSmall"
                        src={avatar}
                        text={getInitialsFromName(donorName)}
                    />
                    <div className={classes.donorNameRow}>
                        <GivelifyLabel
                            bold
                            className={classes.donorUnmatchName}
                            text={donorName}
                            variant="body1"
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchItem + ' ' + classes.unmatchDonation
                    }
                >
                    <GivelifyLabel
                        bold
                        text={`$${
                            lastDonation
                                ? formatMoneyWithCommas(lastDonation)
                                : 0
                        }`}
                        variant="body2"
                    />
                </Grid>
                <Grid
                    item
                    className={classes.matchItem + ' ' + classes.unmatchNumber}
                >
                    <GivelifyLabel text={numberOfMatches} variant="body2" />
                </Grid>
                <Grid
                    item
                    className={classes.matchItem + ' ' + classes.unmatchDate}
                >
                    <GivelifyLabel
                        text={formatWithTimezone(lastDonationDate)}
                        variant="body2"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AutoIntegrationUnmatchDonorRowInfo;
