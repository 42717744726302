import React from 'react';
import { GivelifyLabel, GivelifyTextDivider } from '@givelify/givelify-ui';
import { TrackingProvider } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import DashboardMenuTile from 'components/dashboardMenuTile';
import PageTitle from 'components/PageTitle';
import { MenuTitleInfo } from './types';

interface Props {
    title: string;
    description: string;
    menuItems: MenuTitleInfo[];
}

function DashboardMenu(props: Props) {
    const { title, description, menuItems } = props;

    return (
        <TrackingProvider trackPageVisit pageName={title}>
            <PageTitle description={description} title={title} />
            <GivelifyLabel text={title} variant="heading1" />
            <GivelifyTextDivider margin={0} />
            <GivelifyLabel
                marginBottom={32}
                marginTop={32}
                text={description}
                variant="body1"
            />

            <Grid container spacing={3}>
                {menuItems.map((item, index) => (
                    <Grid key={index} item sm={4} xs={12}>
                        <DashboardMenuTile {...item} />
                    </Grid>
                ))}
            </Grid>
        </TrackingProvider>
    );
}

export default DashboardMenu;
