import React, { useMemo } from 'react';
import { FormGroup, makeStyles, Theme } from '@material-ui/core';
import { GivelifyMobileSelectFilterOptionProps } from '../../../mobileSelect/types';
import { GivelifyCheckbox } from '../../../checkbox';

interface CheckboxGroupProps {
    options: GivelifyMobileSelectFilterOptionProps[];
    onChange: (selectedItem: GivelifyMobileSelectFilterOptionProps[]) => void;
    value: GivelifyMobileSelectFilterOptionProps[];
}

const useStyles = makeStyles((theme: Theme) => ({
    formGroup: {
        width: '100%',
    },
    checkbox: {
        marginLeft: 12,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        '&:last-child': {
            borderBottom: 0,
        },
    },
}));

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
    value,
    options,
    onChange,
}) => {
    const classes = useStyles();

    const checkedMap = useMemo(
        () => new Map(value?.map(v => [v?.value, v?.value])),
        [value],
    );

    const onChangeInternal = (
        checked: boolean,
        option: GivelifyMobileSelectFilterOptionProps,
    ) => {
        if (checked) {
            onChange([...(value || []), option]);
        } else {
            const index = value.findIndex(v => v.value === option.value);
            if (index > -1) {
                const valueCopy = [...value];
                valueCopy.splice(index, 1);
                onChange(valueCopy);
            }
        }
    };

    return (
        <FormGroup classes={{ root: classes.formGroup }}>
            {options.map((option, i) => (
                <GivelifyCheckbox
                    key={i}
                    value={option.value}
                    name="label"
                    ariaLabel="label"
                    checked={Boolean(checkedMap.get(option.value))}
                    variant="primary"
                    onChange={checked => onChangeInternal(checked, option)}
                    label={option.label}
                    labelIsClickable
                    className={classes.checkbox}
                />
            ))}
        </FormGroup>
    );
};

export default CheckboxGroup;
