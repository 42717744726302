import { Reducer } from 'redux';
import { IntegrationsState, IntegrationsActions } from './types';

const initialState: IntegrationsState = {
    totalUnmatchedDonors: 0,
    totalUnmatchedEnvelopes: 0,

    progressBarMatchedValue: 0,
    progressBarTotalValue: 0,

    isOpenReportGeneratingDialog: false,
    reportsLoading: false,
    reportsPagination: {
        count: 0,
        total: 0,
        total_pages: 0,
        per_page: '8',
        current_page: 1,
    },
    reports: [],
    page: 0,

    allItemsMatchedNotifications: [],
    integrationSettingsFlag: false,
};

const IntegrationsReducer: Reducer<IntegrationsState, IntegrationsActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case 'LOAD_REPORTS_START':
            return {
                ...state,
                reportsLoading: true,
                page: action.data.page,
            };
        case 'LOAD_REPORTS_SUCCESS': {
            return {
                ...state,
                reports: action.data.data,
                reportsPagination: action.data.pagination,
                reportsLoading: false,
            };
        }
        case 'UPDATE_REPORT': {
            return {
                ...state,
                reports: [
                    ...state.reports.map(x => x.id === action.data.oldReportId ? action.data.report : x)
                ],
            };
        }
        case 'ON_MATCH_DONOR': {
            const { reportId, matchCreated, matchRemoved } = action.data;

            const newState = {
                ...state,
            };

            if (reportId) {
                newState.reports = state.reports.map(r =>
                    r.id === reportId
                        ? {
                              ...r,
                              totalUnmatchedDonors: matchCreated
                                  ? r.totalUnmatchedDonors - 1
                                  : r.totalUnmatchedDonors,
                              reportState: 'regenerate',
                          }
                        : r,
                );

                const report = newState.reports.find(r => r.id === reportId);
                if (
                    report.totalUnmatchedDonors === 0 &&
                    report.totalUnmatchedEnvelopes === 0
                ) {
                    newState.allItemsMatchedNotifications = [
                        {
                            id: reportId,
                            startDate: report.startDate,
                            endDate: report.endDate,
                            downloadLink: report.downloadLink,
                        },
                        ...newState.allItemsMatchedNotifications.filter(
                            n => n.id !== reportId,
                        ),
                    ];
                }
            }

            if (matchCreated) {
                newState.totalUnmatchedDonors = state.totalUnmatchedDonors - 1;
                newState.progressBarMatchedValue =
                    state.progressBarMatchedValue + 1;
            }

            if (matchRemoved) {
                newState.totalUnmatchedDonors = state.totalUnmatchedDonors + 1;
                newState.progressBarMatchedValue =
                    state.progressBarMatchedValue - 1;
            }

            return newState;
        }

        case 'ON_MATCH_ENVELOPE': {
            const { reportId, matchCreated, matchRemoved } = action.data;

            const newState = {
                ...state,
            };

            if (reportId) {
                newState.reports = state.reports.map(r =>
                    r.id === reportId
                        ? {
                              ...r,
                              totalUnmatchedEnvelopes: matchCreated
                                  ? r.totalUnmatchedEnvelopes - 1
                                  : r.totalUnmatchedEnvelopes,
                              reportState: 'regenerate',
                          }
                        : r,
                );

                const report = newState.reports.find(r => r.id === reportId);
                if (
                    report.totalUnmatchedDonors === 0 &&
                    report.totalUnmatchedEnvelopes === 0
                ) {
                    newState.allItemsMatchedNotifications = [
                        {
                            id: reportId,
                            startDate: report.startDate,
                            endDate: report.endDate,
                            downloadLink: report.downloadLink,
                        },
                        ...newState.allItemsMatchedNotifications.filter(
                            n => n.id !== reportId,
                        ),
                    ];
                }
            }

            if (matchCreated) {
                newState.totalUnmatchedEnvelopes =
                    state.totalUnmatchedEnvelopes - 1;
                newState.progressBarMatchedValue =
                    state.progressBarMatchedValue + 1;
            }

            if (matchRemoved) {
                newState.totalUnmatchedEnvelopes =
                    state.totalUnmatchedEnvelopes + 1;
                newState.progressBarMatchedValue =
                    state.progressBarMatchedValue - 1;
            }

            return newState;
        }
        case 'LOAD_TOTAL_MATCH_STATE_START': {
            return {
                ...state,
                totalUnmatchedDonors: 0,
                totalUnmatchedEnvelopes: 0,
            };
        }
        case 'LOAD_TOTAL_MATCH_STATE_SUCCESS': {
            return {
                ...state,
                totalUnmatchedDonors:
                    action.data.type === 'donors'
                        ? action.data.count
                        : state.totalUnmatchedDonors,
                totalUnmatchedEnvelopes:
                    action.data.type === 'envelopes'
                        ? action.data.count
                        : state.totalUnmatchedEnvelopes,
            };
        }
        case 'LOAD_MATCH_PROGRESS_START': {
            return {
                ...state,
                progressBarMatchedValue: 0,
                progressBarTotalValue: 0,
            };
        }
        case 'LOAD_DONORS_MATCH_PROGRESS_SUCCESS': {
            return {
                ...state,
                progressBarMatchedValue: action.data.totalMatched,
                progressBarTotalValue: action.data.total,
                reports: action.data.reportId
                    ? state.reports.map(r =>
                          r.id === action.data.reportId
                              ? {
                                    ...r,
                                    totalUnmatchedDonors:
                                        action.data.total -
                                        action.data.totalMatched,
                                }
                              : r,
                      )
                    : state.reports,
            };
        }
        case 'LOAD_ENVELOPES_MATCH_PROGRESS_SUCCESS':
            return {
                ...state,
                progressBarMatchedValue: action.data.totalMatched,
                progressBarTotalValue: action.data.total,
                reports: action.data.reportId
                    ? state.reports.map(r =>
                          r.id === action.data.reportId
                              ? {
                                    ...r,
                                    totalUnmatchedEnvelopes:
                                        action.data.total -
                                        action.data.totalMatched,
                                }
                              : r,
                      )
                    : state.reports,
            };
        case 'DOWNLOAD_REPORT':
            return {
                ...state,
                reports: state.reports.map(r =>
                    r.id === action.data.reportId
                        ? {
                              ...r,
                              lastDownloadedByName: action.data.userName,
                              downloadedAt: action.data.date,
                          }
                        : r,
                ),
            };

        case 'SHOW_REPORT_GENERATING_DIALOG':
            return {
                ...state,
                isOpenReportGeneratingDialog: action.data.show,
            };

        case 'CLOSE_MATCH_NOTIFICATION': {
            return {
                ...state,
                allItemsMatchedNotifications: state.allItemsMatchedNotifications.filter(
                    n => n.id !== action.data.reportId,
                ),
            };
        }
        case 'SET_INTEGRATION_SETTINGS':
            return {
                ...state,
                integrationSettingsFlag: action.data.saved,
            };
        default:
            return state;
    }
};

export default IntegrationsReducer;
