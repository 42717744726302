import { ErrorResponse } from '@givelify/givelify-ui';
import { EnvelopeDistribution } from '../types';
import {
    SetEnvelopesCurrentLoading,
    SET_ENVELOPES_CURRENT_LOADING,
    SetEnvelopesCurrentSuccess,
    SET_ENVELOPES_CURRENT_SUCCESS,
    SetEnvelopesCurrentFailed,
    SET_ENVELOPES_CURRENT_FAILED,
} from './types';

export const setEnvelopesCurrentLoading: SetEnvelopesCurrentLoading = {
    type: SET_ENVELOPES_CURRENT_LOADING,
};

export const setEnvelopesCurrentSuccess = (data: {
    data: EnvelopeDistribution[];
}): SetEnvelopesCurrentSuccess => ({
    type: SET_ENVELOPES_CURRENT_SUCCESS,
    data,
});

export const setEnvelopesCurrentFailed = (
    error: ErrorResponse,
): SetEnvelopesCurrentFailed => ({
    type: SET_ENVELOPES_CURRENT_FAILED,
    error,
});
