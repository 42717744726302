import { makeStyles, Theme } from '@material-ui/core';

export const useFundraisingToolsStyle = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: 19,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentDivider: {
    marginBottom: 60,
  },
  noBreadcrumb: {
    marginBottom: 32,
  },
  contentContainer: {
    position: 'relative',
  },
  helpNoteContainer: {
    textAlign: 'center',
    margin: '64px 0 50px 0',
  },
  link: {
    color: '#715AFF',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '16px',
    textDecoration: 'none',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#5F48F0',
    },
  },
}));
