import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const EmptyStateWrapper = styled('div')({
    width: '100%',
    height: 'calc(100% - 40px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: DesignTokens.color.textWhite,
    gap: '8px',
    zIndex: 900,
});

export const TextWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
});

export const EmptyStateImg = styled('img')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        width: '48px',
        height: '48px',
    },
}));

export const EmptyStateText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '14px',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export const BoldEmptyStateText = styled(EmptyStateText)(({ theme }) => ({
    fontFamily: `${DesignTokens.font.areaNormalExtrabold}, sans-serif`,
    fontSize: '22px',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
