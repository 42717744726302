import { Reducer } from 'redux';
import {
    CLEAR_INTEGRATION,
    AutoIntegrationState,
    IntegrationSecondActions,
    SET_INTEGRATION,
} from './types';

export const initialAutoIntegrationState: AutoIntegrationState = {
    data: {
        data: {
            doneeId: 0,
            type: 'F1',
            initialSetupStatus: '',
        },
        unmatchedEnvelopes: 0,
        unmatchedDonors: 0,
    },
};

export const AutoIntegrationReducer: Reducer<
    AutoIntegrationState,
    IntegrationSecondActions
> = (state = initialAutoIntegrationState, action) => {
    switch (action.type) {
        case SET_INTEGRATION:
            return {
                ...state,
                data: action.data,
            };
        case CLEAR_INTEGRATION:
            return {
                ...state,
                data: initialAutoIntegrationState.data,
            };
        case 'LOAD_TOTAL_UNMATCHED_DONORS_STATE_SUCCESS':
            return {
                ...state,
                unmatchedDonors: action.data.count,
            };
        case 'LOAD_TOTAL_UNMATCHED_ENVELOPES_STATE_SUCCESS':
            return {
                ...state,
                unmatchedEnvelopes: action.data.count,
            };
        default:
            return state;
    }
};

export default AutoIntegrationReducer;
