import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgRightChevronBig = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>right-chevron-big</title>
        <path
            clipRule="evenodd"
            d="M8.79289 18.2071C8.40237 17.8166 8.40237 17.1834 8.79289 16.7929L13.5858 12L8.79289 7.20711C8.40237 6.81658 8.40237 6.18342 8.79289 5.79289C9.18342 5.40237 9.81658 5.40237 10.2071 5.79289L15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12C16 12.2652 15.8946 12.5196 15.7071 12.7071L10.2071 18.2071C9.81658 18.5976 9.18342 18.5976 8.79289 18.2071Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const RightChevronBigIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgRightChevronBig}
        fontSize={fontSize}
    />
);
