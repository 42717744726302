import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    notification: {
        marginBottom: 40,
    },
    link: {
        textDecoration: 'none',
        color: theme.colors?.primary || theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.colors?.accentDarkBlue || theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
    header: {
        textAlign: 'center',
        marginBottom: 16,
    },
    subheader: {
        marginBottom: 32,
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
        },
    },
    controls: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: '1fr 1fr auto',
        borderRadius: 4,
        backgroundColor: 'rgba(246, 246, 246, 0.8)',
        padding: 8,
        marginBottom: 16,
        marginLeft: -16,
        marginRight: -16,
    },
    searchButton: {
        borderRadius: 4,
        width: 56,
        height: 56,
        [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 40,
            minWidth: 40,
            minHeight: 40,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 32,
    },
    listWrapper: {
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(248px, 1fr))',
        gridAutoRows: '1fr',
    },
    campusListItemWrapper: {
        border: '1px solid #E3E3E3',
        borderRadius: 10,
        boxShadow: '0px 0px 8px rgba(131, 131, 131, 0.3)',
        padding: '24px 16px 16px',
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
        },
        '&:hover': {
            border: `1px solid ${theme.colors.neutralGrey75}`,
            cursor: 'pointer',
        },
    },
    listItemText: {
        flex: 1,
    },
    listItemHeader: {
        marginBottom: 8,
    },
    listItemAction: {
        alignSelf: 'center',
        [theme.breakpoints.up('md')]: {
            marginTop: 32,
            alignSelf: 'end',
        },
    },
    chip: {
        backgroundColor: '#DCF1E5',
        borderRadius: 20,
        padding: theme.spacing('3px', '8px'),
        color: theme.colors.neutralGrey,
    },
    cantFindListItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        border: '1px dashed #E3E3E3',
        borderRadius: 10,
    },
    locationSelect: {
        height: 48,
        backgroundColor: 'white',
    },
}));

export default useStyles;
