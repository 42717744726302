import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgUpArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>up-arrow</title>
        <path
            d="M12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L4.92893 10.6569C4.53841 11.0474 4.53841 11.6805 4.92893 12.0711C5.31946 12.4616 5.95262 12.4616 6.34315 12.0711L12 6.41421L17.6569 12.0711C18.0474 12.4616 18.6805 12.4616 19.0711 12.0711C19.4616 11.6805 19.4616 11.0474 19.0711 10.6569L12.7071 4.29289ZM13 20L13 5L11 5L11 20L13 20Z"
            fill="current"
        />
    </svg>
);

export const UpArrowIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgUpArrow}
        fontSize={fontSize}
    />
);
