import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 60,

    '&::-webkit-scrollbar': {
      width: 1,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 40px)',
      overflowY: 'auto',
      margin: 0,
      padding: 16,
      paddingRight: 12,
      display: 'block',
    },
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: '89px',
    paddingTop: 0,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  description: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  sectionTitle: {
    marginBottom: 16,
  },
  number: {
    marginRight: 8,
  },
  radioBox: {
    display: 'flex',
    margin: '16px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  radioButton: {
    marginRight: 32,
  },
  downloadButton: {
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      margin: '0 auto 32px auto',
    },
  },
  preview: {
    color: '#676E77',
  },
  previewBox: {
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    marginTop: 4,
    marginBottom: 16,
    display: 'inline-flex',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      display: 'block',
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  previewImage: {
    margin: '40px 75px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0',
      width: '100%',
    },
  },
  instruction: {
    margin: '0 0 16px 0',
  },
}));
