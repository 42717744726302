import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    permissionCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    detailText: {
        fontSize: 16,
        color: theme.colors.primaryDarkGray,
    },
}));
