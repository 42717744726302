import React, { useMemo } from 'react';
import { Avatar, styled } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { OverridableStringUnion } from '@mui/types';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { GivelifyAvatarProps, GivelifyAvatarViewProps } from './types';

const GivelifyAvatarView: React.FC<GivelifyAvatarViewProps> = ({
    src,
    text,
    textSize,
    className,
    'data-testid': dataTestId,
    ...props
}) => {
    return (
        <Avatar
            {...props}
            className={className}
            data-testid={dataTestId}
            src={src}
        >
            {text ? (
                <GivelifyLabel
                    color={DesignTokens.color.textWhite}
                    text={text}
                    variant={textSize}
                />
            ) : null}
        </Avatar>
    );
};

const StyledAvatar = styled(GivelifyAvatarView, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'backgroundColor',
})(({ size, backgroundColor }) => ({
    width: size,
    height: size,
    backgroundColor: backgroundColor,
}));

export const GivelifyAvatar: React.FC<GivelifyAvatarProps> = ({
    color,
    size,
    ...props
}) => {
    const avatarSize: string = useMemo(() => {
        switch (size) {
            case 'xLarge':
                return DesignTokens.measurement.avatarBoxSizeExtraLarge;
            case 'large':
                return DesignTokens.measurement.avatarBoxSizeLarge;
            case 'mediumL':
                return DesignTokens.measurement.avatarBoxSizeMediumLarge;
            case 'small':
                return DesignTokens.measurement.avatarBoxSizeSmall;
            case 'xSmall':
                return DesignTokens.measurement.avatarBoxSizeExtraSmall;
            case 'medium':
            default:
                return DesignTokens.measurement.avatarBoxSizeMedium;
        }
    }, [size]);
    const avatarBg: string = useMemo(() => {
        switch (color) {
            case 'black':
                return DesignTokens.color.avatarBlack;
            case 'eucalyptus':
                return DesignTokens.color.avatarEucalyptus;
            case 'grey':
                return DesignTokens.color.avatarGrey;
            case 'navy':
                return DesignTokens.color.avatarNavy;
            case 'camel':
                return DesignTokens.color.avatarCamel;
            case 'red':
                return DesignTokens.color.avatarRed;
            case 'pink':
                return DesignTokens.color.avatarPink;
            case 'green':
                return DesignTokens.color.avatarGreen;
            case 'gold':
                return DesignTokens.color.avatarGold;
            case 'brown':
                return DesignTokens.color.avatarBrown;
            case 'burgundy':
                return DesignTokens.color.avatarBurgundy;
            case 'magenta':
                return DesignTokens.color.avatarMagenta;
            case 'purple':
                return DesignTokens.color.avatarPurple;
            case 'blue':
                return DesignTokens.color.avatarBlue;
            case 'teal':
                return DesignTokens.color.avatarTeal;
            case 'violet':
                return DesignTokens.color.avatarViolet;
            default:
                if (props.src) {
                    return '';
                } else {
                    return DesignTokens.color.avatarViolet;
                }
        }
    }, [color, props.src]);
    const textSize: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    > = useMemo(() => {
        switch (avatarSize) {
            case 'xLarge':
                return 'heading1';
            case 'large':
                return 'heading2S';
            case 'xSmall':
                return 'body2';
            case 'small':
            case 'medium':
            default:
                return 'body1';
        }
    }, [avatarSize]);

    return (
        <StyledAvatar
            {...props}
            backgroundColor={avatarBg}
            size={avatarSize}
            textSize={textSize}
        />
    );
};
