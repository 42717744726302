import { createStyles, makeStyles } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles';

export const swipeCardsStyles = makeStyles(() =>
    createStyles({
        container: {
            transition: 'left 0.5s',
        },
        arrowHolder: {
            width: (props: { cardSpacing: number; arrowSpacing: number }) =>
                props.cardSpacing,
            '& .inside': {
                width: (props: { cardSpacing: number; arrowSpacing: number }) =>
                    props.arrowSpacing,
                position: 'relative',
                background: 'yellow',
                top: '50%',
            } as CreateCSSProperties,
        },
        buttonLeft: {
            position: 'absolute',
            right: (props: { cardSpacing: number; arrowSpacing: number }) =>
                props.arrowSpacing === props.cardSpacing
                    ? props.cardSpacing
                    : -props.cardSpacing,
            transform: (props: { cardSpacing: number; arrowSpacing: number }) =>
                props.arrowSpacing === props.cardSpacing
                    ? 'translate(50%, -50%)'
                    : 'translate(75%, -50%)',
            opacity: 0.8,
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#FFFFFF',
            width: 40,
            height: 40,
            '&:hover': {
                opacity: 1,
                backgroundColor: '#FFFFFF',
            },
            zIndex: 10,
        },
        buttonRight: {
            position: 'absolute',
            right: 0,
            transform: (props: { cardSpacing: number; arrowSpacing: number }) =>
                props.arrowSpacing === props.cardSpacing
                    ? 'translate(50%, -50%)'
                    : 'translate(25%, -50%)',
            opacity: 0.8,
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#FFFFFF',
            width: 40,
            height: 40,
            '&:hover': {
                opacity: 1,
                backgroundColor: '#FFFFFF',
            },
            zIndex: 10,
        },
        hide: {
            display: 'none',
        },
        arrowRight: {
            fontSize: 16,
        },
        arrowLeft: {
            fontSize: 16,
            transform: 'rotate(-180deg)',
        },
    }),
);
