import { DesignTokens } from '@givelify/ui';
import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    contentModal: {
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
        },
    },
    showHideModal: {
        justifyContent: 'flex-start',
    },
    closeModal: {
        padding: theme.spacing(2),
        borderRight: '1px solid',
        borderRightColor: DesignTokens.color.dividerPrimaryDark,
    },
    modalMobileTitle: {
        paddingLeft: theme.spacing(3),
    },
    showHideContent: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 4),
        },
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: DesignTokens.shadow.divider2,
    },
    modalTitle: {
        textAlign: 'center',
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5),
        },
    },
    switchImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5),
        },
    },
    switchArrowImg: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    switchContent: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        },
    },
    switchTitle: {
        marginBottom: theme.spacing(3),
    },
    switchBottom: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(8, 0, 6, 0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: theme.spacing(2),
        },
    },
    hideEnvelopeBtn: {
        [theme.breakpoints.down('sm')]: {
            order: 0,
            width: '100%',
            marginBottom: theme.spacing(3),
        },
    },
    cancelBtn: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
            width: '100%',
        },
    },
    editorContainer: {
        padding: theme.spacing(0, 7),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    alwaysRadio: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiButtonBase-root': {
                paddingLeft: 0,
            },
        },
    },
    mobileSmallTitle: {
        [theme.breakpoints.down('sm')]: {
            display: 'inline',
        },
    },
    specificDate: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: 16,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    dateFieldLabelWrapper: {
        marginBottom: 26,
    },
    selectDate: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: 0,
        top: -16,
        
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            flexWrap: 'wrap',
            marginTop: theme.spacing(3),
        },
    },
    selectDateItem: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            justifyContent: 'flex-start',
            width: '100%',
        },
    },
    mobileDate: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 45,
            margin: theme.spacing(0, 3, 0, 0),
            textTransform: 'capitalize',
        },
    },
    mobileDatePicker: {
        flexGrow: 1,
    },
    hidden: {
        display: 'none',
    },
}));
