import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSuccessColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="38"
        width="38"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 38 38"
    >
        <circle
            cx="19"
            cy="19"
            fill="#E8F9ED"
            r="17.8125"
            stroke="white"
            strokeWidth="1.1875"
        />
        <path
            clipRule="evenodd"
            d="M27.9062 19C27.9062 23.9188 23.9188 27.9062 19 27.9062C14.0812 27.9062 10.0938 23.9188 10.0938 19C10.0938 14.0812 14.0812 10.0938 19 10.0938C23.9188 10.0938 27.9062 14.0812 27.9062 19ZM29.6875 19C29.6875 24.9025 24.9025 29.6875 19 29.6875C13.0975 29.6875 8.3125 24.9025 8.3125 19C8.3125 13.0975 13.0975 8.3125 19 8.3125C24.9025 8.3125 29.6875 13.0975 29.6875 19ZM23.3842 17.237C23.7222 16.8797 23.7065 16.316 23.3492 15.978C22.9918 15.64 22.4281 15.6556 22.0901 16.013L18.4593 19.8516L16.6279 18.1991C16.2627 17.8695 15.6995 17.8985 15.37 18.2636C15.0405 18.6288 15.0694 19.192 15.4346 19.5215L17.9119 21.7568C18.271 22.0809 18.8231 22.059 19.1555 21.7076L23.3842 17.237Z"
            fill="#087D26"
            fillRule="evenodd"
        />
    </svg>
);

export const SuccessColoredIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSuccessColored}
        fontSize={fontSize}
    />
);
