import React from 'react';
import { Typography } from '@mui/material';
import { GivelifyLabelProps } from './types';

export const GivelifyLabel: React.FC<GivelifyLabelProps> = ({
    text,
    variant = 'body1',
    bold,
    children,
    //wrap is exluded from props
    //eslint-disable-next-line
    wrap,
    fontWeight,
    ...props
}) => {
    return (
        <Typography
            {...props}
            fontWeight={bold ? 'bold' : fontWeight}
            variant={variant}
            variantMapping={{
                heading1: 'h1',
                heading2: 'h2',
                heading3: 'h3',
                heading1M: 'h1',
                heading2M: 'h2',
                heading3M: 'h3',
                heading1S: 'h1',
                heading2S: 'h2',
                heading3S: 'h3',
                caption1: 'div',
                caption2: 'div',
            }}
        >
            {children ? children : text}
        </Typography>
    );
};
