import React, { useMemo, useState, useEffect } from 'react';
import { GivelifyLabel, isSucceeded, isFailed } from '@givelify/givelify-ui';
import { Donee } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { useInvokeApi, InvokeApiErrorResponse } from 'hooks/useInvokeApi';
import isEqual from 'lodash/isEqual';
import { EditOption } from 'pages/settings/components/EditOption';
import { useDispatch } from 'react-redux';
import { setDoneeAddress } from 'store/donee/actions';

import { AddressInfo } from 'types/orgInfoTypes';

import { useAdvancedTranslation } from 'utils/i18n';
import { aboutStyle } from '../style';
import AddressSectionEditor from './AddressSectionEditor';
import { TimeZoneNote } from './TimeZoneNote';

interface AddressSectionProps {
    donee: Donee;
    isReadOnly: boolean;
}

const useStyles = makeStyles(() => ({
    addressContent: {
        marginTop: 24,
    },
}));

const AddressSection: React.FC<AddressSectionProps> = ({
    donee,
    isReadOnly,
}) => {
    const commonStyle = aboutStyle();
    const style = useStyles();
    const { at, t } = useAdvancedTranslation();

    const copy = useMemo(
        () => ({
            title: at('pages.settings.organization-info.physical.title'),
            description1: t(
                'pages.settings.organization-info.physical.description-1',
            ),
            description2: t(
                'pages.settings.organization-info.physical.description-2',
            ),
        }),
        [t, at],
    );

    const dispatch = useDispatch();

    const [addressData, setAddressData] = useState({
        address: '',
        zip: '',
        state: '',
        city: '',
    });
    const [editorEnabledState, setEditorEnabledState] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    useEffect(() => {
        setAddressData({
            address: donee.address ? donee.address : '',
            city: donee.city ? donee.city : '',
            zip: donee.zip ? donee.zip : '',
            state: donee.state ? donee.state : '',
        });
    }, [donee]);

    const [updateRequestState, updateRequest] = useInvokeApi<unknown>();

    const onSubmit = (newData: AddressInfo) => {
        if (!isEqual(addressData, newData)) {
            updateRequest('PATCH', `/donees/${donee.id.toString()}`, {
                physical_address: {
                    street: newData.address,
                    city: newData.city,
                    state: newData.state,
                    postal: newData.zip,
                },
            });
            setSaveError(undefined);
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            const dataAsAny = updateRequestState.data as {
                address: string;
                city: string;
                state: string;
                zip: string;
                phone: string;
                updated_at: string;
                timezone_long: string;
                timezone_short: string;
            };
            dispatch(
                setDoneeAddress(
                    donee.id,
                    dataAsAny.address,
                    dataAsAny.city,
                    dataAsAny.state,
                    dataAsAny.zip,
                    dataAsAny.phone,
                    new Date(dataAsAny.updated_at),
                    dataAsAny.timezone_long,
                    dataAsAny.timezone_short,
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, donee.id]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
    };

    const saving = updateRequestState.type === 'REQUEST_START';

    return (
        <div className={commonStyle.section}>
            <GivelifyLabel
                bold
                className={commonStyle.subtitle}
                text={copy.title}
                variant="heading3"
            />
            <GivelifyLabel
                className={commonStyle.body}
                text={copy.description1}
                variant="body2"
            />
            <GivelifyLabel
                className={commonStyle.body}
                text={copy.description2}
                variant="body2"
            />
            {editorEnabledState ? (
                <AddressSectionEditor
                    data={addressData}
                    error={saveError}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            ) : (
                <div className={style.addressContent}>
                    <GivelifyLabel
                        className={commonStyle.body}
                        text={addressData.address}
                        variant="body1"
                    />
                    <GivelifyLabel
                        className={commonStyle.body}
                        text={`${addressData.city}, ${addressData.state} ${addressData.zip}`}
                        variant="body1"
                    />
                </div>
            )}
            {!editorEnabledState ? <TimeZoneNote /> : null}
            <div className={commonStyle.editButtonContainer}>
                {!isReadOnly && !editorEnabledState && (
                    <EditOption onClick={onEditClick} testId="address-edit" />
                )}
            </div>
        </div>
    );
};

export default AddressSection;
