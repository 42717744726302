import React from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { styled } from '@mui/material';
import { MenuItem } from 'store/system/types';

type NavMenuItemRootProps = {
    active?: boolean;
    variant?:
        | 'desktop'
        | 'tablet-collapsed'
        | 'tablet-expanded'
        | 'mobile'
        | 'mobile-expanded';
};
const NavMenuItemRoot = styled('a', {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'variant',
})<NavMenuItemRootProps>(({ active, variant, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '48px',
    borderRadius: DesignTokens.measurement.borderRadiusS,
    color: DesignTokens.color.textSecondary,
    fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
    '&:link': {
        textDecoration: 'none',
    },
    '&:hover': {
        backgroundColor: DesignTokens.color.backgroundComponentSecondaryAccent,
    },
    ...(active && {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
        color: DesignTokens.color.textAccentDefault,
    }),
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    '.nav-icn-root': {
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.nav-btn': {
        marginLeft: 'auto',
    },
    ...(variant === 'tablet-collapsed' && {
        width: '40px',
        height: '40px',
        padding: 0,
        justifyContent: 'center',
        '.nav-lbl': {
            display: 'none',
        },
        '.nav-btn': {
            display: 'none',
        },
    }),
    ...(variant === 'tablet-expanded' && {
        width: '100%',
        height: '40px',
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-start',
        '.nav-lbl': {
            display: 'block',
        },
        '.nav-btn': {
            display: 'inline-flex',
            marginLeft: 'auto',
        },
    }),
    ...(variant === 'mobile' && {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(1, 0),
        gap: theme.spacing(0.5),
        '.nav-icn-root': {
            width: '20px',
            height: '20px',
            fontSize: '20px',
        },
        '.nav-lbl': {
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 800,
        },
        '.nav-btn': {
            display: 'none',
            marginLeft: 'auto',
        },
    }),
}));

export type NavMenuItemProps = {
    item: MenuItem;
    onClick: (link: string, label: string) => void;
    onExpandClick: (id: string) => void;
    variant?:
        | 'desktop'
        | 'tablet-collapsed'
        | 'tablet-expanded'
        | 'mobile'
        | 'mobile-expanded';
};

export const NavMenuItem: React.FC<NavMenuItemProps> = ({
    item,
    onClick,
    onExpandClick,
    variant = 'desktop',
}) => {
    const {
        id,
        label,
        labelShort,
        alternativeLable,
        url,
        active,
        childIsActive,
        icon,
        nested,
        isExpanded,
        defaultExpanded,
    } = item;
    const formattedId = id.replace(/\s/g, '');
    const onNavLinkClick = React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            event.stopPropagation();
            onClick(url, label);
        },
        [label, url, onClick],
    );
    const onNavExpandClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();
            onExpandClick(id);
        },
        [onExpandClick, id],
    );
    return (
        <NavMenuItemRoot
            active={active || (childIsActive && variant === 'tablet-collapsed')}
            data-testid={formattedId}
            href={url}
            id={formattedId}
            onClick={onNavLinkClick}
            variant={variant}
        >
            <div className="nav-icn-root">
                {icon ? (
                    <GivelifyIcon
                        className="nav-icn"
                        data-testid={`${formattedId}-icon`}
                        id="nav-menu-item-icn"
                        variant={icon}
                    />
                ) : null}
            </div>
            <GivelifyLabel
                noWrap
                className="nav-lbl"
                color="inherit"
                data-testid={`${formattedId}-label`}
                id="nav-menu-item-lbl"
                text={
                    variant === 'mobile' || variant === 'mobile-expanded'
                        ? labelShort
                            ? labelShort
                            : alternativeLable
                            ? alternativeLable
                            : label
                        : label
                }
                variant="body2B"
            />
            {nested ? (
                <GivelifyButton
                    className="nav-btn"
                    data-testid={`${formattedId}-expand`}
                    id={`${formattedId}-expand`}
                    onClick={onNavExpandClick}
                    size="small"
                    variant="icon"
                >
                    <GivelifyIcon
                        data-testid={
                            isExpanded || defaultExpanded
                                ? 'close-icon'
                                : 'open-icon'
                        }
                        variant={
                            isExpanded || defaultExpanded
                                ? 'down-chevron'
                                : 'right-chevron'
                        }
                    />
                </GivelifyButton>
            ) : null}
        </NavMenuItemRoot>
    );
};
