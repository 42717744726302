import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';

const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
    [theme.breakpoints.down('mobile')]: {
        gridTemplateColumns: '1fr',
        '& :first-of-type': {
            order: 1,
        },
        width: '100%',
    },
}));

const StyledIcon = styled(GivelifyIcon)(() => ({
    marginTop: 16,
    marginBottom: 32,
}));

interface CancelModalProps {
    page: 'bankVerification' | 'taxId' | 'primaryRepresentative';
    onSubmit: () => void;
    onCancel: () => void;
}

const CancelModal: React.FC<CancelModalProps> = ({
    onCancel,
    onSubmit,
    page,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            header: {
                bankVerification: t(
                    'captivePortal.cancelModal.bankVerificationHeader',
                ),
                taxId: t('captivePortal.cancelModal.taxIdHeader'),
                primaryRepresentative: t(
                    'captivePortal.cancelModal.primaryRepHeader',
                ),
            },
            description: t('captivePortal.cancelModal.description'),
            description2: t('captivePortal.cancelModal.description2'),
            returnToSetup: t('captivePortal.cancelModal.returnToSetup'),
            goBack: t('captivePortal.cancelModal.goBack'),
            cancel: t('captivePortal.cancelModal.cancel'),
        }),
        [t],
    );

    return (
        <GivelifyModal
            open
            showCloseButton
            onClose={onCancel}
            onSubmit={onSubmit}
        >
            <Grid container alignItems="center" flexDirection="column">
                <StyledIcon variant="alert" />
                <GivelifyLabel
                    align="center"
                    marginBottom={2}
                    text={copy.header[page]}
                    variant="heading2S"
                />
                <GivelifyLabel
                    align="center"
                    text={copy.description}
                    variant="body2"
                />
                <GivelifyLabel
                    marginBottom={5}
                    text={copy.description2}
                    variant="body2"
                />
                <ButtonsWrapper>
                    <GivelifyButton
                        fullWidth
                        onClick={onCancel}
                        text={
                            page === 'primaryRepresentative'
                                ? copy.goBack
                                : copy.returnToSetup
                        }
                        variant="secondary"
                    />
                    <GivelifyButton
                        fullWidth
                        onClick={onSubmit}
                        text={copy.cancel}
                        variant="primary"
                    />
                </ButtonsWrapper>
            </Grid>
        </GivelifyModal>
    );
};

export default CancelModal;
