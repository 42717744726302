import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

export const Description = styled(GivelifyLabel)(({ theme }) => ({
    color: DesignTokens.color.textSecondary,
}));
