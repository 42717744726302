import { GivelifyLabel, GivelifyPaper } from '@givelify/ui';
import { styled } from '@mui/material';

export const Heading = styled(GivelifyLabel, {
    shouldForwardProp: (prop) => prop !== 'isEmpty',
})<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
    borderBottom: isEmpty ? 'none' : '1px solid #D8D8D8',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const TableWrapper = styled(GivelifyPaper, {
    shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading: boolean }>(({ theme, isLoading }) => ({
    paddingBottom: theme.spacing(isLoading ? 3 : 0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
}));
