import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgQuickbooks = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={43}
        width={38}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 38 43"
    >
        <path
            d="M0 32.8127C3.06521 35.128 8.66871 36.2101 14.25 36.2101C19.8312 36.2101 25.4347 35.128 28.4999 32.8127V36.2101C28.4999 39.0187 22.1171 41.3022 14.25 41.3022C6.3828 41.3022 0 39.0187 0 36.2101V32.8127ZM23.7499 10.7496C15.8828 10.7496 9.49998 8.46611 9.49998 5.65752C9.49998 2.84893 15.8828 0.56543 23.7499 0.56543C31.6171 0.56543 37.9999 2.84893 37.9999 5.65752C37.9999 8.46611 31.6171 10.7496 23.7499 10.7496ZM0 24.4665C3.06521 27.1716 8.67618 28.5719 14.25 28.5719C19.8238 28.5719 25.4347 27.1716 28.4999 24.4665V28.5719C28.4999 31.3805 22.1171 33.664 14.25 33.664C6.3828 33.664 0 31.3805 0 28.5719V24.4665ZM30.8749 25.3417V20.2814C33.7472 19.7324 36.278 18.8413 37.9999 17.5364V20.9338C37.9999 22.8195 35.1276 24.4585 30.8749 25.3417ZM14.25 13.2957C22.1171 13.2957 28.4999 16.144 28.4999 19.6608C28.4999 23.1775 22.1171 26.0259 14.25 26.0259C6.3828 26.0259 0 23.1775 0 19.6608C0 16.144 6.3828 13.2957 14.25 13.2957ZM30.5261 17.7751C29.873 15.8894 28.2624 14.3618 26.073 13.224C30.8378 12.9695 35.3652 11.8954 37.9999 9.89827V13.2957C37.9999 15.2291 34.9792 16.9158 30.5261 17.7751Z"
            fill="current"
        />
    </svg>
);

export const QuickbooksIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgQuickbooks}
        fontSize={fontSize}
    />
);
