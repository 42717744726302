import React, { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface EditOptionProps {
    onClick: () => void;
    text?: string;
    testId?: string;
}

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: 24,
    },
}));

export const EditOption: React.FC<EditOptionProps> = (
    props: EditOptionProps,
) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            edit: t('labels.edit'),
        }),
        [t],
    );
    const { icon } = useStyles();
    return (
        <GivelifyButton
            area-label="Edit"
            classes={{ startIcon: icon }}
            data-testid={props.testId}
            marginLeft="auto"
            onClick={props.onClick}
            startIconVariant="edit"
            text={props.text ? props.text : copy.edit}
            variant="link"
        />
    );
};
