import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyButton, GivelifyLink } from '@givelify/ui';
import { Stack, Grid, Divider, useMediaQuery, useTheme } from '@mui/material';
import { getCcbUrl } from '../../../IntegrationContent/TabsContent/AutoIntegrationContent/types';
import { IntegrationTypes } from '../../../types';

type Screen1Props = {
    //eslint-disable-next-line
    t: (key: any, d?: any) => string;
    type: IntegrationTypes;
    onSubmit: () => void;
};

type StepProps = {
    prefix: string;
    //eslint-disable-next-line
    t: (key: any, d?: any) => string;
};

type TextStepProps = {
    title: string;
    line: string;
};

const TextStep: React.FC<TextStepProps> = ({ title, line }) => {
    return (
        <Stack alignItems="center" paddingBottom={5} paddingTop={3}>
            <GivelifyLabel
                marginBottom={2}
                text={title}
                textAlign="center"
                variant="heading3S"
            />
            <GivelifyLabel
                maxWidth={210}
                text={line}
                textAlign="center"
                variant="body2"
            />
        </Stack>
    );
};

const TextStep1: React.FC<StepProps> = ({ t, prefix }) => {
    const copy = useMemo(
        () => ({
            title: t(`${prefix}.screen1.step1.title`),
            line: t(`${prefix}.screen1.step1.line`),
        }),
        [prefix, t],
    );
    return <TextStep line={copy.line} title={copy.title} />;
};

const TextStep2: React.FC<StepProps> = ({ t, prefix }) => {
    const copy = useMemo(
        () => ({
            title: t(`${prefix}.screen1.step2.title`),
            line: t(`${prefix}.screen1.step2.line`),
        }),
        [prefix, t],
    );
    return <TextStep line={copy.line} title={copy.title} />;
};

const Step1Icon = () => <img alt="" src="/ccbIcons/step1.svg" />;
const Step2Icon = () => <img alt="" src="/ccbIcons/step2.svg" />;

const LargeScreenLayout: React.FC<StepProps> = (props) => (
    <Stack maxWidth={800}>
        <Grid container alignItems="center" spacing={1}>
            <Grid item sm />
            <Grid
                item
                sm={4}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Step1Icon />
            </Grid>
            <Grid item sm={2}>
                <Divider />
            </Grid>
            <Grid
                item
                sm={4}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Step2Icon />
            </Grid>
            <Grid item sm />
            <Grid item sm={12}>
                <Stack flexDirection="row">
                    <Stack width="100%">
                        <TextStep1 {...props} />
                    </Stack>
                    <Stack width="100%">
                        <TextStep2 {...props} />
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    </Stack>
);

const SmallScreenLayout: React.FC<StepProps> = (props) => (
    <Stack alignItems="center" flexDirection="column">
        <Step1Icon />
        <TextStep1 {...props} />
        <Step2Icon />
        <TextStep2 {...props} />
    </Stack>
);

export const Screen1: React.FC<Screen1Props> = ({ t, type, onSubmit }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const translationPrefix = type.integrationType.toLowerCase();

    const text = useMemo(
        () => ({
            heading: t('title.heading', {
                type: type && (type.integrationSystem || type.name),
            }),
            clickHere: t(`${translationPrefix}.screen1.clickHere`),
            line: t(`${translationPrefix}.screen1.line`),
            submitText: t(`${translationPrefix}.screen1.submitText`),
        }),
        [t, translationPrefix, type],
    );

    return (
        <Stack alignItems="center" flexDirection="column">
            <GivelifyLabel
                marginBottom={5}
                text={text.heading}
                textAlign="center"
                variant={isMobile ? 'heading1S' : 'heading1M'}
            />
            {isMobile ? (
                <SmallScreenLayout prefix={translationPrefix} t={t} />
            ) : (
                <LargeScreenLayout prefix={translationPrefix} t={t} />
            )}
            <Stack
                justifyContent="center"
                marginBottom={10}
                maxWidth={800}
                paddingLeft={isMobile ? 2 : 10}
                paddingRight={isMobile ? 2 : 10}
            >
                <GivelifyLabel
                    fontWeight={400}
                    textAlign="center"
                    variant="body2"
                >
                    <GivelifyLink
                        fontWeight={400}
                        href={getCcbUrl}
                        rel="noopener"
                        target="_blank"
                        text={text.clickHere}
                        variant="body2"
                    />
                    {text.line}
                </GivelifyLabel>
            </Stack>
            <GivelifyButton
                name="submit"
                onClick={onSubmit}
                size="large"
                text={text.submitText}
                variant="primary"
            />
        </Stack>
    );
};
