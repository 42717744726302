import React, { useMemo } from 'react';
import { PAGE_NAME } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import SettingsPage from '../SettingsPage';
import MissionStatementPage from './components/MissionStatementPage';

const MissionStatement: React.FC = () => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            title: t('pages.settings.mission-statement.title'),
            caption: t('pages.settings.mission-statement.caption'),
        }),
        [t],
    );

    return (
        <SettingsPage
            trackPageVisit
            useDivider
            description={copy.caption}
            pageName={PAGE_NAME.SettingsMissionStatement}
            title={copy.title}
        >
            <MissionStatementPage />
        </SettingsPage>
    );
};

export default MissionStatement;
