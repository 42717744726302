import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import {
    AddressWithPhone,
    isValidAddress,
    useApiRequest,
} from '@givelify/givelify-ui';
import { isEqual } from 'lodash';
import {
    FaithLeaderEditorInfo,
    ImageWithDimensions,
    RepresentativeWithImage,
} from '../../@types/assets/onboarding';
import { getRepresentativesAPI } from '../../api/requests/api';
import { isImageChanged } from '../../utils/compareImages';
import { CustomizeProfileRef } from '../CustomizeProfileRef';
import { ValidateFaithLeader } from './FaithLeaderSection';
import {
    CustomizeProfileStepOneProps,
    CustomizeProfileStepOneWithDataProps,
} from './types';
import View from './view';

const CustomizeProfileStepOneComponent: React.FC<CustomizeProfileStepOneWithDataProps> =
    ({
        userId,
        appProfile,
        organizationType,
        organizationName,
        setIsValid,
        onMobilePreviewRequest,
        className,
        hideFullScreenPreviewOption,
        settingsUsersPath,
        data,
        isLoading,
        forwardRef,
        setIsDataChanged,
        formProps,
    }) => {
        const [bannerImage, setBannerImg] = useState(appProfile.bannerImage);
        const [faithLeaderImage, setFaithLeaderImg] = useState(
            appProfile.faithLeaderImage,
        );
        const [faithLeader, setFaithLeaderInfo] = useState(
            appProfile.faithLeader,
        );
        const [address, setAddressInfo] = useState(
            appProfile.organizationAddress,
        );
        const [isAddressValid, setIsAddressValid] = useState(
            isValidAddress(appProfile.organizationAddress, false),
        );
        const [doNotDisplayFaithLeader, setDoNotDisplayFaithLeader] = useState(
            appProfile.doNotDisplayFaithLeader,
        );

        const setFaithLeader = (faithLeaderInfo?: FaithLeaderEditorInfo) => {
            setFaithLeaderInfo(faithLeaderInfo);
            setIsDataChanged &&
                setIsDataChanged(
                    isEqual(faithLeaderInfo, appProfile.faithLeader),
                );
        };

        const setFaithLeaderImage = (image?: ImageWithDimensions) => {
            setFaithLeaderImg(image);
            setIsDataChanged &&
                setIsDataChanged(isEqual(image, appProfile.faithLeaderImage));
        };

        const setBannerImage = (image?: ImageWithDimensions) => {
            setBannerImg(image);
            setIsDataChanged &&
                setIsDataChanged(isEqual(image, appProfile.bannerImage));
        };

        const setAddress = (organizationAddress: AddressWithPhone) => {
            setAddressInfo(organizationAddress);
            setIsDataChanged &&
                setIsDataChanged(
                    isEqual(address, appProfile.organizationAddress),
                );
        };

        const isFaithLeaderValid = useMemo(() => {
            if (doNotDisplayFaithLeader) return true;

            return ValidateFaithLeader({
                firstName: faithLeader?.firstName || '',
                lastName: faithLeader?.lastName || '',
                title: faithLeader?.title || '',
            }).isValid;
        }, [doNotDisplayFaithLeader, faithLeader]);

        useEffect(() => {
            if (organizationType === 'church') {
                if (setIsValid)
                    setIsValid(isAddressValid && isFaithLeaderValid);
            } else {
                if (setIsValid) setIsValid(isAddressValid);
            }
        }, [isAddressValid, isFaithLeaderValid, setIsValid, organizationType]);

        useEffect(() => {
            setBannerImage(appProfile.bannerImage);
            //eslint-disable-next-line
        }, [appProfile.bannerImage]);

        useImperativeHandle(forwardRef, () => ({
            submit: () => {
                return {
                    ...appProfile,
                    organizationAddress: address,
                    faithLeaderImage,
                    bannerImage: isImageChanged(
                        appProfile.bannerImage,
                        bannerImage,
                    ),
                    faithLeader,
                    doNotDisplayFaithLeader,
                };
            },
        }));
        return (
            <View
                {...{
                    address,
                    organizationName,
                    setIsAddressValid,
                    data,
                    onMobilePreviewRequest,
                    hideFullScreenPreviewOption,
                    faithLeaderImage,
                    faithLeader,
                    bannerImage,
                    userId,
                    doNotDisplayFaithLeader,
                    settingsUsersPath,
                    setFaithLeaderImage,
                    setFaithLeader,
                    setDoNotDisplayFaithLeader,
                    setBannerImage,
                    setAddress,
                    organizationType,
                    isLoading,
                    className,
                    appProfilebannerCroppedUrl:
                        appProfile.bannerImage?.croppedUrl,
                    initialFiathLeader: appProfile.faithLeader,
                    initialFaithLeaderImage: appProfile.faithLeaderImage,
                    formProps: formProps,
                }}
            />
        );
    };

const CustomizeProfileDataLoaderWrapper = (
    props: CustomizeProfileStepOneProps,
    forwardRef: React.ForwardedRef<CustomizeProfileRef>,
) => {
    const { doneeId, appProfileHash } = props;
    const [getRepresentativesStatus, makeGetRepresentativesRequest] =
        useApiRequest<{ data: RepresentativeWithImage[] }>();

    useEffect(() => {
        makeGetRepresentativesRequest(getRepresentativesAPI(doneeId));
    }, [doneeId, makeGetRepresentativesRequest]);

    const isLoading = useMemo(
        () => getRepresentativesStatus.type === 'REQUEST_START',
        [getRepresentativesStatus],
    );

    const key = `${doneeId}${appProfileHash}`;
    return (
        <CustomizeProfileStepOneComponent
            {...{
                ...props,
                data: getRepresentativesStatus.data?.data || [],
                isLoading,
                key,
                forwardRef,
            }}
        />
    );
};

export const CustomizeProfileStepOne = forwardRef<
    CustomizeProfileRef,
    CustomizeProfileStepOneProps
>(CustomizeProfileDataLoaderWrapper);
