import React from 'react';
import { Stack, styled, Switch } from '@mui/material';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { GivelifyToggleProps } from './types';

const StyleSwitchView = styled(Switch)(() => ({
    '& .MuiSwitch-track': {
        opacity: 1,
        background: DesignTokens.color.backgroundComponentDisabled,
    },
    '& .MuiSwitch-thumb': {
        boxShadow:
            '0px 0px 0px 1px #C2C0CD, 0px 0.25px 1px rgba(10, 0, 77, 0.039), 0px 0.85px 3px rgba(10, 0, 77, 0.19)',
        background: DesignTokens.color.backgroundComponentPrimaryDefault,
    },
    '& .Mui-checked': {
        '& .MuiSwitch-thumb': {
            background: DesignTokens.color.backgroundComponentPrimaryAccent,
            boxShadow: DesignTokens.shadow.shadowMediumLow,
        },
    },
    '& .Mui-disabled': {
        '&.Mui-checked .MuiSwitch-thumb': {
            background: DesignTokens.color.backgroundComponentTertiaryAccent,
        },
        '& + .MuiSwitch-thumb': {
            boxShadow: DesignTokens.shadow.shadowMediumLow,
            padding: 0,
        },
        '& + .MuiSwitch-track': {
            background: DesignTokens.color.backgroundComponentTertiary,
        },
    },
}));

const GivelifyToggleView: React.FC = (props) => {
    return <StyleSwitchView {...props} />;
};

const GivelifyToggleLabel: React.FC<GivelifyToggleProps> = ({
    label,
    textPosition,
    ...props
}) => {
    return (
        <Stack alignItems="center" direction="row" spacing={0}>
                {textPosition === 'start' && (
                    <GivelifyLabel text={label} variant="componentInput" />
                )}
                <GivelifyToggleView {...props} />
                {textPosition === 'end' && (
                    <GivelifyLabel text={label} variant="componentInput" />
                )}
            </Stack>
    );
};

export const GivelifyToggle: React.FC<GivelifyToggleProps> = ({
    label,
    textPosition = 'end',
    ...props
}) => {
    if (label) {
        return (
            <GivelifyToggleLabel
                {...props}
                label={label}
                textPosition={textPosition}
            />
        );
    }
    return <GivelifyToggleView {...props} />;
};
