import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgRightArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>right-arrow</title>
        <path
            clipRule="evenodd"
            d="M13.0607 5L19.7803 11.4696C20.0732 11.7625 20.0732 12.2374 19.7803 12.5303L13.0607 19C12.7678 19.2929 12.2929 19.2929 12 19C11.7071 18.7071 11.7071 18.2322 12 17.9393L17.4393 12.75L4.75 12.75C4.33579 12.75 4 12.4142 4 12C4 11.5857 4.33579 11.25 4.75 11.25L17.4393 11.25L12 6.06066C11.7071 5.76777 11.7071 5.2929 12 5C12.2929 4.70711 12.7678 4.70711 13.0607 5Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const RightArrowIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgRightArrow}
        fontSize={fontSize}
    />
);
