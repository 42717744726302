import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { mergeClassNames } from '../utils/classNameUtils';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        boxShadow: theme.gas.elevation.medium,
        borderRadius: 20,
        overflow: 'auto', // Need this to be able to put Table inside Paper
    },
}));

export interface GivelifyPaperProps {
    className?: string;
}

export const GivelifyPaper: React.FC<GivelifyPaperProps> = ({
    className,
    children,
}) => {
    const { paper } = useStyles();

    return <div className={mergeClassNames(paper, className)}>{children}</div>;
};
