import React, { useCallback, useEffect, useState } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { useApiRequest } from '@givelify/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { LoadTotalUnmatchedStateAsync } from 'store/automatedIntegration/thunks';
import { IntegrationTypes } from '../../../../../types';
import { matchSelect } from '../../../../../utils/integrationFormatFilterUtils';
import { integrationUrl } from '../../types';
import { deleteMatchedEnvelope } from '../automatedIntegrationRequest';
import AutoIntegrationUnmatchEnvelopeHeader from './AutoIntegrationUnmatchEnvelopeHeader';
import MatchEnvelopeInfiniteLoader from './MatchEnvelopeInfiniteLoader';

interface F1CCBMatchEnvelopeContentProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onSortingChange: (sorting: Sorting[]) => void;
    campusSelected: number;
    match?: matchSelect;
    isReadOnly: boolean;
    integration: IntegrationTypes;
}

const AutoIntegrationMatchEnvelopeContent: React.FC<F1CCBMatchEnvelopeContentProps> = ({
    sortColumn,
    sortDirection,
    onSortingChange,
    campusSelected,
    isReadOnly,
    integration,
}) => {
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));
    const dispatch = useDispatch();

    const [reset, setReset] = useState<true | undefined>();
    const [selectedPageNumber, setSelectedPageNumber] = useState<number>();
    const [reload, setReload] = useState<boolean>(false);
    const url = useCallback(
        (pageNumber: number) => {
            return integrationUrl(
                userDoneeId,
                `envelopes?has_match=1&page=${pageNumber}&per_page=10${
                    campusSelected !== 0 ? '&campus_id=' + campusSelected : ''
                }&order_by=${sortColumn}&sort=${sortDirection}`,
            );
        },
        [userDoneeId, campusSelected, sortColumn, sortDirection],
    );
    useEffect(() => {
        setReset(true);
    }, [userDoneeId]);
    const onReset = () => setReset(undefined);
    const onReloaded = () => setReload(false);

    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [unmatchStatus, setUnmatchStatus] = useState<boolean>(false);

    const [
        unmatchEnvelopeResponse,
        makeRequestUnmatchEnvelope,
    ] = useApiRequest();
    const unmatchEnvelopeFunction = (envelopeId: number, pageNumber?: number) => {
        setSelectedPageNumber(pageNumber);
        makeRequestUnmatchEnvelope(
            deleteMatchedEnvelope(userDoneeId, envelopeId),
        );
        setBtnLoading(true);
    };
    useEffect(() => {
        if (unmatchEnvelopeResponse.type === 'REQUEST_SUCCESS') {
            dispatch(LoadTotalUnmatchedStateAsync());
            setReload(true);
            setBtnLoading(false);
            setUnmatchStatus(true);
        } else if (unmatchEnvelopeResponse.type === 'REQUEST_ERROR') {
            setBtnLoading(false);
        }
    }, [unmatchEnvelopeResponse, dispatch]);

    return (
        <>
            <AutoIntegrationUnmatchEnvelopeHeader
                matched
                integration={integration}
                onOrderClick={onSortingChange}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            />
            <MatchEnvelopeInfiniteLoader
                btnLoading={btnLoading}
                integration={integration}
                isReadOnly={isReadOnly}
                onReloaded={onReloaded}
                onReset={onReset}
                reload={reload}
                reset={reset}
                selectedPageNumber={selectedPageNumber}
                unmatchEnvelopeFunction={unmatchEnvelopeFunction}
                unmatchStatus={unmatchStatus}
                url={url}
            />
        </>
    );
};

export default AutoIntegrationMatchEnvelopeContent;
