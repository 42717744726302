import React, { useMemo } from 'react';
import { ServerErrorPage } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { PATH } from '../../router/routes';

const Error500Internal = () => {
    const { t } = useTranslation();
    const { heading, text, goToOverview, goToPreviousPage } = useMemo(
        () => ({
            heading: t('error.500.heading'),
            text: t('error.500.text'),
            goToOverview: t('error.404.goToOverview'),
            goToPreviousPage: t('error.404.goToPreviousPage'),
        }),
        [t],
    );
    return (
        <ServerErrorPage
            showReturnToPreviousButton
            descriptionText={text}
            overviewButtonText={goToOverview}
            overviewButtonUrl={PATH.OVERVIEW}
            pageNotFoundText={heading}
            returnButtonText={goToPreviousPage}
        />
    );
};

// We need to keep this function to make all the hooks work
export const Error500 = () => <Error500Internal />;
