import { useFilterParams } from 'api/hooks/useFilterParams';

export type TabFilter = {
    tab: string;
};

const paramNames = ['tab'] as const;

export const useTabFilter = (tabs: string[], defaultValue: TabFilter) => {
    const [filter, setFilter] = useFilterParams<TabFilter, typeof paramNames>(
        defaultValue,
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'tab':
                default:
                    return state.tab;
            }
        },
        (values) => {
            const tab = values['tab'];
            if (tabs.includes(tab)) {
                return {
                    tab,
                };
            }
            return defaultValue;
        },
    );
    return [filter, setFilter] as const;
};
