import { makeStyles, Theme } from '@material-ui/core';

export const inviteToGivelifyModalStyles = makeStyles((theme: Theme) => ({
    tabsHeader: {
        marginTop: 0,
    },
    tabBodyHeader: {
        marginTop: 10,
    },
    tabBodyDescription: {
        margin: '16px 0px',
    },
    emailField: {
        maxWidth: 688,
        marginTop: 32,
    },
    emailFieldHelpText: {
        marginTop: 5,
        marginBottom: 50,
    },
    userInfoBox: {
        display: 'flex',
        maxWidth: 688,
        border: '1px solid ' + theme.colors.neutralPlatinum,
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 80,
        marginTop: 32,
        marginBottom: 8,
    },
    userAvatarWrapper: {
        width: 48,
        margin: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    userAvatar: {
        width: 48,
        height: 48,
    },
    userNameContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 'auto',
    },
    fullName: {
        lineHeight: '20px',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
    },
    title: {
        lineHeight: '14px',
        color: theme.colors.neutralGrey,
    },
}));
