import React from 'react';
import {
    initialIntegrationPaginatedResponse,
    IntegrationPaginatedResponse,
} from '@givelify/givelify-ui';
import { Envelope } from 'api/models';
import { UpdateEnvelopeRequest } from 'api/requests';

type requestState =
    | 'REQUEST_INIT'
    | 'REQUEST_START'
    | 'REQUEST_ERROR'
    | 'REQUEST_SUCCESS';

interface EnvelopesContextProps {
    hasFullAccess: boolean;

    activeEnvelopes: Envelope[];
    getActiveEnvelopesRequestState: requestState;
    activeEnvelopesLoading: boolean;

    inactiveEnvelopes: IntegrationPaginatedResponse<Envelope>;
    getInactiveEnvelopesRequestState: requestState;
    inactiveEnvelopesPageNumber: number;
    loadNewInactivePage: () => void;
    inactiveEnvelopesLoading: boolean;

    hideEnvelope: (envelopeId: number) => void;
    hideEnvelopeRequestState: requestState;

    showEnvelope: (
        envelopeId: number,
        isDefault: boolean,
        always: boolean,
        startDate?: string,
        endDate?: string,
    ) => void;
    showEnvelopeRequestState: requestState;

    showNotification: boolean;
    hideNotificationClick: () => void;

    editTarget: Envelope | undefined;

    createEnvelopeClick: () => void;
    editEnvelopeClick: (id: number) => void;
    closeEditForm: () => void;

    createEnvelope: (data: UpdateEnvelopeRequest) => Promise<void>;
    createEnvelopeRequestState: requestState;

    updateEnvelope: (data: UpdateEnvelopeRequest) => Promise<void>;
    updateEnvelopeRequestState: requestState;

    deleteEnvelope: (id: number) => Promise<void>;
    deleteEnvelopeRequestState: requestState;

    dndDisabled: boolean;
    dragAndDrop: (from: number, to: number) => void;
    updatePrioritiesRequestState: requestState;
    movedItemId: number;
    showCheckMark: boolean;
    rollbackDragAndDrop: () => void;
}

const NOT_INITIALIZED = () => {
    throw new Error('Context not initialized');
};

export const EnvelopesContext = React.createContext<EnvelopesContextProps>({
    hasFullAccess: false,

    activeEnvelopes: [],
    getActiveEnvelopesRequestState: 'REQUEST_INIT',
    activeEnvelopesLoading: true,

    inactiveEnvelopes: initialIntegrationPaginatedResponse,
    getInactiveEnvelopesRequestState: 'REQUEST_INIT',
    inactiveEnvelopesPageNumber: 0,
    loadNewInactivePage: NOT_INITIALIZED,
    inactiveEnvelopesLoading: true,

    hideEnvelope: NOT_INITIALIZED,
    hideEnvelopeRequestState: 'REQUEST_INIT',

    showEnvelope: NOT_INITIALIZED,
    showEnvelopeRequestState: 'REQUEST_INIT',

    hideNotificationClick: NOT_INITIALIZED,
    showNotification: false,

    editTarget: undefined,

    createEnvelopeClick: NOT_INITIALIZED,
    editEnvelopeClick: NOT_INITIALIZED,
    closeEditForm: NOT_INITIALIZED,

    createEnvelope: NOT_INITIALIZED,
    createEnvelopeRequestState: 'REQUEST_INIT',

    updateEnvelope: NOT_INITIALIZED,
    updateEnvelopeRequestState: 'REQUEST_INIT',

    deleteEnvelope: NOT_INITIALIZED,
    deleteEnvelopeRequestState: 'REQUEST_INIT',

    dndDisabled: undefined,
    dragAndDrop: NOT_INITIALIZED,
    updatePrioritiesRequestState: 'REQUEST_INIT',
    movedItemId: undefined,
    showCheckMark: undefined,
    rollbackDragAndDrop: NOT_INITIALIZED,
});
