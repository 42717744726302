import React from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyModal,
    GivelifyModalProps,
} from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { primaryRepEditorViews } from '../types';
import { Box } from './styles';

type ConfirmDialogProps = GivelifyModalProps & {
    onConfirm: () => void;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    onConfirm,
    ...props
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.dialog.title',
            ),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.editor.dialog.description',
            ),
            cancel: t(
                'pages.settings.bank-info.customize-rep-tab.editor.dialog.cancel',
            ),
            confirm: t(
                'pages.settings.bank-info.customize-rep-tab.editor.dialog.confirm',
            ),
        }),
        [t],
    );
    return (
        <GivelifyModal
            {...props}
            showCloseButton
            name={primaryRepEditorViews.confirmCancellation}
        >
            <Box>
                <GivelifyIcon variant="alert" />
                <GivelifyLabel className="title" variant="heading2S">
                    {copy.title}
                </GivelifyLabel>
                <GivelifyLabel className="descr" variant="body2">
                    {copy.description}
                </GivelifyLabel>
                <div className="footer">
                    <GivelifyButton
                        fullWidth
                        name="Go Back"
                        onClick={() => props.onClose({}, 'closeButtonClick')}
                        text={copy.cancel}
                    />
                    <GivelifyButton
                        fullWidth
                        name="Cancel and Exit"
                        onClick={onConfirm}
                        text={copy.confirm}
                        variant="primary"
                    />
                </div>
            </Box>
        </GivelifyModal>
    );
};
