import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryRepBox, PrimaryRepBoxProps } from '../primaryRepBox';
import { Box, Description } from './styles';

type PrimaryRepDetailsProps = PrimaryRepBoxProps;

export const PrimaryRepDetails: React.FC<PrimaryRepDetailsProps> = ({
    children,
    ...props
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            description: t(
                'pages.settings.bank-info.customize-rep-tab.description',
            ),
        }),
        [t],
    );
    return (
        <Box>
            <Description variant="body2">{copy.description}</Description>
            <PrimaryRepBox {...props} />
        </Box>
    );
};
