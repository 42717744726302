import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { DirectDepositInfo } from '@givelify/onboarding';
import { useTranslation } from 'react-i18next';
import { AccountDetailStyles } from './accountDetailStyles';
import { AccountInfo } from './AccountInfo';

interface AccountRetryProps {
    bankingInfo: Omit<
        DirectDepositInfo,
        'bankChequeFile' | 'addressIsSame' | 'status'
    >;
    onRetryClick: () => void;
}

export const AccountRetry: React.FC<AccountRetryProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newAccount: t(
                'pages.settings.bank-info.customize-account-tab.new-account',
            ),
            status: t('pages.settings.bank-info.customize-account-tab.retry'),
            info: t(
                'pages.settings.bank-info.customize-account-tab.retry-info-1',
            ),
            retry: t(
                'pages.settings.bank-info.customize-account-tab.retry-info-2',
            ),
        }),
        [t],
    );
    const { bankingInfo, onRetryClick } = props;
    const { accountRetry } = AccountDetailStyles();
    const onClick = React.useCallback(() => {
        onRetryClick();
    }, [onRetryClick]);
    return (
        <div className={accountRetry}>
            <div className="header">
                <GivelifyLabel text={copy.newAccount} variant="heading3" />
                <GivelifyIcon
                    color="#DC9F00"
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="info-circle"
                />
                <GivelifyLabel className="submitted" text={copy.status} />
            </div>
            <GivelifyNotification show variant="warning">
                <span className="info">
                    {copy.info}{' '}
                    <span className="button" onClick={onClick}>
                        {copy.retry}
                    </span>
                </span>
            </GivelifyNotification>
            <AccountInfo
                disabled
                bankingInfo={bankingInfo}
                className="detailes"
            />
            <GivelifyTextDivider />
        </div>
    );
};
