import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '55px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: '40px 0',
    },
  },
  title: {
    padding: '0 32px',
  },
  orBox: {
    margin: '0 40px',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      margin: '8px 0',
    },
  },
  link: {
    marginTop: '12px',
    color: '#715AFF',
  },
  roundBox: {
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '160px',
    height: '88px',
    [theme.breakpoints.down('xs')]: {
      width: 279,
    },
    '&:hover': {
      cursor: 'pointer',
      '&link': {
        color: '#104C84',
        textDecoration: 'underline',
      },
    },
  },
  icon: {
    width: 32,
    margin: '0 auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    marginTop: '24px',
  },
  formFooter: {
    display: 'flex',
    marginTop: 24,
    marginBottom: 32,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  nameInput: {},
  emailInput: {
    marginTop: '16px',
  },
  submitButton: {
    marginLeft: '16px',
  },
}));
