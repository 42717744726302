import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCloseOutlined = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>close-outlined</title>
        <path
            clipRule="evenodd"
            d="M3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3.909 5.784c.29.29.292.76.005 1.052L13.057 12l2.857 2.914a.748.748 0 1 1-1.068 1.047L12 13.057l-2.846 2.904a.748.748 0 1 1-1.068-1.047L10.943 12 8.039 9.154a.748.748 0 0 1 1.047-1.068L12 10.943l2.846-2.904a.748.748 0 0 1 1.063-.005Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const CloseOutlinedIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgCloseOutlined}
        fontSize={fontSize}
    />
);
