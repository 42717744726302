import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

export const GivelifyBreadcrumbsStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyBoxMarginProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyBoxMarginProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyBoxMarginProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyBoxMarginProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        root: {
            width: '100$',
            display: 'flex',
            alignItems: 'center',
            '& button': {
                fontSize: 14,
                lineHeight: '24px',
                fontWeight: 600,
            },
            '& a:link': {
                fontSize: 14,
                lineHeight: '24px',
                fontWeight: 600,
                color: theme.gas.palette.button.secondary.idle.text,
                textDecoration: 'none',
            },
            '& a:hover': {
                color: theme.gas.palette.button.secondary.hover.text,
                textDecoration: 'underline',
            },
            '& a:active': {
                color: theme.gas.palette.button.secondary.active.text,
                textDecoration: 'underline',
            },
            '& a:visited': {
                color: theme.gas.palette.button.secondary.idle.text,
                textDecoration: 'none',
            },
        },
    }),
);
