import { RequestState } from '@givelify/givelify-ui';
import { RequestState as NewRequestState } from '@givelify/utils';
import { GetDonorsResponse } from '../../../api/services/responses/donors/GetDonorsResponse';
import {
    SetDonorsCurrentLoading,
    SetDonorsCurrentSuccess,
    SetDonorsCurrentFailed,
} from './current/types';
import {
    SetDonorsPreviousLoading,
    SetDonorsPreviousSuccess,
    SetDonorsPreviousFailed,
} from './previous/types';
// TODO: rename after everything is changed to new package

export const SET_DONORS_FILTERS = 'SET_DONORS_FILTERS';
export const SET_DONORS = 'SET_DONORS';

export interface DonorDistribution {
    id?: number;
    name: string;
    donorsCount: number;
}

export type Donor = {
    id: number;
    donorId: number;
    name: string;
    phone: string;
    externalId: string;
    lastDonationDate: string;
    lastDonationAmount: number;
    lifetime_donations: number;
    doneeDonorStatusTypesId: number;
    doneeDonorStatusType: string;
};

export interface DonorsTimeFrameData {
    data: DonorDistribution[];
    meta: {
        totalGiving: number;
    };
}

export interface DonorsState {
    previous?: RequestState<DonorsTimeFrameData>;
    current?: RequestState<DonorsTimeFrameData>;
    donors?: NewRequestState<GetDonorsResponse>;
}

export type SetDonors = {
    type: typeof SET_DONORS;
    data: NewRequestState<GetDonorsResponse>;
};

export type DonorsActions =
    | SetDonorsCurrentLoading
    | SetDonorsPreviousLoading
    | SetDonorsCurrentSuccess
    | SetDonorsPreviousSuccess
    | SetDonorsCurrentFailed
    | SetDonorsPreviousFailed
    | SetDonors;
