import { ImageWithDimensions } from '../@types/assets/onboarding';

export const isImageChanged = (
    oldImage: ImageWithDimensions | undefined,
    newImage: ImageWithDimensions | undefined,
): ImageWithDimensions | undefined => {
    if (
        (!oldImage && !newImage) ||
        !newImage ||
        !newImage.croppedUrl ||
        oldImage?.croppedUrl === newImage.croppedUrl
    )
        return undefined;
    return newImage;
};
