import { ErrorResponse } from '@givelify/givelify-ui';
import { EnvelopeDistribution } from '../types';
import {
    SetGivingTrendEnvelopesStatisticsLoadingAtIndex,
    SetGivingTrendEnvelopesStatisticsSuccessAtIndex,
    SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX,
    SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX,
    SetGivingTrendEnvelopesStatisticsFailedAtIndex,
    SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX,
    SetInitialGivingTrendStatisticsState,
    SET_INITIAL_GIVING_TREND_STATISTICS_STATE,
} from './types';

export const setInitialGivingTrendState = (
    count: number,
): SetInitialGivingTrendStatisticsState => ({
    type: SET_INITIAL_GIVING_TREND_STATISTICS_STATE,
    count,
});

export const setGivingTrendEnvelopesStatisticsLoadingAtIndex = (
    index: number,
) => (): SetGivingTrendEnvelopesStatisticsLoadingAtIndex => ({
    type: SET_GIVING_TREND_ENVELOPES_STATISTICS_LOADING_AT_INDEX,
    index,
});

export const setGivingTrendEnvelopesStatisticsSuccessAtIndex = (
    index: number,
) => (data: {
    data: EnvelopeDistribution[];
}): SetGivingTrendEnvelopesStatisticsSuccessAtIndex => ({
    type: SET_GIVING_TREND_ENVELOPES_STATISTICS_SUCCESS_AT_INDEX,
    index,
    data,
});

export const setGivingTrendEnvelopesStatisticsFailedAtIndex = (
    index: number,
) => (
    error: ErrorResponse,
): SetGivingTrendEnvelopesStatisticsFailedAtIndex => ({
    type: SET_GIVING_TREND_ENVELOPES_STATISTICS_FAILED_AT_INDEX,
    index,
    error,
});
