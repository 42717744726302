import * as React from 'react';
import { Donee } from '@givelify/givelify-ui';
import {
    BankingSettings,
    BankingSettingsModal,
    DirectDepositInfo,
} from '@givelify/onboarding';
import { RequestState } from '@givelify/utils';
import { BankAccount } from 'api/models/BankAccount';
import LoadingBar from 'components/system/LoadingBar';
import { AccountDetailStyles } from './accountDetailStyles';
import { AccountPending } from './AccountPending';
import { AccountRetry } from './AccountRetry';
import { AccountSuccess } from './AccountSuccess';
import { AccountWarning } from './AccountWarning';

interface AccountContentProps {
    getRequest: RequestState<{
        data: BankAccount[];
    }>;
    isUpdating?: boolean;
    updateHasError?: boolean;
    yodleeEnabled?: boolean;
    screen: CustomizeAccountTabScreen;
    donee: Donee;
    error?: string;
    onReplaceClick: () => void;
    onRetryClick: () => void;
    onRetryCancel: () => void;
    onBankingInfoChange: (data: DirectDepositInfo) => void;
}

export type CustomizeAccountTabScreen = 'add' | 'replace' | 'list' | 'retry';

export const AccountContent: React.FunctionComponent<AccountContentProps> = (
    props,
) => {
    const {
        getRequest: request,
        screen,
        donee,
        onReplaceClick,
        onRetryClick,
        onBankingInfoChange,
        onRetryCancel,
        isUpdating,
        updateHasError,
        yodleeEnabled,
        error,
    } = props;
    const { yodleeContainer } = AccountDetailStyles();
    const renderReplacementAccount = React.useCallback(
        (account: BankAccount) => {
            if (account === undefined) return null;

            switch (account.status) {
                case 'in_progress':
                case 'unchecked':
                    return <AccountPending bankingInfo={account} />;
                case 'request_callback':
                    return <AccountWarning bankingInfo={account} />;
                case 'require_information':
                    return (
                        <AccountRetry
                            bankingInfo={account}
                            onRetryClick={onRetryClick}
                        />
                    );
                default:
                    return null;
            }
        },
        [onRetryClick],
    );

    if (request.type !== 'REQUEST_SUCCESS') return <LoadingBar show />;

    switch (screen) {
        case 'list': {
            if (request.response?.data?.length > 0) {
                const accountCount = request.response.data.length;
                if (accountCount === 1) {
                    const activeAccount = request.response.data[0];
                    if (activeAccount.status === 'verified') {
                        return (
                            <AccountSuccess
                                bankingInfo={activeAccount}
                                onReplaceClick={onReplaceClick}
                            />
                        );
                    } else {
                        return renderReplacementAccount(activeAccount);
                    }
                } else {
                    const activeAccount = request.response.data.find(
                        (x) => x.status === 'verified',
                    );
                    const replacementAccount = request.response.data.find(
                        (x) => x.status !== 'verified',
                    );
                    const renderActiveAccountComponent = activeAccount ? (
                        <AccountSuccess
                            disableReplace
                            bankingInfo={activeAccount}
                            onReplaceClick={onReplaceClick}
                        />
                    ) : null;
                    const renderReplacementAccountComponent =
                        renderReplacementAccount(replacementAccount);
                    return (
                        <>
                            {renderActiveAccountComponent}
                            {renderReplacementAccountComponent}
                        </>
                    );
                }
            } else {
                return (
                    <BankingSettings
                        bankingInfo={{
                            accountNumber: '',
                            bankChequeFile: undefined,
                            bankingAddress: {
                                city: donee.city,
                                state: donee.state,
                                street: donee.address,
                                zip: donee.zip,
                            },
                            routingNumber: '',
                            status: 'unchecked',
                            submittedAt: null,
                            submittedByOfficialId: null,
                        }}
                        directDepositClassName={yodleeContainer}
                        doneeId={donee.id}
                        hasError={updateHasError}
                        isLoading={isUpdating}
                        onChange={onBankingInfoChange}
                    />
                );
            }
        }
        case 'add': {
            return (
                <BankingSettings
                    bankingInfo={{
                        accountNumber: '',
                        bankChequeFile: undefined,
                        bankingAddress: {
                            city: donee.city,
                            state: donee.state,
                            street: donee.address,
                            zip: donee.zip,
                        },
                        routingNumber: '',
                        status: 'unchecked',
                        submittedAt: null,
                        submittedByOfficialId: null,
                    }}
                    directDepositClassName={yodleeContainer}
                    doneeId={donee.id}
                    error={error}
                    hasError={updateHasError}
                    isLoading={isUpdating}
                    onChange={onBankingInfoChange}
                />
            );
        }
        case 'replace': {
            return (
                <BankingSettingsModal
                    bankingInfo={{
                        accountNumber: '',
                        bankChequeFile: undefined,
                        bankingAddress: {
                            city: donee.city,
                            state: donee.state,
                            street: donee.address,
                            zip: donee.zip,
                        },
                        routingNumber: '',
                        status: 'unchecked',
                        submittedAt: null,
                        submittedByOfficialId: null,
                    }}
                    doneeId={donee.id}
                    error={error}
                    hasError={updateHasError}
                    isLoading={isUpdating}
                    onCancel={onRetryCancel}
                    onChange={onBankingInfoChange}
                    showYodlee={yodleeEnabled}
                />
            );
        }
        case 'retry': {
            const replacementAccount = request.response.data.find(
                (x) => x.status !== 'verified',
            );
            return (
                <BankingSettingsModal
                    bankingInfo={{
                        ...replacementAccount,
                        bankingAddress: {
                            ...replacementAccount.bankingAddress,
                            street:
                                replacementAccount.bankingAddress?.street || '',
                        },
                        bankChequeFile: undefined,
                    }}
                    doneeId={donee.id}
                    hasError={updateHasError}
                    isLoading={isUpdating}
                    onCancel={onRetryCancel}
                    onChange={onBankingInfoChange}
                    showYodlee={!yodleeEnabled}
                />
            );
        }
    }
};
