import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getSignInApiEndpoint,
    getSignInAdminApiEndpoint,
} from 'api/client/endpoints';
import { SignInRequest, SignInAdminRequest } from 'api/requests/auth';
import { IAuthService } from '../interfaces/IAuthService';
import { SignInResponse } from '../responses/auth';

export class AuthService implements IAuthService {
    public async signIn(
        payload: SignInRequest,
    ): Promise<ApiResponse<SignInResponse>> {
        const url = getSignInApiEndpoint();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<SignInResponse>(httpRequest);
        return result;
    }

    public async signInAdmin(
        payload: SignInAdminRequest,
    ): Promise<ApiResponse<SignInResponse>> {
        const url = getSignInAdminApiEndpoint();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<SignInResponse>(httpRequest);
        return result;
    }
}
