import React from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { DesignTokens } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';

interface EnvelopeListItemIconProps {
    id: number;
    active: boolean;
}

const useStyles = makeStyles(() => ({
    '@keyframes myAnimation': {
        '20%': { transform: 'scale(1)' },
        '50%': {
            transform: 'scale(1.2)',
        },
        '100%': { transform: 'scale(1)' },
    },
    checkmarkIcon: {
        color: 'transparent',
        animation: '$myAnimation 1s',
    },
}));

const EnvelopeListItemIcon: React.FC<EnvelopeListItemIconProps> = (props) => {
    const { id, active } = props;
    const { checkmarkIcon } = useStyles();

    const { updatePrioritiesRequestState, movedItemId, showCheckMark } =
        useEnvelopesContext();

    const itemIsMoved = movedItemId === id;
    const isMoving = updatePrioritiesRequestState === 'REQUEST_START';
    const isError = updatePrioritiesRequestState === 'REQUEST_ERROR';

    const currentItemIsMoving = itemIsMoved && isMoving;
    const currentItemIsError = itemIsMoved && isError;
    const otherItemIsMoving = !itemIsMoved && isMoving;

    return (
        <div>
            {currentItemIsError ? (
                <GivelifyIcon
                    color={DesignTokens.color.iconError}
                    variant="info-circle"
                />
            ) : itemIsMoved && showCheckMark ? (
                <GivelifyIcon className={checkmarkIcon} variant="checkmark" />
            ) : currentItemIsMoving ? (
                <GivelifyIcon spin variant="loading" />
            ) : (
                <GivelifyIcon
                    color={
                        (!active || otherItemIsMoving) &&
                        DesignTokens.color.iconDisabled
                    }
                    variant="move"
                />
            )}
        </div>
    );
};

export default EnvelopeListItemIcon;
