import { useFilterParams } from 'api/hooks/useFilterParams';

export type PaginationFilter = {
    page: number;
};

const paramNames = ['page'] as const;

export const usePaginationFilter = (defaultValue?: PaginationFilter) => {
    const [filter, setFilter] = useFilterParams<
        PaginationFilter,
        typeof paramNames
    >(
        defaultValue
            ? defaultValue
            : {
                  page: 1,
              },
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'page':
                    return state.page.toString();
            }
        },
        (values) => {
            try {
                const page = parseInt(values['page']);
                if (isNaN(page)) {
                    throw new Error('Invalid page number');
                }
                return {
                    page,
                };
            } catch (_) {
                return {
                    page: 1,
                };
            }
        },
    );
    return [filter, setFilter] as const;
};
