import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { colorsList } from '../../../../components/styles';
import useStyles from './styles';

const iconStyles = (i: number): React.CSSProperties => ({
    backgroundColor: colorsList[i % colorsList.length],
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: 100,
    marginRight: 10,
    flexShrink: 0,
});

export interface PieChartLegendProps<T> {
    heading: string;
    nameKey: keyof T & string;
    legends: T[];
}

const PieChartLegend = <T,>({
    heading,
    legends,
    nameKey,
}: PieChartLegendProps<T>) => {
    const classes = useStyles();

    return (
        <div>
            <GivelifyLabel text={heading} variant="body1" />
            <div className={classes.legend}>
                {legends
                    ? legends.map((legend, index) => (
                          <div key={index} className={classes.legendItem}>
                              <div style={iconStyles(index)} />
                              <GivelifyLabel
                                  color="black"
                                  text={String(legend[nameKey])}
                                  variant="body3"
                              />
                          </div>
                      ))
                    : []}
            </div>
        </div>
    );
};

export default PieChartLegend;
