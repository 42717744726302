const appUrl = process.env.REACT_APP_ONBOARDING_ROOT_APP_URL;
const apiUrl = process.env.REACT_APP_API_BASE_URL;
const termsOfUse = process.env.REACT_APP_ONBOARDING_TERMS_OF_USE;
const supportArticleLink =
    process.env.REACT_APP_ONBOARDING_SUPPORT_ARTICLE_LINK;
const applyEinLink = process.env.REACT_APP_ONBOARDING_APPLY_FOR_EIN_LINK;
const yodleeInitialize = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';
const yodleeBaseUrl = process.env.REACT_APP_YODLEE_BASE_URL;
const yodleeFastLinkURL = process.env.REACT_APP_YODLEE_FAST_LINK_URL;
const yodleeClientId = process.env.REACT_APP_YODLEE_CLIENT_ID;
const yodleeSecret = process.env.REACT_APP_YODLEE_SECRET;
const apiVersion = '1.1';
const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const taxIdHelpArticleURL = 'https://support.givelify.com/hc/en-us/articles/1500004540781-How-to-register-your-account-to-receive-donations';

export const webConfig = {
    apiUrl,
    appUrl,
    termsOfUse,
    applyEinLink,
    supportArticleLink,
    yodleeInitialize,
    yodleeBaseUrl,
    yodleeFastLinkURL,
    yodleeClientId,
    yodleeSecret,
    apiVersion,
    imageBaseUrl,
    taxIdHelpArticleURL,
};
