import dayjs from 'dayjs';

export type FormShowEnvelope = {
    isAlways: IsAlwaysValue;
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
};

export enum isAlwaysValues {
    ALWAYS = 'Always',
    TIMED = 'Timed',
}
export type IsAlwaysValue = 'Always' | 'Timed';
