import React, { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { headerStyle } from './headerStyle';

interface Props {
    onInviteNewuserClick: () => void;
}

const Header: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const styles = headerStyle();
    const copy = useMemo(
        () => ({
            inviteNewUser: t('pages.settings.users.invite-new-user'),
        }),
        [t],
    );
    return (
        <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item className={styles.lastGrid} xs={4}>
                <GivelifyButton
                    onClick={props.onInviteNewuserClick}
                    text={copy.inviteNewUser}
                />
            </Grid>
        </Grid>
    );
};

export default Header;
