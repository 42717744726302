import React, { useMemo, useState, useEffect } from 'react';
import { GivelifyLabel, isSucceeded, isFailed } from '@givelify/givelify-ui';
import { Donee } from '@givelify/givelify-ui';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { useInvokeApi, InvokeApiErrorResponse } from 'hooks/useInvokeApi';
import isEqual from 'lodash/isEqual';
import { EditOption } from 'pages/settings/components/EditOption';
import { SETTINGS_ASSETS_PATH } from 'pages/settings/SettingsAssetsPath';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setDoneeSocial } from 'store/donee/actions';

import { SocialInfo } from 'types/orgInfoTypes';

import { aboutStyle } from '../style';
import SocialItem from './SocialItem';
import SocialSectionEditor from './SocialSectionEditor';


type UpdateResponse = {
    facebook: string;
    instagram: string;
    twitter: string;
    website: string;
    updatedAt: string;
};
interface SocialSectionProps {
    donee: Donee;
    isReadOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    websiteCol: {
        [theme.breakpoints.down('md')]: {
            marginTop: 12,
        },
    },
}));

const SocialSection: React.FC<SocialSectionProps> = ({ donee, isReadOnly }) => {
    const commonStyle = aboutStyle();
    const { websiteCol } = useStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            socialMedia: t(
                'pages.settings.organization-info.social.socialMedia',
            ),
            website: t('pages.settings.organization-info.social.website'),
        }),
        [t],
    );

    const dispatch = useDispatch();

    const [socialData, setSocialData] = useState({
        facebook: '',
        instagram: '',
        twitter: '',
        website: '',
    });

    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    useEffect(() => {
        setSocialData({
            facebook: donee.facebook || '',
            instagram: donee.instagram || '',
            twitter: donee.twitter || '',
            website: donee.website || '',
        });
    }, [donee]);

    const [updateRequestState, updateRequest] = useInvokeApi<UpdateResponse>();

    const [editorEnabledState, setEditorEnabledState] = useState(false);

    const onSubmit = (data: SocialInfo) => {
        if (!isEqual(socialData, data)) {
            updateRequest('PATCH', `/donees/${donee.id.toString()}`, data);
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            dispatch(
                setDoneeSocial(
                    donee.id,
                    updateRequestState.data.facebook,
                    updateRequestState.data.instagram,
                    updateRequestState.data.twitter,
                    updateRequestState.data.website,
                    new Date(updateRequestState.data.updatedAt),
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, donee.id]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
        setSaveError(undefined);
    };

    const saving = updateRequestState.type === 'REQUEST_START';

    return (
        <div className={commonStyle.section}>
            {editorEnabledState ? (
                <SocialSectionEditor
                    data={socialData}
                    error={saveError}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            ) : (
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <GivelifyLabel
                            className={commonStyle.subtitle}
                            text={copy.socialMedia}
                            variant="heading3"
                        />
                        <SocialItem
                            imgAlt="facebook link"
                            imgSrc={SETTINGS_ASSETS_PATH.FACEBOOK_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.facebook}
                        />
                        <SocialItem
                            imgAlt="twitter link"
                            imgSrc={SETTINGS_ASSETS_PATH.TWITTER_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.twitter}
                        />
                        <SocialItem
                            imgAlt="instagram link"
                            imgSrc={SETTINGS_ASSETS_PATH.INSTAGRAM_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.instagram}
                        />
                    </Grid>
                    <Grid item className={websiteCol} md={6} sm={12} xs={12}>
                        <GivelifyLabel
                            className={commonStyle.subtitle}
                            text={copy.website}
                            variant="heading3"
                        />
                        <SocialItem
                            imgAlt="web link"
                            imgSrc={SETTINGS_ASSETS_PATH.WEB_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.website}
                        />
                    </Grid>
                </Grid>
            )}
            <div className={commonStyle.editButtonContainer}>
                {!isReadOnly && !editorEnabledState && (
                    <EditOption onClick={onEditClick} />
                )}
            </div>
        </div>
    );
};

export default SocialSection;
