import { DesignTokens, GivelifyModal } from '@givelify/ui';
import { styled } from '@mui/material';

export const StyledModal = styled(GivelifyModal)(() => ({
    '& .MuiPaper-root': {
        maxWidth: 496,
    },
    '& .header': {
        marginBottom: 16,
    },
    '& .sub-header': {
        fontSize: 18,
    },
    '& .description': {
        marginBottom: 16,
    },
    '& .confirm': {
        marginTop: 16,
    },
    '& .link': {
        fontWeight: 600,
    },
    '& ul': {
        marginTop: 0,
        paddingLeft: 20,

        '& li::marker': {
            color: DesignTokens.color.textSecondary,
            fontSize: 10,
            marginBottom: 3,
        },
    },
}));