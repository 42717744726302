import React from 'react';
import { GivelifyTableColumn } from '../types';
import RenderCell from './RenderCell';
import useStyles from './styles';

interface RenderRowProps<T> {
    item?: T;
    rowKey: string;
    columns: GivelifyTableColumn<T>[];
    offset: number[];
    loading?: boolean;
    rowId?: string;
}

const RenderRow = <T,>({
    item,
    rowKey,
    columns,
    offset,
    loading,
    rowId,
}: RenderRowProps<T>) => {
    const { tbRow } = useStyles();

    return (
        <tr className={tbRow} id={rowId}>
            {columns.map((column, columnIndex) => (
                <RenderCell<T>
                    key={`${rowKey}-column-${columnIndex}`}
                    row={item}
                    column={column}
                    columnIndex={columnIndex}
                    offset={offset}
                    loading={loading}
                />
            ))}
        </tr>
    );
};

export const RenderRowPure = <T,>({
    item,
    rowKey,
    columns,
    offset,
    loading,
}: RenderRowProps<T>) => {
    return (
        <>
            {columns.map((column, columnIndex) => (
                <RenderCell<T>
                    key={`${rowKey}-column-${columnIndex}`}
                    row={item}
                    column={column}
                    columnIndex={columnIndex}
                    offset={offset}
                    loading={loading}
                />
            ))}
        </>
    );
};

export default RenderRow;
