import React from 'react';
import { Radio } from '@material-ui/core';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyLabel } from '../label';
import { radioStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

export interface GivelifyRadioProps extends GivelifyBoxMarginProps {
    id?: string;
    /**
     * Input name
     */
    name: string;
    /**
     * Input value
     */
    value?: string;
    /**
     * Default Input value
     */
    defaultValue?: string;
    /**
     * Input aria-label
     */
    ariaLabel: string;
    /**
     * Color primary or secondary
     */
    variant?: 'primary' | 'secondary';
    /**
     * Radio state
     */
    checked: boolean;
    /**
     * OnChange handler
     */
    onChange?: (checked: boolean) => void;
    /**
     * Input label to display
     */
    label?: string;
    /**
     * Input label position
     */
    labelPosition?: 'before' | 'after';
    /**
     * Use bold Input label
     */
    labelBold?: boolean;
    className?: string;
    classNameLabel?: string;
    /**
     * Disable Radio
     */
    disabled?: boolean;
    /**
     * Set this so that it can be select using tab
     */
    tabIndex?: number;
    testId?: string;
}

export const GivelifyRadio: React.FC<GivelifyRadioProps> = props => {
    const {
        boxMargin,
        radioContainer,
        labelAfter,
        labelBefore,
        radioHover,
    } = radioStyles({
        variant: props.variant ? props.variant : 'secondary',
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        marginRight: props.marginRight,
        marginTop: props.marginTop,
    });
    const className = mergeClassNames(
        boxMargin,
        radioContainer,
        props.className,
    );
    const classNameLabelAfter = mergeClassNames(
        labelAfter,
        props.classNameLabel,
    );
    const classNameLabelBefore = mergeClassNames(
        labelBefore,
        props.classNameLabel,
    );
    const onClick = () => {
        if (props.onChange) {
            props.onChange(!props.checked);
        }
    };
    return (
        <div
            id={`${props.id ? `${props.id}-container` : ''}`}
            className={className}
        >
            {props.label && props.labelPosition === 'before' ? (
                <GivelifyLabel
                    className={classNameLabelBefore}
                    text={props.label}
                    variant="body1"
                    bold={props.labelBold}
                />
            ) : null}
            <Radio
                id={props.id}
                classes={{ root: radioHover, checked: radioHover }}
                checked={props.checked}
                onClick={onClick}
                value={props.value}
                name={props.name}
                inputProps={{ 'aria-label': props.ariaLabel }}
                color="default"
                defaultValue={props.defaultValue}
                size="small"
                disabled={props.disabled}
                tabIndex={props.tabIndex}
                data-testid={props.testId}
            />
            {props.label && props.labelPosition !== 'before' ? (
                <GivelifyLabel
                    className={classNameLabelAfter}
                    text={props.label}
                    variant="body1"
                    bold={props.labelBold}
                />
            ) : null}
        </div>
    );
};
