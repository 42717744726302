import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyFormInput } from '@givelify/givelify-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Grid } from '@material-ui/core';
import { ErrorText } from 'components/ErrorText';
import { SETTINGS_ASSETS_PATH } from 'pages/settings/SettingsAssetsPath';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SocialInfo } from 'types/orgInfoTypes';
import { urlRegExp } from 'utils/validationRegExp';
import { AnySchema, object, string } from 'yup';

import EditorButtons from '../EditorButtons';

const useStyles = makeStyles(() => ({
    editorContainer: {
        width: '100%',
        marginTop: 32,
        marginBottom: 8,
    },
    editorItemTitle: {
        lineHeight: '20px',
        marginBottom: 16,
    },
    editorItemValue: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 40,
    },
    imgContainer: {
        width: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    editorInputField: {
        height: 48,
        marginLeft: 11,
    },
}));

interface SocialSectionEditorProps {
    data: SocialInfo;
    onCancel: () => void;
    onSubmit: (data: SocialInfo) => void;
    saving?: boolean;
    error?: string;
}

const SocialSectionEditor: React.FC<SocialSectionEditorProps> = (
    props: SocialSectionEditorProps,
) => {
    const {
        editorContainer,
        editorItemTitle,
        editorInputField,
        editorItemValue,
        imgContainer,
    } = useStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            socialMedia: t(
                'pages.settings.organization-info.social.socialMedia',
            ),
            website: t('pages.settings.organization-info.social.website'),
            facebook: t('pages.settings.organization-info.social.facebook'),
            instagram: t('pages.settings.organization-info.social.instagram'),
            twitter: t('pages.settings.organization-info.social.twitter'),
            invalidURL: t(
                'pages.settings.organization-info.social.invalid-url',
            ),
            required: t('labels.required'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const fieldSchema = string().test(
        'Invalid URL',
        copy.invalidURL,
        function (value) {
            if (!value?.length) return true;
            return urlRegExp.test(value);
        },
    );

    const form = useForm<SocialInfo>({
        mode: 'onBlur',
        resolver: yupResolver(
            object<Record<keyof SocialInfo, AnySchema>>({
                facebook: fieldSchema,
                instagram: fieldSchema,
                twitter: fieldSchema,
                website: fieldSchema.required(copy.required),
            }),
        ),
        defaultValues: {
            facebook: props.data.facebook,
            instagram: props.data.instagram,
            twitter: props.data.twitter,
            website: props.data.website,
        },
    });

    const handleSubmit = () => {
        const { facebook, instagram, twitter, website } = form.getValues();
        const formatURL = (url: string) => {
            if (!url?.length) return null;
            return !url.match(/^[a-zA-Z]+:\/\//) ? 'http://' + url : url;
        };
        props.onSubmit({
            facebook: formatURL(facebook),
            instagram: formatURL(instagram),
            twitter: formatURL(twitter),
            website: formatURL(website),
        });
    };

    return (
        <form
            className={editorContainer}
            onSubmit={form.handleSubmit(handleSubmit)}
        >
            <Grid container spacing={3}>
                <Grid item md={6} sm={12} xs={12}>
                    <GivelifyLabel
                        className={editorItemTitle}
                        text={copy.socialMedia}
                        variant="heading5"
                    />
                    <div className={editorItemValue}>
                        <div className={imgContainer}>
                            <img
                                alt="facebook link"
                                src={SETTINGS_ASSETS_PATH.FACEBOOK_ICON}
                            />
                        </div>
                        <GivelifyFormInput
                            fullWidth
                            ariaLabel="facebook-input"
                            className={editorInputField}
                            disabled={props.saving}
                            formRef={form}
                            id="facebook"
                            inputLabel="facebook-input"
                            name="facebook"
                            placeholder={copy.facebook}
                        />
                    </div>
                    <div className={editorItemValue}>
                        <div className={imgContainer}>
                            <img
                                alt="twitter link"
                                src={SETTINGS_ASSETS_PATH.TWITTER_ICON}
                            />
                        </div>
                        <GivelifyFormInput
                            fullWidth
                            ariaLabel="twitter-input"
                            className={editorInputField}
                            disabled={props.saving}
                            formRef={form}
                            id="twitter"
                            inputLabel="twitter-input"
                            name="twitter"
                            placeholder={copy.twitter}
                        />
                    </div>
                    <div className={editorItemValue}>
                        <div className={imgContainer}>
                            <img
                                alt="instagram link"
                                src={SETTINGS_ASSETS_PATH.INSTAGRAM_ICON}
                            />
                        </div>
                        <GivelifyFormInput
                            fullWidth
                            ariaLabel="instagram-input"
                            className={editorInputField}
                            disabled={props.saving}
                            formRef={form}
                            id="instagram"
                            inputLabel="instagram-input"
                            name="instagram"
                            placeholder={copy.instagram}
                        />
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <GivelifyLabel
                        className={editorItemTitle}
                        text={copy.website}
                        variant="heading5"
                    />
                    <div className={editorItemValue}>
                        <div className={imgContainer}>
                            <img
                                alt="web link"
                                src={SETTINGS_ASSETS_PATH.WEB_ICON}
                            />
                        </div>
                        <GivelifyFormInput
                            fullWidth
                            ariaLabel="website-input"
                            className={editorInputField}
                            disabled={props.saving}
                            formRef={form}
                            id="website"
                            inputLabel="website-input"
                            name="website"
                            placeholder={copy.website}
                        />
                    </div>
                </Grid>
            </Grid>
            {props.error && <ErrorText text={props.error} />}
            <EditorButtons onCancel={props.onCancel} saving={props.saving} />
        </form>
    );
};

export default SocialSectionEditor;
