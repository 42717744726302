import React from 'react';
import { formatWithLocaltime } from '@givelify/utils';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import GivelifyModal from '../../../../theme/components/GivelifyModal';
import { formatMoneyWithCommas } from '../../../../utils/formatMoneyWithCommas';
import { ModelContentStylesProps } from '../../../login/useStyles';
import { scrollBarStyles } from '../../components/style';
import { DonationsStatus } from '../BankDepositInfiniteLoader/BankDepositRow';
import { BankDepositModalPagination } from './BankDepositModalPagination';

interface BankDepositModalProps {
    donation: DonationsStatus | undefined;
    handleClose: () => unknown;
}

const modalStyles: ModelContentStylesProps = {
    textAlign: 'left',
    padding: '0 0 !important',
    heightOnSmallScreens: '100%',
    width: 860,
    breakPoint: 'sm',
};

const useModalTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {
            '& .no-data': {
                padding: 10,
            },
            '& .pagination': {
                float: 'right',
            },
            '& .pagination td': {
                border: 'none',
            },
            '& .table-container': {
                height: '65vh',
                scrollbarColor: '#cacaca transparent',
                scrollbarWidth: 'thin',
                zIndex: 1,
                ...scrollBarStyles(),
            },
            '& .table-head': {
                borderTop: '1px solid #CAD2DB',
                borderBottom: '1px solid #CAD2DB',
                '& th': {
                    lineHeight: '16px',
                    background: theme.palette.background.paper,
                    borderTop: '1px solid #CAD2DB',
                },
            },
            '& .heading': {
                padding: theme.spacing(6),
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '26px',
            },
        },
    }),
);

const BankDepositModal: React.FC<BankDepositModalProps> = ({
    donation,
    handleClose,
}) => {
    const { t } = useTranslation();
    const { modalContent } = useModalTableStyles();
    if (!donation) return null;
    return (
        <GivelifyModal
            handleClose={handleClose}
            isOpen={true}
            label="Detail"
            modalStyles={modalStyles}
        >
            <div className={modalContent}>
                <Typography className="heading" variant="h2">
                    {t('bankDeposits.totalDepositOn')}{' '}
                    {formatWithLocaltime(donation.depositDate)} - $
                    {formatMoneyWithCommas(donation.depositAmount)}
                </Typography>
                <BankDepositModalPagination donation={donation} />
            </div>
        </GivelifyModal>
    );
};

export default BankDepositModal;
