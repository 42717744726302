import { useMemo } from 'react';

export const getInitialsFromName = (name: string | null, characters = 2) =>
    // Removing all emojis & surrogate pairs
    name?.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '')
        .replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
        .split(' ')
        .map(word => word.charAt(0))
        .join('')
        .slice(0, characters);

export const useAvatarOrInitials = (avatar: string | null, name: string, characters = 2) => {
    const avatarSrc = useMemo(
        () =>
            avatar
                ? avatar
                : getInitialsFromName(name, characters),
        [name, avatar, characters],
    );

    return avatarSrc;
};

export const useInitialsFromName = (name: string, characters = 2) => {
    const initials = useMemo(
        () =>
            getInitialsFromName(name, characters),
        [name, characters],
    );

    return initials;
};
