import React, { useCallback, useEffect, useState } from 'react';
import { GivelithonLaunch } from '@givelify/givelithon-ui';
import { useApiRequest, Logger, ApiResponse } from '@givelify/utils';
import LoadingBar from 'components/system/LoadingBar';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import { Error500 } from 'theme/components/ErrorPage';
import { getEnvelopesService } from '../../api/utils/serviceProvider';
import PageTitle from '../../components/PageTitle';
import { AppState } from '../../store';
import { webConfig } from '../../webConfig';
import {
    LaunchGivelithonData,
    LaunchGivelithonDataResponse,
    LaunchGivelithonDonor,
    LaunchGivelithonDonorsDataResponse,
    mapLaunchGivelithonDonorData,
    mapLaunchGivelithonEnvelopeData,
} from './types';

const loadData = async (
    doneeId: number,
    envelopeId: number,
): Promise<
    ApiResponse<
        [LaunchGivelithonDataResponse, LaunchGivelithonDonorsDataResponse]
    >
> => {
    const envelopeService = getEnvelopesService();

    const givelithonDataResponse = await envelopeService.getEnvelopeStats(
        doneeId,
        envelopeId,
    );
    if (!givelithonDataResponse.success) {
        return {
            success: false,
            error: givelithonDataResponse.error,
        };
    }
    const givelithonDonorsResponse = await envelopeService.getEnvelopeDonors(
        doneeId,
        envelopeId,
    );
    if (!givelithonDonorsResponse.success) {
        return {
            success: false,
            error: givelithonDonorsResponse.error,
        };
    }

    return {
        success: true,
        response: [
            givelithonDataResponse.response,
            givelithonDonorsResponse.response,
        ],
    };
};

const LaunchGivelithon = () => {
    const [envelopeData, setEnvelopeData] = useState<LaunchGivelithonData>();
    const [donorList, setDonorList] = useState<LaunchGivelithonDonor[]>([]);
    const socketRef = React.useRef<ReturnType<typeof io.connect>>();
    const { doneeName, doneeId, doneeType } = useSelector(
        (state: AppState) => ({
            doneeName: state.Donee.donee.name,
            doneeId: state.Donee.donee.id,
            doneeType: state.Donee.donee.type,
        }),
    );
    const params = useParams<{ envelopeId: string }>();
    const envelopeId = +params.envelopeId;

    const [loadDataRequest, makeLoadDataRequest] =
        useApiRequest<
            [LaunchGivelithonDataResponse, LaunchGivelithonDonorsDataResponse]
        >();

    useEffect(() => {
        if (loadDataRequest.type !== 'REQUEST_SUCCESS') return;

        const [envelopeResponse, donorsResponse] = loadDataRequest.response;
        setEnvelopeData(mapLaunchGivelithonEnvelopeData(envelopeResponse.data));
        setDonorList(
            donorsResponse.data.map((donor) =>
                mapLaunchGivelithonDonorData(donor),
            ) as LaunchGivelithonDonor[],
        );
    }, [loadDataRequest]);

    const requestEnvelopeStats = useCallback(() => {
        makeLoadDataRequest(loadData(doneeId, envelopeId));
    }, [doneeId, envelopeId, makeLoadDataRequest]);

    useEffect(() => {
        socketRef.current = io.connect(webConfig.givelithonApiBaseUrl, {
            query: {
                doneeId,
                secret: webConfig.givelithonApiSecret,
                token: webConfig.givelithonApiToken,
            },
            // https://stackoverflow.com/questions/30713635/node-js-with-socket-io-long-polling-fails-and-throws-code1-messagesessi
            path: '/socket.io',
            secure: true,
        });
        socketRef.current.on('donationUpdates', () => {
            requestEnvelopeStats();
        });

        socketRef.current.on('error', (error: Error) => {
            Logger.log(
                `Givelithon socket error: ${error.message}`,
                {
                    envelopeId,
                },
                Logger.LogLevel.ERROR,
            );
        });

        socketRef.current.on('disconnect', (reason: unknown) => {
            if (reason === 'io server disconnect') {
                socketRef.current?.connect();
            }

            Logger.log(
                `Givelithon socket disconnect: ${reason}`,
                {
                    envelopeId,
                },
                Logger.LogLevel.ERROR,
            );
        });

        socketRef.current.on('connect_error', (error: Error) => {
            Logger.log(
                `Givelithon socket connect_error: ${error.message}`,
                {
                    envelopeId,
                },
                Logger.LogLevel.ERROR,
            );
        });

        socketRef.current.on('reconnect', () => {
            requestEnvelopeStats();

            Logger.log(
                `Givelithon socket reconnected`,
                {
                    envelopeId,
                },
                Logger.LogLevel.ERROR,
            );
        });

        // initial start
        requestEnvelopeStats();

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                console.log('cleanup disconnect');
            }
        };
        // listen only to doneeId and requestEnvelopeStats
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doneeId, requestEnvelopeStats]);

    React.useEffect(() => {
        console.count('requestEnvelopeStats updated');
    }, [requestEnvelopeStats]);

    if (loadDataRequest.type === 'REQUEST_ERROR') {
        return <Error500 />;
    }

    const isLoading = loadDataRequest.type === 'REQUEST_START';

    return (
        <>
            <PageTitle
                description="Launch Givelithon"
                title="Launch Givelithon"
            />
            {isLoading ? <LoadingBar show /> : null}
            {envelopeData ? (
                <GivelithonLaunch
                    amount={envelopeData.amount}
                    doneeName={doneeName}
                    doneeType={doneeType}
                    donorList={donorList}
                    envelopeId={envelopeId}
                    goal={envelopeData.goal}
                    name={envelopeData.envelopeName}
                    siteUrl={webConfig.siteUrl}
                    totalDonor={donorList.length}
                />
            ) : null}
        </>
    );
};

export default LaunchGivelithon;
