import React from 'react';
import { GivelifyColorPalette, GivelifyIcon } from '@givelify/givelify-ui';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SelectOptionProps } from '../types';

const useStyles = makeStyles(() => ({
    checkmarkRadio: {
        padding: '12px 24px',
        borderBottom: `1px solid ${GivelifyColorPalette.grey500}`,
        justifyContent: 'space-between',
        '& .MuiRadio-checked': {
            background: 'hotpink',
        },
        '& .MuiRadio-root': {
            order: 1,
            marginLeft: 'auto',
            padding: 0,
        },
        '&:last-child': {
            borderBottom: 0,
        },
    },
    checkIcon: {
        fontSize: 14,
    },
}));

const SelectorItem = ({ option }: { option: SelectOptionProps }) => {
    const classes = useStyles();

    return (
        <FormControlLabel
            className={classes.checkmarkRadio}
            control={
                <Radio
                    checkedIcon={
                        <GivelifyIcon
                            className={classes.checkIcon}
                            color="#52545B"
                            variant="check"
                        />
                    }
                    icon={
                        <GivelifyIcon
                            className={classes.checkIcon}
                            color="transparent"
                            variant="check"
                        />
                    }
                />
            }
            label={option.label}
            value={option.value}
        />
    );
};

export default SelectorItem;
