import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyModal } from '@givelify/givelify-ui';
import { GivelifyLabel, DesignTokens } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';
import { useAdvancedTranslation } from 'utils/i18n';

interface CancelConfirmModalProps {
    onCancel: () => void;
    onOk: () => void;
    open: boolean;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    label: {
        marginTop: '32px !important',
        marginBottom: '16px !important',
    },
}));

const CancelConfirmModal: React.FC<CancelConfirmModalProps> = ({
    onCancel,
    onOk,
    open,
}) => {
    const { label, wrapper } = useStyles();

    const { scopedTranslate } = useAdvancedTranslation(
        'pages.settings.envelopes2.envelopes-editor.cancel-confirm-modal',
    );

    const copy = useMemo(
        () => ({
            firstLabel: scopedTranslate('firstLabel'),
            secondLabel: scopedTranslate('secondLabel'),
            cancel: scopedTranslate('cancel'),
            confirm: scopedTranslate('confirm'),
        }),
        [scopedTranslate],
    );

    return (
        <GivelifyModal
            autoFullscreen
            centerButtons
            showCloseButton
            autoFullscreenBreakpoint="sm"
            onClose={onCancel}
            onPrimaryClick={onOk}
            onSecondaryClick={onCancel}
            open={open}
            primaryButtonText={copy.confirm}
            secondaryButtonText={copy.cancel}
        >
            <div className={wrapper}>
                <GivelifyIcon size={64} variant="alert" />
                <div className={label}>
                    <GivelifyLabel text={copy.firstLabel} variant="heading2S" />
                </div>
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    text={copy.secondLabel}
                    variant="body1"
                />
            </div>
        </GivelifyModal>
    );
};

export default CancelConfirmModal;
