import React from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Heading from '../../components/Heading';
import PageTitle from '../../components/PageTitle';
import Space from '../../components/Space';
import roleTypes from '../../constants/roleTypes';
import { AppState } from '../../store';
import { usePaperStyles } from '../../styles/paperClasses';
import EditProfileForm from './components/EditProfileForm';
import LeftColumn from './components/LeftColumn';

const breakPoint = 'md';
const useStyles = makeStyles((theme: Theme) => {
    const spacing5 = theme.spacing(5);
    const spacing6 = theme.spacing(6);
    return createStyles({
        grid: {
            '& .left-column': {
                paddingTop: spacing5,
                paddingBottom: spacing5,
                paddingLeft: spacing5,
                paddingRight: spacing5,
                borderRight: '1px solid #d8d8d8',
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down(breakPoint)]: {
                    borderRight: 'unset',
                    paddingRight: spacing5,
                    paddingLeft: spacing6,
                    paddingTop: spacing5,
                    paddingBottom: 0,
                    width: '100%',
                    justifyContent: 'center',
                },
            },
            '& .top-row': {
                display: 'none',
                marginBottom: theme.spacing(4),
                '& .actions': {
                    textAlign: 'center',
                },
                [theme.breakpoints.down(breakPoint)]: {
                    display: 'inherit',
                },
            },
            '& .right-column': {
                paddingRight: spacing5,
                paddingLeft: spacing6,
                paddingTop: spacing5,
                paddingBottom: spacing5,
            },
        },
    });
});

const EditProfile: React.FC = () => {
    const { t } = useTranslation();
    const { paperWithoutPadding } = usePaperStyles();
    const { grid } = useStyles();
    const { user } = useSelector((state: AppState) => ({
        user: state.User.user,
    }));

    const role = user.role;
    const isAdminPanelRole = role === roleTypes.ADMIN_PANEL_USER;

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.EditProfile}>
            <PageTitle description="Edit Profile" title="Edit Profile" />
            <Heading text={t('editProfile.title')} />
            <Space gap={4} />
            <Paper className={paperWithoutPadding}>
                <Grid container className={grid}>
                    <Grid item className="left-column" lg={3} xl={3}>
                        <LeftColumn disabled={isAdminPanelRole} />
                    </Grid>
                    <Grid
                        item
                        className="right-column"
                        lg={9}
                        md={12}
                        sm={12}
                        xl={9}
                        xs={12}
                    >
                        <EditProfileForm disabled={isAdminPanelRole} />
                    </Grid>
                </Grid>
            </Paper>
        </TrackingProvider>
    );
};

export default EditProfile;
