import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyTextField,
    GivelifyFormDropDown,
    GivelifyFormTextField,
    emailRegex,
} from '@givelify/givelify-ui';
import { isValidPhoneNumber } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Theme, Grid } from '@material-ui/core';
import { ErrorText } from 'components/ErrorText';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ContactInfo } from 'types/orgInfoTypes';
import { useAdvancedTranslation } from 'utils/i18n';
import { AnySchema, object, string } from 'yup';

import EditorButtons from '../EditorButtons';

const useStyles = makeStyles((theme: Theme) => ({
    editorContainer: {
        width: '100%',
    },
    editorItemTitle: {
        lineHeight: '20px',
        marginBottom: 8,
    },
    editorInputField: {
        marginBottom: 24,
        '& input': {
            height: '11px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
    helperText: {
        fontSize: 12,
        lineHeight: '20px',
        color: '#828282',
        fontWeight: 400,
    },
    helperTextLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
}));

interface ContactSectionEditorProps {
    data?: ContactInfo;
    onCancel: () => void;
    onSubmit: (data: ContactInfo) => void;
    saving?: boolean;
    error?: string;
    isChurch?: boolean;
    denominations: { id: string; name: string }[];
    loadingDenominations: boolean;
}
const phoneMask = '(999) 999-9999';
const sizeOptions = [
    { value: '1 - 99', label: '1 - 99' },
    { value: '100 - 499', label: '100 - 499' },
    { value: '500 - 1,499', label: '500 - 1,499' },
    { value: '1,500 - 4,999', label: '1,500 - 4,999' },
    { value: '5,000 - 9,999', label: '5,000 - 9,999' },
    { value: '10,000 +', label: '10,000 +' },
];
const ContactSectionEditor: React.FC<ContactSectionEditorProps> = (
    props: ContactSectionEditorProps,
) => {
    const {
        editorContainer,
        editorItemTitle,
        editorInputField,
        helperText,
        helperTextLink,
    } = useStyles();
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            phoneNumber: t(
                'pages.settings.organization-info.contact.phoneNumber',
            ),
            invalidPhoneNumber: t(
                'pages.settings.organization-info.contact.invalidPhoneNumber',
            ),
            phoneMaxlen: t(
                'pages.settings.organization-info.contact.phoneMaxlen',
            ),
            emailMaxlen: t(
                'pages.settings.organization-info.contact.emailMaxlen',
            ),
            email: t('pages.settings.organization-info.contact.email'),
            size: at('pages.settings.organization-info.contact.size'),
            sizePlaceholder: at(
                'pages.settings.organization-info.contact.sizePlaceholder',
            ),
            denomination: t(
                'pages.settings.organization-info.contact.denomination',
            ),
            denominationHelp: t(
                'pages.settings.organization-info.contact.denominationHelp',
            ),
            established: t(
                'pages.settings.organization-info.contact.established',
            ),
            invalidYear: t(
                'pages.settings.organization-info.contact.invalidYear',
            ),
            contactSupport: t(
                'pages.settings.organization-info.contact.contactSupport',
            ),
            emailRequired: t('error.signup.email'),
            phoneRequired: t('error.signup.phone'),
            required: t('labels.required'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
            denominationOptions: props.denominations.map((denom) => ({
                value: denom.name,
                label: denom.name,
            })),
        }),
        [t, props.denominations, at],
    );

    const form = useForm<ContactInfo>({
        mode: 'onBlur',
        resolver: yupResolver(
            object<Record<keyof ContactInfo, AnySchema>>({
                phone: string()
                    .test('phone', copy.invalidPhoneNumber, isValidPhoneNumber)
                    .required(copy.required)
                    .max(14, copy.phoneMaxlen),
                email: string()
                    .matches(emailRegex, copy.emailRequired)
                    .required(copy.required)
                    .max(255, copy.emailMaxlen),
                established: string().test('year', copy.invalidYear, (year) => {
                    if (!/^\d{4}$/.test(year)) return false;
                    return parseInt(year) <= new Date().getFullYear();
                }),
                congregationSize: string(),
                denomination: string(),
            }),
        ),
        defaultValues: {
            phone: props.data.phone || '',
            email: props.data.email || '',
            established: props.data.established || '',
            denomination: props.data.denomination || null,
            congregationSize: props.data.congregationSize || null,
        },
    });

    const handleSubmit = () => {
        const formValues = form.getValues();
        props.onSubmit({
            ...props.data,
            ...formValues,
        });
    };

    return (
        <form
            className={editorContainer}
            onSubmit={form.handleSubmit(handleSubmit)}
        >
            <Grid container spacing={3}>
                <Grid item md={6} sm={12} xs={12}>
                    <GivelifyLabel
                        className={editorItemTitle}
                        text={copy.phoneNumber}
                        variant="heading5"
                    />
                    <div className={editorInputField}>
                        <Controller
                            control={form.control}
                            defaultValue={props.data.phone || null}
                            name="phone"
                            render={(field) => (
                                <InputMask
                                    mask={phoneMask}
                                    onChange={field.onChange}
                                    value={field.value}
                                >
                                    {() => (
                                        <GivelifyTextField
                                            ariaLabel="Phone Input"
                                            id="phone"
                                            leftHelperText={
                                                form.errors['phone']
                                                    ? form.errors['phone']
                                                          .message
                                                    : ''
                                            }
                                            name="phone"
                                            state={
                                                form.errors['phone']
                                                    ? 'error'
                                                    : 'normal'
                                            }
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <GivelifyLabel
                        className={editorItemTitle}
                        text={copy.email}
                        variant="heading5"
                    />
                    <div className={editorInputField}>
                        <GivelifyFormTextField
                            ariaLabel="Email input"
                            defaultValue={props.data.email || null}
                            formInputRef={form.register({
                                required: true,
                            })}
                            id="email"
                            leftHelperText={
                                form.errors['email']
                                    ? form.errors['email'].message
                                    : ''
                            }
                            maxLength={255}
                            name="email"
                            state={form.errors['email'] ? 'error' : 'normal'}
                        />
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <GivelifyLabel
                        className={editorItemTitle}
                        text={copy.established}
                        variant="heading5"
                    />
                    <div className={editorInputField}>
                        <GivelifyFormTextField
                            ariaLabel="Established year"
                            defaultValue={props.data.established || null}
                            formInputRef={form.register({
                                required: true,
                            })}
                            id="established"
                            leftHelperText={
                                form.errors['established']
                                    ? form.errors['established'].message
                                    : ''
                            }
                            maxLength={4}
                            name="established"
                            state={
                                form.errors['established'] ? 'error' : 'normal'
                            }
                        />
                    </div>
                </Grid>
                {props.isChurch && (
                    <>
                        <Grid item md={6} sm={12} xs={12}>
                            <GivelifyLabel
                                className={editorItemTitle}
                                text={copy.denomination}
                                variant="heading5"
                            />
                            <div className={editorInputField}>
                                <GivelifyFormDropDown
                                    disableClearButton
                                    ariaLabel="Denomination Select"
                                    defaultValue={
                                        props.data.denomination || null
                                    }
                                    formInputRef={form.register({
                                        required: true,
                                    })}
                                    id="denomination-select"
                                    leftHelperText={
                                        form.errors['denomination']
                                            ? form.errors['denomination']
                                                  .message
                                            : ''
                                    }
                                    name="denomination"
                                    options={copy.denominationOptions}
                                    state={
                                        form.errors['denomination']
                                            ? 'error'
                                            : 'normal'
                                    }
                                    width="100%"
                                />
                                <div className={helperText}>
                                    {copy.denominationHelp}
                                    <a
                                        className={helperTextLink}
                                        href="https://support.givelify.com/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {copy.contactSupport}
                                    </a>
                                    .
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <GivelifyLabel
                                className={editorItemTitle}
                                text={copy.size}
                                variant="heading5"
                            />
                            <div className={editorInputField}>
                                <GivelifyFormDropDown
                                    disableClearButton
                                    ariaLabel="Congregation Size"
                                    defaultValue={
                                        props.data.congregationSize || null
                                    }
                                    formInputRef={form.register({
                                        required: true,
                                    })}
                                    id="congregationSize-select"
                                    leftHelperText={
                                        form.errors['congregationSize']
                                            ? form.errors['congregationSize']
                                                  .message
                                            : ''
                                    }
                                    name="congregationSize"
                                    options={sizeOptions}
                                    state={
                                        form.errors['state']
                                            ? 'error'
                                            : 'normal'
                                    }
                                    width="100%"
                                />
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
            {props.error && <ErrorText text={props.error} />}
            <EditorButtons onCancel={props.onCancel} saving={props.saving} />
        </form>
    );
};

export default ContactSectionEditor;
