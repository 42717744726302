import React, { useMemo } from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { Typography, Box } from '@material-ui/core';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { webConfig } from 'webConfig';
import logo from '../../assets/givelify-logo.svg';
import ResetPasswordForm from '../../components/forms/resetPassword/ResetPasswordForm';
import PageTitle from '../../components/PageTitle';
import Space from '../../components/Space';
import { AppState } from '../../store';
import { isLoggedIn } from '../../store/user/types';
import { useStyles } from './useStyles';

const ResetPassword = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const loggedIn = useSelector((state: AppState) => isLoggedIn(state));
    const { PATH } = useGasRouterContext();

    const resetPasswordText = useMemo(
        () => ({
            text: {
                page: t('reset_password.text.page'),
                givelifyLlc: t('login.text.givelify_llc'),
            },
            link: {
                signup: t('login.link.signup'),
            },
            copyright: t('footer.copyright'),
        }),
        [t],
    );

    if (loggedIn) {
        return <Navigate to={PATH.OVERVIEW} />;
    }

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.ResetPassword}>
            <GoogleReCaptchaProvider reCaptchaKey={webConfig.googleSiteKey}>
                <div className={classes.container}>
                    <PageTitle
                        description={resetPasswordText.text.page}
                        title={resetPasswordText.text.page}
                    />
                    <div className={classes.background}>
                        <Box flexGrow={1}>
                            <img
                                alt="logo"
                                className={classes.logo}
                                src={logo}
                            />
                        </Box>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.form}>
                            <div className={classes.resetContent}>
                                <Typography
                                    className={classes.resetTitle}
                                    variant="h1"
                                >
                                    {resetPasswordText.text.page}
                                </Typography>
                                <Space gap={6} />
                                <ResetPasswordForm />
                            </div>
                        </div>
                        <Space gap={5} />
                        <footer>
                            <div className="copy">
                                <Typography variant="caption">
                                    {resetPasswordText.copyright}{' '}
                                    {new Date().getFullYear()}{' '}
                                    {resetPasswordText.text.givelifyLlc}
                                </Typography>
                            </div>
                        </footer>
                    </div>
                </div>
            </GoogleReCaptchaProvider>
        </TrackingProvider>
    );
};

export default ResetPassword;
