import React from 'react';
import { styled } from '@mui/material';
import { DataTablePaginationProps } from 'components';
import { useTranslation } from 'react-i18next';
import { TableNoResults } from '../../components/tableNoResults';
import { DonorsTable, DonorsTableProps } from './components';
import {
    DonorsFilterRow,
    DonorsFilterRowProps,
} from './components/donorsFilterRow';
import { EmptyDonors } from './components/emptyDonors';

const DonorsPageBox = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('tablet')]: {
        paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('mobile')]: {
        //mobile pading (16px) currently is provided by layout
        paddingBottom: 0,
    },
}));

type DonorsPageViewProps = Omit<DonorsTableProps, 'totalCount'> &
    Omit<DonorsFilterRowProps, 'filterPanelSubmitText'> & {
        totalCount?: number;
        pagination: DataTablePaginationProps;
        totalUnfilteredDonors?: number;
        learnMoreHref?: string;
        onLearnMoreClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    };

export const DonorsPageView: React.FC<DonorsPageViewProps> = ({
    loading,
    totalCount = 0,
    totalUnfilteredDonors = 0,
    data,
    pagination,
    onSortRequest,
    onSearchSubmit,
    onFilterSubmit,
    search,
    sort,
    filters,
    hasFilters,
    learnMoreHref,
    onLearnMoreClick,
    isFilterPanelOpen,
    onFilterButtonClick,
    doneeSignupDate,
    onFilterPanelClose,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            viewDonors: t('filters.viewDonors'),
        }),
        [t],
    );
    const noDonors = !loading && totalUnfilteredDonors === 0 && !hasFilters;
    return (
        <DonorsPageBox>
            <DonorsFilterRow
                disabled={noDonors || loading}
                doneeSignupDate={doneeSignupDate}
                filterPanelSubmitText={copy.viewDonors}
                filters={filters}
                isFilterPanelOpen={isFilterPanelOpen}
                onFilterButtonClick={onFilterButtonClick}
                onFilterPanelClose={onFilterPanelClose}
                onFilterSubmit={onFilterSubmit}
                onSearchSubmit={onSearchSubmit}
                search={search}
            />
            {noDonors ? (
                <EmptyDonors
                    href={learnMoreHref}
                    onLearnMoreClick={onLearnMoreClick}
                />
            ) : (
                <DonorsTable
                    NoResultComponent={
                        <TableNoResults
                            hasFilters={Object.values(filters).some((filter) =>
                                Boolean(filter),
                            )}
                            name="donors"
                            search={search}
                        />
                    }
                    data={data}
                    hasFilters={hasFilters}
                    loading={loading}
                    onSortRequest={onSortRequest}
                    pagination={pagination}
                    sort={sort}
                    totalCount={totalCount}
                    totalUnfilteredDonors={totalUnfilteredDonors}
                />
            )}
        </DonorsPageBox>
    );
};
