import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    checkboxRow: {
        marginTop: 35,
        marginBottom: 33,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    link: {
        textDecoration: 'none',
        color: theme.colors?.primary || theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.colors?.accentDarkBlue || theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
    agreementStyle: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 600,
        color: theme.gas.palette.label.primary,
        marginTop: -5,
    },
    formWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 16,
        '& > *': {
            gridColumn: 'span 2',
        },
    },
    column: {
        gridColumn: 'span 1',
    },
}));

export default useStyles;
