import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useSecondaryLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        secondaryContent: {
            flex: 1,
            position: 'relative',
            height: 'max-content',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            backgroundColor: '#FFFFFF',
        },
        contentWrapper: {
            flexGrow: 1,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2, 0),
            },
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
);
