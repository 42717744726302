import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const confirmationModalStyle = makeStyles((theme: Theme) => {
    return createStyles({
        modalHeader: {
            minHeight: 45,
        },
        confirmationModal: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        modalContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            paddingLeft: 40,
            paddingRight: 40,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        confirmationModalBody: {
            minHeight: 560,
            marginTop: 30,
        },
        statement: {
            textAlign: 'left',
        },
        desc: {
            fontSize: 16,
            lineHeight: '24px',
        },
        errorBanner: {
            paddingTop: 3,
            paddingBottom: 3,
            lineHeight: '18px',
        },
        modalFooter: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 32,
            marginBottom: 32,
        },
        cancelButton: {
            [theme.breakpoints.down('sm')]: {
                minWidth: 140,
                height: '40px',
            },
        },
        deleteButton: {
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                background: theme.palette.error.dark,
            },
            '&:disabled': {
                background: theme.colors.neutralPlatinum,
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: 140,
                height: '40px',
            },
        },
        userAvatarWrapper: {
            width: 48,
            margin: '16px 8px 16px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        usernameContainer: {
            width: '100%',
            display: 'flex',
            cursor: 'pointer',
            marginBottom: '10px',
            marginTop: '10px',
        },
        fullName: {
            lineHeight: '22px',
            textOverflow: 'ellipsis',
            fontSize: 17,
        },
        title: {
            lineHeight: '14px',
            color: theme.colors.neutralGrey,
        },
        userNameContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: 'auto',
            maxWidth: 'calc(100% - 176px)',
            '@media (max-width: 360px)': {
                maxWidth: 'unset',
            },
        },
        notification: {
            fontSize: 16,
        },
        saveButton: {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                background: theme.palette.primary.dark,
            },
            '&:disabled': {
                background: theme.colors.neutralPlatinum,
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: 140,
                height: '40px',
            },
        },
        you: {
            color: theme.colors.neutralGrey75,
            lineHeight: 'inherit',
        },
        changeAccountOwnerNameWrapper: {
            display: 'inline-flex',
        },
    });
});
