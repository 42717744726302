import React from 'react';
import { RequestState } from '@givelify/givelify-ui';
import CircularProgress from '@material-ui/core/CircularProgress';

interface DefaultLoadingProps<T, U> {
    requestState: RequestState<T>;
    error: U;
    loading?: JSX.Element;
    children: (t: T | U) => JSX.Element;
}

//TODO: we need either move it to the common folder or get rid of it
const DefaultLoading = <T, U>(
    props: DefaultLoadingProps<T, U>,
): JSX.Element => {
    switch (props.requestState.type) {
        case 'REQUEST_SUCCESS':
            return props.children(props.requestState.data);
        case 'REQUEST_ERROR':
            return props.children(props.error);

        case 'REQUEST_INIT':
        case 'REQUEST_START':
        default:
            return props.loading || <CircularProgress />;
    }
};

export default DefaultLoading;
