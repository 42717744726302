import React from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { Grid } from '@material-ui/core';
import { matchRowStyle } from '../../../../../components/style';
import { HeaderCell } from '../../../DonorMatching/components/DonorMatchingHeader';
import { donorTabStyle } from '../../style';

interface F1CCBUnmatchDonorHeaderProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: (sorting: Sorting[]) => void;
    copy: {
        donorName: string;
        lastDonation: string;
        noOfMatches: string;
        lastDonationDate: string;
        sort: string;
    };
}

const AutoIntegrationUnmatchDonorHeader: React.FC<F1CCBUnmatchDonorHeaderProps> = ({
    sortColumn,
    sortDirection,
    onOrderClick,
    copy,
}) => {
    const classes = matchRowStyle();
    const style = donorTabStyle();

    return (
        <div className={style.unmatchHeaderDonorRow}>
            <Grid
                container
                alignItems="center"
                className={style.donorContainer}
            >
                <Grid
                    item
                    className={
                        classes.matchHeaderItem +
                        ' ' +
                        style.unmatchDonorName +
                        ' header-item'
                    }
                >
                    <HeaderCell
                        label={copy.donorName}
                        name={'full_name'}
                        onOrderClick={() =>
                            onOrderClick([
                                {
                                    columnName: 'full_name',
                                    direction:
                                        sortDirection === 'asc'
                                            ? 'desc'
                                            : 'asc',
                                },
                            ])
                        }
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sortLabel={copy.sort}
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem +
                        ' ' +
                        style.unmatchDonation +
                        ' header-item'
                    }
                >
                    <HeaderCell
                        label={copy.lastDonation}
                        name={'last_donation_amount'}
                        onOrderClick={() =>
                            onOrderClick([
                                {
                                    columnName: 'last_donation_amount',
                                    direction:
                                        sortDirection === 'asc'
                                            ? 'desc'
                                            : 'asc',
                                },
                            ])
                        }
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sortLabel={copy.sort}
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem +
                        ' ' +
                        style.unmatchNumber +
                        ' header-item'
                    }
                >
                    <HeaderCell
                        label={copy.noOfMatches}
                        name={'match_suggestions_count'}
                        onOrderClick={() =>
                            onOrderClick([
                                {
                                    columnName: 'match_suggestions_count',
                                    direction:
                                        sortDirection === 'asc'
                                            ? 'desc'
                                            : 'asc',
                                },
                            ])
                        }
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sortLabel={copy.sort}
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem +
                        ' ' +
                        style.unmatchDate +
                        ' header-item'
                    }
                >
                    <HeaderCell
                        label={copy.lastDonationDate}
                        name={'last_donated_at'}
                        onOrderClick={() =>
                            onOrderClick([
                                {
                                    columnName: 'last_donated_at',
                                    direction:
                                        sortDirection === 'asc'
                                            ? 'desc'
                                            : 'asc',
                                },
                            ])
                        }
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sortLabel={copy.sort}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AutoIntegrationUnmatchDonorHeader;
