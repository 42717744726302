import { useMemo, useState } from 'react';
import heic2any from 'heic2any';
import * as pdfjs from 'pdfjs-dist';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import workerUrl from 'pdfjs-dist/build/pdf.worker.js?url';

pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;

export interface UseFilePickerProps {
    openFilePicker: () => void;
    onDeleteClick: () => void;
    fileExtension?: string;
    fileConvertInProgress: boolean;
    fileContent?: string;
    error?: 'size' | 'extension';
    uploadedFile?: File;
    clearErrors: () => void;
}

const parseHeic = async (file: File) => {
    const converted = await heic2any({
        blob: file,
        toType: 'image/jpg',
    });

    const blob = Array.isArray(converted) ? converted[0] : converted;

    const fileContent = URL.createObjectURL(blob);
    return fileContent;
};

const parsePdf = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const result = await pdfjs.getDocument({ data: buffer }).promise;
    const firstPage = await result.getPage(1);

    const scale = 1.5;
    const viewport = firstPage.getViewport({ scale: scale });

    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) return;

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
        canvasContext,
        viewport,
    };

    await firstPage.render(renderContext).promise;
    const fileContent = canvas.toDataURL('image/png');
    return fileContent;
};

const parseFile = async (file: File, extension: string) => {
    if (extension === 'heic') {
        return await parseHeic(file);
    } else if (extension === 'pdf') {
        return await parsePdf(file);
    } else {
        return URL.createObjectURL(file);
    }
};

export const useFilePicker = (): UseFilePickerProps => {
    const [uploadedFile, setUploadedFile] = useState<File>();
    const fileExtension = useMemo(
        () =>
            uploadedFile?.name
                ?.substr(uploadedFile?.name.lastIndexOf('.') + 1)
                ?.toLocaleLowerCase(),
        [uploadedFile],
    );
    const [fileConvertInProgress, setFileConvertInProgress] = useState(false);
    const [fileContent, setFileContent] = useState<string>();
    const [error, setError] = useState<'extension' | 'size'>();

    const clearErrors = () => setError(undefined);

    const openFilePicker = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = false;
        input.accept =
            'image/png, image/jpeg, image/jpg, application/pdf, image/heic';

        input.onchange = (_) => {
            const files = input.files;
            onFilePick(files);
        };

        input.click();
    };

    const onFilePick = async (files: FileList | null) => {
        let file = files?.item(0);
        if (!file) return;

        const supportedExtensions = [
            'jpg',
            'jpeg',
            'jpf',
            'png',
            'heic',
            'pdf',
        ];

        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;

        const fileNameAndExtension = file.name;
        const fileName = fileNameAndExtension.substr(
            0,
            fileNameAndExtension.lastIndexOf('.'),
        );
        const extension = fileNameAndExtension
            .substr(fileNameAndExtension.lastIndexOf('.') + 1)
            ?.toLocaleLowerCase();

        const validExtension = supportedExtensions.includes(extension);
        const validSize = fileMb <= 50;

        if (!validExtension) {
            onDeleteClick();
            setError('extension');
            return;
        } else if (!validSize) {
            onDeleteClick();
            setError('size');
            return;
        } else {
            setError(undefined);
        }

        setFileConvertInProgress(true);
        const fileDataUrl = await parseFile(file, extension);
        if (!fileDataUrl) return;

        setFileContent(fileDataUrl);
        if (extension === 'heic') {
            const response = await fetch(fileDataUrl || '');
            const blob = await response.blob();
            file = new File([blob], `${fileName}.jpg`);
        }

        setUploadedFile(file);
        setFileConvertInProgress(false);
    };

    const onDeleteClick = () => {
        setUploadedFile(undefined);
        setFileContent(undefined);
        setError(undefined);
    };

    return {
        openFilePicker,
        onDeleteClick,
        fileExtension,
        fileConvertInProgress,
        fileContent,
        error,
        uploadedFile,
        clearErrors,
    };
};
