import { DesignTokens, GivelifyButton } from '@givelify/ui';
import { styled } from '@mui/material';
import { ReactComponent as CheckMark } from './checkMark.svg';

export const DeleteButton = styled(GivelifyButton)(() => ({
    paddingLeft: 8,
    paddingRight: 8,
    color: `${DesignTokens.color.textSecondary} !important`,
    '& svg': {
        stroke: DesignTokens.color.textSecondary,
    },
}));

export const ListWrapper = styled('ul')(() => ({
    marginTop: 0,
    marginBottom: 16,
}));

export const UploadedFileWrapper = styled('div')(() => ({
    maxWidth: 282,
    border: `1px solid ${DesignTokens.color.borderComponentDefault}`,
    borderRadius: 8,
    padding: '32px 24px 16px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    backgroundColor: DesignTokens.color.backgroundComponentSecondary,
}));

export const UploadedFileImage = styled('img')(() => ({
    display: 'block',
    maxWidth: '100%',
    maxHeight: 112,
    border: `0.424px solid ${DesignTokens.color.textDisabled}`,
}));

export const CheckMarkIcon = styled(CheckMark)(() => ({
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
}));
