import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLockCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={31}
        width={28}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 28 31"
    >
        <path
            d="M6 24C5.45 24 4.979 23.8133 4.587 23.44C4.195 23.0667 3.99934 22.6184 4 22.0952V12.5714C4 12.0476 4.196 11.599 4.588 11.2257C4.98 10.8524 5.45067 10.666 6 10.6667H7V8.7619C7 7.44444 7.48767 6.32127 8.463 5.39238C9.43833 4.46349 10.6173 3.99937 12 4C13.3833 4 14.5627 4.46445 15.538 5.39333C16.5133 6.32222 17.0007 7.44508 17 8.7619V10.6667H18C18.55 10.6667 19.021 10.8533 19.413 11.2267C19.805 11.6 20.0007 12.0483 20 12.5714V22.0952C20 22.619 19.804 23.0676 19.412 23.441C19.02 23.8143 18.5493 24.0006 18 24H6ZM9 10.6667H15V8.7619C15 7.96825 14.7083 7.29365 14.125 6.7381C13.5417 6.18254 12.8333 5.90476 12 5.90476C11.1667 5.90476 10.4583 6.18254 9.875 6.7381C9.29167 7.29365 9 7.96825 9 8.7619V10.6667Z"
            fill="#5F47F6"
        />
        <circle
            cx="19"
            cy="21.8799"
            fill="#5F47F6"
            r="5.45455"
            stroke="#5F47F6"
            strokeWidth="0.909091"
        />
        <path
            d="M18.3 24.1799L21.825 20.6549L21.125 19.9549L18.3 22.7799L16.875 21.3549L16.175 22.0549L18.3 24.1799ZM19 26.8799C18.3083 26.8799 17.6583 26.7485 17.05 26.4859C16.4417 26.2232 15.9125 25.8671 15.4625 25.4174C15.0125 24.9674 14.6563 24.4382 14.394 23.8299C14.1317 23.2215 14.0003 22.5715 14 21.8799C14 21.1882 14.1313 20.5382 14.394 19.9299C14.6567 19.3215 15.0128 18.7924 15.4625 18.3424C15.9125 17.8924 16.4417 17.5362 17.05 17.2739C17.6583 17.0115 18.3083 16.8802 19 16.8799C19.6917 16.8799 20.3417 17.0112 20.95 17.2739C21.5583 17.5365 22.0875 17.8927 22.5375 18.3424C22.9875 18.7924 23.3438 19.3215 23.6065 19.9299C23.8692 20.5382 24.0003 21.1882 24 21.8799C24 22.5715 23.8687 23.2215 23.606 23.8299C23.3433 24.4382 22.9872 24.9674 22.5375 25.4174C22.0875 25.8674 21.5583 26.2237 20.95 26.4864C20.3417 26.749 19.6917 26.8802 19 26.8799Z"
            fill="white"
        />
    </svg>
);

export const LockCheckIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgLockCheck}
        fontSize={fontSize}
    />
);
