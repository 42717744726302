import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';

export interface ErrorPageProps {
    pageNotFoundText?: string;
    descriptionText?: string;
    overviewButtonText?: string;
    returnButtonText?: string;
    overviewButtonUrl: string | undefined;
    showReturnToPreviousButton: boolean;
    marginTopDesktop?: number;
    marginTopMobile?: number;
}

export const useErrorPageStyles = makeStyles((theme: Theme) =>
    createStyles({
        styles: ({
            showReturnToPreviousButton,
            svgWidth,
            marginTopDesktop,
            marginTopMobile,
        }: {
            showReturnToPreviousButton: boolean;
            svgWidth: number;
            marginTopDesktop?: number;
            marginTopMobile?: number;
        }) => ({
            maxWidth: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            '& .buttons': {
                marginTop: 56,
            },
            '& .buttons button': {
                minWidth: 248,
                display: 'inline-block',
            },
            '& .buttons .main-button': {
                marginRight: showReturnToPreviousButton
                    ? theme.spacing(3)
                    : undefined,
            },
            '& h1': {
                fontFamily: theme.typography.fontFamily,
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '40px',
                lineHeight: '38px',
                marginTop: 50,
                marginBottom: theme.spacing(4),
            },
            '& svg': {
                width: svgWidth,
                marginTop: marginTopDesktop || 104,
            },
            [theme.breakpoints.down('sm')]: {
                '& svg': {
                    marginTop: marginTopMobile || 96,
                },
                '& .label': {
                    fontSize: 18,
                    lineHeight: '21px',
                },
                '& .buttons': {
                    marginTop: theme.spacing(5),
                },
                '& .buttons button': {
                    minWidth: 257,
                    height: 36,
                    display: 'block',
                },
                '& .buttons .main-button': {
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginBottom: theme.spacing(3),
                },
                '& .buttons .previous-button': {
                    marginRight: 'auto',
                    marginLeft: 'auto',
                },
                '& h1': {
                    marginTop: 30,
                    fontSize: '24px',
                    lineHeight: '28px',
                    marginBottom: theme.spacing(3),
                },
            },
        }),
    }),
);
