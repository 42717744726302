import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GvlModalFooterBottomMargin, GvlModalSize } from './types';

export const modalStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentContainer: {
            position: 'absolute',
            width: (props: {
                width?: number;
                centerButtons?: boolean;
                size: GvlModalSize;
                footerMargin: GvlModalFooterBottomMargin;
            }) => {
                if (props.width) return props.width;
                switch (props.size) {
                    case 'small':
                        return 688;
                    case 'medium':
                        return 788;
                    case 'large':
                        return 864;
                    case 'xlarge':
                        return 1040;
                    case 'auto':
                        return 'auto';
                }
                return 688;
            },
            minHeight: 320,
            backgroundColor: theme.gas.palette.background.primary,
            boxShadow: theme.gas.elevation.high,
            borderRadius: 24,
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            '&:focus': {
                outline: 'none',
            },
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
        titleContainer: {
            display: 'flex',
            paddingTop: 20,
            paddingRight: 20,
            paddingBottom: 4,
        },
        footerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
            marginBottom: (props: {
                width?: number;
                centerButtons?: boolean;
                size: GvlModalSize;
                footerMargin: GvlModalFooterBottomMargin;
            }) => (props.centerButtons ? 32 : 24),
        },
        secondaryButton: {
            marginLeft: (props: {
                width?: number;
                centerButtons?: boolean;
                size: GvlModalSize;
                footerMargin: GvlModalFooterBottomMargin;
            }) => (props.centerButtons ? 16 : 'auto'),
            marginRight: (props: {
                width?: number;
                centerButtons?: boolean;
                size: GvlModalSize;
                footerMargin: GvlModalFooterBottomMargin;
            }) => (props.centerButtons ? 16 : 12),
        },
        primaryButton: {
            marginLeft: (props: {
                width?: number;
                centerButtons?: boolean;
                size: GvlModalSize;
                footerMargin: GvlModalFooterBottomMargin;
            }) => (props.centerButtons ? 16 : 12),
            marginRight: (props: {
                width?: number;
                centerButtons?: boolean;
                size: GvlModalSize;
                footerMargin: GvlModalFooterBottomMargin;
            }) => (props.centerButtons ? 16 : 24),
        },
        contentFullscreen: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transform: `translate(0, 0)`,
            width: '100% !important',
        },
        content: {
            flexGrow: 1,
            paddingBottom: '20px',
            paddingTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    }),
);
