import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const GraphToolTip = styled('div')({
    backgroundColor: DesignTokens.color.textWhite,
    padding: '12px',
    borderRadius: '16px',
    boxShadow: DesignTokens.shadow.shadowMedium,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    position: 'absolute',
    whiteSpace: 'nowrap',
    minWidth: '110px',
    transform: 'translate(-50%, -100%)',
});

export const Arrow = styled('div')({
    width: '0',
    height: '0',
    position: 'absolute',
    top: '100%',
    borderTop: `10px solid ${DesignTokens.color.textWhite}`,
    borderBottom: '10px solid transparent',
    borderRight: '8px solid transparent',
    borderLeft: '8px solid transparent',
});
