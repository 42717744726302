import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgBankDeposits = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={40}
        width={42}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 42 40"
    >
        <g clipPath="url(#clip0_295_48355)">
            <path
                d="M42 8.57143V10C42 10.3943 41.6864 10.7143 41.3 10.7143H39.2V11.7857C39.2 12.3774 38.7299 12.8571 38.15 12.8571H3.85C3.27014 12.8571 2.8 12.3774 2.8 11.7857V10.7143H0.7C0.313599 10.7143 0 10.3943 0 10V8.57143C0 8.30784 0.193713 8.01217 0.432373 7.91146L20.7324 0.0543213C20.8033 0.0243269 20.9232 0 21 0C21.0768 0 21.1967 0.0243269 21.2676 0.0543213L41.5676 7.91146C41.8063 8.01217 42 8.30784 42 8.57143ZM39.9 35.7143C41.0598 35.7143 42 36.6737 42 37.8571V39.2857C42 39.68 41.6864 40 41.3 40H0.7C0.313599 40 0 39.68 0 39.2857V37.8571C0 36.6737 0.940198 35.7143 2.1 35.7143H39.9ZM7 14.2857H12.6V31.4286H18.2V14.2857H23.8V31.4286H29.4V14.2857H35V31.4286H38.15C38.7299 31.4286 39.2 31.9083 39.2 32.5V34.2857H2.8V32.5C2.8 31.9083 3.27014 31.4286 3.85 31.4286H7V14.2857Z"
                fill="current"
            />
        </g>
        <defs>
            <clipPath id="clip0_295_48355">
                <rect fill="white" height="40" width="42" />
            </clipPath>
        </defs>
    </svg>
);

export const BankDepositsIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgBankDeposits}
        fontSize={fontSize}
    />
);
