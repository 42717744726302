import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { mergeClassNames, useTrackingContext } from '@givelify/utils';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    LOGIN_PAGE_CLICK_GIVE_NOW,
    LOGIN_PAGE_CLICK_PRIVACY_LINK,
    LOGIN_PAGE_CLICK_SUPPORT_LINK,
    LOGIN_PAGE_CLICK_TERMS_LINK,
} from 'utils/clevertapEvents';
import logo from '../assets/givelify-rebranding-logo.svg';
import Space from '../components/Space';
import { useLoginLayoutStyles } from '../styles/loginLayout';

interface LoginWrapperProps {
    children?: React.ReactNode;
}

const LoginWrapper: React.FC<LoginWrapperProps> = ({ children }) => {
    const { trackEvent } = useTrackingContext();
    const classes = useLoginLayoutStyles();
    const { t } = useTranslation();

    const currentYear = new Date().getFullYear();

    const loginWrapperText = useMemo(
        () => ({
            text: {
                donate: t('login.text.donate'),
                givelifyLlc: t('login.text.givelify_llc'),
                allRightsReserved: t('login.text.allRightsReserved'),
                tryingToDonate: t('login.text.tryingToDonate'),
                securedWith: t('login.text.securedWith'),
                copyRight: t('login.text.copyRight', {
                    year: currentYear,
                }),
            },
            link: {
                download: t('login.link.download'),
                signup: t('login.link.signup'),
                giveNow: t('login.link.giveNow'),
                militaryGradeEncryption: t(
                    'login.link.militaryGradeEncryption',
                ),
                termsUse: t('login.link.termsUse'),
                privacyPolicy: t('login.link.privacyPolicy'),
                support: t('login.link.support'),
            },
            copyright: t('footer.copyright'),
        }),
        [t, currentYear],
    );

    const onGiveNowClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_GIVE_NOW);
        window.open('https://www.givelify.com/givers/', '_blank');
    };

    const onTermsClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_TERMS_LINK);
        window.open('https://www.givelify.com/about/terms-of-use/', '_blank');
    };

    const onPrivacyPolicyClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_PRIVACY_LINK);
        window.open('https://www.givelify.com/about/privacy-policy/', '_blank');
    };

    const onSupportClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_SUPPORT_LINK);
        window.open('https://support.givelify.com/', '_blank');
    };

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div className={classes.body}>
                    <div className={classes.loginBody}>
                        <div className={classes.logoContent}>
                            <img
                                alt="logo"
                                className={classes.logo}
                                src={logo}
                            />
                        </div>
                        <div className={classes.form}>
                            <div>{children}</div>
                        </div>
                    </div>
                    <GivelifyTextDivider />
                    <div className={classes.donateBody}>
                        <GivelifyLabel
                            color="#3A4C63"
                            fontWeight={800}
                            text={loginWrapperText.text.tryingToDonate}
                            variant="body1"
                        />
                        <GivelifyButton
                            className={classes.giveButton}
                            onClick={onGiveNowClick}
                            text={loginWrapperText.link.giveNow}
                            variant="secondary-alt"
                        />
                    </div>
                </div>
                <div className={classes.securedRow}>
                    <GivelifyIcon size={15} variant="lock" />
                    <GivelifyLabel
                        color="neutralGrey"
                        marginLeft={8}
                        marginRight={3}
                        text={loginWrapperText.text.securedWith}
                        variant="body3"
                    />
                    <GivelifyLabel
                        bold
                        color="neutralGrey"
                        text={loginWrapperText.link.militaryGradeEncryption}
                        variant="body3"
                    />
                </div>
                <Space gap={4} />
            </div>
            <div className={classes.footer}>
                <div className={classes.supportRowMobile}>
                    <Link
                        className={classes.linkWiouthUnderline}
                        onClick={onTermsClick}
                    >
                        <GivelifyLabel
                            bold
                            className={classes.pointerCursor}
                            color="neutralGrey"
                            marginRight={8}
                            text={loginWrapperText.link.termsUse}
                            variant="body3"
                        />
                    </Link>
                    <Link
                        className={classes.linkWiouthUnderline}
                        onClick={onPrivacyPolicyClick}
                    >
                        <GivelifyLabel
                            bold
                            className={classes.pointerCursor}
                            color="neutralGrey"
                            marginRight={8}
                            text={loginWrapperText.link.privacyPolicy}
                            variant="body3"
                        />
                    </Link>
                    <Link
                        className={classes.linkWiouthUnderline}
                        onClick={onSupportClick}
                    >
                        <GivelifyLabel
                            bold
                            className={classes.pointerCursor}
                            color="neutralGrey"
                            marginRight={8}
                            text={loginWrapperText.link.support}
                            variant="body3"
                        />
                    </Link>
                </div>
                <div className={classes.copyRow}>
                    <GivelifyLabel
                        className={classes.copyRight}
                        color="neutralGrey"
                        text={loginWrapperText.text.copyRight}
                        variant="body3"
                    />
                    <Link
                        className={classes.linkWiouthUnderline}
                        onClick={onTermsClick}
                    >
                        <GivelifyLabel
                            bold
                            className={mergeClassNames(
                                classes.copyRight,
                                classes.showInWeb,
                            )}
                            color="neutralGrey"
                            text={loginWrapperText.link.termsUse}
                            variant="body3"
                        />
                    </Link>
                    <Link
                        className={classes.linkWiouthUnderline}
                        onClick={onPrivacyPolicyClick}
                    >
                        <GivelifyLabel
                            bold
                            className={mergeClassNames(
                                classes.copyRight,
                                classes.showInWeb,
                            )}
                            color="neutralGrey"
                            text={loginWrapperText.link.privacyPolicy}
                            variant="body3"
                        />
                    </Link>
                    <Link
                        className={classes.linkWiouthUnderline}
                        onClick={onSupportClick}
                    >
                        <GivelifyLabel
                            bold
                            className={mergeClassNames(
                                classes.copyRight,
                                classes.showInWeb,
                            )}
                            color="neutralGrey"
                            text={loginWrapperText.link.support}
                            variant="body3"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LoginWrapper;
