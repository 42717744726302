import { useLocation } from 'react-router-dom';

export type IntegrationCallback = {
    code?: string;
    error?: string;
    error_description?: string;
};

// Gets query parameters from Planning Center Auth callback
export const useIntegrationCallback = (): IntegrationCallback | null => {
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const code = params.get('code');
    const error = params.get('error');

    if (!code && !error) {
        return null;
    }

    const result: IntegrationCallback = {
        code: params.get('code'),
        error: params.get('error'),
        error_description: params.get('error_description'),
    };

    return result;
};
