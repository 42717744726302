import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        maxWidth: 688,
    },
    addressWrapper: {
        border: '1px solid #E3E3E3',
        borderRadius: 8,
        padding: '14px 32px',
        marginTop: 24,
        marginBottom: 8,
        '& > *': {
            lineHeight: '24px',
            '&:not(:first-child)': {
                fontWeight: 400,
            },
        },
    },
    textWrapper: {
        color: `${theme.colors.neutralGrey} !important`,
    },
    link: {
        textDecoration: 'none',
        color: `${theme.colors.neutralGrey} !important`,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
