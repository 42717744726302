import { useCallback, useMemo } from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';

const MailingAddressInfo = () => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const TRANSLATION_KEY_VALIDATION =
        'captivePortal.checkingAccount.mailingAddress';
    const scopedTranslate = useCallback(
        (key: string, args = undefined) =>
            t(`${TRANSLATION_KEY_VALIDATION}.${key}`, args),
        [t],
    );

    const copy = useMemo(
        () => ({
            aboutHeader: scopedTranslate('aboutHeader'),
            aboutDescription: scopedTranslate('aboutDescription'),
        }),
        [scopedTranslate],
    );
    return (
        <>
            <GivelifyLabel
                style={{
                    marginBottom: 12,
                }}
                text={copy.aboutHeader}
                variant="heading2S"
            />
            <GivelifyLabel text={copy.aboutDescription} variant="body2" />
        </>
    );
};

export default MailingAddressInfo;
