import { useMemo, useRef, useState } from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    HelpIcon,
} from '@givelify/ui';
import { LINK_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { webConfig } from 'webConfig';
import CalendlyModal, { useCalendly } from './CalendlyModal';
import { PendingInvites } from './PendingInvites/PendingInvites';
import { DesktopMenu, DesktopMenuItem, DesktopMenuItemIcon } from './styles';

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
} as const;

const transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
} as const;

const OnboardingDropdown = () => {
    const anchorRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { inviteOfficialEnabled, navigateToUsers } =
        useCaptivePortalContext();

    const { t } = useTranslation();

    const { goToHelp, inviteAnOfficial, scheduleCall } = useMemo(
        () => ({
            goToHelp: t('userDropdown.goToHelp'),
            inviteAnOfficial: t('userDropdown.inviteAnOfficial'),
            scheduleCall: t('userDropdown.scheduleCall'),
        }),
        [t],
    );

    const { isCalendlyEnabled, openCalendly, setOpenCalendly } = useCalendly();

    const { trackEvent } = useTrackingContext();

    return (
        <div
            ref={anchorRef}
            style={{
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
            }}
        >
            <GivelifyButton
                disableRipple
                name="Help"
                onClick={handleOpen}
                size="large"
                startIcon={
                    <HelpIcon
                        style={{
                            fontSize: '40px',
                        }}
                    />
                }
                style={{
                    padding: 0,
                    background: 'none',
                    color: open
                        ? DesignTokens.color.textAccentDefault
                        : DesignTokens.color.textSecondary,
                }}
                text="Help"
                variant="ghost"
            />
            <DesktopMenu
                anchorEl={anchorRef.current}
                anchorOrigin={anchorOrigin}
                data-testid="dropdown-menu"
                onClose={handleClose}
                open={open}
                transformOrigin={transformOrigin}
            >
                <DesktopMenuItem
                    {...{
                        onClick: () => {
                            handleClose();
                        },
                        component: GivelifyLink,
                        href: webConfig.supportUrl,
                        target: '_blank',
                        name: 'Articles',
                        text: (
                            <>
                                <DesktopMenuItemIcon variant="summary" />
                                <GivelifyLabel
                                    text={goToHelp}
                                    variant="body1"
                                />
                            </>
                        ),
                        'data-testid': 'toolbar-link',
                    }}
                >
                    &nbsp;
                </DesktopMenuItem>
                {isCalendlyEnabled && (
                    <DesktopMenuItem
                        onClick={() => {
                            trackEvent(`<Help Talk with an Expert>_${LINK_IDENTIFIER}`);
                            setOpenCalendly(true);
                            handleClose();
                        }}
                    >
                        <DesktopMenuItemIcon variant="calendar" />
                        <GivelifyLabel text={scheduleCall} variant="body1" />
                    </DesktopMenuItem>
                )}
                {inviteOfficialEnabled && (
                    <DesktopMenuItem
                        {...{
                            component: GivelifyLink,
                            onClick: () => {
                                navigateToUsers(true);
                                handleClose();
                            },
                            name: 'Help Invite an Official',
                            text: (
                                <>
                                    <DesktopMenuItemIcon variant="users" />
                                    <GivelifyLabel
                                        text={inviteAnOfficial}
                                        variant="body1"
                                    />
                                </>
                            ),
                            'data-testid': 'toolbar-invite-official',
                        }}
                    >
                        &nbsp;
                    </DesktopMenuItem>
                )}
                {inviteOfficialEnabled && (
                    <PendingInvites onAction={handleClose} />
                )}
            </DesktopMenu>
            {openCalendly && (
                <CalendlyModal onClose={() => setOpenCalendly(false)} />
            )}
        </div>
    );
};

export default OnboardingDropdown;
