import React from 'react';
import { GivelifyLabel, GivelifyPaper } from '@givelify/givelify-ui';
import { mergeClassNames } from '@givelify/utils';
import { makeStyles } from '@material-ui/core';

interface OverviewPaperProps {
    title: string;
    icon?: React.ReactNode;
    className?: string;
}

const useStyles = makeStyles(() => ({
    paper: {
        padding: '24px 32px 32px 32px',
        position: 'relative',
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        marginBottom: 24,
    },
}));

const OverviewPaper: React.FC<OverviewPaperProps> = ({
    className,
    children,
    title,
    icon,
}) => {
    const classes = useStyles();

    return (
        <GivelifyPaper className={mergeClassNames(classes.paper, className)}>
            <div className={classes.titleWrapper}>
                <GivelifyLabel text={title} variant="heading4" />
                {icon}
            </div>
            {children}
        </GivelifyPaper>
    );
};

export default OverviewPaper;
