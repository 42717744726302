export const HASH_PREFIX = 'my-donee-portal-ui-key';

export const encryptString = (content: string): string =>
    btoa(HASH_PREFIX + content);

export const decryptString = (encryptedString: string): string => {
    try {
        const decryptedStr = atob(encryptedString);
        return decryptedStr.replace(HASH_PREFIX, '');
    } catch (e) {
        return '';
    }
};
