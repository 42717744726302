import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GivelifyTextField } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import {
    ValidationErrors,
    ValidateAccountInfo,
} from '../../../utils/validation';

interface DirectDepositAccountInfoProps {
    routing: string | number | undefined;
    accounting: string | number | undefined;
    isFormValid: (isValid: boolean) => unknown;
    routingRef: React.RefObject<HTMLInputElement>;
    accountingRef: React.RefObject<HTMLInputElement>;
}

const DirectDepositAccountInfo: React.FC<DirectDepositAccountInfoProps> = ({
    routingRef,
    routing,
    isFormValid,
    accountingRef,
    accounting,
}) => {
    const [routingNumber, setRoutingNumber] = useState<string>(
        routing?.toString() || '',
    );
    const [accountNumber, setAccountNumber] = useState<string>(
        accounting?.toString() || '',
    );

    const [routingNumberIsDirty, setRoutingNumberIsDirty] = useState(false);
    const [accountNumberIsDirty, setAccountNumberIsDirty] = useState(false);

    const [formErrors, setFormErrors] = useState<{
        routingNumber: ValidationErrors;
        accountNumber: ValidationErrors;
    }>();

    const { t } = useTranslation(I18N_NAMESPACE);

    const { routingNumberPlaceholder, accountNumberPlaceholder, validation } =
        useMemo(
            () => ({
                routingNumberPlaceholder: t(
                    'directDepositManualEntry.textFields.routingNumber',
                ),
                invalidRoutingNumber: t(
                    'directDepositManualEntry.textFields.invalidRoutingNumber',
                ),
                accountNumberPlaceholder: t(
                    'directDepositManualEntry.textFields.accountNumber',
                ),
                invalidAccountNumber: t(
                    'directDepositManualEntry.textFields.invalidAccountNumber',
                ),
                validation: {
                    routingNumber: {
                        length: t(
                            'directDepositManualEntry.textFields.validation.routingNumber.length',
                        ),
                        startsWith: t(
                            'directDepositManualEntry.textFields.validation.routingNumber.startsWith',
                        ),
                        consecutive: t(
                            'directDepositManualEntry.textFields.validation.routingNumber.consecutive',
                        ),
                        space: t(
                            'directDepositManualEntry.textFields.validation.routingNumber.space',
                        ),
                    },
                    accountNumber: {
                        length: t(
                            'directDepositManualEntry.textFields.validation.accountNumber.length',
                        ),
                        consecutive: t(
                            'directDepositManualEntry.textFields.validation.accountNumber.consecutive',
                        ),
                        space: t(
                            'directDepositManualEntry.textFields.validation.accountNumber.space',
                        ),
                        startsWith: '',
                    },
                },
            }),
            [t],
        );

    useEffect(() => {
        const formData = {
            routingNumber,
            accountNumber,
        };

        const { isValid, errors } = ValidateAccountInfo(formData);

        isFormValid(isValid);
        setFormErrors(
            errors as {
                routingNumber: ValidationErrors;
                accountNumber: ValidationErrors;
            },
        );
    }, [routingNumber, accountNumber, isFormValid]);

    const handleRouting = useCallback(
        (_e: unknown, v: string) => {
            setRoutingNumber(v);
            setRoutingNumberIsDirty(true);
        },
        [setRoutingNumber],
    );

    const handleAccounting = useCallback(
        (_e: unknown, v: string) => {
            setAccountNumber(v);
            setAccountNumberIsDirty(true);
        },
        [setAccountNumber],
    );

    return (
        <>
            <GivelifyTextField
                ariaLabel={routingNumberPlaceholder}
                data-testid="routingNumber"
                id="routing-number"
                inputRef={routingRef}
                label={routingNumberPlaceholder}
                leftHelperText={
                    routingNumberIsDirty && formErrors?.routingNumber
                        ? validation.routingNumber[formErrors.routingNumber]
                        : undefined
                }
                marginBottom={16}
                maxLength={9}
                onChange={handleRouting}
                placeholder={routingNumberPlaceholder}
                state={
                    routingNumberIsDirty && formErrors?.routingNumber
                        ? 'error'
                        : 'normal'
                }
                value={routingNumber}
            />
            <GivelifyTextField
                ariaLabel={accountNumberPlaceholder}
                data-testid="accountNumber"
                id="account-number"
                inputRef={accountingRef}
                label={accountNumberPlaceholder}
                leftHelperText={
                    accountNumberIsDirty && formErrors?.accountNumber
                        ? validation.accountNumber[formErrors.accountNumber]
                        : undefined
                }
                onChange={handleAccounting}
                placeholder={accountNumberPlaceholder}
                state={
                    accountNumberIsDirty && formErrors?.accountNumber
                        ? 'error'
                        : 'normal'
                }
                value={accountNumber}
            />
        </>
    );
};

export default React.memo(DirectDepositAccountInfo);
