import React, { useMemo } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Box, List, ListItem, useTheme } from '@material-ui/core';
import Space from 'components/Space';
import IntegrationSetup from 'pages/integrations/components/IntegrationSetup';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { IntegrationTypes } from '../../../types';
import styles from './styles';

interface OwnProps {
    //eslint-disable-next-line
    t: (key: any, d?: any) => string;
    type: IntegrationTypes;
    doneCallback: () => void;
}

const CommonContent: React.FC<OwnProps> = ({ type, t, doneCallback }) => {
    const theme = useTheme();
    const classes = styles();

    const copy = useMemo(() => {
        return {
            title: {
                welcomeTo: t('title.welcome-to'),
                heading: t('title.heading', {
                    type: type && (type.integrationSystem || type.name),
                }),
                description: t('title.description'),
            },
            checklist: {
                heading: t('title.checklist.heading'),
                firstItem: t('title.checklist.first-item'),
                secondItem: t('title.checklist.second-item'),
                thirdItem: t('title.checklist.third-item', {
                    fundLabel: type ? type.fundLabel : '',
                }),
            },
            integrationForm: {
                getStarted: t('integration-form.get-started'),
                description: t('integration-form.description'),
            },
        };
    }, [t, type]);

    const enabledFeatures = useSelector(
        (state: AppState) => state.System.enabledFeatures,
    );

    const checklistItems = useMemo(() => {
        const items = [];
        if (
            isFeatureEnabled(enabledFeatures, Features.INTEGRATION_SCHEDULING)
        ) {
            items.push(copy.checklist.firstItem);
        }
        items.push(copy.checklist.secondItem);
        items.push(copy.checklist.thirdItem);
        if (type && !type.memberLabel) {
            items.pop();
        }
        return items;
    }, [copy, type, enabledFeatures]);

    return (
        <>
            <div className={classes.titleIntro} id="form-dialog-title">
                <GivelifyLabel
                    className={classes.integrationTitle}
                    text={copy.title.heading}
                    variant="heading4"
                />
                <GivelifyLabel
                    className={classes.titleContent}
                    text={copy.title.description}
                    variant="body1"
                />
                <GivelifyLabel
                    bold
                    className={classes.expect}
                    text={copy.checklist.heading}
                    variant="body1"
                />
                <List className={classes.titleCheckbox}>
                    {checklistItems.map((text: string) => {
                        return (
                            <ListItem key={text}>
                                <Box mr={2}>
                                    <FontAwesomeIcon
                                        className={classes.checkIcon}
                                        icon={faCheck}
                                        size="sm"
                                    />
                                </Box>
                                <GivelifyLabel text={text} variant="body2" />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
            <div className={classes.dialogContent}>
                <div>
                    {theme.breakpoints.up('md') && <Space gap={5} />}
                    <GivelifyLabel
                        bold
                        text={copy.integrationForm.getStarted}
                        variant="heading4"
                    />
                    {type &&
                        type.integrationType !== 'ACS' &&
                        type.integrationType !== 'SHELBY' && (
                            <GivelifyLabel
                                text={copy.integrationForm.description}
                                variant="body1"
                            />
                        )}
                </div>
                <IntegrationSetup
                    doneCallBack={doneCallback}
                    integration={type}
                />
            </div>
        </>
    );
};

export default CommonContent;
