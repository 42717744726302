import React, { useState } from 'react';
import { GivelifyButton, GivelifyTextField } from '@givelify/givelify-ui';
import {
    DONOR_PROFILE_PAGE_CLICK_CANCEL_EDIT_MEMBER_ID_LINK,
    DONOR_PROFILE_PAGE_CLICK_SAVE_EDIT_MEMBER_ID_LINK,
} from 'utils/clevertapEvents';
import { useStyles } from './useStyles';
import { States, ExpandableInputProps } from '.';

type FocusedProps = {
    saveLabel: string;
    setState: React.Dispatch<React.SetStateAction<States>>;
    classes: ReturnType<typeof useStyles>;
} & Omit<ExpandableInputProps, 'assignLabel'>;
export const Focused = ({
    saveLabel,
    value: initialValue,
    onValueUpdate,
    setState,
    classes,
}: FocusedProps) => {
    const [value, setValue] = useState(initialValue || '');
    const onSaveClick = () => {
        onValueUpdate(value);
        setState(States.NOT_FOCUSED);
    };
    const onPressEnter = (val: string) => {
        onValueUpdate(val);
        setState(States.NOT_FOCUSED);
    };
    const handleCancelClick = () => {
        onValueUpdate(initialValue);
        setState(States.NOT_FOCUSED);
    };

    return (
        <>
            <GivelifyTextField
                autoFocus
                ariaLabel="label"
                boxSize="dense"
                id="expandable_input"
                onChange={(_, value) => setValue(value)}
                onEnterSubmit={onPressEnter}
                value={value}
                width={120}
            />
            <GivelifyButton
                className={classes.saveButton}
                name={DONOR_PROFILE_PAGE_CLICK_SAVE_EDIT_MEMBER_ID_LINK}
                onClick={onSaveClick}
                text={saveLabel}
                variant="ghost"
            />
            <GivelifyButton
                className={classes.cancelButton}
                iconVariant="close"
                name={DONOR_PROFILE_PAGE_CLICK_CANCEL_EDIT_MEMBER_ID_LINK}
                onClick={handleCancelClick}
                variant="ghost"
            />
        </>
    );
};
