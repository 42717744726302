import { createContext, useContext, useState } from 'react';
import { LoaderRequestState } from '@givelify/ui';
import { directDepositAPI } from '../../../../api/requests/api';
import { UseFilePickerProps, useFilePicker } from '../../useFilePicker';
import SelectDocumentType from './SelectDocumentType';
import { AdditionalInfoProps, DocumentType } from './types';
import UploadDocument from './UploadDocument';

interface DocumentUploadContextProps {
    currentPage: 'selectDocumentType' | 'uploadDocument';
    selectedDocumentType?: DocumentType;
    onSelectDocumentType: (type: DocumentType) => void;
    onBack: () => void;
    onCancel: () => void;
    retryMessage: string;
    onContinue: () => void;
    onSubmit: (file: File) => Promise<void>;
    isLoading: boolean;
    filePickerProps: UseFilePickerProps;
    requestState: LoaderRequestState;
    onUploadSuccess: () => void;
}

const NOT_INITIALIZED = () => {
    throw new Error('Context not initialized');
};

const DocumentUploadContext = createContext<DocumentUploadContextProps>({
    currentPage: 'selectDocumentType',
    selectedDocumentType: undefined,
    onSelectDocumentType: NOT_INITIALIZED,
    onBack: NOT_INITIALIZED,
    onCancel: NOT_INITIALIZED,
    retryMessage: '',
    onContinue: NOT_INITIALIZED,
    onSubmit: NOT_INITIALIZED,
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    filePickerProps: undefined,
    requestState: 'REQUEST_INIT',
    onUploadSuccess: NOT_INITIALIZED,
});

export const useDocumentUploadContext = () => useContext(DocumentUploadContext);

const DocumentUploadProvider: React.FC<AdditionalInfoProps> = ({
    data,
    doneeId,
    onClose,
    onSuccess,
}) => {
    const [selectedDocumentType, setSelectedDocumentType] =
        useState<DocumentType>();
    const [currentPage, setCurrentPage] = useState<
        'selectDocumentType' | 'uploadDocument'
    >('selectDocumentType');
    const [requestState, setRequestState] =
        useState<LoaderRequestState>('REQUEST_INIT');

    const filePicker = useFilePicker();

    const onBack = () => {
        currentPage === 'selectDocumentType'
            ? onClose()
            : setCurrentPage('selectDocumentType');
    };

    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = async (file: File) => {
        setIsLoading(true);
        setRequestState('REQUEST_START');
        const response = await directDepositAPI(doneeId, {
            ...data,
            bankChequeFile: file,
        });
        if (!response.success) {
            setIsLoading(false);
            setRequestState('REQUEST_ERROR');
            return;
        }

        setRequestState('REQUEST_SUCCESS');
    };

    const onUploadSuccess = () => {
        onSuccess({ ...data, status: 'in_progress' });
    }

    return (
        <DocumentUploadContext.Provider
            value={{
                currentPage,
                selectedDocumentType,
                onSelectDocumentType: setSelectedDocumentType,
                onBack,
                onCancel: onClose,
                retryMessage: data.retryMessage || '',
                onContinue: () => {
                    filePicker.clearErrors();
                    setCurrentPage('uploadDocument');
                },
                onSubmit,
                isLoading,
                filePickerProps: filePicker,
                requestState,
                onUploadSuccess,
            }}
        >
            {currentPage === 'selectDocumentType' && <SelectDocumentType />}
            {currentPage === 'uploadDocument' && selectedDocumentType && (
                <UploadDocument />
            )}
        </DocumentUploadContext.Provider>
    );
};

export default DocumentUploadProvider;
