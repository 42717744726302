import { useMemo } from 'react';
import {
    Address,
    AddressWithPhone,
    isContainsPOBox,
    isValidCity,
    isValidStreet,
    isValidZip,
    phoneValidation,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { GivelifyForm } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import * as yup from 'yup';
import { I18N_NAMESPACE } from '../../../../consts';
import View from './view';

interface AddressProps {
    address: AddressWithPhone;
    onSubmit: (address: AddressWithPhone) => void;
    onBackClick: () => void;
    onCancelClick: () => void;
}

const AddressScreen: React.FC<AddressProps> = ({
    address,
    onBackClick,
    onCancelClick,
    onSubmit,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.primaryRepresentative.address`,
    });

    const copy = useMemo(
        () => ({
            poBoxError: scopedTranslate('poBoxError'),
            cityError: scopedTranslate('cityError'),
            streetError: scopedTranslate('streetError'),
            zipError: scopedTranslate('zipError'),
            phoneError: scopedTranslate('phoneError'),
        }),
        [scopedTranslate],
    );

    const schema: yup.SchemaOf<AddressWithPhone> = yup.object().shape({
        city: yup
            .string()
            .required(copy.cityError)
            .test('validCity', copy.cityError, (value) =>
                isValidCity(value || ''),
            ),
        state: yup.string().required(),
        street: yup
            .string()
            .required(copy.streetError)
            .test(
                'validStreet',
                ({ value }) =>
                    isContainsPOBox(value) ? copy.poBoxError : copy.streetError,
                (value) => isValidStreet(value || '', true),
            ),
        zip: yup
            .string()
            .required(copy.zipError)
            .test('validZip', copy.zipError, (value) =>
                isValidZip(value || ''),
            ),
        country: yup.string(),
        phone: yup
            .string()
            .required(copy.phoneError)
            .test('validPhone', copy.phoneError, (value) =>
                phoneValidation(value || ''),
            ),
    });

    const onSubmitInternal = (data: Partial<AddressWithPhone>) => {
        onSubmit({
            city: data.city || '',
            phone: data.phone || '',
            state: data.state || '',
            street: data.street || '',
            zip: data.zip || '',
        });
    };

    return (
        <TrackingProvider trackPageVisit pageName="Primary Rep Address">
            <GivelifyForm<Partial<Address>>
                defaultValues={address}
                onSubmit={onSubmitInternal}
                schema={schema}
            >
                <View onBackClick={onBackClick} onCancelClick={onCancelClick} />
            </GivelifyForm>
        </TrackingProvider>
    );
};

export default AddressScreen;
