import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((_theme: Theme) => ({
    wrapper: {
        flex: 1,
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 48,
        borderBottom: '3px solid #D7EBF4',
        paddingLeft: 24,
        paddingRight: 12,
    },
}));
