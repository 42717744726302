import React from 'react';
import { Paper, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
interface Props {
    variant?: 'bordered';
    maxWidth?: string;
}

const useStyle = makeStyles<Theme>({
    paper: (props: Props) => ({
        borderRadius: 10,
        border: props.variant === 'bordered' ? '1px solid #CACACA' : 'none',
        maxWidth: props.maxWidth || 'max-content',
    }),
});

const ContentCard: React.FC<Props> = props => {
    useTheme();
    const { paper } = useStyle(props);
    return (
        <Paper className={paper} elevation={props.variant ? 0 : 1}>
            {props.children}
        </Paper>
    );
};

export default ContentCard;
