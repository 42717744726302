import React from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { GivelifyLoading } from '@givelify/ui';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import CaptivePortal from '../pages/captivePortal';
import Home from '../pages/overview';
import { OverviewPage } from '../pages/overview/OverviewPage';

const OverviewRoutes = () => {
    const { featureEnabled } = useSelector((state: AppState) => ({
        featureEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.OVERVIEW_NEW_EXPERIENCE,
            false,
        ),
    }));

    const { showCaptivePortal, showLoader } = useCaptivePortalContext();

    if (showLoader) {
        return <GivelifyLoading type="linear" />;
    }

    if (showCaptivePortal) return <CaptivePortal />;

    return featureEnabled ? <OverviewPage /> : <Home />;
};

export default OverviewRoutes;
