import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgOrganization = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={72}
        width={68}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 68 72"
    >
        <path d="M33.8099 72H34V16L16.4848 0L0 16V72H33.8099Z" fill="#DBD6FF" />
        <path d="M68 16L50.32 0H16.5001L33.6802 16H68Z" fill="#715AFF" />
        <path d="M68 16H34V72H68V16Z" fill="#BEB3FF" />
        <path d="M48 25H40V40H48V25Z" fill="#715AFF" />
        <path d="M62 25H54V40H62V25Z" fill="#715AFF" />
        <path d="M22 54H12V72H22V54Z" fill="#715AFF" />
        <path
            d="M16.5 37C16.5 37 17.3407 37 20.7901 32.9581C24.2394 28.9162 23.1873 25.7572 20.7901 25.0962C18.4409 24.4528 16.5 27.248 16.5 27.248C16.5 27.248 14.5591 24.4528 12.2099 25.0962C9.8127 25.7528 8.7606 28.9118 12.2099 32.9581C15.6593 37 16.5 37 16.5 37Z"
            fill="#715AFF"
        />
    </svg>
);

export const OrganizationIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgOrganization} fontSize={fontSize} />
);
