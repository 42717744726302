import { GivelifyPaper, GivelifyTab } from '@givelify/ui';
import { styled } from '@mui/material';

export const DonationHeaderWrapper = styled('div')(({ theme }) => ({
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '24px',
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const DonationPaper = styled(GivelifyPaper)(({ theme }) => ({
    padding: '32px',
    [theme.breakpoints.down('mobile')]: {
        padding: '16px',
    },
}));

export const DonationsPageTab = styled(GivelifyTab)({
    width: '100%',
});

export const DonationAmount = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
    },
}));

export const DonationTitleWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
    },
}));
