import { toISODate } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';

export function clearQueryFromUrl() {
    const newUrl = `${window.location.origin}${window.location.pathname}`;
    window.history.replaceState({}, null, newUrl);
}

export function removeDoneeQueryFromUrl(searchParams: URLSearchParams) {
    searchParams.delete('donee');
    const newSearch = searchParams.toString();
    const newUrl = `${window.location.origin}${window.location.pathname}${
        newSearch ? `?${newSearch}` : ''
    }`;
    window.history.replaceState({}, null, newUrl);
}

export function addDoneeQueryInUrl(
    doneeId: number,
    searchParams?: URLSearchParams,
) {
    if (searchParams) {
        searchParams.delete('donee');
    } else {
        searchParams = new URLSearchParams();
    }
    searchParams.append('donee', doneeId.toString());
    const newUrl = `${window.location.origin}${
        window.location.pathname
    }?${searchParams.toString()}`;
    window.history.replaceState({}, null, newUrl);
}

export function addTimeFrameQueryInUrl(
    searchParams: URLSearchParams,
    timeFrame: TimeFrameValue,
) {
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    searchParams.append('startDate', toISODate(timeFrame.start));
    searchParams.append('endDate', toISODate(timeFrame.end));
    const newUrl = `${window.location.origin}${
        window.location.pathname
    }?${searchParams.toString()}`;
    window.history.replaceState({}, null, newUrl);
}

export function removeTimeFrameQueryFromUrl(searchParams: URLSearchParams) {
    searchParams.delete('startDate');
    searchParams.delete('endDate');

    let searchParamsObject;
    try {
        searchParamsObject = Object.fromEntries(searchParams);
    } catch {
        searchParamsObject = {};
    }

    let newUrl = '';
    if (Object.keys(searchParamsObject)?.length > 0) {
        newUrl = `${window.location.origin}${
            window.location.pathname
        }?${searchParams.toString()}`;
    } else {
        newUrl = `${window.location.origin}${window.location.pathname}`;
    }

    window.history.replaceState({}, null, newUrl);
}
