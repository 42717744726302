import { makeStyles } from '@material-ui/core';

const blueColor = '#cce2ef';
export default makeStyles(() => ({
    expansionPanel: {
        borderBottom: '2px solid ' + blueColor,
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
        },
        '&::before': {
            height: 0,
        },
    },
    expandIcon: {
        marginRight: 12,
    },
    expansionTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    accordionSummaryRoot: {
        minHeight: '48px !important',
        paddingRight: 0,
    },
    accordionSummaryContent: {
        margin: '12px 0 !important',
        paddingLeft: 16,
    },
    accordionDetailsRoot: {
        padding: 0,
    },
}));
