import { Stack, styled } from '@mui/material';

export const EmptyBox = styled(Stack)({
    margin: '8px auto 0',
    padding: '0 64px',
});

export const TextWrapper = styled('div')({
    textAlign: 'center',
});
