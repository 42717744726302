import React, { ReactNode } from 'react';
import { GivelifyLabelStyles } from '@givelify/givelify-ui';
import { mobileStyleMap, moreLabelStyles } from './style';
import { ColorVariant, LabelVariant } from './types';

interface LabelProps extends React.HTMLAttributes<HTMLSpanElement> {
    variant?: LabelVariant;
    children: ReactNode;
    color?: ColorVariant;
    isBold?: boolean;
    isLink?: boolean;
    isInline?: boolean;
}

// label styles that don't have the 'primary-dark-grey' color
const nonDefaultColorStyles: LabelVariant[] = [
    'body3',
    'caption1',
    'smallText',
];

export default function Label({
    variant = 'custom',
    children,
    color = 'primary-dark-grey',
    isBold = false,
    isLink = false,
    isInline = false,
    className,
    ...restProps
}: LabelProps) {
    const { bold, ...styles } = GivelifyLabelStyles({});
    const { link, inline } = moreLabelStyles();
    const variantStyle = styles[variant as keyof typeof styles];
    const mobileStyle =
        styles[
            mobileStyleMap[
                variant as keyof typeof mobileStyleMap
            ] as keyof typeof styles
        ];

    const isOverridingDefaultColor =
        color === 'primary-dark-grey' &&
        nonDefaultColorStyles.includes(variant);

    const classes = `${className || ''} ${variantStyle} ${mobileStyle} ${
        !isOverridingDefaultColor ? styles[color as keyof typeof styles] : ''
    } ${isBold ? bold : ''} ${isLink ? link : ''} ${isInline ? inline : ''}`;

    return (
        <div {...restProps} className={classes}>
            {children}
        </div>
    );
}
