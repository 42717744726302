import { DonationRow } from 'api/models';
import { DonationItemRowProps } from '../DonationActivity/DonationActivityInfiniteLoader/DonationActivityRow';

const messagesConversion = (
    note: DonationRow['donationNote'] | undefined,
    donor: DonationRow['donor'],
    doneeName: string,
    doneeAvatar: string,
): DonationItemRowProps['messages'] => {
    if (!note) return [];
    const messages: DonationItemRowProps['messages'] = [];
    if (note.memo && note.memo.length > 0) {
        messages.push({
            name: donor.name,
            message: note.memo,
            avatarSrc: donor.picture,
        });
    }
    if (note.reply?.text?.length) {
        messages.push({
            name: note.reply.userName || doneeName,
            message: note.reply.text,
            avatarSrc: note.reply.userPicture || doneeAvatar,
        });
    }
    return messages;
};

export const mapDonationRowToDonationItemRowProps = (
    r: DonationRow,
    doneeAvatar?: string,
): DonationItemRowProps | undefined => {
    // API is sending inconsistent data, which will exclude some of the donation rows
    // TODO: Try to figure out the inconsistency in the data set.
    try {
        const result: DonationItemRowProps = {
            id: r.id,
            name: r.donor.name,
            isRefund: r.isRefund,
            refundDate: r.refundDate,
            status: r.status,
            latestStatusAt: r.latestStatusAt,
            receiptNumber: r.receiptNumber,
            location: `${r.donor.city}, ${r.donor.state}`,
            date: r.createdAt,
            donation: r.donationTotal,
            doneeName: r.doneeName,
            donorAvatar: r.donor.picture,
            envelopes: r.envelopes,
            fees: r.fees,
            donorId: r.donor.id,
            doneeAvatar,
            donationNoteId: r.donationNote && r.donationNote.id,
            messages: messagesConversion(
                r.donationNote,
                r.donor,
                r.doneeName,
                doneeAvatar,
            ),
            donationFees: r.donationFees,
        };
        return result;
    } catch {
        return undefined;
    }
};
