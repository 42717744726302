import { useCallback } from 'react';
import { ApiResponse } from '../responses';
import {
    requestStart,
    requestSuccess,
    requestError,
    useRequestState,
    RequestState,
} from './useRequestState';

export const useApiRequest = <T>() => {
    const [requestState, setRequestState] = useRequestState<T>();
    const makeRequest = useCallback(
        async (request: Promise<ApiResponse<T>>): Promise<RequestState<T>> => {
            setRequestState(requestStart());
            const response = await request;
            if (response.success) {
                const result = requestSuccess<T>(response.response);
                setRequestState(result);
                return result;
            } else if (response.error?.status === 499) {
                const result = requestStart();
                setRequestState(result);
                return result;
            } else {
                const result = requestError(response.error);
                setRequestState(result);
                return result;
            }
        },
        [setRequestState],
    );
    return [requestState, makeRequest, setRequestState] as const;
};
