import React, { useEffect, useState, useMemo } from 'react';
import { dataOrUndefined } from '@givelify/givelify-ui';
import { GivelifyPoll } from '@givelify/ui';
import { TimeFrameValue, TimeFrameValues, isValidRange } from '@givelify/utils';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    addTimeFrameQueryInUrl,
    removeTimeFrameQueryFromUrl,
} from 'router/queryUtil';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { splitDateRange } from 'utils/timeFrameUtils';
import Space from '../../components/Space';
import { useDoneeStartDate } from '../../hooks/useDoneeStartDate';
import { AppState } from '../../store';
import { getDonationsCurrentTotal } from '../../store/dashboard/donations/thunks';
import { getDonorsCurrentTotal } from '../../store/dashboard/donors/thunks';
import {
    getEnvelopesCurrent,
    getGivingTrendEnvelopes,
} from '../../store/dashboard/envelopes/thunks';
import { getLifeTimeTotal } from '../../store/dashboard/lifeTime/thunks';
import CardsGrid from './CardsGrid';
import { useCfroContext } from './Cfro/context';
import EditUserContactInfoModal from './Cfro/UserContactInfoModal';
import PieChartsSectionGrid from './PieCharts';
import StackedBarChart from './StackedBarChart';
import useStyles from './styles';
import TopDashboardGrid from './TopDashboardGrid';

const Dashboard: React.FC = () => {
    const POLL = 'quick_poll_answered';
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            save: t('labels.save'),
        }),
        [t],
    );
    const dispatch = useDispatch();
    const { grid, cardsWrapper, pieChartsWrapper } = useStyles();
    const searchParams = new URLSearchParams(window.location.search);
    const thisYearTimeFrameSelect = TimeFrameValues.thisYear;
    const [timeFrame, setTimeFrame] = useState<TimeFrameValue>(
        thisYearTimeFrameSelect,
    );
    const [givingTrendTimeFrames, setGivingTrendTimeFrames] = useState<
        [Dayjs, Dayjs][]
    >([]);
    const [isPollAnswered, setIsPollAnswered] = useState<boolean>(true);

    const { cfroModalOpen } = useCfroContext();

    const doneeStartDate = useDoneeStartDate();
    const { donee, envelopes, quickPollEnabled } = useSelector(
        (state: AppState) => ({
            donee: state.Donee.donee,
            envelopes: state.Dashboard.Envelopes.current,
            quickPollEnabled: isFeatureEnabled(
                state.System.enabledFeatures,
                Features.OVERVIEW_QUICK_POLL,
                false,
            ),
        }),
    );
    const doneeId = donee.id;
    const [currentDoneeId, setCurrentDoneeId] = React.useState(doneeId);

    const [showPoll, setShowPoll] = useState(true);

    const handleTimeFrameChange = (timeFrame: TimeFrameValue) => {
        if (timeFrame.selector === 'lifetime') {
            timeFrame.start = dayjs.tz(doneeStartDate);
        }
        setTimeFrame(timeFrame);
        addTimeFrameQueryInUrl(searchParams, timeFrame);
    };
    /**
     * Convert the timeframe selection to an array of start,end date tuples for the API
     */
    useEffect(() => {
        setGivingTrendTimeFrames(splitDateRange(timeFrame));
    }, [timeFrame]);

    useEffect(() => {
        getDonationsCurrentTotal(timeFrame, doneeId)(dispatch);
        getDonorsCurrentTotal(timeFrame, doneeId)(dispatch);
        getEnvelopesCurrent(timeFrame, doneeId)(dispatch);
    }, [timeFrame, dispatch, doneeId]);

    const envelopeIds = useMemo(() => {
        const data = dataOrUndefined(envelopes);
        const envelopeIds = data ? data.data.map((e) => e.id) : [];
        return envelopeIds;
    }, [envelopes]);

    useEffect(() => {
        if (envelopeIds.length === 0) {
            // if there are no specific envelopIds to load for the trend, then we shouldn't make the call.
            return;
        }
        getGivingTrendEnvelopes(
            givingTrendTimeFrames,
            envelopeIds,
            doneeId,
        )(dispatch);
    }, [envelopeIds, givingTrendTimeFrames, dispatch, doneeId]);

    useEffect(() => {
        getLifeTimeTotal(doneeId)(dispatch);
    }, [dispatch, doneeId]);

    useEffect(() => {
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        if (isValidRange(startDate, endDate)) {
            setTimeFrame({
                start: dayjs.tz(startDate),
                end: dayjs.tz(endDate),
                selector: 'custom',
            });
        } else {
            removeTimeFrameQueryFromUrl(searchParams);
        }

        // localStorage will be replaced with API calls later
        setIsPollAnswered(!!localStorage.getItem(POLL));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (doneeId !== currentDoneeId) {
            setCurrentDoneeId(doneeId);
            removeTimeFrameQueryFromUrl(searchParams);
            setTimeFrame(thisYearTimeFrameSelect);
        }
        //eslint-disable-next-line
    }, [doneeId]);

    const pollAnswer = (answerId: number) => {
        localStorage.setItem(POLL, answerId.toString());
    };

    const closePoll = () => {
        setShowPoll(false);
    };

    return (
        <>
            {cfroModalOpen && (
                <EditUserContactInfoModal ctaSecondActionText={copy.save} />
            )}
            <TopDashboardGrid
                setTimeFrame={handleTimeFrameChange}
                timeFrame={timeFrame}
            />
            <Space gap={3} />
            <div className={grid}>
                <div className={cardsWrapper}>
                    <CardsGrid />
                </div>
                {showPoll && !isPollAnswered && quickPollEnabled ? (
                    <div>
                        {/* We don't use proper translations here as this is only for MVP, we'll replace hardcoded querstions with API calls later */}
                        <GivelifyPoll
                            onAnswer={pollAnswer}
                            onClose={closePoll}
                            poll={{
                                question:
                                    'What Givelify feature is the most powerful for your organization?',
                                userAnswer: null,
                                answers: [
                                    'Envelope Manager',
                                    'Giving Tools',
                                    'Donor Filters',
                                    'Reports',
                                ],
                            }}
                            text={{
                                title: 'Quick Poll',
                                thankYouMessage: 'Thanks for participating!',
                                closePoll: 'Dismiss',
                            }}
                        />
                    </div>
                ) : null}
                <div className={pieChartsWrapper}>
                    <PieChartsSectionGrid />
                </div>
                <StackedBarChart
                    dates={givingTrendTimeFrames}
                    selectedTimeFrame={timeFrame}
                />
            </div>
        </>
    );
};

export default Dashboard;
