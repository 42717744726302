import React from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DonorsSvg from '../../../../assets/illustration/donors.svg';

const EmptyDonorsBox = styled(Stack)({
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '8px',
});

export type EmptyDonorsProps = {
    href?: string;
    onLearnMoreClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const EmptyDonorsComponent: React.FC<EmptyDonorsProps> = ({
    href,
    onLearnMoreClick,
}) => {
    const { t } = useTranslation();
    return (
        <EmptyDonorsBox alignItems="center" data-testid="empty-donors" gap={3}>
            <img alt="Donors" data-testid="donors-icon" src={DonorsSvg} />
            <GivelifyLabel text={t('donors.empty.title')} variant="heading1S" />
            <GivelifyLabel text={t('donors.empty.description')} />
            <GivelifyButton
                data-testid="learn-more-button"
                href={href}
                onClick={onLearnMoreClick}
                text={t('donors.empty.button')}
                variant="primary"
            />
        </EmptyDonorsBox>
    );
};

export const EmptyDonors = React.memo(EmptyDonorsComponent);
