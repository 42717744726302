import * as React from 'react';
import { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { settingsStyle } from '../style';

interface SaveCancelButtonsProps {
    onCancel: () => void;
    onSave: () => void;
    saving?: boolean;
    submitDisabled?: boolean;
    cancelDisabled?: boolean;
}

const SaveCancelButtons: React.FunctionComponent<SaveCancelButtonsProps> = (
    props,
) => {
    const { submitButtonsHolderSingle } = settingsStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    return (
        <div className={submitButtonsHolderSingle}>
            <GivelifyButton
                data-testid="cancel-button"
                disabled={props.cancelDisabled}
                marginRight={12}
                name="cancel"
                onClick={props.onCancel}
                text={copy.cancel}
                variant="secondary"
            />
            <GivelifyButton
                data-testid="save-button"
                disabled={props.submitDisabled}
                isLoading={props.saving}
                marginLeft={12}
                name="save"
                onClick={props.onSave}
                text={copy.save}
                variant="primary"
                
            />
        </div>
    );
};

export default SaveCancelButtons;
