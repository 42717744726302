import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { MOCKUP_PATH } from './paths';
import { quickGivePreviewStyle } from './quickGivePreviewStyle';

export interface QuickGiveMobilePreviewProps {
    options: string[];
    contentTitle: string;
    imageUrl?: string;
    doneeName: string;
}

const QuickGiveMobilePreview: React.FC<QuickGiveMobilePreviewProps> = (
    props,
) => {
    const {
        mobilePreviewBase,
        mobilePreviewContent,
        mobilePreviewStatusBar,
        mobilePreviewTitleBar,
        mobilePreviewBackArrow,
        mobilePreviewPaginationDots,
        mobilePreviewCover,
        mobilePreviewContentTitle,
        mobilePreviewQuickGiveOption,
        mobilePreviewQuickGiveOptionText,
        mobilePreviewQuickGiveOptionsHolder,
    } = quickGivePreviewStyle();
    return (
        <div className={mobilePreviewBase}>
            <div className={mobilePreviewContent}>
                <div className={mobilePreviewStatusBar}></div>
                <div className={mobilePreviewTitleBar}>
                    <img
                        alt="back"
                        className={mobilePreviewBackArrow}
                        src={MOCKUP_PATH.ICONS.BACK_ARROW}
                        title="Back arrow"
                    />
                    <GivelifyLabel text={props.doneeName} variant="h2Mobile" />
                </div>
                <div className={mobilePreviewCover}>
                    {props.imageUrl && (
                        <img
                            alt="organization cover"
                            className={mobilePreviewCover}
                            src={props.imageUrl}
                            title="Organisation cover picture"
                        />
                    )}
                </div>
                <div className={mobilePreviewPaginationDots}>
                    <img
                        alt="pagination dots"
                        src={MOCKUP_PATH.PAGINATION_DOTS}
                        title="Pagination dots"
                    />
                </div>
                <GivelifyLabel
                    className={mobilePreviewContentTitle}
                    text={props.contentTitle}
                    variant="caption2"
                />
                <Grid
                    container
                    className={mobilePreviewQuickGiveOptionsHolder}
                    justifyContent="center"
                >
                    {props.options.map((value) => (
                        <Grid
                            key={'prev-items-' + value}
                            item
                            className={mobilePreviewQuickGiveOption}
                        >
                            <GivelifyLabel
                                className={mobilePreviewQuickGiveOptionText}
                                text={value}
                                variant="body1"
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default QuickGiveMobilePreview;
