/* eslint-disable-next-line */
import React, { useContext, useMemo } from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { GivelifyLabel, GivelifyLink, GivelifyButton } from '@givelify/ui';
import { toISODate, useApiRequest } from '@givelify/utils';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { GenerateReportResponse } from 'api/services/responses/reports';
import { getReportsService } from 'api/utils/serviceProvider';
import { TimeFrameFilter, useNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'router/routes';
import { webConfig } from 'webConfig';
import { DonorProfileContext } from '../../DonorProfileContext';
import { DonationStat } from './DonationStat';
import { DonorDonationHistoryTable } from './DonorDonationHistoryTable';

const Loading: JSX.Element = (
    <FacebookLoading
        borderRadius={0}
        height="50vh"
        marginTop={4}
        width="100%"
    />
);

const useStyle = makeStyles((theme: Theme) => {
    const spacing1 = theme.spacing(1);
    const spacing3 = theme.spacing(3);
    return {
        wrapper: {
            flex: 1,
            padding: theme.spacing(3, 5),
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                padding: spacing1,
            },
        },
        inputWrapper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        historyTitle: {
            fontSize: '16px',
            fontWeight: 'bold',
            paddingTop: spacing3,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        statSection: {
            display: 'flex',
            justifyContent: 'space-around',
            paddingTop: spacing3,
            paddingBottom: spacing3,
            [theme.breakpoints.down('xs')]: {
                paddingTop: spacing1,
                paddingBottom: spacing1,
            },
        },
        borderOne: {
            borderBottom: 0,
            borderColor: '#d8d8d8',
        },
    };
});

const baseTranslation = 'pages.donor_details';

export const DonorDonationHistory: React.FC = () => {
    const classes = useStyle({});
    const {
        onTimeFrameChange,
        donationsMeta: { sum, count, average },
        donations,
        pageChange,
        donationPagination: { perPage, total, currentPage },
        isLoadingDonationsPage,
        timeFrame,
        donor,
        doneeId,
    } = useContext(DonorProfileContext);
    const { t } = useTranslation();
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const copy = useMemo(
        () => ({
            totalDonated: t(`${baseTranslation}.total_donated`),
            donations: t(`${baseTranslation}.donations`),
            averageDonation: t(`${baseTranslation}.average_donation`),
            donationHistory: t(`${baseTranslation}.donation_history`),
            generateReportMessage1: t(
                `${baseTranslation}.generateReportMessage1`,
            ),
            generateReportMessage2: t(
                `${baseTranslation}.generateReportMessage2`,
            ),
            generateReportMessageError1: t(
                `${baseTranslation}.generateReportMessageError1`,
            ),
            generateReportMessageError2: t(
                `${baseTranslation}.generateReportMessageError2`,
            ),
            generateReportMessageError3: t(
                `${baseTranslation}.generateReportMessageError3`,
            ),
            generateReportButton: t(`${baseTranslation}.generateReportButton`),
        }),
        [t],
    );
    const [postReportsRequest, makePostReportsRequest] =
        useApiRequest<GenerateReportResponse>();
    const reportsService = getReportsService();

    const navigateToHistory = React.useCallback(
        (
            event: React.MouseEvent<HTMLAnchorElement> &
                React.MouseEvent<HTMLSpanElement>,
        ) => {
            event.preventDefault();
            navigate(PATH.REPORTS.REPORT_HISTORY);
            showNotification(null);
        },
        [navigate, showNotification],
    );

    const showSuccessNotification = React.useCallback(() => {
        showNotification({
            text: (
                <GivelifyLabel variant="body1B">
                    {`${copy.generateReportMessage1} `}
                    <GivelifyLink
                        href={PATH.REPORTS.REPORT_HISTORY}
                        onClick={navigateToHistory}
                        text={copy.generateReportMessage2}
                        variant="body1B"
                    />
                </GivelifyLabel>
            ),
            variant: 'info',
        });
    }, [
        showNotification,
        copy.generateReportMessage1,
        copy.generateReportMessage2,
        navigateToHistory,
    ]);

    const showErrorNotification = React.useCallback(() => {
        showNotification({
            text: (
                <GivelifyLabel variant="body1B">
                    {`${copy.generateReportMessageError1} `}
                    <GivelifyLink
                        href={webConfig.supportUrl}
                        target="_blank"
                        text={copy.generateReportMessageError2}
                        variant="body1B"
                    />
                    {` ${copy.generateReportMessageError3} `}
                </GivelifyLabel>
            ),
            variant: 'error',
        });
    }, [
        showNotification,
        copy.generateReportMessageError1,
        copy.generateReportMessageError2,
        copy.generateReportMessageError3,
    ]);

    const exportDonationData = React.useCallback(() => {
        void makePostReportsRequest(
            reportsService.generateDonorLifetimeReport({
                doneeId: doneeId,
                donorId: donor.id,
                startDate: toISODate(timeFrame.start),
                endDate: toISODate(timeFrame.end),
            }),
        );
    }, [doneeId, donor.id, makePostReportsRequest, reportsService, timeFrame]);

    React.useEffect(() => {
        if (postReportsRequest.type === 'REQUEST_SUCCESS') {
            showSuccessNotification();
        } else if (postReportsRequest.type === 'REQUEST_ERROR') {
            showErrorNotification();
        }
    }, [postReportsRequest, showErrorNotification, showSuccessNotification]);

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.inputWrapper}>
                <TimeFrameFilter
                    onChange={onTimeFrameChange}
                    value={timeFrame}
                />
                <GivelifyButton
                    isLoading={postReportsRequest.type === 'REQUEST_START'}
                    onClick={exportDonationData}
                    size="large"
                    text={copy.generateReportButton}
                />
            </Box>
            <hr className={classes.borderOne} />
            <div className={classes.statSection}>
                <DonationStat
                    hasLoadedDonor={isLoadingDonationsPage === false}
                    id="total-donated"
                    isMoney={true}
                    label={copy.totalDonated}
                    value={sum}
                />
                <DonationStat
                    hasLoadedDonor={isLoadingDonationsPage === false}
                    label={copy.donations}
                    value={count}
                />
                <DonationStat
                    hasLoadedDonor={isLoadingDonationsPage === false}
                    isMoney={true}
                    label={copy.averageDonation}
                    value={average}
                />
            </div>
            <hr className={classes.borderOne} />
            <div className={classes.historyTitle}>{copy.donationHistory}</div>
            {isLoadingDonationsPage ? (
                Loading
            ) : (
                <DonorDonationHistoryTable
                    currentPage={currentPage > 0 ? currentPage - 1 : 0}
                    donations={donations}
                    onChangePage={pageChange}
                    rowsPerPage={Number(perPage)}
                    totalItems={total}
                />
            )}
        </Box>
    );
};
