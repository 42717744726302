import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyTextFieldStyleProps = {
    state: 'normal' | 'error' | 'warning' | 'success';
    variant: 'default' | 'alt' | 'message' | 'search';
    type: 'text' | 'password';
    width: number | 'auto' | '100%';
    focused?: boolean;
    hovered?: boolean;
    prefixLength?: number;
    suffixLength?: number;
    multiline?: boolean;
    rows?: number;
} & GivelifyBoxMarginProps;

export const GivelifyTextFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyTextFieldStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyTextFieldStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyTextFieldStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyTextFieldStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        tfRoot: {
            boxSizing: 'border-box',
            position: 'relative',
            width: (props: GivelifyTextFieldStyleProps) =>
                props.width === 'auto' ? 'auto' : props.width,
        },
        tfWrapper: {
            boxSizing: 'border-box',
            position: 'relative',
        },
        '@keyframes showTopLabel': {
            '0%': { opacity: 0 },
            '99%': { opacity: 1 },
            '100%': { opacity: 1 },
        },
        '@keyframes hideTopLabel': {
            '0%': { opacity: 1 },
            '99%': { opacity: 1 },
            '100%': { opacity: 0 },
        },
        '@keyframes hideTopBorder': {
            from: {
                'border-top-width': '2px',
            },
            to: {
                'border-top-width': 0,
            },
        },
        tfTopBorder: {
            animationName: '$hideTopBorder',
            animationDuration: '30ms',
            animationIterationCount: 1,
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
        },
        tfLabel: {
            fontFamily: theme.gas.font,
            fontSize: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? 12 : 16,
            lineHeight: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? '14px' : '20px',
            fontWeight: 800,
            letterSpacing: 0.15,
            color: theme.gas.palette.label.placeholder,
            position: 'absolute',
            top: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? 0 : props.multiline ? 18.5 : '50%',
            left: 0,
            paddingLeft: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'search'
                    ? 48
                    : props.prefixLength
                    ? 9 * props.prefixLength + 26
                    : 12,
            transform: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? `translate(0, 0%)` : `translate(0, -50%)`,
            transition: 'top 10ms, left 10ms',
            pointerEvents: 'none',
        },
        tfLabelHide: {
            animationName: '$hideTopLabel',
            animationDuration: '10ms',
            animationIterationCount: 1,
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
        },
        tfLabelFocusedRoot: {
            display: 'flex',
            'flex-direction': 'row',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            '&::before': {
                'border-radius': '4px 0 0 0',
                display: 'block',
                content: '""',
                height: '4px',
                borderTopStyle: 'solid',
                borderTopWidth: (props: GivelifyTextFieldStyleProps) =>
                    props.focused || props.hovered ? 2 : 1,
                borderTopColor: (props: GivelifyTextFieldStyleProps) =>
                    props.state === 'error'
                        ? theme.gas.palette.state.error.text
                        : props.state === 'warning'
                        ? theme.gas.palette.state.warning.border
                        : props.state === 'success'
                            ? theme.gas.palette.state.success.text
                            : props.focused
                                ? props.variant === 'alt'
                                    ? theme.gas.palette.input.focusedAlt.border
                                    : theme.gas.palette.input.focused.border
                                : theme.gas.palette.input.idle.border,
                width: '8px',
            },
            '&::after': {
                'border-radius': '0 4px 0 0',
                display: 'block',
                content: '""',
                flex: '1 0 0',
                height: '4px',
                borderTopStyle: 'solid',
                borderTopWidth: (props: GivelifyTextFieldStyleProps) =>
                    props.focused || props.hovered ? 2 : 1,
                borderTopColor: (props: GivelifyTextFieldStyleProps) =>
                    props.state === 'error'
                        ? theme.gas.palette.state.error.text
                        : props.state === 'warning'
                        ? theme.gas.palette.state.warning.border
                        : props.state === 'success'
                            ? theme.gas.palette.state.success.text
                            : props.focused
                                ? props.variant === 'alt'
                                    ? theme.gas.palette.input.focusedAlt.border
                                    : theme.gas.palette.input.focused.border
                                : theme.gas.palette.input.idle.border,
            },
            animationName: '$showTopLabel',
            animationDuration: '10ms',
            animationIterationCount: 1,
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
        },
        tfLabelFocused: {
            fontFamily: theme.gas.font,
            fontSize: 12,
            lineHeight: '16px',
            fontWeight: 600,
            color: (props: GivelifyTextFieldStyleProps) =>
                props.focused || props.state !== 'normal'
                    ? props.state === 'error'
                    ? theme.gas.palette.state.error.text
                    : props.state === 'warning'
                        ? theme.gas.palette.state.warning.border
                        : props.state === 'success'
                            ? theme.gas.palette.state.success.text
                            : props.variant === 'alt'
                                ? theme.gas.palette.input.focusedAlt.border
                                : theme.gas.palette.input.focused.border
                    : theme.gas.palette.label.secondary,
            overflow: 'hidden',
            transform: `translate(0, -50%)`,
            paddingLeft: 4,
            paddingRight: 4,
            width: 'auto',
        },
        tfInput: {
            boxSizing: 'border-box',
            width: '100%',
            backgroundColor: theme.gas.palette.input.idle.background,
            color: theme.gas.palette.input.idle.text,
            letterSpacing: 0.15,
            textTransform: 'none',
            textIndent: 0,
            textShadow: 'none',
            appearance: 'textfield',
            '-webkit-rtl-ordering': 'logical',
            cursor: 'text',
            margin: 0,
            fontFamily: theme.gas.font,
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 800,
            padding: 0,
            paddingLeft: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'search'
                    ? 48
                    : props.prefixLength
                    ? 9 * props.prefixLength +
                    26 +
                    (props.variant === 'message' ? 12 : 0)
                    : 12 + (props.variant === 'message' ? 12 : 0),
            paddingRight: (props: GivelifyTextFieldStyleProps) =>
                props.suffixLength ? 9 * props.suffixLength + 22 : 12,
            borderWidth: 0,
            borderStyle: 'initial',
            borderColor: 'initial',
            borderImage: 'initial',
            '&::-webkit-input-placeholder': {
                /* WebKit browsers */
                color: theme.gas.palette.label.placeholder,
            },
            '&:-moz-placeholder': {
                /* Mozilla Firefox 4 to 18 */
                color: theme.gas.palette.label.placeholder,
            },
            '&::-moz-placeholder': {
                /* Mozilla Firefox 19+ */
                color: theme.gas.palette.label.placeholder,
            },
            '&:-ms-input-placeholder': {
                /* Internet Explorer 10+ */
                color: theme.gas.palette.label.placeholder,
            },
            '&:disabled::-webkit-input-placeholder': {
                /* WebKit browsers */
                color: theme.gas.palette.label.placeholder,
            },
            '&:disabled:-moz-placeholder': {
                /* Mozilla Firefox 4 to 18 */
                color: theme.gas.palette.label.placeholder,
            },
            '&:disabled::-moz-placeholder': {
                /* Mozilla Firefox 19+ */
                color: theme.gas.palette.label.placeholder,
            },
            '&:disabled:-ms-input-placeholder': {
                /* Internet Explorer 10+ */
                color: theme.gas.palette.label.placeholder,
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::input[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '&::-ms-reveal': {
                '-webkit-appearance': 'none',
                display: 'none',
                margin: 0,
            },
            '&::-ms-clear': {
                '-webkit-appearance': 'none',
                display: 'none',
                margin: 0,
            },
        },
        tfInputMessage: {
            display: 'block',
            minHeight: 48,
            fontSize: 14,
            lineHeight: '24px',
            fontWeight: 600,
            paddingLeft: 24,
            paddingRight: 14,
            paddingTop: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 14 : 0,
            paddingBottom: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 14 : 0,
            borderRadius: (props: GivelifyTextFieldStyleProps) =>
                props.rows ? (props.rows > 1 ? 20 : 24) : 24,
        },
        tfStartPadding: {
            paddingLeft: '47px !important',
        },
        tfEndPadding: {
            paddingRight: 47,
        },
        tfPrefix: {
            fontFamily: theme.gas.font,
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 800,
            color: theme.gas.palette.label.primary,
            position: 'absolute',
            top: '50%',
            left: 14,
            transform: `translate(0, -50%)`,
            transition: 'top 30ms, left 30ms',
            pointerEvents: 'none',
        },
        tfSuffix: {
            fontFamily: theme.gas.font,
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 800,
            color: theme.gas.palette.label.primary,
            position: 'absolute',
            top: '50%',
            right: 10,
            transform: `translate(0, -50%)`,
            transition: 'top 30ms, left 30ms',
            pointerEvents: 'none',
        },
        tfLarge: {
            minHeight: 56,
            paddingTop: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 18 : 0,
            paddingBottom: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 18 : 0,
            borderRadius: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'search' ? 28 : 4,
        },
        tfMedium: {
            minHeight: 48,
            paddingTop: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 14 : 0,
            paddingBottom: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 14 : 0,
            borderRadius: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'search' ? 24 : 4,
        },
        tfDense: {
            minHeight: 40,
            paddingTop: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 10 : 0,
            paddingBottom: (props: GivelifyTextFieldStyleProps) =>
                props.multiline ? 10 : 0,
            borderRadius: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'search' ? 20 : 4,
        },
        tfBorderNormal: {
            borderWidth: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? 2 : 1,
            borderStyle: 'solid',
            borderColor: (props: GivelifyTextFieldStyleProps) =>
                props.focused
                    ? theme.gas.palette.input.focused.border
                    : theme.gas.palette.input.idle.border,
            outline: 'none',
            '&:hover': {
                borderWidth: 2,
            },
            '&:focus': {
                borderWidth: 2,
                borderColor: (props: GivelifyTextFieldStyleProps) =>
                    props.variant === 'alt'
                        ? theme.gas.palette.input.focusedAlt.border
                        : theme.gas.palette.input.focused.border,
                outline: 'none',
            },
        },
        tfBorderError: {
            borderWidth: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? 2 : 1,
            borderStyle: 'solid',
            borderColor: theme.gas.palette.state.error.text,
            '&:hover': {
                borderWidth: 2,
            },
            '&:focus': {
                borderWidth: 2,
                borderColor: theme.gas.palette.state.error.text,
                outline: 'none',
            },
        },
        tfBorderWarning: {
            borderWidth: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? 2 : 1,
            borderStyle: 'solid',
            borderColor: theme.gas.palette.state.warning.border,
            '&:hover': {
                borderWidth: 2,
            },
            '&:focus': {
                borderWidth: 2,
                borderColor: theme.gas.palette.state.warning.border,
                outline: 'none',
            },
        },
        tfBorderSuccess: {
            borderWidth: (props: GivelifyTextFieldStyleProps) =>
                props.focused ? 2 : 1,
            borderStyle: 'solid',
            borderColor: theme.gas.palette.state.success.text,
            '&:hover': {
                borderWidth: 2,
            },
            '&:focus': {
                borderWidth: 2,
                borderColor: theme.gas.palette.state.success.text,
                outline: 'none',
            },
        },
        tfDisabled: {
            '&:disabled': {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.gas.palette.input.disabled.border,
                color: theme.gas.palette.input.disabled.text,
                backgroundColor: theme.gas.palette.input.disabled.background,
                '&::-webkit-input-placeholder': {
                    /* WebKit browsers */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&:-moz-placeholder': {
                    /* Mozilla Firefox 4 to 18 */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&::-moz-placeholder': {
                    /* Mozilla Firefox 19+ */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&:-ms-input-placeholder': {
                    /* Internet Explorer 10+ */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&:disabled::-webkit-input-placeholder': {
                    /* WebKit browsers */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&:disabled:-moz-placeholder': {
                    /* Mozilla Firefox 4 to 18 */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&:disabled::-moz-placeholder': {
                    /* Mozilla Firefox 19+ */
                    color: theme.gas.palette.input.disabled.text,
                },
                '&:disabled:-ms-input-placeholder': {
                    /* Internet Explorer 10+ */
                    color: theme.gas.palette.input.disabled.text,
                },
            },
        },
        tfHelper: {
            boxSizing: 'border-box',
            display: 'flex',
            marginTop: 3,
            fontFamily: theme.gas.font,
            fontSize: 12,
            lineHeight: '14px',
            fontWeight: 600,
            alignItems: 'center',
            color: (props: GivelifyTextFieldStyleProps) =>
                props.state === 'warning'
                    ? theme.gas.palette.state.warning.text
                    : props.state === 'error'
                    ? theme.gas.palette.state.error.text
                    : props.state === 'success'
                        ? theme.gas.palette.state.success.text
                        : theme.gas.palette.label.secondary,
        },
        tfHelperLeft: {
            paddingLeft: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'default' || props.variant === 'alt' ? 0 : 24,
            marginRight: 'auto',
        },
        tfHelperRight: {
            marginLeft: 'auto',
            marginRight: (props: GivelifyTextFieldStyleProps) =>
                props.variant === 'default' || props.variant === 'alt' ? 0 : 14,
        },
        tfSendButton: {
            position: 'absolute',
            top: '50%',
            transform: `translate(0, -50%)`,
            right: 7,
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tfSearchButton: {
            position: 'absolute',
            top: '50%',
            transform: `translate(0, -50%)`,
            left: 8,
        },
        tfPasswordButton: {
            position: 'absolute',
            top: '50%',
            transform: `translate(0, -50%)`,
            right: 14,
            width: 28,
            height: 28,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tfPasswordIcon: {
            width: 22,
            height: 15,
            color: theme.gas.palette.label.secondary,
        },
        tfStartAdornment: {
            position: 'absolute',
            top: '50%',
            transform: `translate(0, -50%)`,
            left: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tfEndAdornment: {
            position: 'absolute',
            top: '50%',
            transform: `translate(0, -50%)`,
            right: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);
