import { DesignTokens, GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { UseTaxIdContext } from '../../TaxIdContext';
import { useUploadDocumentTranslation } from './useUploadDocumentTranslation';

type SidebarContentProps = {
    exampleImage: string;
    showExampleModal: () => void;
};

const SidebarContent: React.FC<SidebarContentProps> = ({
    exampleImage,
    showExampleModal,
}) => {
    const { selectedDocumentType } = UseTaxIdContext();
    const { text, copy } = useUploadDocumentTranslation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedDocumentType! as
            | 'confirmationLetter'
            | 'bankStatement'
            | '990Form',
    );

    return (
        <>
            <GivelifyLabel text={text?.informationHeader} variant="heading3S" />
            <img
                alt={text?.imageAlt}
                src={exampleImage}
                style={{
                    width: '100%',
                    marginTop: 16,
                    marginBottom: 4,
                }}
            />
            <GivelifyLabel
                align="center"
                color={DesignTokens.color.textSecondary}
                style={{
                    marginBottom: 16,
                }}
                text={copy.locationMayVary}
                variant="caption1"
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <GivelifyButton
                    name="ViewExample"
                    onClick={showExampleModal}
                    text={copy.viewLarger}
                    variant="ghost"
                />
            </div>
        </>
    );
};

export default SidebarContent;
