import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDownArrowCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>down-arrow-circle</title>
        <path
            clipRule="evenodd"
            d="M12 20.375C7.44365 20.375 3.75 16.6813 3.75 12.125C3.75 7.56865 7.44365 3.875 12 3.875C16.5564 3.875 20.25 7.56865 20.25 12.125C20.25 16.6814 16.5563 20.375 12 20.375ZM2.25 12.125C2.25 17.5098 6.61522 21.875 12 21.875C17.3848 21.875 21.75 17.5098 21.75 12.125C21.75 6.74023 17.3848 2.375 12 2.375C6.61523 2.375 2.25 6.74022 2.25 12.125ZM9.53033 10.8447C9.23744 10.5518 8.76256 10.5518 8.46967 10.8447C8.17678 11.1376 8.17678 11.6124 8.46967 11.9053L11.4697 14.9053C11.7626 15.1982 12.2374 15.1982 12.5303 14.9053L15.5303 11.9053C15.8232 11.6124 15.8232 11.1376 15.5303 10.8447C15.2374 10.5518 14.7626 10.5518 14.4697 10.8447L12 13.3143L9.53033 10.8447Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const DownArrowCircleIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDownArrowCircle}
        fontSize={fontSize}
    />
);
