import React, { useState } from 'react';
import {
    Address,
    AddressWithPhone,
    GivelifyButton,
    GivelifyLabel,
    ShowAndEditAddress,
} from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { SaveCancelButtons } from '../../components/SaveCancelButton';
import { I18N_NAMESPACE } from '../../consts';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editorStyles: {
            marginBottom: 23,
            '& .address-wrapper': {
                display: 'flex',
                marginTop: theme.spacing(3),
                alignItems: 'center',
            },
            '& .address-wrapper > div:first-child': {
                flex: 1,
            },
            '& .hide-sm': {
                display: 'initial',
                [theme.breakpoints.down('xs')]: {
                    display: 'none',
                },
            },
            '& .show-sm': {
                display: 'none',
                [theme.breakpoints.down('xs')]: {
                    display: 'initial',
                },
            },
        },
    }),
);

// address must be `AddressWithPhone | Address`
// Since Storybook does not support `as` notation for some weird reason,
// had to use `any` here.
const addPhoneToAddress = (
    address: AddressWithPhone | Address,
    phone: string,
): AddressWithPhone => {
    if ((address as AddressWithPhone).phone !== undefined) {
        return address as AddressWithPhone;
    } else {
        return { ...address, phone };
    }
};

export interface AddressEditorFormRef {
    close: () => void;
}

interface AddressEditorProps {
    address: AddressWithPhone;
    onChange: (address: AddressWithPhone) => unknown;
    isAddressValid: (isValid: boolean) => unknown;
    submitProps?: {
        onSave: () => void;
        isSaving?: boolean;
        disable?: boolean;
    };
}

const AddressEditorComponent = (
    { address, onChange, isAddressValid, submitProps }: AddressEditorProps,
    forwardRef: React.ForwardedRef<AddressEditorFormRef>,
) => {
    const { editorStyles } = useStyles();
    const [initialAddress, setInitialAddress] = useState<
        AddressWithPhone | undefined
    >(undefined);
    const [isEdit, setEdit] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(false);
    const { t } = useTranslation(I18N_NAMESPACE);

    const {
        editText,
        locationTitle,
        locationDisclaimer,
        isPOBoxIsNotAllowedText,
        streetPlaceholder,
        statePlaceholder,
        cityPlaceholder,
        zipPlaceholder,
        phonePlaceholder,
        zipErrorMessage,
        streetErrorMessage,
        cityErrorMessage,
        phoneErrorMessage,
    } = React.useMemo(
        () => ({
            editText: t('labels.edit'),
            heading: t('customizeProfile.heading'),
            descriptionText: t('customizeProfile.description.text'),
            descriptionLink: t('customizeProfile.description.link'),
            locationTitle: t('customizeProfile.locationTitle'),
            locationDisclaimer: t('customizeProfile.locationDisclaimer'),
            streetPlaceholder: t('addressForm.placeholder.street'),
            cityPlaceholder: t('addressForm.placeholder.city'),
            statePlaceholder: t('addressForm.placeholder.state'),
            zipPlaceholder: t('addressForm.placeholder.zip'),
            phonePlaceholder: t('addressForm.placeholder.phone'),
            isPOBoxIsNotAllowedText: t('addressForm.POBoxIsNotAllowed'),
            zipErrorMessage: t('errors.zip'),
            streetErrorMessage: t('errors.street'),
            cityErrorMessage: t('errors.city'),
            phoneErrorMessage: t('errors.phone'),
        }),
        [t],
    );

    React.useEffect(() => {
        setInitialAddress(address);
        //eslint-disable-next-line
    }, []);
    const handleChange = React.useCallback(
        (newAddress: AddressWithPhone | Address) => {
            onChange(addPhoneToAddress(newAddress, address.phone));
        },
        [onChange, address],
    );

    const onEdit = React.useCallback(() => {
        if (isEdit) {
            if (initialAddress) {
                onChange(initialAddress);
            }
            setEdit(false);
        } else {
            setEdit(true);
        }
    }, [isEdit, initialAddress, setEdit, onChange]);
    const addressValidationCallback = React.useCallback(
        (value: boolean) => {
            isAddressValid(value);
            setValid(value);
        },
        [isAddressValid],
    );
    React.useImperativeHandle(forwardRef, () => ({
        close: () => setEdit(false),
    }));
    return (
        <div className={editorStyles}>
            <div>
                <div className="address-wrapper">
                    <GivelifyLabel
                        className="hide-sm"
                        text={locationTitle}
                        variant="heading4"
                    />
                    <GivelifyButton
                        iconVariant="edit"
                        marginLeft="auto"
                        onClick={onEdit}
                        text={editText}
                        variant="icon-text"
                    />
                </div>
                <GivelifyLabel
                    className="show-sm"
                    text={locationTitle}
                    variant="heading4"
                />
                <GivelifyLabel
                    marginBottom={16}
                    text={locationDisclaimer}
                    variant="body2"
                />
            </div>
            <ShowAndEditAddress
                address={address}
                cityErrorMessage={cityErrorMessage}
                cityPlaceholder={cityPlaceholder}
                isEdit={isEdit}
                name="Onboarding Address Editor"
                onChange={handleChange}
                phoneErrorMessage={phoneErrorMessage}
                phonePlaceholder={phonePlaceholder}
                poBoxNotAllowedText={isPOBoxIsNotAllowedText}
                setIsAddressValid={addressValidationCallback}
                statePlaceholder={statePlaceholder}
                streetErrorMessage={streetErrorMessage}
                streetPlaceholder={streetPlaceholder}
                zipErrorMessage={zipErrorMessage}
                zipPlaceholder={zipPlaceholder}
            />
            {submitProps && isEdit ? (
                <SaveCancelButtons
                    onCancel={onEdit}
                    onSave={submitProps.onSave}
                    saving={submitProps.isSaving}
                    submitDisabled={!valid || submitProps.disable}
                />
            ) : null}
        </div>
    );
};

export default React.memo(
    React.forwardRef<AddressEditorFormRef, AddressEditorProps>(
        AddressEditorComponent,
    ),
);
