import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { DonorsTimeFrameData } from 'store/dashboard/donors/types';
import DefaultLoading from '../../components/DefaultLoading';
import View from './view';

const donorsError: DonorsTimeFrameData = {
    data: [],
    meta: {
        totalGiving: 0,
    },
};

const Loading: JSX.Element = (
    <FacebookLoading
            borderRadius={0}
            height={300}
            marginTop={4}
            width="100%"
        />
);

const DonorsStatusDataWrapper: React.FC = () => {
    const { donorsCurrent } = useSelector((state: AppState) => ({
        donorsCurrent: state.Dashboard.Donors.current,
    }));
    return (
        <DefaultLoading
            error={donorsError}
            loading={Loading}
            requestState={donorsCurrent}
        >
            {(e: DonorsTimeFrameData) => <View distribution={e.data} />}
        </DefaultLoading>
    );
};

export default DonorsStatusDataWrapper;
