import toCamelCase from 'camelcase-keys';
import {
    Report,
    ReportHistoryResponse,
    ReportHistory,
} from 'store/report/types';

export const mapReportHistoryResponse = (
    response: ReportHistoryResponse,
): Report[] =>
    response.data.map(report => (toCamelCase(report) as unknown) as Report);

export const mapReportResponse = (response: ReportHistory) =>
    (toCamelCase(response) as unknown) as Report;
