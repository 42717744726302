import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import LoginWrapper from 'layout/LoginWrapper';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { isLoggedIn } from 'store/user/types';
import View from './view';

const Login = () => {
    const [searchParams] = useSearchParams();
    const loggedIn = useSelector((state: AppState) => isLoggedIn(state));

    if (loggedIn) {
        const returnUrl = searchParams.get('returnUrl');
        return (
            <Navigate
                to={
                    returnUrl && !returnUrl.includes('donee')
                        ? returnUrl
                        : PATH.OVERVIEW
                }
            />
        );
    }

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.Login}>
            <LoginWrapper>
                <View />
            </LoginWrapper>
        </TrackingProvider>
    );
};

export default Login;
