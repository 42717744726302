import React, { useContext } from 'react';
import { Envelope, Poll, PollAnswer } from 'api/models';
import { CurrentStep } from './types';

interface GivingTuesdayContextProps {
    isEnabled: boolean;
    currentStep: CurrentStep;

    givingTuesdayPoll: Poll;
    givingTuesdayPollIsLoading: boolean;

    pollAnswer: PollAnswer;
    setPollAnswer: (answer: PollAnswer) => void;
    customPollAnswer: string;
    setCustomPollAnswer: (answer: string) => void;

    allEnvelopes: Envelope[];
    givingTuesdayEnvelopes: Envelope[];
    envelopesLoading: boolean;

    joinGivingTuesdayClick: () => void;
    remindMeLaterClick: () => void;
    noThanksClick: () => void;
    confirmAndOptOutClick: () => Promise<boolean>;
    updateEnvelopeConfirmClick: (envelope: Envelope) => void;
    showCreateEnvelopeFormClick: () => void;
    createNewEnvelopeClick: (name: string) => Promise<any>;
    backClick: () => void;
}

const NOT_INITIALIZED = () => {
    throw new Error('Giving Tuesday Context is not initialized');
};

export const useGivingTueasdayContext = () => useContext(GivingTuesdayContext);

export const GivingTuesdayContext =
    React.createContext<GivingTuesdayContextProps>({
        isEnabled: false,
        currentStep: undefined,

        givingTuesdayPoll: undefined,
        givingTuesdayPollIsLoading: true,

        pollAnswer: undefined,
        setPollAnswer: NOT_INITIALIZED,
        customPollAnswer: undefined,
        setCustomPollAnswer: NOT_INITIALIZED,

        allEnvelopes: undefined,
        givingTuesdayEnvelopes: undefined,
        envelopesLoading: true,

        joinGivingTuesdayClick: NOT_INITIALIZED,
        remindMeLaterClick: NOT_INITIALIZED,
        noThanksClick: NOT_INITIALIZED,
        confirmAndOptOutClick: NOT_INITIALIZED,
        updateEnvelopeConfirmClick: NOT_INITIALIZED,
        showCreateEnvelopeFormClick: NOT_INITIALIZED,
        createNewEnvelopeClick: NOT_INITIALIZED,
        backClick: NOT_INITIALIZED,
    });
