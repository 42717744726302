import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyRadioStyleProps = {
    variant: 'primary' | 'secondary';
} & GivelifyBoxMarginProps;

export const radioStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyRadioStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyRadioStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyRadioStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyRadioStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        radioContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        labelBefore: {
            marginRight: 8,
        },
        labelAfter: {
            marginLeft: 8,
        },
        radioHover: {
            color: (props: GivelifyRadioStyleProps) =>
                props.variant === 'primary'
                    ? theme.gas.palette.primary
                    : theme.gas.palette.label.secondary,
        },
    }),
);
