import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomizeProfileBanner from '../../../assets/customize-profile-captive-portal.svg';
import { I18N_NAMESPACE } from '../../../consts';
import {
    CustomizeProfileButton,
    CustomizeProfileContainer,
    CustomizeProfileDescription,
    CustomizeProfileImg,
    CustomizeProfileTitle,
} from './styles';

type CustomizeProfileProps = {
    goToSettingsClick: () => void;
    isNonProfit: boolean;
};

export const CustomizeProfile: React.FC<CustomizeProfileProps> = ({
    goToSettingsClick,
    isNonProfit,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            titleMobile: t('requiredInfo.customizeProfile.titleMobile'),
            descriptionMobile: t(
                'requiredInfo.customizeProfile.descriptionMobile',
            ),
            title: t('requiredInfo.customizeProfile.title'),
            worshipDescription: t(
                'requiredInfo.customizeProfile.worshipDescription',
            ),
            nonProfitDescription: t(
                'requiredInfo.customizeProfile.nonProfitDescription',
            ),
            actionButtonText: t('requiredInfo.customizeProfile.buttonText'),
        }),
        [t],
    );

    const handleButtonClick = React.useCallback(() => {
        goToSettingsClick();
    }, [goToSettingsClick]);

    return (
        <CustomizeProfileContainer container wrap="nowrap">
            <Grid item>
                <CustomizeProfileImg
                    alt="Customize Profile Banner"
                    src={CustomizeProfileBanner}
                />
            </Grid>
            <Grid item>
                <CustomizeProfileTitle
                    text={isMobile ? copy.titleMobile : copy.title}
                />
                <CustomizeProfileDescription
                    text={
                        isMobile
                            ? copy.descriptionMobile
                            : isNonProfit
                            ? copy.nonProfitDescription
                            : copy.worshipDescription
                    }
                />
                <CustomizeProfileButton
                    name="Customize your profile"
                    onClick={handleButtonClick}
                    size="large"
                    text={copy.actionButtonText}
                    variant="secondary"
                />
            </Grid>
        </CustomizeProfileContainer>
    );
};
