import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyTextFieldStyles } from './styles';
import { GivelifyButton } from '../button';
import { mergeRefs } from '../utils/mergeRefs';

export type GivelifyInputState = 'normal' | 'error' | 'warning' | 'success';
export interface GivelifyTextFieldBaseProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    id: string;
    name?: string;
    placeholder?: string;
    value?: string;
    inputRef?:
        | ((instance: HTMLInputElement) => void)
        | React.RefObject<HTMLInputElement | null>
        | null;
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string,
    ) => void;
    boxSize?: 'large' | 'medium' | 'dense';
    state?: GivelifyInputState;
    variant?: 'default' | 'alt' | 'message' | 'search';
    classes?: {
        root?: string;
        inputWrapper?: string;
        input?: string;
        helperRoot?: string;
        helperLeft?: string;
        helperRight?: string;
        sendButton?: string;
        searchButton?: string;
        sendIcon?: string;
        passwordButton?: string;
        startAdornmentRoot?: string;
        endAdornmentRoot?: string;
        passwordIcon?: string;
        prefix?: string;
        suffix?: string;
        label?: string;
        labelFocused?: string;
        labelFocusedRoot?: string;
        labelTop?: string;
        startPadding?: string;
        endPadding?: string;
    };
    disabled?: boolean;
    leftHelperText?: string;
    rightHelperText?: string;
    countLength?: boolean;
    maxLength?: number;
    onEnterSubmit?: (value: string) => void;
    submitting?: boolean;
    required?: boolean;
    defaultValue?: string;
    richHelperText?: boolean;
    width?: number | 'auto' | '100%';
    type?: 'text' | 'password' | 'number' | 'email';
    prefix?: string;
    suffix?: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    fakeFocus?: boolean;
    debounceValidation?: (value: string) => boolean;
    debounceErrorText?: string;
    hidePasswordAdornment?: boolean;
    showPassword?: boolean;
}

const VALIDATION_TIMER = 2000;
let validationCounter = 0;

export interface GivelifyTextFieldLabeledProps {
    label: string | undefined;
    ariaLabel?: string;
}
export interface GivelifyTextFieldAriaLabeledProps {
    label?: string;
    ariaLabel: string | undefined;
}
export type GivelifyTextFieldProps = GivelifyBoxMarginProps &
    GivelifyTextFieldBaseProps &
    (GivelifyTextFieldAriaLabeledProps | GivelifyTextFieldLabeledProps);

export const GivelifyTextField: React.FC<GivelifyTextFieldProps> = props => {
    const {
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        classes,
        id,
        ariaLabel,
        boxSize = 'medium',
        state = 'normal',
        variant = 'default',
        placeholder,
        disabled,
        leftHelperText,
        rightHelperText,
        countLength,
        maxLength,
        submitting,
        value: passedValue,
        required,
        richHelperText,
        width = 'auto',
        onChange,
        type = 'text',
        name,
        label,
        className,
        onEnterSubmit,
        inputRef,
        defaultValue,
        prefix,
        suffix,
        startAdornment,
        endAdornment,
        fakeFocus,
        onKeyDown: onKeyDownEvent,
        debounceValidation,
        debounceErrorText,
        hidePasswordAdornment = false,
        showPassword: showPasswordProp = false,
        ...inputProps
    } = props;
    const ref = React.useRef<HTMLInputElement | null>(null);
    const [value, setValue] = React.useState(defaultValue ? defaultValue : '');
    const [inputState, setInputState] = React.useState<GivelifyInputState>(
        state,
    );
    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(showPasswordProp);

    const {
        boxMargin,
        tfRoot,
        tfInput,
        tfWrapper,
        tfInputMessage,
        tfLarge,
        tfMedium,
        tfDense,
        tfBorderNormal,
        tfBorderError,
        tfBorderSuccess,
        tfBorderWarning,
        tfDisabled,
        tfHelper,
        tfHelperLeft,
        tfHelperRight,
        tfSendButton,
        tfSearchButton,
        tfPasswordButton,
        tfLabel,
        tfLabelFocused,
        tfLabelFocusedRoot,
        tfLabelHide,
        tfTopBorder,
        tfPrefix,
        tfSuffix,
        tfStartAdornment,
        tfEndAdornment,
        tfStartPadding,
        tfEndPadding,
    } = GivelifyTextFieldStyles({
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        state: inputState,
        variant,
        width: width,
        type: type === 'password' ? 'password' : 'text',
        focused: focused || fakeFocus,
        hovered,
        prefixLength:
            prefix && variant !== 'message' ? prefix.length : undefined,
        suffixLength:
            suffix && variant !== 'message' ? suffix.length : undefined,
    });

    const runDebounceValidations = React.useCallback(
        (value: string) => {
            if (!debounceValidation) return;
            window.clearTimeout(validationCounter);
            if (debounceValidation(value)) {
                setInputState('normal');
            } else {
                validationCounter = window.setTimeout(() => {
                    setInputState('error');
                }, VALIDATION_TIMER);
            }
        },
        [debounceValidation, setInputState],
    );

    const onInputValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (submitting) return;
            const newValue = event.target.value;
            setValue(newValue);
            if (onChange) {
                onChange(event, newValue);
                runDebounceValidations(newValue);
            }
        },
        [submitting, onChange, setValue, runDebounceValidations],
    );

    React.useEffect(() => {
        setInputState(state);
    }, [state, setInputState]);

    const toggleVisibility = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword, setShowPassword]);

    React.useEffect(() => {
        setShowPassword(showPasswordProp);
    }, [showPasswordProp, setShowPassword]);

    const onSubmit = React.useCallback(() => {
        if (onEnterSubmit) {
            onEnterSubmit(value);
        }
        if (ref) {
            ref.current?.blur();
        }
        // eslint-disable-next-line
    }, [value, ref, onEnterSubmit]);

    const onKeyDown = (event: any) => {
        if (event.defaultPrevented) {
            return;
        }
        let handled = false;
        if (event.key === 'Enter') {
            handled = true;
        } else if (event.keyCode === 13) {
            handled = true;
        }
        if (handled) {
            if (!event.shiftKey) {
                onSubmit();
            }
        }
    };

    const renderRichText = React.useCallback((content: string) => {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }, []);

    const rootClassName = mergeClassNames(
        boxMargin,
        tfRoot,
        classes ? (classes.root ? classes.root : '') : '',
        props.className,
    );

    const holderClassName = mergeClassNames(
        classes ? (classes.inputWrapper ? classes.inputWrapper : '') : '',
        tfWrapper,
    );

    const prefixClassName = mergeClassNames(
        tfPrefix,
        classes ? (classes.prefix ? classes.prefix : '') : '',
    );

    const suffixClassName = mergeClassNames(
        tfSuffix,
        classes ? (classes.suffix ? classes.suffix : '') : '',
    );

    const helperClassName = mergeClassNames(
        tfHelper,
        classes ? (classes.helperRoot ? classes.helperRoot : '') : '',
    );

    const helperLeftClassName = mergeClassNames(
        tfHelperLeft,
        classes ? (classes.helperLeft ? classes.helperLeft : '') : '',
    );

    const helperRightClassName = mergeClassNames(
        tfHelperRight,
        classes ? (classes.helperRight ? classes.helperRight : '') : '',
    );

    const sendButtonClassName = mergeClassNames(
        tfSendButton,
        classes ? (classes.sendButton ? classes.sendButton : '') : '',
    );

    const searchButtonClassName = mergeClassNames(
        tfSearchButton,
        classes ? (classes.searchButton ? classes.searchButton : '') : '',
    );

    const passwordButtonClassName = mergeClassNames(
        tfPasswordButton,
        classes ? (classes.passwordButton ? classes.passwordButton : '') : '',
    );

    const startAdornmentClassName = mergeClassNames(
        tfStartAdornment,
        classes
            ? classes.startAdornmentRoot
                ? classes.startAdornmentRoot
                : ''
            : '',
    );

    const endAdornmentClassName = mergeClassNames(
        tfEndAdornment,
        classes
            ? classes.endAdornmentRoot
                ? classes.endAdornmentRoot
                : ''
            : '',
    );

    const startPaddingClassName =
        classes && classes.startPadding ? classes.startPadding : tfStartPadding;

    const endPaddingClassName =
        classes && classes.endPadding ? classes.endPadding : tfEndPadding;

    const labelNormalClassName = mergeClassNames(
        tfLabel,
        startAdornment !== undefined && startPaddingClassName,
        classes ? (classes.label ? classes.label : '') : '',
    );

    const labelFocusedClassName = mergeClassNames(
        tfLabelFocused,
        classes ? (classes.labelFocused ? classes.labelFocused : '') : '',
    );

    const labelFocusedRootClassName = mergeClassNames(
        tfLabelFocusedRoot,
        classes
            ? classes.labelFocusedRoot
                ? classes.labelFocusedRoot
                : ''
            : '',
    );

    const inputClassName = mergeClassNames(
        variant === 'message' && tfInputMessage,
        tfInput,
        (variant === 'default' || variant === 'search' || variant === 'alt') &&
            boxSize === 'large' &&
            tfLarge,
        (variant === 'default' || variant === 'search' || variant === 'alt') &&
            boxSize === 'medium' &&
            tfMedium,
        (variant === 'default' || variant === 'search' || variant === 'alt') &&
            boxSize === 'dense' &&
            tfDense,
        inputState === 'normal' && tfBorderNormal,
        inputState === 'error' && !disabled && tfBorderError,
        inputState === 'success' && !disabled && tfBorderSuccess,
        inputState === 'warning' && !disabled && tfBorderWarning,
        disabled && tfDisabled,
        label && (focused || value || fakeFocus) && tfTopBorder,
        startAdornment !== undefined && startPaddingClassName,
        ((type === 'password' && !hidePasswordAdornment) ||
            endAdornment !== undefined) &&
            endPaddingClassName,
        classes ? (classes.input ? classes.input : '') : '',
    );

    if (
        onChange !== undefined &&
        passedValue !== undefined &&
        passedValue !== value
    ) {
        setValue(passedValue);
    }

    return (
        <div id={`${id}-root`} className={rootClassName}>
            <div id={`${id}-holder`} className={holderClassName}>
                <input
                    {...inputProps}
                    id={id}
                    name={name}
                    aria-label={ariaLabel ? ariaLabel : label}
                    className={inputClassName}
                    placeholder={
                        variant === 'search'
                            ? placeholder
                            : label
                            ? focused || fakeFocus
                                ? placeholder
                                : undefined
                            : placeholder
                    }
                    disabled={disabled}
                    value={value}
                    onChange={onInputValueChange}
                    maxLength={maxLength}
                    onKeyDown={onKeyDownEvent ? onKeyDownEvent : onKeyDown}
                    ref={inputRef ? mergeRefs([ref, inputRef]) : ref}
                    required={required}
                    draggable={false}
                    style={{ resize: 'none' }}
                    type={showPassword ? 'text' : type}
                    onFocus={event => {
                        setFocused(true);
                        if (inputProps?.onFocus) inputProps.onFocus(event);
                    }}
                    onBlur={event => {
                        setFocused(false);
                        if (inputProps?.onBlur) inputProps.onBlur(event);
                    }}
                    onMouseEnter={event => {
                        setHovered(true);
                        if (inputProps?.onMouseEnter)
                            inputProps.onMouseEnter(event);
                    }}
                    onMouseLeave={event => {
                        setHovered(false);
                        if (inputProps?.onMouseLeave)
                            inputProps.onMouseLeave(event);
                    }}
                />
                {prefix && variant !== 'search' && variant !== 'message' ? (
                    <div className={prefixClassName}>{prefix}</div>
                ) : null}
                {label &&
                    variant !== 'search' &&
                    variant !== 'message' &&
                    !value && (
                        <div
                            className={`${labelNormalClassName} ${
                                focused || fakeFocus ? tfLabelHide : ''
                            }`}
                        >
                            {label}
                        </div>
                    )}
                {label &&
                    variant !== 'search' &&
                    variant !== 'message' &&
                    (focused || value || fakeFocus) && (
                        <div className={labelFocusedRootClassName}>
                            <div className={labelFocusedClassName}>{label}</div>
                        </div>
                    )}
                {startAdornment ? (
                    <div className={startAdornmentClassName}>
                        {startAdornment}
                    </div>
                ) : null}
                {suffix && variant !== 'search' && variant !== 'message' ? (
                    <div className={suffixClassName}>{suffix}</div>
                ) : null}
                {endAdornment ? (
                    <div className={endAdornmentClassName}>{endAdornment}</div>
                ) : null}
                {variant === 'default' ? (
                    type === 'password' && !hidePasswordAdornment ? (
                        <div className={passwordButtonClassName}>
                            <GivelifyButton
                                name={`${name}_passwordToggle`}
                                variant="icon"
                                onClick={toggleVisibility}
                                size="shrink"
                                iconVariant={
                                    showPassword ? 'password' : 'password-open'
                                }
                            />
                        </div>
                    ) : null
                ) : variant === 'message' ? (
                    <div className={sendButtonClassName}>
                        <GivelifyButton
                            name={`${name}_send`}
                            onClick={onSubmit}
                            variant="icon"
                            iconVariant="send"
                            isLoading={submitting}
                        />
                    </div>
                ) : variant === 'search' ? (
                    <div className={searchButtonClassName}>
                        <GivelifyButton
                            name={`${name}_search`}
                            onClick={onSubmit}
                            variant="icon"
                            iconVariant="search"
                            isLoading={submitting}
                        />
                    </div>
                ) : null}
            </div>
            {leftHelperText ||
            (debounceErrorText && inputState === 'error') ||
            rightHelperText ||
            countLength ? (
                <div id={`${id}-helper`} className={helperClassName}>
                    {!leftHelperText && !debounceErrorText ? null : (
                        <div
                            id={`${id}-helper-left`}
                            className={helperLeftClassName}
                        >
                            {debounceErrorText
                                ? debounceErrorText
                                : richHelperText && leftHelperText
                                ? renderRichText(leftHelperText)
                                : leftHelperText}
                        </div>
                    )}
                    {rightHelperText || countLength ? (
                        <div
                            id={`${id}-helper-right`}
                            className={helperRightClassName}
                        >
                            {countLength
                                ? maxLength
                                    ? `${value.length}/${maxLength}`
                                    : value.length
                                : rightHelperText
                                ? richHelperText
                                    ? renderRichText(rightHelperText)
                                    : rightHelperText
                                : ''}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export type GivelifyFormTextFieldProps = GivelifyTextFieldProps & {
    name: string;
    formInputRef: React.Ref<HTMLInputElement>;
};

export const GivelifyFormTextField: React.FC<GivelifyFormTextFieldProps> = props => {
    const {
        name,
        defaultValue,
        value,
        onChange,
        formInputRef,
        required,
        ...inputProps
    } = props;
    const [curValue, setCurValue] = React.useState(
        value ? value : defaultValue ? defaultValue : '',
    );
    const onChangeEvent = React.useCallback(
        (event, newValue) => {
            if (value && onChange) {
                onChange(event, newValue);
            } else {
                setCurValue(newValue);
            }
        },
        [setCurValue, onChange, value],
    );
    React.useEffect(() => {
        if (value !== undefined) {
            setCurValue(value);
        }
    }, [value]);
    return (
        <>
            <input
                id={props.id}
                hidden
                name={name}
                value={curValue}
                // eslint-disable-next-line
                onChange={() => {}}
                ref={formInputRef}
                required={required}
            />
            <GivelifyTextField
                {...inputProps}
                id={`${props.id}-controll`}
                name={name}
                required={false}
                value={curValue}
                onChange={onChangeEvent}
            />
        </>
    );
};
