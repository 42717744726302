import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSelect = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>select</title>
        <path
            clipRule="evenodd"
            d="M5.79289 8.79289C6.18342 8.40237 6.81658 8.40237 7.20711 8.79289L12 13.5858L16.7929 8.79289C17.1834 8.40237 17.8166 8.40237 18.2071 8.79289C18.5976 9.18342 18.5976 9.81658 18.2071 10.2071L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071L5.79289 10.2071C5.40237 9.81658 5.40237 9.18342 5.79289 8.79289Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const SelectIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSelect}
        fontSize={fontSize}
    />
);
