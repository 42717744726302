import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    matchHintText: {
        fontSize: 14,
    },
    autocomplete: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        '&:focus-within': {
            boxShadow: '0 0 0',
            border: 0,
            borderRadius: '25px',
        },
        '& .MuiInput-input': {
            border: 'none',
            backgroundColor: theme.palette.background.paper,
        },
    },
    autocompleteInput: {
        '& .MuiInputBase-root': {
            border: '1px solid #D8D8D8',
            borderRadius: '25px',
            paddingRight: '16px !important',
            paddingLeft: theme.spacing(3),
            paddingBottom: 0,
            height: 40,
            '&:focus-within': {
                boxShadow: '0 0 0',
            },
        },
        '&:focus': {
            border: 'none',
            outline: 0,
            boxShadow: 'none',
        },
        '& .MuiInputBase-input': {
            paddingLeft: '16px !important',
            '&:focus': {
                outline: 0,
                border: 'none',
                boxShadow: '0 0 0',
            },
        },
    },
    searchContent: {
        marginLeft: 'auto',
    },
    autocompletePopper: {
        display: 'none',
    },
}));
