import React, { useEffect, useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { UserFormData } from '../types';
import PermissionCard from './PermissionCard';
import useStyles from './styles';

const RolePicker = () => {
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.users.add-new-modal.form.permissions',
    });

    const copy = useMemo(
        () => ({
            step2: scopedTranslate('labels.step2'),
            permissions: scopedTranslate('label'),
            allPrivilegesOf: scopedTranslate('allPrivilegesOf'),
            basic: scopedTranslate('basic.title'),
            basicSubTitle: scopedTranslate('basic.subTitle'),
            basicViewAppProfile: scopedTranslate('basic.viewAppProfile'),
            basicViewEnvelopes: scopedTranslate('basic.viewEnvelopes'),
            basicUserTools: scopedTranslate('basic.userTools'),
            basicViewFinancial: scopedTranslate('basic.viewFinancial'),
            basicGenerateReports: scopedTranslate('basic.generateReports'),
            financial: scopedTranslate('financial.title'),
            financialSubTitle: scopedTranslate('financial.subTitle'),
            financialExportData: scopedTranslate('financial.exportData'),
            financialManageProfiles: scopedTranslate(
                'financial.manageProfiles',
            ),
            financialCommunicateWithDonor: scopedTranslate(
                'financial.communicateWithDonor',
            ),
            financialNoAccess: scopedTranslate('financial.noAccess'),
            admin: scopedTranslate('admin.title'),
            adminSubTitle: scopedTranslate('admin.subTitle'),
            adminFinancialCoordinator: scopedTranslate(
                'admin.financialCoordinator',
            ),
            adminManageBankAccount: scopedTranslate('admin.manageBankAccount'),
            adminAddUsers: scopedTranslate('admin.addUsers'),
            adminFullAccess: scopedTranslate('admin.fullAccess'),
        }),
        [scopedTranslate],
    );

    const styles = useStyles();

    const { watch, setValue, register } = useFormContext<UserFormData>();

    const { role } = watch(['role']);

    const onRoleChange = (value: string) => setValue('role', value);

    useEffect(() => {
        register('role');
    }, [register]);

    return (
        <Grid container spacing={3}>
                <Grid item className={styles.permissionCard} md={4} sm={12}>
                    <PermissionCard
                        details={[
                            <GivelifyLabel
                                key={copy.basicViewAppProfile}
                                text={copy.basicViewAppProfile}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicViewEnvelopes}
                                text={copy.basicViewEnvelopes}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicUserTools}
                                text={copy.basicUserTools}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicViewFinancial}
                                text={copy.basicViewFinancial}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.basicGenerateReports}
                                text={copy.basicGenerateReports}
                                variant="body1"
                            />,
                        ]}
                        id="permission-basic"
                        onSelected={() => {
                            onRoleChange('Basic');
                        }}
                        selected={role === 'Basic'}
                        subTitle={copy.basicSubTitle}
                        title={copy.basic}
                    />
                </Grid>
                <Grid item className={styles.permissionCard} md={4} sm={12}>
                    <PermissionCard
                        details={[
                            <div
                                key={`${copy.allPrivilegesOf} ${copy.basic}`}
                                className={styles.detailText}
                            >
                                {copy.allPrivilegesOf}{' '}
                                <strong>{copy.basic}</strong>
                            </div>,
                            <GivelifyLabel
                                key={copy.financialManageProfiles}
                                text={copy.financialManageProfiles}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.financialExportData}
                                text={copy.financialExportData}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.financialCommunicateWithDonor}
                                text={copy.financialCommunicateWithDonor}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.financialNoAccess}
                                text={copy.financialNoAccess}
                                variant="body1"
                            />,
                        ]}
                        id="permission-financial"
                        onSelected={() => {
                            onRoleChange('Financial');
                        }}
                        selected={role === 'Financial'}
                        subTitle={copy.financialSubTitle}
                        title={copy.financial}
                    />
                </Grid>
                <Grid item className={styles.permissionCard} md={4} sm={12}>
                    <PermissionCard
                        details={[
                            <div
                                key={`${copy.allPrivilegesOf} ${copy.adminFinancialCoordinator}`}
                                className={styles.detailText}
                            >
                                {copy.allPrivilegesOf}{' '}
                                <strong>
                                    {copy.adminFinancialCoordinator}
                                </strong>
                            </div>,
                            <GivelifyLabel
                                key={copy.adminManageBankAccount}
                                text={copy.adminManageBankAccount}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.adminAddUsers}
                                text={copy.adminAddUsers}
                                variant="body1"
                            />,
                            <GivelifyLabel
                                key={copy.adminFullAccess}
                                text={copy.adminFullAccess}
                                variant="body1"
                            />,
                        ]}
                        id="permission-admin"
                        onSelected={() => {
                            onRoleChange('Admin');
                        }}
                        selected={role === 'Admin'}
                        subTitle={copy.adminSubTitle}
                        title={copy.admin}
                    />
                </Grid>
            </Grid>
    );
};

export default RolePicker;
