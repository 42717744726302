import { useEffect, useState } from 'react';
import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { UserResponse } from '../models';

export const useSubmittedByName = (doneeId: number, userId: number | null) => {
    const [name, setName] = useState<string | null>(null);

    useEffect(() => {
        const loadUser = async () => {
            const httpRequest = getAxiosClient().get(
                `/donees/${doneeId}/users/${userId}`,
            );

            const userResponse = await makeApiRequest<UserResponse>(
                httpRequest,
            );

            if (!userResponse.success || !userResponse.response) return;

            const { firstName, lastName } = userResponse.response.data;
            setName(`${firstName} ${lastName}`);
        };

        if (userId) {
            loadUser();
        }
    }, [doneeId, userId]);

    return name;
};
