import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    wrapper: {
        maxWidth: 1040,
        margin: '0 auto',
    },
}));

export default useStyles;
