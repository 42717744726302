// Social Media Giving

export const SOCIAL_GIVING_LOADED = 'Landed on Social Media Giving page';
export const CLICK_FB_GIVING_CARD = 'Clicked Facebook Giving Card';
export const CLICK_IG_GIVING_CARD = 'Clicked Instagram Giving Card';
export const CLICK_YT_GIVING_CARD = 'Clicked YouTube Giving Card';
export const CLICK_TW_GIVING_CARD = 'Clicked Twitter Giving Card';

// Facebook Giving
export const FB_GIVING_LOADED = 'Landed on Facebook Giving page';
export const FB_GIVING_SHARE = 'Clicked on Share on Facebook';
export const FB_GIVING_COPY_LINK = 'Copied giving link on Facebook Giving page';

// Twitter Giving
export const TW_GIVING_LOADED = 'Landed on Twitter Giving page';
export const TW_GIVING_SHARE = 'Clicked on Share on Twitter';
export const TW_GIVING_COPY_LINK = 'Copied giving link on Twitter Giving page';

// Instagram Giving
export const IG_GIVING_LOADED = 'Landed on Instagram Giving page';
export const IG_GIVING_COPY_LINK =
    'Copied giving link on Instagram Giving page';

// YouTube Giving
export const YT_GIVING_LOADED = 'Landed on YouTube Giving page';
export const YT_GIVING_COPY_LINK = 'Copied giving link on YouTube Giving page';

// Online Giving
export const ONLINE_GIVING_LANDED = 'Landed on Online Giving page';

// Online Giving - copy button code
export const LIGHT_GIVE_BUTTON_COPY_CODE =
    'Copied the code of light give button on Givelify button page';
export const DARK_GIVE_BUTTON_COPY_CODE =
    'Copied the code of dark give button on Givelify button page';
export const PRIMARY_GIVE_BUTTON_COPY_CODE =
    'Copied the code of primary give button on Givelify button page';

// Online Giving - Givelify button public page
export const GIVELIFY_BUTTON_PUBLIC_PAGE_Loaded =
    'Landed on Givelify button (public) page';
