import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getEnvelopesDeleteEndpoint,
    getEnvelopesUpdateEndpoint,
    getUpdatePrioritiesEndpoint,
} from 'api/client/endpoints';
import { Envelope } from 'api/models';

import {
    getEnvelopes,
    UpdateEnvelopeResponse,
} from 'api/requests';

export const getActiveEnvelopes = (doneeId: number) =>
    getEnvelopes(doneeId, 'active', undefined, true, 'priority');

export const getInactiveEnvelopes = (doneeId: number, page: number) =>
    getEnvelopes(doneeId, 'inactive', page, false, 'priority');

export const hideEnvelope = (doneeId: number, envelopeId: number) => {
    const url = getEnvelopesUpdateEndpoint(doneeId, envelopeId);
    const httpRequest = getAxiosClient().patch(url, {
        active: false,
        priority: 'lowest',
    });
    return makeApiRequest<UpdateEnvelopeResponse>(httpRequest);
};

export const showEnvelope = (
    doneeId: number,
    envelopeId: number,
    isDefault: boolean,
    alwaysOn: 'Always' | 'Timed',
    startDate: string,
    endDate: string,
    priority: number,
) => {
    const defaultData = {
        active: true,
        priority,
    };

    const url = getEnvelopesUpdateEndpoint(doneeId, envelopeId);
    const data = isDefault
        ? defaultData
        : {
              ...defaultData,
              alwaysOn,
              startDate,
              endDate,
          };
    const httpRequest = getAxiosClient().patch(url, data);
    return makeApiRequest<UpdateEnvelopeResponse>(httpRequest);
};

export const deleteEnvelope = (doneeId: number, id: number) => {
    const url = getEnvelopesDeleteEndpoint(doneeId, id);

    const httpRequest = getAxiosClient().delete(url);
    return makeApiRequest(httpRequest);
};

export const updatePriorities = (
    doneeId: number,
    priorities: { envelopeId: number; priority: number }[],
) => {
    const url = getUpdatePrioritiesEndpoint(doneeId);

    const httpRequest = getAxiosClient().patch(url, priorities);
    return makeApiRequest<Envelope[]>(httpRequest);
};
