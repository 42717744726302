import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyLabel,
    GivelifyButton,
    GivelifyNotification,
} from '@givelify/givelify-ui';
import { GivelifyLink, GivelifyIcon } from '@givelify/ui';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAdvancedTranslation } from 'utils/i18n';
import ReportsPageHeader from '../components/ReportsPageHeader';
import LegacyReportItem from './components/LegacyReportItem';
import { legacyReportStyles } from './components/style';

interface ReportState {
    showInProgress: boolean;
    showError: boolean;
}

const LegacyReports: React.FC = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation();
    const classes = legacyReportStyles();
    const location = useLocation();
    const state = location.state as ReportState;
    const navigate = useNavigate();

    const ReportText = useMemo(
        () => ({
            dataExports: t('reports.dataExports'),
            dataExportsDefine: t(
                'reports.legacyReportsDashboard.dataExportsDefine',
            ),
            quickBooksHeader: t(
                'reports.legacyReportsDashboard.quickBooksHeader',
            ),
            quickBooksHeaderDefine: t(
                'reports.legacyReportsDashboard.quickBooksHeaderDefine',
            ),
            heading: t('reports.legacyReportsDashboard.heading'),
            historyLink: t(
                'reports.legacyReportsDashboard.viewPreviousReports',
            ),
            item: {
                donations: t('reports.legacyReportsDashboard.item.donations'),
                donationsDefine: t(
                    'reports.legacyReportsDashboard.item.donationsDefine',
                ),
                disbursements: t(
                    'reports.legacyReportsDashboard.item.disbursements',
                ),
                disbursementsDefine: t(
                    'reports.legacyReportsDashboard.item.disbursementsDefine',
                ),
                donors: t('reports.legacyReportsDashboard.item.donors'),
                donorsDefine: t(
                    'reports.legacyReportsDashboard.item.donorsDefine',
                ),
                quickBooksEnvelope: t(
                    'reports.legacyReportsDashboard.item.quickBooksEnvelope',
                ),
                quickBooksEnvelopeDefine: t(
                    'reports.legacyReportsDashboard.item.quickBooksEnvelopeDefine',
                ),
                quickBooksDonor: t(
                    'reports.legacyReportsDashboard.item.quickBooksDonor',
                ),
                quickBooksDonorDefine: t(
                    'reports.legacyReportsDashboard.item.quickBooksDonorDefine',
                ),
                importFriendly: t(
                    'reports.legacyReportsDashboard.item.importFriendly',
                ),
                importFriendlyDefine: t(
                    'reports.legacyReportsDashboard.item.importFriendlyDefine',
                ),
            },
            info: {
                donationsDataExportInfo: t(
                    'reports.legacyReportsDashboard.info.donationsDataExportInfo',
                ),
                quickBooksEnvelopeInfo: t(
                    'reports.legacyReportsDashboard.info.quickBooksEnvelopeInfo',
                ),
                quickBooksDonorInfo: t(
                    'reports.legacyReportsDashboard.info.quickBooksDonorInfo',
                ),
                disbursementsInfo: t(
                    'reports.legacyReportsDashboard.info.disbursementsInfo',
                ),
                donorsInfo: t('reports.legacyReportsDashboard.info.donorsInfo'),
                importFriendlyInfo: t(
                    'reports.legacyReportsDashboard.info.importFriendlyInfo',
                ),
            },
            gotoHistory: t('reports.gotoHistory'),
            generatingReport: t('reports.generatingReport'),
            viewHistory: t('reports.viewHistory'),
            error: t('error.500.heading'),
        }),
        [t],
    );

    useEffect(() => {
        if (state) {
            setShowInProgress(!!state.showInProgress);
            setShowError(!!state.showError);
        }
        // run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToHistory = () => {
        navigate(PATH.REPORTS.REPORT_HISTORY);
    };
    const [showInProgress, setShowInProgress] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.ReportsOldDashboardTab}
        >
            <ReportsPageHeader page="GenerateLegacyReport" />
            <GivelifyNotification
                className={classes.reportNotification}
                onClose={() => setShowInProgress(false)}
                show={showInProgress}
                variant="info"
            >
                <div className={classes.reportNotificationContent}>
                    <GivelifyLabel
                        className="successfully"
                        text={ReportText.generatingReport}
                        variant="body1"
                    />
                    <GivelifyButton
                        className="goToHistory"
                        onClick={goToHistory}
                        text={ReportText.viewHistory}
                        variant="ghost"
                    />
                    .
                </div>
            </GivelifyNotification>
            <GivelifyNotification
                className={classes.reportNotification}
                onClose={() => setShowError(false)}
                show={showError}
                variant="error"
            >
                <div className={classes.reportNotificationContent}>
                    <GivelifyLabel
                        className="successfully"
                        text={ReportText.error}
                        variant="body1"
                    />
                </div>
            </GivelifyNotification>

            <Grid container>
                <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.legacyReportsHeaderWrapper}
                    justifyContent="space-between"
                    lg={12}
                    xs={12}
                >
                    <Grid item md="auto" xs={12}>
                        <GivelifyLabel
                            className={classes.legacyReportsHeader}
                            text={ReportText.heading}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.viewPreviousReports}
                        md="auto"
                        xs={12}
                    >
                        <GivelifyLink
                            name="View Previous Reports"
                            onClick={goToHistory}
                            text={ReportText.historyLink}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    alignItems="center"
                    lg={12}
                    spacing={2}
                    wrap="nowrap"
                    xs={12}
                >
                    <GivelifyIcon
                        className={classes.reportIcon}
                        variant="data-exports"
                    />
                    <Grid item xs="auto">
                        <GivelifyLabel
                            fontWeight={700}
                            marginBottom={0}
                            text={ReportText.dataExports}
                            variant="heading3"
                        />
                        <GivelifyLabel
                            fontWeight={600}
                            text={ReportText.dataExportsDefine}
                            variant="body1"
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.reportRow} lg={12} xs={12}>
                    <Grid container className={classes.reportMainContent}>
                        <LegacyReportItem
                            define={ReportText.item.donationsDefine}
                            icon={
                                <GivelifyIcon
                                    className={classes.reportIcon}
                                    variant="hand-holding-usd-fill"
                                />
                            }
                            id="legacy-donations"
                            include={ReportText.info.donationsDataExportInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_DONATIONS}
                            name={ReportText.item.donations}
                        />
                        <LegacyReportItem
                            define={ReportText.item.disbursementsDefine}
                            icon={
                                <GivelifyIcon
                                    className={classes.reportIcon}
                                    variant="bank-deposits"
                                />
                            }
                            include={ReportText.info.disbursementsInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS}
                            name={ReportText.item.disbursements}
                        />
                        <LegacyReportItem
                            quickbook
                            define={ReportText.item.donorsDefine}
                            icon={
                                <GivelifyIcon
                                    className={classes.reportIcon}
                                    variant="donors-old"
                                />
                            }
                            id="legacy-donors"
                            include={ReportText.info.donorsInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_DONORS}
                            name={ReportText.item.donors}
                        />
                        <LegacyReportItem
                            quickbook
                            define={ReportText.item.importFriendlyDefine}
                            icon={
                                <GivelifyIcon
                                    className={classes.reportIcon}
                                    variant="import-friendly"
                                />
                            }
                            id="legacy-import-friendly"
                            include={ReportText.info.importFriendlyInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY}
                            name={ReportText.item.importFriendly}
                        />
                    </Grid>
                </Grid>

                <Grid item className={classes.reportRow} lg={12} xs={12}>
                    <Grid
                        container
                        item
                        alignItems="center"
                        lg={12}
                        wrap="nowrap"
                        xs={12}
                    >
                        <GivelifyIcon
                            className={classes.reportIcon}
                            variant="quickbooks"
                        />
                        <div>
                            <GivelifyLabel
                                fontWeight={700}
                                marginBottom={0}
                                text={ReportText.quickBooksHeader}
                                variant="heading3"
                            />
                            <GivelifyLabel
                                fontWeight={600}
                                text={ReportText.quickBooksHeaderDefine}
                                variant="body1"
                            />
                        </div>
                    </Grid>
                    <Grid container className={classes.reportList}>
                        <LegacyReportItem
                            define={ReportText.item.quickBooksEnvelopeDefine}
                            icon={
                                <GivelifyIcon
                                    className={classes.reportIcon}
                                    variant="by-envelope-fill"
                                />
                            }
                            id="legacy-qb-envelopes"
                            include={ReportText.info.quickBooksEnvelopeInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE}
                            name={ReportText.item.quickBooksEnvelope}
                        />

                        <LegacyReportItem
                            define={ReportText.item.quickBooksDonorDefine}
                            icon={
                                <GivelifyIcon
                                    className={classes.reportIcon}
                                    variant="by-donor-fill"
                                />
                            }
                            id="legacy-qb-donor"
                            include={ReportText.info.quickBooksDonorInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_BY_DONOR}
                            name={ReportText.item.quickBooksDonor}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </TrackingProvider>
    );
};

export default LegacyReports;
