import React, { useMemo } from 'react';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { reportHistoryStyle } from '../components/style';

const ReportHistoryNoResult: React.FC = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useTranslation();
    const classes = reportHistoryStyle();
    const navigate = useNavigate();

    const copy = useMemo(
        () => ({
            noReport: t('reportHistory.noReport'),
            noReportDefine: t('reportHistory.noReportDefine'),
            goReport: t('reportHistory.goReport'),
        }),
        [t],
    );

    return (
        <div className={classes.noResultContent}>
            <FontAwesomeIcon icon={faFileAlt} />
            <Typography variant="h1">{copy.noReport}</Typography>
            <Typography className={classes.noResultDefine}>
                {copy.noReportDefine}
            </Typography>
            <Link
                className={classes.backToReport}
                onClick={() => navigate(PATH.REPORTS.GENERATE_REPORTS())}
            >
                {copy.goReport}
            </Link>
        </div>
    );
};

export default ReportHistoryNoResult;
