import React from 'react';
import { PAGE_NAME } from '@givelify/utils';
import SettingsPage from '../SettingsPage';
import { CampusesProvider } from './CampusesProvider';
import View from './view';

const Campuses = () => {
    return (
        <SettingsPage
            trackPageVisit
            pageName={PAGE_NAME.SettingsCampuses}
            title="Campuses"
        >
            <CampusesProvider>
                <View />
            </CampusesProvider>
        </SettingsPage>
    );
};

export default Campuses;
