import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>search</title>
        <path
            clipRule="evenodd"
            d="M2 10.8571C2 5.96548 5.96548 2 10.8571 2C15.7488 2 19.7143 5.96548 19.7143 10.8571C19.7143 15.7488 15.7488 19.7143 10.8571 19.7143C5.96548 19.7143 2 15.7488 2 10.8571ZM10.8571 0C4.86091 0 0 4.86091 0 10.8571C0 16.8534 4.86091 21.7143 10.8571 21.7143C13.4952 21.7143 15.9135 20.7734 17.7948 19.209L22.2929 23.7071C22.6834 24.0976 23.3166 24.0976 23.7071 23.7071C24.0976 23.3166 24.0976 22.6834 23.7071 22.2929L19.209 17.7948C20.7734 15.9135 21.7143 13.4952 21.7143 10.8571C21.7143 4.86091 16.8534 0 10.8571 0Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const SearchIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSearch}
        fontSize={fontSize}
    />
);
