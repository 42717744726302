import { makeStyles } from '@material-ui/core';

const TABLE_CELL_BACKGROUND_COLOR = 'white';

export default makeStyles(() => ({
    tbCell: {
        boxSizing: 'border-box',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.12)',
        backgroundColor: TABLE_CELL_BACKGROUND_COLOR,
    },
    tbTextCenter: {
        textAlign: 'center',
    },
    tbTextRight: {
        textAlign: 'right',
    },
}));
