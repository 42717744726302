import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GivelifyListTile } from '../listTile';

interface SliderStyles {
    height: number;
}

const useStyles = makeStyles(() => ({
    sliderStyles: {
        position: 'relative',
        padding: '13px 11px 12px 7px',
        background: 'inherit',
        boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
        borderRadius: 10,
        '& .top-section': {
            cursor: 'pointer',
        },
    },
    childrenWrapper: {
        overflow: 'hidden',
        transition: 'all 0.5s',
        height: ({ height }: SliderStyles) => height,
    },
}));

export interface SlidingPanelProps {
    open: boolean;
    heading: string;
    onClick: (selected: boolean) => void;
    headerContent?: JSX.Element;
    selectId?: string;
}

export const SlidingPanel: React.FC<SlidingPanelProps> = ({
    open,
    heading,
    children,
    onClick,
    headerContent,
    selectId,
}) => {
    return (
        <GivelifyListTile
            id={selectId}
            onSelect={onClick}
            selected={open}
            heading={heading}
            headerContent={headerContent}
        >
            {children && <SlidingBox open={open}>{children}</SlidingBox>}
        </GivelifyListTile>
    );
};

export const SlidingBox: React.FC<{ open: boolean }> = ({ open, children }) => {
    const childWrapperRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);
    const { childrenWrapper } = useStyles({ height: open ? height : 0 });

    useEffect(() => {
        if (!childRef.current || !childWrapperRef.current) return;
        const clone = childRef.current.cloneNode(true);
        childWrapperRef.current.appendChild(clone);
        clone.style = 'height: auto;';
        setHeight(clone.offsetHeight || 0);
        childWrapperRef.current.removeChild(clone);
    }, [children]);

    return (
        <div ref={childWrapperRef}>
            <div ref={childRef} className={childrenWrapper}>
                {children}
            </div>
        </div>
    );
};
