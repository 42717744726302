import React from 'react';
import {
    DesignTokens,
    GivelifyFormCheckbox,
    GivelifyFormDatePicker,
    GivelifyFormTextField,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SocialNumberInput } from '../../socialNumberInput';
import { PrimaryRepInfoForm, primaryRepManualEvents } from '../../types';
import { PrimaryRepEditorFooter } from '../editorFooter';
import { NameFields, InputsBox, Note } from '../styles';

export type InfoStepFormRef = {
    isEditing: () => boolean;
};

export type InfoStepFormProps = {
    userDetails: Partial<PrimaryRepInfoForm>;
    onCancelClick: () => void;
    replace?: boolean;
};

export const InfoStepForm = React.forwardRef<
    InfoStepFormRef,
    InfoStepFormProps
>(({ userDetails, onCancelClick, replace }, ref) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            placeholder1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder1',
            ),
            placeholder2: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder2',
            ),
            placeholder3: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder3',
            ),
            placeholder4: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder4',
            ),
            placeholder5: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder5',
            ),
            placeholder6: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder6',
            ),
            note: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.note',
            ),
        }),
        [t],
    );
    const {
        watch,
        setValue,
        formState: { isValid, isDirty },
    } = useFormContext<PrimaryRepInfoForm>();
    const { trackEvent } = useTrackingContext();
    const isPrimaryRep = watch('isPrimaryRepresentative');
    React.useEffect(() => {
        if (isDirty) {
            if (isPrimaryRep && isDirty) {
                setValue('firstName', userDetails.firstName, {
                    shouldValidate: true,
                });
                setValue('lastName', userDetails.lastName, {
                    shouldValidate: true,
                });
                setValue('title', userDetails.title, { shouldValidate: true });
            } else {
                setValue('firstName', '', { shouldValidate: true });
                setValue('lastName', '', { shouldValidate: true });
                setValue('title', '', { shouldValidate: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrimaryRep]);
    React.useImperativeHandle(ref, () => ({
        isEditing: () => isDirty,
    }));
    const clearSsn = React.useCallback(() => {
        setValue('ssn', '', { shouldValidate: true });
    }, [setValue]);
    const trackPrimaryRepClick = React.useCallback(() => {
        trackEvent(
            isPrimaryRep
                ? primaryRepManualEvents.uncheckRepClick
                : primaryRepManualEvents.checkRepClick,
        );
    }, [isPrimaryRep, trackEvent]);
    return (
        <>
            <GivelifyFormCheckbox<PrimaryRepInfoForm>
                aria-label="I am the primary representative"
                id="primary-rep"
                label={copy.placeholder1}
                name="isPrimaryRepresentative"
                onClick={trackPrimaryRepClick}
                rootClass="rep-box"
            />
            <InputsBox>
                <NameFields>
                    <GivelifyFormTextField<PrimaryRepInfoForm>
                        fullWidth
                        aria-label="First name editor"
                        data-testid="firstName"
                        id="admin-form-firstName"
                        label={copy.placeholder2}
                        name="firstName"
                        placeholder={copy.placeholder2}
                        size="large"
                    />
                    <GivelifyFormTextField<PrimaryRepInfoForm>
                        fullWidth
                        aria-label="Last name editor"
                        data-testid="lastName"
                        id="admin-form-lastName"
                        label={copy.placeholder3}
                        name="lastName"
                        placeholder={copy.placeholder3}
                        size="large"
                    />
                </NameFields>
                <GivelifyFormTextField<PrimaryRepInfoForm>
                    fullWidth
                    aria-label="Title editor"
                    data-testid="title"
                    id="admin-form-title"
                    label={copy.placeholder4}
                    name="title"
                    placeholder={copy.placeholder4}
                    size="large"
                />
                <GivelifyFormDatePicker<PrimaryRepInfoForm>
                    fullwidth
                    aria-label="BirthDate editor"
                    id="admin-form-birthDate"
                    label={copy.placeholder5}
                    name="dateOfBirth"
                    placeholder={copy.placeholder5}
                    size="large"
                />
                <SocialNumberInput clearSsn={clearSsn} replace={replace} />
            </InputsBox>
            <Note>
                <GivelifyIcon fontSize={28} variant="lock-check" />
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    variant="body2"
                >
                    {copy.note}
                </GivelifyLabel>
            </Note>
            <PrimaryRepEditorFooter
                canSubmit={isValid}
                onCancelClick={onCancelClick}
            />
        </>
    );
});
