import { BUTTON_IDENTIFIER, CHECKBOX_IDENTIFIER } from '@givelify/utils';

export type PrimaryRepresentativeInfoStatus =
    | 'add'
    | 'update'
    | 'completed'
    | 'success';

export type PrimaryRepInfoForm = {
    isPrimaryRepresentative: boolean;
    firstName: string;
    lastName: string;
    title: string;
    dateOfBirth: Date | null;
    ssn: string;
};

export type PrimaryRepAddressForm = {
    street: string;
    zip: string;
    state: string;
    city: string;
    phoneNumber: string;
};

export const apiHiddenSsnRegex = /^XXXXX\d{4}$/;
export const apiHiddenSsnRegex11Digits = /^XXXXXXX\d{4}$/;

export const primaryRepEditorViews = {
    infoStep: 'Information Step',
    addressStep: 'Address Step',
    summaryStep: 'Summary Step',
    confirmCancellation: 'Confirm cancellation',
    learnMore: 'Learn more',
};

export const primaryRepManualEvents = {
    hideClick: `<hide>_${BUTTON_IDENTIFIER}`,
    showClick: `<show>_${BUTTON_IDENTIFIER}`,
    checkRepClick: `<check_is_primary_rep>_${CHECKBOX_IDENTIFIER}`,
    uncheckRepClick: `<uncheck_is_primary_rep>_${CHECKBOX_IDENTIFIER}`,
};
