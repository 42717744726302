import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const ProfileMobilePreviewStyle = makeStyles((theme: Theme) => {
    return createStyles({
        mobilePrevieContainer: {
            textAlign: 'center',
            overflow: 'visible',
        },
        mobilePreviewCoverSection: {
            height: 210,
            width: 375,
        },
        mobilePreviewCover: {
            position: 'relative',
            top: -1,
            height: 210,
            width: 375,
            objectFit: 'cover',
        },
        mobilePreviewDetailsSection: {
            background: theme.gas.palette.background.primary,
            boxShadow: theme.gas.elevation.high,
            padding: 20,
            paddingBottom: 32,
            position: 'relative',
            '& .profileStatement': {
                fontSize: 14,
                lineHeight: '20px',
                fontWeight: 700,
                marginTop: 12,
                textOverflow: 'ellipsis',
                maxHeight: 85,
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-line-clamp': 3,
                '-webkit-box-orient': 'vertical',
            },
            zIndex: 2,
        },
        mobilePreviewBackArrow: {
            position: 'absolute',
            left: 16,
            top: 'calc(50% - 20.5px/2 - 290.75px)',
            width: 36,
            height: 36,
            background: theme.gas.palette.background.primary,
            boxShadow: '0px 2px 4px rgba(40, 74, 119, 0.16)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobilePreviewShare: {
            position: 'absolute',
            right: 22,
            top: 'calc(50% - 20.5px/2 - 290.75px)',
            width: 36,
            height: 36,
            background: '#FFFFFF',
            boxShadow: '0px 2px 4px rgba(40, 74, 119, 0.16)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobilePreviewOrgSec: {
            display: 'flex',
            marginTop: 44,
        },
        mobilePreviewOrgBadge: {
            marginLeft: 6,
        },
        mobilePreviewTaxTip: {
            fontSize: 12,
            lineHeight: '18px',
            fontWeight: 700,
            color: theme.gas.palette.label.secondary,
            marginTop: 12,
        },
        mobilePreviewButton: {
            background:
                'linear-gradient(98.52deg, #F85520 8.25%, #F85520 132.88%)',
            boxShadow: '0px 2px 4px rgba(249, 86, 32, 0.3)',
            borderRadius: 100,
            height: 52,
            color: theme.gas.palette.button.alt.idle.text,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '24px',
            display: 'flex',
            marginTop: 12,
            fontSize: 16,
            fontWeight: 900,
        },
        mobilePreviewPhoneSec: {
            display: 'flex',
            marginTop: 32,
            marginBottom: 5,
            '& .phoneValue': {
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 600,
                marginLeft: 21.5,
                color: theme.gas.palette.label.primary,
            },
        },
        mobilePreviewLocationSec: {
            display: 'flex',
            marginTop: 16,
            alignItems: 'start',
            '& .phoneValue': {
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 600,
                marginLeft: 21.5,
                color: theme.gas.palette.label.primary,
            },
        },
        mobilePreviewAvatar: {
            position: 'absolute',
            left: 20,
            top: -36,
            width: 72,
            height: 72,
            backgroundSize: 'cover',
            borderRadius: 100,
            background: theme.gas.palette.misc.separator,
            border: '4px solid #FFFFFF',
        },
        mobilePreviewFav: {
            position: 'absolute',
            right: 20,
            top: -14,
            border: '4px solid #FFFFFF',
            borderRadius: 100,
        },
        mobileMap: { position: 'relative', top: -50 },
    });
});
