import React, { useState } from 'react';
import { GivelifyMenu, OptionItem } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';
import {
    MATCH,
    matchSelect,
} from '../../../../../../utils/integrationFormatFilterUtils';
import { MatchOption } from '../../types';

interface MatchSelectProps {
    matchFilter: matchSelect;
    onMatchFilterChange: (value: matchSelect) => void;
    matchOptions: MatchOption[];
}

const MatchSelect: React.FC<MatchSelectProps> = ({
    matchFilter,
    onMatchFilterChange,
}) => {
    const [isMatchOpen, setIsMatchOpen] = useState(false);
    const [matchValue, setMatchValue] = useState<string>(
        matchFilter || MATCH[1],
    );
    const onChangeMatch = values => {
        onMatchFilterChange(values[0] as matchSelect);
        setMatchValue(values[0]);
        setIsMatchOpen(false);
    };
    const defaultValue = MATCH[matchFilter];
    const allMatchOptions: OptionItem[] = MATCH.map(item => ({
        label: item,
        value: item,
    }));
    const renderMatchLabel = values => {
        if (values && values.length > 0) {
            return values[0];
        } else {
            return defaultValue;
        }
    };
    return (
        <Box component="span" style={{ marginRight: 25 }}>
            <GivelifyMenu
                checkMarkVariant="mark"
                id="select-type"
                onChange={onChangeMatch}
                onOpenToggle={setIsMatchOpen}
                open={isMatchOpen}
                options={allMatchOptions}
                renderMenuLabel={renderMatchLabel}
                value={[matchValue]}
            />
        </Box>
    );
};

export default MatchSelect;
