import { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { I18N_NAMESPACE } from '../../../../../../consts';
import { DocumentType } from '../../TaxIdContext';

export const useUploadDocumentTranslation = (documentType: DocumentType) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.taxId.church.uploadDocument`,
    });

    const { scopedTranslate: scopedTranslateByType } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: `captivePortal.taxId.church.uploadDocument.${documentType}`,
    });

    const copy = useMemo(
        () => ({
            delete: scopedTranslate('delete'),
            close: scopedTranslate('close'),
            viewLarger: scopedTranslate('viewLarger'),
            submit: scopedTranslate('submit'),
            locationMayVary: scopedTranslate('locationMayVary'),
            errorSize: scopedTranslate('errorSize'),
            errorExtension: scopedTranslate('errorExtension'),
            labelExtension: scopedTranslate('labelExtension'),
            labelSize: scopedTranslate('labelSize'),
        }),
        [scopedTranslate],
    );

    const text = useMemo(
        () => ({
            notificationText: scopedTranslateByType(`notificationText`),
            heading: scopedTranslateByType(`heading`),
            description: scopedTranslateByType(`description`),
            descriptionMobile1: scopedTranslateByType(`descriptionMobile1`),
            descriptionMobile2: scopedTranslateByType(`descriptionMobile2`),
            descriptionMobile3: scopedTranslateByType(`descriptionMobile3`),
            listItems: [
                scopedTranslateByType(`listItem1`),
                scopedTranslateByType(`listItem2`),
            ].filter((i) => i),
            buttonText: scopedTranslateByType(`buttonText`),
            imageAlt: scopedTranslateByType(`imageAlt`),
            documentType: scopedTranslateByType(`documentType`),
            informationHeader: scopedTranslateByType(`informationHeader`),
            exampleModalHeader: scopedTranslateByType(`exampleModalHeader`),
            uploadedModalHeader: scopedTranslateByType(`uploadedModalHeader`),
        }),
        [scopedTranslateByType],
    );

    return { copy, text };
};
