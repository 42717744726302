import axios, { AxiosPromise, AxiosResponse, Method } from 'axios';
import { formatMilliseconds } from '../../dateUtils';
import { log } from '../../logger/logger';
import { CustomField } from '../../logger/models';
import { ApiResponse, FailApiResponse } from '../responses';

type RequestParams = {
    logData?: { [key: string]: CustomField };
    shouldLogRequest?: boolean;
};

const logRequest = (
    {
        method,
        duration,
        status,
        url,
    }: {
        method?: Method | string;
        duration?: number;
        status?: number;
        url?: string;
    },
    data: { [key: string]: CustomField } | undefined,
    errorMessage?: string,
) => {
    const methodToLog = (method || '<Unknown>').toUpperCase();
    const responseTimeToLog = duration
        ? formatMilliseconds(duration)
        : '<Unknown>';
    const statusToLog = status || '<Unknown>';
    let messageToLog = `${statusToLog} ${methodToLog} /${url} response time: ${responseTimeToLog}.`;

    if (errorMessage) {
        messageToLog = `${messageToLog} Error: ${errorMessage}`;
    }

    return log(messageToLog, data);
};

export const makeApiRequest = async <T>(
    httpPromise: AxiosPromise,
    params?: RequestParams,
): Promise<ApiResponse<T>> => {
    let result: ApiResponse<T>;
    let response: AxiosResponse<unknown>;

    try {
        response = await httpPromise;

        if (
            response.status === 200 ||
            response.status === 204 ||
            response.status === 201
        ) {
            const body = response.data as T;
            result = {
                success: true,
                response: body,
            };
        } else {
            //eslint-disable-next-line
            const resp = response as any;
            const message = resp
                ? resp.data
                    ? resp.data.message
                    : ''
                : resp.statusText;
            result = {
                success: false,
                error: {
                    isAxiosError: false,
                    status: response.status,
                    errors: resp?.data?.errors,
                    message: message,
                    name: response.status.toString(),
                },
            };
        }
    } catch (err) {
        //eslint-disable-next-line
        const error = err as any;
        response = error;

        if (error.response) {
            const resp = error.response;
            const message = resp
                ? resp.data
                    ? resp.data.message
                    : ''
                : resp.statusText;
            result = {
                success: false,
                error: {
                    isAxiosError: false,
                    status: error.response.status,
                    message: message,
                    errors: resp?.data?.errors,
                    name: error.response.status.toString(),
                },
            };
        } else if (axios.isCancel(error)) {
            result = {
                success: false,
                error: {
                    isAxiosError: true,
                    status: 499,
                    message: 'Request cancelled',
                    name: '499',
                },
            };
        } else {
            result = {
                success: false,
                error: {
                    isAxiosError: true,
                    status: 500,
                    message: 'ERR_CONNECTION_REFUSED. Possible network problem',
                    name: '500',
                },
            };
        }
    }

    if (params?.shouldLogRequest) {
        void logRequest(
            {
                duration: response.duration,
                method: response.config?.method as Method,
                status:
                    response.status ||
                    (result as FailApiResponse).error?.status,
                url: response.config?.url,
            },
            params?.logData,
            result?.success ? undefined : result?.error?.message,
        );
    }

    return result;
};
