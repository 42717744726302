import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    TooltipProps,
    Legend,
} from 'recharts';
import { formatMoneyWithKAndM } from 'utils/formatMoneyWithCommas';
import { colorsList } from '../components/styles';
import { NameType, ValueType } from '../PieCharts/components/PieChartWrapper';

const style = {
    backgroundColor: '#4a4a4a',
    color: '#fefefe',
    padding: '15px 20px 5px 20px',
    borderRadius: 10,
    fontSize: '14px',
};

const formatterYAxis = (value: string) => `$${formatMoneyWithKAndM(value)}`;

//eslint-disable-next-line
interface StackedBarChartProps<T extends object> {
    data: T[];
    dataKey: string;
    keys: { id: string; title: string }[];
}

const margin = { top: 20, right: 30, left: 20, bottom: 5 };

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
    mapIdToTitle: Record<string, unknown>;
}

const CustomizedTooltip: React.FC<CustomTooltipProps> = ({
    label,
    payload,
    active,
    mapIdToTitle,
}) => {
    if (!active || !label || !payload || payload.length === 0) return null;

    return (
        <div style={style}>
            <Typography variant="subtitle2">{label}</Typography>
            {payload.map((p, i) => {
                const title = mapIdToTitle[p.name];
                const value = formatMoneyWithKAndM(
                    // we are passing the values as string, so its safe to assume here
                    p.value as string,
                );
                const text = `${title}: $${value}`;
                return <p key={i}>{text}</p>;
            })}
        </div>
    );
};
//eslint-disable-next-line
const StackedBarChart = <T extends object>({
    data,
    dataKey,
    keys,
}: StackedBarChartProps<T>) => {
    const mapIdToTitle = React.useMemo(() => {
        const mapIdToTitle = {};
        keys.forEach((key) => {
            mapIdToTitle[key.id] = key.title;
        });
        return mapIdToTitle;
    }, [keys]);

    const [enableTooltip, setEnableTooltip] = React.useState(false);
    const onBarMouseEnter = React.useCallback(() => {
        setEnableTooltip(true);
    }, []);
    const onBarMouseLeave = React.useCallback(() => {
        setEnableTooltip(false);
    }, []);

    return (
        <ResponsiveContainer aspect={1} maxHeight={430} width="99%">
            <BarChart data={data} margin={margin}>
                <XAxis axisLine={false} dataKey={dataKey} tickLine={false} />
                <YAxis
                    axisLine={false}
                    tickFormatter={formatterYAxis}
                    tickLine={false}
                />
                <CartesianGrid stroke="#f5f5f6" vertical={false} />
                <Tooltip
                    animationDuration={250}
                    content={<CustomizedTooltip mapIdToTitle={mapIdToTitle} />}
                    cursor={false}
                    wrapperStyle={{ display: enableTooltip ? 'block' : 'none' }}
                />
                <Legend
                    formatter={(id) => mapIdToTitle[id]}
                    iconSize={8}
                    iconType="circle"
                />
                {keys.map((key, index) => (
                    <Bar
                        key={index}
                        dataKey={key.id}
                        fill={colorsList[index % colorsList.length]}
                        onMouseEnter={onBarMouseEnter}
                        onMouseLeave={onBarMouseLeave}
                        stackId={1}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default StackedBarChart;
