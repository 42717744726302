import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

export const CardCompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'relative',
            minHeight: (props: { width: number }) =>
                props.width > 742 ? 320 : 429,
            width: (props: { width: number }) => props.width,
            backgroundColor: theme.colors?.primaryWhite,
            boxShadow: '0px 0px 8px rgba(131, 131, 131, 0.4)',
            borderRadius: 10,
            overflow: 'hidden',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            '& .header': {
                margin: 16,
                marginBottom: 0,
                height: 18,
                display: 'flex',
                alignItems: 'center',
                '& .label': {
                    marginLeft: 'auto',
                },
            },
            '& .content': {
                flex: 1,
                display: 'flex',
                '& .misc': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                },
                '& a:link': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
                '& a:hover': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.main,
                    textDecoration: 'underline',
                },
                '& a:active': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.dark,
                    textDecoration: 'underline',
                },
                '& a:visited': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
            },
            '& .content-lg': {
                display: 'flex',
                padding: 32,
                paddingTop: 14,
                flexDirection: 'row',
                '& .main': {
                    maxWidth: 617,
                    '& .description': {
                        marginTop: 24,
                    },
                    '& .complete': {
                        width: 'auto',
                        minWidth: 207,
                    },
                    '& .complete-sm-margin': {
                        marginTop: 32,
                    },
                    '& .complete-lg-margin': {
                        marginTop: 76,
                    },
                },
            } as CSSProperties,
            '& .content-xs': {
                display: 'flex',
                padding: 16,
                paddingTop: 14,
                flexDirection: 'column-reverse',
                '& .main': {
                    maxWidth: 'initial',
                    marginTop: 32,
                    '& .description': {
                        marginTop: 24,
                        marginBottom: 24,
                    },
                    '& .complete': {
                        width: '100%',
                    },
                },
            } as CSSProperties,
            '& .review-bar': {
                minHeight: 54,
                backgroundColor: theme.colors?.warning25,
                display: 'flex',
                alignItems: 'center',
                fontSize: 16,
                fontFamily: 'Roboto',
                '& .review-icon': {
                    marginRight: 15,
                    color: theme.colors?.warning,
                },
                '& .review-link': {
                    fontFamily: 'Roboto',
                    color: theme.colors?.primary || theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                        color:
                            theme.colors?.primary || theme.palette.primary.main,
                        textDecoration: 'underline',
                    },
                    '&:active': {
                        color:
                            theme.colors?.accentDarkBlue ||
                            theme.palette.primary.dark,
                        textDecoration: 'underline',
                    },
                },
                paddingLeft: 32,
                paddingRight: 32,
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: 16,
                    paddingRight: 16,
                },
            },
            '& .complete-bar': {
                minHeight: 54,
                backgroundColor: theme.colors?.accentBlue25,
                display: 'flex',
                alignItems: 'center',
                fontSize: 16,
                fontFamily: 'Roboto',
                paddingLeft: 32,
                paddingRight: 32,
                [theme.breakpoints.down('xs')]: {
                    padding: 16,
                },
                '& .complete-link': {
                    marginLeft: 3.5,
                    '& a:link': {
                        color:
                            theme.colors?.primary || theme.palette.primary.main,
                        textDecoration: 'none',
                    },
                    '& a:hover': {
                        color:
                            theme.colors?.accentDarkBlue ||
                            theme.palette.primary.main,
                        textDecoration: 'underline',
                    },
                    '& a:active': {
                        color:
                            theme.colors?.accentDarkBlue ||
                            theme.palette.primary.dark,
                        textDecoration: 'underline',
                    },
                    '& a:visited': {
                        color:
                            theme.colors?.primary || theme.palette.primary.main,
                        textDecoration: 'none',
                    },
                },
            },
        },
        '@keyframes dismiss': {
            from: {
                transform: 'scaleY(1)',
            },
            to: {
                transform: 'scaleY(0)',
            },
        },
        cardDismissal: {
            animationName: '$dismiss',
            animationDuration: '250ms',
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
            transformOrigin: 'center center',
        },
    }),
);
