import React, { useMemo } from 'react';
import { GivelifyLabel, GivelifyModal, GivelifyButton } from '@givelify/ui';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { invitationModalsStyles } from './invitationModalsStyles';

interface ResendInvitationModalProps {
    open: boolean;
    onClose: () => void;
    email: string;
    onSend: () => void;
    handleEmailChange: (email: string) => void;
    emailError: boolean;
}

const ResendInvitationModal: React.FC<ResendInvitationModalProps> = props => {
    const { onClose, open, email, onSend } = props;
    const styles = invitationModalsStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            resendInvitation: t('pages.settings.users.resendInvitation'),
            cancel: t('labels.cancel'),
            send: t('labels.send'),
            email: t('pages.settings.users.add-new-modal.form.email'),
            sendNewActivation: t('pages.settings.users.sendNewActivation', {
                emailAddress: email,
            }),
        }),
        [t, email],
    );
    return (
        <GivelifyModal showCloseButton maxWidth='mobile' onClose={onClose} open={open}>
            <div className={styles.modalContent}>
                <GivelifyLabel
                    className={styles.header}
                    text={copy.resendInvitation}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={styles.description}
                    color="neutralGrey"
                    text={copy.sendNewActivation}
                    variant="body1"
                />
            </div>
            <Grid
                container
                className={styles.actions}
                justifyContent="flex-end"
                spacing={2}
            >
                <Grid item>
                    <GivelifyButton
                        onClick={onClose}
                        size="medium"
                        text={copy.cancel}
                    />
                </Grid>
                <Grid item>
                    <GivelifyButton
                        onClick={onSend}
                        size="medium"
                        text={copy.send}
                        variant="primary"
                    />
                </Grid>
            </Grid>
        </GivelifyModal>
    );
};

export default ResendInvitationModal;
