import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
import { WidgetHeader } from './WidgetHeader';
import { WidgetLoadingIconProps } from './WidgetLoadingIcon';

type WidgetPaperProps = {
    width?: string;
    height?: string;
    className?: string;
};

const WidgetPaper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})<WidgetPaperProps>((props) => ({
    backgroundColor: DesignTokens.color.backgroundSecondary,
    width: props.width ? props.width : '328px',
    height: props.height ? props.height : '366px',
    borderRadius: '12px',
    overflow: 'hidden',
    paddingTop: '21px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '8px',
}));

export type WidgetBaseProps = {
    id: string;
    title: string;
    description: string;
    iconProps: Omit<WidgetLoadingIconProps, 'loading'>;
    loading?: boolean;
    paperProps?: WidgetPaperProps;
    children: React.ReactNode;
};

export const WidgetBase: React.FC<WidgetBaseProps> = ({
    id,
    title,
    description,
    iconProps,
    loading,
    paperProps,
    children,
}) => {
    return (
        <WidgetPaper data-testid={id} id={id} {...paperProps}>
            <WidgetHeader
                description={description}
                iconProps={iconProps}
                id={id}
                loading={loading}
                title={title}
            />
            {loading ? null : (
                <div
                    className="wg-content"
                    data-testid={`${id}-content`}
                    id={`${id}-content`}
                >
                    {children}
                </div>
            )}
        </WidgetPaper>
    );
};
