import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { legacyReportStyles } from 'pages/reports/LegacyReports/components/style';

interface LegacyReportInfoProps {
    icon: JSX.Element | string;
    name: string;
    description: string;
}

const LegacyReportInfo: React.FC<LegacyReportInfoProps> = (props) => {
    const legacyStyles = legacyReportStyles();

    return (
        <Grid
            container
            className={legacyStyles.reportInfoHeader}
            spacing={2}
            wrap="nowrap"
        >
            {props.icon}
            <Grid item xs="auto">
                <GivelifyLabel
                    fontWeight={700}
                    marginBottom={0}
                    text={props.name}
                    variant="heading3"
                />
                <GivelifyLabel
                    fontWeight={600}
                    text={props.description}
                    variant="body1"
                />
            </Grid>
        </Grid>
    );
};

export default LegacyReportInfo;
