import { DesignTokens, GivelifyTab } from '@givelify/ui';
import { styled } from '@mui/material';

export const ResponsiveTab = styled(GivelifyTab)(({ theme }) => ({
    '& .tab-label': {
        fontSize: DesignTokens.textStyle.globalBody1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
        [theme.breakpoints.down('mobile')]: {
            fontSize: DesignTokens.textStyle.globalCaption1.font.size,
        },
    },
    marginBottom: '32px',
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '16px',
    },
}));
