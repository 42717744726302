import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyCloseButton,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { useTranslation } from '../utils/i18n';
import { givelithonModalStyle } from './style';

interface GivelithonWorkModalProps {
    open: boolean;
    onClose: () => void;
}

export const GivelithonWorkModal: React.FC<GivelithonWorkModalProps> = (
    props,
) => {
    const classes = givelithonModalStyle();
    const { t, at } = useTranslation();
    const copy = useMemo(
        () => ({
            howWork: t('givelithonModal.howWork'),
            info: t('givelithonModal.info'),
            step1: at('givelithonModal.step1'),
            step2: t('givelithonModal.step2'),
            step3: t('givelithonModal.step3'),
            ok: t('givelithonButton.ok'),
        }),
        [t, at],
    );

    return (
        <GivelifyModal
            autoFullscreen
            onClose={props.onClose}
            open={props.open}
            width={830}
        >
            <div
                className={classes.modalContent}
                data-testid="givelithon-work-modal"
            >
                <div className={classes.modalTitle}>
                    <GivelifyCloseButton
                        className={classes.closeModal}
                        onClick={props.onClose}
                    />
                    <GivelifyLabel
                        color="primary"
                        text={copy.howWork}
                        variant="heading3"
                    />
                    <GivelifyLabel
                        marginTop={24}
                        text={copy.info}
                        variant="heading3"
                    />
                </div>
                <div className={classes.info}>
                    <ol className={classes.workSteps}>
                        <li className={classes.step}>
                            <GivelifyLabel text={copy.step1} />
                        </li>
                        <li className={classes.step}>
                            <GivelifyLabel text={copy.step2} />
                        </li>
                        <li className={classes.step}>
                            <GivelifyLabel text={copy.step3} />
                        </li>
                    </ol>
                    <div className={classes.doneButtonGroup}>
                        <GivelifyButton
                            data-testid="givelithon-work-modal-ok-button"
                            onClick={props.onClose}
                            size="large"
                            text={copy.ok}
                            variant="primary"
                        />
                    </div>
                </div>
            </div>
        </GivelifyModal>
    );
};
