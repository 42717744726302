import React from 'react';
import { DesignTokens, GivelifyFlagIcon, GivelifyLabel } from '@givelify/ui';
import { Tooltip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const countryInfos: {
    [x: string]: {
        currency: string;
        name: string;
        lang: string;
        tooltipI18nKey?: string;
    };
} = {
    US: {
        currency: '$',
        name: 'United States',
        lang: 'English',
    },
    BHS: {
        currency: 'B$',
        name: 'Bahamas',
        tooltipI18nKey: 'internationalization.bahamas.currencyTooltip',
        lang: 'English',
    },
};

const CurrencyTooltip = styled(Tooltip)({
    display: 'flex',
});

const FlagWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
        width: '32px',
        height: '32px',
        [theme.breakpoints.down('mobile')]: {
            width: '24px',
            height: '24px',
        },
    },
}));

const CurrencyTooltipWrapper = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('mobile')]: {
        marginTop: '16px',
    },
}));

interface Props {
    country: string;
    renderTooltip?: boolean | (() => string);
    renderLabel?: boolean;
}

const CurrencyAnnotationComponent: React.FC<Props> = ({
    renderTooltip = true,
    country,
    renderLabel = false,
}) => {
    const { t } = useTranslation();
    const countryInfo = countryInfos[country];

    const getFlagText = React.useCallback((): string => {
        if (
            (renderTooltip === true || renderLabel) &&
            countryInfo.tooltipI18nKey
        ) {
            return t(countryInfo.tooltipI18nKey);
        } else if (renderTooltip && typeof renderTooltip === 'function') {
            return renderTooltip();
        }
        return '';
    }, [renderTooltip, renderLabel, countryInfo, t]);

    if (!countryInfo) return null;

    return (
        country === 'BHS' && (
            <CurrencyTooltipWrapper data-testid="currency-tooltip-wrapper">
                <CurrencyTooltip
                    arrow
                    aria-label={`Flag of ${countryInfo.name}`}
                    componentsProps={{
                        arrow: {
                            sx: {
                                color: DesignTokens.color
                                    .backgroundComponentToolTip,
                            },
                        },
                        tooltip: {
                            sx: {
                                backgroundColor:
                                    DesignTokens.color
                                        .backgroundComponentToolTip,
                                width: '150px',
                                padding: '8px',
                                textAlign: 'center',
                            },
                        },
                    }}
                    title={Boolean(renderTooltip) && getFlagText()}
                >
                    <div>
                        <FlagWrapper data-testid="flag-wrapper">
                            <GivelifyFlagIcon country={country} />
                        </FlagWrapper>
                        {renderLabel ? (
                            <GivelifyLabel
                                color={DesignTokens.color.textSecondary}
                                marginLeft="8px"
                                maxWidth="105px"
                                text={getFlagText()}
                                variant="componentLabel"
                            />
                        ) : null}
                    </div>
                </CurrencyTooltip>
            </CurrencyTooltipWrapper>
        )
    );
};

export const CurrencyAnnotation = React.memo(CurrencyAnnotationComponent);
