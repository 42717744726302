import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { mergeClassNames } from '@givelify/givelify-ui';
import { ImageWithDimensions } from '../@types/assets/onboarding';
import { ProfileMobilePreview } from '../mobilePreviews/ProfileMobilePreview';
import { CustomizeProfileRef } from './CustomizeProfileRef';
import useCustomizeProfileStyles from './CustomizeProfileStepOne/styles';
import { CustomizeProfileStepOneProps } from './CustomizeProfileStepOne/types';
import MissionStatement from './MissionStatement';
import { OrganizationLogoSection } from './OrganizationLogoSection';

export interface CustomizeProfileStepTwoProps
    extends CustomizeProfileStepOneProps {
    organizationName: string;
    onMobilePreviewRequest: () => void;
    className?: string;
}

const CustomizeProfileStepTwoComponent = (
    {
        organizationName,
        appProfile,
        organizationType,
        onMobilePreviewRequest,
        className,
    }: CustomizeProfileStepTwoProps,
    forwardRef: React.ForwardedRef<CustomizeProfileRef>,
) => {
    const { profileStyles } = useCustomizeProfileStyles();
    const [organizationLogo, setOrganizationLogo] = useState<
        ImageWithDimensions | undefined
    >(appProfile.organizationLogo);
    const [missionStatement, setMissionStatement] = useState<string>(
        appProfile.missionStatement,
    );

    useImperativeHandle(forwardRef, () => ({
        submit: () => ({
            ...appProfile,
            missionStatement,
            organizationLogo,
        }),
        refreshData: () => {
            /* do nothing */
        },
    }));

    return (
        <div className={mergeClassNames(profileStyles, className)}>
            <div className="form-section">
                <MissionStatement
                    missionStatement={missionStatement}
                    onChange={setMissionStatement}
                    onMobilePreviewRequest={onMobilePreviewRequest}
                />
                <OrganizationLogoSection
                    organizationAddress={appProfile.organizationAddress}
                    organizationLogo={organizationLogo}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    setOrganizationLogo={setOrganizationLogo}
                />
            </div>
            <div className="image-section">
                <ProfileMobilePreview
                    coverUrl={appProfile.bannerImage?.croppedUrl}
                    fullAddress={appProfile.organizationAddress}
                    isNonprofit={organizationType === 'nonprofit'}
                    organizationName={organizationName}
                    profileStatement={missionStatement}
                    representativeAvatar={
                        appProfile.faithLeaderImage?.croppedUrl
                    }
                />
            </div>
        </div>
    );
};

export const CustomizeProfileStepTwo = forwardRef<
    CustomizeProfileRef,
    CustomizeProfileStepTwoProps
>(CustomizeProfileStepTwoComponent);
