import React, { useMemo, useEffect, useState, useCallback } from 'react';
import {
    Donee,
    GivelifyInput,
    GivelifyLabel,
    isFailed,
} from '@givelify/givelify-ui';
import { ProfileMobilePreview } from '@givelify/onboarding';
import { Box } from '@material-ui/core';
import { ErrorText } from 'components/ErrorText';
import permissionTypes from 'constants/permissionTypes';
import { useInvokeApi, InvokeApiErrorResponse } from 'hooks/useInvokeApi';
import { replace } from 'lodash';
import { EditOption } from 'pages/settings/components/EditOption';
import SaveCancelButtons from 'pages/settings/components/SaveCancelButtons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { setDoneeMissionStatement } from 'store/donee/actions';
import permissionsByPath from 'utils/permissionsByPath';
import { missionStyle } from './style';

const updateEndpoint = '/donees/{doneeId}';

const MissionStatementPage: React.FunctionComponent = () => {
    const {
        missionContainer,
        missionEditorSide,
        missionTitleContainer,
        missionTipContainer,
        missionTip,
        missionDescriptionHolder,
        missionExample,
        missionEditor,
        missionPreviewSide,
        missionExampleCaptionClosed,
    } = missionStyle();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            yourStatement: t('pages.settings.mission-statement.your-statement'),
            addStatement: t('pages.settings.mission-statement.add-statement'),
            description: t('pages.settings.mission-statement.description'),
            description2: t('pages.settings.mission-statement.description2'),
            forExample: t('pages.settings.mission-statement.for-example'),
            example: t('pages.settings.mission-statement.example'),
            inputPlaceholder: t(
                'pages.settings.mission-statement.input-placeholder',
            ),
            edit: t('pages.settings.mission-statement.add-edit'),
            tip: t('labels.tip'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );
    const {
        doneeId,
        missionStatement,
        organizationName,
        organizationType,
        phoneNumber,
        fullAddress,
        coverUrl,
        representativeName,
        representativeAvatar,
        user,
    } = useSelector((state: AppState) => {
        return {
            user: state.User.user,
            doneeId: state.Donee.donee.id,
            organizationName: state.Donee.donee.name,
            organizationType: state.Donee.donee.type,
            missionStatement: state.Donee.donee.missionStatement || '',
            phoneNumber: state.Donee.donee.phone,
            fullAddress: {
                street: state.Donee.donee.address,
                city: state.Donee.donee.city,
                state: state.Donee.donee.state,
                zip: state.Donee.donee.zip,
            },
            coverUrl: state.Donee.donee.photo,
            representativeName: '',
            representativeAvatar:
                state.Donee.donee.onboarding.appProfile.faithLeader.avatar,
        };
    });

    const [missionText, setMissionText] = useState(missionStatement);
    const handleChange = (value: string) => {
        if (value.length > 200) {
            value = value.substring(0, 200);
        }
        setMissionText(value);
    };
    const dispatch = useDispatch();
    const [doneeRequestState, makeDoneeRequest] = useInvokeApi<Donee>(true);
    const [editorEnabled, setEditorEnabled] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const onEditClick = () => {
        setEditorEnabled(true);
    };
    const onEditCancel = useCallback(() => {
        setEditorEnabled(false);
        setSaveError(undefined);
        setMissionText(missionStatement);
    }, [missionStatement]);
    const onEditSubmit = async () => {
        setSaveError(undefined);
        await makeDoneeRequest(
            'PATCH',
            replace(updateEndpoint, '{doneeId}', doneeId.toString()),
            {
                mission_statement: missionText,
            },
        );
        dispatch(setDoneeMissionStatement(doneeId, missionText, new Date()));
    };

    useEffect(() => {
        onEditCancel();
    }, [missionStatement, onEditCancel]);

    useEffect(() => {
        if (doneeRequestState.type === 'REQUEST_SUCCESS') {
            dispatch(
                setDoneeMissionStatement(
                    doneeId,
                    doneeRequestState.data.onboarding.appProfile
                        .missionStatement,
                    new Date(doneeRequestState.data.updatedAt),
                ),
            );
            setEditorEnabled(false);
            setSaveError(undefined);
        } else if (isFailed(doneeRequestState)) {
            setSaveError(
                (doneeRequestState as InvokeApiErrorResponse).error.message,
            );
        }
        // eslint-disable-next-line
    }, [doneeRequestState, dispatch]);

    const isLoading = doneeRequestState.type === 'REQUEST_START';

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.MISSION_STATEMENT][user?.role] ===
        permissionTypes.FULL_ACCESS;

    return (
        <div className={missionContainer}>
            <div className={missionEditorSide}>
                <div className={missionTitleContainer}>
                    <GivelifyLabel
                        text={
                            editorEnabled
                                ? copy.addStatement
                                : copy.yourStatement
                        }
                        variant="heading4"
                    />
                    {!editorEnabled && hasFullAccess ? (
                        <EditOption
                            aria-label="Edit mission statement"
                            onClick={onEditClick}
                            testId="add-edit-button"
                            text={copy.edit}
                        />
                    ) : null}
                </div>
                {!editorEnabled && (
                    <GivelifyLabel
                        wrap
                        id="mission-description"
                        overflowWrap={'break-word'}
                        text={missionText ? missionText : copy.description}
                        variant="body2"
                    />
                )}
                {editorEnabled ? (
                    <Box
                        className={missionEditor}
                        data-testid="mission-statement-editor"
                    >
                        <GivelifyInput
                            fullWidth
                            helperTextRight
                            multiline
                            ariaLabel="mission statement editor"
                            helperText={`${missionText.length}/200`}
                            id="add-mission-statement"
                            inputLabel="Add mission statement"
                            onChange={handleChange}
                            placeholder={copy.inputPlaceholder}
                            rows={5}
                            value={missionText}
                        />
                    </Box>
                ) : null}
                <div className={missionTipContainer}>
                    <GivelifyLabel
                        className={missionTip}
                        text={copy.tip}
                        variant="caption2"
                    />
                    <div className={missionDescriptionHolder}>
                        <GivelifyLabel
                            wrap
                            text={copy.description2}
                            variant="body2"
                        />
                        <GivelifyLabel
                            marginTop={20}
                            text={copy.forExample}
                            variant="heading5"
                        />
                        <div className={missionExample}>
                            {copy.example}
                            <img
                                alt="close"
                                className={missionExampleCaptionClosed}
                                src="/caption-close.svg"
                            />
                        </div>
                    </div>
                </div>
                {editorEnabled && (
                    <div>
                        {saveError && <ErrorText text={saveError} />}
                        <SaveCancelButtons
                            cancelDisabled={isLoading}
                            onCancel={onEditCancel}
                            onSave={onEditSubmit}
                            saving={isLoading}
                            submitDisabled={
                                isLoading || missionText === missionStatement
                            }
                        />
                    </div>
                )}
            </div>
            <div className={missionPreviewSide}>
                <ProfileMobilePreview
                    coverUrl={coverUrl}
                    fullAddress={{ ...fullAddress, phone: phoneNumber }}
                    isNonprofit={organizationType === 'nonprofit'}
                    organizationName={organizationName}
                    profileStatement={missionText}
                    representativeAvatar={representativeAvatar}
                    representativeName={representativeName}
                />
            </div>
        </div>
    );
};

export default MissionStatementPage;
