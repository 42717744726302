import * as React from 'react';
import { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    submitButtonsHolderSingle: {
        marginTop: 16,
        textAlign: 'right',
        float: 'right',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 72,
            padding: 16,
            justifyContent: 'center',
            boxShadow: '0 0 8px rgba(58, 76, 99, 0.16)',
            background: '#FFFFFF',
        },
    },
}));

interface SaveCancelButtonsProps {
    onCancel?: () => void;
    onSave?: () => void;
    saving?: boolean;
    submitDisabled?: boolean;
    cancelDisabled?: boolean;
}

export const SaveCancelButtons: React.FunctionComponent<SaveCancelButtonsProps> =
    (props) => {
        const { submitButtonsHolderSingle } = useStyles();
        const { t } = useTranslation();
        const copy = useMemo(
            () => ({
                save: t('labels.save'),
                cancel: t('labels.cancel'),
            }),
            [t],
        );

        return (
            <div className={submitButtonsHolderSingle}>
                <GivelifyButton
                    disabled={props.cancelDisabled}
                    marginRight={12}
                    name="cancel"
                    onClick={props.onCancel}
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    disabled={props.submitDisabled}
                    isLoading={props.saving}
                    marginLeft={12}
                    name="save"
                    onClick={props.onSave}
                    text={copy.save}
                    variant="primary"
                />
            </div>
        );
    };
