import { DesignTokens, GivelifyPill } from '@givelify/ui';
import { styled } from '@mui/material';

export const FilterPillsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: '7px',
    [theme.breakpoints.down('tablet')]: {
        marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.down('mobile')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        flexWrap: 'nowrap',
        overflowX: 'scroll',
        overflowY: 'hidden',
        width: `calc(100vw - ${theme.spacing(4)})`,
        height: '24px',
        alignItems: 'initial',
        justifyContent: 'initial',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));

export const StyledPill = styled(GivelifyPill)(({ theme }) => ({
    width: 'initial',
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
        marginTop: theme.spacing(0.5),
        '& .pill-label': {
            fontSize: DesignTokens.textStyle.globalCaption1.font.size,
        },
    },
    [theme.breakpoints.down('mobile')]: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        '& .pill-label': {
            fontSize: '10px',
        },
    },
}));
