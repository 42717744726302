import { Reducer } from 'redux';
import {
    ReportStoreState,
    ReportActions,
    SET_REPORT_HISTORY_TOTAL_DONATION,
    SET_INTEGRATION_MATCHING_PROGRESS,
} from './types';

export const initialReportState: ReportStoreState = {
    reportHistory: [],
    reportTotalDonationSinceLastExport: 0,
    matchingProgress: {
        total_donors: 0,
        total_matched_donors: 0,
        total_envelopes: 0,
        total_matched_envelopes: 0,
    },
};

const ReportReducer: Reducer<ReportStoreState, ReportActions> = (
    state = initialReportState,
    action,
) => {
    switch (action.type) {
        case SET_REPORT_HISTORY_TOTAL_DONATION:
            return {
                ...state,
                reportTotalDonationSinceLastExport: action.count,
            };
        case SET_INTEGRATION_MATCHING_PROGRESS:
            return {
                ...state,
                matchingProgress: action.data,
            };
        default:
            return state;
    }
};

export default ReportReducer;
