import { emailRegex } from '@givelify/givelify-ui';
export const onlyNumberExp = /^\d+$/;
export function containsOnlyNumbers(value: string): boolean {
    const matches = onlyNumberExp.exec(value);
    if (matches) return true;
    return false;
}
export function lengthBetween1To55(value: string): boolean {
    if (value === null || value === 'undefined') {
        return false;
    }
    return value.length < 1 || value.length > 55;
}
export function lengthBetween1To25(value: string): boolean {
    if (value === null || value === 'undefined') {
        return false;
    }
    return value.length < 1 || value.length > 25;
}
export function validateEmailAddress(value: string): boolean {
    if (value === null || value === 'undefined') {
        return false;
    }
    return !emailRegex.test(value);
}
