import React from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    DesignTokens,
    GivelifyLabel,
    GivelifyNotificationProps,
} from '@givelify/ui';
import {
    LINK_IDENTIFIER,
    PAGE_NAME,
    useTrackingContext,
} from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import RoleTypes from '../../../constants/roleTypes';
import { NotificationContext } from '../notificationContext';

const ADD_PRIMARY_REP_DEADLINE = '2024-10-01';

export const NotificationProvider: React.FC = ({ children }) => {
    const { trackEvent } = useTrackingContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { PATH: GAS_ROUTER_PATH } = useGasRouterContext();
    const { onboardingNotification, showCaptivePortal } =
        useCaptivePortalContext();
    const [notification, setNotification] =
        React.useState<GivelifyNotificationProps | null>(null);
    const notificationRef = React.useRef<HTMLDivElement>(null);
    const [notificationHeight, setNotificationHeight] = React.useState(0);
    const {
        newEscalatedPrimaryRepVerificationEnabled,
        newSeverePrimaryRepVerificationEnabled,
        donee,
        mainDoneeId,
        user,
    } = useSelector((state: AppState) => ({
        newOverviewEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.OVERVIEW_NEW_EXPERIENCE,
            false,
        ),
        newPrimaryRepPageEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_PRIMARY_REPRESENTATIVE_PAGE,
            false,
        ),
        newEscalatedPrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
            false,
        ),
        newSeverePrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_SEVERE_NOTIFICATION,
            false,
        ),
        newDonorDetailsPageEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_DONOR_DETAILS_PAGE,
            false,
        ),
        donee: state.Donee.donee,
        mainDoneeId: state.Donee.campuses ? state.Donee.campuses[0].id : null,
        user: state.User.user,
    }));

    const openNotification = React.useCallback(
        (content: GivelifyNotificationProps) => {
            setNotification(content);
        },
        [],
    );
    const closeNotification = React.useCallback(
        (_, reason: SnackbarCloseReason) => {
            if (reason !== 'clickaway') {
                setNotification(null);
            }
        },
        [],
    );
    React.useEffect(() => {
        if (!showCaptivePortal && onboardingNotification) {
            openNotification(onboardingNotification);
        } else {
            openNotification(null);
        }
    }, [showCaptivePortal, openNotification, onboardingNotification]);
    // Escalated Primary Rep Verification Notification
    React.useEffect(() => {
        if (
            !newEscalatedPrimaryRepVerificationEnabled &&
            !newSeverePrimaryRepVerificationEnabled
        )
            return;
        const sever = newSeverePrimaryRepVerificationEnabled;
        const enabledLocationAdmin =
            location.pathname !== PATH.SETTINGS.BANK_INFO ||
            !location.search.includes('rep');
        const enabledLocationNonAdmin =
            location.pathname !== PATH.SETTINGS.USERS;
        const isAdmin = user.role === RoleTypes.ADMIN;
        const isBasicOrFinancial =
            user.role === RoleTypes.BASIC || user.role === RoleTypes.FINANCIAL;
        const validUser =
            mainDoneeId !== null &&
            donee.id === mainDoneeId &&
            (isAdmin || isBasicOrFinancial);
        const hasMid = donee.onboarding.hasMid;
        const hasPrimaryRep =
            donee.onboarding.primaryRepresentative &&
            donee.onboarding.primaryRepresentative.firstName;
        if (
            (isAdmin || isBasicOrFinancial) &&
            validUser &&
            hasMid &&
            !hasPrimaryRep
        ) {
            if (
                !notification ||
                notification.id === 'primaryRepVerificationEscalated'
            ) {
                trackEvent(
                    isAdmin
                        ? PAGE_NAME.PrimaryRepresentativeNotification
                        : PAGE_NAME.PrimaryRepresentativeNonAdminNotification,
                    { page: location.pathname },
                    'view',
                );
                const adminPrefix = isAdmin ? 'admin' : 'basic';
                const severityPrefix = sever ? 'severe' : 'warning';
                const title = sever ? (
                    <GivelifyLabel variant="body2B">
                        <GivelifyLabel
                            color={DesignTokens.color.textErrorDefault}
                            variant="body2B"
                        >
                            {t(
                                `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title1`,
                                {
                                    DAYS: dayjs
                                        .tz(ADD_PRIMARY_REP_DEADLINE)
                                        .diff(dayjs().tz(), 'days'),
                                },
                            )}
                        </GivelifyLabel>
                        {t(
                            `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title2`,
                        )}
                    </GivelifyLabel>
                ) : (
                    t(
                        `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title`,
                    )
                );
                setNotification({
                    id: 'primaryRepVerificationEscalated',
                    text: title,
                    description: t(
                        `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.description`,
                    ),
                    variant: sever ? 'error' : 'warning',
                    actionButtonProps: !(
                        (!enabledLocationNonAdmin && isBasicOrFinancial) ||
                        (!enabledLocationAdmin && isAdmin)
                    )
                        ? {
                              text: t(
                                  `notifications.addPrimaryRep.common.${adminPrefix}.buttonText`,
                              ),
                              onClick: () => {
                                  trackEvent(
                                      `<${
                                          isAdmin
                                              ? 'Get Started'
                                              : 'Get Started Non Admin'
                                      }>_${LINK_IDENTIFIER}`,
                                      {},
                                      'click',
                                  );
                                  navigate(
                                      isAdmin
                                          ? GAS_ROUTER_PATH.SETTINGS.BANK_INFO(
                                                'rep',
                                            )
                                          : GAS_ROUTER_PATH.SETTINGS.USERS(),
                                  );
                              },
                          }
                        : undefined,
                });
            }
        } else {
            if (
                notification &&
                notification.id === 'primaryRepVerificationEscalated'
            ) {
                setNotification(null);
            }
        }
        //eslint-disable-next-line
    }, [
        donee,
        location.pathname,
        location.search,
        newEscalatedPrimaryRepVerificationEnabled,
    ]);
    React.useEffect(() => {
        if (!notification) {
            setNotificationHeight(0);
            return;
        }
        const updateHeight = () => {
            if (notificationRef.current) {
                setNotificationHeight(notificationRef.current.offsetHeight);
            }
        };
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [notification]);
    return (
        <NotificationContext.Provider
            value={{
                showNotification: openNotification,
                closeNotification,
                notification,
                notificationHeight,
                notificationRef,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
