import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { Skeleton, styled } from '@mui/material';

export const Title = styled(GivelifyLabel)(({ theme }) => ({
    maxWidth: '80px',
    lineHeight: '18px',
    color: DesignTokens.color.globalGuidingViolet600,
}));
export const Divider = styled('div')({
    width: '1px',
    height: '26px',
    backgroundColor: DesignTokens.color.globalGuidingViolet200,
});
export const Amount = styled(GivelifyLabel)(({ theme }) => ({
    lineHeight: '36px',
    [theme.breakpoints.down('mobile')]: {
        marginLeft: 'auto',
    },
}));
export const TotalDonationsWrapper = styled('div')(({ theme }) => ({
    backgroundColor: DesignTokens.color.globalGuidingViolet100,
    borderRadius: DesignTokens.measurement.borderRadiusS,
    padding: theme.spacing(1.75, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
    },
}));

export const TotalDonationsSkeleton = styled(Skeleton)(({ theme }) => ({
    width: '250px',
    height: '68px',
    borderRadius: DesignTokens.measurement.borderRadiusS,
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        height: '64px',
    },
}));
