import { getAxiosClient, makeApiRequest, ApiResponse } from '@givelify/utils';
import { postDenominationUrl } from '../../consts/endpoints';
import { Denomination } from '../../models/Denomination';

export const postDenomination = async (
    name: string,
): Promise<ApiResponse<{ data: Denomination }>> => {
    const httpRequest = getAxiosClient().post<Denomination>(
        postDenominationUrl(),
        { name },
    );
    const result = makeApiRequest<{ data: Denomination }>(httpRequest);
    return result;
};
