import React from 'react';
import {
    DesignTokens,
    DropdownOption,
    GivelifyDropdown,
    GivelifyLabel,
} from '@givelify/ui';
import {
    BUTTON_IDENTIFIER,
    useTrackingContext,
    useWindowSize,
} from '@givelify/utils';
import {
    SelectChangeEvent,
    Stack,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const TableControl = styled('div')(({ theme }) => ({
    height: '80px',
    padding: '0px 32px 0px 32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
        height: '72px',
        padding: '0px 24px 0px 24px',
    },
}));

const TableSortControl = styled(Stack)({
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
});

const SortDropDownWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        width: '67px',
        maxWidth: '67px',
    },
}));

const StyledLabel = styled(GivelifyLabel)(({ theme }) => ({
    flexShrink: 0,
    fontWeight: 900,
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
    },
}));

const StyledDropDown = styled(GivelifyDropdown)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        paddingRight: '0px !important',
    },
    '& .MuiSelect-select': {
        padding: '12px 48px 12px 24px',
        '&.MuiOutlinedInput-input': {
            paddingRight: '24px',
        },
        [theme.breakpoints.down('tablet')]: {
            padding: '8px 40px 8px 16px',
        },
        [theme.breakpoints.down('mobile')]: {
            padding: '4px 12px!important',
        },
    },
    '& .MuiTypography-root': {
        fontSize: DesignTokens.textStyle.globalBody1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
        [theme.breakpoints.down('tablet')]: {
            fontSize: DesignTokens.textStyle.globalBody1.font.size,
        },
        [theme.breakpoints.down('mobile')]: {
            fontSize: DesignTokens.textStyle.globalCaption1.font.size,
        },
    },
}));

export type DataTableControlProps = {
    title: string | JSX.Element;
    sortOptions: DropdownOption[];
    sortValue: string;
    onSortChange: (sort: string) => void;
    NoResultComponent?: JSX.Element;
    onMenuItemRender?: (option: DropdownOption) => JSX.Element;
    setRenderValueText?: (value: DropdownOption) => string;
    loading?: boolean;
    className?: string;
};

const DataTableControlComponent: React.FC<DataTableControlProps> = ({
    title,
    sortValue,
    sortOptions,
    onSortChange,
    onMenuItemRender,
    setRenderValueText,
    loading,
    className,
}) => {
    const { trackEvent } = useTrackingContext();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            sortBy: t(`components.dataTable.sortBy`),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const innerSize = useWindowSize();
    const onSortChangeCallback = React.useCallback(
        (event: SelectChangeEvent<string>) => {
            onSortChange(event.target.value);

            trackEvent(`<Sort>_${BUTTON_IDENTIFIER}`, {
                sortOption: setRenderValueText(
                    sortOptions.find(
                        (option) => option.id === event.target.value,
                    ),
                ),
            });
        },
        [onSortChange, trackEvent, setRenderValueText, sortOptions],
    );
    return (
        <TableControl className={className}>
            {title}
            <TableSortControl className="data-table-control-sort-root" gap={1}>
                {!isMobile ? (
                    <StyledLabel className="data-table-control-sort-label">
                        {t('components.dataTable.sortBy')}:
                    </StyledLabel>
                ) : null}
                <SortDropDownWrapper>
                    <StyledDropDown
                        MenuProps={{
                            MenuListProps: {
                                sx: {
                                    ...(isMobile
                                        ? {
                                              height: `calc(${innerSize.innerHeight}px - 70px - 48px)`,
                                          }
                                        : null),
                                },
                            },
                            sx: {
                                marginTop: '8px',
                                marginLeft: '24px',
                                zIndex: 1301,
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        }}
                        className="data-table-control-sort-dropdown"
                        classes={{
                            select: 'data-table-control-sort-text',
                        }}
                        componentsProps={{
                            root: {
                                'aria-label': 'sort select',
                            },
                        }}
                        data-testid="data-table-control-sort-dropdown"
                        disabled={loading}
                        hasCloseButton={isMobile}
                        menuTitle={isMobile ? `${copy.sortBy}` : undefined}
                        mobileButtonText={copy.sortBy}
                        mode="accent"
                        onChange={onSortChangeCallback}
                        onMenuItemRender={onMenuItemRender}
                        options={sortOptions}
                        setRenderValueText={setRenderValueText}
                        value={sortValue}
                        width="fullwidth"
                    />
                </SortDropDownWrapper>
            </TableSortControl>
        </TableControl>
    );
};

export const DataTableControl = React.memo(DataTableControlComponent);
