import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

export const givelithonEnvelopeStyle = makeStyles((theme: Theme) => ({
    givelithonEnvelope: {
        padding: theme.spacing(4, 3),
        boxShadow: '0px 0px 6px rgba(118, 142, 168, 0.4)',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        '& *': {
            boxSizing: 'border-box',
        },
    },
    envelopeName: {
        width: '45%',
        paddingRight: 72,
    },
    envelopeGoal: {
        width: '30%',
    },
    envelopeGroupButton: {
        width: '25%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: 72,
    },
    launchGivelithonBtn: {
        fontFamily: theme.gas?.font,
        fontStyle: 'normal',
        fontWeight: 'normal',
        textTransform: 'none',
        paddingLeft: 24,
        paddingRight: 24,
        borderStyle: 'none',
        backgroundColor: GivelifyColorPalette.primary,
        color: GivelifyColorPalette.primaryWhite,
        height: 36,
        minWidth: 144,
        borderRadius: 25,
        fontSize: 15,
        lineHeight: '36px',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: GivelifyColorPalette.accentDarkBlue,
            textDecoration: 'none',
        },
    },
    [theme.breakpoints.down('sm')]: {
        givelithonEnvelope: {
            display: 'block',
        },
        envelopeName: {
            width: '100%',
            display: 'block',
        },
        envelopeGoal: {
            width: '100%',
            display: 'block',
            marginTop: 30,
        },
        envelopeGroupButton: {
            width: '100%',
            paddingLeft: 0,
            marginTop: 35,
        },
    },
}));
