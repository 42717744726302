import React from 'react';
import {
    GivelifyLabel,
    GivelifyLabelProps,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
    variant?: GivelifyLabelProps['variant'];
    underline?: boolean;
    text: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        [theme.breakpoints.down('xs')]: {
            '& hr': {
                display: 'none',
            },
        },
    },
    heading: {
        whiteSpace: 'nowrap',
        '&:focus': {
            outline: 'none',
        },
    },
    divider: {
        marginTop: 16,
        height: 2,
    },
}));

const Heading: React.FC<Props> = props => {
    const { wrapper, heading, divider } = useStyles(props);
    const { text, variant = 'heading1', underline } = props;

    return (
        <div className={wrapper}>
            <GivelifyLabel className={heading} text={text} variant={variant} />
            {underline && <GivelifyTextDivider className={divider} text="" />}
        </div>
    );
};

export default Heading;
