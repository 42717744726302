import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

interface ZeroStateProps {
    src: string;
    type?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        zeroState: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            margin: theme.spacing(6, 0),
            '& img': {
                marginBottom: theme.spacing(3),
            },
        },
    }),
);

const ZeroState: React.FC<ZeroStateProps> = ({ src, type }) => {
    const { t } = useTranslation();
    const { zeroState } = useStyles();
    const { zeroMessage } = useMemo(
        () => ({
            zeroMessage: t('zeroState', { type: type }),
        }),
        [t, type],
    );
    return (
        <div className={zeroState} data-testid="zero-state">
            <img
                alt="No data"
                data-testid="zero-state-img"
                src={src}
                title="No data"
            />
            <GivelifyLabel
                color="#676E77"
                text={zeroMessage}
                variant="caption2"
            />
        </div>
    );
};

export default ZeroState;
