import {
    OFFICERS,
    Officer,
    OfficersSet,
    OfficerTitlesSet,
    LoadOfficersStart,
    LoadOfficersFinish,
    LoadOfficerTitlesStart,
    LoadOfficerTitlesFinish,
} from './types';

export const setOfficers = (officers: Officer[]): OfficersSet => ({
    type: OFFICERS.SET,
    data: officers,
});

export const setOfficerTitles = (
    officerTitles: string[],
): OfficerTitlesSet => ({
    type: OFFICERS.SET_OFFICER_TITLES,
    data: officerTitles,
});

export const loadOfficersStart = (): LoadOfficersStart => ({
    type: OFFICERS.LOAD_OFFICERS_START,
});

export const loadOfficersFinish = (): LoadOfficersFinish => ({
    type: OFFICERS.LOAD_OFFICERS_FINISH,
});

export const loadOfficerTitlesStart = (): LoadOfficerTitlesStart => ({
    type: OFFICERS.LOAD_OFFICER_TITLES_START,
});

export const loadOfficerTitlesFinish = (): LoadOfficerTitlesFinish => ({
    type: OFFICERS.LOAD_OFFICER_TITLES_FINISH,
});
