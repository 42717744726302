import { styled } from '@mui/material';

export const TitleWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const Content = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: '0 16px',
    },
}));

export const ChartsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
});

export const TrendChartWrapper = styled('div')({
    marginBottom: '24px',
});
