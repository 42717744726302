import { ReportType } from 'store/report/types';

export enum ACS {
    ACS = 'ACS People',
    ACSREALM = 'ACS Realm',
}

export enum SHELBY {
    SHELBYARENA = 'Shelby Arena',
    SHELBYNEXT = 'Shelby Next',
}

export const formatIntegrationVersionFilter = (
    value: ACS | SHELBY,
): ReportType => {
    switch (value) {
        case ACS.ACSREALM:
            return 'ACSREALM';
        case SHELBY.SHELBYNEXT:
            return 'SHELBYNEXT';
        default:
        case ACS.ACS:
            return 'ACS';
        case SHELBY.SHELBYARENA:
            return 'SHELBYARENA';
    }
};

export const MATCHED = 'Matched';
export const UNMATCH = 'Unmatched';
export const MATCH = [MATCHED, UNMATCH];
export type matchSelect = typeof MATCHED | typeof UNMATCH;
