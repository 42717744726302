import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';
import { ResponsiveTab } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { DonorsPage } from './DonorsPage';

const PageHeader = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const PageTitle = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: DesignTokens.textStyle.globalHeadingL2.font.size,
    lineHeight: `${DesignTokens.textStyle.globalHeadingL2.font.lineHeight}px`,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalHeadingM1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalHeadingM1.font.lineHeight}px`,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalHeadingS2.font.size,
        lineHeight: `${DesignTokens.textStyle.globalHeadingS2.font.lineHeight}px`,
        marginBottom: theme.spacing(1),
    },
}));

const TAB_PATH = {
    list: 'your-donors',
} as const;

export const DonorsMain: React.FC = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = React.useState(0);
    const copy = React.useMemo(
        () => ({
            title: t('menu.donors.title'),
            yoursTab: t('donors.tabs.yourDonors'),
        }),
        [t],
    );
    const { PATH } = useGasRouterContext();
    const tabHrefs = React.useMemo(() => {
        return [PATH.DONORS.ROOT(TAB_PATH.list)];
    }, [PATH.DONORS]);

    const navigate = useNavigate();
    const location = useLocation();
    const handleChange = (newValue: number) => {
        if (currentTab !== newValue) {
            navigate(PATH.DONORS.ROOT(TAB_PATH.list));
            setCurrentTab(newValue);
        }
    };

    React.useEffect(() => {
        if (
            location.search.startsWith(`?${TAB_PATH.list}`) &&
            currentTab !== 0
        ) {
            setCurrentTab(0);
        }
    }, [location, currentTab]);
    return (
        <div>
            <PageHeader>
                <PageTitle
                    role="heading"
                    text={copy.title}
                    variant="heading2"
                />
                <ResponsiveTab
                    onChange={handleChange}
                    options={[
                        {
                            label: copy.yoursTab,
                            tabHref: tabHrefs[0],
                        },
                    ]}
                    value={currentTab}
                />
            </PageHeader>
            {currentTab === 0 && <DonorsPage />}
        </div>
    );
};
