import { Donee, OnboardingAPIData } from '@givelify/givelify-ui';
import {
    convertToDate,
    setDefaultStartDate,
    defaultStartDate,
    getAxiosClient,
    makeApiRequest,
    ApiResponse,
} from '@givelify/utils';
import { AxiosResponse } from 'axios';
import { ThunkAction } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { EditUserInfo } from 'types/userTypes';
import { httpPatch } from '../thunks';
import {
    setDoneeAccountOwner,
    setDoneeAndCampuses,
    setDoneeFaithLeader,
} from './actions';
import { AccountOwnerInfo, FaithLeaderInfo } from './types';

const identifyUserToFullStory = (donee: Donee) => {
    const fs = window.FS;
    if (!fs) return;
    fs.identify(donee.id.toString(), {
        displayName: `${donee.id} | ${donee.name}`,
        email: donee.email,
        // suffix (_bool, _str) is required by FullStory, not really sure why!
        // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Recording-custom-user-data
        onboardingCompleted_bool: donee.onboarding.hasCompleted,
        organizationType_str: donee.type,
        signupDate_date: new Date(donee.signupDate),
        city_str: donee.city,
        state_str: donee.state,
        country_str: donee.country,
        platform_str: 'studio',
    });
};

const mapResponseToDoneeOnboarding = (
    onboarding: OnboardingAPIData,
): OnboardingAPIData => {
    return {
        hasCompleted: onboarding['hasCompleted'],
        hasMid: onboarding['hasMid'],
        organizationType: onboarding['organizationType'],
        midStatus: onboarding.midStatus,
        midDateAdded: onboarding['midDateAdded']
            ? convertToDate(onboarding['midDateAdded'])
            : undefined,
        appProfile: {
            status: undefined,
            missionStatement: onboarding['appProfile']['missionStatement'],
            coverPhoto: {
                coverPhoto:
                    onboarding['appProfile']['coverPhoto']['coverPhoto'],
                coverPhotoCroppedCoordinates:
                    onboarding['appProfile']['coverPhoto'][
                        'coverPhotoCroppedCoordinates'
                    ],
                coverPhotoOriginal:
                    onboarding['appProfile']['coverPhoto'][
                        'coverPhotoOriginal'
                    ],
            },
            faithLeader: {
                avatar: onboarding['appProfile']['faithLeader']['avatar'],
                avatarCroppedCoordinates:
                    onboarding['appProfile']['faithLeader'][
                        'avatarCroppedCoordinates'
                    ],
                avatarOriginal:
                    onboarding['appProfile']['faithLeader']['avatarOriginal'],
                firstName: onboarding['appProfile']['faithLeader']['firstName'],
                isFaithLeader:
                    onboarding['appProfile']['faithLeader']['isFaithLeader'],
                lastName: onboarding['appProfile']['faithLeader']['lastName'],
                title: onboarding['appProfile']['faithLeader']['title'],
                userId: onboarding['appProfile']['faithLeader']['userId'],
                active: onboarding['appProfile']['faithLeader']['active'],
                email: onboarding['appProfile']['faithLeader']['email'],
                invitationEmailSent:
                    onboarding['appProfile']['faithLeader'][
                        'invitationEmailSent'
                    ],
            },
            accountOwner: {
                avatar: onboarding['appProfile']['accountOwner']['avatar'],
                avatarCroppedCoordinates:
                    onboarding['appProfile']['accountOwner'][
                        'avatarCroppedCoordinates'
                    ],
                avatarOriginal:
                    onboarding['appProfile']['accountOwner']['avatarOriginal'],
                firstName:
                    onboarding['appProfile']['accountOwner']['firstName'],
                lastName: onboarding['appProfile']['accountOwner']['lastName'],
                title: onboarding['appProfile']['accountOwner']['title'],
                userId: onboarding['appProfile']['accountOwner']['userId'],
            },
            organizationAddress: {
                city: onboarding['appProfile']['organizationAddress']['city'],
                phone: onboarding['appProfile']['organizationAddress']['phone'],
                state: onboarding['appProfile']['organizationAddress']['state'],
                street: onboarding['appProfile']['organizationAddress'][
                    'street'
                ],
                zip: onboarding['appProfile']['organizationAddress']['zip'],
            },
            organizationLogo: {
                logo: onboarding['appProfile']['organizationLogo']['logo'],
                logoCroppedCoordinates:
                    onboarding['appProfile']['organizationLogo'][
                        'logoCroppedCoordinates'
                    ],
                logoOriginal:
                    onboarding['appProfile']['organizationLogo'][
                        'logoOriginal'
                    ],
            },
        },
        bankInfo: {
            status: onboarding['bankInfo']?.['status'],
            retryMessage: onboarding['bankInfo']?.['retryMessage'],
            accountNumber: onboarding['bankInfo']?.['accountNumber'],
            routingNumber: onboarding['bankInfo']?.['routingNumber'],
            billingAddress: onboarding['bankInfo']?.['billingAddress'],
            billingCity: onboarding['bankInfo']?.['billingCity'],
            billingCountry: onboarding['bankInfo']?.['billingCountry'],
            billingState: onboarding['bankInfo']?.['billingState'],
            billingZip: onboarding['bankInfo']?.['billingZip'],
            submittedAt: onboarding['bankInfo']?.['submittedAt'],
            submittedByOfficialId:
                onboarding['bankInfo']?.['submittedByOfficialId'],
            fileSubmitted: onboarding['bankInfo']?.fileSubmitted,
        },
        ein: {
            status: onboarding['ein']['status'],
            retryMessage: onboarding['ein']['retryMessage'],
            congregationSize: onboarding['ein']['congregationSize'],
            organizationYear: onboarding['ein']['organizationYear'],
            religiousAffiliation: onboarding['ein']['religiousAffiliation'],
            ein: onboarding['ein']['ein'],
            hasEin: !!onboarding['ein']['ein'],
            submittedAt: onboarding['ein']['submittedAt'],
            submittedByOfficialId: onboarding['ein']['submittedByOfficialId'],
            fileSubmitted: onboarding['ein']['fileSubmitted'],
        },
        primaryRepresentative: {
            street: onboarding['primaryRepresentative']['address'],
            city: onboarding['primaryRepresentative']['city'],
            state: onboarding['primaryRepresentative']['state'],
            zip: onboarding['primaryRepresentative']['zip'],
            status: onboarding['primaryRepresentative']['status'],
            retryMessage: onboarding['primaryRepresentative']['retryMessage'],
            dateOfBirth: onboarding['primaryRepresentative']['dateOfBirth'],
            firstName: onboarding['primaryRepresentative']['firstName'],
            lastName: onboarding['primaryRepresentative']['lastName'],
            ssn: onboarding['primaryRepresentative']['ssn'],
            title: onboarding['primaryRepresentative']['title'],
            isPrimaryRepresentative:
                onboarding['primaryRepresentative']['isPrimaryRepresentative'],
            phoneNumber: onboarding['primaryRepresentative']['phoneNumber'],
            submittedAt: onboarding['primaryRepresentative']['submittedAt'],
            submittedByOfficialId:
                onboarding['primaryRepresentative']['submittedByOfficialId'],
        },
    };
};

const mapResponseToDonee = (donee: Donee): Donee => {
    return {
        onboarding: mapResponseToDoneeOnboarding(donee['onboarding']),
        id: donee['id'],
        name: donee['name'],
        email: donee['email'],
        address: donee['address'],
        address2: donee['address2'],
        city: donee['city'],
        state: donee['state'],
        country: donee['country'],
        zip: donee['zip'],
        createdAt: convertToDate(donee['createdAt'] || defaultStartDate),
        updatedAt: convertToDate(donee['updatedAt'] || defaultStartDate),
        photo: donee['photo'],
        type: donee['type'],
        signupDate: convertToDate(donee['signupDate'] || defaultStartDate),
        congregationSize: donee['congregationSize'],
        denomination: donee['denomination'],
        established: donee['established'],
        facebook: donee['facebook'],
        instagram: donee['instagram'],
        phone: donee['phone'],
        twitter: donee['twitter'],
        useCustomReceipt:
            donee['customReceipt'] === 1 || donee['customReceipt'] === true,
        website: donee['website'],
        mailingAddress: donee['mailingAddress'],
        parentCampus: donee['parentCampus'],
        missionStatement: donee['onboarding']['appProfile']['missionStatement'],
        lastDonationDate: donee['lastDonationDate'],
        timezone: donee['timezone'],
        timezoneLong: donee['timezoneLong'],
        timezoneShort: donee['timezoneShort'],
    };
};

const mapResponseToCampuses = (donee: Donee): Donee[] => {
    const rawCampuses = donee['campuses'];
    let result = [mapResponseToDonee(donee)];
    if (rawCampuses) {
        const campuses = rawCampuses.map((campus) => {
            return mapResponseToDonee(campus);
        });
        result = result.concat(campuses);
    }
    return result;
};

export const loadDonee =
    (
        keepSelectedCampus = false,
        defaultDoneeId?: number,
    ): ThunkAction<Promise<Donee | null>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const selectedDoneeId = getState().Donee.donee?.id;

        const httpRequest = getAxiosClient().get(`/donee`);

        const doneeResponse = await makeApiRequest<Donee>(httpRequest);
        if (!doneeResponse.success) return null;

        const campuses = mapResponseToCampuses(doneeResponse.response);
        let donee = keepSelectedCampus
            ? campuses.find((c) => c.id === selectedDoneeId)
            : campuses[0];
        if (!keepSelectedCampus && defaultDoneeId && defaultDoneeId > 0) {
            const defaultDonee = campuses.find((c) => c.id === defaultDoneeId);
            if (defaultDonee) {
                donee = defaultDonee;
            }
        }
        setDefaultStartDate(donee.createdAt.toString());
        dispatch(setDoneeAndCampuses(donee, campuses));
        identifyUserToFullStory(donee);
        return donee;
    };

export const resendInviteEmail =
    (
        doneeId: number,
        userId: number,
    ): ThunkAction<
        Promise<ApiResponse<Donee>>,
        AppState,
        undefined,
        AppActions
    > =>
    async () => {
        const httpRequest = getAxiosClient().post(
            `/donees/${doneeId}/users/${userId}/resend-invite`,
        );

        return makeApiRequest<Donee>(httpRequest);
    };

export const makeFaithLeader =
    (
        userData: EditUserInfo,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const doneeId = state.Donee?.donee?.id;

        /* eslint-disable-next-line */
        const response: AxiosResponse = await httpPatch(`/donees/${doneeId}`, {
            faith_leader_id: userData.id,
        })(dispatch);

        if (response) {
            const responseFaithLeaderInfo =
                response.data.onboarding.appProfile.faithLeader;
            const faithLeaderInfo: FaithLeaderInfo = {
                userId: responseFaithLeaderInfo.userId,
                avatar: responseFaithLeaderInfo.avatar,
                avatarCroppedCoordinates:
                    responseFaithLeaderInfo.avatarCroppedCoordinates,
                avatarOriginal: responseFaithLeaderInfo.avatarOriginal,
                firstName: responseFaithLeaderInfo.firstName,
                isFaithLeader: responseFaithLeaderInfo.isFaithLeader,
                lastName: responseFaithLeaderInfo.lastName,
                title: responseFaithLeaderInfo.title,
                active: responseFaithLeaderInfo.active,
                email: responseFaithLeaderInfo.email,
                invitationEmailSent:
                    responseFaithLeaderInfo.invitationEmailSent,
            };

            dispatch(
                setDoneeFaithLeader(
                    faithLeaderInfo,
                    new Date(response.data['updatedAt']),
                ),
            );
        }
    };

export const changeAccountOwner =
    (
        userData: EditUserInfo,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const doneeId = state.Donee?.donee?.id;

        /* eslint-disable-next-line */
        const response: AxiosResponse = await httpPatch(
            `/donees/${doneeId}/account-owner`,
            {
                account_owner_id: userData.id,
            },
        )(dispatch);

        if (response) {
            const responseAccountOwnerInfo = response.data.data;
            const accountOwnerInfo: AccountOwnerInfo = {
                userId: responseAccountOwnerInfo['id'],
                avatar: responseAccountOwnerInfo['avatar'],
                avatarCroppedCoordinates: null,
                avatarOriginal: responseAccountOwnerInfo['avatar'],
                firstName: responseAccountOwnerInfo['firstName'],
                lastName: responseAccountOwnerInfo['lastName'],
                title: responseAccountOwnerInfo['title'],
            };

            dispatch(
                setDoneeAccountOwner(
                    accountOwnerInfo,
                    new Date(response.data['updatedAt']),
                ),
            );
        }
    };
