import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useAdvancedTranslation } from 'utils/i18n';
import { AppState } from '../../../../../../../store';
import { LoadTotalUnmatchedStateAsync } from '../../../../../../../store/automatedIntegration/thunks';
import { IntegrationTypes } from '../../../../../types';
import { donorTabStyle } from '../../style';
import {
    AutoIntegrationDonorMatchedData,
    IntegrationPaginatedResponse,
    integrationUrl,
} from '../../types';
import AutoIntegrationInfiniteLoader from '../AutoIntegrationInfiniteLoader';
import { TableBigRowLoadingComponent } from '../AutoIntegrationLoading';
import AutoIntegrationNoResultComponent from '../AutoIntegrationNoResultComponent';
import { deleteMatchDonor } from '../automatedIntegrationRequest';
import AutoIntegrationMatchDonorHeader from './AutoIntegrationMatchDonorHeader';
import AutoIntegrationMatchedDonorButton from './AutoIntegrationMatchedDonorButton';
import AutoIntegrationMatchedDonorInfo from './AutoIntegrationMatchedDonorInfo';

interface MatchDonorContentProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onSortingChange: (sorting: Sorting[]) => void;
    doneeId: number;
    searchValue: string;
    campusSelected: number;
    integration: IntegrationTypes;
    isReadOnly: boolean;
}

const TRANSLATION_KEY = 'pages.integration_donor_matching';

const AutoIntegrationMatchDonorContent: React.FC<MatchDonorContentProps> = ({
    sortColumn,
    sortDirection,
    onSortingChange,
    doneeId,
    searchValue,
    campusSelected,
    integration,
    isReadOnly,
}) => {
    const classes = donorTabStyle();
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));
    const dispatch = useDispatch();
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);
    const onChangeRequest = useCallback(
        () => setRequestSuccess(!requestSuccess),
        // eslint-disable-next-line
        [],
    );

    const { t, scopedTranslate } = useAdvancedTranslation(TRANSLATION_KEY);

    const [reset, setReset] = useState<true | undefined>();

    const copy = useMemo(
        () => ({
            sort: t('labels.sort'),
            platform: t('f1DonorPage.platform'),
            donorName: scopedTranslate(`content.table.heading.name`),
            emailAddress: t('f1DonorPage.emailAddress'),
            phoneNumber: t('f1DonorPage.phoneNumber'),
            address: t('f1DonorPage.address'),
            errorMatchDonor: t('pages.integrations.matchError.errorDonor'),
            noMatchDonor: t('pages.integrations.matchError.noDonor'),
        }),
        [t, scopedTranslate],
    );

    const url = useCallback(
        (pageNumber: number) => {
            return integrationUrl(
                userDoneeId,
                `donors?has_match=1&page=${pageNumber}&per_page=10${
                    searchValue.length > 0 ? '&search=' + searchValue : ''
                }${
                    campusSelected !== 0 ? '&campus_id=' + campusSelected : ''
                }&order_by=${sortColumn}&sort=${sortDirection}`,
            );
        },
        // This logic has to be re-written
        // unfortunately by adding `requestSuccess` here does the table reload
        // eslint-disable-next-line
        [
            userDoneeId,
            searchValue,
            campusSelected,
            sortColumn,
            sortDirection,
            requestSuccess,
        ],
    );
    const Error = (
        <GivelifyLabel
            bold
            margin={16}
            text={copy.errorMatchDonor}
            variant="body2"
        />
    );
    const Zeroth = (
        <AutoIntegrationNoResultComponent text={copy.noMatchDonor} />
    );
    useEffect(() => {
        setReset(true);
    }, [doneeId]);
    const onReset = () => setReset(undefined);

    const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
    const onResetDeleteStatus = () => setDeleteStatus(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [requestDeleteMatch, makeRequestDeleteMatch] = useApiRequest();
    const unMatchFunction = (donorId: number) => {
        void makeRequestDeleteMatch(deleteMatchDonor(userDoneeId, donorId));
        setDisabled(true);
    };
    useEffect(() => {
        if (requestDeleteMatch.type === 'REQUEST_SUCCESS') {
            dispatch(LoadTotalUnmatchedStateAsync());
            setDisabled(false);
            setDeleteStatus(true);
            onChangeRequest();
        } else {
            setDeleteStatus(false);
        }
    }, [requestDeleteMatch, dispatch, onChangeRequest]);

    const [hasData, setHasData] = useState<boolean>(false);
    const setDataFunction = (value: boolean) => setHasData(value);

    return (
        <>
            {!hasData && (
                <AutoIntegrationMatchDonorHeader
                    copy={{
                        platform: copy.platform,
                        donorName: copy.donorName,
                        email: copy.emailAddress,
                        phone: copy.phoneNumber,
                        address: copy.address,
                        sort: copy.sort,
                    }}
                    onOrderClick={onSortingChange}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
            )}
            <AutoIntegrationInfiniteLoader
                ErrorComponent={Error}
                LoadingComponent={TableBigRowLoadingComponent}
                ZerothComponent={Zeroth}
                hasData={setDataFunction}
                onReloaded={onResetDeleteStatus}
                onReset={onReset}
                reload={deleteStatus}
                renderData={(
                    data: IntegrationPaginatedResponse<AutoIntegrationDonorMatchedData>,
                ) => (
                    <Grid container className={classes.matchDonorContent}>
                        <Grid item className={classes.matchDonorInfo}>
                            <AutoIntegrationMatchDonorHeader
                                copy={{
                                    platform: copy.platform,
                                    donorName: copy.donorName,
                                    email: copy.emailAddress,
                                    phone: copy.phoneNumber,
                                    address: copy.address,
                                    sort: copy.sort,
                                }}
                                onOrderClick={onSortingChange}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                            />
                            {data.data.map((donor, i) => (
                                <AutoIntegrationMatchedDonorInfo
                                    key={i}
                                    address={donor.streetAddress}
                                    chmsAddress={
                                        donor.match?.chmsDonor?.addresses[0]
                                            ?.streetAddress
                                    }
                                    chmsDonorEmail={
                                        donor.match?.chmsDonor?.emails[0]
                                    }
                                    chmsDonorId={
                                        donor.match?.chmsDonor?.chmsInternalId
                                    }
                                    chmsDonorName={
                                        donor.match?.chmsDonor?.firstName +
                                        ' ' +
                                        donor.match?.chmsDonor?.lastName
                                    }
                                    chmsPhoneNumber={
                                        donor.match?.chmsDonor?.phones[0]
                                    }
                                    donorEmail={donor.email}
                                    donorImage={donor.pictureUrl}
                                    donorName={donor.fullName}
                                    integration={integration}
                                    phoneNumber={donor.phone}
                                />
                            ))}
                        </Grid>
                        <Grid item className={classes.matchDonorButton}>
                            <div className={classes.headerButtonItem} />
                            {data.data.map((donor, i) => (
                                <AutoIntegrationMatchedDonorButton
                                    key={i}
                                    deleteStatus={deleteStatus}
                                    disabled={disabled}
                                    id={donor.id}
                                    isReadOnly={isReadOnly}
                                    setDeleteStatus={onResetDeleteStatus}
                                    unMatch={unMatchFunction}
                                />
                            ))}
                        </Grid>
                    </Grid>
                )}
                reset={reset}
                url={url}
            />
        </>
    );
};

export default AutoIntegrationMatchDonorContent;
