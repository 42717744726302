import { useEffect } from 'react';
import bankStatementImage from './img/bankStatement.png';
import verificationLetterImage from './img/verificationLetter.png';
import voidedCheckImage from './img/voidedCheck.png';

export const usePreloadImages = () => {
    useEffect(() => {
        new Image().src = bankStatementImage;
        new Image().src = verificationLetterImage;
        new Image().src = voidedCheckImage;
    }, []);
};
