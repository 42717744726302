import dayjs from 'dayjs';
import { regenerateTimeFrameList } from '../timeFrameUtils';

export class TimezoneManager {
    private static defaultTimezone: string = dayjs.tz.guess();
    static setDefault(timezone: string) {
        dayjs.tz.setDefault(timezone);
        TimezoneManager.defaultTimezone = timezone;
        regenerateTimeFrameList();
    }

    static getDefault(): string | undefined {
        return TimezoneManager.defaultTimezone;
    }
}
