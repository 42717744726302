import React from 'react';
import { TimeFrameValue } from '@givelify/utils';
import { Skeleton } from '@mui/material';
import { DonationRow, Donor, Pagination } from 'api/models';
import { DataTablePagination } from 'components';
import { useTranslation } from 'react-i18next';
import { DonorDonationHistoryTable } from '../donorDonationHistoryTable';
import { TableEmptyState } from '../tableEmptyState';
import { Wrapper } from './styles';

type DonorDonationHistoryProps = {
    donations: DonationRow[];
    isLoadingDonationsPage: boolean;
    donationPagination: Partial<Pagination>;
    onPageChange: (page: number) => void;
    donor: Donor;
    timeFrame: TimeFrameValue;
};

const DonationHistoryTableComponent: React.FC<DonorDonationHistoryProps> = ({
    donations,
    donationPagination,
    isLoadingDonationsPage,
    onPageChange,
    donor,
    timeFrame,
}) => {
    const { perPage, total, currentPage, totalPages, from, to } =
        donationPagination;
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            noDonationHistory: t('pages.donor_details.noDonations'),
        }),
        [t],
    );

    return (
        <Wrapper>
            {isLoadingDonationsPage ? (
                <Skeleton
                    animation="wave"
                    height="50vh"
                    variant="rectangular"
                    width="100%"
                />
            ) : (
                <>
                    {donations.length === 0 ? (
                        <TableEmptyState
                            text={copy.noDonationHistory}
                            timeFrame={timeFrame}
                        />
                    ) : (
                        <DonorDonationHistoryTable
                            currentPage={currentPage > 0 ? currentPage - 1 : 0}
                            donations={donations}
                            donor={donor}
                            rowsPerPage={Number(perPage)}
                            totalItems={total}
                        />
                    )}

                    <DataTablePagination
                        from={from}
                        maxPages={totalPages}
                        onPageChange={onPageChange}
                        pageNumber={currentPage}
                        resultCount={total}
                        to={to}
                    />
                </>
            )}
        </Wrapper>
    );
};

export const DonationHistoryTable = React.memo(DonationHistoryTableComponent);
