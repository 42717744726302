import { ErrorResponse } from '@givelify/givelify-ui';
import { DonorsTimeFrameData } from '../types';
import {
    SetDonorsCurrentLoading,
    SET_DONORS_CURRENT_LOADING,
    SetDonorsCurrentSuccess,
    SET_DONORS_CURRENT_SUCCESS,
    SetDonorsCurrentFailed,
    SET_DONORS_CURRENT_FAILED,
} from './types';


export const setDonorsCurrentLoading: SetDonorsCurrentLoading = {
    type: SET_DONORS_CURRENT_LOADING,
};

export const setDonorsCurrentSuccess = (
    data: DonorsTimeFrameData,
): SetDonorsCurrentSuccess => ({
    type: SET_DONORS_CURRENT_SUCCESS,
    data,
});

export const setDonorsCurrentFailed = (
    error: ErrorResponse,
): SetDonorsCurrentFailed => ({
    type: SET_DONORS_CURRENT_FAILED,
    error,
});
