import { createContext, useContext } from 'react';
import { GivelifyNotificationProps } from '@givelify/ui';
import { TimeFrameValue } from '@givelify/utils';
import { Donor } from 'api/models';

export const DonorProfileContext = createContext<{
    doneeId: number;
    donor: Donor;
    donorId: string;
    timeFrame: TimeFrameValue;
    setNotification: (notification: GivelifyNotificationProps) => void;
}>({} as undefined);

export const useDonorProfileContext = () => useContext(DonorProfileContext);
