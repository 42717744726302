import React from 'react';
import { SvgIcon } from '@mui/material';
import { BahamasIcon } from '../assets/icons/SvgBahamas';

export interface GivelifyFlagIconProps {
    country: string;
}

function getFlagIconSvg(variant: string) {
    switch (variant) {
        case 'BHS':
            return BahamasIcon;
        default:
            console.error(`Unsupported country flag - ${variant}`);
            return null;
    }
}

/**
 * Flag icons used in donee portal
 */
export const GivelifyFlagIcon: React.FC<GivelifyFlagIconProps> = (props) => {
    const flagIcon = getFlagIconSvg(props.country);
    return flagIcon ? (
        <SvgIcon component={flagIcon} viewBox="0 0 32 32" />
    ) : null;
};
