import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
export const DONEE_START_DATE_KEY = 'donee-start-date';
export const DEFAULT_START_DATE_STRING = '2012-01-01';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
// Cannot use daytime format 'Y-m-d H:i:s' like `New Date('2014-02-12 15:23:08')` in safari
// use this function instead
export const convertToDate = (date: string | Date, format?: string): Date => {
    if (date instanceof Date) return date;
    return dayjs(date, format).toDate();
};
export const defaultStartDate = convertToDate(DEFAULT_START_DATE_STRING);
export const defaultStartDateDayjs = dayjs.tz(DEFAULT_START_DATE_STRING);
export const getDefaultStartDate = () => {
    const dateString =
        localStorage.getItem(DONEE_START_DATE_KEY) || DEFAULT_START_DATE_STRING;
    return convertToDate(dateString);
};
export const setDefaultStartDate = (dateString: string) => {
    localStorage.setItem(DONEE_START_DATE_KEY, dateString);
};
export const addDaysInDate = (day: Date, days: number): Date => {
    const result = dayjs(day).add(days, 'day').toDate();
    return result;
};
export const isValidDate = (date: Date | Dayjs | string | null): boolean => {
    if (date === null) return false;
    return dayjs(date).isValid();
};
export const isValidRange = (
    startDate: Date | Dayjs | string | null,
    endDate: Date | Dayjs | string | null,
    noSameDay?: boolean,
): boolean => {
    if (!isValidDate(startDate) || !isValidDate(endDate)) return false;
    return noSameDay
        ? dayjs(endDate).isAfter(startDate, 'day')
        : dayjs(endDate).isSame(startDate, 'day') ||
              dayjs(endDate).isAfter(startDate, 'day');
};
export const dateToString = (date: Date, format?: string) =>
    dayjs(date).format(format ? format : DEFAULT_DATETIME_FORMAT);
export const formatMilliseconds = (ms: number) => {
    const milliseconds = Math.floor(ms % 1000),
        seconds = Math.floor((ms / 1000) % 60),
        minutes = Math.floor((ms / (1000 * 60)) % 60),
        hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    let result = '';
    if (hours > 0) {
        result += hours < 10 ? '0' + hours : hours;
        result += 'h ';
    }
    if (minutes > 0) {
        result += minutes < 10 ? '0' + minutes : minutes;
        result += 'm ';
    }
    if (seconds > 0) {
        result += seconds < 10 ? '0' + seconds : seconds;
        result += 's ';
    }
    result += `${milliseconds}ms`;

    return result;
};
export const formatWithTimezone = (
    date: Date | string | null | dayjs.Dayjs,
    format = 'MM/DD/YYYY',
): string => {
    if (isValidDate(date)) {
        return dayjs(date).tz().format(format);
    }
    return '-';
};
export const formatWithLocaltime = (
    date: Date | string | null | dayjs.Dayjs,
    format = 'MM/DD/YYYY',
): string => {
    if (isValidDate(date)) {
        return dayjs(date).format(format);
    }
    return '-';
};
export const DEFAULT_NEW_DONEE_DATE = '06/28/2021';
export const defaultNewDoneeDate = convertToDate(DEFAULT_NEW_DONEE_DATE);
export const toISODate = (date: Date | Dayjs | string | null): string => {
    if (isValidDate(date)) {
        return dayjs(date).format('YYYY-MM-DD');
    }
    return '-';
};
