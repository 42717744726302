import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
export const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
    gap: theme.spacing(2),
    [theme.breakpoints.down('mobile')]: {
        marginBottom: theme.spacing(2),
        '& > div': {
            width: '100%',
        },
    },
}));
export const Divider = styled('hr')({
    margin: 0,
    border: `0.5px solid ${DesignTokens.color.dividerPrimaryLight}`,
});
export const DesktopToolbar = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
export const DesktopToolbarControls = styled('div')(({ theme }) => ({
    gap: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
}));
export const MobileToolbar = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
}));
