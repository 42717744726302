import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSquareNoFlag = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="22"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 30 22"
    >
        <rect fill="#00ACC4" height="22" rx="2" width="30" />
        <path
            d="M14.992 3C10.576 3 7 6.584 7 11C7 15.416 10.576 19 14.992 19C19.416 19 23 15.416 23 11C23 6.584 19.416 3 14.992 3ZM20.536 7.8H18.176C17.92 6.8 17.552 5.84 17.072 4.952C18.544 5.456 19.768 6.48 20.536 7.8ZM15 4.632C15.664 5.592 16.184 6.656 16.528 7.8H13.472C13.816 6.656 14.336 5.592 15 4.632ZM8.808 12.6C8.68 12.088 8.6 11.552 8.6 11C8.6 10.448 8.68 9.912 8.808 9.4H11.512C11.448 9.928 11.4 10.456 11.4 11C11.4 11.544 11.448 12.072 11.512 12.6H8.808ZM9.464 14.2H11.824C12.08 15.2 12.448 16.16 12.928 17.048C11.456 16.544 10.232 15.528 9.464 14.2ZM11.824 7.8H9.464C10.232 6.472 11.456 5.456 12.928 4.952C12.448 5.84 12.08 6.8 11.824 7.8ZM15 17.368C14.336 16.408 13.816 15.344 13.472 14.2H16.528C16.184 15.344 15.664 16.408 15 17.368ZM16.872 12.6H13.128C13.056 12.072 13 11.544 13 11C13 10.456 13.056 9.92 13.128 9.4H16.872C16.944 9.92 17 10.456 17 11C17 11.544 16.944 12.072 16.872 12.6ZM17.072 17.048C17.552 16.16 17.92 15.2 18.176 14.2H20.536C19.768 15.52 18.544 16.544 17.072 17.048ZM18.488 12.6C18.552 12.072 18.6 11.544 18.6 11C18.6 10.456 18.552 9.928 18.488 9.4H21.192C21.32 9.912 21.4 10.448 21.4 11C21.4 11.552 21.32 12.088 21.192 12.6H18.488Z"
            fill="white"
        />
    </svg>
);

export const SquareNoFlagIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSquareNoFlag}
        fontSize={fontSize}
    />
);
