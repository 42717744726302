import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { tooltipStyle, progressStyle } from './styles';
import { GivelifyLabel } from '../label';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import triangle from '../assets/triangle.svg';

export const BorderLinearProgress = withStyles(() =>
    createStyles({
        root: {
            height: 8,
            borderRadius: 8,
        },
        colorPrimary: {
            background: '#E3E3E3',
        },
        bar: {
            borderRadius: 8,
        },
    }),
)(LinearProgress);

export interface ProgressBarProps extends GivelifyBoxMarginProps {
    id?: string | number;
    value: number;
    goal: number;
    valueText: string;
    percentigeText: string;
}

export const GivelifyProgressBar: React.FC<ProgressBarProps> = (props) => {
    const classes = progressStyle();
    const { envelopeProgressFooter, envelopeProgressFooterArrow } =
        tooltipStyle({
            position: props.value > 100 ? 100 : props.value,
        });

    return (
        <div
            className={classes.progressContainer}
            aria-label="Active envelopes progress status"
            data-testid="progress-bar"
        >
            <div className={classes.progressHeader}>
                <GivelifyLabel
                    className={classes.progressHeaderLeft}
                    text="Goal"
                    variant="body3"
                    bold
                />
                <GivelifyLabel
                    id={`goal-value-${props.id}`}
                    className={classes.progressHeaderRight}
                    text={`$${props.goal.toLocaleString()}`}
                    variant="body3"
                    bold
                />
            </div>
            <BorderLinearProgress
                variant="determinate"
                value={props.value > 100 ? 100 : props.value}
                aria-label="Active envelopes progress bar"
            />
            <div className={envelopeProgressFooter}>
                <img
                    src={triangle}
                    title="arrow"
                    alt="arrow"
                    className={envelopeProgressFooterArrow}
                />
                <EnvelopeProgressTooltip
                    value={props.valueText}
                    percentige={props.percentigeText}
                    id={props.id}
                />
            </div>
        </div>
    );
};

interface EnvelopeProgressTooltipProps {
    value: string;
    percentige: string;
    id?: string | number;
}

const EnvelopeProgressTooltip: React.FC<EnvelopeProgressTooltipProps> = (
    props,
) => {
    const classes = progressStyle();
    return (
        <div
            className={classes.progressTooltipContainer}
            aria-label="Active envelopes progress tip"
            id={`progress-tooltip-${props.id}`}
        >
            <GivelifyLabel
                id={`envelope-value-${props.id}`}
                text={props.value}
                dataTestId={`progress-bar-value-${props.id}`}
                variant="small"
                className={classes.progressTooltipText}
            />
            <GivelifyLabel
                text="|"
                variant="small"
                className={classes.progressTooltipTextSplit}
            />
            <GivelifyLabel
                id={`envelope-percent-${props.id}`}
                text={props.percentige}
                variant="small"
                dataTestId={`progress-bar-percent-${props.id}`}
                className={classes.progressTooltipText}
            />
        </div>
    );
};
