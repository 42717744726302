import {
    ScheduledReportFrequency,
    NONE,
    DAILY,
    WEEKLY,
    BI_WEEKLY,
    MONTHLY,
} from 'store/integration/types';

const determineCadenceSubSelectType = (cadence: ScheduledReportFrequency) => {
    if (cadence === DAILY || cadence === NONE) return null;
    if (cadence === WEEKLY || cadence === BI_WEEKLY) return 'day';
    if (cadence === MONTHLY) return 'date';
    throw new Error(`Unknown cadence: ${cadence}`);
};

export default determineCadenceSubSelectType;
