import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithLocaltime } from '@givelify/utils';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { formatMoneyWithCommas } from '../../../../utils/formatMoneyWithCommas';
import { donationsRowTableStyles } from '../../components/DonationsRowTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
);

export interface DonationsStatus {
    depositDate: string;
    depositAmount: number;
    status: string;
}

interface BankDepositRowProps {
    donation: DonationsStatus;
    onOpenDetail: (donation: DonationsStatus) => unknown;
    className?: string;
}

const BankDepositRow: React.FC<BankDepositRowProps> = ({
    donation,
    onOpenDetail,
    className,
}) => {
    const { link } = useStyles();
    const { leftColumn, middle, rightColumn } = donationsRowTableStyles();
    const onClick = () => onOpenDetail(donation);
    return (
        <Grid container className={className} justifyContent="space-between">
            <Grid
                item
                className={leftColumn}
                lg={4}
                md={4}
                sm={4}
                xl={4}
                xs={4}
            >
                <GivelifyLabel
                    text={formatWithLocaltime(donation.depositDate)}
                    variant="body1"
                />
            </Grid>
            <Grid item className={middle} lg={4} md={4} sm={4} xl={4} xs={4}>
                <GivelifyLabel
                    className={link}
                    onClick={onClick}
                    text={`$${formatMoneyWithCommas(donation.depositAmount)}`}
                    variant="body1"
                />
            </Grid>
            <Grid
                item
                className={rightColumn}
                lg={4}
                md={4}
                sm={4}
                xl={4}
                xs={4}
            >
                <GivelifyLabel text={donation.status} variant="body1" />
            </Grid>
        </Grid>
    );
};

export default React.memo(BankDepositRow);
