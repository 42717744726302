import React from 'react';
import { CompleteCards } from '../cards';
import { SwipeCardsProps } from '../cards/v1/SwipeCards';
import {
    CustomizeProfileModal,
    CustomizeProfileModalProps,
} from '../customizeProfile';
import { RequiredInfoModal, RequiredInfoModalProps } from '../requiredInfo';

export interface OnboardingViewProps {
    swipeCardsProps: SwipeCardsProps;
    customizeProfileProps: Omit<
        CustomizeProfileModalProps,
        'onMobilePreviewRequest'
    >;
    requiredInfoPageProps: RequiredInfoModalProps;
}

export const OnboardingView: React.FunctionComponent<OnboardingViewProps> = ({
    swipeCardsProps,
    customizeProfileProps,
    requiredInfoPageProps,
}) => {
    return (
        <>
            <CompleteCards {...swipeCardsProps} />
            <CustomizeProfileModal {...customizeProfileProps} />
            <RequiredInfoModal {...requiredInfoPageProps} />
        </>
    );
};
