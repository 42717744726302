import { styled } from '@mui/material';
import { DesktopSimpleMenuItem } from '../styles';

export const PendingInvitesContainer = styled(DesktopSimpleMenuItem)(() => ({
    paddingLeft: 64,
    
    '&.isMobile': {
        cursor: 'pointer',
        paddingLeft: 48,
    },
}));
