export enum CAMPUS_ITEM_STATUS {
    PENDING = 1,
    APPROVED = 2,
    REJECTED = 3,
    PENDING_DELETION = 4,
}
export interface CampusItem {
    id: number;
    name: string;
    short_name: string;
    address: string;
    address2: string;
    city: string;
    zip: string;
    state: string;
    phone: string;
    status: CAMPUS_ITEM_STATUS;
}
