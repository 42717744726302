import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import { OnboardingModal } from '../OnboardingModal';
import { PrimaryRepDetails } from './primaryRepDetails';
import { PrimaryRepDetailsProps } from './types';

export const RequiredPrimaryRepInfo: React.FC<PrimaryRepDetailsProps> = (
    props,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            title: t('primaryRepresentative.pageTitle'),
            lockText: t('directDeposit.safeAndSecure'),
        }),
        [t],
    );

    return (
        <OnboardingModal
            iconVariant="primary-rep-colored"
            lockText={copy.lockText}
            title={copy.title}
        >
            <PrimaryRepDetails {...props} />
        </OnboardingModal>
    );
};
