import React, { PureComponent } from 'react';
import { getArr } from './utils';

interface SliceProps {
    id?: string;
    digit: number;
    height: number;
    width: number;
    delay: number;
}

export class Slice extends PureComponent<SliceProps> {
    state = {
        offset: 0,
        isRolling: false,
        prevDigit: 0,
    };

    componentDidMount = () => {
        const { digit, height } = this.props;
        const offset = -digit * height;
        setTimeout(() => {
            this.setState({ offset, isRolling: true });
        }, 100);
    };

    componentDidUpdate = (nextProps: SliceProps) => {
        if (nextProps.digit !== this.props.digit) {
            this.reset(this.props.digit);
            //slice move in animation
            const diff = nextProps.digit - this.props.digit;
            const offset =
                diff > 0
                    ? -this.props.digit * this.props.height
                    : nextProps.digit < this.props.digit
                    ? -this.props.digit * this.props.height
                    : -(diff + 10) * this.props.height;
            setTimeout(() => {
                this.setState({ offset, isRolling: true });
            }, 100);
        }
    };

    reset = (prevDigit: number) => {
        this.setState({ offset: 0, isRolling: false, prevDigit });
    };
    render() {
        const { digit, width, height, delay } = this.props;
        let arr = getArr(this.state.prevDigit.toString(), digit.toString());
        if (arr && arr[0] > 0) {
            arr = getArr('0', digit.toString());
        } else {
            arr = getArr(this.state.prevDigit.toString(), digit.toString());
        }
        return (
            <div
                id={this.props.id}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    marginTop: this.state.offset + 'rem',
                    transition: this.state.isRolling
                        ? `margin ${delay}s ease`
                        : '',
                }}
            >
                {arr &&
                    arr.map((d, index) => (
                        <div
                            key={index}
                            style={{
                                flex: 'none',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxSizing: 'border-box',
                                fontSize: '120px',
                                height: height + 'rem',
                                width: width + 'rem',
                            }}
                        >
                            {d}
                        </div>
                    ))}
            </div>
        );
    }
}
