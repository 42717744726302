import { useCallback, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from './../store/index';

export function useAdvancedTranslation(TRANSLATION_KEY?: string) {
    const { t, ...rest } = useTranslation();

    const { doneeType = 'church' } = useSelector((state: AppState) => ({
        doneeType: state.Donee.donee?.type,
    }));

    const at = useCallback(
        (key: Parameters<TFunction>[0], ...props) => {
            return t(`${key}.${doneeType}`, ...props);
        },
        [t, doneeType],
    );

    const scopedTranslate = useCallback(
        (key: string, args = undefined) =>
            t(TRANSLATION_KEY ? `${TRANSLATION_KEY}.${key}` : key, args),
        [t, TRANSLATION_KEY],
    );

    const scopedATranslate = useCallback(
        (key: string, args = undefined) =>
            at(TRANSLATION_KEY ? `${TRANSLATION_KEY}.${key}` : key, args),
        [at, TRANSLATION_KEY],
    );

    return useMemo(
        () => ({
            ...rest,
            t,
            at,
            scopedTranslate,
            scopedATranslate,
        }),
        [t, at, scopedTranslate, scopedATranslate, rest],
    );
}
