import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyButton,
    GivelifyIcon,
    GivelifyInfo,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { mergeClassNames } from '@givelify/utils';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { CampusItem, CAMPUS_ITEM_STATUS } from '../types';
import Menu from './Menu';
import useStyles from './styles';

interface ListItemProps {
    item: CampusItem;
    usersAvailable: boolean;
    appProfileAvailable: boolean;
    envelopesAvailable: boolean;
    donationsAvailable: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
    item,
    usersAvailable,
    appProfileAvailable,
    envelopesAvailable,
    donationsAvailable,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();
    const isDesktop = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));

    const { id, name, short_name, address, city, zip, status, state } = item;

    const isApproved = status === CAMPUS_ITEM_STATUS.APPROVED;
    const isPending = status === CAMPUS_ITEM_STATUS.PENDING;
    const isPendingDeletion = status === CAMPUS_ITEM_STATUS.PENDING_DELETION;

    const viewDonationsClick = () => {
        navigate(PATH.DONATIONS.DONATIONS_ACTIVITY({ newDoneeId: id }));
    };

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem',
    });

    const { pendingApproval, deleteInfo, pendingDeletion, viewDonations } =
        useMemo(
            () => ({
                pendingApproval: scopedTranslate('pendingApproval'),
                deleteInfo: scopedTranslate('deleteInfo'),
                pendingDeletion: scopedTranslate('pendingDeletion'),
                viewDonations: scopedTranslate('viewDonations'),
            }),
            [scopedTranslate],
        );

    return (
        <div
            className={mergeClassNames(
                classes.wrapper,
                !isApproved && classes.inactive,
            )}
        >
            <GivelifyLabel
                wordBreak
                className={classes.shortName}
                id={`campus-shortname-${id}`}
                text={short_name}
                variant="heading3"
            />
            <GivelifyLabel
                wordBreak
                className={classes.title}
                id={`campus-title-${id}`}
                text={name}
                variant="body2"
            />
            <div className={classes.address} id={`campus-address-${id}`}>
                <GivelifyLabel
                    wordBreak
                    color="grey600"
                    text={address}
                    variant="body3"
                />
                {isDesktop && (
                    <GivelifyLabel
                        wordBreak
                        color="grey600"
                        text=",&nbsp;"
                        variant="body3"
                    />
                )}
                <GivelifyLabel
                    wordBreak
                    color="grey600"
                    text={`${city}, ${state} ${zip}`}
                    variant="body3"
                />
            </div>
            <div className={classes.actionWrapper} id={`campus-approval-${id}`}>
                {isPending && (
                    <>
                        <GivelifyIcon
                            size={isDesktop ? 19 : 14}
                            variant="send"
                        />
                        <GivelifyLabel
                            color="grey600"
                            dataTestId={`pending-approval-${id}`}
                            text={pendingApproval}
                            variant={isDesktop ? 'body1' : 'body2'}
                        />
                    </>
                )}
                {isPendingDeletion && (
                    <>
                        <GivelifyInfo
                            renderIcon={
                                <GivelifyIcon
                                    className={classes.pendingIcon}
                                    color="#DC9F00"
                                    size={isDesktop ? 19 : 14}
                                    variant="info-circle"
                                />
                            }
                            text={deleteInfo}
                        />
                        <GivelifyLabel
                            color="grey600"
                            dataTestId={`pending-deletion-${id}`}
                            text={pendingDeletion}
                            variant={isDesktop ? 'body1' : 'body2'}
                        />
                    </>
                )}
                {isApproved && donationsAvailable && (
                    <GivelifyButton
                        data-testid={`view-donation-${id}`}
                        id={`campus-action-${id}`}
                        onClick={viewDonationsClick}
                        size={isDesktop ? 'large' : 'small'}
                        text={viewDonations}
                        variant="primary"
                        width="100%"
                    />
                )}
            </div>
            <Menu
                appProfileAvailable={appProfileAvailable}
                buttonId={`campus-menu-${id}`}
                envelopesAvailable={envelopesAvailable}
                item={item}
                usersAvailable={usersAvailable}
            />
        </div>
    );
};

export default ListItem;
