import React from 'react';
import { GivelifyPillProps } from '@givelify/ui';
import { useMountedState } from '@givelify/utils';
import { GivenAmountFilter } from 'api/hooks';
import { PageFilterRow, PageFilterRowComponentProps } from 'components';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generateDateRange } from 'utils/strings/dateRange';
import { generateMoneyRange } from 'utils/strings/moneyRange';
import { DonorsFilter, DonorsFilterForm } from '../donorsFilterForm';
import { filterPanelSchema } from '../donorsFilterForm/schema';

const useDebouncedValidation = (
    callback: (...args: any[]) => void,
    delay: number,
) => {
    const timer = React.useRef<ReturnType<typeof setTimeout>>();

    const debouncedCallback = React.useCallback(
        (...args: any[]) => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay],
    );

    return debouncedCallback;
};

export type DonorsFilterRowProps = Omit<
    PageFilterRowComponentProps,
    | 'onFilterSubmit'
    | 'isFilterBtnActive'
    | 'canClear'
    | 'canSubmit'
    | 'canUndo'
    | 'onFilterClear'
    | 'onFilterUndo'
> & {
    filters: DonorsFilter;
    onFilterSubmit: (filter: DonorsFilter) => void;
    doneeSignupDate?: Date;
};

export const DonorsFilterRow: React.FC<DonorsFilterRowProps> = ({
    filters,
    search,
    onSearchSubmit,
    disabled,
    isFilterPanelOpen,
    onFilterButtonClick,
    filterPanelSubmitText,
    doneeSignupDate,
    onFilterSubmit,
    onFilterPanelClose,
}) => {
    const isMounted = useMountedState();
    const { t } = useTranslation();
    const schema = filterPanelSchema(t);
    const copy = React.useMemo(
        () => ({
            totalGiven: t('donors.totalGiven'),
            lastGift: t('donors.lastGivenAmount'),
            givingStyle: t('donors.givingStyleText'),
            new: t('donors.givingStyles.new'),
            occasional: t('donors.givingStyles.occasional'),
            consistent: t('donors.givingStyles.consistent'),
            decreasing: t('donors.givingStyles.decreasing'),
            inactive: t('donors.givingStyles.inactive'),
            lastGivenOn: t('donors.givingStyles.lastGivenOn'),
        }),
        [t],
    );
    const [value, setValue] = React.useState<DonorsFilter>(filters);
    const [errors, setErrors] = React.useState<GivenAmountFilter>({
        lastGivenMax: '',
        lastGivenMin: '',
        totalGivenMax: '',
        totalGivenMin: '',
    });
    const onSubmitFilter = React.useCallback(() => {
        onFilterSubmit(value);
    }, [value, onFilterSubmit]);
    const onClearFilter = React.useCallback(() => {
        setValue({
            givenAmount: {
                lastGivenMax: '',
                lastGivenMin: '',
                totalGivenMax: '',
                totalGivenMin: '',
            },
            givingStyles: {
                consistent: false,
                decreasing: false,
                inactive: false,
                new: false,
                occasional: false,
            },
            timeFrame: undefined,
        });
    }, []);
    const onFilterUndo = React.useCallback(() => {
        setValue(filters);
    }, [filters]);
    const canUndo = React.useCallback(() => {
        return !isEqual(filters, value);
    }, [filters, value]);
    const onFilterPillRemove = React.useCallback(
        (key: string | string[] | number) => {
            if (key === 'timeFrame') {
                onFilterSubmit({
                    ...value,
                    timeFrame: undefined,
                });
                return;
            }
            if (key === 'totalGiven') {
                onFilterSubmit({
                    ...value,
                    givenAmount: {
                        ...value.givenAmount,
                        totalGivenMax: '',
                        totalGivenMin: '',
                    },
                });
                return;
            }
            if (key === 'lastGift') {
                onFilterSubmit({
                    ...value,
                    givenAmount: {
                        ...value.givenAmount,
                        lastGivenMax: '',
                        lastGivenMin: '',
                    },
                });
                return;
            }
            if (key === 'givingStyle-new') {
                onFilterSubmit({
                    ...value,
                    givingStyles: {
                        ...value.givingStyles,
                        new: false,
                    },
                });
                return;
            }
            if (key === 'givingStyle-occasional') {
                onFilterSubmit({
                    ...value,
                    givingStyles: {
                        ...value.givingStyles,
                        occasional: false,
                    },
                });
                return;
            }
            if (key === 'givingStyle-consistent') {
                onFilterSubmit({
                    ...value,
                    givingStyles: {
                        ...value.givingStyles,
                        consistent: false,
                    },
                });
                return;
            }
            if (key === 'givingStyle-decreasing') {
                onFilterSubmit({
                    ...value,
                    givingStyles: {
                        ...value.givingStyles,
                        decreasing: false,
                    },
                });
                return;
            }
            if (key === 'givingStyle-inactive') {
                onFilterSubmit({
                    ...value,
                    givingStyles: {
                        ...value.givingStyles,
                        inactive: false,
                    },
                });
            }
        },
        [onFilterSubmit, value],
    );
    const validateField = (
        name: keyof GivenAmountFilter,
        value: GivenAmountFilter,
    ) => {
        schema
            .validateAt(name, value)
            .then(() => {
                if (!isMounted()) {
                    return;
                }
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: '',
                }));
            })
            .catch((err) => {
                if (!isMounted()) {
                    return;
                }
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: err.errors[0],
                }));
            });
    };
    const debouncedValidation = useDebouncedValidation(validateField, 300);
    const onFilterChange = React.useCallback(
        (filter: DonorsFilter, field?: keyof GivenAmountFilter) => {
            setValue(filter);
            if (field) {
                debouncedValidation(field, filter.givenAmount);
            }
        },
        [debouncedValidation],
    );
    React.useEffect(() => {
        setValue(filters);
    }, [filters]);
    const isFilterBtnActive =
        Object.values(filters.givingStyles).some((val) => val) ||
        Object.values(filters.givenAmount).some(
            (val) => val !== undefined && val !== '',
        ) ||
        (filters.timeFrame !== undefined &&
            (filters.timeFrame.start !== null ||
                filters.timeFrame.end !== null));

    const canClear =
        Object.values(value.givingStyles).some((val) => val) ||
        Object.values(value.givenAmount).some(
            (val) => val !== undefined && val !== '',
        ) ||
        (value.timeFrame !== undefined &&
            (value.timeFrame.start !== null || value.timeFrame.end !== null));

    const canSubmit =
        errors.lastGivenMax === '' &&
        errors.lastGivenMin === '' &&
        errors.totalGivenMax === '' &&
        errors.totalGivenMin === '';

    const onClose = React.useCallback(() => {
        if (canSubmit && canUndo()) {
            onSubmitFilter();
        } else {
            onFilterPanelClose();
        }
    }, [canSubmit, canUndo, onFilterPanelClose, onSubmitFilter]);

    const filterPills = React.useMemo(() => {
        const pills: GivelifyPillProps[] = [];
        if (filters.timeFrame && filters.timeFrame.start !== null) {
            pills.push({
                dataTestId: 'dateRange-pill',
                onClick: () => onFilterPillRemove('timeFrame'),
                text: copy.lastGivenOn,
                value: generateDateRange({
                    start: filters.timeFrame.start,
                    end: filters.timeFrame.end,
                }),
            });
        }
        if (filters.givingStyles.new) {
            pills.push({
                dataTestId: 'givingStyle-new-pill',
                onClick: () => onFilterPillRemove('givingStyle-new'),
                text: copy.givingStyle,
                value: copy.new,
            });
        }
        if (filters.givingStyles.occasional) {
            pills.push({
                dataTestId: 'givingStyle-occasional-pill',
                onClick: () => onFilterPillRemove('givingStyle-occasional'),
                text: copy.givingStyle,
                value: copy.occasional,
            });
        }
        if (filters.givingStyles.consistent) {
            pills.push({
                dataTestId: 'givingStyle-consistent-pill',
                onClick: () => onFilterPillRemove('givingStyle-consistent'),
                text: copy.givingStyle,
                value: copy.consistent,
            });
        }
        if (filters.givingStyles.decreasing) {
            pills.push({
                dataTestId: 'givingStyle-decreasing-pill',
                onClick: () => onFilterPillRemove('givingStyle-decreasing'),
                text: copy.givingStyle,
                value: copy.decreasing,
            });
        }
        if (filters.givingStyles.inactive) {
            pills.push({
                dataTestId: 'givingStyle-inactive-pill',
                onClick: () => onFilterPillRemove('givingStyle-inactive'),
                text: copy.givingStyle,
                value: copy.inactive,
            });
        }
        if (
            filters.givenAmount.totalGivenMin ||
            filters.givenAmount.totalGivenMax
        ) {
            pills.push({
                dataTestId: 'totalGiven-pill',
                onClick: () => onFilterPillRemove('totalGiven'),
                text: copy.totalGiven,
                value: generateMoneyRange({
                    min: filters.givenAmount.totalGivenMin,
                    max: filters.givenAmount.totalGivenMax,
                }),
            });
        }
        if (
            filters.givenAmount.lastGivenMin ||
            filters.givenAmount.lastGivenMax
        ) {
            pills.push({
                dataTestId: 'lastGift-pill',
                onClick: () => onFilterPillRemove('lastGift'),
                text: copy.lastGift,
                value: generateMoneyRange({
                    min: filters.givenAmount.lastGivenMin,
                    max: filters.givenAmount.lastGivenMax,
                }),
            });
        }
        return pills;
    }, [copy, filters, onFilterPillRemove]);

    return (
        <PageFilterRow
            canClear={canClear}
            canSubmit={canSubmit}
            canUndo={canUndo()}
            disabled={disabled}
            filterPanelSubmitText={filterPanelSubmitText}
            filterPillProps={filterPills}
            isFilterBtnActive={isFilterBtnActive}
            isFilterPanelOpen={isFilterPanelOpen}
            onFilterButtonClick={onFilterButtonClick}
            onFilterClear={onClearFilter}
            onFilterPanelClose={onClose}
            onFilterSubmit={onSubmitFilter}
            onFilterUndo={onFilterUndo}
            onSearchSubmit={onSearchSubmit}
            search={search}
        >
            <DonorsFilterForm
                doneeSignupDate={doneeSignupDate}
                errors={errors}
                filter={value}
                setFilter={onFilterChange}
            />
        </PageFilterRow>
    );
};
