import React, { useEffect, useMemo, useState } from 'react';
import {
    FacebookLoading,
    GivelifyLabel,
    GivelifyNotification,
    GivelifyTextDivider,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store';
import { Donor } from '../../../../../../../types/donorTypes';
import { PaginatedResponse } from '../../../../../../../types/paginationTypes';
import { IntegrationTypes } from '../../../../../types';
import {
    AutoIntegrationDonorData,
    AutomatedIntegrationDonorResponseData,
    mapAutoInteDonorResponseToDonor,
} from '../../types';
import F1CCBNoResultComponent from '../AutoIntegrationNoResultComponent';
import { getPotentialDonors } from '../automatedIntegrationRequest';
import { unMatchDonorTabStyle } from '../style';
import AutoIntegrationDonorCurrentInfo from './AutoIntegrationDonorCurrentInfo';
import AutoIntegrationPotentialMatchDonorRow from './AutoIntegrationPotentialMatchDonorRow';

interface F1CCBPotentialMatchesProps {
    donor?: Donor;
    donorId: number;
    isLoading: boolean;
    matchDonor: (donorId: number, f1DonorId: number) => void;
    isReadOnly: boolean;
    integration: IntegrationTypes;
    errorMessage: string;
}

const F1CCBPotentialLoading = () => {
    return (
        <>
            <FacebookLoading borderRadius={0} height={64} width="100%" />
            <GivelifyTextDivider />
            <FacebookLoading borderRadius={0} height={64} width="100%" />
            <GivelifyTextDivider />
            <FacebookLoading borderRadius={0} height={64} width="100%" />
        </>
    );
};

const AutoIntegrationPotentialMatches: React.FC<F1CCBPotentialMatchesProps> = ({
    donor,
    donorId,
    isLoading,
    matchDonor,
    isReadOnly,
    integration,
    errorMessage,
}) => {
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));
    const classes = unMatchDonorTabStyle();
    const { t } = useAdvancedTranslation();
    const translationPrefix =
        integration.integrationType === 'F1GO'
            ? 'f1GoDonorPage'
            : 'f1DonorPage';
    const copy = useMemo(
        () => ({
            chooseOne: t(`${translationPrefix}.chooseOne`),
            ccbChooseOne: t('pages.integrations.ccbIntegration.ccbChooseOne'),
            f1goChooseOne: t('pages.integrations.f1goIntegration.chooseOne'),
            planningCenterChooseOne: t(
                'pages.integrations.planningCenter.chooseOne',
            ),
            confirm: t(`${translationPrefix}.confirm`),
            noPotential: t(`${translationPrefix}.noPotential`),
        }),
        [t, translationPrefix],
    );
    const [loadingPotential, setLoadingPotential] = useState<boolean>(false);

    const [getRequestPotential, makeRequestPotential] =
        useApiRequest<
            PaginatedResponse<AutomatedIntegrationDonorResponseData>
        >();

    const [potentialDonors, setPotentialDonors] =
        useState<AutoIntegrationDonorData[]>();

    const { isCCB, isF1, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    useEffect(() => {
        makeRequestPotential(getPotentialDonors(userDoneeId, donorId));
        setLoadingPotential(true);
    }, [userDoneeId, donorId, makeRequestPotential]);

    useEffect(() => {
        if (getRequestPotential.type === 'REQUEST_SUCCESS') {
            const data = getRequestPotential.response.data;
            setPotentialDonors(
                data.map((donor) => mapAutoInteDonorResponseToDonor(donor)),
            );
        }
        setLoadingPotential(false);
    }, [getRequestPotential]);

    return (
        <div className={classes.potentialContent}>
            <GivelifyNotification
                className={classes.errorNotification}
                show={!!errorMessage && errorMessage.length > 0}
                text={errorMessage}
                variant="error"
            />
            <AutoIntegrationDonorCurrentInfo
                donor={donor}
                isLoading={isLoading}
            />
            <GivelifyLabel
                bold
                text={
                    isCCB
                        ? copy.ccbChooseOne
                        : isF1
                        ? copy.chooseOne
                        : isF1Go
                        ? copy.f1goChooseOne
                        : copy.planningCenterChooseOne
                }
                variant="body2"
            />
            <div className={classes.listF1Donors}>
                {loadingPotential && <F1CCBPotentialLoading />}
                {potentialDonors?.length === 0 && (
                    <F1CCBNoResultComponent text={copy.noPotential} />
                )}
                {potentialDonors?.map((f1Donor, i) => (
                    <AutoIntegrationPotentialMatchDonorRow
                        key={i}
                        donorId={donor?.id}
                        f1DonorAddress={f1Donor.f1Address}
                        f1DonorEmail={f1Donor.f1Email}
                        f1DonorId={f1Donor.f1Id}
                        f1DonorPhone={f1Donor.f1Phone}
                        f1FirstName={f1Donor.f1FirstName}
                        f1LastName={f1Donor.f1LastName}
                        isReadOnly={isReadOnly}
                        matchDonor={matchDonor}
                        matchPercent={f1Donor.similarityScore}
                    />
                ))}
            </div>
        </div>
    );
};

export default AutoIntegrationPotentialMatches;
