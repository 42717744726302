import React, { useCallback, useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyColorPalette,
    GivelifyLabel,
    GivelifyTheme,
} from '@givelify/givelify-ui';
import { Box, Grid, ThemeProvider } from '@material-ui/core';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { useTranslation } from 'react-i18next';
import { matchRowStyle } from '../../../../../components/style';
import { IntegrationTypes } from '../../../../../types';
import { matchSelect } from '../../../../../utils/integrationFormatFilterUtils';
import { ChmsEnvelope, ChmsEnvelopeParent } from '../../types';
import AutoIntegrationConfirmModal from '../AutoIntegrationConfirmModal';
import { useCCBRowStyles } from './AutoIntegrationEnvelopeUnmatchRow';

interface AutoIntegrationEnvelopeMatchedRowProps {
    id: number;
    name: string;
    ccbEnvelope: ChmsEnvelope;
    ccbEnvelopeParent: ChmsEnvelopeParent;
    campusName?: string;
    unmatchEnvelope: (envelopeId: number, pageNumber?: number) => void;
    match?: matchSelect;
    isReadOnly?: boolean;
    btnLoading?: boolean;
    unmatchStatus?: boolean;
    integration: IntegrationTypes;
    pageNumber?: number;
}

const AutoIntegrationEnvelopeMatchedRow: React.FC<
    AutoIntegrationEnvelopeMatchedRowProps
> = ({
    id,
    name,
    ccbEnvelope,
    ccbEnvelopeParent,
    campusName,
    unmatchEnvelope,
    isReadOnly,
    btnLoading,
    unmatchStatus,
    integration,
    pageNumber,
}) => {
    const styles = useCCBRowStyles();
    const classes = matchRowStyle();

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integration_envelope_matching.content.ccb';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const text = useMemo(
        () => ({
            selectCategory: scopedTranslate('selectCategory'),
            unmatch: scopedTranslate('unmatch'),
            unmatched: scopedTranslate('unmatched'),
            unmatchTitle: t('pages.integrations.confirmModal.confirmUnmatch'),
            unmatchEnvelope: t(
                'pages.integrations.confirmModal.unmatchEnvelope',
            ),
        }),
        [scopedTranslate, t],
    );

    const { isCCB, isPlanningCenter, isF1, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    const [openModal, setOpenModal] = useState<boolean>(false);
    const onCloseModal = () => setOpenModal(false);
    const onOpenModal = () => setOpenModal(true);

    const onUnmatchEnvelope = () => {
        unmatchEnvelope(id, pageNumber);
        onCloseModal();
    };

    return (
        <ThemeProvider theme={GivelifyTheme}>
            <Grid container className={classes.matchRowContent}>
                <Grid item className={styles.gridItem} md={4} xs={6}>
                    <GivelifyLabel bold wordBreak text={name} variant="body2" />
                    <GivelifyLabel
                        color={GivelifyColorPalette.neutralGrey}
                        text={campusName || ''}
                        variant="body3"
                    />
                </Grid>
                <Grid item className={styles.gridItem} md={5} xs={6}>
                    {(isCCB || isF1Go || isPlanningCenter) && (
                            <GivelifyLabel
                                text={
                                    ccbEnvelopeParent
                                        ? ccbEnvelopeParent.name
                                        : ccbEnvelope.name
                                }
                                variant="body2"
                            />
                        )}
                    {isF1 && (
                        <Grid container>
                            <Grid item xs={6}>
                                <GivelifyLabel
                                    text={ccbEnvelope.name}
                                    variant="body2"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <GivelifyLabel
                                    text={
                                        ccbEnvelopeParent
                                            ? ccbEnvelopeParent?.name
                                            : ''
                                    }
                                    variant="body2"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item className={styles.gridMatchItem} md={3} xs={12}>
                    <Box display="flex" justifyContent="center">
                        <GivelifyButton
                            disabled={isReadOnly}
                            marginLeft="auto"
                            marginRight="auto"
                            name="match"
                            onClick={onOpenModal}
                            size="small"
                            text={unmatchStatus ? text.unmatched : text.unmatch}
                            variant="secondary"
                        />
                    </Box>
                </Grid>
            </Grid>
            <AutoIntegrationConfirmModal
                btnLoading={btnLoading}
                confirmFunction={onUnmatchEnvelope}
                confirmText={text.unmatchEnvelope}
                confirmTitle={text.unmatchTitle}
                onClose={onCloseModal}
                open={openModal}
            />
        </ThemeProvider>
    );
};

export default AutoIntegrationEnvelopeMatchedRow;
