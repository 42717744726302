import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDonors = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>donors</title>
        <path
            clipRule="evenodd"
            d="M8.4646 5.89355C6.18642 5.89355 4.3396 7.74038 4.3396 10.0186C4.3396 12.2967 6.18642 14.1436 8.4646 14.1436C10.7428 14.1436 12.5896 12.2967 12.5896 10.0186C12.5896 7.74038 10.7428 5.89355 8.4646 5.89355ZM2.8396 10.0186C2.8396 6.91195 5.358 4.39355 8.4646 4.39355C11.5712 4.39355 14.0896 6.91195 14.0896 10.0186C14.0896 13.1252 11.5712 15.6436 8.4646 15.6436C5.358 15.6436 2.8396 13.1252 2.8396 10.0186Z"
            fill="current"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M16.5184 6.04683C15.9358 5.95735 15.3408 5.99377 14.7734 6.15363C14.3747 6.26596 13.9604 6.03382 13.8481 5.63513C13.7358 5.23644 13.9679 4.82217 14.3666 4.70984C15.1403 4.49186 15.9516 4.4422 16.7461 4.56421C17.5406 4.68622 18.2997 4.97706 18.9723 5.41715C19.6449 5.85724 20.2154 6.43635 20.6453 7.11548C21.0753 7.79462 21.3547 8.558 21.4648 9.35421C21.5748 10.1504 21.513 10.961 21.2834 11.7313C21.0539 12.5016 20.6619 13.2138 20.1339 13.8198C19.6059 14.4259 18.9542 14.9118 18.2226 15.2447C17.4911 15.5777 16.6966 15.75 15.8928 15.7501C15.4786 15.7502 15.1428 15.4144 15.1428 15.0002C15.1427 14.586 15.4785 14.2502 15.8927 14.2501C16.4821 14.2501 17.0647 14.1237 17.6012 13.8795C18.1378 13.6353 18.6157 13.279 19.0029 12.8345C19.3901 12.3901 19.6776 11.8678 19.8459 11.3029C20.0143 10.738 20.0596 10.1435 19.9789 9.55962C19.8982 8.97571 19.6933 8.41588 19.3779 7.91783C19.0626 7.41978 18.6443 6.99508 18.151 6.67234C17.6578 6.3496 17.1011 6.13631 16.5184 6.04683Z"
            fill="current"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M8.46431 15.6436C7.26079 15.6436 6.07494 15.9332 5.00689 16.488C3.93883 17.0427 3.01993 17.8463 2.32776 18.8308C2.08953 19.1697 1.62172 19.2513 1.28286 19.013C0.944009 18.7748 0.862431 18.307 1.10065 17.9682C1.93123 16.7867 3.03388 15.8225 4.31549 15.1568C5.59711 14.4911 7.02008 14.1436 8.46425 14.1436C9.90843 14.1435 11.3314 14.4909 12.6131 15.1565C13.8948 15.822 14.9975 16.7862 15.8281 17.9676C16.0664 18.3064 15.9849 18.7742 15.646 19.0125C15.3072 19.2507 14.8394 19.1692 14.6011 18.8304C13.9089 17.8459 12.9899 17.0424 11.9218 16.4877C10.8537 15.933 9.66784 15.6435 8.46431 15.6436Z"
            fill="current"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M19.3507 16.5934C18.2827 16.0388 17.0967 15.7496 15.8933 15.7505C15.4791 15.7508 15.143 15.4152 15.1427 15.001C15.1424 14.5868 15.478 14.2508 15.8922 14.2505C17.3367 14.2494 18.7601 14.5965 20.042 15.2622C21.324 15.9279 22.4266 16.8928 23.2566 18.075C23.4946 18.414 23.4127 18.8817 23.0737 19.1197C22.7347 19.3577 22.2669 19.2759 22.0289 18.9369C21.3374 17.9519 20.4188 17.1481 19.3507 16.5934Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const DonorsIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDonors}
        fontSize={fontSize}
    />
);
