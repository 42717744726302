import { ErrorResponse } from '@givelify/givelify-ui';
import { DonationsTimeFrameData } from '../types';

export const SET_DONATIONS_CURRENT_LOADING = 'SET_DONATIONS_CURRENT_LOADING';
export const SET_DONATIONS_CURRENT_SUCCESS = 'SET_DONATIONS_CURRENT_SUCCESS';
export const SET_DONATIONS_CURRENT_FAILED = 'SET_DONATIONS_CURRENT_FAILED';

export interface SetDonationsCurrentLoading {
    type: typeof SET_DONATIONS_CURRENT_LOADING;
}

export interface SetDonationsCurrentSuccess {
    type: typeof SET_DONATIONS_CURRENT_SUCCESS;
    data: DonationsTimeFrameData;
}

export interface SetDonationsCurrentFailed {
    type: typeof SET_DONATIONS_CURRENT_FAILED;
    error: ErrorResponse;
}
