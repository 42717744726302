import {
    LoadDoneeNotificationStateSuccess,
    DoneeNotificationState,
    DoneeNotificationType,
    SetNotification,
} from './types';

export const loadDoneeNotificationState = (
    notifications: DoneeNotificationState,
): LoadDoneeNotificationStateSuccess => ({
    type: 'LOAD_DONEE_NOTIFICATION_STATE_SUCCESS',
    data: notifications,
});

export const setNotification = (
    notification: DoneeNotificationType,
    value: boolean,
): SetNotification => ({
    type: 'SET_NOTIFICATION',
    data: {
        notification,
        value,
    },
});
