import { useRef, useEffect } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        async function tick() {
            await savedCallback?.current?.();
        }

        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};
