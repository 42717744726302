import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { GivelifyLoading } from '../loading';
import { IntegrationPaginatedResponse } from './types';
import { Grid } from '@mui/material';

export * from './types';

const DEFAULT_THRESHOLD = 150;

interface GivelifyInfiniteLoaderProps<T> {
    LoadingComponent: JSX.Element;
    ErrorComponent: JSX.Element;
    NoDataComponent: JSX.Element;
    reset: true | undefined;
    onReset?: () => unknown;
    isLoading: boolean;
    pageNumber: number;
    data: IntegrationPaginatedResponse<T>;
    loadNextPage: () => void;
    renderData: (
        data: IntegrationPaginatedResponse<T>,
    ) => JSX.Element | JSX.Element[];
    isError: boolean;
    className?: string;
    useWindow?: boolean;
    hasData?: (value: boolean) => void;
    threshold?: number;
    getScrollParent?: () => HTMLElement | null;
}

const Loading = (
    <Grid
        container
        alignItems="center"
        justifyContent="center"
        marginTop="15px"
        marginBottom="15px"
    >
        <GivelifyLoading key={0} type="circular" margin={16} />
    </Grid>
);
export const GivelifyInfiniteLoader = <T,>(
    props: GivelifyInfiniteLoaderProps<T>,
) => {
    const { isLoading, loadNextPage, isError, pageNumber, data } = props;

    if (isError) return props.ErrorComponent;
    if (isLoading && pageNumber === 1) return props.LoadingComponent;
    if (!isLoading && data.data?.length === 0) return props.NoDataComponent;

    return (
        <div className={props.className}>
            <InfiniteScroll
                pageStart={1}
                loadMore={loadNextPage}
                hasMore={pageNumber < (data.meta.lastPage || 0)}
                loader={Loading}
                useWindow={props.useWindow}
                getScrollParent={props.getScrollParent}
                threshold={props.threshold || DEFAULT_THRESHOLD}
            >
                {props.renderData(data)}
            </InfiniteScroll>
        </div>
    );
};
