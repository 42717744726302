import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    icon: {
        maxWidth: 244,
        maxHeight: 139,
        width: '100%',
        height: '100%',
        marginBottom: 32,
    },
    text: {
        marginBottom: 40,
        color: theme.colors.neutralGrey,
    },
}));

export default useStyles;
