import React, { useMemo, useState } from 'react';
import { Donee, GivelifyIcon } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyFullscreenLoading,
    GivelifyLabel,
    GivelifyLink,
    LoaderRequestState,
} from '@givelify/ui';
import { getAxiosClient, makeApiRequest, sleep } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { DirectDepositInfo } from '../../../../../@types/assets/onboarding';
import { directDepositAPI } from '../../../../../api/requests/api';
import { I18N_NAMESPACE } from '../../../../../consts';
import { OnboardingActions } from '../../../OnboardingActions';
import {
    Divider,
    StyledForm,
    SummaryHeaderLabel,
    SummaryWrapper,
} from './styles';
import { BankInfoSummaryProps } from './types';

const ActionLabel = ({
    text,
    onClick,
    name,
}: {
    text: string;
    onClick: () => void;
    name: string;
}) => (
    <GivelifyLink
        name={name}
        onClick={onClick}
        text={
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                }}
            >
                <GivelifyIcon
                    color={DesignTokens.color.textAccentDefault}
                    size={16}
                    variant="edit-outline"
                />
                {text}
            </div>
        }
        variant="caption1"
    ></GivelifyLink>
);

const BankInfoSummaryView: React.FC<BankInfoSummaryProps> = ({
    data,
    addressIsSame,
    bankingAddress,
    bankName,
    doneeId,
    onAction,
    onCancelClick,
    onContinueClick,
    onBackClick,
}) => {
    const [requestState, setRequestState] =
        useState<LoaderRequestState>('REQUEST_INIT');
    const [result, setResult] = useState<DirectDepositInfo>();

    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            routingNumber: t('bankInfo.routingNumber'),
            accountNumber: t('bankInfo.accountNumber'),
            mailingAddress: t('bankInfo.mailingAddress'),
            edit: t('labels.edit'),
            submit: t('labels.submit'),
            header: t('bankInfo.header'),
            description: t('bankInfo.description'),
            loading: t('bankInfo.loading'),
            success: t('taxId.success'),
        }),
        [t],
    );

    const requestData = useMemo(() => {
        return {
            ...data,
            ...(!addressIsSame && {
                bankingAddress,
            }),
        };
    }, [data, bankingAddress, addressIsSame]);

    const handleSubmit = async () => {
        setRequestState('REQUEST_START');

        const response = await directDepositAPI(doneeId, requestData);
        if (!response.success) return;

        const POLL_INTERVAL = 1000; // Interval between polling requests
        const TOTAL_TIMEOUT = 10000; // If haven't recieved ok response in 10 sec - just continue

        const abortSignal = AbortSignal.timeout(TOTAL_TIMEOUT);
        while (!abortSignal.aborted) {
            const httpRequest = getAxiosClient().get(`/donee`, {
                signal: abortSignal,
            });
            const doneeResponse = await makeApiRequest<Donee>(httpRequest);

            if (
                !doneeResponse.success ||
                !doneeResponse.response ||
                !['require_information', 'verified'].includes(
                    doneeResponse.response.onboarding.bankInfo.status || '',
                )
            ) {
                await sleep(POLL_INTERVAL);
            } else {
                setResult({
                    ...requestData,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    status: doneeResponse.response.onboarding.bankInfo.status!,
                });
                setRequestState('REQUEST_SUCCESS');
                return;
            }
        }

        setResult({ ...requestData, status: 'in_progress' });
        setRequestState('REQUEST_SUCCESS');
        return;
    };

    const onComplete = () => {
        if (result) {
            onContinueClick(result);
        }
    };

    return (
        <>
            <StyledForm>
                <GivelifyLabel
                    marginBottom={1}
                    text={copy.header}
                    variant="heading1S"
                />

                <GivelifyLabel
                    marginBottom={3}
                    text={copy.description}
                    variant="body1"
                />
                <SummaryWrapper>
                    <SummaryHeaderLabel
                        text={copy.routingNumber}
                        variant="body1B"
                    />
                    <div>
                        <GivelifyLabel
                            style={{
                                letterSpacing: 2,
                            }}
                            text={data.routingNumber}
                            variant="body2"
                        />
                        <GivelifyLabel
                            color={DesignTokens.color.textSecondary}
                            text={bankName}
                            variant="caption1"
                        />
                    </div>
                    <ActionLabel
                        name="editRouting"
                        onClick={() => onAction('accountInfo')}
                        text={copy.edit}
                    />

                    <Divider />

                    <SummaryHeaderLabel
                        text={copy.accountNumber}
                        variant="body1B"
                    />
                    <GivelifyLabel
                        style={{
                            letterSpacing: 2,
                            overflowWrap: 'anywhere',
                        }}
                        text={data.accountNumber}
                        variant="body2"
                    />
                    <ActionLabel
                        name="editAcc"
                        onClick={() => onAction('accountInfo')}
                        text={copy.edit}
                    />

                    <Divider />

                    <SummaryHeaderLabel
                        text={copy.mailingAddress}
                        variant="body1B"
                    />
                    <div>
                        <GivelifyLabel
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                            text={requestData.bankingAddress.street}
                            variant="body2"
                        />
                        <GivelifyLabel
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                            text={`${requestData.bankingAddress.city}, ${requestData.bankingAddress.state} ${requestData.bankingAddress.zip}`}
                            variant="body2"
                        />
                    </div>
                    <ActionLabel
                        name="editAddress"
                        onClick={() => onAction('mailingAddress')}
                        text={copy.edit}
                    />
                </SummaryWrapper>
                <OnboardingActions
                    continueText={copy.submit}
                    disableContinue={false}
                    onBack={onBackClick}
                    onCancel={() => onCancelClick(false)}
                    onContinue={handleSubmit}
                    submitButtonName='submit'
                />
            </StyledForm>
            <GivelifyFullscreenLoading
                note={copy.loading}
                onComplete={onComplete}
                requestState={requestState}
                successMessage={copy.success}
                successTimeout={3000}
                timeout={4000}
            />
        </>
    );
};

export default BankInfoSummaryView;
