const invalidSsnList = [
    '078051120',
    '219099999',
    '123456789',
    '111111111',
    '222222222',
    '333333333',
    '444444444',
    '555555555',
    '777777777',
    '888888888',
    '999999999',
];

export const isValidSSN = (ssn: string): boolean => {
    if (!Number(ssn)) {
        return false;
    }
    // Check SSN with 9 number
    if (ssn.length !== 9) {
        return false;
    }
    // SSN cannot start with the number 9
    if (ssn.charAt(0) === '9') {
        return false;
    }
    const firstThreeDigits = ssn.substring(0, 3);
    // SSN cannot start with the number 666 or 000
    if (firstThreeDigits === '666') {
        return false;
    }
    // SSN cannot have a group with all zeros: 000-XX-XXXX, XXX-00-XXXX, and XXX-XX-0000 are invalid
    if (
        firstThreeDigits === '000' ||
        ssn.substring(3, 5) === '00' ||
        ssn.substring(5) === '0000'
    ) {
        return false;
    }
    // SSN cannot contain all matching values (000-00-0000, 111-11-1111, 222-22-2222, etc.)
    if (invalidSsnList.includes(ssn)) {
        return false;
    }
    return true;
};
