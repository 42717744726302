import React, { useMemo, useEffect, useState } from 'react';
import { GivelifyTab } from '@givelify/givelify-ui';
import { PAGE_NAME } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import SettingsPage from '../SettingsPage';
import { CustomizeProfileTab } from './CustomizeProfileTab';
import { CustomizeReceiptTab } from './CustomizeReceiptTab';

const TAB_PATH = {
    profile: 'profile',
    receipt: 'receipt',
} as const;

const AppProfile: React.FC = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    const copy = useMemo(
        () => ({
            title: t('pages.settings.app-profile.title'),
            description: t('pages.settings.app-profile.caption'),
            profileTab: t(
                'pages.settings.app-profile.customize-profile-tab.title',
            ),
            receiptTab: t(
                'pages.settings.app-profile.customize-receipt-tab.title',
            ),
        }),
        [t],
    );
    const { PATH } = useGasRouterContext();
    const tabHrefs = React.useMemo(
        () => [
            PATH.SETTINGS.APP_PROFILE(TAB_PATH.profile),
            PATH.SETTINGS.APP_PROFILE(TAB_PATH.receipt),
        ],
        [PATH.SETTINGS],
    );
    const navigate = useNavigate();
    const location = useLocation();
    const handleChange = (newValue: number) => {
        navigate(
            PATH.SETTINGS.APP_PROFILE(
                newValue === 1 ? TAB_PATH.receipt : TAB_PATH.profile,
            ),
        );
        setCurrentTab(newValue);
    };
    useEffect(() => {
        if (
            location.search.startsWith(`?${TAB_PATH.receipt}`) &&
            currentTab === 0
        ) {
            setCurrentTab(1);
        }
    }, [location, currentTab]);

    return (
        <SettingsPage
            description={copy.description}
            pageName={PAGE_NAME.SettingsAppProfile}
            title={copy.title}
            trackPageVisit={false}
        >
            <GivelifyTab
                marginTop={30}
                onChange={handleChange}
                options={[
                    {
                        label: copy.profileTab,
                        tabHref: tabHrefs[0],
                    },
                    {
                        label: copy.receiptTab,
                        tabHref: tabHrefs[1],
                    },
                ]}
                value={currentTab}
            />
            {currentTab === 0 && <CustomizeProfileTab />}
            {currentTab === 1 && <CustomizeReceiptTab />}
        </SettingsPage>
    );
};

export default AppProfile;
