import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgTaxId = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={73}
        width={72}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 72 73"
    >
        <g clipPath="url(#clip0_3810_97)">
            <path
                d="M24.5148 6.50391C22.6258 6.50391 20.9131 7.4733 19.8805 9.08896C18.0922 11.9226 15.5736 14.1596 12.5764 15.5765C10.4103 16.5956 8.99988 18.7581 8.99988 21.0946V31.6336C8.99988 39.4136 10.7629 45.8514 14.3646 51.3447C17.9915 56.8628 23.5073 61.4363 31.2144 65.3636C34.2116 66.8798 37.7629 66.8798 40.7601 65.3885C55.9476 57.7576 62.9999 47.0197 62.9999 31.6336V21.0946C62.9999 18.7581 61.5894 16.5956 59.4234 15.5765C56.4262 14.1596 53.9075 11.9226 52.1193 9.08896C51.1118 7.4733 49.3739 6.50391 47.485 6.50391H24.5148Z"
                fill="#715AFF"
            />
            <path
                clipRule="evenodd"
                d="M14.8157 5.86831L14.825 5.85378C16.9133 2.58651 20.4932 0.5 24.5149 0.5H47.4851C51.4582 0.5 55.1054 2.54733 57.1997 5.89278C58.3852 7.76717 60.0342 9.22375 61.9839 10.1463C66.1603 12.114 69 16.3347 69 21.0906V31.6297C69 40.2826 67.0011 48.0097 62.5869 54.7146C58.1997 61.3785 51.7154 66.595 43.454 70.7459L43.4436 70.7511L43.4332 70.7563C38.7513 73.0859 33.205 73.0907 28.5061 70.7136L28.4983 70.7096L28.4904 70.7056C20.1448 66.453 13.7054 61.2616 9.35079 54.6363L9.34708 54.6306C4.99884 47.9987 3 40.3698 3 31.6297V21.0906C3 16.3348 5.83963 12.1141 10.0159 10.1464C11.9691 9.22223 13.6206 7.76211 14.8065 5.8829L14.8157 5.86831ZM12.5765 15.5725C10.4104 16.5917 9 18.7542 9 21.0906V31.6297C9 39.4097 10.7631 45.8475 14.3647 51.3408C17.9916 56.8588 23.5075 61.4324 31.2146 65.3597C34.2118 66.8759 37.7631 66.8759 40.7603 65.3846C55.9478 57.7537 63 47.0158 63 31.6297V21.0906C63 18.7542 61.5896 16.5917 59.4235 15.5725C56.4263 14.1557 53.9076 11.9187 52.1194 9.08505C51.1119 7.4694 49.3741 6.5 47.4851 6.5H24.5149C22.6259 6.5 20.9132 7.4694 19.8806 9.08505C18.0924 11.9187 15.5737 14.1557 12.5765 15.5725Z"
                fill="#BEB3FF"
                fillRule="evenodd"
            />
            <path
                d="M32.2402 49.5C31.3932 49.5 30.5461 49.1864 29.8812 48.5535L19.0904 38.2879C17.6998 36.9662 17.6308 34.7512 18.9331 33.3399C20.2354 31.9286 22.4178 31.8585 23.8084 33.1803L32.1381 41.1049L49.0872 23.5503C50.4198 22.1698 52.605 22.1474 53.9653 23.4999C55.3255 24.8524 55.3476 27.0702 54.0149 28.4507L34.7068 48.4499C34.0308 49.15 33.1369 49.5 32.2402 49.5Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_3810_97">
                <rect
                    fill="white"
                    height="72"
                    transform="translate(0 0.5)"
                    width="72"
                />
            </clipPath>
        </defs>
    </svg>
);

export const TaxIdIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgTaxId}
        fontSize={fontSize}
    />
);
