import React, { useEffect } from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { TablePagination } from '@material-ui/core';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { GetReportsAsync } from 'store/integrations/thunks';
import { usePaperStyles } from 'styles/paperClasses';
import IntegrationReportsTable from './IntegrationReportsTable';
import NoIntegrationReport from './NoIntegrationReport';

interface IntegrationReportContentProps {
    integration: IntegrationTypes;
    isReadOnly: boolean;
}

const style = { borderBottom: '1px solid #e0e0e0' };
const marginTop = { marginTop: '24px' };
const Loading = (
    <div style={marginTop}>
        <FacebookLoading borderRadius={0} height={69} width="100%" />
        <div style={style} />
        <FacebookLoading borderRadius={0} height={69} width="100%" />
        <div style={style} />
        <FacebookLoading borderRadius={0} height={69} width="100%" />
        <div style={style} />
    </div>
);

const pageSize = 8;

const IntegrationReportContent: React.FC<IntegrationReportContentProps> = ({
    integration,
    isReadOnly,
}) => {
    const { t } = useTranslation();
    const classes = usePaperStyles();
    const dispatch = useDispatch();

    const { reports, reportsLoading, pagination, page } = useSelector(
        (state: AppState) => ({
            reports: state.Integrations.reports,
            reportsLoading: state.Integrations.reportsLoading,
            pagination: state.Integrations.reportsPagination,
            page: state.Integrations.page,
        }),
    );

    const onChangePage = (e: unknown, pageNumber: number) => {
        dispatch(GetReportsAsync(pageNumber + 1, pageSize));
    };

    useEffect(() => {
        dispatch(GetReportsAsync(1, pageSize));
    }, [dispatch]);

    if (reportsLoading) return Loading;
    if (reports.length === 0) return <NoIntegrationReport />;

    const count = pagination.total;

    return (
        <div>
            <IntegrationReportsTable
                integration={integration}
                isReadOnly={isReadOnly}
                pageNumber={page}
                rows={reports}
            />

            <TablePagination
                backIconButtonProps={{
                    'aria-label': t('labels.previous'),
                }}
                className={classes.integrationPagination}
                component="div"
                count={count}
                nextIconButtonProps={{
                    'aria-label': t('labels.next'),
                }}
                onPageChange={onChangePage}
                page={page - 1}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[count]}
            />
        </div>
    );
};

export default IntegrationReportContent;
