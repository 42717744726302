import { useMemo } from 'react';
import { TrackingProvider } from '@givelify/utils';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom';
import LoadingBar from '../components/system/LoadingBar';

import SettingsAbout from '../pages/settings/about';
import SettingsAppProfile from '../pages/settings/appProfile/AppProfile';
import { BankInfoV2 } from '../pages/settings/banking';
import BankInfoV1 from '../pages/settings/banking/BankInfoV1';
import SettingsCampuses from '../pages/settings/campuses';
import SettingsDashboard from '../pages/settings/dashboard/SettingsDashboard';
import Envelopes from '../pages/settings/envelopes';
import MissionStatement from '../pages/settings/mission/MissionStatement';
import SettingsOfficers from '../pages/settings/officers';
import QuickGive from '../pages/settings/quickGive/QuickGive';
import { AppState } from '../store';
import { isFeatureEnabled, Features } from '../utils/featureGating';
import { PATH } from './routes';

const CampusesElement = () => {
    const { doneeType } = useSelector((state: AppState) => ({
        doneeType: state.Donee.donee?.type,
    }));

    return doneeType === 'church' ? (
        <SettingsCampuses />
    ) : (
        <Navigate replace to={PATH.SETTINGS.ROOT} />
    );
};

const QuickGiveElement = () => {
    const isSynagogue = useSelector(
        (state: AppState) => state.User.user?.isSynagogue,
    );
    return isSynagogue ? (
        <QuickGive />
    ) : (
        <Navigate replace to={PATH.SETTINGS.ROOT} />
    );
};

const BankInfo = () => {
    const { featureEnabled } = useSelector((state: AppState) => ({
        featureEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_PRIMARY_REPRESENTATIVE_PAGE,
            false,
        ),
    }));

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('rep')) {
        searchParams.delete('rep');
        searchParams.append('tab', 'rep');
        return <Navigate replace to={{ search: searchParams.toString() }} />;
    }

    return featureEnabled ? <BankInfoV2 /> : <BankInfoV1 />;
};

export const SETTINGS_ROUTES = (
    <>
        <Route element={<SettingsDashboard />} path={PATH.SETTINGS.ROOT} />
        <Route
            element={<SettingsAppProfile />}
            path={PATH.SETTINGS.APP_PROFILE}
        />
        <Route element={<QuickGiveElement />} path={PATH.SETTINGS.QUICK_GIVE} />
        <Route element={<Envelopes />} path={PATH.SETTINGS.ENVELOPES} />
        <Route element={<SettingsOfficers />} path={PATH.SETTINGS.USERS} />
        <Route element={<BankInfo />} path={PATH.SETTINGS.BANK_INFO} />
        <Route element={<SettingsAbout />} path={PATH.SETTINGS.ORG_INFO} />
        <Route element={<CampusesElement />} path={PATH.SETTINGS.CAMPUSES} />
        <Route
            element={<MissionStatement />}
            path={PATH.SETTINGS.MISSION_STATEMENT}
        />
    </>
);

const SettingsRoutes = () => {
    const enabledFeatures = useSelector(
        (state: AppState) => state.System.enabledFeatures,
    );
    const allSettings = useMemo(
        () => isFeatureEnabled(enabledFeatures, Features.SETTINGS_ALL),
        [enabledFeatures],
    );

    if (enabledFeatures.length === 0) {
        return <LoadingBar show />;
    }

    if (!allSettings) {
        return <Navigate replace to={PATH.NOT_FOUND} />;
    }

    return (
        <TrackingProvider trackPageVisit={false}>
            <Outlet />
        </TrackingProvider>
    );
};

export default SettingsRoutes;
