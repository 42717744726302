import { RequestState } from '@givelify/givelify-ui';
import { TimeFrameValue } from '@givelify/utils';
import { Dayjs } from 'dayjs';
import { getDateLabel } from 'utils/timeFrameUtils';
import { EnvelopeDistributionData } from '../../../store/dashboard/envelopes/types';

export const flattenGivingTrendData = (
    givingTrend: RequestState<EnvelopeDistributionData>[],
    dates: [Dayjs, Dayjs][],
    selectedTimeFrame: TimeFrameValue,
) =>
    givingTrend.map((trend, index) =>
        trend.type === 'REQUEST_SUCCESS'
            ? trend.data.data.reduce(
                  (acc, curr) => {
                      const barChartKey = `${curr.id || `0`}`;

                      acc[barChartKey] = (acc[barChartKey] || 0) + curr.sum;

                      return acc;
                  },
                  {
                      name: `${getDateLabel(index, selectedTimeFrame, dates)}`,
                  },
              )
            : {
                  name: `${getDateLabel(index, selectedTimeFrame, dates)}`,
              },
    );
