import React from 'react';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { useCfroContext } from '../context';
import View from './view';

const ConfirmContactInfoPanel: React.FC = () => {
    const { currentStep } = useCfroContext();

    return (
        <TrackingProvider
            trackPageVisit
            pageName={`${PAGE_NAME.CfroPanel}_${currentStep}`}
        >
            <View currentStep={currentStep} />
        </TrackingProvider>
    );
};

export default ConfirmContactInfoPanel;
