import { PrimaryRepresentativeInfo } from '../@types/assets/onboarding';

const SET_PRIMARY_REP = 'PRIMARY_REP';

export const removePrimaryRepLocalStorage = (): void =>
    localStorage.removeItem(SET_PRIMARY_REP);

export const setPrimaryRepLocalStorage = (
    primaryRep: PrimaryRepresentativeInfo,
): void => localStorage.setItem(SET_PRIMARY_REP, JSON.stringify(primaryRep));

export const getPrimaryRepLocalStorage = ():
    | PrimaryRepresentativeInfo
    | undefined => {
    const data = localStorage.getItem(SET_PRIMARY_REP);
    if (data) {
        return JSON.parse(data);
    }
    return undefined;
};
