import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { DonationsTimeFrameData } from 'store/dashboard/donations/types';
import {
    formatMoneyWithCommas,
    formatCountWithCommas,
} from 'utils/formatMoneyWithCommas';
import ZeroState from '../../ZeroState';
import DonationsStatusWidget from '../components/DonationsStatusWidget';

export const donationsErrorObject: DonationsTimeFrameData = {
    data: {
        sum: 0,
        count: 0,
        average: 0,
        donorsCount: 0,
    },
};

interface TotalDonationsProps {
    sumDonationsCurrent: number;
    totalDonationsCount: number;
    averageDonation: number;
}

const src = '/overview/total-donation.svg';
const srcGrey = '/overview/total-donation-grey.svg';

const TotalDonations: React.FC<TotalDonationsProps> = ({
    sumDonationsCurrent,
    totalDonationsCount,
    averageDonation,
}) => {
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            unknown: t('Unknown'),
            recurringRightText: t('dashboard.totalDonations.recurring'),
            totalDonationsCount: t('dashboard.totalDonors.donations'),
            averageDonation: t('dashboard.averageDonation.title'),
            noDonations: t('noDonations'),
        }),
        [t],
    );

    const footerLeftNumber = useMemo(
        () => ({
            label:
                totalDonationsCount >= 0
                    ? formatCountWithCommas(totalDonationsCount)
                    : copy.unknown,
            value: copy.totalDonationsCount,
        }),
        [totalDonationsCount, copy],
    );

    const footerRightNumber = useMemo(
        () => ({
            label:
                averageDonation >= 0
                    ? `$${formatMoneyWithCommas(averageDonation)}`
                    : copy.unknown,
            value: copy.averageDonation,
        }),
        [averageDonation, copy],
    );

    const totalAmount = useMemo(
        () => `$${formatMoneyWithCommas(sumDonationsCurrent)}`,
        [sumDonationsCurrent],
    );

    const { onboardingEnabled, onboardingCompleted } = useSelector(
        (state: AppState) => ({
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
        }),
    );

    if (sumDonationsCurrent === 0) {
        return (
            <ZeroState
                src={onboardingEnabled && onboardingCompleted ? src : srcGrey}
                type={copy.noDonations}
            />
        );
    }

    return (
        <DonationsStatusWidget
            cardId="donations"
            footerLeftNumber={footerLeftNumber}
            footerRightNumber={footerRightNumber}
            total={totalAmount}
            totalPercentage={undefined}
        />
    );
};

export default TotalDonations;
