import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLeftChevronBar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>left-chevron-bar</title>
        <path
            clipRule="evenodd"
            d="M21.2071 18.2071C21.5976 17.8166 21.5976 17.1834 21.2071 16.7929L16.4142 12L21.2071 7.20711C21.5976 6.81658 21.5976 6.18342 21.2071 5.79289C20.8166 5.40237 20.1834 5.40237 19.7929 5.79289L14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12C14 12.2652 14.1054 12.5196 14.2929 12.7071L19.7929 18.2071C20.1834 18.5976 20.8166 18.5976 21.2071 18.2071Z"
            fill="current"
            fillRule="evenodd"
        />
        <rect
            fill="current"
            height="14"
            rx="1"
            transform="matrix(-1 0 0 1 11 5)"
            width="2"
        />
    </svg>
);

export const LeftChevronBarIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgLeftChevronBar}
        fontSize={fontSize}
    />
);
