import React, { useCallback, useState } from 'react';
import { GivelifyDropDown } from '../dropdown/GivelifyDropDown';
import { STATE_DROPDOWN_OPTIONS } from '../dropdown/stateOptions';
import { GivelifyInputState } from '../textField';
import { getStateValue, isValidState } from '../utils/addressUtils';

export interface StateFieldProps {
    name?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    state: string | undefined;
    onChange?: (state: string) => unknown;
    className?: string;
    placeholder: string;
    showState?: boolean;
}

export const StateField: React.FC<StateFieldProps> = React.memo(
    ({
        name,
        state,
        inputRef,
        className,
        placeholder,
        onChange,
        showState = false,
    }) => {
        const [newState, setState] = useState<string>(getStateValue(state));
        const [inputState, setInputState] = React.useState<GivelifyInputState>(
            'normal',
        );
        const handleChange = useCallback(
            (value: string) => {
                setState(value);
                onChange && onChange(value);
                setInputState(isValidState(value) ? 'normal' : 'error');
            },
            [setState, onChange, setInputState],
        );
        React.useEffect(() => {
            if (showState) {
                setInputState(isValidState(newState) ? 'normal' : 'error');
            }
            //eslint-disable-next-line
        }, [showState]);
        return (
            <GivelifyDropDown
                name={name}
                inputProps={{ inputRef }}
                id="state"
                value={newState}
                onChange={handleChange}
                ariaLabel={placeholder}
                label={placeholder}
                placeholder={placeholder}
                options={STATE_DROPDOWN_OPTIONS}
                className={className}
                state={inputState}
            />
        );
    },
);
