import {
    TimeFrameId,
    TimeFrameIds,
    TimeFrameIdsInvariant,
    TimeFrameValues,
    TimeFrameValue,
} from '@givelify/utils';
import dayjs from 'dayjs';
import { useFilterParams } from './useFilterParams';

const params = ['startDate', 'endDate', 'timeFrame'] as const;

export const useTimeframeFilter = (
    timeframe?: TimeFrameValue,
    forceDefault?: boolean,
) => {
    const defualtTimeFrame = TimeFrameValues.thisYear;
    return useFilterParams<TimeFrameValue, typeof params>(
        forceDefault ? timeframe : timeframe ? timeframe : defualtTimeFrame,
        params,
        (state, paramName) => {
            if (state === undefined) return '';
            switch (paramName) {
                case 'startDate':
                    return state.start ? state.start.format('YYYY-MM-DD') : '';
                case 'endDate':
                    return state.end ? state.end.format('YYYY-MM-DD') : '';
                case 'timeFrame':
                    return state.selector ? state.selector : '';
            }
        },
        (values) => {
            const timeFrameQuery = values.timeFrame
                ? values.timeFrame.toLocaleLowerCase()
                : 'custom';
            let timeFrameId: TimeFrameId = 'custom';
            if (TimeFrameIdsInvariant.includes(timeFrameQuery)) {
                timeFrameId =
                    TimeFrameIds[TimeFrameIdsInvariant.indexOf(timeFrameQuery)];
            }
            if (timeFrameId !== 'custom') {
                return TimeFrameValues[timeFrameId];
            }
            const startValueValid =
                values.startDate && dayjs(values.startDate).isValid();
            const endValueValid =
                values.endDate && dayjs(values.endDate).isValid();
            if (!startValueValid && !endValueValid) {
                //no dates were provided
                return TimeFrameValues['thisYear'];
            }
            const startStr = startValueValid
                ? values.startDate
                : values.endDate;
            const endStr = endValueValid ? values.endDate : values.startDate;

            let startDate = dayjs.tz(startStr);
            if (startDate.isAfter(dayjs().tz().format('YYYY-MM-DD'))) {
                startDate = dayjs().tz();
            }
            let endDate = dayjs.tz(endStr);
            if (endDate.isAfter(dayjs().tz().format('YYYY-MM-DD'))) {
                endDate = dayjs().tz();
            }
            if (startDate.isAfter(endDate)) {
                return {
                    selector: 'custom',
                    start: endDate,
                    end: startDate,
                };
            }
            return {
                selector: 'custom',
                start: startDate,
                end: endDate,
            };
        },
    );
};
