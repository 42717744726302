import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgInfoWarning = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        {...props}
        height="33"
        viewBox="0 0 32 33"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="16"
            cy="16.5713"
            fill="#FFF0F0"
            r="15"
            transform="rotate(-180 16 16.5713)"
        />
        <path
            clip-rule="evenodd"
            d="M16 9.07129C11.8579 9.07129 8.5 12.4292 8.5 16.5713C8.5 20.7134 11.8579 24.0713 16 24.0713C20.1421 24.0713 23.5 20.7134 23.5 16.5713C23.5 12.4292 20.1421 9.07129 16 9.07129ZM16 7.57129C11.0294 7.57129 7 11.6007 7 16.5713C7 21.5418 11.0294 25.5713 16 25.5713C20.9706 25.5713 25 21.5419 25 16.5713C25 11.6007 20.9706 7.57129 16 7.57129ZM15 20.5713C15 20.019 15.4477 19.5713 16 19.5713C16.5523 19.5713 17 20.019 17 20.5713C17 21.1236 16.5523 21.5713 16 21.5713C15.4477 21.5713 15 21.1236 15 20.5713ZM15.25 17.8213C15.25 18.2355 15.5858 18.5713 16 18.5713C16.4142 18.5713 16.75 18.2355 16.75 17.8213L16.75 12.3213C16.75 11.9071 16.4142 11.5713 16 11.5713C15.5858 11.5713 15.25 11.9071 15.25 12.3213L15.25 17.8213Z"
            fill="#C91C1C"
            fill-rule="evenodd"
        />
    </svg>
);

export const InfoWarningIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgInfoWarning}
        fontSize={fontSize}
    />
);
