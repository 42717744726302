import React from 'react';
import { DesignTokens, GivelifyLink } from '@givelify/ui';
import dayjs from 'dayjs';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';
import {
    Divider,
    DonationInfoName,
    DonationSummaryInfoSummaryWrapper,
    InfoPart,
    InfoPartSecondaryText,
    InfoPartText,
    NameWrapper,
} from './styles';

type ClickHandler = (
    ...args: [string | number] | [dayjs.Dayjs, dayjs.Dayjs]
) => void;

type DonationSummaryInfoProps = {
    clickHandler: ClickHandler;
    id?: string | number;
    name: string;
    amount: number;
    donationsAmount: number;
    donorsAmount: number;
    startDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
};

const DonationSummaryInfoComponent: React.FC<DonationSummaryInfoProps> = ({
    clickHandler,
    id,
    name,
    amount,
    donationsAmount,
    donorsAmount,
    startDate,
    endDate,
}) => {
    const onClickHandler = ({
        id,
        startDate,
        endDate,
    }: {
        id?: string | number;
        startDate?: dayjs.Dayjs;
        endDate?: dayjs.Dayjs;
    }) => {
        if (startDate && endDate) {
            clickHandler(startDate, endDate);
        } else {
            clickHandler(id);
        }
    };

    return (
        <DonationSummaryInfoSummaryWrapper data-testid="donations-summary-wrapper">
            <NameWrapper>
                <DonationInfoName
                    data-testid="summary-envelope-name"
                    fontWeight="700"
                    text={name}
                />
            </NameWrapper>
            <Divider />
            <InfoPart>
                <GivelifyLink
                    data-testid="summary-donation-amount"
                    name="Donation amount"
                    onClick={() => onClickHandler({ id, startDate, endDate })}
                    text={
                        <InfoPartText
                            color={DesignTokens.color.textAccentDefault}
                            text={formatMoneyWithCommasAndDolarSign(amount)}
                            variant="heading1S"
                        />
                    }
                />

                <InfoPartSecondaryText
                    color={DesignTokens.color.textSecondary}
                    data-testid="summary-secondary-text"
                    text={`${donationsAmount} donations by ${donorsAmount} donors`}
                    variant="body2"
                />
            </InfoPart>
        </DonationSummaryInfoSummaryWrapper>
    );
};

export const DonationSummaryInfo = React.memo(DonationSummaryInfoComponent);
