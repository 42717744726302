import React, { forwardRef, useImperativeHandle } from 'react';
import { GivelifyButton, User } from '@givelify/givelify-ui';
import {
    PrimaryRepresentativeInfo,
    PrimaryRepresentativeStepOne,
    PrimaryRepresentativeStepRef,
    PrimaryRepresentativeStepTwo,
    PrimaryRepresentativeTip,
} from '@givelify/onboarding';
import { useTranslation } from 'react-i18next';
import { RepDetailStyles } from './repDetailStyles';

interface RepEditorProps {
    user: User;
    data: PrimaryRepresentativeInfo;
    onCancel: () => void;
    onSave: () => void;
    isSaving?: boolean;
    disableCancel?: boolean;
}

export interface RepEditorRef {
    submit: () => PrimaryRepresentativeInfo;
}

const RepEditorComponent = (
    { user, data, onCancel, onSave, isSaving, disableCancel }: RepEditorProps,
    forwardRef,
) => {
    const { repEditor } = RepDetailStyles();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t('pages.settings.bank-info.customize-rep-tab.title'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );
    const [validPart1, setValidPart1] = React.useState(false);
    const [validPart2, setValidPart2] = React.useState(false);
    const stepOneRef = React.useRef<PrimaryRepresentativeStepRef>(null);
    const stepTwoRef = React.useRef<PrimaryRepresentativeStepRef>(null);
    const onPart1Validation = React.useCallback(
        (valid: boolean) => {
            setValidPart1(valid);
        },
        [setValidPart1],
    );

    const onPart2Validation = React.useCallback(
        (valid: boolean) => {
            setValidPart2(valid);
        },
        [setValidPart2],
    );

    useImperativeHandle(forwardRef, () => ({
        submit: () => {
            const part1 = stepOneRef.current.submit();
            const part2 = stepTwoRef.current.submit();
            return {
                ...part1,
                primaryRepresentativeAddress:
                    part2.primaryRepresentativeAddress,
            };
        },
    }));
    return (
        <div className={repEditor} id="primary-rep-page">
            <div className={'rep-form'} id="rep-form">
                <PrimaryRepresentativeStepOne
                    ref={stepOneRef}
                    data={data}
                    onValidation={onPart1Validation}
                    user={user}
                />
                <PrimaryRepresentativeStepTwo
                    ref={stepTwoRef}
                    data={data}
                    onValidation={onPart2Validation}
                />
                <div className="submit-footer">
                    <GivelifyButton
                        disabled={isSaving || disableCancel}
                        marginLeft="auto"
                        marginRight={8}
                        name="cancel"
                        onClick={onCancel}
                        size="large"
                        text={copy.cancel}
                        variant="secondary"
                    />
                    <GivelifyButton
                        disabled={!validPart1 || !validPart2}
                        isLoading={isSaving}
                        marginLeft={8}
                        name={'save'}
                        onClick={onSave}
                        size="large"
                        text={copy.save}
                        type="submit"
                        variant="primary"
                    />
                </div>
            </div>
            <div className="tip">
                <PrimaryRepresentativeTip />
            </div>
        </div>
    );
};

export const RepEditor = forwardRef<RepEditorRef, RepEditorProps>(
    RepEditorComponent,
);
