import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 4),
    backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
    boxShadow: DesignTokens.shadow.shadowMediumHigh,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    [theme.breakpoints.down('mobile')]: {
        gap: theme.spacing(1.5),
    },
}));

export const Description = styled(GivelifyLabel)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    maxWidth: 650,
    [theme.breakpoints.down('mobile')]: {
        marginTop: 0,
    },
}));
