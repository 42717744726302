import { DesignTokens } from '@givelify/ui';
import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    listWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: theme.gas.elevation.medium,
        minHeight: 104,
        paddingLeft: 32,
        paddingRight: 32,
        gap: 60,
        borderRadius: 4,
        border: '1px solid transparent',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            paddingTop: 24,
            paddingBottom: 24,
            gap: 16,
        },
    },
    mobileWrapper: {
        padding: theme.spacing(4, 3),
        gap: 0,
        flexDirection: 'row',
        minHeight: 0,
    },
    wrapperActive: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    wrapperError: {
        borderColor: DesignTokens.color.borderComponentError,
    },
    dragZone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 40,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'normal',
            gap: 16,
        },
    },
    dragZoneMobile: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 24,
    },
    switch: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleWrapper: {
        flex: 1,
    },
    handle: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    switchText: {
        flex: 1,
    },
    progressWrapper: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 240,
        },
    },
    wrap: {
        wordBreak: 'break-all',
    },
    errorMessage: {
        backgroundColor: DesignTokens.color.backgroundComponentError,
        padding: '5px 24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    closeErrorIcon: {
        color: DesignTokens.color.iconPrimary,
        cursor: 'pointer',
    },
}));
