import React, { useEffect, useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyTextField,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AnySchema, number, object, string } from 'yup';
import { AppState } from '../../../../store';
import { setIntegration } from '../../../../store/automatedIntegration/action';
import { AutoIntegration } from '../../../../store/automatedIntegration/types';
import { enableF1 } from '../../IntegrationContent/TabsContent/AutoIntegrationContent/components/automatedIntegrationRequest';
import {
    F1EnableResponse,
    mapF1EnableResponseToAutoIntegration,
    mapSetupF1ToRequest,
    SetupF1FormData,
} from '../../IntegrationContent/TabsContent/AutoIntegrationContent/types';
import { useCCBSettingStyles } from '../../IntegrationContent/TabsContent/IntegrationSettings/F1GoAndCCBIntegrationSettings';

const useStyle = makeStyles((theme: Theme) => ({
    error: {
        marginBottom: theme.spacing(1),
    },
    Button: {
        boxShadow: 'none',
        position: 'absolute',
        bottom: 40,
        right: 64,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            position: 'static',
            marginBottom: theme.spacing(3),
        },
    },
    hasVal: {
        '& label': {
            display: 'inline-block',
            position: 'absolute',
            top: -6,
            left: 12,
            paddingLeft: 5,
            paddingRight: 5,
            background: '#fff',
        },
    },
}));

interface F1LoginFormProps {
    doneCallBack: () => unknown;
    setError: (value: string) => void;
}

const F1LoginForm: React.FC<F1LoginFormProps> = ({
    doneCallBack,
    setError,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.integrations.content.setup-dialog',
    });
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const dispatch = useDispatch();
    const classes = useStyle();
    const { passwordField } = useCCBSettingStyles();
    const copy = useMemo(
        () => ({
            username: scopedTranslate('f1IntegrationSetup.username'),
            usernameRequired: scopedTranslate(
                'f1IntegrationSetup.usernameRequired',
            ),
            password: scopedTranslate('f1IntegrationSetup.password'),
            passwordRequired: scopedTranslate(
                'f1IntegrationSetup.passwordRequired',
            ),
            consumerKey: scopedTranslate('f1IntegrationSetup.consumerKey'),
            consumerKeyRequired: scopedTranslate(
                'f1IntegrationSetup.consumerKeyRequired',
            ),
            consumerSecret: scopedTranslate(
                'f1IntegrationSetup.consumerSecret',
            ),
            consumerSecretRequired: scopedTranslate(
                'f1IntegrationSetup.consumerSecretRequired',
            ),
            churchCode: scopedTranslate('f1IntegrationSetup.churchCode'),
            churchCodeRequired: scopedTranslate(
                'f1IntegrationSetup.churchCodeRequired',
            ),
            keyTypeNumber: scopedTranslate('f1IntegrationSetup.keyTypeNumber'),
            signIn: scopedTranslate('f1IntegrationSetup.signIn'),
        }),
        [scopedTranslate],
    );
    const form = useForm<SetupF1FormData>({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        shouldFocusError: true,
        resolver: yupResolver(
            object<Record<keyof SetupF1FormData, AnySchema>>({
                username: string().required(copy.usernameRequired),
                password: string().required(copy.passwordRequired),
                key: number()
                    .required(copy.consumerKeyRequired)
                    .typeError(copy.keyTypeNumber),
                secret: string().required(copy.consumerSecretRequired),
                churchCode: string().required(copy.churchCodeRequired),
            }),
        ),
    });
    const { errors, control } = form;
    const submitDisabled = useMemo(
        () =>
            !(
                form.getValues().username &&
                form.getValues().password &&
                form.getValues().key &&
                form.getValues().secret &&
                form.getValues().churchCode
            ),
        [form],
    );

    const [enableF1Status, requestEnableF1] = useApiRequest();
    const handleSubmit = () => {
        const value = form.getValues();
        setError('');
        const f1Credential = mapSetupF1ToRequest(value);
        const data = {
            type: 'F1',
            credentials: f1Credential,
        };
        requestEnableF1(enableF1(doneeId, data));
    };
    useEffect(() => {
        if (enableF1Status.type === 'REQUEST_SUCCESS') {
            dispatch(
                setIntegration(
                    mapF1EnableResponseToAutoIntegration(
                        enableF1Status.response as F1EnableResponse,
                    ) as AutoIntegration,
                ),
            );
            if (doneCallBack) doneCallBack();
        } else if (enableF1Status.type === 'REQUEST_ERROR') {
            setError(enableF1Status.error.message);
        }
    }, [enableF1Status, dispatch, doneCallBack, setError]);
    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Controller
                control={control}
                name="username"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        ariaLabel="username"
                        id="username"
                        inputRef={ref}
                        label={copy.username}
                        leftHelperText={errors.username?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        state={errors.username ? 'error' : 'normal'}
                        value={value}
                    />
                )}
            />
            <Controller
                control={control}
                name="password"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        fakeFocus
                        ariaLabel="password"
                        className={passwordField}
                        classes={{
                            labelFocusedRoot: 'labelFocusedRoot',
                            labelFocused: 'labelFocused',
                        }}
                        id="password"
                        inputRef={ref}
                        label={copy.password}
                        leftHelperText={errors.password?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        placeholder="********"
                        state={errors.password ? 'error' : 'normal'}
                        type="password"
                        value={value}
                    />
                )}
            />
            <Controller
                control={control}
                name="key"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        ariaLabel="key"
                        id="key"
                        inputRef={ref}
                        label={copy.consumerKey}
                        leftHelperText={errors.key?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        state={errors.key ? 'error' : 'normal'}
                        value={value}
                    />
                )}
            />
            <Controller
                control={control}
                name="secret"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        ariaLabel="secret"
                        id="secret"
                        inputRef={ref}
                        label={copy.consumerSecret}
                        leftHelperText={errors.secret?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        state={errors.secret ? 'error' : 'normal'}
                        value={value}
                    />
                )}
            />
            <Controller
                control={control}
                name="churchCode"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        ariaLabel="churchCode"
                        id="churchCode"
                        inputRef={ref}
                        label={copy.churchCode}
                        leftHelperText={errors.churchCode?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        state={errors.churchCode ? 'error' : 'normal'}
                        value={value}
                    />
                )}
            />
            <GivelifyButton
                className={classes.Button}
                disabled={submitDisabled}
                name="submit"
                size="xLarge"
                text={copy.signIn}
                type="submit"
                variant="primary"
            />
        </form>
    );
};

export default F1LoginForm;
