import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLeftChevronBig = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>left-chevron-big</title>
        <path
            clipRule="evenodd"
            d="M15.2071 18.2071C15.5976 17.8166 15.5976 17.1834 15.2071 16.7929L10.4142 12L15.2071 7.20711C15.5976 6.81658 15.5976 6.18342 15.2071 5.79289C14.8166 5.40237 14.1834 5.40237 13.7929 5.79289L8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071L13.7929 18.2071C14.1834 18.5976 14.8166 18.5976 15.2071 18.2071Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const LeftChevronBigIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgLeftChevronBig}
        fontSize={fontSize}
    />
);
