import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GivelifyButton } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';

type MatchItemType = 'donor' | 'envelope';

const useStyles = makeStyles(() => ({
    button: {
        width: '100%',
    },
}));

const MatchCell = (
    allProps: Table.DataCellProps & {
        columnName: string;
        openMatchModal: (reportId: number, type: MatchItemType) => void;
    },
) => {
    const { button } = useStyles();
    const { columnName, openMatchModal, ...props } = allProps;

    const openMatchModalClick = () => {
        const reportId = props.row.id;
        const type =
            columnName === 'totalUnmatchedEnvelopes' ? 'envelope' : 'donor';
        openMatchModal(reportId, type);
    };

    if (props.value && props.value > 0) {
        return (
            <Table.Cell {...props}>
                <GivelifyButton
                    className={button}
                    onClick={openMatchModalClick}
                    text={props.value}
                    variant="link"
                />
            </Table.Cell>
        );
    }
    return (
        <Table.Cell {...props}>
            <div />
        </Table.Cell>
    );
};

export default MatchCell;
