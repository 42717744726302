import React, { useMemo } from 'react';
import {
    GivelifyCheckbox,
    GivelifyLabel,
    GivelifyTextField,
    GivelifyFormDropDown,
    GivelifyFormTextField,
    STATE_DROPDOWN_OPTIONS,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { formatPhoneNumber } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { isZipCode } from 'utils/validateZipCode';
import { webConfig } from 'webConfig';
import { FormProps } from '../types';
import useStyles from './styles';

const ManualEntry = () => {
    const { checkboxRow, link, formWrapper, column, agreementStyle } =
        useStyles();

    const { register, errors, watch, setValue } = useFormContext<FormProps>();
    const { authorized, name, phone } = watch();

    const handlePhoneNumber = (value: string) => {
        const phone = formatPhoneNumber(value);
        setValue('phone', phone);
    };

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.addForm',
    });

    const copy = useMemo(
        () => ({
            campusDetails: scopedTranslate('campusDetails'),
            officialName: scopedTranslate('officialName'),
            allFieldsRequired: scopedTranslate('allFieldsRequired'),
            text1: scopedTranslate('text1'),
            nameLabel: scopedTranslate('nameLabel'),
            nameMaxLength: scopedTranslate('nameMaxLength'),
            internalName: scopedTranslate('internalName'),
            text4: scopedTranslate('text4'),
            nicknameLabel: scopedTranslate('nicknameLabel'),
            nicknameUnique: scopedTranslate('nicknameUnique'),
            nicknameHelperText: scopedTranslate('nicknameHelperText'),
            campusLocation: scopedTranslate('campusLocation'),
            addressLabel: scopedTranslate('addressLabel'),
            addressMaxLength: scopedTranslate('addressMaxLength'),
            zipLabel: scopedTranslate('zipLabel'),
            invalidZip: scopedTranslate('invalidZip'),
            cityLabel: scopedTranslate('cityLabel'),
            cityMaxLength: scopedTranslate('cityMaxLength'),
            stateLabel: scopedTranslate('stateLabel'),
            phoneLabel: scopedTranslate('phoneLabel'),
            haveAuthority: scopedTranslate('haveAuthority'),
            iAgree: scopedTranslate('iAgree'),
            tos: scopedTranslate('tos'),
            required: scopedTranslate('required'),
        }),
        [scopedTranslate],
    );

    return (
        <>
            <GivelifyLabel
                marginBottom={8}
                text={copy.campusDetails}
                variant="heading3"
            />
            <GivelifyLabel
                color="neutralGrey"
                marginBottom={16}
                text={copy.allFieldsRequired}
                variant="small"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.officialName}
                variant="heading5"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.text1}
                variant="body1"
            />
            <GivelifyFormTextField
                countLength
                defaultValue={name}
                formInputRef={register({
                    required: {
                        message: copy.required,
                        value: true,
                    },
                    maxLength: {
                        message: copy.nameMaxLength,
                        value: 255,
                    },
                })}
                id="nameame"
                label={copy.nameLabel}
                leftHelperText={errors.name?.message}
                marginBottom={26}
                maxLength={255}
                name="name"
                state={errors.name ? 'error' : 'normal'}
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.internalName}
                variant="heading5"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.text4}
                variant="body1"
            />
            <GivelifyFormTextField
                countLength
                formInputRef={register({
                    required: {
                        message: copy.required,
                        value: true,
                    },
                })}
                id="nickname"
                label={copy.nicknameLabel}
                leftHelperText={
                    errors.nickname?.message ||
                    (errors.nickname?.type === 'unique' &&
                        copy.nicknameUnique) ||
                    copy.nicknameHelperText
                }
                marginBottom={32}
                maxLength={50}
                name="nickname"
                state={errors.nickname ? 'error' : 'normal'}
            />
            <GivelifyLabel
                marginBottom={8}
                text={copy.campusLocation}
                variant="heading3"
            />
            <GivelifyLabel
                color="neutralGrey"
                marginBottom={16}
                text={copy.allFieldsRequired}
                variant="small"
            />
            <div className={formWrapper}>
                <GivelifyFormTextField
                    formInputRef={register({
                        required: {
                            message: copy.required,
                            value: true,
                        },
                        maxLength: {
                            message: copy.addressMaxLength,
                            value: 255,
                        },
                    })}
                    id="address"
                    label={copy.addressLabel}
                    leftHelperText={errors.address?.message}
                    name="address"
                    state={errors.address ? 'error' : 'normal'}
                />
                <GivelifyFormTextField
                    formInputRef={register({
                        required: {
                            message: copy.required,
                            value: true,
                        },
                        validate: (value) => {
                            const valid = isZipCode(value);
                            if (!valid) return copy.invalidZip;
                        },
                    })}
                    id="zip"
                    label={copy.zipLabel}
                    leftHelperText={errors.zip?.message}
                    name="zip"
                    state={errors.zip ? 'error' : 'normal'}
                />
                <GivelifyFormTextField
                    className={column}
                    formInputRef={register({
                        required: {
                            message: copy.required,
                            value: true,
                        },
                        maxLength: {
                            message: copy.cityMaxLength,
                            value: 55,
                        },
                    })}
                    id="city"
                    label={copy.cityLabel}
                    leftHelperText={errors.city?.message}
                    name="city"
                    state={errors.city ? 'error' : 'normal'}
                />
                <GivelifyFormDropDown
                    ariaLabel="state"
                    className={column}
                    formInputRef={register({
                        required: {
                            message: copy.required,
                            value: true,
                        },
                    })}
                    id="state"
                    label={copy.stateLabel}
                    leftHelperText={errors.state?.message}
                    name="state"
                    options={STATE_DROPDOWN_OPTIONS}
                    state={errors.state ? 'error' : 'normal'}
                    width="100%"
                />
                <GivelifyTextField
                    id="phone"
                    label={copy.phoneLabel}
                    leftHelperText={errors.phone?.message}
                    maxLength={14}
                    onChange={(_, value) => handlePhoneNumber(value)}
                    state={errors.phone ? 'error' : 'normal'}
                    value={phone}
                />
            </div>
            <div className={checkboxRow}>
                <GivelifyCheckbox
                    ariaLabel="authorized"
                    checked={Boolean(authorized)}
                    labelPosition="after"
                    marginRight={12}
                    name="authorized"
                    onChange={(value) => setValue('authorized', value)}
                    variant={errors.authorized ? 'special' : 'primary'}
                />
                <div className={agreementStyle}>
                    {copy.haveAuthority}
                    <div className="row2">
                        {`${copy.iAgree} `}
                        <a
                            className={link}
                            href={webConfig.termsAndConditionsUrl}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {copy.tos}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManualEntry;
