import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const envelopesPageStyle = makeStyles((theme: Theme) => {
    return createStyles({
        envelopesContainer: {
            width: '100%',
            marginBottom: 32,
            marginTop: 24,
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(5),
            },
        },
        envelopesTitleContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 'auto',
        },
        envelopesListContainer: {
            marginTop: 24,
        },
        envelopesInactiveTitle: {
            marginRight: 'auto',
            marginTop: 11,
        },
        envelopesOptionsContainer: {
            marginLeft: 'auto',
        },
        footerContainer: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            padding: '16px 0 32px 0',

            display: 'flex',
            justifyContent: 'flex-end',
            background: '#FFFFFF',
            boxShadow: '0px -1px 2px rgba(58, 76, 99, 0.2)',

            '& button': {
                minWidth: 160,
                height: 42,
            },
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                justifyContent: 'center',
                padding: 16,
                '& button': {
                    width: '100%',
                    height: 42,
                },
            },
        },
        footerErrorBanner: {
            fontSize: 16,
            lineHeight: '22px',
            marginBottom: 16,
            color: '#373739',
        },
        submitButton: {
            marginLeft: 16,
            marginRight: 96,
            [theme.breakpoints.down('sm')]: {
                marginRight: 32,
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: 0,
            },
        },
        snackBar: {
            left: 'calc(50% + 115px)',
            '& > div': {
                padding: '4px 33px',
                fontSize: '14px',
                minWidth: '344px',
                lineHeight: '24px',
            },
            [theme.breakpoints.down('xs')]: {
                left: 8,
            },
        },
        envelopesNotFound: {
            textAlign: 'center',
            marginTop: 48,
        },
        envelopeSetting: {
            display: 'flex',
            alignItems: 'center',
        },
        showCheckbox: {
            marginLeft: theme.spacing(3),
        },
        showOnSm: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        },
        hideOnSm: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        showTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        btnReorder: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                    fontSize: 24,
                },
            },
        },
    });
});
