import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgClose = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>close</title>
        <path
            clipRule="evenodd"
            d="M5.621 4.293a1 1 0 0 0-1.414 1.414l6.379 6.379-6.35 6.349a1 1 0 1 0 1.415 1.414L12 13.5l6.35 6.35a1 1 0 1 0 1.413-1.415l-6.349-6.35 6.379-6.378a1 1 0 0 0-1.414-1.414L12 10.672l-6.379-6.38Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const CloseIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgClose}
        fontSize={fontSize}
    />
);
