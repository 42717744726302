import React, { useState, useCallback, useEffect } from 'react';
import {
    useApiRequest,
    TimeFrameValues,
    useTrackingContext,
    MODAL_NAME,
} from '@givelify/utils';
import { useTimeframeFilter } from 'api/hooks';
import { DonationRow } from 'api/models';
import { MainFilterRow } from 'components/filters/MainFilterRow';
import { useTransactionFilter } from 'components/filters/transactionFilter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppState } from 'store';
import { PaginatedResponse } from 'types/paginationTypes';
import { BANK_DEPOSITS_PAGE_CLICK_DEPOSIT_AMOUNT_LINK } from 'utils/clevertapEvents';
import PageTitle from '../../../components/PageTitle';
import { getRefundsInfiniteScroll } from '../request/getDonations';
import BankDepositInfiniteLoader from './BankDepositInfiniteLoader';
import { DonationsStatus } from './BankDepositInfiniteLoader/BankDepositRow';
import BankDepositModal from './BankDepositModal';

export const BankDepositsView: React.FC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            bankTitle: t('menu.donations.bankDeposits'),
            deposits: t('dashboard.deposits'),
            total: t('dashboard.total'),
            depositsTab: t('bankDeposits.tabName'),
            refundsTab: t('refunds.tabName'),
        }),
        [t],
    );
    const navigate = useNavigate();
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const { trackEvent, trackOpenCloseEvent } = useTrackingContext();

    const { PATH } = useGasRouterContext();
    const tabHrefs = React.useMemo(
        () => [PATH.DONATIONS.BANK_DEPOSITS, PATH.DONATIONS.REFUNDS],
        [PATH.DONATIONS.BANK_DEPOSITS, PATH.DONATIONS.REFUNDS],
    );

    const [timeFrame, setTimeFrame] = useTimeframeFilter();
    const [transactionFilterValue, setTransactionFilterValue] =
        useTransactionFilter();
    const [hasRefunds, setHasRefunds] = useState(false);
    const [refundsRequestData, makeRefundsRequest] =
        useApiRequest<PaginatedResponse<DonationRow>>();
    useEffect(() => {
        makeRefundsRequest(
            getRefundsInfiniteScroll(
                doneeId,
                1,
                TimeFrameValues['lifetime'],
                [],
            ),
        );
    }, [doneeId, makeRefundsRequest]);
    useEffect(() => {
        if (refundsRequestData.type !== 'REQUEST_SUCCESS') return;
        if (refundsRequestData.response.data?.length > 0) setHasRefunds(true);
    }, [refundsRequestData]);
    const [donation, setDonation] = useState<DonationsStatus | undefined>();
    const handleClose = useCallback(() => {
        setDonation(undefined);
        trackOpenCloseEvent(false, MODAL_NAME.DepositDetails);
    }, [trackOpenCloseEvent]);
    const handleOpen = useCallback(
        (donation: DonationsStatus) => {
            trackEvent(BANK_DEPOSITS_PAGE_CLICK_DEPOSIT_AMOUNT_LINK);
            setDonation(donation);
            trackOpenCloseEvent(true, MODAL_NAME.DepositDetails);
        },
        [trackEvent, trackOpenCloseEvent],
    );
    const handleTabCahnge = useCallback(
        (index: number) => {
            if (index === 1) {
                navigate(tabHrefs[1]);
            }
        },
        [navigate, tabHrefs],
    );
    const tabOptions = hasRefunds
        ? [
              {
                  label: copy.depositsTab,
                  tabHref: tabHrefs[0],
                  id: 'bank-deposits-tab',
              },
              {
                  label: copy.refundsTab,
                  tabHref: tabHrefs[1],
                  id: 'refunds-tab',
              },
          ]
        : [
              {
                  label: copy.depositsTab,
                  tabHref: tabHrefs[0],
                  id: 'bank-deposits-tab',
              },
          ];
    return (
        <>
            <PageTitle description="Bank Deposits" title="Bank Deposits" />
            <MainFilterRow
                page="bankDeposit"
                tabProps={{
                    value: 0,
                    options: tabOptions,
                    onChange: handleTabCahnge,
                }}
                timeFrameFilterProps={{
                    value: timeFrame,
                    onChange: setTimeFrame,
                }}
                title={copy.bankTitle}
                totalBannerProps={{
                    position: 'row',
                    size: 'small',
                    mode: 'real',
                    topText: copy.total,
                    bottomText: copy.deposits,
                }}
                transactionFilterProps={{
                    value: transactionFilterValue,
                    onChange: setTransactionFilterValue,
                }}
            />
            <BankDepositModal donation={donation} handleClose={handleClose} />
            <BankDepositInfiniteLoader
                doneeId={doneeId}
                handleOpen={handleOpen}
                timeFrame={timeFrame}
                transactionFilter={transactionFilterValue}
            />
        </>
    );
};
