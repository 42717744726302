import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GivelifyLoading } from '@givelify/givelify-ui';
import { CaptivePortalProvider } from '@givelify/onboarding';
import {
    TrackingProvider,
    registerUnauthorizedInterceptor,
} from '@givelify/utils';
import { useGivelifyDetector } from 'GivelifyDetector';
import { Layout as MainLayout } from 'layout';
import HeaderDropdowns from 'layout/components/header/Dropdowns';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { setDonee } from 'store/donee/actions';
import { loadDonee } from 'store/donee/thunks';
import { showLoader } from 'store/system/actions';
import { loadData, changeCampus } from 'store/system/thunks';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { webConfig } from 'webConfig';
import { AppActions, AppState } from '../store';
import { setAccessToken } from '../store/user/actions';
import { isLoggedIn } from '../store/user/types';
import { useDoneeIdFromQuery } from './GasRouterProvider';
import { PATH } from './routes';
import { useRouterBlocker } from './useRouterBlockr';

const SecuredRoutes: React.FC = () => {
    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();
    const location = useLocation();
    const { loggedIn } = useSelector((state: AppState) => ({
        loggedIn: isLoggedIn(state),
    }));

    const doneeIdFromQuery = useDoneeIdFromQuery();
    const [dataIsLoaded, setDataIsLoaded] = useState(false);

    React.useEffect(() => {
        registerUnauthorizedInterceptor(() => {
            dispatch(setAccessToken(undefined));
            if (window.mixpanel?.initialized) {
                mixpanel.reset();
            }
        });
        //run only once
        //eslint-disable-next-line
    }, []);

    const onPageLoad = useCallback(async () => {
        if (!loggedIn) {
            return;
        }

        dispatch(showLoader(true));
        const result = await dispatch(loadData(doneeIdFromQuery));
        if (!result) {
            dispatch(setAccessToken(undefined));
            return;
        }

        dispatch(showLoader(false));
        setDataIsLoaded(true);
        //listen only to loggedIn change
        //eslint-disable-next-line
    }, [loggedIn]);

    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);

    const { isBlocked } = useRouterBlocker();

    if (!loggedIn) {
        const returnUrl = location.pathname + location.search;
        const redirectTo = returnUrl
            ? `${PATH.DONEE.LOGIN}?returnUrl=${encodeURIComponent(returnUrl)}`
            : PATH.DONEE.LOGIN;
        return <Navigate replace to={redirectTo} />;
    }

    if (!dataIsLoaded) {
        return <GivelifyLoading type="linear" />;
    }

    return isBlocked ? (
        <Navigate replace to={PATH.NOT_FOUND} />
    ) : (
        <CampusChangeHandler />
    );
};

const CampusChangeHandler = () => {
    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();
    const { donee, mainDoneeId, campuses, enabledFeatures } = useSelector(
        (state: AppState) => ({
            donee: state.Donee.donee,
            mainDoneeId: state.Donee.campuses
                ? state.Donee.campuses[0].id
                : null,
            campuses: state.Donee.campuses,
            enabledFeatures: state.System.enabledFeatures,
        }),
    );

    useGivelifyDetector();

    const doneeIdFromQuery = useDoneeIdFromQuery();
    const currentDoneeId = donee?.id;
    const shouldBeDoneeId = doneeIdFromQuery || mainDoneeId;
    const shouldChangeDonee = currentDoneeId !== shouldBeDoneeId;

    const [showLoader, setShowLoader] = useState(shouldChangeDonee);

    useEffect(() => {
        if (!shouldChangeDonee) return;

        setShowLoader(true);
        (async () => {
            await dispatch(changeCampus(shouldBeDoneeId));
            setShowLoader(false);
        })();
    }, [dispatch, shouldChangeDonee, shouldBeDoneeId]);

    const {
        captivePortalEnabled,
        bankVerificationEnabled,
        taxIdEnabled,
        primaryRepEnabled,
        inviteOfficialEnabled,
    } = useMemo(
        () => ({
            captivePortalEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.CAPTIVE_PORTAL,
                false,
            ),
            taxIdEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.ONBOARDING_EIN_TAX_ID,
                false,
            ),
            bankVerificationEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.ONBOARDING_BANK_VERIFICATION,
                false,
            ),
            primaryRepEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.ONBOARDING_PRIMARY_REP,
                false,
            ),
            inviteOfficialEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.ONBOARDING_INVITE_OFFICIAL,
                false,
            ),
        }),
        [enabledFeatures],
    );

    if (showLoader || shouldChangeDonee) {
        return <GivelifyLoading type="linear" />;
    }

    return (
        <TrackingProvider trackPageVisit={false}>
            <CaptivePortalProvider
                bankVerificationEnabled={bankVerificationEnabled}
                campuses={campuses}
                donee={donee}
                featureEnabled={captivePortalEnabled}
                headerDropdowns={<HeaderDropdowns />}
                inviteOfficialEnabled={inviteOfficialEnabled}
                inviteUserPath={PATH.SETTINGS.USERS}
                loadDonee={() => dispatch(loadDonee(false, doneeIdFromQuery))}
                overviewPath={PATH.OVERVIEW}
                primaryRepEnabled={primaryRepEnabled}
                setDonee={(donee) => dispatch(setDonee(donee))}
                settingsPath={PATH.SETTINGS.APP_PROFILE}
                skipOnboarding={webConfig.skipOnboarding}
                taxIdEnabled={taxIdEnabled}
            >
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </CaptivePortalProvider>
        </TrackingProvider>
    );
};

export default SecuredRoutes;
