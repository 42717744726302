import React, { useMemo } from 'react';
import { DesignTokens, GivelifyIcon, GivelifyLabel } from '@givelify/ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import { Box, Divider, LockRow, TitleRow } from './styles';

export type SettingsPageProps = {
    title: string;
    titleId?: string;
    titleSecondRow?: string;
    description?: string;
    useDivider?: boolean;
    pageName: PAGE_NAME;
    trackPageVisit: boolean;
    safeAndSecure?: boolean;
};

export const SettingsPage: React.FC<SettingsPageProps> = ({
    pageName,
    title,
    trackPageVisit,
    children,
    description,
    safeAndSecure,
    titleId,
    titleSecondRow,
    useDivider,
}) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            secure: t('labels.secure'),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        <TrackingProvider pageName={pageName} trackPageVisit={trackPageVisit}>
            <PageTitle description={description} title={title} />
            <Box>
                <TitleRow>
                    <GivelifyLabel
                        id={titleId}
                        text={title}
                        variant={isMobile ? 'heading1S' : 'heading1M'}
                    />
                    {safeAndSecure && !isMobile ? (
                        <LockRow>
                            <GivelifyIcon
                                color={DesignTokens.color.textSecondary}
                                fontSize={21}
                                variant="lock-old"
                            />
                            <GivelifyLabel
                                color={DesignTokens.color.textSecondary}
                                text={copy.secure}
                                variant="body1"
                            />
                        </LockRow>
                    ) : null}
                </TitleRow>
                {titleSecondRow ? (
                    <TitleRow>
                        <GivelifyLabel
                            color={DesignTokens.color.textSecondary}
                            marginTop={1}
                            text={titleSecondRow}
                            variant="heading3S"
                        />
                    </TitleRow>
                ) : null}
                {useDivider ? <Divider /> : null}
                {children}
            </Box>
        </TrackingProvider>
    );
};
