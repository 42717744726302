import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
    container: {
        padding: '24px 24px 16px 24px',
        height: '100%',
        border: `1px solid ${theme.colors.neutralPlatinum}`,
        background: theme.colors.primaryWhite,
        borderRadius: 4,

        '&:hover': {
            background: theme.colors.neutralHoverGrey,
            cursor: 'pointer',

            '& $title': {
                color: theme.colors.accentDarkBlue,
                textDecoration: 'underline',
            },
        },
    },
    title: {
        marginBottom: 16,
        marginTop: 10,
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '26px',
        textAlign: 'left',

        '&:hover': {
            color: theme.colors.accentDarkBlue,
        },
    },
}));
