import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const EnvelopeSummaryWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
});

export const NameWrapper = styled('div')({
    width: 'calc(50% - 8px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
});

export const Divider = styled('div')(({ theme }) => ({
    width: '16px',
    height: '2px',
    backgroundColor: DesignTokens.color.dividerPrimaryDark,
    marginTop: '15px',
    [theme.breakpoints.down('mobile')]: {
        marginTop: '11px',
    },
}));

export const InfoPart = styled('div')({
    width: 'calc(50% - 8px)',
});

export const SkeletonWrapperSmall = styled('div')(({ theme }) => ({
    width: '100px',
    height: '32px',
    [theme.breakpoints.down('mobile')]: {
        width: '100px',
        height: '24px',
    },
}));

export const SkeletonWrapperMiddle = styled('div')(({ theme }) => ({
    width: '130px',
    height: '32px',
    [theme.breakpoints.down('mobile')]: {
        width: '100px',
        height: '24px',
    },
}));

export const SkeletonWrapperLarge = styled('div')(({ theme }) => ({
    width: '200px',
    height: '24px',
    [theme.breakpoints.down('mobile')]: {
        width: '100px',
        height: 'auto',
    },
}));
