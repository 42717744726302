import { Reducer } from 'redux';
import { DoneeNotificationActions, DoneeNotificationState } from './types';

const initialState: DoneeNotificationState = {
    hideContactInfoNotification: false,
    hideInviteFaithLeaderNotification: false,
    hideResendInviteNotification: false,
    hideOnboardingProfileMessage: false,
    hideOnboardingVerifiedMessage: false,
    startedOnboardingV2: false,
    hideGivingTuesday2023Modal: false,
    hideVideoCardsModal: false,
};

const NotificationsReducer: Reducer<
    DoneeNotificationState,
    DoneeNotificationActions
> = (state = initialState, action) => {
    switch (action.type) {
        case 'LOAD_DONEE_NOTIFICATION_STATE_SUCCESS': {
            const res = { ...action.data };
            return res;
        }
        case 'SET_NOTIFICATION': {
            return {
                ...state,
                [action.data.notification]: action.data.value,
            };
        }
    }
    return state;
};

export default NotificationsReducer;
