import { GivelifyLink } from '@givelify/ui';
import { styled } from '@mui/material';
import { colorsList } from 'pages/overview/components/styles';

export const LegendBlock = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto',
    [theme.breakpoints.down('mobile')]: {
        gridTemplateColumns: '1fr 1fr',
        marginBottom: theme.spacing(1),
    },
}));
export const LegendItem = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('mobile')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export const LegendIcon = styled('div', {
    shouldForwardProp: (prop) => prop !== 'index' && prop !== 'legendColor',
})<{ index: number; legendColor?: string }>(({ index, legendColor }) => ({
    backgroundColor: legendColor || colorsList[index % colorsList.length],
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: 100,
    marginRight: 8,
    flexShrink: 0,
}));

export const LegendText = styled(GivelifyLink, {
    shouldForwardProp: (prop) => prop !== 'isChartDrillDownEnabled',
})<{ isChartDrillDownEnabled?: boolean }>(({ isChartDrillDownEnabled }) => ({
    cursor: 'pointer',
    fontWeight: isChartDrillDownEnabled ? 800 : 'bold',
    '&:hover': {
        cursor: isChartDrillDownEnabled ? 'pointer' : 'default',
        color: isChartDrillDownEnabled ? 'default' : 'black',
        textDecoration: isChartDrillDownEnabled ? 'underline' : 'none',
        textUnderlinePosition: 'under',
    },
}));
