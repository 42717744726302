import React from 'react';
import {
    GivelifyTextField,
    GivelifyTextFieldProps,
} from '@givelify/givelify-ui';
import { Controller, FieldName, UseFormMethods } from 'react-hook-form';
//eslint-disable-next-line
export type InputProps<Form = Record<string, any>> = Omit<
    GivelifyTextFieldProps,
    'name'
> & {
    name: FieldName<Form>;
    formRef: UseFormMethods<Form>;
};
//eslint-disable-next-line
export const TextInput = <Form extends {}>(Props: InputProps<Form>) => {
    const { formRef, defaultValue, ...inputProps } = Props;

    const { errors, control } = formRef;
    const name = inputProps.name.toString();

    const error = errors[name];
    const massage = error && error.message ? error.message : '';

    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            render={({ name, onBlur, onChange, ref, value }) => (
                <GivelifyTextField
                    ariaLabel="Password input"
                    defaultValue={defaultValue}
                    id="password"
                    inputRef={ref}
                    leftHelperText={massage}
                    onBlur={onBlur}
                    onChange={onChange}
                    state={massage ? 'error' : 'normal'}
                    type="password"
                    value={value}
                    {...inputProps}
                    name={name}
                />
            )}
        />
    );
};
