import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { Stack } from '@mui/material';
import NoResultsSvg from 'assets/illustration/no-results.svg';
import { useTranslation } from 'react-i18next';
import { TextWrapper, EmptyBox } from './styles';

export type NoResultsProps = {
    search: string;
    hasFilters?: boolean;
    name?: string;
};

const TableNoResultsComponent: React.FC<NoResultsProps> = ({
    search,
    hasFilters = false,
    name,
}) => {
    const { t } = useTranslation();
    const hasSearch = Boolean(search);
    let mainText;
    let subText;

    if (hasFilters && hasSearch) {
        mainText = t('components.noResults.noSearchFilters');
        subText = t('components.noResults.noSubTextSearchFilters');
    } else if (hasFilters && !hasSearch) {
        mainText = t('components.noResults.noSelectedFilters');
        subText = t('components.noResults.noSubTextSelectedFilters');
    } else if (!hasFilters && hasSearch) {
        mainText = t('components.noResults.noSearch');
        subText = t('components.noResults.noSubTextSearch');
    }
    return (
        <EmptyBox
            alignItems="center"
            data-testid={`no-${name}-results`}
            gap={3}
        >
            <img
                alt={`No ${name} results`}
                data-testid={`no-${name}-results-icon`}
                src={NoResultsSvg}
            />
            <Stack
                alignItems="center"
                data-testid={`no-${name}-results-text`}
                gap={1}
                justifyContent="center"
            >
                <TextWrapper>
                    <GivelifyLabel
                        data-testid={`no-${name}-main-text`}
                        text={mainText}
                        variant="heading1S"
                    />
                    {hasSearch ? (
                        <GivelifyLabel
                            bold
                            data-testid={`no-${name}-search-text`}
                            text={`“${search}”`}
                            variant="heading1S"
                        />
                    ) : null}
                </TextWrapper>
                <GivelifyLabel
                    data-testid={`no-${name}-sub-text`}
                    text={subText}
                />
            </Stack>
        </EmptyBox>
    );
};

export const TableNoResults = React.memo(TableNoResultsComponent);
