import { styled } from '@mui/material';

export const BarChartWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    marginTop: '16px',
    '& .recharts-surface': {
        overflow: 'initial',
    },
    [theme.breakpoints.down('tablet')]: {
        marginTop: '8px',
    },
}));
