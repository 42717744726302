import React from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './styles';
import { GivelifyIcon } from '../components/GivelifyIcon';

export interface GivelifySnackbarProps extends SnackbarProps {
    hasCloseButton?: boolean;
}

export function GivelifySnackbar({
    open,
    onClose,
    className,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    },
    autoHideDuration = 3000,
    hasCloseButton = false,
    ...rest
}: GivelifySnackbarProps) {
    const classes = useStyles();

    const handleCloseClick = (event: React.SyntheticEvent<any>) => {
        if (onClose) onClose(event, 'timeout');
    };

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            className={`${className ?? ''} ${classes.root}`}
            data-testid="givelify-snackbar"
            action={
                hasCloseButton ? (
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseClick}
                    >
                        <GivelifyIcon
                            variant="close"
                            size="13px"
                            color="inherit"
                        />
                    </IconButton>
                ) : null
            }
            {...rest}
        />
    );
}
