import { getAxiosClient } from '@givelify/utils';
import { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { makeApiRequest } from '../../api/utils/makeApiRequest';
import {
    AutoIntegrationMatchNumberResponse,
    SetupCCBFormData,
    SetupF1GoFormData,
} from '../../pages/integrations/IntegrationContent/TabsContent/AutoIntegrationContent/types';
import { webConfig } from '../../webConfig';
import { AppActions, AppState } from '../index';
import { httpGet, httpPost } from '../thunks';
import {
    LoadTotalUnmatchedDonorsStateSuccess,
    LoadTotalUnmatchedEnvelopesStateSuccess,
    LoadTotalUnmatchedStateStart,
    setIntegration,
} from './action';
import { AutoIntegration } from './types';

export const getIntegration = (
    doneeId: number,
): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> => {
    return async (dispatch) => {
        const httpRequest = getAxiosClient().get(
            `/donees/${doneeId}/integration/details`,
        );
        const response = await makeApiRequest<AutoIntegration>(httpRequest);

        dispatch(setIntegration(response.response ?? null));
        return true;
    };
};

export const LoadTotalUnmatchedStateAsync =
    (): ThunkAction<void, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        dispatch(LoadTotalUnmatchedStateStart());
        const parentCampus = getState().Donee.campuses[0];
        if (!parentCampus) return;

        const doneeId = parentCampus.id;
        const loadUnmatched = async () => {
            const response: AxiosResponse<AutoIntegrationMatchNumberResponse> =
                await httpGet(`/donees/${doneeId}/integration/stats`)(dispatch);

            if (response.status !== 200) return;

            dispatch(
                LoadTotalUnmatchedDonorsStateSuccess(
                    'donors',
                    response.data.data.donors.unmatched,
                ),
            );
            dispatch(
                LoadTotalUnmatchedEnvelopesStateSuccess(
                    'envelopes',
                    response.data.data.envelopes.unmatched,
                ),
            );
        };

        await Promise.all([loadUnmatched()]);
    };

export const newCCBIntegration = (
    data: SetupCCBFormData,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return async (dispatch) => {
        const { data: resData } = await httpPost(
            `${webConfig.baseUrl}/donees/${doneeId}/integration/registration`,
            {
                type: 'CCB',
                credentials: data,
            },
        )(dispatch);
        dispatch(setIntegration(resData));
    };
};

export const newF1GoIntegration = (
    data: SetupF1GoFormData,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return async (dispatch) => {
        const { data: resData } = await httpPost(
            `${webConfig.baseUrl}/donees/${doneeId}/integrations`,
            {
                type: 'F1GO',
                credentials: data,
            },
        )(dispatch);
        console.log('resData', resData);
        dispatch(setIntegration(resData));
    };
};
