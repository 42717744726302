import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgPasswordVisible = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>password-vidible</title>
        <path
            clipRule="evenodd"
            d="M12 18c-3.146 0-6.26-2.195-8.166-6C5.74 8.195 8.854 6 12 6c3.146 0 6.26 2.195 8.166 6-1.907 3.805-5.02 6-8.166 6Zm0-13.5c-3.99 0-7.626 2.834-9.678 7.18L2.17 12l.152.32C4.374 16.666 8.01 19.5 12 19.5c3.99 0 7.626-2.834 9.678-7.18l.151-.32-.151-.32C19.626 7.334 15.989 4.5 12 4.5ZM9.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const PasswordVisibleIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgPasswordVisible}
        fontSize={fontSize}
    />
);
