import React, { useMemo } from 'react';
import {
    GivelifyBox,
    GivelifyLabel,
    GivelifyLoading,
} from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import matchingPercent from 'utils/matchingPercent';

interface Props {
    tooltipMessage: string;
    width?: number;
    maxWidth?: number;
    infoText?: string;
}

const useStyle = makeStyles<Theme>((theme) => ({
    root: {
        position: 'relative',
        height: 50,
    },
    tooltip: {
        position: 'absolute',
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(2, 3),
        borderRadius: 50,
        width: 'max-content',
        bottom: '16px',
        left: (percent) => percent + '%',
        transform: (percent) => {
            if ((percent as number) < 20) {
                return 'translateX(-20%)';
            } else if ((percent as number) > 80) {
                return 'translateX(-80%)';
            } else {
                return 'translateX(-50%)';
            }
        },
    },
    triangle: {
        bottom: 0,
        left: (percent) => percent + '%',
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        position: 'absolute',
        borderTop: `16px solid ${theme.palette.background.paper}`,
        zIndex: 5,
        transform: 'translateX(-50%)',
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        background: '#CCC',
        '& > .MuiLinearProgress-barColorPrimary': {
            background: (percent: number) =>
                `linear-gradient(270deg, #B3CD02 ${percent}%, #715AFF ${
                    100 - percent
                }%)`,
        },
    },
    infoText: {
        marginBottom: -35,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
        },
    },
    matchNumber: {
        fontWeight: 400,
        marginLeft: 'auto',
    },
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 'auto auto',
        rowGap: '14px',
    },
    loading: {
        gridColumn: '1 / 3',
    },
}));

export const TooltipProgressBar: React.FC<Props> = (props) => {
    const { infoText } = props;

    const { matchTotal, total } = useSelector((state: AppState) => ({
        matchTotal: state.Integrations.progressBarMatchedValue,
        total: state.Integrations.progressBarTotalValue,
    }));

    const percent = useMemo(
        () => matchingPercent(matchTotal, total),
        [matchTotal, total],
    );
    const classes = useStyle(percent);

    return (
        <GivelifyBox className={classes.wrapper}>
            <GivelifyLabel bold text={infoText} variant="body2" />
            <GivelifyLabel text={`${matchTotal} of ${total}`} variant="body2" />
            <GivelifyLoading
                className={classes.loading}
                type="linear"
                value={percent}
            />
        </GivelifyBox>
    );
};
