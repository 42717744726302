import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const errorTextStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorText: {
            color: '#DF5E61',
            borderRadius: 4,
            display: 'inline-block',
            marginTop: theme.spacing(3),
            backgroundColor: 'rgba(213, 41, 44, 0.1)',
            padding: theme.spacing(2, 4),
            width: '100%',
        },
    }),
);
export const ErrorText: React.FC<{
    text: string | React.ReactChildren | React.ReactChild;
}> = ({ text }) => {
    const { errorText } = errorTextStyles();
    return <div className={errorText}>{text}</div>;
};
