import React from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { BankDepositsView } from './BankDepositsView';

export const BankDeposits: React.FC = () => {
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.BankDeposits}>
            <BankDepositsView />
        </TrackingProvider>
    );
};
