export enum FORMAT {
    EXCEL = 'EXCEL',
    CSV = 'CSV',
}

export const getFormatFilter = (value: FORMAT): string => {
    switch (value) {
        case FORMAT.CSV:
            return 'csv';
        default:
        case FORMAT.EXCEL:
            return 'xls';
    }
};

export const getFormatType = (value): string => {
    switch (value) {
        case 'ACS':
            return 'ACS';
        case 'POWERCHURCH':
            return 'PC';
        case 'ServantKeeper':
            return 'SK';
        case 'ACS REALM':
            return 'ACSR';
        case 'SHELBY ARENA':
            return 'Arena';
        case 'SHELBYNEXT':
            return 'SNXT';
        case 'RECONCILIATION':
            return 'bank_deposits';
        default:
            return '';
    }
};

export const getDisplayType = (value): string => {
    switch (value) {
        case 'RECONCILIATION':
            return 'BANK DEPOSITS';
        default:
            return value;
    }
};

export const LegacyXlsFormatVisible = ['donations', 'donors', 'import-friendly'];