import AddCampus from './AddCampus';
import CampusSubmittedModal from './AddCampus/CampusSubmittedModal';
import CampusList from './CampusList';

const CampusesView = () => {
    return (
        <>
            <CampusList />
            <AddCampus />
            <CampusSubmittedModal />
        </>
    );
};

export default CampusesView;
