import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyErrorBanner,
    GivelifyLabel,
    GivelifyLabelStyles,
    GivelifyModal,
    GivelifyNotification,
} from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { EditUserInfo } from 'types/userTypes';
import { AccountOwnerInfo } from '../../../../../store/donee/types';
import { confirmationModalStyle } from './ConfirmationModalStyle';

interface ChangeAccountOwnerModalProps {
    onChangeAccountOwnerModalClose: () => void;
    onChangeAccountOwner: () => void;
    userInfo: EditUserInfo;
    userAvatar: string;
    accountOwnerInfo: AccountOwnerInfo;
    changeAccountOwnerError?: string;
    isChangingAccountOwner?: boolean;
}

const ChangeAccountOwnerModal: React.FC<ChangeAccountOwnerModalProps> = (
    props: ChangeAccountOwnerModalProps,
) => {
    const { accountOwnerInfo, userInfo, userAvatar } = props;

    const style = confirmationModalStyle();
    const { body1 } = GivelifyLabelStyles({});

    const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
    const curAccountOwnerFullName = `${accountOwnerInfo.firstName} ${accountOwnerInfo.lastName}`;
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            makeAccountOwner: t('pages.settings.users.make-account-owner'),
            confirm: t(
                'pages.settings.users.change-account-owner-modal.confirm',
            ),
            currentOwner: t(
                'pages.settings.users.change-account-owner-modal.current-owner',
            ),
            newOwner: t(
                'pages.settings.users.change-account-owner-modal.new-owner',
            ),
            confirmTip: t(
                'pages.settings.users.change-account-owner-modal.confirm-tip',
            ),
            confirmDescPart1: t(
                'pages.settings.users.change-account-owner-modal.confirm-desc.part1',
            ),
            confirmDescPart2: t(
                'pages.settings.users.change-account-owner-modal.confirm-desc.part2',
            ),
            confirmDescPart3: t(
                'pages.settings.users.change-account-owner-modal.confirm-desc.part3',
            ),
            you: t('pages.settings.users.change-account-owner-modal.you'),
            cancel: t('labels.cancel'),
            save: t('labels.transfer'),
        }),
        [t],
    );
    return (
        <GivelifyModal
            autoFullscreen
            open
            showCloseButton
            contentClassName={style.confirmationModal}
            contentContainerClassName={style.confirmationModalBody}
            headerClassName={style.modalHeader}
            name="change-account-owner-modal"
            onClose={props.onChangeAccountOwnerModalClose}
            width={702}
        >
            <div className={style.modalContainer}>
                <GivelifyLabel
                    className={style.statement}
                    marginBottom={8}
                    marginTop={32}
                    text={copy.confirm}
                    variant="heading3"
                />
                <span className={body1}>
                    {copy.confirmDescPart1}
                    <strong>{copy.confirmDescPart2}</strong>
                    {copy.confirmDescPart3}
                </span>
                {props.changeAccountOwnerError && (
                    <GivelifyErrorBanner
                        className={style.errorBanner}
                        marginTop={32}
                        text={props.changeAccountOwnerError}
                    />
                )}
                <GivelifyLabel
                    className={style.statement}
                    marginTop={16}
                    text={copy.currentOwner}
                    variant="heading4"
                />
                <div aria-label="User" className={style.usernameContainer}>
                    <div className={style.userAvatarWrapper}>
                        <GivelifyAvatar
                            color="grey"
                            size="medium"
                            src={accountOwnerInfo.avatar}
                        />
                    </div>
                    <div className={style.userNameContent}>
                        <div className={style.changeAccountOwnerNameWrapper}>
                            <GivelifyLabel
                                bold
                                className={style.fullName}
                                marginRight={5}
                                text={curAccountOwnerFullName}
                                variant="heading5"
                            />
                            <GivelifyLabel
                                className={style.you}
                                text={copy.you}
                                variant="caption2"
                            />
                        </div>
                        <GivelifyLabel
                            className={style.title}
                            text={accountOwnerInfo.title}
                            variant="small"
                        />
                    </div>
                </div>

                <GivelifyLabel
                    className={style.statement}
                    marginTop={16}
                    text={copy.newOwner}
                    variant="heading4"
                />
                <div aria-label="User" className={style.usernameContainer}>
                    <div className={style.userAvatarWrapper}>
                        <GivelifyAvatar
                            color="grey"
                            size="medium"
                            src={userAvatar}
                        />
                    </div>
                    <div className={style.userNameContent}>
                        <GivelifyLabel
                            bold
                            className={style.fullName}
                            text={fullName}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={style.title}
                            text={userInfo.title}
                            variant="small"
                        />
                    </div>
                </div>

                <GivelifyNotification
                    show
                    className={style.notification}
                    text={copy.confirmTip}
                    variant="warning"
                />
            </div>
            <div className={style.modalFooter}>
                <GivelifyButton
                    className={style.cancelButton}
                    disabled={props.isChangingAccountOwner}
                    marginRight={8}
                    name="cancelChangingAccountOwner"
                    onClick={props.onChangeAccountOwnerModalClose}
                    size="xLarge"
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    className={style.saveButton}
                    disabled={props.isChangingAccountOwner}
                    isLoading={props.isChangingAccountOwner}
                    marginLeft={8}
                    name="saveChangingAccountOwner"
                    onClick={props.onChangeAccountOwner}
                    size="xLarge"
                    text={copy.save}
                    variant="primary"
                />
            </div>
        </GivelifyModal>
    );
};

export default ChangeAccountOwnerModal;
