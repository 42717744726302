import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { TooltipProps } from 'recharts';
import { AppState } from 'store';
import { EnvelopeDistribution } from 'store/dashboard/envelopes/types';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { useAdvancedTranslation } from 'utils/i18n';
import DefaultLoading from '../../components/DefaultLoading';
import ZeroState from '../../ZeroState';
import PieChartWrapper, {
    NameType,
    ValueType,
} from '../components/PieChartWrapper';
import { tooltipStyle } from '../components/PieChartWrapper/SimplePieChart';

const envelopeError: {
    data: EnvelopeDistribution[];
} = {
    data: [
        {
            id: null,
            aggregatedIds: null,
            average: 0,
            count: 0,
            sum: 0,
            title: 'Unknown',
        },
    ],
};

const Loading: JSX.Element = (
    <FacebookLoading borderRadius={0} height={300} marginTop={4} width="100%" />
);

export const EnvelopeStatusTooltip: React.FC<
    TooltipProps<ValueType, NameType>
> = (props) => {
    if (props.payload.length > 0) {
        const data = props.payload[0];
        return (
            <div style={tooltipStyle}>
                <p>
                    <strong>{data.name}</strong>
                </p>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box fontWeight="bold" pr={2}>
                        Total:
                    </Box>
                    <span>
                        $
                        {formatMoneyWithCommas(
                            parseFloat(data.value.toString()),
                        )}
                    </span>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    pb={3}
                    width="100%"
                >
                    <Box fontWeight="bold" pr={2}>
                        Average:
                    </Box>
                    <span>${formatMoneyWithCommas(data.payload.average)}</span>
                </Box>
            </div>
        );
    }
    return null;
};

const src = '/overview/donor-giving-to.svg';
const srcGrey = '/overview/donor-giving-to-grey.svg';

const EnvelopesStatus: React.FC = () => {
    const { t, at } = useAdvancedTranslation();
    const { envelopesCurrent } = useSelector((state: AppState) => ({
        envelopesCurrent: state.Dashboard.Envelopes.current,
    }));

    const legendTitle = at('dashboard.donorsGivingTo.legendTitle');
    const { onboardingEnabled, onboardingCompleted } = useSelector(
        (state: AppState) => ({
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
        }),
    );
    return (
        <DefaultLoading
            error={envelopeError}
            loading={Loading}
            requestState={envelopesCurrent}
        >
            {(envelopes) => {
                if (envelopes.data.length === 0) {
                    return (
                        <ZeroState
                            src={
                                onboardingEnabled && onboardingCompleted
                                    ? src
                                    : srcGrey
                            }
                            type={t('donationData')}
                        />
                    );
                }
                const parsedEnvelopes = envelopes.data.map((env) =>
                    env.id !== null ? env : { ...env, title: 'Other' },
                );
                return (
                    <PieChartWrapper
                        chart={{
                            data: parsedEnvelopes,
                            dataKey: 'sum',
                            nameKey: 'title',
                            tooltip: EnvelopeStatusTooltip,
                        }}
                        legend={{
                            heading: legendTitle,
                            legends: parsedEnvelopes,
                            nameKey: 'title',
                        }}
                    />
                );
            }}
        </DefaultLoading>
    );
};

export default EnvelopesStatus;
