import { DesignTokens, GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const CustomizeProfileContainer = styled(Grid)(({ theme }) => ({
    maxWidth: '800px',
    marginTop: '32px',
    paddingLeft: '24px',
    gap: '40px',
    [theme.breakpoints.down('mobile')]: {
        padding: '16px',
        marginTop: '32px',
        boxShadow: DesignTokens.shadow.shadowMediumHigh,
        backgroundColor: DesignTokens.color.textWhite,
        borderRadius: DesignTokens.measurement.borderRadiusS,
        position: 'relative',
        gap: '16px',
    },
}));

export const CustomizeProfileTitle = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: '24px',
    color: DesignTokens.color.textPrimary,
    fontWeight: 700,
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        color: DesignTokens.color.textSecondary,
        fontWeight: 600,
    },
}));

export const CustomizeProfileDescription = styled(GivelifyLabel)(
    ({ theme }) => ({
        fontSize: '16px',
        color: DesignTokens.color.textSecondary,
        marginTop: '12px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '18px',
            color: DesignTokens.color.textPrimary,
            fontWeight: 800,
            marginTop: 0,
        },
    }),
);

export const CustomizeProfileButton = styled(GivelifyButton)(({ theme }) => ({
    marginTop: '24px',
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        height: '100%',
        opacity: 0,
        left: 0,
        top: 0,
        margin: 0,
        position: 'absolute',
    },
}));

export const CustomizeProfileImg = styled('img')(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        width: '43px',
        height: '74px',
    },
}));
