import { getAxiosClient, makeApiRequest, ApiResponse } from '@givelify/utils';
import { getTitlesUrl } from '../../consts/endpoints';
import { GetTitlesResponse } from '../../responses/titles/GetTitlesResponse';

export const getTitlesRequest = async (): Promise<
    ApiResponse<GetTitlesResponse>
> => {
    const httpRequest = getAxiosClient().get<GetTitlesResponse>(getTitlesUrl());
    const result = makeApiRequest<GetTitlesResponse>(httpRequest);
    return result;
};
