import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const Box = styled('div', {
    shouldForwardProp: (propName) => propName !== 'expanded',
})<{ expanded?: boolean }>(({ expanded, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: DesignTokens.color.textWhite,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    boxShadow: DesignTokens.shadow.shadowMedium,
    padding: theme.spacing(3),
    width: 230,
    height: 360,
    overflow: 'hidden',
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        padding: theme.spacing(2),
        height: expanded ? '100%' : 72,
    },
    flexShrink: 0,
}));

export const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    '& .expand': {
        marginLeft: 'auto',
        display: 'none',
    },
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& .expand': {
            display: 'block',
        },
    },
}));

export const NameBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('mobile')]: {
        alignItems: 'flex-start',
    },
    textWrap: 'nowrap',
    overflow: 'hidden',
}));

export const DetailsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& .addr-box': {
        alignItems: 'flex-start',
    },
    [theme.breakpoints.down('mobile')]: {
        marginTop: theme.spacing(2),
    },
}));

export const DetailsRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& .email-link': {
        lineBreak: 'anywhere',
    },
}));

export const Divider = styled('div')(({ theme }) => ({
    width: '100%',
    height: 1,
    backgroundColor: DesignTokens.color.dividerPrimaryLight,
    margin: theme.spacing(1.5, 0),
}));

export const DetailsRowNoIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
}));

export const FlagImg = styled('img')(({ theme }) => ({
    width: 17,
    height: 13,
}));
