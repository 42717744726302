import { useMemo } from 'react';
import { GivelifyBreadcrumbs, GivelifyBreadcrumbsOption } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { OrganizationType } from '../../../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../../../consts';

interface TaxIdInfoBreadcrumbs {
    active: 'ein' | 'organizationInfo' | 'summary';
    disableNavigation: boolean;
    onClick: (
        step: 'ein' | 'organizationInfo' | 'yearFounded' | 'summary',
    ) => void;
    organizationType: OrganizationType;
    taxIdCompleted?: boolean;
    orgInfoCompleted?: boolean;
    summaryCompleted?: boolean;
}

export const TaxIdInfoBreadcrumbs: React.FC<TaxIdInfoBreadcrumbs> = ({
    active,
    disableNavigation,
    onClick,
    organizationType,
    taxIdCompleted,
    orgInfoCompleted,
    summaryCompleted,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const copy = useMemo(
        () => ({
            ein: t('taxId.ein'),
            organizationInfo: t('taxId.organizationInfo'),
            summary: t('taxId.summary'),
            yearFounded: t('taxId.yearFounded'),
        }),
        [t],
    );

    const breadcrumbsOptions: GivelifyBreadcrumbsOption[] = [
        {
            id: 'ein',
            label: copy.ein,
            onClick: () => onClick('ein'),
            isCompleted: taxIdCompleted,
        },
        {
            id: 'organizationInfo',
            label:
                organizationType === 'church'
                    ? copy.organizationInfo
                    : copy.yearFounded,
            onClick: () =>
                onClick(
                    organizationType === 'church'
                        ? 'organizationInfo'
                        : 'yearFounded',
                ),
            isCompleted: orgInfoCompleted,
        },
        {
            id: 'summary',
            label: copy.summary,
            onClick: () => onClick('summary'),
            isCompleted: summaryCompleted,
        },
    ];

    return (
        <GivelifyBreadcrumbs
            active={active}
            disableNavigation={disableNavigation}
            options={breadcrumbsOptions}
        />
    );
};
