import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    matchHeader: {
        padding: theme.spacing(3, 0, 4),
    },
    descriptionLabel: {
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));
