import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { DonationRow } from 'api/models';
import { TRANSACTION_FILTER_VALUE } from 'components';
import { getDonationQueryString } from 'store/donations/donation/types';
import { PaginatedResponse } from 'types/paginationTypes';

export const getRefundsUrl = (
    doneeId: number,
    pageNumber: number,
    timeFrame: TimeFrameValue,
    envelopeIds: number[],
) =>
    `/donees/${doneeId}/donations` +
    getDonationQueryString({
        timeFrame: timeFrame,
        envelopeIds,
        page: pageNumber,
        transactionFilter: TRANSACTION_FILTER_VALUE.REFUNDED,
    });

export const getRefundsInfiniteScroll = (
    doneeId: number,
    pageNumber: number,
    timeFrame: TimeFrameValue,
    envelopeIds: number[],
): Promise<ApiResponse<PaginatedResponse<DonationRow>>> => {
    const url = getRefundsUrl(doneeId, pageNumber, timeFrame, envelopeIds);
    const httpRequest = getAxiosClient().get(url);
    return makeApiRequest<PaginatedResponse<DonationRow>>(httpRequest);
};
