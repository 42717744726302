import dayjs from 'dayjs';
import { getDefaultStartDate } from '../dateUtils';

export type TimeFrameId =
    | 'today'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'thisYear'
    | 'lastYear'
    | 'last30Days'
    | 'last90Days'
    | 'last12Months'
    | 'lifetime'
    | 'custom';

export const TimeFrameIds: TimeFrameId[] = [
    'thisWeek',
    'lastWeek',
    'thisMonth',
    'lastMonth',
    'thisYear',
    'lastYear',
    'lifetime',
    'last12Months',
    'last30Days',
    'last90Days',
    'today',
    'yesterday',
    'custom',
];

export const TimeFrameFilterIds: TimeFrameId[] = [
    'thisWeek',
    'lastWeek',
    'thisMonth',
    'lastMonth',
    'thisYear',
    'lastYear',
    'lifetime',
    'custom',
];

export type TimeFrameValue = {
    selector: TimeFrameId;
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
};

export const TimeFrameIdsInvariant: string[] = TimeFrameIds.map((x) =>
    x.toLocaleLowerCase(),
);
export type TimeFrameList = { [id in TimeFrameId]: TimeFrameValue };

export const TimeFrameLegacyIds: { [key in TimeFrameId]: string } = {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisYear: 'This Year',
    lastYear: 'Last Year',
    last30Days: 'Last 30 Days',
    last90Days: 'Last 90 Days',
    last12Months: 'Last 12 Months',
    lifetime: 'Lifetime',
    custom: 'Custom dates',
};

const getTimeFrameList = (options?: {
    excludeToday?: boolean;
    today?: dayjs.Dayjs;
}): TimeFrameList => {
    const today = options?.today
        ? options.today
        : options?.excludeToday
        ? dayjs().tz().subtract(1, 'day').endOf('day')
        : dayjs().tz();
    return {
        today: {
            selector: 'today',
            start: today.startOf('day'),
            end: today,
        },
        yesterday: {
            selector: 'yesterday',
            start: today.subtract(1, 'day').startOf('day'),
            end: today.subtract(1, 'day').endOf('day'),
        },
        last30Days: {
            selector: 'last30Days',
            start: today.subtract(30, 'day').startOf('day'),
            end: today,
        },
        last90Days: {
            selector: 'last90Days',
            start: today.subtract(90, 'day').startOf('day'),
            end: today,
        },
        last12Months: {
            selector: 'last12Months',
            start: today.subtract(12, 'month').startOf('day'),
            end: today,
        },
        thisWeek: {
            selector: 'thisWeek',
            start: today.startOf('week'),
            end: today,
        },
        lastWeek: {
            selector: 'lastWeek',
            start: today.subtract(1, 'week').startOf('week'),
            end: today.subtract(1, 'week').endOf('week'),
        },
        thisMonth: {
            selector: 'thisMonth',
            start: today.startOf('month'),
            end: today,
        },
        lastMonth: {
            selector: 'lastMonth',
            start: today.subtract(1, 'month').startOf('month'),
            end: today.subtract(1, 'month').endOf('month'),
        },
        thisYear: {
            selector: 'thisYear',
            start: today.startOf('year'),
            end: today,
        },
        lastYear: {
            selector: 'lastYear',
            start: today.subtract(1, 'year').startOf('year'),
            end: today.subtract(1, 'year').endOf('year'),
        },
        lifetime: {
            selector: 'lifetime',
            start: dayjs(getDefaultStartDate()),
            end: today,
        },
        custom: {
            selector: 'custom',
            start: options?.excludeToday
                ? today.subtract(1, 'day').startOf('day')
                : today,
            end: today,
        },
    };
};

export let TimeFrameValues = getTimeFrameList();

export const regenerateTimeFrameList = (options?: {
    excludeToday?: boolean;
    today?: dayjs.Dayjs;
}) => {
    TimeFrameValues = getTimeFrameList(options);
};

export const ExcludeTodayTimeFrameValues = getTimeFrameList({
    excludeToday: true,
});

export const toRangeString = (
    value: string,
    timeFrame: TimeFrameValue,
    format: string,
    single?: boolean,
): string => {
    const startString = timeFrame.start.format(format);
    const endString = timeFrame.end.format(format);
    const label = single ? `${startString}` : `${startString} - ${endString}`;
    return value ? `${value}: ${label}` : `${label}`;
};

export const toCustomRangeString = (
    value: string,
    timeFrame: TimeFrameValue,
): string => {
    return toRangeString(value, timeFrame, 'MMM DD YYYY');
};

export const selectorWithRangeValueToString = (
    value: string,
    timeFrame: TimeFrameValue,
): string => {
    switch (timeFrame.selector) {
        case 'thisWeek':
        case 'lastWeek':
            return toRangeString(value, timeFrame, 'MMM DD');
        case 'thisMonth':
        case 'lastMonth':
            return toRangeString(value, timeFrame, 'MMM YYYY', true);
        case 'thisYear':
        case 'lastYear':
            return toRangeString(value, timeFrame, 'YYYY', true);
        default:
            return value;
    }
};
