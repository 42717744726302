import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
    AutocompleteOption,
    GivelifyAutocomplete,
    GivelifyAutocompleteProps,
} from '../autocomplete';

export const GivelifyFormAutocomplete: React.FC<GivelifyAutocompleteProps> = (
    props,
) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ onChange, value }, { invalid }) => {
                return (
                    <GivelifyAutocomplete
                        {...props}
                        forcePopupIcon
                        clearIcon={null}
                        name={props.name}
                        onChange={(e, value) =>
                            props.allowCustomInput
                                ? onChange(
                                      typeof value === 'string'
                                          ? value
                                          : value
                                          ? value.label
                                          : '',
                                  )
                                : onChange(value?.value)
                        }
                        options={props.options}
                        state={invalid ? 'error' : 'idle'}
                        value={
                            props.allowCustomInput
                                ? value
                                : (value &&
                                      (props.options.find(
                                          (option: AutocompleteOption) =>
                                              option.value.toString() ===
                                              value.toString(),
                                      ) as AutocompleteOption)) ||
                                  null
                        }
                    />
                );
            }}
        />
    );
};
