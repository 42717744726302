import React from 'react';
import {
    DataTypeProvider,
    DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

const NADefaultValueFormatter = ({ value }: { value: string | number }) => (
    <div>{value ? value : 'N/A'}</div>
);

const NADefaultValueTypeProvider = (props: DataTypeProviderProps) => (
    <DataTypeProvider formatterComponent={NADefaultValueFormatter} {...props} />
);

export default NADefaultValueTypeProvider;
