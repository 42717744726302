import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyButton } from '../button';

interface SubmitFooterProps {
    onCancel: () => void;
    cancelText: string;
    onSubmit: () => void;
    submitText: string;
    disableSubmit?: boolean;
    isLoading?: boolean;
    testId?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submitFooter: {
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: 80,
            [theme.breakpoints.down('xs')]: {
                paddingRight: 16,
                paddingLeft: 16,
                justifyContent: 'center',
            },
            '& .btn1': {
                marginRight: 12,
                minWidth: 160,
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    marginRight: 8,
                    minWidth: 'initial',
                    height: 36,
                },
            },
            '& .btn2': {
                marginLeft: 12,
                minWidth: 170,
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    marginLeft: 8,
                    minWidth: 'initial',
                    height: 36,
                },
            },
        },
    }),
);

export const SubmitFooter: React.FunctionComponent<SubmitFooterProps> = props => {
    const {
        onCancel,
        onSubmit,
        cancelText,
        submitText,
        disableSubmit,
        isLoading,
        testId,
    } = props;
    const { submitFooter } = useStyles();
    return (
        <div id="submit-footer" className={submitFooter}>
            <GivelifyButton
                name="cancel"
                text={cancelText}
                onClick={onCancel}
                size="small"
                className="btn1"
                data-testid={testId && `${testId}Cancel`}
            />
            <GivelifyButton
                name="submit"
                text={submitText}
                onClick={onSubmit}
                size="small"
                variant="primary"
                disabled={disableSubmit}
                isLoading={isLoading}
                className="btn2"
                data-testid={testId && `${testId}Submit`}
            />
        </div>
    );
};
