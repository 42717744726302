import { Envelope } from 'api/models';
import { CreateEnvelopesResponse } from 'api/services/responses/envelopes';

export const ToEnvelope = (
    createResponse: CreateEnvelopesResponse,
): Envelope => ({
    active: createResponse.data.active,
    amount: 0,
    detail: createResponse.data.detail,
    donationCount: 0,
    doneeId: createResponse.data.doneeId,
    end: createResponse.data.endDate,
    externalId: createResponse.data.externalFundNameOrNumber,
    goal: createResponse.data.fundGoal,
    id: createResponse.data.id,
    isDefault: false,
    isDetailPublic: false,
    name: createResponse.data.name,
    offeringId: createResponse.data.offeringId,
    priority: createResponse.data.priority,
    start: createResponse.data.startDate,
    alwaysOn: createResponse.data.alwaysOn,
});
