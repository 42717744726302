import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';

import { GivelifySteps } from '../steps';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepVerticalStyles: {
            marginRight: 80,
            justifyContent: 'unset',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        stepHorizontalStyles: {
            marginBottom: theme.spacing(5),
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 1,
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
            },
        },
    }),
);

interface ModalStepsProps {
    currentStep: number;
    totalSteps: number;
    onStepClick?: (step: number) => void;
}

export const ModalSteps: React.FC<ModalStepsProps> = ({
    currentStep,
    totalSteps,
    onStepClick,
}) => {
    const { stepVerticalStyles } = useStyles();
    return (
        <GivelifySteps
            variant="vertical"
            current={currentStep}
            steps={totalSteps}
            className={stepVerticalStyles}
            onClick={(index) => {
                onStepClick ? onStepClick(index) : null;
            }}
        />
    );
};

export const ModalHorizontalSteps: React.FC<ModalStepsProps> = ({
    currentStep,
    totalSteps,
    onStepClick,
}) => {
    const { stepHorizontalStyles } = useStyles();
    return (
        <GivelifySteps
            variant="horizontal"
            current={currentStep}
            steps={totalSteps}
            className={stepHorizontalStyles}
            onClick={(index) => {
                onStepClick ? onStepClick(index) : null;
            }}
        />
    );
};
