import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgHandHoldingUsdFill = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={40}
        width={42}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 42 40"
    >
        <path
            d="M19.5463 11.6347C17.8619 11.1479 16.5112 9.75577 16.2912 8.00619C15.9877 5.57198 17.7329 3.48774 20.032 3.21384V1.87506C20.032 1.20567 20.5783 0.657959 21.246 0.657959H22.4601C23.1278 0.657959 23.6742 1.20567 23.6742 1.87506V3.2215C24.5468 3.32795 25.3662 3.61707 26.0871 4.09629C26.5576 4.40814 26.6031 5.09276 26.2009 5.49591L24.873 6.82712C24.5923 7.10859 24.1674 7.14662 23.8032 6.97925C23.5603 6.8728 23.2948 6.81196 23.0216 6.81196H20.5328C20.1837 6.81196 19.8954 7.10101 19.8954 7.4509C19.8954 7.73237 20.0851 7.99103 20.3582 8.0671L24.1522 9.15487C25.8367 9.64167 27.1873 11.0338 27.4074 12.7834C27.7109 15.2176 25.9657 17.3018 23.6666 17.5757V18.9145C23.6666 19.5839 23.1203 20.1316 22.4525 20.1316H21.2385C20.5707 20.1316 20.0244 19.5839 20.0244 18.9145V17.5681C19.1518 17.4616 18.3323 17.1725 17.6114 16.6933C17.141 16.3814 17.0955 15.6968 17.4976 15.2936L18.8255 13.9624C19.1062 13.681 19.5312 13.6429 19.8954 13.8103C20.1382 13.9168 20.4038 13.9776 20.677 13.9776H23.1658C23.5148 13.9776 23.8032 13.6885 23.8032 13.3387C23.8032 13.0572 23.6135 12.7985 23.3403 12.7225L19.5463 11.6347ZM40.5117 25.6161C41.5891 26.6507 41.5057 28.4155 40.413 29.3359L29.5775 38.5403C28.758 39.2325 27.7489 39.6052 26.7093 39.6052H1.14574C0.51596 39.6052 0 39.0575 0 38.3881V31.0855C0 30.4161 0.51596 29.8684 1.14574 29.8684H5.12181L8.45292 27.0006C9.94767 25.7074 11.8219 25 13.7568 25H25.2221C26.6107 25 27.7185 26.3236 27.4833 27.845C27.3012 29.0392 26.2465 29.8684 25.1007 29.8684H19.4933C18.8634 29.8684 18.3475 30.4161 18.3475 31.0855C18.3475 31.7549 18.8634 32.3026 19.4933 32.3026H27.9689C29.0161 32.3026 30.0252 31.9299 30.8371 31.2377L37.4537 25.6161C38.3491 24.8554 39.6618 24.8023 40.5117 25.6161Z"
            fill="current"
        />
    </svg>
);

export const HandHoldingUsdFillIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgHandHoldingUsdFill}
        fontSize={fontSize}
    />
);
