import StackTrace from 'stacktrace-js';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import { getAxiosClient } from '../api/client';
import {
    AddLogRequest,
    UserType,
    CustomField,
    LoggerConfig,
    LogLevel,
    TraceItem,
    LogEntityType,
    AdditionalInfo,
    ExceptionLog,
    GenericLog,
} from './models';

const isConfigured = (): boolean => service !== '';

let service = '';
let userType: UserType;

let userId: number;
let doneeId: number;

const loggerApi = getAxiosClient({
    baseURL: process.env.REACT_APP_LOGGER_BASE_URL
        ? process.env.REACT_APP_LOGGER_BASE_URL
        : '',
    withCredentials: true,
});

export const configure = (config: LoggerConfig): void => {
    service = config.service;
    userType = config.userType;

    if (config.logUnhandledExceptions) {
        globalThis.onerror = globalErrorHandler;
        globalThis.onunhandledrejection = (event: PromiseRejectionEvent) =>
            globalPromiseErrorHandler(event.reason);
    }
};

export const configureUserData = (
    doneeIdValue: number,
    userIdValue: number,
) => {
    doneeId = doneeIdValue;
    userId = userIdValue;
};

export const globalErrorHandler = async (
    msg: Event | string,
    file?: string,
    line?: number,
    col?: number,
    error?: Error,
) => {
    if (!error) {
        return;
    }

    const stackTrace = await getStackTrace(error);
    const req = await buildErrorAddLogRequest(msg, file, line, col, stackTrace);
    await saveException(req);
};

export const globalPromiseErrorHandler = async (error: Error) => {
    const stackTrace = await getStackTrace(error);
    const req = await buildErrorAddLogRequest(
        error.message,
        stackTrace[0].file,
        stackTrace[0].line,
        stackTrace[0].column,
        stackTrace,
    );
    await saveException(req);
};

export const log = async (
    message: string,
    data: { [key: string]: CustomField } = {},
    level: LogLevel = LogLevel.DEBUG,
) => {
    const req = buildCustomAddLogRequest(message, data, level);
    await saveCustomLog(req);
};

const getStackTrace = async (error: Error) => {
    const stackTraceFrameArray = await StackTrace.fromError(error, {
        offline: true,
    });

    const stackTrace: TraceItem[] = stackTraceFrameArray.map((item) => ({
        file: item.fileName || 'Unknown',
        line: item.lineNumber,
        column: item.columnNumber,
        function: item.functionName || 'Unknown',
    }));

    return stackTrace;
};

export const buildErrorAddLogRequest = (
    message: string | Event = '',
    file = 'No data',
    line = 0,
    column = 0,
    trace: TraceItem[],
) => ({
    givelifyEventId: uuidv4(),
    service: service,
    logLevel: LogLevel.ERROR,
    entity: {
        type: LogEntityType.WEB_UI_EXCEPTION,
        value: {
            file,
            trace,
            column,
            line,
            message: message.toString(),
            doneeId,
            userId,
            ...getAdditionalInfo(),
        },
    },
});

export const buildCustomAddLogRequest = (
    message: string,
    data: { [key: string]: CustomField },
    level: LogLevel,
): {
    givelifyEventId: string;
    service: string;
    logLevel: LogLevel;
    message: string;
    entity: {
        type: LogEntityType;
        value: {
            data: {
                [key: string]: CustomField;
                doneeId: number;
                userId: number;
            };
        } & AdditionalInfo;
    };
} => ({
    givelifyEventId: uuidv4(),
    service: service,
    logLevel: level,
    message,
    entity: {
        type: LogEntityType.GENERIC,
        value: {
            data: {
                ...data,
                doneeId,
                userId,
            },
            ...getAdditionalInfo(),
        },
    },
});

export const getAdditionalInfo = (): AdditionalInfo => {
    const systemInfo = new UAParser().getResult();

    return {
        browserInfo: {
            browserName: systemInfo.browser.name,
            browserVersion: systemInfo.browser.version,
            browserWidth: globalThis.innerWidth,
            browserHeight: globalThis.innerHeight,
        },
        osInfo: {
            osName: systemInfo.os.name,
            osVersion: systemInfo.os.version,
        },
        location: globalThis.location.href,
    };
};

const saveException = async (
    request: AddLogRequest<ExceptionLog>,
): Promise<void> => {
    if (!isConfigured()) {
        return;
    }

    try {
        await loggerApi.post(`/api/${userType}/exception`, request);
    } catch (_) {
        return;
    }
};

const saveCustomLog = async (
    request: AddLogRequest<GenericLog>,
): Promise<void> => {
    if (!isConfigured()) {
        return;
    }

    try {
        await loggerApi.post(`/api/${userType}/custom`, request);
    } catch (_) {
        return;
    }
};
