import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyCloseButton,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { useAdvancedTranslation } from 'utils/i18n';
import { useStyle } from './style';

interface Props {
    open: boolean;
    onClose: () => void;
}

const steps = [1, 2, 3, 4, 5];

function GivingStyleHelp(props: Props) {
    const classes = useStyle();
    const { scopedTranslate: t } = useAdvancedTranslation(
        'dashboard.donorStatus.help',
    );
    const copy = useMemo(
        () => ({
            title: t('title'),
            info: t('info'),
            ok: t('ok'),
            step1: {
                definition: t('step1.definition'),
                description: t('step1.description'),
            },
            step2: {
                definition: t('step2.definition'),
                description: t('step2.description'),
            },
            step3: {
                definition: t('step3.definition'),
                description: t('step3.description'),
            },
            step4: {
                definition: t('step4.definition'),
                description: t('step4.description'),
            },
            step5: {
                definition: t('step5.definition'),
                description: t('step5.description'),
            },
        }),
        [t],
    );

    return (
        <GivelifyModal
            autoFullscreen
            onClose={props.onClose}
            open={props.open}
            width={952}
        >
            <div className={classes.modalContent}>
                <div className={classes.modalTitle}>
                    <GivelifyLabel
                        color="#FFF"
                        text={copy.title}
                        variant="heading1"
                    />
                </div>
                <div className={classes.info}>
                    <GivelifyCloseButton
                        className={classes.closeModal}
                        onClick={props.onClose}
                    />
                    <GivelifyLabel
                        marginBottom={40}
                        text={copy.info}
                        variant="body1"
                    />
                    <ol className={classes.workSteps}>
                        {steps.map(step => (
                            <li key={step} className={classes.step}>
                                <GivelifyLabel
                                    bold
                                    className={classes.inline}
                                    text={copy[`step${step}`].definition}
                                />
                                <GivelifyLabel
                                    className={classes.inline}
                                    text={
                                        ': ' + copy[`step${step}`].description
                                    }
                                />
                            </li>
                        ))}
                    </ol>
                    <div className={classes.doneButtonGroup}>
                        <GivelifyButton
                            onClick={props.onClose}
                            text={copy.ok}
                            variant="primary"
                        />
                    </div>
                </div>
            </div>
        </GivelifyModal>
    );
}

export default GivingStyleHelp;
