import React, { useEffect, useState } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import { IntegrationTypes } from '../../../types';
import {
    MATCHED,
    matchSelect,
    UNMATCH,
} from '../../../utils/integrationFormatFilterUtils';
import AutoIntegrationSearchFilterHeader from './components/AutoIntegrationSearchFilterHeader';
import AutoIntegrationMatchDonorContent from './components/DonorTabContent/AutoIntegrationMatchDonorContent';
import AutoIntegrationUnmatchDonorContent from './components/DonorTabContent/AutoIntegrationUnmatchDonorContent';
import { donorTabStyle } from './style';

interface AutoIntegrationDonorTabContentProps {
    defaultMatchStatus?: matchSelect;
    isReadOnly: boolean;
    integration: IntegrationTypes;
}

const AutoIntegrationDonorTabContent: React.FC<AutoIntegrationDonorTabContentProps> = ({
    defaultMatchStatus,
    isReadOnly,
    integration,
}) => {
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));
    const classes = donorTabStyle();

    const [sortColumn, setSortColumn] = useState('full_name');
    const [sortDirection, setSortDirection] = useState<SortingDirection>('asc');

    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchChange = (value: string) => {
        setSearchValue(value);
    };

    const onSortingChange = (sorting: Sorting[]) => {
        setSortColumn(sorting[0].columnName);
        setSortDirection(sorting[0].direction);
    };

    const [campusSelected, setCampusSelected] = useState<number>(
        doneeId === userDoneeId ? 0 : doneeId,
    );
    useEffect(() => {
        if (doneeId === userDoneeId) {
            setCampusSelected(0);
        } else {
            setCampusSelected(doneeId);
        }
    }, [doneeId, userDoneeId]);
    const onCampusChange = (value: number) => {
        setCampusSelected(value);
    };

    const [matchFilter, setMatchFilter] = useState<matchSelect>(
        defaultMatchStatus || UNMATCH,
    );
    const onChangeMatchFilter = (value: matchSelect) => {
        setMatchFilter(value);
    };

    return (
        <div className={classes.donorTabContent}>
            <AutoIntegrationSearchFilterHeader
                campusSelected={campusSelected}
                defaultMatch={matchFilter}
                donorTab={true}
                onCampusChange={onCampusChange}
                onMatchChange={onChangeMatchFilter}
                onSearchChange={onSearchChange}
            />
            {matchFilter === UNMATCH && (
                <Paper className={classes.donorTableContent}>
                    <AutoIntegrationUnmatchDonorContent
                        campusSelected={campusSelected}
                        integration={integration}
                        isReadOnly={isReadOnly}
                        onSortingChange={onSortingChange}
                        searchValue={searchValue}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                </Paper>
            )}
            {matchFilter === MATCHED && (
                <Paper className={classes.matchedDonorTableContent}>
                    <AutoIntegrationMatchDonorContent
                        campusSelected={campusSelected}
                        doneeId={doneeId}
                        integration={integration}
                        isReadOnly={isReadOnly}
                        onSortingChange={onSortingChange}
                        searchValue={searchValue}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                </Paper>
            )}
        </div>
    );
};

export default AutoIntegrationDonorTabContent;
