import { useEffect, useState, useCallback } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilterContext } from './filterContext';

export type ExistingValue<K extends Readonly<string[]>> = {
    [key in K[number]]: string;
};

export const useFilterParams = <T, K extends Readonly<string[]>>(
    initialState: T,
    paramNames: K,
    stateToURL: (state: T, paramName: string) => string,
    URLToState: (existingValue: ExistingValue<K>) => T,
): [T, (state: T) => void] => {
    const filterContext = useFilterContext();
    const location = useLocation();
    const navigate = useNavigate();
    const readStateFromUrl = useCallback(
        (state: T) => {
            const search = new URLSearchParams(location.search);
            const existingValues = {} as ExistingValue<K>;
            paramNames.forEach((name: string) => {
                const value = search.get(name);
                if (value) {
                    existingValues[name] = value;
                }
            });
            if (isEmpty(existingValues)) {
                if (!isEqual(state, initialState)) {
                    return initialState;
                }
            } else {
                const stateFromUrl = URLToState(existingValues);
                if (!isEqual(state, stateFromUrl)) {
                    return stateFromUrl;
                }
            }
            return state;
        },
        [URLToState, location.search, initialState, paramNames],
    );
    const [state, setState] = useState<T>(readStateFromUrl(null));
    useEffect(() => {
        const newState = readStateFromUrl(state);
        if (newState !== null) {
            setState(newState);
        }
        // listen to changes in location.search
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const onChange = useCallback(
        (s: T) => {
            setState(s);
            const searchParams = new URLSearchParams();
            paramNames.forEach((name) => {
                const serialized = stateToURL(s, name);
                searchParams.set(name, serialized);
            });
            try {
                filterContext.update(searchParams);
            } catch (e) {
                const filteredSearchParams = new URLSearchParams(
                    location.search,
                );
                searchParams.forEach((value, key) => {
                    if (value) {
                        filteredSearchParams.set(key, value);
                    } else {
                        filteredSearchParams.delete(key);
                    }
                });
                navigate({
                    pathname: location.pathname,
                    search: filteredSearchParams.toString(),
                });
            }
        },
        [filterContext, location.pathname, location.search, navigate, paramNames, stateToURL],
    );

    return [state, onChange];
};
