import React from 'react';
import { FormControlLabel, Radio, RadioGroup, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    ACS,
    SHELBY,
} from 'pages/integrations/utils/integrationFormatFilterUtils';
import { selectIntegrationTypeStyle } from '../../style';

interface GroupSelectIntegrationTypeProps<T> {
    integrationType: string;
    label: string;
    name: string;
    onChange?: (value: string) => void;
    options: T[];
    initialValues: T[];
    isReadOnly: boolean;
}

export const useRadioStyles = makeStyles((theme: Theme) => ({
    groupRadio: {
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
}));

const GroupSelectIntegrationVersion = <T,>(
    props: GroupSelectIntegrationTypeProps<T>,
) => {
    const classes = selectIntegrationTypeStyle();
    const groupClasses = useRadioStyles();

    let defaultValue;
    if (props.integrationType === 'ACS') {
        defaultValue = ACS.ACS;
    } else if (props.integrationType === 'SHELBY') {
        defaultValue = SHELBY.SHELBYARENA;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        props.onChange(value);
    };

    return (
        <RadioGroup
            classes={{ root: groupClasses.groupRadio }}
            defaultValue={defaultValue}
            onChange={handleChange}
        >
            {props.options.map((o, i) => (
                <FormControlLabel
                    key={i}
                    className={classes.typeItem}
                    control={<Radio disabled={props.isReadOnly} />}
                    label={o}
                    value={o}
                />
            ))}
        </RadioGroup>
    );
};

export default GroupSelectIntegrationVersion;
