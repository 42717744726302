import React, { useMemo } from 'react';
import { GivelifyIcon, GivelifyLabel, GivelifyMenuItem } from '@givelify/ui';
import { AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    FilterAccordion,
    FilterAccordionDetails,
    FilterList,
} from '../components/filterMenuItemStyles';
import {
    TRANSACTION_FILTER_VALUE,
    TransactionFilterProps,
    capitalize,
    toLabelText,
    toTransactionValueText,
    transactionFilterOptions,
} from './types';

export const TransactionFilterAccordion: React.FC<
    TransactionFilterProps & {
        expanded: boolean;
        onExpandChange: (
            event: React.SyntheticEvent,
            expanded: boolean,
        ) => void;
    }
> = ({ onChange, value, expanded, onExpandChange }) => {
    const { t } = useTranslation();
    const handleSelectChange = React.useCallback(
        (value: TRANSACTION_FILTER_VALUE) => {
            onChange(value);
        },
        [onChange],
    );
    const TransactionText = useMemo(
        () => ({
            transactionTitle: t('bankDeposits.transaction.title'),
        }),
        [t],
    );
    const getFilterText = React.useCallback(() => {
        return `${TransactionText.transactionTitle}: ${capitalize(
            toTransactionValueText(value, t),
        )}`;
    }, [TransactionText.transactionTitle, value, t]);
    const onRenderMenuContent = React.useCallback(() => {
        return [
            <FilterList key="list" id="transaction-filter-options">
                {transactionFilterOptions.map((opt) => (
                    <GivelifyMenuItem
                        key={opt}
                        id={`option-${opt}`}
                        onClick={() => handleSelectChange(opt)}
                        selected={opt === value}
                        text={toLabelText(opt, t)}
                    />
                ))}
            </FilterList>,
        ];
    }, [t, value, handleSelectChange]);
    return (
        <FilterAccordion expanded={expanded} onChange={onExpandChange}>
            <AccordionSummary
                aria-controls="transaction-filter-content"
                expandIcon={
                    <GivelifyIcon fontSize={24} variant="down-chevron" />
                }
                id="transaction-filter"
            >
                <GivelifyLabel text={getFilterText()} />
            </AccordionSummary>
            <FilterAccordionDetails>
                {onRenderMenuContent()}
            </FilterAccordionDetails>
        </FilterAccordion>
    );
};
