import { createStyles, makeStyles } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyLoadingStyleProps = GivelifyBoxMarginProps;

export const GivelifyLoadingStyles = makeStyles(() =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyLoadingStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyLoadingStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyLoadingStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyLoadingStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        progressBar: {
            backgroundColor: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100%',
        },
        blurOverly: {
            filter: 'blur(10px)',
            backgroundColor: 'rgba(0,0,0,0)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100%',
        },
    }),
);
