import { DesignTokens } from "@givelify/ui";
import { styled } from "@mui/material";

export const StyledForm = styled('div')(({ theme }) => ({
    '& .page-description': {
        color: DesignTokens.color.textSecondary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '14px',
        },
    },
    '& .input-field': {
        marginTop: '24px',
        marginBottom: '24px',

        '& label:not(.Mui-focused)': {
            color: DesignTokens.color.textSecondary,
            fontWeight: 700,
        },
    },
    '& .congregationSize-container': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '8px',

        [theme.breakpoints.down('mobile')]: {
            display: 'flex',
            width: 'calc(100vw - 32px)',
            overflowX: 'auto',
            paddingBottom: '8px',
        },
    },
    '& .congregationSize-root': {
        display: 'none',
    },
    '& .congregationSize-root + div': {
        cursor: 'pointer',
        border: `1px solid ${DesignTokens.color.globalNeutral200}`,
        borderRadius: '8px',
        width: '100%',
        height: '56px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('mobile')]: {
            padding: '16px 24px',
            whiteSpace: 'nowrap',
        },

        '& p': {
            fontWeight: 700,
        },
    },
    '& .congregationSize-checked + div': {
        border: `1px solid ${DesignTokens.color.borderButtonDefault}`,
        color: DesignTokens.color.textAccentDefault,
        background: DesignTokens.color.globalGuidingViolet100,

        '& p': {
            color: DesignTokens.color.textAccentDefault,
        },
    },
}));