import React, { useCallback, useMemo } from 'react';
import { Box, MenuItem, Select } from '@material-ui/core';
import { FilterInputWrapper } from 'components/inputs/FilterInputWrapper';
import { useTranslation } from 'react-i18next';

const TableControls = () => {
    const { t } = useTranslation();

    const TRANSLATION_KEY = 'pages.integration_envelope_matching.content.ccb';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );
    const copy = useMemo(
        () => ({
            unmatched: scopedTranslate('unmatched'),
            matched: scopedTranslate('matched'),
            allCampuses: scopedTranslate('allCampuses'),
        }),
        [scopedTranslate],
    );

    return (
        <FilterInputWrapper>
            <Box component="span" style={{ marginRight: 32 }}>
                <Select value={copy.unmatched} variant="outlined">
                    <MenuItem value={copy.unmatched}>{copy.unmatched}</MenuItem>
                    <MenuItem value={copy.matched}>{copy.matched}</MenuItem>
                </Select>
            </Box>
            <Select value={copy.allCampuses} variant="outlined">
                <MenuItem value={copy.allCampuses}>{copy.allCampuses}</MenuItem>
            </Select>
        </FilterInputWrapper>
    );
};

export default TableControls;
