import { getAxiosClient, makeApiRequest, ApiResponse } from '@givelify/utils';
import { getBankNameUrl } from '../../consts/endpoints';

export const getBankNameRequest = async (
    routingNumber: string,
): Promise<ApiResponse<{ bankName: string }>> => {
    const httpRequest = getAxiosClient().get<{ bankName: string }>(
        getBankNameUrl(routingNumber),
    );
    const result = makeApiRequest<{ bankName: string }>(httpRequest);
    return result;
};
