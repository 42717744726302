import { ReportType } from '../report/types';

export type ScheduledReportRequest = {
    name: string;
    id: number;
    frequency: ScheduledReportFrequency;
    scheduled_date?: null | number;
    scheduled_day?: Weekday | null;
    receives_notifications: boolean;
    report_type: ReportType;
    report_type_version: string;
    donee_id: number;
    next_run_date: string;
    date_range_type: string;
    match_by: string;
};

export const NONE = 'None';
export const DAILY = 'Daily';
export const WEEKLY = 'Weekly';
export const BI_WEEKLY = 'Bi-Weekly';
export const MONTHLY = 'Monthly';
export const NAME = 'Name';
export const ENVELOPE_NUMBER = 'Envelope number';

export const SCHEDULED_REPORT_FREQUENCIES = [
    NONE,
    DAILY,
    WEEKLY,
    BI_WEEKLY,
    MONTHLY,
];

export const DONOR_MATCH_BY = [NAME, ENVELOPE_NUMBER];

/**
 * Available ScheduledReportFrequencies
 */
export type ScheduledReportFrequency =
    | typeof NONE
    | typeof DAILY
    | typeof WEEKLY
    | typeof BI_WEEKLY
    | typeof MONTHLY;

export const MONDAY = 'Monday' as const;
export const TUESDAY = 'Tuesday' as const;
export const WEDNESDAY = 'Wednesday' as const;
export const THURSDAY = 'Thursday' as const;
export const FRIDAY = 'Friday' as const;
export const SATURDAY = 'Saturday' as const;
export const SUNDAY = 'Sunday' as const;

export const WEEKDAYS = [
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
];

export type Weekday =
    | typeof MONDAY
    | typeof TUESDAY
    | typeof WEDNESDAY
    | typeof THURSDAY
    | typeof FRIDAY
    | typeof SATURDAY
    | typeof SUNDAY;

export const DATES = Array.from(Array(31).keys()).map(v => v + 1);

export enum DATE_RANGE_TYPE {
    DONATION = 'donationDate',
    BANK_DEPOSITS = 'bankDepositDate',
}

export const parseBaseOnToString = (value: DATE_RANGE_TYPE) => {
    switch (value) {
        case DATE_RANGE_TYPE.BANK_DEPOSITS:
            return 'Bank Deposit Date';
        default:
        case DATE_RANGE_TYPE.DONATION:
            return 'Donation Date';
    }
};

export const parseStringToBaseOn = (value: string) => {
    switch (value) {
        case 'Bank Deposit Date':
            return DATE_RANGE_TYPE.BANK_DEPOSITS;
        default:
        case 'Donation Date':
            return DATE_RANGE_TYPE.DONATION;
    }
};

export type ScheduledReport = {
    name: string;
    id: number;
    frequency: ScheduledReportFrequency;
    scheduledDate: null | number;
    scheduledDay: null | Weekday;
    receivesNotifications: boolean;
    reportType: ReportType;
    reportTypeVersion: string;
    doneeId: number;
    nextRunDate: Date;
    dateRangeType: string;
    matchBy: string;
};

export interface IntegrationState {
    scheduledReport: ScheduledReport;
}

export const SET_SCHEDULED_REPORT = 'SET_SCHEDULED_REPORT';

export interface SetScheduledReport {
    type: typeof SET_SCHEDULED_REPORT;
    data: IntegrationState['scheduledReport'];
}
export type IntegrationActions = SetScheduledReport;
