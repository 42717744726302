import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { PCIntegrationResponse } from 'api/models/Integration';

export const setupPCIntegration = (doneeId: number, code: string) => {
    const httpRequest = getAxiosClient().post(
        `/donees/${doneeId}/integrations`,
        {
            type: 'PLANNING_CENTER',
            credentials: { code },
        },
    );
    const result = makeApiRequest<PCIntegrationResponse>(httpRequest);
    return result;
};
