import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

type WidgetPaperProps = {
    width?: number | string;
    height?: number | string;
};

const WidgetPaper = styled('div')<WidgetPaperProps>(({ width, height }) => ({
    width: width || '368px',
    height: height || '449px',
    backgroundColor: DesignTokens.color.backgroundSecondary,
    borderRadius: '12px',
    padding: '24px',
    overflow: 'hidden',
}));

const Title = styled(GivelifyLabel)({
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    color: DesignTokens.color.textPrimary,
    opacity: 0.6,
    marginBottom: '10px',
});

type WidgetErrorBoxProps = {
    width?: number | string;
    height?: number | string;
};

export const WidgetErrorBox: React.FC<WidgetErrorBoxProps> = ({
    width,
    height,
}) => {
    return (
        <WidgetPaper
            data-testid="widget-error-box"
            height={height}
            width={width}
        >
            <Title text={'Something went wrong'} />
        </WidgetPaper>
    );
};
