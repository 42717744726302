import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DATE_RANGE_TYPE } from 'store/integration/types';
import { useStyles } from './style';

interface IntegrationBaseOnSelectorProps<T> {
    name: string;
    initialValues: T[];
    options: T[];
    onBaseChange?: (value: DATE_RANGE_TYPE) => void;
    defaultBaseOn: string;
    isReadOnly: boolean;
}

const IntegrationBaseOnSelector = <T,>(
    props: IntegrationBaseOnSelectorProps<T>,
) => {
    const { baseOnRadio, baseOnLabel } = useStyles();
    const defaultValue = DATE_RANGE_TYPE.DONATION;
    const onChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const range =
            value === 'donationDate'
                ? DATE_RANGE_TYPE.DONATION
                : DATE_RANGE_TYPE.BANK_DEPOSITS;
        props.onBaseChange(range);
    };
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            baseOn: props.options.map(base => ({
                base: base,
                label: t(`pages.integrations.settings.baseOnLabel.${base}`),
                labelDefine: t(
                    `pages.integrations.settings.baseOnDefine.${base}`,
                ),
            })),
        }),
        [t, props],
    );

    return (
        <RadioGroup
            defaultValue={props.defaultBaseOn || defaultValue}
            onChange={onChangeString}
        >
            {copy.baseOn.map((base, i) => (
                <div key={i} className={baseOnRadio}>
                    <FormControlLabel
                        key={i}
                        control={
                            <Radio
                                color="primary"
                                disabled={props.isReadOnly}
                                size="small"
                            />
                        }
                        label=""
                        value={base.base}
                    />
                    <div>
                        <GivelifyLabel
                            bold
                            className={baseOnLabel}
                            text={base.label}
                            variant="body1"
                        />
                        <GivelifyLabel
                            text={base.labelDefine}
                            variant="body1"
                        />
                    </div>
                </div>
            ))}
        </RadioGroup>
    );
};

export default IntegrationBaseOnSelector;
