import React from 'react';
import { TextField } from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { onKeypressHandlerFactory } from '../../utils/onKeypressHandlerFactory';

interface Props extends StandardTextFieldProps {
    onEnter: (value: string) => void;
    longInput?: boolean;
    className?: string;
}

const maxLength = { maxLength: 55 };

const OnEnterChangeTextField: React.FC<Props> = props => {
    const { onEnter, longInput, className, ...textFieldProps } = props;
    const onEnterHandler = onKeypressHandlerFactory(onEnter);

    return (
        <TextField
            {...textFieldProps}
            className={`${className} ${longInput ? 'expend-input' : ''}`}
            inputProps={maxLength}
            onBlur={ev => onEnter(ev.target.value)}
            onKeyPress={onEnterHandler}
        />
    );
};

export default OnEnterChangeTextField;
