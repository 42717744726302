import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifySelect,
    GivelifyTheme,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import {
    Box,
    FormControl,
    Grid,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { matchRowStyle } from 'pages/integrations/components/style';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { IntegrationTypes } from '../../../../../types';
import { CCBEnvelope } from '../../types';
import { matchEnvelope } from '../automatedIntegrationRequest';
import F1SelectFund from './F1SelectFund';

export const useCCBRowStyles = makeStyles((theme: Theme) => {
    return createStyles({
        input: {
            border: 0,
            paddingLeft: 16,
            paddingTop: 22,
            paddingBottom: 22,
            backgroundColor: 'initial',
        },
        root: {
            width: '100%',
        },
        gridItem: {
            padding: '3px 0 3px 16px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: 496,
        },
        givelifyEnvName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        gridMatchItem: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: 202,
            height: 64,
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 0),
            },
        },
        selectEnvelope: {
            '& input': {
                paddingLeft: 0,
                fontSize: 14,
            },
            '& fieldset': {
                border: 0,
            },
        },
        fund: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
        },
        selectFund: {
            display: 'flex',
            flexDirection: 'column',
        },
        nowrap: {
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    });
});

interface AutoIntegrationEnvelopeUnmatchRowProps {
    id: number;
    name: string;
    campusName: string;
    listCcbEnvelope: CCBEnvelope[];
    requestSuccess: () => void;
    integration: IntegrationTypes;
}

const AutoIntegrationEnvelopeUnmatchRow: React.FC<
    AutoIntegrationEnvelopeUnmatchRowProps
> = ({
    id,
    name,
    campusName,
    listCcbEnvelope,
    requestSuccess,
    integration,
}) => {
    const styles = useCCBRowStyles();
    const classes = matchRowStyle();

    const userDoneeId = useSelector(
        (state: AppState) => state.User.user.doneeId,
    );

    const TRANSLATION_KEY = 'pages.integration_envelope_matching.content.ccb';
    const { scopedTranslate, t } = useAdvancedTranslation({
        TRANSLATION_KEY: TRANSLATION_KEY,
    });

    const text = useMemo(
        () => ({
            selectCategory: scopedTranslate('selectCategory'),
            match: scopedTranslate('match'),
            matched: scopedTranslate('matched'),
            selectFund: t(
                'pages.integration_envelope_matching.content.planningCenter.selectFund',
            ),
        }),
        [scopedTranslate, t],
    );

    const [matchEnvelopeResponse, makeRequestMatchEnvelope] = useApiRequest();

    const matchEnvelopeFunction = (
        envelopeId: number,
        chmsEnvelopeId: number,
    ) => {
        makeRequestMatchEnvelope(
            matchEnvelope(userDoneeId, envelopeId, chmsEnvelopeId),
        );
    };

    useEffect(() => {
        if (matchEnvelopeResponse.type === 'REQUEST_SUCCESS') {
            requestSuccess();
        }
    }, [matchEnvelopeResponse, requestSuccess]);

    const [selectedId, setSelectedId] = useState<number>();
    const [selectSubFund, setSelectSubFund] = useState<number>();
    const onChangeEnvelope = (value) => setSelectedId(value.key);
    const onChangeSubFund = (value) => setSelectSubFund(value.key);
    const btnDisable = selectedId?.toString().length > 0;
    const onMatchEnvelope = () => {
        matchEnvelopeFunction(id, selectSubFund ? selectSubFund : selectedId);
    };

    const { isCCB, isPlanningCenter } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    return (
        <ThemeProvider theme={GivelifyTheme}>
            <Grid container className={classes.matchRowContent}>
                <Grid item className={styles.gridItem} md={4} xs={6}>
                    <GivelifyLabel bold wordBreak text={name} variant="body2" />
                    <GivelifyLabel
                        color="neutralGrey"
                        text={campusName}
                        variant="body3"
                    />
                </Grid>
                <Grid item className={styles.gridItem} md={5} xs={6}>
                    <FormControl fullWidth>
                        {listCcbEnvelope.length > 0 &&
                        (isCCB || isPlanningCenter) ? (
                            <GivelifySelect
                                fullWidth
                                ariaLabel="ccb-envelope"
                                className={styles.selectEnvelope}
                                disabled={
                                    matchEnvelopeResponse.type ===
                                    'REQUEST_SUCCESS'
                                }
                                inputLabel="ccb-envelope"
                                onChange={onChangeEnvelope}
                                options={listCcbEnvelope?.map((envelope) => ({
                                    key: envelope.chmsInternalId,
                                    value: envelope.name,
                                }))}
                                placeholder={
                                    isCCB
                                        ? text.selectCategory
                                        : text.selectFund
                                }
                            />
                        ) : (
                            <F1SelectFund
                                listEnvelope={listCcbEnvelope}
                                onChangeEnvelope={onChangeEnvelope}
                                onChangeSubFund={onChangeSubFund}
                            />
                        )}
                    </FormControl>
                </Grid>
                <Grid item className={styles.gridMatchItem} md={3} xs={12}>
                    <Box display="flex" justifyContent="center">
                        <GivelifyButton
                            disabled={
                                !btnDisable ||
                                matchEnvelopeResponse.type === 'REQUEST_SUCCESS'
                            }
                            isLoading={
                                matchEnvelopeResponse.type === 'REQUEST_SUCCESS'
                            }
                            marginLeft="auto"
                            marginRight="auto"
                            name="match"
                            onClick={onMatchEnvelope}
                            size="small"
                            text={
                                matchEnvelopeResponse.type === 'REQUEST_SUCCESS'
                                    ? text.matched
                                    : text.match
                            }
                            variant="secondary"
                        />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default AutoIntegrationEnvelopeUnmatchRow;
