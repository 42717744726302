import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';

export const useStyles = makeStyles(() => {
    return createStyles({
        container: {
            minHeight: 269,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& .footer': {
                display: 'flex',
            },
            '& .button': {
                minWidth: 160,
                paddingLeft: 26,
                paddingRight: 26,
            },
        },
    });
});

interface DiscardConfirmDialogProps {
    open?: boolean;
    onDiscardClick: () => void;
    onConfirmClick: () => void;
    onCancelClick: () => void;
    confirmLabel?: string;
}

export const DiscardConfirmDialog: React.FunctionComponent<DiscardConfirmDialogProps> =
    (props) => {
        const {
            open = false,
            onConfirmClick,
            onDiscardClick,
            onCancelClick,
            confirmLabel,
        } = props;
        const { container, content } = useStyles();
        const { t } = useTranslation(I18N_NAMESPACE);
        const { title, description, confirm, discard } = useMemo(
            () => ({
                title: t('discard-confirm.title'),
                description: t('discard-confirm.description'),
                confirm: t('discard-confirm.confirm'),
                discard: t('discard-confirm.discard'),
            }),
            [t],
        );
        return (
            <GivelifyModal
                showCloseButton
                contentClassName={container}
                onClose={onCancelClick}
                open={open}
            >
                <div className={content}>
                    <GivelifyLabel
                        marginBottom={16}
                        text={title}
                        variant="heading3"
                    />
                    <GivelifyLabel marginBottom={64} text={description} />
                    <div className="footer">
                        <GivelifyButton
                            className="button"
                            marginRight={8}
                            onClick={onDiscardClick}
                            size="large"
                            text={discard}
                            variant="danger-secondary"
                        />
                        <GivelifyButton
                            className="button"
                            marginLeft={8}
                            onClick={onConfirmClick}
                            size="large"
                            text={confirmLabel ? confirmLabel : confirm}
                            variant="primary"
                        />
                    </div>
                </div>
            </GivelifyModal>
        );
    };
