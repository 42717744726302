import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import Grid from '@material-ui/core/Grid';
import { donationsRowTableStyles } from '../../components/DonationsRowTable';

const BankDepositLoading: React.FC = () => {
    const { row, leftColumn, middle, rightColumn } = donationsRowTableStyles();
    return (
        <Grid container className={row} justifyContent="space-between">
            <Grid
                item
                className={leftColumn}
                lg={4}
                md={4}
                sm={4}
                xl={4}
                xs={4}
            >
                <FacebookLoading
                    borderRadius={4}
                    height={18}
                    marginTop={4}
                    width={98}
                />
            </Grid>
            <Grid item className={middle} lg={4} md={4} sm={4} xl={4} xs={4}>
                <FacebookLoading
                    borderRadius={4}
                    height={18}
                    marginLeft="auto"
                    marginTop={4}
                    width={98}
                />
            </Grid>
            <Grid
                item
                className={rightColumn}
                lg={4}
                md={4}
                sm={4}
                xl={4}
                xs={4}
            >
                <FacebookLoading
                    borderRadius={4}
                    height={18}
                    marginLeft="auto"
                    marginTop={4}
                    width={98}
                />
            </Grid>
        </Grid>
    );
};

export default BankDepositLoading;
