import { makeApiRequest, getAxiosClient, ApiResponse } from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import {
    DonationsStatisticType,
    getDonorDonationsStatisticEndpoint,
    getDonorProfileEndpoint,
    getDonorsEndpoint,
} from 'api/client/endpoints';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { donorsEndPoint } from 'store/dashboard/donors/thunks';
import { DonorsTimeFrameData } from 'store/dashboard/donors/types';
import { IDonorsService } from '../interfaces';
import { GetDonorsRequest } from '../requests';
import {
    GetDonorsResponse,
    GetDonorProfileResponse,
    GetDonorDonationsStatisticResponse,
} from '../responses';

export class DonorsService implements IDonorsService {
    private getDonorsCancelTokenSource: CancelTokenSource;

    public async getDonors(
        doneeId: number,
        payload: GetDonorsRequest,
    ): Promise<ApiResponse<GetDonorsResponse>> {
        this.getDonorsCancelTokenSource?.cancel();
        this.getDonorsCancelTokenSource = axios.CancelToken.source();
        const url = getDonorsEndpoint(doneeId, payload);
        const httpRequest = getAxiosClient().get(url, {
            cancelToken: this.getDonorsCancelTokenSource.token,
        });
        return makeApiRequest(httpRequest);
    }

    public async getDonorsData(
        timeFrame: TimeFrameValue,
        donneId: number,
    ): Promise<ApiResponse<DonorsTimeFrameData>> {
        const url = donorsEndPoint(timeFrame, donneId);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest(httpRequest);
    }

    public async getDonorProfile(
        donneId: number,
        donorId: string,
    ): Promise<ApiResponse<GetDonorProfileResponse>> {
        const url = getDonorProfileEndpoint(donneId, donorId);
        const httpRequest = getAxiosClient().get(url);
        return makeApiRequest(httpRequest);
    }

    public async getDonorDonationsStatistic(
        donneId: number,
        donorId: string,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs,
        type: DonationsStatisticType,
        skipCancel?: boolean,
    ): Promise<ApiResponse<GetDonorDonationsStatisticResponse>> {
        if (!skipCancel) {
            this.getDonorsCancelTokenSource?.cancel();
            this.getDonorsCancelTokenSource = axios.CancelToken.source();
        }
        const url = getDonorDonationsStatisticEndpoint(
            donneId,
            donorId,
            type,
            startDate,
            endDate,
        );
        const httpRequest = getAxiosClient().get(url, {
            cancelToken: skipCancel
                ? undefined
                : this.getDonorsCancelTokenSource.token,
        });
        return makeApiRequest<GetDonorDonationsStatisticResponse>(httpRequest);
    }
}
