import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={32}
        width={32}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 32 32"
    >
        <title>Warning</title>
        <path
            clipRule="evenodd"
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM17.5 22.5C17.5 23.3284 16.8284 24 16 24C15.1716 24 14.5 23.3284 14.5 22.5C14.5 21.6716 15.1716 21 16 21C16.8284 21 17.5 21.6716 17.5 22.5ZM17 9C17 8.44772 16.5523 8 16 8C15.4477 8 15 8.44772 15 9V18C15 18.5523 15.4477 19 16 19C16.5523 19 17 18.5523 17 18V9Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const WarningIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgWarning}
        fontSize={fontSize}
    />
);
