import { DesignTokens, GivelifyLabel, GivelifyPaper } from '@givelify/ui';
import { BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';

export const Wrapper = styled(GivelifyPaper)<{ selected: boolean }>(
    ({ selected }) => ({
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        ':hover': {
            boxShadow: DesignTokens.shadow.shadowLow,
            backgroundColor: DesignTokens.color.globalGuidingViolet100,
            cursor: 'pointer',
            '& > *': {
                color: DesignTokens.color.globalGuidingViolet400,
            },
        },
        ...(selected
            ? {
                  boxShadow: DesignTokens.shadow.shadowLow,
                  backgroundColor: DesignTokens.color.globalGuidingViolet100,
                  cursor: 'pointer',
                  '& > *': {
                      color: DesignTokens.color.globalGuidingViolet400,
                  },
              }
            : {}),
    }),
);

type SelectDocumentTypeButtonParams = {
    title1: string;
    title2?: string;
    title3?: string;
    type: string;
    onClick: (type: string) => void;
    selectedDocumentType?: string;
    IconComponent: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    trackName?: string;
};

const SelectDocumentTypeButton = ({
    title1,
    title2,
    title3,
    type,
    IconComponent,
    onClick,
    selectedDocumentType,
    trackName,
}: SelectDocumentTypeButtonParams) => {
    const selected = type === selectedDocumentType;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { trackEvent } = useTrackingContext();

    const onClickInternal = () => {
        if (trackName) {
            trackEvent(`<${trackName}>_${BUTTON_IDENTIFIER}`);
        }
        onClick(type);
    };

    return (
        <Wrapper onClick={onClickInternal} selected={selected}>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <GivelifyLabel
                    align="center"
                    text={title1}
                    variant={isMobile ? 'body2' : 'body1'}
                />
                {title2 && (
                    <GivelifyLabel
                        align="center"
                        text={title2}
                        variant={isMobile ? 'body2' : 'body1'}
                    />
                )}
                {title3 && (
                    <GivelifyLabel
                        align="center"
                        text={title3}
                        variant={isMobile ? 'body2' : 'body1'}
                    />
                )}
            </div>
            <div
                style={{
                    marginTop: 12,
                    height: isMobile ? 32 : 54,
                }}
            >
                <IconComponent
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
        </Wrapper>
    );
};

export default SelectDocumentTypeButton;
