import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { TooltipProps } from 'recharts';
import { formatMoneyWithCommas } from '../../../../../utils/formatMoneyWithCommas';
import { useAdvancedTranslation } from '../../../../../utils/i18n';
import { NameType, ValueType } from '../../../types';
import { GraphTooltip } from '../components/SimplePieChart/styles';

type EnvelopeChartTooltipProps = TooltipProps<ValueType, NameType>;

export const EnvelopeChartTooltip: React.FC<EnvelopeChartTooltipProps> = (
    props,
) => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            total: t(`overview.total`),
            average: t(`overview.average`),
        }),
        [t],
    );

    if (props.payload.length > 0) {
        const data = props.payload[0];
        return (
            <GraphTooltip data-testid="graph-tooltip">
                <p data-testid="graph-tooltip-title">
                    <strong>{data.name}</strong>
                </p>
                <Box
                    data-testid="graph-tooltip-text-total"
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box fontWeight="bold" pr={2}>
                        {copy.total}:
                    </Box>
                    <span>
                        $
                        {formatMoneyWithCommas(
                            parseFloat(data.value.toString()),
                        )}
                    </span>
                </Box>
                <Box
                    data-testid="graph-tooltip-text-average"
                    display="flex"
                    justifyContent="space-between"
                    pb={3}
                    width="100%"
                >
                    <Box fontWeight="bold" pr={2}>
                        {copy.average}:
                    </Box>
                    <span>${formatMoneyWithCommas(data.payload.average)}</span>
                </Box>
            </GraphTooltip>
        );
    }
    return null;
};
