import React, { forwardRef, useImperativeHandle } from 'react';
import {
    GivelifyButton,
    GivelifyTextField,
    GivelifyLabel,
    GivelifyModal,
    GivelifyLabelStyles,
    GivelifyDropDown,
    OptionItem,
    mergeClassNames,
} from '@givelify/givelify-ui';
import {
    createStyles,
    debounce,
    Grid,
    makeStyles,
    SvgIcon,
    Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    GivelifyInputState,
    OrganizationType,
    TaxIdInfo,
} from '../@types/assets/onboarding';
import { ReactComponent as TaxIdSvg } from '../assets/rc-assets/tax-id.svg';
import roundedArrow from '../assets/rounded-arrow.svg';
import { ReactComponent as VerifiedSvg } from '../assets/verified.svg';
import { I18N_NAMESPACE } from '../consts';
import { InfoBox } from '../infoBox';
import { ValidateTaxId } from '../utils/validation';
import { webConfig } from '../utils/webConfig';
import { EinUploadSection } from './components/documentUpload/EinUploadSection';

const congregationSizeList: string[] = [
    '1 - 99',
    '100 - 499',
    '500 - 1,499',
    '1,500 - 4,999',
    '5,000 - 9,999',
    '10,000 +',
];

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        taxContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        taxForm: {
            maxWidth: 600,
            flex: 1,
            [theme.breakpoints.down('xs')]: {
                maxWidth: 'initial',
            },
            '& .affiliation-caption': {
                marginBottom: 24,
                color: theme.colors?.neutralGrey,
                '& a:link': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
                '& a:hover': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.main,
                    textDecoration: 'underline',
                },
                '& a:active': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.dark,
                    textDecoration: 'underline',
                },
                '& a:visited': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
            },
        },
        taxTip: {
            flex: 1,
            marginLeft: 10,
            marginRight: 'auto',
            maxWidth: 544,
            justifyContent: 'center',
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        taxTipBadge: {
            marginTop: 24,
            marginBottom: 16,
            display: 'flex',
            '& .badge': {
                width: 60,
                height: 67,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        taxTipPreview: {
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: 4,
            padding: '16px 32px',
            position: 'relative',

            '& .ttp-row1': {
                display: 'flex',
                marginBottom: 4,
                alignItems: 'center',
            },

            '& .verified': {
                width: 16,
                height: 18,
                marginLeft: 9.19,
                paddingBottom: 3,
            },

            '& .arrow': {
                position: 'absolute',
                right: 45,
                top: 0,
                transform: 'translate(-20%, -35%)',
                display: 'none',
            },
        },
        taxSizeBox: {
            '& .item': {
                backgroundColor: '#FFFFFF',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.colors?.neutralPlatinum || '#E3E3E3',
                borderRadius: 8,
                boxSizing: 'border-box',
                height: 54,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                outline: 'none',
                '&:active': {
                    backgroundColor:
                        theme.colors?.neutralHoverGrey || '#F6F6F6',
                },
            },

            '& .active': {
                borderColor: '#676E77',
            },
        },
        taxIdPopup: {
            transform: 'translate(-50%, -70%)',
            [theme.breakpoints.down('xs')]: {
                left: 16,
                right: 16,
                width: 'initial',
                transform: 'translate(0, -70%)',
            },
            '& .title': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 24,
            },
            '& .content-head': {
                marginLeft: 64,
                marginRight: 64,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: 16,
                    marginRight: 16,
                },
            },
            '& .content': {
                margin: 64,
                marginTop: 32,
                color: theme.colors?.primaryDarkGray,
                '& a:link': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
                '& a:hover': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.main,
                    textDecoration: 'underline',
                },
                '& a:active': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.dark,
                    textDecoration: 'underline',
                },
                '& a:visited': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
                [theme.breakpoints.down('xs')]: {
                    margin: 16,
                    marginTop: 32,
                },
            },
        },
    }),
);

export interface TaxIdPageProps {
    organizationName: string;
    organizationType: OrganizationType;
    accountOwnerFullName: string;
    data: TaxIdInfo;
    onValidation?: (isValid: boolean) => void;
    applyForEinHRefClick?: () => void;
    denominationOptions?: OptionItem[];
}

export interface TaxIdPageRef {
    submit: () => TaxIdInfo | null;
    getData: () => TaxIdInfo;
    isValid: () => {
        isValid: boolean;
        validationResult: {
            taxId: GivelifyInputState;
            religiousAffiliation: GivelifyInputState;
            organizationYear: GivelifyInputState;
        };
    };
}

export const TaxIdPage = forwardRef<TaxIdPageRef, TaxIdPageProps>(
    (props: TaxIdPageProps, forwardRef) => {
        const {
            data,
            onValidation,
            applyForEinHRefClick,
            denominationOptions = [],
            organizationName,
            organizationType,
            accountOwnerFullName,
        } = props;
        const { t } = useTranslation(I18N_NAMESPACE);
        const copy = React.useMemo(
            () => ({
                tipTitle: t('taxIdPage.tipTitle'),
                einTip: t('taxIdPage.einTip'),
                formTitle: t('taxIdPage.formTitle'),
                sizeHeader: t('taxIdPage.sizeHeader'),
                placeholder1: t('taxIdPage.placeholder1'),
                placeholder2: t('taxIdPage.placeholder2'),
                placeholder3: t('taxIdPage.placeholder3'),
                tip1: t('taxIdPage.tip1'),
                tip3: t('taxIdPage.tip3'),
                tip4: t('taxIdPage.tip4'),
                tipContent1: t('taxIdPage.tipContent1'),
                tipContent2: t('taxIdPage.tipContent2'),
                popupTitle: t('taxIdPage.popup.title'),
                popupContent1: t('taxIdPage.popup.content1'),
                popupContent2: t('taxIdPage.popup.content2'),
                popupRef: t('taxIdPage.popup.contentRef'),
                popupContent3: t('taxIdPage.popup.content3'),
                validation: {
                    length: t('taxIdPage.validation.length'),
                    consecutive: t('taxIdPage.validation.consecutive'),
                    space: t('taxIdPage.validation.space'),
                    startsWith: '',
                },
            }),
            [t],
        );
        const {
            taxContainer,
            taxForm,
            taxTip,
            taxTipBadge,
            taxTipPreview,
            taxSizeBox,
            taxIdPopup,
        } = useStyles();
        const { body1, body3 } = GivelifyLabelStyles({});
        const showFileUpload = data.status === 'require_information';
        const [taxId, setTaxId] = React.useState(data.taxId);
        const [einFile, setEinFile] = React.useState<File | undefined>();
        const [organizationYear, setOrganizationYear] = React.useState(
            data.organizationYear,
        );
        const [religiousAffiliation, setReligiousAffiliation] = React.useState(
            data.religiousAffiliation,
        );
        const [congregationSize, setCongregationSize] = React.useState(
            data.congregationSize,
        );
        const [taxIdInputState, setTaxIdInputState] =
            React.useState<GivelifyInputState>('normal');
        const [taxIdMessage, setTaxIdMessage] = React.useState<string>(
            copy.tip1,
        );
        const [organizationYearInputState, setOrganizationYearInputState] =
            React.useState<GivelifyInputState>('normal');
        const [
            religiousAffiliationInputState,
            setReligiousAffiliationInputState,
        ] = React.useState<GivelifyInputState>('normal');

        const [showTip, setShowTip] = React.useState(false);

        const onEinTipClick = React.useCallback(() => {
            setShowTip(true);
        }, [setShowTip]);

        const getSubmitData = React.useCallback(() => {
            const newData: TaxIdInfo = {
                hasTaxId: true,
                status: 'in_progress',
                taxId,
                religiousAffiliation,
                organizationYear,
                congregationSize,
                einFile,
                submittedAt: data.submittedAt,
                submittedByOfficialId: data.submittedByOfficialId,
            };
            return newData;
        }, [
            taxId,
            religiousAffiliation,
            organizationYear,
            congregationSize,
            einFile,
            data.submittedAt,
            data.submittedByOfficialId,
        ]);

        const isTaxIdValid = React.useCallback(
            (taxId: string) => {
                const { isValid, errors } = ValidateTaxId(taxId);

                setTaxIdMessage(
                    errors && errors.taxId
                        ? copy.validation[errors.taxId]
                        : copy.tip1,
                );

                return isValid;
            },
            [copy.tip1, copy.validation],
        );

        const isReligiousAffiliationValid = React.useCallback(
            (religiousAffiliation: string | undefined) => {
                if (!religiousAffiliation) {
                    return false;
                }
                return true;
            },
            [],
        );

        const isOrganizationYearValid = React.useCallback(
            (organizationYear: string) => {
                if (!organizationYear || organizationYear.length !== 4) {
                    return false;
                }
                return (
                    new Date().getFullYear() >= parseInt(organizationYear, 0)
                );
            },
            [],
        );

        const isDataValid = React.useCallback(
            (
                taxId: string,
                religiousAffiliation: string | undefined,
                organizationYear: string,
            ) => {
                const validationResult: {
                    taxId: GivelifyInputState;
                    religiousAffiliation: GivelifyInputState;
                    organizationYear: GivelifyInputState;
                } = {
                    taxId: 'success',
                    religiousAffiliation: 'success',
                    organizationYear: 'success',
                };
                let isValid = true;
                if (showFileUpload && einFile === undefined) {
                    isValid = false;
                }
                const taxIdValidation = ValidateTaxId(taxId);
                if (!taxIdValidation.isValid && !showFileUpload) {
                    isValid = false;
                    validationResult.taxId = 'error';
                }
                if (
                    organizationType === 'church' &&
                    !isReligiousAffiliationValid(religiousAffiliation)
                ) {
                    validationResult.religiousAffiliation = 'error';
                    isValid = false;
                }
                if (!isOrganizationYearValid(organizationYear)) {
                    validationResult.organizationYear = 'error';
                    isValid = false;
                }
                return { isValid, validationResult };
            },
            [
                isReligiousAffiliationValid,
                isOrganizationYearValid,
                organizationType,
                einFile,
                showFileUpload,
            ],
        );

        const isSubmitDataValid = React.useCallback(() => {
            return isDataValid(taxId, religiousAffiliation, organizationYear);
        }, [taxId, religiousAffiliation, organizationYear, isDataValid]);

        const validateSubmitData = React.useCallback(() => {
            const { isValid, validationResult } = isDataValid(
                taxId,
                religiousAffiliation,
                organizationYear,
            );
            setTaxIdInputState(validationResult.taxId);
            setOrganizationYearInputState(validationResult.organizationYear);
            setReligiousAffiliationInputState(
                validationResult.religiousAffiliation,
            );
            return isValid;
        }, [
            taxId,
            religiousAffiliation,
            organizationYear,
            setTaxIdInputState,
            setOrganizationYearInputState,
            setReligiousAffiliationInputState,
            isDataValid,
        ]);

        const submit = React.useCallback(() => {
            const isValid = isSubmitDataValid();
            if (isValid) {
                const submitData = getSubmitData();
                return submitData;
            }
            return null;
        }, [isSubmitDataValid, getSubmitData]);

        const fireValidationResultWithDebounce = debounce(
            (isValid: boolean) => {
                if (onValidation) {
                    onValidation(isValid);
                }
            },
            300,
        );

        const onChange = React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                switch (event.currentTarget.id) {
                    case 'tax-form-tax-id': {
                        const newValue = value.substring(0, 9);
                        setTaxId(newValue);
                        setTaxIdInputState(
                            isTaxIdValid(newValue) ? 'success' : 'error',
                        );
                        break;
                    }
                    case 'tax-form-organization-year': {
                        const newValue = value.substring(0, 4);
                        setOrganizationYear(newValue);
                        setOrganizationYearInputState(
                            isOrganizationYearValid(newValue)
                                ? 'success'
                                : 'error',
                        );
                        break;
                    }
                }
            },
            [
                setTaxId,
                setOrganizationYear,
                setTaxIdInputState,
                isTaxIdValid,
                isOrganizationYearValid,
                setOrganizationYearInputState,
            ],
        );

        const onDenominationChange = React.useCallback(
            (value: string) => {
                setReligiousAffiliation(value);
                setReligiousAffiliationInputState(
                    isReligiousAffiliationValid(value) ? 'success' : 'error',
                );
            },
            [
                setReligiousAffiliation,
                setReligiousAffiliationInputState,
                isReligiousAffiliationValid,
            ],
        );

        React.useEffect(() => {
            const validation = isDataValid(
                taxId,
                religiousAffiliation,
                organizationYear,
            );
            fireValidationResultWithDebounce(validation.isValid);
        }, [
            taxId,
            religiousAffiliation,
            organizationYear,
            fireValidationResultWithDebounce,
            isDataValid,
        ]);

        useImperativeHandle(forwardRef, () => ({
            getData: getSubmitData,
            isValid: isSubmitDataValid,
            validate: validateSubmitData,
            submit: submit,
        }));

        const congregationSizeItems = [];
        for (const size of congregationSizeList) {
            congregationSizeItems.push(
                <Grid item sm={3} xs={6}>
                    <button
                        className={`item ${
                            congregationSize === size && 'active'
                        }`}
                        onClick={() => setCongregationSize(size)}
                        type="button"
                    >
                        <GivelifyLabel text={size} variant="body1" />
                    </button>
                </Grid>,
            );
        }

        return (
            <div className={taxContainer} id="tax-id-page">
                <div className={taxForm} id="tax-form">
                    <EinUploadSection
                        onChange={setEinFile}
                        show={data.status === 'require_information'}
                    />
                    <GivelifyLabel
                        id="tax-form-title"
                        marginBottom={8}
                        text={copy.formTitle}
                        variant="heading3"
                    />
                    <GivelifyButton
                        marginBottom={16}
                        onClick={onEinTipClick}
                        text={copy.einTip}
                        variant="link"
                    />
                    <GivelifyTextField
                        ariaLabel="tax-form-tax-id-editor"
                        id="tax-form-tax-id"
                        label={copy.placeholder1}
                        leftHelperText={taxIdMessage}
                        marginBottom={40}
                        maxLength={9}
                        onChange={onChange}
                        placeholder={copy.placeholder1}
                        state={taxIdInputState}
                        type="text"
                        value={taxId}
                    />
                    <GivelifyLabel
                        marginBottom={24}
                        text="Additional info"
                        variant="heading3"
                    />
                    <GivelifyTextField
                        ariaLabel="tax-form-organization-year-editor"
                        id="tax-form-organization-year"
                        label={copy.placeholder2}
                        marginBottom={16}
                        onChange={onChange}
                        placeholder={copy.placeholder2}
                        state={organizationYearInputState}
                        type="number"
                        value={organizationYear}
                    />
                    {organizationType === 'church' ? (
                        <>
                            <GivelifyDropDown
                                disableAutoScroll
                                disableClearButton
                                ariaLabel="Religious affiliation"
                                id="tax-form-religious-affiliation"
                                marginBottom={3}
                                onChange={onDenominationChange}
                                options={denominationOptions}
                                optionsWidth="100%"
                                placeholder={copy.placeholder3}
                                state={religiousAffiliationInputState}
                                value={religiousAffiliation}
                                width="100%"
                            />
                            <div
                                className={mergeClassNames(
                                    body3,
                                    'affiliation-caption',
                                )}
                            >
                                {copy.tip3}{' '}
                                <a
                                    href={webConfig.supportArticleLink}
                                    target="blank"
                                >
                                    {copy.tip4}
                                </a>
                                {'.'}
                            </div>
                            <GivelifyLabel
                                color="neutralGrey"
                                marginBottom={8}
                                text={copy.sizeHeader}
                                variant="body3"
                            />
                            <Grid container className={taxSizeBox} spacing={2}>
                                {congregationSizeItems}
                            </Grid>
                        </>
                    ) : null}
                </div>
                <div className={taxTip} id="tax-tip">
                    <InfoBox>
                        <div className={taxTipBadge}>
                            <SvgIcon
                                className="badge"
                                component={TaxIdSvg}
                                viewBox="0 0 60 67"
                            />
                        </div>
                        <GivelifyLabel
                            marginBottom={24}
                            text={copy.tipTitle}
                            variant="heading2"
                        />
                        <GivelifyLabel
                            marginBottom={21}
                            text={copy.tipContent1}
                            variant="body1"
                        />
                        <GivelifyLabel
                            color="primaryDarkGray"
                            fontSize={14}
                            lineHeight={16}
                            marginBottom={16}
                            marginTop={41}
                            text={copy.tipContent2}
                        />
                        <div className={taxTipPreview}>
                            <div className="ttp-row1">
                                <GivelifyLabel
                                    text={organizationName}
                                    variant="heading5"
                                />
                                <SvgIcon
                                    className="verified"
                                    component={VerifiedSvg}
                                    viewBox="0 0 15 17"
                                />
                            </div>
                            <GivelifyLabel
                                color="neutralGrey"
                                text={accountOwnerFullName}
                                variant="body2"
                            />
                            <img
                                alt="arrow"
                                className="arrow"
                                src={roundedArrow}
                            />
                        </div>
                    </InfoBox>
                </div>
                <GivelifyModal
                    disableEnforceFocus
                    showCloseButton
                    contentClassName={taxIdPopup}
                    onClose={() => setShowTip(false)}
                    open={showTip}
                >
                    <div className="title">
                        <GivelifyLabel
                            text={copy.popupTitle}
                            variant="heading2"
                        />
                    </div>
                    <GivelifyLabel
                        className="content-head"
                        text={copy.popupContent1}
                        variant="body1"
                    />
                    <div className={mergeClassNames(body1, 'content')}>
                        {copy.popupContent2}{' '}
                        <a
                            href={webConfig.applyEinLink}
                            onClick={(e) => {
                                if (applyForEinHRefClick !== undefined) {
                                    e.preventDefault();
                                    applyForEinHRefClick();
                                }
                            }}
                            target="blank"
                        >
                            {copy.popupRef}
                        </a>{' '}
                        {copy.popupContent3}
                    </div>
                </GivelifyModal>
            </div>
        );
    },
);

TaxIdPage.displayName = 'TaxIdPage';
