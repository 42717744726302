import { IntegrationTypes } from '../types';

export const getAutoIntegrationType = (integration: IntegrationTypes) => {
    return {
        isCCB: integration.integrationType === 'CCB',
        isPlanningCenter: integration.integrationType === 'PLANNING_CENTER',
        isF1: integration.integrationType === 'F1',
        isF1Go: integration.integrationType === 'F1GO',
    };
};
