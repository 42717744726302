import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgErrorWarningColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="39"
        width="38"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 38 39"
    >
        <circle
            cx="19"
            cy="19.5"
            fill="#FEFAEC"
            r="17.8125"
            stroke="white"
            strokeWidth="1.1875"
            transform="rotate(-180 19 19.5)"
        />
        <path
            clipRule="evenodd"
            d="M19 10.5937C14.0812 10.5937 10.0938 14.5812 10.0938 19.5C10.0938 24.4188 14.0812 28.4062 19 28.4062C23.9188 28.4062 27.9063 24.4188 27.9063 19.5C27.9063 14.5812 23.9188 10.5937 19 10.5937ZM19 8.8125C13.0975 8.8125 8.3125 13.5975 8.3125 19.5C8.3125 25.4025 13.0975 30.1875 19 30.1875C24.9025 30.1875 29.6875 25.4025 29.6875 19.5C29.6875 13.5975 24.9025 8.8125 19 8.8125ZM17.8125 24.25C17.8125 23.5942 18.3442 23.0625 19 23.0625C19.6558 23.0625 20.1875 23.5942 20.1875 24.25C20.1875 24.9058 19.6558 25.4375 19 25.4375C18.3442 25.4375 17.8125 24.9058 17.8125 24.25ZM18.1094 20.9844C18.1094 21.4763 18.5081 21.875 19 21.875C19.4919 21.875 19.8906 21.4763 19.8906 20.9844L19.8906 14.4531C19.8906 13.9612 19.4919 13.5625 19 13.5625C18.5081 13.5625 18.1094 13.9612 18.1094 14.4531L18.1094 20.9844Z"
            fill="#9C6103"
            fillRule="evenodd"
        />
    </svg>
);

export const ErrorWarningColoredIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgErrorWarningColored}
        fontSize={fontSize}
    />
);
