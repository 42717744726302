import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyLabelStyles,
    GivelifyFormTextField,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { mergeClassNames } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { CampusItem } from '../../../types';
import { FormProps } from '../types';
import useStyles from './styles';

interface EditFormProps {
    item: CampusItem;
}

const EditForm: React.FC<EditFormProps> = ({ item }) => {
    const { PATH } = useGasRouterContext();
    const { wrapper, addressWrapper, textWrapper, link } = useStyles();
    const { body1 } = GivelifyLabelStyles({});

    const { address, city, state, name, zip, phone } = item;
    const { register, watch, errors } = useFormContext<FormProps>();
    const { short_name } = watch();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem.editModal',
    });

    const copy = useMemo(
        () => ({
            campusDetails: scopedTranslate('campusDetails'),
            nicknameTitle: scopedTranslate('nicknameTitle'),
            nicknameDescription: scopedTranslate('nicknameDescription'),
            nicknameLabel: scopedTranslate('nicknameLabel'),
            nicknameHelper: scopedTranslate('nicknameHelper'),
            nicknameUniqueValidation: scopedTranslate(
                'nicknameUniqueValidation',
            ),
            nameTitle: scopedTranslate('nameTitle'),
            nameDescription: scopedTranslate('nameDescription'),
            linkDescription: scopedTranslate('linkDescription'),
            linkText: scopedTranslate('linkText'),
        }),
        [scopedTranslate],
    );

    return (
        <div className={wrapper}>
            <GivelifyLabel
                marginBottom={24}
                text={copy.campusDetails}
                variant="heading3"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.nicknameTitle}
                variant="heading5"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.nicknameDescription}
                variant="body1"
            />
            <GivelifyFormTextField
                countLength
                defaultValue={short_name}
                formInputRef={register({
                    required: true,
                })}
                id="shortName"
                label={copy.nicknameLabel}
                leftHelperText={
                    errors.short_name?.message ||
                    (errors.short_name?.type === 'unique' &&
                        copy.nicknameUniqueValidation) ||
                    copy.nicknameHelper
                }
                marginBottom={32}
                maxLength={50}
                name="short_name"
                state={errors.short_name ? 'error' : 'normal'}
            />

            <GivelifyLabel
                marginBottom={16}
                text={copy.nameTitle}
                variant="heading5"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.nameDescription}
                variant="body1"
            />
            <div className={addressWrapper} id={`campus-address-wrapper`}>
                <GivelifyLabel
                    id="campus-name"
                    text={name}
                    variant="heading4"
                />
                <GivelifyLabel
                    id="campus-address"
                    text={address}
                    variant="heading4"
                />
                <GivelifyLabel
                    id="campus-city-state-zip"
                    text={`${city}, ${state} ${zip}`}
                    variant="heading4"
                />
                <GivelifyLabel
                    id="campus-phone"
                    text={phone}
                    variant="heading4"
                />
            </div>
            <div className={mergeClassNames(textWrapper, body1)}>
                {`${copy.linkDescription} `}
                <Link
                    className={link}
                    title={copy.linkText}
                    to={PATH.SETTINGS.APP_PROFILE('profile')}
                >
                    {copy.linkText}
                </Link>
                {'.'}
            </div>
        </div>
    );
};

export default EditForm;
