import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgByEnvelopeFill = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={42}
        width={42}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 42 42"
    >
        <path
            d="M20.3 34.0078C21.602 34.0078 22.904 33.6028 24.0158 32.7946L40.5999 20.7275V37.7514C40.5999 39.8688 38.8958 41.5853 36.7937 41.5853H3.80624C1.7041 41.5853 0 39.8688 0 37.7514V20.7275L16.5841 32.7946C17.6959 33.6037 18.9979 34.0078 20.3 34.0078ZM39.1417 13.7059C40.0622 14.4327 40.5999 15.5454 40.5999 16.7226V17.5733L32.9874 23.1124V8.35842H7.61248V23.1124L0 17.5733V16.7226C0 15.6927 0.653423 14.3412 1.45824 13.7059C2.16006 13.1515 2.82533 12.6292 3.80624 11.884V8.35842C3.80624 6.24102 5.51035 4.52455 7.61248 4.52455H13.7619C14.003 4.34881 14.2274 4.18431 14.4788 4.00062C15.8126 3.02055 18.4603 0.662751 20.3 0.690676C22.1397 0.662751 24.7881 3.02133 26.1211 4.00062C26.3725 4.18431 26.5969 4.34881 26.838 4.52455H32.9874C35.0896 4.52455 36.7937 6.24102 36.7937 8.35842V11.884C37.7746 12.63 38.4398 13.1515 39.1417 13.7059ZM20.9343 11.5533C21.2848 11.5533 21.5687 11.8393 21.5687 12.1923V13.4934C22.464 13.5397 23.3346 13.8544 24.0562 14.3999C24.3655 14.634 24.3813 15.1005 24.1015 15.3696L23.1697 16.265C22.95 16.4758 22.6233 16.4854 22.3664 16.3233C22.0604 16.1292 21.7114 16.0262 21.3499 16.0262H19.1208C18.6054 16.0262 18.1851 16.499 18.1851 17.0797C18.1851 17.5549 18.4714 17.9727 18.8805 18.0965L22.4489 19.1747C23.9238 19.6204 24.9531 21.0453 24.9531 22.6404C24.9531 24.5996 23.4425 26.1899 21.5695 26.2402V27.5278C21.5695 27.8808 21.2856 28.1668 20.9351 28.1668H19.6664C19.3159 28.1668 19.032 27.8808 19.032 27.5278V26.2267C18.1367 26.1803 17.2661 25.8657 16.5444 25.3201C16.2352 25.0861 16.2193 24.6196 16.4993 24.3504L17.431 23.4551C17.6506 23.2442 17.9773 23.2346 18.2343 23.3968C18.5403 23.5909 18.8893 23.6939 19.2508 23.6939H21.4799C21.9953 23.6939 22.4156 23.2211 22.4156 22.6404C22.4156 22.1652 22.1294 21.7474 21.7202 21.6236L18.1518 20.5453C16.6769 20.0997 15.6476 18.6747 15.6476 17.0797C15.6476 15.1212 17.1582 13.5302 19.0312 13.4798V12.1923C19.0312 11.8393 19.3151 11.5533 19.6656 11.5533H20.9343Z"
            fill="current"
        />
    </svg>
);

export const ByEnvelopeFillIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgByEnvelopeFill}
        fontSize={fontSize}
    />
);
