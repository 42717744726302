import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    generateContent: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            '& button': {
                // Needed to override the existing style(src/pages/login/useStyles.ts) b/c `Ok` button looked strange on mobile
                display: 'inline-flex',
            },
        },
    },

    generateTitle: {
        marginBottom: 16,
        marginTop: 32,
    },
    generateText: {
        marginBottom: 32,
    },
}));
