import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const DonationSummaryInfoSummaryWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
    marginBottom: '24px',
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '16px',
    },
}));

export const NameWrapper = styled('div')({
    width: 'calc(50% - 8px)',
    textAlign: 'right',
});

export const DonationInfoName = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: '18px',
    lineHeight: '32px',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
        lineHeight: '24px',
    },
}));

export const Divider = styled('div')(({ theme }) => ({
    width: '16px',
    height: '2px',
    backgroundColor: DesignTokens.color.dividerPrimaryDark,
    marginTop: '15px',
    [theme.breakpoints.down('mobile')]: {
        marginTop: '11px',
    },
}));

export const InfoPart = styled('div')({
    width: 'calc(50% - 8px)',
});

export const InfoPartText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '16px',
        lineHeight: '24px',
    },
}));

export const InfoPartSecondaryText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        lineHeight: '24px',
    },
}));
