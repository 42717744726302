import React from 'react';
import { Checkbox, Stack, styled } from '@mui/material';
import { DarkCheckboxCheckedIcon, DarkCheckboxIcon } from '../assets';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { GivelifyCheckboxProps } from './types';

const GivelifyCheckboxView = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
        fontSize: 24,
        '@media (max-width: 767px)': {
            fontSize: 20,
        },
    },
    '&.MuiCheckbox-root': {
        padding: 0,
        marginRight: 8,
        '@media (max-width: 767px)': {
            marginRight: 6,
        },
    },
    '&.MuiCheckbox-colorPrimary': {
        color: DesignTokens.color.iconPrimary,
        '&:hover': {
            color: DesignTokens.color.iconHover,
            background: DesignTokens.color.borderComponentWhite,
        },
    },
    '&.Mui-checked': {
        '&.MuiCheckbox-colorPrimary': {
            color: DesignTokens.color.iconAccentDefault,
            '&:hover': {
                color: DesignTokens.color.iconAccentHover,
            },
        },
    },
    '&.Mui-disabled': {
        color: DesignTokens.color.iconDisabled,
        background: DesignTokens.color.borderComponentWhite,
        '&.Mui-checked': {
            color: DesignTokens.color.iconDisabled,
        },
    },
});
const GivelifyDarkCheckboxView = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
        fontSize: 27,
        '@media (max-width: 767px)': {
            fontSize: 20,
        },
    },
    '&.MuiCheckbox-root': {
        padding: 0,
        marginRight: 8,
        '@media (max-width: 767px)': {
            marginRight: 6,
        },
    },
    '&.MuiCheckbox-colorPrimary': {
        color: DesignTokens.color.globalNeutral700,
        '&:hover': {
            color: DesignTokens.color.globalNeutral600,
        },
    },
    '&.Mui-checked': {
        '&.MuiCheckbox-colorPrimary': {
            color: DesignTokens.color.iconAccentDefault,
            '&:hover': {
                color: DesignTokens.color.iconAccentHover,
            },
        },
    },
    '&.Mui-disabled': {
        color: DesignTokens.color.globalNeutral800,
        '&.Mui-checked': {
            color: DesignTokens.color.globalNeutral700,
            '& path': {
                stroke: DesignTokens.color.iconPrimary,
            },
        },
    },
    '@media (max-width: 767px)': {},
});

const GivelifyCheckboxComponent: React.FC<GivelifyCheckboxProps> = ({
    darkMode,
    ...props
}) => {
    if (darkMode) {
        return (
            <GivelifyDarkCheckboxView
                {...props}
                checkedIcon={<DarkCheckboxCheckedIcon />}
                icon={<DarkCheckboxIcon />}
            />
        );
    }
    return <GivelifyCheckboxView {...props} />;
};

const GivelifyLabelEllipsis = styled(GivelifyLabel, {
    shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode?: boolean }>(({ darkMode }) =>
    darkMode
        ? {
              overflow: 'hidden',
              width: '100%',
              textOverflow: 'ellipsis',
              color: DesignTokens.color.textWhite,
              '@media (max-width: 767px)': {
                  fontSize: '12px',
                  lineHeight: '16px',
              },
          }
        : {
              overflow: 'hidden',
              width: '100%',
              textOverflow: 'ellipsis',
              '@media (max-width: 767px)': {
                  fontSize: '12px',
                  lineHeight: '16px',
              },
          },
);

const GivelifyCheckboxLabel: React.FC<GivelifyCheckboxProps> = ({
    label,
    secondLabel,
    multipleLine,
    rootClass,
    ...props
}) => {
    if (multipleLine) {
        return (
            <Stack
                alignItems="start"
                className={rootClass}
                direction="row"
                spacing={0}
                width="100%"
            >
                <GivelifyCheckboxComponent {...props} />
                <Stack
                    alignItems="start"
                    direction="column"
                    spacing={1}
                    width="calc(100% - 36px)"
                >
                    <GivelifyLabelEllipsis
                        darkMode={props.darkMode}
                        text={label}
                        title={label}
                        variant="body1"
                    />
                    {secondLabel && (
                        <GivelifyLabelEllipsis
                            color={DesignTokens.color.textSecondary}
                            text={secondLabel}
                            title={secondLabel}
                            variant="body1"
                        />
                    )}
                </Stack>
            </Stack>
        );
    }
    return (
        <Stack
            alignItems="center"
            className={rootClass}
            direction="row"
            spacing={0}
            width="100%"
        >
            <GivelifyCheckboxComponent {...props} />
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                width="calc(100% - 36px)"
            >
                <GivelifyLabelEllipsis
                    darkMode={props.darkMode}
                    text={label}
                    title={label}
                    variant="body1"
                />
                {secondLabel && (
                    <GivelifyLabelEllipsis
                        color={DesignTokens.color.textSecondary}
                        text={secondLabel}
                        title={secondLabel}
                        variant="body1"
                    />
                )}
            </Stack>
        </Stack>
    );
};

export const GivelifyCheckbox: React.FC<GivelifyCheckboxProps> = ({
    label,
    secondLabel,
    multipleLine,
    size = 'small',
    color = 'primary',
    rootClass,
    ...props
}) => {
    if (label) {
        return (
            <GivelifyCheckboxLabel
                label={label}
                multipleLine={multipleLine}
                rootClass={rootClass}
                secondLabel={secondLabel}
                {...props}
            />
        );
    }

    return <GivelifyCheckboxComponent {...props} />;
};
