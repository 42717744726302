export const enTranslations = {
    givelithonModal: {
        howWork: 'What is a Givelithon?',
        info:
            'A Givelithon is a real-time view of fundraising progress. Watching donations appear instantly motivates your donors to meet and exceed your fundraising goals.',
        step1: {
            church:
                'Choose the envelope for your fundraiser. Want to start fresh? Create a new envelope in Settings.',
            nonprofit:
                'Choose the campaign for your fundraiser. Want to start fresh? Create a new campaign in Settings.',
        },
        step2:
            'Launch your Givelithon. If you have a large audience, we recommend projecting your screen.',
        step3:
            'As donors give, the progress bar fills up. You’ll see a special celebration screen when you reach your goal.',
    },
    givelithonButton: {
        ok: 'OK',
        launch: 'Launch Givelithon',
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
    },
    givelithonPage: {
        givelithon: 'Givelithon',
        activeEnvelope: {
            church: 'Active Envelopes',
            nonprofit: 'Active Campaigns',
        },
        description: {
            start: {
                church: 'To create and edit an envelope, ',
                nonprofit: 'To create and edit a campaign, ',
            },
            link: {
                church: 'go to Envelopes',
                nonprofit: 'go to Campaigns',
            },
            end: ' in Settings.',
        },
        goal: 'Goal',
        availableAlways: 'Always available',
        availableByDefault: 'Available by default',
        availableUntil: 'Available until ',
        sortBy: 'Sort by',
        atoz: 'A to Z',
        ztoa: 'Z to A',
    },
    launchGivelithon: {
        totalDonation: 'Donation total',
        getGivingGoing: 'Get the giving going',
        thankYou: 'Thank You!',
        hideDonors: 'Hide donors',
        showDonors: 'Show donors',
        donor: '1 Donor',
        donor_plural: '{{count}} Donors',
    },
};
