export const TOTAL_LIFETIME_DONATIONS_DATE = 'TOTAL_LIFETIME_DONATIONS_DATE';

const REQUEST_TIMEOUT_MINUTES = 30;

export const setDateLifeTimeDonations = (doneeId: number): void =>
    localStorage.setItem(
        TOTAL_LIFETIME_DONATIONS_DATE,
        JSON.stringify({ doneeId, date: new Date() }),
    );

export const getDateLifeTimeDonations = (): string | null =>
    localStorage.getItem(TOTAL_LIFETIME_DONATIONS_DATE);

export const shouldRequestLifeTimeDonation = (doneeId: number): boolean => {
    const lsData = JSON.parse(getDateLifeTimeDonations());
    if (!lsData) return true;
    const newDate = new Date();
    const prevDate = new Date(lsData.date);
    const lsDoneeId = lsData.doneeId;
    return (
        (newDate.getTime() - prevDate.getTime()) / 1000 / 60 >
            REQUEST_TIMEOUT_MINUTES || lsDoneeId !== doneeId
    );
};
