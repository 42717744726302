import React from 'react';
import { styled } from '@mui/material';
import { formatMoney } from '../formatMoneyWithCommas';

type MoneyRange = {
    min: null | string | number;
    max: null | string | number;
};

const MoneyRangeText = styled('span')({
    margin: '0 2px',
});

export const generateMoneyRange = ({ min, max }: MoneyRange): JSX.Element => {
    if (min) {
        const minFormattedMoney = formatMoney(min);
        const minFormattedText =
            minFormattedMoney.length > 18
                ? minFormattedMoney.substring(0, 18) + '...'
                : minFormattedMoney;
        if (max) {
            const maxFormattedMoney = formatMoney(max);
            const maxFormattedText =
                maxFormattedMoney.length > 18
                    ? maxFormattedMoney.substring(0, 18) + '...'
                    : maxFormattedMoney;
            return (
                <>
                    <MoneyRangeText title={minFormattedMoney}>
                        {minFormattedText}
                    </MoneyRangeText>{' '}
                    -{' '}
                    <MoneyRangeText title={maxFormattedMoney}>
                        {maxFormattedText}
                    </MoneyRangeText>
                </>
            );
        }
        return (
            <MoneyRangeText title={minFormattedMoney}>
                {minFormattedText}+
            </MoneyRangeText>
        );
    }
    if (max) {
        const maxFormattedMoney = formatMoney(max);
        const maxFormattedText =
            maxFormattedMoney.length > 18
                ? maxFormattedMoney.substring(0, 18) + '...'
                : maxFormattedMoney;
        return (
            <>
                <MoneyRangeText title="$0">{formatMoney(0)}</MoneyRangeText> -{' '}
                <MoneyRangeText title={maxFormattedMoney}>
                    {maxFormattedText}
                </MoneyRangeText>
            </>
        );
    }
};
