import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyDropDownOptionsStyles } from './styles';
import { GivelifyLabel } from '../label';
import { DoubleLineOption } from './DoubleLineOption';
import { SingleLineOption } from './SingleLineOption';

export interface DropDownOptionsProps {
    id: string;
    options: {
        value: string;
        label: string;
        selected: boolean;
        active: boolean;
        description?: string;
    }[];
    onSelect: (value: string) => void;
    header?: string;
    className?: string;
    hasPadding?: boolean;
    checkMarkVariant?: 'mark' | 'radio' | 'checkbox';
    hideCheckMark?: boolean;
    optionClasses?: {
        root?: string;
        checkRoot?: string;
        checkIcon?: string;
        contentRoot?: string;
        activeOption?: string;
        label?: string;
        description?: string;
        header?: string;
        footer?: string;
    };
    onHeaderRender?: () => React.ReactNode;
    onFooterRender?: () => React.ReactNode;
    disableAutoScroll?: boolean;
    menuHeight?: 'auto' | number;
    width?: number | 'auto' | '100%';
}

export function DropDownOptions(props: DropDownOptionsProps) {
    const {
        id,
        options,
        onSelect,
        header,
        hasPadding,
        checkMarkVariant = 'mark',
        hideCheckMark,
        className,
        optionClasses,
        onHeaderRender,
        onFooterRender,
        disableAutoScroll,
        menuHeight,
        width = '100%',
    } = props;
    const {
        drdOptions,
        drdOptionHeading,
        drdOptionUp,
        drdOptionHeader,
        drdOptionFooter,
    } = GivelifyDropDownOptionsStyles({
        hasHeader: !!onHeaderRender,
        hasFooter: !!onFooterRender,
        menuHeight,
        width,
    });
    const headerClassName = mergeClassNames(
        drdOptionHeader,
        optionClasses?.header,
    );
    const footerClassName = mergeClassNames(
        drdOptionFooter,
        optionClasses?.footer,
    );
    const optionsClassName = mergeClassNames(
        drdOptions,
        hasPadding && drdOptionUp,
        className,
    );
    return (
        <div id={id} className={optionsClassName}>
            {onHeaderRender && (
                <div id={`${id}-header`} className={headerClassName}>
                    {onHeaderRender()}
                </div>
            )}
            {header && (
                <div id={`${id}-heading`} className={drdOptionHeading}>
                    <GivelifyLabel text={header} variant="heading5" />
                </div>
            )}
            {options.map(item =>
                item.description ? (
                    <DoubleLineOption
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        selected={item.selected}
                        onSelect={onSelect}
                        active={item.active}
                        description={item.description}
                        checkMarkVariant={checkMarkVariant}
                        hideCheckMark={hideCheckMark}
                        classes={optionClasses}
                        disableAutoScroll={disableAutoScroll}
                    />
                ) : (
                    <SingleLineOption
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        selected={item.selected}
                        onSelect={onSelect}
                        active={item.active}
                        checkMarkVariant={checkMarkVariant}
                        hideCheckMark={hideCheckMark}
                        classes={optionClasses}
                        disableAutoScroll={disableAutoScroll}
                    />
                ),
            )}
            {onFooterRender && (
                <div id={`${id}-footer`} className={footerClassName}>
                    {onFooterRender()}
                </div>
            )}
        </div>
    );
}
