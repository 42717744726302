import React from 'react';
import { Header } from './view';

const HeaderWrapperComponent: React.FC<{
    hideTotalDonations?: boolean;
}> = ({ hideTotalDonations }) => {
    return <Header hideTotalDonations={hideTotalDonations} />;
};

export const HeaderWrapper = React.memo(HeaderWrapperComponent);
