import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgImportFriendly = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={42}
        width={41}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 41 42"
    >
        <path
            d="M1.23854 23.6937H9.90831V28.8055H1.23854C0.557358 28.8055 0 28.2304 0 27.5275V24.9716C0 24.2688 0.557358 23.6937 1.23854 23.6937ZM39.0914 9.07703C39.4397 9.43645 39.6332 9.92364 39.6332 10.4269V10.9141H29.7249V0.69043H30.1971C30.6925 0.69043 31.1647 0.890111 31.513 1.24954L39.0914 9.07703ZM27.2479 11.5531C27.2479 12.6074 28.0838 13.47 29.1057 13.47H39.6332V39.6681C39.6332 40.7304 38.805 41.5851 37.7754 41.5851H11.7661C10.7366 41.5851 9.90831 40.7304 9.90831 39.6681V28.8055H19.8089V34.0051C19.8089 35.1474 21.1481 35.7145 21.9299 34.9078L29.3379 27.208C29.8488 26.6809 29.8488 25.8263 29.3379 25.2911L21.9376 17.5834C21.1558 16.7768 19.8166 17.3438 19.8166 18.486V23.6937H9.90831V2.60737C9.90831 1.54508 10.7366 0.69043 11.7661 0.69043H27.2479V11.5531Z"
            fill="current"
        />
    </svg>
);

export const ImportFriendlyIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgImportFriendly}
        fontSize={fontSize}
    />
);
