import { ErrorResponse } from '@givelify/givelify-ui';
import { DonationsTimeFrameData } from '../types';
import {
    SetDonationsCurrentLoading,
    SET_DONATIONS_CURRENT_LOADING,
    SetDonationsCurrentSuccess,
    SET_DONATIONS_CURRENT_SUCCESS,
    SetDonationsCurrentFailed,
    SET_DONATIONS_CURRENT_FAILED,
} from './types';

export const setDonationsCurrentLoading: SetDonationsCurrentLoading = {
    type: SET_DONATIONS_CURRENT_LOADING,
};

export const setDonationsCurrentSuccess = (
    data: DonationsTimeFrameData,
): SetDonationsCurrentSuccess => ({
    type: SET_DONATIONS_CURRENT_SUCCESS,
    data,
});

export const setDonationsCurrentFailed = (
    error: ErrorResponse,
): SetDonationsCurrentFailed => ({
    type: SET_DONATIONS_CURRENT_FAILED,
    error,
});
