export const BUTTON_IDENTIFIER = 'btn';
export const RADIO_BUTTON_IDENTIFIER = 'radbtn';
export const LINK_IDENTIFIER = 'lnk';
export const CHECKBOX_IDENTIFIER = 'chkbox';
export const DROPDOWN_IDENTIFIER = 'drpd';

export const INPUT_IDENTIFIER = 'inpt';

export const SECTOR_IDENTIFIER = 'sctr';

export const BAR_IDENTIFIER = 'bar';
