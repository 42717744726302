import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const LoadingBox = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: DesignTokens.color.borderComponentDefault,
    backgroundColor: DesignTokens.color.backgroundPrimary,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    gap: theme.spacing(1),
}));

export const SuccessBox = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: DesignTokens.color.borderComponentSuccess,
    backgroundColor: DesignTokens.color.backgroundComponentSuccess,
    borderRadius: DesignTokens.measurement.borderRadiusM,
    gap: theme.spacing(1),
}));

export const Row = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
}));
