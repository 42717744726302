import {
    requestInit,
    requestStart,
    requestSuccess,
    requestError,
} from '@givelify/givelify-ui';
import { Reducer } from 'redux';
import {
    LifeTimeState,
    LifeTimeActions,
    SET_LIFE_TIME_LOADING,
    SET_LIFE_TIME_SUCCESS,
    SET_LIFE_TIME_FAILED,
} from './types';

const LifeTimeReducer: Reducer<LifeTimeState, LifeTimeActions> = (
    state = requestInit(),
    action,
) => {
    switch (action.type) {
        case SET_LIFE_TIME_LOADING:
            return requestStart();
        case SET_LIFE_TIME_SUCCESS:
            return requestSuccess(action.data);
        case SET_LIFE_TIME_FAILED:
            return requestError(action.error);
        default:
            return state;
    }
};

export default LifeTimeReducer;
