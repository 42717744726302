import { isValidPhoneNumber, isValidSSN, isValidStreet } from '@givelify/utils';
import dayjs from 'dayjs';
import * as yup from 'yup';
import {
    PrimaryRepAddressForm,
    PrimaryRepInfoForm,
    apiHiddenSsnRegex,
    apiHiddenSsnRegex11Digits,
} from './types';

export const useRepInfoSchema: (
    t: (key: string, obj?: { [key: string]: number }) => string,
) => yup.SchemaOf<PrimaryRepInfoForm> = (t) =>
    yup.object().shape({
        isPrimaryRepresentative: yup.boolean().required(),
        firstName: yup.string().required(t('labels.required')),
        lastName: yup.string().required(t('labels.required')),
        title: yup.string().required(t('labels.required')),
        ssn: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    const value = val.replace(/[^0-9]/g, '');
                    return (
                        isValidSSN(value) ||
                        apiHiddenSsnRegex.test(val) ||
                        apiHiddenSsnRegex11Digits.test(val)
                    );
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.invalidSsn',
                ),
            }),
        dateOfBirth: yup
            .date()
            .nullable()
            .required(t('labels.required'))
            .typeError(t('labels.required'))
            .test({
                test: (val) => dayjs().diff(val, 'year') >= 18,
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.mustBeOver18',
                ),
            }),
    });

export const useRepAddressSchema: (
    t: (key: string, obj?: { [key: string]: number }) => string,
) => yup.SchemaOf<PrimaryRepAddressForm> = (t) =>
    yup.object().shape({
        city: yup.string().required(t('labels.required')),
        zip: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    return val.length === 5 && !isNaN(Number(val));
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.zipTip',
                ),
            }),
        phoneNumber: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    return isValidPhoneNumber(val);
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.phoneTip',
                ),
            }),
        state: yup.string().required(t('labels.required')),
        street: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    return isValidStreet(val, true);
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.addressTip',
                ),
            }),
    });
