import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithTimezone } from '@givelify/utils';

const CreatedAtCell: React.FC = (props: Table.DataCellProps) => {
    const { createdAt } = props.row;

    return (
        <Table.Cell {...props}>
            <GivelifyLabel
                text={formatWithTimezone(createdAt.split(' ')[0])}
                variant="body2"
            />
        </Table.Cell>
    );
};

export default CreatedAtCell;
