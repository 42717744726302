import React, { useState } from 'react';
import { useDebouncedRippleCleanUp } from './useDebouncedRippleCleanUp';
import { GivelifyButtonRippleStyles } from './styles';

export const GivelifyButtonRipple = (props: { color: string }) => {
    const { btnRipple } = GivelifyButtonRippleStyles();
    const [rippleArray, setRippleArray] = useState<
        {
            x: number;
            y: number;
            size: number;
        }[]
    >([]);

    useDebouncedRippleCleanUp(rippleArray.length, 400, () => {
        setRippleArray([]);
    });

    const addRipple = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const rippleContainer = event.currentTarget.getBoundingClientRect();
        const size =
            rippleContainer.width > rippleContainer.height
                ? rippleContainer.width
                : rippleContainer.height;
        const x = event.clientX - rippleContainer.x - size / 2;
        const y = event.clientY - rippleContainer.y - size / 2;
        const newRipple = {
            x,
            y,
            size,
        };
        setRippleArray([...rippleArray, newRipple]);
    };

    return (
        <div onMouseDown={addRipple} className={btnRipple}>
            {rippleArray.length > 0 &&
                rippleArray.map((ripple, index) => {
                    return (
                        <span
                            className="ripple"
                            key={'span' + index}
                            style={{
                                top: ripple.y,
                                left: ripple.x,
                                width: ripple.size,
                                height: ripple.size,
                                backgroundColor: props.color,
                            }}
                        />
                    );
                })}
        </div>
    );
};
