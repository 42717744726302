import React from 'react';
import {  GivelifyForm } from '@givelify/ui';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { UseTaxIdContext } from '../TaxIdContext';
import { OrgInfoFormSchema } from './types';
import View from './view';

const OrganizationInfo: React.FC = () => {
    const { organizationType, taxIdInfo, onOrgInfoContinue } =
        UseTaxIdContext();

    const schema: yup.SchemaOf<OrgInfoFormSchema> = yup.object().shape({
        organizationYear: yup
            .string()
            .required('')
            .min(4, '')
            .test('maxYear', '', (value) =>
                value ? dayjs().year() >= +value : false,
            ),
        ...(organizationType === 'church'
            ? {
                  congregationSize: yup.string().required(),
                  religiousAffiliation: yup.string().required().min(2),
              }
            : {
                  congregationSize: yup.string(),
                  religiousAffiliation: yup.string(),
              }),
    });

    return (
        <GivelifyForm<OrgInfoFormSchema>
            defaultValues={{
                congregationSize: taxIdInfo.congregationSize || '',
                organizationYear: taxIdInfo.organizationYear || '',
                religiousAffiliation: taxIdInfo.religiousAffiliation || '',
            }}
            onSubmit={(
                { congregationSize, organizationYear, religiousAffiliation },
                form,
            ) => {
                onOrgInfoContinue(
                    Number(organizationYear),
                    religiousAffiliation,
                    congregationSize,
                );
            }}
            schema={schema}
        >
            <View />
        </GivelifyForm>
    );
};

export default OrganizationInfo
