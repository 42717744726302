import React from 'react';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';
import EnvelopeForm from './EnvelopeForm';

const EnvelopeEditor: React.FC = () => {
    const { editTarget } = useEnvelopesContext();
    if (!editTarget) return null;

    return <EnvelopeForm />;
};

export default EnvelopeEditor;
