import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="25"
        viewBox="0 0 24 25"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2100_7108)">
            <rect
                fill="#EBF9EF"
                height="40"
                rx="20"
                width="40"
                x="-8"
                y="-7.66504"
            />
            <path
                d="M15.9398 9.83038L10.796 14.9742L7.92588 12.1874"
                stroke="#00AC31"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
        </g>
        <defs>
            <clipPath id="clip0_2100_7108">
                <rect
                    fill="white"
                    height="24"
                    rx="12"
                    width="24"
                    y="0.334961"
                />
            </clipPath>
        </defs>
    </svg>
);

export const SuccessIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSuccessIcon}
        fontSize={fontSize}
    />
);
