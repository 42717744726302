import React, { useCallback, useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyFormDatePicker,
    GivelifyFormAutocomplete,
    GivelifyIcon,
    GivelifyRadio,
} from '@givelify/ui';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../consts';
import { useCaptivePortalContext } from '../../../CaptivePortalProvider';
import { OnboardingNote } from '../../components/OnboardingNote';
import { OnboardingActions } from '../../OnboardingActions';
import OnboardingPage from '../../OnboardingPage';
import { PrimaryRepInfoFormType, PrimaryRepInfoProps } from '../types';
import { PrimaryRepInfoHelp } from './PrimaryRepInfoHelp';

const StyledForm = styled('div')(({ theme }) => ({
    '& .page-description': {
        color: DesignTokens.color.textSecondary,
        marginBottom: '24px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '14px',
        },
    },
    '& .input-field': {
        marginTop: '16px',
    },

    '& .note-wrapper': {
        flexDirection: 'row',
        flexWrap: 'nowrap',

        '& .note': {
            fontSize: '14px',
            fontWeight: 600,
            color: DesignTokens.color.textSecondary,
        },
    },
    '& .hidden': {
        display: 'none',
    },
}));

const PrimaryRepInfoForm: React.FC<PrimaryRepInfoProps> = ({
    onCancelClick,
    data,
    titles,
    user,
    iAmPrimaryRep,
    onRadioChange,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const { inviteOfficialEnabled, navigateToUsers } =
        useCaptivePortalContext();

    const [isPrimaryRep, setIsPrimaryRep] = useState<boolean | undefined>(
        iAmPrimaryRep,
    );

    const {
        formState: { isValid, isDirty },
        setValue,
    } = useFormContext<PrimaryRepInfoFormType>();

    const { t } = useTranslation(I18N_NAMESPACE);

    const scopedTranslate = useCallback(
        (key: string, args = undefined) =>
            t(`primaryRepresentative.${key}`, args),
        [t],
    );

    const copy = useMemo(
        () => ({
            header: scopedTranslate('header'),
            subHeader: scopedTranslate('subHeader'),
            iAmPrimaryRep: scopedTranslate('iAmPrimaryRep'),
            iAmNotPrimaryRep: scopedTranslate('iAmNotPrimaryRep'),
            firstName: scopedTranslate('firstName'),
            lastName: scopedTranslate('lastName'),
            title: scopedTranslate('title'),
            dateOfBirth: scopedTranslate('dateOfBirth'),
            SSN: scopedTranslate('SSN'),
            note: scopedTranslate('note'),
            enterPrimaryRepInfo: scopedTranslate('enterPrimaryRepInfo'),
            dontHaveInfo: scopedTranslate('dontHaveInfo'),
            inviteWhoDoes: scopedTranslate('inviteWhoDoes'),
            weWillSendLink: scopedTranslate('weWillSendLink'),
        }),
        [scopedTranslate],
    );

    const handleChange = (
        isPrimaryRepValue: boolean,
        previousValue: boolean | undefined,
    ) => {
        setIsPrimaryRep(isPrimaryRepValue);
        onRadioChange?.(isPrimaryRepValue);

        if (isPrimaryRepValue) {
            setValue('firstName', user.firstName, { shouldValidate: true });
            setValue('lastName', user.lastName, { shouldValidate: true });
            setValue('title', user.title, { shouldValidate: true });
        } else {
            const shouldValidate = previousValue !== undefined;
            setValue('firstName', '', { shouldValidate });
            setValue('lastName', '', { shouldValidate });
            setValue('title', '', { shouldValidate });
        }
    };

    return (
        <StyledForm>
            <GivelifyLabel
                style={{
                    marginBottom: isMobile ? 8 : 12,
                }}
                text={copy.header}
                variant="heading1S"
            />
            <GivelifyLabel
                className="page-description"
                style={{
                    color: DesignTokens.color.textSecondary,
                }}
                text={copy.subHeader}
                variant="body1"
            />
            <Grid container gap={2}>
                <Grid item xs={12}>
                    <GivelifyRadio
                        checked={!!isPrimaryRep}
                        data-testid="iAmPrimaryRep"
                        label={copy.iAmPrimaryRep}
                        name="iAmPrimaryRep"
                        onChange={() => handleChange(true, isPrimaryRep)}
                        trackingName="I'm Prim Rep"
                        value="1"
                    />
                </Grid>
                <Grid item xs={12}>
                    <GivelifyRadio
                        checked={isPrimaryRep === false}
                        data-testid="iAmNotPrimaryRep"
                        label={copy.iAmNotPrimaryRep}
                        name="iAmPrimaryRep"
                        onChange={() => handleChange(false, isPrimaryRep)}
                        trackingName="I'm NOT Prim Rep"
                        value="0"
                    />
                </Grid>
                {iAmPrimaryRep === false && inviteOfficialEnabled && (
                    <OnboardingNote
                        heading={copy.enterPrimaryRepInfo}
                        linkAction={() => navigateToUsers(true)}
                        linkName='Invite Official'
                        linkText={copy.inviteWhoDoes}
                        secondaryText={copy.weWillSendLink}
                        text={copy.dontHaveInfo}
                    />
                )}
                <Grid
                    container
                    className={isPrimaryRep !== undefined ? '' : 'hidden'}
                    gap={2}
                    marginTop={2}
                >
                    <Grid
                        container
                        direction={isMobile ? 'column' : 'row'}
                        flexWrap="nowrap"
                        gap={2}
                    >
                        <Grid item lg={6} xs={12}>
                            <GivelifyFormTextField<PrimaryRepInfoFormType>
                                fullWidth
                                data-testid="pr-first-name"
                                label={copy.firstName}
                                name="firstName"
                                size="large"
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <GivelifyFormTextField<PrimaryRepInfoFormType>
                                fullWidth
                                data-testid="pr-last-name"
                                label={copy.lastName}
                                name="lastName"
                                size="large"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <GivelifyFormAutocomplete
                            allowCustomInput
                            fullWidth
                            data-testid="pr-title"
                            id="title"
                            label={copy.title}
                            name="title"
                            options={titles}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GivelifyFormDatePicker<PrimaryRepInfoFormType>
                            disableFuture
                            fullwidth
                            id="pr-datepicker"
                            label={copy.dateOfBirth}
                            name="birthDate"
                            size="large"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GivelifyFormTextField<PrimaryRepInfoFormType>
                            fullWidth
                            data-testid="pr-ssn"
                            label={copy.SSN}
                            maskOptions={{ mask: '000-00-0000' }}
                            name="socialSecurityNumber"
                            size="large"
                        />
                    </Grid>
                    <Grid container className="note-wrapper">
                        <Grid item>
                            <GivelifyIcon fontSize={28} variant="lock-check" />
                        </Grid>
                        <Grid item>
                            <GivelifyLabel className="note">
                                {copy.note}
                            </GivelifyLabel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <OnboardingActions
                disableContinue={!isValid}
                onBack={() => onCancelClick(!isDirty)}
                onCancel={() => onCancelClick(!isDirty)}
            />
        </StyledForm>
    );
};

const PrimaryRepInfoFormWrapper: React.FC<PrimaryRepInfoProps> = (props) => {
    return (
        <OnboardingPage
            content={<PrimaryRepInfoForm {...props} />}
            informationContent={<PrimaryRepInfoHelp />}
        />
    );
};

export default PrimaryRepInfoFormWrapper;
