import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        textAlign: 'center',
    },
    content: {
        paddingLeft: 32,
        paddingRight: 32,
    },
    text: {
        marginTop: 24,
        marginBottom: 32,
    },
    link: {
        color: theme.colors?.primary || theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:active': {
            color: theme.colors?.accentDarkBlue || theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
