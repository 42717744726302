import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifySwitch,
    GivelifyLabel,
    GivelifyLoading,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

import {
    getIntegrationSettings,
    updateIntegrationAutoExport,
} from '../../AutoIntegrationContent/components/automatedIntegrationRequest';
import { AutoIntegrationSettingData } from '../../AutoIntegrationContent/types';

const SettingsContainer = styled('div')(() => ({
    '& .description': {
        fontWeight: 700,
    },
}));

export const PCIntegrationSettings: React.FC = () => {
    const { t } = useTranslation();

    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));

    const text = useMemo(
        () => ({
            header: t('pages.integrations.settings.planningCenter.header'),
            label: t('pages.integrations.settings.planningCenter.label'),
        }),
        [t],
    );

    const [loading, setLoading] = useState<boolean>(false);

    const [getIntegrationSetting, requestIntegrationSetting] =
        useApiRequest<AutoIntegrationSettingData>();

    const [integrationSetting, setIntegrationSetting] =
        useState<AutoIntegrationSettingData>();

    useEffect(() => {
        requestIntegrationSetting(getIntegrationSettings(userDoneeId));
        setLoading(true);
    }, [doneeId, requestIntegrationSetting, userDoneeId]);

    const [checked, setChecked] = useState<boolean>(
        integrationSetting?.data.settings.donationsAutoexport,
    );
    useEffect(() => {
        if (getIntegrationSetting.type === 'REQUEST_SUCCESS') {
            setIntegrationSetting(getIntegrationSetting.response);
            setChecked(
                getIntegrationSetting.response.data.settings
                    .donationsAutoexport,
            );
            setLoading(false);
        }
    }, [getIntegrationSetting]);

    const [, makeRequestAutoExport] = useApiRequest();

    const changeAutoExport = (value: boolean) => {
        setChecked(value);
        makeRequestAutoExport(updateIntegrationAutoExport(userDoneeId, value));
    };

    return (
        <SettingsContainer>
            <GivelifyLabel bold text={text.header} variant="heading5" />
            <GivelifyLabel
                className="description"
                marginBottom={16}
                marginTop={8}
                text={text.label}
                variant="body1"
            />
            {loading && <GivelifyLoading type="circular" />}
            {!loading && (
                <GivelifySwitch
                    checked={checked}
                    onChange={changeAutoExport}
                    variant="thick-labeled"
                />
            )}
        </SettingsContainer>
    );
};
