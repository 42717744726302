import { Envelope } from 'api/models';
import { UpdateEnvelopeResponse } from 'api/requests';

export const ToEnvelope = (
    current: Envelope,
    updated: UpdateEnvelopeResponse,
): Envelope => ({
    ...current,
    active: updated.data.active,
    start: updated.data.startDate,
    end: updated.data.endDate,
    priority: updated.data.priority,
    alwaysOn: updated.data.alwaysOn,
    detail: updated.data.detail,
    externalId: updated.data.externalId,
    doneeId: updated.data.doneeId,
    goal: updated.data.fundGoal,
    name: updated.data.name,
    offeringId: updated.data.offeringId,
});

export const ToEnvelope2 = (env: UpdateEnvelopeResponse): Envelope => ({
    active: env.data.active,
    amount: 0,
    detail: env.data.detail,
    donationCount: 0,
    doneeId: env.data.doneeId,
    end: env.data.endDate,
    externalId: env.data.externalId,
    goal: env.data.fundGoal,
    id: env.data.id,
    isDefault: false,
    isDetailPublic: false,
    name: env.data.name,
    offeringId: env.data.offeringId,
    priority: env.data.priority,
    start: env.data.startDate,
    alwaysOn: env.data.alwaysOn,
});
