import {
    TimezoneManager,
    addProfile,
    getAxiosClient,
    makeApiRequest,
} from '@givelify/utils';
import { ThunkAction } from 'redux-thunk';
import { setDonee } from 'store/donee/actions';
import { loadDonee } from 'store/donee/thunks';
import { loadDoneeNotifications } from 'store/doneeNotification/thunks';
import { getScheduledReport } from 'store/integration/thunks';
import { loadNotifications } from 'store/notifications/thunks';
import { loadUser } from 'store/user/thunks';
import accessibleFeaturesByRole from 'utils/accessibleFeaturesByRole';
import { handleAppcues } from 'utils/appcues';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { webConfig } from 'webConfig';
import { AppActions, AppState } from '..';
import { getIntegration } from '../automatedIntegration/thunks';
import {
    setEnabledFeatures,
    setSnapGiveQRCodes,
    setOnlineGivingURLs,
} from './actions';
import {
    EnableFeature,
    SET_SNAP_GIVE_QR_CODES,
    SnapGiveDoneeResponse,
    SET_ONLINE_GIVING_URLS,
    OnlineGivingURLsInfo,
} from './types';

/* eslint-disable-next-line */

export const locationRetrieveError = '( Unable to Retrieve Location )';

const loadEnabledFeatures =
    (
        role: string,
    ): ThunkAction<Promise<EnableFeature[]>, AppState, undefined, AppActions> =>
    async (dispatch) => {
        const httpRequest = getAxiosClient().get(`/enabled-features`);

        const enabledFeaturesResponse = await makeApiRequest<{
            enabledFeatures: EnableFeature[];
        }>(httpRequest);
        if (!enabledFeaturesResponse.success) return null;

        const features = enabledFeaturesResponse.response.enabledFeatures;
        const roledFeatures = features?.map((feature) => ({
            ...feature,
            isEnabled:
                feature.isEnabled &&
                accessibleFeaturesByRole[role]?.includes(feature.label),
        }));

        dispatch(setEnabledFeatures(roledFeatures));
        return roledFeatures;
    };

const loadSnapGiveQRCodes = (doneeId: number) => async (dispatch) => {
    const httpRequest = getAxiosClient().get(`/donees/${doneeId}/snap-to-give`);

    const qrCodesResponse = await makeApiRequest<SnapGiveDoneeResponse>(
        httpRequest,
    );

    if (!qrCodesResponse.success) return null;

    if (qrCodesResponse.response.status === 'error')
        dispatch({ type: SET_SNAP_GIVE_QR_CODES, qrCodes: [] });
    else dispatch(setSnapGiveQRCodes(qrCodesResponse.response));

    return qrCodesResponse.response;
};

export const loadOnlineGivingURLs = (doneeId: number) => async (dispatch) => {
    const httpRequest = getAxiosClient().get(`/donees/${doneeId}/giving-urls`);

    const givingURLsResponse = await makeApiRequest<{
        onlineGivingURLs: OnlineGivingURLsInfo[];
    }>(httpRequest);

    if (!givingURLsResponse.success) {
        dispatch({ type: SET_ONLINE_GIVING_URLS, onlineGivingURLs: [] });

        // We won't prevent the app from loading if the Giving Url server is unavailable
        // An URL from the Donee model will be used instead.
        return true;
    }
    dispatch(setOnlineGivingURLs(givingURLsResponse.response));

    return givingURLsResponse.response;
};

/**
 * Call this method once when app loads
 */
export const loadData =
    (
        defaultDoneeId?: number,
    ): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const userResult = await dispatch(loadUser());
        if (!userResult) return false;

        const user = getState().User?.user;

        const doneeRequest = dispatch(loadDonee(false, defaultDoneeId));
        const snapGiveRequest = dispatch(loadSnapGiveQRCodes(user.doneeId));
        const onlineGivingURLsRequest = dispatch(
            loadOnlineGivingURLs(user.doneeId),
        );
        const reportRequest = dispatch(getScheduledReport(user.doneeId));
        const enabledFeaturesRequest = dispatch(loadEnabledFeatures(user.role));
        const integrationRequest = dispatch(getIntegration(user.doneeId));
        const notificationsRequest = dispatch(loadNotifications());
        const doneeNotificationRequest = dispatch(
            loadDoneeNotifications(defaultDoneeId || user.doneeId),
        );

        const [
            doneeResult,
            reportResult,
            integrationResult,
            enabledFeaturesResult,
            snapGiveResult,
            onlineGivingURLsResult,
            notificationsResult,
            doneeNotificationResult,
        ] = await Promise.all([
            doneeRequest,
            reportRequest,
            integrationRequest,
            enabledFeaturesRequest,
            snapGiveRequest,
            onlineGivingURLsRequest,
            notificationsRequest,
            doneeNotificationRequest,
        ]);

        const { donee } = getState().Donee;

        handleAppcues(user, donee);

        const { isSuper } = user;
        if (!isSuper) {
            addProfile(user, donee, webConfig.appVersion);
        }

        if (doneeResult !== null && enabledFeaturesResult !== null) {
            const timezonesEnabled = isFeatureEnabled(
                enabledFeaturesResult,
                Features.TIMEZONES,
                false,
            );
            const timezone = doneeResult?.timezone;
            if (timezonesEnabled && timezone !== null) {
                TimezoneManager.setDefault(timezone);
            }
        }

        return (
            doneeResult &&
            reportResult &&
            integrationResult &&
            enabledFeaturesResult &&
            !!snapGiveResult &&
            !!onlineGivingURLsResult &&
            notificationsResult &&
            doneeNotificationResult
        );
    };

export const changeCampus =
    (
        doneeId: number,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const donee = getState().Donee.campuses?.find((c) => c.id === doneeId);
        if (!donee) return;
        dispatch(setDonee(donee));

        await dispatch(loadDoneeNotifications(donee.id));
    };
