import { styled } from '@mui/material';

export const Box = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    maxWidth: 348,
    '& .title': {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    '& .descr': {
        padding: theme.spacing(0, 3),
        textAlign: 'left',
    },
    '& .footer': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(0, 4),
    },
}));
