import * as React from 'react';
import { useMemo } from 'react';
import { dataOrUndefined } from '@givelify/givelify-ui';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { DonorsTimeFrameData } from 'store/dashboard/donors/types';
import DefaultLoading from '../../components/DefaultLoading';
import Loading from '../components/Loading';
import View from './view';

const errorObject: DonorsTimeFrameData = {
    meta: {
        totalGiving: 1,
    },
    data: [],
};

const TotalDonorsDataWrapper: React.FC = () => {
    const { currentDonors, currentDonations } = useSelector(
        (state: AppState) => ({
            currentDonors: state.Dashboard.Donors.current,
            currentDonations: state.Dashboard.Donations.current,
        }),
    );

    const totalDonorsCount = useMemo(() => {
        const data = dataOrUndefined(currentDonations);
        return data ? data.data.donorsCount : 0;
    }, [currentDonations]);

    return (
        <DefaultLoading
            error={errorObject}
            loading={Loading}
            requestState={currentDonors}
        >
            {(res) => {
                const { data = [] } = res;
                const newDonors = data.find((distr) => distr.name === 'New');
                const newDonorsCount = newDonors ? newDonors.donorsCount : 0;

                return (
                    <View
                        newDonorsCount={newDonorsCount}
                        totalDonorsCount={totalDonorsCount}
                    />
                );
            }}
        </DefaultLoading>
    );
};

export default TotalDonorsDataWrapper;
