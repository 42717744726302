import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { GivelifyIcon } from '../icon';
import { DesignTokens } from '../specify';
import { GivelifyBreadcrumbsOption, GivelifyBreadcrumbsProps } from './types';

const BreadcrumbsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

const BreadcrumbLabel = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 600,

    '& .badge': {
        width: 24,
        height: 24,
        borderRadius: '50%',
        padding: '4px 8px',
        background: DesignTokens.color.iconPrimary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: DesignTokens.color.textWhite,
        fontSize: 12,
        fontWeight: 600,
        marginRight: 16,
    },

    '&.active': {
        color: DesignTokens.color.textAccentDefault,
        fontWeight: 800,

        '& .badge': {
            background: DesignTokens.color.textAccentDefault,
        },
    },

    '&.completed': {
        color: DesignTokens.color.textSecondaryAccent,
        fontWeight: 800,
        '& .badge': {
            background: DesignTokens.color.textSecondaryAccent,
        },
    },

    [theme.breakpoints.down('mobile')]: {
        fontSize: 14,
        fontWeight: 600,

        '& .badge': {
            marginRight: 8,
        },

        '&.active, &.completed': {
            fontWeight: 600,
        },
    },
}));

const Separator = styled(GivelifyIcon)(() => ({
    margin: '0 16px',
    fontSize: 24,
}));

export const GivelifyBreadcrumbs: React.FC<GivelifyBreadcrumbsProps> = ({
    disableNavigation,
    active,
    options,
    className,
}) => {
    const activeItem = useMemo(
        () => options.findIndex((option) => option.id === active),
        [options, active],
    );

    return (
        <BreadcrumbsContainer className={className}>
            {options.map((option: GivelifyBreadcrumbsOption, index: number) => (
                <React.Fragment key={option.id}>
                    <BreadcrumbLabel
                        className={
                            index === activeItem
                                ? 'active'
                                : index < activeItem || option.isCompleted
                                ? 'completed'
                                : ''
                        }
                        onClick={
                            disableNavigation ? () => null : option?.onClick
                        }
                    >
                        <div className="badge">{index + 1}</div>
                        <div>{option.label}</div>
                    </BreadcrumbLabel>
                    {index < options.length - 1 && (
                        <Separator variant="right-chevron" />
                    )}
                </React.Fragment>
            ))}
        </BreadcrumbsContainer>
    );
};
