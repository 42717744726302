import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const StyledForm = styled('div')(({ theme }) => ({
    '& .page-header': {
        [theme.breakpoints.down('mobile')]: {
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
    '& .page-description': {
        color: DesignTokens.color.textSecondary,
        marginBottom: '24px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '14px',
        },
    },
}));

export const SummaryWrapperStyled = styled('div')(() => ({
    display: 'grid',
    columnGap: 32,
    borderRadius: 16,
    backgroundColor: DesignTokens.color.globalNeutral100,
    padding: '20px 24px',
    alignItems: 'center',
}));

export const SummaryWrapper = styled(SummaryWrapperStyled, {
    shouldForwardProp: (propName) => propName !== 'gridTemplateColumns',
})<{ gridTemplateColumns?: string }>(({ theme, gridTemplateColumns }) => ({
    gridTemplateColumns: gridTemplateColumns
        ? gridTemplateColumns
        : '3fr 4fr auto',
    [theme.breakpoints.down('mobile')]: {
        gridTemplateColumns: '1fr auto',
    },
    '& .expand-text': {
        wordBreak: 'break-word',
    },
}));

export const SummaryHeaderLabel = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        gridColumn: 'span 2',
    },
    '& .expand-text': {
        letterSpacing: '2px',
    },
}));

export const Divider = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${DesignTokens.color.dividerPrimaryLight}`,
    gridColumn: 'span 3',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('mobile')]: {
        gridColumn: 'span 2',
        marginTop: 12,
        marginBottom: 12,
    },

    '&.full-width': {
        [theme.breakpoints.down('mobile')]: {
            gridColumn: 'span 3',
        },
    },
}));
