import React from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    GivelifyModal,
    GivelifyModalProps,
} from '@givelify/ui';
import { SUPPORT_WHY_PRIMARY_REP_URL } from 'api/constants';
import { useTranslation } from 'react-i18next';
import { primaryRepEditorViews } from '../types';
import { Box } from './styles';

export const LearnMoreDialog: React.FC<GivelifyModalProps> = (props) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.title',
            ),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.description',
            ),
            learnMore: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.learnMoreLong',
            ),
            ok: t(
                'pages.settings.bank-info.customize-rep-tab.editor.info.gotIt',
            ),
        }),
        [t],
    );
    const onLearnMorClick = React.useCallback((event) => {
        event.preventDefault();
        window.open(SUPPORT_WHY_PRIMARY_REP_URL, '_blank');
    }, []);
    return (
        <GivelifyModal
            {...props}
            showCloseButton
            name={primaryRepEditorViews.learnMore}
        >
            <Box>
                <GivelifyLabel className="title" variant="heading2S">
                    {copy.title}
                </GivelifyLabel>
                <div className="descr">
                    <GivelifyLabel variant="body2">
                        {copy.description}
                    </GivelifyLabel>
                    <GivelifyLink
                        href={SUPPORT_WHY_PRIMARY_REP_URL}
                        name="Learn more"
                        onClick={onLearnMorClick}
                        text={copy.learnMore}
                        variant="body2B"
                    />
                </div>
                <div className="footer">
                    <GivelifyButton
                        name="Got It"
                        onClick={() => props.onClose({}, 'closeButtonClick')}
                        text={copy.ok}
                        variant="primary"
                    />
                </div>
            </Box>
        </GivelifyModal>
    );
};
