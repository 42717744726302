import React from 'react';
import { PopoverProps, Popover, styled } from '@mui/material';
import { DesignTokens } from '../specify';

export type GivelifyMenuProps = PopoverProps & {
    onRenderContent: () => React.ReactNode;
    maxHeight?: 'default' | 'auto';
};

const StyledMenu = styled(Popover, {
    shouldForwardProp: prop => prop !== 'maxHeight',
})<{ maxHeight?: 'default' | 'auto' }>(({ maxHeight, theme }) => ({
    boxShadow: DesignTokens.shadow.shadowHigh,
    marginTop: theme.spacing(0.5),
    '& .MuiPopover-paper': {
        borderRadius: theme.spacing(1),
        width: '326px',
        maxHeight: maxHeight === 'auto' ? 'initial' : '416px',
        padding: theme.spacing(2, 0),
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
    },
}));

export const GivelifyMenu: React.FC<GivelifyMenuProps> = ({
    onRenderContent,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
    maxHeight,
    ...props
}) => {
    return (
        <StyledMenu
            {...props}
            anchorOrigin={anchorOrigin}
            maxHeight={maxHeight}
            transformOrigin={transformOrigin}
        >
            {onRenderContent()}
        </StyledMenu>
    );
};
