import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { Slice } from './Slice';
import { DigitRollDivider, DigitRoll, Currency } from './styles';
import { formatDigit } from './utils';

export interface DigitRollProps {
    num: number | string;
    /**
     * height of each digit (rem)
     */
    height: number;
    /**
     * width of each digit (rem)
     */
    width: number;
    /**
     * delay to show number, in seconds
     */
    delay: number;
    divider?: string;
    className?: string;
}

const DigitRollComponent: React.FC<DigitRollProps> = ({
    num,
    height,
    width,
    divider = ',',
    delay,
    className,
}) => {
    const amountNumber = num.toString().split('.');
    const actualLength = amountNumber[0].toString().length;

    const numArr = formatDigit(
        parseInt(amountNumber[0]),
        actualLength,
        divider,
    );

    let oddNumArr;

    if (amountNumber[1] && amountNumber[1].toString().length === 1) {
        oddNumArr = formatDigit(parseInt(amountNumber[1] + '0'), 2, divider);
    } else if (amountNumber[1] && amountNumber[1].toString().length === 2) {
        oddNumArr = formatDigit(parseInt(amountNumber[1]), 2, divider);
    } else if (amountNumber[1] && amountNumber[1].toString().length > 2) {
        oddNumArr = formatDigit(
            parseInt(amountNumber[1].substring(0, 2)),
            2,
            divider,
        );
    } else {
        oddNumArr = formatDigit(parseInt('00'), 2, divider);
    }

    return (
        <DigitRoll>
            <div
                className={`digit-roll-wrapper ${className}`}
                style={{ height: height + 'rem' }}
            >
                {oddNumArr.map((d, index) => {
                    return (
                        <Slice
                            key={index}
                            className={`digit-number-${
                                index % 2 === 0 ? 'next' : 'prev'
                            }`}
                            delay={delay}
                            digit={parseInt(d, 10)}
                            height={height}
                            id={`digit-odd-number-${index}`}
                            width={width}
                        />
                    );
                })}
                <DigitRollDivider
                    className="digit-roll-divider"
                    data-testid="active-digit"
                    height={height}
                >
                    .
                </DigitRollDivider>
                {numArr.map((d, index) => {
                    if (index % 4 === 3) {
                        return (
                            <DigitRollDivider
                                key={index}
                                className="digit-roll-divider"
                                data-testid="active-digit"
                                height={height}
                            >
                                {divider}
                            </DigitRollDivider>
                        );
                    }
                    return (
                        <Slice
                            key={index}
                            className={mergeClassNames(
                                `digit-number-${
                                    index % 4 === 0
                                        ? 'next'
                                        : index % 4 === 2
                                        ? 'prev'
                                        : 'middle'
                                }`,
                                index === numArr.length - 1 ? 'last' : '',
                            )}
                            delay={delay}
                            digit={parseInt(d, 10)}
                            height={height}
                            id={`digit-number-${index}`}
                            width={width}
                        />
                    );
                })}
                <Currency
                    className="digit-currency"
                    height={height}
                    width={width}
                >
                    $
                </Currency>
            </div>
        </DigitRoll>
    );
};

export const GivelifyDigitRoll = React.memo(DigitRollComponent);
