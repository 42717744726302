import React, { useMemo } from 'react';
import {
    getStateName,
    GivelifyLabel,
    phoneFormatter,
    GivelifyMobilePreviewBase,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import backArrow from '../assets/mockups/back-icon.svg';
import buttonFavourite from '../assets/mockups/button-favourite.svg';
import locationIcon from '../assets/mockups/location-icon.svg';
import mapNormal from '../assets/mockups/map-normal.png';
import previewAvatar from '../assets/mockups/mobile-preview-avatar.svg';
import mpcNormal from '../assets/mockups/mobile-preview-cover-normal.svg';
import phoneIcon from '../assets/mockups/phone-icon.svg';
import shareIcon from '../assets/mockups/share-icon.svg';
import verifiedIcon from '../assets/mockups/verified-icon.svg';
import { I18N_NAMESPACE } from '../consts';
import { ProfileMobilePreviewStyle } from './profileMobilePreviewStyle';

export interface ProfileMobilePreviewProps {
    isNonprofit?: boolean;
    organizationName?: string;
    profileStatement?: string;
    fullAddress?: {
        street?: string;
        city?: string;
        state?: string;
        zip?: string;
        phone?: string;
    };
    coverUrl?: string;
    representativeName?: string;
    representativeAvatar?: string;
    size?: 'large' | 'small';
    hideTitle?: boolean;
    isVerified?: boolean;
    title?: string;
    titleContentGap?: number;
}

export const ProfileMobilePreview: React.FC<ProfileMobilePreviewProps> =
    React.memo((props) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const copy = useMemo(
            () => ({
                yourOrganization: t('mobile-preview.your-organization'),
                organizationsRepresentative: t(
                    'mobile-preview.organizations-representative',
                ),
                taxDeductibleOrganization: t(
                    'mobile-preview.tax-deductible-organization',
                ),
                give: t('mobile-preview.give'),
                profilePreview: t('customizeProfile.profilePreview'),
            }),
            [t],
        );
        const {
            mobilePrevieContainer,
            mobilePreviewCoverSection,
            mobilePreviewCover,
            mobilePreviewDetailsSection,
            mobilePreviewBackArrow,
            mobilePreviewShare,
            mobilePreviewOrgSec,
            mobilePreviewOrgBadge,
            mobilePreviewTaxTip,
            mobilePreviewButton,
            mobilePreviewPhoneSec,
            mobilePreviewLocationSec,
            mobilePreviewAvatar,
            mobilePreviewFav,
            mobileMap,
        } = ProfileMobilePreviewStyle();
        const {
            coverUrl,
            fullAddress,
            title,
            hideTitle,
            size = 'small',
            isNonprofit,
            representativeName,
            representativeAvatar,
            profileStatement,
            organizationName,
            isVerified,
            titleContentGap = 25,
        } = props;
        return (
            <div className={mobilePrevieContainer}>
                {hideTitle ? null : (
                    <GivelifyLabel
                        color="neutralGrey"
                        text={title ? title : copy.profilePreview}
                        variant="body3"
                    />
                )}
                <GivelifyMobilePreviewBase
                    marginTop={hideTitle ? undefined : -80.75 + titleContentGap}
                    scale={size === 'large' ? undefined : 0.75}
                >
                    <div className={mobilePreviewCoverSection}>
                        <img
                            alt="organization cover"
                            className={mobilePreviewCover}
                            onError={(event) => {
                                event.currentTarget.src = mpcNormal;
                            }}
                            src={coverUrl || mpcNormal}
                            title="Organization cover picture"
                        />
                        <div className={mobilePreviewBackArrow}>
                            <img
                                alt="back"
                                src={backArrow}
                                title="Back arrow"
                            />
                        </div>
                        <div className={mobilePreviewShare}>
                            <img
                                alt="share"
                                src={shareIcon}
                                title="Share icon"
                            />
                        </div>
                    </div>
                    <div className={mobilePreviewDetailsSection}>
                        <div className={mobilePreviewOrgSec}>
                            <GivelifyLabel
                                text={organizationName || copy.yourOrganization}
                                variant="heading3"
                            />
                            {isVerified ? (
                                <img
                                    alt="verified"
                                    className={mobilePreviewOrgBadge}
                                    src={verifiedIcon}
                                    title="Verified badge"
                                />
                            ) : null}
                        </div>
                        {!isNonprofit && (
                            <GivelifyLabel
                                color="grey600"
                                marginTop={4}
                                text={
                                    representativeName ||
                                    copy.organizationsRepresentative
                                }
                                variant="heading5"
                            />
                        )}
                        <div className={mobilePreviewTaxTip}>
                            {copy.taxDeductibleOrganization}
                        </div>
                        {profileStatement ? (
                            <div className="profileStatement">
                                {profileStatement}
                            </div>
                        ) : null}
                        <div className={mobilePreviewButton}>{copy.give}</div>
                        <div className={mobilePreviewPhoneSec}>
                            <img
                                alt="phone"
                                src={phoneIcon}
                                title="Phone icon"
                            />
                            <div className="phoneValue">
                                {fullAddress && fullAddress.phone
                                    ? phoneFormatter(fullAddress.phone) ||
                                      '(678) 687-4115'
                                    : '(678) 687-4115'}
                            </div>
                        </div>
                        <div className={mobilePreviewLocationSec}>
                            <img
                                alt="location"
                                src={locationIcon}
                                title="Location icon"
                            />
                            <div className="phoneValue">
                                {`${
                                    fullAddress?.street || '620 Hembree Pkwy'
                                }, ${fullAddress?.city || 'Roswell'}, ${
                                    getStateName(fullAddress?.state) ||
                                    'Georgia'
                                } ${fullAddress?.zip || '30076'}`}
                            </div>
                        </div>
                        {!isNonprofit && (
                            <img
                                alt="avatar"
                                className={mobilePreviewAvatar}
                                height={72}
                                onError={(event) => {
                                    event.currentTarget.src = previewAvatar;
                                }}
                                src={representativeAvatar || previewAvatar}
                                title="Avatar picture"
                                width={72}
                            />
                        )}
                        <img
                            alt="fav"
                            className={mobilePreviewFav}
                            src={buttonFavourite}
                            title="fav picture"
                        />
                    </div>
                    <img
                        alt="map"
                        className={mobileMap}
                        src={mapNormal}
                        title="map preview"
                    />
                </GivelifyMobilePreviewBase>
            </div>
        );
    });

ProfileMobilePreview.displayName = 'ProfileMobilePreview';
