import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import Yodlee from '../../yodlee/Yodlee';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        directDepositStyles: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'visible',
            '& .manual-entry-link': {
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(1),
                '& .lock': {
                    color: theme.colors?.neutralGrey,
                },
            },
            '& .note': {
                maxWidth: 600,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
                textAlign: 'center',
            },
            '& .title': {
                textAlign: 'center',
            },
        },
    }),
);

interface DirectDepositMainProps {
    doneeId: number;
    openManualEntry: () => unknown;
    onSubmit: (account: number, routing: number) => unknown;
    className?: string;
}

const lockStyle = {
    endIcon: 'lock',
};

const DirectDepositMain: React.FC<DirectDepositMainProps> = ({
    doneeId,
    openManualEntry,
    onSubmit,
    className,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const { directDepositStyles } = useStyles();

    const {
        linkBankAccountHeading,
        linkBankManuallyText,
        timeNoticeCaption,
        bankLinkDescription,
    } = useMemo(
        () => ({
            linkBankAccountHeading: t('directDeposit.linkBankAccountHeading'),
            searchBankText: t('directDeposit.searchBankText'),
            linkBankManuallyText: t('directDeposit.linkBankManuallyText'),
            timeNoticeCaption: t('directDeposit.timeNoticeCaption'),
            bankLinkDescription: t('directDeposit.bankLinkDescription'),
        }),
        [t],
    );

    const onRefresh = React.useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div className={mergeClassNames(directDepositStyles, className)}>
            <GivelifyLabel
                bold
                className="title"
                text={linkBankAccountHeading}
                variant="heading2"
            />
            <Yodlee
                doneeId={doneeId}
                onManualEntry={openManualEntry}
                onRefresh={onRefresh}
                onSubmit={onSubmit}
            />
            <GivelifyButton
                bold
                className="manual-entry-link"
                classes={lockStyle}
                endIconVariant="lock"
                onClick={openManualEntry}
                text={linkBankManuallyText}
                variant="link"
            />
            <GivelifyLabel
                color="neutralGrey"
                text={timeNoticeCaption}
                variant="caption2"
            />
            <GivelifyLabel
                className="note"
                color="neutralGrey"
                text={bankLinkDescription}
                variant="body2"
            />
        </div>
    );
};

export default DirectDepositMain;
