import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState, AppActions } from '..';
import { noDoneeIdError } from '../report/thunks';
import { httpPost, httpPatch, httpDelete } from '../thunks';
import { setScheduledReport, mapScheduledReportToRequest } from './actions';
import { initialIntegrationState, initialScheduledReport } from './reducer';
import { ScheduledReport } from './types';

export const noScheduleReportIdError = new Error(
    'Schedule Report Id not found',
);

/**
 * Dispatcher to get schedule Report
 */
export const getScheduledReport = (
    doneeId: number,
): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> => {
    return async (dispatch) => {
        const httpRequest = getAxiosClient().get(
            `/donees/${doneeId}/scheduled-reports`,
        );

        const response = await makeApiRequest<ScheduledReport[]>(httpRequest);
        if (!response.success) return false;

        dispatch(
            setScheduledReport(response.response[0] ?? initialScheduledReport),
        );

        return true;
    };
};

/**
 * Dispatcher to create schedule Report
 */
export const newScheduledReport = (
    data: ScheduledReport,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return async (dispatch) => {
        const requestData = mapScheduledReportToRequest(data);
        const { data: resData } = await httpPost(
            `donees/${data.doneeId}/scheduled-reports`,
            requestData,
            {
                axios: {
                    headers: {
                        'Enable-Feature-Toggles': 'scheduled-report-cadence',
                    },
                    withCredentials: true,
                },
            },
        )(dispatch);
        dispatch(setScheduledReport(resData));
    };
};

/**
 * Dispatcher to edit schedule Report
 */
export const editScheduledReport = (
    id: number,
    data: ScheduledReport,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return async (dispatch) => {
        if (!id) throw noScheduleReportIdError;

        const requestData = mapScheduledReportToRequest(data);
        const { data: resData } = await httpPatch(
            `donees/${data.doneeId}/scheduled-reports/${id}`,
            requestData,
        )(dispatch);
        dispatch(setScheduledReport(resData));
    };
};

/**
 * Dispatcher to delete schedule Report
 */
export const deleteScheduledReport = (
    id: number,
    doneeId: number,
): ThunkDispatch<AppState, undefined, AppActions> => {
    return async (dispatch) => {
        if (!id) throw noScheduleReportIdError;
        if (!doneeId) throw noDoneeIdError;
        await httpDelete(`donees/${doneeId}/scheduled-reports/${id}`)(dispatch);
        dispatch(setScheduledReport(initialIntegrationState.scheduledReport));
    };
};
