import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgPrimaryRepColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="72"
        width="72"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 72 72"
    >
        <ellipse cx="36.5" cy="27" fill="#BEB3FF" rx="26.5" ry="27" />
        <path
            d="M2.01412 70.9998C2.26273 62.2231 5.77808 54.6527 11.3764 48.7566C12.0731 48.0228 13.2161 47.9824 13.9988 48.6237L33.44 64.5514C34.9231 65.8021 37.0769 65.8021 38.56 64.5514L58.0012 48.6237C58.7839 47.9824 59.9269 48.0228 60.6236 48.7566C66.2219 54.6527 69.7373 62.2231 69.9859 70.9998C70.0015 71.5518 69.5523 72 69 72H3C2.44772 72 1.99849 71.5518 2.01412 70.9998Z"
            fill="#715AFF"
        />
    </svg>
);

export const PrimaryRepColoredIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgPrimaryRepColored}
        fontSize={fontSize}
    />
);
