import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const requiredCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        requiredCard: {
            width: 336,
            height: 336,
            backgroundColor: theme.colors
                ? theme.colors.primaryWhite
                : '#FFFFFF',
            boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                width: '98%',
            },
            '& .rc-title': {
                height: 80,
                borderBottomColor: '#D8D8D8',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& .rc-title-pending': {
                backgroundColor: theme.colors
                    ? theme.colors.neutralHoverGrey
                    : '#F6F6F6',
            },
            '& .rc-content': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 26,
                paddingBottom: 29,
                paddingLeft: 16,
                paddingRight: 16,
                textAlign: 'center',
                boxSizing: 'border-box',
                height: 256,
            },
            '& .rc-hidden': {
                // visibility: 'hidden',
                display: 'none',
            },
            '& .rc-pending-badge': {
                width: 40,
                height: 34,
                marginTop: 25,
                marginBottom: 43,
            },
            '& .rc-warning-badge': {
                width: 40,
                height: 40,
                marginTop: 22,
                marginBottom: 40,
            },
            '& .rc-retry-badge': {
                width: 40,
                height: 40,
                marginTop: 22,
                marginBottom: 24,
            },
            '& .rc-complete-badge': {
                width: 40,
                height: 40,
                marginTop: 22,
                marginBottom: 40,
            },
            '& .rc-caption': {
                marginBottom: 16,
            },
            '& .rc-caption-retry': {
                marginBottom: 8,
            },
        },
    }),
);
