import React, { useMemo } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import loginIcon from 'assets/login.svg';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export default function DescriptionCard() {
    const theme = useTheme();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            topMessage: t(
                'overview.confirmContactInfoModal.descCard.topMessage',
            ),
            middleMessage: t(
                'overview.confirmContactInfoModal.descCard.middleMessage',
            ),
            bottomMessage: t(
                'overview.confirmContactInfoModal.descCard.bottomMessage',
            ),
            viewDetail: t('bankDeposits.text.viewDetail'),
            closeDetail: t('bankDeposits.text.closeDetail'),
        }),
        [t],
    );
    const styles = useStyles();

    const [showDetail, setShowDetail] = React.useState<boolean>(true);
    const handleShowDetail = () => {
        setShowDetail(!showDetail);
    };

    const showToggleBtn = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        setShowDetail(!showToggleBtn);
    }, [showToggleBtn]);

    return (
        <Box className={styles.descriptionCard}>
            <Box className={styles.secureIcon}>
                <img alt="login" sizes={'64px'} src={loginIcon} />
            </Box>
            <GivelifyLabel
                className={styles.marginTop45}
                color="#000"
                fontSize={16}
                fontWeight={700}
                lineHeight={20}
                text={copy.topMessage}
            />

            {showToggleBtn && (
                <GivelifyButton
                    className={styles.btnDetail}
                    endIconVariant={showDetail ? 'chevron-up' : 'chevron-down'}
                    onClick={handleShowDetail}
                    text={showDetail ? copy.closeDetail : copy.viewDetail}
                    variant="link"
                />
            )}

            {showDetail && (
                <>
                    <GivelifyLabel
                        className={styles.marginTop15}
                        color="#000"
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={22}
                        text={copy.middleMessage}
                    />
                    <GivelifyLabel
                        className={styles.marginTop15}
                        color="#000"
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={22}
                        text={copy.bottomMessage}
                    />
                </>
            )}
        </Box>
    );
}
