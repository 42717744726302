import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import successImg from 'assets/success.svg';
import { matchRowStyle } from '../../../../components/style';

interface MatchSuccessfulProps {
    success: boolean;
    successText: string;
}

const MatchSuccessful: React.FC<MatchSuccessfulProps> = props => {
    const classes = matchRowStyle();

    return (
        <div className={classes.matchingSuccess}>
            <img
                alt="Successful"
                className={classes.successImg}
                src={successImg}
            />
            <GivelifyLabel text={props.successText} variant="body2" />
        </div>
    );
};

export default MatchSuccessful;
