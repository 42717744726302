import { Snackbar, styled } from '@mui/material';
import { DesignTokens } from '../specify';

export const StyledSnackbar = styled(Snackbar, {
    shouldForwardProp: (propName) =>
        propName !== 'variant' && propName !== 'global',
})<{
    variant: 'info' | 'warning' | 'success' | 'error';
    global?: boolean;
}>(({ variant, global, theme }) => ({
    position: global ? 'fixed' : 'relative',
    width: global ? 'initial' : '100%',
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    borderWidth: '2px',
    borderStyle: 'solid',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
    },
    ...(!global && {
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
    }),
    ...(!global && {
        '@media (min-width: 600px)': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
    }),
    '& .MuiPaper-root': {
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: 0,
        margin: 0,
        flexWrap: 'nowrap',
        [theme.breakpoints.down('smallTablet')]: {
            flexWrap: 'wrap',
        },
    },
    '& .MuiSnackbarContent-message': {
        padding: 0,
    },
    '& .MuiSnackbarContent-action': {
        marginRight: 0,
    },
    '&.inline': {
        position: 'relative',
    },
    ...(variant === 'info' && {
        borderColor: DesignTokens.color.borderComponentInfo,
        backgroundColor: DesignTokens.color.globalInfo100,
    }),
    ...(variant === 'warning' && {
        borderColor: DesignTokens.color.borderComponentWarning,
        backgroundColor: DesignTokens.color.globalWarning100,
    }),
    ...(variant === 'error' && {
        borderColor: DesignTokens.color.borderComponentError,
        backgroundColor: DesignTokens.color.globalError100,
    }),
    ...(variant === 'success' && {
        borderColor: DesignTokens.color.borderComponentSuccess,
        backgroundColor: DesignTokens.color.globalSuccess100,
    }),
}));

export const StyledAction = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
    '& .ntf-act': {
        padding: theme.spacing(1, 3),
        lineHeight: `${DesignTokens.textStyle.globalBody1B.font.lineHeight}px`,
        fontSize: DesignTokens.textStyle.globalBody1B.font.size,
        fontWeight: DesignTokens.textStyle.globalBody1B.font.weight,
    },
    '& .ntf-act-sec-success': {
        color: DesignTokens.color.textSuccessDefault,
        borderColor: DesignTokens.color.textSuccessDefault,
        backgroundColor: DesignTokens.color.backgroundSecondary,
        '&:hover': {
            color: DesignTokens.color.globalSuccess800,
            borderColor: DesignTokens.color.textSuccessDefault,
            backgroundColor: DesignTokens.color.backgroundSecondary,
        },
    },
    '& .ntf-act-sec-error': {
        color: DesignTokens.color.textErrorDefault,
        borderColor: DesignTokens.color.textErrorDefault,
        backgroundColor: DesignTokens.color.backgroundSecondary,
        '&:hover': {
            color: DesignTokens.color.globalError800,
            borderColor: DesignTokens.color.textErrorDefault,
            backgroundColor: DesignTokens.color.backgroundSecondary,
        },
    },
    '& .ntf-act-sec-info': {
        color: DesignTokens.color.textInfoDefault,
        borderColor: DesignTokens.color.textInfoDefault,
        backgroundColor: DesignTokens.color.backgroundSecondary,
        '&:hover': {
            color: DesignTokens.color.globalInfo800,
            borderColor: DesignTokens.color.textInfoDefault,
            backgroundColor: DesignTokens.color.backgroundSecondary,
        },
    },
    '& .ntf-act-sec-warning': {
        color: DesignTokens.color.textWarningDefault,
        borderColor: DesignTokens.color.textWarningDefault,
        backgroundColor: DesignTokens.color.backgroundSecondary,
        '&:hover': {
            color: DesignTokens.color.globalWarning800,
            borderColor: DesignTokens.color.textWarningDefault,
            backgroundColor: DesignTokens.color.backgroundSecondary,
        },
    },
    '& .ntf-act-prm-success': {
        color: DesignTokens.color.textWhite,
        borderColor: DesignTokens.color.globalSuccess400,
        backgroundColor: DesignTokens.color.globalSuccess400,
        '&:hover': {
            color: DesignTokens.color.textWhite,
            borderColor: DesignTokens.color.globalSuccess600,
            backgroundColor: DesignTokens.color.globalSuccess600,
        },
    },
    '& .ntf-act-prm-error': {
        color: DesignTokens.color.textWhite,
        borderColor: DesignTokens.color.globalError600,
        backgroundColor: DesignTokens.color.globalError600,
        '&:hover': {
            color: DesignTokens.color.textWhite,
            borderColor: DesignTokens.color.globalError800,
            backgroundColor: DesignTokens.color.globalError800,
        },
    },
    '& .ntf-act-prm-info': {
        color: DesignTokens.color.textWhite,
        borderColor: DesignTokens.color.globalInfo600,
        backgroundColor: DesignTokens.color.globalInfo600,
        '&:hover': {
            color: DesignTokens.color.textWhite,
            borderColor: DesignTokens.color.globalInfo800,
            backgroundColor: DesignTokens.color.globalInfo800,
        },
    },
    '& .ntf-act-prm-warning': {
        color: DesignTokens.color.textWhite,
        borderColor: DesignTokens.color.globalWarning600,
        backgroundColor: DesignTokens.color.globalWarning600,
        '&:hover': {
            color: DesignTokens.color.textWhite,
            borderColor: DesignTokens.color.globalWarning800,
            backgroundColor: DesignTokens.color.globalWarning800,
        },
    },
    '& .ntf-act-dismiss': {
        color: DesignTokens.color.textSecondary,
        padding: 0,
        borderRadius: 0,
        lineHeight: '20px',
        fontSize: '14px',
        fontWeight: 800,
        '&:hover': {
            color: DesignTokens.color.textSecondary,
        },
    },
    [theme.breakpoints.down('smallTablet')]: {
        flexDirection: 'row-reverse',
    },
}));

export const StyledMessage = styled('div', {
    shouldForwardProp: (propName) => propName !== 'noActions',
})<{ noActions?: boolean }>(({ noActions, theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    '& .ntf-title': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: theme.spacing(1.5),
    },
    '& .ntf-icon': {
        fontSize: '24px',
        lineHeight: '24px',
        color: DesignTokens.color.textInfoDefault,
    },
    '& .ntf-content': {
        marginLeft: theme.spacing(4.5),
    },
    [theme.breakpoints.down('smallTablet')]: {
        maxWidth: noActions ? 'initial' : 574,
        marginBottom: noActions ? 0 : theme.spacing(2),
    },
}));
