import axios, { AxiosHeaders } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { getAccessTokenLocalStorage } from '../utils/accessTokenInLocalStorage';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

if (!apiUrl) {
    throw new Error(
        `REACT_APP_API_BASE_URL must be existing in the environment`,
    );
}

export const axiosClient = applyCaseMiddleware(
    axios.create({
        baseURL: new URL('api', apiUrl).toString(),
        withCredentials: true,
    }),
    {
        preservedKeys: ['_method'],
    },
);

axiosClient.interceptors.request.use((req) => {
    const token = getAccessTokenLocalStorage();
    if (token) {
        req.headers = req.headers ?? new AxiosHeaders();
        req.headers.Authorization = token;
    }
    return req;
});
