import React from 'react';
import { TrackingProvider } from '@givelify/utils';
import { NotificationWrapper } from 'components';
import { HeaderWrapper } from 'layout/components/header';
import {
    MobileNavigationMenuProps,
    NavigationMenu,
} from 'layout/components/navMenu';
import { Context } from 'layout/MainLayoutContext';
import {
    CaptivePortalLayout,
    ContentRoot,
    Layout,
    MainContent,
    MessageContent,
} from './styles';

export type LayoutViewProps = MobileNavigationMenuProps & {
    legacyPage?: boolean;
    showCaptivePortal?: boolean;
};

export const LayoutView: React.FC<LayoutViewProps> = ({
    showCaptivePortal,
    legacyPage,
    children,
    ...props
}) => {
    const topSectionDivRef = React.useRef();
    const mainHolderDivRef = React.useRef();
    return (
        <TrackingProvider
            hideInChild
            pageName="Navigation"
            trackPageVisit={false}
        >
            <NotificationWrapper />
            <Layout id="layout">
                <HeaderWrapper hideTotalDonations={showCaptivePortal} />
                {showCaptivePortal ? (
                    <CaptivePortalLayout>
                        <div>{children}</div>
                    </CaptivePortalLayout>
                ) : (
                    <>
                        <NavigationMenu
                            navigationProps={props.navigationProps}
                            onMoreToggle={props.onMoreToggle}
                            openMoreMenu={props.openMoreMenu}
                        />
                        <ContentRoot
                            ref={mainHolderDivRef}
                            extraMargin={
                                props.navigationProps.notificationHeight
                            }
                            id="content-root"
                        >
                            <Context.Provider
                                value={{
                                    mainHolderDivRef,
                                    topSectionDivRef,
                                }}
                            >
                                <MessageContent
                                    ref={topSectionDivRef}
                                    id="message-content"
                                />
                                <MainContent
                                    id="main-content"
                                    isLegacyPage={legacyPage}
                                >
                                    {children}
                                </MainContent>
                            </Context.Provider>
                        </ContentRoot>
                    </>
                )}
            </Layout>
        </TrackingProvider>
    );
};
