import { createContext, useContext } from 'react';

interface TrackingConfigProps {
    getPayload: () => any;
}

const TrackingConfigContext = createContext<TrackingConfigProps>({
    getPayload: () => {
        return {};
    },
});

export const useTrackingConfigContext = () => useContext(TrackingConfigContext);

export const TrackingConfigProvider: React.FC<TrackingConfigProps> = ({
    getPayload,
    children,
}) => {
    return (
        <TrackingConfigContext.Provider
            value={{
                getPayload,
            }}
        >
            {children}
        </TrackingConfigContext.Provider>
    );
};
