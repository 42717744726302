import { Theme, makeStyles } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    gridStyles: {
        height: '100%',
        marginBottom: theme.spacing(4),
        '& .check-image, & .error-image': {
            textAlign: 'center',
            marginBottom: 40,
        },
        '& .check-image': {
            marginLeft: 'auto',
            marginRight: 40,
        },
        '& .error-image': {
            marginLeft: 40,
            marginRight: 'auto',
        },
        '& .border-right': {
            borderRight: '1px solid #E3E3E3',
        },
        '& .border-bottom': {
            borderBottom: '1px dashed #E3E3E3',
        },
        '& .box-container': {
            display: 'flex',
            height: 136,
        },
        '& .left-box, & .right-box, & .check-image, & .error-image': {
            maxWidth: 236,
        },
        '& .left-box': {
            marginLeft: 'auto',
            marginRight: 40,
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
        '& .right-box': {
            marginRight: 'auto',
            marginLeft: 40,
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.down('sm')]: {
            '& .check-image, & .error-image': {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            '& .left-box, & .right-box': {
                margin: 'auto',
            },
            '& .border-right': {
                borderRight: 'none',
            },
            '& .image-container': {
                marginBottom: 20,
            },
            '& .error-image': {
                marginTop: 40,
            },
        },
    },
}));
