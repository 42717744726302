import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgEnvelope = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        viewBox="0 0 24 24"
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            clipRule="evenodd"
            d="M19.0656 6.00208L4.93508 6.00261L12 11.783L19.0656 6.00208ZM3.75 6.97109V17.978C3.79013 17.9915 3.84568 18.0032 3.91667 18.0032L20.0832 18.0026C20.1542 18.0026 20.2098 17.9909 20.2499 17.9774V6.97121L12.475 13.3325C12.1987 13.5586 11.8014 13.5586 11.5251 13.3325L3.75 6.97109ZM20.0832 4.50204L3.91667 4.50265C2.99619 4.50265 2.25 5.0996 2.25 5.83598V18.1698C2.25 18.9062 2.99619 19.5032 3.91667 19.5032L20.0832 19.5026C21.0037 19.5026 21.7499 18.9056 21.7499 18.1692V5.83538C21.7499 5.099 21.0037 4.50204 20.0832 4.50204Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const EnvelopeIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgEnvelope}
        fontSize={fontSize}
    />
);
