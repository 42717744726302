import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const spacing2 = theme.spacing(2);
    return {
        baseFont: {
            fontSize: 16,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        marginRight: {
            marginRight: spacing2,
        },
        select: {
            width: 250,
            marginRight: spacing2,
            maxWidth: 250,
            '& .MuiInputLabel-root': {
                background: theme.palette.background.paper,
                padding: theme.spacing(0, 1),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: '0 auto 8px',
            },
        },
        bigSelect: {
            minWidth: 510,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: '0 auto 8px',
            },
        },
        label: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        hidden: {
            display: 'none',
        },
        integrationBaseOn: {
            marginTop: theme.spacing(4),
        },
        integrationMenuItem: {
            padding: theme.spacing(3),
            borderBottom: '1px solid #E3E3E3',
            '&:last-child': {
                borderBottom: 0,
            },
        },
        baseOnText: {
            color: theme.palette.text.primary,
            marginBottom: theme.spacing(3),
        },
        oftenTitle: {
            marginTop: theme.spacing(3),
        },
        reportSchedule: {
            marginTop: theme.spacing(3),
        },
        baseOnValue: {
            marginTop: theme.spacing(4),
        },
        receiveCheck: {
            marginTop: theme.spacing(3),
        },
        notificationCheckbox: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiFormControlLabel-root': {
                marginRight: 0,
            },
        },
        checkReceive: {
            marginTop: theme.spacing(5),
        },
        baseOnRadio: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(4),
            '& .MuiIconButton-root': {
                paddingTop: 0,
                paddingRight: 0,
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
                margin: theme.spacing(2, 0),
            },
        },
        baseOnLabel: {
            marginBottom: theme.spacing(2),
        },
    };
});

export const useF1Styles = makeStyles((theme: Theme) => {
    return {
        f1Step: {
            textAlign: 'center',
            padding: theme.spacing(5),
            position: 'relative',
        },
        f1StepImage: {
            width: 100,
            height: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            background: '#EFF4F9',
            margin: '0 auto 32px',
        },
        f1StepTitle: {
            marginBottom: theme.spacing(3),
        },
        stepLink: {
            border: `2px solid #EFF4F9`,
            width: 86,
            position: 'absolute',
            top: 82,
            right: '-40px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    };
});
