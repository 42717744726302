import { DonationRow } from 'api/models';
import { Reducer } from 'redux';
import {
    PaginatedResponse,
    initialPaginatedResponse,
} from '../../../types/paginationTypes';
import { DonationsPageActions, DONATIONS } from './types';

const DonationsReducer: Reducer<
    PaginatedResponse<DonationRow>,
    DonationsPageActions
> = (state = initialPaginatedResponse, action) => {
    switch (action.type) {
        case DONATIONS.CLEAR:
            return initialPaginatedResponse;

        case DONATIONS.SET:
            return action.data;

        case DONATIONS.APPEND:
            return {
                ...state,
                data: [...state.data, ...action.data.data],
            };
        default:
            return state;
    }
};

export default DonationsReducer;
