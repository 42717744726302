import { DesignTokens, GivelifyTab } from '@givelify/ui';
import { styled } from '@mui/material';

export const DonationSummaryTabs = styled(GivelifyTab)(({ theme }) => ({
    '&.MuiBox-root': {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        '& .tab-stack': {
            gap: '8px',
        },
        '& .tab-root': {
            minWidth: '128px',
            minHeight: '32px',
            padding: '0',
            borderRadius: '32px',
            background: DesignTokens.color.backgroundButtonSecondaryHover,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:before': {
                display: 'none',
            },
            '& .tab-label': {
                color: DesignTokens.color.textSecondary,
                fontSize: '14px',
            },
            '&.tab-selected': {
                background: DesignTokens.color.backgroundButtonPrimaryDefault,
                '& .tab-label': {
                    color: DesignTokens.color.textWhite,
                },
            },
            [theme.breakpoints.down('mobile')]: {
                minHeight: '28px',
                '& .tab-label': {
                    fontSize: '12px',
                },
            },
        },
    },
}));

export const DatePickerWrapper = styled('div')(({ theme }) => ({
    marginBottom: '32px',
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '24px',
    },
}));

export const NumbersRollWrapper = styled('div')(({ theme }) => ({
    margin: '48px 0',
    [theme.breakpoints.down('tablet')]: {
        margin: '24px 0 48px',
    },
    [theme.breakpoints.down('mobile')]: {
        margin: '24px 0',
    },
}));
