import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const DropdownListItem = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalData1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalData1.font.lineHeight}px`,
    },
}));
export const MainLabel = styled('span')({
    fontWeight: 800,
});

export const TitleLine = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    strong: {
        fontWeight: 900,
    },
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalCaption1.font.size,
    },
}));

export const DataTableControlWrapper = styled('div')({
    borderBottom: `1px solid ${DesignTokens.color.dividerPrimaryLight}`,
    marginBottom: '16px',
});
