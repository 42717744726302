import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { requestInit, useApiRequest } from '@givelify/givelify-ui';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppProfile } from '../@types/assets/onboarding';
import {
    customizeProfileAPI,
    saveMissionStatementAPI,
} from '../api/requests/api';
import { DiscardConfirmDialog } from '../components/DiscardConfirmDialog';
import { I18N_NAMESPACE } from '../consts';
import { FullWidthModalOnboarding } from '../modal';
import { isImageChanged } from '../utils/compareImages';
import {
    CustomizeProfileModalComponents,
    CustomizeProfileComponentsProps,
    Step,
} from './CustomizeProfileModalComponents';
import { CustomizeProfileRef } from './CustomizeProfileRef';

export interface CustomizeProfileModalProps
    extends Omit<CustomizeProfileComponentsProps, 'onMobilePreviewRequest'> {
    userId: number;
    doneeId: number;
    open: boolean;
    onClose: () => void;
    initialStep?: Step;
    onSubmit: (data: AppProfile) => unknown;
    settingsUsersPath: string;
}

export const CustomizeProfileModal: React.FC<CustomizeProfileModalProps> = ({
    open,
    onClose,
    initialStep = 'main',
    organizationName,
    organizationType,
    onSubmit,
    appProfile,
    doneeId,
    userId,
    settingsUsersPath,
    formProps,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const [saveDataStatus, makeSaveDataRequest, setStatus] =
        useApiRequest<unknown>();
    const customizeProfileRef = useRef<CustomizeProfileRef>(null);
    const [isValid, setIsValid] = useState<boolean>(initialStep !== 'main');
    const [step, setStep] = useState<Step>(initialStep);
    const [prevStep, setPrevStep] = useState<Step>(initialStep);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const {
        heading,
        viewProfilePreview,
        saveAndContinueText,
        saveAndCloseText,
        errorMessage,
    } = useMemo(
        () => ({
            heading: t('customizeProfile.heading'),
            saveAndContinueText: t('labels.saveAndContinue'),
            saveAndCloseText: t('labels.saveAndClose'),
            errorMessage: t('errors.generic'),
            viewProfilePreview: t('customizeProfile.viewProfilePreview'),
        }),
        [t],
    );
    useEffect(() => {
        if (saveDataStatus.type !== 'REQUEST_SUCCESS') return;

        switch (step) {
            case 'main': {
                if (openConfirmDialog) {
                    setOpenConfirmDialog(false);
                    onClose();
                } else {
                    setStep('mission');
                }
                break;
            }
            case 'mission': {
                if (openConfirmDialog) {
                    setOpenConfirmDialog(false);
                }
                onClose();
                setStep('main');
                break;
            }

            default:
                break;
        }
        setStatus(requestInit());
        // eslint-disable-next-line
    }, [saveDataStatus.type, setStep]);
    const closeStepOne = useCallback(() => {
        const data = customizeProfileRef.current?.submit();
        if (isValid && data) {
            if (
                data.bannerImage ||
                isImageChanged(
                    appProfile.faithLeaderImage,
                    data.faithLeaderImage,
                ) ||
                data.doNotDisplayFaithLeader !==
                    appProfile.doNotDisplayFaithLeader
            ) {
                setOpenConfirmDialog(true);
                return;
            }
            if (
                !isEqual(
                    data.organizationAddress,
                    appProfile.organizationAddress,
                )
            ) {
                setOpenConfirmDialog(true);
                return;
            }
            if (
                !data.doNotDisplayFaithLeader &&
                !isEqual(data.faithLeader, appProfile.faithLeader)
            ) {
                setOpenConfirmDialog(true);
                return;
            }
        }
        onClose();
    }, [
        customizeProfileRef,
        setOpenConfirmDialog,
        onClose,
        isValid,
        appProfile,
    ]);
    const closeStepTwo = useCallback(() => {
        const data = customizeProfileRef.current?.submit();
        if (isValid && data) {
            if (
                data.missionStatement !== appProfile.missionStatement ||
                !isEqual(data.organizationLogo, appProfile.organizationLogo)
            ) {
                setOpenConfirmDialog(true);
                return;
            }
        }
        setStep('main');
    }, [
        customizeProfileRef,
        setOpenConfirmDialog,
        setStep,
        isValid,
        appProfile,
    ]);

    const useBackArrow = useMemo(
        () => step === 'phone' || step === 'mission',
        [step],
    );

    const handleClose = useMemo(() => {
        switch (step) {
            case 'main': {
                return closeStepOne;
            }
            case 'phone': {
                return () => setStep(prevStep);
            }
            case 'mission': {
                return closeStepTwo;
            }
        }
    }, [step, prevStep, closeStepOne, setStep, closeStepTwo]);

    const handleSave = useCallback(() => {
        if (saveDataStatus.type === 'REQUEST_START') return;

        const data = customizeProfileRef.current?.submit();
        if (!data) return;

        if (step === 'main') {
            makeSaveDataRequest(
                customizeProfileAPI(doneeId, {
                    ...data,
                    faithLeaderImage: isImageChanged(
                        appProfile.faithLeaderImage,
                        data.faithLeaderImage,
                    ),
                    organizationLogo: undefined,
                }),
            );
        } else if (step === 'mission') {
            if (
                data.organizationLogo &&
                appProfile.organizationLogo &&
                data.organizationLogo.url === appProfile.organizationLogo.url
            ) {
                makeSaveDataRequest(
                    saveMissionStatementAPI(doneeId, {
                        missionStatement: data.missionStatement,
                    }),
                );
            } else {
                makeSaveDataRequest(saveMissionStatementAPI(doneeId, data));
            }
        }
        onSubmit({
            ...data,
            bannerImage: data.bannerImage || appProfile.bannerImage,
            faithLeaderImage: data.faithLeaderImage,
            organizationLogo:
                data.organizationLogo || appProfile.organizationLogo,
        });
        // eslint-disable-next-line
    }, [
        onSubmit,
        saveDataStatus.type,
        doneeId,
        makeSaveDataRequest,
        step,
        appProfile,
    ]);
    const onDialogDiscardClick = useCallback(() => {
        setOpenConfirmDialog(false);
        if (step === 'main') {
            onClose();
        } else if (step === 'mission') {
            setStep('main');
        }
    }, [step, onClose, setOpenConfirmDialog, setStep]);
    const onDialogConfirmClick = useCallback(() => {
        handleSave();
    }, [handleSave]);
    const onDialogCancelClick = useCallback(() => {
        setOpenConfirmDialog(false);
    }, [setOpenConfirmDialog]);
    const onMobilePreviewRequest = useCallback(() => {
        setPrevStep(step);
        setStep('phone');
    }, [setStep, setPrevStep, step]);
    const onStepClick = useCallback(
        (index: number) => {
            if (index === 0) {
                closeStepOne();
            }
        },
        [closeStepOne],
    );
    return (
        <>
            <FullWidthModalOnboarding
                errorMessage={
                    saveDataStatus.type === 'REQUEST_ERROR'
                        ? saveDataStatus.error.message || errorMessage
                        : undefined
                }
                footer={
                    step === 'phone'
                        ? undefined
                        : {
                              onCancel: onClose,
                              submitText:
                                  step === 'main'
                                      ? saveAndContinueText
                                      : saveAndCloseText,
                              onSubmit: handleSave,
                              isLoading:
                                  saveDataStatus.type === 'REQUEST_START',
                              disableSubmit: !isValid,
                          }
                }
                heading={step === 'phone' ? viewProfilePreview : heading}
                name="Onboarding Donee Profile Modal"
                onClose={handleClose}
                onStepClick={onStepClick}
                open={open}
                retryMessage={undefined}
                steps={
                    step === 'phone'
                        ? undefined
                        : {
                              currentStep: step === 'main' ? 1 : 2,
                              totalSteps: 2,
                          }
                }
                useBackArrow={useBackArrow}
            >
                <CustomizeProfileModalComponents
                    appProfile={appProfile}
                    customizeProfileRef={customizeProfileRef}
                    doneeId={doneeId}
                    formProps={formProps}
                    onMobilePreviewRequest={onMobilePreviewRequest}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    setIsValid={setIsValid}
                    settingsUsersPath={settingsUsersPath}
                    step={step}
                    userId={userId}
                />
            </FullWidthModalOnboarding>
            <DiscardConfirmDialog
                confirmLabel={saveAndCloseText}
                onCancelClick={onDialogCancelClick}
                onConfirmClick={onDialogConfirmClick}
                onDiscardClick={onDialogDiscardClick}
                open={openConfirmDialog}
            />
        </>
    );
};
