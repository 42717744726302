import React, { useMemo } from 'react';
import { GivelifyButton, GivelifyFormTextField } from '@givelify/ui';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useAdvancedTranslation } from 'utils/i18n';
import { SetupCCBFormData } from '../../../IntegrationContent/TabsContent/AutoIntegrationContent/types';

type CbbF1GoAuthFormProps = {
    loading?: boolean;
};

export const CbbF1GoAuthForm: React.FC<CbbF1GoAuthFormProps> = ({
    loading,
}) => {
    const { t } = useAdvancedTranslation();
    const text = useMemo(
        () => ({
            url: t('pages.integrations.settings.ccb.url'),
            username: t('pages.integrations.settings.ccb.username'),
            password: t('pages.integrations.settings.ccb.password'),
            submit: t('labels.submit'),
        }),
        [t],
    );
    const {
        formState: { isValid },
    } = useFormContext<SetupCCBFormData>();
    return (
        <Stack gap={4}>
            <GivelifyFormTextField<SetupCCBFormData>
                fullWidth
                aria-label="url"
                data-testid="url"
                id="url"
                label={text.url}
                name="url"
                size="medium"
            />
            <GivelifyFormTextField<SetupCCBFormData>
                fullWidth
                aria-label="username"
                data-testid="username"
                id="username"
                label={text.username}
                name="username"
                size="medium"
            />
            <GivelifyFormTextField<SetupCCBFormData>
                fullWidth
                aria-label="password"
                data-testid="password"
                id="password"
                label={text.password}
                name="password"
                size="medium"
                type="password"
            />
            <Stack marginLeft="auto" marginRight="auto">
                <GivelifyButton
                    disabled={!isValid}
                    isLoading={loading}
                    name="submit"
                    size="large"
                    text={text.submit}
                    type="submit"
                    variant="primary"
                />
            </Stack>
        </Stack>
    );
};
