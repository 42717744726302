import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgRightChevronDouble = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={30}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>right chevren double</title>
        <path
            clipRule="evenodd"
            d="M10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L13.5858 12L10.2929 8.70711C9.90237 8.31658 9.90237 7.68342 10.2929 7.29289C10.6834 6.90237 11.3166 6.90237 11.7071 7.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071Z"
            fill="current"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929L18.5858 12L15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const RightChevronDoubleIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgRightChevronDouble}
        fontSize={fontSize}
    />
);
