import { Theme, makeStyles } from '@material-ui/core';

export const invitationModalsStyles = makeStyles((theme: Theme) => ({
    modalContent: {
        padding: '8px 32px 32px 32px',
    },
    header: {
        marginBottom: 8,
    },
    description: {
        marginBottom: 24,
    },
    actions: {
        padding: '8px 32px 0 32px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
        '& button': {
            width: '160px',
            [theme.breakpoints.down('xs')]: {
                width: '100px',
            },
        },
    },
}));
