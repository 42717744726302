import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
  container: {
    padding: '24px 24px 16px 24px',
    height: '100%',
    border: `1px solid ${theme?.colors?.neutralPlatinum || '#E3E3E3'}`,
    background: theme?.colors?.primaryWhite || '#ffffff',
    borderRadius: 4,

    '&:hover': {
      background: theme?.colors?.neutralHoverGrey || '#F6F6F6',
      cursor: 'pointer',

      '& $title': {
        color: theme?.colors?.accentDarkBlue || '#5F48F0',
        textDecoration: 'underline',
      },
    },
  },
  title: {
    marginBottom: 16,
    marginTop: 10,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '30px',
    textAlign: 'left',

    '&:hover': {
      color: theme?.colors?.accentDarkBlue || '#5F48F0',
    },
  },
}));
