import { Address, isValidAddress } from '@givelify/givelify-ui';
import { DirectDepositInfo } from '../@types/assets/onboarding';

export const getBankingAddress = (
    directDeposit: DirectDepositInfo,
    organizationAddress: Address,
): DirectDepositInfo => {
    if (isValidAddress(directDeposit.bankingAddress, true)) {
        return directDeposit;
    }
    directDeposit.bankingAddress = organizationAddress;
    return directDeposit;
};
