import { styled } from '@mui/material';
import dayjs from 'dayjs';

type DateRange = {
    start: null | dayjs.Dayjs;
    end: null | dayjs.Dayjs;
};

const DateRangeText = styled('span')({
    margin: '0 2px',
});

export const generateDateRange = ({ start, end }: DateRange): JSX.Element => {
    let minFormattedText = '';
    let maxFormattedText = '';
    if (start && start.isValid()) {
        minFormattedText = start.format('MM/DD/YYYY');
        maxFormattedText = minFormattedText;
    }
    if (end && end.isValid()) {
        maxFormattedText = end.format('MM/DD/YYYY');
        if (minFormattedText === '') {
            minFormattedText = maxFormattedText;
        }
    }
    return (
        <>
            <DateRangeText title="startDate">{minFormattedText}</DateRangeText>{' '}
            - <DateRangeText title="endDate">{maxFormattedText}</DateRangeText>
        </>
    );
};
