import React from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { useTimeframeFilter } from 'api/hooks';
import {
    useMessageFilter,
    useEnvelopesFilter,
    messageFilterOptions,
} from 'components/filters';
import { MainFilterRow } from 'components/filters/MainFilterRow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageTitle from '../../../components/PageTitle';
import { AppState } from '../../../store';
import NoResultsScreen from '../components/NoResultsScreen';
import { DonationPaper } from '../donationsPage/styles';
import DonationActivityInfiniteLoader from './DonationActivityInfiniteLoader';

export const DonationActivity: React.FC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            activityTitle: t('donationsActivity.title'),
            donations: t('dashboard.donations'),
            total: t('dashboard.total'),
            depositsTab: t('bankDeposits.tabName'),
            refundsTab: t('refunds.tabName'),
        }),
        [t],
    );
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const [messageFilterValue, setMessageFilterValue] = useMessageFilter();
    const [timeFrame, setTimeFrame] = useTimeframeFilter();
    const [envelopesFilterValue, setEnvelopesFilterValue] = useEnvelopesFilter(
        doneeId,
        timeFrame,
    );
    const noResults =
        messageFilterValue.length === 0 ||
        (envelopesFilterValue.values.length === 0 &&
            !envelopesFilterValue.loading);
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.DonationActivity}>
            <PageTitle
                description="Donations Activity"
                title="Donations Activity"
            />
            <DonationPaper>
                <MainFilterRow
                    envelopesFilterProps={{
                        values: envelopesFilterValue.values,
                        options: envelopesFilterValue.options,
                        disabled: envelopesFilterValue.loading,
                        onChange: setEnvelopesFilterValue,
                    }}
                    messageFilterProps={{
                        values: messageFilterValue,
                        onChange: setMessageFilterValue,
                    }}
                    page="activity"
                    timeFrameFilterProps={{
                        value: timeFrame,
                        onChange: setTimeFrame,
                    }}
                    title={copy.activityTitle}
                    totalBannerProps={{
                        position: 'title',
                        size: 'normal',
                        mode: noResults ? 'empty' : 'real',
                        topText: copy.total,
                        bottomText: copy.donations,
                    }}
                />
                {noResults ? (
                    <NoResultsScreen pageName="activity" />
                ) : (
                    <DonationActivityInfiniteLoader
                        doneeId={doneeId}
                        envelopeIds={
                            envelopesFilterValue.options.length ===
                            envelopesFilterValue.values.length
                                ? undefined
                                : envelopesFilterValue.values
                        }
                        envelopesLoading={envelopesFilterValue.loading}
                        messageFilter={
                            messageFilterValue.length ===
                            messageFilterOptions.length
                                ? undefined
                                : messageFilterValue
                        }
                        timeFrame={timeFrame}
                    />
                )}
            </DonationPaper>
        </TrackingProvider>
    );
};
