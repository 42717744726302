import React from 'react';
import { DesignTokens, GivelifyModal } from '@givelify/ui';
import { styled } from '@mui/material';
import { useGivingTueasdayContext } from './context/GivingTuesdayContext';
import CreateEnvelopeScreen from './CreateEnvelopeScreen';
import EnvelopeListsListScreen from './EnvelopesListScreen';
import BackgroundLeftBottom from './img/backgroundLeftBottom.svg';
import BackgroundTopRight from './img/backgroundTopRight.svg';
import PollScreen from './PollScreen';
import ShareScreen from './ShareScreen';
import WelcomeScreen from './WelcomeScreen';

const topRight = btoa(BackgroundTopRight);
const leftBottom = btoa(BackgroundLeftBottom);

const Wrapper = styled('div')<{ showBackground: boolean }>(
    ({ showBackground, theme }) => ({
        maxWidth: 683,
        paddingTop: 48,
        paddingBottom: 48,
        paddingLeft: 24,
        paddingRight: 24,
        overflowY: 'auto',

        [theme.breakpoints.down('mobile')]: {
            paddingTop: 24,
            paddingBottom: 24,
        },

        '&:hover': {
            scrollbarWidth: 'auto',
            '&::-webkit-scrollbar': {
                width: '6px',
                height: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '3px',
                backgroundColor:
                    DesignTokens.color.backgroundComponentQuaternary,
            },
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: 'transparent',
        },
        ...(showBackground
            ? {
                  backgroundImage: `url('data:image/svg+xml;base64,${topRight}'), url('data:image/svg+xml;base64,${leftBottom}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPositionX: 'right, left',
                  backgroundPositionY: 'top, bottom',
                  backgroundAttachment: 'scroll',
              }
            : {}),
    }),
);

const GivingTuesday = () => {
    const { currentStep } = useGivingTueasdayContext();

    return (
        <GivelifyModal noPadding open maxWidth={false}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Wrapper showBackground={currentStep === 'welcome'}>
                    {currentStep === 'welcome' && <WelcomeScreen />}
                    {currentStep === 'poll' && <PollScreen />}
                    {currentStep === 'envelopeList' && (
                        <EnvelopeListsListScreen />
                    )}
                    {currentStep === 'createEnvelope' && (
                        <CreateEnvelopeScreen />
                    )}
                    {currentStep === 'share' && <ShareScreen />}
                </Wrapper>
            </div>
        </GivelifyModal>
    );
};

export default GivingTuesday;
