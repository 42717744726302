import React from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { Grid } from '@material-ui/core';
import { matchRowStyle } from '../../../../../components/style';
import { HeaderCell } from '../../../DonorMatching/components/DonorMatchingHeader';
import { donorTabStyle } from '../../style';

interface MatchDonorHeaderProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: (sorting: Sorting[]) => void;
    copy: {
        platform: string;
        donorName: string;
        email: string;
        phone: string;
        address: string;
        sort: string;
    };
}

const AutoIntegrationMatchDonorHeader: React.FC<MatchDonorHeaderProps> = ({
    sortColumn,
    sortDirection,
    onOrderClick,
    copy,
}) => {
    const classes = matchRowStyle();
    const style = donorTabStyle();

    return (
        <div className={style.headerDonorRow}>
            <Grid
                container
                alignItems="center"
                className={style.donorContainer}
            >
                <Grid
                    item
                    className={
                        classes.matchHeaderItem + ' ' + style.matchHeaderLogo
                    }
                >
                    {copy.platform}
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem +
                        ' ' +
                        style.matchDonorName +
                        ' ' +
                        style.matchDonorNameHeader
                    }
                >
                    <HeaderCell
                        label={copy.donorName}
                        name={'full_name'}
                        onOrderClick={() =>
                            onOrderClick([
                                {
                                    columnName: 'full_name',
                                    direction:
                                        sortDirection === 'asc'
                                            ? 'desc'
                                            : 'asc',
                                },
                            ])
                        }
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sortLabel={copy.sort}
                    />
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem + ' ' + style.matchDonorEmail
                    }
                >
                    {copy.email}
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem + ' ' + style.matchDonorPhone
                    }
                >
                    {copy.phone}
                </Grid>
                <Grid
                    item
                    className={
                        classes.matchHeaderItem + ' ' + style.matchDonorAddress
                    }
                >
                    {copy.address}
                </Grid>
            </Grid>
        </div>
    );
};

export default AutoIntegrationMatchDonorHeader;
