import { DesignTokens, GivelifyLink } from '@givelify/ui';
import { styled } from '@mui/material';

export const BarChartContainer = styled('div')(({ theme }) => ({
    '& .recharts-legend-wrapper': {
        bottom: '0 !important',
        '& .recharts-legend-item': {
            marginRight: theme.spacing(3) + ' !important',
        },
        '& .recharts-legend-item-text': {
            color: '#000000 !important',
        },
    },
    '& .recharts-yAxis': {
        '& .recharts-cartesian-axis-tick': {
            '&:first-of-type': {
                transform: 'translate(0, -8px)',
            },
        },
    },
}));

export const LegendText = styled(GivelifyLink)({
    color: DesignTokens.color.textAccentDefault,
    '&:hover': {
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
    },
});
