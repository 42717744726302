import React from 'react';
import { createPortal } from 'react-dom';

type PortalProps = React.PropsWithChildren<{
    ref?: React.RefObject<HTMLElement>;
}>;

const Portal = React.forwardRef<HTMLElement, PortalProps>(
    ({ children }, ref) => {
        const rr = ref as React.RefObject<HTMLElement>;
        return rr.current ? createPortal(children, rr.current) : null;
    },
);
Portal.displayName = 'Portal';

export default Portal;
