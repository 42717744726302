import { makeStyles, Theme } from '@material-ui/core';

export const usePageStyle = makeStyles((theme: Theme) => ({
  container: {
    background: '#FFFFFF',
    padding: '32px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
    borderRadius: '4px',
  },

  bottomDesc: {
    textAlign: 'center',
    marginTop: 24,
  },

  givelifyBtnDesc: {
    marginTop: theme.spacing(1),
  },

  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: 2,
  },

  codeBlock: {
    background: '#F4F4F4',
    borderRadius: '4px',
    padding: '16px 20px',
    textAlign: 'center',
    wordBreak: 'break-word',
    maxWidth: '512px',
  },

  copyCodeButton: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 140,
      margin: '8px auto 0 auto',
    },
  },

  givelifyButton: {
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& > img': {
        width: '100%',
      },
    },
  },

  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& > div': {
      flex: '1 1 auto',
      display: 'flex',
      position: 'relative',

      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        padding: theme.spacing(0, 3, 0, 3),
      },

      '&:first-child': {
        [theme.breakpoints.down('sm')]: {
          padding: '40px 0 32px 0',
        },
      },
    },

    '& > div + div': {
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 0, 0, 3),
      },
    },
  },

  buttonLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  previewLabel: {
    marginBottom: 4,
  },

  // styles for public page
  ppHeading: {
    margin: '127px 0 31px 0',
  },

  ppContainer: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    maxWidth: '1040px',
    flexDirection: 'column',
    margin: '0 auto',
    paddingBottom: '80px',
  },
}));
