import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IntegrationTypes } from '../../../types';
import F1SettingTabContent from '../F1IntegrationTabsContent/F1SettingTabContent';
import { F1GoAndCCBIntegrationSettings } from '../IntegrationSettings/F1GoAndCCBIntegrationSettings';
import { PCIntegrationSettings } from '../IntegrationSettings/PCIntegrationSettings/PCIntegrationSettings';

const useStyles = makeStyles((theme: Theme) => ({
    editLink: {
        padding: 0,
        fontWeight: 400,
    },
    settingTab: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
}));

interface SettingTabContentProps {
    integration: IntegrationTypes;
    savedSetting?: () => void;
    isReadonly?: boolean;
}

const AutoIntegrationSettings: React.FC<SettingTabContentProps> = ({
    integration,
    isReadonly,
}) => {
    const { settingTab } = useStyles();

    const CCB = integration.integrationType === 'CCB';
    const F1 = integration.integrationType === 'F1';
    const PC = integration.integrationType === 'PLANNING_CENTER';
    const F1GO = integration.integrationType === 'F1GO';

    return (
        <div className={settingTab}>
            {CCB || F1GO ? <F1GoAndCCBIntegrationSettings f1Go={F1GO} /> : null}
            {F1 ? <F1SettingTabContent isReadOnly={isReadonly} /> : null}
            {PC ? <PCIntegrationSettings /> : null}
        </div>
    );
};

export default AutoIntegrationSettings;
