import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { mergeClassNames } from '../utils/classNameUtils';
import { ReactComponent as AccountBalanceSvg } from '../assets/icons/account-balance.svg';
import { ReactComponent as AnalyticsSvg } from '../assets/icons/analytics.svg';
import { ReactComponent as ArrowAltSvg } from '../assets/icons/arrow-alt.svg';
import { ReactComponent as ArrowDownSvg } from '../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpSvg } from '../assets/icons/arrow-up.svg';
import { ReactComponent as CheckSvg } from '../assets/icons/check.svg';
import { ReactComponent as CheckCircleSvg } from '../assets/icons/check-circle.svg';
import { ReactComponent as ChevronDownSvg } from '../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpSvg } from '../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronRightSvg } from '../assets/icons/chevron-right.svg';
import { ReactComponent as CloseCircle } from '../assets/icons/close-circle.svg';
import { ReactComponent as CloseSimple } from '../assets/icons/close-simple.svg';
import { ReactComponent as CommentsSvg } from '../assets/icons/comments.svg';
import { ReactComponent as CopySvg } from '../assets/icons/copy.svg';
import { ReactComponent as CreditCardSvg } from '../assets/icons/credit-card.svg';
import { ReactComponent as DownloadSvg } from '../assets/icons/download.svg';
import { ReactComponent as DescriptionSvg } from '../assets/icons/description.svg';
import { ReactComponent as EditSvg } from '../assets/icons/edit.svg';
import { ReactComponent as EditSimpleSvg } from '../assets/icons/edit-simple.svg';
import { ReactComponent as EnvelopeSvg } from '../assets/icons/envelope.svg';
import { ReactComponent as FileAltSvg } from '../assets/icons/file-alt.svg';
import { ReactComponent as FileCsvSvg } from '../assets/icons/file-csv.svg';
import { ReactComponent as FileDownloadSvg } from '../assets/icons/file-download.svg';
import { ReactComponent as FileExportSvg } from '../assets/icons/file-export.svg';
import { ReactComponent as FileImportSvg } from '../assets/icons/file-import.svg';
import { ReactComponent as FilterSvg } from '../assets/icons/filter.svg';
import { ReactComponent as GiftCardSvg } from '../assets/icons/gift-card.svg';
import { ReactComponent as HistorySvg } from '../assets/icons/history.svg';
import { ReactComponent as HandHoldingUsdSvg } from '../assets/icons/hand-holding-usd.svg';
import { ReactComponent as InfoCircleSvg } from '../assets/icons/info-circle.svg';
import { ReactComponent as InfoFilledSvg } from '../assets/icons/info-filled.svg';
import { ReactComponent as InvoiceDollarSvg } from '../assets/icons/invoice-dollar.svg';
import { ReactComponent as MapMarkerSvg } from '../assets/icons/map-marker.svg';
import { ReactComponent as QuestionCircleSvg } from '../assets/icons/question-circle.svg';
import { ReactComponent as RedoSvg } from '../assets/icons/redo.svg';
import { ReactComponent as SettingSvg } from '../assets/icons/setting.svg';
import { ReactComponent as ShareSvg } from '../assets/icons/share.svg';
import { ReactComponent as ShareAltSvg } from '../assets/icons/share-alt.svg';
import { ReactComponent as SortSvg } from '../assets/icons/sort.svg';
import { ReactComponent as TasksSvg } from '../assets/icons/tasks.svg';
import { ReactComponent as UndoSvg } from '../assets/icons/undo.svg';
import { ReactComponent as UserSvg } from '../assets/icons/user.svg';
import { ReactComponent as UserCheckSvg } from '../assets/icons/user-check.svg';
import { ReactComponent as UserLockSvg } from '../assets/icons/user-lock.svg';
import { ReactComponent as QuoteOpenSvg } from '../assets/icons/quote-open.svg';
import { ReactComponent as QuoteCloseSvg } from '../assets/icons/quote-close.svg';
import { ReactComponent as AvatarSvg } from '../assets/icons/avatar.svg';
import { ReactComponent as ImageSvg } from '../assets/icons/image.svg';
import { ReactComponent as DeleteSvg } from '../assets/icons/delete.svg';
import { ReactComponent as UploadSvg } from '../assets/icons/upload.svg';
import { ReactComponent as LockSvg } from '../assets/icons/lock.svg';
import { ReactComponent as UsersSvg } from '../assets/icons/users.svg';
import { ReactComponent as BackSvg } from '../assets/icons/back.svg';
import { ReactComponent as LocationSvg } from '../assets/icons/location-icon.svg';
import { ReactComponent as PasswordSvg } from '../assets/icons/password.svg';
import { ReactComponent as PasswordOpenSvg } from '../assets/icons/password-open.svg';
import { ReactComponent as SelectArrowSvg } from '../assets/icons/select-arrow.svg';
import { ReactComponent as SendSvg } from '../assets/icons/send.svg';
import { ReactComponent as DateSvg } from '../assets/icons/date.svg';
import { ReactComponent as SearchSvg } from '../assets/icons/search.svg';
import { ReactComponent as FullScreenSvg } from '../assets/icons/full-screen.svg';
import { ReactComponent as ExitFullScreenSvg } from '../assets/icons/exit-full-screen.svg';
import { ReactComponent as PaySvg } from '../assets/icons/pay.svg';
import { ReactComponent as CloseSvg } from '../assets/icons/close.svg';
import { ReactComponent as AddSvg } from '../assets/icons/add.svg';
import { ReactComponent as LoadingSvg } from '../assets/icons/loading.svg';
import { ReactComponent as FilterAltSvg } from '../assets/icons/filter-alt.svg';
import { ReactComponent as ExpandSvg } from '../assets/icons/expand.svg';
import { ReactComponent as CollapseSvg } from '../assets/icons/collapse.svg';
import { ReactComponent as PhotoSvg } from '../assets/icons/photo.svg';
import { ReactComponent as PollSvg } from '../assets/icons/poll.svg';
import { ReactComponent as StarSvg } from '../assets/icons/star.svg';
import { ReactComponent as ThumbUpSvg } from '../assets/icons/thumb-up.svg';
import { ReactComponent as MoreSvg } from '../assets/icons/more.svg';
import { ReactComponent as ExclamationCircleSvg } from '../assets/icons/exclamation-circle.svg';
import { ReactComponent as CheckCircleFilledCheckedSvg } from '../assets/icons/check-circle-filled-checked.svg';
import { ReactComponent as CheckCircleFilledUncheckedSvg } from '../assets/icons/check-circle-filled-unchecked.svg';
import { ReactComponent as InstitutionSvg } from '../assets/icons/institution.svg';
import { ReactComponent as InstitutionNewCheckMarkSvg } from '../assets/icons/institution-new-checkmark.svg';
import { ReactComponent as ComputerSvg } from '../assets/icons/computer.svg';
import { ReactComponent as DonorSvg } from '../assets/icons/donor.svg';
import { ReactComponent as HeartInHandSvg } from '../assets/icons/heart-in-hand.svg';
import { ReactComponent as MailSvg } from '../assets/icons/mail.svg';
import { ReactComponent as PeopleSvg } from '../assets/icons/people.svg';
import { ReactComponent as BankSvg } from '../assets/icons/bank.svg';
import { ReactComponent as LocationPinSvg } from '../assets/icons/location-pin.svg';
import { ReactComponent as LocationMapSvg } from '../assets/icons/location-map.svg';
import { ReactComponent as FirstPageSvg } from '../assets/icons/first-page.svg';
import { ReactComponent as LastPageSvg } from '../assets/icons/last-page.svg';
import { ReactComponent as PreviousPageSvg } from '../assets/icons/previous.svg';
import { ReactComponent as NextPageSvg } from '../assets/icons/next.svg';
import { ReactComponent as BreadcrumbsSeparatorSvg } from '../assets/icons/breadcrumbs-separator.svg';
import { ReactComponent as HamburgerSvg } from '../assets/icons/hamburger.svg';
import { ReactComponent as GivelifyLogoSvg } from '../assets/icons/givelify-logo.svg';
import { ReactComponent as OverviewDonationsSvg } from '../assets/icons/overview/donations.svg';
import { ReactComponent as OverviewDonorsSvg } from '../assets/icons/overview/donors.svg';
import { ReactComponent as OverviewGivingSvg } from '../assets/icons/overview/giving.svg';
import { ReactComponent as OverviewBehaviorSvg } from '../assets/icons/overview/giving-behavior.svg';
import { ReactComponent as OverviewTrendSvg } from '../assets/icons/overview/trend.svg';
import { ReactComponent as SidebarBank } from '../assets/icons/sidebar-bank.svg';
import { ReactComponent as SidebarDonations } from '../assets/icons/sidebar-donations.svg';
import { ReactComponent as SidebarDonors } from '../assets/icons/sidebar-donors.svg';
import { ReactComponent as SidebarEnvelopes } from '../assets/icons/sidebar-envelopes.svg';
import { ReactComponent as SidebarGive } from '../assets/icons/sidebar-give.svg';
import { ReactComponent as SidebarIntegrations } from '../assets/icons/sidebar-integrations.svg';
import { ReactComponent as SidebarReports } from '../assets/icons/sidebar-reports.svg';
import { ReactComponent as SidebarSettings } from '../assets/icons/sidebar-settings.svg';
import { ReactComponent as SidebarOverview } from '../assets/icons/sidebar-overview.svg';
import { ReactComponent as GivingMoney } from '../assets/icons/giving-money.svg';
import { ReactComponent as EditOutline } from '../assets/icons/edit-outline.svg';
import { ReactComponent as ActiveEnvelope } from '../assets/icons/active-envelope.svg';
import { ReactComponent as InActiveEnvelope } from '../assets/icons/inactive-envelope.svg';
import { ReactComponent as SwitchArrow } from '../assets/icons/switch-arrow.svg';
import { ReactComponent as Move } from '../assets/icons/move.svg';
import { ReactComponent as AlertSvg } from '../assets/icons/alert.svg';
import { ReactComponent as Arrange } from '../assets/icons/arrange.svg';
import { ReactComponent as Trash } from '../assets/icons/trash.svg';
import { ReactComponent as Checkmark } from '../assets/icons/checkmark.svg';
import { iconStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyColorOrString } from '../style/givelifyDefaultTheme';

export type GivelifyIconVariant =
    | 'account-balance'
    | 'analytics'
    | 'arrow-alt'
    | 'arrow-down'
    | 'arrow-up'
    | 'back'
    | 'check'
    | 'check-circle'
    | 'chevron-down'
    | 'narrow-chevron-down'
    | 'chevron-up'
    | 'chevron-right'
    | 'bigClose'
    | 'close-circle'
    | 'close-simple'
    | 'comments'
    | 'copy'
    | 'date'
    | 'download'
    | 'edit'
    | 'edit-simple'
    | 'envelope'
    | 'file-alt'
    | 'file-csv'
    | 'file-download'
    | 'file-export'
    | 'file-import'
    | 'filter'
    | 'hand-holding-usd'
    | 'info-circle'
    | 'info-filled'
    | 'invoice-dollar'
    | 'map-marker'
    | 'question-circle'
    | 'redo'
    | 'setting'
    | 'share'
    | 'share-alt'
    | 'sort'
    | 'tasks'
    | 'undo'
    | 'user'
    | 'user-check'
    | 'lock'
    | 'avatar'
    | 'user-lock'
    | 'quote-open'
    | 'quote-close'
    | 'image'
    | 'delete'
    | 'upload'
    | 'users'
    | 'location'
    | 'password'
    | 'password-open'
    | 'select-arrow'
    | 'send'
    | 'search'
    | 'full-screen'
    | 'exit-full-screen'
    | 'pay'
    | 'add'
    | 'loading'
    | 'filter-alt'
    | 'expand'
    | 'collapse'
    | 'history'
    | 'gift-card'
    | 'credit-card'
    | 'description'
    | 'photo'
    | 'poll'
    | 'star'
    | 'thumb-up'
    | 'more'
    | 'exclamation-circle'
    | 'close'
    | 'check-circle-filled-checked'
    | 'check-circle-filled-unchecked'
    | 'institution'
    | 'insititution-new-checkmark'
    | 'computer'
    | 'donor'
    | 'heart-in-hand'
    | 'mail'
    | 'people'
    | 'bank'
    | 'location-pin'
    | 'location-map'
    | 'first-page'
    | 'previous-page'
    | 'next-page'
    | 'last-page'
    | 'breadcrumbs-separator'
    | 'hamburger'
    | 'givelify-logo'
    | 'overview-donations'
    | 'overview-donors'
    | 'overview-giving'
    | 'overview-behavior'
    | 'overview-trend'
    | 'sidebar-bank'
    | 'sidebar-donations'
    | 'sidebar-donors'
    | 'sidebar-envelopes'
    | 'sidebar-give'
    | 'sidebar-integrations'
    | 'sidebar-reports'
    | 'sidebar-settings'
    | 'sidebar-overview'
    | 'giving-money'
    | 'edit-outline'
    | 'active-envelope'
    | 'inactive-envelope'
    | 'switch-arrow'
    | 'move'
    | 'alert'
    | 'arrange'
    | 'trash'
    | 'checkmark';

export interface GivelifyIconProps extends GivelifyBoxMarginProps {
    /**
     * Icon variant
     */
    variant: GivelifyIconVariant;
    /**
     * Icon size (font size)
     */
    size?: string | number;
    /**
     * Icon color (font color)
     */
    color?: GivelifyColorOrString;
    ignoreOwnClasses?: boolean;
    className?: string;
    onClick?: () => unknown;
    spin?: boolean;
    dataTestId?: string;
}

const getIconSvg = (variant: GivelifyIconVariant) => {
    switch (variant) {
        case 'analytics':
            return AnalyticsSvg;
        case 'arrow-alt':
            return ArrowAltSvg;
        case 'back':
            return BackSvg;
        case 'arrow-down':
            return ArrowDownSvg;
        case 'arrow-up':
            return ArrowUpSvg;
        case 'check':
            return CheckSvg;
        case 'check-circle':
            return CheckCircleSvg;
        case 'chevron-down':
        case 'narrow-chevron-down':
            return ChevronDownSvg;
        case 'chevron-up':
            return ChevronUpSvg;
        case 'chevron-right':
            return ChevronRightSvg;
        case 'bigClose':
        case 'close-circle':
            return CloseCircle;
        case 'close-simple':
            return CloseSimple;
        case 'comments':
            return CommentsSvg;
        case 'copy':
            return CopySvg;
        case 'date':
            return DateSvg;
        case 'download':
            return DownloadSvg;
        case 'edit':
            return EditSvg;
        case 'edit-simple':
            return EditSimpleSvg;
        case 'envelope':
            return EnvelopeSvg;
        case 'file-alt':
            return FileAltSvg;
        case 'file-csv':
            return FileCsvSvg;
        case 'file-download':
            return FileDownloadSvg;
        case 'file-export':
            return FileExportSvg;
        case 'file-import':
            return FileImportSvg;
        case 'filter':
            return FilterSvg;
        case 'hand-holding-usd':
            return HandHoldingUsdSvg;
        case 'info-circle':
            return InfoCircleSvg;
        case 'info-filled':
            return InfoFilledSvg;
        case 'invoice-dollar':
            return InvoiceDollarSvg;
        case 'map-marker':
            return MapMarkerSvg;
        case 'question-circle':
            return QuestionCircleSvg;
        case 'redo':
            return RedoSvg;
        case 'setting':
            return SettingSvg;
        case 'share':
            return ShareSvg;
        case 'share-alt':
            return ShareAltSvg;
        case 'sort':
            return SortSvg;
        case 'tasks':
            return TasksSvg;
        case 'undo':
            return UndoSvg;
        case 'user':
            return UserSvg;
        case 'user-check':
            return UserCheckSvg;
        case 'user-lock':
            return UserLockSvg;
        case 'quote-open':
            return QuoteOpenSvg;
        case 'quote-close':
            return QuoteCloseSvg;
        case 'avatar':
            return AvatarSvg;
        case 'image':
            return ImageSvg;
        case 'delete':
            return DeleteSvg;
        case 'upload':
            return UploadSvg;
        case 'lock':
            return LockSvg;
        case 'users':
            return UsersSvg;
        case 'location':
            return LocationSvg;
        case 'password':
            return PasswordSvg;
        case 'password-open':
            return PasswordOpenSvg;
        case 'select-arrow':
            return SelectArrowSvg;
        case 'send':
            return SendSvg;
        case 'search':
            return SearchSvg;
        case 'full-screen':
            return FullScreenSvg;
        case 'exit-full-screen':
            return ExitFullScreenSvg;
        case 'pay':
            return PaySvg;
        case 'close':
            return CloseSvg;
        case 'add':
            return AddSvg;
        case 'loading':
            return LoadingSvg;
        case 'filter-alt':
            return FilterAltSvg;
        case 'expand':
            return ExpandSvg;
        case 'collapse':
            return CollapseSvg;
        case 'photo':
            return PhotoSvg;
        case 'poll':
            return PollSvg;
        case 'star':
            return StarSvg;
        case 'thumb-up':
            return ThumbUpSvg;
        case 'account-balance':
            return AccountBalanceSvg;
        case 'history':
            return HistorySvg;
        case 'gift-card':
            return GiftCardSvg;
        case 'credit-card':
            return CreditCardSvg;
        case 'description':
            return DescriptionSvg;
        case 'more':
            return MoreSvg;
        case 'exclamation-circle':
            return ExclamationCircleSvg;
        case 'check-circle-filled-checked':
            return CheckCircleFilledCheckedSvg;
        case 'check-circle-filled-unchecked':
            return CheckCircleFilledUncheckedSvg;
        case 'institution':
            return InstitutionSvg;
        case 'insititution-new-checkmark':
            return InstitutionNewCheckMarkSvg;
        case 'computer':
            return ComputerSvg;
        case 'donor':
            return DonorSvg;
        case 'heart-in-hand':
            return HeartInHandSvg;
        case 'mail':
            return MailSvg;
        case 'people':
            return PeopleSvg;
        case 'bank':
            return BankSvg;
        case 'location-pin':
            return LocationPinSvg;
        case 'location-map':
            return LocationMapSvg;
        case 'first-page':
            return FirstPageSvg;
        case 'last-page':
            return LastPageSvg;
        case 'previous-page':
            return PreviousPageSvg;
        case 'next-page':
            return NextPageSvg;
        case 'breadcrumbs-separator':
            return BreadcrumbsSeparatorSvg;
        case 'hamburger':
            return HamburgerSvg;
        case 'givelify-logo':
            return GivelifyLogoSvg;
        case 'overview-donations':
            return OverviewDonationsSvg;
        case 'overview-donors':
            return OverviewDonorsSvg;
        case 'overview-giving':
            return OverviewGivingSvg;
        case 'overview-behavior':
            return OverviewBehaviorSvg;
        case 'overview-trend':
            return OverviewTrendSvg;
        case 'sidebar-bank':
            return SidebarBank;
        case 'sidebar-donations':
            return SidebarDonations;
        case 'sidebar-donors':
            return SidebarDonors;
        case 'sidebar-envelopes':
            return SidebarEnvelopes;
        case 'sidebar-give':
            return SidebarGive;
        case 'sidebar-integrations':
            return SidebarIntegrations;
        case 'sidebar-reports':
            return SidebarReports;
        case 'sidebar-settings':
            return SidebarSettings;
        case 'sidebar-overview':
            return SidebarOverview;
        case 'giving-money':
            return GivingMoney;
        case 'edit-outline':
            return EditOutline;
        case 'active-envelope':
            return ActiveEnvelope;
        case 'inactive-envelope':
            return InActiveEnvelope;
        case 'switch-arrow':
            return SwitchArrow;
        case 'move':
            return Move;
        case 'alert':
            return AlertSvg;
        case 'arrange':
            return Arrange;
        case 'trash':
            return Trash;
        case 'checkmark':
            return Checkmark;
    }
};

const getIconSvgViewBox = (variant: GivelifyIconVariant) => {
    switch (variant) {
        case 'question-circle':
            return '0 0 48 48';
        case 'file-download':
            return '0 0 36 48';
        case 'hand-holding-usd':
            return '0 0 48 44';
        case 'info-filled':
        case 'close':
            return '0 0 14 14';
        case 'invoice-dollar':
            return '0 0 37 48';
        case 'select-arrow':
            return '0 0 9 6';
        case 'share':
            return '0 0 48 43';
        case 'tasks':
            return '0 0 48 40';
        case 'user-lock':
            return '0 0 48 39';
        case 'quote-open':
            return '0 0 16 13';
        case 'quote-close':
            return '0 0 14 12';
        case 'file-csv':
            return '0 0 21 21';
        case 'location':
        case 'search':
        case 'chevron-down':
        case 'chevron-up':
            return '0 0 16 16';
        case 'chevron-right':
            return '0 0 8 12';
        case 'exclamation-circle':
        case 'info-circle':
            return '0 0 20 20';
        case 'check-circle-filled-checked':
            return '0 0 16 16';
        case 'check-circle-filled-unchecked':
            return '0 0 18 17';
        case 'institution':
            return '0 0 107 72';
        case 'insititution-new-checkmark':
            return '0 0 64 64';
        case 'computer':
            return '0 0 239 234';
        case 'donor':
        case 'heart-in-hand':
        case 'mail':
        case 'people':
            return '0 0 225 225';
        case 'bank':
            return '0 0 250 233';
        case 'location-map':
            return '0 0 244 139';
        case 'first-page':
        case 'last-page':
            return '0 0 14 12';
        case 'next-page':
        case 'previous-page':
            return '0 0 12 12';
        case 'breadcrumbs-separator':
            return '0 0 5 8';
        case 'hamburger':
            return '0 0 24 18';
        case 'givelify-logo':
            return '0 0 117 36';

        case 'sidebar-bank':
        case 'sidebar-donations':
        case 'sidebar-donors':
        case 'sidebar-envelopes':
        case 'sidebar-integrations':
        case 'sidebar-overview':
        case 'sidebar-reports':
        case 'sidebar-settings':
            return '0 0 28 28';

        case 'sidebar-give':
            return '0 0 28 29';

        case 'account-balance':
        case 'description':
        case 'credit-card':
        case 'gift-card':
        case 'star':
        case 'thumb-up':
        case 'poll':
        case 'history':
        case 'arrange':
            return '0 0 24 24';
        case 'overview-donations':
        case 'overview-donors':
        case 'overview-giving':
        case 'overview-behavior':
        case 'overview-trend':
        case 'active-envelope':
        case 'inactive-envelope':
            return '0 0 64 64';

        case 'giving-money':
            return '0 0 64 65';

        case 'edit-outline':
            return '0 0 16 17';
        case 'switch-arrow':
            return '0 0 28 26';

        case 'alert':
            return '0 0 64 64';
    }
    return '0 0 24 24';
};

export const GivelifyIcon: React.FC<GivelifyIconProps> = ({
    size,
    color,
    margin,
    marginLeft,
    marginBottom,
    marginRight,
    marginTop,
    ignoreOwnClasses,
    className,
    variant,
    onClick,
    spin,
    dataTestId,
}) => {
    const { boxMargin, icon, spinIcon } = iconStyles({
        size: size,
        color: color,
        margin: margin,
        marginLeft: marginLeft,
        marginBottom: marginBottom,
        marginRight: marginRight,
        marginTop: marginTop,
    });
    return (
        <SvgIcon
            data-testid={dataTestId}
            onClick={onClick}
            classes={{
                root: mergeClassNames(
                    !ignoreOwnClasses && boxMargin,
                    !ignoreOwnClasses && icon,
                    className,
                    spin && spinIcon,
                ),
            }}
            fontSize="inherit"
            component={getIconSvg(variant)}
            viewBox={getIconSvgViewBox(variant)}
        />
    );
};
