import { GivelifyPaper, GivelifyButton, DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const Wrapper = styled(GivelifyPaper)(() => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
}));

export const Content = styled('div')(({ theme }) => ({
    margin: 32,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
        margin: '24px 16px',
    },
}));

export const Main = styled('div')(({ theme }) => ({
    flexGrow: 0,
    [theme.breakpoints.up('mobile')]: {
        flexBasis: 600,
    },
}));

export const Misc = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
    [theme.breakpoints.down('mobile')]: {
        marginTop: 8,
        marginBottom: 24,
    },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    marginBottom: -32,
    [theme.breakpoints.down('mobile')]: {
        marginBottom: 0,
    },
}));

export const Header = styled('div')(() => ({
    position: 'absolute',
    right: 16,
    top: 16,
}));

export const Button = styled(GivelifyButton)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: { width: '100%', marginTop: 24 },
}));

export const BannerLink = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
    '&:active': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
    },
    '&:visited': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
}));

export const ErrorBanner = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('mobile')]: {
        padding: '16px 32px',
    },
    padding: '16px 40px 16px 24px',
    backgroundColor: DesignTokens.color.globalWarning200,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
}));

export const List = styled('ul')(({ theme }) => ({
    marginTop: 0,
    paddingLeft: 20,
    marginBottom: 0,
    [theme.breakpoints.up('mobile')]: {
        marginBottom: 32,
    },
}));
