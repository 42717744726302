import { styled } from '@mui/material';
import { DesignTokens } from '../specify';

export const SliceWrapper = styled('div')({
    border: `1px solid ${DesignTokens.border.borderComponentDefault.color}`,
    borderRadius: '8px',
    '&:first-of-type': {
        borderRadius: '0 8px 8px 0',
        borderLeft: 'none',
    },
    '& + &': {
        borderRadius: '0 0 0 0',
        borderLeft: 'none',
    },
    '&.digit-number-prev': {
        borderRadius: '8px 0 0 8px',
        borderLeft: `1px solid ${DesignTokens.border.borderComponentDefault.color}`,
        '&.last': {
            borderRadius: '0',
            borderLeft: 'none',
        },
    },
    '&.digit-number-next': {
        borderRadius: '0 8px 8px 0',
        borderLeft: 'none',
    },
});

export const SliceInnerWrapper = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'offset' &&
        propName !== 'isRolling' &&
        propName !== 'delay',
})<{ offset: number; isRolling: boolean; delay: number }>(
    ({ offset, isRolling, delay }) => ({
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        marginTop: offset + 'rem',
        transition: isRolling ? `margin ${delay}s ease` : '',
    }),
);

export const SliceNumber = styled('div', {
    shouldForwardProp: (propName) =>
        propName !== 'width' && propName !== 'height',
})<{ width: number; height: number }>(({ theme, width, height }) => ({
    flex: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    fontSize: '58px',
    height: height + 'rem',
    width: width + 'rem',
    [theme.breakpoints.down('tablet')]: {
        fontSize: '56px',
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: '22px',
    },
}));

export const DigitRoll = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#000000',
    '& .digit-roll-wrapper': {
        display: 'flex',
        flexDirection: 'row-reverse',
        overflow: 'hidden',
    },
});

export const DigitRollDivider = styled('div', {
    shouldForwardProp: (propName) => propName !== 'height',
})<{ height?: number }>(({ theme, height }) => ({
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '58px',
    ...(height ? { height: height + 'rem' } : {}),
    '& + .digit-number-middle': {
        borderRadius: '0 8px 8px 0',
        borderLeft: 'none',
    },
    [theme.breakpoints.down('tablet')]: {
        fontSize: '56px',
        padding: '0 8px',
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: '30px',
    },
}));

export const Currency = styled(SliceNumber)({
    border: `1px solid ${DesignTokens.border.borderComponentDefault.color}`,
    borderRadius: '8px 0 0 8px',
    background: DesignTokens.color.globalNeutral200,
});
