import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>circle</title>
        <circle
            cx="12"
            cy="12"
            fill="current"
            r="9"
            stroke="current"
            strokeWidth="2"
        />
    </svg>
);

export const CircleIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgCircle}
        fontSize={fontSize}
    />
);
