import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgVerify = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            cx="12"
            cy="12"
            fill="#00AC31"
            r="9"
            stroke="#00AC31"
            strokeWidth="2"
        />
        <path
            d="M10.8865 15.25C10.7148 15.25 10.5431 15.189 10.4083 15.0659L8.221 13.0699C7.93912 12.8129 7.92514 12.3822 8.18912 12.1077C8.45309 11.8333 8.89548 11.8197 9.17736 12.0767L10.8658 13.6176L14.5011 9.54676C14.7713 9.27833 15.2142 9.27397 15.4899 9.53696C15.7657 9.79995 15.7701 10.2312 15.5 10.4996L11.3865 15.0458C11.2495 15.1819 11.0683 15.25 10.8865 15.25Z"
            fill="white"
        />
    </svg>
);

export const VerifyIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgVerify}
        fontSize={fontSize}
    />
);
