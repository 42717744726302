import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { useSubmittedByName } from '../hooks/useSubmittedByName';
import { VerificationCardVariantProps } from './model';
import { VerificationCard } from './verificationCard';

export const DirectDepositVerification: React.FC<
    VerificationCardVariantProps
> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const submittedByName = useSubmittedByName(
        props.doneeId,
        props.submittedBy,
    );

    const copy = React.useMemo(
        () => ({
            title: t('requiredInfo.bankVerification.title'),
            description: t('requiredInfo.bankVerification.description'),
            actionButtonText: t('requiredInfo.bankVerification.buttonText'),
            pendingDescription: t(
                'requiredInfo.bankVerification.pendingDescription',
            ),
            warningDescription: t(
                'requiredInfo.bankVerification.warningDescription',
            ),
            successDescription: t(
                'requiredInfo.bankVerification.successDescription',
            ),
            retryButtonText: t('requiredInfo.bankVerification.retryButtonText'),
        }),
        [t],
    );

    return (
        <VerificationCard
            actionButtonText={copy.actionButtonText}
            badgeVariant="bank-colored"
            buttonName={props.buttonName}
            description={copy.description}
            estimatedTime={3}
            onClick={props.onClick}
            pendingDescription={copy.pendingDescription}
            retryButtonText={copy.retryButtonText}
            state={props.state ? props.state : 'unchecked'}
            submittedAt={props.submittedAt}
            submittedBy={submittedByName}
            successDescription={copy.successDescription}
            testId="directDepositCard"
            title={copy.title}
            warningDescription={copy.warningDescription}
        />
    );
};
