import React from 'react';
import { TrackingProvider } from '@givelify/utils';
import OnboardingPage from '../../../OnboardingPage';
import { BankInfoSummaryProps } from './types';
import View from './view';

export const BankInfoSummary: React.FC<BankInfoSummaryProps> = ({
    addressIsSame,
    bankingAddress,
    data,
    bankName,
    doneeId,
    onAction,
    onCancelClick,
    onContinueClick,
    onBackClick,
}) => {
    return (
        <TrackingProvider trackPageVisit pageName="SetUpCheckingAcc Summary">
            <OnboardingPage
                content={
                    <View
                        addressIsSame={addressIsSame}
                        bankName={bankName}
                        bankingAddress={bankingAddress}
                        data={data}
                        doneeId={doneeId}
                        onAction={onAction}
                        onBackClick={onBackClick}
                        onCancelClick={onCancelClick}
                        onContinueClick={onContinueClick}
                    />
                }
                informationContent={null}
            />
        </TrackingProvider>
    );
};
