import { makeStyles, Theme } from '@material-ui/core';

export const usePageStyle = makeStyles((_: Theme) => ({
  labelContainer: {
    marginBottom: 24,
  },
  girdItemContainer: {
    marginBottom: 8,
  },
}));
