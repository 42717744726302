import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
    GivelifyMenuItem,
} from '@givelify/ui';
import { AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    FilterFooter,
    FilterList,
    FilterAccordion,
    FilterAccordionDetails,
    FilterMenuItem,
} from '../components/filterMenuItemStyles';
import {
    MESSAGE_FILTER_VALUE,
    MessageFilterProps,
    messageFilterOptions,
    toMessageStringText,
} from './types';

export const MessageFilterAccordion: React.FC<
    MessageFilterProps & {
        expanded: boolean;
        onExpandChange: (
            event: React.SyntheticEvent,
            expanded: boolean,
        ) => void;
    }
> = ({ onChange, values, expanded, onExpandChange }) => {
    const { t } = useTranslation();
    const options = React.useMemo(
        () =>
            messageFilterOptions.map((opt) => ({
                label: toMessageStringText(opt, t),
                id: opt,
            })),
        [t],
    );
    const copy = React.useMemo(
        () => ({
            messages: t('donationsActivity.messages.messages'),
            selectAllDonations: t('donationsActivity.text.selectAllDonations'),
            clearAll: t('donationsActivity.text.clearAll'),
            all: t('donationsActivity.text.all'),
        }),
        [t],
    );
    const toggleAll = React.useCallback(() => {
        if (values.length === 3) {
            onChange([]);
        } else {
            onChange([...messageFilterOptions]);
        }
    }, [onChange, values]);
    const clearAll = React.useCallback(() => {
        onChange([]);
    }, [onChange]);
    const onItemClick = React.useCallback(
        (id: MESSAGE_FILTER_VALUE) => {
            if (values.findIndex((item) => item === id) === -1) {
                onChange([...values, id]);
            } else {
                const newValues = values.filter((opt) => opt !== id);
                onChange(newValues);
            }
        },
        [onChange, values],
    );
    const getFilterText = React.useCallback(() => {
        if (values === undefined || values.length === 3) {
            return `${copy.messages}: ${copy.all}`;
        }
        return `${copy.messages}: ${values.length}`;
    }, [copy.all, copy.messages, values]);
    const onRenderMenuContent = React.useCallback(() => {
        return [
            <FilterList key="list" id="message-filter-options">
                <FilterMenuItem
                    key="all"
                    id="select-all"
                    indeterminate={values?.length > 0 && values?.length < 3}
                    onClick={toggleAll}
                    selected={values === undefined || values.length === 3}
                    text={copy.selectAllDonations}
                    variant="checkbox"
                />
                {options.map((opt) => (
                    <GivelifyMenuItem
                        key={opt.id}
                        id={`checkbox-${opt.label
                            .toLowerCase()
                            .replace(/\s/g, '-')}`}
                        onClick={() => onItemClick(opt.id)}
                        selected={
                            values === undefined || values.includes(opt.id)
                        }
                        text={opt.label}
                        variant="checkbox"
                    />
                ))}
            </FilterList>,
            <FilterFooter key="footer">
                <GivelifyLink
                    fontSize={16}
                    onClick={clearAll}
                    text={copy.clearAll}
                />
            </FilterFooter>,
        ];
    }, [
        copy.selectAllDonations,
        copy.clearAll,
        toggleAll,
        values,
        options,
        clearAll,
        onItemClick,
    ]);
    return (
        <FilterAccordion expanded={expanded} onChange={onExpandChange}>
            <AccordionSummary
                aria-controls="messages-filter-content"
                expandIcon={
                    <GivelifyIcon fontSize={24} variant="down-chevron" />
                }
                id="messages-filter"
            >
                <GivelifyLabel text={getFilterText()} />
            </AccordionSummary>
            <FilterAccordionDetails>
                {onRenderMenuContent()}
            </FilterAccordionDetails>
        </FilterAccordion>
    );
};
