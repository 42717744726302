export const initialIntegrationPaginatedResponse = {
    data: [],
    meta: {},
};

export interface IntegrationPaginatedResponse<T> {
    data: T[];
    meta: Partial<IntegrationPagination>;
}

export type IntegrationPagination = {
    total: number;
    count: number;
    perPage: string;
    currentPage: number;
    lastPage: number;
};
