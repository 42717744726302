import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

export const CardProfileStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'relative',
            minHeight: (props: { width: number }) =>
                props.width > 742 ? 320 : 429,
            width: (props: { width: number }) => props.width,
            backgroundColor: theme.colors?.primaryWhite,
            boxShadow: '0px 0px 8px rgba(131, 131, 131, 0.4)',
            borderRadius: 10,
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            '& .content': {
                display: 'flex',
                '& .misc': {
                    display: 'flex',
                    '& .image-holder': {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        '& .image': {
                            width: (props: { width: number }) =>
                                props.width >= 350 ? 318 : props.width - 32,
                        },
                    },
                },
                '& a:link': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
                '& a:hover': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.main,
                    textDecoration: 'underline',
                },
                '& a:active': {
                    color:
                        theme.colors?.accentDarkBlue ||
                        theme.palette.primary.dark,
                    textDecoration: 'underline',
                },
                '& a:visited': {
                    color: theme.colors?.primary || theme.palette.primary.main,
                    textDecoration: 'none',
                },
            } as CSSProperties,
            '& .content-lg': {
                flex: 1,
                padding: 16,
                paddingTop: 32,
                flexDirection: 'row',
                '& .main': {
                    maxWidth: 551,
                    marginTop: 0,
                    '& .description': {
                        marginTop: 24,
                    },
                    '& .complete': {
                        width: 'auto',
                        minWidth: 207,
                        marginTop: 66,
                    },
                },
                '& .misc': {
                    flex: 1,
                    minWidth: 318,
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .image-holder': {
                        position: 'absolute',
                        bottom: 0,
                    },
                },
            } as CSSProperties,
            '& .content-xs': {
                padding: 0,
                flexDirection: 'column-reverse',
                '& .main': {
                    maxWidth: 'initial',
                    marginTop: 32,
                    '& .description': {
                        marginTop: 24,
                        marginBottom: 24,
                    },
                    '& .complete': {
                        width: '100%',
                    },
                },
                '& .misc': {
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    minWidth: 'initial',
                    '& .image-holder': {
                        position: 'initial',
                    },
                },
            } as CSSProperties,
        },
        '@keyframes dismiss': {
            from: {
                transform: 'scaleY(1)',
            },
            to: {
                transform: 'scaleY(0)',
            },
        },
        cardDismissal: {
            animationName: '$dismiss',
            animationDuration: '250ms',
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
            transformOrigin: 'center center',
        },
    }),
);
