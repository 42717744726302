import React from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { MODAL_NAME, useTrackingContext } from '@givelify/utils';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OverviewPaper from '../components/OverviewPaper';
import EnvelopesStatusChart from './EnvelopesStatus';
import GivingStyleChart from './GivingStyle';
import GivingStyleHelpModal from './GivingStyleHelp';

const useStyles = makeStyles(() => ({
    icon: {
        cursor: 'pointer',
    },
}));

const PieChartsSectionGrid: React.FC = () => {
    const { t } = useTranslation();
    const { icon } = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const { trackOpenCloseEvent } = useTrackingContext();

    const copy = {
        donorsStatusTitle: t('dashboard.donorStatus.title'),
        envelopesStatusTitle: t('dashboard.donorsGivingTo.title'),
    };

    const handleInfoIconClick = () => {
        trackOpenCloseEvent(false, MODAL_NAME.GivingStyleHelp);
        setIsOpen(true);
    };

    const handleModalClose = () => {
        trackOpenCloseEvent(false, MODAL_NAME.GivingStyleHelp);
        setIsOpen(false);
    };

    return (
        <>
            <GivingStyleHelpModal onClose={handleModalClose} open={isOpen} />
            <OverviewPaper title={copy.envelopesStatusTitle}>
                <EnvelopesStatusChart />
            </OverviewPaper>
            <OverviewPaper
                icon={
                    <GivelifyIcon
                        className={icon}
                        color="primary"
                        onClick={handleInfoIconClick}
                        size={20}
                        variant="info-circle"
                    />
                }
                title={copy.donorsStatusTitle}
            >
                <GivingStyleChart />
            </OverviewPaper>
        </>
    );
};

export default PieChartsSectionGrid;
