import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getIntegrationDonorsApiEndpoint,
    getIntegrationDonorOptionsApiEndpoint,
} from 'api/client/endpoints';
import { IIntegrationService } from '../interfaces';
import { CreateF1GoDonorProfileRequest } from '../requests';
import { GetF1GoDonorOptionsResponse } from '../responses';

export class IntegrationService implements IIntegrationService {
    public async postF1GoDonorProfile(
        donorId: number,
        data: CreateF1GoDonorProfileRequest,
    ): Promise<ApiResponse<unknown>> {
        const url = getIntegrationDonorsApiEndpoint(donorId);
        const httpRequest = getAxiosClient().post(url, data);
        const result = await makeApiRequest<unknown>(httpRequest);
        return result;
    }

    public async getF1GoProfileOptions(
        donorId: number,
    ): Promise<ApiResponse<GetF1GoDonorOptionsResponse>> {
        const url = getIntegrationDonorOptionsApiEndpoint(donorId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetF1GoDonorOptionsResponse>(
            httpRequest,
        );
        return result;
    }
}
