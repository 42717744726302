import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgEarthGlobeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse
            cx="12"
            cy="12.3359"
            rx="4"
            ry="9"
            stroke="#6A6873"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <path
            d="M20.5 9.33594H3.5"
            stroke="#6A6873"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <rect
            height="18"
            rx="8"
            stroke="#6A6873"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            width="18"
            x="3"
            y="3.33594"
        />
        <path
            d="M20.5 15.3359H3.5"
            stroke="#6A6873"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
    </svg>
);

export const EarthGlobeIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgEarthGlobeIcon}
        fontSize={fontSize}
    />
);
