import React from 'react';
import { GivelifyTextField } from '@givelify/ui';
import { INPUT_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import { FilterPanelInputWrapper } from './styles';

type FilterInputProps<T> = {
    value: string;
    label: string;
    name: keyof T;
    id: string;
    error?: string;
    onChange?: (name: keyof T, value: string) => void;
    maxLength: number;
};

export const FilterInput = <T,>({
    value,
    label,
    name,
    id,
    error,
    onChange,
    maxLength,
}: FilterInputProps<T>) => {
    const [innerValue, setInnerValue] = React.useState(value ? value : '');
    const theme = useTheme();
    const { trackEvent } = useTrackingContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [isExtended, setIsExtended] = React.useState(
        innerValue?.length >= (isMobile ? 11 : 6),
    );
    const [isPasted, setIsPasted] = React.useState(false);
    React.useEffect(() => {
        setIsExtended(innerValue?.length >= (isMobile ? 11 : 6));
        if (innerValue.length <= maxLength) {
            setIsPasted(false);
        }
    }, [innerValue, isMobile, maxLength]);
    React.useEffect(() => {
        setInnerValue(value ? value : '');
    }, [value, setInnerValue]);

    const blurHandler = React.useCallback(() => {
        if (onChange) {
            onChange(name, innerValue);
        }
    }, [onChange, innerValue, name]);

    const pasteHandler = React.useCallback((e) => {
        if (/^[0-9]+$/.test(e.clipboardData.getData('text/plain'))) {
            setIsPasted(true);
        } else {
            e.preventDefault();
        }
    }, []);

    const focusHandler = React.useCallback(() => {
        trackEvent(`<${name as string}>_${INPUT_IDENTIFIER}`);
    }, [name, trackEvent]);

    const clearHandler = React.useCallback(() => {
        setInnerValue('');
        if (onChange) {
            onChange(name, '');
        }
    }, [onChange, name, setInnerValue]);

    const onChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const val = e.target.value;
            if (!val.includes('.')) {
                setInnerValue(val);
                if (onChange && e) {
                    onChange(name, val);
                }
            }
        },
        [onChange, name, setInnerValue],
    );
    return (
        <FilterPanelInputWrapper
            className={isExtended ? 'extended' : ''}
            data-testid={`${id}-wrapper`}
        >
            <GivelifyTextField
                clearable
                darkMode
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                    ),
                }}
                id={id}
                inputProps={{
                    'data-testid': id,
                }}
                label={label}
                leftHelperText={error}
                maskOptions={{
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: ',',
                    padFractionalZeros: false,
                    radix: '.',
                    normalizeZeros: true,
                    ...(!isPasted && { maxLength }),
                }}
                name={name.toString()}
                onBlur={blurHandler}
                onChange={onChangeHandler}
                onClearClick={clearHandler}
                onFocus={focusHandler}
                onPaste={pasteHandler}
                size="medium"
                state={error ? 'error' : 'idle'}
                value={innerValue}
            />
        </FilterPanelInputWrapper>
    );
};
