import React from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import { DONOR_PROFILE_PAGE_CLICK_EDIT_MEMBER_ID_LINK } from 'utils/clevertapEvents';
import { States } from '.';

interface NotFocusedProps {
    setState: React.Dispatch<React.SetStateAction<States>>;
    label: string;
    value: string;
}
export const NotFocused = ({ setState, label, value }: NotFocusedProps) => (
    <GivelifyButton
        disableRipple
        marginLeft={-8}
        name={DONOR_PROFILE_PAGE_CLICK_EDIT_MEMBER_ID_LINK}
        onClick={() => setState(States.FOCUSED)}
        text={value?.length > 0 ? value : label}
        variant="ghost"
    />
);
