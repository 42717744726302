import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const receiptPreviewStyle = makeStyles((theme: Theme) => {
    return createStyles({
        mobilePreviewHead: {
            position: 'absolute',
            top: 16,
        },
        mobilePreviewContent: {
            backgroundColor: '#F9FAFC',
        },
        mobilePreviewDetails: {
            backgroundColor: '#F9FAFC',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            '& .white': {
                backgroundColor: theme.gas.palette.background.primary,
            },
            '& .inner': {
                paddingTop: 48,
                paddingLeft: 20,
                paddingRight: 20,
            },
        },
        mobilePreviewTabBar: {
            boxSizing: 'border-box',
            height: 60,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            position: 'absolute',
            bottom: 1,
            background: '#FFFFFF',
            paddingTop: 7.25,
            paddingBottom: 6,
            marginTop: 'auto',
        },
        mobilePreviewDoneeText: {
            color: '#3A4C63',
            lineHeight: '19px',
            fontWeight: 500,
            textAlign: 'center',
            marginTop: 32,
        },
        mobilePreviewDateText: {
            color: '#85919F',
            lineHeight: '19px',
            textAlign: 'center',
            marginTop: 4,
            marginBottom: 24,
        },
        mobilePreviewDetailsRow: {
            display: 'flex',
            marginTop: 6,
            marginBottom: 6,
        },
        mobilePreviewDetailsRowLined: {
            display: 'flex',
            borderTop: '0.5px solid #EEEDF3',
            height: 44,
            boxSizing: 'border-box',
            alignItems: 'center',
        },
        mobilePreviewDetailsRowText: {
            lineHeight: '20px',
            color: '#85919F',
        },
        mobilePreviewDetailsRowValue: {
            lineHeight: '20px',
            color: '#3A4C63',
            marginLeft: 'auto',
            textAlign: 'right',
        },
        mobilePreviewTitleBar: {
            height: 45,
            display: 'flex',
            alignItems: 'center',
        },
        mobilePreviewAmmount: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 45,
            marginBottom: 12,
        },
        mobilePreviewTitleText: {
            textAlign: 'center',
        },
        mobilePreviewBackArrow: {
            position: 'absolute',
            left: 16,
        },
        mobilePreviewDetails2: {
            boxSizing: 'border-box',
            height: 84,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 12,
            position: 'relative',
            backgroundColor: theme.gas.palette.background.primary,
        },
        mobilePreviewDetailsRow2Value: {
            lineHeight: '18px',
            color: '#00ACC4',
            marginLeft: 'auto',
            textAlign: 'right',
        },
        mobilePreviewDetails3: {
            boxSizing: 'border-box',
            height: 76,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 16,
            paddingBottom: 16,
            marginTop: 12,
            position: 'relative',
            backgroundColor: theme.gas.palette.background.primary,
        },
        mobilePreviewDetailsRowCard: {
            display: 'flex',
            marginLeft: 'auto',
            alignItems: 'center',
        },
        mobilePreviewVisa: {
            width: 37,
            height: 13,
            marginRight: 8,
        },
        mobilePreviewTabBarIconNormal: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: '#95939E',
            height: 47.75,
            '& .text': {
                marginTop: 'auto',

                fontSize: 11,
                lineHeight: '13.2px',
                fontWeight: 700,
            },
        },
        mobilePreviewTabBarIconSelected: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: '#F85520',
            height: 47.75,
            '& .text': {
                marginTop: 'auto',

                fontSize: 11,
                lineHeight: '13.2px',
                fontWeight: 700,
            },
        },
        mobilePreviewHidden: {
            display: 'none',
        },
    });
});
