import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    formContainer: {
        '& .headingTitle': {
            fontSize: '24px',
            marginTop: '16px',
            marginBottom: '22px',
            fontWeight: 600,
            lineHeight: '28.8px',
        },
        '& .allFieldText': {
            color: theme.colors.neutralGrey,
            fontSize: 12,
            lineHeight: '20px',
            marginBottom: 8,
        },
        '& .contactAccountOwner': {
            fontSize: 14,
            lineHeight: '22px',
            fontWeight: 400,
            marginBottom: 26,
            marginTop: -12,
        },
    },
    modalFooter: {
        [theme.breakpoints.down('xs')]: {
            height: '168px !important',

            '& #submit-footer': {
                flexDirection: 'column-reverse',
                gap: 16,

                '& button': {
                    margin: 0,
                    height: 48,
                },
            },
        },
    },
    textError: {
        color: GivelifyColorPalette.error,
    },
    descriptionCard: {
        overflow: 'hidden',
        position: 'relative',
        height: 'fit-content',
        width: '100%',
        border: '1px solid #DDD',
        borderRadius: '10px 10px 10px 10px',
        paddingLeft: '26px',
        paddingRight: '26px',
        paddingTop: '56px',
        paddingBottom: '40px',

        '&::after': {
            position: 'absolute',
            content: '""',
            left: 0,
            right: 0,
            top: 0,
            height: '8px',
            width: '100%',
            backgroundColor: '#6D5FF6',
        },

        [theme.breakpoints.down('md')]: {
            marginTop: '26px',
            marginBottom: '34px',

            maxWidth: '380px',
        },

        [theme.breakpoints.up('md')]: {
            marginTop: '70px',
            marginBottom: 0,

            maxWidth: '300px',
        },
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        gap: 32,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: 16,
        },
    },
    secureIcon: {
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    btnDetail: {
        minWidth: 115,
        marginTop: 24,
    },
    widthFull: {
        width: '100%',
    },
    marginTop45: {
        marginTop: '45px',
    },
    marginTop15: {
        marginTop: '15px',
    },
    marginBottom16: {
        marginBottom: 16,
    },
    firstNameBlock: {
        paddingRight: 8,
    },
    lastNameBlock: {
        paddingLeft: 8,
    },
    detailRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
        color: theme.colors.primaryDarkGray,
    },
    permissionCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContent: {
        width: '100%',
        maxWidth: 336,
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
        borderRadius: '10px',
        cursor: 'pointer',
        border: '1px solid transparent',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 32,
    },
    cardFooter: {
        display: 'flex',
        justifyContent: 'center',
        background: theme.colors.neutralHoverGrey,
        borderTop: '1px solid #D8D8D8',
        borderRadius: '0px 0px 10px 10px',
    },
    radioButton: {
        padding: '5.5px 0px',
    },
    radioButtonSelected: {
        padding: '4.5px 0px',
    },
    checkIcon: {
        marginRight: 12,
    },
    titleSelect: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            background: '#FFFFFF',
            padding: '0px 8px',
        },
    },
    titleSelectHelperText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 26,
    },
}));
