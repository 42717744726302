import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
    GivelifyColor,
    GivelifyColorOrString,
} from '../style/givelifyDefaultTheme';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { FontWeightProperty } from '@material-ui/styles/node_modules/csstype';

export const GivelifyButtonRippleStyles = makeStyles((_theme: Theme) =>
    createStyles({
        '@keyframes ripple': {
            to: {
                opacity: 0,
                transform: 'scale(2)',
            },
        },
        btnRipple: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            '& .ripple': {
                transform: 'scale(0)',
                borderRadius: '100%',
                position: 'absolute',
                opacity: '0.75',
                animationName: '$ripple',
                animationDuration: '400ms',
                animationIterationCount: 1,
                animationTimingFunction: 'linear',
                animationFillMode: 'forwards',
            },
        },
    }),
);

export const GivelifyButtonBaseStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnRoot: {
            outline: 'none',
            cursor: 'pointer',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
            minWidth: 160,
            paddingLeft: 24,
            paddingRight: 24,
            [theme.breakpoints.down('xs')]: {
                minWidth: 163,
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        btnText: {
            fontFamily: theme.gas.font,
            fontWeight: 800,
            textTransform: 'none',
        },
        btnTextLarge: { fontSize: 16, lineHeight: '20px' },
        btnTextSmall: { fontSize: 16, lineHeight: '20px' },
        btnSmall: {
            height: 36,
            borderRadius: 32,
        },
        btnXLarge: {
            height: 52,
            borderRadius: 32,
        },
        btnLarge: {
            height: 42,
            borderRadius: 32,
        },
        btnIconLarge: {
            fontSize: 16,
            lineHeight: '18px',
            marginRight: 8,
        },
        btnIconSmall: {
            fontSize: 15,
            lineHeight: '22px',
        },
        btnStartIcon: {
            marginRight: 8,
            paddingBottom: 2,
        },
        btnEndIcon: {
            marginLeft: 8,
            paddingBottom: 2,
        },
        btnPrimary: {
            borderStyle: 'none',
            backgroundColor: theme.gas.palette.button.primary.idle.background,
            color: theme.gas.palette.button.primary.idle.text,
            borderColor: theme.gas.palette.button.primary.idle.border,
            '&:hover': {
                backgroundColor:
                    theme.gas.palette.button.primary.hover.background,
                color: theme.gas.palette.button.primary.hover.text,
                borderColor: theme.gas.palette.button.primary.hover.border,
            },
            '&:disabled': {
                backgroundColor:
                    theme.gas.palette.button.primary.disabled.background,
                color: theme.gas.palette.button.primary.disabled.text,
                borderColor: theme.gas.palette.button.primary.disabled.border,
            },
            '&:active': {
                backgroundColor:
                    theme.gas.palette.button.primary.active.background,
                color: theme.gas.palette.button.primary.active.text,
                borderColor: theme.gas.palette.button.primary.active.border,
            },
        },
        btnPrimaryAlt: {
            borderStyle: 'none',
            backgroundColor: theme.gas.palette.button.alt.idle.background,
            color: theme.gas.palette.button.alt.idle.text,
            borderColor: theme.gas.palette.button.alt.idle.border,
            '&:hover': {
                backgroundColor: theme.gas.palette.button.alt.hover.background,
                color: theme.gas.palette.button.alt.hover.text,
                borderColor: theme.gas.palette.button.alt.hover.border,
            },
            '&:disabled': {
                backgroundColor:
                    theme.gas.palette.button.alt.disabled.background,
                color: theme.gas.palette.button.alt.disabled.text,
                borderColor: theme.gas.palette.button.alt.disabled.border,
            },
            '&:active': {
                backgroundColor: theme.gas.palette.button.alt.active.background,
                color: theme.gas.palette.button.alt.active.text,
                borderColor: theme.gas.palette.button.alt.active.border,
            },
        },
        btnPrimaryLoading: {
            '&:disabled': {
                borderStyle: 'none',
                borderColor: theme.gas.palette.button.primary.idle.border,
                backgroundColor:
                    theme.gas.palette.button.primary.idle.background,
                color: theme.gas.palette.button.primary.idle.text,
            },
        },
        btnPrimaryAltLoading: {
            '&:disabled': {
                borderStyle: 'none',
                borderColor: theme.gas.palette.button.alt.idle.border,
                backgroundColor: theme.gas.palette.button.alt.idle.background,
                color: theme.gas.palette.button.alt.idle.text,
            },
        },
        btnSecondary: {
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor: theme.gas.palette.button.secondary.idle.background,
            color: theme.gas.palette.button.secondary.idle.text,
            borderColor: theme.gas.palette.button.secondary.idle.border,
            '&:hover': {
                backgroundColor:
                    theme.gas.palette.button.secondary.hover.background,
                color: theme.gas.palette.button.secondary.hover.text,
                borderColor: theme.gas.palette.button.secondary.hover.border,
            },
            '&:disabled': {
                backgroundColor:
                    theme.gas.palette.button.secondary.disabled.background,
                color: theme.gas.palette.button.secondary.disabled.text,
                borderColor: theme.gas.palette.button.secondary.disabled.border,
            },
            '&:active': {
                backgroundColor:
                    theme.gas.palette.button.secondary.active.background,
                color: theme.gas.palette.button.secondary.active.text,
                borderColor: theme.gas.palette.button.secondary.active.border,
            },
        },
        btnSecondaryLoading: {
            '&:disabled': {
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: theme.gas.palette.button.secondary.idle.border,
                backgroundColor:
                    theme.gas.palette.button.secondary.idle.background,
                color: theme.gas.palette.button.secondary.idle.text,
            },
        },
        btnSecondaryAlt: {
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor:
                theme.gas.palette.button.altSecondary.idle.background,
            color: theme.gas.palette.button.altSecondary.idle.text,
            borderColor: theme.gas.palette.button.altSecondary.idle.border,
            '&:hover': {
                backgroundColor:
                    theme.gas.palette.button.altSecondary.hover.background,
                color: theme.gas.palette.button.altSecondary.hover.text,
                borderColor: theme.gas.palette.button.altSecondary.hover.border,
            },
            '&:disabled': {
                backgroundColor:
                    theme.gas.palette.button.altSecondary.disabled.background,
                color: theme.gas.palette.button.altSecondary.disabled.text,
                borderColor:
                    theme.gas.palette.button.altSecondary.disabled.border,
            },
            '&:focus': {
                backgroundColor:
                    theme.gas.palette.button.altSecondary.active.background,
                color: theme.gas.palette.button.altSecondary.active.text,
                borderColor:
                    theme.gas.palette.button.altSecondary.active.border,
            },
        },
        btnSecondaryAltLoading: {
            '&:disabled': {
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: theme.gas.palette.button.altSecondary.idle.border,
                backgroundColor:
                    theme.gas.palette.button.altSecondary.idle.background,
                color: theme.gas.palette.button.altSecondary.idle.text,
            },
        },
        btnDanger: {
            paddingLeft: 24,
            paddingRight: 24,
            borderStyle: 'none',
            backgroundColor: theme.gas.palette.button.danger.idle.background,
            color: theme.gas.palette.button.danger.idle.text,
            borderColor: theme.gas.palette.button.danger.idle.border,
            '&:hover': {
                backgroundColor:
                    theme.gas.palette.button.danger.hover.background,
                color: theme.gas.palette.button.danger.hover.text,
                borderColor: theme.gas.palette.button.danger.hover.border,
            },
            '&:disabled': {
                backgroundColor:
                    theme.gas.palette.button.danger.disabled.background,
                color: theme.gas.palette.button.danger.disabled.text,
                borderColor: theme.gas.palette.button.danger.disabled.border,
            },
            '&:focus': {
                backgroundColor:
                    theme.gas.palette.button.danger.active.background,
                color: theme.gas.palette.button.danger.active.text,
                borderColor: theme.gas.palette.button.danger.active.border,
            },
        },
        btnDangerSecondary: {
            paddingLeft: 24,
            paddingRight: 24,
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor:
                theme.gas.palette.button.dangerSecondary.idle.background,
            color: theme.gas.palette.button.dangerSecondary.idle.text,
            borderColor: theme.gas.palette.button.dangerSecondary.idle.border,
            '&:hover': {
                backgroundColor:
                    theme.gas.palette.button.dangerSecondary.hover.background,
                color: theme.gas.palette.button.dangerSecondary.hover.text,
                borderColor:
                    theme.gas.palette.button.dangerSecondary.hover.border,
            },
            '&:disabled': {
                backgroundColor:
                    theme.gas.palette.button.dangerSecondary.disabled
                        .background,
                color: theme.gas.palette.button.dangerSecondary.disabled.text,
                borderColor:
                    theme.gas.palette.button.dangerSecondary.disabled.border,
            },
            '&:focus': {
                backgroundColor:
                    theme.gas.palette.button.dangerSecondary.active.background,
                color: theme.gas.palette.button.dangerSecondary.active.text,
                borderColor:
                    theme.gas.palette.button.dangerSecondary.active.border,
            },
        },
        btnDangerLoading: {
            '&:disabled': {
                borderStyle: 'none',
                backgroundColor:
                    theme.gas.palette.button.dangerSecondary.idle.background,
                color: theme.gas.palette.button.dangerSecondary.idle.text,
            },
        },
        btnGhost: {
            fontSize: 16,
            lineHeight: '20px',
            borderStyle: 'none',
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 32,
            minWidth: 0,
            minHeight: 0,
            width: 'auto',
            height: 'auto',
            backgroundColor: 'initial',
            color: theme.gas.palette.button.secondary.idle.text,
            '&:hover': {
                color: theme.gas.palette.button.secondary.hover.text,
            },
            '&:active': {
                color: theme.gas.palette.button.secondary.active.text,
            },
            '&:disabled': {
                borderStyle: 'none',
                backgroundColor: 'initial',
                color: theme.gas.palette.button.secondary.disabled.text,
            },
        },
        btnGhostAlt: {
            fontSize: 16,
            lineHeight: '20px',
            borderStyle: 'none',
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 32,
            minWidth: 0,
            minHeight: 0,
            width: 'auto',
            height: 'auto',
            backgroundColor: 'initial',
            color: theme.gas.palette.button.altSecondary.idle.text,
            '&:hover': {
                color: theme.gas.palette.button.altSecondary.hover.text,
            },
            '&:active': {
                color: theme.gas.palette.button.altSecondary.active.text,
            },
            '&:disabled': {
                borderStyle: 'none',
                backgroundColor: 'initial',
                color: theme.gas.palette.button.altSecondary.disabled.text,
            },
        },
        btnIconText: {
            fontSize: 16,
            lineHeight: '20px',
            borderStyle: 'none',
            minWidth: 0,
            minHeight: 0,
            width: 'auto',
            height: 'auto',
            backgroundColor: 'initial',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors
                ? theme.colors.primary
                : theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.colors
                    ? theme.colors.neutralHoverGrey
                    : theme.palette.action.hover,
            },
            '&:disabled': {
                borderStyle: 'none',
                backgroundColor: 'initial',
                color: theme.colors
                    ? theme.colors.neutralGrey75
                    : theme.palette.action.disabled,
            },
            padding: '8px 6px 6px 8px',
            borderRadius: 40,
        },
        btnIconOnlyRoot: {
            fontSize: 24,
            lineHeight: '24px',
            borderStyle: 'none',
            minWidth: 0,
            minHeight: 0,
            width: 'auto',
            height: 'auto',
            backgroundColor: 'initial',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors
                ? theme.colors.neutralGrey
                : theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: theme.colors
                    ? theme.colors.neutralHoverGrey
                    : theme.palette.action.hover,
            },
            '&:disabled': {
                borderStyle: 'none',
                backgroundColor: 'initial',
                color: theme.colors
                    ? theme.colors.neutralGrey75
                    : theme.palette.action.disabled,
            },
            padding: 8,
            borderRadius: '50%',
        },
        btnIcon: {
            fontSize: 16,
            lineHeight: '24px',
            marginRight: 4,
            paddingBottom: 2,
        },
        btnIconOnly: {
            fontSize: 24,
            lineHeight: '24px',
        },
        btnPrimaryIconRoot: {
            padding: 8,
            minWidth: 42,
            minHeight: 42,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
        },
        btnPrimaryIconOnly: {
            fontSize: 16,
            lineHeight: '22px',
        },
        btnLink: {
            fontSize: 14,
            lineHeight: '16px',
            borderStyle: 'none',
            padding: 0,
            minWidth: 0,
            minHeight: 0,
            width: 'auto',
            height: 'auto',
            backgroundColor: 'initial',
            color: theme.gas.palette.button.secondary.idle.text,
            '&:hover': {
                textDecoration: 'underline',
                color: theme.gas.palette.button.secondary.hover.text,
            },
            '&:active': {
                textDecoration: 'underline',
                color: theme.gas.palette.button.secondary.active.text,
            },
            '&:disabled': {
                textDecoration: 'none',
                borderStyle: 'none',
                backgroundColor: 'initial',
                color: theme.gas.palette.button.secondary.disabled.text,
            },
        },
        btnLinkAlt: {
            fontSize: 14,
            lineHeight: '18px',
            borderStyle: 'none',
            padding: 0,
            minWidth: 0,
            minHeight: 0,
            width: 'auto',
            height: 'auto',
            backgroundColor: 'initial',
            color: theme.gas.palette.button.altSecondary.idle.text,
            '&:hover': {
                textDecoration: 'underline',
                color: theme.gas.palette.button.altSecondary.hover.text,
            },
            '&:active': {
                textDecoration: 'underline',
                color: theme.gas.palette.button.altSecondary.active.text,
            },
            '&:disabled': {
                textDecoration: 'none',
                borderStyle: 'none',
                backgroundColor: 'initial',
                color: theme.gas.palette.button.altSecondary.disabled.text,
            },
            '&:focus': {
                textDecoration: 'underline',
                color: theme.gas.palette.button.altSecondary.idle.text,
            },
        },
        btnShrink: {
            minWidth: 0,
            minHeight: 0,
            padding: 0,
            borderRadius: 60,
        },
        btnDense: {
            minWidth: 0,
            minHeight: 0,
            padding: 3.5,
            borderRadius: 60,
        },
        btnAuto: {
            minWidth: 0,
            borderRadius: 60,
        },
    }),
);

export type GvlButtonState = {
    backgroundColor?: GivelifyColorOrString;
    color?: GivelifyColorOrString;
    borderStyle?:
        | 'none'
        | 'hidden'
        | 'dotted'
        | 'dashed'
        | 'solid'
        | 'double'
        | 'groove'
        | 'ridge'
        | 'inset'
        | 'outset'
        | 'initial'
        | 'inherit';
    borderWidth?: number;
    borderColor?: GivelifyColorOrString;
    padding?: number;
};

type GivelifyButtonVariableStyleProps = {
    width: 'auto' | '100%' | number;
    minWidth?: 'auto' | '100%' | 'initial' | number;
    minHeight?: 'auto' | '100%' | 'initial' | number;
    height?: number;
    fontSize?: number;
    lineHeight?: number;
    wrap?: boolean;
    size?: 'xLarge' | 'large' | 'small' | 'auto' | 'shrink' | 'dense';
    normalStyle?: GvlButtonState;
    hoverStyle?: GvlButtonState;
    activeStyle?: GvlButtonState;
    disabledStyle?: GvlButtonState;
    focusStyle?: GvlButtonState;
    color?: GivelifyColorOrString;
    padding?: number;
} & GivelifyBoxMarginProps;

export const GivelifyButtonVariableStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyButtonVariableStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyButtonVariableStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyButtonVariableStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyButtonVariableStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        btnTextBold: {
            fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        },
        btnTextCustom: {
            fontSize: (props: GivelifyButtonVariableStyleProps) =>
                props.fontSize ? props.fontSize : undefined,
            lineHeight: (props: GivelifyButtonVariableStyleProps) =>
                props.lineHeight ? `${props.lineHeight}px` : undefined,
            color: (props: GivelifyButtonVariableStyleProps) => {
                if (!props.color) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.color as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.color;
            },
            padding: (props: GivelifyButtonVariableStyleProps) => props.padding,
        },
        btnDimensions: {
            width: (props: GivelifyButtonVariableStyleProps) =>
                props.width === 'auto'
                    ? props.wrap
                        ? props.size === 'small'
                            ? 144
                            : props.size === 'large'
                            ? 207
                            : props.size === 'xLarge'
                            ? 207
                            : 'auto'
                        : 'auto'
                    : props.width === '100%'
                    ? '100%'
                    : props.width,
            minWidth: (props: GivelifyButtonVariableStyleProps) =>
                props.width !== 'auto' && props.width !== '100%'
                    ? 0
                    : props.minWidth,
            height: (props: GivelifyButtonVariableStyleProps) =>
                props.height ? props.height : props.wrap ? 'auto' : undefined,
            minHeight: (props: GivelifyButtonVariableStyleProps) =>
                props.height
                    ? 0
                    : props.minHeight
                    ? props.minHeight
                    : undefined,
        },
        btnNormalState: {
            color: (props: GivelifyButtonVariableStyleProps) => {
                if (!props.normalStyle) return undefined;
                if (!props.normalStyle.color) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.normalStyle
                        .color as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.normalStyle.color;
            },
            backgroundColor: (props: GivelifyButtonVariableStyleProps) => {
                if (!props.normalStyle) return undefined;
                if (!props.normalStyle.backgroundColor) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.normalStyle
                        .backgroundColor as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.normalStyle.backgroundColor;
            },
            borderWidth: (props: GivelifyButtonVariableStyleProps) =>
                props.normalStyle ? props.normalStyle.borderWidth : undefined,
            borderStyle: (props: GivelifyButtonVariableStyleProps) =>
                props.normalStyle ? props.normalStyle.borderStyle : undefined,
            borderColor: (props: GivelifyButtonVariableStyleProps) => {
                if (!props.normalStyle) return undefined;
                if (!props.normalStyle.borderColor) return undefined;
                if (theme.colors) {
                    const cKey: GivelifyColor = props.normalStyle
                        .borderColor as GivelifyColor;
                    const color = theme.colors[cKey];
                    if (color) return color;
                }
                return props.normalStyle.borderColor;
            },
            padding: (props: GivelifyButtonVariableStyleProps) =>
                props.normalStyle ? props.normalStyle.padding : undefined,
        },
        btnHoverState: {
            '&:hover': {
                color: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.hoverStyle) return undefined;
                    if (!props.hoverStyle.color) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.hoverStyle
                            .color as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.hoverStyle.color;
                },
                backgroundColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.hoverStyle) return undefined;
                    if (!props.hoverStyle.backgroundColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.hoverStyle
                            .backgroundColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.hoverStyle.backgroundColor;
                },
                borderWidth: (props: GivelifyButtonVariableStyleProps) =>
                    props.hoverStyle ? props.hoverStyle.borderWidth : undefined,
                borderStyle: (props: GivelifyButtonVariableStyleProps) =>
                    props.hoverStyle ? props.hoverStyle.borderStyle : undefined,
                borderColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.hoverStyle) return undefined;
                    if (!props.hoverStyle.borderColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.hoverStyle
                            .borderColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.hoverStyle.borderColor;
                },
                padding: (props: GivelifyButtonVariableStyleProps) =>
                    props.hoverStyle ? props.hoverStyle.padding : undefined,
            },
        },
        btnActiveState: {
            '&:active': {
                color: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.activeStyle) return undefined;
                    if (!props.activeStyle.color) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.activeStyle
                            .color as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.activeStyle.color;
                },
                backgroundColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.activeStyle) return undefined;
                    if (!props.activeStyle.backgroundColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.activeStyle
                            .backgroundColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.activeStyle.backgroundColor;
                },
                borderWidth: (props: GivelifyButtonVariableStyleProps) =>
                    props.activeStyle
                        ? props.activeStyle.borderWidth
                        : undefined,
                borderStyle: (props: GivelifyButtonVariableStyleProps) =>
                    props.activeStyle
                        ? props.activeStyle.borderStyle
                        : undefined,
                borderColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.activeStyle) return undefined;
                    if (!props.activeStyle.borderColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.activeStyle
                            .borderColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.activeStyle.borderColor;
                },
                padding: (props: GivelifyButtonVariableStyleProps) =>
                    props.activeStyle ? props.activeStyle.padding : undefined,
            },
        },
        btnDisabledState: {
            '&:disabled': {
                color: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.disabledStyle) return undefined;
                    if (!props.disabledStyle.color) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.disabledStyle
                            .color as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.disabledStyle.color;
                },
                backgroundColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.disabledStyle) return undefined;
                    if (!props.disabledStyle.backgroundColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.disabledStyle
                            .backgroundColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.disabledStyle.backgroundColor;
                },
                borderWidth: (props: GivelifyButtonVariableStyleProps) =>
                    props.disabledStyle
                        ? props.disabledStyle.borderWidth
                        : undefined,
                borderStyle: (props: GivelifyButtonVariableStyleProps) =>
                    props.disabledStyle
                        ? props.disabledStyle.borderStyle
                        : undefined,
                borderColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.disabledStyle) return undefined;
                    if (!props.disabledStyle.borderColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.disabledStyle
                            .borderColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.disabledStyle.borderColor;
                },
                padding: (props: GivelifyButtonVariableStyleProps) =>
                    props.disabledStyle
                        ? props.disabledStyle.padding
                        : undefined,
            },
        },
        btnFocusState: {
            '&:focus': {
                color: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.focusStyle) return undefined;
                    if (!props.focusStyle.color) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.focusStyle
                            .color as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.focusStyle.color;
                },
                backgroundColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.focusStyle) return undefined;
                    if (!props.focusStyle.backgroundColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.focusStyle
                            .backgroundColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.focusStyle.backgroundColor;
                },
                borderWidth: (props: GivelifyButtonVariableStyleProps) =>
                    props.focusStyle ? props.focusStyle.borderWidth : undefined,
                borderStyle: (props: GivelifyButtonVariableStyleProps) =>
                    props.focusStyle ? props.focusStyle.borderStyle : undefined,
                borderColor: (props: GivelifyButtonVariableStyleProps) => {
                    if (!props.focusStyle) return undefined;
                    if (!props.focusStyle.borderColor) return undefined;
                    if (theme.colors) {
                        const cKey: GivelifyColor = props.focusStyle
                            .borderColor as GivelifyColor;
                        const color = theme.colors[cKey];
                        if (color) return color;
                    }
                    return props.focusStyle.borderColor;
                },
                padding: (props: GivelifyButtonVariableStyleProps) =>
                    props.focusStyle ? props.focusStyle.padding : undefined,
            },
        },
    }),
);
