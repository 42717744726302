import React from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';

export default (
    <>
        <FacebookLoading
            borderRadius={0}
            height={37.6}
            marginTop={32}
            width={400}
        />
        <div
            style={{
                marginTop: 32,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <FacebookLoading
                borderRadius={0}
                height={21.2}
                marginTop={4}
                width={140}
            />
            <FacebookLoading
                borderRadius={0}
                height={21.2}
                marginTop={4}
                width={140}
            />
        </div>
    </>
);
