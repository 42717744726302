import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    GivelifyMenu,
    GivelifyTextField,
    OptionItem,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest, useDebounce } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { FilterInputWrapper } from '../../../../../../components/inputs/FilterInputWrapper';
import {
    MATCH,
    matchSelect,
} from '../../../../utils/integrationFormatFilterUtils';
import { donorTabStyle } from '../style';
import { IntegrationCampus, IntegrationCampusesResponse } from '../types';
import { getCampuses } from './automatedIntegrationRequest';

interface SearchFilterHeaderProps {
    donorTab?: boolean;
    exportTab?: boolean;
    defaultMatch?: matchSelect;
    onMatchChange?: (value: matchSelect) => unknown;
    campusSelected?: number;
    onCampusChange?: (value: number) => unknown;
    onSearchChange?: (value: string) => void;
}

const AutoIntegrationSearchFilterHeader: React.FC<SearchFilterHeaderProps> = ({
    donorTab,
    exportTab,
    defaultMatch,
    onMatchChange,
    campusSelected,
    onCampusChange,
    onSearchChange,
}) => {
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));
    const classes = donorTabStyle();
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            searchPlaceholder: t('pages.integrations.searchPlaceHolder'),
            allCampus: t(
                'pages.integration_envelope_matching.content.allCampuses',
            ),
        }),
        [t],
    );

    const [value, setValue] = useState<string>('');
    const debouncedSearchValue = useDebounce<string>(value, 500);
    const onChange = useCallback(
        (
            e: React.ChangeEvent<{ name?: string; value: string }>,
            value: string,
        ) => {
            setValue(value);
        },
        [setValue],
    );

    useEffect(() => {
        if (
            (debouncedSearchValue.trim().length === 0 ||
                debouncedSearchValue.trim().length >= 3) &&
            onSearchChange
        ) {
            onSearchChange(debouncedSearchValue);
        }
    }, [debouncedSearchValue, onSearchChange]);

    const [campusDataResponse, getCampusData] =
        useApiRequest<IntegrationCampusesResponse>();
    const [campusesList, setCampusesList] = useState<IntegrationCampus[]>();
    useEffect(() => {
        getCampusData(getCampuses(userDoneeId));
    }, [doneeId, getCampusData, userDoneeId]);
    useEffect(() => {
        if (campusDataResponse.type === 'REQUEST_SUCCESS') {
            setCampusesList(campusDataResponse.response.data);
        }
    }, [campusDataResponse]);

    const [isMatchOpen, setIsMatchOpen] = useState(false);
    const [matchValue, setMatchValue] = useState<string>(
        defaultMatch || MATCH[1],
    );
    const onChangeMatch = values => {
        onMatchChange(values[0] as matchSelect);
        setMatchValue(values[0]);
        setIsMatchOpen(false);
    };
    const defaultValue = MATCH[defaultMatch];
    const matchOptions: OptionItem[] = MATCH.map(item => ({
        label: item,
        value: item,
    }));
    const renderMatchLabel = values => {
        if (values && values.length > 0) {
            return values[0];
        } else {
            return defaultValue;
        }
    };

    const [isCampusOpen, setIsCampusOpen] = useState<boolean>(false);
    const [campusValue, setCampusValue] = useState<string>(
        campusSelected.toString(),
    );
    const campusOptions: OptionItem[] = campusesList?.map(campus => ({
        label: campus.name,
        value: campus.id.toString(),
    }));
    const onChangeCampus = values => {
        setCampusValue(values[0]);
        onCampusChange(parseInt(values[0]));
        setIsCampusOpen(false);
    };

    return (
        <Grid container alignItems="center">
            <div className={classes.filterRow}>
                {!exportTab && (
                    <>
                        <Grid item className={classes.filterItem}>
                            <FilterInputWrapper>
                                <GivelifyMenu
                                    checkMarkVariant="mark"
                                    className={classes.selector}
                                    id="select-type"
                                    onChange={onChangeMatch}
                                    onOpenToggle={setIsMatchOpen}
                                    open={isMatchOpen}
                                    options={matchOptions}
                                    renderMenuLabel={renderMatchLabel}
                                    value={[matchValue]}
                                />
                            </FilterInputWrapper>
                        </Grid>
                        {campusesList?.length > 1 && (
                            <Grid item className={classes.filterItem}>
                                <FilterInputWrapper>
                                    <GivelifyMenu
                                        checkMarkVariant="mark"
                                        className={classes.selector}
                                        id="select-campus"
                                        onChange={onChangeCampus}
                                        onOpenToggle={setIsCampusOpen}
                                        open={isCampusOpen}
                                        options={[
                                            {
                                                label: copy.allCampus,
                                                value: '0',
                                            },
                                            ...campusOptions,
                                        ]}
                                        value={[campusValue]}
                                    />
                                </FilterInputWrapper>
                            </Grid>
                        )}
                    </>
                )}
            </div>
            {donorTab || exportTab ? (
                <Grid item className={classes.searchContent}>
                    <GivelifyTextField
                        ariaLabel="search-input"
                        boxSize="dense"
                        className={classes.autocompleteInput}
                        id="search-dense"
                        onChange={onChange}
                        placeholder={copy.searchPlaceholder}
                        value={value}
                        variant="search"
                    />
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );
};

export default AutoIntegrationSearchFilterHeader;
