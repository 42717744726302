import React from 'react';
import { Donee } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyDatePicker,
    GivelifyDropdown,
    GivelifyLabel,
    GivelifyRadio,
    GivelifyTextField,
} from '@givelify/ui';
import { defaultStartDateDayjs } from '@givelify/utils';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { showReportGeneratingDialog } from 'store/integrations/actions';
import { ReportRequestData, generateCustomReport } from 'store/report/thunks';
import { ReportType } from 'store/report/types';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { reportList } from '../CustomReportForm/reportList';
import {
    Box,
    CampusBox,
    ExtraInputRow,
    Footer,
    InputBox,
    InputRow,
    TitleRow,
} from './style';

type CustomReportFormProps = {
    integration?: IntegrationTypes;
    isSuperUser?: boolean;
    doneeStartDate?: dayjs.Dayjs;
    doneeId?: number;
    campuses?: Donee[];
    reportType?: string;
    onGenerateReport: () => void;
};

export const CustomReportForm: React.FC<CustomReportFormProps> = ({
    integration,
    isSuperUser,
    doneeStartDate,
    doneeId,
    campuses,
    reportType,
    onGenerateReport,
}) => {
    const dispatch = useDispatch();
    const { timezoneLong, timezoneShort, timezoneEnabled } = useSelector(
        (state: AppState) => {
            return {
                timezoneLong: state.Donee.donee.timezoneLong,
                timezoneShort: state.Donee.donee.timezoneShort,
                timezoneEnabled:
                    isFeatureEnabled(
                        state.System.enabledFeatures,
                        Features.TIMEZONES,
                        false,
                    ) && state.Donee.donee.timezone !== null,
            };
        },
    );
    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integrations_custom_report';
    const scopedTranslate = React.useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );
    const copy = React.useMemo(
        () => ({
            title: scopedTranslate('title'),
            title2: scopedTranslate('title2'),
            title2Campus: scopedTranslate('title2Campus'),
            startDate: scopedTranslate('start_date'),
            endDate: scopedTranslate('end_date'),
            doneeId: scopedTranslate('donee_id'),
            selectType: scopedTranslate('select_type'),
            reportType: scopedTranslate('report_type'),
            startDateMessage: scopedTranslate('start_date_message'),
            endDateMessage: scopedTranslate('end_date_message'),
            startDateRequired: scopedTranslate('startDateRequired'),
            endDateRequired: scopedTranslate('endDateRequired'),
            invalidStartDate: scopedTranslate('invalidStartDate'),
            invalidEndDate: scopedTranslate('invalidEndDate'),
            allCampuses: scopedTranslate('allCampuses'),
            buildReport: t('labels.buildReport'),
            timezoneNote: t('timezones.notes.reports'),
        }),
        [scopedTranslate, t],
    );
    const campusesOptions = React.useMemo(
        () => [
            { id: -1, name: copy.allCampuses, address: undefined },
            ...campuses.map((c) => ({
                id: c.id,
                name: c.name,
                address: `${c.address} ${c.city}, ${c.state}`,
            })),
        ],
        [campuses, copy.allCampuses],
    );
    const [curDoneeId, setCurDoneeId] = React.useState(doneeId);
    const [selectedCampusId, setSelectedCampusId] = React.useState(-1);
    const [selectedType, setSelectedType] = React.useState(
        reportType ? reportType : '',
    );
    const minStartDate = isSuperUser ? defaultStartDateDayjs : doneeStartDate;
    const maxEndDate = dayjs().subtract(1, 'day');
    const [startDate, setStartDate] = React.useState<dayjs.Dayjs | undefined>(
        undefined,
    );
    const [endDate, setEndDate] = React.useState<dayjs.Dayjs | undefined>(
        undefined,
    );
    const maxStartDate = React.useMemo(
        () => (endDate && endDate.isValid() ? endDate : maxEndDate),
        [endDate, maxEndDate],
    );

    const minEndDate = React.useMemo(
        () => (startDate && startDate.isValid() ? startDate : minStartDate),
        [startDate, minStartDate],
    );
    const canSubmit = React.useMemo(() => {
        return startDate && endDate && startDate.isValid() && endDate.isValid();
    }, [startDate, endDate]);
    const onDoneeIdChange = React.useCallback((e) => {
        setCurDoneeId(e.target.value);
    }, []);
    const onCampusChange = React.useCallback((e) => {
        const val = e.target.value as string;
        setSelectedCampusId(parseInt(val));
    }, []);
    const hasCampuses = campuses && campuses.length > 1;
    const handleSubmit = React.useCallback(async () => {
        const data: ReportRequestData = {
            doneeId: isSuperUser
                ? curDoneeId
                : selectedCampusId > -1
                ? selectedCampusId
                : doneeId,
            reportType: selectedType as ReportType,
            startDate: startDate.format('MM/DD/YYYY'),
            endDate: endDate.format('MM/DD/YYYY'),
            allCampuses: String(selectedCampusId === -1),
        };

        try {
            await generateCustomReport(data)(dispatch);
        } catch (error) {
            /* do nothing */
        } finally {
            dispatch(showReportGeneratingDialog());
            onGenerateReport();
        }
    }, [
        curDoneeId,
        dispatch,
        doneeId,
        endDate,
        isSuperUser,
        onGenerateReport,
        selectedCampusId,
        selectedType,
        startDate,
    ]);
    return (
        <Box>
            <TitleRow>
                <GivelifyLabel
                    text={`${copy.title} ${
                        integration.abbreviation === 'Shelby Arena' ||
                        integration.abbreviation === 'Shelby Next'
                            ? integration.name
                            : integration.abbreviation
                    }`}
                    variant="heading1M"
                />
                <GivelifyLabel
                    text={hasCampuses ? copy.title2Campus : copy.title2}
                    variant="heading3S"
                />
            </TitleRow>
            <InputBox>
                {hasCampuses ? (
                    <CampusBox>
                        <GivelifyDropdown
                            multipleLine
                            aria-label="campus"
                            id="campus"
                            name="campus"
                            onChange={onCampusChange}
                            options={campusesOptions.map((c) => ({
                                label: c.name,
                                id: c.id,
                                secondaryLabel: c.address,
                            }))}
                            shape="input"
                            size="medium"
                            value={selectedCampusId}
                            width="fullwidth"
                        />
                    </CampusBox>
                ) : null}
                <InputRow>
                    <GivelifyDatePicker
                        label={copy.startDate}
                        maxDate={maxStartDate}
                        minDate={minStartDate}
                        name="startDate"
                        onDateChange={setStartDate}
                        size="medium"
                        value={startDate}
                    />
                    <GivelifyDatePicker
                        label={copy.endDate}
                        maxDate={maxEndDate}
                        minDate={minEndDate}
                        name="endDate"
                        onDateChange={setEndDate}
                        size="medium"
                        value={endDate}
                    />
                </InputRow>
            </InputBox>
            {timezoneEnabled ? (
                <Stack alignItems="center" justifyContent="center" mt={2}>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.timezoneNote}
                    />
                    <GivelifyLabel
                        fontWeight={700}
                        text={`${timezoneLong} - ${timezoneShort}`}
                    />
                </Stack>
            ) : null}
            {isSuperUser ? (
                <ExtraInputRow>
                    <GivelifyTextField
                        fullWidth
                        id="doneeId-input"
                        inputProps={{
                            min: 0,
                        }}
                        label={copy.doneeId}
                        name="doneeId"
                        onChange={onDoneeIdChange}
                        type="number"
                        value={curDoneeId}
                    />
                    <GivelifyLabel text={copy.selectType} variant="heading2S" />
                    {reportList.map((item, index) => (
                        <GivelifyRadio
                            key={`report-${index}`}
                            checked={item.value === selectedType}
                            label={item.label}
                            onChange={() => setSelectedType(item.value)}
                            value={item.value}
                        />
                    ))}
                </ExtraInputRow>
            ) : null}
            <Footer>
                <GivelifyButton
                    disabled={!canSubmit}
                    name="buildReport"
                    onClick={handleSubmit}
                    text={copy.buildReport}
                    variant="primary"
                />
            </Footer>
        </Box>
    );
};
