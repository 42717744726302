import { styled } from "@mui/material";

export const FormWrapper = styled('div')(() => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 24,
    rowGap: 16,
    '& > *': {
        gridColumn: 'span 2',
    },
}));