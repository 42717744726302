import React from 'react';
import { GivelifyMobilePreviewStyle } from './style';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { mergeClassNames } from '../utils/classNameUtils';

export interface GivelifyMobilePreviewBaseProps extends GivelifyBoxMarginProps {
    scale?: number;
    classes?: {
        root?: string;
        content?: string;
    };
    className?: string;
}

export const GivelifyMobilePreviewBase: React.FC<GivelifyMobilePreviewBaseProps> = props => {
    const { children, classes, className, ...styleProps } = props;
    const {
        boxMargin,
        mobilePreviewBase,
        mobilePreviewContent,
    } = GivelifyMobilePreviewStyle(styleProps);
    const rootClassName = mergeClassNames(
        boxMargin,
        mobilePreviewBase,
        classes?.root,
        className,
    );
    const contentClassName = mergeClassNames(
        mobilePreviewContent,
        classes?.content,
    );
    return (
        <div className={rootClassName}>
            <div className={contentClassName}>{props.children}</div>
        </div>
    );
};

GivelifyMobilePreviewBase.displayName = 'GivelifyMobilePreviewBase';
