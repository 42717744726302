import React, { useCallback, useState } from 'react';
import { GivelifyInputState, GivelifyTextField } from '../textField';
import { isValidZip } from '../utils/addressUtils';

export interface ZipFieldProps {
    name?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    zip: string | undefined;
    onChange?: (zip: string) => unknown;
    placeholder: string;
    showState?: boolean;
    zipErrorMessage?: string;
}

export const ZipField: React.FC<ZipFieldProps> = React.memo(
    ({
        name,
        inputRef,
        zip,
        placeholder,
        onChange,
        showState = false,
        zipErrorMessage,
    }) => {
        const [newZip, setZip] = useState<string>(zip || '');
        const [inputState, setInputState] = React.useState<GivelifyInputState>(
            'normal',
        );
        const handleChange = useCallback(
            (_e: unknown, value: string) => {
                setZip(value);
                onChange && onChange(value);
                setInputState(isValidZip(value) ? 'normal' : 'error');
            },
            [setZip, onChange, setInputState],
        );
        React.useEffect(() => {
            if (showState) {
                setInputState(isValidZip(newZip) ? 'normal' : 'error');
            }
            //eslint-disable-next-line
        }, [showState]);
        return (
            <GivelifyTextField
                name={name}
                inputRef={inputRef}
                id="zip"
                value={newZip}
                onChange={handleChange}
                ariaLabel={placeholder}
                label={placeholder}
                placeholder={placeholder}
                state={inputState}
                leftHelperText={
                    inputState === 'error' ? zipErrorMessage : undefined
                }
            />
        );
    },
);
