import React from 'react';
import {
    isSucceeded,
    RequestState,
    responseOrUndefined,
} from '@givelify/utils';
import { TimeFrameValue } from '@givelify/utils';
import { DonationsStatisticType } from 'api/client/endpoints';
import { GetDonationsStatisticResponse } from 'api/services/responses/donations';
import {
    transformToDailyTrendData,
    transformToHourlyTrendData,
    transformToMonthlyTrendData,
    transformToWeeklyTrendData,
    transformToYearlyTrendData,
} from '../helpers';
import { TrendData } from '../types';
import { OverviewTrendChartView } from './OverviewTrendChartView';

export type OverviewTrendChartProps = {
    timeFrame: TimeFrameValue;
    donationsDataRequest: RequestState<GetDonationsStatisticResponse>;
    rangeDateType: DonationsStatisticType;
};

const OverviewTrendChartComponent: React.FC<OverviewTrendChartProps> = ({
    timeFrame,
    donationsDataRequest,
    rangeDateType,
}) => {
    const [chartData, setChartData] = React.useState<TrendData>([]);

    const donationsData = responseOrUndefined(donationsDataRequest);

    const isDataEmpty = React.useMemo(() => {
        return chartData.every((d) => d.sum === 0);
    }, [chartData]);

    const totalDonationsAmount = React.useMemo(() => {
        return chartData.reduce((acc, cur) => acc + cur.sum, 0);
    }, [chartData]);

    React.useEffect(() => {
        switch (rangeDateType) {
            case 'hourly':
                setChartData(transformToHourlyTrendData(donationsData?.data));
                break;
            case 'daily':
                setChartData(
                    transformToDailyTrendData(
                        donationsData?.data,
                        timeFrame.selector,
                    ),
                );
                break;
            case 'weekly': {
                setChartData(transformToWeeklyTrendData(donationsData?.data));
                break;
            }
            case 'monthly':
                setChartData(
                    transformToMonthlyTrendData(
                        donationsData?.data,
                        timeFrame.selector,
                    ),
                );
                break;
            case 'yearly': {
                setChartData(transformToYearlyTrendData(donationsData?.data));
                break;
            }
        }
    }, [rangeDateType, timeFrame, donationsData]);

    return (
        <OverviewTrendChartView
            data={chartData}
            dataKey="name"
            donationsCount={donationsData?.totals.count}
            isLoading={!isSucceeded(donationsDataRequest)}
            rangeDateType={rangeDateType}
            showEmptyState={isDataEmpty}
            timeFrame={timeFrame}
            totalDonation={totalDonationsAmount}
        />
    );
};

export const OverviewTrendChart = React.memo(OverviewTrendChartComponent);
