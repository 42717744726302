import { DesignTokens } from '@givelify/ui';
import { createStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const TabStyles = makeStyles(() => {
    return createStyles({
        tabRoot: {
            padding: '16px 0 5px',
            height: 'auto',
            overflowX: 'auto',
            overflowY: 'hidden',
            '&::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
                background: DesignTokens.color.backgroundComponentPrimaryHover,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: DesignTokens.color.iconPrimary,
                borderRadius: '13px',
            },
            '&': {
                scrollbarWidth: 'thin',
                scrollbarColor: `${DesignTokens.color.iconPrimary} ${DesignTokens.color.backgroundComponentPrimaryHover}`,
            },
        },
        tabDefault: {
            whiteSpace: 'nowrap',
        },
    });
});
