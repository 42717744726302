import { requestInit } from '@givelify/utils';
import { Reducer } from 'redux';
import { LifeTimeState, LifeTimeActions, SET_LIFE_TIME } from './types';
import { setDateLifeTimeDonations } from './utils';

const LifeTimeReducer: Reducer<LifeTimeState, LifeTimeActions> = (
    state = requestInit(),
    action,
) => {
    switch (action.type) {
        case SET_LIFE_TIME:
            if (action.payload.response.type === 'REQUEST_SUCCESS') {
                setDateLifeTimeDonations(action.payload.doneeId);
            }
            return {
                ...action.payload.response,
            };
        default:
            return state;
    }
};

export default LifeTimeReducer;
