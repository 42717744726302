import { makeStyles, Theme } from '@material-ui/core/styles';

const heading = 'heading';
const timeframePicker = 'timeframePicker';
const totalDonations = 'totalDonations';

export default makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'grid',
        alignItems: 'center',
        gap: 16,
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateAreas: `"heading timeframePicker totalDonations"`,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr auto',
            gridTemplateAreas: `
				"heading timeframePicker"
				". totalDonations"`,
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `
				"heading"
				"totalDonations"
				"timeframePicker"`,
        },
    },
    [heading]: {
        gridArea: 'heading',
    },
    [timeframePicker]: {
        gridArea: 'timeframePicker',
        [theme.breakpoints.only('md')]: {
            justifySelf: 'end',
        },
    },
    [totalDonations]: {
        gridArea: 'totalDonations',
    },
}));
