import { styled } from '@mui/material';

export const DialogRoot = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const DialogContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
}));

export const DialogFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(7),
}));
