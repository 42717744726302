import { ValidationError, object, string } from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';

export enum ValidationErrors {
    LENGTH = 'length',
    STARTS_WITH = 'startsWith',
    CONSECUTIVE = 'consecutive',
    SPACE = 'space',
}

export interface BankAccountInfo {
    accountNumber: ValidationErrors | undefined;
    routingNumber: ValidationErrors | undefined;
    retypeAccountNumber?: ValidationErrors | undefined;
}

export interface ValidationResult<T> {
    errors?: T;
    isValid: boolean;
}

declare module 'yup' {
    interface StringSchema {
        startsWith(): StringSchema;
        consecutive(length: number): StringSchema;
    }
}

const sameNumbersRegex = /\b(\d)\1+\b/;
const ascPattern = '0123456789';
const descPattern = '987654321';

export const validateSequence = (value = '', length?: number) =>
    !(
        (sameNumbersRegex.exec(value) ||
            ascPattern.includes(value) ||
            descPattern.includes(value)) &&
        (length ? value.length >= length : true)
    );

export const routingNumberStartsWith = (value = '') =>
    '0123'.includes(value[0]);

const validate = (schema: OptionalObjectSchema<AnyObject>, value: unknown) => {
    try {
        schema.validateSync(value, {
            abortEarly: false,
        });

        return {
            isValid: true,
        };
    } catch (err) {
        const errors: Record<string, string> = {};
        if ((err as ValidationError).inner) {
            (err as ValidationError).inner.forEach((e: ValidationError) => {
                if (e.path) {
                    errors[e.path] = e.message;
                }
            });
        }

        return {
            isValid: false,
            errors,
        };
    }
};

export const ValidateAccountInfo = (formData: {
    routingNumber: string;
    accountNumber: string;
    retypeAccountNumber?: string;
}): ValidationResult<BankAccountInfo> => {
    const userSchema = object({
        routingNumber: string()
            .test({
                test: routingNumberStartsWith,
                message: 'startsWith',
            })
            .test({
                test: (value = '') => validateSequence(value, 9),
                message: 'consecutive',
            })
            .matches(/^\d+$/, 'space')
            .length(9, 'length'),

        accountNumber: string()
            .test({
                test: (value = '') => validateSequence(value, 8),
                message: 'consecutive',
            })
            .matches(/^\d+$/, 'space')
            .min(8, 'length'),

        ...(formData.retypeAccountNumber !== undefined && {
            retypeAccountNumber: string().test({
                test: (value = '') => value === formData.accountNumber,
                message: 'startsWith',
            }),
        }),
    });

    return validate(userSchema, formData) as ValidationResult<BankAccountInfo>;
};

export const ValidateTaxId = (
    taxId: string,
): ValidationResult<{ taxId: ValidationErrors | undefined }> => {
    taxId = taxId.replace('-', '');
    
    const taxIdSchema = object({
        taxId: string()
            .test({
                test: (value = '') => validateSequence(value, 9),
                message: 'consecutive',
            })
            .length(9, 'length')
            .matches(/^\d+$/, 'space'),
    });

    return validate(taxIdSchema, { taxId }) as ValidationResult<{
        taxId: ValidationErrors | undefined;
    }>;
};
