import { useEffect } from 'react';
import Img1 from './img/1.png';
import Img2 from './img/2.png';
import Img3 from './img/3.png';

export const usePreloadImages = () => {
    useEffect(() => {
        new Image().src = Img1;
        new Image().src = Img2;
        new Image().src = Img3;
    }, []);
};
