import React, { useMemo, useState } from 'react';
import {
    GivelifyLabel,
    GivelifyLabelStyles,
    GivelifyLoading,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import { ProfileMobilePreview } from '../../mobilePreviews/ProfileMobilePreview';
import AddressEditor from './AddressEditor';
import BannerImageSection from './BannerImageSection';
import FaithLeaderSection from './FaithLeaderSection';
import PopupTip from './PopupTip';
import useStyles from './styles';
import { CustomizeProfileStepOneViewProps } from './types';
import ViewProfilePreviewLink from './ViewProfilePreviewLink';

const CustomizeProfileStepOneComponent: React.FC<CustomizeProfileStepOneViewProps> =
    ({
        isLoading,
        data,
        className,
        address,
        doNotDisplayFaithLeader,
        organizationType,
        setAddress,
        setBannerImage,
        setDoNotDisplayFaithLeader,
        setFaithLeader,
        setFaithLeaderImage,
        settingsUsersPath,
        userId,
        bannerImage,
        faithLeader,
        faithLeaderImage,
        hideFullScreenPreviewOption,
        onMobilePreviewRequest,
        setIsAddressValid,
        organizationName,
        appProfilebannerCroppedUrl,
        initialFaithLeaderImage,
        initialFiathLeader,
        formProps,
    }) => {
        const { container, profileStyles, contentWrapper } = useStyles();
        const { body1 } = GivelifyLabelStyles({});
        const { t } = useTranslation(I18N_NAMESPACE);

        const [openPopupTip, setPopupTip] = useState(false);
        const onOpenPopupTip = () => setPopupTip(true);
        const onClosePopupTip = () => setPopupTip(false);
        const { titleText, descriptionText, descriptionLink } = useMemo(
            () => ({
                titleText: t('customizeProfile.title'),
                descriptionText: t('customizeProfile.description.text'),
                descriptionLink: t('customizeProfile.description.link'),
            }),
            [t],
        );
        const onBunnerImageSaveRequest = React.useCallback(() => {
            if (formProps?.bunnerImageFormSubmitProps) {
                formProps.bunnerImageFormSubmitProps.onSave(bannerImage);
            }
        }, [formProps, bannerImage]);
        const onAddressSaveRequest = React.useCallback(() => {
            if (formProps?.addressFormSubmitProps) {
                formProps.addressFormSubmitProps.onSave(address);
            }
        }, [formProps, address]);
        const onFaithLeaderSaveRequest = React.useCallback(() => {
            if (formProps?.faithLeadeFormSubmitProps) {
                formProps.faithLeadeFormSubmitProps.onSave(
                    faithLeader,
                    faithLeaderImage,
                    doNotDisplayFaithLeader,
                );
            }
        }, [formProps, faithLeader, faithLeaderImage, doNotDisplayFaithLeader]);
        return (
            <div
                className={mergeClassNames(container, className, profileStyles)}
            >
                {isLoading && <GivelifyLoading type="topLoadingBar" />}
                <div className="form-section">
                    <GivelifyLabel
                        marginBottom={8}
                        text={titleText}
                        variant="heading3"
                    />
                    <div className={body1}>
                        {descriptionText}
                        <span className="link" onClick={onOpenPopupTip}>
                            {descriptionLink}
                        </span>
                        .
                    </div>
                    <div className={contentWrapper}>
                        <BannerImageSection
                            bannerImage={bannerImage}
                            setBannerImage={setBannerImage}
                            submitProps={
                                formProps?.bunnerImageFormSubmitProps
                                    ? {
                                          onSave: onBunnerImageSaveRequest,
                                          isSaving:
                                              formProps
                                                  ?.bunnerImageFormSubmitProps
                                                  .isSaving,
                                          disable:
                                              formProps
                                                  ?.bunnerImageFormSubmitProps
                                                  .disable,
                                      }
                                    : undefined
                            }
                        />
                        <ViewProfilePreviewLink
                            hideFullScreenPreviewOption={
                                hideFullScreenPreviewOption
                            }
                            onClick={onMobilePreviewRequest}
                        />

                        {organizationType === 'church' && !isLoading && (
                            <>
                                <FaithLeaderSection
                                    doNotDisplayFaithLeader={
                                        doNotDisplayFaithLeader
                                    }
                                    faithLeader={faithLeader}
                                    faithLeaderImage={faithLeaderImage}
                                    initialFaithLeader={initialFiathLeader}
                                    initialfaithLeaderImage={
                                        initialFaithLeaderImage
                                    }
                                    onChangeFaithLeader={setFaithLeader}
                                    onDoNotDisplayFaithLeaderChange={
                                        setDoNotDisplayFaithLeader
                                    }
                                    representatives={data}
                                    setFaithLeaderImage={setFaithLeaderImage}
                                    settingsUsersPath={settingsUsersPath}
                                    submitProps={
                                        formProps?.faithLeadeFormSubmitProps
                                            ? {
                                                  onSave: onFaithLeaderSaveRequest,
                                                  isSaving:
                                                      formProps
                                                          ?.faithLeadeFormSubmitProps
                                                          .isSaving,
                                              }
                                            : undefined
                                    }
                                    userId={userId}
                                />
                                <ViewProfilePreviewLink
                                    hideFullScreenPreviewOption={
                                        hideFullScreenPreviewOption
                                    }
                                    onClick={onMobilePreviewRequest}
                                />
                            </>
                        )}
                        <AddressEditor
                            ref={formProps?.addressFormSubmitProps?.formRef}
                            address={address}
                            isAddressValid={setIsAddressValid}
                            onChange={setAddress}
                            submitProps={
                                formProps?.addressFormSubmitProps
                                    ? {
                                          onSave: onAddressSaveRequest,
                                          isSaving:
                                              formProps?.addressFormSubmitProps
                                                  .isSaving,
                                          disable:
                                              formProps?.addressFormSubmitProps
                                                  .disable,
                                      }
                                    : undefined
                            }
                        />
                        <ViewProfilePreviewLink
                            hideFullScreenPreviewOption={
                                hideFullScreenPreviewOption
                            }
                            onClick={onMobilePreviewRequest}
                        />
                    </div>
                </div>
                <div className="image-section">
                    <ProfileMobilePreview
                        coverUrl={
                            bannerImage?.croppedUrl ||
                            appProfilebannerCroppedUrl
                        }
                        fullAddress={address}
                        isNonprofit={organizationType === 'nonprofit'}
                        organizationName={organizationName}
                        representativeAvatar={faithLeaderImage?.croppedUrl}
                    />
                </div>
                <PopupTip onClose={onClosePopupTip} open={openPopupTip} />
            </div>
        );
    };

export default CustomizeProfileStepOneComponent;
