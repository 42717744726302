import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { RepDetailStyles } from './repDetailStyles';

interface RepPendingProps {
    firstName: string;
    lastName: string;
}

export const RepPending: React.FC<RepPendingProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            caption: t('pages.settings.bank-info.customize-rep-tab.caption'),
            newRep: t('pages.settings.bank-info.customize-rep-tab.new-rep'),
            primaryRep: t('pages.settings.bank-info.customize-rep-tab.title'),
            submitted: t(
                'pages.settings.bank-info.customize-rep-tab.submitted',
            ),
            submittedInfo: t(
                'pages.settings.bank-info.customize-rep-tab.submitted-info',
            ),
        }),
        [t],
    );
    const { firstName, lastName } = props;
    const { repPending } = RepDetailStyles();
    return (
        <div className={repPending}>
            <GivelifyLabel marginTop={40} text={copy.caption} />
            <div className="header">
                <GivelifyLabel text={copy.newRep} variant="heading3" />
                <GivelifyIcon
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="send"
                />
                <GivelifyLabel className="submitted" text={copy.submitted} />
            </div>
            <GivelifyNotification
                show
                text={copy.submittedInfo}
                variant="info"
            />
            <GivelifyLabel
                color="neutralGrey75"
                marginBottom={6}
                marginTop={24}
                text={copy.primaryRep}
                variant="heading5"
            />
            <GivelifyLabel
                color="neutralGrey75"
                marginBottom={16}
                text={`${firstName} ${lastName}`}
                variant="body1"
            />
        </div>
    );
};
