import { useEffect, useState } from 'react';
import { GivelifyLoading } from '@givelify/ui';
import { getAxiosClient, makeApiRequest, useApiRequest } from '@givelify/utils';
import { getCalendlyScheduledEvents } from 'api/client/endpoints';
import { useCalendlyEventListener } from 'react-calendly';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import ConfirmationModal from './ConfirmationModal';
import SchedulePopup from './SchedulePopup';
import { CalendlyBackdrop, LoaderWrapper } from './styles';
import { CalendlyResponse, CurrentPage } from './types';
import { usePreloadImages } from './usePreloadImages';

const LoaderComponent = () => (
    <LoaderWrapper>
        <GivelifyLoading type="circular" />
    </LoaderWrapper>
);

const getReuqest = (eventId: string, inviteeId: string) => {
    const url = getCalendlyScheduledEvents(eventId, inviteeId);
    const httpRequest = getAxiosClient().get(url);
    return makeApiRequest<CalendlyResponse>(httpRequest);
};

const CalendlyWidget: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    usePreloadImages();

    const [currentStep, setCurrentStep] = useState<
        'Popup' | 'Loading' | 'Confirmation'
    >('Popup');

    const [currentPage, setCurrentPage] = useState<CurrentPage>('EventType');

    const [calendlyScheduledEvent, makeGetCalendlyScheduledEventRequest] =
        useApiRequest<CalendlyResponse>();

    useEffect(() => {
        if (calendlyScheduledEvent.type === 'REQUEST_SUCCESS') {
            setCurrentStep('Confirmation');
        } else if (calendlyScheduledEvent.type === 'REQUEST_ERROR') {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendlyScheduledEvent]);

    useCalendlyEventListener({
        onDateAndTimeSelected: () => {
            setCurrentPage('ScheduleEvent');
        },
        onEventScheduled: (e) => {
            setCurrentStep('Loading');

            const pathname = new URL(e.data.payload.invitee.uri).pathname;
            const split = pathname.split('/');

            const eventId = split[2];
            const inviteeId = split[4];
            makeGetCalendlyScheduledEventRequest(
                getReuqest(eventId, inviteeId),
            );
        },
    });

    if (currentStep === 'Loading')
        return (
            <CalendlyBackdrop>
                <LoaderComponent />
            </CalendlyBackdrop>
        );

    if (
        currentStep === 'Confirmation' &&
        calendlyScheduledEvent.type === 'REQUEST_SUCCESS'
    ) {
        return (
            <ConfirmationModal
                onClose={onClose}
                response={calendlyScheduledEvent.response}
            />
        );
    }

    return <SchedulePopup currentPage={currentPage} onClose={onClose} />;
};

export default CalendlyWidget;

export const useCalendly = () => {
    const { featureEnabled, donee } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
        featureEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.SCHEDULE_CALL_WITH_SUPPORT,
            false,
        ),
    }));

    const hasMid = donee?.onboarding?.hasMid;
    const isCalendlyEnabled =
        featureEnabled && !hasMid && donee?.type === 'church';

    const [openCalendly, setOpenCalendly] = useState(false);

    return { isCalendlyEnabled, openCalendly, setOpenCalendly };
};
