import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { I18N_NAMESPACE } from './const';

const DEFAULT_DONEE_TYPE = 'church' as const;
type DoneeType = typeof DEFAULT_DONEE_TYPE | 'nonprofit';
let cachedDoneeType: string | undefined = undefined;

const saveDoneeType = (doneeType?: string) => {
    cachedDoneeType = doneeType;
};
const getDoneeType = (): string | undefined => {
    return cachedDoneeType;
};

export const useTranslation = (
    TRANSLATION_KEY?: string,
    doneeType?: string,
) => {
    if (doneeType) {
        saveDoneeType(doneeType);
    }

    const utils = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY,
        doneeType: (getDoneeType() || DEFAULT_DONEE_TYPE) as DoneeType,
    });

    return { ...utils };
};
