import React from 'react';
import {
    PieLabelRenderProps,
    ResponsiveContainer,
    PieChart,
    Tooltip,
    Pie,
    Cell,
    TooltipProps,
} from 'recharts';
import { colorsList } from '../../../components/styles';
import { NameType, ValueType } from './index';

//eslint-disable-next-line
export interface SimplePieChartProps<T extends object> {
    nameKey: keyof T & string;
    dataKey: keyof T & string;
    data: T[];
    tooltip?: React.FC<TooltipProps<ValueType, NameType>>;
}

const RADIAN = Math.PI / 180;
const textStyles: React.CSSProperties = { fontWeight: 'bold', fontSize: 16 };
export const tooltipStyle = {
    backgroundColor: '#4a4a4a',
    color: '#fefefe',
    padding: '2px 20px',
    borderRadius: 5,
    fontSize: '14px',
};

const RenderCustomizedLabel: React.FC<PieLabelRenderProps> = (props) => {
    const iRadius = Number(props.innerRadius) || 0;
    const oRadius = Number(props.outerRadius) || 0;
    const mAngle = Number(props.midAngle) || 0;
    const chartX = Number(props.cx) || 0;
    const chartY = Number(props.cy) || 0;
    let percentage = Number(props.percent);

    const radius = iRadius + (oRadius - iRadius) * 0.45;
    let x = chartX + radius * Math.cos(-mAngle * RADIAN);
    const y = chartY + radius * Math.sin(-mAngle * RADIAN);

    if (!percentage) return null;
    if (percentage === 1) x += 10;
    percentage *= 100;
    if (percentage < 4) return null;

    return (
        <text
            dominantBaseline="central"
            fill="#373739"
            style={textStyles}
            textAnchor="middle"
            x={x}
            y={y}
        >
            {`${percentage.toFixed(0)}%`}
        </text>
    );
};

const CustomizedTooltip: React.FC<TooltipProps<ValueType, NameType>> = (props: {
    payload: { name: string; value: string }[];
    children?: React.ReactNode;
}) => {
    if (props.payload.length > 0) {
        const data = props.payload[0];
        return (
            <div style={tooltipStyle}>
                <p>{data.name}</p>
                <p>{data.value}</p>
            </div>
        );
    }
    return null;
};
//eslint-disable-next-line
const SimplePieChart = <T extends object>({
    data,
    nameKey,
    dataKey,
    tooltip,
}: SimplePieChartProps<T>) => {
    return (
        <ResponsiveContainer aspect={1} maxHeight={400} width="100%">
            <PieChart>
                <Tooltip content={tooltip ? tooltip : CustomizedTooltip} />
                <Pie
                    data={data}
                    dataKey={dataKey}
                    innerRadius="60%"
                    // https://github.com/recharts/recharts/issues/750
                    // the library seems to have a bug
                    // where customized label component does not get props.
                    // Thus it does not render the label at all
                    // isAnimationActive={false} seems to solve the problem
                    // but it loses the animation
                    isAnimationActive={false}
                    label={RenderCustomizedLabel}
                    labelLine={false}
                    nameKey={nameKey}
                    outerRadius="100%"
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colorsList[index % colorsList.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default SimplePieChart;
