import React, { useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyMenu,
} from '@givelify/givelify-ui';
import { givelithonMainPageStyle } from '../../GivelithonMainPage/style';
import { useTranslation } from '../../utils/i18n';

interface GivelithonHeaderProps {
    onSort: (
        value: string | { key: string | number; value: string } | null,
    ) => void;
    linkToSettings: () => void;
}

export const GivelithonHeader: React.FC<GivelithonHeaderProps> = (props) => {
    const classes = givelithonMainPageStyle();
    const { t, at } = useTranslation();
    const copy = useMemo(
        () => ({
            activeEnvelope: at('givelithonPage.activeEnvelope'),
            description: {
                start: at('givelithonPage.description.start'),
                link: at('givelithonPage.description.link'),
                end: t('givelithonPage.description.end'),
            },
            sortBy: t('givelithonPage.sortBy'),
            atoz: t('givelithonPage.atoz'),
            ztoa: t('givelithonPage.ztoa'),
        }),
        [t, at],
    );

    const [sortOption, setSortOption] = React.useState<string[] | undefined>([
        'atoz',
    ]);

    const handleSortOptionChange = (vs: string[] | undefined) => {
        setSortOption(vs);
        if (props.onSort && vs) {
            props.onSort({
                key: vs[0],
                value: vs[0],
            });
        }
    };

    return (
        <div className={classes.givelithonHeader}>
            <GivelifyLabel
                marginBottom={8}
                text={copy.activeEnvelope}
                variant="heading4"
            />
            <div className={classes.givelithonNav}>
                <div>
                    <GivelifyLabel
                        className={classes.inline}
                        text={copy.description.start}
                        variant="body1"
                    />
                    <GivelifyButton
                        className={classes.inline}
                        data-testid="navigate-to-envelopes-settings"
                        fontSize={16}
                        lineHeight={22}
                        marginLeft={4}
                        marginRight={4}
                        name="navigateToEnvelopesSettings"
                        onClick={props.linkToSettings}
                        text={copy.description.link}
                        variant="link"
                    />
                    <GivelifyLabel
                        className={classes.inline}
                        text={copy.description.end}
                        variant="body1"
                    />
                </div>
                <GivelifyMenu
                    classes={{
                        root: classes.sortMenu,
                    }}
                    id="sort-options"
                    onChange={handleSortOptionChange}
                    options={[
                        { value: 'atoz', label: copy.atoz },
                        { value: 'ztoa', label: copy.ztoa },
                    ]}
                    placeholder={copy.sortBy}
                    renderMenuLabel={(value) => {
                        return value && Array.isArray(value)
                            ? `${copy.sortBy}: ${
                                  value[0] === 'atoz' ? copy.atoz : copy.ztoa
                              }`
                            : copy.sortBy;
                    }}
                    value={sortOption}
                />
            </div>
        </div>
    );
};
