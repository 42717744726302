import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const PromptWrapper = styled('div')(({ theme }) => ({
    maxWidth: '362px',
    [theme.breakpoints.down('mobile')]: {
        maxWidth: '280px',
    },
    '& .title': {
        color: DesignTokens.color.textWhite,
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '18px',
            lineHeight: '26px',
        },
    },
    '& .description': {
        color: DesignTokens.color.textWhite,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: 0,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '12px',
            fontWeight: 600,
        },
    },
    '& .go-to-reports': {
        fontSize: '16px !important',
        height: '40px',
    },
}));
