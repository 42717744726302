import React, { useEffect, useMemo } from 'react';
import { FullWidthModal } from '@givelify/givelify-ui';
import { PAGE_NAME } from '@givelify/utils';
import { Stack } from '@mui/material';
import { useFormContext, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCfroContext } from '../context';
import { CurrentStep } from '../context/types';
import AccountOwnerInfo from './AccountOwnerInfo';
import DescriptionCard from './DescriptionCard';
import FaithLeaderInfoEditor from './FaithLeaderInfoEditor';
import RolePicker from './RolePicker';
import useStyles from './styles';
import { UserFormData } from './types';

interface EditUserContactInfoModalViewProps {
    currentStep: CurrentStep;
    onSubmit: (
        data: UserFormData,
        form: UseFormMethods<UserFormData>,
    ) => Promise<void>;
}

const View: React.FC<EditUserContactInfoModalViewProps> = ({
    currentStep,
    onSubmit,
}) => {
    const { onCloseCfroModal } = useCfroContext();
    const styles = useStyles();
    const form = useFormContext<UserFormData>();
    const {
        register,
        formState: { isSubmitting },
        handleSubmit,
    } = form;

    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            confirmTitle: t('overview.confirmContactInfoModal.confirmTitle'),
            inviteTitle: t('overview.confirmContactInfoModal.inviteTitle'),

            save: t('labels.save'),
            cancel: t('labels.cancel'),
            sendInvite: t('overview.confirmContactInfoModal.sendInvite'),
        }),
        [t],
    );

    useEffect(() => {
        register('id');
    }, [register]);

    const formName = `${PAGE_NAME.CfroForm}_${currentStep}`;

    return (
        <FullWidthModal
            open
            trackPageVisit
            footer={{
                cancelText: copy.cancel,
                submitText:
                    currentStep === 'Confirm' ? copy.save : copy.sendInvite,
                onCancel: onCloseCfroModal,
                onSubmit: () => {
                    handleSubmit((data) => onSubmit(data, form))();
                },
                disableSubmit: isSubmitting,
                isLoading: isSubmitting,
            }}
            heading={
                currentStep === 'Confirm' ? copy.confirmTitle : copy.inviteTitle
            }
            id="edit-user-contact-info-modal"
            name={formName}
            onClose={onCloseCfroModal}
            testId={
                currentStep === 'Confirm'
                    ? 'cfroStep1Modal'
                    : currentStep === 'Invite'
                    ? 'cfroStep2Modal'
                    : undefined
            }
        >
            <Stack
                alignItems={{ xs: 'center', md: 'flex-start' }}
                className={styles.formContainer}
                columnGap={14}
                direction={{ xs: 'column-reverse', md: 'row' }}
            >
                <Stack rowGap={8}>
                    <FaithLeaderInfoEditor />
                    {currentStep === 'Confirm' && (
                        <AccountOwnerInfo isEdit={false} />
                    )}
                    {currentStep === 'Invite' && <RolePicker />}
                </Stack>
                {currentStep === 'Confirm' && <DescriptionCard />}
            </Stack>
        </FullWidthModal>
    );
};

export default View;
