import React, { useCallback, useState, useMemo } from 'react';
import { GivelifyInputState, GivelifyTextField } from '../textField';
import { isValidStreet, isPOBoxInPOBoxNotAllowed } from '../utils/addressUtils';

export interface StreetFieldProps {
    name?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    street: string | undefined;
    onChange?: (street: string) => unknown;
    className?: string;
    placeholder: string;
    poBoxNotAllowedText: string | undefined;
    showState?: boolean;
    streetErrorMessage?: string;
}

export const StreetField: React.FC<StreetFieldProps> = React.memo(
    ({
        name,
        street,
        inputRef,
        className,
        placeholder,
        poBoxNotAllowedText,
        onChange,
        showState = false,
        streetErrorMessage,
    }) => {
        const [newStreet, setStreet] = useState<string>(street || '');
        const [inputState, setInputState] = React.useState<GivelifyInputState>(
            'normal',
        );
        const handleChange = useCallback(
            (_e: unknown, value: string) => {
                setStreet(value);
                onChange && onChange(value);
                setInputState(
                    isValidStreet(value, poBoxNotAllowedText !== undefined)
                        ? 'normal'
                        : 'error',
                );
            },
            [setStreet, onChange, setInputState, poBoxNotAllowedText],
        );
        React.useEffect(() => {
            if (showState) {
                setInputState(
                    isValidStreet(newStreet, poBoxNotAllowedText !== undefined)
                        ? 'normal'
                        : 'error',
                );
            }
            //eslint-disable-next-line
        }, [showState]);

        const leftHelperText = useMemo(() => {
            const isPOBoxNotAllowed = isPOBoxInPOBoxNotAllowed(
                newStreet,
                poBoxNotAllowedText !== undefined,
            );
            return inputState === 'error'
                ? !isPOBoxNotAllowed && !!poBoxNotAllowedText
                    ? streetErrorMessage
                    : poBoxNotAllowedText
                : poBoxNotAllowedText;

            // ? 'Street name and number are both needed'
            // : poBoxNotAllowedText
            // ? poBoxNotAllowedText
            // : undefined;
        }, [inputState, newStreet, poBoxNotAllowedText, streetErrorMessage]);

        return (
            <GivelifyTextField
                name={name}
                inputRef={inputRef}
                classes={className ? { root: className } : undefined}
                id="street"
                value={newStreet}
                onChange={handleChange}
                ariaLabel={placeholder}
                label={placeholder}
                placeholder={placeholder}
                leftHelperText={leftHelperText}
                state={inputState}
            />
        );
    },
);
