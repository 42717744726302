import React from 'react';
import { GivelifyIcon } from '../../components/GivelifyIcon';
import { GivelifyLabel } from '../../label';
import { mergeClassNames } from '../../utils/classNameUtils';
import { SortDirection, GivelifyTableColumn } from '../types';
import { GivelifyTableDynamicStyles } from '../styles';
import useStyles from './styles';

interface RenderHeaderCellProps<T> {
    column: GivelifyTableColumn<T>;
    columnIndex: number;
    sortColumn?: keyof T;
    sortDirection?: SortDirection;
    onSort?: (sortDirection: SortDirection, sortColumn?: keyof T) => void;
    offset: number[];
    className?: string;
}

// How to sort when clicking unsorted column
const DEFAULT_SORT_DIRECTION = 'asc';

const RenderHeaderCell = <T,>({
    column,
    columnIndex,
    sortColumn,
    sortDirection,
    onSort,
    offset,
    className,
}: RenderHeaderCellProps<T>) => {
    const {
        tbHead,
        tbHeadSortable,
        tbHeadSorted,
        tbHeadIconSortable,
        tbHeadIconSorted,
        tbTextCenter,
        tbTextRight,
    } = useStyles();

    const sortedByColumn = sortColumn === column.name;
    //What will be sort direction when user clicks current column
    const newSortDirection = sortedByColumn
        ? sortDirection === 'asc'
            ? 'desc'
            : 'asc'
        : DEFAULT_SORT_DIRECTION;

    const widthCss =
        column.width &&
        GivelifyTableDynamicStyles({
            width: column.width,
            fixed: column.fixed,
            offset: offset[columnIndex],
        }).tbCellWidth;

    const headClassName = mergeClassNames(
        tbHead,
        column.canSort && tbHeadSortable,
        column.headerAlign === 'center' && tbTextCenter,
        column.headerAlign === 'right' && tbTextRight,
        sortedByColumn && tbHeadSorted,
        widthCss,
        className,
    );

    return (
        <th className={headClassName}>
            {column.renderHeader ? (
                column.renderHeader()
            ) : (
                <div
                    id={column.headerLabelId || ''}
                    style={{
                        display: 'flex',

                        justifyContent:
                            column.headerAlign === 'center'
                                ? 'center'
                                : column.headerAlign === 'right'
                                ? 'flex-end'
                                : 'flex-start',
                        alignItems: 'center',
                    }}
                    onClick={() => {
                        if (!column.canSort) return;
                        onSort?.(newSortDirection, column.name);
                    }}
                >
                    <div>
                        {column.label && (
                            <GivelifyLabel
                                variant="heading5"
                                text={column.label}
                            />
                        )}
                        {column.labelSecondRow && (
                            <GivelifyLabel
                                variant="heading5"
                                text={column.labelSecondRow}
                            />
                        )}
                    </div>
                    {column.canSort && (
                        <GivelifyIcon
                            variant={
                                sortedByColumn
                                    ? sortDirection === 'asc'
                                        ? 'arrow-up'
                                        : 'arrow-down'
                                    : newSortDirection === 'asc'
                                    ? 'arrow-up'
                                    : 'arrow-down'
                            }
                            size={16}
                            className={
                                sortedByColumn
                                    ? tbHeadIconSorted
                                    : tbHeadIconSortable
                            }
                        />
                    )}
                </div>
            )}
        </th>
    );
};

export default RenderHeaderCell;
