import React, { useEffect, useMemo, useState } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    GivelifyRadio,
    GivelifyTextField,
} from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { PollAnswer } from 'api/models';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { webConfig } from 'webConfig';
import { useGivingTueasdayContext } from './context/GivingTuesdayContext';
import { ReactComponent as HeartIconSvg } from './img/heartIcon.svg';

const HeartIcon = styled(HeartIconSvg)(({ theme }) => ({
    width: 105,
    [theme.breakpoints.down('mobile')]: {
        width: 77,
    },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
    marginTop: 32,
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
    },
}));

const PollScreen: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const {
        givingTuesdayPoll,
        givingTuesdayPollIsLoading,
        pollAnswer,
        setPollAnswer,
        backClick,
        confirmAndOptOutClick,
        customPollAnswer,
        setCustomPollAnswer,
    } = useGivingTueasdayContext();

    const [isLoading, setIsLoading] = useState(false);
    const onBackClick = () => {
        if (isLoading) return;

        pushEvent('Click GT23 Opt-out modal Back_btn');
        backClick();
    };

    const confirmClick = () => {
        if (isLoading) return;

        pushEvent('Click GT23 Opt-out modal Confirm_btn');
        setIsLoading(true);
        confirmAndOptOutClick();
    };

    useEffect(() => {
        pushEvent('View GT23 Opt-out modal');
    }, []);

    const toolkitLinkClick = () => {
        pushEvent('Click GT23 Opt-out modal Toolkit_lnk');
    };

    const setPollAnswerInternal = (answer: PollAnswer) => {
        if (answer?.isCustomAnswer) {
            pushEvent('Click GT23 Opt-out modal Other');
        } else {
            pushEvent('Click GT23 Opt-out modal Reason');
        }

        setPollAnswer(answer);
    };

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'givingTuesday.poll',
    });

    const copy = useMemo(
        () => ({
            heading1: scopedTranslate('heading1'),
            heading2: scopedTranslate('heading2'),
            heading3: scopedTranslate('heading3'),
            description1: scopedTranslate('description1'),
            description2: scopedTranslate('description2'),
            description3: scopedTranslate('description3'),
            description4: scopedTranslate('description4'),
            pollHeader: scopedTranslate('pollHeader'),
            customPlaceholder: scopedTranslate('customPlaceholder'),
            backButtonText: scopedTranslate('backButtonText'),
            confirmButtonText: scopedTranslate('confirmButtonText'),
        }),
        [scopedTranslate],
    );

    const { donee } = useSelector((state: AppState) => ({
        donee: state.Donee.donee,
    }));

    const {
        supportChurchGivingTuesdayToolkit,
        supportNonprofitGivingTuesdayToolkit,
    } = webConfig;

    const toolkitUrl =
        donee.type === 'church'
            ? supportChurchGivingTuesdayToolkit
            : supportNonprofitGivingTuesdayToolkit;

    if (givingTuesdayPollIsLoading) return null;

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <HeartIcon />
                <GivelifyLabel
                    style={{
                        marginTop: 16,
                        marginBottom: 24,
                        textAlign: 'center',
                        color: DesignTokens.color.textSecondary,
                    }}
                    variant={isMobile ? 'body1' : 'heading2S'}
                >
                    {`${copy.heading1} `}
                    <span
                        style={{
                            color: DesignTokens.color.textPrimary,
                        }}
                    >
                        {copy.heading2}
                    </span>
                    {` ${copy.heading3}`}
                </GivelifyLabel>
            </div>
            <GivelifyLabel
                style={{
                    color: DesignTokens.color.textSecondary,
                }}
                variant="body2"
            >
                <GivelifyLabel variant="body2B">
                    {copy.description1}
                </GivelifyLabel>
                {` ${copy.description2} `}
                <GivelifyLink
                    href={toolkitUrl}
                    onClick={toolkitLinkClick}
                    target="_blank"
                    text={copy.description3}
                    variant="body2"
                />
                {` ${copy.description4}`}
            </GivelifyLabel>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    marginTop: 24,
                }}
            >
                <GivelifyLabel
                    style={{
                        color: DesignTokens.color.textSecondary,
                    }}
                    text={copy.pollHeader}
                    variant={isMobile ? 'body2' : 'body1'}
                />
                {givingTuesdayPoll.answers.map((answer) => (
                    <GivelifyRadio
                        key={answer.id}
                        checked={pollAnswer?.id === answer.id}
                        label={answer.answer}
                        onChange={() => setPollAnswerInternal(answer)}
                        title={answer.answer}
                    />
                ))}
                {pollAnswer?.isCustomAnswer && (
                    <GivelifyTextField
                        countLength
                        fullWidth
                        maxLength={80}
                        onChange={(evt) =>
                            setCustomPollAnswer(evt.target.value)
                        }
                        placeholder={copy.customPlaceholder}
                        value={customPollAnswer}
                    />
                )}
            </div>
            <ButtonsWrapper>
                <GivelifyButton
                    onClick={onBackClick}
                    text={copy.backButtonText}
                    variant="ghost"
                />
                <GivelifyButton
                    loading={isLoading}
                    onClick={confirmClick}
                    text={copy.confirmButtonText}
                    variant="primary"
                />
            </ButtonsWrapper>
        </>
    );
};

export default PollScreen;
