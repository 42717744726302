import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import NoResultsIcon from '../../../../../../assets/no-results.svg';

interface NoResultComponentProps {
    text: string;
    tip?: boolean;
}

const useStyle = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 54,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 16,
                marginRight: 16,
            },
        },
    });
});

const AutoIntegrationNoResultComponent: React.FC<NoResultComponentProps> = ({
    text,
    tip,
}) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            tip: t('error.noResultsScreenTip'),
        }),
        [t],
    );
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <img alt="No Results" src={NoResultsIcon} />
            <GivelifyLabel
                marginBottom={32}
                marginTop={32}
                text={text}
                variant="heading4"
            />
            {tip && (
                <GivelifyLabel
                    color="neutralGrey"
                    text={copy.tip}
                    variant="body1"
                />
            )}
        </div>
    );
};

export default AutoIntegrationNoResultComponent;
