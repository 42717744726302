import { styled } from '@mui/material';

const OnboardingTopSection = styled('div')(({ theme }) => ({
    marginBottom: 40,
    [theme.breakpoints.down('mobile')]: {
        marginBottom: 24,
    },
    overflow: 'auto',
}));

export default OnboardingTopSection;
