import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    checkmarkRadio: {
        padding: '12px 12px',
        marginLeft: 12,
        marginRight: 12,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        justifyContent: 'space-between',
        '& .MuiRadio-root': {
            order: 1,
            marginLeft: 'auto',
            padding: 0,
        },
        '&:last-child': {
            borderBottom: 0,
        },
    },
    checkIcon: {
        fontSize: 14,
    },
    label: {
        fontSize: 14,
    },
    selected: {
        backgroundColor: '#F0F8FD',
    },
}));
