import React from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import View from './view';

const UserDropdownComponent: React.FC = () => {
    const { user } = useSelector((state: AppState) => ({
        user: state.User.user,
    }));

    const { showCaptivePortal } = useCaptivePortalContext();

    return (
        <View
            hideEditProfile={showCaptivePortal}
            userData={{
                avatar: user?.avatar,
                avatarOriginal: user?.avatarOriginal,
                fullName: user?.fullName,
            }}
        />
    );
};

export const UserDropdown = React.memo(UserDropdownComponent);
