import { Tab, TabProps, Tabs, Theme, withStyles } from '@material-ui/core';
import { FontWeightProperty } from '@material-ui/styles/node_modules/csstype';

export const BookTabs = withStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    indicator: {
        backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
        justifyContent: 'flex-end',
    },
}))(Tabs);

export const BookTab = withStyles((theme: Theme) => {
    const spacing1 = theme.spacing(1);
    return {
        root: {
            backgroundColor: '#F7FAFD',
            boxShadow: '0 0 6px 0 rgba(118,142,168,0.42);',
            minHeight: '42px',
            fontWeight: theme.typography
                .fontWeightRegular as FontWeightProperty,
            marginRight: spacing1,
            marginLeft: spacing1,
            borderRadius: '10px 10px 0 0',
            marginTop: '6px',
            [theme.breakpoints.down('sm')]: {
                flex: 1,
            },
            '&:hover': {
                color: '#494A50',
                opacity: 1,
            },
            '&$selected': {
                color: '#494A50',
                fontWeight: 'bold',
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                color: '#494A50',
            },
        },
        selected: {},
    };
})((props: TabProps) => {
    return <Tab disableRipple {...props} />;
});
