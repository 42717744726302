import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Box } from '@material-ui/core';

export interface TableColumnProps {
    column: {
        name: string;
        upperTitle?: string;
        lowerTitle?: string;
    };
    align?: string; //'left' | 'right';
}

export const HeaderContentComponent = props => {
    const {
        column: { lowerTitle, upperTitle },
    } = props as TableColumnProps;

    return (
        <Box flex={1}>
            <GivelifyLabel
                bold
                fontSize={14}
                lineHeight={16}
                text={upperTitle}
                whiteSpace="nowrap"
            />
            <GivelifyLabel
                bold
                fontSize={14}
                lineHeight={16}
                text={lowerTitle}
                whiteSpace="nowrap"
            />
        </Box>
    );
};
