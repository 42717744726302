import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { LabelVariant } from './types';

export const mobileStyleMap: { [x in LabelVariant]?: LabelVariant } = {
    heading1: 'h1Mobile',
    heading2: 'h2Mobile',
    heading3: 'h3Mobile',
    heading4: 'h4Mobile',
    heading5: 'h4Mobile',
};

export const moreLabelStyles = makeStyles((theme: Theme) =>
    createStyles({
        inline: {
            display: 'inline !important',
        },
        link: {
            color: '#715AFF',
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
                color: '#5F48F0',
                textDecoration: 'underline',
            },
        },
    }),
);
