import React from 'react';
import { DesignTokens, GivelifyIcon } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import {
    styled,
    TableCell,
    TableCellProps,
    TableHead,
    TableRow,
} from '@mui/material';
import { DataTableSortArrow } from './DataTable';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& .dt-sort-icon-root': {
        position: 'relative',
    },
    '& .dt-sort-icon': {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translate(100%, -50%)',
        fontSize: DesignTokens.measurement.iconDefaultSize,
        [theme.breakpoints.down('tablet')]: {
            fontSize: DesignTokens.textStyle.globalHeadingS2.font.size,
        },
    },
    '& .hidden': {
        visibility: 'hidden',
    },
}));

const StyledTableRow = styled(TableRow)({
    boxSizing: 'border-box',
    border: `1px solid ${DesignTokens.color.dividerPrimaryLight}`,
    borderLeft: 'none',
    borderRight: 'none',
    borderCollapse: 'collapse',
    height: '64px',
});

const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (propName) => propName !== 'active',
})<TableCellProps & { active?: boolean }>(({ align, theme, active }) => ({
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 0,
    color: active
        ? DesignTokens.color.globalInfo400
        : DesignTokens.color.textSecondary,
    fontSize: DesignTokens.textStyle.globalBody1.font.size,
    lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
    paddingLeft: align === 'left' ? '12px' : 0,
    paddingRight: align === 'right' ? '12px' : 0,
    [theme.breakpoints.down('tablet')]: {
        fontSize: DesignTokens.textStyle.globalBody2.font.size,
        lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalData1.font.size,
        lineHeight: `${DesignTokens.textStyle.globalData1.font.lineHeight}px`,
    },
}));

export type DataTableColumn<T> = {
    field: keyof T;
    label: string;
    align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
    onRender?: (item: T, loading?: boolean) => JSX.Element;
    format?: (item: T) => string;
    noPadding?: boolean;
};

export type DataTableHeaderProps<T> = {
    columns?: DataTableColumn<T>[];
    sortColumn?: keyof T;
    onSortClick?: (field: keyof T) => void;
    loading?: boolean;
    className?: string;
    columnDirectionArrow: DataTableSortArrow;
};

const DataTableHeaderComponent = <T,>({
    columns,
    sortColumn,
    columnDirectionArrow,
    onSortClick,
    loading,
    className,
}: DataTableHeaderProps<T>): JSX.Element => {
    const onSortClickCallback = React.useCallback(
        (field: keyof T) => {
            if (onSortClick && !loading) {
                onSortClick(field);
            }
        },
        [onSortClick, loading],
    );
    return (
        <StyledTableHead className={className}>
            <StyledTableRow className="data-table-header-row">
                {columns.map((col, i) => (
                    <StyledTableCell
                        key={`tb-hd-${col.field.toString()}`}
                        active={sortColumn === col.field}
                        align={col.align}
                        aria-label={`${col.label} column`}
                        className={mergeClassNames(
                            'th-cell',
                            `th-cell-${col.field.toString()}`,
                        )}
                        data-testid={`th-cell-${col.field.toString()}`}
                        onClick={() => onSortClickCallback(col.field)}
                    >
                        <span
                            aria-label={`${col.label} sort`}
                            className={'dt-sort-icon-root'}
                        >
                            <span data-testid={`th-cell-${i}-text`}>
                                {col.label}
                            </span>
                            <GivelifyIcon
                                className={mergeClassNames(
                                    'dt-sort-icon',
                                    sortColumn !== col.field && 'hidden',
                                )}
                                color={DesignTokens.color.globalInfo400}
                                variant={columnDirectionArrow}
                            />
                        </span>
                    </StyledTableCell>
                ))}
            </StyledTableRow>
        </StyledTableHead>
    );
};

export const DataTableHeader = React.memo(
    DataTableHeaderComponent,
) as typeof DataTableHeaderComponent;
