import React from 'react';
import { GivelifyLabel, GivelifyPaper, GivelifyLabelProps } from '@givelify/ui';
import { styled } from '@mui/material';

interface OverviewPaperProps {
    title?: string;
    icon?: React.ReactNode;
    variant?: GivelifyLabelProps['variant'];
    ['data-testid']?: string;
}

const StyledPaper = styled(GivelifyPaper)(({ theme }) => ({
    padding: '24px 32px',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'initial',
    [theme.breakpoints.down('mobile')]: {
        padding: '16px 16px 24px',
    },
}));

const TitleWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginBottom: 32,
});

const Title = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: 16,
        lineHeight: '18px',
    },
}));

const OverviewPaperComponent: React.FC<OverviewPaperProps> = ({
    children,
    title,
    icon,
    variant = 'heading2S',
    'data-testid': dataTestId,
}) => {
    return (
        <StyledPaper translate={undefined}>
            {title ? (
                <TitleWrapper>
                    <Title
                        data-testid={dataTestId}
                        text={title}
                        variant={variant}
                    />
                    {icon}
                </TitleWrapper>
            ) : null}
            {children}
        </StyledPaper>
    );
};

export const OverviewPaper: typeof OverviewPaperComponent = React.memo(
    OverviewPaperComponent,
);
