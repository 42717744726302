import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FontWeightProperty } from '@material-ui/styles/node_modules/csstype';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { CreateCSSProperties } from '@material-ui/styles';

type GivelifyDropDownStyleProps = {
    width: number | 'auto' | '100%';
} & GivelifyBoxMarginProps;

export const GivelifyDropDownStyles = makeStyles((_theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyDropDownStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyDropDownStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyDropDownStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyDropDownStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        drdRoot: {
            position: 'relative',
            width: (props: GivelifyDropDownStyleProps) =>
                props.width === 'auto' ? 'auto' : props.width,
        },
        drdControls: {
            display: 'flex',
            alignItems: 'center',
        },
        drdClearIcon: {
            fontSize: 12,
        },
        drdOpenIcon: {
            fontSize: 9,
        },
        drdInputPadding: {
            paddingRight: 55,
            textOverflow: 'ellipsis',
        },
    }),
);

type GivelifyDropDownOptionsStyleProps = {
    hasHeader: boolean;
    hasFooter: boolean;
    menuHeight?: 'auto' | number;
    width: number | 'auto' | '100%';
};

export const GivelifyDropDownOptionsStyles = makeStyles((theme: Theme) =>
    createStyles({
        drdOptions: {
            boxSizing: 'border-box',
            maxHeight: (props: GivelifyDropDownOptionsStyleProps) =>
                props.menuHeight ? props.menuHeight : 336,
            width: (props: GivelifyDropDownStyleProps) => props.width,
            boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.73)',
            borderRadius: 4,
            backgroundColor: theme.colors
                ? theme.colors.primaryWhite
                : theme.palette.background.default,
            paddingTop: (props: GivelifyDropDownOptionsStyleProps) =>
                props.hasHeader ? 0 : 8,
            paddingBottom: (props: GivelifyDropDownOptionsStyleProps) =>
                props.hasFooter ? 0 : 8,
            overflowX: 'hidden',
            overflowY: (props: {
                hasHeader: boolean;
                hasFooter: boolean;
                menuHeight?: 'auto' | number;
            }) =>
                props.menuHeight
                    ? props.menuHeight === 'auto'
                        ? 'hidden'
                        : 'auto'
                    : 'auto',
            marginTop: 4,
        } as CreateCSSProperties,
        drdOptionUp: {
            top: 'calc(100% - 22px)',
        },
        drdOptionHeading: {
            boxSizing: 'border-box',
            display: 'flex',
            height: 40,
            paddingLeft: 16,
            alignItems: 'center',
        },
        drdOptionHeader: {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.background.default,
        },
        drdOptionFooter: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: theme.palette.background.default,
        },
    }),
);

export const GivelifyDropDownSingleLineOptionStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            drdOptionRoot: {
                boxSizing: 'border-box',
                display: 'flex',
                minHeight: 48,
                paddingRight: 16,
                '&:hover': {
                    backgroundColor: theme.colors
                        ? theme.colors.neutralHoverGrey
                        : '#F6F6F6',
                },
                '&:focus': {
                    backgroundColor: theme.colors
                        ? theme.colors.neutralHoverGrey
                        : '#F6F6F6',
                },
                cursor: 'pointer',
                outline: 'none',
            },
            drdActiveOption: {
                backgroundColor: theme.colors
                    ? theme.colors.neutralHoverGrey
                    : '#F6F6F6',
            },
            drdCheckRoot: {
                width: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            drdCheckIcon: {
                color: theme.colors ? theme.colors.neutralGrey : '#676E77',
                fontSize: '18px',
            },
            drdContentRoot: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                fontFamily: theme.gas.font,
                fontWeight: theme.typography
                    .fontWeightRegular as FontWeightProperty,
                fontSize: 14,
                lineHeight: '22px',
                color: theme.colors ? theme.colors.primaryDarkGray : '#373739',
            },
            drdOptionContentHideMark: {
                paddingLeft: 16,
            },
        }),
);

export const GivelifyDropDownDoubleLineOptionStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            drdOptionRoot: {
                boxSizing: 'border-box',
                display: 'flex',
                minHeight: 64,
                paddingRight: 16,
                '&:hover': {
                    backgroundColor: theme.colors
                        ? theme.colors.neutralHoverGrey
                        : '#F6F6F6',
                },
                '&:focus': {
                    backgroundColor: theme.colors
                        ? theme.colors.neutralHoverGrey
                        : '#F6F6F6',
                },
                cursor: 'pointer',
                outline: 'none',
            },
            drdActiveOption: {
                backgroundColor: theme.colors
                    ? theme.colors.neutralHoverGrey
                    : '#F6F6F6',
            },
            drdCheckRoot: {
                width: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            drdCheckIcon: {
                color: theme.colors ? theme.colors.neutralGrey : '#676E77',
                fontSize: '18px',
            },
            drdContentRoot: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            drdOptionContentHideMark: {
                paddingLeft: 16,
            },
            drdLabel: {
                marginBottom: 2,
                boxSizing: 'border-box',
                lineHeight: '22px',
                cursor: 'pointer',
            },
            drdDescription: {
                marginTop: 2,
                color: theme.colors ? theme.colors.neutralGrey : '#676E77',
                boxSizing: 'border-box',
                cursor: 'pointer',
            },
        }),
);

type GivelifyMenuStyleProps = {
    width: number | 'auto' | '100%';
    size: 'normal' | 'small';
} & GivelifyBoxMarginProps;

export const GivelifyMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyMenuStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyMenuStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyMenuStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyMenuStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        mnRoot: {
            position: 'relative',
            width: (props: GivelifyMenuStyleProps) =>
                props.width === 'auto' ? 'auto' : props.width,
        },
        mnSelect: {
            width: (props: GivelifyMenuStyleProps) =>
                props.width === 'auto' ? 'auto' : '100%',
            background: theme.gas.palette.background.primary,
            height: (props: GivelifyMenuStyleProps) =>
                props.size === 'normal' ? 48 : 32,
            borderRadius: 100,
            paddingLeft: 22,
            paddingRight: 32,
            fontFamily: theme.gas.font,
            fontSize: (props: GivelifyMenuStyleProps) =>
                props.size === 'normal' ? 16 : 14,
            lineHeight: (props: GivelifyMenuStyleProps) =>
                props.size === 'normal' ? '22px' : '24px',
            fontWeight: 700,
            textAlign: 'left',
            color: theme.gas.palette.label.primary,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.colors.grey500,
            cursor: 'pointer',
            position: 'relative',
            '&:focus': {
                borderColor: theme.colors.primary,
                outline: 'none',
            },
            '&::-ms-expand': {
                display: 'none',
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        mnSelectFocused: {
            borderColor: theme.colors.primary,
        },
        mnSelectSuccess: {
            borderColor: theme.gas.palette.state.success.border,
        },
        mnSelectWarning: {
            borderColor: theme.gas.palette.state.warning.border,
        },
        mnSelectError: {
            borderColor: theme.gas.palette.state.error.border,
        },
        mnDown: {
            width: 9,
            height: 9,
            fontSize: 9,
            transition: 'transform 0.3s',
            justifySelf: 'flex-end',
            position: 'absolute',
            top: 'calc(50% - 4.5px)',
            right: 13,
            transformOrigin: 'center center',
        },
        mnUp: {
            transform: 'rotate(180deg)',
            '-ms-transform': 'rotate(180deg)',
            '-webkit-transform': 'rotate(180deg)',
        },
    }),
);
