import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            clipRule="evenodd"
            d="M4.93925 14.941C4.658 15.2223 4.5 15.6038 4.5 16.0016V18.5016C4.5 19.33 5.17157 20.0016 6 20.0016H8.5C8.89787 20.0016 9.27944 19.8436 9.56075 19.5622L19.2073 9.91411C19.9882 9.13305 19.9881 7.86681 19.2071 7.0858L17.4142 5.29291C16.6331 4.51181 15.3667 4.51187 14.5857 5.29302L4.93925 14.941ZM14.0608 7.93948L15.6464 6.3536C15.8417 6.15831 16.1583 6.1583 16.3536 6.35357L18.1465 8.14646C18.3417 8.34171 18.3417 8.65827 18.1465 8.85354L16.5608 10.4395L14.0608 7.93948ZM13.0002 9.00023L6 16.0016V18.5016H8.5L15.5002 11.5002L13.0002 9.00023Z"
            fill="#5F47F6"
            fillRule="evenodd"
        />
    </svg>
);

export const EditIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgEdit} fontSize={fontSize} />
);
