import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 32,
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            marginTop: 16,
            marginBottom: 16,
        },
        '& .text': {
            marginRight: 16,
        },
        '& .button': {
            marginLeft: 'auto',
            minWidth: 200,
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
}));

export default useStyles;
