import React, { useMemo } from 'react';
import { GivelifyLoading } from '@givelify/givelify-ui';
import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
import { useAdvancedTranslation } from 'utils/i18n';

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '100px'
}));

const Loading = styled(GivelifyLoading)(() => ({
    color: DesignTokens.color.iconPrimary,
    strokeLinecap: 'round',
}));

const Message = styled('div')(() => ({
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    marginTop: '16px'
}));

export const AutoIntegrationSyncLoader: React.FC = () => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            line1: t(
                'pages.integrations.content.setup-dialog.planningCenterSetup.syncProgress1',
            ),
            line2: t(
                'pages.integrations.content.setup-dialog.planningCenterSetup.syncProgress2',
            ),
        }),
        [t],
    );

    return (
        <Container>
            <Loading type="circular" />
            <Message>
                <div>{copy.line1}</div>
                <div>{copy.line2}</div>
            </Message>
        </Container>
    );
};
