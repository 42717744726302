import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PageTitleProps {
    title: string;
    description: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta content={description} name="description" />
        </Helmet>
    );
};

export default PageTitle;
