const streetRegex = /([A-z]+\s\d+)|(\d([a-zA-Z0-9]?)+\s[A-z]+)/;
const POBOXES = [
    'Box *',
    'Box-*',
    'Box*',
    'HC73 P.O. Box *',
    'P O Box*',
    'P. O. Box',
    'P.O *',
    'P.O*',
    'P.O',
    'PO.*',
    'PO. *',
    'PO.-*',
    'P.O. Box *',
    'P.O. Box',
    'P.O.B *',
    'P.O.B. *',
    'P.O.B. #*',
    'P.O.B.',
    'P0 Box',
    'PO *',
    'P.O. *',
    'P.O.',
    'PO Box N',
    'PO Box',
    'PO-Box',
    'POB *',
    'POB',
    'POBOX*',
    'POBOX *',
    'Po Box',
    'Post *',
    'Post Box *',
    'Post Office Box *',
    'Post Office Box',
    'box #*',
    'box *',
    'number *',
    'p box',
    'p-o box',
    'p-o-box',
    'p.o box',
    'p.o. box',
    'p.o.-box',
    'p.o.b. #*',
    'p.o.b.',
    'p/o box',
    'po #*',
    'po box *',
    'po box',
    'po num*',
    'po-box',
    'post office box',
    'Post Box #*',
    'postofficebox',
    '* PO Box',
    '* P.O. Box',
    '* P.O.B.',
    '* PO Box',
    'PO-*',
    'PO*',
    'PO.B.',
    'P.O.B',
    'PO.B',
    'P.OB.',
];

const POBOXES_REGEX = POBOXES.map(
    (pobox) =>
        pobox.toLowerCase().replace(/\./g, '\\.').replace(/\*/g, '\\d+') +
        '\\s?',
);

const combinedRegex = new RegExp(POBOXES_REGEX.join('|'), 'i');

export const containsPOBox = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    return combinedRegex.test(lowerCaseText);
};

export const isValidStreet = (
    street: string,
    isPOBoxNotAllowed: boolean,
): boolean => {
    if (street.length < 2) return false;
    try {
        if (!streetRegex.test(street)) return false;
    } catch (e) {
        return false;
    }
    if (isPOBoxNotAllowed) {
        return !containsPOBox(street);
    }
    return true;
};
