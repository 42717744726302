import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyDropDownSingleLineOptionStyles } from './styles';
import { GivelifyIcon } from '../components/GivelifyIcon';
import { GivelifyRadio } from '../radio';
import { GivelifyCheckbox } from '../checkbox';

export interface SingleLineOptionProps {
    value: string;
    label: string;
    onSelect: (value: string) => void;
    selected?: boolean;
    active?: boolean;
    checkMarkVariant?: 'mark' | 'radio' | 'checkbox';
    hideCheckMark?: boolean;
    classes?: {
        root?: string;
        checkRoot?: string;
        checkIcon?: string;
        contentRoot?: string;
        activeOption?: string;
        label?: string;
    };
    disableAutoScroll?: boolean;
}

export function SingleLineOption(props: SingleLineOptionProps) {
    const {
        value,
        label,
        selected,
        active,
        checkMarkVariant = 'mark',
        hideCheckMark,
        onSelect,
        disableAutoScroll,
        classes,
    } = props;
    const innerRef = React.useRef<HTMLDivElement | null>(null);
    const {
        drdOptionRoot,
        drdCheckRoot,
        drdCheckIcon,
        drdContentRoot,
        drdActiveOption,
        drdOptionContentHideMark,
    } = GivelifyDropDownSingleLineOptionStyles();

    const activeOptionClassName = mergeClassNames(
        drdActiveOption,
        classes?.activeOption ? classes.activeOption : '',
    );
    const rootClassName = mergeClassNames(
        drdOptionRoot,
        active && activeOptionClassName,
        classes?.root ? classes.root : '',
    );
    const checkIconClassName = mergeClassNames(
        drdCheckIcon,
        classes?.checkIcon ? classes.checkIcon : '',
    );
    const contentRootClassName = mergeClassNames(
        drdContentRoot,
        hideCheckMark && drdOptionContentHideMark,
        classes?.contentRoot ? classes.contentRoot : '',
        classes?.label ? classes.label : '',
    );
    const checkRootClassName = mergeClassNames(
        drdCheckRoot,
        classes?.checkRoot ? classes.checkRoot : '',
    );

    const onClick = React.useCallback(() => {
        onSelect(value);
    }, [onSelect, value]);

    React.useEffect(() => {
        if (active) {
            innerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
    }, [active, innerRef]);

    React.useEffect(() => {
        if (!disableAutoScroll && selected) {
            innerRef.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [selected, innerRef, disableAutoScroll]);

    return (
        <div
            id={`option-${value}`}
            className={rootClassName}
            onClick={onClick}
            tabIndex={1}
            ref={innerRef}
            data-testid={`option-${value}`}
        >
            {!hideCheckMark && (
                <div className={checkRootClassName}>
                    {checkMarkVariant === 'checkbox' ? (
                        <GivelifyCheckbox
                            name={`option-${value}-radio`}
                            ariaLabel={`option-${value}-radio`}
                            checked={selected ? selected : false}
                            variant="primary"
                        />
                    ) : checkMarkVariant === 'radio' ? (
                        <GivelifyRadio
                            name={`option-${value}-radio`}
                            ariaLabel={`option-${value}-radio`}
                            checked={selected ? selected : false}
                            variant="primary"
                        />
                    ) : selected ? (
                        <GivelifyIcon
                            variant="check"
                            className={checkIconClassName}
                        />
                    ) : null}
                </div>
            )}
            <div className={contentRootClassName}>{label}</div>
        </div>
    );
}
