import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    GivelifySwitch,
    GivelifyLabel,
    GivelifyLoading,
    FacebookLoading,
    useAdvancedTranslation,
    GivelifyColorPalette,
} from '@givelify/givelify-ui';
import { GivelifyDatePicker } from '@givelify/ui';
import { useApiRequest } from '@givelify/utils';
import { Divider, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import roleTypes from '../../../../../../constants/roleTypes';
import {
    getIntegrationSettings,
    updateIntegrationAutoExport,
} from '../../AutoIntegrationContent/components/automatedIntegrationRequest';
import UpdateCCBForm from '../../AutoIntegrationContent/components/SettingTabContent/UpdateCCBForm';
import { AutoIntegrationSettingData } from '../../AutoIntegrationContent/types';

const FormLoading = () => {
    return (
        <>
            <FacebookLoading
                borderRadius={4}
                height={48}
                marginBottom={32}
                width="100%"
            />
            <FacebookLoading
                borderRadius={4}
                height={48}
                marginBottom={32}
                width="100%"
            />
            <FacebookLoading
                borderRadius={4}
                height={48}
                marginBottom={32}
                width="100%"
            />
        </>
    );
};

export const useCCBSettingStyles = makeStyles((theme: Theme) => ({
    settingDesc: {
        whiteSpace: 'normal',
        fontWeight: 400,
    },
    turnSetting: {
        marginBottom: theme.spacing(6),
    },
    turnDateSetting: {
        marginTop: theme.spacing(4),
    },
    dateBox: {
        marginTop: theme.spacing(4),
    },
    divider: {
        marginBottom: 32,
    },
    header2: {
        marginBottom: 32,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'right',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    errorNotification: {
        marginBottom: theme.spacing(3),
    },
    passwordField: {
        '& input': {
            borderWidth: 1,
            borderColor: GivelifyColorPalette.neutralPlatinum,
            '&:focus, &:focus ~ .labelFocusedRoot:before, &:focus ~ .labelFocusedRoot:after':
                {
                    borderWidth: 2,
                    borderColor: GivelifyColorPalette.primary,
                },
            '&:focus ~ .labelFocusedRoot .labelFocused': {
                color: GivelifyColorPalette.primary,
            },
            '&:hover, &:hover ~ .labelFocusedRoot:before, &:hover ~ .labelFocusedRoot:after':
                {
                    borderColor: GivelifyColorPalette.neutralGrey,
                },
        },
        '& .labelFocusedRoot:before, & .labelFocusedRoot:after': {
            borderWidth: 1,
            borderColor: GivelifyColorPalette.neutralPlatinum,
        },
        '& .labelFocused': {
            color: GivelifyColorPalette.neutralGrey75,
        },
    },
}));

type F1GoAndCCBIntegrationSettingsProps = {
    f1Go?: boolean;
};

export const F1GoAndCCBIntegrationSettings: React.FC<
    F1GoAndCCBIntegrationSettingsProps
> = ({ f1Go }) => {
    const { userRole } = useSelector((state: AppState) => ({
        userRole: state.User.user.role,
    }));

    const { t } = useAdvancedTranslation();
    const classes = useCCBSettingStyles();
    const { doneeId, userDoneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        userDoneeId: state.User.user.doneeId,
    }));
    const translationPrefix = f1Go ? 'f1go' : 'ccb';
    const TRANSLATION_KEY = 'pages.integrations.settings';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${translationPrefix}.${key}`),
        [t, translationPrefix],
    );

    const text = useMemo(
        () => ({
            header1: scopedTranslate('header1'),
            label: scopedTranslate('label'),
            header2: scopedTranslate('header2'),
            exportDate: t(`${TRANSLATION_KEY}.exportDate`),
        }),
        [scopedTranslate, t],
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [getIntegrationSetting, requestIntegrationSetting] =
        useApiRequest<AutoIntegrationSettingData>();
    const [integrationSetting, setIntegrationSetting] =
        useState<AutoIntegrationSettingData>();
    useEffect(() => {
        requestIntegrationSetting(getIntegrationSettings(userDoneeId));
        setLoading(true);
    }, [doneeId, requestIntegrationSetting, userDoneeId]);
    const [checked, setChecked] = useState<boolean>(
        integrationSetting?.data.settings.donationsAutoexport,
    );
    const [startDate, setStartDate] = useState<Dayjs | null>(
        integrationSetting?.data.settings.exportStartDate === null
            ? null
            : dayjs.tz(integrationSetting?.data.settings.exportStartDate),
    );
    const [apiUrl, setApiUrl] = useState<string>('');
    const [defaultUsername, setDefaultUsername] = useState<string>('');
    useEffect(() => {
        if (getIntegrationSetting.type === 'REQUEST_SUCCESS') {
            setIntegrationSetting(getIntegrationSetting.response);
            setChecked(
                getIntegrationSetting.response.data.settings
                    .donationsAutoexport,
            );
            setStartDate(
                getIntegrationSetting.response.data.settings.exportStartDate ===
                    null
                    ? null
                    : dayjs.tz(
                          getIntegrationSetting.response.data.settings
                              .exportStartDate,
                      ),
            );
            setApiUrl(getIntegrationSetting.response.data.credentials?.url);
            setDefaultUsername(
                getIntegrationSetting.response.data.credentials?.username,
            );
            setLoading(false);
        }
    }, [getIntegrationSetting]);
    const [requestAutoExport, makeRequestAutoExport] = useApiRequest();
    const [disabled, setDisabled] = useState<boolean>(false);
    const changeAutoExport = (value: boolean) => {
        setChecked(value);
        makeRequestAutoExport(
            updateIntegrationAutoExport(userDoneeId, value, startDate),
        );
        setDisabled(true);
    };
    const changeAutoExportDateToggle = (value: boolean) => {
        const date = value ? dayjs().tz() : null;
        setStartDate(date);
        makeRequestAutoExport(
            updateIntegrationAutoExport(userDoneeId, checked, date),
        );
        setDisabled(true);
    };
    const onDateChange = (date: Dayjs) => {
        setStartDate(date);
        makeRequestAutoExport(
            updateIntegrationAutoExport(userDoneeId, checked, date),
        );
        setDisabled(true);
    };
    useEffect(() => {
        if (requestAutoExport.type === 'REQUEST_SUCCESS') {
            setDisabled(false);
        }
    }, [requestAutoExport]);

    const isReadOnly =
        userRole !== roleTypes.ADMIN && userRole !== roleTypes.FINANCIAL;

    return (
        <>
            <div className={classes.turnSetting}>
                <GivelifyLabel bold text={text.header1} variant="heading5" />
                <GivelifyLabel
                    className={classes.settingDesc}
                    marginBottom={16}
                    marginTop={8}
                    text={text.label}
                    variant="body1"
                />
                {loading && <GivelifyLoading type="circular" />}
                {!loading && (
                    <GivelifySwitch
                        checked={checked}
                        disabled={disabled}
                        onChange={changeAutoExport}
                        variant="thick-labeled"
                    />
                )}
                {f1Go && checked && (
                    <div className={classes.turnDateSetting}>
                        <GivelifyLabel
                            className={classes.settingDesc}
                            marginBottom={16}
                            marginTop={8}
                            text={text.exportDate}
                            variant="body1"
                        />
                        {loading && <GivelifyLoading type="circular" />}
                        {!loading && (
                            <GivelifySwitch
                                checked={startDate !== null}
                                disabled={disabled}
                                onChange={changeAutoExportDateToggle}
                                variant="thick-labeled"
                            />
                        )}
                        {!loading && startDate ? (
                            <div className={classes.dateBox}>
                                <GivelifyDatePicker
                                    onDateChange={onDateChange}
                                    value={startDate}
                                />
                            </div>
                        ) : null}
                    </div>
                )}
            </div>

            <Divider className={classes.divider} />
            <GivelifyLabel
                bold
                marginBottom={32}
                text={text.header2}
                variant="heading5"
            />
            <Grid container>
                <Grid item lg={6} md={8} xs={12}>
                    {loading && <FormLoading />}
                    {!loading && (
                        <UpdateCCBForm
                            defaultName={defaultUsername}
                            defaultUrl={apiUrl}
                            isReadOnly={isReadOnly}
                            loading={loading}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
