import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

const title = 'title';
const action = 'action';
const shortName = 'shortName';
const address = 'address';

const inactive = 'inactive';
const actionWrapper = 'actionWrapper';
const edit = 'edit';

const useStyles = makeStyles((theme: Theme) => {
    const mobile = theme.breakpoints.down('sm');

    return {
        wrapper: {
            padding: '13px 13px 16px 16px',
            borderRadius: 10,
            boxShadow: '0px 0px 6px rgba(118, 142, 168, 0.4)',
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto 1fr auto',
            gridTemplateAreas: `
			"${shortName} ${shortName} ${edit}"
			"${title} ${title} ${title}"
			"${address} ${address} ${address}"
			"${action} . ."
			`,
            [theme.breakpoints.up('md')]: {
                gap: 4,
                padding: 16,
                paddingRight: 32,
                gridTemplateColumns: '1fr 170px 120px',
                gridTemplateAreas: `
			"${shortName} ${action} ${edit}"
			"${title} ${action} ${edit}"
			"${address} ${action} ${edit}"
			`,
            },
        },
        [inactive]: {
            [`& > *:not($${actionWrapper}):not($${edit})`]: {
                opacity: 0.5,
            },
        },
        [title]: {
            gridArea: title,
            [mobile]: {
                marginTop: 10,
                marginBottom: 8,
            },
        },
        [actionWrapper]: {
            gridArea: action,
            display: 'flex',
            alignItems: 'center',
            justifySelf: 'center',
            color: `${GivelifyColorPalette.neutralGrey} !important`,
            fontSize: 16,
            '& svg': {
                marginRight: 8,
            },
            minWidth: 200,
        },
        [shortName]: {
            gridArea: shortName,
            [theme.breakpoints.up('md')]: {
                marginBottom: 4,
            },
            maxWidth: 550,
        },
        [address]: {
            gridArea: address,
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: 20,
            },
            maxWidth: 550,
        },
        [edit]: {
            gridArea: edit,
            justifySelf: 'end',
            width: 24,
            height: 24,
            minWidth: 24,
            minHeight: 24,
            padding: 0,
            '& svg': {
                fontSize: 24,
            },
        },
        menuPaper: {
            boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.73)',
            width: 248,
        },
        menuItem: {
            paddingTop: 11,
            paddingBottom: 11,
            paddingLeft: 16,
        },
        pendingIcon: {
            color: '#DC9F00',
        },
    };
});

export default useStyles;
