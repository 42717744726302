import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    socialItemValue: {
        marginBottom: 14,
        display: 'flex',
        alignItems: 'center',
    },
    imgContainer: {
        minWidth: 28,
        height: 28,
        marginRight: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    linkContainer: {
        width: 'calc(100% - 44px)',
    },
    valLink: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        color: theme.gas.palette.primary,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        '&:visited': {
            textDecoration: 'none',
            color: theme.gas.palette.primary,
        },
    },
}));

interface SocialItemProps {
    imgSrc: string;
    imgAlt: string;
    link: string;
    isReadOnly: boolean;
}

const SocialItem: React.FC<SocialItemProps> = (props: SocialItemProps) => {
    const {
        socialItemValue,
        imgContainer,
        valLink,
        linkContainer,
    } = useStyles();

    return (
        <div className={socialItemValue}>
            <div className={imgContainer}>
                <img alt={props.imgAlt} src={props.imgSrc} />
            </div>
            <div className={linkContainer}>
                {props.isReadOnly ? (
                    <GivelifyLabel text={props.link} variant="body1" />
                ) : (
                    <a className={valLink} href={props.link} target="blank">
                        {props.link}
                    </a>
                )}
            </div>
        </div>
    );
};

export default SocialItem;
