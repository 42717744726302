import { RequestState } from '@givelify/utils';
import { GetLifeTimeDonationsResponse } from '../../../api/services/responses/widgets';
import { SET_LIFE_TIME, SetLifeTime } from './types';

export type SetLifeTimePayload = {
    doneeId: number;
    response: RequestState<GetLifeTimeDonationsResponse>;
};

export const setLifeTime = (payload: SetLifeTimePayload): SetLifeTime => ({
    type: SET_LIFE_TIME,
    payload,
});
