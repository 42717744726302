import { DesignTokens } from '@givelify/ui';
import { makeStyles, Theme } from '@material-ui/core';

export const inviteUserModalStyle = makeStyles((theme: Theme) => ({
    stepHeader: {
        color: theme.colors.neutralGrey,
        marginBottom: 32,
    },
    allFieldText: {
        color: theme.colors.neutralGrey,
        fontSize: 12,
        lineHeight: '20px',
        marginBottom: 8,
    },
    marginBottom16: {
        marginBottom: 16,
    },
    firstNameBlock: {
        paddingRight: 8,
    },
    lastNameBlock: {
        paddingLeft: 8,
    },
    detailRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
        color: theme.colors.primaryDarkGray,
    },
    permissionCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContent: {
        width: '100%',
        maxWidth: 336,
        background: '#FFFFFF',
        boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
        borderRadius: '10px',
        cursor: 'pointer',
        border: '1px solid transparent',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 32,
    },
    detailContent: {
        height: 336,
        padding: '0px 24px',
        '@media (max-width: 860px)': {
            height: 380,
        },
        '@media (max-width: 760px)': {
            height: 400,
        },
        '@media (max-width: 700px)': {
            height: 425,
        },
    },
    detailText: {
        fontSize: 16,
        color: theme.colors.primaryDarkGray,
    },
    cardFooter: {
        display: 'flex',
        justifyContent: 'center',
        background: theme.colors.neutralHoverGrey,
        borderTop: '1px solid #D8D8D8',
        borderRadius: '0px 0px 10px 10px',
    },
    radioButton: {
        padding: '5.5px 0px',
    },
    radioButtonSelected: {
        padding: '4.5px 0px',
    },
    checkIcon: {
        marginRight: 12,
    },
    cardSelected: {
        border: '1px solid #715AFF',
        filter: 'drop-shadow(0px 0px 8px rgba(152, 162, 173, 0.4))',
    },
    titleSelect: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            background: '#FFFFFF',
            padding: '0px 8px',
        },
    },
    titleSelectHelperText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 26,
    },
    cardDisabled: {
        border: 'none',
        cursor: 'default',
        '& *': {
            color: DesignTokens.color.textDisabled,
        },
    }
}));
