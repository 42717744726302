import React, { useMemo } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import {
    Box,
    makeStyles,
    Paper,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core';
import Space from 'components/Space';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import IntegrationsBanner from './IntegrationsBanner';

const useStyle = makeStyles((theme: Theme) => ({
    congratulationContainer: {
        marginTop: theme.spacing(5),
        paddingTop: theme.spacing(5),
        borderTop: '1px solid ' + theme.palette.divider,
    },
    congratulationContent: {
        padding: theme.spacing(5),
    },
    borderBottom: {
        borderBottom: '1px solid ' + theme.palette.divider,
    },
    congratTitle: {
        fontSize: 24,
        lineHeight: '26px',
        fontWeight: 700,
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    onClose: () => void;
    integrationReportType: IntegrationTypes;
}

export const IntegrationSetupCongratulationsBanner: React.FC<Props> = ({
    onClose,
    integrationReportType,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyle({});

    const copy = useMemo(
        () => ({
            title: t('pages.integrations_banner.title'),
            description: t('pages.integrations_banner.description', {
                reportType: integrationReportType.name,
            }),
            title2: t('pages.integrations_banner.title_2'),
            continue: t('labels.continue'),
        }),
        [t, integrationReportType],
    );

    return (
        <div className={classes.congratulationContainer}>
            <Paper>
                <Box className={classes.congratulationContent}>
                    <Box className={classes.borderBottom}>
                        <Typography
                            align="center"
                            className={classes.congratTitle}
                            variant="h1"
                        >
                            {copy.title}
                        </Typography>
                        <Typography align="center" variant="body1">
                            {copy.description}
                        </Typography>
                        <Space gap={5} />
                    </Box>
                    <Box paddingTop={5}>
                        <Typography align="center" variant="h5">
                            {copy.title2}
                        </Typography>
                        <Space gap={5} />
                        <IntegrationsBanner
                            integrationReportType={integrationReportType}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        paddingTop={theme.spacing(1)}
                    >
                        <GivelifyButton
                            name="continue"
                            onClick={onClose}
                            size="xLarge"
                            text={copy.continue}
                            variant="primary"
                        />
                    </Box>
                </Box>
            </Paper>
        </div>
    );
};
