import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDarkCheckbox = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>dark-checkbox</title>
        <rect fill="current" height="20" rx="2" width="20" x="2" y="2" />
    </svg>
);

export const DarkCheckboxIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDarkCheckbox}
        fontSize={fontSize}
    />
);
