import React from 'react';
import { SortingDirection, Sorting } from '@devexpress/dx-react-grid';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { Grid, TableSortLabel, Tooltip } from '@material-ui/core';
import { matchRowStyle } from 'pages/integrations/components/style';

interface HeaderCellOwnProps {
    name: string;
    label: string;
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: () => void;
    sortLabel: string;
}

export const HeaderCell: React.FC<HeaderCellOwnProps> = ({
    name,
    label,
    onOrderClick,
    sortColumn,
    sortDirection,
    sortLabel,
}) => (
    <Tooltip aria-label={sortLabel} title={sortLabel}>
        <TableSortLabel
            active={sortColumn === name}
            direction={sortColumn === name ? sortDirection : 'asc'}
            onClick={onOrderClick}
        >
            {label}
        </TableSortLabel>
    </Tooltip>
);

interface DonorMatchingHeaderProps {
    sortColumn: string;
    sortDirection: SortingDirection;
    onOrderClick: (sorting: Sorting[]) => void;
    copy: {
        donorName: string;
        phone: string;
        lastDonationsDate: string;
        memberId: string;
        sort: string;
    };
}

const DonorMatchingHeader: React.FC<DonorMatchingHeaderProps> = ({
    sortColumn,
    sortDirection,
    onOrderClick,
    copy,
}) => {
    const classes = matchRowStyle();

    return (
        <Grid container alignItems="center" className={classes.matchHeader}>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={3}
                md={3}
                sm={4}
                xs={4}
            >
                <HeaderCell
                    label={copy.donorName}
                    name={'name'}
                    onOrderClick={() =>
                        onOrderClick([
                            {
                                columnName: 'name',
                                direction:
                                    sortDirection === 'asc' ? 'desc' : 'asc',
                            },
                        ])
                    }
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortLabel={copy.sort}
                />
            </Grid>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={3}
                md={3}
                sm={4}
                xs={4}
            >
                <HeaderCell
                    label="Email"
                    name={'email'}
                    onOrderClick={() =>
                        onOrderClick([
                            {
                                columnName: 'email',
                                direction:
                                    sortDirection === 'asc' ? 'desc' : 'asc',
                            },
                        ])
                    }
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortLabel={copy.sort}
                />
            </Grid>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={2}
                md={2}
                sm={2}
                xs={2}
            >
                {copy.phone}
            </Grid>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={2}
                md={2}
                sm={2}
                xs={2}
            >
                <HeaderCell
                    label={copy.lastDonationsDate}
                    name={'last_donation_date'}
                    onOrderClick={() =>
                        onOrderClick([
                            {
                                columnName: 'last_donation_date',
                                direction:
                                    sortDirection === 'asc' ? 'desc' : 'asc',
                            },
                        ])
                    }
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    sortLabel={copy.sort}
                />
            </Grid>
            <Grid
                item
                className={classes.matchHeaderItem}
                lg={2}
                md={2}
                sm={4}
                xs={4}
            >
                <GivelifyLabel
                    bold
                    className={classes.headerItemRight}
                    text={copy.memberId}
                    variant="body2"
                />
            </Grid>
        </Grid>
    );
};

export default DonorMatchingHeader;
