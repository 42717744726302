import React, { useState } from 'react';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName } from '@givelify/utils';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import permissionTypes from 'constants/permissionTypes';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import permissionsByPath from 'utils/permissionsByPath';
import { PATH } from '../../../../router/routes';
import { messengerStyles } from '../style';
import Message, { MessageProps } from './Message';
import MessengerTextField from './MessengerTextField';

interface MessengerProps {
    donationNoteId: number;
    messages: MessageProps[];
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        marginTop: 5,
    },
    primary: {
        color: theme.colors.primaryDarkGray,
    },
    hint: {
        marginTop: 4,
    },
}));

const Messenger: React.FC<MessengerProps> = ({
    donationNoteId,
    messages,
    className,
}) => {
    const { avatar } = useStyles();

    const [messageList, setMessageList] = useState(messages);
    const { messengerTextBoxStyles } = messengerStyles();
    const user = useSelector((state: AppState) => state.User.user);
    const onSubmit = (text: string) => {
        setMessageList([
            ...messageList,
            {
                message: text,
                name: user.fullName,
                avatarSrc: user.profilePicUrl,
            },
        ]);
    };
    const showResponseInput =
        permissionsByPath[PATH.DONATIONS.DONATIONS_ACTIVITY][user?.role] ===
        permissionTypes.FULL_ACCESS;

    return (
        <div className={className}>
            {messageList.map((m, i) => (
                <Message key={i} {...m} />
            ))}
            {showResponseInput && donationNoteId && messageList.length === 1 ? (
                <div className={messengerTextBoxStyles}>
                    <GivelifyAvatar
                        className={avatar}
                        color="eucalyptus"
                        size="xSmall"
                        src={user.profilePicUrl}
                        text={getInitialsFromName(user.fullName)}
                    />
                    <div className="text-area">
                        <MessengerTextField
                            donationNoteId={donationNoteId}
                            onPressEnter={onSubmit}
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default Messenger;
