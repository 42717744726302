import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

export const GivelifyTabStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyBoxMarginProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyBoxMarginProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyBoxMarginProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyBoxMarginProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        tRoot: {
            boxSizing: 'border-box',
            width: '100%',
            height: 38,
            backgroundColor: theme.gas.palette.background.primary,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.gas.palette.misc.separator,
            borderBottomWidth: 1,
            display: 'grid',
            gridAutoFlow: 'column',
            gridColumnGap: '32px',
            justifyItems: 'start',
            justifyContent: 'start',
        },
        tBadge: {
            boxSizing: 'border-box',
            minWidth: 18,
            backgroundColor: theme.gas.palette.primary,
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: 800,
            color: theme.gas.palette.label.contrast,
            borderRadius: 20,
            padding: '2px 5px',
            position: 'absolute',
            right: 0,
            top: 0,
            transform: 'translate(100%, -100%)',
        },
        tItemSelected: {
            [theme.breakpoints.down(375)]: {
                fontSize: 14,
                lineHeight: '24px',
                fontWeight: 600,
            },
            height: 38,
            padding: 0,
            border: 'none',
            display: 'flex',
            alignItems: 'flex-start',
            backgroundColor: 'initial',
            cursor: 'pointer',
            position: 'relative',
            fontWeight: 800,
            outline: 'none',
            fontSize: '16px',
            lineHeight: '20px',
            color: theme.gas.palette.primary,
            '&::before': {
                content: '""',
                background: theme.gas.palette.primary,
                position: 'absolute',
                left: '0px',
                right: '0px',
                bottom: '0px',
                width: '100%',
                height: '6px',
                borderRadius: '4px 4px 0px 0px',
            },
        },
        tItem: {
            [theme.breakpoints.down(375)]: {
                fontSize: 14,
                lineHeight: '24px',
            },
            height: 38,
            padding: 0,
            border: 'none',
            display: 'flex',
            alignItems: 'flex-start',
            backgroundColor: 'initial',
            cursor: 'pointer',
            position: 'relative',
            color: theme.gas.palette.label.secondary,
            fontWeight: 600,
            outline: 'none',
            fontSize: '16px',
            lineHeight: '20px',
        },
        tabLink: {
            textDecoration: 'none',
            color: theme.gas.palette.label.secondary,
            fontWeight: 600,
            outline: 'none',
        },
        tabSelected: {
            textDecoration: 'none',
            fontWeight: 800,
            outline: 'none',
            color: theme.gas.palette.primary,
            '&::before': {
                content: '""',
                background: theme.gas.palette.primary,
                position: 'absolute',
                left: '0px',
                right: '0px',
                bottom: '0px',
                width: '100%',
                height: '6px',
                borderRadius: '4px 4px 0px 0px',
            },
        }
    }),
);
