import { useCallback } from 'react';
import {
    useRequestState,
    requestError,
    requestStart,
    requestSuccess,
} from '@givelify/givelify-ui';
import { Method, AxiosResponse } from 'axios';
import toCamelCase from 'camelcase-keys';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleErrorMessages } from '../store/thunks';
import { setAccessToken } from '../store/user/actions';
import { accessToken } from '../store/user/reducer';
import { axiosClient } from './api';

/**
 * Hook returns a function that can be used to make requests at a later time
 * (Such as in response to user interactions)
 *
 * @param {string} url - endpoint for the request
 * @param {Method} method - Http request method
 * @param {T} data
 */
export const useInvokeApi = <Response, Request = unknown>(camelCase?: true) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [requestState, setRequestState] = useRequestState<Response>();
    const makeRequest = useCallback(
        (method: Method, url: string, data?: Request) => {
            setRequestState(requestStart());
            axiosClient(url, {
                method,
                data,
                headers: {
                    Authorization: accessToken,
                },
            })
                .then((result: AxiosResponse<Response>) => {
                    setRequestState(
                        requestSuccess(
                            camelCase
                                ? toCamelCase(result.data, { deep: true })
                                : result.data,
                        ),
                    );
                })
                .catch((e) => {
                    if (e.response && e.response.status === 401) {
                        if (accessToken) {
                            dispatch(setAccessToken(undefined));
                        }
                    }
                    setRequestState(requestError(handleErrorMessages(e, t)));
                });
        },
        [setRequestState, t, dispatch, camelCase],
    );
    return [requestState, makeRequest] as const;
};

export type InvokeApiErrorResponse = { error: { message: string } };
