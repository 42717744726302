import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';

export const HideButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
    width: 64,
    '& .hide-icon': {
        width: 28,
        height: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    '&:hover': {
        '& .hide-icon': {
            background: DesignTokens.color.backgroundComponentPrimaryHover,
        },
    },
}));
