import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Donee } from '@givelify/givelify-ui';
import RoleTypes from 'constants/roleTypes';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { loadDonee, resendInviteEmail } from 'store/donee/thunks';
import {
    loadDoneeNotifications,
    updateNotification,
} from 'store/doneeNotification/thunks';
import { DoneeNotificationState } from 'store/doneeNotification/types';
import { getOfficers, getOfficerTitles } from 'store/settings/officers/thunks';
import { Officer } from 'store/settings/officers/types';
import { editUser, updateContactInfo } from 'store/user/thunks';
import { User } from 'store/user/types';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import ResendInviteModal from '../ResendInviteModal';
import { UserFormData } from '../UserContactInfoModal/types';
import { CfroContext } from './CfroContext';
import { CurrentStep, UserInfo } from './types';

export const useCfroContext = () => useContext(CfroContext);

const getCurrentStep = (
    doneeNotifications: DoneeNotificationState,
    donee: Donee,
): CurrentStep => {
    const {
        hideContactInfoNotification,
        hideInviteFaithLeaderNotification,
        hideResendInviteNotification,
    } = doneeNotifications;
    // This flag is set by API. Its true when step 1 is finished
    if (!hideContactInfoNotification) return 'Confirm';

    const { active, invitationEmailSent } = donee.onboarding.appProfile
        ? donee.onboarding.appProfile.faithLeader
        : { active: false, invitationEmailSent: false };

    if (active) return 'Finished';

    // If user clicked close button on step 2
    if (hideInviteFaithLeaderNotification) return 'Finished';
    if (!invitationEmailSent) return 'Invite';

    //If user clicked close button on step 3
    if (hideResendInviteNotification) return 'Finished';
    if (invitationEmailSent) return 'Resend';

    return 'Finished';
};

export const CfroProviderInternal: React.FC = ({ children }) => {
    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();

    const { donee, officers, doneeNotifications } = useSelector(
        (state: AppState) => ({
            donee: state.Donee.donee,
            officers: state.Settings.Officers.officers,
            officersLoading: state.Settings.Officers.officersLoading,
            officerTitlesLoading: state.Settings.Officers.officerTitlesLoading,
            doneeNotifications: state.DoneeNotifications,
        }),
    );

    const doneeId = donee.id;

    const convertUserInfo = useCallback((userInfo: User | Officer) => {
        if (!userInfo) return undefined;

        const userInfoConverted: UserInfo = {
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            title: userInfo.title,
            email: userInfo.email,
            role: userInfo.role,
        };

        return userInfoConverted;
    }, []);

    const faithLeader = useMemo(() => {
        const userId = donee?.onboarding.appProfile.faithLeader.userId;

        return officers.find((officer) => officer.id === userId);
    }, [officers, donee]);

    const faithLeaderInfo = useMemo(
        () => convertUserInfo(faithLeader),
        [convertUserInfo, faithLeader],
    );

    const accountOwner = useMemo(() => {
        return officers.find((officer) => officer.isAccountHolder === true);
    }, [officers]);

    const accountOwnerInfo = useMemo(
        () => convertUserInfo(accountOwner),
        [accountOwner, convertUserInfo],
    );

    const [isDataReady, setIsDataReady] = useState(false);

    const currentStep: CurrentStep = useMemo(
        () => getCurrentStep(doneeNotifications, donee),
        [doneeNotifications, donee],
    );

    const showCfroPanel = useMemo(
        () =>
            isDataReady &&
            (currentStep === 'Confirm' ||
                currentStep === 'Invite' ||
                currentStep === 'Resend'),
        [isDataReady, currentStep],
    );

    const [cfroModalOpen, setCfroModalOpen] = useState(false);
    const onOpenCfroModal = useCallback(() => {
        setCfroModalOpen(true);
    }, []);
    const onCloseCfroModal = useCallback(() => {
        setCfroModalOpen(false);
    }, []);

    const [resendModalOpen, setResendModalOpen] = useState(false);
    const onOpenResendModal = useCallback(() => {
        setResendModalOpen(true);
    }, []);
    const onCloseResendModal = useCallback(() => {
        setResendModalOpen(false);
    }, []);

    const loadData = useCallback(async () => {
        setIsDataReady(false);

        const officersPromise = dispatch(getOfficers(doneeId));
        const titlesPromise = dispatch(getOfficerTitles());
        const [officersSuccess, titlesSuccess] = await Promise.all([
            officersPromise,
            titlesPromise,
        ]);

        setIsDataReady(officersSuccess && titlesSuccess);
    }, [dispatch, doneeId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const onSaveUserInfo = useCallback(
        async (data: UserFormData) => {
            try {
                if (currentStep === 'Confirm') {
                    const { firstName, lastName, title, email } = data;
                    const updateContactInfoResponse = await dispatch(
                        updateContactInfo({
                            firstName,
                            lastName,
                            title,
                            email,
                        }),
                    );
                    if (!updateContactInfoResponse.success)
                        return updateContactInfoResponse;

                    await dispatch(loadDoneeNotifications(doneeId));
                } else if (currentStep === 'Invite') {
                    const updateUserResponse = await dispatch(
                        editUser(false, data),
                    );
                    if (!updateUserResponse.success) return updateUserResponse;

                    const resendResponse = await dispatch(
                        resendInviteEmail(doneeId, data.id),
                    );
                    if (!resendResponse.success) return resendResponse;
                }

                await Promise.all([
                    dispatch(loadDonee(false)),
                    dispatch(getOfficers(doneeId)),
                    dispatch(getOfficerTitles()),
                ]);

                onCloseCfroModal();
            } catch {
                /* Do nothing*/
            }
        },
        [currentStep, dispatch, doneeId, onCloseCfroModal],
    );

    const onCloseInvitePanel = () => {
        if (currentStep === 'Invite') {
            dispatch(
                updateNotification('hideInviteFaithLeaderNotification', true),
            );
        } else if (currentStep === 'Resend') {
            dispatch(updateNotification('hideResendInviteNotification', true));
        }
    };

    const onSubmitResendModal = async () => {
        await dispatch(resendInviteEmail(doneeId, faithLeaderInfo?.id));
        dispatch(updateNotification('hideResendInviteNotification', true));
        onCloseResendModal();
    };

    return (
        <CfroContext.Provider
            value={{
                currentStep: currentStep,
                showCfroPanel,

                cfroModalOpen,
                onOpenCfroModal,
                onCloseCfroModal,

                faithLeaderInfo,
                accountOwnerInfo,
                onSaveUserInfo,

                resendModalOpen,
                onOpenResendModal,
                onSubmitResendModal,
                onCloseResendModal,

                onCloseInvitePanel,
            }}
        >
            <ResendInviteModal />
            {children}
        </CfroContext.Provider>
    );
};

export const CfroProvider: React.FC = ({ children }) => {
    const { user, donee, enabledFeatures, doneeNotifications, campuses } =
        useSelector((state: AppState) => ({
            user: state.User.user,
            donee: state.Donee.donee,
            enabledFeatures: state.System.enabledFeatures,
            doneeNotifications: state.DoneeNotifications,
            campuses: state.Donee.campuses,
        }));

    const { featureCfroEnabled } = useMemo(
        () => ({
            featureCfroEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.SHOW_CFRO,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const currentStep: CurrentStep = useMemo(
        () => getCurrentStep(doneeNotifications, donee),
        [doneeNotifications, donee],
    );

    const doneeIsChurch = donee.type === 'church';
    const isAccountOwner =
        donee?.onboarding.appProfile.accountOwner.userId === user.id;
    const isAdmin = user.role === RoleTypes.ADMIN;
    const isHQ = donee.id === campuses[0].id;

    const cfroEnabled =
        featureCfroEnabled &&
        doneeIsChurch &&
        (isAccountOwner || isAdmin) &&
        isHQ &&
        currentStep !== 'Finished';

    if (!cfroEnabled) return <div>{children}</div>;

    return <CfroProviderInternal>{children}</CfroProviderInternal>;
};
