import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLock = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>lock</title>
        <path
            clipRule="evenodd"
            d="M17.25 6.625C17.25 3.93261 15.0674 1.75 12.375 1.75H11.625C8.93261 1.75 6.75 3.93261 6.75 6.625L6.75 10H6C5.17157 10 4.5 10.6716 4.5 11.5V20.5C4.5 21.3284 5.17157 22 6 22H18C18.8284 22 19.5 21.3284 19.5 20.5V11.5C19.5 10.6716 18.8284 10 18 10H17.25V6.625ZM15.75 10V6.625C15.75 4.76104 14.239 3.25 12.375 3.25H11.625C9.76104 3.25 8.25 4.76104 8.25 6.625L8.25 10H15.75ZM6.75 11.5L6 11.5V20.5H18V11.5L17.25 11.5H6.75Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const LockIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgLock} fontSize={fontSize} />
);
