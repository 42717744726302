import React from 'react';
import {
    isFailed,
    isLoading,
    isSucceeded,
    responseOrUndefined,
    useApiRequest,
} from '@givelify/utils';
import { usePaginationFilter } from 'api/hooks';
import { DonationMessages, initialPagination, Pagination } from 'api/models';
import { GetPagedDonationsMessagesResponse } from 'api/services/responses/donations';
import { getDonationsService } from 'api/utils/serviceProvider';
import { useDonorProfileContext } from 'pages/newDonorProfile/DonorProfileContext';
import { useTranslation } from 'react-i18next';
import { mapDonationsResponseDataToDonation } from 'types/donorTypes';
import { DonorDonationMessageHistoryTable } from '../donorDonationMessageHistoryTable';
import { Heading, TableWrapper } from './styles';

const MessageHistoryTabComponent: React.FC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            noMessages: t('pages.donor_details.noMessages'),
            memoHeading: t('pages.donor_details.memo_history'),
        }),
        [t],
    );

    const { doneeId, donor, donorId, timeFrame } = useDonorProfileContext();

    const [pagination, setPagination] =
        React.useState<Partial<Pagination>>(initialPagination);
    const [messages, setMessages] = React.useState<DonationMessages[]>([]);
    const [paginationFilter, setPaginationFilter] = usePaginationFilter({
        page: 1,
    });

    const [getDonationsMessagesRequest, makeGetDonationsMessagesRequest] =
        useApiRequest<GetPagedDonationsMessagesResponse>();

    const donationsService = getDonationsService();

    React.useEffect(() => {
        void makeGetDonationsMessagesRequest(
            donationsService.getDonationsMessages(
                doneeId,
                donorId,
                timeFrame.start,
                timeFrame.end,
                paginationFilter.page,
            ),
        );
    }, [
        paginationFilter.page,
        timeFrame,
        doneeId,
        donorId,
        makeGetDonationsMessagesRequest,
        donationsService,
    ]);

    React.useEffect(() => {
        if (isSucceeded(getDonationsMessagesRequest)) {
            const donations = responseOrUndefined(getDonationsMessagesRequest);
            setMessages(donations.data);
            setPagination(donations.pagination);
        } else if (isFailed(getDonationsMessagesRequest)) {
            setMessages([]);
            setPagination(initialPagination);
        }
    }, [getDonationsMessagesRequest]);

    const onPageChange = React.useCallback(
        (page: number) => {
            setPaginationFilter({ page });
        },
        [setPaginationFilter],
    );

    const donationsMessages = React.useMemo(
        () => messages?.map((d) => mapDonationsResponseDataToDonation(d)) || [],
        [messages],
    );

    return (
        <TableWrapper
            data-testid="message-tab-content"
            isLoading={isLoading(getDonationsMessagesRequest)}
        >
            <Heading
                isEmpty={donationsMessages.length === 0}
                text={copy.memoHeading}
                variant="heading1S"
            />

            <DonorDonationMessageHistoryTable
                donations={donationsMessages}
                donor={donor}
                isLoading={isLoading(getDonationsMessagesRequest)}
                pageChange={onPageChange}
                pagination={pagination}
                timeFrame={timeFrame}
            />
        </TableWrapper>
    );
};
export const MessageHistoryTab = React.memo(MessageHistoryTabComponent);
