import React, { useEffect, useState } from 'react';
import { isValidPhoneNumber } from '@givelify/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useCampusesContext } from '../CampusesProvider';
import { OrganizationSuggestionsResponse } from './SearchForm/types';
import { FormProps } from './types';
import View from './view';

const AddCampus = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const { addCampus, addCampusRequestState, setShowConfirmModal } =
        useCampusesContext();

    const { doneeName } = useSelector((state: AppState) => ({
        doneeName: state.Donee.donee.name,
    }));

    const form = useForm<FormProps>({
        mode: 'onBlur',
    });

    const { watch, setValue, handleSubmit, setError, reset } = form;
    const vls = watch();

    const onSubmit = () => {
        handleSubmit(() => setShowConfirmModal(true))();
    };
    useEffect(() => {
        if (
            addCampusRequestState.type === 'REQUEST_ERROR' &&
            addCampusRequestState.error.response.status === 422
        ) {
            setError('nickname', {
                type: 'unique',
            });
        }
    }, [addCampusRequestState, setError]);

    useEffect(() => {
        if (currentStep === 0) reset();
    }, [currentStep, reset]);

    const onConfirmOk = () => {
        setShowConfirmModal(false);
        addCampus({
            name: vls.name,
            address: vls.address,
            city: vls.city,
            nickname: vls.nickname,
            phone: vls.phone,
            state: vls.state,
            zip: vls.zip,
            campusId: vls.campusId,
            ypId: vls.ypId,
        });
    };

    const onCampusSelect = (value?: OrganizationSuggestionsResponse) => {
        const isCampusSelectedFromList = !!value;

        form.register('address');
        form.register('campusSelected');
        form.register('city');
        form.register('nickname');
        form.register('name');
        form.register(
            'phone',
            !isCampusSelectedFromList && {
                required: {
                    message: 'This field is required',
                    value: true,
                },
                validate: (value: string) => {
                    const isValid = isValidPhoneNumber(value);
                    if (!isValid) return 'Invalid phone number';
                },
            },
        );
        form.register('state');
        form.register('zip');
        form.register('authorized', {
            required: true,
        });
        form.register('campusId');
        form.register('ypId');

        if (isCampusSelectedFromList) {
            setValue('campusId', value.id);
            setValue('address', value.source.address);
            setValue('city', value.source.city);
            setValue('phone', value.source.phone);
            setValue('zip', value.source.zip);
            setValue('name', value.source.name);
            setValue('state', value.source.state);
            setValue('ypId', value.source.ypId);
        } else {
            setValue('name', doneeName);
        }

        setValue('campusSelected', isCampusSelectedFromList);
        setCurrentStep(1);
    };

    return (
        <FormProvider {...form}>
            <form>
                <View
                    currentStep={currentStep}
                    onCampusSelect={onCampusSelect}
                    onConfirm={onConfirmOk}
                    onSubmit={onSubmit}
                    setCurrentStep={setCurrentStep}
                />
            </form>
        </FormProvider>
    );
};

const WrappedModal = () => {
    const { showAddCampusModal } = useCampusesContext();

    if (!showAddCampusModal) return null;
    return <AddCampus />;
};

export default WrappedModal;
