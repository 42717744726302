import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDonorsOld = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={40}
        width={45}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 45 40"
    >
        <path
            d="M13.7903 15.7143C9.37778 15.7143 5.80645 12.1996 5.80645 7.85714C5.80645 3.51464 9.37778 0 13.7903 0C18.2029 0 21.7742 3.51464 21.7742 7.85714C21.7742 12.1996 18.2029 15.7143 13.7903 15.7143ZM19.3064 18.5714C23.8745 18.5714 27.5806 22.0268 27.5806 26.2857V28.2143C27.5806 29.9888 26.0364 31.4286 24.1331 31.4286H3.44758C1.54423 31.4286 0 29.9888 0 28.2143V26.2857C0 22.0268 3.70612 18.5714 8.27421 18.5714H8.87034C10.3715 19.2411 12.0235 19.6429 13.7903 19.6429C15.5572 19.6429 17.2164 19.2411 18.7103 18.5714H19.3064ZM34.1129 15.7143C30.5066 15.7143 27.5806 12.8348 27.5806 9.28571C27.5806 5.73661 30.5066 2.85714 34.1129 2.85714C37.7193 2.85714 40.6452 5.73661 40.6452 9.28571C40.6452 12.8348 37.7193 15.7143 34.1129 15.7143ZM36.985 17.1429C41.4148 17.1429 45 20.7214 45 25.1429C45 27.0357 43.4614 28.5714 41.565 28.5714H28.9272C28.9343 28.4215 28.9701 28.2714 28.9701 28.1143V25.3714C28.9701 22.6143 27.8752 20.1214 26.129 18.2429C27.3098 17.5643 28.6552 17.1429 30.1151 17.1429H30.387C31.3817 17.4857 32.4337 17.7143 33.55 17.7143C34.6664 17.7143 35.7184 17.4857 36.7131 17.1429H36.985Z"
            fill="current"
        />
        <path
            d="M21.7746 40C27.3865 40 31.9359 35.5228 31.9359 30C31.9359 24.4772 27.3865 20 21.7746 20C16.1626 20 11.6133 24.4772 11.6133 30C11.6133 35.5228 16.1626 40 21.7746 40Z"
            fill="white"
        />
        <path
            d="M21.7741 21.4287C26.5855 21.4287 30.4838 25.2651 30.4838 30.0001C30.4838 34.7352 26.5855 38.5716 21.7741 38.5716C16.9627 38.5716 13.0645 34.7352 13.0645 30.0001C13.0645 25.2651 16.9627 21.4287 21.7741 21.4287ZM22.5338 34.5716C23.719 34.5644 24.6836 33.4252 24.6774 32.0216C24.6774 30.893 24.0406 29.8858 23.1264 29.5716L20.8568 28.7894C20.5763 28.6894 20.3777 28.3573 20.3777 27.9787C20.3777 27.518 20.6677 27.143 21.0239 27.143H22.4424C22.663 27.143 22.8774 27.2073 23.0697 27.3359C23.2714 27.4716 23.5204 27.4609 23.6907 27.2787L24.0689 26.8751C24.3117 26.6216 24.2707 26.1859 23.9965 25.9751C23.5614 25.643 23.0539 25.4573 22.5338 25.4359V24.8573C22.5338 24.543 22.3068 24.2859 22.0294 24.2859H21.5251C21.2477 24.2859 21.0207 24.543 21.0207 24.8573V25.4287C19.8355 25.4323 18.8709 26.5751 18.8709 27.9787C18.8709 29.1073 19.5077 30.1144 20.4218 30.4287L22.6977 31.2109C22.9783 31.3109 23.1769 31.643 23.1769 32.0216C23.1769 32.4823 22.8869 32.8573 22.5307 32.8573H21.1122C20.8915 32.8573 20.6771 32.793 20.4849 32.6644C20.2831 32.5287 20.0341 32.5394 19.8639 32.7216L19.4856 33.1251C19.2429 33.3787 19.2839 33.8144 19.5581 34.0251C19.9931 34.3573 20.5006 34.543 21.0207 34.5644V35.143C21.0207 35.4573 21.2477 35.7144 21.5251 35.7144H22.0294C22.3068 35.7144 22.5338 35.4573 22.5338 35.143V34.5716Z"
            fill="current"
        />
    </svg>
);

export const DonorsOldIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDonorsOld}
        fontSize={fontSize}
    />
);
