import { createTheme } from '@mui/material';
import { DesignTokens } from '../specify';

export type GivelifyColor = keyof typeof DesignTokens.color;
export type GivelifyColorOrString =
    | GivelifyColor
    | (string & { fromT?: unknown });

export const GivelifyTheme = createTheme({
    typography: {
        fontFamily: 'AreaNormal',
        fontWeightBold: 800,
        fontWeightMedium: 700,
        fontWeightRegular: 600,
        fontWeightLight: 400,
        htmlFontSize: DesignTokens.textStyle.globalBody1.font.size,
        fontSize: DesignTokens.textStyle.globalBody1.font.size,
        allVariants: {
            color: DesignTokens.color.textPrimary,
        },
        heading1: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingL1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingL1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingL1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingL1.font.size,
            '@media (max-width:1280.5px)': {
                color: DesignTokens.color.textPrimary,
                lineHeight: `${DesignTokens.textStyle.globalHeadingM1.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalHeadingM1.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalHeadingM1.letterSpacing,
                fontSize: DesignTokens.textStyle.globalHeadingM1.font.size,
            },
            '@media (max-width:768.5px)': {
                color: DesignTokens.color.textPrimary,
                lineHeight: `${DesignTokens.textStyle.globalHeadingS1.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalHeadingS1.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalHeadingS1.letterSpacing,
                fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
            },
            '&:focus': {
                outline: 'none',
            },
        },
        heading2: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingL2.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingL2.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingL2.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingL2.font.size,
            '@media (max-width:1280.5px)': {
                color: DesignTokens.color.textPrimary,
                lineHeight: `${DesignTokens.textStyle.globalHeadingM2.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalHeadingM2.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalHeadingM2.letterSpacing,
                fontSize: DesignTokens.textStyle.globalHeadingM2.font.size,
            },
            '@media (max-width:768.5px)': {
                color: DesignTokens.color.textPrimary,
                lineHeight: `${DesignTokens.textStyle.globalHeadingS2.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalHeadingS2.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalHeadingS2.letterSpacing,
                fontSize: DesignTokens.textStyle.globalHeadingS2.font.size,
            },
        },
        heading3: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingL3.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingL3.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingL3.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingL3.font.size,
            '@media (max-width:1280.5px)': {
                color: DesignTokens.color.textPrimary,
                lineHeight: `${DesignTokens.textStyle.globalHeadingM3.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalHeadingM3.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalHeadingM3.letterSpacing,
                fontSize: DesignTokens.textStyle.globalHeadingM3.font.size,
            },
            '@media (max-width:768.5px)': {
                color: DesignTokens.color.textPrimary,
                lineHeight: `${DesignTokens.textStyle.globalHeadingS3.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalHeadingS3.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalHeadingS3.letterSpacing,
                fontSize: DesignTokens.textStyle.globalHeadingS3.font.size,
            },
        },
        heading1M: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingM1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingM1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingM1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingM1.font.size,
            '&:focus': {
                outline: 'none',
            },
        },
        heading2M: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingM2.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingM2.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingM2.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingM2.font.size,
        },
        heading3M: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingM3.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingM3.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingM3.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingM3.font.size,
        },
        heading1S: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingS1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingS1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingS1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
            '&:focus': {
                outline: 'none',
            },
        },
        heading2S: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingS2.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingS2.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingS2.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingS2.font.size,
        },
        heading3S: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalHeadingS3.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalHeadingS3.font.weight,
            letterSpacing: DesignTokens.textStyle.globalHeadingS3.letterSpacing,
            fontSize: DesignTokens.textStyle.globalHeadingS3.font.size,
        },
        body1: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalBody1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalBody1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalBody1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalBody1.font.size,
        },
        body1B: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalBody1B.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalBody1B.font.weight,
            letterSpacing: DesignTokens.textStyle.globalBody1B.letterSpacing,
            fontSize: DesignTokens.textStyle.globalBody1B.font.size,
        },
        body2: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalBody2.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalBody2.font.weight,
            letterSpacing: DesignTokens.textStyle.globalBody2.letterSpacing,
            fontSize: DesignTokens.textStyle.globalBody2.font.size,
        },
        body2B: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalBody2B.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalBody2B.font.weight,
            letterSpacing: DesignTokens.textStyle.globalBody2B.letterSpacing,
            fontSize: DesignTokens.textStyle.globalBody2B.font.size,
        },
        caption1: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalCaption1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalCaption1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalCaption1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalCaption1.font.size,
        },
        caption1B: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalCaption1B.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalCaption1B.font.weight,
            letterSpacing: DesignTokens.textStyle.globalCaption1B.letterSpacing,
            fontSize: DesignTokens.textStyle.globalCaption1B.font.size,
        },
        caption2: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalCaption2.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalCaption2.font.weight,
            letterSpacing: DesignTokens.textStyle.globalCaption2.letterSpacing,
            fontSize: DesignTokens.textStyle.globalCaption2.font.size,
            textTransform: 'uppercase',
        },
        caption2B: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalCaption2B.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalCaption2B.font.weight,
            letterSpacing: DesignTokens.textStyle.globalCaption2B.letterSpacing,
            fontSize: DesignTokens.textStyle.globalCaption2B.font.size,
            textTransform: 'uppercase',
        },
        data1: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalData1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalData1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalData1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalData1.font.size,
        },
        data1B: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalData1B.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalData1B.font.weight,
            letterSpacing: DesignTokens.textStyle.globalData1B.letterSpacing,
            fontSize: DesignTokens.textStyle.globalData1B.font.size,
        },
        componentInput: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalComponentInput.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalComponentInput.font.weight,
            letterSpacing:
                DesignTokens.textStyle.globalComponentInput.letterSpacing,
            fontSize: DesignTokens.textStyle.globalComponentInput.font.size,
        },
        componentLabel: {
            color: DesignTokens.color.textPrimary,
            lineHeight: `${DesignTokens.textStyle.globalComponentLabel.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalComponentLabel.font.weight,
            letterSpacing:
                DesignTokens.textStyle.globalComponentLabel.letterSpacing,
            fontSize: DesignTokens.textStyle.globalComponentLabel.font.size,
        },
        button: {
            color: DesignTokens.color.textPrimary,
            textTransform: 'none',
            lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalButton1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalButton1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalButton1.font.size,
            [`@media (max-width: 767px)`]: {
                textTransform: 'none',
                lineHeight: `${DesignTokens.textStyle.globalButton1M.font.lineHeight}px`,
                fontWeight: DesignTokens.textStyle.globalButton1M.font.weight,
                letterSpacing:
                    DesignTokens.textStyle.globalButton1M.letterSpacing,
                fontSize: DesignTokens.textStyle.globalButton1M.font.size,
            },
        },
        button1: {
            color: DesignTokens.color.textPrimary,
            textTransform: 'none',
            lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
            fontWeight: DesignTokens.textStyle.globalButton1.font.weight,
            letterSpacing: DesignTokens.textStyle.globalButton1.letterSpacing,
            fontSize: DesignTokens.textStyle.globalButton1.font.size,
        },
    },
    palette: {
        primary: {
            main: DesignTokens.color.globalGuidingViolet400,
        },
        primaryAlt: {
            main: DesignTokens.color.globalInfo400,
        },
        secondary: {
            main: DesignTokens.color.globalGenerosityOrange400,
        },
        warning: {
            main: DesignTokens.color.textWarningDefault,
            '200': DesignTokens.color.globalWarning200,
            '300': DesignTokens.color.globalWarning300,
            '400': DesignTokens.color.globalWarning400,
            '600': DesignTokens.color.globalWarning600,
            '700': DesignTokens.color.globalWarning700,
            '800': DesignTokens.color.globalWarning800,
        },
        success: {
            main: DesignTokens.color.textSuccessDefault,
            '200': DesignTokens.color.globalSuccess200,
            '300': DesignTokens.color.globalSuccess300,
            '400': DesignTokens.color.globalSuccess400,
            '600': DesignTokens.color.globalSuccess600,
            '700': DesignTokens.color.globalSuccess700,
            '800': DesignTokens.color.globalSuccess800,
        },
        error: {
            main: DesignTokens.color.textErrorDefault,
            '200': DesignTokens.color.globalError200,
            '300': DesignTokens.color.globalError300,
            '400': DesignTokens.color.globalError400,
            '600': DesignTokens.color.globalError600,
            '700': DesignTokens.color.globalError700,
            '800': DesignTokens.color.globalError800,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: DesignTokens.measurement.buttonRadius,
                    '&.Mui-disabled': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonDisabled,
                        color: DesignTokens.color.textWhite,
                    },
                },
                sizeLarge: {
                    padding: `${DesignTokens.measurement.buttonPaddingLargeY} ${DesignTokens.measurement.buttonPaddingLargeX}`,
                    fontFamily: 'AreaNormal',
                    lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalButton1.font.weight,
                    letterSpacing:
                        DesignTokens.textStyle.globalButton1.letterSpacing,
                    fontSize: DesignTokens.textStyle.globalButton1.font.size,
                },
                sizeMedium: {
                    padding: `${DesignTokens.measurement.buttonPaddingMediumY} ${DesignTokens.measurement.buttonPaddingMediumX}`,
                    fontFamily: 'AreaNormal',
                    lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalButton1.font.weight,
                    letterSpacing:
                        DesignTokens.textStyle.globalButton1.letterSpacing,
                    fontSize: DesignTokens.textStyle.globalButton1.font.size,
                },
                sizeSmall: {
                    padding: `${DesignTokens.measurement.buttonPaddingSmallY} ${DesignTokens.measurement.buttonPaddingSmallX}`,
                    fontFamily: 'AreaNormal',
                    lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalButton1.font.weight,
                    letterSpacing:
                        DesignTokens.textStyle.globalButton1.letterSpacing,
                    fontSize: DesignTokens.textStyle.globalButton1.font.size,
                },
                text: {
                    fontFamily: 'AreaNormal',
                    lineHeight: `${DesignTokens.textStyle.globalBody1B.font.lineHeight}px`,
                    fontWeight: DesignTokens.textStyle.globalBody1B.font.weight,
                    letterSpacing:
                        DesignTokens.textStyle.globalBody1B.letterSpacing,
                    fontSize: DesignTokens.textStyle.globalBody1B.font.size,
                },
                textSizeLarge: {
                    padding: `${'12px'} ${'8px'}`,
                },
                textSizeMedium: {
                    padding: `${'8px'} ${'8px'}`,
                },
                textSizeSmall: {
                    padding: `${'4px'} ${'8px'}`,
                },
                textPrimary: {
                    color: DesignTokens.color.textAccentDefault,
                    textTransform: 'none',
                    '&:hover': {
                        color: DesignTokens.color.textAccentHover,
                    },
                    '&:active': {
                        color: DesignTokens.color.textAccentPressed,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'initial',
                        color: DesignTokens.color.textDisabled,
                    },
                },
                containedPrimary: {
                    backgroundColor:
                        DesignTokens.color.backgroundButtonPrimaryDefault,
                    color: DesignTokens.color.textWhite,
                    '&:hover': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonPrimaryHover,
                    },
                    '&:active': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonPrimaryPressed,
                    },
                    '&.Mui-disabled': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonDisabled,
                        color: DesignTokens.color.textWhite,
                    },
                },
                outlinedPrimary: {
                    borderColor: DesignTokens.color.borderButtonDefault,
                    color: DesignTokens.color.textAccentDefault,
                    '&:hover': {
                        borderColor: DesignTokens.color.borderButtonHover,
                        color: DesignTokens.color.textAccentHover,
                    },
                    '&:active': {
                        borderColor: DesignTokens.color.borderButtonPressed,
                        color: DesignTokens.color.textAccentPressed,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'initial',
                        borderColor: DesignTokens.color.borderButtonDisabled,
                        color: DesignTokens.color.textDisabled,
                    },
                },
                containedSecondary: {
                    backgroundColor: DesignTokens.color.backgroundButtonSticky,
                    color: DesignTokens.color.textWhite,
                    '&:hover': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonSticky,
                    },
                    '&:active': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonSticky,
                    },
                },
                containedWarning: {
                    backgroundColor: DesignTokens.color.backgroundButtonWarning,
                },
                outlinedWarning: {
                    backgroundColor:
                        DesignTokens.color.backgroundButtonWarningSecondary,
                    '&:hover': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonWarning,
                        color: DesignTokens.color.textWhite,
                    },
                    '&:active': {
                        backgroundColor:
                            DesignTokens.color.backgroundButtonWarning,
                        color: DesignTokens.color.textWhite,
                    },
                },
                startIcon: {
                    lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalButton1.font.weight,
                },
                endIcon: {
                    lineHeight: `${DesignTokens.textStyle.globalButton1.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalButton1.font.weight,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeLarge: {
                    padding: DesignTokens.measurement.iconButtonPaddingLarge,
                    fontSize: DesignTokens.measurement.iconDefaultSize,
                },
                sizeMedium: {
                    padding: DesignTokens.measurement.iconButtonPaddingMedium,
                    fontSize: DesignTokens.measurement.iconDefaultSize,
                },
                sizeSmall: {
                    padding: DesignTokens.measurement.iconButtonPaddingSmall,
                    fontSize: DesignTokens.measurement.iconDefaultSize,
                },
                colorInherit: {
                    color: DesignTokens.color.iconPrimary,
                    '&:hover': {
                        backgroundColor: DesignTokens.color.iconAccentBg,
                    },
                    '&:active': {
                        backgroundColor: DesignTokens.color.iconAccentBg,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    color: DesignTokens.color.textAccentDefault,
                    '&:hover': {
                        color: DesignTokens.color.textAccentHover,
                    },
                    '&:active': {
                        color: DesignTokens.color.textAccentPressed,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: DesignTokens.textStyle.globalComponentInput.color,
                    fontFamily: 'AreaNormal',
                    lineHeight: `${DesignTokens.textStyle.globalComponentInput.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalComponentInput.font.weight,
                    letterSpacing:
                        DesignTokens.textStyle.globalComponentInput
                            .letterSpacing,
                    fontSize:
                        DesignTokens.textStyle.globalComponentInput.font.size,
                    borderRadius: DesignTokens.measurement.inputFieldRadius,
                },
                input: {
                    paddingLeft:
                        DesignTokens.measurement.inputFieldTextLargeLeft,
                    paddingRight:
                        DesignTokens.measurement.inputFieldTextLargeRight,
                    paddingTop: DesignTokens.measurement.inputFieldTextLargeTop,
                    paddingBottom:
                        DesignTokens.measurement.inputFieldTextLargeBottom,
                    borderRadius: DesignTokens.measurement.inputFieldRadius,
                    height: DesignTokens.measurement
                        .inputFieldTextLargeMinHeight,
                },
                inputSizeSmall: {
                    paddingLeft:
                        DesignTokens.measurement.inputFieldTextMediumLeft,
                    paddingRight:
                        DesignTokens.measurement.inputFieldTextMediumRight,
                    paddingTop:
                        DesignTokens.measurement.inputFieldTextMediumTop,
                    paddingBottom:
                        DesignTokens.measurement.inputFieldTextMediumBottom,
                    borderRadius: DesignTokens.measurement.inputFieldRadius,
                    height: DesignTokens.measurement
                        .inputFieldTextMediumMinHeight,
                },
                inputAdornedEnd: {
                    paddingRight: 0,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                contained: {
                    fontFamily: 'AreaNormal',
                    lineHeight: `${DesignTokens.textStyle.globalCaption1.font.lineHeight}px`,
                    fontWeight:
                        DesignTokens.textStyle.globalCaption1.font.weight,
                    letterSpacing:
                        DesignTokens.textStyle.globalCaption1.letterSpacing,
                    fontSize: DesignTokens.textStyle.globalCaption1.font.size,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: DesignTokens.color.avatarViolet,
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    fontSize: DesignTokens.measurement.fontDefaultSize,
                    lineHeight: DesignTokens.measurement.fontDefaultSize,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            smallMobile: 500,
            mobile: 768,
            smallTablet: 1025,
            tablet: 1280,
        },
    },
});
