import {
    ApiResponse,
    getAxiosClient,
    makeApiRequest,
} from '@givelify/utils';
import { ThunkAction } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { loadDoneeNotificationState, setNotification } from './actions';
import { DoneeNotificationState, DoneeNotificationType } from './types';

export const loadDoneeNotifications =
    (
        doneeId: number,
    ): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch) => {
        const httpRequest = getAxiosClient().get(
            `donees/${doneeId}/notification-state`,
        );
        const result = await makeApiRequest<{
            data: { [DoneeNotificationType: string]: boolean }[];
        }>(httpRequest);

        if (!result.success) return false;

        const entries = result.response.data.flatMap(Object.entries);
        const notifications = Object.fromEntries(
            entries,
        ) as DoneeNotificationState;

        dispatch(loadDoneeNotificationState(notifications));

        return true;
    };

export const updateNotification =
    (
        type: DoneeNotificationType,
        value: boolean,
        optimisticUpdate = true,
    ): ThunkAction<
        Promise<ApiResponse<any>>,
        AppState,
        undefined,
        AppActions
    > =>
    async (dispatch, getState) => {
        if (optimisticUpdate) {
            dispatch(setNotification(type, value));
        }

        const doneeId = getState().Donee.donee.id;

        const httpRequest = getAxiosClient().post(
            `donees/${doneeId}/notification-state`,
            {
                type,
                status: value,
            },
        );
        const result = await makeApiRequest<Record<string, unknown>>(
            httpRequest,
        );

        if (!optimisticUpdate) {
            if (result.success) {
                dispatch(setNotification(type, value));
            }
        }

        return result;
    };
