import React, { useEffect, useMemo, useState } from 'react';
import {
    useFacebookShareURL,
    useTwitterShareURL,
} from '@givelify/fundraising-tools';
import { GivelifySnackbar } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    FacebookIcon,
    TwitterIcon,
    GivelifyLink,
} from '@givelify/ui';
import { copyToClipboard, pushEvent } from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/base';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useAdvancedTranslation } from 'utils/i18n';
import { webConfig } from 'webConfig';
import { useGivingTueasdayContext } from './context/GivingTuesdayContext';
import { ReactComponent as HeartIconSvg } from './img/heartIcon.svg';

const Link = styled('a')(() => ({
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const Divider = styled('hr')(({ theme }) => ({
    margin: 0,
    border: 0,
    borderTop: `1px solid ${DesignTokens.color.dividerPrimaryLight}`,
    width: 400,
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.down('mobile')]: {
        borderTop: 0,
        marginTop: 16,
        marginBottom: 16,
        width: 0,
    },
}));

const CopyFieldWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${DesignTokens.color.borderComponentDisabled}`,
    borderRadius: 21,
    paddingLeft: 24,
    marginTop: 16,
    marginBottom: 24,
    width: '100%',
    maxWidth: 500,
}));

const HeartIcon = styled(HeartIconSvg)(({ theme }) => ({
    width: 105,
    [theme.breakpoints.down('mobile')]: {
        width: 77,
    },
}));

const ShareScreen = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const {
        supportChurchGivingTuesdayToolkit,
        supportNonprofitGivingTuesdayToolkit,
    } = webConfig;
    const { onlineGivingURLs, donee } = useSelector((state: AppState) => ({
        onlineGivingURLs: state.System.onlineGivingURLs,
        donee: state.Donee.donee,
    }));

    const givingUrl =
        onlineGivingURLs?.find((i) => i.channel === 'OGA')?.shortLink || '';
    const twitterUrl =
        onlineGivingURLs?.find((i) => i.channel === 'TWT')?.shortLink || '';
    const facebookUrl =
        onlineGivingURLs?.find((i) => i.channel === 'FB')?.shortLink || '';

    const twitterShareURL = useTwitterShareURL(donee.name, twitterUrl);
    const facebookShareURL = useFacebookShareURL(donee.name, facebookUrl);

    const toolkitUrl =
        donee.type === 'church'
            ? supportChurchGivingTuesdayToolkit
            : supportNonprofitGivingTuesdayToolkit;

    const { backClick } = useGivingTueasdayContext();

    const [showSnackbar, setShowSnackbar] = useState(false);
    const handleCopyLinkClick = async () => {
        pushEvent('Click GT23 Share modal Copy Link');
        if (!givingUrl) return;

        const isSuccess = await copyToClipboard(givingUrl);
        if (isSuccess) setShowSnackbar(true);
    };

    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'givingTuesday.share',
    );

    const copy = useMemo(
        () => ({
            copiedNotificationText: scopedTranslate('copiedNotificationText'),
            header: scopedATranslate('header'),
            toolkit1: scopedTranslate('toolkit1'),
            toolkit2: scopedTranslate('toolkit2'),
            shareLabel: scopedTranslate('shareLabel'),
            facebook: scopedTranslate('facebook'),
            twitter: scopedTranslate('twitter'),
            copyLink: scopedTranslate('copyLink'),
            backToStudio: scopedTranslate('backToStudio'),
        }),
        [scopedTranslate, scopedATranslate],
    );

    const backClickInternal = () => {
        pushEvent('Click GT23 Share modal Back to Studio');
        backClick();
    };

    const toolkitLinkClick = () => {
        pushEvent('Click GT23 Share modal Toolkit_lnk');
    };

    useEffect(() => {
        pushEvent('View GT23 Share modal');
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <GivelifySnackbar
                autoHideDuration={5000}
                message={copy.copiedNotificationText}
                onClose={(
                    _: React.SyntheticEvent<unknown, Event>,
                    reason: SnackbarCloseReason,
                ) => {
                    if (reason === 'clickaway') return;
                    setShowSnackbar(false);
                }}
                open={showSnackbar}
            />
            <HeartIcon />
            <GivelifyLabel
                align="center"
                style={{
                    marginTop: 32,
                    marginBottom: 16,
                }}
                text={copy.header}
                variant={isMobile ? 'body1' : 'heading1S'}
            />
            <GivelifyLabel
                align="center"
                style={{
                    color: DesignTokens.color.textSecondary,
                }}
                variant={isMobile ? 'body2' : 'body1'}
            >
                {`${copy.toolkit1} `}
                <GivelifyLink
                    href={toolkitUrl}
                    onClick={toolkitLinkClick}
                    target="_blank"
                    text={copy.toolkit2}
                    variant={isMobile ? 'body2B' : 'body1B'}
                />
            </GivelifyLabel>
            <Divider />
            <GivelifyLabel
                text={copy.shareLabel}
                variant={isMobile ? 'body2' : 'heading3S'}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 24,
                    marginTop: 16,
                }}
            >
                <Link href={facebookShareURL} target="_blank">
                    <FacebookIcon
                        style={{
                            width: 48,
                            height: 48,
                        }}
                    />
                    <GivelifyLabel text={copy.facebook} variant="body2" />
                </Link>
                <Link href={twitterShareURL} target="_blank">
                    <TwitterIcon
                        style={{
                            width: 48,
                            height: 48,
                        }}
                    />
                    <GivelifyLabel text={copy.twitter} variant="body2" />
                </Link>
            </div>
            <CopyFieldWrapper>
                <GivelifyLabel
                    style={{
                        color: DesignTokens.color.textSecondary,
                        flex: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    text={givingUrl}
                    variant="body1"
                />
                <GivelifyButton
                    onClick={handleCopyLinkClick}
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                    text={copy.copyLink}
                    variant="ghost"
                />
            </CopyFieldWrapper>
            <GivelifyButton
                onClick={backClickInternal}
                text={copy.backToStudio}
                variant="ghost"
            />
        </div>
    );
};

export default ShareScreen;
