import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { CircularProgress, LinearProgress } from '@mui/material';
import { GivelifyBoxMarginProps, GivelifyLoadingStyles } from './styles';

interface GivelifyLoadingBaseProps extends GivelifyBoxMarginProps {
    className?: string;
    color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
}

interface GivelifyLoadingLinearProps extends GivelifyLoadingBaseProps {
    value?: number;
    type: 'linear';
}

interface GivelifyLoadingCircularProps extends GivelifyLoadingBaseProps {
    size?: number;
    type: 'circular';
}

interface GivelifyLoadingTopBarProps extends GivelifyLoadingBaseProps {
    type: 'topLoadingBar';
}

export type GivelifyLoadingProps =
    | GivelifyLoadingLinearProps
    | GivelifyLoadingCircularProps
    | GivelifyLoadingTopBarProps;

function getBoxMargin(marginProps?: GivelifyBoxMarginProps) {
    if (marginProps) {
        if (
            marginProps.margin ||
            marginProps.marginLeft ||
            marginProps.marginRight ||
            marginProps.marginBottom ||
            marginProps.marginTop
        ) {
            const { boxMargin } = GivelifyLoadingStyles(marginProps);
            return boxMargin;
        }
    }
    return '';
}

export const GivelifyLoading: React.FC<GivelifyLoadingProps> = (props) => {
    const boxMargin = getBoxMargin({
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
    });
    const className = mergeClassNames(boxMargin, props.className);

    if (props.type === 'circular')
        return (
            <CircularProgress
                className={className}
                color={props.color}
                size={props.size}
            />
        );

    if (props.type === 'topLoadingBar') {
        const { blurOverly, progressBar } = GivelifyLoadingStyles({});
        const overlyClassName = mergeClassNames(className, blurOverly);
        const progressBarClassName = mergeClassNames(className, progressBar);
        return (
            <>
                <LinearProgress
                    className={overlyClassName}
                    color={props.color}
                    variant="indeterminate"
                />
                <LinearProgress
                    className={progressBarClassName}
                    color={props.color}
                    variant="indeterminate"
                />
            </>
        );
    }

    return (
        <LinearProgress
            className={className}
            color={props.color}
            value={props.value}
            variant={
                props.value === undefined ? 'indeterminate' : 'determinate'
            }
        />
    );
};
