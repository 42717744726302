import avatarBlack from '../../assets/images/avatarBlack.svg';
import avatarBlue from '../../assets/images/avatarBlue.svg';
import avatarRed from '../../assets/images/avatarRed.svg';
import avatarWhiteRed from '../../assets/images/avatarWhiteRed.svg';
import avatarWhiteYellow from '../../assets/images/avatarWhiteYellow.svg';
import avatarYellow from '../../assets/images/avatarYellow.svg';

export const defaultAvatar = [
    avatarBlack,
    avatarBlue,
    avatarRed,
    avatarWhiteRed,
    avatarWhiteYellow,
    avatarYellow,
];
