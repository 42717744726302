import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyStepsStyleProps = {
    variant: 'vertical' | 'horizontal';
    childCount: number;
} & GivelifyBoxMarginProps;

export const GivelifyStepsStyles = makeStyles((_theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyStepsStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyStepsStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyStepsStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyStepsStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        stRoot: {
            display: 'flex',
            flexDirection: (props: GivelifyStepsStyleProps) =>
                props.variant === 'vertical' ? 'column' : 'row',
            justifyContent: (props: GivelifyStepsStyleProps) =>
                props.variant === 'vertical' ? 'center' : 'initial',
            alignItems: 'center',
            width: (props: GivelifyStepsStyleProps) =>
                props.variant === 'vertical' ? 40 : props.childCount * 40,
            height: (props: GivelifyStepsStyleProps) =>
                props.variant === 'vertical' ? 'auto' : 40,
        },
    }),
);

export const GivelifyStepsCircleStyles = makeStyles((theme: Theme) =>
    createStyles({
        stOutDefault: {
            width: 40,
            height: 40,
            backgroundColor: theme.colors?.neutralHoverGrey || '#F6F6F6',
            boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        stInDefault: {
            width: 32,
            height: 32,
            backgroundColor: theme.colors?.neutralPlatinum || '#E3E3E3',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: theme.gas.font,
            fontSize: 14,
            lineHeight: '13px',
            fontWeight: 700,
            borderStyle: 'none',
            color: theme.colors?.primaryWhite || '#FFFFFF',
            '&:disabled': {
                backgroundColor: theme.colors?.neutralPlatinum || '#E3E3E3',
                color: theme.colors?.primaryWhite || '#FFFFFF',
                cursor: 'initial',
            },
        },
        stOutActive: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colors?.primary || theme.palette.primary.main,
        },
        stInActive: {
            cursor: 'pointer',
            padding: 0,
            outline: 'none',
            backgroundColor: theme.colors
                ? theme.colors.primary
                : theme.palette.primary.main,
            color: theme.colors
                ? theme.colors.primaryWhite
                : theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.colors
                    ? theme.colors.accentDarkBlue
                    : theme.palette.primary.dark,
            },
            '&:active': {
                backgroundColor: theme.colors
                    ? theme.colors.neutralPlatinum
                    : theme.palette.action.disabledBackground,
                color: theme.colors
                    ? theme.colors.neutralGrey75
                    : theme.palette.action.disabled,
            },
            '&:disabled': {
                backgroundColor: theme.colors
                    ? theme.colors.primary
                    : theme.palette.primary.main,
                color: theme.colors
                    ? theme.colors.primaryWhite
                    : theme.palette.primary.contrastText,
                cursor: 'initial',
            },
        },
    }),
);

export const GivelifyStepsLineStyles = makeStyles((theme: Theme) =>
    createStyles({
        stLineDefault: {
            width: (props: { variant: 'vertical' | 'horizontal' }) =>
                props.variant === 'vertical' ? 2 : 40,
            height: (props: { variant: 'vertical' | 'horizontal' }) =>
                props.variant === 'vertical' ? 40 : 2,
            backgroundColor: theme.colors?.neutralPlatinum || '#E3E3E3',
        },
        stLineActive: {
            backgroundColor:
                theme.colors?.primary || theme.palette.primary.main,
        },
    }),
);
