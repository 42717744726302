import { webConfig } from '../utils/webConfig';
import {
    YodleeAccountType,
    YodleeBankAccountInfo,
    YodleeOnCloseAction,
    YodleeOnErrorAction,
    YodleeOnSuccessAction,
} from './yodleeResponseInterfaces';

export const yodleeContainerId = 'yodlee-container';
export const yodleeUnableToRetrieveKey =
    'directDeposit.yodlee.yodleeUnableToRetrieve';
export const yodleeErrorMessageKey = 'directDeposit.yodlee.yodleeErrorMessage';
export const yodleeMoreThanOneAccountSelectedKey =
    'directDeposit.yodlee.yodleeMoreThanOneAccountSelected';

const getRoutingNumber = (
    info: { id: string; type: 'ROUTING_NUMBER' | string }[],
): number | undefined => {
    const routingObject = info.find((x) => x.type === 'ROUTING_NUMBER');
    if (routingObject) {
        const routing = parseInt(routingObject.id);
        if (isNaN(routing)) return undefined;
        return routing;
    }
    return undefined;
};

interface AccountInfo {
    account: number;
    routing: number;
    accountType: YodleeAccountType;
    accountStatus: string;
}

export const extractAccountAndRouting = (
    banksInfo: YodleeBankAccountInfo,
): AccountInfo | undefined => {
    if (!banksInfo.account || banksInfo.account.length === 0) {
        return undefined;
    }
    const account = banksInfo.account[0];
    if (!account.fullAccountNumberList || !account.bankTransferCode)
        return undefined;
    const accountNumber = parseInt(
        account.fullAccountNumberList.paymentAccountNumber,
    );
    const routingNumber = getRoutingNumber(account.bankTransferCode);
    if (isNaN(accountNumber) || !routingNumber) return undefined;
    return {
        account: accountNumber,
        routing: routingNumber,
        accountType: account.accountType,
        accountStatus: account.accountStatus,
    };
};

export const closeFastLink = () => {
    window.fastlink?.close();
};

export interface YodleeAccountIds {
    accountId: string;
    providerAccountId: string;
}

export const openFastLink = (
    providerId: number,
    accessToken: string,
    onClose: () => unknown,
    onError: (errorKey: string) => unknown,
    setAccountIds: (accountIds: YodleeAccountIds) => unknown,
) => {
    window.fastlink?.open(
        {
            fastLinkURL: webConfig.yodleeFastLinkURL,
            accessToken: `Bearer ${accessToken}`,
            params: {
                providerId,
                configName: 'Verification',
                flow: 'add',
            },
            //eslint-disable-next-line
            onSuccess: (_data: YodleeOnSuccessAction) => {},
            //eslint-disable-next-line
            onError: (_data: YodleeOnErrorAction) => {},
            //eslint-disable-next-line
            onClose: (data: YodleeOnCloseAction) => {
                if (
                    data.sites &&
                    data.sites.length > 0 &&
                    data.sites[0].status === 'SUCCESS'
                ) {
                    const site = data.sites[0];
                    setAccountIds({
                        accountId: site.accountId.toString(),
                        providerAccountId: site.providerAccountId.toString(),
                    });
                    if (data.sites.length > 1) {
                        onError(yodleeMoreThanOneAccountSelectedKey);
                    }
                } else {
                    onClose();
                }
            },
            //eslint-disable-next-line
            onEvent: (_data: Record<string, unknown>) => {},
        },
        yodleeContainerId,
    );
};
