import React from 'react';
import { DesignTokens } from '@givelify/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BarProps } from 'recharts';

dayjs.extend(utc);

interface ChartBarProps extends BarProps {
    value: number;
    date: dayjs.Dayjs | string;
    activeIndex: null | number;
    index: number;
}
const ChartBarComponent: React.FC<ChartBarProps> = (props) => {
    const [isHover, setIsHover] = React.useState(false);
    const { x, y, width, index, value, date, activeIndex } = props;
    const height = parseFloat(props.height as string);

    const barHeight = value === 0 ? 1 : Math.max(height, 4);

    return (
        <>
            <rect
                data-testid={`chart-bar-${index}`}
                fill={
                    value === 0
                        ? dayjs(date).isAfter(dayjs().utc())
                            ? DesignTokens.color.globalNeutral200
                            : isHover
                            ? DesignTokens.color.globalNeutral400
                            : DesignTokens.color.globalNeutral300
                        : isHover || activeIndex === index
                        ? DesignTokens.color.chartViolet700
                        : DesignTokens.color.chartViolet400
                }
                height={barHeight}
                width={width}
                x={x}
                y={Number(y) + (height - barHeight)}
            />
            <rect
                cursor={height > 0 ? 'pointer' : 'default'}
                data-testid={`hover-rect-${index}`}
                fill={'transparent'}
                height="calc(100% - 55px)"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                style={{
                    position: 'absolute',
                }}
                width={width}
                x={x}
                y={20}
            />
        </>
    );
};

export const ChartBar = React.memo(ChartBarComponent);
