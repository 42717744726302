import React from 'react';
import { GivelifyTabStyles } from './styles';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { mergeClassNames } from '../utils/classNameUtils';

export interface GivelifyTabOption {
    id?: string;
    label: string;
    badgeText?: string | number;
    tabHref?: string;
}

export interface GivelifyTabProps {
    value?: number;
    onChange: (value: number) => unknown;
    options: GivelifyTabOption[];
    className?: string;
    classes?: {
        root?: string;
        defaultTab?: string;
        selectedTab?: string;
        badge?: string;
    };
}

export const GivelifyTab: React.FC<GivelifyTabProps &
    GivelifyBoxMarginProps> = ({
    value = 0,
    onChange,
    options,
    className,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    classes,
}) => {
    const {
        boxMargin,
        tRoot,
        tItem,
        tItemSelected,
        tBadge,
        tabLink,
        tabSelected,
    } = GivelifyTabStyles({
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
    });
    const rootClassName = mergeClassNames(
        boxMargin,
        tRoot,
        classes?.root,
        className,
    );
    const tabClassName = mergeClassNames(tItem, classes?.defaultTab);
    const tabSClassName = mergeClassNames(tItemSelected, classes?.selectedTab);
    const badgeClassName = mergeClassNames(tBadge, classes?.badge);
    return (
        <div className={rootClassName}>
            {options.map((item, index) => {
                return (
                    <button
                        id={item.id || ''}
                        className={
                            index === value ? tabSClassName : tabClassName
                        }
                        key={`tab-${index}`}
                        onClick={() => onChange(index)}
                    >
                        {item.tabHref && (
                            <a
                                href={item.tabHref}
                                onClick={(e) => {e.preventDefault()}}
                                className={index === value ? tabSelected : tabLink}
                            >
                                {item.label}
                                {item.badgeText ? (
                                    <div className={badgeClassName}>
                                        {item.badgeText}
                                    </div>
                                ) : null}
                            </a>
                        )}
                        {!item.tabHref && (
                            <>
                                {item.label}
                                {item.badgeText ? (
                                    <div className={badgeClassName}>
                                        {item.badgeText}
                                    </div>
                                ) : null}
                            </>
                        )}
                    </button>
                );
            })}
        </div>
    );
};
