import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgLeftArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>left-arrow</title>
        <path
            clipRule="evenodd"
            d="M10.9393 19L4.21967 12.5304C3.92678 12.2375 3.92678 11.7626 4.21967 11.4697L10.9393 5C11.2322 4.70711 11.7071 4.70711 12 5C12.2929 5.29289 12.2929 5.76777 12 6.06066L6.56067 11.25L19.25 11.25C19.6642 11.25 20 11.5858 20 12C20 12.4143 19.6642 12.75 19.25 12.75L6.56067 12.75L12 17.9393C12.2929 18.2322 12.2929 18.7071 12 19C11.7071 19.2929 11.2322 19.2929 10.9393 19Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const LeftArrowIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgLeftArrow}
        fontSize={fontSize}
    />
);
