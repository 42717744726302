import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
import { MOBILE_FOOTER_HEIGHT } from 'theme/layoutMeaserments';
import { NavMenuItem } from '../components';
import { NavigationProps } from '../types';
import { MoreNavItem } from './MoreNavItem';

const MobileNavigationDrawer = styled('div')(({ theme }) => ({
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: MOBILE_FOOTER_HEIGHT,
    background: DesignTokens.color.backgroundSecondary,
    display: 'none',
    padding: theme.spacing(1.25, 2, 1, 2),
    boxShadow: DesignTokens.shadow.shadowMedium,
    [theme.breakpoints.down('tablet')]: {
        display: 'none',
    },
    [theme.breakpoints.down('mobile')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    zIndex: theme.zIndex.appBar,
}));

export type MobileNavigationMenuProps = {
    navigationProps: NavigationProps;
    onMoreToggle: (open: boolean) => void;
    openMoreMenu?: boolean;
};

export const MobileNavigationMenu: React.FC<MobileNavigationMenuProps> = ({
    navigationProps,
    onMoreToggle,
    openMoreMenu = false,
}) => {
    const { items, onClick, onExpandClick } = navigationProps;
    const basicItems = React.useMemo(() => {
        if (items.length < 5) return [];
        return items.slice(0, 4);
    }, [items]);
    const moreItems = React.useMemo(() => {
        if (items.length < 5) return [];
        return items.slice(4);
    }, [items]);
    const moreItemsIsActive = React.useMemo(() => {
        if (items.length < 5) return false;
        return (
            !items[0].active &&
            !items[1].active &&
            !items[2].active &&
            !items[3].active
        );
    }, [items]);
    if (basicItems.length < 4) return null;
    return (
        <MobileNavigationDrawer id="mobile-nav-drawer">
            {basicItems.map((item) => (
                <NavMenuItem
                    key={`nav-item-${item.label}`}
                    item={item}
                    onClick={onClick}
                    onExpandClick={onExpandClick}
                    variant="mobile"
                />
            ))}
            <MoreNavItem
                active={moreItemsIsActive}
                items={moreItems}
                onClick={onClick}
                onExpandClick={onExpandClick}
                onMoreToggle={onMoreToggle}
                openMoreMenu={openMoreMenu}
            />
        </MobileNavigationDrawer>
    );
};
