export interface Officer {
    id: number;
    avatar: string;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    admin?: boolean;
    isFinancial?: boolean;
    isNonFinancial?: boolean;
    role?: string;
    isAccountHolder: boolean;
    active: boolean;
}
export interface OfficersState {
    officers?: Officer[];
    officersLoading: boolean;
    officerTitles?: string[];
    officerTitlesLoading: boolean;
}

export enum OFFICERS {
    LOAD_OFFICERS_START = 'LOAD_OFFICERS_START',
    LOAD_OFFICERS_FINISH = 'LOAD_OFFICERS_FINISH',
    SET = 'OFFICERS_SET',
    LOAD_OFFICER_TITLES_START = 'LOAD_OFFICER_TITLES_START',
    LOAD_OFFICER_TITLES_FINISH = 'LOAD_OFFICER_TITLES_FINISH',
    SET_OFFICER_TITLES = 'SET_OFFICER_TITLES',
}

export interface OfficersSet {
    data: Officer[];
    type: OFFICERS.SET;
}
export interface OfficerTitlesSet {
    data: string[];
    type: OFFICERS.SET_OFFICER_TITLES;
}

export interface LoadOfficersStart {
    type: OFFICERS.LOAD_OFFICERS_START;
}

export interface LoadOfficersFinish {
    type: OFFICERS.LOAD_OFFICERS_FINISH;
}

export interface LoadOfficerTitlesStart {
    type: OFFICERS.LOAD_OFFICER_TITLES_START;
}

export interface LoadOfficerTitlesFinish {
    type: OFFICERS.LOAD_OFFICER_TITLES_FINISH;
}

export type OfficersActions =
    | OfficersSet
    | OfficerTitlesSet
    | LoadOfficersStart
    | LoadOfficersFinish
    | LoadOfficerTitlesStart
    | LoadOfficerTitlesFinish;
