import React, { useMemo, useState, useEffect } from 'react';
import {
    GivelifyLabel,
    GivelifyRadio,
    isSucceeded,
    isFailed,
} from '@givelify/givelify-ui';
import { Donee } from '@givelify/givelify-ui';
import { makeStyles } from '@material-ui/core';
import { useInvokeApi, InvokeApiErrorResponse } from 'hooks/useInvokeApi';
import isEqual from 'lodash/isEqual';
import { EditOption } from 'pages/settings/components/EditOption';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setDoneeMailingAddress } from 'store/donee/actions';

import { AddressInfo } from 'types/orgInfoTypes';

import { aboutStyle } from '../style';
import AddressSectionEditor from './AddressSectionEditor';

interface MailSectionProps {
    donee: Donee;
    isReadOnly: boolean;
}

const useStyles = makeStyles(() => ({
    mailValues: {
        marginTop: 16,
        marginLeft: -10,
    },
    addressContent: {
        marginTop: 24,
    },
}));

const MailSection: React.FC<MailSectionProps> = ({ donee, isReadOnly }) => {
    const commonStyle = aboutStyle();
    const style = useStyles();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            title: t('pages.settings.organization-info.mail.title'),
            description: t('pages.settings.organization-info.mail.description'),
            yes: t('labels.yes'),
            no: t('labels.no'),
        }),
        [t],
    );

    const dispatch = useDispatch();

    const [addressData, setAddressData] = useState({
        address: '',
        zip: '',
        state: '',
        city: '',
    });
    const [editorEnabledState, setEditorEnabledState] = useState(false);
    const [sameMail, setSameMail] = useState(true);

    useEffect(() => {
        setSameMail(!donee.mailingAddress);
        setAddressData({
            address: donee.mailingAddress?.address || '',
            city: donee.mailingAddress?.city || '',
            zip: donee.mailingAddress?.zip || '',
            state: donee.mailingAddress?.state || '',
        });
    }, [donee]);

    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const [updateRequestState, updateRequest] = useInvokeApi<unknown>();

    const onSubmit = (newData: AddressInfo) => {
        if (!isEqual(addressData, newData)) {
            updateRequest('PATCH', `/donees/${donee.id.toString()}`, {
                mailing_address: {
                    street: newData.address,
                    city: newData.city,
                    state: newData.state,
                    postal: newData.zip,
                },
            });
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            const dataAsAny = updateRequestState.data as {
                mailing_address: AddressInfo;
                updatedAt: string;
            };
            dispatch(
                setDoneeMailingAddress(
                    donee.id,
                    dataAsAny.mailing_address,
                    new Date(dataAsAny.updatedAt),
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, donee.id]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
        setSameMail(!donee.mailingAddress);
    };

    const handleSame = (value: boolean) => {
        if (sameMail !== value) {
            setSameMail(value);
            if (value) {
                updateRequest('PATCH', `/donees/${donee.id.toString()}`, {
                    mailing_address: null,
                });
                setEditorEnabledState(false);
            } else {
                setEditorEnabledState(true);
            }
        }
    };

    const saving = updateRequestState.type === 'REQUEST_START';
    const showAddress = !sameMail && !editorEnabledState;
    const showEditor = !sameMail && editorEnabledState;

    return (
        <div className={commonStyle.section}>
            <GivelifyLabel
                bold
                className={commonStyle.subtitle}
                text={copy.title}
                variant="heading3"
            />
            <GivelifyLabel
                bold
                className={commonStyle.body}
                text={copy.description}
                variant="body1"
            />
            <div className={style.mailValues}>
                <GivelifyRadio
                    labelBold
                    ariaLabel={copy.yes}
                    checked={sameMail}
                    disabled={saving || isReadOnly}
                    label={copy.yes}
                    labelPosition="after"
                    name="same-yes"
                    onChange={() => handleSame(true)}
                    testId="sameAddress"
                    variant="primary"
                />
                <GivelifyRadio
                    labelBold
                    ariaLabel={copy.no}
                    checked={!sameMail}
                    disabled={saving || isReadOnly}
                    label={copy.no}
                    labelPosition="after"
                    name="same-no"
                    onChange={() => handleSame(false)}
                    testId="differentAddress"
                    variant="primary"
                />
            </div>
            {showAddress && (
                <div className={style.addressContent}>
                    <GivelifyLabel
                        className={commonStyle.body}
                        text={addressData.address}
                        variant="body1"
                    />
                    <GivelifyLabel
                        className={commonStyle.body}
                        text={`${addressData.city}, ${addressData.state} ${addressData.zip}`}
                        variant="body1"
                    />
                </div>
            )}
            {showEditor && (
                <AddressSectionEditor
                    data={addressData}
                    error={saveError}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            )}
            <div className={commonStyle.editButtonContainer}>
                {!isReadOnly && showAddress && (
                    <EditOption
                        onClick={onEditClick}
                        testId="edit-mail-address"
                    />
                )}
            </div>
        </div>
    );
};

export default MailSection;
