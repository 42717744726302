import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgOverview = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>overview</title>
        <path
            clipRule="evenodd"
            d="M3.42856 2.67822C3.01434 2.67822 2.67856 3.01401 2.67856 3.42822V10.2854C2.67856 10.6996 3.01434 11.0354 3.42856 11.0354H10.2857C10.6999 11.0354 11.0357 10.6996 11.0357 10.2854V3.42822C11.0357 3.01401 10.6999 2.67822 10.2857 2.67822H3.42856ZM4.17856 9.53537V4.17822H9.5357V9.53537H4.17856ZM13.7143 2.67822C13.3001 2.67822 12.9643 3.01401 12.9643 3.42822V10.2854C12.9643 10.6996 13.3001 11.0354 13.7143 11.0354H20.5715C20.9857 11.0354 21.3215 10.6996 21.3215 10.2854V3.42822C21.3215 3.01401 20.9857 2.67822 20.5715 2.67822H13.7143ZM14.4643 9.53537V4.17822H19.8215V9.53537H14.4643ZM2.67856 13.7144C2.67856 13.3001 3.01434 12.9644 3.42856 12.9644H10.2857C10.6999 12.9644 11.0357 13.3001 11.0357 13.7144V20.5715C11.0357 20.9857 10.6999 21.3215 10.2857 21.3215H3.42856C3.01434 21.3215 2.67856 20.9857 2.67856 20.5715V13.7144ZM4.17856 14.4644V19.8215H9.5357V14.4644H4.17856ZM13.7143 12.9644C13.3001 12.9644 12.9643 13.3001 12.9643 13.7144V20.5715C12.9643 20.9857 13.3001 21.3215 13.7143 21.3215H20.5715C20.9857 21.3215 21.3215 20.9857 21.3215 20.5715V13.7144C21.3215 13.3001 20.9857 12.9644 20.5715 12.9644H13.7143ZM14.4643 19.8215V14.4644H19.8215V19.8215H14.4643Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const OverviewIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgOverview}
        fontSize={fontSize}
    />
);
