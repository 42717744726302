import React from 'react';
import { RequiredCardState, useApiRequest } from '@givelify/givelify-ui';
import {
    PrimaryRepresentativeInfo,
    convertDoneeToPrimaryRepresentativeInfo,
    primaryRepresentativeAPI,
} from '@givelify/onboarding';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { setDoneePrimaryRep } from 'store/donee/actions';
import { RepEditor, RepEditorRef } from './components/RepEditor';
import { RepPending } from './components/RepPending';
import { RepRetry } from './components/RepRetry';
import { RepSuccess } from './components/RepSuccess';

type CustomizeRepTabScreen =
    | 'add'
    | 'replace'
    | 'success'
    | 'retry'
    | 'pending';

const InitialPrimaryRepData: PrimaryRepresentativeInfo = {
    birthDate: null,
    firstName: '',
    beneficiaryIdFile: undefined,
    isPrimaryRepresentative: false,
    lastName: '',
    primaryRepresentativeAddress: {
        city: '',
        phone: '',
        state: '',
        street: '',
        zip: '',
    },
    socialSecurityNumber: '',
    status: 'unchecked' as RequiredCardState,
    title: '',
    submittedAt: null,
    submittedByOfficialId: null,
};

export const PrimaryRepresentativeTabV1: React.FC = () => {
    const { doneeId, user, primaryRepInfo, address } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            user: state.User.user,
            primaryRepInfo: convertDoneeToPrimaryRepresentativeInfo(
                toCamelCase(state.Donee.donee, { deep: true }),
            ),
            address: state.Donee.donee.onboarding.primaryRepresentative.street,
        }),
    );
    const dispatch = useDispatch();
    const editorRef = React.useRef<RepEditorRef>(null);
    const [content, setContent] = React.useState<PrimaryRepresentativeInfo>({
        ...primaryRepInfo,
        primaryRepresentativeAddress: {
            ...primaryRepInfo.primaryRepresentativeAddress,
            street: address,
        },
    });
    const [saveDataStatus, makeSaveDataRequest] = useApiRequest<unknown>();
    const [repScreen, setRepScreen] =
        React.useState<CustomizeRepTabScreen>('add');
    const [lastRepScreen, setLastRepScreen] =
        React.useState<CustomizeRepTabScreen>('add');

    React.useEffect(() => {
        if (content && content.firstName && content.lastName) {
            switch (content.status) {
                case 'in_progress': {
                    setRepScreen('pending');
                    setLastRepScreen('pending');
                    break;
                }
                case 'require_information':
                case 'request_callback': {
                    setRepScreen('retry');
                    setLastRepScreen('retry');
                    break;
                }
                case 'unchecked': {
                    setRepScreen('add');
                    setLastRepScreen('add');
                    break;
                }
                case 'verified': {
                    setRepScreen('success');
                    setLastRepScreen('success');
                    break;
                }
                default:
                    break;
            }
        } else {
            setRepScreen('add');
            setLastRepScreen('add');
        }
        //eslint-disable-next-line
    }, []);

    const onRetryClick = React.useCallback(() => {
        setLastRepScreen(repScreen);
        setRepScreen('replace');
    }, [setRepScreen, setLastRepScreen, repScreen]);
    const onReplaceClick = React.useCallback(() => {
        setLastRepScreen(repScreen);
        setRepScreen('replace');
    }, [setRepScreen, setLastRepScreen, repScreen]);
    const onEditCancel = React.useCallback(() => {
        setRepScreen(lastRepScreen);
    }, [setRepScreen, lastRepScreen]);
    const onEditSave = React.useCallback(() => {
        const data = editorRef.current.submit();
        makeSaveDataRequest(primaryRepresentativeAPI(doneeId, data));
    }, [editorRef, makeSaveDataRequest, doneeId]);

    React.useEffect(() => {
        if (saveDataStatus.type === 'REQUEST_SUCCESS') {
            const data = editorRef.current.submit();
            dispatch(setDoneePrimaryRep(data));
            setRepScreen('pending');
            setLastRepScreen('pending');
            setContent({ ...data, status: 'verified' });
        }
        //eslint-disable-next-line
    }, [saveDataStatus]);

    const renderContent = React.useCallback(() => {
        switch (repScreen) {
            case 'replace':
            case 'add': {
                return (
                    <RepEditor
                        ref={editorRef}
                        data={
                            repScreen === 'replace'
                                ? content
                                : InitialPrimaryRepData
                        }
                        disableCancel={repScreen === 'add'}
                        isSaving={saveDataStatus.type === 'REQUEST_START'}
                        onCancel={onEditCancel}
                        onSave={onEditSave}
                        user={user}
                    />
                );
            }
            case 'pending': {
                return (
                    <RepPending
                        firstName={content.firstName}
                        lastName={content.lastName}
                    />
                );
            }
            case 'retry': {
                return (
                    <RepRetry
                        firstName={content.firstName}
                        lastName={content.lastName}
                        onRetryClick={onRetryClick}
                    />
                );
            }
            case 'success': {
                return (
                    <RepSuccess
                        firstName={content.firstName}
                        lastName={content.lastName}
                        onReplaceClick={onReplaceClick}
                    />
                );
            }
        }
    }, [
        repScreen,
        content,
        user,
        onEditCancel,
        onEditSave,
        saveDataStatus.type,
        onRetryClick,
        onReplaceClick,
    ]);

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsBankInfoPrimaryRepTab}
        >
            {renderContent()}
        </TrackingProvider>
    );
};
