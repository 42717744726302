import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyInputStyleProps = {
    width: number;
    helperTextRight?: boolean;
} & GivelifyBoxMarginProps;

export const inputStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            paddingTop: 18.5,
            paddingBottom: 18.5,
            paddingLeft: 12,
            paddingRight: 12,
        },
        boxMargin: {
            marginLeft: (props: GivelifyInputStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyInputStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyInputStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyInputStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        focus: {
            '& label': {},
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#676E77',
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        fakeFocus: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
            '& label': { color: theme.palette.primary.main },
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
        },
        focusError: {
            '& label': {},
            '& label.Mui-focused': {
                color: theme.palette.error.main,
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#676E77',
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
        focusSuccess: {
            '& input:valid + fieldset': {
                borderColor: theme.palette.success.dark,
                borderWidth: 2,
            },
            '& label': {},
            '& label.Mui-focused': {
                color: theme.palette.success.dark,
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#676E77',
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.success.dark,
                },
            },
        },
        large: {
            height: 19,
        },
        medium: {
            height: 11,
        },
        mediumLabel: {
            transform: 'translate(14px, 16px) scale(1)',
        },
        dense: {
            height: 3,
        },
        denseLabel: {
            transform: 'translate(14px, 12px) scale(1)',
        },
        helperTextRoot: {
            fontSize: 12,
            lineHeight: '20px',
        },
        helperTextContained: {
            marginLeft: 0,
            marginRight: 0,
            textAlign: (props: GivelifyInputStyleProps) =>
                props.helperTextRight ? 'right' : 'left',
        },
        inputBoxSuccess: {
            borderColor: theme.palette.success.dark,
        },
        customWidth: {
            width: (props: GivelifyInputStyleProps) => props.width,
        },
    }),
);
