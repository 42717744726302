import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { DesignTokens } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const legacyReportsAccentColor = '#0288CB';
export const legacyReportsAccentTextColor = '#0088CC';

export const legacyReportStyles = makeStyles((theme: Theme) => {
    return createStyles({
        reportRow: {
            marginTop: theme.spacing(5),
        },
        reportHeadTitle: {
            marginBottom: theme.spacing(1),
            color: theme.palette.text.primary + ' !important',
        },
        reportNotification: {
            marginTop: theme.spacing(3),
        },
        reportNotificationContent: {
            '& .successfully': {
                paddingRight: theme.spacing(1),
                display: 'inline',
            },
            '& .goToHistory': {
                padding: 0,
                display: 'inline',
            },
        },
        reportMainContent: {
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
            },
        },
        reportList: {
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
            },
        },
        reportItemContainer: {
            borderRadius: '10px',
            boxShadow: '0 0 8px rgba(152,162,173,0.4)',
            width: 248,
            marginRight: '12px',
            marginBottom: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.background.paper,
            [theme.breakpoints.down(1432)]: {
                width: 228,
            },
            [theme.breakpoints.down(1252)]: {
                width: 248,
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: 0,
                width: '100%',
                minHeight: 211,
            },
            '&:last-child': {
                marginRight: 0,
            },
        },
        reportInfoHeader: {
            flexBasis: 'content',
        },
        reportItemHeader: {
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(4, 4, 4, 2),
            borderBottom: '1px solid #d8d8d8',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(3),
            },
        },
        reportTooltip: {
            backgroundColor: '#717171',
            padding: theme.spacing(3),
        },
        reportTooltipText: {
            whiteSpace: 'pre-wrap',
        },
        excludeEnvelopes: {
            marginLeft: '2px',
            '& .MuiButtonBase-root': {
                color: '#4a4a4a',
            },
        },
        excludeEnvelopesLabel: {
            fontSize: 14,
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                fontSize: 12
            }
        },
        reportItemBody: {
            flexGrow: 1,
            padding: theme.spacing(3, 3, 4),
            display: 'flex',
            flexDirection: 'column',
            minHeight: 156,
            [theme.breakpoints.down('xs')]: {
                minHeight: 92,
            },
        },
        getReport: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            marginTop: 'auto',
        },
        getReportLink: {
            padding: 0,
            fontSize: 14,
            fontWeight: 600,
            color: legacyReportsAccentTextColor,
            borderRadius: 0,
            '&:hover': {
                textDecoration: 'underline',
                color: legacyReportsAccentTextColor,
            },
        },
        quickbookIcon: {
            marginRight: 'auto',
            marginLeft: 'auto',
            width: 85,
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(4),
            },
        },
        reportIcon: {
            fontSize: '40px !important',
            color: legacyReportsAccentColor + ' !important',
            marginRight: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                fontSize: '32px !important',
            },
        },
        infoIcon: {
            color: legacyReportsAccentColor
        },
        reportItemName: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            '& svg': {
                marginLeft: theme.spacing(2),
            },
        },
        openInfo: {
            paddingLeft: theme.spacing(1),
            fontSize: 16,
        },
        reportItemDefine: {
            flexGrow: 1,
            marginBottom: 6,
        },
        define: {
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(2),
                paddingRight: theme.spacing(7),
            },
        },
        reportInclude: {
            position: 'absolute',
            bottom: 'calc(100% + 8px)',
            width: '215px',
            background: theme.palette.text.primary,
            color: '#fff',
            borderRadius: '10px',
            fontSize: 12,
            lineHeight: '14px',
            textAlign: 'left',
            padding: theme.spacing(2),
        },
        reportIncludeTitle: {
            fontSize: 12,
            fontWeight: 'bold',
            marginBottom: theme.spacing(2),
        },
        arrow: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid ' + theme.palette.text.primary,
            position: 'absolute',
            bottom: '100%',
            right: 0,
        },
        quickbooksItemContainer: {
            width: '248px',
            padding: theme.spacing(6, 3, 3),
            borderRadius: '10px',
            boxShadow: '0 0 4px rgba(188,188,188,0.4)',
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(3),
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: theme.palette.background.paper,
            [theme.breakpoints.down('xs')]: {
                marginRight: 0,
            },
        },
        quickbookImg: {
            width: '176px',
            marginBottom: theme.spacing(6),
        },
        quickbooksLink: {
            marginTop: 'auto',
        },
        reportHeadContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        legacyReportsHeaderWrapper: {
            marginBottom: 54,
            marginTop: 32
        },
        legacyReportsHeader: {
            fontSize: DesignTokens.textStyle.globalHeadingS1.font.size,
            fontWeight: DesignTokens.textStyle.globalHeadingS1.font.weight,
            [theme.breakpoints.down(1024)]: {
                fontSize: 18,
            },
        },
        viewPreviousReports: {
            '& a': {
                color: legacyReportsAccentColor,
            },
            '& a:hover': {
                color: legacyReportsAccentColor
            },
            [theme.breakpoints.down(817)]: {
                marginTop: '16px !important'
            }
        },
    });
});

export const reportHistoryStyle = makeStyles((theme: Theme) => {
    return createStyles({
        reportTitle: {
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(4),
            },
        },
        reportHistoryHeader: {
            marginTop: 18,
            marginBottom: theme.spacing(5),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'start',
            },
        },
        historyTitle: {
            color: '#373739 !important',
            marginBottom: theme.spacing(1),
        },
        refreshButton: {
            padding: 0,
            '& svg': {
                fontSize: 18,
            },
        },
        noResultContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '670px',
            margin: '0 auto',
            '& svg': {
                fontSize: 80,
                marginBottom: theme.spacing(5),
            },
        },
        noResultDefine: {
            textAlign: 'center',
            lineHeight: '22px',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(5),
        },
        backToReport: {
            fontSize: 16,
        },
        reportHistoryTable: {
            '& > thead': {
                '& tr th': {
                    lineHeight: '16px',
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    fontSize: 14,
                },
            },
            '& > tbody': {
                '& tr:hover': {
                    background: GivelifyColorPalette.neutralHoverGrey,
                },
                '& tr td': {
                    lineHeight: '16px',
                    padding: theme.spacing(4, 3),
                    fontSize: 14,
                    [theme.breakpoints.down('xs')]: {
                        padding: theme.spacing(3),
                    },
                },
            },
        },
        downloadRow: {
            overflow: 'inherit !important',
            padding: '24px 0 !important',
            [theme.breakpoints.down('xs')]: {
                padding: '16px 0 !important',
            },
        },
        downloadContent: {
            position: 'relative',
        },
        downloadButton: {
            fontSize: 14,
            lineHeight: '16px',
            padding: 0,
        },
        downloadTooltip: {
            position: 'absolute',
            top: '100%',
            left: 0,
            width: 197,
            background: GivelifyColorPalette.primaryWhite,
            boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.73)',
            borderRadius: 4,
            padding: theme.spacing(2, 0),
            zIndex: 1,
        },
        exportLink: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 0,
            color: GivelifyColorPalette.primary,
            fontSize: 14,
            '& .downloadIcon': {
                color: GivelifyColorPalette.primary,
            },
            '&:hover': {
                color: GivelifyColorPalette.accentDarkBlue,
                textDecoration: 'none',
                cursor: 'pointer',
                '& .downloadIcon': {
                    color: GivelifyColorPalette.accentDarkBlue,
                },
            },
        },
        iconRow: {
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        generating: {
            display: 'flex',
            alignItems: 'center',
        },
        downloadModalTitle: {
            textAlign: 'center',
            marginBottom: 30,
        },
        downloadModalButton: {
            width: 194,
            display: 'block',
            margin: '0 74px 16px',
            borderRadius: 25,
            background: GivelifyColorPalette.primary,
            color: GivelifyColorPalette.primaryWhite,
            textAlign: 'center',
            padding: theme.spacing(2),
            fontSize: 15,
            lineHeight: '20px',
        },
        reportHistoryPagination: {
            '& .MuiIconButton-root': {
                padding: 0,
            },
            '& p': {
                fontSize: 14,
                lineHeight: '14px',
            },
        },
        tablePagination: {
            flexShrink: 0,
            display: 'flex',
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(3),
        },
        paginationButton: {
            margin: theme.spacing(0, 2),
            '&:disabled svg path': {
                fill: GivelifyColorPalette.neutralGrey75,
            },
            '&:disabled svg rect': {
                fill: GivelifyColorPalette.neutralGrey75,
            },
        },
    });
});

export const reportItemStyles = makeStyles((theme: Theme) => {
    return createStyles({
        topReport: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(4),
        },
        viewPrevious: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
        reportItemContent: {
            background: theme.palette.background.paper,
            marginTop: theme.spacing(5),
            display: 'flex',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
        },
        reportInformationContent: {
            width: 336,
            minWidth: 336,
            padding: '48px 24px 40px',
            marginLeft: 104,
            border: '1px solid #e3e3e3',
            borderTop: '8px solid ' + GivelifyColorPalette.primary,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                order: `-1`,
                width: '100%',
                marginLeft: 0,
                marginBottom: theme.spacing(5),
                padding: theme.spacing(3),
            },
        },
        reportImage: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        reportDefinition: {
            marginTop: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                alignSelf: 'flex-start',
            },
        },
        defineBold: {
            display: 'inline',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        },
        defineNormal: {
            display: 'inline',
        },
        detailBtn: {
            fontSize: 14,
            padding: 0,
            marginTop: theme.spacing(2),
        },
        reportFormContainer: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
        },
        reportDateRow: {
            maxWidth: 592,
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr',
            gap: 16,
            '& > :first-child': {
                gridColumn: 'span 3',
            },
        },
        reportRadio: {
            '& .MuiRadio-root': {
                color: theme.palette.primary.main,
            },
        },
        formButtons: {
            display: 'flex',
        },
        generateButton: {
            marginRight: theme.spacing(3),
            padding: theme.spacing(0, 6),
            height: 52,
            background: legacyReportsAccentColor,
            '&:not(:disabled):hover': {
                background: legacyReportsAccentColor,
            },
            '&.btnPrimaryLoading': {
                backgroundColor: legacyReportsAccentColor
            }
        },
        loadingButton: {
            background: `${legacyReportsAccentColor} !important`
        },
        fileFormatLabel: {
            '& div': {
                fontSize: 14,
                fontWeight: 600,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 12
                }
            },
            '& .MuiButtonBase-root': {
                padding: '8px 0',
            },
            '& .MuiSvgIcon-root': {
                width: 24,
                height: 24,
                color: '#4A4A4A',
                [theme.breakpoints.down('xs')]: {
                    width: 20,
                    height: 20
                }
            },
        },
        formatLabel: {
            marginLeft: 2,
        },
        hoverInfo: {
            position: 'absolute',
            bottom: 'calc(100% + 8px)',
            right: '-48px',
            width: '215px',
            background: theme.palette.text.primary,
            color: '#fff',
            borderRadius: '10px',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '14px',
            textAlign: 'left',
            padding: theme.spacing(2),
        },
        hoverInfoTitle: {
            fontSize: 12,
            fontWeight: 'bold',
            marginBottom: theme.spacing(2),
        },
        exclusiveLabel: {
            '& svg': {
                marginLeft: theme.spacing(2),
                '& path': {
                    fill: theme.palette.primary.main,
                },
            },
        },
        exclusiveBlue: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            paddingRight: theme.spacing(2),
        },
        timeFrameSelector: {
            marginBottom: theme.spacing(3),
            '& button': {
                width: '100%',
                height: 56,
                borderRadius: 4,
            },
            [theme.breakpoints.down('sm')]: {
                '& button': {
                    height: 40,
                },
            },
        },
        selectDateRange: {
            '& .MuiOutlinedInput-root': {
                border: '0 !important',
                borderRadius: '4px !important',
            },
            '& .MuiSelect-outlined': {
                borderRadius: '4px !important',
                background: theme.gas.palette.background.primary,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.gas.palette.input.focused.border,
            },
            '& .MuiSelect-root': {
                height: 'auto !important',
                padding: '15px 16px !important',
                fontWeight: '400 !important',
                lineHeight: '16px',
                background: theme.palette.background.paper,
                color: GivelifyColorPalette.primaryDarkGray,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
            },
            '& .MuiSelect-icon': {
                marginRight: theme.spacing(3),
            },
            '& > div:focus': {
                background: 'transparent !important',
            },
            '& fieldset': {
                borderRadius: '4px !important',
                background: 'transparent !important',
                border: '1px solid #d8d8d8 !important',
            },
            '& .markSelected': {
                display: 'none',
            },
        },
        datePicker: {
            '& input': {
                fontSize: 14,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 12
                }
            },
        },
        quickbooksImg: {
            width: 176,
        },
        navigarionLinks: {
            margin: '32px 0',
            '& a': {
                [theme.breakpoints.down('xs')]: {
                    fontSize: 12
                }
            }
            
        },
        backToReports: {
            display: 'flex',
            alignItems: 'center'
        },
        backToReportsIcon: {
            marginRight: 16
        }
    });
});
