import React, { useMemo } from 'react';
import { GivelifyDatePicker, GivelifyLabel } from '@givelify/ui';
import {
    TimeFrameId,
    getDefaultStartDate,
    TimeFrameValue,
    TimeFrameValues,
    useTrackingContext,
    BUTTON_IDENTIFIER,
    TimeFrameLegacyIds,
    PAGE_NAME,
} from '@givelify/utils';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
    DatePickerWidgetTitle,
    DatePickerWidgetWrapper,
    DatePill,
    DatesWrapper,
    Wrapper,
} from './styles';

export type DatePillsList = { id: TimeFrameId; label: string }[];

export type DatePickersWidgetViewProps = {
    title: string;
    timeFrame: TimeFrameValue;
    titleVariant?: typeof GivelifyLabel.defaultProps.variant;
    onChangeHandler: (dateRange: TimeFrameValue) => void;
    shortcuts?: DatePillsList;
    className?: string;
};

const GAP_BETWEEN_TITLE_AND_PILLS = 14;
const GAP_BETWEEN_PILLS = 8;

export const DatePickersWidgetView: React.FC<DatePickersWidgetViewProps> = ({
    title,
    timeFrame,
    shortcuts,
    onChangeHandler,
    titleVariant = 'heading2',
    className,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isSmallTablet = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const { t } = useTranslation();
    const { thisMonth, lastMonth, thisWeek, lastWeek, moreDates } = useMemo(
        () => ({
            thisMonth: t('components.timeFrameSelector.thisMonth'),
            lastMonth: t('components.timeFrameSelector.lastMonth'),
            thisWeek: t('components.timeFrameSelector.thisWeek'),
            lastWeek: t('components.timeFrameSelector.lastWeek'),
            thisYear: t('components.timeFrameSelector.thisYear'),
            lastYear: t('components.timeFrameSelector.lastYear'),
            yesterday: t('components.timeFrameSelector.yesterday'),
            today: t('components.timeFrameSelector.today'),
            moreDates: t('overview.widgets.datePicker.moreDates'),
            specificDate: t('overview.widgets.datePicker.specificDate'),
        }),
        [t],
    );
    const PILLS_LIST: DatePillsList = React.useMemo(
        () =>
            shortcuts
                ? shortcuts
                : [
                      { id: 'thisMonth', label: thisMonth },
                      { id: 'lastMonth', label: lastMonth },
                      { id: 'thisWeek', label: thisWeek },
                      { id: 'lastWeek', label: lastWeek },
                  ],
        [shortcuts, thisMonth, lastMonth, thisWeek, lastWeek],
    );
    const [datePillList, setDatePillList] = React.useState<DatePillsList>([
        PILLS_LIST[0],
    ]);
    const listRef = React.useRef<HTMLDivElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const titleRef = React.useRef<HTMLDivElement>(null);
    const { trackEvent } = useTrackingContext();
    const [customDateText, setCustomDateText] = React.useState<string | null>(
        null,
    );
    const ref = React.useRef<HTMLButtonElement>(null);
    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const handleCustomDateRangeChange = React.useCallback(
        (timeFrame: TimeFrameValue, initialDatePillsList?: DatePillsList) => {
            if (
                (initialDatePillsList || datePillList).findIndex(
                    (item) => item.id === timeFrame.selector,
                ) === -1
            ) {
                // custom date range or not shown shortcut
                if (timeFrame.selector === 'custom') {
                    setCustomDateText(
                        `${timeFrame.start.format(
                            'MMM DD, YYYY',
                        )} - ${timeFrame.end.format('MMM DD, YYYY')}`,
                    );
                } else {
                    setCustomDateText(
                        t(`components.timeFrameSelector.${timeFrame.selector}`),
                    );
                }
            } else {
                setCustomDateText(null);
            }
        },
        [datePillList, t],
    );
    const signUpDate = React.useMemo(() => dayjs.tz(getDefaultStartDate()), []);
    const handleDateClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const id = event.currentTarget.id as TimeFrameId;
            if (id !== 'custom') {
                setCustomDateText(null);
                onChangeHandler(TimeFrameValues[id]);
                trackEvent(
                    `<${
                        TimeFrameLegacyIds[TimeFrameValues[id].selector]
                    }>_${BUTTON_IDENTIFIER}`,
                );
            } else {
                setCalendarOpen(true);
                trackEvent(
                    `<${TimeFrameLegacyIds['custom']}>_${BUTTON_IDENTIFIER}`,
                );
            }
        },
        [onChangeHandler, trackEvent],
    );
    const onCalendarClose = React.useCallback(
        (_reason: 'submit' | 'cancel' | 'dismiss') => {
            setCalendarOpen(false);
        },
        [],
    );

    const onRangeChange = React.useCallback(
        (start: Dayjs, end: Dayjs, range: TimeFrameId) => {
            if (
                timeFrame.start.isSame(start) &&
                timeFrame.end.isSame(end) &&
                timeFrame.selector === range
            ) {
                return;
            }

            onChangeHandler({
                start: start,
                end: end,
                selector: range,
            });

            handleCustomDateRangeChange({ start, end, selector: range });
        },
        [
            handleCustomDateRangeChange,
            onChangeHandler,
            timeFrame.end,
            timeFrame.selector,
            timeFrame.start,
        ],
    );

    const handleResize = React.useCallback(() => {
        // Logic to show/hide pills, so it could fit one line on screens above 1280
        if (!isTablet && !isMobile) {
            const listWidth = Math.ceil(listRef.current.offsetWidth);
            const wrapperWidth = Math.ceil(wrapperRef.current.offsetWidth);
            const titleWidth = Math.ceil(titleRef.current.offsetWidth);

            const totalWidth =
                listWidth + titleWidth + GAP_BETWEEN_TITLE_AND_PILLS;

            if (totalWidth > wrapperWidth) {
                const updatedItems = [...PILLS_LIST];
                while (totalWidth >= wrapperWidth && updatedItems.length > 1) {
                    updatedItems.pop();
                    const customPillWidth = Math.ceil(ref.current.offsetWidth);
                    const newTotalWidth =
                        updatedItems.reduce((acc, item) => {
                            const pill = document.getElementById(item.id);
                            if (pill) {
                                return (
                                    acc + pill.offsetWidth + GAP_BETWEEN_PILLS
                                );
                            }
                            return acc;
                        }, titleWidth + GAP_BETWEEN_TITLE_AND_PILLS) +
                        customPillWidth +
                        GAP_BETWEEN_PILLS * (updatedItems.length - 1);
                    if (newTotalWidth <= wrapperWidth) {
                        break;
                    }
                }
                setDatePillList(updatedItems);
                return updatedItems;
            } else {
                const defaultPillWidth = document.getElementById(
                    PILLS_LIST[0].id,
                ).offsetWidth;
                if (
                    listWidth +
                        defaultPillWidth +
                        titleWidth +
                        GAP_BETWEEN_TITLE_AND_PILLS +
                        (datePillList.length - 1) * GAP_BETWEEN_PILLS <
                    wrapperWidth
                ) {
                    setDatePillList(PILLS_LIST);
                    return PILLS_LIST;
                }
            }
        } else {
            const pillsToReturn = PILLS_LIST.slice(0, 3);
            setDatePillList(pillsToReturn);
            return pillsToReturn;
        }
    }, [
        PILLS_LIST,
        listRef,
        wrapperRef,
        titleRef,
        isTablet,
        isMobile,
        datePillList.length,
    ]);

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [timeFrame, handleResize]);

    React.useEffect(() => {
        const initialDateListPills = handleResize();

        handleCustomDateRangeChange(timeFrame, initialDateListPills);
        // run only ones
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DatePickerWidgetWrapper
            ref={wrapperRef}
            container
            alignItems="center"
            className={className}
            data-testid="datepicker-widget-wrapper"
            gap={`${GAP_BETWEEN_TITLE_AND_PILLS}px`}
            id="datepicker-widget-wrapper"
            justifyContent="space-between"
        >
            <Grid
                ref={titleRef}
                item
                data-testid="date-picker-title"
                id="datepicker-title"
            >
                <DatePickerWidgetTitle
                    marginRight="27px"
                    text={title}
                    variant={titleVariant}
                />
            </Grid>
            <Wrapper
                ref={listRef}
                item
                data-testid="datepicker-list"
                id="datepicker-list"
                justifyContent="center"
            >
                <DatesWrapper
                    className="dates-wrapper"
                    data-testid="date-pills-wrapper"
                >
                    {datePillList.map((item) => (
                        <DatePill
                            key={item.id}
                            ref={item.id === 'custom' ? ref : undefined}
                            disableRipple
                            className={
                                timeFrame.selector === item.id
                                    ? 'active'
                                    : undefined
                            }
                            data-testid={`${item.id}-btn`}
                            id={item.id}
                            isActive={timeFrame.selector === item.id}
                            onClick={handleDateClick}
                            text={item.label}
                            variant="primary"
                        />
                    ))}
                    <DatePill
                        ref={ref}
                        disableRipple
                        data-testid={`custom-btn`}
                        id="custom"
                        isActive={customDateText !== null}
                        onClick={handleDateClick}
                        text={`${moreDates}${
                            customDateText && !isMobile && !isSmallTablet
                                ? `: ${customDateText}`
                                : ''
                        }`}
                        variant="primary"
                    />
                </DatesWrapper>
                <GivelifyDatePicker
                    disableFuture
                    hideTextInput
                    calendarAnchorRef={ref}
                    end={timeFrame.end}
                    minDate={signUpDate}
                    name={PAGE_NAME.Datepicker}
                    onClose={onCalendarClose}
                    onRangeChange={onRangeChange}
                    open={calendarOpen}
                    pickerMode="range"
                    popoverProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    }}
                    range={timeFrame.selector}
                    start={timeFrame.start}
                />
            </Wrapper>
        </DatePickerWidgetWrapper>
    );
};
