import React, { useMemo, useRef, useState, useEffect } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { useTrackingContext } from '@givelify/utils';
import { Box, Grid } from '@material-ui/core';
import OnEnterChangeTextField from 'components/inputs/OnEnterChangeTextField';
import { matchRowStyle } from 'pages/integrations/components/style';
import { integrationWithoutEnvelope } from 'pages/integrations/IntegrationContent';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MatchAsync } from 'store/integrations/thunks';
import { INTEGRATIONS_MATCH_ENVELOPE } from 'utils/clevertapEvents';

interface EnvelopeMatchingRowContentProps {
    id: number;
    doneeId: number;
    name: string;
    reportId: number;
    integration?: IntegrationTypes;
    className: string;
    externalId: string | null;
    onSuccess: () => void;
    campusName: string;
    allCampuses: boolean;
    isReadOnly: boolean;
}

const EnvelopeMatchingRowContent: React.FC<EnvelopeMatchingRowContentProps> = ({
    doneeId,
    id,
    integration,
    className,
    name,
    externalId,
    onSuccess,
    reportId,
    campusName,
    allCampuses,
    isReadOnly,
}) => {
    const classes = matchRowStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { trackEvent } = useTrackingContext();

    const inputRef = useRef(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const [updatedValue, setUpdatedValue] = useState<string>('');
    const [isError, setIsError] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const currentValue = isUpdated ? updatedValue : externalId;
    const showInput = isEdit || !currentValue;

    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);

    const itemClick = () => {
        setIsEdit(true);
    };

    useEffect(() => {
        if (isEdit) {
            inputRef.current.focus();
        }
    }, [isEdit]);

    const updateEnvelope = async (newValue: string) => {
        if ((newValue || '') === (currentValue || '')) return;

        trackEvent(INTEGRATIONS_MATCH_ENVELOPE, {
            envelopeId: id,
            reportId: reportId,
        });

        const result = await MatchAsync(
            'envelope',
            doneeId,
            id,
            newValue,
            currentValue,
            reportId,
        )(dispatch, null, null);

        if (!componentIsMounted.current) return;

        setIsError(!result.success);
        if (result.success) {
            setIsUpdated(true);
            setUpdatedValue(result.result ?? '');
            onSuccess();
            setIsEdit(false);
        } else {
            inputRef.current.focus();
        }
    };

    const { placeHolder } = useMemo(
        () => ({
            placeHolder: t(
                'pages.integration_envelope_matching.content.table.heading.code',
                {
                    reportType: !integrationWithoutEnvelope.includes(
                        integration.integrationType,
                    )
                        ? integration.abbreviation
                        : '',
                    fundCodeLabel: integration.fundCodeLabel,
                },
            ),
        }),
        [t, integration],
    );

    return (
        <Grid
            container
            alignItems="center"
            className={`${classes.matchRowContent} ${className}`}
            justifyContent="space-between"
        >
            <Grid
                item
                className={classes.envelopeName}
                lg={9}
                md={9}
                sm={6}
                xl={9}
                xs={12}
            >
                <Box display="flex" flexDirection="column">
                    <GivelifyLabel bold text={name} variant="body2" />
                    {allCampuses && (
                        <GivelifyLabel text={campusName} variant="body3" />
                    )}
                </Box>
            </Grid>
            <Grid
                item
                className={classes.integrationInputRight}
                lg={3}
                md={3}
                sm={6}
                xl={3}
                xs={12}
            >
                {showInput && (
                    <OnEnterChangeTextField
                        fullWidth
                        className={classes.integrationInput}
                        defaultValue={currentValue}
                        disabled={isReadOnly}
                        error={isError}
                        inputRef={inputRef}
                        onEnter={updateEnvelope}
                        placeholder={placeHolder}
                        type="tel"
                    />
                )}
                {!showInput && (
                    <Box className={classes.labelWrapper}>
                        <GivelifyButton
                            wrap
                            className={classes.matchButton}
                            disabled={isReadOnly}
                            name="matchedEnvelopeValue"
                            onClick={itemClick}
                            size="auto"
                            text={currentValue}
                            variant="link"
                        />
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default EnvelopeMatchingRowContent;
