import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { GivelifyCheckbox, GivelifyCheckboxProps } from '../checkbox';
import { OmitFields } from './types';

export type GivelifyFormCheckboxProps<Form = Record<string, unknown>> = Omit<
    GivelifyCheckboxProps,
    OmitFields
> & {
    name: keyof Form;
};

export const GivelifyFormCheckbox = <Form,>(
    props: GivelifyFormCheckboxProps<Form>,
) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ name, value, onChange }) => {
                return (
                    <GivelifyCheckbox
                        {...props}
                        checked={value}
                        name={name}
                        onChange={(e) => onChange(e.target.checked)}
                    />
                );
            }}
        />
    );
};
