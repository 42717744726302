import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Logger } from '@givelify/utils';
import { webConfig } from './webConfig';

// This code tries to detect whether user has any js blocker enabled or not
// First we check if global objects exists in the window's scope
// If not - they most probably blocked
// If we did not find any issues the we try to make some HEAD http calls for urls with tracking libs
// If any of the requests is failed - user most probably has blocker installed
// If any blocker is found we log result with Givelify Logger since it will not be affected by any blocker

const url1 = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
const url2 = 'https://bat.bing.com/bat.js';
const url3 = 'https://connect.facebook.net/en_US/fbevents.js';

const detectWithRequest = async (url) => {
    try {
        const request = fetch(url, {
            method: 'HEAD',
            mode: 'no-cors',
        });
        const response = await request;
        if (response.redirected) {
            // If the request is redirected, then the ads are blocked.
            return true;
        }
    } catch {
        // If the request fails completely although connected to the internet,
        // then the ads are blocked.
        return window.navigator.onLine;
    }
    return false;
};

const useGivelifyDetector = () => {
    useEffect(() => {
        if (webConfig.environment === 'development') return;

        let datadogOk = false;
        datadogRum.onReady(() => {
            datadogOk = true;
        });

        setTimeout(async function () {
            let isError = false;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (window.ga === undefined) {
                isError = true;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (window.Appcues === undefined) {
                isError = true;
            }

            if (!isError) {
                isError = await detectWithRequest(url1);
            }
            if (!isError) {
                isError = await detectWithRequest(url2);
            }
            if (!isError) {
                isError = await detectWithRequest(url3);
            }

            if (isError || !datadogOk) {
                Logger.log('Blocker detected');
            }
        }, 5000);
    }, []);
};

export { useGivelifyDetector };
