import { styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    gap: '24px',
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('tablet')]: {
        flexWrap: 'wrap',
    },
}));

export const Item = styled('div')(({ theme }) => ({
    flex: '1',
    [theme.breakpoints.down('tablet')]: {
        width: '100%',
        flex: 'none',
    },
}));
