import React from 'react';
import { GivelifyDigitRoll, GivelifyTabOption } from '@givelify/ui';
import {
    BUTTON_IDENTIFIER,
    isSucceeded,
    PAGE_NAME,
    RequestState,
    responseOrUndefined,
    TrackingProvider,
    useApiRequest,
    useTrackingContext,
} from '@givelify/utils';
import { TimeFrameValues } from '@givelify/utils';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material';
import { DonationsStatisticType } from 'api/client/endpoints';
import { useTimeframeFilter } from 'api/hooks';
import { DonationStatistic } from 'api/models';
import { GetDonationsStatisticResponse } from 'api/services/responses/donations';
import { GetAllEnvelopesResponse } from 'api/services/responses/envelopes';
import {
    getDonationService,
    getEnvelopesService,
} from 'api/utils/serviceProvider';
import { DatePickersWidget } from 'components';
import PageTitle from 'components/PageTitle';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH as ROUTE_CONSTANTS } from 'router/routes';
import { AppState } from 'store';
import {
    getRangeBasedDate,
    getRangeBasedDateShort,
} from 'utils/strings/getRangeBasedDateFormat';
import { getRangeDateType } from 'utils/timeFrameUtils';
import { DonationPaper } from '../donationsPage/styles';
import { DonationSummaryResult } from '../donationSummaryResult';
import {
    DatePickerWrapper,
    DonationSummaryTabs,
    NumbersRollWrapper,
} from './styles';

export type FormattedDataType = {
    id: string | number;
    name: string;
    amount: number;
    donationsAmount: number;
    donorsAmount: number;
    startDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
};

export type DonationStatisticsData = {
    data: DonationStatistic[];
    rangeDateType: DonationsStatisticType;
}[];

const DonationSummaryPage = () => {
    const { PATH } = useGasRouterContext();
    const [timeFrame, setTimeFrame] = useTimeframeFilter(
        TimeFrameValues['thisMonth'],
    );
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const [currentTab, setCurrentTab] = React.useState<number>(
        location.pathname.includes(
            ROUTE_CONSTANTS.DONATIONS.DONATION_SUMMARY.BY_DATE,
        )
            ? 1
            : 0,
    );
    const { trackEvent } = useTrackingContext();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            donationSummaryTitle: t('donationSummary.title'),
            filterByEnvelopes: t('donationSummary.byEnvelope'),
            filterByCampaigns: t('donationSummary.byCampaign'),
            filterByDate: t('donationSummary.byDate'),
        }),
        [t],
    );
    const { doneeId, isNonProfit } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        isNonProfit: state.Donee.donee.type !== 'church',
    }));
    const [getEnvelopesDataRequest, makeGetEnvelopesDataRequest] =
        useApiRequest<GetAllEnvelopesResponse>();
    const [getDonationsDataRequest, makeGetDonationsDataRequest] =
        useApiRequest<GetDonationsStatisticResponse>();
    const [donationsData, setDonationsData] =
        React.useState<DonationStatisticsData | null>(null);

    const envelopeService = getEnvelopesService();
    const donationService = getDonationService();

    const rangeDateType = getRangeDateType(timeFrame, isMobile);

    React.useEffect(() => {
        if (currentTab === 0) {
            void makeGetEnvelopesDataRequest(
                envelopeService.getAllEnvelopesData(
                    doneeId,
                    timeFrame.start,
                    timeFrame.end,
                ),
            );
        } else {
            setDonationsData(null);

            const start = timeFrame.start;
            const end = timeFrame.end;
            const startOfMonth = end.startOf('month');
            const startOfYear = end.startOf('year');
            const diffDays = end.diff(start, 'day');
            const diffMonths = end.diff(start, 'month');
            const diffYears = startOfYear.diff(start, 'year');
            const isDiffMonth = !start.isSame(end, 'month');
            const isDiffYear = !start.isSame(end, 'year');
            const isEndJan = end.isSame(startOfYear, 'month');
            const requests: {
                data: Promise<RequestState<GetDonationsStatisticResponse>>;
                rangeDateType: DonationsStatisticType;
            }[] = [];
            if (diffDays >= 0) {
                requests.push({
                    data: makeGetDonationsDataRequest(
                        donationService.getDonationsStatistic(
                            doneeId,
                            start.isAfter(startOfMonth) ? start : startOfMonth,
                            end,
                            'daily',
                            true,
                        ),
                    ),
                    rangeDateType: 'daily',
                });
            }
            if (diffMonths >= 0 && isDiffMonth && !isEndJan) {
                requests.push({
                    data: makeGetDonationsDataRequest(
                        donationService.getDonationsStatistic(
                            doneeId,
                            start.isAfter(startOfYear) ? start : startOfYear,
                            startOfMonth.add(-1, 'day'),
                            'monthly',
                            true,
                        ),
                    ),
                    rangeDateType: 'monthly',
                });
            }
            if (diffYears >= 0 && isDiffYear) {
                requests.push({
                    data: makeGetDonationsDataRequest(
                        donationService.getDonationsStatistic(
                            doneeId,
                            start,
                            startOfYear.add(-1, 'day'),
                            'yearly',
                            true,
                        ),
                    ),
                    rangeDateType: 'yearly',
                });
            }
            Promise.all(requests.map((request) => request.data)).then(
                (responses) => {
                    setDonationsData(
                        responses
                            .filter((r) => isSucceeded(r))
                            .map((r, index) => ({
                                data: r.response?.data.reverse(),
                                rangeDateType: requests[index].rangeDateType,
                            })),
                    );
                },
            );
        }
    }, [
        timeFrame,
        doneeId,
        makeGetEnvelopesDataRequest,
        makeGetDonationsDataRequest,
        envelopeService,
        donationService,
        currentTab,
        rangeDateType,
    ]);

    const tabsOptions: GivelifyTabOption[] = React.useMemo(
        () => [
            {
                tabHref: PATH.DONATIONS.DONATION_SUMMARY_ENVELOPES({
                    params: {
                        startDate: timeFrame.start.format('YYYY-MM-DD'),
                        endDate: timeFrame.end.format('YYYY-MM-DD'),
                        timeFrame: timeFrame.selector,
                    },
                }),
                label: isNonProfit
                    ? copy.filterByCampaigns
                    : copy.filterByEnvelopes,
                id: 'envelopes-tab',
            },
            {
                tabHref: PATH.DONATIONS.DONATION_SUMMARY_DATE({
                    params: {
                        startDate: timeFrame.start.format('YYYY-MM-DD'),
                        endDate: timeFrame.end.format('YYYY-MM-DD'),
                        timeFrame: timeFrame.selector,
                    },
                }),
                label: copy.filterByDate,
                id: 'date-tab',
            },
        ],
        [
            PATH.DONATIONS,
            timeFrame.start,
            timeFrame.end,
            timeFrame.selector,
            isNonProfit,
            copy,
        ],
    );

    const onTabChange = React.useCallback(
        (value: number) => {
            setCurrentTab(value);
            navigate(tabsOptions[value].tabHref);
            trackEvent(`<${tabsOptions[value].label}>_${BUTTON_IDENTIFIER}`);
        },
        [navigate, tabsOptions, trackEvent],
    );

    const totalDonationsAmount = React.useMemo(() => {
        if (currentTab === 0) {
            if (getEnvelopesDataRequest.type === 'REQUEST_SUCCESS') {
                return getEnvelopesDataRequest?.response?.totals.sum;
            }
        } else {
            if (donationsData) {
                return donationsData.reduce((acc, cur) => {
                    return acc + cur.data.reduce((a, c) => a + c.sum, 0);
                }, 0);
            }
        }
        return null;
    }, [donationsData, currentTab, getEnvelopesDataRequest]);

    const infoSummaryEnvelopeClickHandler = React.useCallback(
        (envelopeId: number) => {
            const url = PATH.DONATIONS.DONATIONS_ACTIVITY({
                params: {
                    envelopeIds: envelopeId.toString(),
                    startDate: timeFrame.start.format('YYYY-MM-DD'),
                    endDate: timeFrame.end.format('YYYY-MM-DD'),
                    timeFrame: timeFrame.selector,
                },
            });
            navigate(url);
        },
        [
            PATH.DONATIONS,
            navigate,
            timeFrame.end,
            timeFrame.selector,
            timeFrame.start,
        ],
    );

    const infoSummaryDateClickHandler = React.useCallback(
        (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
            const url = PATH.DONATIONS.DONATIONS_ACTIVITY({
                params: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                    timeFrame: 'custom',
                },
            });
            navigate(url);
        },
        [PATH.DONATIONS, navigate],
    );

    const formatData: FormattedDataType[] = React.useMemo(() => {
        if (currentTab === 0) {
            const data = responseOrUndefined(getEnvelopesDataRequest)?.data;
            return (
                data?.map((item) => ({
                    id: item.envelopeId,
                    name: item.envelopeName,
                    amount: item.sum,
                    donationsAmount: item.count,
                    donorsAmount: item.donorsCount,
                })) || []
            );
        } else {
            return (
                donationsData
                    ?.map((item, index) => {
                        let name: string;
                        return item?.data?.map((donation) => {
                            const startDate = dayjs(donation.startDateTime);
                            const endDate = dayjs(donation.endDateTime);

                            if (timeFrame.selector === 'custom') {
                                name = getRangeBasedDateShort(
                                    startDate,
                                    endDate,
                                    item.rangeDateType,
                                );
                            } else {
                                name = getRangeBasedDate(
                                    startDate,
                                    endDate,
                                    item.rangeDateType,
                                );
                            }

                            return {
                                id: `${name}-${index}`,
                                name: name,
                                amount: donation.sum,
                                donationsAmount: donation.count,
                                donorsAmount: donation.donorsCount,
                                startDate: startDate,
                                endDate: endDate,
                            };
                        });
                    })
                    ?.flatMap((data) => data) || []
            );
        }
    }, [
        currentTab,
        getEnvelopesDataRequest,
        donationsData,
        timeFrame.selector,
    ]);

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.DonationSummary}>
            <PageTitle
                description={copy.donationSummaryTitle}
                title={copy.donationSummaryTitle}
            />

            <DonationPaper>
                <DatePickerWrapper>
                    <DatePickersWidget
                        setTimeFrame={setTimeFrame}
                        timeFrame={timeFrame}
                        title={copy.donationSummaryTitle}
                        titleVariant="heading1M"
                    />
                </DatePickerWrapper>

                <NumbersRollWrapper data-testid="donations-total-amount">
                    <GivelifyDigitRoll
                        delay={3}
                        height={isMobile ? 1.9 : isTablet ? 3.8 : 4.8}
                        num={totalDonationsAmount || '000000'}
                        width={isMobile ? 1.35 : isTablet ? 3.25 : 4.25}
                    />
                </NumbersRollWrapper>

                <DonationSummaryTabs
                    onChange={onTabChange}
                    options={tabsOptions}
                    value={currentTab}
                />

                {currentTab === 0 ? (
                    <DonationSummaryResult
                        data={formatData}
                        isSuccess={isSucceeded(getEnvelopesDataRequest)}
                        linkClickHandler={infoSummaryEnvelopeClickHandler}
                    />
                ) : (
                    <DonationSummaryResult
                        data={formatData}
                        isSuccess={
                            donationsData &&
                            isSucceeded(getDonationsDataRequest)
                        }
                        linkClickHandler={infoSummaryDateClickHandler}
                    />
                )}
            </DonationPaper>
        </TrackingProvider>
    );
};

export const DonationSummary = React.memo(DonationSummaryPage);
