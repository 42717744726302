import React, { useMemo } from 'react';
import {
    FacebookLoading,
    GivelifyButton,
    GivelifyLabel,
    GivelifyLabelStyles,
    RequestState,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { Theme, useMediaQuery } from '@material-ui/core';
import { OrganizationSuggestionsResponse } from '../types';
import ListItem from './ListItem';
import useStyles from './styles';

interface ResultListProps {
    suggestionsRequestState: RequestState<OrganizationSuggestionsResponse[]>;
    onSelect: (item: OrganizationSuggestionsResponse) => void;
}

const ResultList: React.FC<ResultListProps> = ({
    suggestionsRequestState,
    onSelect,
}) => {
    const classes = useStyles();
    const { body1, bold } = GivelifyLabelStyles({});
    const isMobile = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down('sm'),
    );

    const isLoading =
        suggestionsRequestState.type === 'REQUEST_INIT' ||
        suggestionsRequestState.type === 'REQUEST_START';
    const isSuccess = suggestionsRequestState.type === 'REQUEST_SUCCESS';
    const isError = suggestionsRequestState.type === 'REQUEST_ERROR';

    const addManuallyClick = () => {
        onSelect(null);
    };

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY:
            'pages.settings.campuses.addCampusModal.searchForm.resultList',
    });

    const copy = useMemo(
        () => ({
            found: scopedTranslate('found'),
            orgsFound: scopedTranslate('orgsFound'),
            cantFindCampus: scopedTranslate('cantFindCampus'),
            addManually: scopedTranslate('addManually'),
            errorMessage: scopedTranslate('errorMessage'),
        }),
        [scopedTranslate],
    );

    return (
        <>
            <div className={classes.details}>
                {isSuccess && (
                    <div className={body1}>
                        <span className={bold}>
                            {suggestionsRequestState.data?.length || 0}
                        </span>{' '}
                        {isMobile ? copy.found : copy.orgsFound}
                    </div>
                )}
                {isLoading && (
                    <FacebookLoading borderRadius={4} height={22} width={150} />
                )}
                {isSuccess &&
                    (isMobile ? (
                        <div className={body1}>
                            {`${copy.cantFindCampus} `}
                            {/* eslint-disable-next-line */}
                            <a
                                className={classes.link}
                                href="#"
                                onClick={addManuallyClick}
                            >
                                {copy.addManually}
                            </a>
                        </div>
                    ) : (
                        <GivelifyButton
                            onClick={addManuallyClick}
                            text={copy.cantFindCampus}
                            variant="link"
                        />
                    ))}
                {isLoading && (
                    <FacebookLoading borderRadius={4} height={22} width={150} />
                )}
            </div>
            <div className={classes.listWrapper}>
                {isLoading &&
                    Array(8)
                        .fill(0)
                        .map((_, index) => <ListItem key={index} isLoading />)}
                {isSuccess && (
                    <>
                        {suggestionsRequestState.data.map((v, index) => (
                            <ListItem
                                key={index}
                                data={v}
                                onSelect={onSelect}
                            />
                        ))}
                        <div className={classes.cantFindListItem}>
                            <GivelifyLabel
                                bold
                                text={copy.cantFindCampus}
                                variant="body1"
                            />
                            <GivelifyButton
                                onClick={addManuallyClick}
                                text={copy.addManually}
                                variant="link"
                            />
                        </div>
                    </>
                )}
                {isError && copy.errorMessage}
            </div>
        </>
    );
};

export default ResultList;
