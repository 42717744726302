import { useEffect } from 'react';
import { useApiRequest } from '@givelify/utils';
import { getOfficers } from 'api/requests/Officers';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { setOfficers } from 'store/settings/officers/actions';
import { Officer } from 'store/settings/officers/types';

export const usePendingInvites = () => {
    const dispatch = useDispatch();

    const { pendingInvites, doneeId } = useSelector((state: AppState) => ({
        pendingInvites: state.Settings.Officers.officers?.filter(
            (user) => !user.active,
        ),
        doneeId: state.Donee.donee.id,
    }));

    const [requestState, makeApiRequest] = useApiRequest<{
        data: Officer[];
    }>();

    useEffect(() => {
        makeApiRequest(getOfficers(doneeId));
    }, [doneeId, makeApiRequest]);

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            dispatch(setOfficers(requestState.response.data));
        }
    }, [requestState, dispatch]);

    return pendingInvites.length;
};
