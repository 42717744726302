import React, { useMemo } from 'react';
import { TimeFrameValue } from '@givelify/utils';
import { TimeFrameFilter } from 'components';
import { useTranslation } from 'react-i18next';
import Heading from '../../../components/Heading';
import useStyles from './styles';

interface TopDashboardGridProps {
    setTimeFrame: (timeFrame: TimeFrameValue) => unknown;
    timeFrame: TimeFrameValue;
}

const TopDashboardGrid: React.FC<TopDashboardGridProps> = ({
    setTimeFrame,
    timeFrame,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            dashboardHeading: t('dashboard.overviewTitle'),
        }),
        [t],
    );

    return (
        <div className={classes.wrapper}>
            <div className={classes.heading}>
                <Heading text={copy.dashboardHeading} />
            </div>
            <TimeFrameFilter onChange={setTimeFrame} value={timeFrame} />
        </div>
    );
};

export default TopDashboardGrid;
