import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../consts';
import {
    DocumentUploadSection,
    DocumentUploadSectionProps,
} from './DocumentUploadSection';

export const RepUploadSection: React.FunctionComponent<
    Omit<DocumentUploadSectionProps, 'title' | 'description'>
> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const heading = t('primaryRepresentativePage.upload.heading');
    const caption = t('primaryRepresentativePage.upload.caption');

    const title = useMemo(
        () => (
            <GivelifyLabel
                bold
                marginBottom={6}
                text={heading}
                variant="heading5"
            />
        ),
        [heading],
    );

    const description = useMemo(
        () => (
            <GivelifyLabel marginBottom={12} text={caption} variant="body1" />
        ),
        [caption],
    );
    return (
        <DocumentUploadSection
            {...props}
            description={description}
            title={title}
        />
    );
};
