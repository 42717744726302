export type Pagination = {
    total: number;
    count: number;
    per_page: string;
    current_page: number;
    total_pages: number;
};

export type PaginationCamelCase = {
    total: number;
    count: number;
    perPage: string;
    currentPage: number;
    totalPages: number;
};

export interface PaginatedResponse<T> {
    data: T[];
    pagination: Partial<Pagination>;
}

export interface PaginatedCamelCaseResponse<T> {
    data: T[];
    pagination: Partial<PaginationCamelCase>;
}

export const initialPaginatedResponse = {
    data: [],
    pagination: {},
};

export const initialPagination: Pagination = {
    count: 0,
    current_page: 0,
    per_page: '0',
    total: 0,
    total_pages: 0,
};

export const initialPaginationCamelCase: PaginationCamelCase = {
    count: 0,
    currentPage: 0,
    perPage: '0',
    total: 0,
    totalPages: 0,
};
