import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithLocaltime } from '@givelify/utils';

interface Props {
    row: { startDate: Date; endDate: Date };
}

export const DateRangeFormatter: React.FC<Props> = ({
    row,
}: {
    row: { startDate: Date; endDate: Date };
}) => {
    return (
        <GivelifyLabel
            text={`${formatWithLocaltime(
                row.startDate,
            )} - ${formatWithLocaltime(row.endDate)}`}
            variant="body2"
            whiteSpace="nowrap"
        />
    );
};

export const DateRangeCell = (props: Table.DataCellProps) => {
    return (
        <Table.Cell {...props}>
            <DateRangeFormatter row={props.row} />
        </Table.Cell>
    );
};
