import React from 'react';
import { Skeleton } from '@mui/material';
import {
    Divider,
    EnvelopeSummaryWrapper,
    InfoPart,
    NameWrapper,
    SkeletonWrapperLarge,
    SkeletonWrapperMiddle,
    SkeletonWrapperSmall,
} from './styles';

const SkeletonEnvelopeSummaryComponent = () => {
    return (
        <EnvelopeSummaryWrapper>
            <NameWrapper>
                <SkeletonWrapperSmall>
                    <Skeleton animation="wave" height="100%" width="100%" />
                </SkeletonWrapperSmall>
            </NameWrapper>
            <Divider />
            <InfoPart>
                <SkeletonWrapperMiddle>
                    <Skeleton animation="wave" height="100%" width="100%" />
                </SkeletonWrapperMiddle>
                <SkeletonWrapperLarge>
                    <Skeleton animation="wave" height="100%" width="100%" />
                </SkeletonWrapperLarge>
            </InfoPart>
        </EnvelopeSummaryWrapper>
    );
};

export const SkeletonEnvelopeSummary = React.memo(
    SkeletonEnvelopeSummaryComponent,
);
