import { createStyles, makeStyles } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifySelectStyleProps = {
    width: number | undefined;
    fullwidth: boolean | undefined;
} & GivelifyBoxMarginProps;

export const givelifySelectStyle = makeStyles(() =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifySelectStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifySelectStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifySelectStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifySelectStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        selectInputHolder: {
            width: (props: GivelifySelectStyleProps) => {
                if (props.fullwidth) return 'auto';
                if (props.width) return props.width;
                return 240;
            },
        },
        selectInputOptionsHolder: {
            padding: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
        },
        selectInputOptionItem: {
            padding: 10,
            margin: 0,
            borderBottom: '0.5px solid #D8D8D8',
            width: '100%',
            height: 48,
            display: 'flex',
            alignItems: 'center',
            '& p': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        textfieldEnd: {
            paddingRight: 35,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
);

export const givelifySelectOptionBaseStyle = makeStyles(() =>
    createStyles({
        selectInputOptionItemContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        selectInputCheck: {
            marginLeft: 'auto',
            marginRight: 3,
            color: '#676E77',
        },
    }),
);

export const givelifySelectBaseStyle = makeStyles(() =>
    createStyles({
        selectInputDropDownIcon: {
            position: 'absolute',
            right: 19.57,
            color: '#676E77',
            cursor: 'pointer',
        },
        selectInputDropDownRing: {
            marginRight: 12,
        },
        multiSelectorMenuOptions: {
            padding: 0,
            '& .Mui-selected': {
                backgroundColor: 'inherit',
            },
            '& .MuiMenuItem-root': {
                paddingRight: 50,
                paddingLeft: 4,
            },
        },
    }),
);
