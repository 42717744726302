import React from 'react';

import { GivelifyTextDivider } from '@givelify/givelify-ui';
import GiveNowIllustration from '../../../../assets/online-giving/givenow-illustration.svg';
import Label from '../../../../components/Label';
import { useTranslations } from '../../../../utils/translations';
import { useStyle } from './style';
// import { pushEvent } from '../../../utils/cleverTapUtils';
// import { ONLINE_GIVING_LANDED } from '../../../utils/cleverTapEvents';

export const CustomModalContent: React.FC = () => {
    const cs = useStyle();

    const { customModal: cm } = useTranslations('onlineGiving.givelifyButton', {
        customModal: [
            'title',
            'color',
            'colorDesc',
            'placement',
            'placementDesc',
        ],
    });

    return (
        <div className={cs.content} data-testid="custom-modal-content">
            <Label variant="heading3">{cm.title}</Label>

            <img
                alt="Give now"
                className={cs.illustration}
                src={GiveNowIllustration}
            />

            <div className={cs.mainBox}>
                <Label className={cs.sectionTitle} variant="heading4">
                    {cm.color}
                </Label>
                <Label variant="body1">{cm.colorDesc}</Label>
                <GivelifyTextDivider className={cs.divider} text="" />
                <Label className={cs.sectionTitle} variant="heading4">
                    {cm.placement}
                </Label>
                <Label variant="body1">{cm.placementDesc}</Label>
            </div>
        </div>
    );
};
