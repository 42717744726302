import { IntegrationPaginatedResponse } from '@givelify/givelify-ui';
import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { Envelope } from 'api/models';
import {
    getEnvelopesCreateEndpoint,
    getEnvelopesUpdateEndpoint,
    getlegacyEnvelopesEndPoint,
} from '../../client/endpoints';
import { CreateEnvelopesResponse } from '../../services/responses/envelopes/CreateEnvelopesResponse';
import { UpdateEnvelopeRequest } from './UpdateEnvelopeRequest';
import { UpdateEnvelopeResponse } from './UpdateEnvelopeResponse';

export * from './CreateEnvelopeRequest';
export * from './UpdateEnvelopePriorityRequest';
export * from './UpdateEnvelopeRequest';
export * from './UpdateEnvelopeResponse';

export const getEnvelopes = (
    doneeId: number,
    filter: 'any' | 'active' | 'inactive',
    page?: number,
    fetchAll?: boolean,
    sort?: string,
    givingTuesday2023 = false,
) => {
    const url = getlegacyEnvelopesEndPoint(
        doneeId,
        filter,
        page,
        fetchAll,
        sort,
        givingTuesday2023,
    );
    const httpRequest = getAxiosClient().get(url);
    return makeApiRequest<IntegrationPaginatedResponse<Envelope>>(httpRequest);
};

export const createEnvelope = (
    doneeId: number,
    data: UpdateEnvelopeRequest,
) => {
    const url = getEnvelopesCreateEndpoint(doneeId);
    const requestData = {
        active: data.active,
        alwaysOn: data.alwaysOn,
        name: data.name,
        detail: data.detail || undefined,
        externalFundNameOrNumber: data.externalId || undefined,
        goal: data.goal || undefined,
        isDetailPublic: data.isDetailPublic,
        startDate: data.start || undefined,
        endDate: data.end || undefined,
        priority: data.priority,
    };

    const httpRequest = getAxiosClient().post(url, requestData);
    return makeApiRequest<CreateEnvelopesResponse>(httpRequest);
};

export const updateEnvelope = (
    doneeId: number,
    data: UpdateEnvelopeRequest,
) => {
    const url = getEnvelopesUpdateEndpoint(doneeId, data.id);

    const requestData = data.isDefault
        ? {
              externalFundNameOrNumber: data.externalId,
              active: data.active,
          }
        : {
              name: data.name,
              detail: data.detail,
              externalFundNameOrNumber: data.externalId,
              goal: data.goal,
              active: data.active,
              isDetailPublic: data.isDetailPublic,
              startDate: data.start || undefined,
              endDate: data.end || undefined,
              alwaysOn: data.alwaysOn,
          };

    const httpRequest = getAxiosClient().patch(url, requestData);
    return makeApiRequest<UpdateEnvelopeResponse>(httpRequest);
};
