import { GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const ModalContent = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    [theme.breakpoints.down('mobile')]: {
        display: 'block',
        height: '100vh',
        overflow: 'auto',
    },
}));
export const ModalTitle = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    padding: '96px 40px',
    minWidth: 352,
    boxSizing: 'border-box',
    [theme.breakpoints.down('mobile')]: {
        maxWidth: '100%',
        minWidth: '100%',
        padding: '48px 20px',
    },
}));
export const Info = styled('div')(({ theme }) => ({
    padding: '96px 56px 48px 48px',
    flexGrow: 1,
    [theme.breakpoints.down('mobile')]: {
        padding: 48,
    },
}));
export const CloseModal = styled(GivelifyButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    fontSize: 30,
    [theme.breakpoints.down('mobile')]: {
        color: theme.palette.primary.light + '!important',
    },
}));
export const WorkSteps = styled('ol')({
    paddingLeft: 20,
    margin: 0,
});
export const Step = styled('li')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '&::marker': {
        color: theme.palette.primary.main,
        fontSize: 18,
        fontWeight: 700,
        fontFamily: 'Roboto',
    },
}));
export const DoneButtonGroup = styled('div')(({ theme }) => ({
    textAlign: 'center',
    marginTop: theme.spacing(4),
    width: '160px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > button': {
        height: '36px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));
export const LabelInline = styled(GivelifyLabel)({
    display: 'inline',
    lineHeight: '22px',
});
