import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={48}
        viewBox="0 0 48 48"
        width={48}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect fill="#282830" height="48" rx="24" width="48" />
        <path
            d="M32.974 10H37.724L27.3467 21.8605L39.5547 38H29.996L22.5092 28.2115L13.9426 38H9.18982L20.2893 25.3138L8.57812 10H18.3796L25.1469 18.9471L32.974 10ZM31.3069 35.1569H33.9389L16.9494 12.6937H14.125L31.3069 35.1569Z"
            fill="white"
        />
    </svg>
);

export const TwitterIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgTwitter}
        fontSize={fontSize}
        viewBox="0 0 48 48"
    />
);
