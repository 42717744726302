import React from 'react';
import { GivelifyModal } from '@givelify/ui';
import { Dialog, IconButton } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@mui/material';
import closeIcon from 'assets/integration-icons/times.svg';
import { useTranslation } from 'react-i18next';
import { ScheduledReport } from 'store/integration/types';
import { IntegrationTypes } from '../../types';
import { CCBContent } from './CCBContent';
import CommonContent from './CommonContent';
import useStyles from './styles';

export interface IntegrationSetupForm {
    donors: {
        donorId: number;
        memberId: number;
    }[];
    envelopes: {
        offeringId: number;
        externalId: number;
    }[];
    scheduledReport: ScheduledReport;
}

interface Props {
    open: boolean;
    handleClose: () => void;
    type?: IntegrationTypes;
    setShowBanner: () => void;
}

export const IntegrationsSetupDialog: React.FC<Props> = ({
    open,
    handleClose,
    type,
    setShowBanner,
}) => {
    const classes = useStyles({});
    const { t: trans } = useTranslation();
    const t = (key, d?) =>
        trans(`pages.integrations.content.setup-dialog.${key}`, d || '');
    const doneCallBack = () => {
        setShowBanner();
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const isCCBorF1Go =
        type?.integrationType === 'CCB' || type?.integrationType === 'F1GO';

    if (isCCBorF1Go) {
        return (
            <GivelifyModal
                disableBackdropClose
                fullWidth
                showCloseButton
                aria-labelledby="form-dialog-title"
                fullScreen={isMobile}
                maxWidth="md"
                name="f1IntegrationSetupModal"
                onClose={handleClose}
                open={open}
            >
                <CCBContent doneCallback={doneCallBack} t={t} type={type} />
            </GivelifyModal>
        );
    }

    return (
        <Dialog
            PaperProps={{
                className: classes.dialog,
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            onClose={handleClose}
            open={open}
        >
            <div className={classes.closeDialog}>
                <IconButton onClick={handleClose}>
                    <img alt="Close" src={closeIcon} title="Close" />
                </IconButton>
            </div>
            <CommonContent doneCallback={doneCallBack} t={t} type={type} />
        </Dialog>
    );
};
