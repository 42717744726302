import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
  downloadNow: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    flexShrink: 0,
  },
  downloadHelp: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 16,
    },
    flexShrink: 0,
    width: 270,
  },
  qrCodeBox: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'center',
  },
  helpContainer: {
    marginTop: 86,
    padding: 30,
    border: `1px solid ${theme.colors?.disabled}`,
    borderRadius: 11,
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
  },
  helpButtonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));
