import React from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import key from '../../../../assets/integration-icons/key.svg';
import login from '../../../../assets/integration-icons/login.svg';
import paper from '../../../../assets/integration-icons/paper.svg';
import { useF1Styles } from '../../IntegrationSetup/IntegrationSetupDialog/styles';
import F1IntegrationSetupStep from './F1IntegrationSetupStep';

interface F1IntegrationStepsProps {
    switchToLogin: () => void;
}

const F1IntegrationSteps: React.FC<F1IntegrationStepsProps> = ({
    switchToLogin,
}) => {
    const classes = useF1Styles();
    const { t: trans } = useTranslation();
    const t = (key, d?) =>
        trans(`pages.integrations.content.setup-dialog.${key}`, d || '');
    const copy = {
        step1Title: t('f1IntegrationSetup.step1.step1Title'),
        step1Content: t('f1IntegrationSetup.step1.step1Content'),
        step2Title: t('f1IntegrationSetup.step2.step2Title'),
        step2Content: t('f1IntegrationSetup.step2.step2Content'),
        step3Title: t('f1IntegrationSetup.step3.step3Title'),
        step3Content: t('f1IntegrationSetup.step3.step3Content'),
        f1Content: t('f1IntegrationSetup.f1Content'),
        understand: t('f1IntegrationSetup.understand'),
    };

    return (
        <>
            <Grid container>
                <F1IntegrationSetupStep
                    content={copy.step1Content}
                    hasStepLink={true}
                    icon={paper}
                    title={copy.step1Title}
                />
                <F1IntegrationSetupStep
                    content={copy.step2Content}
                    hasStepLink={true}
                    icon={key}
                    title={copy.step2Title}
                />
                <F1IntegrationSetupStep
                    content={copy.step3Content}
                    hasStepLink={false}
                    icon={login}
                    title={copy.step3Title}
                />
            </Grid>
            <GivelifyLabel
                className={classes.f1Content}
                text={copy.f1Content}
                variant="body2"
            />
            <div className={classes.f1Continue}>
                <GivelifyButton
                    name="understand"
                    onClick={switchToLogin}
                    size="xLarge"
                    text={copy.understand}
                    type="submit"
                    variant="primary"
                />
            </div>
        </>
    );
};

export default F1IntegrationSteps;
