import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { GivelifyNotification, StateType } from '../notifications';
import { useTrackingContext } from '@givelify/utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notificationStyles: {
            marginBottom: theme.spacing(4),
        },
    }),
);

interface ModalTopMessageProps {
    variant: StateType;
    message: string | undefined;
}

const ModalTopMessage: React.FC<ModalTopMessageProps> = ({
    message,
    variant,
}) => {
    const { pageName } = useTrackingContext();
    const { notificationStyles } = useStyles();
    if (!message) return null;
    return (
        <GivelifyNotification
            name={`${pageName}_notification`}
            show
            variant={variant}
            text={message}
            onClose={undefined}
            className={notificationStyles}
        />
    );
};

export default ModalTopMessage;
