/**
 * Copy the content to the clipboard
 *
 * @param content string
 * @returns boolean
 */
export const copyToClipboard = async (content: string): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(content);
    } catch (err) {
        console.error('Failed to copy to clipboard: ', content);
        return false;
    }

    return true;
};
