import { defaultStartDate } from '@givelify/utils';
import { User } from 'api/models';

type SignedUser = {
    id: number;
    doneeId: number;
    username: string;
    fullName: string;
    name: string;
    lname: string;
    picture: string;
    isSuper?: boolean;
    avatarOriginal?: string;
    avatar: string;
    createdDateUser: string;
    doneeName: string;
    notify: boolean;
    phone: string;
    title: string;
    email: string;
    officialId: number;
    isSynagogue?: boolean;
    role: string;
    roleId: number;
};

export type SignInResponse = {
    accessToken: string;
    expiresIn: number;
    tokenType: string;
    user: SignedUser;
};

export const mapSignedUserToLocalUser = (user: SignedUser): User => {
    return {
        ...user,
        username: user.username || user.email,
        profilePicUrl: user.picture,
        createdDateUser: new Date(user.createdDateUser || defaultStartDate),
        notify: user.notify || false,
        firstName: user.name,
        lastName: user.lname,
    };
};
