import { ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from '..';
import { httpGet } from '../thunks';

interface StatisticsThunkProps {
    loading: AppActions;
    //eslint-disable-next-line
    success: (data: object) => AppActions;
    //eslint-disable-next-line
    error: (error: object) => AppActions;
    url: string;
}

export const getStatisticsBaseUrl = (
    endpoint: 'donations' | 'donors' | 'envelopes',
    doneeId: number,
): string => `/donees/${doneeId}/${endpoint}/statistics`;

export const getStatisticsThunk =
    (
        props: StatisticsThunkProps,
    ): ThunkDispatch<AppState, undefined, AppActions> =>
    async (dispatch) => {
        dispatch(props.loading);
        try {
            const response = await httpGet(props.url)(dispatch);
            dispatch(props.success(response.data));
        } catch (e) {
            dispatch(props.error(e));
        }
    };
