import { DesignTokens, GivelifyAvatar } from '@givelify/ui';
import { Box, styled } from '@mui/material';

export const ImageWrapper = styled('div')(({ theme }) => ({
    flex: '0 0 auto',
    width: '32px',
    height: '32px',
    borderRadius: '100px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        width: '16px',
        height: '16px',
        marginRight: theme.spacing(1.5),
    },
}));

export const Image = styled(GivelifyAvatar)(({ theme }) => ({
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
    },
}));
export const DesktopName = styled('div')(({ theme }) => ({
    flex: '0 0 auto',
    paddingRight: theme.spacing(4),
    maxWidth: '180px',
    minWidth: '115px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const MessageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
    },
}));

export const MessageText = styled('div')(({ theme }) => ({
    textWrap: 'wrap',
}));

export const MobileName = styled('div')(({ theme }) => ({
    fontSize: '14px',
    display: 'none',
    [theme.breakpoints.down('mobile')]: {
        flex: '0 0 auto',
        display: 'block',
        width: 'calc(100% - 28px)',
        overflowWrap: 'anywhere',
    },
}));

export const Bubble = styled('div')(({ theme }) => ({
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: DesignTokens.color.chartGrey200,
    borderRadius: '25px',
    fontSize: '16px',
    padding: theme.spacing(1, 3),
    overflowWrap: 'anywhere',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        marginLeft: '20px',
        marginTop: theme.spacing(1),
    },
}));

export const MessageDate = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    fontSize: '14px',
    [theme.breakpoints.down('tablet')]: {
        textAlign: 'start',
        marginLeft: '20px',
        fontSize: '12px',
    },
}));

export const Message = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        flexWrap: 'wrap',
        width: '100%',
    },
}));
