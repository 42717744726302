import * as React from 'react';
import {
    GivelifyBox,
    GivelifyBoxMarginProps,
    GivelifyButton,
    GivelifyLabel,
    mergeClassNames,
} from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import PhonePng from '../../assets/phone-preview.png';
import { I18N_NAMESPACE } from '../../consts';
import { CardProfileStyles } from './cardProfileStyles';

export interface CardProfileProps extends GivelifyBoxMarginProps {
    onCustomizeClick?: () => void;
    width?: number;
    dismissed?: boolean;
    disable?: boolean;
    requiredPartIsComplete?: boolean;
}

const cardStyleBreakpoint = 742;

export const CardProfile: React.FC<CardProfileProps> = ({
    dismissed,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    onCustomizeClick,
    width = 1040,
    disable,
    requiredPartIsComplete = false,
}) => {
    const { card, cardDismissal } = CardProfileStyles({ width });
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            title: t('onboardingCards.customizeYourProfile.title'),
            title2: t('onboardingCards.customizeYourProfile.title2'),
            description: t('onboardingCards.customizeYourProfile.description'),
            customizeProfile: t(
                'onboardingCards.customizeYourProfile.customizeProfile',
            ),
            sampleOrganization: t(
                'onboardingCards.customizeYourProfile.sampleOrganization',
            ),
        }),
        [t],
    );
    return (
        <GivelifyBox
            className={mergeClassNames(card, dismissed && cardDismissal)}
            margin={margin}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginTop={marginTop}
            opacity={disable ? 0.6 : 1}
        >
            <div
                className={mergeClassNames(
                    'content',
                    width > cardStyleBreakpoint && 'content-lg',
                    width <= cardStyleBreakpoint && 'content-xs',
                )}
            >
                <div className="main">
                    <GivelifyLabel
                        bold
                        className="title"
                        text={requiredPartIsComplete ? copy.title2 : copy.title}
                        variant="heading1"
                    />
                    <GivelifyLabel
                        className="description"
                        fontSize={18}
                        lineHeight={24}
                        text={copy.description}
                    />
                    <GivelifyButton
                        className="complete"
                        disableRipple={disable}
                        onClick={disable ? undefined : onCustomizeClick}
                        size="large"
                        text={copy.customizeProfile}
                        variant="primary"
                    />
                </div>
                <div className="misc">
                    <div className="image-holder">
                        <GivelifyLabel
                            marginBottom={16}
                            text={copy.sampleOrganization}
                            variant="body3"
                        />
                        <img
                            alt="profile example"
                            className="image"
                            src={PhonePng}
                            title="profile example"
                        />
                    </div>
                </div>
            </div>
        </GivelifyBox>
    );
};
