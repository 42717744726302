import React, { useMemo } from 'react';
import { FacebookLoading } from '@givelify/givelify-ui';
import { TimeFrameValue } from '@givelify/utils';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import DefaultLoading from '../components/DefaultLoading';
import OverviewPaper from '../components/OverviewPaper';
import { flattenGivingTrendData } from '../utils/flattenGivingTrendData';
import ZeroState from '../ZeroState';
import useStyles from './styles';
import View from './view';

interface StackedBarChartLogicProps {
    selectedTimeFrame: TimeFrameValue;
    dates: [Dayjs, Dayjs][];
}

const Loading: JSX.Element = (
    <FacebookLoading borderRadius={0} height={318} marginTop={4} width="100%" />
);

const src = '/overview/giving-trend.svg';
const srcGrey = '/overview/giving-trend-grey.svg';

const StackedBarChartLogic: React.FC<StackedBarChartLogicProps> = (props) => {
    const { barChartContainer } = useStyles();
    const { t } = useTranslation();

    const { givingTrendData, envelopes } = useSelector((state: AppState) => ({
        givingTrendData: state.Dashboard.Envelopes.givingTrend,
        envelopes: state.Dashboard.Envelopes.current,
    }));

    const flattendGivingTrendData = useMemo(
        () =>
            flattenGivingTrendData(
                givingTrendData,
                props.dates,
                props.selectedTimeFrame,
            ),
        [givingTrendData, props.dates, props.selectedTimeFrame],
    );

    const copy = {
        title: t('dashboard.givingTrend.title'),
        unknown: t('Unknown'),
    };

    const { onboardingEnabled, onboardingCompleted } = useSelector(
        (state: AppState) => ({
            onboardingEnabled: state.System.onboardingEnabled,
            onboardingCompleted: state.Donee.donee.onboarding.hasCompleted,
        }),
    );

    return (
        <OverviewPaper className={barChartContainer} title={copy.title}>
            <DefaultLoading
                error="Unknown"
                loading={Loading}
                requestState={envelopes}
            >
                {(envelopes) => {
                    let keys: { id: string; title: string }[];

                    if (
                        typeof envelopes === 'string' ||
                        envelopes.data.length === 0
                    ) {
                        return (
                            <ZeroState
                                src={
                                    onboardingEnabled && onboardingCompleted
                                        ? src
                                        : srcGrey
                                }
                                type={t('donationData')}
                            />
                        );
                    } else {
                        keys = envelopes.data.map((e) => ({
                            id: `${e.id || '0'}`,
                            title: e.id ? e.title : 'Other',
                        }));
                    }

                    return (
                        <View
                            data={flattendGivingTrendData}
                            dataKey="name"
                            keys={keys}
                        />
                    );
                }}
            </DefaultLoading>
        </OverviewPaper>
    );
};

export default StackedBarChartLogic;
