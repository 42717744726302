import {
    CLEAR_INTEGRATION,
    ClearIntegration,
    AutoIntegration,
    LoadTotalUnmatchedStateStart as LoadTotalUnmatchedStateStartActionType,
    LoadTotalUnmatchedDonorsStateSuccess as LoadTotalUnmatchedDonorsStateSuccessActionType,
    LoadTotalUnmatchedEnvelopesStateSuccess as LoadTotalUnmatchedEnvelopesStateSuccessActionType,
    SET_INTEGRATION,
    SetIntegration,
} from './types';

export const setIntegration = (data: AutoIntegration): SetIntegration => {
    return {
        type: SET_INTEGRATION,
        data: ({
            ...data,
        } as unknown) as AutoIntegration,
    };
};

export const LoadTotalUnmatchedStateStart = (): LoadTotalUnmatchedStateStartActionType => ({
    type: 'LOAD_TOTAL_UNMATCHED_STATE_START',
});

export const LoadTotalUnmatchedDonorsStateSuccess = (
    type: 'donors',
    count: number,
): LoadTotalUnmatchedDonorsStateSuccessActionType => ({
    type: 'LOAD_TOTAL_UNMATCHED_DONORS_STATE_SUCCESS',
    data: {
        count,
        type,
    },
});

export const LoadTotalUnmatchedEnvelopesStateSuccess = (
    type: 'envelopes',
    count: number,
): LoadTotalUnmatchedEnvelopesStateSuccessActionType => ({
    type: 'LOAD_TOTAL_UNMATCHED_ENVELOPES_STATE_SUCCESS',
    data: {
        count,
        type,
    },
});

export const clearIntegration = (): ClearIntegration => {
    return {
        type: CLEAR_INTEGRATION,
    };
};
