import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    legend: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));
