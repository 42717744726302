import { DonationRow } from 'api/models';
import { combineReducers } from 'redux';
import { PaginatedResponse } from '../../types/paginationTypes';
import DonationsReducer from './donation/reducer';

export interface DonationReducers {
    AllDonations: PaginatedResponse<DonationRow>;
}

export default combineReducers({
    AllDonations: DonationsReducer,
});
