import React, { useState } from 'react';
import { GivelifyMenu, OptionItem } from '@givelify/givelify-ui';
import { CampuseItem } from 'pages/integrations/IntegrationContent/TabsContent/EnvelopeMatching/types';

interface CampusesSelectProps {
    campusesOptions?: CampuseItem[];
    campusesFilter?: CampuseItem;
    onCampusesFilterChange: (value: CampuseItem) => void;
}

const CampusesSelect: React.FC<CampusesSelectProps> = ({
    campusesFilter,
    campusesOptions,
    onCampusesFilterChange,
}) => {
    const [isCampusOpen, setIsCampusOpen] = useState<boolean>(false);
    const [campusValue, setCampusValue] = useState<string>(
        campusesFilter.id.toString(),
    );
    const campusOptions: OptionItem[] = campusesOptions?.map(campus => ({
        label: campus.name,
        value: campus.id.toString(),
    }));
    const onChangeCampus = values => {
        setCampusValue(values[0]);
        const selectValue = campusesOptions.filter(campus => {
            return campus.id === parseInt(values[0]);
        });
        onCampusesFilterChange(selectValue[0]);
        setIsCampusOpen(false);
    };

    return (
        <GivelifyMenu
            checkMarkVariant="mark"
            id="select-campus"
            onChange={onChangeCampus}
            onOpenToggle={setIsCampusOpen}
            open={isCampusOpen}
            options={campusOptions}
            value={[campusValue]}
        />
    );
};

export default CampusesSelect;
