import { Donee } from '@givelify/givelify-ui';
import { getUserTrackingData } from '@givelify/utils';
import { User } from '../store/user/types';

export const handleAppcues = (userData: User, donee: Donee) => {
    window.Appcues?.identify(
        userData.id, // unique, required
        getUserTrackingData(userData, donee),
    );
};
