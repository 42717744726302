import React from 'react';
import { LINK_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { Link, TypographyProps } from '@mui/material';
import { CustomVariants, GivelifyLinkProps } from './types';

export const GivelifyLink: React.FC<GivelifyLinkProps> = ({
    text,
    variant = 'default',
    underline = 'hover',
    track = true,
    ...props
}) => {
    const linkVariant: TypographyProps['variant'] =
        (variant === 'small' && 'caption1B') ||
        (variant === 'medium' && 'body1B') ||
        (variant === 'large' && 'heading3S') ||
        (variant === 'default' && 'body2B') ||
        (variant as TypographyProps['variant']);

    const isCustomVariant = variant in CustomVariants;
    const { trackEvent } = useTrackingContext();
    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (props.name && track) {
                trackEvent(`<${props.name}>_${LINK_IDENTIFIER}`);
            }
            props.onClick?.(e);
        },
        [props, track, trackEvent],
    );

    return (
        <Link
            {...props}
            onClick={handleClick}
            style={{
                ...props.style,
                ...(isCustomVariant ? { fontWeight: 800 } : {}),
            }}
            underline={underline}
            variant={linkVariant}
        >
            {text}
        </Link>
    );
};
