import React from 'react';
import { GivelifyIcon, GivelifyLabel } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

interface SettingsTileProps {
    badgeUrl: string;
    title: string;
    description: string;
    actionUrl: string;
    onClick: (actionUrl: string) => void;
    isLink?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.disabled,
        borderRadius: 4,
        padding: 26,
        height: '100%',
    },
    title: {
        marginBottom: 10,
    },
    link: {
        color: theme.gas.palette.primary,
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        marginTop: 21,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    badge: {
        width: 20,
        height: 20,
        fontSize: 16,
        color: theme.colors.grey600,
        marginBottom: 12,
    },
}));

const SettingsTile: React.FC<SettingsTileProps> = (
    props: SettingsTileProps,
) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
                <img
                    alt="icon"
                    className={classes.badge}
                    src={props.badgeUrl}
                />
                <div className={classes.title}>
                    <a
                        className={classes.link}
                        href={props.actionUrl}
                        onClick={ev => {
                            if (!props.isLink) {
                                ev.preventDefault();
                                props.onClick(props.actionUrl);
                            }
                        }}
                    >
                        <GivelifyLabel
                            color="primary"
                            marginRight={11}
                            text={props.title}
                            variant="heading3"
                        />
                        <GivelifyIcon
                            color="primary"
                            size={14}
                            variant="chevron-right"
                        />
                    </a>
                </div>
                <GivelifyLabel text={props.description} variant="body1" />
            </div>
    );
};

export default SettingsTile;
