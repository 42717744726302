import React from 'react';
import {
    BUTTON_IDENTIFIER,
    PAGE_NAME,
    TrackingProvider,
    useApiRequest,
    useTrackingContext,
} from '@givelify/utils';
import { SUPPORT_LAUNCH_ENVELOPES_FUNDRAISING_URL } from 'api/constants';
import {
    FilterProvider,
    useGivenAmountFilter,
    useGivingStylesFilter,
    usePaginationFilter,
    useSearchFilter,
    useSortFilter,
    useTimeframeFilter,
} from 'api/hooks';
import { Donor } from 'api/models';
import { GetDonorsResponse } from 'api/services/responses/donors/GetDonorsResponse';
import { getDonorsService } from 'api/utils/serviceProvider';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { DonorsFilter, requestSerializer } from './components/donorsFilterForm';
import { DonorsPageView } from './DonorsPageView';

const sortValues = [
    'name-asc',
    'name-desc',
    'lastDonationDate-desc',
    'lastDonationDate-asc',
    'lastDonationAmount-desc',
    'lastDonationAmount-asc',
    'lifetimeDonations-desc',
    'lifetimeDonations-asc',
    'donationStatus-asc',
    'donationStatus-desc',
];

export type DonorsQueryModel = {
    sort: string;
    page: number;
    search: string | null;
    filter: DonorsFilter;
};
type DonorsPageDataSource = {
    data: Donor[];
    totalDonors: number;
    totalUnfilteredDonors: number;
    loading: boolean;
    sort: string;
    pagination: {
        pageNumber: number;
        maxPages: number;
        from: number;
        to: number;
        resultCount: number;
    };
};

const DonorsPageComponent: React.FC = () => {
    const { trackEvent } = useTrackingContext();
    const { doneeId, doneeSignupDate } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        doneeSignupDate: state.Donee.donee.signupDate,
    }));
    const [timeFrame, setTimeFrame] = useTimeframeFilter(undefined, true);
    const [givingStylesFilter, setGivingStylesFilter] = useGivingStylesFilter();
    const [givenAmountFilter, setGivenAmountFilter] = useGivenAmountFilter();
    const [paginationFilter, setPaginationFilter] = usePaginationFilter({
        page: 1,
    });
    const [sortFilter, setSortFilter] = useSortFilter({
        sort: 'name-asc',
    });
    const [searchFilter, setSearchFilter] = useSearchFilter();
    const [openFilterPanel, setOpenFilterPanel] = React.useState(false);
    const [pageDataSource, setPageDataSource] =
        React.useState<DonorsPageDataSource | null>({
            data: [],
            loading: true,
            totalDonors: 0,
            totalUnfilteredDonors: 0,
            pagination: {
                from: 0,
                maxPages: 1,
                to: 0,
                resultCount: 0,
                pageNumber: 1,
            },
            sort: 'name-asc',
        });
    const [getDonorsRequest, makeGetDonorsRequest] =
        useApiRequest<GetDonorsResponse>();
    const donorsService = getDonorsService();
    const fetchData = React.useCallback(
        (doneeId: number, query: DonorsQueryModel) => {
            const sort = sortValues.includes(query.sort)
                ? query.sort
                : 'name-asc';
            const asc = sort.split('-')[1] === 'asc';
            void makeGetDonorsRequest(
                donorsService.getDonors(doneeId, {
                    ...requestSerializer(query),
                    asc: asc,
                    desc: !asc,
                }),
            );
        },
        [donorsService, makeGetDonorsRequest],
    );
    const onFilterButtonClick = React.useCallback(() => {
        setOpenFilterPanel((prev) => !prev);
    }, []);
    const onFilterPanelClose = React.useCallback(() => {
        setOpenFilterPanel(false);
    }, []);
    const onPageChange = React.useCallback(
        (page: number) => {
            setPaginationFilter({
                page,
            });
            setPageDataSource((prevPageDataSource) => ({
                ...prevPageDataSource,
                pagination: {
                    ...prevPageDataSource.pagination,
                    pageNumber: page,
                },
            }));
        },
        [setPaginationFilter],
    );
    const onSearchSubmit = React.useCallback(
        (search: string) => {
            setSearchFilter({
                search,
            });
            setPaginationFilter({
                page: 1,
            });
            setPageDataSource((prevPageDataSource) => ({
                ...prevPageDataSource,
                pagination: {
                    ...prevPageDataSource.pagination,
                    pageNumber: 1,
                },
            }));
        },
        [setPaginationFilter, setSearchFilter],
    );
    const onSortRequest = React.useCallback(
        (field: string, direction: string) => {
            const sort = `${field}-${direction}`;
            setSortFilter({
                sort,
            });
            if (paginationFilter.page !== 1) {
                setPaginationFilter({
                    page: 1,
                });
            }
            setPageDataSource((prevPageDataSource) => ({
                ...prevPageDataSource,
                sort: sort,
                pagination: {
                    ...prevPageDataSource.pagination,
                    pageNumber: 1,
                },
            }));
        },
        [paginationFilter.page, setPaginationFilter, setSortFilter],
    );
    React.useEffect(() => {
        fetchData(doneeId, {
            filter: {
                givenAmount: givenAmountFilter,
                givingStyles: givingStylesFilter,
                timeFrame: timeFrame,
            },
            page: paginationFilter.page,
            search: searchFilter.search,
            sort: sortFilter.sort,
        });
    }, [
        doneeId,
        fetchData,
        givenAmountFilter,
        givingStylesFilter,
        paginationFilter.page,
        searchFilter.search,
        sortFilter.sort,
        timeFrame,
    ]);
    React.useEffect(() => {
        if (getDonorsRequest.type === 'REQUEST_SUCCESS') {
            setPageDataSource({
                data: getDonorsRequest.response.data,
                totalDonors: getDonorsRequest.response.meta.totalDonors,
                totalUnfilteredDonors:
                    getDonorsRequest.response.meta.totalUnfilteredDonors,
                loading: false,
                pagination: {
                    pageNumber:
                        getDonorsRequest.response.pagination.currentPage,
                    maxPages: getDonorsRequest.response.pagination.totalPages,
                    from: getDonorsRequest.response.pagination.from,
                    to: getDonorsRequest.response.pagination.to,
                    resultCount: getDonorsRequest.response.meta.totalDonors,
                },
                sort: sortFilter.sort,
            });
        } else {
            if (pageDataSource) {
                setPageDataSource({
                    ...pageDataSource,
                    loading: true,
                });
            }
        }
        //listen only to getDonorsRequest
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDonorsRequest]);
    const onFilterSubmit = React.useCallback(
        (filter: DonorsFilter) => {
            if (!isEqual(filter.givenAmount, givenAmountFilter)) {
                setGivenAmountFilter(filter.givenAmount);
            }
            if (!isEqual(filter.givingStyles, givingStylesFilter)) {
                setGivingStylesFilter(filter.givingStyles);
            }
            if (!isEqual(filter.timeFrame, timeFrame)) {
                setTimeFrame(filter.timeFrame);
            }
            if (paginationFilter.page !== 1) {
                setPaginationFilter({
                    page: 1,
                });
            }
            setOpenFilterPanel(false);

            trackEvent(`<Submit filters>_${BUTTON_IDENTIFIER}`, {
                lastGivenOnApplied: !!filter.timeFrame,
                totalGivenMin: filter.givenAmount.totalGivenMin || 'None',
                totalGivenMax: filter.givenAmount.totalGivenMax || 'None',
                lastGiftMin: filter.givenAmount.lastGivenMin || 'None',
                lastGiftMax: filter.givenAmount.lastGivenMax || 'None',
                givingStyleNew: filter.givingStyles.new,
                givingStyleOccasional: filter.givingStyles.occasional,
                givingStyleConsistent: filter.givingStyles.consistent,
                givingStyleDecreasing: filter.givingStyles.decreasing,
                givingStyleInactive: filter.givingStyles.inactive,
            });
        },
        [
            givenAmountFilter,
            givingStylesFilter,
            paginationFilter.page,
            setGivenAmountFilter,
            setGivingStylesFilter,
            setPaginationFilter,
            setTimeFrame,
            timeFrame,
            trackEvent,
        ],
    );
    const onLearnMoreClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            window.open(SUPPORT_LAUNCH_ENVELOPES_FUNDRAISING_URL, 'blank');
        },
        [],
    );
    const hasFilters = React.useMemo(
        () =>
            Boolean(searchFilter.search) ||
            Object.values(givingStylesFilter).some((val) => val) ||
            Object.values(givenAmountFilter).some(
                (val) => val !== undefined && val !== '',
            ) ||
            (timeFrame !== undefined &&
                (timeFrame.start !== null || timeFrame.end !== null)),
        [searchFilter.search, givingStylesFilter, givenAmountFilter, timeFrame],
    );
    return (
        <DonorsPageView
            data={pageDataSource.data}
            doneeSignupDate={doneeSignupDate}
            filters={{
                givenAmount: givenAmountFilter,
                givingStyles: givingStylesFilter,
                timeFrame: timeFrame,
            }}
            hasFilters={hasFilters}
            isFilterPanelOpen={openFilterPanel}
            learnMoreHref={SUPPORT_LAUNCH_ENVELOPES_FUNDRAISING_URL}
            loading={pageDataSource.loading}
            onFilterButtonClick={onFilterButtonClick}
            onFilterPanelClose={onFilterPanelClose}
            onFilterSubmit={onFilterSubmit}
            onLearnMoreClick={onLearnMoreClick}
            onSearchSubmit={onSearchSubmit}
            onSortRequest={onSortRequest}
            pagination={{
                ...pageDataSource.pagination,
                onPageChange: onPageChange,
            }}
            search={searchFilter.search}
            sort={pageDataSource.sort}
            totalCount={pageDataSource.pagination.resultCount}
            totalUnfilteredDonors={pageDataSource.totalUnfilteredDonors}
        />
    );
};

export const DonorsPage: React.FC = () => {
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.DonorsNew}>
            <FilterProvider>
                <DonorsPageComponent />
            </FilterProvider>
        </TrackingProvider>
    );
};
