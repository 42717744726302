import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { DirectDepositInfo } from '@givelify/onboarding';
import { useTranslation } from 'react-i18next';
import { AccountDetailStyles } from './accountDetailStyles';
import { AccountInfo } from './AccountInfo';

interface AccountPendingProps {
    bankingInfo: Omit<
        DirectDepositInfo,
        'bankChequeFile' | 'addressIsSame' | 'status'
    >;
}

export const AccountPending: React.FC<AccountPendingProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newAccount: t(
                'pages.settings.bank-info.customize-account-tab.new-account',
            ),
            submitted: t(
                'pages.settings.bank-info.customize-account-tab.submitted',
            ),
            submittedInfo: t(
                'pages.settings.bank-info.customize-account-tab.submitted-info',
            ),
        }),
        [t],
    );
    const { bankingInfo } = props;
    const { accountPending } = AccountDetailStyles();
    return (
        <div className={accountPending}>
            <div className="header">
                <GivelifyLabel text={copy.newAccount} variant="heading3" />
                <GivelifyIcon
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="send"
                />
                <GivelifyLabel className="submitted" text={copy.submitted} />
            </div>
            <GivelifyNotification
                show
                text={copy.submittedInfo}
                variant="info"
            />
            <AccountInfo
                disabled
                bankingInfo={bankingInfo}
                className="detailes"
            />
            <GivelifyTextDivider />
        </div>
    );
};
