import { makeStyles, Theme } from '@material-ui/core';

export const usePageStyle = makeStyles((theme: Theme) => ({
  container: {
    background: '#FFFFFF',
    padding: '32px',
    boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
    borderRadius: '10px',
  },
  introBox: {
    '& > div': {
      textAlign: 'center',
      maxWidth: '66%',
      margin: '0 auto',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
  introDesc: {
    margin: '16px 0 24px 0',
    whiteSpace: 'pre-line',
  },
  fullContent: {
    width: '100%',
  },
  mt3: {
    marginTop: '24px',
  },
  pb0: {
    paddingBottom: '0 !important',
  },
  givelifyButtonDesc: {
    margin: '8px 0 16px 0',
  },
  divider: {
    margin: '24px 0',
    height: '2px',
  },
  previewImage: {
    maxWidth: '357px',
    width: '100%',
    height: 'auto',
  },
  previewImageBox: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  longImageWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewArrowsContainer: {
    position: 'relative',
    float: 'right',
    marginTop: '20%',
    paddingLeft: theme.spacing(2),

    '& > div': {
      position: 'absolute',
      top: '40%',
    },
  },
  previewArrowText: {
    textAlign: 'center',
    width: '115px',
    marginBottom: 4,
  },
  shareButton: {
    marginTop: '16px',
    marginBottom: 12,
    padding: '8px 42px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buildIcon: {
    marginRight: 8,
    verticalAlign: 'middle',
  },
}));
