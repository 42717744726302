import { getAxiosClient, makeApiRequest, ApiResponse } from '@givelify/utils';
import { getBankAccountsApiEndpoint } from 'api/client/endpoints';
import { BankAccount } from 'api/models/BankAccount';

export const getBankAccountsApi = (
    doneeId: number,
): Promise<ApiResponse<{ data: BankAccount[] }>> => {
    const httpRequest = getAxiosClient().get(
        getBankAccountsApiEndpoint(doneeId),
    );
    const result = makeApiRequest<{ data: BankAccount[] }>(httpRequest);
    return result;
};
