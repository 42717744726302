import React from 'react';
import { DROPDOWN_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { GivelifyCapsule, GivelifyCapsuleProps } from '../capsule';
import { GivelifyMenu, GivelifyMenuProps } from '../menu';
import { mergeRefs } from '../utils';

export type GivelifyFilterProps = GivelifyCapsuleProps & {
    open?: boolean;
    onClose?: () => void;
    menuProps?: Omit<
        GivelifyMenuProps,
        'anchorEl' | 'open' | 'onClose' | 'onRenderContent'
    >;
    onRenderMenuContent: () => React.ReactNode;
};

export const GivelifyFilter = React.forwardRef<
    HTMLButtonElement,
    GivelifyFilterProps
>(
    (
        {
            onClick,
            onClose,
            open,
            onRenderMenuContent,
            menuProps,
            name,
            ...props
        },
        ref,
    ) => {
        const { trackEvent } = useTrackingContext();
        const [openInner, setOpenInner] = React.useState(false);
        const anchorRef = React.useRef<HTMLButtonElement>(null);
        const onCapsuleClick = React.useCallback(
            (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (onClick) {
                    onClick(event);
                } else {
                    setOpenInner((prev) => !prev);
                }
                if (name) {
                    trackEvent(`<${name}>_${DROPDOWN_IDENTIFIER}`);
                }
            },
            [onClick, trackEvent, name],
        );
        const onMenuClose = React.useCallback(() => {
            if (onClose) {
                onClose();
            } else if (open === undefined) {
                setOpenInner(false);
            }
        }, [onClose, open]);

        React.useEffect(() => {
            if (open !== undefined) {
                setOpenInner(open);
            }
        }, [open]);

        return (
            <>
                <GivelifyCapsule
                    name={name}
                    {...props}
                    ref={mergeRefs([ref, anchorRef])}
                    active={openInner}
                    onClick={onCapsuleClick}
                />
                <GivelifyMenu
                    {...menuProps}
                    anchorEl={anchorRef.current}
                    onClose={onMenuClose}
                    onRenderContent={onRenderMenuContent}
                    open={openInner}
                />
            </>
        );
    },
);
