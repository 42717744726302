import React, { useState, useEffect, useContext } from 'react';
import { Donee } from '@givelify/givelify-ui';
import { GivelifyLabel, GivelifyNotificationProps } from '@givelify/ui';
import {
    getAxiosClient,
    makeApiRequest,
    useInterval,
    pushEvent,
    useTrackingContext,
} from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    DirectDepositInfo,
    PrimaryRepresentativeInfo,
    TaxIdInfo,
} from '../@types/assets/onboarding';
import { CAPTIVE_PORTAL_NOTIFICATION_CLICK } from '../consts';
import { convertDoneeToOnboardingInfo } from '../utils/convertDoneeToOnboardingInfo';
import { getBankingAddress } from '../utils/getBankingAddress';
import { CaptivePortalContext } from './CaptivePortalContext';
import { BackToActivationLink } from './styles';

export const useCaptivePortalContext = () => useContext(CaptivePortalContext);

const CaptivePortalProviderInternal: React.FC<{
    donee: Donee;
    loadDonee: () => Promise<Donee>;
    setDonee: (donee: Donee) => void;
    overviewPath: string;
    settingsPath: string;
    inviteUserPath: string;
    skipOnboardingClick: () => void;
    bankVerificationEnabled: boolean;
    taxIdEnabled: boolean;
    primaryRepEnabled: boolean;
    inviteOfficialEnabled: boolean;
    headerDropdowns: React.ReactNode;
}> = ({
    donee,
    children,
    loadDonee,
    setDonee,
    overviewPath,
    settingsPath,
    inviteUserPath,
    skipOnboardingClick,
    bankVerificationEnabled,
    taxIdEnabled,
    primaryRepEnabled,
    inviteOfficialEnabled,
    headerDropdowns,
}) => {
    const { trackEvent } = useTrackingContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            notificationText: t('captivePortal.notificationText'),
            activationLink: t('captivePortal.activationLink'),
            inviteSuccess: t('captivePortal.inviteSuccess'),
        }),
        [t],
    );

    const [showLoader, setShowLoader] = useState(false);

    const onboarding = convertDoneeToOnboardingInfo(donee);

    const [taxId, setTaxId] = useState<TaxIdInfo>(onboarding.taxId);
    const [directDeposit, setDirectDeposit] = useState<DirectDepositInfo>(
        getBankingAddress(
            onboarding.directDeposit,
            onboarding.appProfile.organizationAddress,
        ),
    );

    const [primaryRepresentative, setPrimaryRepresentative] =
        useState<PrimaryRepresentativeInfo>(onboarding.primaryRepresentative);

    const statuses = React.useMemo(
        () => [
            taxId.status,
            directDeposit.status,
            primaryRepresentative.status,
        ],
        [taxId.status, directDeposit.status, primaryRepresentative.status],
    );

    const anyActionNeeded = statuses.some(
        (status) => status === 'require_information',
    );
    const inReview = statuses.every(
        (status) =>
            status === 'in_progress' ||
            status === 'request_callback' ||
            status === 'verified',
    );

    const [onboardingState, setOnboardingState] = useState<
        | 'started' // initial state
        | 'showOverview' // onboarding in review, show Overview page and poll for updates
        | 'failed' // show Captive page on next route change
        | 'actionNeeded' // show action needed label
        | 'finished' // show Overview page and stop polling
    >(
        (donee.onboarding.hasMid && 'finished') ||
            (donee.onboarding.hasCompleted && 'showOverview') ||
            (anyActionNeeded && 'actionNeeded') ||
            (inReview && 'showOverview') ||
            'started',
    );
    const [onboardingNotification, setOnboardingNotification] =
        useState<GivelifyNotificationProps | null>(null);
    const [captivePortalNotification, setCaptivePortalNotification] =
        useState<GivelifyNotificationProps | null>(null);

    useInterval(async () => {
        const shouldPoll = onboardingState === 'showOverview' && inReview;
        if (!shouldPoll) return;

        const httpRequest = getAxiosClient().get(`/donee`);

        const doneeResponse = await makeApiRequest<Donee>(httpRequest);
        if (!doneeResponse.success || !doneeResponse.response) return;

        if (doneeResponse.response.onboarding.hasMid) {
            setDonee(doneeResponse.response);
            setOnboardingState('finished');
            return;
        }

        if (
            [
                doneeResponse.response.onboarding.appProfile.status,
                doneeResponse.response.onboarding.bankInfo?.status,
                doneeResponse.response.onboarding.ein.status,
            ].some((state) => state === 'require_information')
        ) {
            setOnboardingState('failed');
        }
    }, 5000);

    useEffect(() => {
        const reloadDonee = async () => {
            setShowLoader(true);
            setOnboardingState('actionNeeded');
            const result = await loadDonee();
            if (!result) {
                return;
            }

            setShowLoader(false);
        };

        if (onboardingState === 'failed') {
            reloadDonee();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname]);

    const showCaptivePortal =
        onboardingState === 'started' || onboardingState === 'actionNeeded';

    const shouldActivateAccount = React.useMemo(() => {
        return (
            !donee.onboarding.hasMid &&
            !donee.onboarding.hasCompleted &&
            !statuses.every(
                (status) =>
                    status === 'in_progress' ||
                    status === 'request_callback' ||
                    status === 'verified',
            )
        );
    }, [donee.onboarding.hasMid, donee.onboarding.hasCompleted, statuses]);

    const navigateToUsers = (invite?: boolean) => {
        const searchParams = new URLSearchParams(location?.search);
        searchParams.append('invite', '1');

        if (!['finished', 'inReview'].includes(onboardingState)) {
            skipOnboardingClick();
            setOnboardingState('showOverview');
        }

        navigate({
            pathname: inviteUserPath,
            search: invite ? searchParams.toString() : '',
        });
    };

    React.useEffect(() => {
        if (shouldActivateAccount) {
            setOnboardingNotification({
                text: (
                    <GivelifyLabel variant="body1B">
                        {copy.notificationText}{' '}
                        <BackToActivationLink
                            onClick={() => {
                                setOnboardingState('actionNeeded');
                                trackEvent(CAPTIVE_PORTAL_NOTIFICATION_CLICK);
                            }}
                            text={copy.activationLink}
                            variant="body1B"
                        />
                    </GivelifyLabel>
                ),
                variant: 'warning',
                meta: shouldActivateAccount ? 'main' : 'content',
            });
        } else {
            setOnboardingNotification(null);
        }
    }, [
        copy.activationLink,
        copy.notificationText,
        shouldActivateAccount,
        trackEvent,
    ]);

    return (
        <CaptivePortalContext.Provider
            value={{
                onboardingState,
                showCaptivePortal,
                showLoader,
                bankVerificationEnabled,
                taxIdEnabled,
                primaryRepEnabled,
                inviteOfficialEnabled,
                onboardingNotification,
                captivePortalNotification,
                setCaptivePortalNotification,
                setDirectDeposit,
                directDeposit,
                setTaxId,
                taxId,
                setPrimaryRepresentative,
                primaryRepresentative,
                goToSettingsClick: () => {
                    setOnboardingState('showOverview');
                    navigate(settingsPath);
                    window.scrollTo(0, 0);
                },
                goToOverviewClick: () => {
                    setOnboardingState('showOverview');
                    navigate(overviewPath);
                },
                skipOnboardingClick: () => {
                    skipOnboardingClick();
                    setOnboardingState('showOverview');
                },
                navigateToUsers,
                goToCaptivePortal: (showSuccessMessage?: boolean) => {
                    setOnboardingState('started');
                    if (showSuccessMessage) {
                        setCaptivePortalNotification({
                            text: copy.inviteSuccess,
                            variant: 'success',
                        });
                    }
                },
                headerDropdowns,
            }}
        >
            {children}
        </CaptivePortalContext.Provider>
    );
};

export const CaptivePortalProvider: React.FC<{
    featureEnabled: boolean;
    donee: Donee;
    loadDonee: () => Promise<Donee>;
    setDonee: (donee: Donee) => void;
    overviewPath: string;
    settingsPath: string;
    inviteUserPath: string;
    skipOnboarding: boolean;
    campuses: Donee[];
    bankVerificationEnabled: boolean;
    taxIdEnabled: boolean;
    primaryRepEnabled: boolean;
    inviteOfficialEnabled: boolean;
    headerDropdowns: React.ReactNode;
}> = ({
    children,
    featureEnabled,
    donee,
    loadDonee,
    setDonee,
    overviewPath,
    settingsPath,
    inviteUserPath,
    campuses,
    bankVerificationEnabled,
    taxIdEnabled,
    primaryRepEnabled,
    inviteOfficialEnabled,
    headerDropdowns,
    ...props
}) => {
    const isHQ = donee.id === campuses[0]?.id && !donee.parentCampus;

    if (props.skipOnboarding || !featureEnabled || !isHQ)
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;

    return (
        <CaptivePortalProviderInternal
            bankVerificationEnabled={bankVerificationEnabled}
            donee={donee}
            headerDropdowns={headerDropdowns}
            inviteOfficialEnabled={inviteOfficialEnabled}
            inviteUserPath={inviteUserPath}
            loadDonee={loadDonee}
            overviewPath={overviewPath}
            primaryRepEnabled={primaryRepEnabled}
            setDonee={setDonee}
            settingsPath={settingsPath}
            skipOnboardingClick={() => {
                pushEvent('Click Captive Portal Skip');
                window.scrollTo(0, 0);
            }}
            taxIdEnabled={taxIdEnabled}
        >
            {children}
        </CaptivePortalProviderInternal>
    );
};
