import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectDepositInfo } from '../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../consts';
import { FullWidthModalOnboarding } from '../../modal';
import {
    DirectDepositManualEntry,
    DirectDepositManualEntryRef,
} from '../components/directDepositManualEntry/DirectDepositManualEntry';

interface DirectDepositManualEntryModalProps {
    data: DirectDepositInfo;
    isOpen: boolean;
    onSubmit: (d: DirectDepositInfo) => unknown;
    onCancel: () => unknown;
    onClose: () => unknown;
    isLoading?: boolean;
    hasError?: boolean;
    error?: string;
}

export const DirectDepositManualEntryModal: React.FC<DirectDepositManualEntryModalProps> =
    ({
        data,
        isOpen,
        onCancel,
        onSubmit,
        onClose,
        isLoading = false,
        hasError = false,
        error,
    }) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const directDepositManualEntryRef =
            useRef<DirectDepositManualEntryRef>(null);
        const [isValid, setValid] = useState<boolean>(false);

        const {
            headingText,
            safeAndSecureText,
            defaultModalRetryText,
            errorMessage,
        } = useMemo(
            () => ({
                headingText: t('directDeposit.heading'),
                safeAndSecureText: t('directDeposit.safeAndSecure'),
                defaultModalRetryText: t('labels.defaultModalRetryText'),
                errorMessage: t('errors.generic'),
            }),
            [t],
        );

        const handleSave = useCallback(() => {
            const data = directDepositManualEntryRef.current?.submit();
            if (data) {
                onSubmit({ ...data, status: 'in_progress' });
            }
        }, [directDepositManualEntryRef, onSubmit]);

        return (
            <FullWidthModalOnboarding
                useBackArrow
                errorMessage={hasError ? error || errorMessage : undefined}
                footer={{
                    onCancel,
                    onSubmit: handleSave,
                    isLoading: isLoading,
                    disableSubmit: !isValid,
                }}
                heading={headingText}
                lockText={safeAndSecureText}
                name="Direct Deposit Modal"
                onClose={onClose}
                open={isOpen}
                retryMessage={
                    data.status === 'require_information'
                        ? data.retryMessage || defaultModalRetryText
                        : undefined
                }
                steps={undefined}
            >
                <DirectDepositManualEntry
                    ref={directDepositManualEntryRef}
                    data={data}
                    onValidation={setValid}
                    showChequeSection={data.status === 'require_information'}
                />
            </FullWidthModalOnboarding>
        );
    };
