import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const appProfileStyle = makeStyles((theme: Theme) => {
    return createStyles({
        profileContainer: {
            display: 'flex',
            marginTop: 40,
        },
        profileEditorSide: {
            maxWidth: 600,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginBottom: 75,
            },
        },
        profilePreviewSide: {
            marginLeft: 'auto',
            marginRight: 32,
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        profileEditorContainer: { marginTop: 48 },
        repInputs: {
            marginTop: 32,
            marginLeft: 88,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
        },
        repNameInputsHolder: {
            display: 'flex',
            marginBottom: 24,
        },
        profileEditorFlex: {
            display: 'flex',
        },
    });
});
