export const enTranslations = {
  root: {
    title: 'Giving tools',
    description:
      'Increase participation and giving with our suite of fundraising tools that make Givelify part of your online presence.',
    onlineGiving: {
      title: 'Online giving',
      description:
        'Add the Givelify button to your website and email campaigns.',
    },
    socialMediaGiving: {
      title: 'Social media giving',
      description:
        'Share your giving link on social media to grow your donations.',
    },
    givelithon: {
      title: 'Givelithon',
      description:
        'Host fundraising events with real-time donor engagement in person or online.',
    },
    snapGive: {
      title: 'Snap-to-Give',
      description:
        'Get a customized QR code to let donors give with their smartphone cameras.',
    },
  },
  socialMediaGiving: {
    landingPage: {
      title: 'Social media giving',
      desc:
        'Social media isn’t just for cat pictures anymore. By being present where your donors are, you can increase awareness of your mission. By including your Givelify social giving link, you’ll also increase your donations. Choose a service to find out how.',
      learnMore: 'Learn more',
      help: 'Need Help? Contact Support',
      facebook: {
        title: 'Facebook',
        desc: 'Optimize your Facebook to increase your reach and donations.',
      },
      twitter: {
        title: 'Twitter',
        desc: 'Use Twitter’s quick updates to connect with potential donors.',
      },
      instagram: {
        title: 'Instagram',
        desc:
          'Tell your organization’s story with photos. Show donors where their donations go.',
      },
      youtube: {
        title: 'YouTube',
        desc:
          'The world’s biggest video and live streaming site is also the second-biggest search engine.',
      },
      linkedin: {
        title: 'Linkedin',
        desc:
          'Connect with professionals and others making a difference around the world with LinkedIn.',
      },
      snapchat: {
        title: 'SnapChat',
        desc:
          'Snapchat for Business is a great way to make your organization discoverable by new donors.',
      },
      tiktok: {
        title: 'TikTok',
        desc:
          'TikTok’s short-form videos can be an excellent way to connect with your donors.',
      },
    },
    facebook: {
      copyLink: 'Copy Link',

      page: {
        title: 'Facebook Giving',
        increaseGiving: {
          title: 'Increase giving with Facebook',
          desc:
            'Give your supporters a quick way to donate using Givelify. Sharing on social media can greatly increase your donations.',
          shareOnFacebook: 'Share on Facebook',
        },
        copyOrgLink: {
          title: 'Copy your organization’s giving link',
          desc: 'Add this link to any post on your Facebook Page.',
        },
        tip: {
          title: 'Tip',
          desc:
            'Pinning a post with your giving link to the top of your Page’s timeline allows visitors see your most important message.',
          linkTitle:
            'Learn how to pin a post to the top of your Page’s timeline.',
        },
        preview: {
          title: 'Sample Facebook post:',
          imageDesc: 'Your organization’s banner image in Givelify',
          linkDesc: 'Link to your Givelify donation page',
        },

        post: {
          text:
            'Join {DONEE_NAME} in furthering our mission. Find us in the Givelify app or give online: ',
        },
      },
    },

    twitter: {
      copyLink: 'Copy Link',

      page: {
        title: 'Twitter Giving',
        increaseGiving: {
          title: 'Increase giving with Twitter',
          desc:
            'Give your supporters a quick way to donate using Givelify. Sharing on social media can greatly increase your donations.',
          shareOnTwitter: 'Share on Twitter',
        },
        copyOrgLink: {
          title: 'Copy your organization’s giving link',
          desc: 'Add this link to any post on Twitter.',
        },
        tip: {
          title: 'Tip',
          desc:
            'Pinning a tweet with your giving link to the top of your Page’s timeline allows visitors see your most important message.',
          linkTitle:
            'Learn how to pin a tweet to the top of your Page’s timeline.',
        },
        preview: {
          title: 'Sample Tweet:',
          imageDesc: 'Your organization’s banner image in Givelify',
          linkDesc: 'Link to your Givelify donation page',
        },

        post: {
          text:
            'Join {DONEE_NAME} in furthering our mission. Find us in the Givelify app or give online: ',
        },
      },
    },

    instagram: {
      copyLink: 'Copy Link',

      page: {
        title: 'Instagram Giving',
        increaseGiving: {
          title: 'Increase giving with Instagram',
          desc:
            'Give your supporters a quick way to donate using Givelify. Sharing on social media can greatly increase your donations.',
        },
        copyOrgLink: {
          title: 'Add your giving link to your Instagram bio',
          desc1:
            'In Instagram, go to Edit Profile and paste the link found below into your about section (bio).',
          desc2:
            'Your Instagram bio is the only way to share a clickable link with your followers. Make use of it!',
        },
        tip: {
          title: 'Tip',
          desc:
            'Try posting a screenshot of your Givelify App Profile. Let your donors know they can support you on Givelify. Don’t forget to say the donation link is in your bio.',
        },
        preview: {
          title: 'Sample Instagram:',
          linkDesc: 'Add the link in your Bio',
        },
      },
    },

    youtube: {
      copyLink: 'Copy Link',

      page: {
        title: 'YouTube Giving',
        increaseGiving: {
          title: 'Increase giving with YouTube',
          desc:
            'Give your supporters a quick way to donate using Givelify. Sharing on social media can greatly increase your donations.',
        },
        copyOrgLink: {
          title: 'Add your giving link to your next live stream',
          desc1:
            'Just paste the link found below into your live stream comments, and into the description of your live stream.',
          desc2:
            'Not using live streams? Publish a post with your giving link in the Community section of your channel.',
        },
        tip: {
          title: 'Tip',
          desc:
            'Add your giving link into the description of live streams to increase micro donations. When the event is re-shared, the giving link will follow the video.',
          linkTitle: 'Learn how to live stream on YouTube.',
        },
        preview: {
          title: 'Sample YouTube:',
          linkDesc: 'Add your link to the video description',
        },
      },
    },
  },

  onlineGiving: {
    landingPage: {
      intro: {
        title: 'Your giving link',
        desc:
          'Use your unique giving link to send donors straight to your donation page from emails, social media, and more.',
        link: 'Read about the best ways to use this link.',
      },
      givelifyButton: {
        title: 'Givelify button',
        desc:
          'Streamline donation with the Givelify button. Add it to your organization’s website or emails to give users a satisfying click-to-donate experience.',
        getButton: 'Get the Givelify button',
        sendToDevelopers: 'Send to Developers',
        shareDesc:
          'If you’d prefer to have the button added by your web team, share the Givelify Button for Developers with them.',
        linkDesc: 'Link to your Givelify donation page',
      },
    },
    givelifyButton: {
      title: 'Choose the Givelify button that best suits your website',
      desc:
        'Copy and paste the code into your HTML code. If you use a website builder like Wix or Squarespace <1>click here</1> for instructions.',
      needHelp: 'Need help choosing the right button?',
      sharePage: {
        preText:
          'If you’d prefer to have this button added by your web team, share the ',
        link: 'Givelify Button for Developers',
        postText: ' with them.',
      },
      preview: 'Preview',
      lightGive: 'Light give button',
      primaryGive: 'Primary give button',
      darkGive: 'Dark give button',
      copyCode: 'Copy Code',
      forDevs: 'For developers',
      publicPageDesc: 'Copy and paste the code into your HTML code.',
      shareModal: {
        title: 'Have your developers install the Givelify button',
        copyLink: 'Copy link',
        or: 'Or',
        email: 'Email',
        receiptName: 'Recipient’s name',
        receiptEmail: 'Recipient’s email',
        back: 'Back',
        send: 'Send',
      },
      customModal: {
        title: 'How to choose the right button',
        color: 'Color',
        colorDesc:
          'Choose a color that compliments your brand, but stands out enough to grab your visitors’ attention. Make it clear how your donors can give.',
        placement: 'Placement',
        placementDesc:
          'Place the Givelify button in a prominent place that catches donors’ attention. It’s best to keep it separate from other calls to action, to avoid confusion.',
      },
      instructModal: {
        title: 'Givelify button for your website builder',
        desc:
          'Is your organization’s website built with a site builder like WIX, Squarespace, or similar? Follow the instructions below to add a giving button to your website. ',
        downloadButton:
          'Download the image of the button that works best for your website. ',
        goToBuilder:
          'Go to your website builder (WIX, squarespace, etc.) or email editor and add the downloaded image into your site or email.',
        addLink:
          'Once you have placed the image, turn the image into a button by linking it to your giving link.',
        lightGive: 'Light give button',
        primaryGive: 'Primary give button',
        darkGive: 'Dark give button',
        preview: 'Preview',
        download: 'Download',
      },
    },
  },

  givelithon: {
    title: 'This is givelithon page',
  },

  snapGive: {
    title: 'Get your custom Snap-to-Give™ QR code',
    description:
      'Get your beautifully designed and customized QR code. Enable fast, safe and secure giving from anywhere. Online or in-person.',
    description2:
      'Donors simply point their camera phone at your QR code. They will be guided to complete their donations in seconds. No text-to-give codes to memorize or type in!',
    downloadNow: 'Download Snap-To-Give QR Code Now',
    needHelp: 'Need more help?',
    helpInstruction:
      'Tips for getting the most of your custom Givelify Snap-to-Give® QR Code.',
    downloadHelp: 'Get Tips',
  },

  general: {
    notifications: {
      linkCopied: 'Link copied.',
      codeCopied: 'Code copied.',
      emailSent: 'Email sent.',
    },

    errors: {
      validation: {
        required: 'This field is required',
        minLength: 'This field can have minimum one character',
        minLength_plural: 'This field can have minimum {{count}} characters',
        maxLength: 'This field can have maximum one character',
        maxLength_plural: 'This field can have maximum {{count}} characters',
        email: 'This should be an valid email',
      },
      status: {
        404: 'Sorry, we are experiencing a technical outage. If the issue persists, please contact Givelify Support',
        500: 'Sorry, we are experiencing a technical outage. If the issue persists, please contact Givelify Support',
        401: 'Your session is expired. Please login again',
        422: 'The given data was invalid',
        429: 'Too many requests',
        409: 'Conflict records',
      },
    },
  },
};
