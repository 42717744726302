import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgSuccessBoxIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        height="24"
        viewBox="0 0 24 24"
        width="24"
    >
        <path
            clipRule="evenodd"
            d="M12 22.1538C6.39218 22.1538 1.84615 17.6078 1.84616 12C1.84616 6.39218 6.39219 1.84615 12 1.84615C17.6078 1.84615 22.1538 6.39219 22.1538 12C22.1538 17.6078 17.6078 22.1538 12 22.1538ZM0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 8.69081e-07 12 0C5.37259 -8.6908e-07 2.44269e-06 5.37258 0 12ZM10.3487 15.4658C10.5146 15.6172 10.7259 15.6923 10.9372 15.6923C11.1609 15.6923 11.384 15.6085 11.5526 15.441L16.3696 10.6549C16.7021 10.3245 16.6966 9.79372 16.3572 9.47005C16.0179 9.14637 15.4727 9.15173 15.1402 9.48211L10.9118 13.6832L8.83367 11.7867C8.48675 11.4704 7.94227 11.4872 7.61737 11.8249C7.29248 12.1627 7.30969 12.6928 7.65661 13.0091L10.3487 15.4658Z"
            fill="#34A652"
            fillRule="evenodd"
        />
    </svg>
);

export const SuccessBoxIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgSuccessBoxIcon}
        fontSize={fontSize}
    />
);
