import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => ({
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 6px rgba(118, 142, 168, 0.4)',
    borderRadius: '10px',
    padding: '40px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  },

  fullContent: {
    width: '100%',
  },

  tipContainer: {
    marginTop: '40px',
  },

  shareBody: {
    marginTop: '8px',
    marginBottom: '16px',
  },

  givingLinkTitle: {
    marginTop: '24px',
  },

  shareButton: {
    borderRadius: '60px',
    padding: '10px 32px',
  },

  divider: {
    margin: '20px 0',
  },

  previewContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },

  previewImage: {
    maxWidth: '235px',
    width: '100%',
    height: 'auto',
  },

  previewArrowsContainer: {
    position: 'relative',
    paddingLeft: theme.spacing(1),

    '& > div, img': {
      position: 'absolute',
    },
  },

  previewTitle: {
    margin: '24px 0 6px 0',
    color: '#676E77',
  },

  previewArrowText: {
    textAlign: 'center',
    minWidth: '115px',
    color: '#676E77',
  },

  linkArrowText: {
    top: 'calc(24px + 16px + 82px)',
  },

  linkArrow: {
    top: 'calc(24px + 16px + 116px)',
  },
}));
