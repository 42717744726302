import React from 'react';
import {
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
    GivelifyTextDivider,
} from '@givelify/givelify-ui';
import { DirectDepositInfo } from '@givelify/onboarding';
import { useTranslation } from 'react-i18next';
import { AccountDetailStyles } from './accountDetailStyles';
import { AccountInfo } from './AccountInfo';

interface AccountWarningProps {
    bankingInfo: Omit<
        DirectDepositInfo,
        'bankChequeFile' | 'addressIsSame' | 'status'
    >;
}

export const AccountWarning: React.FC<AccountWarningProps> = props => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            newAccount: t(
                'pages.settings.bank-info.customize-account-tab.new-account',
            ),
            status: t('pages.settings.bank-info.customize-account-tab.warning'),
            info: t(
                'pages.settings.bank-info.customize-account-tab.warning-info',
            ),
        }),
        [t],
    );
    const { bankingInfo } = props;
    const { accountWarning } = AccountDetailStyles();
    return (
        <div className={accountWarning}>
            <div className="header">
                <GivelifyLabel text={copy.newAccount} variant="heading3" />
                <GivelifyIcon
                    color="#DC9F00"
                    marginLeft={16}
                    marginRight={6}
                    size="16px"
                    variant="info-circle"
                />
                <GivelifyLabel className="submitted" text={copy.status} />
            </div>
            <GivelifyNotification show text={copy.info} variant="warning" />
            <AccountInfo
                disabled
                bankingInfo={bankingInfo}
                className="detailes"
            />
            <GivelifyTextDivider />
        </div>
    );
};
