export interface SingleFileProps {
    errorText: string;
    maxSizeInMB: number;
    file: File | null | undefined;
    allowedExtensions: string[];
}

export const initialFileType: FileType = {
    status: 'idle',
    file: undefined,
};

export const getFileName = (file: File): string => file.name;

export type FileType =
    | {
          status: 'success';
          file: File;
      }
    | {
          status: 'idle';
          file: undefined;
      }
    | {
          status: 'error';
          // string when there is an error
          file: string;
      };

export const allowedExtension = (
    allowedExtensions: string[],
    file: File,
): boolean => {
    const fileNameWithExtension = file.name?.split('.');
    if (!fileNameWithExtension || fileNameWithExtension.length < 2)
        return false;
    const extension =
        fileNameWithExtension[fileNameWithExtension.length - 1]?.toLowerCase();
    return allowedExtensions.includes(extension);
};

export const getSingleFile = ({
    errorText,
    maxSizeInMB,
    file,
}: SingleFileProps): FileType => {
    if (!file) return initialFileType;
    if (file.size === 0) return initialFileType;

    if (file.size > maxSizeInMB * 1024 * 1024) {
        return { file: errorText, status: 'error' };
    } else {
        return getFileType(file);
    }
};

export const getFileType = (file: File | string | undefined): FileType => {
    if (file === undefined) {
        return { file: undefined, status: 'idle' };
    } else if (typeof file === 'string') {
        return { file, status: 'error' };
    } else {
        return { file, status: 'success' };
    }
};

const fileSizeArray = ['B', 'KB', 'MB', 'GB', 'TB'];
export const getFileSize = (size: number): string => {
    let i = 0;
    while (size > 999) {
        size /= 1024;
        i++;
    }
    return Math.round(size * 100) / 100 + ' ' + fileSizeArray[i];
};
