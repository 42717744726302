import React from 'react';
import { convertToDate, formatWithTimezone } from '@givelify/utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { DonationRow } from 'api/models';
import { TFunction } from 'i18next';
import { useAdvancedTranslation } from 'utils/i18n';
import { formatMoneyWithCommas } from '../../../../../utils/formatMoneyWithCommas';
import { EnvelopeDetailProps } from '../../../components/DonationDetails';
import { donationsRowTableStyles } from '../../../components/DonationsRowTable';
import { DonationItemRowProps } from '../../../DonationActivity/DonationActivityInfiniteLoader/DonationActivityRow';
import { mapDonationRowToDonationItemRowProps } from '../../../utils/DonationsRowUtils';

const isRefunded = (isRefunded: boolean | undefined, value: string) =>
    isRefunded ? `($${value})` : `$${value}`;

const getEnvelopeName = (envelopes?: EnvelopeDetailProps[]): string => {
    if (envelopes.length === 1) {
        return envelopes[0].name;
    }
    if (!envelopes || envelopes.length === 0) {
        return 'Unknown';
    }
    return 'Multiple';
};

const TableHeader = (t: TFunction, at: TFunction) => {
    const { firstColumn, lastColumn } = donationsRowTableStyles();

    return (
        <TableHead className="table-head">
            <TableRow>
                <TableCell className={firstColumn}>
                    {t('bankDeposits.table.date')}
                </TableCell>
                <TableCell>{t('bankDeposits.table.time')}</TableCell>
                <TableCell>{t('bankDeposits.table.donor')}</TableCell>
                <TableCell align="right">
                    {t('bankDeposits.table.total')}
                </TableCell>
                <TableCell align="right">
                    {t('bankDeposits.table.fees')}
                </TableCell>
                <TableCell align="right">
                    {t('bankDeposits.table.net')}
                </TableCell>
                <TableCell className={lastColumn}>
                    {at('bankDeposits.table.envelope')}
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

interface BankDepositTableProps {
    donationRows: DonationRow[];
    total?: number;
    currentPage?: number;
    handleChangePage: (e: unknown, pageNumber: number) => void;
}

const rowsPerPage = 10;
const BankDepositTable: React.FC<BankDepositTableProps> = ({
    donationRows,
    total,
    currentPage,
    handleChangePage,
}) => {
    const { t, at } = useAdvancedTranslation();
    const {
        textEllipsis,
        firstColumn,
        lastColumn,
        row: rowClassName,
    } = donationsRowTableStyles();
    // NOTE: this table styles are defined in useModalTableStyles
    return (
        <>
            <TableContainer className="table-container">
                <Table aria-label="Donation Details" stickyHeader={true}>
                    {TableHeader(t, at)}
                    <TableBody>
                        {donationRows.map((row, i) => {
                            const data: DonationItemRowProps =
                                mapDonationRowToDonationItemRowProps(row);
                            const convertedDate = convertToDate(row.createdAt);
                            const formattedDate =
                                formatWithTimezone(convertedDate);
                            const formattedTime = formatWithTimezone(
                                convertedDate,
                                'hh:mm A z',
                            );
                            return (
                                <TableRow key={i} className={rowClassName}>
                                    <TableCell className={firstColumn}>
                                        {formattedDate}
                                    </TableCell>
                                    <TableCell>{formattedTime}</TableCell>
                                    <TableCell className={textEllipsis}>
                                        {row.donor?.name || t('Unknown')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {isRefunded(
                                            data.isRefund,
                                            formatMoneyWithCommas(
                                                data.donation,
                                            ),
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${formatMoneyWithCommas(data.fees)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {isRefunded(
                                            data.isRefund,
                                            formatMoneyWithCommas(
                                                data.donation - data.fees,
                                            ),
                                        )}
                                    </TableCell>
                                    <TableCell className={lastColumn}>
                                        {getEnvelopeName(data.envelopes)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <table className="pagination">
                <tbody>
                    <tr>
                        <TablePagination
                            count={total}
                            labelDisplayedRows={({ page }) =>
                                `Page ${page + 1} of ${Math.ceil(
                                    total / rowsPerPage,
                                )}`
                            }
                            onPageChange={handleChangePage}
                            page={currentPage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                        />
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default BankDepositTable;
