import React, { useMemo } from 'react';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import { IntegrationTypes } from '../../../../../types';
import {
    CCBEnvelope,
    CCBEnvelopeUnmatchData,
    IntegrationPaginatedResponse,
} from '../../types';
import AutoIntegrationInfiniteLoader from '../AutoIntegrationInfiniteLoader';
import { TableLoadingComponent } from '../AutoIntegrationLoading';
import AutoIntegrationNoResultComponent from '../AutoIntegrationNoResultComponent';
import AutoIntegrationEnvelopeUnmatchRow from './AutoIntegrationEnvelopeUnmatchRow';

interface UnmatchEnvelopeInfiniteLoaderProps {
    reset: true | undefined;
    onReset: () => unknown;
    reload: boolean;
    onReloaded: () => unknown;
    url: (pageNumber: number) => string;
    ccbEnvelopeList: CCBEnvelope[];
    onChangeRequest: () => void;
    integration: IntegrationTypes;
}

const UnmatchEnvelopeInfiniteLoader: React.FC<UnmatchEnvelopeInfiniteLoaderProps> = ({
    reset,
    onReset,
    reload,
    onReloaded,
    url,
    ccbEnvelopeList,
    onChangeRequest,
    integration,
}) => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            errorMatchEnvelope: t(
                'pages.integrations.matchError.errorEnvelope',
            ),
            noMatchEnvelope: t('pages.integrations.matchError.noEnvelope'),
        }),
        [t],
    );

    const Error = (
        <GivelifyLabel
            bold
            margin={16}
            text={copy.errorMatchEnvelope}
            variant="body2"
        />
    );
    const Zeroth = (
        <AutoIntegrationNoResultComponent text={copy.noMatchEnvelope} />
    );
    return (
        <AutoIntegrationInfiniteLoader
            ErrorComponent={Error}
            LoadingComponent={TableLoadingComponent}
            ZerothComponent={Zeroth}
            onReloaded={onReloaded}
            onReset={onReset}
            reload={reload}
            renderData={(
                data: IntegrationPaginatedResponse<CCBEnvelopeUnmatchData>,
            ) =>
                data.data.map((envelope, i) => (
                    <AutoIntegrationEnvelopeUnmatchRow
                        key={i}
                        campusName={envelope.campus?.name}
                        id={envelope.id}
                        integration={integration}
                        listCcbEnvelope={ccbEnvelopeList}
                        name={envelope.name}
                        requestSuccess={onChangeRequest}
                    />
                ))
            }
            reset={reset}
            url={url}
        />
    );
};

export default React.memo(UnmatchEnvelopeInfiniteLoader);
