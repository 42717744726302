import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [innerSize, setInnerSize] = useState({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setInnerSize({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return innerSize
}