import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyLoadingStyles } from './styles';

interface GivelifyLoadingBaseProps extends GivelifyBoxMarginProps {
    className?: string;
}

interface GivelifyLoadingCommonProps extends GivelifyLoadingBaseProps {
    value?: number;
    type: 'linear' | 'circular';
}

interface GivelifyLoadingTopBarProps extends GivelifyLoadingBaseProps {
    type: 'topLoadingBar';
}

export type GivelifyLoadingProps =
    | GivelifyLoadingCommonProps
    | GivelifyLoadingTopBarProps;

function getBoxMargin(marginProps?: GivelifyBoxMarginProps) {
    if (marginProps) {
        if (
            marginProps.margin ||
            marginProps.marginLeft ||
            marginProps.marginRight ||
            marginProps.marginBottom ||
            marginProps.marginTop
        ) {
            const { boxMargin } = GivelifyLoadingStyles(marginProps);
            return boxMargin;
        }
    }
    return '';
}

export const GivelifyLoading: React.FC<GivelifyLoadingProps> = props => {
    const boxMargin = getBoxMargin({
        margin: props.margin,
        marginLeft: props.marginLeft,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
    });
    const className = mergeClassNames(boxMargin, props.className);

    if (props.type === 'circular')
        return <CircularProgress className={className} />;

    if (props.type === 'topLoadingBar') {
        const { blurOverly, progressBar } = GivelifyLoadingStyles({});
        const overlyClassName = mergeClassNames(className, blurOverly);
        const progressBarClassName = mergeClassNames(className, progressBar);
        return (
            <>
                <LinearProgress
                    className={overlyClassName}
                    variant="indeterminate"
                />
                <LinearProgress
                    className={progressBarClassName}
                    variant="indeterminate"
                />
            </>
        );
    }

    return (
        <LinearProgress
            className={className}
            value={props.value}
            variant={
                props.value === undefined ? 'indeterminate' : 'determinate'
            }
        />
    );
};
