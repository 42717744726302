import { getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ThunkAction } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { loadNotificationsSuccess } from './actions';
import { NotificationsState, NotificationType } from './types';

export const loadNotifications =
    (): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch) => {
        const httpRequest = getAxiosClient().get(`/notification-state`);
        const result = await makeApiRequest<{
            data: { [NotificationType: string]: boolean }[];
        }>(httpRequest);

        if (!result.success) return false;

        const entries = result.response.data.flatMap(Object.entries);
        const notifications = Object.fromEntries(entries) as NotificationsState;

        dispatch(loadNotificationsSuccess(notifications));

        return true;
    };

export const updateNotification =
    (
        type: NotificationType,
        visible: boolean,
    ): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async () => {
        const httpRequest = getAxiosClient().post(`/notification-state`, {
            type,
            status: visible,
        });
        const result = await makeApiRequest<Record<string, unknown>>(
            httpRequest,
        );

        return result.success;
    };
