import React from 'react';
import { RequestState, responseOrUndefined } from '@givelify/utils';
import { Skeleton } from '@mui/material';
import { TooltipProps } from 'recharts';
import { BasicErrorBoundary } from '../../../../components';
import { useAdvancedTranslation } from '../../../../utils/i18n';
import { NameType, ValueType } from '../../types';
import ZeroState from '../../ZeroState';
import { WidgetErrorBox } from '../components';
import { PieChart } from './pieChart';

const src = '/overview/donor-giving-to.svg';
const srcGrey = '/overview/donor-giving-to-grey.svg';

export type PieChartsViewProps<
    K extends {
        id?: number | null;
        aggregatedIds?: number[] | null;
    },
    T extends {
        data: K[];
    },
> = {
    data: RequestState<T>;
    legendTitle: string;
    nameKey: keyof K & string;
    dataKey: string;
    isChartDrillDownEnabled?: boolean;
    fillColors?: Record<string, string>;
    onSectorClick?: (entry: K, isSector?: boolean) => void;
    onboardingEnabled?: boolean;
    onboardingCompleted?: boolean;
    helperText1?: string;
    helperText2?: string;
    tooltip?: React.FC<TooltipProps<ValueType, NameType>>;
};

const PieChartsViewComponent = <
    K extends {
        id?: number | null;
        aggregatedIds?: number[] | null;
    },
    T extends {
        data: K[];
    },
>({
    data,
    legendTitle,
    nameKey,
    dataKey,
    isChartDrillDownEnabled,
    fillColors,
    onSectorClick,
    onboardingEnabled,
    onboardingCompleted,
    helperText1,
    helperText2,
    tooltip,
}: PieChartsViewProps<K, T>): JSX.Element => {
    const { t } = useAdvancedTranslation();

    const response = responseOrUndefined<T>(data);

    if (response && response?.data?.length === 0) {
        return (
            <ZeroState
                src={onboardingEnabled && onboardingCompleted ? src : srcGrey}
                type={t('donationData')}
            />
        );
    }

    return (
        <BasicErrorBoundary
            fallback={<WidgetErrorBox height="100%" width="100%" />}
        >
            {data.type !== 'REQUEST_SUCCESS' ? (
                <Skeleton
                    data-testid="pie-chart-skeleton"
                    height={'100%'}
                    sx={{
                        maxHeight: '300px',
                        aspectRatio: '1',
                    }}
                    variant="rectangular"
                    width={'100%'}
                />
            ) : (
                <PieChart
                    data={data}
                    dataKey={dataKey}
                    fillColors={fillColors}
                    helperText1={helperText1}
                    helperText2={helperText2}
                    isChartDrillDownEnabled={isChartDrillDownEnabled}
                    legendTitle={legendTitle}
                    nameKey={nameKey}
                    onSectorClick={onSectorClick}
                    tooltip={tooltip}
                />
            )}
        </BasicErrorBoundary>
    );
};

export const PieChartsView = React.memo(
    PieChartsViewComponent,
) as typeof PieChartsViewComponent;
