import React from 'react';

export enum Keys {
    ENTER = 'Enter',
}

type Handler = (value: string | number) => void;

export const onKeypressHandlerFactory = (
    handler: Handler,
): ((e: React.KeyboardEvent) => void) => {
    return (e: React.KeyboardEvent) => {
        if (e.key === Keys.ENTER) {
            handler((e.target as HTMLInputElement).value);
        }
    };
};
