import { DesignTokens } from '@givelify/ui';
import { BubbleVariant } from './types';

export const VariantColor: Record<BubbleVariant, string> = {
    [BubbleVariant.New]: DesignTokens.color.chartBlue400,
    [BubbleVariant.Occasional]: DesignTokens.color.globalNeutral300,
    [BubbleVariant.Consistent]: DesignTokens.color.globalGuidingViolet400,
    [BubbleVariant.Decreasing]: DesignTokens.color.globalWarning400,
    [BubbleVariant.Inactive]: DesignTokens.color.globalNeutral600,
};
