import { convertToDate } from '@givelify/utils';
import { Donation, DonationResponseData } from 'pages/donors/DonorProfile';
import { DonorResponseData } from 'pages/integrations/IntegrationContent/TabsContent/DonorMatching/types';
import { mapDonationNoteResponseDataToDonationNote } from './donationTypes';

export interface DonorType {
    id: number;
    statusType: string;
    internalName?: string;
    description?: string;
}

export interface Donor {
    id: number;
    givelifyNumber: string;
    externalId: string;
    name: string;
    donorType: DonorType;
    phone: string;
    email: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    picture: string;
    lastDonationDate: Date | string;
    lifetimeDonations: number;
    lastDonationAmount: number;
}

export interface DonorResponse {
    data: DonorResponseData;
}

export const mapDonationsResponseDataToDonation = (
    donation: DonationResponseData,
) => {
    const mappedDonation: Donation = {
        id: donation.id,
        receiptNumber: donation.receiptNumber,
        amount: donation.amount,
        fees: donation.fees,
        createdAt: convertToDate(donation.createdAt),
        envelopes: donation.envelopes,
        note: mapDonationNoteResponseDataToDonationNote(donation.donationNote),
        total: donation.donationTotal,
        doneeName: donation.doneeName,
        isRefund: donation.isRefund,
        refundDate: donation.refundDate,
        status: donation.status,
        latestStatusAt: donation.latestStatusAt,
        donationTotal: donation.donationTotal,
        donationFees: donation.donationFees,
    };
    return mappedDonation;
};
