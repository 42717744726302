import React from 'react';
import { PAGE_NAME, TimeFrameValues } from '@givelify/utils';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import {
    DataTableSortDirection,
    messageFilterOptions,
} from '../../../components';
import DonationActivityInfiniteLoader from './components/DonationActivityInfiniteLoader';
import {
    DonationsFilterRow,
    DonationsFilterRowProps,
} from './components/donationsFillterRow';
import { DonationsFilter } from './components/donationsFilterForm';
import { DonationActivityPaper } from './styles';

type DonationActivityViewProps = Omit<
    DonationsFilterRowProps,
    'filterPanelSubmitText'
> & {
    onFilterSubmit: (filter: DonationsFilter, isSubmitButton?: boolean) => void;
    doneeId: number;
    sort: string;
    hasMid: boolean;
    orgName: string;
    allDocumentsInReview: boolean;
    city: string;
    onSortRequest: (field: string, direction: DataTableSortDirection) => void;
    filters: DonationsFilter;
    doneeSignupDate?: Date;
    totalDonationAmountRef: React.MutableRefObject<HTMLDivElement>;
};

const DonationActivityPageView: React.FC<DonationActivityViewProps> = ({
    search,
    onSearchSubmit,
    sort,
    hasMid,
    orgName,
    allDocumentsInReview,
    city,
    onSortRequest,
    onFilterSubmit,
    onFilterButtonClick,
    doneeId,
    isFilterPanelOpen,
    doneeSignupDate,
    filters,
    totalDonationAmountRef,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            viewDonations: t('filters.viewDonations'),
        }),
        [t],
    );
    const [loading, setLoading] = React.useState(true);
    const [showNoData, setShowNoData] = React.useState(false);

    const hasFilters =
        filters.envelopes.values.length > 0 ||
        filters.messages.length > 0 ||
        !!filters.timeFrame;

    return (
        <>
            <PageTitle
                description={PAGE_NAME.DonationActivity}
                title={PAGE_NAME.DonationActivity}
            />
            <DonationsFilterRow
                disabled={loading || showNoData}
                doneeSignupDate={doneeSignupDate}
                filterPanelSubmitText={copy.viewDonations}
                filters={filters}
                isFilterPanelOpen={isFilterPanelOpen}
                onFilterButtonClick={onFilterButtonClick}
                onFilterSubmit={onFilterSubmit}
                onSearchSubmit={onSearchSubmit}
                search={search}
            />
            <DonationActivityPaper hasFiltersAndSearch={!showNoData}>
                <DonationActivityInfiniteLoader
                    allDocumentsInReview={allDocumentsInReview}
                    city={city}
                    doneeId={doneeId}
                    envelopeIds={
                        filters.envelopes.values.length === 0
                            ? undefined
                            : filters.envelopes.values
                    }
                    envelopesLoading={filters.envelopes.loading}
                    hasFilters={hasFilters}
                    hasMid={hasMid}
                    messageFilter={
                        filters.messages.length ===
                            messageFilterOptions.length ||
                        filters.messages.length === 0
                            ? undefined
                            : filters.messages
                    }
                    onLoadingChange={setLoading}
                    onSortRequest={onSortRequest}
                    orgName={orgName}
                    search={search}
                    setShowNoData={setShowNoData}
                    showNoData={showNoData}
                    sort={sort}
                    timeFrame={
                        filters.timeFrame
                            ? filters.timeFrame
                            : TimeFrameValues.lifetime
                    }
                    totalDonationAmountRef={totalDonationAmountRef}
                />
            </DonationActivityPaper>
        </>
    );
};

export const DonationActivityView = React.memo(DonationActivityPageView);
