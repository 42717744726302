import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';

import onboardingEn from './en/onboarding.json';
import onboardingEs from './es/onboarding.json';

i18n.use(detector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',

        interpolation: {
            escapeValue: false, // React does this automatically
        },

        resources: {
            en: {
                [I18N_NAMESPACE]: onboardingEn,
            },
            es: {
                [I18N_NAMESPACE]: onboardingEs,
            },
        },

        react: {
            useSuspense: false,
        },

        defaultNS: I18N_NAMESPACE,
    });

export default i18n;
