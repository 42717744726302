import React, { FC, useEffect, useMemo } from 'react';
import { useApiRequest } from '@givelify/utils';
import { GetLifeTimeDonationsResponse } from 'api/services/responses/widgets';
import { getDonationService } from 'api/utils/serviceProvider';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'store';

import { shouldRequestLifeTimeDonation } from 'store/dashboard/newLifeTime/utils';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';

import { setLifeTime } from '../../../../store/dashboard/newLifeTime/actions';
import { TotalDonationsWidgetView } from './TotalDonationsWidgetView';

type TotalDonationsWidgetWrapperProps = {
    className?: string;
    fullWidth?: boolean;
};

export const TotalDonationsWidgetWrapper: FC<
    TotalDonationsWidgetWrapperProps
> = ({ className, fullWidth }) => {
    const { t } = useTranslation();
    const { title } = useMemo(
        () => ({
            title: t('overview.widgets.totalDonations'),
        }),
        [t],
    );
    const dispatch = useDispatch();
    const { doneeId, lifetimeDonations } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
        lifetimeDonations: state.Dashboard.NewLifeTime,
    }));

    const service = getDonationService();
    const [getRequest, makeGetRequest] =
        useApiRequest<GetLifeTimeDonationsResponse>();

    useEffect(() => {
        if (
            !lifetimeDonations.response ||
            shouldRequestLifeTimeDonation(doneeId)
        ) {
            void makeGetRequest(service.getLifeTimeDonations(doneeId));
        }
        //listen only to doneeId
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doneeId]);

    useEffect(() => {
        if (getRequest.type !== 'REQUEST_INIT') {
            dispatch(setLifeTime({ doneeId, response: getRequest }));
        }
        //listen only to getRequest
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRequest]);

    return (
        <TotalDonationsWidgetView
            className={className}
            fullWidth={fullWidth}
            isLoading={lifetimeDonations.type !== 'REQUEST_SUCCESS'}
            title={title}
            value={formatMoneyWithCommasAndDolarSign(
                lifetimeDonations.response?.data.sum || 0,
            )}
        />
    );
};
