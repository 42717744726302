import { DesignTokens, GivelifyPaper } from "@givelify/ui";
import { styled } from "@mui/material";

export const Wrapper = styled(GivelifyPaper)<{ selected: boolean }>(
    ({ selected }) => ({
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        ':hover': {
            boxShadow: DesignTokens.shadow.shadowLow,
            backgroundColor: DesignTokens.color.globalGuidingViolet100,
            cursor: 'pointer',
            '& > *': {
                color: DesignTokens.color.globalGuidingViolet400,
            },
        },
        ...(selected
            ? {
                  boxShadow: DesignTokens.shadow.shadowLow,
                  backgroundColor: DesignTokens.color.globalGuidingViolet100,
                  cursor: 'pointer',
                  '& > *': {
                      color: DesignTokens.color.globalGuidingViolet400,
                  },
              }
            : {}),

        '& :nth-last-child(2)': {
            flex: 1,
        },
    }),
);