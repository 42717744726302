import React, { useMemo } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { webConfig } from '../../../../../utils/webConfig';
import { StyledModal } from './styles';
import { ApplyForTaxIdModalProps } from './types';

export const NonProfitApplyForTaxIdModal: React.FC<ApplyForTaxIdModalProps> = ({
    onClose,
    open,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const translationKey = 'taxId.helpModal.nonprofit';
    const copy = useMemo(
        () => ({
            heading: t(`${translationKey}.heading`),
            description: t(`${translationKey}.description`),
            subHeading: t(`${translationKey}.subHeading`),
            applyFor501c3: t(`${translationKey}.applyFor501c3`),
            ifYou: t(`${translationKey}.ifYou`),
            moreInfo: t(`${translationKey}.moreInfo`),
            taxIdArticle: t(`${translationKey}.taxIdArticle`),
            documentList1: t(`${translationKey}.documentList1`),
            documentList2: t(`${translationKey}.documentList2`),
            documentList3: t(`${translationKey}.documentList3`),
        }),
        [t],
    );
    return (
        <StyledModal
            showCloseButton
            CloseButton={<GivelifyIcon variant="close-outlined" />}
            onClose={onClose}
            open={open}
        >
            <GivelifyLabel
                className="header"
                text={copy.heading}
                variant="heading1S"
            />
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                text={copy.description}
                variant="body2"
            />
            <GivelifyLabel
                className="header sub-header"
                text={copy.subHeading}
                variant="heading1S"
            />
            <ul>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList1}
                        variant="body2"
                    />
                </li>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList2}
                        variant="body2"
                    />
                </li>
                <li>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.documentList3}
                        variant="body2"
                    />
                </li>
            </ul>
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                <GivelifyLink
                    fontWeight={600}
                    href={webConfig.applyEinLink}
                    target="_blank"
                    text={copy.applyFor501c3}
                />
                {copy.ifYou}
            </GivelifyLabel>

            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                {copy.moreInfo}{' '}
                <GivelifyLink
                    fontWeight={600}
                    href={webConfig.taxIdHelpArticleURL}
                    target="_blank"
                    text={copy.taxIdArticle}
                />
            </GivelifyLabel>

            <GivelifyButton
                fullWidth
                className="confirm"
                onClick={onClose}
                text="Got it, Thanks"
                variant="primary"
            />
        </StyledModal>
    );
};
