export interface YodleeBankOnClick {
    onClick: (id: number) => unknown;
}

export interface YodleeBank {
    id: number;
    name: string;
    logo: string;
    status: 1 | 'Beta';
}

export const usaBankList: YodleeBank[] = [
    {
        id: 2852,
        name: 'Bank of America',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2852_1_2.SVG',
        status: 1,
    },
    {
        id: 5,
        name: 'Wells Fargo',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5_1_2.SVG',
        status: 1,
    },
    {
        id: 643,
        name: 'Chase',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_643_1_2.SVG',
        status: 1,
    },
    {
        id: 783,
        name: 'Regions Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_783_1_2.SVG',
        status: 1,
    },
    {
        id: 3010,
        name: 'BB&T',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3010_1_2.SVG',
        status: 1,
    },
    {
        id: 2383,
        name: 'SunTrust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2383_1_2.SVG',
        status: 1,
    },
    {
        id: 524,
        name: 'U.S. Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_524_1_2.SVG',
        status: 1,
    },
    {
        id: 2162,
        name: 'PNC Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2162_1_2.SVG',
        status: 1,
    },
    {
        id: 782,
        name: 'Huntington Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_782_1_2.SVG',
        status: 1,
    },
    {
        id: 745,
        name: 'Fifth Third Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_745_1_2.SVG',
        status: 1,
    },
    {
        id: 4132,
        name: 'TD Bank',
        logo: 'https://www.td.com/us/en/personal-banking/images/TDB_tag_white_tcm371-253361.png',
        status: 1,
    },
    {
        id: 19811,
        name: 'Synovus',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19811_1_1.PNG',
        status: 1,
    },
    {
        id: 3142,
        name: 'M&T Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3142_1_2.SVG',
        status: 1,
    },
    {
        id: 3037,
        name: 'BMO Harris Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3037_1_2.SVG',
        status: 1,
    },
    {
        id: 3112,
        name: 'Comerica Web Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3112_1_2.SVG',
        status: 1,
    },
    {
        id: 1603,
        name: 'Citibank (online.citibank.com)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1603_1_2.SVG',
        status: 1,
    },
    {
        id: 16441,
        name: 'Dag Site',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16441_1_2.SVG',
        status: 1,
    },
    {
        id: 21,
        name: 'Charles Schwab',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21_1_2.SVG',
        status: 1,
    },
    {
        id: 84,
        name: 'Rayne State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_84_1_1.PNG',
        status: 1,
    },
    {
        id: 424,
        name: 'Great Western Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_424_1_1.PNG',
        status: 1,
    },
    {
        id: 431,
        name: 'First Internet Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_431_1_1.PNG',
        status: 1,
    },
    {
        id: 438,
        name: 'San Diego County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_438_1_2.SVG',
        status: 1,
    },
    {
        id: 439,
        name: 'USE Credit Union (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_439_1_1.PNG',
        status: 1,
    },
    {
        id: 458,
        name: 'First Republic Bank (Banking Online)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_458_1_2.SVG',
        status: 1,
    },
    {
        id: 459,
        name: 'Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_459_1_2.SVG',
        status: 1,
    },
    {
        id: 464,
        name: 'Stanford Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_464_1_2.SVG',
        status: 1,
    },
    {
        id: 473,
        name: 'First Tech Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_473_1_2.SVG',
        status: 1,
    },
    {
        id: 528,
        name: 'California Bank & Trust Personal Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_528_1_1.PNG',
        status: 1,
    },
    {
        id: 546,
        name: '1st Advantage FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_546_1_1.PNG',
        status: 1,
    },
    {
        id: 646,
        name: 'First Horizon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_646_1_2.SVG',
        status: 1,
    },
    {
        id: 744,
        name: 'E*TRADE or E Trade',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_744_1_2.SVG',
        status: 1,
    },
    {
        id: 747,
        name: 'Hudson Valley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_747_1_1.PNG',
        status: 1,
    },
    {
        id: 1104,
        name: 'Unify Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1104_1_1.PNG',
        status: 1,
    },
    {
        id: 1130,
        name: 'Treasury Department Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1130_1_1.PNG',
        status: 1,
    },
    {
        id: 1131,
        name: '1st United Services Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1131_1_1.PNG',
        status: 1,
    },
    {
        id: 1132,
        name: "America's First Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1132_1_1.PNG',
        status: 1,
    },
    {
        id: 1134,
        name: 'SAFE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1134_1_1.PNG',
        status: 1,
    },
    {
        id: 1135,
        name: 'Arsenal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1135_1_1.PNG',
        status: 1,
    },
    {
        id: 1136,
        name: 'ASI Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1136_1_1.PNG',
        status: 1,
    },
    {
        id: 1138,
        name: 'Education First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1138_1_1.PNG',
        status: 1,
    },
    {
        id: 1140,
        name: 'Liberty First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1140_1_1.PNG',
        status: 1,
    },
    {
        id: 1141,
        name: 'Bethpage FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1141_1_1.PNG',
        status: 1,
    },
    {
        id: 1143,
        name: 'Black Hills Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1143_1_1.PNG',
        status: 1,
    },
    {
        id: 1145,
        name: 'First Priority Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1145_1_1.PNG',
        status: 1,
    },
    {
        id: 1148,
        name: 'California Coast Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1148_1_1.PNG',
        status: 1,
    },
    {
        id: 1150,
        name: 'Capitol Area School Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1150_1_1.PNG',
        status: 1,
    },
    {
        id: 1151,
        name: 'CBC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1151_1_1.PNG',
        status: 1,
    },
    {
        id: 1153,
        name: 'Commonwealth Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1153_1_1.PNG',
        status: 1,
    },
    {
        id: 1154,
        name: 'Capitol Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1154_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 1155,
        name: 'Centier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1155_1_1.PNG',
        status: 1,
    },
    {
        id: 1156,
        name: 'PrimeWay Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1156_1_1.PNG',
        status: 1,
    },
    {
        id: 1157,
        name: 'City Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1157_1_1.PNG',
        status: 1,
    },
    {
        id: 1158,
        name: 'Commonwealth One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1158_1_1.PNG',
        status: 1,
    },
    {
        id: 1160,
        name: 'CommonWealth Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1160_1_1.PNG',
        status: 1,
    },
    {
        id: 1161,
        name: 'LegacyTexas Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1161_1_1.PNG',
        status: 1,
    },
    {
        id: 1163,
        name: 'Corning Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1163_1_1.PNG',
        status: 1,
    },
    {
        id: 1166,
        name: 'Del Norte Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1166_1_1.PNG',
        status: 1,
    },
    {
        id: 1167,
        name: 'Department of Commerce Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1167_1_1.PNG',
        status: 1,
    },
    {
        id: 1168,
        name: 'Neighborhood Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1168_1_1.PNG',
        status: 1,
    },
    {
        id: 1170,
        name: 'EECU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1170_1_1.PNG',
        status: 1,
    },
    {
        id: 1173,
        name: 'Heritage South Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1173_1_1.PNG',
        status: 1,
    },
    {
        id: 1175,
        name: 'First Community Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1175_1_1.PNG',
        status: 1,
    },
    {
        id: 1176,
        name: 'Financial Center Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1176_1_1.PNG',
        status: 1,
    },
    {
        id: 1179,
        name: 'First Financial Credit Union (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1179_1_1.PNG',
        status: 1,
    },
    {
        id: 1181,
        name: 'First Eagle Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1181_1_1.PNG',
        status: 1,
    },
    {
        id: 1183,
        name: 'Abound Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1183_1_1.PNG',
        status: 1,
    },
    {
        id: 1184,
        name: 'Partners Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1184_1_2.SVG',
        status: 1,
    },
    {
        id: 1186,
        name: 'Franklin Mint Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1186_1_1.PNG',
        status: 1,
    },
    {
        id: 1188,
        name: 'Fort Bragg Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1188_1_1.PNG',
        status: 1,
    },
    {
        id: 1190,
        name: 'Fort Worth Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1190_1_1.PNG',
        status: 1,
    },
    {
        id: 1191,
        name: 'Sun Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1191_1_1.PNG',
        status: 1,
    },
    {
        id: 1192,
        name: 'Gulf Coast Educators Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1192_1_1.PNG',
        status: 1,
    },
    {
        id: 1193,
        name: 'Gold Coast Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1193_1_1.PNG',
        status: 1,
    },
    {
        id: 1194,
        name: 'Salal CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1194_1_1.PNG',
        status: 1,
    },
    {
        id: 1198,
        name: 'HAR-CO Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1198_1_1.PNG',
        status: 1,
    },
    {
        id: 1199,
        name: 'Heartland Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1199_1_1.PNG',
        status: 1,
    },
    {
        id: 1203,
        name: 'Hiway Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1203_1_1.PNG',
        status: 1,
    },
    {
        id: 1204,
        name: 'Bedford Independent Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1204_1_1.PNG',
        status: 1,
    },
    {
        id: 1205,
        name: 'Hawaiian Tel Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1205_1_2.SVG',
        status: 1,
    },
    {
        id: 1206,
        name: 'Horizon Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1206_1_1.PNG',
        status: 1,
    },
    {
        id: 1208,
        name: 'Island Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1208_1_1.PNG',
        status: 1,
    },
    {
        id: 1209,
        name: 'Veridian Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1209_1_1.PNG',
        status: 1,
    },
    {
        id: 1214,
        name: 'La Capitol Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1214_1_1.PNG',
        status: 1,
    },
    {
        id: 1215,
        name: 'Landmark Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1215_1_1.PNG',
        status: 1,
    },
    {
        id: 1216,
        name: 'Long Beach City Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1216_1_1.PNG',
        status: 1,
    },
    {
        id: 1217,
        name: 'Library of Congress Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1217_1_1.PNG',
        status: 1,
    },
    {
        id: 1218,
        name: 'L&N Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1218_1_1.PNG',
        status: 1,
    },
    {
        id: 1219,
        name: 'Logix Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1219_1_2.SVG',
        status: 1,
    },
    {
        id: 1221,
        name: 'Mid-Atlantic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1221_1_1.PNG',
        status: 1,
    },
    {
        id: 1225,
        name: 'TruWest Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1225_1_1.PNG',
        status: 1,
    },
    {
        id: 1227,
        name: 'Extra Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1227_1_1.PNG',
        status: 1,
    },
    {
        id: 1228,
        name: 'MIT FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1228_1_1.PNG',
        status: 1,
    },
    {
        id: 1229,
        name: 'Mid USA Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1229_1_1.PNG',
        status: 1,
    },
    {
        id: 1231,
        name: 'NCPD FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1231_1_1.PNG',
        status: 1,
    },
    {
        id: 1232,
        name: 'Notre Dame Federal CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1232_1_1.PNG',
        status: 1,
    },
    {
        id: 1233,
        name: 'NIH Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1233_1_1.PNG',
        status: 1,
    },
    {
        id: 1234,
        name: 'Credit Union of New Jersey',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1234_1_1.PNG',
        status: 1,
    },
    {
        id: 1235,
        name: 'Nusenda Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1235_1_1.PNG',
        status: 1,
    },
    {
        id: 1238,
        name: 'Orange Countys Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1238_1_1.PNG',
        status: 1,
    },
    {
        id: 1241,
        name: 'Heritage Grove Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 1242,
        name: 'Orlando Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1242_1_1.PNG',
        status: 1,
    },
    {
        id: 1243,
        name: 'Advantis Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1243_1_1.PNG',
        status: 1,
    },
    {
        id: 1244,
        name: 'Peoples Alliance Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1244_1_1.PNG',
        status: 1,
    },
    {
        id: 1249,
        name: 'Pacific NW Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1249_1_1.PNG',
        status: 1,
    },
    {
        id: 1250,
        name: 'Powerco FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1250_1_1.PNG',
        status: 1,
    },
    {
        id: 1251,
        name: 'OnPoint Community Credit Union (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1251_1_2.SVG',
        status: 1,
    },
    {
        id: 1254,
        name: 'Allegacy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1254_1_1.PNG',
        status: 1,
    },
    {
        id: 1255,
        name: 'Red River FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1255_1_1.PNG',
        status: 1,
    },
    {
        id: 1257,
        name: 'Rivermark Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1257_1_1.PNG',
        status: 1,
    },
    {
        id: 1258,
        name: 'Ardent Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 1261,
        name: 'Sioux Empire Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1261_1_1.PNG',
        status: 1,
    },
    {
        id: 1262,
        name: 'Connex Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1262_1_1.PNG',
        status: 1,
    },
    {
        id: 1264,
        name: 'Suffolk Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1264_1_1.PNG',
        status: 1,
    },
    {
        id: 1265,
        name: 'Southwest Airlines Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1265_1_1.PNG',
        status: 1,
    },
    {
        id: 1266,
        name: 'Texas Bay Area Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1266_1_1.PNG',
        status: 1,
    },
    {
        id: 1267,
        name: 'United Teletech Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1267_1_1.PNG',
        status: 1,
    },
    {
        id: 1269,
        name: 'Triangle Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1269_1_1.PNG',
        status: 1,
    },
    {
        id: 1271,
        name: 'Firefly Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1271_1_1.PNG',
        status: 1,
    },
    {
        id: 1272,
        name: 'University of Virginia Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1272_1_1.PNG',
        status: 1,
    },
    {
        id: 1273,
        name: 'XCEL Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1273_1_1.PNG',
        status: 1,
    },
    {
        id: 1296,
        name: 'Bank of Botetourt',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1296_1_1.PNG',
        status: 1,
    },
    {
        id: 1297,
        name: 'Bank of Holly Springs',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1297_1_1.PNG',
        status: 1,
    },
    {
        id: 1302,
        name: 'Bank of Montgomery',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1302_1_1.PNG',
        status: 1,
    },
    {
        id: 1307,
        name: 'Citizens Tri-County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1307_1_1.PNG',
        status: 1,
    },
    {
        id: 1312,
        name: 'Crest Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1312_1_1.PNG',
        status: 1,
    },
    {
        id: 1315,
        name: 'EvaBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1315_1_1.PNG',
        status: 1,
    },
    {
        id: 1317,
        name: 'Rivers Edge Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1317_1_1.PNG',
        status: 1,
    },
    {
        id: 1318,
        name: 'First Community Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1318_1_1.PNG',
        status: 1,
    },
    {
        id: 1322,
        name: 'Penn Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1322_1_2.SVG',
        status: 1,
    },
    {
        id: 1327,
        name: 'Flagstar Personal Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1327_1_1.PNG',
        status: 1,
    },
    {
        id: 1328,
        name: 'Farmers & Merchants Bank (FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1328_1_1.PNG',
        status: 1,
    },
    {
        id: 1331,
        name: 'First National Bank of Hutchinson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1331_1_1.PNG',
        status: 1,
    },
    {
        id: 1340,
        name: 'Hometown Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1340_1_1.PNG',
        status: 1,
    },
    {
        id: 1343,
        name: 'MutualBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1343_1_1.PNG',
        status: 1,
    },
    {
        id: 1344,
        name: 'The Peoples Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1344_1_1.PNG',
        status: 1,
    },
    {
        id: 1345,
        name: 'Merchants National Bank of Bangor',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1345_1_1.PNG',
        status: 1,
    },
    {
        id: 1348,
        name: 'National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1348_1_1.PNG',
        status: 1,
    },
    {
        id: 1350,
        name: 'Ocean First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1350_1_1.PNG',
        status: 1,
    },
    {
        id: 1356,
        name: 'Republic Bank & Trust (KY, IN, TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1356_1_1.PNG',
        status: 1,
    },
    {
        id: 1357,
        name: 'Richwood Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1357_1_1.PNG',
        status: 1,
    },
    {
        id: 1362,
        name: 'Citizens Bank of Philadelphia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1362_1_1.PNG',
        status: 1,
    },
    {
        id: 1525,
        name: 'KeyPoint CU (Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1525_1_1.PNG',
        status: 1,
    },
    {
        id: 1622,
        name: 'Technology Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1622_1_1.PNG',
        status: 1,
    },
    {
        id: 1629,
        name: 'Provident Credit Union (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1629_1_1.PNG',
        status: 1,
    },
    {
        id: 1683,
        name: 'Salem Five',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_1683_1_1.PNG',
        status: 1,
    },
    {
        id: 2305,
        name: 'KeyBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2305_1_2.SVG',
        status: 1,
    },
    {
        id: 2606,
        name: 'Cleveland State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2606_1_1.PNG',
        status: 1,
    },
    {
        id: 2971,
        name: 'Commerce Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2971_1_2.SVG',
        status: 1,
    },
    {
        id: 2979,
        name: 'UMB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2979_1_1.PNG',
        status: 1,
    },
    {
        id: 2991,
        name: 'Heritage Bank of Commerce (Commercial Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2991_1_1.PNG',
        status: 1,
    },
    {
        id: 2996,
        name: 'GE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_2996_1_1.PNG',
        status: 1,
    },
    {
        id: 3012,
        name: 'Congressional Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3013,
        name: 'American Bank Online',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3013_1_1.PNG',
        status: 1,
    },
    {
        id: 3017,
        name: 'American Express (Personal Savings)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3017_1_2.SVG',
        status: 1,
    },
    {
        id: 3025,
        name: 'Glenview State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3025_1_1.PNG',
        status: 1,
    },
    {
        id: 3026,
        name: 'Digital Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3026_1_2.SVG',
        status: 1,
    },
    {
        id: 3035,
        name: 'BankDirect',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3035_1_1.PNG',
        status: 1,
    },
    {
        id: 3038,
        name: 'Eastern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3038_1_2.SVG',
        status: 1,
    },
    {
        id: 3048,
        name: 'FirstBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3048_1_2.SVG',
        status: 1,
    },
    {
        id: 3079,
        name: 'Omaha State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3079_1_1.PNG',
        status: 1,
    },
    {
        id: 3080,
        name: 'Cambridge Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3080_1_1.PNG',
        status: 1,
    },
    {
        id: 3082,
        name: 'Sandy Spring Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3082_1_1.PNG',
        status: 1,
    },
    {
        id: 3083,
        name: 'Patriot National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3083_1_1.PNG',
        status: 1,
    },
    {
        id: 3084,
        name: "People's United Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3084_1_2.SVG',
        status: 1,
    },
    {
        id: 3110,
        name: 'Golden 1 Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3110_1_2.SVG',
        status: 1,
    },
    {
        id: 3111,
        name: 'Webster Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3111_1_2.SVG',
        status: 1,
    },
    {
        id: 3116,
        name: 'Patelco Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3116_1_2.SVG',
        status: 1,
    },
    {
        id: 3120,
        name: 'Affinity Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3120_1_1.PNG',
        status: 1,
    },
    {
        id: 3121,
        name: 'Valley National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3121_1_1.PNG',
        status: 1,
    },
    {
        id: 3124,
        name: 'Greater Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3127,
        name: 'RBC Bank US',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3127_1_1.PNG',
        status: 1,
    },
    {
        id: 3158,
        name: 'Legacy Texas Bank (Commercial Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3158_1_1.PNG',
        status: 1,
    },
    {
        id: 3160,
        name: 'OneAZ Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3160_1_1.PNG',
        status: 1,
    },
    {
        id: 3162,
        name: 'Pacific Mercantile Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3162_1_1.PNG',
        status: 1,
    },
    {
        id: 3168,
        name: 'First County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3168_1_1.PNG',
        status: 1,
    },
    {
        id: 3171,
        name: 'Bank of the West',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3171_1_2.SVG',
        status: 1,
    },
    {
        id: 3174,
        name: 'Dallas Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3185,
        name: 'Oxford Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3185_1_1.PNG',
        status: 1,
    },
    {
        id: 3192,
        name: 'Popular Bank - US',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3192_1_2.SVG',
        status: 1,
    },
    {
        id: 3195,
        name: 'Bank of the West (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3195_1_1.PNG',
        status: 1,
    },
    {
        id: 3213,
        name: 'National Bank of Arizona',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3213_1_1.PNG',
        status: 1,
    },
    {
        id: 3214,
        name: 'Premier America Credit Union (CA, TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3214_1_1.PNG',
        status: 1,
    },
    {
        id: 3215,
        name: 'Presidential Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3215_1_1.PNG',
        status: 1,
    },
    {
        id: 3218,
        name: 'Genisys Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3218_1_1.PNG',
        status: 1,
    },
    {
        id: 3219,
        name: 'HomeStreet Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3219_1_1.PNG',
        status: 1,
    },
    {
        id: 3221,
        name: 'Sunmark Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3221_1_1.PNG',
        status: 1,
    },
    {
        id: 3222,
        name: 'BOK Financial (Online Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3222_1_1.PNG',
        status: 1,
    },
    {
        id: 3250,
        name: 'North Shore Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3250_1_1.PNG',
        status: 1,
    },
    {
        id: 3252,
        name: 'Colorado State Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3252_1_1.PNG',
        status: 1,
    },
    {
        id: 3266,
        name: 'Bank Of Bartlett',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3266_1_1.PNG',
        status: 1,
    },
    {
        id: 3270,
        name: 'Cadence Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3270_1_1.PNG',
        status: 1,
    },
    {
        id: 3271,
        name: 'First American Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3271_1_1.PNG',
        status: 1,
    },
    {
        id: 3275,
        name: 'North Dallas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3276,
        name: 'Tennessee Teachers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 'Beta',
    },
    {
        id: 3277,
        name: 'Town County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3277_1_1.PNG',
        status: 1,
    },
    {
        id: 3278,
        name: 'USAA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3278_1_2.SVG',
        status: 1,
    },
    {
        id: 3279,
        name: 'City National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3279_1_1.PNG',
        status: 1,
    },
    {
        id: 3280,
        name: 'BankUnited',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3280_1_1.PNG',
        status: 1,
    },
    {
        id: 3289,
        name: 'Clear Mountain Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3289_1_1.PNG',
        status: 1,
    },
    {
        id: 3291,
        name: 'Centra Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3291_1_1.PNG',
        status: 1,
    },
    {
        id: 3296,
        name: 'Mechanics Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3296_1_1.PNG',
        status: 1,
    },
    {
        id: 3306,
        name: 'Fremont Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3306_1_1.PNG',
        status: 1,
    },
    {
        id: 3309,
        name: 'Liberty Bank (CT) Personal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3309_1_1.PNG',
        status: 1,
    },
    {
        id: 3310,
        name: 'National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3310_1_1.PNG',
        status: 1,
    },
    {
        id: 3315,
        name: 'SouthTrust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3315_1_1.PNG',
        status: 1,
    },
    {
        id: 3317,
        name: 'Umpqua Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3317_1_1.PNG',
        status: 1,
    },
    {
        id: 3319,
        name: 'Texas Heritage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3319_1_1.PNG',
        status: 1,
    },
    {
        id: 3321,
        name: 'West Suburban Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3321_1_1.PNG',
        status: 1,
    },
    {
        id: 3341,
        name: 'Apollo Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3341_1_1.PNG',
        status: 1,
    },
    {
        id: 3342,
        name: 'Enterprise Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3342_1_1.PNG',
        status: 1,
    },
    {
        id: 3343,
        name: 'Greylock Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3343_1_1.PNG',
        status: 1,
    },
    {
        id: 3349,
        name: 'InTouch Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3349_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 3354,
        name: 'STAR Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3354_1_1.PNG',
        status: 1,
    },
    {
        id: 3356,
        name: 'Western Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3356_1_1.PNG',
        status: 1,
    },
    {
        id: 3357,
        name: 'Navy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3357_1_2.SVG',
        status: 1,
    },
    {
        id: 3360,
        name: 'Trustmark National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3360_1_1.PNG',
        status: 1,
    },
    {
        id: 3363,
        name: 'Stock Yards Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3363_1_1.PNG',
        status: 1,
    },
    {
        id: 3382,
        name: 'Santander Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3382_1_2.SVG',
        status: 1,
    },
    {
        id: 3393,
        name: 'Alpine Banks of Colorado',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3393_1_1.PNG',
        status: 1,
    },
    {
        id: 3394,
        name: 'Community Financial Services Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3394_1_1.PNG',
        status: 1,
    },
    {
        id: 3395,
        name: 'Bank of Cleveland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3395_1_1.PNG',
        status: 1,
    },
    {
        id: 3396,
        name: 'Wyoming County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3396_1_1.PNG',
        status: 1,
    },
    {
        id: 3397,
        name: 'Bank of Elk River',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3397_1_1.PNG',
        status: 1,
    },
    {
        id: 3400,
        name: 'Winona National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3402,
        name: 'Wilson Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3402_1_1.PNG',
        status: 1,
    },
    {
        id: 3409,
        name: 'Union National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3420,
        name: 'Union Bank (VT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3420_1_1.PNG',
        status: 1,
    },
    {
        id: 3421,
        name: 'Tioga State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3421_1_1.PNG',
        status: 1,
    },
    {
        id: 3422,
        name: 'Thomasville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3422_1_1.PNG',
        status: 1,
    },
    {
        id: 3424,
        name: 'Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3424_1_1.PNG',
        status: 1,
    },
    {
        id: 3425,
        name: 'Texas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3425_1_1.PNG',
        status: 1,
    },
    {
        id: 3426,
        name: 'Sterling National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3426_1_1.PNG',
        status: 1,
    },
    {
        id: 3427,
        name: 'State Central Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3427_1_1.PNG',
        status: 1,
    },
    {
        id: 3432,
        name: 'Sauk Valley Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3432_1_1.PNG',
        status: 1,
    },
    {
        id: 3457,
        name: 'Oritani Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3457_1_1.PNG',
        status: 1,
    },
    {
        id: 3486,
        name: 'A+ Federal Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3486_1_1.PNG',
        status: 1,
    },
    {
        id: 3489,
        name: 'East West Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3489_1_1.PNG',
        status: 1,
    },
    {
        id: 3492,
        name: 'Pinnacle Bank (NE, KS, MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3492_1_1.PNG',
        status: 1,
    },
    {
        id: 3499,
        name: 'Amboy National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3499_1_1.PNG',
        status: 1,
    },
    {
        id: 3502,
        name: 'American National Bank Omaha',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3502_1_1.PNG',
        status: 1,
    },
    {
        id: 3504,
        name: 'Credit Union ONE (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3504_1_1.PNG',
        status: 1,
    },
    {
        id: 3505,
        name: 'Fidelity Bank (NC, VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3505_1_1.PNG',
        status: 1,
    },
    {
        id: 3514,
        name: 'Midwest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3514_1_1.PNG',
        status: 1,
    },
    {
        id: 3517,
        name: 'Opus Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3517_1_1.PNG',
        status: 1,
    },
    {
        id: 3518,
        name: 'First Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3518_1_1.PNG',
        status: 1,
    },
    {
        id: 3520,
        name: 'MidAmerica Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3520_1_1.PNG',
        status: 1,
    },
    {
        id: 3522,
        name: 'Byline Bank Personal Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3522_1_1.PNG',
        status: 1,
    },
    {
        id: 3524,
        name: 'Bank of Hope',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3524_1_2.SVG',
        status: 1,
    },
    {
        id: 3525,
        name: 'Park Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3525_1_1.PNG',
        status: 1,
    },
    {
        id: 3529,
        name: 'Towne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3529_1_1.PNG',
        status: 1,
    },
    {
        id: 3537,
        name: 'Brookline Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3537_1_1.PNG',
        status: 1,
    },
    {
        id: 3540,
        name: 'Community Banks Of Colorado',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3540_1_1.PNG',
        status: 1,
    },
    {
        id: 3542,
        name: 'Albany Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3542_1_1.PNG',
        status: 1,
    },
    {
        id: 3545,
        name: 'City National Bank (WV, KY, VA, OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3545_1_1.PNG',
        status: 1,
    },
    {
        id: 3549,
        name: 'Woodforest National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3549_1_2.SVG',
        status: 1,
    },
    {
        id: 3550,
        name: 'Mechanics & Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3550_1_1.PNG',
        status: 1,
    },
    {
        id: 3577,
        name: 'WaterStone Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3577_1_1.PNG',
        status: 1,
    },
    {
        id: 3578,
        name: 'Volunteer State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3578_1_1.PNG',
        status: 1,
    },
    {
        id: 3579,
        name: 'Wright-Patt CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3579_1_1.PNG',
        status: 1,
    },
    {
        id: 3580,
        name: 'Frost Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3580_1_2.SVG',
        status: 1,
    },
    {
        id: 3588,
        name: 'Bellco Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3588_1_2.SVG',
        status: 1,
    },
    {
        id: 3591,
        name: 'Bank of Hawaii (Hawaii/American Samoa)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3591_1_2.SVG',
        status: 1,
    },
    {
        id: 3598,
        name: 'Amegy Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3598_1_1.PNG',
        status: 1,
    },
    {
        id: 3604,
        name: 'Hancock Whitney Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3604_1_2.SVG',
        status: 1,
    },
    {
        id: 3607,
        name: 'Commercial Bank of California',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3622,
        name: 'Credit Human',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3622_1_1.PNG',
        status: 1,
    },
    {
        id: 3623,
        name: 'BECU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3623_1_2.SVG',
        status: 1,
    },
    {
        id: 3624,
        name: 'Velocity Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3624_1_1.PNG',
        status: 1,
    },
    {
        id: 3632,
        name: 'Pennsylvania State Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3632_1_2.SVG',
        status: 1,
    },
    {
        id: 3634,
        name: 'GTE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3634_1_2.SVG',
        status: 1,
    },
    {
        id: 3643,
        name: 'Citywide Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3643_1_1.PNG',
        status: 1,
    },
    {
        id: 3644,
        name: 'Peoples State Bank of Munising',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3644_1_1.PNG',
        status: 1,
    },
    {
        id: 3645,
        name: 'First Hawaiian Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3645_1_2.SVG',
        status: 1,
    },
    {
        id: 3646,
        name: 'Capital One',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3646_1_2.SVG',
        status: 1,
    },
    {
        id: 3652,
        name: 'Premier Banks (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3652_1_1.PNG',
        status: 1,
    },
    {
        id: 3656,
        name: 'Bank of Southside Virginia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3656_1_1.PNG',
        status: 1,
    },
    {
        id: 3660,
        name: 'Bank of the San Juans',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3660_1_1.PNG',
        status: 1,
    },
    {
        id: 3661,
        name: 'Brenham National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3661_1_1.PNG',
        status: 1,
    },
    {
        id: 3663,
        name: 'Camden National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3663_1_1.PNG',
        status: 1,
    },
    {
        id: 3664,
        name: 'Stride Bank - US',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3664_1_1.PNG',
        status: 1,
    },
    {
        id: 3665,
        name: 'Central National Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3665_1_1.PNG',
        status: 1,
    },
    {
        id: 3666,
        name: 'Citizens State Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3666_1_1.PNG',
        status: 1,
    },
    {
        id: 3669,
        name: 'Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3669_1_1.PNG',
        status: 1,
    },
    {
        id: 3675,
        name: 'First Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3675_1_1.PNG',
        status: 1,
    },
    {
        id: 3677,
        name: 'First Citizens Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3677_1_2.SVG',
        status: 1,
    },
    {
        id: 3678,
        name: 'First Community National Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3678_1_1.PNG',
        status: 1,
    },
    {
        id: 3679,
        name: 'First Dakota National Bank - Cash Management Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3679_1_1.PNG',
        status: 1,
    },
    {
        id: 3681,
        name: 'First National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3681_1_1.PNG',
        status: 1,
    },
    {
        id: 3686,
        name: 'First National Bank of Elk River',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3686_1_1.PNG',
        status: 1,
    },
    {
        id: 3688,
        name: 'First National Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3688_1_1.PNG',
        status: 1,
    },
    {
        id: 3691,
        name: 'The First (ME)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3691_1_1.PNG',
        status: 1,
    },
    {
        id: 3693,
        name: 'Extraco Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3693_1_1.PNG',
        status: 1,
    },
    {
        id: 3702,
        name: 'Marquette Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3702_1_1.PNG',
        status: 1,
    },
    {
        id: 3708,
        name: 'Spokane Teachers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3708_1_1.PNG',
        status: 1,
    },
    {
        id: 3714,
        name: 'Farmers Insurance Group Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3714_1_1.PNG',
        status: 1,
    },
    {
        id: 3715,
        name: 'New England Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3715_1_1.PNG',
        status: 1,
    },
    {
        id: 3840,
        name: 'Johnson Bank (WI & AZ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3840_1_1.PNG',
        status: 1,
    },
    {
        id: 3847,
        name: 'Numerica Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 3855,
        name: 'SchoolsFirst Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3855_1_2.SVG',
        status: 1,
    },
    {
        id: 3860,
        name: 'Delta Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3860_1_2.SVG',
        status: 1,
    },
    {
        id: 3869,
        name: 'Security Service Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3869_1_1.PNG',
        status: 1,
    },
    {
        id: 3875,
        name: 'INTRUST Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3875_1_1.PNG',
        status: 1,
    },
    {
        id: 3881,
        name: 'NAPUS Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3881_1_1.PNG',
        status: 1,
    },
    {
        id: 3882,
        name: 'Texans Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3882_1_1.PNG',
        status: 1,
    },
    {
        id: 3942,
        name: 'Alaska USA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3942_1_1.PNG',
        status: 1,
    },
    {
        id: 3945,
        name: 'Associated Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3945_1_1.PNG',
        status: 1,
    },
    {
        id: 3961,
        name: 'Credit Union of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3961_1_1.PNG',
        status: 1,
    },
    {
        id: 3962,
        name: 'Achieva Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3962_1_1.PNG',
        status: 1,
    },
    {
        id: 3963,
        name: 'SCE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3963_1_1.PNG',
        status: 1,
    },
    {
        id: 3964,
        name: 'Oregon Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_3964_1_1.PNG',
        status: 1,
    },
    {
        id: 4074,
        name: 'iThink Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4074_1_1.PNG',
        status: 1,
    },
    {
        id: 4082,
        name: 'BBVA Compass',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4082_1_2.SVG',
        status: 1,
    },
    {
        id: 4103,
        name: 'PenFed Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4103_1_2.SVG',
        status: 1,
    },
    {
        id: 4113,
        name: 'Visions Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4113_1_1.PNG',
        status: 1,
    },
    {
        id: 4130,
        name: 'Bank of Albuquerque',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4130_1_1.PNG',
        status: 1,
    },
    {
        id: 4131,
        name: 'Bank of Oklahoma',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4131_1_1.PNG',
        status: 1,
    },
    {
        id: 4145,
        name: 'Belco Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4145_1_1.PNG',
        status: 1,
    },
    {
        id: 4174,
        name: 'Silver State Schools Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4174_1_1.PNG',
        status: 1,
    },
    {
        id: 4177,
        name: 'First National Bank of Omaha',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4177_1_2.SVG',
        status: 1,
    },
    {
        id: 4181,
        name: 'Redstone Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4181_1_1.PNG',
        status: 1,
    },
    {
        id: 4222,
        name: 'Dupaco Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 4325,
        name: 'Coastal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4325_1_1.PNG',
        status: 1,
    },
    {
        id: 4326,
        name: 'CEFCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4326_1_1.PNG',
        status: 1,
    },
    {
        id: 4328,
        name: 'Purdue Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4328_1_1.PNG',
        status: 1,
    },
    {
        id: 4331,
        name: 'Teachers CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4331_1_1.PNG',
        status: 1,
    },
    {
        id: 4332,
        name: 'Community First Credit Union (FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4332_1_1.PNG',
        status: 1,
    },
    {
        id: 4334,
        name: 'Allied First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4334_1_1.PNG',
        status: 1,
    },
    {
        id: 4335,
        name: 'American Airlines Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4335_1_1.PNG',
        status: 1,
    },
    {
        id: 4363,
        name: 'America First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4363_1_2.SVG',
        status: 1,
    },
    {
        id: 4426,
        name: 'Dutch Point CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4426_1_1.PNG',
        status: 1,
    },
    {
        id: 4445,
        name: 'SPC Cooperative Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4445_1_1.PNG',
        status: 1,
    },
    {
        id: 4450,
        name: 'Apple Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4450_1_1.PNG',
        status: 1,
    },
    {
        id: 4454,
        name: "America's Credit Union (WA)",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4454_1_1.PNG',
        status: 1,
    },
    {
        id: 4457,
        name: 'SMW Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4457_1_1.PNG',
        status: 1,
    },
    {
        id: 4464,
        name: 'APCI Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4464_1_1.PNG',
        status: 1,
    },
    {
        id: 4469,
        name: 'Fairwinds Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4469_1_1.PNG',
        status: 1,
    },
    {
        id: 4473,
        name: 'Pittsford Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4473_1_1.PNG',
        status: 1,
    },
    {
        id: 4474,
        name: 'Schools Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4474_1_1.PNG',
        status: 1,
    },
    {
        id: 4488,
        name: 'ESL Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4488_1_1.PNG',
        status: 1,
    },
    {
        id: 4490,
        name: 'Members Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4490_1_1.PNG',
        status: 1,
    },
    {
        id: 4491,
        name: 'Members Plus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4491_1_1.PNG',
        status: 1,
    },
    {
        id: 4501,
        name: 'SPIRE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4501_1_1.PNG',
        status: 1,
    },
    {
        id: 4515,
        name: 'Premier Members Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4515_1_1.PNG',
        status: 1,
    },
    {
        id: 4518,
        name: 'Northwest Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4518_1_1.PNG',
        status: 1,
    },
    {
        id: 4521,
        name: 'Christian Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4521_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 4524,
        name: 'Northland Area Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4524_1_1.PNG',
        status: 1,
    },
    {
        id: 4525,
        name: 'Y-12 FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4525_1_1.PNG',
        status: 1,
    },
    {
        id: 4527,
        name: 'Truliant Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4527_1_1.PNG',
        status: 1,
    },
    {
        id: 4528,
        name: 'USF Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4528_1_1.PNG',
        status: 1,
    },
    {
        id: 4529,
        name: 'OSU Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4529_1_1.PNG',
        status: 1,
    },
    {
        id: 4533,
        name: 'Frontwave Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4533_1_1.PNG',
        status: 1,
    },
    {
        id: 4537,
        name: 'Blackhawk CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4537_1_1.PNG',
        status: 1,
    },
    {
        id: 4544,
        name: 'CAHP Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4544_1_1.PNG',
        status: 1,
    },
    {
        id: 4546,
        name: 'Essential Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4546_1_1.PNG',
        status: 1,
    },
    {
        id: 4553,
        name: 'POPA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4553_1_1.PNG',
        status: 1,
    },
    {
        id: 4559,
        name: 'Summit Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 4562,
        name: 'Capital Educators Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4562_1_1.PNG',
        status: 1,
    },
    {
        id: 4563,
        name: 'Wanigas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4563_1_1.PNG',
        status: 1,
    },
    {
        id: 4571,
        name: 'Hughes Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4571_1_1.PNG',
        status: 1,
    },
    {
        id: 4573,
        name: 'Rogue Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4573_1_1.PNG',
        status: 1,
    },
    {
        id: 4576,
        name: 'Red Rocks Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4576_1_1.PNG',
        status: 1,
    },
    {
        id: 4582,
        name: 'First Financial Credit Union (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4582_1_1.PNG',
        status: 1,
    },
    {
        id: 4585,
        name: 'Directions CU Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4585_1_1.PNG',
        status: 1,
    },
    {
        id: 4589,
        name: 'Nebraska Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4589_1_1.PNG',
        status: 1,
    },
    {
        id: 4702,
        name: 'Envista Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4702_1_1.PNG',
        status: 1,
    },
    {
        id: 4705,
        name: 'Launch Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4705_1_1.PNG',
        status: 1,
    },
    {
        id: 4718,
        name: 'Shell Federal Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4718_1_1.PNG',
        status: 1,
    },
    {
        id: 4737,
        name: 'Cincinnati Police Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 4771,
        name: 'Worcester Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 4862,
        name: 'Sea West Coast Guard Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4862_1_1.PNG',
        status: 1,
    },
    {
        id: 4863,
        name: 'Sikorsky Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4863_1_1.PNG',
        status: 1,
    },
    {
        id: 4864,
        name: 'Greater Cincinnati Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 4865,
        name: 'Chemcel Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4865_1_1.PNG',
        status: 1,
    },
    {
        id: 4868,
        name: 'United One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4868_1_1.PNG',
        status: 1,
    },
    {
        id: 4871,
        name: 'Maine Family Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4871_1_1.PNG',
        status: 1,
    },
    {
        id: 4872,
        name: 'Connects FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4872_1_1.PNG',
        status: 1,
    },
    {
        id: 4888,
        name: 'Alabama Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4888_1_1.PNG',
        status: 1,
    },
    {
        id: 4889,
        name: 'American State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4889_1_1.PNG',
        status: 1,
    },
    {
        id: 4894,
        name: 'Fairfield National Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4894_1_1.PNG',
        status: 1,
    },
    {
        id: 4896,
        name: 'Bank of Yates City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4896_1_1.PNG',
        status: 1,
    },
    {
        id: 4897,
        name: 'Brady National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4897_1_1.PNG',
        status: 1,
    },
    {
        id: 4902,
        name: 'City State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4902_1_1.PNG',
        status: 1,
    },
    {
        id: 4904,
        name: 'Cooperativa Zeno Gandia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4904_1_1.PNG',
        status: 1,
    },
    {
        id: 4905,
        name: 'Community State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4905_1_1.PNG',
        status: 1,
    },
    {
        id: 4907,
        name: 'Decorah Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4907_1_1.PNG',
        status: 1,
    },
    {
        id: 4912,
        name: 'Farmers National Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4912_1_1.PNG',
        status: 1,
    },
    {
        id: 4913,
        name: 'Covington County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4913_1_1.PNG',
        status: 1,
    },
    {
        id: 4918,
        name: 'First Arkansas Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4918_1_1.PNG',
        status: 1,
    },
    {
        id: 4922,
        name: 'First Independent Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 4923,
        name: 'Kearny Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4923_1_1.PNG',
        status: 1,
    },
    {
        id: 4927,
        name: 'First National Bank & Trust of Miami',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4927_1_1.PNG',
        status: 1,
    },
    {
        id: 4929,
        name: 'Listerhill Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4929_1_1.PNG',
        status: 1,
    },
    {
        id: 4930,
        name: 'Lone Star National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4930_1_1.PNG',
        status: 1,
    },
    {
        id: 4931,
        name: 'First National Bank of North Arkansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4931_1_1.PNG',
        status: 1,
    },
    {
        id: 4933,
        name: 'Midwest Bank of Western Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4933_1_1.PNG',
        status: 1,
    },
    {
        id: 4934,
        name: 'First Security Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4934_1_1.PNG',
        status: 1,
    },
    {
        id: 4936,
        name: 'First Security Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4936_1_1.PNG',
        status: 1,
    },
    {
        id: 4940,
        name: 'North American Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4940_1_1.PNG',
        status: 1,
    },
    {
        id: 4946,
        name: 'International City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4946_1_2.SVG',
        status: 1,
    },
    {
        id: 4947,
        name: 'Grinnell State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4947_1_1.PNG',
        status: 1,
    },
    {
        id: 4948,
        name: 'Jefferson Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4948_1_1.PNG',
        status: 1,
    },
    {
        id: 4949,
        name: 'Illini State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4949_1_1.PNG',
        status: 1,
    },
    {
        id: 4950,
        name: 'Heartland Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4950_1_1.PNG',
        status: 1,
    },
    {
        id: 4951,
        name: 'Hendricks County Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4951_1_1.PNG',
        status: 1,
    },
    {
        id: 4953,
        name: 'Orange Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4953_1_1.PNG',
        status: 1,
    },
    {
        id: 4955,
        name: 'United Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4955_1_1.PNG',
        status: 1,
    },
    {
        id: 4956,
        name: 'United Bank of Iowa',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4956_1_1.PNG',
        status: 1,
    },
    {
        id: 4957,
        name: 'Valley Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4957_1_1.PNG',
        status: 1,
    },
    {
        id: 4960,
        name: 'Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4960_1_1.PNG',
        status: 1,
    },
    {
        id: 4963,
        name: 'Granville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4963_1_1.PNG',
        status: 1,
    },
    {
        id: 4974,
        name: 'Resource Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4974_1_1.PNG',
        status: 1,
    },
    {
        id: 4976,
        name: 'Security Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4976_1_1.PNG',
        status: 1,
    },
    {
        id: 4980,
        name: 'Suburban Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4980_1_1.PNG',
        status: 1,
    },
    {
        id: 4982,
        name: 'TSB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4982_1_2.SVG',
        status: 1,
    },
    {
        id: 4985,
        name: 'First National Bank of Sharp County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4985_1_1.PNG',
        status: 1,
    },
    {
        id: 4987,
        name: 'Ozona National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4987_1_1.PNG',
        status: 1,
    },
    {
        id: 4988,
        name: 'Fort Jennings State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4988_1_1.PNG',
        status: 1,
    },
    {
        id: 4991,
        name: 'Panola National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4991_1_1.PNG',
        status: 1,
    },
    {
        id: 4992,
        name: 'FCBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4992_1_1.PNG',
        status: 1,
    },
    {
        id: 4993,
        name: 'First Security Bank of Mountain Home',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4993_1_1.PNG',
        status: 1,
    },
    {
        id: 4994,
        name: 'Progress Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4994_1_1.PNG',
        status: 1,
    },
    {
        id: 4995,
        name: 'Republic Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4995_1_1.PNG',
        status: 1,
    },
    {
        id: 4999,
        name: 'Southern Michigan Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_4999_1_1.PNG',
        status: 1,
    },
    {
        id: 5000,
        name: 'State Bank & Trust Company (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5000_1_1.PNG',
        status: 1,
    },
    {
        id: 5003,
        name: 'First National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5028,
        name: 'Bank of the Sierra',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5028_1_1.PNG',
        status: 1,
    },
    {
        id: 5029,
        name: 'Beneficial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5029_1_1.PNG',
        status: 1,
    },
    {
        id: 5034,
        name: 'Central Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5034_1_1.PNG',
        status: 1,
    },
    {
        id: 5036,
        name: 'Isabella Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5036_1_1.PNG',
        status: 1,
    },
    {
        id: 5040,
        name: 'Winchester Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5040_1_1.PNG',
        status: 1,
    },
    {
        id: 5041,
        name: 'Central Bank of Boone County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5041_1_1.PNG',
        status: 1,
    },
    {
        id: 5042,
        name: 'First Command Financial Services Inc.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5042_1_1.PNG',
        status: 1,
    },
    {
        id: 5048,
        name: 'Jefferson Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5048_1_1.PNG',
        status: 1,
    },
    {
        id: 5049,
        name: 'CU of Southern California',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5049_1_1.PNG',
        status: 1,
    },
    {
        id: 5051,
        name: 'Northern Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5051_1_1.PNG',
        status: 1,
    },
    {
        id: 5052,
        name: 'Adventure CU (formerly Bell Com CU)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5052_1_1.PNG',
        status: 1,
    },
    {
        id: 5055,
        name: 'Atlantic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5055_1_1.PNG',
        status: 1,
    },
    {
        id: 5060,
        name: 'Citizens Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5060_1_2.SVG',
        status: 1,
    },
    {
        id: 5070,
        name: 'Capitol National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5070_1_1.PNG',
        status: 1,
    },
    {
        id: 5071,
        name: 'Central Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5071_1_1.PNG',
        status: 1,
    },
    {
        id: 5072,
        name: 'Citizens Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5072_1_1.PNG',
        status: 1,
    },
    {
        id: 5073,
        name: 'Commercial Bank of Ozark',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5073_1_1.PNG',
        status: 1,
    },
    {
        id: 5078,
        name: 'CommunityFirst Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5078_1_1.PNG',
        status: 1,
    },
    {
        id: 5081,
        name: 'First Navy Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5081_1_1.PNG',
        status: 1,
    },
    {
        id: 5084,
        name: 'Drummond Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5084_1_1.PNG',
        status: 1,
    },
    {
        id: 5085,
        name: 'First State Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5085_1_1.PNG',
        status: 1,
    },
    {
        id: 5088,
        name: 'First United Security Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5088_1_1.PNG',
        status: 1,
    },
    {
        id: 5096,
        name: 'Firstate Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5096_1_1.PNG',
        status: 1,
    },
    {
        id: 5097,
        name: 'Five Star Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5097_1_1.PNG',
        status: 1,
    },
    {
        id: 5098,
        name: 'Franklin Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5098_1_1.PNG',
        status: 1,
    },
    {
        id: 5100,
        name: 'Bank First Financial Services',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5100_1_1.PNG',
        status: 1,
    },
    {
        id: 5101,
        name: 'Bank of Camilla',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5101_1_1.PNG',
        status: 1,
    },
    {
        id: 5102,
        name: 'Bank of Commerce (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5102_1_1.PNG',
        status: 1,
    },
    {
        id: 5104,
        name: 'Bank of Idaho',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5104_1_1.PNG',
        status: 1,
    },
    {
        id: 5106,
        name: 'Southern FIRST',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5108,
        name: 'Elgin State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5116,
        name: 'Exchange Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5116_1_1.PNG',
        status: 1,
    },
    {
        id: 5117,
        name: 'Farmers & Merchants Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5117_1_1.PNG',
        status: 1,
    },
    {
        id: 5118,
        name: 'Bank of Magnolia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5118_1_1.PNG',
        status: 1,
    },
    {
        id: 5119,
        name: 'BNA Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5119_1_1.PNG',
        status: 1,
    },
    {
        id: 5121,
        name: 'The Farmers & Merchants Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5121_1_1.PNG',
        status: 1,
    },
    {
        id: 5122,
        name: 'Bank of Sullivan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5122_1_1.PNG',
        status: 1,
    },
    {
        id: 5125,
        name: 'MidSouth Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5125_1_1.PNG',
        status: 1,
    },
    {
        id: 5127,
        name: 'Tippins Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5127_1_1.PNG',
        status: 1,
    },
    {
        id: 5128,
        name: 'Troy Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5128_1_1.PNG',
        status: 1,
    },
    {
        id: 5129,
        name: 'Turbotville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5129_1_1.PNG',
        status: 1,
    },
    {
        id: 5133,
        name: 'Unity National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5134,
        name: 'Western State Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5135,
        name: 'Warrington Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5135_1_1.PNG',
        status: 1,
    },
    {
        id: 5136,
        name: 'West Central Georgia Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5136_1_1.PNG',
        status: 1,
    },
    {
        id: 5138,
        name: 'Libertyville Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5138_1_1.PNG',
        status: 1,
    },
    {
        id: 5141,
        name: 'Marine Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5141_1_1.PNG',
        status: 1,
    },
    {
        id: 5143,
        name: 'Mercantile Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5143_1_1.PNG',
        status: 1,
    },
    {
        id: 5144,
        name: 'First Bank of Dalton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5144_1_1.PNG',
        status: 1,
    },
    {
        id: 5147,
        name: 'Ameris Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5147_1_2.SVG',
        status: 1,
    },
    {
        id: 5149,
        name: 'First Metro Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5149_1_1.PNG',
        status: 1,
    },
    {
        id: 5150,
        name: 'BancorpSouth (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5150_1_1.PNG',
        status: 1,
    },
    {
        id: 5151,
        name: 'Mifflinburg Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5151_1_1.PNG',
        status: 1,
    },
    {
        id: 5154,
        name: 'FNB South',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5157,
        name: 'First National Bank of Coffee County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5157_1_1.PNG',
        status: 1,
    },
    {
        id: 5158,
        name: 'First Interstate Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5158_1_1.PNG',
        status: 1,
    },
    {
        id: 5161,
        name: 'QueensBorough National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5161_1_1.PNG',
        status: 1,
    },
    {
        id: 5163,
        name: 'Nevada Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5163_1_1.PNG',
        status: 1,
    },
    {
        id: 5164,
        name: 'Newfield National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5164_1_1.PNG',
        status: 1,
    },
    {
        id: 5171,
        name: 'Persons Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5171_1_1.PNG',
        status: 1,
    },
    {
        id: 5173,
        name: 'Peoples Bank and Trust (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5173_1_1.PNG',
        status: 1,
    },
    {
        id: 5174,
        name: 'Peoples Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5174_1_1.PNG',
        status: 1,
    },
    {
        id: 5181,
        name: 'Stockman Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5181_1_1.PNG',
        status: 1,
    },
    {
        id: 5182,
        name: 'Banesco Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5182_1_1.PNG',
        status: 1,
    },
    {
        id: 5187,
        name: 'Muncy Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5187_1_1.PNG',
        status: 1,
    },
    {
        id: 5188,
        name: 'Northumberland National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5188_1_1.PNG',
        status: 1,
    },
    {
        id: 5192,
        name: 'Small Town Bank of Wedowee (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5192_1_1.PNG',
        status: 1,
    },
    {
        id: 5196,
        name: 'Chelsea State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5196_1_1.PNG',
        status: 1,
    },
    {
        id: 5197,
        name: 'Lone Star Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5197_1_1.PNG',
        status: 1,
    },
    {
        id: 5250,
        name: 'Arkansas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5250_1_1.PNG',
        status: 1,
    },
    {
        id: 5270,
        name: 'Brattleboro Savings & Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5270_1_1.PNG',
        status: 1,
    },
    {
        id: 5276,
        name: 'Community Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5276_1_1.PNG',
        status: 1,
    },
    {
        id: 5286,
        name: 'Chevron Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5286_1_1.PNG',
        status: 1,
    },
    {
        id: 5314,
        name: 'First Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5314_1_1.PNG',
        status: 1,
    },
    {
        id: 5320,
        name: 'Florence Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5320_1_1.PNG',
        status: 1,
    },
    {
        id: 5426,
        name: 'BFG Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5426_1_1.PNG',
        status: 1,
    },
    {
        id: 5457,
        name: 'Bossier Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 5525,
        name: 'North Coast Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5525_1_1.PNG',
        status: 1,
    },
    {
        id: 5551,
        name: 'Schlumberger Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5551_1_1.PNG',
        status: 1,
    },
    {
        id: 5694,
        name: 'United Southeast Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5694_1_1.PNG',
        status: 1,
    },
    {
        id: 5710,
        name: 'Pioneer Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5710_1_1.PNG',
        status: 1,
    },
    {
        id: 5806,
        name: 'West Virginia Central Credit Union Investments',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5806_1_1.PNG',
        status: 1,
    },
    {
        id: 5854,
        name: 'RiverLand Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5854_1_1.PNG',
        status: 1,
    },
    {
        id: 5859,
        name: 'CHROME Federal Credit Union Investments',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5859_1_1.PNG',
        status: 1,
    },
    {
        id: 5873,
        name: 'White Sands Federal Credit Union Investments',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5873_1_1.PNG',
        status: 1,
    },
    {
        id: 5928,
        name: 'MAC Federal Credit Union Investments',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5928_1_1.PNG',
        status: 1,
    },
    {
        id: 5992,
        name: 'Carolina Collegiate Federal Credit Union Investments',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_5992_1_1.PNG',
        status: 1,
    },
    {
        id: 6098,
        name: 'Eastman Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6098_1_1.PNG',
        status: 1,
    },
    {
        id: 6100,
        name: 'United Educators Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6100_1_1.PNG',
        status: 1,
    },
    {
        id: 6122,
        name: 'Houston Police Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6122_1_1.PNG',
        status: 1,
    },
    {
        id: 6187,
        name: 'Mississippi Telco Federal Credit Union Investments',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6187_1_1.PNG',
        status: 1,
    },
    {
        id: 6333,
        name: 'Gesa Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6333_1_1.PNG',
        status: 1,
    },
    {
        id: 6381,
        name: 'Greater Nevada Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6381_1_1.PNG',
        status: 1,
    },
    {
        id: 6450,
        name: 'RTN FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6450_1_1.PNG',
        status: 1,
    },
    {
        id: 6451,
        name: 'First City Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6451_1_1.PNG',
        status: 1,
    },
    {
        id: 6452,
        name: 'Alliance CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6453,
        name: '1st Kentucky Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6453_1_1.PNG',
        status: 1,
    },
    {
        id: 6455,
        name: '1st Source Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6455_1_1.PNG',
        status: 1,
    },
    {
        id: 6457,
        name: 'Abilene Teachers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6457_1_1.PNG',
        status: 1,
    },
    {
        id: 6459,
        name: 'ACCESS FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6459_1_1.PNG',
        status: 1,
    },
    {
        id: 6461,
        name: 'Ascend Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6461_1_1.PNG',
        status: 1,
    },
    {
        id: 6462,
        name: 'Alltru Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6462_1_2.SVG',
        status: 1,
    },
    {
        id: 6463,
        name: 'Avadian Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6463_1_1.PNG',
        status: 1,
    },
    {
        id: 6465,
        name: 'Alliance Credit Union (CA & NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6465_1_1.PNG',
        status: 1,
    },
    {
        id: 6468,
        name: 'Opportunity Bank of Montana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6468_1_1.PNG',
        status: 1,
    },
    {
        id: 6469,
        name: 'Riverview Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6469_1_1.PNG',
        status: 1,
    },
    {
        id: 6471,
        name: 'Arizona Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6471_1_1.PNG',
        status: 1,
    },
    {
        id: 6472,
        name: 'Arizona Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6472_1_1.PNG',
        status: 1,
    },
    {
        id: 6473,
        name: 'Arlington Virginia Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6473_1_1.PNG',
        status: 1,
    },
    {
        id: 6474,
        name: 'All In Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6474_1_1.PNG',
        status: 1,
    },
    {
        id: 6475,
        name: 'Atlanta Postal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6475_1_1.PNG',
        status: 1,
    },
    {
        id: 6476,
        name: 'Atlantic Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6476_1_1.PNG',
        status: 1,
    },
    {
        id: 6480,
        name: 'Bank of Bolivar',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6480_1_1.PNG',
        status: 1,
    },
    {
        id: 6481,
        name: 'Bank of Dickson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6481_1_1.PNG',
        status: 1,
    },
    {
        id: 6482,
        name: 'BOS',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6482_1_1.PNG',
        status: 1,
    },
    {
        id: 6483,
        name: 'Bank Rhode Island',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6483_1_1.PNG',
        status: 1,
    },
    {
        id: 6484,
        name: 'BankFinancial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6484_1_1.PNG',
        status: 1,
    },
    {
        id: 6486,
        name: 'Barksdale Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6486_1_1.PNG',
        status: 1,
    },
    {
        id: 6490,
        name: 'Bridgewater Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6490_1_1.PNG',
        status: 1,
    },
    {
        id: 6492,
        name: 'Capital Communications Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6492_1_1.PNG',
        status: 1,
    },
    {
        id: 6495,
        name: 'Pacific Western Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6495_1_1.PNG',
        status: 1,
    },
    {
        id: 6496,
        name: 'Central Credit Union of Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6496_1_1.PNG',
        status: 1,
    },
    {
        id: 6497,
        name: 'Central Minnesota Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6497_1_1.PNG',
        status: 1,
    },
    {
        id: 6499,
        name: 'Centris Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6499_1_1.PNG',
        status: 1,
    },
    {
        id: 6500,
        name: 'Century Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6500_1_1.PNG',
        status: 1,
    },
    {
        id: 6501,
        name: 'New York Community Bank (Personal Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6501_1_1.PNG',
        status: 1,
    },
    {
        id: 6503,
        name: 'Citizens 1st National Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6503_1_1.PNG',
        status: 1,
    },
    {
        id: 6504,
        name: 'BayCoast Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6504_1_1.PNG',
        status: 1,
    },
    {
        id: 6505,
        name: 'CME Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6505_1_1.PNG',
        status: 1,
    },
    {
        id: 6506,
        name: 'CODE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6506_1_1.PNG',
        status: 1,
    },
    {
        id: 6507,
        name: 'Columbia Bank (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6507_1_1.PNG',
        status: 1,
    },
    {
        id: 6509,
        name: 'Columbia CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6509_1_1.PNG',
        status: 1,
    },
    {
        id: 6512,
        name: 'Communication Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6512_1_1.PNG',
        status: 1,
    },
    {
        id: 6513,
        name: 'Wildfire Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6513_1_1.PNG',
        status: 1,
    },
    {
        id: 6516,
        name: 'Consumers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6516_1_1.PNG',
        status: 1,
    },
    {
        id: 6517,
        name: 'Zeal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6517_1_1.PNG',
        status: 1,
    },
    {
        id: 6519,
        name: 'Department of the Interior Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6519_1_1.PNG',
        status: 1,
    },
    {
        id: 6520,
        name: 'Peoples Driven Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6520_1_1.PNG',
        status: 1,
    },
    {
        id: 6523,
        name: 'Education Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6523_1_1.PNG',
        status: 1,
    },
    {
        id: 6525,
        name: 'First State Bank of Dekalb County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6525_1_1.PNG',
        status: 1,
    },
    {
        id: 6526,
        name: 'Emory Alliance Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6526_1_1.PNG',
        status: 1,
    },
    {
        id: 6527,
        name: 'Empower Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6527_1_1.PNG',
        status: 1,
    },
    {
        id: 6528,
        name: 'Energy 1 Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6528_1_1.PNG',
        status: 1,
    },
    {
        id: 6534,
        name: 'JetStream Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6534_1_1.PNG',
        status: 1,
    },
    {
        id: 6535,
        name: 'Aspire Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6535_1_1.PNG',
        status: 1,
    },
    {
        id: 6538,
        name: 'Fifth District Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6538_1_1.PNG',
        status: 1,
    },
    {
        id: 6540,
        name: 'First Atlantic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6540_1_1.PNG',
        status: 1,
    },
    {
        id: 6542,
        name: 'First National Bank (Ames)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6542_1_1.PNG',
        status: 1,
    },
    {
        id: 6546,
        name: 'First National Bank of Ottawa',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6546_1_1.PNG',
        status: 1,
    },
    {
        id: 6548,
        name: 'First National Bank of Syracuse Johnson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6548_1_1.PNG',
        status: 1,
    },
    {
        id: 6551,
        name: 'Village Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6551_1_1.PNG',
        status: 1,
    },
    {
        id: 6556,
        name: 'First State Bank Of Wyoming',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6556_1_1.PNG',
        status: 1,
    },
    {
        id: 6559,
        name: 'AllSouth Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6559_1_1.PNG',
        status: 1,
    },
    {
        id: 6562,
        name: 'Royal Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6569,
        name: 'Greater El Pasos Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6569_1_1.PNG',
        status: 1,
    },
    {
        id: 6574,
        name: 'HNB National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6574_1_1.PNG',
        status: 1,
    },
    {
        id: 6575,
        name: 'Hapo Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6575_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 6576,
        name: 'Heritage Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6576_1_1.PNG',
        status: 1,
    },
    {
        id: 6578,
        name: 'Hillcrest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6578_1_1.PNG',
        status: 1,
    },
    {
        id: 6580,
        name: 'IH Mississippi Valley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6580_1_1.PNG',
        status: 1,
    },
    {
        id: 6581,
        name: 'Illiana Financial CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6581_1_1.PNG',
        status: 1,
    },
    {
        id: 6584,
        name: 'Infinity Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6585,
        name: 'FedChoice Federal Credit Union (MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6585_1_1.PNG',
        status: 1,
    },
    {
        id: 6586,
        name: 'Juniata Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6586_1_1.PNG',
        status: 1,
    },
    {
        id: 6589,
        name: 'Katahdin Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6589_1_1.PNG',
        status: 1,
    },
    {
        id: 6594,
        name: 'Leaders Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6594_1_1.PNG',
        status: 1,
    },
    {
        id: 6600,
        name: 'Michigan Schools and Government Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6600_1_1.PNG',
        status: 1,
    },
    {
        id: 6601,
        name: 'Marine Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6601_1_1.PNG',
        status: 1,
    },
    {
        id: 6602,
        name: 'MAX Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6602_1_1.PNG',
        status: 1,
    },
    {
        id: 6603,
        name: 'Members Choice CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6603_1_1.PNG',
        status: 1,
    },
    {
        id: 6604,
        name: 'Members First Credit Union of Florida',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6604_1_1.PNG',
        status: 1,
    },
    {
        id: 6607,
        name: 'Miami Firefighters Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6607_1_1.PNG',
        status: 1,
    },
    {
        id: 6608,
        name: 'Michigan Educational Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6608_1_1.PNG',
        status: 1,
    },
    {
        id: 6611,
        name: 'Millington Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6612,
        name: 'Mobil Oil Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6612_1_1.PNG',
        status: 1,
    },
    {
        id: 6615,
        name: 'Monson Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6615_1_1.PNG',
        status: 1,
    },
    {
        id: 6616,
        name: 'SkyPoint Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6616_1_1.PNG',
        status: 1,
    },
    {
        id: 6620,
        name: 'Jovia Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6620_1_1.PNG',
        status: 1,
    },
    {
        id: 6621,
        name: 'Premier One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6621_1_1.PNG',
        status: 1,
    },
    {
        id: 6622,
        name: "America's Christian Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6622_1_1.PNG',
        status: 1,
    },
    {
        id: 6626,
        name: 'One Nevada CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6626_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 6627,
        name: 'New Mexico Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6627_1_1.PNG',
        status: 1,
    },
    {
        id: 6631,
        name: 'Norway Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6631_1_1.PNG',
        status: 1,
    },
    {
        id: 6632,
        name: 'Tennessee Members First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6632_1_1.PNG',
        status: 1,
    },
    {
        id: 6634,
        name: 'Oklahoma Employees CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6634_1_1.PNG',
        status: 1,
    },
    {
        id: 6637,
        name: 'Otero FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6637_1_1.PNG',
        status: 1,
    },
    {
        id: 6638,
        name: 'Partners Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6639,
        name: 'Pasadena Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6639_1_1.PNG',
        status: 1,
    },
    {
        id: 6642,
        name: 'Pen Air Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6642_1_1.PNG',
        status: 1,
    },
    {
        id: 6644,
        name: 'Piedmont Aviation Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 'Beta',
    },
    {
        id: 6645,
        name: 'First U.S. Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6645_1_1.PNG',
        status: 1,
    },
    {
        id: 6646,
        name: 'Planters & Citizens Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6646_1_1.PNG',
        status: 1,
    },
    {
        id: 6647,
        name: 'Power Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6647_1_1.PNG',
        status: 1,
    },
    {
        id: 6650,
        name: 'PriorityONE Credit Union of Florida',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6650_1_1.PNG',
        status: 1,
    },
    {
        id: 6651,
        name: 'Putnam Info',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6651_1_1.PNG',
        status: 1,
    },
    {
        id: 6652,
        name: 'R.I.A Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6652_1_1.PNG',
        status: 1,
    },
    {
        id: 6654,
        name: 'Illinois Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6654_1_1.PNG',
        status: 1,
    },
    {
        id: 6656,
        name: 'Rocky Mountain Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6656_1_1.PNG',
        status: 1,
    },
    {
        id: 6658,
        name: 'Firstmark Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6658_1_1.PNG',
        status: 1,
    },
    {
        id: 6659,
        name: 'Xplore Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6659_1_1.PNG',
        status: 1,
    },
    {
        id: 6662,
        name: 'South Carolina Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6662_1_1.PNG',
        status: 1,
    },
    {
        id: 6664,
        name: 'SRP Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6664_1_1.PNG',
        status: 1,
    },
    {
        id: 6666,
        name: 'Vue Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6666_1_1.PNG',
        status: 1,
    },
    {
        id: 6667,
        name: "St. Helen's Community Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6669,
        name: 'State Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6669_1_1.PNG',
        status: 1,
    },
    {
        id: 6670,
        name: 'Sturdy Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6670_1_1.PNG',
        status: 1,
    },
    {
        id: 6672,
        name: 'SunWest Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6672_1_1.PNG',
        status: 1,
    },
    {
        id: 6675,
        name: 'The Bank Of Delmarva',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6675_1_1.PNG',
        status: 1,
    },
    {
        id: 6677,
        name: 'Baraboo State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6677_1_1.PNG',
        status: 1,
    },
    {
        id: 6678,
        name: 'Farmers State Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6678_1_1.PNG',
        status: 1,
    },
    {
        id: 6679,
        name: 'Connecticut Community Bank (Greenwich)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6679_1_1.PNG',
        status: 1,
    },
    {
        id: 6682,
        name: 'The Paris National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6682_1_1.PNG',
        status: 1,
    },
    {
        id: 6683,
        name: 'Provident Bank of New Jersey',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6683_1_1.PNG',
        status: 1,
    },
    {
        id: 6684,
        name: 'State Bank Of Viroqua',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6684_1_1.PNG',
        status: 1,
    },
    {
        id: 6685,
        name: 'The Valley State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6685_1_1.PNG',
        status: 1,
    },
    {
        id: 6687,
        name: 'Third Federal Savings and Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6687_1_1.PNG',
        status: 1,
    },
    {
        id: 6688,
        name: 'Three Rivers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6688_1_1.PNG',
        status: 1,
    },
    {
        id: 6691,
        name: 'Travis CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6691_1_1.PNG',
        status: 1,
    },
    {
        id: 6692,
        name: 'Tropical Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6692_1_1.PNG',
        status: 1,
    },
    {
        id: 6696,
        name: 'Union State Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6696_1_1.PNG',
        status: 1,
    },
    {
        id: 6699,
        name: 'Ventura County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6699_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 6701,
        name: 'Water & Power Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6701_1_1.PNG',
        status: 1,
    },
    {
        id: 6703,
        name: 'Rockcanyonbank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6703_1_1.PNG',
        status: 1,
    },
    {
        id: 6705,
        name: 'Wisconsin Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6705_1_1.PNG',
        status: 1,
    },
    {
        id: 6706,
        name: 'FNB Corporation (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6706_1_1.PNG',
        status: 1,
    },
    {
        id: 6707,
        name: "America's Credit Union (TX)",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6707_1_1.PNG',
        status: 1,
    },
    {
        id: 6714,
        name: 'Bangor Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6714_1_1.PNG',
        status: 1,
    },
    {
        id: 6717,
        name: 'Banner Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6717_1_1.PNG',
        status: 1,
    },
    {
        id: 6720,
        name: 'Blue Ridge Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6720_1_1.PNG',
        status: 1,
    },
    {
        id: 6721,
        name: 'BNC National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6721_1_1.PNG',
        status: 1,
    },
    {
        id: 6723,
        name: 'Burke & Herbert Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6723_1_1.PNG',
        status: 1,
    },
    {
        id: 6725,
        name: 'Centinel Bank of Taos (New Mexico)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6725_1_1.PNG',
        status: 1,
    },
    {
        id: 6729,
        name: 'Citizens Bank of Las Cruces',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6729_1_1.PNG',
        status: 1,
    },
    {
        id: 6730,
        name: 'Citizens Bank of Mukwonago',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6730_1_1.PNG',
        status: 1,
    },
    {
        id: 6731,
        name: 'Citizens Bank Tri-Cities',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6731_1_1.PNG',
        status: 1,
    },
    {
        id: 6735,
        name: 'Community National Bank (VT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6735_1_1.PNG',
        status: 1,
    },
    {
        id: 6737,
        name: 'Damariscotta Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6737_1_1.PNG',
        status: 1,
    },
    {
        id: 6740,
        name: 'First Citizens Community Bank (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6740_1_1.PNG',
        status: 1,
    },
    {
        id: 6742,
        name: 'First Independent Bank of Nevada',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6742_1_1.PNG',
        status: 1,
    },
    {
        id: 6747,
        name: 'Pinnacle Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6747_1_1.PNG',
        status: 1,
    },
    {
        id: 6748,
        name: 'First Texas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6748_1_1.PNG',
        status: 1,
    },
    {
        id: 6751,
        name: 'Peoples Bank of Alabama',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6751_1_1.PNG',
        status: 1,
    },
    {
        id: 6753,
        name: 'Glacier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6753_1_1.PNG',
        status: 1,
    },
    {
        id: 6755,
        name: 'Great Southern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6755_1_1.PNG',
        status: 1,
    },
    {
        id: 6758,
        name: 'Allegiance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6758_1_1.PNG',
        status: 1,
    },
    {
        id: 6759,
        name: 'Isabella Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6759_1_1.PNG',
        status: 1,
    },
    {
        id: 6760,
        name: 'Jersey Shore State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6760_1_1.PNG',
        status: 1,
    },
    {
        id: 6761,
        name: 'Lake Elmo Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6761_1_1.PNG',
        status: 1,
    },
    {
        id: 6762,
        name: 'Liberty National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6762_1_1.PNG',
        status: 1,
    },
    {
        id: 6763,
        name: 'Lincoln Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6763_1_1.PNG',
        status: 1,
    },
    {
        id: 6764,
        name: 'Mascoma Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6764_1_1.PNG',
        status: 1,
    },
    {
        id: 6766,
        name: 'Meredith Village Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6766_1_1.PNG',
        status: 1,
    },
    {
        id: 6767,
        name: 'Metropolitan National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6767_1_1.PNG',
        status: 1,
    },
    {
        id: 6769,
        name: 'Morris State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6769_1_1.PNG',
        status: 1,
    },
    {
        id: 6771,
        name: 'Oconee State Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6771_1_1.PNG',
        status: 1,
    },
    {
        id: 6772,
        name: 'Old Second Bancorp',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6772_1_1.PNG',
        status: 1,
    },
    {
        id: 6773,
        name: 'Peapack-Gladstone Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6773_1_1.PNG',
        status: 1,
    },
    {
        id: 6775,
        name: 'Prosperity Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6775_1_2.SVG',
        status: 1,
    },
    {
        id: 6776,
        name: 'RCB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6776_1_1.PNG',
        status: 1,
    },
    {
        id: 6779,
        name: 'Rockville Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6779_1_1.PNG',
        status: 1,
    },
    {
        id: 6780,
        name: 'Silver Lake Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6780_1_1.PNG',
        status: 1,
    },
    {
        id: 6785,
        name: 'Stillman Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6785_1_1.PNG',
        status: 1,
    },
    {
        id: 6786,
        name: 'Me Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6786_1_1.PNG',
        status: 1,
    },
    {
        id: 6787,
        name: 'Berlin City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6787_1_1.PNG',
        status: 1,
    },
    {
        id: 6788,
        name: 'Civista Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6788_1_2.SVG',
        status: 1,
    },
    {
        id: 6789,
        name: 'Dime Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6789_1_1.PNG',
        status: 1,
    },
    {
        id: 6792,
        name: 'Honesdale National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6792_1_1.PNG',
        status: 1,
    },
    {
        id: 6795,
        name: 'Northway Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6795_1_2.SVG',
        status: 1,
    },
    {
        id: 6797,
        name: 'United Business & Industry Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6797_1_1.PNG',
        status: 1,
    },
    {
        id: 6798,
        name: 'Valley Bank of Helena',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6798_1_1.PNG',
        status: 1,
    },
    {
        id: 6801,
        name: 'First Security Bank of Missoula',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6801_1_1.PNG',
        status: 1,
    },
    {
        id: 6822,
        name: 'Star One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6822_1_1.PNG',
        status: 1,
    },
    {
        id: 6824,
        name: 'Community Credit Union of Florida',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6824_1_1.PNG',
        status: 1,
    },
    {
        id: 6828,
        name: 'nuVision Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6828_1_1.PNG',
        status: 1,
    },
    {
        id: 6829,
        name: 'Pilgrim Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6829_1_1.PNG',
        status: 1,
    },
    {
        id: 6830,
        name: 'Point Breeze Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6830_1_1.PNG',
        status: 1,
    },
    {
        id: 6841,
        name: 'Associated Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6841_1_2.SVG',
        status: 1,
    },
    {
        id: 6848,
        name: 'Providence Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6848_1_1.PNG',
        status: 1,
    },
    {
        id: 6852,
        name: 'Zions Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6852_1_2.SVG',
        status: 1,
    },
    {
        id: 6856,
        name: 'Old National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6856_1_1.PNG',
        status: 1,
    },
    {
        id: 6928,
        name: 'Arvest Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6928_1_2.SVG',
        status: 1,
    },
    {
        id: 6942,
        name: 'Bank of Agriculture & Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6942_1_1.PNG',
        status: 1,
    },
    {
        id: 6943,
        name: 'Bank of Blue Valley',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6943_1_1.PNG',
        status: 1,
    },
    {
        id: 6945,
        name: 'Bank OZK',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6945_1_1.PNG',
        status: 1,
    },
    {
        id: 6948,
        name: 'Bank of Yazoo City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6950,
        name: 'CDC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6950_1_1.PNG',
        status: 1,
    },
    {
        id: 6952,
        name: 'CTBC Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 6954,
        name: 'CoreFirst Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6954_1_1.PNG',
        status: 1,
    },
    {
        id: 6956,
        name: 'Cross Keys Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6956_1_1.PNG',
        status: 1,
    },
    {
        id: 6957,
        name: 'Exchange Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6957_1_1.PNG',
        status: 1,
    },
    {
        id: 6961,
        name: 'First Columbia Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6961_1_1.PNG',
        status: 1,
    },
    {
        id: 6964,
        name: 'First National Bank of the Credit Union (Bankmberlands)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6964_1_1.PNG',
        status: 1,
    },
    {
        id: 6966,
        name: 'First State Bank of Athens (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6966_1_1.PNG',
        status: 1,
    },
    {
        id: 6967,
        name: 'Horizon Bank (Texas)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6967_1_1.PNG',
        status: 1,
    },
    {
        id: 6969,
        name: 'InsBank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6969_1_1.PNG',
        status: 1,
    },
    {
        id: 6970,
        name: 'Lakeland Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6970_1_1.PNG',
        status: 1,
    },
    {
        id: 6971,
        name: 'Landmark National Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6971_1_1.PNG',
        status: 1,
    },
    {
        id: 6973,
        name: 'Longview Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6973_1_1.PNG',
        status: 1,
    },
    {
        id: 6976,
        name: 'Middlesex Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6976_1_1.PNG',
        status: 1,
    },
    {
        id: 6980,
        name: 'NBT Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6980_1_1.PNG',
        status: 1,
    },
    {
        id: 6981,
        name: 'North Cascades National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6981_1_1.PNG',
        status: 1,
    },
    {
        id: 6984,
        name: 'Peoples Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6984_1_1.PNG',
        status: 1,
    },
    {
        id: 6986,
        name: 'Planters Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6986_1_1.PNG',
        status: 1,
    },
    {
        id: 6987,
        name: 'PlainsCapital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6987_1_1.PNG',
        status: 1,
    },
    {
        id: 6988,
        name: 'Raymond James Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6988_1_1.PNG',
        status: 1,
    },
    {
        id: 6989,
        name: 'Rockland Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6989_1_1.PNG',
        status: 1,
    },
    {
        id: 6990,
        name: 'Savings Bank of Walpole',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6990_1_1.PNG',
        status: 1,
    },
    {
        id: 6991,
        name: 'Second National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6991_1_1.PNG',
        status: 1,
    },
    {
        id: 6992,
        name: 'Southside Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6992_1_1.PNG',
        status: 1,
    },
    {
        id: 6993,
        name: 'St.Landry Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6993_1_1.PNG',
        status: 1,
    },
    {
        id: 6994,
        name: 'bankSNB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_6994_1_1.PNG',
        status: 1,
    },
    {
        id: 6995,
        name: 'Stockgrowers State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7000,
        name: 'Texas Tech Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7000_1_1.PNG',
        status: 1,
    },
    {
        id: 7002,
        name: 'Inter Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7002_1_1.PNG',
        status: 1,
    },
    {
        id: 7064,
        name: 'Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7064_1_1.PNG',
        status: 1,
    },
    {
        id: 7068,
        name: 'Delaware National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7068_1_1.PNG',
        status: 1,
    },
    {
        id: 7069,
        name: 'OneWest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7069_1_1.PNG',
        status: 1,
    },
    {
        id: 7071,
        name: 'First National Bank of Long Island',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7071_1_1.PNG',
        status: 1,
    },
    {
        id: 7073,
        name: 'First National Bank of Greencastle',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7073_1_1.PNG',
        status: 1,
    },
    {
        id: 7075,
        name: 'Lake City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7075_1_1.PNG',
        status: 1,
    },
    {
        id: 7081,
        name: 'United Bank (WV)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7081_1_1.PNG',
        status: 1,
    },
    {
        id: 7095,
        name: 'Los Angeles Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7095_1_1.PNG',
        status: 1,
    },
    {
        id: 7097,
        name: 'Macon Atlanta State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7107,
        name: 'Peoples Bank (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7107_1_1.PNG',
        status: 1,
    },
    {
        id: 7118,
        name: 'Western Districts Members Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7118_1_1.PNG',
        status: 1,
    },
    {
        id: 7119,
        name: 'ATL Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7119_1_1.PNG',
        status: 1,
    },
    {
        id: 7120,
        name: 'MembersAlliance Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7120_1_1.PNG',
        status: 1,
    },
    {
        id: 7121,
        name: 'Compass Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7121_1_1.PNG',
        status: 1,
    },
    {
        id: 7124,
        name: 'Muskegon Governmental Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7124_1_1.PNG',
        status: 1,
    },
    {
        id: 7125,
        name: 'TBA Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7125_1_1.PNG',
        status: 1,
    },
    {
        id: 7126,
        name: 'Building Trades Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7126_1_1.PNG',
        status: 1,
    },
    {
        id: 7128,
        name: 'East Traverse Catholic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7128_1_1.PNG',
        status: 1,
    },
    {
        id: 7129,
        name: 'River Valley CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7129_1_1.PNG',
        status: 1,
    },
    {
        id: 7130,
        name: 'Focus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7130_1_1.PNG',
        status: 1,
    },
    {
        id: 7131,
        name: 'Filer Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7131_1_1.PNG',
        status: 1,
    },
    {
        id: 7132,
        name: 'Interra Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7132_1_1.PNG',
        status: 1,
    },
    {
        id: 7133,
        name: 'Forest Area FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7133_1_1.PNG',
        status: 1,
    },
    {
        id: 7134,
        name: 'Muskegon Co-op Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7134_1_1.PNG',
        status: 1,
    },
    {
        id: 7135,
        name: 'Cascades Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7135_1_1.PNG',
        status: 1,
    },
    {
        id: 7137,
        name: 'United Community Credit union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7137_1_1.PNG',
        status: 1,
    },
    {
        id: 7138,
        name: 'Community First Credit Union (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7138_1_1.PNG',
        status: 1,
    },
    {
        id: 7139,
        name: 'Michigan One Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7139_1_1.PNG',
        status: 1,
    },
    {
        id: 7140,
        name: 'Evergreen Credit Union (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7140_1_1.PNG',
        status: 1,
    },
    {
        id: 7143,
        name: 'Western Division CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7143_1_1.PNG',
        status: 1,
    },
    {
        id: 7144,
        name: 'Unified Communities Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7144_1_1.PNG',
        status: 1,
    },
    {
        id: 7145,
        name: 'Lakeshore Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7145_1_1.PNG',
        status: 1,
    },
    {
        id: 7146,
        name: 'Western Cooperative CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7146_1_1.PNG',
        status: 1,
    },
    {
        id: 7147,
        name: 'Greater Metro Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7148,
        name: 'Peninsula FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7148_1_1.PNG',
        status: 1,
    },
    {
        id: 7149,
        name: 'Allegiance Credit Union (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7149_1_1.PNG',
        status: 1,
    },
    {
        id: 7150,
        name: 'Port City FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7152,
        name: 'Grand Trunk Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7152_1_1.PNG',
        status: 1,
    },
    {
        id: 7153,
        name: 'Brewery Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7153_1_1.PNG',
        status: 1,
    },
    {
        id: 7155,
        name: 'Advantage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7156,
        name: 'CorePlus Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7156_1_1.PNG',
        status: 1,
    },
    {
        id: 7157,
        name: 'Saginaw County Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7158,
        name: 'West Michigan Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7158_1_1.PNG',
        status: 1,
    },
    {
        id: 7159,
        name: 'Honor Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7159_1_1.PNG',
        status: 1,
    },
    {
        id: 7160,
        name: 'Verve Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7160_1_1.PNG',
        status: 1,
    },
    {
        id: 7161,
        name: 'Catholic United Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7161_1_1.PNG',
        status: 1,
    },
    {
        id: 7162,
        name: 'Explorers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7162_1_1.PNG',
        status: 1,
    },
    {
        id: 7163,
        name: 'Unison Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7163_1_1.PNG',
        status: 1,
    },
    {
        id: 7165,
        name: 'Ripco CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7165_1_1.PNG',
        status: 1,
    },
    {
        id: 7166,
        name: 'Harris County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7166_1_1.PNG',
        status: 1,
    },
    {
        id: 7167,
        name: 'Fox Communities Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7167_1_1.PNG',
        status: 1,
    },
    {
        id: 7168,
        name: 'La-Porter Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7220,
        name: 'S&T Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7220_1_2.SVG',
        status: 1,
    },
    {
        id: 7223,
        name: 'State Bank of the Lakes',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7223_1_1.PNG',
        status: 1,
    },
    {
        id: 7226,
        name: 'Fauquier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7226_1_1.PNG',
        status: 1,
    },
    {
        id: 7228,
        name: 'Helm Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7228_1_1.PNG',
        status: 1,
    },
    {
        id: 7231,
        name: 'Dedham Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7231_1_1.PNG',
        status: 1,
    },
    {
        id: 7233,
        name: 'Kitsap Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7233_1_1.PNG',
        status: 1,
    },
    {
        id: 7237,
        name: 'Mound City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7237_1_1.PNG',
        status: 1,
    },
    {
        id: 7238,
        name: 'StonehamBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7238_1_1.PNG',
        status: 1,
    },
    {
        id: 7241,
        name: 'East Boston Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7241_1_1.PNG',
        status: 1,
    },
    {
        id: 7244,
        name: 'Cambridge Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7244_1_1.PNG',
        status: 1,
    },
    {
        id: 7246,
        name: 'National Exchange Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7246_1_1.PNG',
        status: 1,
    },
    {
        id: 7247,
        name: 'FNBC Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7247_1_1.PNG',
        status: 1,
    },
    {
        id: 7248,
        name: 'City National Bank (FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7248_1_1.PNG',
        status: 1,
    },
    {
        id: 7249,
        name: 'Bankers Trust (Except Cedar Rapids)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7249_1_1.PNG',
        status: 1,
    },
    {
        id: 7250,
        name: 'County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7250_1_1.PNG',
        status: 1,
    },
    {
        id: 7253,
        name: 'Peoples National Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7253_1_1.PNG',
        status: 1,
    },
    {
        id: 7258,
        name: 'MidFirst Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7258_1_1.PNG',
        status: 1,
    },
    {
        id: 7263,
        name: 'Desert Community Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7263_1_1.PNG',
        status: 1,
    },
    {
        id: 7264,
        name: 'Security National Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7264_1_1.PNG',
        status: 1,
    },
    {
        id: 7265,
        name: 'First Farmers & Merchants Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7265_1_1.PNG',
        status: 1,
    },
    {
        id: 7268,
        name: 'The Citizens Bank of Cochran',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7268_1_1.PNG',
        status: 1,
    },
    {
        id: 7276,
        name: 'Bank of Pensacola',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7276_1_1.PNG',
        status: 1,
    },
    {
        id: 7295,
        name: 'Forest Park National Bank and Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7295_1_1.PNG',
        status: 1,
    },
    {
        id: 7298,
        name: 'Oxford Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7298_1_1.PNG',
        status: 1,
    },
    {
        id: 7299,
        name: 'American Savings Bank (HI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7299_1_1.PNG',
        status: 1,
    },
    {
        id: 7300,
        name: 'CIBC Bank USA (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7300_1_1.PNG',
        status: 1,
    },
    {
        id: 7302,
        name: 'Hyde Park Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7302_1_1.PNG',
        status: 1,
    },
    {
        id: 7303,
        name: 'Berkshire Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7303_1_1.PNG',
        status: 1,
    },
    {
        id: 7308,
        name: 'Clinton National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7308_1_1.PNG',
        status: 1,
    },
    {
        id: 7309,
        name: 'Community Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7309_1_1.PNG',
        status: 1,
    },
    {
        id: 7311,
        name: 'Broadway Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7311_1_1.PNG',
        status: 1,
    },
    {
        id: 7329,
        name: 'Lake Trust Credit Union (Lansing, Mt. Pleasant, West Michigan)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7329_1_1.PNG',
        status: 1,
    },
    {
        id: 7330,
        name: 'Thornapple Valley Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7330_1_1.PNG',
        status: 1,
    },
    {
        id: 7332,
        name: 'Randolph-Brooks Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7332_1_2.SVG',
        status: 'Beta',
    },
    {
        id: 7350,
        name: 'Community Credit Union (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7350_1_1.PNG',
        status: 1,
    },
    {
        id: 7351,
        name: '1st Constitution Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7351_1_1.PNG',
        status: 1,
    },
    {
        id: 7353,
        name: 'Brotherhood CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7353_1_1.PNG',
        status: 1,
    },
    {
        id: 7354,
        name: 'East Cambridge Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7354_1_1.PNG',
        status: 1,
    },
    {
        id: 7355,
        name: 'Cecil Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7355_1_1.PNG',
        status: 1,
    },
    {
        id: 7358,
        name: 'Clinton Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7358_1_1.PNG',
        status: 1,
    },
    {
        id: 7360,
        name: 'Carver Federal Savings Bank (Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7360_1_1.PNG',
        status: 1,
    },
    {
        id: 7363,
        name: 'Fall River Municipal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7363_1_1.PNG',
        status: 1,
    },
    {
        id: 7364,
        name: 'Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7364_1_1.PNG',
        status: 1,
    },
    {
        id: 7366,
        name: 'Homefield Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7366_1_1.PNG',
        status: 1,
    },
    {
        id: 7367,
        name: 'Greenfield Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7367_1_1.PNG',
        status: 1,
    },
    {
        id: 7369,
        name: 'Homestead Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7369_1_1.PNG',
        status: 1,
    },
    {
        id: 7372,
        name: 'Lutheran Church Extension Fund',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7372_1_1.PNG',
        status: 1,
    },
    {
        id: 7374,
        name: 'Mechanics Cooperative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7374_1_1.PNG',
        status: 1,
    },
    {
        id: 7376,
        name: 'Millbury FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7376_1_1.PNG',
        status: 1,
    },
    {
        id: 7377,
        name: 'East Boston Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7377_1_1.PNG',
        status: 1,
    },
    {
        id: 7378,
        name: 'Nantucket Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7378_1_1.PNG',
        status: 1,
    },
    {
        id: 7379,
        name: 'Peoples Credit Union (RI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7379_1_1.PNG',
        status: 1,
    },
    {
        id: 7380,
        name: 'Pioneer Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7380_1_1.PNG',
        status: 1,
    },
    {
        id: 7382,
        name: 'Prudential Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7382_1_1.PNG',
        status: 1,
    },
    {
        id: 7384,
        name: 'Savers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7384_1_1.PNG',
        status: 1,
    },
    {
        id: 7385,
        name: 'Severn Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7385_1_1.PNG',
        status: 1,
    },
    {
        id: 7386,
        name: 'Somerset Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7386_1_1.PNG',
        status: 1,
    },
    {
        id: 7387,
        name: 'South Shore Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7387_1_1.PNG',
        status: 1,
    },
    {
        id: 7388,
        name: 'Southbridge Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7388_1_1.PNG',
        status: 1,
    },
    {
        id: 7389,
        name: 'St. Annes CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7389_1_1.PNG',
        status: 1,
    },
    {
        id: 7390,
        name: 'Taylorsville Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7390_1_1.PNG',
        status: 1,
    },
    {
        id: 7391,
        name: 'Bank of Greene County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7391_1_1.PNG',
        status: 1,
    },
    {
        id: 7394,
        name: 'United Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7394_1_1.PNG',
        status: 1,
    },
    {
        id: 7395,
        name: 'Unity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7395_1_1.PNG',
        status: 1,
    },
    {
        id: 7396,
        name: 'Universal 1 Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7396_1_1.PNG',
        status: 1,
    },
    {
        id: 7400,
        name: 'Winter Hill Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7400_1_1.PNG',
        status: 1,
    },
    {
        id: 7402,
        name: 'Bank of Ann Arbor',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7402_1_1.PNG',
        status: 1,
    },
    {
        id: 7405,
        name: 'Farmers & Merchants Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7405_1_1.PNG',
        status: 1,
    },
    {
        id: 7407,
        name: 'GNB Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7408,
        name: 'First State Bank (Florida Keys)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7408_1_1.PNG',
        status: 1,
    },
    {
        id: 7410,
        name: 'IC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7410_1_1.PNG',
        status: 1,
    },
    {
        id: 7413,
        name: 'Security Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7413_1_1.PNG',
        status: 1,
    },
    {
        id: 7417,
        name: 'The First (A National Banking Association)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7417_1_1.PNG',
        status: 1,
    },
    {
        id: 7418,
        name: 'United Southern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7418_1_1.PNG',
        status: 1,
    },
    {
        id: 7473,
        name: 'Chevron West Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7473_1_1.PNG',
        status: 1,
    },
    {
        id: 7474,
        name: 'Bridge Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7474_1_1.PNG',
        status: 1,
    },
    {
        id: 7490,
        name: 'True North Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7490_1_1.PNG',
        status: 1,
    },
    {
        id: 7493,
        name: 'Anoka Hennepin Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7493_1_1.PNG',
        status: 1,
    },
    {
        id: 7497,
        name: 'Bear Paw Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7497_1_1.PNG',
        status: 1,
    },
    {
        id: 7498,
        name: 'Billings Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7498_1_1.PNG',
        status: 1,
    },
    {
        id: 7500,
        name: 'Cal Poly Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7500_1_1.PNG',
        status: 1,
    },
    {
        id: 7503,
        name: 'Citizens Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7503_1_1.PNG',
        status: 1,
    },
    {
        id: 7511,
        name: 'CP Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7516,
        name: 'Deere Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7516_1_1.PNG',
        status: 1,
    },
    {
        id: 7517,
        name: 'Beacon Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7517_1_1.PNG',
        status: 1,
    },
    {
        id: 7518,
        name: 'Midwest Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7518_1_1.PNG',
        status: 1,
    },
    {
        id: 7526,
        name: 'Helena Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7526_1_1.PNG',
        status: 1,
    },
    {
        id: 7535,
        name: 'Sky Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7535_1_1.PNG',
        status: 1,
    },
    {
        id: 7536,
        name: 'L. A. Police Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7536_1_1.PNG',
        status: 1,
    },
    {
        id: 7540,
        name: 'Minnesota Power Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7540_1_1.PNG',
        status: 1,
    },
    {
        id: 7541,
        name: 'Montana Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7541_1_1.PNG',
        status: 1,
    },
    {
        id: 7544,
        name: 'Pacific Cascade Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7544_1_1.PNG',
        status: 1,
    },
    {
        id: 7547,
        name: 'Premier Credit Union (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7547_1_1.PNG',
        status: 1,
    },
    {
        id: 7555,
        name: 'Advantage Credit Union (IO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7557,
        name: 'Two Harbors Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7557_1_1.PNG',
        status: 1,
    },
    {
        id: 7558,
        name: 'UniWyo Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7558_1_1.PNG',
        status: 1,
    },
    {
        id: 7562,
        name: 'Denali Alaskan Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7562_1_1.PNG',
        status: 1,
    },
    {
        id: 7563,
        name: 'JSC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7563_1_1.PNG',
        status: 1,
    },
    {
        id: 7564,
        name: 'Monterey Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7566,
        name: 'Russell Country Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7566_1_1.PNG',
        status: 1,
    },
    {
        id: 7570,
        name: 'Coast Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7570_1_1.PNG',
        status: 1,
    },
    {
        id: 7572,
        name: 'Dade County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7574,
        name: 'Langley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7574_1_1.PNG',
        status: 1,
    },
    {
        id: 7575,
        name: 'TruChoice Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7575_1_1.PNG',
        status: 1,
    },
    {
        id: 7577,
        name: 'Northern Skies Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7577_1_1.PNG',
        status: 1,
    },
    {
        id: 7580,
        name: 'Sooper Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7580_1_1.PNG',
        status: 1,
    },
    {
        id: 7582,
        name: 'FirstLight Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7582_1_1.PNG',
        status: 1,
    },
    {
        id: 7586,
        name: 'North Side Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7586_1_1.PNG',
        status: 1,
    },
    {
        id: 7599,
        name: 'University FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7599_1_2.SVG',
        status: 1,
    },
    {
        id: 7601,
        name: 'Eglin Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7601_1_2.SVG',
        status: 1,
    },
    {
        id: 7632,
        name: 'Eagle Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7632_1_1.PNG',
        status: 1,
    },
    {
        id: 7637,
        name: 'Suncoast Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7637_1_2.SVG',
        status: 1,
    },
    {
        id: 7652,
        name: "Georgia's Own Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7652_1_1.PNG',
        status: 1,
    },
    {
        id: 7653,
        name: 'Dubuque Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 7657,
        name: 'First Entertainment CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7657_1_1.PNG',
        status: 1,
    },
    {
        id: 7658,
        name: 'Washington State Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7658_1_2.SVG',
        status: 1,
    },
    {
        id: 7674,
        name: 'Baxter Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7674_1_1.PNG',
        status: 1,
    },
    {
        id: 7679,
        name: 'Amalgamated Bank of Chicago',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7679_1_1.PNG',
        status: 1,
    },
    {
        id: 7680,
        name: 'IAA CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7680_1_1.PNG',
        status: 1,
    },
    {
        id: 7688,
        name: 'Great Lakes Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7688_1_1.PNG',
        status: 1,
    },
    {
        id: 7689,
        name: 'North Carolina State Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7689_1_2.SVG',
        status: 1,
    },
    {
        id: 7692,
        name: 'First Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7692_1_1.PNG',
        status: 1,
    },
    {
        id: 7693,
        name: 'Wings Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7693_1_2.SVG',
        status: 1,
    },
    {
        id: 7696,
        name: 'Air Academy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7696_1_1.PNG',
        status: 1,
    },
    {
        id: 7738,
        name: 'First Hope Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7738_1_1.PNG',
        status: 1,
    },
    {
        id: 7755,
        name: 'Commercial Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7755_1_1.PNG',
        status: 1,
    },
    {
        id: 7867,
        name: 'GSA Federal Credit Union (Member View)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7867_1_1.PNG',
        status: 1,
    },
    {
        id: 7897,
        name: 'Security National Bank of Enid (PrimeVest)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7897_1_1.PNG',
        status: 1,
    },
    {
        id: 7912,
        name: 'Commercial & Savings Bank (OH) (PrimeVest)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_7912_1_1.PNG',
        status: 1,
    },
    {
        id: 8005,
        name: 'Central One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8005_1_1.PNG',
        status: 1,
    },
    {
        id: 8008,
        name: 'Dort Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8008_1_1.PNG',
        status: 1,
    },
    {
        id: 8022,
        name: 'Merrimack Valley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8022_1_1.PNG',
        status: 1,
    },
    {
        id: 8023,
        name: 'Columbia Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8029,
        name: 'Seattle Metropolitan Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8029_1_1.PNG',
        status: 1,
    },
    {
        id: 8030,
        name: 'Virginia Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8030_1_2.SVG',
        status: 1,
    },
    {
        id: 8031,
        name: 'Austin Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8031_1_1.PNG',
        status: 1,
    },
    {
        id: 8033,
        name: 'Pacific Premier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8033_1_1.PNG',
        status: 1,
    },
    {
        id: 8034,
        name: 'Chemung Canal Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8034_1_1.PNG',
        status: 1,
    },
    {
        id: 8039,
        name: 'Central State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8039_1_1.PNG',
        status: 1,
    },
    {
        id: 8044,
        name: 'Mabrey Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8044_1_1.PNG',
        status: 1,
    },
    {
        id: 8046,
        name: 'Austin Telco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8046_1_1.PNG',
        status: 1,
    },
    {
        id: 8051,
        name: 'KEB Hana Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8051_1_1.PNG',
        status: 1,
    },
    {
        id: 8052,
        name: 'HarborOne',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8052_1_1.PNG',
        status: 1,
    },
    {
        id: 8054,
        name: 'Greenfield Co-operative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8054_1_1.PNG',
        status: 1,
    },
    {
        id: 8056,
        name: 'BankWest (SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8056_1_1.PNG',
        status: 1,
    },
    {
        id: 8060,
        name: 'NorthPark Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8060_1_1.PNG',
        status: 1,
    },
    {
        id: 8063,
        name: 'Family Horizons Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8063_1_1.PNG',
        status: 1,
    },
    {
        id: 8067,
        name: 'Indiana State University Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8071,
        name: 'Valley National Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8071_1_1.PNG',
        status: 1,
    },
    {
        id: 8078,
        name: 'BancFirst',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8078_1_1.PNG',
        status: 1,
    },
    {
        id: 8082,
        name: 'City Bank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8082_1_1.PNG',
        status: 1,
    },
    {
        id: 8084,
        name: 'Dallas Telco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8084_1_1.PNG',
        status: 1,
    },
    {
        id: 8086,
        name: 'Umpqua Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8086_1_1.PNG',
        status: 1,
    },
    {
        id: 8088,
        name: 'Colonial Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8088_1_1.PNG',
        status: 1,
    },
    {
        id: 8089,
        name: 'Citizens & Northern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8089_1_1.PNG',
        status: 1,
    },
    {
        id: 8092,
        name: 'Healthcare Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8092_1_1.PNG',
        status: 1,
    },
    {
        id: 8104,
        name: 'Congressional Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8104_1_1.PNG',
        status: 1,
    },
    {
        id: 8108,
        name: 'Navigant Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8108_1_1.PNG',
        status: 1,
    },
    {
        id: 8109,
        name: '1st Nor Cal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8109_1_1.PNG',
        status: 1,
    },
    {
        id: 8110,
        name: 'CORE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8110_1_1.PNG',
        status: 1,
    },
    {
        id: 8111,
        name: 'Mainstreet CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8111_1_1.PNG',
        status: 1,
    },
    {
        id: 8112,
        name: 'Thrivent Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8112_1_2.SVG',
        status: 1,
    },
    {
        id: 8115,
        name: 'Credit Union 1 (AK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8115_1_1.PNG',
        status: 1,
    },
    {
        id: 8117,
        name: 'Elements Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8117_1_2.SVG',
        status: 1,
    },
    {
        id: 8167,
        name: 'Robins Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8167_1_2.SVG',
        status: 1,
    },
    {
        id: 8171,
        name: 'DuPage CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8171_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 8174,
        name: 'Lampco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8174_1_1.PNG',
        status: 1,
    },
    {
        id: 8205,
        name: 'Kemba Indianapolis Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8205_1_1.PNG',
        status: 1,
    },
    {
        id: 8209,
        name: 'Spencer Savings Bank (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8209_1_1.PNG',
        status: 1,
    },
    {
        id: 8221,
        name: 'Investors Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8221_1_1.PNG',
        status: 1,
    },
    {
        id: 8238,
        name: 'First Community Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8238_1_1.PNG',
        status: 1,
    },
    {
        id: 8256,
        name: 'North Shore Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8256_1_1.PNG',
        status: 1,
    },
    {
        id: 8259,
        name: 'American Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8259_1_1.PNG',
        status: 1,
    },
    {
        id: 8268,
        name: 'Westerra Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8268_1_1.PNG',
        status: 1,
    },
    {
        id: 8269,
        name: 'Direct Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8269_1_1.PNG',
        status: 1,
    },
    {
        id: 8270,
        name: 'Cornerstone Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8270_1_1.PNG',
        status: 1,
    },
    {
        id: 8279,
        name: 'First National Bank of Broken Arrow',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8279_1_1.PNG',
        status: 1,
    },
    {
        id: 8282,
        name: 'First PREMIER Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8282_1_1.PNG',
        status: 1,
    },
    {
        id: 8289,
        name: 'California Business Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8289_1_1.PNG',
        status: 1,
    },
    {
        id: 8290,
        name: 'United Heritage CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8290_1_1.PNG',
        status: 1,
    },
    {
        id: 8291,
        name: 'First Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8291_1_1.PNG',
        status: 1,
    },
    {
        id: 8293,
        name: 'REV Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8293_1_1.PNG',
        status: 1,
    },
    {
        id: 8294,
        name: 'Northeast Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8294_1_1.PNG',
        status: 1,
    },
    {
        id: 8296,
        name: 'Metro Credit Union (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8296_1_1.PNG',
        status: 1,
    },
    {
        id: 8303,
        name: 'Mid-Hudson Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8303_1_1.PNG',
        status: 1,
    },
    {
        id: 8318,
        name: 'Watertown Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8318_1_1.PNG',
        status: 1,
    },
    {
        id: 8330,
        name: 'Scott Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8330_1_1.PNG',
        status: 1,
    },
    {
        id: 8341,
        name: 'Callaway Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8342,
        name: 'Leominster Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8342_1_1.PNG',
        status: 1,
    },
    {
        id: 8343,
        name: 'Seven Seventeen Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8343_1_1.PNG',
        status: 1,
    },
    {
        id: 8345,
        name: 'Great Plains Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8345_1_1.PNG',
        status: 1,
    },
    {
        id: 8346,
        name: 'Navy Army Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8346_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 8347,
        name: 'CU of Denver',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8347_1_1.PNG',
        status: 1,
    },
    {
        id: 8350,
        name: 'Regent Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8350_1_2.SVG',
        status: 1,
    },
    {
        id: 8356,
        name: 'Community Trust Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8356_1_1.PNG',
        status: 1,
    },
    {
        id: 8357,
        name: 'Granite State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8357_1_1.PNG',
        status: 1,
    },
    {
        id: 8365,
        name: 'Hickam Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8365_1_1.PNG',
        status: 1,
    },
    {
        id: 8366,
        name: 'Greenwood Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8366_1_1.PNG',
        status: 1,
    },
    {
        id: 8369,
        name: 'Central Pacific Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8371,
        name: 'Adams County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8371_1_1.PNG',
        status: 1,
    },
    {
        id: 8372,
        name: 'Wyoming Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8372_1_1.PNG',
        status: 1,
    },
    {
        id: 8375,
        name: 'Central Bank (KY & WA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8375_1_1.PNG',
        status: 1,
    },
    {
        id: 8387,
        name: 'Chemical Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8387_1_1.PNG',
        status: 1,
    },
    {
        id: 8388,
        name: 'Waukesha State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8388_1_1.PNG',
        status: 1,
    },
    {
        id: 8391,
        name: 'Bank of Castile',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8391_1_1.PNG',
        status: 1,
    },
    {
        id: 8395,
        name: 'Citizens Business Bank (Personal Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8395_1_1.PNG',
        status: 1,
    },
    {
        id: 8397,
        name: 'Webster Five Cents Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8397_1_1.PNG',
        status: 1,
    },
    {
        id: 8398,
        name: 'German American Bancorp',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8398_1_1.PNG',
        status: 1,
    },
    {
        id: 8401,
        name: 'PNC Bank (Small Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8401_1_1.PNG',
        status: 1,
    },
    {
        id: 8403,
        name: 'Amarillo National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8403_1_1.PNG',
        status: 1,
    },
    {
        id: 8404,
        name: 'Central Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8404_1_1.PNG',
        status: 1,
    },
    {
        id: 8406,
        name: 'AmeriServ Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8406_1_1.PNG',
        status: 1,
    },
    {
        id: 8414,
        name: 'First Midwest Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8414_1_1.PNG',
        status: 1,
    },
    {
        id: 8415,
        name: 'Farmers & Merchants Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8415_1_1.PNG',
        status: 1,
    },
    {
        id: 8416,
        name: 'First Merchants Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8416_1_1.PNG',
        status: 1,
    },
    {
        id: 8422,
        name: 'Central Illinois Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8422_1_1.PNG',
        status: 1,
    },
    {
        id: 8432,
        name: 'Westbury Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8432_1_1.PNG',
        status: 1,
    },
    {
        id: 8439,
        name: 'First Financial Bank (IN, IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8439_1_1.PNG',
        status: 1,
    },
    {
        id: 8440,
        name: 'Whitaker Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8440_1_1.PNG',
        status: 1,
    },
    {
        id: 8441,
        name: 'Farmers & Merchants Bank of Central California',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8441_1_1.PNG',
        status: 1,
    },
    {
        id: 8448,
        name: 'First Citizens State Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8448_1_1.PNG',
        status: 1,
    },
    {
        id: 8456,
        name: 'First National Bank of Newtown',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8456_1_1.PNG',
        status: 1,
    },
    {
        id: 8457,
        name: 'Westfield Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8457_1_1.PNG',
        status: 1,
    },
    {
        id: 8489,
        name: 'Sterling National Bank(eBusiness)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8489_1_2.SVG',
        status: 1,
    },
    {
        id: 8613,
        name: 'Wheelhouse Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8613_1_1.PNG',
        status: 1,
    },
    {
        id: 8615,
        name: 'USE CU (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8615_1_1.PNG',
        status: 1,
    },
    {
        id: 8633,
        name: 'Citizens Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8633_1_1.PNG',
        status: 1,
    },
    {
        id: 8687,
        name: 'Catholic Vantage Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8687_1_1.PNG',
        status: 1,
    },
    {
        id: 8690,
        name: 'Kemba Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8690_1_1.PNG',
        status: 1,
    },
    {
        id: 8699,
        name: 'First Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8728,
        name: 'San Mateo Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8728_1_1.PNG',
        status: 1,
    },
    {
        id: 8740,
        name: 'University of Toledo Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8740_1_1.PNG',
        status: 1,
    },
    {
        id: 8755,
        name: 'Farmers National Bank (ID)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8770,
        name: 'United Community Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8770_1_1.PNG',
        status: 1,
    },
    {
        id: 8780,
        name: 'LGE Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8780_1_1.PNG',
        status: 1,
    },
    {
        id: 8781,
        name: 'Space Coast Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8781_1_2.SVG',
        status: 1,
    },
    {
        id: 8822,
        name: 'Union State Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8822_1_1.PNG',
        status: 1,
    },
    {
        id: 8827,
        name: 'VyStar Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8827_1_1.PNG',
        status: 1,
    },
    {
        id: 8847,
        name: 'Firstrust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8847_1_1.PNG',
        status: 1,
    },
    {
        id: 8848,
        name: 'Kitsap Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8848_1_1.PNG',
        status: 1,
    },
    {
        id: 8852,
        name: 'McCoy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8873,
        name: 'Westamerica Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8873_1_1.PNG',
        status: 1,
    },
    {
        id: 8881,
        name: 'The Paducah Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8881_1_1.PNG',
        status: 1,
    },
    {
        id: 8894,
        name: 'Tucson Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8894_1_1.PNG',
        status: 1,
    },
    {
        id: 8898,
        name: 'CoastHills Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8898_1_1.PNG',
        status: 1,
    },
    {
        id: 8899,
        name: 'NARFE Premier Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8899_1_1.PNG',
        status: 1,
    },
    {
        id: 8900,
        name: 'Foothills Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8900_1_1.PNG',
        status: 1,
    },
    {
        id: 8908,
        name: 'First National Bank of Colorado',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8908_1_1.PNG',
        status: 1,
    },
    {
        id: 8919,
        name: 'Hershey Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8919_1_1.PNG',
        status: 1,
    },
    {
        id: 8954,
        name: 'Southern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8954_1_1.PNG',
        status: 1,
    },
    {
        id: 8966,
        name: 'Cornerstone Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8966_1_1.PNG',
        status: 1,
    },
    {
        id: 8969,
        name: 'Metro Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8969_1_1.PNG',
        status: 1,
    },
    {
        id: 8970,
        name: 'First National Bank of North East',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 8979,
        name: 'Maps Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8979_1_1.PNG',
        status: 1,
    },
    {
        id: 8982,
        name: 'Minnesota Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8982_1_1.PNG',
        status: 1,
    },
    {
        id: 8987,
        name: 'USC Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8987_1_1.PNG',
        status: 1,
    },
    {
        id: 8988,
        name: 'WoodTrust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8988_1_1.PNG',
        status: 1,
    },
    {
        id: 8989,
        name: 'Unitus Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_8989_1_1.PNG',
        status: 1,
    },
    {
        id: 9032,
        name: 'Bank Center First',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 9048,
        name: 'Bank of Clarke County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9048_1_1.PNG',
        status: 1,
    },
    {
        id: 9105,
        name: 'Partners Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9105_1_2.SVG',
        status: 1,
    },
    {
        id: 9125,
        name: 'NorthCountry Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9125_1_1.PNG',
        status: 1,
    },
    {
        id: 9132,
        name: 'Union Bank & Trust (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9132_1_1.PNG',
        status: 1,
    },
    {
        id: 9136,
        name: 'University of Michigan Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9136_1_1.PNG',
        status: 1,
    },
    {
        id: 9150,
        name: 'York State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9150_1_1.PNG',
        status: 1,
    },
    {
        id: 9161,
        name: 'Uwharrie Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9161_1_1.PNG',
        status: 1,
    },
    {
        id: 9172,
        name: 'DuPont Goodrich Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9172_1_1.PNG',
        status: 1,
    },
    {
        id: 9178,
        name: 'Hawaii State Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9178_1_1.PNG',
        status: 1,
    },
    {
        id: 9192,
        name: 'CAMPUS USA Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9192_1_1.PNG',
        status: 1,
    },
    {
        id: 9197,
        name: 'FEDEX Employees Credit Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9197_1_1.PNG',
        status: 1,
    },
    {
        id: 9206,
        name: 'Lake Forest Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9206_1_1.PNG',
        status: 1,
    },
    {
        id: 9209,
        name: 'First Bankers Trust (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9209_1_1.PNG',
        status: 1,
    },
    {
        id: 9212,
        name: 'Union Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9212_1_1.PNG',
        status: 1,
    },
    {
        id: 9218,
        name: 'First National Bank of Fort Smith',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9218_1_1.PNG',
        status: 1,
    },
    {
        id: 9228,
        name: 'Novartis Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9228_1_1.PNG',
        status: 1,
    },
    {
        id: 9238,
        name: 'Community First Credit Union (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9238_1_1.PNG',
        status: 1,
    },
    {
        id: 9252,
        name: 'Territorial Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9252_1_1.PNG',
        status: 1,
    },
    {
        id: 9274,
        name: 'Ford Interest Advantage',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9274_1_1.PNG',
        status: 1,
    },
    {
        id: 9293,
        name: 'Alabama Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9293_1_1.PNG',
        status: 1,
    },
    {
        id: 9294,
        name: 'Evergreen National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9294_1_1.PNG',
        status: 1,
    },
    {
        id: 9312,
        name: 'First Advantage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 9333,
        name: 'CB&S Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9333_1_1.PNG',
        status: 1,
    },
    {
        id: 9338,
        name: 'Aberdeen Proving Ground Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9338_1_1.PNG',
        status: 1,
    },
    {
        id: 9392,
        name: 'NorthStar Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9392_1_1.PNG',
        status: 1,
    },
    {
        id: 9396,
        name: 'Lake Michigan Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9396_1_2.SVG',
        status: 1,
    },
    {
        id: 9417,
        name: 'Dime',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9417_1_1.PNG',
        status: 1,
    },
    {
        id: 9418,
        name: 'Citadel Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9418_1_1.PNG',
        status: 1,
    },
    {
        id: 9447,
        name: 'Lansing Automakers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9447_1_1.PNG',
        status: 1,
    },
    {
        id: 9471,
        name: 'Artisans Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9471_1_1.PNG',
        status: 1,
    },
    {
        id: 9482,
        name: 'Bridge Bank Personal Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9482_1_1.PNG',
        status: 1,
    },
    {
        id: 9483,
        name: 'Bridge Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9483_1_1.PNG',
        status: 1,
    },
    {
        id: 9496,
        name: 'Georgia United Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9496_1_1.PNG',
        status: 1,
    },
    {
        id: 9514,
        name: 'First Foundation Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 9524,
        name: 'Dow Great Western Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9524_1_1.PNG',
        status: 1,
    },
    {
        id: 9552,
        name: 'Waggoner National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9552_1_1.PNG',
        status: 1,
    },
    {
        id: 9565,
        name: 'Ally Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9565_1_2.SVG',
        status: 1,
    },
    {
        id: 9583,
        name: 'Sierra Central CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9583_1_1.PNG',
        status: 1,
    },
    {
        id: 9595,
        name: 'Hinsdale Bank and Trust Co',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9595_1_1.PNG',
        status: 1,
    },
    {
        id: 9596,
        name: 'State Department Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9596_1_1.PNG',
        status: 1,
    },
    {
        id: 9614,
        name: 'UW Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9614_1_2.SVG',
        status: 1,
    },
    {
        id: 9635,
        name: 'Ulster Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9635_1_1.PNG',
        status: 1,
    },
    {
        id: 9655,
        name: 'Synchrony Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9655_1_2.SVG',
        status: 1,
    },
    {
        id: 9702,
        name: 'Apple Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9702_1_1.PNG',
        status: 1,
    },
    {
        id: 9708,
        name: 'State Employees CU (Maryland)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9708_1_2.SVG',
        status: 1,
    },
    {
        id: 9721,
        name: 'Integrity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9721_1_1.PNG',
        status: 1,
    },
    {
        id: 9722,
        name: 'Customers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9722_1_1.PNG',
        status: 1,
    },
    {
        id: 9739,
        name: 'First Neighbor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9739_1_1.PNG',
        status: 1,
    },
    {
        id: 9743,
        name: 'Tradition Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9743_1_1.PNG',
        status: 1,
    },
    {
        id: 9747,
        name: 'First State Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9747_1_1.PNG',
        status: 1,
    },
    {
        id: 9769,
        name: 'NorthSide Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9769_1_1.PNG',
        status: 1,
    },
    {
        id: 9771,
        name: 'Michigan State University Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9771_1_2.SVG',
        status: 1,
    },
    {
        id: 9787,
        name: 'Five Star Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9787_1_1.PNG',
        status: 1,
    },
    {
        id: 9792,
        name: 'DoD Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9792_1_2.SVG',
        status: 1,
    },
    {
        id: 9800,
        name: 'Fort Sill National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9800_1_1.PNG',
        status: 1,
    },
    {
        id: 9804,
        name: 'Great Falls Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9804_1_1.PNG',
        status: 1,
    },
    {
        id: 9812,
        name: 'Financial Resources Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9812_1_1.PNG',
        status: 1,
    },
    {
        id: 9824,
        name: 'American Eagle Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9824_1_1.PNG',
        status: 1,
    },
    {
        id: 9832,
        name: 'F&M Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9832_1_1.PNG',
        status: 1,
    },
    {
        id: 9840,
        name: 'Kern Schools Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9840_1_1.PNG',
        status: 1,
    },
    {
        id: 9843,
        name: 'Kansas City Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9843_1_1.PNG',
        status: 1,
    },
    {
        id: 9884,
        name: 'Texas Trust Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9884_1_1.PNG',
        status: 1,
    },
    {
        id: 9887,
        name: 'USAlliance Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9887_1_1.PNG',
        status: 1,
    },
    {
        id: 9889,
        name: 'First National 1870',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9889_1_1.PNG',
        status: 1,
    },
    {
        id: 9894,
        name: 'Peoples Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9894_1_1.PNG',
        status: 1,
    },
    {
        id: 9901,
        name: 'Amboy Direct Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9901_1_1.PNG',
        status: 1,
    },
    {
        id: 9910,
        name: 'Bragg Mutual Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 9927,
        name: 'Credit Union of Georgia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9927_1_1.PNG',
        status: 1,
    },
    {
        id: 9928,
        name: 'Buckeye State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9928_1_1.PNG',
        status: 1,
    },
    {
        id: 9929,
        name: 'Safe 1 Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9929_1_1.PNG',
        status: 1,
    },
    {
        id: 9930,
        name: 'American First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9930_1_1.PNG',
        status: 1,
    },
    {
        id: 9933,
        name: 'Tri City National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9933_1_1.PNG',
        status: 1,
    },
    {
        id: 9934,
        name: 'WSFS Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9934_1_1.PNG',
        status: 1,
    },
    {
        id: 9935,
        name: 'Home Savings and Loan Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9935_1_1.PNG',
        status: 1,
    },
    {
        id: 9960,
        name: 'Morton Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9960_1_1.PNG',
        status: 1,
    },
    {
        id: 9962,
        name: 'North Shore Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9962_1_1.PNG',
        status: 1,
    },
    {
        id: 9966,
        name: 'University of Utah Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9966_1_1.PNG',
        status: 1,
    },
    {
        id: 9972,
        name: 'Bank Midwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9972_1_1.PNG',
        status: 1,
    },
    {
        id: 9973,
        name: 'Houston Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9973_1_1.PNG',
        status: 1,
    },
    {
        id: 9977,
        name: 'USAgencies Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9977_1_1.PNG',
        status: 1,
    },
    {
        id: 9980,
        name: 'iQ Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9980_1_1.PNG',
        status: 1,
    },
    {
        id: 9986,
        name: 'ISU CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9986_1_1.PNG',
        status: 1,
    },
    {
        id: 9989,
        name: 'Local Government Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_9989_1_1.PNG',
        status: 1,
    },
    {
        id: 10013,
        name: 'St. Lucie Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 10039,
        name: 'First Midwest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10039_1_1.PNG',
        status: 1,
    },
    {
        id: 10068,
        name: 'Meritrust Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10068_1_1.PNG',
        status: 1,
    },
    {
        id: 10075,
        name: 'Coconut Grove Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10075_1_1.PNG',
        status: 1,
    },
    {
        id: 10081,
        name: 'Northfield Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10081_1_1.PNG',
        status: 1,
    },
    {
        id: 10083,
        name: 'Bell Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10083_1_2.SVG',
        status: 1,
    },
    {
        id: 10090,
        name: 'Bridgehampton National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10090_1_1.PNG',
        status: 1,
    },
    {
        id: 10096,
        name: 'Carolinas Telco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10096_1_1.PNG',
        status: 1,
    },
    {
        id: 10097,
        name: 'UFB Direct',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10097_1_1.PNG',
        status: 1,
    },
    {
        id: 10101,
        name: 'Indiana Members Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10101_1_1.PNG',
        status: 1,
    },
    {
        id: 10102,
        name: 'Collins Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10102_1_1.PNG',
        status: 1,
    },
    {
        id: 10104,
        name: 'Monona State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10104_1_1.PNG',
        status: 1,
    },
    {
        id: 10107,
        name: 'The First National Bank of Ipswich',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10107_1_1.PNG',
        status: 1,
    },
    {
        id: 10108,
        name: 'Self-Help Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10108_1_1.PNG',
        status: 1,
    },
    {
        id: 10112,
        name: 'Sandia Laboratory Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10112_1_1.PNG',
        status: 1,
    },
    {
        id: 10115,
        name: 'Duke University Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10115_1_1.PNG',
        status: 1,
    },
    {
        id: 10116,
        name: 'Northwest Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10116_1_2.SVG',
        status: 1,
    },
    {
        id: 10119,
        name: 'Renasant Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10119_1_1.PNG',
        status: 1,
    },
    {
        id: 10121,
        name: 'Credit Union West',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 10125,
        name: 'Cinfed Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10125_1_1.PNG',
        status: 1,
    },
    {
        id: 10126,
        name: "St. Mary's Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10126_1_1.PNG',
        status: 1,
    },
    {
        id: 10129,
        name: 'Chartway Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10129_1_1.PNG',
        status: 1,
    },
    {
        id: 10131,
        name: 'Kirtland FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10131_1_1.PNG',
        status: 1,
    },
    {
        id: 10138,
        name: 'The National Bank of Indianapolis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10138_1_1.PNG',
        status: 1,
    },
    {
        id: 10144,
        name: 'UMassFive',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10144_1_1.PNG',
        status: 1,
    },
    {
        id: 10149,
        name: 'Altra Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10149_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 10171,
        name: 'First Commonwealth Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10171_1_1.PNG',
        status: 1,
    },
    {
        id: 10176,
        name: 'Merck Sharp & Dohme Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10176_1_1.PNG',
        status: 1,
    },
    {
        id: 10177,
        name: 'Fairfield National Bank (OH) - Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10177_1_1.PNG',
        status: 1,
    },
    {
        id: 10180,
        name: 'Iowa State Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10180_1_1.PNG',
        status: 1,
    },
    {
        id: 10211,
        name: 'Centennial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10211_1_1.PNG',
        status: 1,
    },
    {
        id: 10212,
        name: 'Citizens First Bank (FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10212_1_1.PNG',
        status: 1,
    },
    {
        id: 10214,
        name: 'KBR Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10214_1_1.PNG',
        status: 1,
    },
    {
        id: 10215,
        name: 'Bank of Tampa',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10215_1_1.PNG',
        status: 1,
    },
    {
        id: 10222,
        name: 'Sharonview Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10222_1_1.PNG',
        status: 1,
    },
    {
        id: 10240,
        name: 'First Sound Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10240_1_1.PNG',
        status: 1,
    },
    {
        id: 10244,
        name: 'Nutmeg State Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10244_1_1.PNG',
        status: 1,
    },
    {
        id: 10254,
        name: 'Utah Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10254_1_1.PNG',
        status: 1,
    },
    {
        id: 10273,
        name: 'First Community Bank (NC, TN, VA, WV, SC, FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10273_1_1.PNG',
        status: 1,
    },
    {
        id: 10298,
        name: 'North Shore Trust and Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10298_1_1.PNG',
        status: 1,
    },
    {
        id: 10300,
        name: 'OneUnited Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10300_1_1.PNG',
        status: 1,
    },
    {
        id: 10301,
        name: 'New Market Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10301_1_1.PNG',
        status: 1,
    },
    {
        id: 10311,
        name: 'Savings Bank of Mendocino County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10311_1_1.PNG',
        status: 1,
    },
    {
        id: 10344,
        name: 'Citizens Bank & Trust (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10344_1_1.PNG',
        status: 1,
    },
    {
        id: 10394,
        name: 'Icon Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10394_1_1.PNG',
        status: 1,
    },
    {
        id: 10395,
        name: 'First Texoma National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10395_1_1.PNG',
        status: 1,
    },
    {
        id: 10416,
        name: 'SunTrust Online Cash Manager',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10416_1_2.SVG',
        status: 1,
    },
    {
        id: 10467,
        name: "Seamen's Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10467_1_1.PNG',
        status: 1,
    },
    {
        id: 10469,
        name: 'Americana Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10469_1_1.PNG',
        status: 1,
    },
    {
        id: 10471,
        name: 'Pacific Service Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10471_1_1.PNG',
        status: 1,
    },
    {
        id: 10472,
        name: 'Redwood Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10472_1_1.PNG',
        status: 1,
    },
    {
        id: 10476,
        name: 'iGObanking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10476_1_1.PNG',
        status: 1,
    },
    {
        id: 10481,
        name: 'First Security Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10481_1_1.PNG',
        status: 1,
    },
    {
        id: 10482,
        name: 'Horizon Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10482_1_1.PNG',
        status: 1,
    },
    {
        id: 10500,
        name: 'Big Sky Western Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10500_1_1.PNG',
        status: 1,
    },
    {
        id: 10502,
        name: 'Parkway Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10502_1_1.PNG',
        status: 1,
    },
    {
        id: 10509,
        name: 'Northwest Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10509_1_1.PNG',
        status: 1,
    },
    {
        id: 10512,
        name: 'Signature Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10512_1_1.PNG',
        status: 1,
    },
    {
        id: 10515,
        name: 'ABE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10515_1_1.PNG',
        status: 1,
    },
    {
        id: 10517,
        name: 'Justice Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10517_1_1.PNG',
        status: 1,
    },
    {
        id: 10520,
        name: 'Reliance Bank (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10520_1_1.PNG',
        status: 1,
    },
    {
        id: 10534,
        name: 'Day Air Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10534_1_1.PNG',
        status: 1,
    },
    {
        id: 10543,
        name: 'First Westroads Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10543_1_1.PNG',
        status: 1,
    },
    {
        id: 10544,
        name: 'Village Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10544_1_1.PNG',
        status: 1,
    },
    {
        id: 10545,
        name: 'CFCU Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10545_1_1.PNG',
        status: 1,
    },
    {
        id: 10547,
        name: 'Kentucky Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10547_1_1.PNG',
        status: 1,
    },
    {
        id: 10552,
        name: 'The Citizens Bank of Swainsboro & Laurens County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10552_1_1.PNG',
        status: 1,
    },
    {
        id: 10554,
        name: 'KEMBA Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10554_1_1.PNG',
        status: 1,
    },
    {
        id: 10558,
        name: 'Univest (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10558_1_1.PNG',
        status: 1,
    },
    {
        id: 10562,
        name: 'Pinnacle Financial Partners',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10562_1_1.PNG',
        status: 1,
    },
    {
        id: 10572,
        name: 'Elkhorn Valley Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10572_1_1.PNG',
        status: 1,
    },
    {
        id: 10577,
        name: 'State Bank of Cochran',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10577_1_1.PNG',
        status: 1,
    },
    {
        id: 10586,
        name: 'Malvern Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10586_1_1.PNG',
        status: 1,
    },
    {
        id: 10594,
        name: 'GreenState Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10594_1_1.PNG',
        status: 1,
    },
    {
        id: 10604,
        name: 'Tri Counties Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10604_1_1.PNG',
        status: 1,
    },
    {
        id: 10612,
        name: 'Elevations Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10612_1_1.PNG',
        status: 1,
    },
    {
        id: 10628,
        name: 'First American Bank (IA & FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10628_1_1.PNG',
        status: 1,
    },
    {
        id: 10631,
        name: 'University CU (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10631_1_1.PNG',
        status: 1,
    },
    {
        id: 10637,
        name: 'Amegy Bank Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10637_1_1.PNG',
        status: 1,
    },
    {
        id: 10640,
        name: 'MECU Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10640_1_1.PNG',
        status: 1,
    },
    {
        id: 10645,
        name: 'Millennium Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10645_1_1.PNG',
        status: 1,
    },
    {
        id: 10646,
        name: 'First National Bank Southwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10646_1_1.PNG',
        status: 1,
    },
    {
        id: 10648,
        name: 'Garden Savings Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10648_1_1.PNG',
        status: 1,
    },
    {
        id: 10649,
        name: 'Easthampton Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10649_1_1.PNG',
        status: 1,
    },
    {
        id: 10650,
        name: 'SouthSide Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10650_1_1.PNG',
        status: 1,
    },
    {
        id: 10651,
        name: 'First Bank (AK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10651_1_1.PNG',
        status: 1,
    },
    {
        id: 10653,
        name: 'Indiana University Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10653_1_1.PNG',
        status: 1,
    },
    {
        id: 10669,
        name: 'First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10669_1_1.PNG',
        status: 1,
    },
    {
        id: 10681,
        name: 'Pathways Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10681_1_1.PNG',
        status: 1,
    },
    {
        id: 10697,
        name: 'CommunityAmerica Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10697_1_1.PNG',
        status: 1,
    },
    {
        id: 10698,
        name: 'CIBC Bank USA (Business Net Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10698_1_1.PNG',
        status: 1,
    },
    {
        id: 10710,
        name: 'Discover Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10710_1_2.SVG',
        status: 1,
    },
    {
        id: 10719,
        name: 'Desert Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10719_1_1.PNG',
        status: 1,
    },
    {
        id: 10739,
        name: 'TwinStar Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10739_1_1.PNG',
        status: 1,
    },
    {
        id: 10762,
        name: 'SAFE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10762_1_1.PNG',
        status: 1,
    },
    {
        id: 10770,
        name: 'CitiBusiness Online',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10770_1_1.PNG',
        status: 1,
    },
    {
        id: 10780,
        name: 'BMI Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10780_1_1.PNG',
        status: 1,
    },
    {
        id: 10781,
        name: 'South County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10781_1_1.PNG',
        status: 1,
    },
    {
        id: 10785,
        name: 'Ally',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10785_1_1.PNG',
        status: 1,
    },
    {
        id: 10805,
        name: 'Bremer Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10805_1_2.SVG',
        status: 1,
    },
    {
        id: 10808,
        name: 'Chaffey Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10808_1_1.PNG',
        status: 1,
    },
    {
        id: 10809,
        name: 'West Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10809_1_1.PNG',
        status: 1,
    },
    {
        id: 10813,
        name: 'Verity Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10813_1_1.PNG',
        status: 1,
    },
    {
        id: 10814,
        name: 'Sonora Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10814_1_1.PNG',
        status: 1,
    },
    {
        id: 10819,
        name: 'Carolina Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10819_1_1.PNG',
        status: 1,
    },
    {
        id: 10820,
        name: 'Members First Credit Union of NH',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10820_1_1.PNG',
        status: 1,
    },
    {
        id: 10829,
        name: 'Capitol Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10829_1_1.PNG',
        status: 1,
    },
    {
        id: 10831,
        name: 'Spectrum CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10831_1_1.PNG',
        status: 1,
    },
    {
        id: 10832,
        name: 'Arrowhead Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10832_1_1.PNG',
        status: 1,
    },
    {
        id: 10844,
        name: 'State Bank & Trust Company (MS, AL, LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10844_1_1.PNG',
        status: 1,
    },
    {
        id: 10857,
        name: 'FNBO Direct',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10857_1_1.PNG',
        status: 1,
    },
    {
        id: 10875,
        name: 'Picatinny Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10875_1_1.PNG',
        status: 1,
    },
    {
        id: 10891,
        name: 'Gibraltar Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10891_1_1.PNG',
        status: 1,
    },
    {
        id: 10892,
        name: 'Police and Fire Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10892_1_1.PNG',
        status: 1,
    },
    {
        id: 10898,
        name: 'First Financial Credit Union (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10898_1_1.PNG',
        status: 1,
    },
    {
        id: 10900,
        name: 'Sturgis Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10900_1_1.PNG',
        status: 1,
    },
    {
        id: 10906,
        name: 'United Business Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10906_1_1.PNG',
        status: 1,
    },
    {
        id: 10907,
        name: 'Nevada State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10907_1_1.PNG',
        status: 1,
    },
    {
        id: 10916,
        name: 'Profinium Inc',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10916_1_1.PNG',
        status: 1,
    },
    {
        id: 10919,
        name: 'FRB Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10919_1_1.PNG',
        status: 1,
    },
    {
        id: 10974,
        name: 'Liberty Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10974_1_1.PNG',
        status: 1,
    },
    {
        id: 10992,
        name: 'First National Bank of Vandalia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10992_1_1.PNG',
        status: 1,
    },
    {
        id: 10998,
        name: 'Mission Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_10998_1_1.PNG',
        status: 1,
    },
    {
        id: 11019,
        name: 'Signature Bank of Arkansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11019_1_1.PNG',
        status: 1,
    },
    {
        id: 11065,
        name: 'Xceed Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11065_1_1.PNG',
        status: 1,
    },
    {
        id: 11068,
        name: 'American Bank & Trust Company (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11068_1_1.PNG',
        status: 1,
    },
    {
        id: 11140,
        name: 'The Lake Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11140_1_1.PNG',
        status: 1,
    },
    {
        id: 11145,
        name: 'T & I Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 11148,
        name: 'First National Bank of St. Louis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11148_1_1.PNG',
        status: 1,
    },
    {
        id: 11151,
        name: 'Bank Hapoalim USA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11151_1_1.PNG',
        status: 1,
    },
    {
        id: 11152,
        name: 'First Community Credit Union (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11152_1_1.PNG',
        status: 1,
    },
    {
        id: 11156,
        name: 'State Bank Of Toledo',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11156_1_1.PNG',
        status: 1,
    },
    {
        id: 11158,
        name: 'Watertown Savings Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11158_1_1.PNG',
        status: 1,
    },
    {
        id: 11162,
        name: 'Staley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11162_1_1.PNG',
        status: 1,
    },
    {
        id: 11164,
        name: 'Washington Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11164_1_1.PNG',
        status: 1,
    },
    {
        id: 11175,
        name: 'Evangelical Christian Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11175_1_1.PNG',
        status: 1,
    },
    {
        id: 11212,
        name: 'Amalgamated Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11212_1_1.PNG',
        status: 1,
    },
    {
        id: 11227,
        name: 'TrustBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11227_1_1.PNG',
        status: 1,
    },
    {
        id: 11248,
        name: 'K-State FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11248_1_1.PNG',
        status: 1,
    },
    {
        id: 11251,
        name: 'Capital Bank (MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11251_1_1.PNG',
        status: 1,
    },
    {
        id: 11265,
        name: 'CIBC Bank USA (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11265_1_1.PNG',
        status: 1,
    },
    {
        id: 11276,
        name: 'SYNERGY Federal Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11276_1_1.PNG',
        status: 1,
    },
    {
        id: 11278,
        name: 'MassMutual Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11278_1_1.PNG',
        status: 1,
    },
    {
        id: 11279,
        name: 'Sun Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11279_1_1.PNG',
        status: 1,
    },
    {
        id: 11301,
        name: 'Home State Bank (NA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11301_1_1.PNG',
        status: 1,
    },
    {
        id: 11352,
        name: 'BayPort Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11352_1_1.PNG',
        status: 1,
    },
    {
        id: 11358,
        name: 'Advantage Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11358_1_1.PNG',
        status: 1,
    },
    {
        id: 11363,
        name: 'Farmers & Merchants Bank (CA - Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11363_1_1.PNG',
        status: 1,
    },
    {
        id: 11374,
        name: 'mobank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11374_1_1.PNG',
        status: 1,
    },
    {
        id: 11378,
        name: 'Freedom CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11378_1_1.PNG',
        status: 1,
    },
    {
        id: 11385,
        name: 'Happy State Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11385_1_1.PNG',
        status: 1,
    },
    {
        id: 11386,
        name: 'Washington Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11386_1_1.PNG',
        status: 1,
    },
    {
        id: 11389,
        name: 'Fairfield County Bank (CT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11389_1_1.PNG',
        status: 1,
    },
    {
        id: 11394,
        name: 'Manasquan Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11394_1_1.PNG',
        status: 1,
    },
    {
        id: 11401,
        name: 'AnchorBank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11401_1_1.PNG',
        status: 1,
    },
    {
        id: 11425,
        name: 'Heritage Bank of Nevada',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11425_1_1.PNG',
        status: 1,
    },
    {
        id: 11431,
        name: 'Oklahoma National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 11436,
        name: 'First United Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11436_1_1.PNG',
        status: 1,
    },
    {
        id: 11443,
        name: 'Progressive Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11443_1_1.PNG',
        status: 1,
    },
    {
        id: 11450,
        name: 'Ledge Light Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11450_1_1.PNG',
        status: 1,
    },
    {
        id: 11457,
        name: 'Sumner Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11457_1_1.PNG',
        status: 1,
    },
    {
        id: 11460,
        name: 'Sun East Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11460_1_1.PNG',
        status: 1,
    },
    {
        id: 11468,
        name: 'Palmetto State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11468_1_1.PNG',
        status: 1,
    },
    {
        id: 11475,
        name: 'Welcome Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11475_1_1.PNG',
        status: 1,
    },
    {
        id: 11476,
        name: 'WESTconsin Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11476_1_1.PNG',
        status: 1,
    },
    {
        id: 11477,
        name: 'West Gate Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11477_1_1.PNG',
        status: 1,
    },
    {
        id: 11479,
        name: 'Central Bank of Lake of the Ozarks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11479_1_1.PNG',
        status: 1,
    },
    {
        id: 11482,
        name: 'Addition Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11482_1_1.PNG',
        status: 1,
    },
    {
        id: 11485,
        name: 'First Western Trust Bank (iWealth)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11485_1_1.PNG',
        status: 1,
    },
    {
        id: 11486,
        name: 'Washington Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11486_1_1.PNG',
        status: 1,
    },
    {
        id: 11489,
        name: 'First National Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11489_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 11513,
        name: 'First Citizens Bank (Business Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11513_1_1.PNG',
        status: 1,
    },
    {
        id: 11524,
        name: 'Inwood National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11524_1_1.PNG',
        status: 1,
    },
    {
        id: 11531,
        name: 'SeCUrity National Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11531_1_1.PNG',
        status: 1,
    },
    {
        id: 11536,
        name: 'Ion Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11536_1_1.PNG',
        status: 1,
    },
    {
        id: 11548,
        name: 'Commercial Bank (TN & KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11548_1_1.PNG',
        status: 1,
    },
    {
        id: 11552,
        name: 'First Community Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11552_1_1.PNG',
        status: 1,
    },
    {
        id: 11553,
        name: 'BankSouth',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11553_1_1.PNG',
        status: 1,
    },
    {
        id: 11555,
        name: 'First State Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11555_1_1.PNG',
        status: 1,
    },
    {
        id: 11556,
        name: 'Town Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11556_1_1.PNG',
        status: 1,
    },
    {
        id: 11560,
        name: 'NexTier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11560_1_1.PNG',
        status: 1,
    },
    {
        id: 11567,
        name: 'Park National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11567_1_1.PNG',
        status: 1,
    },
    {
        id: 11569,
        name: 'Bank of Eastern Oregon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11569_1_1.PNG',
        status: 1,
    },
    {
        id: 11582,
        name: 'Park Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11582_1_1.PNG',
        status: 1,
    },
    {
        id: 11584,
        name: 'Hickory Point Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11584_1_1.PNG',
        status: 1,
    },
    {
        id: 11599,
        name: 'Bayer Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11599_1_1.PNG',
        status: 1,
    },
    {
        id: 11602,
        name: 'BankPlus',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11602_1_1.PNG',
        status: 1,
    },
    {
        id: 11623,
        name: 'First Community Bank of The Ozarks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11623_1_1.PNG',
        status: 1,
    },
    {
        id: 11625,
        name: 'First National Bank of Livingston',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11625_1_1.PNG',
        status: 1,
    },
    {
        id: 11627,
        name: 'First Federal Lakewood',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11627_1_1.PNG',
        status: 1,
    },
    {
        id: 11628,
        name: 'Campbell and Fetter Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11628_1_1.PNG',
        status: 1,
    },
    {
        id: 11629,
        name: 'Clackamas County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11629_1_1.PNG',
        status: 1,
    },
    {
        id: 11639,
        name: 'MinnWest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11639_1_1.PNG',
        status: 1,
    },
    {
        id: 11640,
        name: 'Richland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11640_1_1.PNG',
        status: 1,
    },
    {
        id: 11679,
        name: 'Signature Bank, N.A (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11679_1_1.PNG',
        status: 1,
    },
    {
        id: 11689,
        name: 'Mountain West Bank (ID)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11689_1_1.PNG',
        status: 1,
    },
    {
        id: 11700,
        name: '1st National Bank of Scotia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11700_1_1.PNG',
        status: 1,
    },
    {
        id: 11705,
        name: 'Bank Leumi USA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11705_1_1.PNG',
        status: 1,
    },
    {
        id: 11712,
        name: "Martha's Vineyard Savings Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 11718,
        name: 'UniBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11718_1_1.PNG',
        status: 1,
    },
    {
        id: 11720,
        name: 'SVB Direct Connect',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11720_1_1.PNG',
        status: 1,
    },
    {
        id: 11726,
        name: 'Amerant Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11726_1_2.SVG',
        status: 1,
    },
    {
        id: 11732,
        name: 'Ridgewood Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11732_1_1.PNG',
        status: 1,
    },
    {
        id: 11733,
        name: 'Charter Oak Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11733_1_1.PNG',
        status: 1,
    },
    {
        id: 11735,
        name: 'Knoxville TVA Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11735_1_1.PNG',
        status: 1,
    },
    {
        id: 11736,
        name: 'Together Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11736_1_1.PNG',
        status: 1,
    },
    {
        id: 11737,
        name: 'Vantage West Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11737_1_1.PNG',
        status: 1,
    },
    {
        id: 11739,
        name: 'University & Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11739_1_1.PNG',
        status: 1,
    },
    {
        id: 11740,
        name: 'Altura Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11740_1_1.PNG',
        status: 1,
    },
    {
        id: 11743,
        name: 'AmeriCU Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11743_1_1.PNG',
        status: 1,
    },
    {
        id: 11745,
        name: 'American National Bank of Texas (Treasury)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11745_1_1.PNG',
        status: 1,
    },
    {
        id: 11750,
        name: 'Texas Dow Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11750_1_1.PNG',
        status: 1,
    },
    {
        id: 11753,
        name: 'Cornhusker Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11753_1_1.PNG',
        status: 1,
    },
    {
        id: 11754,
        name: 'Hills Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11754_1_1.PNG',
        status: 1,
    },
    {
        id: 11757,
        name: 'Publix Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11757_1_1.PNG',
        status: 1,
    },
    {
        id: 11762,
        name: 'Keesler Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11762_1_2.SVG',
        status: 1,
    },
    {
        id: 11763,
        name: 'Tinker Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11763_1_2.SVG',
        status: 1,
    },
    {
        id: 11765,
        name: 'Ent CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11765_1_2.SVG',
        status: 'Beta',
    },
    {
        id: 11766,
        name: 'Enterprise Bank (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11766_1_1.PNG',
        status: 1,
    },
    {
        id: 11767,
        name: 'Educational Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11767_1_1.PNG',
        status: 1,
    },
    {
        id: 11768,
        name: 'The Peoples National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 11772,
        name: 'Seacoast National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11772_1_1.PNG',
        status: 1,
    },
    {
        id: 11787,
        name: 'Beach Municipal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11787_1_1.PNG',
        status: 1,
    },
    {
        id: 11788,
        name: 'Country Club Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11788_1_1.PNG',
        status: 1,
    },
    {
        id: 11792,
        name: 'TruMark Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11792_1_1.PNG',
        status: 1,
    },
    {
        id: 11797,
        name: 'EagleBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11797_1_1.PNG',
        status: 1,
    },
    {
        id: 11802,
        name: 'Bank of Milton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11802_1_1.PNG',
        status: 1,
    },
    {
        id: 11803,
        name: 'drbank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11803_1_1.PNG',
        status: 1,
    },
    {
        id: 11805,
        name: 'Heritage Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11805_1_1.PNG',
        status: 1,
    },
    {
        id: 11812,
        name: 'Premier Bank (Previously First Federal Bank of the Midwest)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11812_1_1.PNG',
        status: 1,
    },
    {
        id: 11813,
        name: 'New York Community Bank - Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11813_1_1.PNG',
        status: 1,
    },
    {
        id: 11824,
        name: 'Rockland Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11824_1_1.PNG',
        status: 1,
    },
    {
        id: 11826,
        name: 'First Federal Savings & Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11826_1_1.PNG',
        status: 1,
    },
    {
        id: 11828,
        name: 'First Financial Bank (OH, KY, IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11828_1_1.PNG',
        status: 1,
    },
    {
        id: 11835,
        name: 'Phoenixville Federal Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11835_1_1.PNG',
        status: 1,
    },
    {
        id: 11836,
        name: 'First-Knox National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11836_1_1.PNG',
        status: 1,
    },
    {
        id: 11837,
        name: 'NexBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11837_1_1.PNG',
        status: 1,
    },
    {
        id: 11859,
        name: 'Premier Members Federal Credit Union (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11859_1_1.PNG',
        status: 1,
    },
    {
        id: 11873,
        name: 'Alliance Catholic Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11873_1_1.PNG',
        status: 1,
    },
    {
        id: 11887,
        name: 'Flagship Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11887_1_1.PNG',
        status: 1,
    },
    {
        id: 11890,
        name: 'Cashmere Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11890_1_1.PNG',
        status: 1,
    },
    {
        id: 11936,
        name: 'First Bank VI',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11936_1_1.PNG',
        status: 1,
    },
    {
        id: 11940,
        name: 'Tobacco Valley Teachers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11940_1_1.PNG',
        status: 1,
    },
    {
        id: 11944,
        name: 'The First National Bank of Central Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11944_1_1.PNG',
        status: 1,
    },
    {
        id: 11965,
        name: 'First Bank Financial Centre',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11965_1_1.PNG',
        status: 1,
    },
    {
        id: 11969,
        name: 'Axos Bank for Nationwide',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11969_1_1.PNG',
        status: 1,
    },
    {
        id: 11978,
        name: 'First Horizon Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11978_1_1.PNG',
        status: 1,
    },
    {
        id: 11996,
        name: 'Independent Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_11996_1_2.SVG',
        status: 1,
    },
    {
        id: 12000,
        name: 'Century Next Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12000_1_2.SVG',
        status: 1,
    },
    {
        id: 12014,
        name: 'BankLiberty',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12014_1_1.PNG',
        status: 1,
    },
    {
        id: 12032,
        name: 'Nodaway Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12032_1_1.PNG',
        status: 1,
    },
    {
        id: 12033,
        name: 'Texas Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12033_1_1.PNG',
        status: 1,
    },
    {
        id: 12035,
        name: 'Windsor Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12035_1_1.PNG',
        status: 1,
    },
    {
        id: 12050,
        name: 'First Community Bank of Central Alabama',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12050_1_1.PNG',
        status: 1,
    },
    {
        id: 12054,
        name: 'First Guaranty Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12054_1_1.PNG',
        status: 1,
    },
    {
        id: 12055,
        name: 'Amplify Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12055_1_1.PNG',
        status: 1,
    },
    {
        id: 12058,
        name: 'WYMAR Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12058_1_1.PNG',
        status: 1,
    },
    {
        id: 12071,
        name: 'Denali State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12071_1_1.PNG',
        status: 1,
    },
    {
        id: 12082,
        name: 'Entegra Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12082_1_1.PNG',
        status: 1,
    },
    {
        id: 12083,
        name: 'Wheatland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12083_1_1.PNG',
        status: 1,
    },
    {
        id: 12084,
        name: 'Countybank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12084_1_1.PNG',
        status: 1,
    },
    {
        id: 12087,
        name: 'Capital Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12087_1_1.PNG',
        status: 1,
    },
    {
        id: 12104,
        name: 'Republic First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12104_1_1.PNG',
        status: 1,
    },
    {
        id: 12107,
        name: 'South Central Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12107_1_1.PNG',
        status: 1,
    },
    {
        id: 12111,
        name: 'FNB of Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12111_1_1.PNG',
        status: 1,
    },
    {
        id: 12120,
        name: 'Towne Bank (e-Biz Plus)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12120_1_1.PNG',
        status: 1,
    },
    {
        id: 12124,
        name: 'Sound Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12124_1_1.PNG',
        status: 1,
    },
    {
        id: 12141,
        name: 'Litchfield Bancorp',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12141_1_1.PNG',
        status: 1,
    },
    {
        id: 12147,
        name: 'Savings Square',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12147_1_1.PNG',
        status: 1,
    },
    {
        id: 12162,
        name: '1880 Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12162_1_1.PNG',
        status: 1,
    },
    {
        id: 12166,
        name: 'The First National Bank of Dieterich',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12166_1_1.PNG',
        status: 1,
    },
    {
        id: 12175,
        name: 'Eastern Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12175_1_1.PNG',
        status: 1,
    },
    {
        id: 12176,
        name: 'Chesapeake Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12176_1_1.PNG',
        status: 1,
    },
    {
        id: 12179,
        name: 'Bank of Nevada Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 12183,
        name: 'First Community Credit Union (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12183_1_1.PNG',
        status: 1,
    },
    {
        id: 12188,
        name: 'Republic Bank & Trust Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12188_1_1.PNG',
        status: 1,
    },
    {
        id: 12190,
        name: 'Honda Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12190_1_1.PNG',
        status: 1,
    },
    {
        id: 12191,
        name: 'Huntington Bank (Commercial)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12191_1_1.PNG',
        status: 1,
    },
    {
        id: 12193,
        name: 'United Community Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12193_1_1.PNG',
        status: 1,
    },
    {
        id: 12211,
        name: 'Mobility Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12211_1_1.PNG',
        status: 1,
    },
    {
        id: 12212,
        name: 'Armed Forces Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12212_1_1.PNG',
        status: 1,
    },
    {
        id: 12218,
        name: 'Central Bank of Midwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12218_1_1.PNG',
        status: 1,
    },
    {
        id: 12228,
        name: 'FNB Alaska',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12228_1_1.PNG',
        status: 1,
    },
    {
        id: 12237,
        name: 'Community First National Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 12293,
        name: 'Deutsche Bank (Private Wealth Online)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12293_1_1.PNG',
        status: 1,
    },
    {
        id: 12299,
        name: 'Harborstone Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12299_1_1.PNG',
        status: 1,
    },
    {
        id: 12301,
        name: 'First Madison Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12301_1_1.PNG',
        status: 1,
    },
    {
        id: 12323,
        name: 'Umpqua Bank (CA) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12323_1_1.PNG',
        status: 1,
    },
    {
        id: 12328,
        name: 'Peoples Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12328_1_1.PNG',
        status: 1,
    },
    {
        id: 12335,
        name: 'Vectra Bank (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12335_1_1.PNG',
        status: 1,
    },
    {
        id: 12352,
        name: 'ASE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12352_1_1.PNG',
        status: 1,
    },
    {
        id: 12360,
        name: 'Community Bank of Downers Grove',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12360_1_1.PNG',
        status: 1,
    },
    {
        id: 12370,
        name: 'Hantz Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12370_1_1.PNG',
        status: 1,
    },
    {
        id: 12372,
        name: 'Crockett National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12372_1_1.PNG',
        status: 1,
    },
    {
        id: 12380,
        name: 'Stock Growers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 12385,
        name: 'Commerce Bank of Arizona',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12385_1_1.PNG',
        status: 1,
    },
    {
        id: 12387,
        name: 'Neches Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12387_1_1.PNG',
        status: 1,
    },
    {
        id: 12398,
        name: 'Christian Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12398_1_1.PNG',
        status: 1,
    },
    {
        id: 12411,
        name: 'Kinecta Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12411_1_2.SVG',
        status: 1,
    },
    {
        id: 12412,
        name: 'Bank Midwest Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12412_1_1.PNG',
        status: 1,
    },
    {
        id: 12422,
        name: 'SELCO Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12422_1_1.PNG',
        status: 1,
    },
    {
        id: 12426,
        name: 'Nevada State Bank (Busines)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12426_1_1.PNG',
        status: 1,
    },
    {
        id: 12436,
        name: 'Trustmark National Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12436_1_1.PNG',
        status: 1,
    },
    {
        id: 12440,
        name: 'RiverBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12440_1_1.PNG',
        status: 1,
    },
    {
        id: 12442,
        name: 'Bank of Pontiac',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12442_1_1.PNG',
        status: 1,
    },
    {
        id: 12445,
        name: 'South Florida Educational Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12445_1_1.PNG',
        status: 1,
    },
    {
        id: 12446,
        name: 'Members First CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12446_1_1.PNG',
        status: 1,
    },
    {
        id: 12448,
        name: 'HFS Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12448_1_1.PNG',
        status: 1,
    },
    {
        id: 12452,
        name: 'Reliant Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12452_1_1.PNG',
        status: 1,
    },
    {
        id: 12454,
        name: 'Advantage Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12454_1_1.PNG',
        status: 1,
    },
    {
        id: 12473,
        name: 'Atlantic Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12473_1_1.PNG',
        status: 1,
    },
    {
        id: 12478,
        name: '1st Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12478_1_1.PNG',
        status: 1,
    },
    {
        id: 12479,
        name: 'Pioneer Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12479_1_1.PNG',
        status: 1,
    },
    {
        id: 12483,
        name: 'Artesia Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12483_1_1.PNG',
        status: 1,
    },
    {
        id: 12498,
        name: 'RTP Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12498_1_1.PNG',
        status: 1,
    },
    {
        id: 12507,
        name: 'Cooperative Teachers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12507_1_1.PNG',
        status: 1,
    },
    {
        id: 12539,
        name: 'River City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12539_1_1.PNG',
        status: 1,
    },
    {
        id: 12544,
        name: 'University of Kentucky Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12544_1_1.PNG',
        status: 1,
    },
    {
        id: 12545,
        name: 'Mission City Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12545_1_1.PNG',
        status: 1,
    },
    {
        id: 12547,
        name: 'Henry State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12547_1_1.PNG',
        status: 1,
    },
    {
        id: 12556,
        name: 'Arizona Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12556_1_1.PNG',
        status: 1,
    },
    {
        id: 12560,
        name: 'Community Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 12561,
        name: 'Maui County Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12561_1_1.PNG',
        status: 1,
    },
    {
        id: 12563,
        name: 'Banc of California',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12563_1_1.PNG',
        status: 1,
    },
    {
        id: 12566,
        name: 'SC Telco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12566_1_1.PNG',
        status: 1,
    },
    {
        id: 12569,
        name: 'Arrowhead Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12569_1_1.PNG',
        status: 1,
    },
    {
        id: 12570,
        name: 'Bank of Bennington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12570_1_1.PNG',
        status: 1,
    },
    {
        id: 12575,
        name: 'Kauai Government Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12575_1_1.PNG',
        status: 1,
    },
    {
        id: 12577,
        name: 'Optimum Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12577_1_1.PNG',
        status: 1,
    },
    {
        id: 12578,
        name: 'Envision CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 12579,
        name: 'ExeCUtive National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12579_1_1.PNG',
        status: 1,
    },
    {
        id: 12626,
        name: 'Farmers State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12626_1_1.PNG',
        status: 1,
    },
    {
        id: 12648,
        name: 'Fifth Third Direct Commercial Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12648_1_1.PNG',
        status: 1,
    },
    {
        id: 12687,
        name: 'Wayne Savings Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12687_1_1.PNG',
        status: 1,
    },
    {
        id: 12710,
        name: 'The Equitable Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12710_1_1.PNG',
        status: 1,
    },
    {
        id: 12711,
        name: 'Saratoga National Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12711_1_1.PNG',
        status: 1,
    },
    {
        id: 12713,
        name: 'Bristol County Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12713_1_1.PNG',
        status: 1,
    },
    {
        id: 12722,
        name: 'Financial Partners Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12722_1_1.PNG',
        status: 1,
    },
    {
        id: 12743,
        name: 'Hawaii Schools Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12743_1_1.PNG',
        status: 1,
    },
    {
        id: 12761,
        name: 'Evans Bancorp',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12761_1_1.PNG',
        status: 1,
    },
    {
        id: 12771,
        name: 'Gate City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12771_1_1.PNG',
        status: 1,
    },
    {
        id: 12795,
        name: 'U.S. Century Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12795_1_1.PNG',
        status: 1,
    },
    {
        id: 12796,
        name: 'Islanders Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12796_1_1.PNG',
        status: 1,
    },
    {
        id: 12797,
        name: 'Admirals Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12797_1_1.PNG',
        status: 1,
    },
    {
        id: 12808,
        name: 'University Credit Union of Maine',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12808_1_1.PNG',
        status: 1,
    },
    {
        id: 12813,
        name: 'The Citizens State Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12813_1_1.PNG',
        status: 1,
    },
    {
        id: 12816,
        name: 'Regional Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12816_1_1.PNG',
        status: 1,
    },
    {
        id: 12852,
        name: 'DuPont Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12852_1_1.PNG',
        status: 1,
    },
    {
        id: 12853,
        name: 'Inspirus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12853_1_1.PNG',
        status: 1,
    },
    {
        id: 12861,
        name: 'Global Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12861_1_1.PNG',
        status: 1,
    },
    {
        id: 12867,
        name: 'Aloha Pacific Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12867_1_1.PNG',
        status: 1,
    },
    {
        id: 12872,
        name: 'Citizens Bank of Blount County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12872_1_1.PNG',
        status: 1,
    },
    {
        id: 12877,
        name: 'Dollar Savings Direct',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12877_1_1.PNG',
        status: 1,
    },
    {
        id: 12879,
        name: 'Hawaii First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12879_1_1.PNG',
        status: 1,
    },
    {
        id: 12880,
        name: 'Hawaii County Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12880_1_1.PNG',
        status: 1,
    },
    {
        id: 12881,
        name: 'Hawaii Pacific Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12881_1_1.PNG',
        status: 1,
    },
    {
        id: 12883,
        name: 'Citizens National Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12883_1_1.PNG',
        status: 1,
    },
    {
        id: 12903,
        name: 'Coulee Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12903_1_1.PNG',
        status: 1,
    },
    {
        id: 12906,
        name: 'Texans Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12906_1_1.PNG',
        status: 1,
    },
    {
        id: 12908,
        name: 'Collegiate Peaks Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12908_1_1.PNG',
        status: 1,
    },
    {
        id: 12911,
        name: 'First Colony Bank (FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12911_1_1.PNG',
        status: 1,
    },
    {
        id: 12934,
        name: 'California Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12934_1_1.PNG',
        status: 1,
    },
    {
        id: 12939,
        name: 'First Northern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12939_1_1.PNG',
        status: 1,
    },
    {
        id: 12962,
        name: 'Hawaii Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12962_1_1.PNG',
        status: 1,
    },
    {
        id: 12965,
        name: 'Kauai Teachers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12965_1_1.PNG',
        status: 1,
    },
    {
        id: 12970,
        name: 'Franklin Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12970_1_1.PNG',
        status: 1,
    },
    {
        id: 12972,
        name: 'Windward Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12972_1_1.PNG',
        status: 1,
    },
    {
        id: 12975,
        name: "People's Trust Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12975_1_1.PNG',
        status: 1,
    },
    {
        id: 12988,
        name: 'Metropolitan National Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12988_1_1.PNG',
        status: 1,
    },
    {
        id: 12991,
        name: 'Michigan First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_12991_1_1.PNG',
        status: 1,
    },
    {
        id: 13007,
        name: 'MPD Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13007_1_1.PNG',
        status: 1,
    },
    {
        id: 13013,
        name: 'Idaho Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13013_1_2.SVG',
        status: 1,
    },
    {
        id: 13020,
        name: 'American National Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13020_1_1.PNG',
        status: 1,
    },
    {
        id: 13023,
        name: 'Peoples Bank (WA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13023_1_1.PNG',
        status: 1,
    },
    {
        id: 13044,
        name: 'Greater Alliance Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13044_1_1.PNG',
        status: 1,
    },
    {
        id: 13051,
        name: 'Jordan Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13051_1_1.PNG',
        status: 1,
    },
    {
        id: 13053,
        name: 'First Robinson Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13053_1_1.PNG',
        status: 1,
    },
    {
        id: 13055,
        name: 'Dart Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13055_1_1.PNG',
        status: 1,
    },
    {
        id: 13064,
        name: 'Sona Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13064_1_1.PNG',
        status: 1,
    },
    {
        id: 13065,
        name: 'Service CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13065_1_2.SVG',
        status: 1,
    },
    {
        id: 13069,
        name: 'Cobalt Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13069_1_1.PNG',
        status: 1,
    },
    {
        id: 13075,
        name: 'Dacotah Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13075_1_1.PNG',
        status: 1,
    },
    {
        id: 13077,
        name: 'Shrewsbury Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13077_1_1.PNG',
        status: 1,
    },
    {
        id: 13093,
        name: 'Summit Credit Union (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13093_1_1.PNG',
        status: 1,
    },
    {
        id: 13107,
        name: 'Citizens National Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13107_1_1.PNG',
        status: 1,
    },
    {
        id: 13112,
        name: 'Birmingham Bloomfield Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13112_1_1.PNG',
        status: 1,
    },
    {
        id: 13117,
        name: 'Family First Federal Credit Union (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13117_1_1.PNG',
        status: 1,
    },
    {
        id: 13118,
        name: 'United Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13118_1_1.PNG',
        status: 1,
    },
    {
        id: 13124,
        name: 'Princeton Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13124_1_1.PNG',
        status: 1,
    },
    {
        id: 13128,
        name: 'Goldenwest Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13128_1_1.PNG',
        status: 1,
    },
    {
        id: 13130,
        name: 'Pioneer Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13130_1_1.PNG',
        status: 1,
    },
    {
        id: 13133,
        name: 'Blue Ridge Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13133_1_1.PNG',
        status: 1,
    },
    {
        id: 13136,
        name: 'State Bank of Cross Plains',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13136_1_1.PNG',
        status: 1,
    },
    {
        id: 13138,
        name: 'MidwestOne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13138_1_1.PNG',
        status: 1,
    },
    {
        id: 13140,
        name: 'Bond Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13147,
        name: 'Meridian Trust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13147_1_1.PNG',
        status: 1,
    },
    {
        id: 13159,
        name: 'WestStar Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13159_1_1.PNG',
        status: 1,
    },
    {
        id: 13177,
        name: 'Old Line Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13177_1_1.PNG',
        status: 1,
    },
    {
        id: 13178,
        name: 'Jax Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13178_1_1.PNG',
        status: 1,
    },
    {
        id: 13179,
        name: 'PioneerValley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13179_1_1.PNG',
        status: 1,
    },
    {
        id: 13181,
        name: 'Beverly Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13181_1_1.PNG',
        status: 1,
    },
    {
        id: 13185,
        name: 'First New England Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13185_1_1.PNG',
        status: 1,
    },
    {
        id: 13186,
        name: 'Tewksbury Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13188,
        name: 'BAC Florida Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13188_1_1.PNG',
        status: 1,
    },
    {
        id: 13205,
        name: 'Central Bank (UT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13205_1_1.PNG',
        status: 1,
    },
    {
        id: 13211,
        name: 'Ephrata National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13211_1_1.PNG',
        status: 1,
    },
    {
        id: 13219,
        name: 'Redneck Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13219_1_1.PNG',
        status: 1,
    },
    {
        id: 13221,
        name: 'CNB Bank Direct',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13228,
        name: 'Bank of American Fork',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13228_1_1.PNG',
        status: 1,
    },
    {
        id: 13230,
        name: 'DFCU Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13230_1_1.PNG',
        status: 1,
    },
    {
        id: 13242,
        name: 'Saco & Biddeford Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13242_1_1.PNG',
        status: 1,
    },
    {
        id: 13254,
        name: 'Advantage One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13254_1_1.PNG',
        status: 1,
    },
    {
        id: 13257,
        name: 'Nassau Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13257_1_1.PNG',
        status: 1,
    },
    {
        id: 13261,
        name: 'Macatawa Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13261_1_1.PNG',
        status: 1,
    },
    {
        id: 13263,
        name: 'FNB of Bastrop',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13263_1_1.PNG',
        status: 1,
    },
    {
        id: 13281,
        name: 'Cumberland County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13281_1_1.PNG',
        status: 1,
    },
    {
        id: 13288,
        name: 'National Bank of Andrews (NBA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13288_1_1.PNG',
        status: 1,
    },
    {
        id: 13291,
        name: 'All America Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13291_1_1.PNG',
        status: 1,
    },
    {
        id: 13304,
        name: '1st Century Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13304_1_1.PNG',
        status: 1,
    },
    {
        id: 13312,
        name: 'The First National Bank in Trinidad',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13312_1_1.PNG',
        status: 1,
    },
    {
        id: 13315,
        name: 'Community Bank of Pleasant Hill',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13315_1_1.PNG',
        status: 1,
    },
    {
        id: 13318,
        name: 'Cape Cod Cooperative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13318_1_1.PNG',
        status: 1,
    },
    {
        id: 13320,
        name: 'AmericaNet Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13320_1_1.PNG',
        status: 1,
    },
    {
        id: 13329,
        name: 'Valley First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13329_1_1.PNG',
        status: 1,
    },
    {
        id: 13335,
        name: 'SkyOne Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13336,
        name: 'Alpine Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13342,
        name: 'Liberty Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13342_1_1.PNG',
        status: 1,
    },
    {
        id: 13343,
        name: 'Capital Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13343_1_1.PNG',
        status: 1,
    },
    {
        id: 13345,
        name: 'Live Oak Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13347,
        name: 'Hanmi Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13347_1_1.PNG',
        status: 1,
    },
    {
        id: 13351,
        name: 'Popular Bank - Puerto Rico',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13351_1_1.PNG',
        status: 1,
    },
    {
        id: 13365,
        name: 'Class Act Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13365_1_1.PNG',
        status: 1,
    },
    {
        id: 13372,
        name: 'West Plains Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13372_1_1.PNG',
        status: 1,
    },
    {
        id: 13380,
        name: 'Byline Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13380_1_1.PNG',
        status: 1,
    },
    {
        id: 13392,
        name: 'Merchants Bank of Indiana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13392_1_1.PNG',
        status: 1,
    },
    {
        id: 13399,
        name: 'Evantage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13399_1_1.PNG',
        status: 1,
    },
    {
        id: 13400,
        name: 'American Business Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13400_1_1.PNG',
        status: 1,
    },
    {
        id: 13401,
        name: 'Highmark Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13401_1_1.PNG',
        status: 1,
    },
    {
        id: 13402,
        name: 'Mills County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13402_1_1.PNG',
        status: 1,
    },
    {
        id: 13406,
        name: 'Lebanon Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13410,
        name: 'Virginia Educators Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13410_1_1.PNG',
        status: 1,
    },
    {
        id: 13414,
        name: 'Credit Union of Colorado',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13414_1_1.PNG',
        status: 1,
    },
    {
        id: 13417,
        name: 'Brazos Valley Schools Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13417_1_1.PNG',
        status: 1,
    },
    {
        id: 13418,
        name: 'Alliance Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13418_1_1.PNG',
        status: 1,
    },
    {
        id: 13426,
        name: 'American Southwest Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13426_1_1.PNG',
        status: 1,
    },
    {
        id: 13429,
        name: 'Fund Staff Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13429_1_1.PNG',
        status: 1,
    },
    {
        id: 13435,
        name: 'Bank of the West (WebDirect)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13435_1_1.PNG',
        status: 1,
    },
    {
        id: 13443,
        name: 'Insight Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13443_1_1.PNG',
        status: 1,
    },
    {
        id: 13445,
        name: 'Liberty National Bank (IA, SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13445_1_1.PNG',
        status: 1,
    },
    {
        id: 13446,
        name: 'Clearwater Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13446_1_1.PNG',
        status: 1,
    },
    {
        id: 13450,
        name: 'Whatcom Educational CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13450_1_1.PNG',
        status: 1,
    },
    {
        id: 13452,
        name: 'Redding Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13453,
        name: 'Sunset Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13453_1_1.PNG',
        status: 1,
    },
    {
        id: 13464,
        name: '1st Summit Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13464_1_1.PNG',
        status: 1,
    },
    {
        id: 13483,
        name: 'Community First Bank of the Heartland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13483_1_1.PNG',
        status: 1,
    },
    {
        id: 13508,
        name: 'CommerceWest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13508_1_1.PNG',
        status: 1,
    },
    {
        id: 13511,
        name: 'Farmers National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13511_1_1.PNG',
        status: 1,
    },
    {
        id: 13523,
        name: 'First-Lockhart National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13523_1_1.PNG',
        status: 1,
    },
    {
        id: 13528,
        name: 'WesBanco Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13528_1_1.PNG',
        status: 1,
    },
    {
        id: 13530,
        name: 'Axiom Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13530_1_1.PNG',
        status: 1,
    },
    {
        id: 13546,
        name: 'Park Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13546_1_1.PNG',
        status: 1,
    },
    {
        id: 13572,
        name: 'Northmark Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13572_1_1.PNG',
        status: 1,
    },
    {
        id: 13573,
        name: 'American First National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13573_1_1.PNG',
        status: 1,
    },
    {
        id: 13584,
        name: 'C US Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13584_1_1.PNG',
        status: 1,
    },
    {
        id: 13590,
        name: 'Rhinebeck Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13590_1_1.PNG',
        status: 1,
    },
    {
        id: 13592,
        name: 'First Financial Federal Credit Union of Maryland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13592_1_1.PNG',
        status: 1,
    },
    {
        id: 13596,
        name: 'Bank of Weston',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13596_1_1.PNG',
        status: 1,
    },
    {
        id: 13597,
        name: 'The Summit Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13597_1_1.PNG',
        status: 1,
    },
    {
        id: 13607,
        name: 'Community Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13607_1_1.PNG',
        status: 1,
    },
    {
        id: 13612,
        name: 'Bippus State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13612_1_1.PNG',
        status: 1,
    },
    {
        id: 13613,
        name: 'Southeast Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13613_1_1.PNG',
        status: 1,
    },
    {
        id: 13614,
        name: 'The Hicksville Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13614_1_1.PNG',
        status: 1,
    },
    {
        id: 13616,
        name: 'Velocity Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13616_1_1.PNG',
        status: 1,
    },
    {
        id: 13620,
        name: 'TheFirst',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13620_1_1.PNG',
        status: 1,
    },
    {
        id: 13621,
        name: 'Securityplus Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13621_1_1.PNG',
        status: 1,
    },
    {
        id: 13665,
        name: 'Texas Citizens Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13665_1_1.PNG',
        status: 1,
    },
    {
        id: 13671,
        name: 'First Choice CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13673,
        name: 'LOC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13673_1_1.PNG',
        status: 1,
    },
    {
        id: 13675,
        name: 'The Commerce Bank of Washington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13675_1_1.PNG',
        status: 1,
    },
    {
        id: 13679,
        name: 'Mutual of Omaha (Personal Online Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13679_1_1.PNG',
        status: 1,
    },
    {
        id: 13687,
        name: 'Fidelity National Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13687_1_1.PNG',
        status: 1,
    },
    {
        id: 13691,
        name: 'New Horizons Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13691_1_1.PNG',
        status: 1,
    },
    {
        id: 13699,
        name: 'Texas Brand Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13700,
        name: 'Goldwater Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13703,
        name: 'Bank of Guam',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13704,
        name: 'Bank of Ruston',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13706,
        name: 'First Source Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13706_1_1.PNG',
        status: 1,
    },
    {
        id: 13709,
        name: 'Progress Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13709_1_1.PNG',
        status: 1,
    },
    {
        id: 13711,
        name: 'The Union Bank Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13712,
        name: 'Hometown National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13712_1_1.PNG',
        status: 1,
    },
    {
        id: 13721,
        name: 'American Bank & Trust Company (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13721_1_1.PNG',
        status: 1,
    },
    {
        id: 13722,
        name: 'Pawtucket Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13722_1_1.PNG',
        status: 1,
    },
    {
        id: 13739,
        name: 'BankFive',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13739_1_1.PNG',
        status: 1,
    },
    {
        id: 13742,
        name: 'Eagle Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13742_1_1.PNG',
        status: 1,
    },
    {
        id: 13750,
        name: 'Security State Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13750_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 13756,
        name: 'Florida Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13756_1_1.PNG',
        status: 1,
    },
    {
        id: 13770,
        name: 'AbbyBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13770_1_1.PNG',
        status: 1,
    },
    {
        id: 13772,
        name: 'SEI Cash Access',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13796,
        name: 'Adams Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13796_1_1.PNG',
        status: 1,
    },
    {
        id: 13824,
        name: 'Financial Center Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13824_1_2.SVG',
        status: 1,
    },
    {
        id: 13852,
        name: 'Parsons Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13852_1_1.PNG',
        status: 1,
    },
    {
        id: 13886,
        name: 'United Bank (VA,DC & MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13886_1_1.PNG',
        status: 1,
    },
    {
        id: 13894,
        name: 'Rural Cooperatives Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13894_1_1.PNG',
        status: 1,
    },
    {
        id: 13896,
        name: 'Farmers & Merchants Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13896_1_1.PNG',
        status: 1,
    },
    {
        id: 13897,
        name: 'FOCUS Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13897_1_1.PNG',
        status: 1,
    },
    {
        id: 13903,
        name: 'Farmers Savings Bank(WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13903_1_1.PNG',
        status: 1,
    },
    {
        id: 13904,
        name: 'Viking Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13904_1_1.PNG',
        status: 1,
    },
    {
        id: 13907,
        name: 'Generations Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 13910,
        name: 'Citizens State Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13910_1_1.PNG',
        status: 1,
    },
    {
        id: 13915,
        name: 'Bank of Star Valley',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13915_1_1.PNG',
        status: 1,
    },
    {
        id: 13929,
        name: 'BankersTrust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13929_1_1.PNG',
        status: 1,
    },
    {
        id: 13932,
        name: '1st Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13932_1_1.PNG',
        status: 1,
    },
    {
        id: 13944,
        name: 'Carolina Alliance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13944_1_1.PNG',
        status: 1,
    },
    {
        id: 13953,
        name: 'Incredible Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13953_1_1.PNG',
        status: 1,
    },
    {
        id: 13963,
        name: 'Colorado Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13963_1_1.PNG',
        status: 1,
    },
    {
        id: 13985,
        name: 'Commercial Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13985_1_1.PNG',
        status: 1,
    },
    {
        id: 13990,
        name: 'Northfield Savings Bank (VT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_13990_1_1.PNG',
        status: 1,
    },
    {
        id: 14010,
        name: 'Reliabank Dakota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14010_1_1.PNG',
        status: 1,
    },
    {
        id: 14021,
        name: 'Community Bankshares (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14021_1_1.PNG',
        status: 1,
    },
    {
        id: 14026,
        name: 'TransPecos Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14026_1_1.PNG',
        status: 1,
    },
    {
        id: 14029,
        name: 'Generations Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14029_1_1.PNG',
        status: 1,
    },
    {
        id: 14032,
        name: 'The Harbor Bank of Maryland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14032_1_1.PNG',
        status: 1,
    },
    {
        id: 14036,
        name: 'CapStar Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14036_1_1.PNG',
        status: 1,
    },
    {
        id: 14053,
        name: 'Ottawa Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14053_1_1.PNG',
        status: 1,
    },
    {
        id: 14056,
        name: 'Missouri Baptist Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14056_1_1.PNG',
        status: 1,
    },
    {
        id: 14078,
        name: 'Royal Banks of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14078_1_1.PNG',
        status: 1,
    },
    {
        id: 14090,
        name: 'Baton Rouge Telco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14090_1_1.PNG',
        status: 1,
    },
    {
        id: 14092,
        name: 'UNCLE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14092_1_1.PNG',
        status: 1,
    },
    {
        id: 14093,
        name: '1st Bank Yuma',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14093_1_1.PNG',
        status: 1,
    },
    {
        id: 14105,
        name: 'Solano First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14105_1_1.PNG',
        status: 1,
    },
    {
        id: 14117,
        name: 'Investors Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14117_1_1.PNG',
        status: 1,
    },
    {
        id: 14124,
        name: 'Montgomery Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14124_1_1.PNG',
        status: 1,
    },
    {
        id: 14141,
        name: 'Santa Clara County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14141_1_1.PNG',
        status: 1,
    },
    {
        id: 14169,
        name: 'The Farmers Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14171,
        name: 'CIBC Net Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14171_1_1.PNG',
        status: 1,
    },
    {
        id: 14172,
        name: 'Thomas County Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14172_1_1.PNG',
        status: 1,
    },
    {
        id: 14173,
        name: 'Community State Bank (IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14173_1_1.PNG',
        status: 1,
    },
    {
        id: 14177,
        name: 'Bank of Canton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14177_1_1.PNG',
        status: 1,
    },
    {
        id: 14180,
        name: 'Fremont Banking (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14180_1_1.PNG',
        status: 1,
    },
    {
        id: 14188,
        name: 'State Employees Credit Union (New Mexico)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14188_1_1.PNG',
        status: 1,
    },
    {
        id: 14208,
        name: 'Bank of Nevada',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14208_1_1.PNG',
        status: 1,
    },
    {
        id: 14214,
        name: 'Florida Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14214_1_1.PNG',
        status: 1,
    },
    {
        id: 14224,
        name: 'Fortera Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14224_1_1.PNG',
        status: 1,
    },
    {
        id: 14228,
        name: 'Bank of Bozeman',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14228_1_1.PNG',
        status: 1,
    },
    {
        id: 14241,
        name: 'First Reliance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14241_1_1.PNG',
        status: 1,
    },
    {
        id: 14242,
        name: 'Cambridge Trust Company Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14242_1_1.PNG',
        status: 1,
    },
    {
        id: 14243,
        name: 'Embassy Bank for The Lehigh Valley',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14243_1_1.PNG',
        status: 1,
    },
    {
        id: 14245,
        name: 'Security First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14245_1_1.PNG',
        status: 1,
    },
    {
        id: 14248,
        name: 'Passumpsic Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14248_1_1.PNG',
        status: 1,
    },
    {
        id: 14249,
        name: 'Kansas State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14249_1_1.PNG',
        status: 1,
    },
    {
        id: 14251,
        name: 'Frederick County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14251_1_1.PNG',
        status: 1,
    },
    {
        id: 14254,
        name: 'Charlotte Metro Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14254_1_1.PNG',
        status: 1,
    },
    {
        id: 14255,
        name: 'Bronco FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14255_1_1.PNG',
        status: 1,
    },
    {
        id: 14260,
        name: 'Bank of Colorado',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14260_1_1.PNG',
        status: 1,
    },
    {
        id: 14261,
        name: 'Farmers and Merchants Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14261_1_1.PNG',
        status: 1,
    },
    {
        id: 14263,
        name: 'Washington Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14263_1_1.PNG',
        status: 1,
    },
    {
        id: 14265,
        name: 'CalPrivate Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14265_1_1.PNG',
        status: 1,
    },
    {
        id: 14266,
        name: 'Heartland Credit Union (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14266_1_2.SVG',
        status: 1,
    },
    {
        id: 14267,
        name: 'First State Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14267_1_1.PNG',
        status: 1,
    },
    {
        id: 14271,
        name: 'Atlantic Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14271_1_1.PNG',
        status: 1,
    },
    {
        id: 14277,
        name: 'West Texas National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14277_1_1.PNG',
        status: 1,
    },
    {
        id: 14278,
        name: 'Northrim Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14278_1_1.PNG',
        status: 1,
    },
    {
        id: 14279,
        name: 'US Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14279_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 14280,
        name: 'Beach Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14280_1_1.PNG',
        status: 1,
    },
    {
        id: 14282,
        name: 'Institution for Savings (Business Cash Management)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14282_1_1.PNG',
        status: 1,
    },
    {
        id: 14283,
        name: 'Kennebec Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14283_1_1.PNG',
        status: 1,
    },
    {
        id: 14284,
        name: 'Kentucky Telco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14284_1_1.PNG',
        status: 1,
    },
    {
        id: 14285,
        name: 'La Loma Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14285_1_1.PNG',
        status: 1,
    },
    {
        id: 14286,
        name: 'QNB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14286_1_1.PNG',
        status: 1,
    },
    {
        id: 14291,
        name: 'Servis 1st Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14291_1_1.PNG',
        status: 1,
    },
    {
        id: 14296,
        name: 'Vision Bank Ada (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14296_1_1.PNG',
        status: 1,
    },
    {
        id: 14302,
        name: 'Spencer Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14302_1_1.PNG',
        status: 1,
    },
    {
        id: 14304,
        name: 'First Federal Bank (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14304_1_1.PNG',
        status: 1,
    },
    {
        id: 14307,
        name: 'Triumph Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14307_1_1.PNG',
        status: 1,
    },
    {
        id: 14310,
        name: 'Hartford Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14310_1_1.PNG',
        status: 1,
    },
    {
        id: 14314,
        name: 'Independent Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14316,
        name: 'Mission Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14316_1_1.PNG',
        status: 1,
    },
    {
        id: 14317,
        name: 'The First National Bank and Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14317_1_1.PNG',
        status: 1,
    },
    {
        id: 14324,
        name: 'Newbury Port Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14324_1_1.PNG',
        status: 1,
    },
    {
        id: 14325,
        name: 'Texas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14325_1_1.PNG',
        status: 1,
    },
    {
        id: 14327,
        name: 'Midwest Members Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14327_1_1.PNG',
        status: 1,
    },
    {
        id: 14328,
        name: 'Cache Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14328_1_1.PNG',
        status: 1,
    },
    {
        id: 14331,
        name: 'Peach State Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14331_1_1.PNG',
        status: 1,
    },
    {
        id: 14335,
        name: 'Home Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14335_1_1.PNG',
        status: 1,
    },
    {
        id: 14337,
        name: 'Peoples Trust & Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14337_1_1.PNG',
        status: 1,
    },
    {
        id: 14340,
        name: 'Town of Hempstead Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14340_1_1.PNG',
        status: 1,
    },
    {
        id: 14357,
        name: 'Henderson State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14357_1_1.PNG',
        status: 1,
    },
    {
        id: 14358,
        name: 'Town & Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14358_1_1.PNG',
        status: 1,
    },
    {
        id: 14368,
        name: 'Santa Cruz County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14368_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 14372,
        name: 'The Cattle National Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14372_1_1.PNG',
        status: 1,
    },
    {
        id: 14373,
        name: 'Stifel Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14373_1_1.PNG',
        status: 1,
    },
    {
        id: 14376,
        name: 'Providence Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14376_1_1.PNG',
        status: 1,
    },
    {
        id: 14377,
        name: 'The Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14377_1_1.PNG',
        status: 1,
    },
    {
        id: 14380,
        name: 'The Exchange National Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14380_1_1.PNG',
        status: 1,
    },
    {
        id: 14386,
        name: 'California Bank & Trust Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14386_1_1.PNG',
        status: 1,
    },
    {
        id: 14392,
        name: 'Tennessee Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14392_1_1.PNG',
        status: 1,
    },
    {
        id: 14444,
        name: 'First Financial Bank (AR, MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14444_1_1.PNG',
        status: 1,
    },
    {
        id: 14445,
        name: 'Kennebunk Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14445_1_1.PNG',
        status: 1,
    },
    {
        id: 14448,
        name: 'Cedar Rapids Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14448_1_1.PNG',
        status: 1,
    },
    {
        id: 14466,
        name: 'JCB (IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14466_1_1.PNG',
        status: 1,
    },
    {
        id: 14467,
        name: 'U$X Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14470,
        name: 'Old Plank Trail Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14470_1_1.PNG',
        status: 1,
    },
    {
        id: 14473,
        name: 'Citizens Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14473_1_1.PNG',
        status: 1,
    },
    {
        id: 14477,
        name: 'Sharefax Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14477_1_1.PNG',
        status: 1,
    },
    {
        id: 14478,
        name: 'Kinetic Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14478_1_1.PNG',
        status: 1,
    },
    {
        id: 14479,
        name: 'Liberty Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14479_1_1.PNG',
        status: 1,
    },
    {
        id: 14481,
        name: 'Profile Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14481_1_1.PNG',
        status: 1,
    },
    {
        id: 14483,
        name: 'Central Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14483_1_1.PNG',
        status: 1,
    },
    {
        id: 14484,
        name: 'Peoples Bank and Trust (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14484_1_1.PNG',
        status: 1,
    },
    {
        id: 14485,
        name: 'Minster Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14485_1_1.PNG',
        status: 1,
    },
    {
        id: 14486,
        name: 'The Bank of South Carolina',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14486_1_1.PNG',
        status: 1,
    },
    {
        id: 14488,
        name: 'Veritex Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14489,
        name: 'Pioneer Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14489_1_1.PNG',
        status: 1,
    },
    {
        id: 14495,
        name: 'Heritage Bank (WA, OR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14495_1_1.PNG',
        status: 1,
    },
    {
        id: 14496,
        name: 'Old Missouri Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14501,
        name: 'ProFed',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14501_1_1.PNG',
        status: 1,
    },
    {
        id: 14510,
        name: 'Bank of Prairie du Sac',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14510_1_1.PNG',
        status: 1,
    },
    {
        id: 14517,
        name: 'Union Savings Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14517_1_1.PNG',
        status: 1,
    },
    {
        id: 14520,
        name: 'Community West Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14520_1_1.PNG',
        status: 1,
    },
    {
        id: 14523,
        name: 'West Alabama Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14525,
        name: 'Northwoods Bank of Minnesota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14525_1_1.PNG',
        status: 1,
    },
    {
        id: 14527,
        name: 'Santa Cruz County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14527_1_1.PNG',
        status: 1,
    },
    {
        id: 14529,
        name: 'Parke Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14529_1_1.PNG',
        status: 1,
    },
    {
        id: 14538,
        name: 'Oostburg State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14538_1_1.PNG',
        status: 1,
    },
    {
        id: 14541,
        name: 'Charleroi Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14541_1_1.PNG',
        status: 1,
    },
    {
        id: 14543,
        name: 'Citizens Bank of ADA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14543_1_1.PNG',
        status: 1,
    },
    {
        id: 14544,
        name: 'Fidelity Bank (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14544_1_1.PNG',
        status: 1,
    },
    {
        id: 14546,
        name: 'McHenry Savings Bank(MSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14546_1_1.PNG',
        status: 1,
    },
    {
        id: 14547,
        name: 'Coastal Commerce Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14547_1_1.PNG',
        status: 1,
    },
    {
        id: 14560,
        name: 'Regions (Cash Management)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14560_1_1.PNG',
        status: 1,
    },
    {
        id: 14561,
        name: 'Bank First National',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14561_1_1.PNG',
        status: 1,
    },
    {
        id: 14562,
        name: 'Heritage Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14563,
        name: 'Redwood Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14563_1_1.PNG',
        status: 1,
    },
    {
        id: 14564,
        name: 'Boundary Waters Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14564_1_1.PNG',
        status: 1,
    },
    {
        id: 14569,
        name: 'First Community Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14570,
        name: 'Farmers & Merchants Bank & Trust (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14570_1_1.PNG',
        status: 1,
    },
    {
        id: 14589,
        name: 'Bank of Oak Ridge',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14589_1_1.PNG',
        status: 1,
    },
    {
        id: 14591,
        name: 'First New York Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14591_1_1.PNG',
        status: 1,
    },
    {
        id: 14593,
        name: 'Ballston Spa National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14593_1_1.PNG',
        status: 1,
    },
    {
        id: 14597,
        name: 'Bank of Marin (Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14597_1_1.PNG',
        status: 1,
    },
    {
        id: 14604,
        name: 'FirstAmerican Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14604_1_1.PNG',
        status: 1,
    },
    {
        id: 14618,
        name: 'Colony Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14618_1_1.PNG',
        status: 1,
    },
    {
        id: 14619,
        name: 'First Federal (OR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14619_1_1.PNG',
        status: 1,
    },
    {
        id: 14620,
        name: 'Community Choice Credit Union (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14620_1_1.PNG',
        status: 1,
    },
    {
        id: 14630,
        name: 'Home Federal Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14630_1_1.PNG',
        status: 1,
    },
    {
        id: 14633,
        name: 'Leader Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14633_1_1.PNG',
        status: 1,
    },
    {
        id: 14636,
        name: 'FCB Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14636_1_1.PNG',
        status: 1,
    },
    {
        id: 14642,
        name: 'TEG Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14642_1_1.PNG',
        status: 1,
    },
    {
        id: 14644,
        name: 'Peoples Bank (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14644_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 14645,
        name: 'The Lyons National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14645_1_1.PNG',
        status: 1,
    },
    {
        id: 14648,
        name: 'El Dorado Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14648_1_1.PNG',
        status: 1,
    },
    {
        id: 14649,
        name: 'Southern Bank & Trust (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14649_1_1.PNG',
        status: 1,
    },
    {
        id: 14650,
        name: 'The Bank of Bennington (VT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14650_1_1.PNG',
        status: 1,
    },
    {
        id: 14651,
        name: 'First Federal Savings Bank (IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14651_1_1.PNG',
        status: 1,
    },
    {
        id: 14652,
        name: 'Mazuma CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14652_1_1.PNG',
        status: 1,
    },
    {
        id: 14655,
        name: 'Northeast Family FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14655_1_1.PNG',
        status: 1,
    },
    {
        id: 14656,
        name: 'Home Federal (MN,IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14657,
        name: 'Bank of Charles Town',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14657_1_1.PNG',
        status: 1,
    },
    {
        id: 14662,
        name: 'HomeTrust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14662_1_1.PNG',
        status: 1,
    },
    {
        id: 14664,
        name: 'Workers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14664_1_1.PNG',
        status: 1,
    },
    {
        id: 14666,
        name: 'First Federal Savings & Loan Assn. of Delta (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14666_1_1.PNG',
        status: 1,
    },
    {
        id: 14667,
        name: 'Piedmont Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14667_1_1.PNG',
        status: 1,
    },
    {
        id: 14669,
        name: 'First Southern National Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14669_1_1.PNG',
        status: 1,
    },
    {
        id: 14670,
        name: 'Clark County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14670_1_1.PNG',
        status: 1,
    },
    {
        id: 14672,
        name: 'Radius Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14672_1_1.PNG',
        status: 1,
    },
    {
        id: 14675,
        name: 'First United Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14675_1_1.PNG',
        status: 1,
    },
    {
        id: 14681,
        name: 'Geddes Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14681_1_1.PNG',
        status: 1,
    },
    {
        id: 14682,
        name: '1st Security Bank of Washington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14686,
        name: 'Financial Plus CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14686_1_1.PNG',
        status: 1,
    },
    {
        id: 14688,
        name: 'Touchmark National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14688_1_1.PNG',
        status: 1,
    },
    {
        id: 14692,
        name: 'Chambers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14692_1_1.PNG',
        status: 1,
    },
    {
        id: 14703,
        name: 'NSWC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14703_1_1.PNG',
        status: 1,
    },
    {
        id: 14708,
        name: 'Coca-Cola Co. Family Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14708_1_1.PNG',
        status: 1,
    },
    {
        id: 14709,
        name: 'OUR Credit Union (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14709_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 14710,
        name: 'Rollstone Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14710_1_1.PNG',
        status: 1,
    },
    {
        id: 14711,
        name: 'Cy-Fair Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14711_1_1.PNG',
        status: 1,
    },
    {
        id: 14713,
        name: 'Advanced Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14713_1_1.PNG',
        status: 1,
    },
    {
        id: 14716,
        name: 'Libertyville Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14716_1_1.PNG',
        status: 1,
    },
    {
        id: 14754,
        name: 'Community Resource Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14754_1_1.PNG',
        status: 1,
    },
    {
        id: 14756,
        name: 'Members First Credit Union (MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14756_1_1.PNG',
        status: 1,
    },
    {
        id: 14757,
        name: 'Memorial Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14757_1_1.PNG',
        status: 1,
    },
    {
        id: 14762,
        name: 'First Farmers Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14762_1_1.PNG',
        status: 1,
    },
    {
        id: 14763,
        name: 'PCSBbank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14763_1_1.PNG',
        status: 1,
    },
    {
        id: 14767,
        name: 'East Wisconsin Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14767_1_1.PNG',
        status: 1,
    },
    {
        id: 14768,
        name: 'Hyperion Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14771,
        name: 'Carrollton Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14771_1_1.PNG',
        status: 1,
    },
    {
        id: 14777,
        name: 'Cross County Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14777_1_1.PNG',
        status: 1,
    },
    {
        id: 14796,
        name: 'Citizens National Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14796_1_1.PNG',
        status: 1,
    },
    {
        id: 14813,
        name: 'Bank of North Carolina Pinnacle',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14813_1_1.PNG',
        status: 1,
    },
    {
        id: 14818,
        name: 'Pensacola Government Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14818_1_1.PNG',
        status: 1,
    },
    {
        id: 14826,
        name: 'First Bank and Trust (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14826_1_1.PNG',
        status: 1,
    },
    {
        id: 14829,
        name: 'Proponent Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14829_1_1.PNG',
        status: 1,
    },
    {
        id: 14833,
        name: 'Blackhawk Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14833_1_1.PNG',
        status: 1,
    },
    {
        id: 14834,
        name: 'State Bank of Chilton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14834_1_1.PNG',
        status: 1,
    },
    {
        id: 14837,
        name: 'Alden State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14839,
        name: 'Mid Penn Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14839_1_1.PNG',
        status: 1,
    },
    {
        id: 14843,
        name: 'EFCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14843_1_1.PNG',
        status: 1,
    },
    {
        id: 14876,
        name: 'Bank of Old Monroe',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14876_1_1.PNG',
        status: 1,
    },
    {
        id: 14880,
        name: 'Carlsbad National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14880_1_1.PNG',
        status: 1,
    },
    {
        id: 14884,
        name: 'Citizens and Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14884_1_1.PNG',
        status: 1,
    },
    {
        id: 14885,
        name: 'United Bank of Michigan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14885_1_1.PNG',
        status: 1,
    },
    {
        id: 14886,
        name: 'Northwest Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14886_1_1.PNG',
        status: 1,
    },
    {
        id: 14887,
        name: 'Home State Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14887_1_1.PNG',
        status: 1,
    },
    {
        id: 14889,
        name: 'Air Force Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14889_1_1.PNG',
        status: 1,
    },
    {
        id: 14913,
        name: 'Emprise Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14913_1_1.PNG',
        status: 1,
    },
    {
        id: 14931,
        name: 'Mercer County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14931_1_1.PNG',
        status: 1,
    },
    {
        id: 14959,
        name: 'First National Community Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14959_1_1.PNG',
        status: 1,
    },
    {
        id: 14960,
        name: 'First Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14960_1_1.PNG',
        status: 1,
    },
    {
        id: 14972,
        name: 'River Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14972_1_1.PNG',
        status: 1,
    },
    {
        id: 14973,
        name: 'Mansfield Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 14977,
        name: 'Skyward Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14977_1_1.PNG',
        status: 1,
    },
    {
        id: 14979,
        name: 'Citizens National Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14979_1_1.PNG',
        status: 1,
    },
    {
        id: 14987,
        name: 'The Liberty National Bank in Paris',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14987_1_1.PNG',
        status: 1,
    },
    {
        id: 14996,
        name: 'Bloom Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_14996_1_1.PNG',
        status: 1,
    },
    {
        id: 15003,
        name: 'The National Capital Bank of Washington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15003_1_1.PNG',
        status: 1,
    },
    {
        id: 15009,
        name: 'Webster First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15009_1_1.PNG',
        status: 1,
    },
    {
        id: 15012,
        name: 'Community West Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15012_1_1.PNG',
        status: 1,
    },
    {
        id: 15013,
        name: 'Flint Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15013_1_1.PNG',
        status: 1,
    },
    {
        id: 15023,
        name: 'Georgia Primary Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15023_1_1.PNG',
        status: 1,
    },
    {
        id: 15027,
        name: ' First United Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15027_1_1.PNG',
        status: 1,
    },
    {
        id: 15032,
        name: 'Lakestone Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15032_1_1.PNG',
        status: 1,
    },
    {
        id: 15035,
        name: 'Gulf Coast Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15035_1_1.PNG',
        status: 1,
    },
    {
        id: 15036,
        name: 'Collinsville Savings Society',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15036_1_1.PNG',
        status: 1,
    },
    {
        id: 15038,
        name: 'Diamond Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15038_1_1.PNG',
        status: 1,
    },
    {
        id: 15039,
        name: 'mBank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15039_1_1.PNG',
        status: 1,
    },
    {
        id: 15047,
        name: 'Community First Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 15049,
        name: 'The Henry County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15049_1_1.PNG',
        status: 1,
    },
    {
        id: 15050,
        name: 'Citizens Bank & Trust Company (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15050_1_1.PNG',
        status: 1,
    },
    {
        id: 15051,
        name: 'United Bank Bank (MA) Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15051_1_1.PNG',
        status: 1,
    },
    {
        id: 15052,
        name: 'Tech Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15052_1_1.PNG',
        status: 1,
    },
    {
        id: 15057,
        name: 'MainStreet Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15057_1_1.PNG',
        status: 1,
    },
    {
        id: 15058,
        name: 'Lake City Bank Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15058_1_1.PNG',
        status: 1,
    },
    {
        id: 15059,
        name: 'Security Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15059_1_1.PNG',
        status: 1,
    },
    {
        id: 15068,
        name: 'Centric Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15068_1_1.PNG',
        status: 1,
    },
    {
        id: 15070,
        name: 'Torrington Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15070_1_1.PNG',
        status: 1,
    },
    {
        id: 15098,
        name: 'Pinnacle Bank (Business - NE, KS, MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15098_1_1.PNG',
        status: 1,
    },
    {
        id: 15102,
        name: 'Republic Bank & Trust (FL & OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15102_1_1.PNG',
        status: 1,
    },
    {
        id: 15108,
        name: 'Wood & Huston Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15108_1_1.PNG',
        status: 1,
    },
    {
        id: 15111,
        name: 'Starion Financial Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15111_1_1.PNG',
        status: 1,
    },
    {
        id: 15112,
        name: 'Pinnacle Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15112_1_1.PNG',
        status: 1,
    },
    {
        id: 15116,
        name: 'Peoples Bank & Trust (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15116_1_1.PNG',
        status: 1,
    },
    {
        id: 15132,
        name: 'Community Bank & Trust (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15132_1_1.PNG',
        status: 1,
    },
    {
        id: 15147,
        name: 'Olean Area Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15147_1_1.PNG',
        status: 1,
    },
    {
        id: 15152,
        name: 'Andrews Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15152_1_1.PNG',
        status: 1,
    },
    {
        id: 15159,
        name: 'Appalachian Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15159_1_1.PNG',
        status: 1,
    },
    {
        id: 15161,
        name: 'Norwood Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15161_1_1.PNG',
        status: 1,
    },
    {
        id: 15166,
        name: 'Ixonia Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15166_1_1.PNG',
        status: 1,
    },
    {
        id: 15168,
        name: 'Howard Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15168_1_1.PNG',
        status: 1,
    },
    {
        id: 15179,
        name: 'Bay-Vanguard Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15179_1_1.PNG',
        status: 1,
    },
    {
        id: 15184,
        name: 'First Advantage Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15184_1_1.PNG',
        status: 1,
    },
    {
        id: 15185,
        name: 'Paramount Bank Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 15195,
        name: 'First Security State Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15195_1_1.PNG',
        status: 1,
    },
    {
        id: 15206,
        name: 'Heritage First Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 15207,
        name: 'Citizens National Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15207_1_1.PNG',
        status: 1,
    },
    {
        id: 15208,
        name: 'Metropolitan Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15208_1_1.PNG',
        status: 1,
    },
    {
        id: 15209,
        name: 'First Chatham Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15209_1_1.PNG',
        status: 1,
    },
    {
        id: 15243,
        name: 'Guaranty Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15243_1_1.PNG',
        status: 1,
    },
    {
        id: 15245,
        name: 'Quail Creek Bank, N.A.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15245_1_1.PNG',
        status: 1,
    },
    {
        id: 15265,
        name: 'Farmers & Merchants State Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15265_1_1.PNG',
        status: 1,
    },
    {
        id: 15269,
        name: 'Pioneer Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 15274,
        name: 'Seasons Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15274_1_1.PNG',
        status: 1,
    },
    {
        id: 15294,
        name: 'Security Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15294_1_1.PNG',
        status: 1,
    },
    {
        id: 15303,
        name: 'Arthur State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15303_1_1.PNG',
        status: 1,
    },
    {
        id: 15309,
        name: 'First Citrus Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15309_1_1.PNG',
        status: 1,
    },
    {
        id: 15339,
        name: 'BB&T Business Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15339_1_1.PNG',
        status: 1,
    },
    {
        id: 15340,
        name: 'Armstrong Associates Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15340_1_1.PNG',
        status: 1,
    },
    {
        id: 15341,
        name: 'Indiana First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15341_1_1.PNG',
        status: 1,
    },
    {
        id: 15343,
        name: 'Marion Center Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15343_1_1.PNG',
        status: 1,
    },
    {
        id: 15361,
        name: 'Benchmark Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15361_1_1.PNG',
        status: 1,
    },
    {
        id: 15368,
        name: 'First National Bank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15368_1_1.PNG',
        status: 1,
    },
    {
        id: 15438,
        name: 'Valley State Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15438_1_1.PNG',
        status: 1,
    },
    {
        id: 15442,
        name: 'R Bank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15442_1_1.PNG',
        status: 1,
    },
    {
        id: 15445,
        name: 'CREWS BANK & TRUST',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15445_1_1.PNG',
        status: 1,
    },
    {
        id: 15447,
        name: 'Home Federal Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15447_1_1.PNG',
        status: 1,
    },
    {
        id: 15450,
        name: 'Oklahoma Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15450_1_1.PNG',
        status: 1,
    },
    {
        id: 15494,
        name: 'NRL Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15494_1_1.PNG',
        status: 1,
    },
    {
        id: 15495,
        name: 'Firefighters First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15495_1_1.PNG',
        status: 1,
    },
    {
        id: 15522,
        name: 'Southwest Louisiana CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15522_1_1.PNG',
        status: 1,
    },
    {
        id: 15535,
        name: 'ESB Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15535_1_1.PNG',
        status: 1,
    },
    {
        id: 15541,
        name: 'FNB of Winnsboro',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15541_1_1.PNG',
        status: 1,
    },
    {
        id: 15544,
        name: 'German American State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15544_1_1.PNG',
        status: 1,
    },
    {
        id: 15549,
        name: 'Heritage Bank of the Ozarks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15549_1_1.PNG',
        status: 1,
    },
    {
        id: 15566,
        name: 'Mayo Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15566_1_1.PNG',
        status: 1,
    },
    {
        id: 15605,
        name: 'Farmers Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15605_1_1.PNG',
        status: 1,
    },
    {
        id: 15606,
        name: 'Relyance Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15606_1_1.PNG',
        status: 1,
    },
    {
        id: 15629,
        name: 'Traditional Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15629_1_1.PNG',
        status: 1,
    },
    {
        id: 15631,
        name: 'Peoples Bank of East Tennessee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15631_1_1.PNG',
        status: 1,
    },
    {
        id: 15634,
        name: 'Charlotte State Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15634_1_1.PNG',
        status: 1,
    },
    {
        id: 15638,
        name: 'Fairport Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15638_1_1.PNG',
        status: 1,
    },
    {
        id: 15640,
        name: 'Herring Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15640_1_1.PNG',
        status: 1,
    },
    {
        id: 15663,
        name: 'Citizens National Bank (Sevier, Jefferson, & Knox, TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15663_1_1.PNG',
        status: 1,
    },
    {
        id: 15678,
        name: 'Boston Private',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15678_1_1.PNG',
        status: 1,
    },
    {
        id: 15692,
        name: 'Citizens Bank & Trust Co. of Vivian',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15692_1_1.PNG',
        status: 1,
    },
    {
        id: 15693,
        name: 'Ideal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15693_1_1.PNG',
        status: 1,
    },
    {
        id: 15702,
        name: 'Incommons Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15702_1_1.PNG',
        status: 1,
    },
    {
        id: 15703,
        name: 'Connexus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15703_1_1.PNG',
        status: 1,
    },
    {
        id: 15705,
        name: 'Chickasaw Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15705_1_2.SVG',
        status: 1,
    },
    {
        id: 15707,
        name: 'Wilson & Muir Bank & Trust Co',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15707_1_1.PNG',
        status: 1,
    },
    {
        id: 15711,
        name: 'Treynor State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15711_1_1.PNG',
        status: 1,
    },
    {
        id: 15714,
        name: 'Nicolet National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15714_1_1.PNG',
        status: 1,
    },
    {
        id: 15717,
        name: 'BTH Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15717_1_1.PNG',
        status: 1,
    },
    {
        id: 15721,
        name: 'MCS Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15721_1_1.PNG',
        status: 1,
    },
    {
        id: 15724,
        name: 'Acclaim Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15724_1_1.PNG',
        status: 1,
    },
    {
        id: 15725,
        name: 'Legend Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15725_1_1.PNG',
        status: 1,
    },
    {
        id: 15726,
        name: 'Monroe County Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15726_1_1.PNG',
        status: 1,
    },
    {
        id: 15727,
        name: 'Iowa State Bank (except Remsen, IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15727_1_1.PNG',
        status: 1,
    },
    {
        id: 15742,
        name: 'Pelican State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15742_1_1.PNG',
        status: 1,
    },
    {
        id: 15752,
        name: 'TwoRiver Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15752_1_1.PNG',
        status: 1,
    },
    {
        id: 15768,
        name: 'City National Bank (NJ, NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15768_1_1.PNG',
        status: 1,
    },
    {
        id: 15792,
        name: 'Raritan Bay Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15792_1_1.PNG',
        status: 1,
    },
    {
        id: 15802,
        name: 'Bank of Commerce (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15802_1_1.PNG',
        status: 1,
    },
    {
        id: 15803,
        name: 'BankFirst',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15803_1_1.PNG',
        status: 1,
    },
    {
        id: 15809,
        name: 'CNB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15809_1_1.PNG',
        status: 1,
    },
    {
        id: 15826,
        name: 'AccessBank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15826_1_1.PNG',
        status: 1,
    },
    {
        id: 15838,
        name: 'Shore United Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15838_1_1.PNG',
        status: 1,
    },
    {
        id: 15875,
        name: 'Pinellas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15875_1_1.PNG',
        status: 1,
    },
    {
        id: 15888,
        name: 'First National Bank of Wauchula',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15888_1_1.PNG',
        status: 1,
    },
    {
        id: 15892,
        name: 'Citizens Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15892_1_1.PNG',
        status: 1,
    },
    {
        id: 15914,
        name: 'Philadelphia Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15914_1_1.PNG',
        status: 1,
    },
    {
        id: 15925,
        name: 'NobleBank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15925_1_1.PNG',
        status: 1,
    },
    {
        id: 15984,
        name: 'Citizens Bank and Trust (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15984_1_1.PNG',
        status: 1,
    },
    {
        id: 15985,
        name: 'Texas Regional Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15985_1_1.PNG',
        status: 1,
    },
    {
        id: 15996,
        name: 'Heritage Bank of Commerce (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 15998,
        name: 'F&M Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_15998_1_1.PNG',
        status: 1,
    },
    {
        id: 16059,
        name: 'Evergreen Bank Group',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16059_1_1.PNG',
        status: 1,
    },
    {
        id: 16063,
        name: 'Citizens Bank & Trust (FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16063_1_1.PNG',
        status: 1,
    },
    {
        id: 16075,
        name: 'Ultima Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16075_1_1.PNG',
        status: 1,
    },
    {
        id: 16097,
        name: 'American Pride Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16097_1_1.PNG',
        status: 1,
    },
    {
        id: 16100,
        name: 'Cornerstone Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16100_1_1.PNG',
        status: 1,
    },
    {
        id: 16104,
        name: 'Spirit of Alaska Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16104_1_1.PNG',
        status: 1,
    },
    {
        id: 16107,
        name: 'Betterment',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16107_1_2.SVG',
        status: 1,
    },
    {
        id: 16125,
        name: 'Advantage Plus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16125_1_1.PNG',
        status: 1,
    },
    {
        id: 16129,
        name: '1st United Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16129_1_1.PNG',
        status: 1,
    },
    {
        id: 16133,
        name: 'SVB Private Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16133_1_1.PNG',
        status: 1,
    },
    {
        id: 16190,
        name: 'Affinity Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16190_1_1.PNG',
        status: 1,
    },
    {
        id: 16204,
        name: 'SC State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 16224,
        name: 'First National Bank and Trust Co. of Ardmore',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16224_1_1.PNG',
        status: 1,
    },
    {
        id: 16248,
        name: '1st Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16248_1_1.PNG',
        status: 1,
    },
    {
        id: 16266,
        name: 'Legacy Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16266_1_1.PNG',
        status: 1,
    },
    {
        id: 16270,
        name: 'The First National Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16270_1_1.PNG',
        status: 1,
    },
    {
        id: 16279,
        name: 'Sycamore Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16279_1_1.PNG',
        status: 1,
    },
    {
        id: 16282,
        name: 'Sound Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16282_1_1.PNG',
        status: 1,
    },
    {
        id: 16325,
        name: 'Barclays USA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16325_1_2.SVG',
        status: 1,
    },
    {
        id: 16341,
        name: 'Minnco Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16341_1_1.PNG',
        status: 1,
    },
    {
        id: 16353,
        name: 'Teutopolis State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16353_1_1.PNG',
        status: 1,
    },
    {
        id: 16373,
        name: 'First Utah Bank (Personal Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16373_1_1.PNG',
        status: 1,
    },
    {
        id: 16378,
        name: 'Family Savings Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16378_1_1.PNG',
        status: 1,
    },
    {
        id: 16389,
        name: 'The First National Bank of Talladega (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16389_1_1.PNG',
        status: 1,
    },
    {
        id: 16403,
        name: 'First National Bank of Griffin (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 16417,
        name: 'Bank of Moundville (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16417_1_1.PNG',
        status: 1,
    },
    {
        id: 16438,
        name: 'Acadia Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16438_1_1.PNG',
        status: 1,
    },
    {
        id: 16476,
        name: 'Quincy Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16476_1_1.PNG',
        status: 1,
    },
    {
        id: 16485,
        name: 'Oak Valley Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16485_1_1.PNG',
        status: 1,
    },
    {
        id: 16514,
        name: 'MTC FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16514_1_1.PNG',
        status: 1,
    },
    {
        id: 16520,
        name: 'Del-One Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16520_1_1.PNG',
        status: 1,
    },
    {
        id: 16524,
        name: 'Mountain Valley (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16524_1_1.PNG',
        status: 1,
    },
    {
        id: 16608,
        name: 'Bank of England',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16608_1_1.PNG',
        status: 1,
    },
    {
        id: 16647,
        name: 'Birmingham Bloomfield Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16647_1_1.PNG',
        status: 1,
    },
    {
        id: 16875,
        name: 'Burke & Herbert Cash Management Account',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16875_1_1.PNG',
        status: 1,
    },
    {
        id: 16876,
        name: 'Stearns Bank NA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16876_1_1.PNG',
        status: 1,
    },
    {
        id: 16889,
        name: 'Simple',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16889_1_2.SVG',
        status: 1,
    },
    {
        id: 16890,
        name: 'Denver Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16890_1_1.PNG',
        status: 1,
    },
    {
        id: 16901,
        name: 'The Westchester Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16901_1_1.PNG',
        status: 1,
    },
    {
        id: 16966,
        name: 'Rosedale Federal Savings & Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_16966_1_1.PNG',
        status: 1,
    },
    {
        id: 17182,
        name: 'Avenue Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17182_1_1.PNG',
        status: 1,
    },
    {
        id: 17194,
        name: 'Mechanics Bank Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17194_1_1.PNG',
        status: 1,
    },
    {
        id: 17197,
        name: 'The Peoples Bank of Kent County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17197_1_1.PNG',
        status: 1,
    },
    {
        id: 17201,
        name: 'Teachers Federal Credit Union (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17201_1_1.PNG',
        status: 1,
    },
    {
        id: 17215,
        name: 'Tandem Federal Credit Union (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17215_1_1.PNG',
        status: 1,
    },
    {
        id: 17235,
        name: 'Community First Bank Personal Banking (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17235_1_1.PNG',
        status: 1,
    },
    {
        id: 17276,
        name: 'BluCurrent Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17276_1_1.PNG',
        status: 1,
    },
    {
        id: 17278,
        name: 'Merced School Employee Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17278_1_1.PNG',
        status: 1,
    },
    {
        id: 17289,
        name: 'Bellwether Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17289_1_1.PNG',
        status: 1,
    },
    {
        id: 17462,
        name: 'Southland Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17462_1_1.PNG',
        status: 1,
    },
    {
        id: 17465,
        name: 'Gain Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17465_1_1.PNG',
        status: 1,
    },
    {
        id: 17481,
        name: 'PeoplesBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 17507,
        name: 'Premier Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17507_1_1.PNG',
        status: 1,
    },
    {
        id: 17509,
        name: 'Sentinel Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17509_1_1.PNG',
        status: 1,
    },
    {
        id: 17574,
        name: 'Haven Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17574_1_1.PNG',
        status: 1,
    },
    {
        id: 17577,
        name: 'UMe Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17577_1_1.PNG',
        status: 1,
    },
    {
        id: 17645,
        name: 'Guaranty Bank & Trust Company (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17645_1_1.PNG',
        status: 1,
    },
    {
        id: 17649,
        name: 'Avidia (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17649_1_1.PNG',
        status: 1,
    },
    {
        id: 17650,
        name: 'Citizens Bank & Trust (Chillicothe, MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17650_1_1.PNG',
        status: 1,
    },
    {
        id: 17652,
        name: 'Bright Star Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17652_1_1.PNG',
        status: 1,
    },
    {
        id: 17655,
        name: '360 Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17655_1_1.PNG',
        status: 1,
    },
    {
        id: 17656,
        name: 'Crown',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17656_1_1.PNG',
        status: 1,
    },
    {
        id: 17661,
        name: 'Canyon Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17661_1_1.PNG',
        status: 1,
    },
    {
        id: 17673,
        name: 'Waterbury CT Teachers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17673_1_1.PNG',
        status: 1,
    },
    {
        id: 17690,
        name: "Chicago Patrolmen's Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17690_1_1.PNG',
        status: 1,
    },
    {
        id: 17696,
        name: 'Community Bank (MS, AL, TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17696_1_1.PNG',
        status: 1,
    },
    {
        id: 17708,
        name: 'Wellesley',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17708_1_1.PNG',
        status: 1,
    },
    {
        id: 17711,
        name: 'Bank of the Ozarks (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17711_1_1.PNG',
        status: 1,
    },
    {
        id: 17712,
        name: 'Western Bank (Formerly Stonebridge)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 17718,
        name: "People's United Bank Business Banking",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17718_1_1.PNG',
        status: 1,
    },
    {
        id: 17729,
        name: 'Fidelity Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17729_1_1.PNG',
        status: 1,
    },
    {
        id: 17746,
        name: 'Southwest Research Center Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17746_1_1.PNG',
        status: 1,
    },
    {
        id: 17768,
        name: 'First Convenience Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17768_1_1.PNG',
        status: 1,
    },
    {
        id: 17789,
        name: 'Woodlands Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17789_1_1.PNG',
        status: 1,
    },
    {
        id: 17796,
        name: 'AmTrust Direct',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 17799,
        name: 'Mauch Chunk Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17799_1_1.PNG',
        status: 1,
    },
    {
        id: 17801,
        name: 'People First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17801_1_1.PNG',
        status: 1,
    },
    {
        id: 17808,
        name: 'Inland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17808_1_1.PNG',
        status: 1,
    },
    {
        id: 17809,
        name: 'Sandy Spring Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17809_1_1.PNG',
        status: 1,
    },
    {
        id: 17821,
        name: 'Ukrainian Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17821_1_1.PNG',
        status: 1,
    },
    {
        id: 17832,
        name: 'Bonneville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17832_1_1.PNG',
        status: 1,
    },
    {
        id: 17834,
        name: 'Cedar Point Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17834_1_1.PNG',
        status: 1,
    },
    {
        id: 17836,
        name: 'Huntington Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17836_1_1.PNG',
        status: 1,
    },
    {
        id: 17840,
        name: 'Jefferson Bank of Missouri Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17840_1_1.PNG',
        status: 1,
    },
    {
        id: 17853,
        name: 'Member One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17853_1_1.PNG',
        status: 1,
    },
    {
        id: 17855,
        name: 'Unico Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17855_1_1.PNG',
        status: 1,
    },
    {
        id: 17859,
        name: 'National Bank of Arizona Business Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17859_1_1.PNG',
        status: 1,
    },
    {
        id: 17864,
        name: 'First Federal Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 17865,
        name: 'Paragon National',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17865_1_1.PNG',
        status: 1,
    },
    {
        id: 17866,
        name: 'First State Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17866_1_1.PNG',
        status: 1,
    },
    {
        id: 17868,
        name: 'Assemblies of God Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17868_1_1.PNG',
        status: 1,
    },
    {
        id: 17870,
        name: 'First Commerce Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17870_1_1.PNG',
        status: 1,
    },
    {
        id: 17877,
        name: 'Mountain Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17877_1_1.PNG',
        status: 1,
    },
    {
        id: 17881,
        name: 'Bank of Kirksville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17881_1_1.PNG',
        status: 1,
    },
    {
        id: 17889,
        name: 'American Bank & Trust (SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17889_1_1.PNG',
        status: 1,
    },
    {
        id: 17891,
        name: 'Kennett National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17891_1_1.PNG',
        status: 1,
    },
    {
        id: 17896,
        name: 'H&R Block Emerald Prepaid Card',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17896_1_1.PNG',
        status: 1,
    },
    {
        id: 17910,
        name: 'Bank of Travelers Rest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17910_1_1.PNG',
        status: 1,
    },
    {
        id: 17916,
        name: 'Bank of San Antonio',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17916_1_1.PNG',
        status: 1,
    },
    {
        id: 17923,
        name: 'SFGI Direct',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17923_1_1.PNG',
        status: 1,
    },
    {
        id: 17926,
        name: 'CashAdvantage',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17926_1_1.PNG',
        status: 1,
    },
    {
        id: 17934,
        name: 'Cross Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17934_1_1.PNG',
        status: 1,
    },
    {
        id: 17936,
        name: 'Tennessee Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17936_1_1.PNG',
        status: 1,
    },
    {
        id: 17937,
        name: 'Carroll Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17937_1_1.PNG',
        status: 1,
    },
    {
        id: 17939,
        name: 'Chemco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17939_1_1.PNG',
        status: 1,
    },
    {
        id: 17941,
        name: 'Utilities Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 17942,
        name: 'AEA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17942_1_1.PNG',
        status: 1,
    },
    {
        id: 17946,
        name: 'INTRUST Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17946_1_1.PNG',
        status: 1,
    },
    {
        id: 17949,
        name: 'Torrey Pines Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17949_1_1.PNG',
        status: 1,
    },
    {
        id: 17951,
        name: 'Torrey Pines Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17951_1_1.PNG',
        status: 1,
    },
    {
        id: 17960,
        name: 'First Florida Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17960_1_1.PNG',
        status: 1,
    },
    {
        id: 17962,
        name: 'Ericson State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17962_1_1.PNG',
        status: 1,
    },
    {
        id: 17968,
        name: 'Triumph (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 17976,
        name: 'Chief Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17976_1_1.PNG',
        status: 1,
    },
    {
        id: 17980,
        name: 'Horizon Bank (Indiana)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17980_1_1.PNG',
        status: 1,
    },
    {
        id: 17985,
        name: 'Midwest Heritage Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17985_1_1.PNG',
        status: 1,
    },
    {
        id: 17988,
        name: 'Truxton Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17988_1_1.PNG',
        status: 1,
    },
    {
        id: 17996,
        name: 'First Bank of Boaz',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17996_1_1.PNG',
        status: 1,
    },
    {
        id: 17999,
        name: 'The State Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_17999_1_1.PNG',
        status: 1,
    },
    {
        id: 18002,
        name: 'Citizens Bank of Alabama',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18002_1_1.PNG',
        status: 1,
    },
    {
        id: 18005,
        name: 'F&M Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18008,
        name: 'CommunityBank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18008_1_1.PNG',
        status: 1,
    },
    {
        id: 18010,
        name: 'The Bank of Herrin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18010_1_1.PNG',
        status: 1,
    },
    {
        id: 18015,
        name: 'Legacy Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18017,
        name: 'Athol Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18028,
        name: 'Luzerne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18028_1_1.PNG',
        status: 1,
    },
    {
        id: 18029,
        name: 'First National Bank of Walker (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18036,
        name: 'BankTennessee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18036_1_1.PNG',
        status: 1,
    },
    {
        id: 18037,
        name: 'United Bank of Kentucky',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18037_1_1.PNG',
        status: 1,
    },
    {
        id: 18039,
        name: 'Taunton Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18039_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 18041,
        name: 'VSECU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18041_1_1.PNG',
        status: 1,
    },
    {
        id: 18053,
        name: 'Red River Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18053_1_1.PNG',
        status: 1,
    },
    {
        id: 18056,
        name: 'The Piedmont Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18056_1_1.PNG',
        status: 1,
    },
    {
        id: 18057,
        name: 'Genco FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18057_1_1.PNG',
        status: 1,
    },
    {
        id: 18059,
        name: 'Citizens Community Bank (ID)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18059_1_1.PNG',
        status: 1,
    },
    {
        id: 18061,
        name: 'Marcus by Goldman Sachs',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18061_1_2.SVG',
        status: 1,
    },
    {
        id: 18062,
        name: 'DATCU Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18062_1_1.PNG',
        status: 1,
    },
    {
        id: 18064,
        name: 'NextMark Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18064_1_1.PNG',
        status: 1,
    },
    {
        id: 18069,
        name: 'Bank of Maysville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18076,
        name: 'Able Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18084,
        name: 'Century Bank (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18084_1_1.PNG',
        status: 1,
    },
    {
        id: 18087,
        name: 'American Bank & Trust Company (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18087_1_1.PNG',
        status: 1,
    },
    {
        id: 18090,
        name: 'Target Credit Union (Division of BCU)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18090_1_1.PNG',
        status: 1,
    },
    {
        id: 18091,
        name: 'TIAA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18091_1_2.SVG',
        status: 1,
    },
    {
        id: 18093,
        name: 'Mega Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18093_1_1.PNG',
        status: 1,
    },
    {
        id: 18094,
        name: 'FNBT',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18094_1_1.PNG',
        status: 1,
    },
    {
        id: 18095,
        name: 'First National Bank of Omaha (Business Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18095_1_1.PNG',
        status: 1,
    },
    {
        id: 18100,
        name: 'Patriot Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18100_1_1.PNG',
        status: 1,
    },
    {
        id: 18112,
        name: 'First Citizens National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18112_1_1.PNG',
        status: 1,
    },
    {
        id: 18116,
        name: 'Metcalf Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18116_1_1.PNG',
        status: 1,
    },
    {
        id: 18123,
        name: 'Autotruck Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18123_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 18125,
        name: 'SF Police Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18125_1_1.PNG',
        status: 1,
    },
    {
        id: 18127,
        name: 'Ohnward Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18127_1_1.PNG',
        status: 1,
    },
    {
        id: 18129,
        name: 'Heritage Bank (MN,IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18129_1_1.PNG',
        status: 1,
    },
    {
        id: 18134,
        name: 'First National Bank of Milaca',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18134_1_1.PNG',
        status: 1,
    },
    {
        id: 18143,
        name: 'Ocean Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18143_1_1.PNG',
        status: 1,
    },
    {
        id: 18145,
        name: 'First Financial Federal Credit Union (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18145_1_1.PNG',
        status: 1,
    },
    {
        id: 18147,
        name: 'First Trust Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18147_1_1.PNG',
        status: 1,
    },
    {
        id: 18151,
        name: 'Southeastern FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18151_1_1.PNG',
        status: 1,
    },
    {
        id: 18161,
        name: 'Matadors Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18161_1_1.PNG',
        status: 1,
    },
    {
        id: 18162,
        name: 'Kemba Louisville Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18162_1_1.PNG',
        status: 1,
    },
    {
        id: 18164,
        name: 'Navigator Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18164_1_1.PNG',
        status: 1,
    },
    {
        id: 18177,
        name: 'First Service Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18177_1_1.PNG',
        status: 1,
    },
    {
        id: 18179,
        name: 'First National Bank and Trust Company (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18179_1_1.PNG',
        status: 1,
    },
    {
        id: 18180,
        name: 'Deepwater Industries Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18180_1_1.PNG',
        status: 1,
    },
    {
        id: 18186,
        name: 'Greater Iowa Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18186_1_1.PNG',
        status: 1,
    },
    {
        id: 18190,
        name: "Niagara's Choice Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18190_1_1.PNG',
        status: 1,
    },
    {
        id: 18194,
        name: 'First Service Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18194_1_1.PNG',
        status: 1,
    },
    {
        id: 18202,
        name: 'Central Bank of Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18202_1_1.PNG',
        status: 1,
    },
    {
        id: 18205,
        name: 'NuMark Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18205_1_1.PNG',
        status: 1,
    },
    {
        id: 18210,
        name: 'Vectra Bank (Business - CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18210_1_1.PNG',
        status: 1,
    },
    {
        id: 18219,
        name: 'InvesTex Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18219_1_1.PNG',
        status: 1,
    },
    {
        id: 18222,
        name: 'Salem Co-operative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18222_1_1.PNG',
        status: 1,
    },
    {
        id: 18223,
        name: 'OE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18223_1_1.PNG',
        status: 1,
    },
    {
        id: 18224,
        name: 'K - State FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18224_1_1.PNG',
        status: 1,
    },
    {
        id: 18233,
        name: 'Sidney Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18233_1_1.PNG',
        status: 1,
    },
    {
        id: 18235,
        name: 'Mutual Savings Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18235_1_1.PNG',
        status: 1,
    },
    {
        id: 18240,
        name: 'Jonah Bank of Wyoming',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18240_1_1.PNG',
        status: 1,
    },
    {
        id: 18248,
        name: 'Prospera Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18248_1_1.PNG',
        status: 1,
    },
    {
        id: 18249,
        name: 'Excel Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18249_1_1.PNG',
        status: 1,
    },
    {
        id: 18256,
        name: 'HomeTown Bank of Alabama',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18256_1_1.PNG',
        status: 1,
    },
    {
        id: 18257,
        name: 'Southern Lakes Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18257_1_1.PNG',
        status: 1,
    },
    {
        id: 18258,
        name: 'First International Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18258_1_1.PNG',
        status: 1,
    },
    {
        id: 18259,
        name: 'Healthcare Systems Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18259_1_1.PNG',
        status: 1,
    },
    {
        id: 18261,
        name: 'Old West Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18261_1_1.PNG',
        status: 1,
    },
    {
        id: 18262,
        name: 'First Mid Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18262_1_1.PNG',
        status: 1,
    },
    {
        id: 18265,
        name: 'First State Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18265_1_1.PNG',
        status: 1,
    },
    {
        id: 18266,
        name: 'Harrison County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18266_1_1.PNG',
        status: 1,
    },
    {
        id: 18267,
        name: 'Union Bank (WV)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18267_1_1.PNG',
        status: 1,
    },
    {
        id: 18270,
        name: 'First National Bank North Platte',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18270_1_1.PNG',
        status: 1,
    },
    {
        id: 18291,
        name: 'Democracy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18291_1_1.PNG',
        status: 1,
    },
    {
        id: 18292,
        name: 'NorState Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18292_1_1.PNG',
        status: 1,
    },
    {
        id: 18293,
        name: 'Trona Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18293_1_1.PNG',
        status: 1,
    },
    {
        id: 18296,
        name: 'CapStar Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18296_1_1.PNG',
        status: 1,
    },
    {
        id: 18302,
        name: 'Security Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18302_1_1.PNG',
        status: 1,
    },
    {
        id: 18303,
        name: 'Freedom First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18303_1_1.PNG',
        status: 1,
    },
    {
        id: 18308,
        name: 'Midland States Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18308_1_1.PNG',
        status: 1,
    },
    {
        id: 18311,
        name: 'Financial Edge Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18311_1_1.PNG',
        status: 1,
    },
    {
        id: 18312,
        name: 'Community National Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18312_1_1.PNG',
        status: 1,
    },
    {
        id: 18313,
        name: 'Centennial Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18313_1_1.PNG',
        status: 1,
    },
    {
        id: 18315,
        name: 'Security Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18315_1_1.PNG',
        status: 1,
    },
    {
        id: 18316,
        name: 'Marine CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18316_1_1.PNG',
        status: 1,
    },
    {
        id: 18318,
        name: 'Embers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18318_1_1.PNG',
        status: 1,
    },
    {
        id: 18320,
        name: 'Citizens Bank of Kansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18320_1_1.PNG',
        status: 1,
    },
    {
        id: 18321,
        name: 'Keys FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18321_1_1.PNG',
        status: 1,
    },
    {
        id: 18329,
        name: 'Vermont Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18329_1_1.PNG',
        status: 1,
    },
    {
        id: 18332,
        name: 'Needham Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18332_1_1.PNG',
        status: 1,
    },
    {
        id: 18333,
        name: 'Edmonton State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18333_1_1.PNG',
        status: 1,
    },
    {
        id: 18334,
        name: 'Monticello Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18334_1_1.PNG',
        status: 1,
    },
    {
        id: 18336,
        name: 'Hancock Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18336_1_1.PNG',
        status: 1,
    },
    {
        id: 18344,
        name: 'Amarillo Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18344_1_1.PNG',
        status: 1,
    },
    {
        id: 18346,
        name: 'First Foundation Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18346_1_1.PNG',
        status: 1,
    },
    {
        id: 18347,
        name: 'WEOKIE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18347_1_1.PNG',
        status: 1,
    },
    {
        id: 18352,
        name: 'First Bank Kansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18352_1_1.PNG',
        status: 1,
    },
    {
        id: 18362,
        name: 'Southern Bank (N.C.)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18362_1_1.PNG',
        status: 1,
    },
    {
        id: 18363,
        name: 'Park Side Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18363_1_1.PNG',
        status: 1,
    },
    {
        id: 18382,
        name: 'Citizens Business Bank(CBank Online)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18382_1_1.PNG',
        status: 1,
    },
    {
        id: 18383,
        name: 'Phelps County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18383_1_1.PNG',
        status: 1,
    },
    {
        id: 18385,
        name: 'The First National Bank of Dennison',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18385_1_1.PNG',
        status: 1,
    },
    {
        id: 18387,
        name: 'Advantage Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18387_1_1.PNG',
        status: 1,
    },
    {
        id: 18388,
        name: 'Sibley State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18388_1_1.PNG',
        status: 1,
    },
    {
        id: 18394,
        name: 'Callaway Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18394_1_1.PNG',
        status: 1,
    },
    {
        id: 18396,
        name: 'United Fidelity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18396_1_1.PNG',
        status: 1,
    },
    {
        id: 18401,
        name: 'Embark Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18401_1_1.PNG',
        status: 1,
    },
    {
        id: 18402,
        name: 'Maine Savings Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18402_1_1.PNG',
        status: 1,
    },
    {
        id: 18407,
        name: 'ABNB Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18407_1_1.PNG',
        status: 1,
    },
    {
        id: 18414,
        name: 'Telco Comunity Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18414_1_1.PNG',
        status: 1,
    },
    {
        id: 18415,
        name: 'The Bank of Tampa (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18415_1_1.PNG',
        status: 1,
    },
    {
        id: 18417,
        name: 'Pantex FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18417_1_1.PNG',
        status: 1,
    },
    {
        id: 18419,
        name: 'ESL Federal Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18419_1_1.PNG',
        status: 1,
    },
    {
        id: 18422,
        name: 'FSUCU.org',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18422_1_1.PNG',
        status: 1,
    },
    {
        id: 18426,
        name: 'American Bank & Trust of the Cumberlands',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18426_1_1.PNG',
        status: 1,
    },
    {
        id: 18429,
        name: 'Simplicity Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18429_1_1.PNG',
        status: 1,
    },
    {
        id: 18433,
        name: 'TCF Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18433_1_2.SVG',
        status: 1,
    },
    {
        id: 18435,
        name: 'Utah Power Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18435_1_1.PNG',
        status: 1,
    },
    {
        id: 18436,
        name: 'CenterState (Cash Management)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18436_1_1.PNG',
        status: 1,
    },
    {
        id: 18439,
        name: 'Resource Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18439_1_1.PNG',
        status: 1,
    },
    {
        id: 18444,
        name: 'Parkway Bank & Trust Co. (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18444_1_1.PNG',
        status: 1,
    },
    {
        id: 18446,
        name: 'The Bank of Princeton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18446_1_1.PNG',
        status: 1,
    },
    {
        id: 18452,
        name: 'Hanscom Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18452_1_1.PNG',
        status: 1,
    },
    {
        id: 18454,
        name: 'Ascentra Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18454_1_1.PNG',
        status: 1,
    },
    {
        id: 18457,
        name: 'Puget Sound Cooperative Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18457_1_1.PNG',
        status: 1,
    },
    {
        id: 18459,
        name: 'Bank of Internet Federal(Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18459_1_1.PNG',
        status: 1,
    },
    {
        id: 18461,
        name: 'Mountain West Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18461_1_1.PNG',
        status: 1,
    },
    {
        id: 18464,
        name: 'Bank of Ann Arbor (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18464_1_1.PNG',
        status: 1,
    },
    {
        id: 18465,
        name: 'Cornerstone Bank (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18465_1_1.PNG',
        status: 1,
    },
    {
        id: 18466,
        name: "The People's Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 18468,
        name: 'Golden Belt Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18468_1_1.PNG',
        status: 1,
    },
    {
        id: 18471,
        name: 'TAB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18471_1_1.PNG',
        status: 1,
    },
    {
        id: 18474,
        name: 'Members First Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18474_1_1.PNG',
        status: 1,
    },
    {
        id: 18485,
        name: 'Unison Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18485_1_1.PNG',
        status: 1,
    },
    {
        id: 18495,
        name: 'Jonestown Bank & Trust Co. (JBT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18495_1_1.PNG',
        status: 1,
    },
    {
        id: 18497,
        name: 'White Crown Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18497_1_1.PNG',
        status: 1,
    },
    {
        id: 18499,
        name: 'Peoples Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18499_1_1.PNG',
        status: 1,
    },
    {
        id: 18504,
        name: 'Community First Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18504_1_1.PNG',
        status: 1,
    },
    {
        id: 18512,
        name: 'Tri Counties Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18512_1_1.PNG',
        status: 1,
    },
    {
        id: 18517,
        name: 'First Priority Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18517_1_1.PNG',
        status: 1,
    },
    {
        id: 18519,
        name: 'Charlevoix State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18519_1_1.PNG',
        status: 1,
    },
    {
        id: 18521,
        name: 'Square 1 Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18521_1_1.PNG',
        status: 1,
    },
    {
        id: 18523,
        name: 'First Midwest Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18523_1_1.PNG',
        status: 1,
    },
    {
        id: 18525,
        name: 'Alliance Bank of Arizona (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18525_1_1.PNG',
        status: 1,
    },
    {
        id: 18532,
        name: 'MidFirst Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18532_1_1.PNG',
        status: 1,
    },
    {
        id: 18533,
        name: 'Peoples Bank (Consumer Internet Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18533_1_1.PNG',
        status: 1,
    },
    {
        id: 18534,
        name: 'Fibre Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18534_1_1.PNG',
        status: 1,
    },
    {
        id: 18535,
        name: 'First National Bank & Trust (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18535_1_1.PNG',
        status: 1,
    },
    {
        id: 18538,
        name: 'Forward Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18538_1_1.PNG',
        status: 1,
    },
    {
        id: 18540,
        name: 'Metro Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18540_1_1.PNG',
        status: 1,
    },
    {
        id: 18546,
        name: 'Community Bank of the Bay',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18546_1_1.PNG',
        status: 1,
    },
    {
        id: 18554,
        name: 'Yolo Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18554_1_1.PNG',
        status: 1,
    },
    {
        id: 18556,
        name: 'SouthEast Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18556_1_1.PNG',
        status: 1,
    },
    {
        id: 18569,
        name: 'Rocky Mountain Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18569_1_1.PNG',
        status: 1,
    },
    {
        id: 18570,
        name: 'AdventHealth Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18570_1_1.PNG',
        status: 1,
    },
    {
        id: 18571,
        name: 'South Metro Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18571_1_1.PNG',
        status: 1,
    },
    {
        id: 18574,
        name: 'LowellFive Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18574_1_1.PNG',
        status: 1,
    },
    {
        id: 18576,
        name: 'TEFederal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18576_1_1.PNG',
        status: 1,
    },
    {
        id: 18579,
        name: 'TruPartner Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18579_1_1.PNG',
        status: 1,
    },
    {
        id: 18580,
        name: 'CASE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18580_1_1.PNG',
        status: 1,
    },
    {
        id: 18583,
        name: 'North Shore Community Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18583_1_1.PNG',
        status: 1,
    },
    {
        id: 18585,
        name: 'Cardinal Community',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18585_1_1.PNG',
        status: 1,
    },
    {
        id: 18591,
        name: 'Coosa Valley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18591_1_1.PNG',
        status: 1,
    },
    {
        id: 18595,
        name: 'Allied Healthcare Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18595_1_1.PNG',
        status: 1,
    },
    {
        id: 18597,
        name: 'Citizens Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18597_1_1.PNG',
        status: 1,
    },
    {
        id: 18598,
        name: 'First National Bank of Germantown',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18598_1_1.PNG',
        status: 1,
    },
    {
        id: 18600,
        name: 'Weber State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18600_1_1.PNG',
        status: 1,
    },
    {
        id: 18602,
        name: 'Powell Valley National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18602_1_1.PNG',
        status: 1,
    },
    {
        id: 18609,
        name: 'State Bank of Lizton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18609_1_1.PNG',
        status: 1,
    },
    {
        id: 18613,
        name: 'Cumberland Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18613_1_1.PNG',
        status: 1,
    },
    {
        id: 18614,
        name: 'Farmers & Merchants Union Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18614_1_1.PNG',
        status: 1,
    },
    {
        id: 18617,
        name: 'State Bank Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18617_1_1.PNG',
        status: 1,
    },
    {
        id: 18620,
        name: 'Community Bank of Pickens County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18620_1_1.PNG',
        status: 1,
    },
    {
        id: 18621,
        name: 'Woodsville Guaranty Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18621_1_1.PNG',
        status: 1,
    },
    {
        id: 18622,
        name: 'General Electric Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18622_1_1.PNG',
        status: 1,
    },
    {
        id: 18625,
        name: 'Heartland Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18625_1_2.SVG',
        status: 1,
    },
    {
        id: 18626,
        name: 'First Bank of Berne',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18626_1_1.PNG',
        status: 1,
    },
    {
        id: 18627,
        name: 'Washington Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18627_1_1.PNG',
        status: 1,
    },
    {
        id: 18628,
        name: 'American United Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18628_1_2.SVG',
        status: 1,
    },
    {
        id: 18634,
        name: 'Nymeo Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18634_1_1.PNG',
        status: 1,
    },
    {
        id: 18636,
        name: 'HTM Area Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18636_1_1.PNG',
        status: 1,
    },
    {
        id: 18638,
        name: 'Essa Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18638_1_1.PNG',
        status: 1,
    },
    {
        id: 18640,
        name: 'Freedom Credit Union (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18640_1_1.PNG',
        status: 1,
    },
    {
        id: 18643,
        name: 'Community 1st Credit Union (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18643_1_1.PNG',
        status: 1,
    },
    {
        id: 18648,
        name: 'Community Bank (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18648_1_1.PNG',
        status: 1,
    },
    {
        id: 18649,
        name: 'One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18649_1_1.PNG',
        status: 1,
    },
    {
        id: 18651,
        name: 'Texas Bank (Main Br.Henderson)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18651_1_1.PNG',
        status: 1,
    },
    {
        id: 18652,
        name: 'Credit Union One (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18652_1_1.PNG',
        status: 1,
    },
    {
        id: 18657,
        name: 'Bank of Prairie Village',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18657_1_1.PNG',
        status: 1,
    },
    {
        id: 18662,
        name: 'Santa Cruz Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18662_1_1.PNG',
        status: 1,
    },
    {
        id: 18672,
        name: 'Meritus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18672_1_1.PNG',
        status: 1,
    },
    {
        id: 18673,
        name: 'VAntage Trust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18673_1_1.PNG',
        status: 1,
    },
    {
        id: 18675,
        name: 'Equity',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18675_1_1.PNG',
        status: 1,
    },
    {
        id: 18676,
        name: 'Missouri Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18676_1_1.PNG',
        status: 1,
    },
    {
        id: 18684,
        name: 'First Security Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18684_1_1.PNG',
        status: 1,
    },
    {
        id: 18701,
        name: 'CSE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18701_1_1.PNG',
        status: 1,
    },
    {
        id: 18702,
        name: 'Tompkins State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18702_1_1.PNG',
        status: 1,
    },
    {
        id: 18703,
        name: 'Mountain Valley (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18703_1_1.PNG',
        status: 1,
    },
    {
        id: 18706,
        name: 'Pheple Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18706_1_1.PNG',
        status: 1,
    },
    {
        id: 18709,
        name: 'Community Trust & Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18709_1_1.PNG',
        status: 1,
    },
    {
        id: 18711,
        name: 'First National Bank of Ava',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18711_1_1.PNG',
        status: 1,
    },
    {
        id: 18714,
        name: 'Raritan State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18714_1_1.PNG',
        status: 1,
    },
    {
        id: 18715,
        name: 'EagleBank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18715_1_1.PNG',
        status: 1,
    },
    {
        id: 18717,
        name: 'Central Florida Postal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18717_1_1.PNG',
        status: 1,
    },
    {
        id: 18718,
        name: 'Llano National',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18718_1_1.PNG',
        status: 1,
    },
    {
        id: 18719,
        name: 'Enterprise Bank (MA) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18719_1_1.PNG',
        status: 1,
    },
    {
        id: 18729,
        name: 'Lakeside Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18729_1_1.PNG',
        status: 1,
    },
    {
        id: 18735,
        name: 'FORUM Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18735_1_1.PNG',
        status: 1,
    },
    {
        id: 18737,
        name: 'WEPCO Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18737_1_1.PNG',
        status: 1,
    },
    {
        id: 18750,
        name: 'Croghan Colonial Bank - Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18750_1_1.PNG',
        status: 1,
    },
    {
        id: 18753,
        name: 'First National Bank (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18753_1_1.PNG',
        status: 1,
    },
    {
        id: 18758,
        name: 'Financial Plus Credit Union (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18758_1_1.PNG',
        status: 1,
    },
    {
        id: 18778,
        name: 'Fairfield Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18778_1_1.PNG',
        status: 1,
    },
    {
        id: 18780,
        name: 'Resource One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18780_1_1.PNG',
        status: 1,
    },
    {
        id: 18781,
        name: 'Skyline National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18781_1_1.PNG',
        status: 1,
    },
    {
        id: 18784,
        name: 'SB One bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18784_1_1.PNG',
        status: 1,
    },
    {
        id: 18804,
        name: 'Community Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18804_1_1.PNG',
        status: 1,
    },
    {
        id: 18810,
        name: 'Citizens Community (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18810_1_1.PNG',
        status: 1,
    },
    {
        id: 18817,
        name: 'Western Healthcare Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18817_1_1.PNG',
        status: 1,
    },
    {
        id: 18819,
        name: 'Mayville State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18819_1_1.PNG',
        status: 1,
    },
    {
        id: 18821,
        name: 'Peoples Bank of Arkansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18821_1_1.PNG',
        status: 1,
    },
    {
        id: 18824,
        name: 'Scranton Times Downtown Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18824_1_1.PNG',
        status: 1,
    },
    {
        id: 18825,
        name: 'Greenwoods State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18825_1_1.PNG',
        status: 1,
    },
    {
        id: 18829,
        name: 'Sabine State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18829_1_1.PNG',
        status: 1,
    },
    {
        id: 18831,
        name: 'Palmetto Citizens Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18831_1_1.PNG',
        status: 1,
    },
    {
        id: 18833,
        name: 'Telcomm Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18833_1_1.PNG',
        status: 1,
    },
    {
        id: 18837,
        name: 'SouthCrest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18837_1_1.PNG',
        status: 1,
    },
    {
        id: 18838,
        name: 'First Security Bank of Malta',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18838_1_1.PNG',
        status: 1,
    },
    {
        id: 18840,
        name: 'Bay Atlantic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18840_1_1.PNG',
        status: 1,
    },
    {
        id: 18841,
        name: 'First National Bank of Oklahoma',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18841_1_1.PNG',
        status: 1,
    },
    {
        id: 18842,
        name: 'First Enterprise',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18842_1_1.PNG',
        status: 1,
    },
    {
        id: 18843,
        name: 'American State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18843_1_1.PNG',
        status: 1,
    },
    {
        id: 18844,
        name: 'Shore to Shore Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18844_1_1.PNG',
        status: 1,
    },
    {
        id: 18855,
        name: 'Kaw Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18855_1_1.PNG',
        status: 1,
    },
    {
        id: 18859,
        name: 'RiverHills Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18859_1_1.PNG',
        status: 1,
    },
    {
        id: 18860,
        name: 'Community First Credit Union (FL) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18860_1_1.PNG',
        status: 1,
    },
    {
        id: 18861,
        name: 'Citizens Bank of Florida',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18861_1_1.PNG',
        status: 1,
    },
    {
        id: 18864,
        name: 'KANZA Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18864_1_1.PNG',
        status: 1,
    },
    {
        id: 18865,
        name: 'Honor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18865_1_1.PNG',
        status: 1,
    },
    {
        id: 18866,
        name: 'First Coast Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18866_1_1.PNG',
        status: 1,
    },
    {
        id: 18868,
        name: 'First Central Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18868_1_1.PNG',
        status: 1,
    },
    {
        id: 18870,
        name: 'Down East Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18870_1_1.PNG',
        status: 1,
    },
    {
        id: 18872,
        name: 'Providence Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18872_1_1.PNG',
        status: 1,
    },
    {
        id: 18873,
        name: 'Providence Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18873_1_1.PNG',
        status: 1,
    },
    {
        id: 18878,
        name: 'LifeStore',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18878_1_1.PNG',
        status: 1,
    },
    {
        id: 18879,
        name: 'The Little Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18879_1_1.PNG',
        status: 1,
    },
    {
        id: 18880,
        name: 'Commerical Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18880_1_1.PNG',
        status: 1,
    },
    {
        id: 18883,
        name: 'Dakotaland Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18883_1_1.PNG',
        status: 1,
    },
    {
        id: 18884,
        name: 'Schaumburg Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18884_1_1.PNG',
        status: 1,
    },
    {
        id: 18888,
        name: 'Washington Trust Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18888_1_1.PNG',
        status: 1,
    },
    {
        id: 18889,
        name: 'First United National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18889_1_1.PNG',
        status: 1,
    },
    {
        id: 18892,
        name: 'HomeStreet Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18892_1_1.PNG',
        status: 1,
    },
    {
        id: 18893,
        name: 'Branson Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18893_1_1.PNG',
        status: 1,
    },
    {
        id: 18900,
        name: 'Schaumburg Bank & Trust Company (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18900_1_1.PNG',
        status: 1,
    },
    {
        id: 18902,
        name: 'Yampa Valley Bank (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18902_1_1.PNG',
        status: 1,
    },
    {
        id: 18903,
        name: 'Manhattan Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18903_1_1.PNG',
        status: 1,
    },
    {
        id: 18911,
        name: 'PrimeTrust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18911_1_1.PNG',
        status: 1,
    },
    {
        id: 18912,
        name: '1st National Bank of Dryden',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18912_1_1.PNG',
        status: 1,
    },
    {
        id: 18913,
        name: 'Midwest One Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18913_1_1.PNG',
        status: 1,
    },
    {
        id: 18914,
        name: 'Independence Bank of Kentucky',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18914_1_1.PNG',
        status: 1,
    },
    {
        id: 18918,
        name: 'Potlatch No.1 Federal Credit Union (ID)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18918_1_1.PNG',
        status: 1,
    },
    {
        id: 18921,
        name: 'City National Bank (Sulphur Springs, TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18921_1_1.PNG',
        status: 1,
    },
    {
        id: 18922,
        name: 'eTreasury Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18922_1_1.PNG',
        status: 1,
    },
    {
        id: 18923,
        name: 'Academy Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18923_1_1.PNG',
        status: 1,
    },
    {
        id: 18926,
        name: 'Ireland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18926_1_1.PNG',
        status: 1,
    },
    {
        id: 18927,
        name: 'Nova Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18927_1_1.PNG',
        status: 1,
    },
    {
        id: 18931,
        name: 'Cornerstone Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18931_1_1.PNG',
        status: 1,
    },
    {
        id: 18933,
        name: 'Heritage Federal Credit Union of Evansville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18933_1_1.PNG',
        status: 1,
    },
    {
        id: 18935,
        name: 'Alta One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18935_1_1.PNG',
        status: 1,
    },
    {
        id: 18937,
        name: 'River City Federal Credit Union (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18937_1_1.PNG',
        status: 1,
    },
    {
        id: 18938,
        name: 'Centennial Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18938_1_1.PNG',
        status: 1,
    },
    {
        id: 18939,
        name: 'Citizens State Bank of Buffalo (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18939_1_1.PNG',
        status: 1,
    },
    {
        id: 18943,
        name: 'Bank of Dawson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18943_1_1.PNG',
        status: 1,
    },
    {
        id: 18945,
        name: 'Piscataqua Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18945_1_1.PNG',
        status: 1,
    },
    {
        id: 18947,
        name: 'Bay Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18947_1_1.PNG',
        status: 1,
    },
    {
        id: 18949,
        name: 'Southern Security Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18949_1_1.PNG',
        status: 1,
    },
    {
        id: 18950,
        name: 'The Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18950_1_1.PNG',
        status: 1,
    },
    {
        id: 18952,
        name: 'FNCB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18952_1_2.SVG',
        status: 1,
    },
    {
        id: 18953,
        name: 'Nantahala Bank & Trust Company-Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18953_1_1.PNG',
        status: 1,
    },
    {
        id: 18954,
        name: 'American Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18954_1_1.PNG',
        status: 1,
    },
    {
        id: 18961,
        name: 'Texas First State',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18961_1_1.PNG',
        status: 1,
    },
    {
        id: 18962,
        name: 'FineMark National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18962_1_1.PNG',
        status: 1,
    },
    {
        id: 18965,
        name: 'Financial Health Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18965_1_1.PNG',
        status: 1,
    },
    {
        id: 18978,
        name: 'Walpole Co-operative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18978_1_1.PNG',
        status: 1,
    },
    {
        id: 18979,
        name: 'First National Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18979_1_1.PNG',
        status: 1,
    },
    {
        id: 18986,
        name: 'Sherwood Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18986_1_1.PNG',
        status: 1,
    },
    {
        id: 18987,
        name: 'SpiritBank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18987_1_1.PNG',
        status: 1,
    },
    {
        id: 18989,
        name: 'First Fidelity Bank (SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18989_1_1.PNG',
        status: 1,
    },
    {
        id: 18998,
        name: 'Fidelity Bank (Business - MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_18998_1_1.PNG',
        status: 1,
    },
    {
        id: 19002,
        name: 'Central National Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19002_1_1.PNG',
        status: 1,
    },
    {
        id: 19004,
        name: 'American River Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19004_1_1.PNG',
        status: 1,
    },
    {
        id: 19005,
        name: 'Bremer Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19005_1_1.PNG',
        status: 1,
    },
    {
        id: 19006,
        name: 'SpiritBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19006_1_1.PNG',
        status: 1,
    },
    {
        id: 19007,
        name: 'First Community Bank (SC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19007_1_1.PNG',
        status: 1,
    },
    {
        id: 19008,
        name: 'Bath Savings Institution',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19008_1_1.PNG',
        status: 1,
    },
    {
        id: 19009,
        name: 'Flagstar Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19009_1_1.PNG',
        status: 1,
    },
    {
        id: 19010,
        name: 'First Community Bank (Business - SC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19010_1_1.PNG',
        status: 1,
    },
    {
        id: 19011,
        name: "Saratoga's Community Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19011_1_1.PNG',
        status: 1,
    },
    {
        id: 19012,
        name: 'The First National Bank in Sioux Falls',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19012_1_1.PNG',
        status: 1,
    },
    {
        id: 19013,
        name: 'Bankers Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19013_1_1.PNG',
        status: 1,
    },
    {
        id: 19016,
        name: 'Heritage Bank (Business - GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19016_1_1.PNG',
        status: 1,
    },
    {
        id: 19017,
        name: 'American Bank-Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19017_1_1.PNG',
        status: 1,
    },
    {
        id: 19023,
        name: 'Singing River Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19023_1_1.PNG',
        status: 1,
    },
    {
        id: 19027,
        name: 'American Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19027_1_1.PNG',
        status: 1,
    },
    {
        id: 19028,
        name: 'Premier Bank of the South',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19028_1_1.PNG',
        status: 1,
    },
    {
        id: 19030,
        name: 'Hingham Institution for Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19030_1_1.PNG',
        status: 1,
    },
    {
        id: 19031,
        name: 'Austin Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19031_1_1.PNG',
        status: 1,
    },
    {
        id: 19032,
        name: 'Pinnacle Bank(GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19032_1_1.PNG',
        status: 1,
    },
    {
        id: 19038,
        name: 'SIU Credit Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19038_1_1.PNG',
        status: 1,
    },
    {
        id: 19041,
        name: 'First Federal bank of Louisiana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19041_1_1.PNG',
        status: 1,
    },
    {
        id: 19042,
        name: 'Bank of Little Rock',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19042_1_1.PNG',
        status: 1,
    },
    {
        id: 19046,
        name: 'Frandsen Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19046_1_1.PNG',
        status: 1,
    },
    {
        id: 19048,
        name: 'Community Bank & Trust of Cheatham County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19048_1_1.PNG',
        status: 1,
    },
    {
        id: 19050,
        name: 'Frandsen Bank and Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19050_1_1.PNG',
        status: 1,
    },
    {
        id: 19052,
        name: 'Bank Forward',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19052_1_1.PNG',
        status: 1,
    },
    {
        id: 19054,
        name: 'Ohio Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19054_1_1.PNG',
        status: 1,
    },
    {
        id: 19055,
        name: 'Community National Bank & Trust (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19055_1_1.PNG',
        status: 1,
    },
    {
        id: 19056,
        name: 'The Rawlins National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19056_1_1.PNG',
        status: 1,
    },
    {
        id: 19059,
        name: 'F&M Bank (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19059_1_1.PNG',
        status: 1,
    },
    {
        id: 19063,
        name: 'Bank of Colorado (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19063_1_1.PNG',
        status: 1,
    },
    {
        id: 19066,
        name: 'Citizens Bank of Rogersville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19066_1_1.PNG',
        status: 1,
    },
    {
        id: 19067,
        name: 'Wallkill Valley Federal Savings & Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19067_1_1.PNG',
        status: 1,
    },
    {
        id: 19069,
        name: 'Guardian Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19069_1_1.PNG',
        status: 1,
    },
    {
        id: 19072,
        name: 'Citizens Bank (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19072_1_1.PNG',
        status: 1,
    },
    {
        id: 19079,
        name: 'One Vision Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19079_1_1.PNG',
        status: 1,
    },
    {
        id: 19080,
        name: 'First Community Bank of Bedford County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19080_1_1.PNG',
        status: 1,
    },
    {
        id: 19081,
        name: 'Mutual Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19081_1_1.PNG',
        status: 1,
    },
    {
        id: 19082,
        name: 'Carter Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19082_1_1.PNG',
        status: 1,
    },
    {
        id: 19083,
        name: 'First Montana Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19083_1_1.PNG',
        status: 1,
    },
    {
        id: 19084,
        name: 'South Georgia Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19084_1_1.PNG',
        status: 1,
    },
    {
        id: 19086,
        name: 'Security Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19086_1_1.PNG',
        status: 1,
    },
    {
        id: 19089,
        name: 'Seaside Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19089_1_1.PNG',
        status: 1,
    },
    {
        id: 19090,
        name: 'SmartBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19090_1_1.PNG',
        status: 1,
    },
    {
        id: 19095,
        name: 'Texas Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19095_1_1.PNG',
        status: 1,
    },
    {
        id: 19096,
        name: 'Grow Financial Federal Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19096_1_1.PNG',
        status: 1,
    },
    {
        id: 19097,
        name: 'Valley Green Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19097_1_1.PNG',
        status: 1,
    },
    {
        id: 19098,
        name: 'American Bank of the North',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19098_1_1.PNG',
        status: 1,
    },
    {
        id: 19099,
        name: 'Western Rockies Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19099_1_1.PNG',
        status: 1,
    },
    {
        id: 19100,
        name: 'Bank of the Pacific',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19100_1_1.PNG',
        status: 1,
    },
    {
        id: 19105,
        name: 'Valley Bank Of Nevada',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19105_1_1.PNG',
        status: 1,
    },
    {
        id: 19106,
        name: 'Grand Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19106_1_1.PNG',
        status: 1,
    },
    {
        id: 19108,
        name: 'Advia Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19108_1_1.PNG',
        status: 1,
    },
    {
        id: 19111,
        name: 'SESLOC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19111_1_1.PNG',
        status: 1,
    },
    {
        id: 19112,
        name: 'StonehamBank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19112_1_1.PNG',
        status: 1,
    },
    {
        id: 19114,
        name: 'Ameris Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19114_1_1.PNG',
        status: 1,
    },
    {
        id: 19116,
        name: 'Commercial Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19116_1_1.PNG',
        status: 1,
    },
    {
        id: 19118,
        name: 'State Bank of Freeport (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19118_1_1.PNG',
        status: 1,
    },
    {
        id: 19122,
        name: 'FirstBank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19122_1_1.PNG',
        status: 1,
    },
    {
        id: 19123,
        name: 'Claremont Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19123_1_1.PNG',
        status: 1,
    },
    {
        id: 19124,
        name: 'Hometown Bank (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19124_1_1.PNG',
        status: 1,
    },
    {
        id: 19127,
        name: 'The State Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19127_1_1.PNG',
        status: 1,
    },
    {
        id: 19132,
        name: 'Hingham Institution for Savings (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19132_1_1.PNG',
        status: 1,
    },
    {
        id: 19133,
        name: 'Country Club Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19133_1_1.PNG',
        status: 1,
    },
    {
        id: 19135,
        name: 'Santa Cruz County (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19135_1_1.PNG',
        status: 1,
    },
    {
        id: 19136,
        name: 'Citizens Business Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19136_1_1.PNG',
        status: 1,
    },
    {
        id: 19140,
        name: 'North Salem State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19140_1_1.PNG',
        status: 1,
    },
    {
        id: 19142,
        name: 'State Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19142_1_1.PNG',
        status: 1,
    },
    {
        id: 19147,
        name: 'Peoples National Bank of Kewanee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19147_1_1.PNG',
        status: 1,
    },
    {
        id: 19151,
        name: 'American Community Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19151_1_1.PNG',
        status: 1,
    },
    {
        id: 19154,
        name: 'WSFS Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19154_1_1.PNG',
        status: 1,
    },
    {
        id: 19155,
        name: 'The Victory Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19155_1_1.PNG',
        status: 1,
    },
    {
        id: 19158,
        name: 'D.L. Evans Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19158_1_1.PNG',
        status: 1,
    },
    {
        id: 19164,
        name: 'Wellington State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19164_1_1.PNG',
        status: 1,
    },
    {
        id: 19165,
        name: 'Northwestern Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19165_1_1.PNG',
        status: 1,
    },
    {
        id: 19167,
        name: 'Huntingdon Valley Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19167_1_1.PNG',
        status: 1,
    },
    {
        id: 19169,
        name: 'Mountain Valley Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19169_1_1.PNG',
        status: 1,
    },
    {
        id: 19170,
        name: 'First Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19170_1_1.PNG',
        status: 1,
    },
    {
        id: 19174,
        name: 'Merchants & Marine Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19174_1_1.PNG',
        status: 1,
    },
    {
        id: 19175,
        name: 'Allentown Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19175_1_1.PNG',
        status: 1,
    },
    {
        id: 19176,
        name: 'Merchants & Marine Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19176_1_1.PNG',
        status: 1,
    },
    {
        id: 19177,
        name: 'Huntingdon Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19177_1_1.PNG',
        status: 1,
    },
    {
        id: 19178,
        name: 'The Middlefield Banking Company (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19178_1_1.PNG',
        status: 1,
    },
    {
        id: 19179,
        name: 'FirstInterstate bank(Flathead bank)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19179_1_1.PNG',
        status: 1,
    },
    {
        id: 19180,
        name: 'Union State Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19180_1_1.PNG',
        status: 1,
    },
    {
        id: 19181,
        name: 'Core Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19181_1_1.PNG',
        status: 1,
    },
    {
        id: 19183,
        name: 'Fort Sill Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19183_1_1.PNG',
        status: 1,
    },
    {
        id: 19186,
        name: 'Citizens Bank & Trust (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19186_1_1.PNG',
        status: 1,
    },
    {
        id: 19188,
        name: 'Citizens Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19188_1_1.PNG',
        status: 1,
    },
    {
        id: 19189,
        name: 'Flushing Savings Bank (Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19189_1_2.SVG',
        status: 1,
    },
    {
        id: 19191,
        name: 'Middlefield Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19191_1_1.PNG',
        status: 1,
    },
    {
        id: 19197,
        name: 'Core Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19197_1_1.PNG',
        status: 1,
    },
    {
        id: 19203,
        name: 'Pikes Peak National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19203_1_1.PNG',
        status: 1,
    },
    {
        id: 19204,
        name: 'Habib American Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19204_1_1.PNG',
        status: 1,
    },
    {
        id: 19205,
        name: 'Emprise Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19205_1_1.PNG',
        status: 1,
    },
    {
        id: 19206,
        name: 'American National Bank of Minnesota (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19206_1_1.PNG',
        status: 1,
    },
    {
        id: 19207,
        name: 'Regal Bank (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19207_1_1.PNG',
        status: 1,
    },
    {
        id: 19208,
        name: 'Citizens National Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19208_1_1.PNG',
        status: 1,
    },
    {
        id: 19210,
        name: 'American National Bank of Minnesota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19210_1_1.PNG',
        status: 1,
    },
    {
        id: 19212,
        name: 'Merchants National Bank of Bangor (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19212_1_1.PNG',
        status: 1,
    },
    {
        id: 19221,
        name: 'Aberdeen Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19221_1_1.PNG',
        status: 1,
    },
    {
        id: 19223,
        name: 'The Peoples Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19223_1_1.PNG',
        status: 1,
    },
    {
        id: 19224,
        name: 'Sandia Laboratory Federal Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19224_1_1.PNG',
        status: 1,
    },
    {
        id: 19225,
        name: 'Savanna-Thomson State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19225_1_1.PNG',
        status: 1,
    },
    {
        id: 19227,
        name: 'Fairwinds Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19227_1_1.PNG',
        status: 1,
    },
    {
        id: 19229,
        name: 'Aquesta Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19229_1_1.PNG',
        status: 1,
    },
    {
        id: 19230,
        name: 'Grand Mountain Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19230_1_1.PNG',
        status: 1,
    },
    {
        id: 19231,
        name: 'Peoples Bank (WA - Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19231_1_1.PNG',
        status: 1,
    },
    {
        id: 19232,
        name: 'Aquesta Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19232_1_1.PNG',
        status: 1,
    },
    {
        id: 19240,
        name: 'United Bank (WV) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19240_1_1.PNG',
        status: 1,
    },
    {
        id: 19243,
        name: 'Buckeye Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19243_1_1.PNG',
        status: 1,
    },
    {
        id: 19244,
        name: 'Cornerstone Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19244_1_1.PNG',
        status: 1,
    },
    {
        id: 19246,
        name: 'Home Federal (Business - MN, IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19246_1_1.PNG',
        status: 1,
    },
    {
        id: 19248,
        name: 'Village Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19248_1_1.PNG',
        status: 1,
    },
    {
        id: 19250,
        name: 'Sterling State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19250_1_1.PNG',
        status: 1,
    },
    {
        id: 19251,
        name: 'Village Bank (Business - VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19251_1_1.PNG',
        status: 1,
    },
    {
        id: 19255,
        name: 'American Community Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19255_1_1.PNG',
        status: 1,
    },
    {
        id: 19265,
        name: 'Heartland Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19265_1_1.PNG',
        status: 1,
    },
    {
        id: 19269,
        name: 'The Citizens Bank (SC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19269_1_1.PNG',
        status: 1,
    },
    {
        id: 19273,
        name: 'Community Alliance Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19273_1_1.PNG',
        status: 1,
    },
    {
        id: 19277,
        name: 'First Choice Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19277_1_1.PNG',
        status: 1,
    },
    {
        id: 19285,
        name: 'Better Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19285_1_1.PNG',
        status: 1,
    },
    {
        id: 19287,
        name: 'State Bank of Cross Plains (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19287_1_1.PNG',
        status: 1,
    },
    {
        id: 19291,
        name: 'Northwestern Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19291_1_1.PNG',
        status: 1,
    },
    {
        id: 19294,
        name: 'Fulton Bank (Cashlink Business - NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19294_1_1.PNG',
        status: 1,
    },
    {
        id: 19295,
        name: 'Cornerstone Community Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19295_1_1.PNG',
        status: 1,
    },
    {
        id: 19298,
        name: 'First Mid-Illinois Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19298_1_1.PNG',
        status: 1,
    },
    {
        id: 19299,
        name: 'Self-Help Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19299_1_1.PNG',
        status: 1,
    },
    {
        id: 19300,
        name: 'CALCOE Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19300_1_1.PNG',
        status: 1,
    },
    {
        id: 19302,
        name: 'CorTrust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19302_1_1.PNG',
        status: 1,
    },
    {
        id: 19311,
        name: 'Peoples Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19311_1_1.PNG',
        status: 1,
    },
    {
        id: 19314,
        name: 'Lubbock National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19314_1_1.PNG',
        status: 1,
    },
    {
        id: 19316,
        name: 'Peoples Bank of Commerce (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19316_1_1.PNG',
        status: 1,
    },
    {
        id: 19324,
        name: 'Provident Bank of New Jersey (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19324_1_1.PNG',
        status: 1,
    },
    {
        id: 19326,
        name: "People's Community Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19326_1_1.PNG',
        status: 1,
    },
    {
        id: 19327,
        name: 'First Bank and Trust (Business - LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19327_1_1.PNG',
        status: 1,
    },
    {
        id: 19330,
        name: 'Atlantic Union Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19330_1_1.PNG',
        status: 1,
    },
    {
        id: 19331,
        name: 'North Shore Bank (MA)(Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19331_1_1.PNG',
        status: 1,
    },
    {
        id: 19338,
        name: 'Gwinnett Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19338_1_1.PNG',
        status: 1,
    },
    {
        id: 19351,
        name: 'Bank of Star City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19351_1_1.PNG',
        status: 1,
    },
    {
        id: 19353,
        name: 'Park National Bank (OH - Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19353_1_1.PNG',
        status: 1,
    },
    {
        id: 19354,
        name: 'Village Bank (Business - MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19354_1_1.PNG',
        status: 1,
    },
    {
        id: 19356,
        name: 'American National Bank (NE) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19356_1_1.PNG',
        status: 1,
    },
    {
        id: 19357,
        name: 'Third Coast Bank SSB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19357_1_1.PNG',
        status: 1,
    },
    {
        id: 19358,
        name: '1st Bank (Cash Mangement)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19358_1_1.PNG',
        status: 1,
    },
    {
        id: 19364,
        name: 'Neighbors Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19364_1_1.PNG',
        status: 1,
    },
    {
        id: 19367,
        name: 'Northwest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19367_1_1.PNG',
        status: 1,
    },
    {
        id: 19370,
        name: 'Community Trust Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19370_1_1.PNG',
        status: 1,
    },
    {
        id: 19378,
        name: 'EastWest Bank (BusinessBridge)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19378_1_1.PNG',
        status: 1,
    },
    {
        id: 19380,
        name: 'First Port City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19380_1_1.PNG',
        status: 1,
    },
    {
        id: 19383,
        name: 'Gloucester Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19383_1_1.PNG',
        status: 1,
    },
    {
        id: 19389,
        name: 'Forcht Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19389_1_1.PNG',
        status: 1,
    },
    {
        id: 19391,
        name: 'American Riviera Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19391_1_1.PNG',
        status: 1,
    },
    {
        id: 19392,
        name: 'Golden Plains Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19392_1_1.PNG',
        status: 1,
    },
    {
        id: 19406,
        name: 'Foundation Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19406_1_1.PNG',
        status: 1,
    },
    {
        id: 19407,
        name: 'HomeBank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19407_1_1.PNG',
        status: 1,
    },
    {
        id: 19409,
        name: 'Great Southern National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19409_1_1.PNG',
        status: 1,
    },
    {
        id: 19417,
        name: 'Millville Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19417_1_1.PNG',
        status: 1,
    },
    {
        id: 19430,
        name: 'United Bank (MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19430_1_1.PNG',
        status: 1,
    },
    {
        id: 19431,
        name: 'KALSEE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19431_1_1.PNG',
        status: 1,
    },
    {
        id: 19436,
        name: 'North Side Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19436_1_1.PNG',
        status: 1,
    },
    {
        id: 19437,
        name: 'DATCU Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19437_1_1.PNG',
        status: 1,
    },
    {
        id: 19439,
        name: 'United Bank (DC & VA) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19439_1_1.PNG',
        status: 1,
    },
    {
        id: 19447,
        name: 'Services CU Military',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19447_1_1.PNG',
        status: 1,
    },
    {
        id: 19448,
        name: 'First State Bank Nebraska',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19448_1_1.PNG',
        status: 1,
    },
    {
        id: 19455,
        name: 'Synergy Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19455_1_1.PNG',
        status: 1,
    },
    {
        id: 19462,
        name: 'Independent-Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19462_1_1.PNG',
        status: 1,
    },
    {
        id: 19492,
        name: 'First National Bank of Mount Dora (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19492_1_1.PNG',
        status: 1,
    },
    {
        id: 19493,
        name: 'Tennessee State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19493_1_1.PNG',
        status: 1,
    },
    {
        id: 19497,
        name: 'Alma Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19497_1_1.PNG',
        status: 1,
    },
    {
        id: 19501,
        name: 'Flag Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19501_1_1.PNG',
        status: 1,
    },
    {
        id: 19503,
        name: 'Seattle Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19503_1_1.PNG',
        status: 1,
    },
    {
        id: 19504,
        name: 'The James Polk Stone Community Bank (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19504_1_1.PNG',
        status: 1,
    },
    {
        id: 19511,
        name: 'Farmers & Merchants Bank (Eatonton, GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19511_1_1.PNG',
        status: 1,
    },
    {
        id: 19512,
        name: '1st National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19512_1_1.PNG',
        status: 1,
    },
    {
        id: 19513,
        name: 'CU Hawaii Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19513_1_1.PNG',
        status: 1,
    },
    {
        id: 19515,
        name: 'Peoples Bank(OH - Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19515_1_1.PNG',
        status: 1,
    },
    {
        id: 19516,
        name: 'Alma Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19516_1_1.PNG',
        status: 1,
    },
    {
        id: 19532,
        name: 'First National Bank of Mount Dora',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19532_1_1.PNG',
        status: 1,
    },
    {
        id: 19533,
        name: 'First Bank and Trust of Childress',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19533_1_1.PNG',
        status: 1,
    },
    {
        id: 19534,
        name: 'Interamerican Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19534_1_1.PNG',
        status: 1,
    },
    {
        id: 19536,
        name: 'Anstaff Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19536_1_1.PNG',
        status: 1,
    },
    {
        id: 19543,
        name: 'Exchange Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19543_1_1.PNG',
        status: 1,
    },
    {
        id: 19545,
        name: 'LEDYARD',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19545_1_1.PNG',
        status: 1,
    },
    {
        id: 19546,
        name: 'NebraskaLand National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19546_1_1.PNG',
        status: 1,
    },
    {
        id: 19550,
        name: 'Jefferson Security Bank (WV)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19550_1_1.PNG',
        status: 1,
    },
    {
        id: 19551,
        name: 'First American Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19551_1_1.PNG',
        status: 1,
    },
    {
        id: 19556,
        name: 'Fidelity Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19556_1_1.PNG',
        status: 1,
    },
    {
        id: 19557,
        name: 'River Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19557_1_1.PNG',
        status: 1,
    },
    {
        id: 19567,
        name: 'Pendleton Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19567_1_1.PNG',
        status: 1,
    },
    {
        id: 19570,
        name: 'Miners Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19570_1_1.PNG',
        status: 1,
    },
    {
        id: 19571,
        name: 'First Volunteer Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19571_1_1.PNG',
        status: 1,
    },
    {
        id: 19572,
        name: 'Synergy Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19572_1_1.PNG',
        status: 1,
    },
    {
        id: 19573,
        name: 'First National Bank Minnesota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19573_1_1.PNG',
        status: 1,
    },
    {
        id: 19574,
        name: 'Penn East Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19574_1_1.PNG',
        status: 1,
    },
    {
        id: 19575,
        name: 'West Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19575_1_1.PNG',
        status: 1,
    },
    {
        id: 19576,
        name: 'Sacramento Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19576_1_1.PNG',
        status: 1,
    },
    {
        id: 19578,
        name: 'Planters Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19578_1_1.PNG',
        status: 1,
    },
    {
        id: 19579,
        name: 'Enterprise Bank(NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19579_1_1.PNG',
        status: 1,
    },
    {
        id: 19580,
        name: 'The Farmers Bank (IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19580_1_1.PNG',
        status: 1,
    },
    {
        id: 19582,
        name: 'Freedom Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19582_1_1.PNG',
        status: 1,
    },
    {
        id: 19583,
        name: 'Artesian City Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19583_1_1.PNG',
        status: 1,
    },
    {
        id: 19584,
        name: 'First National Bank of Northfield',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19584_1_1.PNG',
        status: 1,
    },
    {
        id: 19585,
        name: 'BankWest (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19585_1_1.PNG',
        status: 1,
    },
    {
        id: 19586,
        name: 'FirstCapital Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19586_1_1.PNG',
        status: 1,
    },
    {
        id: 19587,
        name: 'Town Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19587_1_1.PNG',
        status: 1,
    },
    {
        id: 19588,
        name: 'Erie Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19588_1_1.PNG',
        status: 1,
    },
    {
        id: 19596,
        name: 'Peoples Bank (Business - IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19596_1_1.PNG',
        status: 1,
    },
    {
        id: 19597,
        name: 'Umpqua Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19597_1_1.PNG',
        status: 1,
    },
    {
        id: 19600,
        name: 'First State Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19600_1_1.PNG',
        status: 1,
    },
    {
        id: 19602,
        name: 'First National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19602_1_1.PNG',
        status: 1,
    },
    {
        id: 19603,
        name: 'Adirondack Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19603_1_1.PNG',
        status: 1,
    },
    {
        id: 19610,
        name: 'Applied Bank - Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19610_1_1.PNG',
        status: 1,
    },
    {
        id: 19619,
        name: 'Traditions Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19619_1_1.PNG',
        status: 1,
    },
    {
        id: 19621,
        name: 'First State Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19621_1_1.PNG',
        status: 1,
    },
    {
        id: 19623,
        name: 'Texas First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19623_1_1.PNG',
        status: 1,
    },
    {
        id: 19628,
        name: 'Sioux Falls Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19628_1_1.PNG',
        status: 1,
    },
    {
        id: 19629,
        name: 'Independence Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19629_1_1.PNG',
        status: 1,
    },
    {
        id: 19630,
        name: 'Access Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19630_1_1.PNG',
        status: 1,
    },
    {
        id: 19634,
        name: 'Brighton Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19634_1_1.PNG',
        status: 1,
    },
    {
        id: 19635,
        name: 'First National Bank of South Carolina',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19635_1_1.PNG',
        status: 1,
    },
    {
        id: 19637,
        name: 'Bank of McKenney',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19637_1_1.PNG',
        status: 1,
    },
    {
        id: 19638,
        name: 'First Merchants Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19638_1_1.PNG',
        status: 1,
    },
    {
        id: 19639,
        name: 'South Central Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19639_1_1.PNG',
        status: 1,
    },
    {
        id: 19643,
        name: 'PrimeSouth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19643_1_1.PNG',
        status: 1,
    },
    {
        id: 19644,
        name: 'Crown Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19644_1_1.PNG',
        status: 1,
    },
    {
        id: 19647,
        name: 'FNB Alaska (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19647_1_1.PNG',
        status: 1,
    },
    {
        id: 19648,
        name: 'SouthPoint Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19648_1_2.SVG',
        status: 1,
    },
    {
        id: 19650,
        name: 'LakeCentral Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19650_1_1.PNG',
        status: 1,
    },
    {
        id: 19656,
        name: 'Seacoast National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19656_1_1.PNG',
        status: 1,
    },
    {
        id: 19664,
        name: 'Exchange National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19664_1_1.PNG',
        status: 1,
    },
    {
        id: 19667,
        name: 'Triumph (Business - TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19667_1_1.PNG',
        status: 1,
    },
    {
        id: 19668,
        name: 'Upward Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19668_1_1.PNG',
        status: 1,
    },
    {
        id: 19674,
        name: 'Northbrook Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19674_1_1.PNG',
        status: 1,
    },
    {
        id: 19683,
        name: 'United Prairie Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19683_1_1.PNG',
        status: 1,
    },
    {
        id: 19698,
        name: 'S&T Bank(Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19698_1_2.SVG',
        status: 1,
    },
    {
        id: 19701,
        name: 'United Southern Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19701_1_1.PNG',
        status: 1,
    },
    {
        id: 19712,
        name: 'East Boston Savings Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19712_1_1.PNG',
        status: 1,
    },
    {
        id: 19713,
        name: 'Beacon Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19713_1_1.PNG',
        status: 1,
    },
    {
        id: 19719,
        name: 'Dane County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19719_1_1.PNG',
        status: 1,
    },
    {
        id: 19727,
        name: 'Northeast Georgia Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19727_1_1.PNG',
        status: 1,
    },
    {
        id: 19728,
        name: 'Select Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19728_1_1.PNG',
        status: 1,
    },
    {
        id: 19735,
        name: 'Ameriserv (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19735_1_1.PNG',
        status: 1,
    },
    {
        id: 19736,
        name: 'Exchange Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19736_1_1.PNG',
        status: 1,
    },
    {
        id: 19741,
        name: 'Farmers & Merchant Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19741_1_1.PNG',
        status: 1,
    },
    {
        id: 19744,
        name: 'Five Star Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19744_1_1.PNG',
        status: 1,
    },
    {
        id: 19750,
        name: 'The Farmers Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19750_1_1.PNG',
        status: 1,
    },
    {
        id: 19753,
        name: 'HomeTown Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19753_1_1.PNG',
        status: 1,
    },
    {
        id: 19756,
        name: 'Vinton County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19756_1_1.PNG',
        status: 1,
    },
    {
        id: 19757,
        name: 'PSE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19757_1_1.PNG',
        status: 1,
    },
    {
        id: 19760,
        name: 'Port Washington State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19760_1_1.PNG',
        status: 1,
    },
    {
        id: 19765,
        name: 'Academy Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19765_1_1.PNG',
        status: 1,
    },
    {
        id: 19768,
        name: 'GEICO Federal Credit Union (MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19768_1_1.PNG',
        status: 1,
    },
    {
        id: 19773,
        name: 'Royal Bank USA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19773_1_1.PNG',
        status: 1,
    },
    {
        id: 19774,
        name: 'First bank southwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19774_1_1.PNG',
        status: 1,
    },
    {
        id: 19777,
        name: 'Cape Ann Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 19779,
        name: 'Kirkpatrick Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19779_1_1.PNG',
        status: 1,
    },
    {
        id: 19785,
        name: 'First National Bank (Sturgis)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19785_1_1.PNG',
        status: 1,
    },
    {
        id: 19793,
        name: 'Legends Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19793_1_1.PNG',
        status: 1,
    },
    {
        id: 19797,
        name: 'Seacoast Commerce Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19797_1_1.PNG',
        status: 1,
    },
    {
        id: 19798,
        name: 'Alamosa State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19798_1_1.PNG',
        status: 1,
    },
    {
        id: 19800,
        name: 'Community Bank & Trust of Florida',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19800_1_1.PNG',
        status: 1,
    },
    {
        id: 19802,
        name: 'Business Bank of Texas (North America)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19802_1_1.PNG',
        status: 1,
    },
    {
        id: 19804,
        name: 'Carolina Bank (SC) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19804_1_1.PNG',
        status: 1,
    },
    {
        id: 19805,
        name: 'Farmers State Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19805_1_1.PNG',
        status: 1,
    },
    {
        id: 19807,
        name: 'First American Bank (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19807_1_2.SVG',
        status: 1,
    },
    {
        id: 19809,
        name: 'ConnectOne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19809_1_1.PNG',
        status: 1,
    },
    {
        id: 19814,
        name: 'South State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19814_1_1.PNG',
        status: 1,
    },
    {
        id: 19819,
        name: 'Central Bank of Houston',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19819_1_1.PNG',
        status: 1,
    },
    {
        id: 19824,
        name: 'Avidbank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19824_1_1.PNG',
        status: 1,
    },
    {
        id: 19828,
        name: 'Winchester Co-Operative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19828_1_1.PNG',
        status: 1,
    },
    {
        id: 19829,
        name: 'Merchants Bank - MN',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19829_1_1.PNG',
        status: 1,
    },
    {
        id: 19832,
        name: 'Alliance Credit Union (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19832_1_1.PNG',
        status: 1,
    },
    {
        id: 19836,
        name: 'Mid American Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19836_1_1.PNG',
        status: 1,
    },
    {
        id: 19845,
        name: 'Rock Valley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19845_1_1.PNG',
        status: 1,
    },
    {
        id: 19846,
        name: 'Align Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19846_1_1.PNG',
        status: 1,
    },
    {
        id: 19847,
        name: 'Jersey Shore Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19847_1_1.PNG',
        status: 1,
    },
    {
        id: 19848,
        name: 'Hopewell Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19848_1_1.PNG',
        status: 1,
    },
    {
        id: 19849,
        name: 'Provident Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19849_1_1.PNG',
        status: 1,
    },
    {
        id: 19850,
        name: 'Wray State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19850_1_1.PNG',
        status: 1,
    },
    {
        id: 19854,
        name: 'Farmers Bank & Trust(AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19854_1_1.PNG',
        status: 1,
    },
    {
        id: 19855,
        name: 'Greenville Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19855_1_1.PNG',
        status: 1,
    },
    {
        id: 19856,
        name: 'The Bank of Fayette County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19856_1_1.PNG',
        status: 1,
    },
    {
        id: 19864,
        name: 'Evolve Bank & Trust (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19864_1_1.PNG',
        status: 1,
    },
    {
        id: 19867,
        name: 'Florida Community Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19867_1_1.PNG',
        status: 1,
    },
    {
        id: 19877,
        name: 'Seaside National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19877_1_1.PNG',
        status: 1,
    },
    {
        id: 19880,
        name: 'Valley Central Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19880_1_1.PNG',
        status: 1,
    },
    {
        id: 19882,
        name: 'Community National Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19882_1_1.PNG',
        status: 1,
    },
    {
        id: 19883,
        name: 'Central State Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19883_1_1.PNG',
        status: 1,
    },
    {
        id: 19886,
        name: 'Spirit of Texas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19886_1_1.PNG',
        status: 1,
    },
    {
        id: 19902,
        name: 'Intracoastal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19902_1_1.PNG',
        status: 1,
    },
    {
        id: 19903,
        name: 'Unity Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19903_1_1.PNG',
        status: 1,
    },
    {
        id: 19905,
        name: 'Five Points Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19905_1_1.PNG',
        status: 1,
    },
    {
        id: 19907,
        name: 'Oak Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19907_1_1.PNG',
        status: 1,
    },
    {
        id: 19909,
        name: 'Wayne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19909_1_1.PNG',
        status: 1,
    },
    {
        id: 19911,
        name: 'Lafayette State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 19914,
        name: 'Midwest Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19914_1_1.PNG',
        status: 1,
    },
    {
        id: 19931,
        name: 'Farmers State Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19931_1_1.PNG',
        status: 1,
    },
    {
        id: 19932,
        name: 'Sterling National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19932_1_1.PNG',
        status: 1,
    },
    {
        id: 19936,
        name: 'First Northern Bank & Trust (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19936_1_1.PNG',
        status: 1,
    },
    {
        id: 19939,
        name: 'Champion Credit Union (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19939_1_1.PNG',
        status: 1,
    },
    {
        id: 19945,
        name: 'Manufacturers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19945_1_1.PNG',
        status: 1,
    },
    {
        id: 19948,
        name: 'United National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19948_1_1.PNG',
        status: 1,
    },
    {
        id: 19950,
        name: 'West View Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19950_1_1.PNG',
        status: 1,
    },
    {
        id: 19955,
        name: 'Preferred Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19955_1_1.PNG',
        status: 1,
    },
    {
        id: 19959,
        name: 'First Home Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19959_1_1.PNG',
        status: 1,
    },
    {
        id: 19960,
        name: 'Hamlin Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19960_1_1.PNG',
        status: 1,
    },
    {
        id: 19962,
        name: 'Bank of Hope(Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19962_1_1.PNG',
        status: 1,
    },
    {
        id: 19978,
        name: 'Frazer Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19978_1_1.PNG',
        status: 1,
    },
    {
        id: 19981,
        name: 'Texas Security Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19981_1_1.PNG',
        status: 1,
    },
    {
        id: 19986,
        name: 'Peoples Bank(KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19986_1_1.PNG',
        status: 1,
    },
    {
        id: 19987,
        name: 'Karnes County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19987_1_1.PNG',
        status: 1,
    },
    {
        id: 19992,
        name: 'Evolve Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19992_1_1.PNG',
        status: 1,
    },
    {
        id: 19998,
        name: 'Premier Bank Nebraska',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_19998_1_1.PNG',
        status: 1,
    },
    {
        id: 20001,
        name: 'Monterey County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20001_1_1.PNG',
        status: 1,
    },
    {
        id: 20003,
        name: 'Community National Bank & Trust of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20003_1_1.PNG',
        status: 1,
    },
    {
        id: 20005,
        name: 'Lake-Osceola State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20005_1_1.PNG',
        status: 1,
    },
    {
        id: 20006,
        name: 'Old Point National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20006_1_1.PNG',
        status: 1,
    },
    {
        id: 20011,
        name: 'Terre Haute Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20011_1_1.PNG',
        status: 1,
    },
    {
        id: 20015,
        name: 'First Business Bank(Online Private banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20015_1_1.PNG',
        status: 1,
    },
    {
        id: 20017,
        name: 'Portland Federal Credit Union - Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20017_1_1.PNG',
        status: 1,
    },
    {
        id: 20018,
        name: 'ProGrowth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20018_1_1.PNG',
        status: 1,
    },
    {
        id: 20021,
        name: 'Fulton Savings Bank (Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20021_1_1.PNG',
        status: 1,
    },
    {
        id: 20022,
        name: 'Delta Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20022_1_1.PNG',
        status: 1,
    },
    {
        id: 20024,
        name: 'Investar Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20024_1_1.PNG',
        status: 1,
    },
    {
        id: 20025,
        name: 'Choice Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20025_1_1.PNG',
        status: 1,
    },
    {
        id: 20026,
        name: 'Riegel Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20026_1_1.PNG',
        status: 1,
    },
    {
        id: 20040,
        name: 'Denver Fire Department Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20040_1_1.PNG',
        status: 1,
    },
    {
        id: 20045,
        name: 'Prime Security Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20045_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 20048,
        name: 'Oswego County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20048_1_1.PNG',
        status: 1,
    },
    {
        id: 20051,
        name: 'Bellco Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20051_1_1.PNG',
        status: 1,
    },
    {
        id: 20052,
        name: 'Auburn State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20052_1_1.PNG',
        status: 1,
    },
    {
        id: 20053,
        name: 'Fowler State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20053_1_1.PNG',
        status: 1,
    },
    {
        id: 20055,
        name: 'American Spirit Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20055_1_2.SVG',
        status: 1,
    },
    {
        id: 20057,
        name: 'Community National Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20057_1_1.PNG',
        status: 1,
    },
    {
        id: 20058,
        name: 'The Peoples Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20058_1_1.PNG',
        status: 1,
    },
    {
        id: 20064,
        name: 'SVB Private Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20064_1_1.PNG',
        status: 1,
    },
    {
        id: 20076,
        name: 'Golden Valley Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20076_1_1.PNG',
        status: 1,
    },
    {
        id: 20081,
        name: 'Machias Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20081_1_1.PNG',
        status: 1,
    },
    {
        id: 20082,
        name: 'St. Charles Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20082_1_1.PNG',
        status: 1,
    },
    {
        id: 20083,
        name: 'St. Charles Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20083_1_1.PNG',
        status: 1,
    },
    {
        id: 20092,
        name: 'The Fountain Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20092_1_1.PNG',
        status: 1,
    },
    {
        id: 20102,
        name: 'Capital Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20102_1_1.PNG',
        status: 1,
    },
    {
        id: 20103,
        name: 'Heartland Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20103_1_1.PNG',
        status: 1,
    },
    {
        id: 20105,
        name: '1st National Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20105_1_1.PNG',
        status: 1,
    },
    {
        id: 20107,
        name: 'Bank of Kilmichael',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20107_1_1.PNG',
        status: 1,
    },
    {
        id: 20109,
        name: 'The First State Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20109_1_1.PNG',
        status: 1,
    },
    {
        id: 20112,
        name: 'Vibe Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20112_1_1.PNG',
        status: 1,
    },
    {
        id: 20114,
        name: 'Champlain National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20114_1_1.PNG',
        status: 1,
    },
    {
        id: 20117,
        name: 'Steuben Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20117_1_1.PNG',
        status: 1,
    },
    {
        id: 20136,
        name: 'Delta Community CU - Bank (Online Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20136_1_1.PNG',
        status: 1,
    },
    {
        id: 20141,
        name: 'Citizens State Bank of La Crosse-Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20141_1_1.PNG',
        status: 1,
    },
    {
        id: 20142,
        name: 'Lead Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20142_1_1.PNG',
        status: 1,
    },
    {
        id: 20145,
        name: 'Premier Bank(business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20145_1_1.PNG',
        status: 1,
    },
    {
        id: 20147,
        name: 'Bank of America Merrill Lynch (CashPro Online)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20147_1_1.PNG',
        status: 1,
    },
    {
        id: 20148,
        name: 'Lewiston State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20148_1_1.PNG',
        status: 1,
    },
    {
        id: 20149,
        name: 'Lead Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20149_1_1.PNG',
        status: 1,
    },
    {
        id: 20150,
        name: 'First Commerce Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20150_1_1.PNG',
        status: 1,
    },
    {
        id: 20151,
        name: 'Northwest Consumers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20151_1_1.PNG',
        status: 1,
    },
    {
        id: 20152,
        name: 'TBK Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20152_1_1.PNG',
        status: 1,
    },
    {
        id: 20153,
        name: 'Bank of the James',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20153_1_1.PNG',
        status: 1,
    },
    {
        id: 20154,
        name: 'Bank First (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20154_1_1.PNG',
        status: 1,
    },
    {
        id: 20156,
        name: 'Bank of Glen Ullin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20156_1_1.PNG',
        status: 1,
    },
    {
        id: 20158,
        name: 'First Commerce Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20158_1_1.PNG',
        status: 1,
    },
    {
        id: 20164,
        name: 'Farmers and Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20164_1_1.PNG',
        status: 1,
    },
    {
        id: 20169,
        name: '1st Bank of Sea Isle City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20169_1_1.PNG',
        status: 1,
    },
    {
        id: 20170,
        name: 'First Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20170_1_1.PNG',
        status: 1,
    },
    {
        id: 20171,
        name: 'Amalgamated Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20171_1_1.PNG',
        status: 1,
    },
    {
        id: 20172,
        name: 'DeMotte State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20172_1_1.PNG',
        status: 1,
    },
    {
        id: 20173,
        name: 'Midwest Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20173_1_1.PNG',
        status: 1,
    },
    {
        id: 20174,
        name: 'Fidelity Bank Deposit & Discount',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20174_1_1.PNG',
        status: 1,
    },
    {
        id: 20182,
        name: 'First National Bank of Granbury',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20182_1_1.PNG',
        status: 1,
    },
    {
        id: 20185,
        name: '1st National Bank Albany/Breckenridge',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20185_1_1.PNG',
        status: 1,
    },
    {
        id: 20186,
        name: 'Community Bank of Marshall',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20186_1_1.PNG',
        status: 1,
    },
    {
        id: 20188,
        name: 'State Bank of the Lakes (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20188_1_1.PNG',
        status: 1,
    },
    {
        id: 20195,
        name: 'Fidelity Homestead Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20195_1_1.PNG',
        status: 1,
    },
    {
        id: 20197,
        name: 'Select Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20197_1_1.PNG',
        status: 1,
    },
    {
        id: 20199,
        name: 'State Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20199_1_1.PNG',
        status: 1,
    },
    {
        id: 20202,
        name: 'Saginaw Medical Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20202_1_1.PNG',
        status: 1,
    },
    {
        id: 20203,
        name: '1st Bank of Sea Isle City (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20203_1_1.PNG',
        status: 1,
    },
    {
        id: 20204,
        name: 'Rio Grande Credit Union (NM)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20204_1_1.PNG',
        status: 1,
    },
    {
        id: 20205,
        name: 'Peoples Security Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20205_1_1.PNG',
        status: 1,
    },
    {
        id: 20208,
        name: 'Community 1st Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20208_1_1.PNG',
        status: 1,
    },
    {
        id: 20209,
        name: 'National Bank of Middlebury',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20209_1_1.PNG',
        status: 1,
    },
    {
        id: 20210,
        name: 'First State Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 20218,
        name: 'Bank Orion',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20218_1_1.PNG',
        status: 1,
    },
    {
        id: 20221,
        name: 'FCN Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20221_1_1.PNG',
        status: 1,
    },
    {
        id: 20222,
        name: 'Pine Country Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20222_1_1.PNG',
        status: 1,
    },
    {
        id: 20223,
        name: 'Pine Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20223_1_1.PNG',
        status: 1,
    },
    {
        id: 20224,
        name: 'Alternatives Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20224_1_1.PNG',
        status: 1,
    },
    {
        id: 20225,
        name: 'Macatawa Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20225_1_1.PNG',
        status: 1,
    },
    {
        id: 20226,
        name: 'Berkshire Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20226_1_1.PNG',
        status: 1,
    },
    {
        id: 20227,
        name: 'Howard Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20227_1_1.PNG',
        status: 1,
    },
    {
        id: 20228,
        name: 'First National Bank of Bemidji',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20228_1_1.PNG',
        status: 1,
    },
    {
        id: 20231,
        name: 'FLATWATER BANK',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20231_1_1.PNG',
        status: 1,
    },
    {
        id: 20234,
        name: 'Freedom Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20234_1_1.PNG',
        status: 1,
    },
    {
        id: 20237,
        name: 'Charter Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20237_1_1.PNG',
        status: 1,
    },
    {
        id: 20245,
        name: 'Century Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20245_1_1.PNG',
        status: 1,
    },
    {
        id: 20247,
        name: 'Progressive National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20247_1_1.PNG',
        status: 1,
    },
    {
        id: 20249,
        name: 'Members First Community CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20249_1_1.PNG',
        status: 1,
    },
    {
        id: 20250,
        name: 'Benchmark Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20250_1_1.PNG',
        status: 1,
    },
    {
        id: 20252,
        name: 'Commercial National Bank (Texarkana)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20252_1_1.PNG',
        status: 1,
    },
    {
        id: 20254,
        name: 'Meridian Bank (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20254_1_1.PNG',
        status: 1,
    },
    {
        id: 20256,
        name: 'Jefferson Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20256_1_1.PNG',
        status: 1,
    },
    {
        id: 20258,
        name: 'RGV Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20258_1_1.PNG',
        status: 1,
    },
    {
        id: 20259,
        name: 'Blackhawk Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20259_1_1.PNG',
        status: 1,
    },
    {
        id: 20261,
        name: 'Evergreen Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 20264,
        name: 'Virginia National (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20264_1_1.PNG',
        status: 1,
    },
    {
        id: 20265,
        name: 'Virginia National (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20265_1_1.PNG',
        status: 1,
    },
    {
        id: 20266,
        name: 'Town & Country Bank and Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20266_1_1.PNG',
        status: 1,
    },
    {
        id: 20268,
        name: 'NBKC Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20268_1_2.SVG',
        status: 1,
    },
    {
        id: 20284,
        name: 'Town & Country Bank Midwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20284_1_1.PNG',
        status: 1,
    },
    {
        id: 20286,
        name: 'Square 1 Bank (with CompanyID - Upgraded)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20286_1_1.PNG',
        status: 1,
    },
    {
        id: 20289,
        name: 'Northpointe Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20289_1_1.PNG',
        status: 1,
    },
    {
        id: 20291,
        name: 'Affinity Plus Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20291_1_1.PNG',
        status: 1,
    },
    {
        id: 20292,
        name: 'Sentry Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20292_1_1.PNG',
        status: 1,
    },
    {
        id: 20293,
        name: 'Heartland National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20293_1_1.PNG',
        status: 1,
    },
    {
        id: 20294,
        name: 'Greenfield Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20294_1_1.PNG',
        status: 1,
    },
    {
        id: 20306,
        name: 'Capital City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20306_1_1.PNG',
        status: 1,
    },
    {
        id: 20307,
        name: 'Capital City Bank (Personal Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20307_1_1.PNG',
        status: 1,
    },
    {
        id: 20312,
        name: 'Security National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20312_1_1.PNG',
        status: 1,
    },
    {
        id: 20314,
        name: 'Easthampton Savings Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20314_1_1.PNG',
        status: 1,
    },
    {
        id: 20322,
        name: 'Bank of Tennessee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20322_1_1.PNG',
        status: 1,
    },
    {
        id: 20325,
        name: 'High Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20325_1_1.PNG',
        status: 1,
    },
    {
        id: 20331,
        name: 'Morton Community Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20331_1_1.PNG',
        status: 1,
    },
    {
        id: 20332,
        name: 'MountainOne',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20332_1_1.PNG',
        status: 1,
    },
    {
        id: 20333,
        name: 'Texas Gulf Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20333_1_1.PNG',
        status: 1,
    },
    {
        id: 20334,
        name: 'The Stephenson National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20334_1_1.PNG',
        status: 1,
    },
    {
        id: 20336,
        name: 'First Trust & Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20336_1_1.PNG',
        status: 1,
    },
    {
        id: 20337,
        name: 'GLACIER FAMILY OF BANKS',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20337_1_1.PNG',
        status: 1,
    },
    {
        id: 20338,
        name: 'BCB Community Bank (Personal & Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20338_1_1.PNG',
        status: 1,
    },
    {
        id: 20339,
        name: 'Grand Ridge National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20339_1_1.PNG',
        status: 1,
    },
    {
        id: 20342,
        name: 'Boulder Dam Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20342_1_1.PNG',
        status: 1,
    },
    {
        id: 20350,
        name: 'Walled Lake School Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20350_1_1.PNG',
        status: 1,
    },
    {
        id: 20356,
        name: "Mariner's Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20356_1_1.PNG',
        status: 1,
    },
    {
        id: 20364,
        name: 'GHA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20364_1_1.PNG',
        status: 1,
    },
    {
        id: 20368,
        name: 'Vantage Bank (Formerly Inter National Bank)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20368_1_1.PNG',
        status: 1,
    },
    {
        id: 20369,
        name: 'Pan American Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20369_1_1.PNG',
        status: 1,
    },
    {
        id: 20370,
        name: 'St. Johns Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20370_1_1.PNG',
        status: 1,
    },
    {
        id: 20377,
        name: 'Superior Credit Union (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20377_1_1.PNG',
        status: 1,
    },
    {
        id: 20381,
        name: 'Citizens Community Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20381_1_1.PNG',
        status: 1,
    },
    {
        id: 20388,
        name: 'Texas Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20388_1_1.PNG',
        status: 1,
    },
    {
        id: 20392,
        name: 'Lake Shore Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20392_1_1.PNG',
        status: 1,
    },
    {
        id: 20393,
        name: 'ConnectOne Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20393_1_1.PNG',
        status: 1,
    },
    {
        id: 20395,
        name: 'Community Bank of the Chesapeake',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20395_1_1.PNG',
        status: 1,
    },
    {
        id: 20396,
        name: 'Citizens Bank (Lafayette, TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20396_1_1.PNG',
        status: 1,
    },
    {
        id: 20403,
        name: 'Bryn Mawr Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20403_1_1.PNG',
        status: 1,
    },
    {
        id: 20404,
        name: 'National Exchange Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20404_1_1.PNG',
        status: 1,
    },
    {
        id: 20406,
        name: 'Ciera Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20406_1_1.PNG',
        status: 1,
    },
    {
        id: 20407,
        name: 'Waukesha State Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20407_1_1.PNG',
        status: 1,
    },
    {
        id: 20408,
        name: 'Legacy State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20408_1_1.PNG',
        status: 1,
    },
    {
        id: 20413,
        name: 'Tulsa Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20413_1_1.PNG',
        status: 1,
    },
    {
        id: 20416,
        name: 'First Florida Integrity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20416_1_1.PNG',
        status: 1,
    },
    {
        id: 20419,
        name: 'Alden State Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20419_1_1.PNG',
        status: 1,
    },
    {
        id: 20427,
        name: 'East Cambridge Savings Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20427_1_1.PNG',
        status: 1,
    },
    {
        id: 20430,
        name: 'Renasant Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20430_1_1.PNG',
        status: 1,
    },
    {
        id: 20439,
        name: 'Bank of Tescott',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20439_1_1.PNG',
        status: 1,
    },
    {
        id: 20440,
        name: 'Community Shores Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20440_1_1.PNG',
        status: 1,
    },
    {
        id: 20443,
        name: 'VacationLand Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20443_1_1.PNG',
        status: 1,
    },
    {
        id: 20444,
        name: 'Post Office Employees Federal Credit Union (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20444_1_1.PNG',
        status: 1,
    },
    {
        id: 20445,
        name: 'Citizens National Bank (TX) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20445_1_1.PNG',
        status: 1,
    },
    {
        id: 20454,
        name: 'Springfield Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20454_1_1.PNG',
        status: 1,
    },
    {
        id: 20455,
        name: 'The First National Bank & Trust Co. of Vinita',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20455_1_1.PNG',
        status: 1,
    },
    {
        id: 20462,
        name: 'Evansville Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20462_1_1.PNG',
        status: 1,
    },
    {
        id: 20465,
        name: 'Western Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20465_1_1.PNG',
        status: 1,
    },
    {
        id: 20470,
        name: 'New Frontier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20470_1_1.PNG',
        status: 1,
    },
    {
        id: 20475,
        name: 'Farmers & Merchant Bank of South Carolina',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20475_1_1.PNG',
        status: 1,
    },
    {
        id: 20480,
        name: 'Guaranty Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20480_1_1.PNG',
        status: 1,
    },
    {
        id: 20483,
        name: 'Bank of San Francisco',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20483_1_1.PNG',
        status: 1,
    },
    {
        id: 20485,
        name: 'Auto Club Trust, FSB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20485_1_1.PNG',
        status: 1,
    },
    {
        id: 20487,
        name: 'Shelby County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20487_1_1.PNG',
        status: 1,
    },
    {
        id: 20488,
        name: 'Kleberg Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20488_1_1.PNG',
        status: 1,
    },
    {
        id: 20490,
        name: 'Memphis City Employees CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20490_1_1.PNG',
        status: 1,
    },
    {
        id: 20502,
        name: 'Bank of Franklin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20502_1_1.PNG',
        status: 1,
    },
    {
        id: 20513,
        name: 'Santee Cooper Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20513_1_1.PNG',
        status: 1,
    },
    {
        id: 20521,
        name: 'The Bank Of Perry County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20521_1_1.PNG',
        status: 1,
    },
    {
        id: 20522,
        name: 'Community First Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20522_1_1.PNG',
        status: 1,
    },
    {
        id: 20524,
        name: 'Gulf Winds Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20524_1_1.PNG',
        status: 1,
    },
    {
        id: 20532,
        name: 'Oriental Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20532_1_1.PNG',
        status: 1,
    },
    {
        id: 20535,
        name: 'First Interstate Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20535_1_1.PNG',
        status: 1,
    },
    {
        id: 20538,
        name: 'The Eastern Colorado Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20538_1_1.PNG',
        status: 1,
    },
    {
        id: 20539,
        name: 'FVCbank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20539_1_1.PNG',
        status: 1,
    },
    {
        id: 20540,
        name: 'Security State Bank (ST)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20540_1_1.PNG',
        status: 1,
    },
    {
        id: 20547,
        name: 'Farmers National Bank (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20547_1_1.PNG',
        status: 1,
    },
    {
        id: 20548,
        name: 'Dakota Community Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20548_1_1.PNG',
        status: 1,
    },
    {
        id: 20552,
        name: 'Industrial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20552_1_1.PNG',
        status: 1,
    },
    {
        id: 20553,
        name: 'Chino Commercial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20553_1_1.PNG',
        status: 1,
    },
    {
        id: 20554,
        name: 'Minnequa Works CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20554_1_1.PNG',
        status: 1,
    },
    {
        id: 20555,
        name: 'Platte Valley Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20555_1_1.PNG',
        status: 1,
    },
    {
        id: 20562,
        name: 'Marion State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20562_1_1.PNG',
        status: 1,
    },
    {
        id: 20565,
        name: 'Northeast Bank (ME)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20565_1_1.PNG',
        status: 1,
    },
    {
        id: 20567,
        name: 'Liberty National Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20567_1_1.PNG',
        status: 1,
    },
    {
        id: 20570,
        name: 'York Traditions Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20570_1_1.PNG',
        status: 1,
    },
    {
        id: 20574,
        name: 'First Bank of Montana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20574_1_1.PNG',
        status: 1,
    },
    {
        id: 20575,
        name: 'Denmark State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20575_1_1.PNG',
        status: 1,
    },
    {
        id: 20584,
        name: 'White Rose Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20584_1_1.PNG',
        status: 1,
    },
    {
        id: 20589,
        name: 'Peshtigo National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20589_1_1.PNG',
        status: 1,
    },
    {
        id: 20592,
        name: 'Cross-First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20592_1_1.PNG',
        status: 1,
    },
    {
        id: 20593,
        name: 'American Momentum Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20593_1_1.PNG',
        status: 1,
    },
    {
        id: 20594,
        name: 'ValueBank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20594_1_1.PNG',
        status: 1,
    },
    {
        id: 20597,
        name: 'Columbia Bank (NJ) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20597_1_1.PNG',
        status: 1,
    },
    {
        id: 20598,
        name: 'Glens Falls National Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20598_1_1.PNG',
        status: 1,
    },
    {
        id: 20599,
        name: 'Lumbee Guaranty Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20599_1_1.PNG',
        status: 1,
    },
    {
        id: 20600,
        name: 'Cheaha Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20600_1_1.PNG',
        status: 1,
    },
    {
        id: 20601,
        name: 'Family SeCredit Unionrity Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20601_1_1.PNG',
        status: 1,
    },
    {
        id: 20602,
        name: 'Bradford National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20602_1_1.PNG',
        status: 1,
    },
    {
        id: 20606,
        name: 'West Valley National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20606_1_1.PNG',
        status: 1,
    },
    {
        id: 20612,
        name: 'McHenry Savings Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20612_1_1.PNG',
        status: 1,
    },
    {
        id: 20613,
        name: 'Lyndon State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20613_1_1.PNG',
        status: 1,
    },
    {
        id: 20623,
        name: 'Ray Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20623_1_1.PNG',
        status: 1,
    },
    {
        id: 20627,
        name: '1st State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20627_1_1.PNG',
        status: 1,
    },
    {
        id: 20632,
        name: 'Bogota Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20632_1_1.PNG',
        status: 1,
    },
    {
        id: 20635,
        name: 'Citizens National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20635_1_1.PNG',
        status: 1,
    },
    {
        id: 20636,
        name: 'First Harrison Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20636_1_1.PNG',
        status: 1,
    },
    {
        id: 20642,
        name: 'Taylor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20642_1_1.PNG',
        status: 1,
    },
    {
        id: 20644,
        name: 'Midwest Regional Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20644_1_1.PNG',
        status: 1,
    },
    {
        id: 20646,
        name: 'Vantage Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20646_1_1.PNG',
        status: 1,
    },
    {
        id: 20650,
        name: 'Shamrock Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20650_1_1.PNG',
        status: 1,
    },
    {
        id: 20652,
        name: 'Vanderbilt University Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20652_1_1.PNG',
        status: 1,
    },
    {
        id: 20653,
        name: 'Cathay Bank (CA) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20653_1_1.PNG',
        status: 1,
    },
    {
        id: 20655,
        name: 'Enterprise Bank of South Carolina',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20655_1_1.PNG',
        status: 1,
    },
    {
        id: 20657,
        name: 'Jones National Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20657_1_1.PNG',
        status: 1,
    },
    {
        id: 20658,
        name: 'The Cooperative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20658_1_1.PNG',
        status: 1,
    },
    {
        id: 20659,
        name: 'Farmers Bank of Milton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20659_1_1.PNG',
        status: 1,
    },
    {
        id: 20665,
        name: 'GHS Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20665_1_1.PNG',
        status: 1,
    },
    {
        id: 20672,
        name: 'Iroquois Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20672_1_1.PNG',
        status: 1,
    },
    {
        id: 20675,
        name: 'Olympia Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20675_1_1.PNG',
        status: 1,
    },
    {
        id: 20680,
        name: 'Main Street Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20680_1_1.PNG',
        status: 1,
    },
    {
        id: 20681,
        name: 'MTCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20681_1_1.PNG',
        status: 1,
    },
    {
        id: 20690,
        name: 'Piggott State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20690_1_1.PNG',
        status: 1,
    },
    {
        id: 20692,
        name: 'Origin Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20692_1_1.PNG',
        status: 1,
    },
    {
        id: 20698,
        name: 'Cass Commercial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20698_1_1.PNG',
        status: 1,
    },
    {
        id: 20700,
        name: 'College Savings Bank (Montana)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20700_1_1.PNG',
        status: 1,
    },
    {
        id: 20717,
        name: 'Black Hill Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20717_1_1.PNG',
        status: 1,
    },
    {
        id: 20720,
        name: 'Union Bank of Mena',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20720_1_1.PNG',
        status: 1,
    },
    {
        id: 20722,
        name: 'ISU Credit Union (ID)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20722_1_1.PNG',
        status: 1,
    },
    {
        id: 20723,
        name: 'Stockmens Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20723_1_1.PNG',
        status: 1,
    },
    {
        id: 20724,
        name: 'Legends Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20724_1_1.PNG',
        status: 1,
    },
    {
        id: 20725,
        name: '4Front Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20725_1_1.PNG',
        status: 1,
    },
    {
        id: 20727,
        name: 'Genoa Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20727_1_1.PNG',
        status: 1,
    },
    {
        id: 20728,
        name: 'Bonduel State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20728_1_1.PNG',
        status: 1,
    },
    {
        id: 20733,
        name: 'Community Bank (Longview, TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20733_1_1.PNG',
        status: 1,
    },
    {
        id: 20737,
        name: 'Southern FIRST (Busineess)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20737_1_1.PNG',
        status: 1,
    },
    {
        id: 20739,
        name: 'American City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20739_1_1.PNG',
        status: 1,
    },
    {
        id: 20740,
        name: 'Farmers and Merchants Bank (MD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20740_1_1.PNG',
        status: 1,
    },
    {
        id: 20741,
        name: 'SNB Bank NA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20741_1_1.PNG',
        status: 1,
    },
    {
        id: 20742,
        name: 'Countryside Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20742_1_1.PNG',
        status: 1,
    },
    {
        id: 20743,
        name: 'Blue Ridge Bank (SC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20743_1_1.PNG',
        status: 1,
    },
    {
        id: 20744,
        name: 'First National Bank in Olney',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20744_1_1.PNG',
        status: 1,
    },
    {
        id: 20745,
        name: 'Collins State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20745_1_1.PNG',
        status: 1,
    },
    {
        id: 20748,
        name: 'Gulf Coast Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20748_1_1.PNG',
        status: 1,
    },
    {
        id: 20749,
        name: '1st Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20749_1_1.PNG',
        status: 1,
    },
    {
        id: 29578,
        name: 'Peoples Bank (Chatham, LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29580,
        name: 'Pioneer State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29581,
        name: 'Savings Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29582,
        name: 'Security State Bank of Oklee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29583,
        name: 'Security State Bank SB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29584,
        name: 'Chambers State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29585,
        name: 'First State Bank (Elmore City)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29586,
        name: 'First State Bank of Kiester',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29588,
        name: 'Preferred Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29715,
        name: 'FirsTier Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29888,
        name: 'Hopewell Chemical Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 30002,
        name: 'Virginia Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 30003,
        name: 'West Texas Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 30044,
        name: 'Texas Bridge Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 30046,
        name: 'Spokane City Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 30074,
        name: 'DC Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 30258,
        name: 'Banc of California (Business Online Pro)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_30258_1_1.PNG',
        status: 1,
    },
    {
        id: 30260,
        name: 'Banc of California (Business Online)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_30260_1_1.PNG',
        status: 1,
    },
    {
        id: 30548,
        name: 'United Bank (Philadelphia)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_30548_1_2.SVG',
        status: 1,
    },
    {
        id: 30686,
        name: 'RVA Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 20750,
        name: 'Gilmer National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20750_1_1.PNG',
        status: 1,
    },
    {
        id: 20752,
        name: 'Main Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20752_1_1.PNG',
        status: 1,
    },
    {
        id: 20753,
        name: 'Royal Business Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20753_1_1.PNG',
        status: 1,
    },
    {
        id: 20754,
        name: 'First United Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20754_1_1.PNG',
        status: 1,
    },
    {
        id: 20756,
        name: 'Riverset Credit',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20756_1_1.PNG',
        status: 1,
    },
    {
        id: 20758,
        name: 'TIAA Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20758_1_2.SVG',
        status: 1,
    },
    {
        id: 20759,
        name: 'County Bank (DE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20759_1_1.PNG',
        status: 1,
    },
    {
        id: 20760,
        name: 'Gateway Bank (AZ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20760_1_1.PNG',
        status: 1,
    },
    {
        id: 20773,
        name: 'State Bank Northwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20773_1_1.PNG',
        status: 1,
    },
    {
        id: 20777,
        name: 'Northwest Bank of Rockford',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20777_1_1.PNG',
        status: 1,
    },
    {
        id: 20782,
        name: 'PCM Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20782_1_1.PNG',
        status: 1,
    },
    {
        id: 20794,
        name: 'Spokane Firefighters Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20794_1_1.PNG',
        status: 1,
    },
    {
        id: 20796,
        name: 'First American National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20796_1_1.PNG',
        status: 1,
    },
    {
        id: 20797,
        name: 'Bank of Frankewing',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20797_1_1.PNG',
        status: 1,
    },
    {
        id: 20801,
        name: 'First Education Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20801_1_1.PNG',
        status: 1,
    },
    {
        id: 20809,
        name: 'American Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20809_1_2.SVG',
        status: 1,
    },
    {
        id: 20817,
        name: 'Jeff Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 20826,
        name: 'Scient Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20826_1_1.PNG',
        status: 1,
    },
    {
        id: 20830,
        name: 'FCB Banks - County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20830_1_1.PNG',
        status: 1,
    },
    {
        id: 20832,
        name: 'Security Bank Minnesota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20832_1_1.PNG',
        status: 1,
    },
    {
        id: 20846,
        name: 'Bank 7',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20846_1_1.PNG',
        status: 1,
    },
    {
        id: 20859,
        name: 'HSA Bank- My Accounts',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20859_1_1.PNG',
        status: 1,
    },
    {
        id: 20871,
        name: "Tarrant County's Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20871_1_1.PNG',
        status: 1,
    },
    {
        id: 20874,
        name: 'Schertz Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 20875,
        name: 'Central Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20875_1_1.PNG',
        status: 1,
    },
    {
        id: 20876,
        name: 'Tampa Postal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20876_1_1.PNG',
        status: 1,
    },
    {
        id: 20886,
        name: 'First Option Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20886_1_1.PNG',
        status: 1,
    },
    {
        id: 20892,
        name: 'Firsttech Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20892_1_1.PNG',
        status: 1,
    },
    {
        id: 20927,
        name: 'Auburn Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20927_1_1.PNG',
        status: 1,
    },
    {
        id: 20943,
        name: 'Oakworth Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20943_1_1.PNG',
        status: 1,
    },
    {
        id: 20952,
        name: 'British Petroleum Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20952_1_1.PNG',
        status: 1,
    },
    {
        id: 20959,
        name: 'Oregon Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20959_1_1.PNG',
        status: 1,
    },
    {
        id: 20966,
        name: 'Wolverine State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20966_1_2.SVG',
        status: 1,
    },
    {
        id: 20968,
        name: 'Marriott Employee Federal CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20968_1_1.PNG',
        status: 1,
    },
    {
        id: 20969,
        name: 'Alpena Alcona Area Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20969_1_1.PNG',
        status: 1,
    },
    {
        id: 20970,
        name: 'Municipal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20970_1_2.SVG',
        status: 1,
    },
    {
        id: 20971,
        name: 'Marquette Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20971_1_1.PNG',
        status: 1,
    },
    {
        id: 20991,
        name: 'Premier Bank, Inc.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20991_1_1.PNG',
        status: 1,
    },
    {
        id: 20998,
        name: 'Parkside Financial Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_20998_1_1.PNG',
        status: 1,
    },
    {
        id: 21007,
        name: 'Du Quoin State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21007_1_1.PNG',
        status: 1,
    },
    {
        id: 21117,
        name: 'One American Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21117_1_1.PNG',
        status: 1,
    },
    {
        id: 21126,
        name: 'TVA Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21126_1_1.PNG',
        status: 1,
    },
    {
        id: 21131,
        name: 'Prime Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21131_1_1.PNG',
        status: 1,
    },
    {
        id: 21137,
        name: 'OneWest Bank(Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21137_1_1.PNG',
        status: 1,
    },
    {
        id: 21155,
        name: 'Erie Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21155_1_1.PNG',
        status: 1,
    },
    {
        id: 21171,
        name: 'Farmers State Bank (Personal - Waupaca)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21171_1_1.PNG',
        status: 1,
    },
    {
        id: 21172,
        name: 'First Savings Bank - Online Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21172_1_1.PNG',
        status: 1,
    },
    {
        id: 21191,
        name: 'Blue Flame Credit Union (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21191_1_1.PNG',
        status: 1,
    },
    {
        id: 21198,
        name: 'Liberty First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21198_1_1.PNG',
        status: 1,
    },
    {
        id: 21199,
        name: 'Greenville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21205,
        name: 'T Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21205_1_1.PNG',
        status: 1,
    },
    {
        id: 21213,
        name: 'Millennium Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21213_1_1.PNG',
        status: 1,
    },
    {
        id: 21214,
        name: 'CIT Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21214_1_2.SVG',
        status: 1,
    },
    {
        id: 21217,
        name: 'Grand Rapids State Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21217_1_1.PNG',
        status: 1,
    },
    {
        id: 21259,
        name: 'Bankwell Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21259_1_1.PNG',
        status: 1,
    },
    {
        id: 21265,
        name: 'Harleysville Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21265_1_1.PNG',
        status: 1,
    },
    {
        id: 21281,
        name: 'Virginia Commonwealth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21281_1_1.PNG',
        status: 1,
    },
    {
        id: 21289,
        name: 'Revere Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21289_1_1.PNG',
        status: 1,
    },
    {
        id: 21291,
        name: 'Yakima Fed Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21291_1_1.PNG',
        status: 1,
    },
    {
        id: 21293,
        name: 'Randolph Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21293_1_1.PNG',
        status: 1,
    },
    {
        id: 21337,
        name: 'TMH Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21337_1_1.PNG',
        status: 1,
    },
    {
        id: 21340,
        name: 'First Federal Savings Bank (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21340_1_1.PNG',
        status: 1,
    },
    {
        id: 21343,
        name: 'First Federal Savings of Lorain',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21343_1_1.PNG',
        status: 1,
    },
    {
        id: 21344,
        name: 'Cecily County School Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21344_1_1.PNG',
        status: 1,
    },
    {
        id: 21353,
        name: 'Merchants and Manufacturers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21353_1_1.PNG',
        status: 1,
    },
    {
        id: 21354,
        name: 'Coastal Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21354_1_1.PNG',
        status: 1,
    },
    {
        id: 21376,
        name: 'Communities of Abilene Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21376_1_1.PNG',
        status: 1,
    },
    {
        id: 21381,
        name: 'Blackhawk Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21381_1_1.PNG',
        status: 1,
    },
    {
        id: 21394,
        name: 'Citizens Deposit Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21394_1_1.PNG',
        status: 1,
    },
    {
        id: 21422,
        name: 'Bay Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21422_1_1.PNG',
        status: 1,
    },
    {
        id: 21432,
        name: 'Santa Barbara Teachers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21432_1_1.PNG',
        status: 1,
    },
    {
        id: 21433,
        name: 'Veritex Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21433_1_1.PNG',
        status: 1,
    },
    {
        id: 21450,
        name: 'Eagle Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21450_1_1.PNG',
        status: 1,
    },
    {
        id: 21479,
        name: 'Bank of Abbeville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21479_1_1.PNG',
        status: 1,
    },
    {
        id: 21480,
        name: 'Bank of George',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21480_1_1.PNG',
        status: 1,
    },
    {
        id: 21505,
        name: 'Community Bank of Joseph Oregon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21505_1_1.PNG',
        status: 1,
    },
    {
        id: 21511,
        name: 'Fitzsimons Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21511_1_1.PNG',
        status: 1,
    },
    {
        id: 21525,
        name: 'Horizons Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21525_1_1.PNG',
        status: 1,
    },
    {
        id: 21539,
        name: 'Great Western Bank eBanking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21539_1_1.PNG',
        status: 1,
    },
    {
        id: 21540,
        name: 'FrontierBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21540_1_1.PNG',
        status: 1,
    },
    {
        id: 21542,
        name: 'City National Bank Metropolis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21542_1_1.PNG',
        status: 1,
    },
    {
        id: 21543,
        name: 'Peoples Bank of the South',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21543_1_1.PNG',
        status: 1,
    },
    {
        id: 21558,
        name: 'Quantum National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21558_1_1.PNG',
        status: 1,
    },
    {
        id: 21561,
        name: 'The Peoples Bank (SC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21561_1_1.PNG',
        status: 1,
    },
    {
        id: 21573,
        name: 'First Choice Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21573_1_1.PNG',
        status: 1,
    },
    {
        id: 21582,
        name: 'First Liberty Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21582_1_1.PNG',
        status: 1,
    },
    {
        id: 21601,
        name: 'Pacific Western Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21601_1_1.PNG',
        status: 1,
    },
    {
        id: 21603,
        name: 'Texas Champion Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21603_1_1.PNG',
        status: 1,
    },
    {
        id: 21641,
        name: 'Motor City Co-op CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21641_1_1.PNG',
        status: 1,
    },
    {
        id: 21645,
        name: 'Grow Financial Federal Credit Union Personal Portal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21645_1_1.PNG',
        status: 1,
    },
    {
        id: 21652,
        name: 'CPM Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21652_1_1.PNG',
        status: 1,
    },
    {
        id: 21662,
        name: 'Gateway -Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21662_1_1.PNG',
        status: 1,
    },
    {
        id: 21664,
        name: 'Gorham Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21664_1_1.PNG',
        status: 1,
    },
    {
        id: 21670,
        name: 'Energy Capital Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21670_1_1.PNG',
        status: 1,
    },
    {
        id: 21675,
        name: 'Afena Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21675_1_1.PNG',
        status: 1,
    },
    {
        id: 21688,
        name: 'Old Dominion National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21688_1_1.PNG',
        status: 1,
    },
    {
        id: 21691,
        name: 'First Federal Savings Bank of Champaign Urbana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21691_1_1.PNG',
        status: 1,
    },
    {
        id: 21708,
        name: 'Glacier Hills Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21708_1_1.PNG',
        status: 1,
    },
    {
        id: 21714,
        name: 'Northwest Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21714_1_1.PNG',
        status: 1,
    },
    {
        id: 21727,
        name: 'Eastern Michigan Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21727_1_1.PNG',
        status: 1,
    },
    {
        id: 21731,
        name: 'American Bank & Trust Company (Tulsa)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21739,
        name: 'Path Finder Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21739_1_1.PNG',
        status: 1,
    },
    {
        id: 21743,
        name: 'Peoples Choice Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21743_1_1.PNG',
        status: 1,
    },
    {
        id: 21744,
        name: 'Home Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21745,
        name: 'Tri-County Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21755,
        name: 'MutualOne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21755_1_1.PNG',
        status: 1,
    },
    {
        id: 21763,
        name: 'Charter Bank- Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21763_1_1.PNG',
        status: 1,
    },
    {
        id: 21775,
        name: 'Barrington Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21775_1_1.PNG',
        status: 1,
    },
    {
        id: 21776,
        name: 'Tremont',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21776_1_1.PNG',
        status: 1,
    },
    {
        id: 21778,
        name: 'Barrington Bank & Trust (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21778_1_1.PNG',
        status: 1,
    },
    {
        id: 21779,
        name: 'Jeff Davis Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21780,
        name: 'Arbor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21780_1_1.PNG',
        status: 1,
    },
    {
        id: 21783,
        name: 'Financial Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21783_1_1.PNG',
        status: 1,
    },
    {
        id: 21789,
        name: 'Royal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21789_1_1.PNG',
        status: 1,
    },
    {
        id: 21790,
        name: 'Alliance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21794,
        name: 'Altonized Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21794_1_1.PNG',
        status: 1,
    },
    {
        id: 21799,
        name: 'HometownCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21799_1_1.PNG',
        status: 1,
    },
    {
        id: 21800,
        name: 'Solvay Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21800_1_1.PNG',
        status: 1,
    },
    {
        id: 21808,
        name: 'First Financial Northwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21808_1_1.PNG',
        status: 1,
    },
    {
        id: 21812,
        name: 'Members 1st of NJ Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21812_1_1.PNG',
        status: 1,
    },
    {
        id: 21813,
        name: 'NMA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21813_1_1.PNG',
        status: 1,
    },
    {
        id: 21815,
        name: 'Maine State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21815_1_1.PNG',
        status: 1,
    },
    {
        id: 21819,
        name: 'Trademark Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21819_1_1.PNG',
        status: 1,
    },
    {
        id: 21821,
        name: 'First Century Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21821_1_1.PNG',
        status: 1,
    },
    {
        id: 21823,
        name: 'Service First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21824,
        name: 'NYM Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21824_1_1.PNG',
        status: 1,
    },
    {
        id: 21836,
        name: 'TCT Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21849,
        name: 'On Tap Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21849_1_1.PNG',
        status: 1,
    },
    {
        id: 21861,
        name: 'Peoples Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21861_1_1.PNG',
        status: 1,
    },
    {
        id: 21864,
        name: 'Great Midwest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21864_1_1.PNG',
        status: 1,
    },
    {
        id: 21868,
        name: 'Northeast Texas Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21868_1_1.PNG',
        status: 1,
    },
    {
        id: 21869,
        name: 'Alliance Bank of Arizona',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21869_1_1.PNG',
        status: 1,
    },
    {
        id: 21877,
        name: 'Northeast Security Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21877_1_1.PNG',
        status: 1,
    },
    {
        id: 21899,
        name: 'Integrity Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21908,
        name: 'The Nebraska Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21909,
        name: 'Peoples Bank Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21914,
        name: 'Bank of Milan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21914_1_1.PNG',
        status: 1,
    },
    {
        id: 21922,
        name: 'American National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21922_1_1.PNG',
        status: 1,
    },
    {
        id: 21943,
        name: 'Fayetteville Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21951,
        name: 'The Community Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21951_1_1.PNG',
        status: 1,
    },
    {
        id: 21954,
        name: 'BANKIOWA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21954_1_1.PNG',
        status: 1,
    },
    {
        id: 21955,
        name: 'Sheboygan Area Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21955_1_1.PNG',
        status: 1,
    },
    {
        id: 21956,
        name: 'Great River Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21956_1_1.PNG',
        status: 1,
    },
    {
        id: 21957,
        name: 'Ambler Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 21965,
        name: 'Cornerstone Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21965_1_1.PNG',
        status: 1,
    },
    {
        id: 21987,
        name: 'Union Bank of North Carolina',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21987_1_1.PNG',
        status: 1,
    },
    {
        id: 21989,
        name: 'Oriental Bank Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21989_1_1.PNG',
        status: 1,
    },
    {
        id: 21993,
        name: 'Bank of Lindsay',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21993_1_1.PNG',
        status: 1,
    },
    {
        id: 21999,
        name: 'Firefighters Community',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_21999_1_1.PNG',
        status: 1,
    },
    {
        id: 22013,
        name: 'IQ Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22013_1_1.PNG',
        status: 1,
    },
    {
        id: 22016,
        name: 'Marin County FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22019,
        name: 'Mars Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22019_1_1.PNG',
        status: 1,
    },
    {
        id: 22023,
        name: 'Wolf River Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22023_1_1.PNG',
        status: 1,
    },
    {
        id: 22028,
        name: 'SUNY Fredonia Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22030,
        name: 'Catholic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22030_1_1.PNG',
        status: 1,
    },
    {
        id: 22031,
        name: 'Bridge Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22031_1_1.PNG',
        status: 1,
    },
    {
        id: 22035,
        name: 'Tulsa Teachers Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22035_1_1.PNG',
        status: 1,
    },
    {
        id: 22039,
        name: 'Security State Bank (Warroad, MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22053,
        name: 'Michigan Tech Employees Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22061,
        name: 'Mountain Commerce Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22061_1_1.PNG',
        status: 1,
    },
    {
        id: 22062,
        name: "People's Exchange Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22062_1_1.PNG',
        status: 1,
    },
    {
        id: 22068,
        name: 'Baraga County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22071,
        name: 'The Harbor Bank of Maryland (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22071_1_1.PNG',
        status: 1,
    },
    {
        id: 22074,
        name: 'Washington Saving Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22074_1_1.PNG',
        status: 1,
    },
    {
        id: 22075,
        name: 'CresCom Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22075_1_1.PNG',
        status: 1,
    },
    {
        id: 22077,
        name: 'Michigan Legacy Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22077_1_1.PNG',
        status: 1,
    },
    {
        id: 22080,
        name: 'Elmira Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22080_1_1.PNG',
        status: 1,
    },
    {
        id: 22082,
        name: 'St. Francis X Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22082_1_1.PNG',
        status: 1,
    },
    {
        id: 22087,
        name: 'Plains Commerce Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22087_1_1.PNG',
        status: 1,
    },
    {
        id: 22088,
        name: 'Woodsboro Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22088_1_1.PNG',
        status: 1,
    },
    {
        id: 22093,
        name: 'Security Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22094,
        name: 'Academic Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22094_1_1.PNG',
        status: 1,
    },
    {
        id: 22095,
        name: 'First National Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22095_1_1.PNG',
        status: 1,
    },
    {
        id: 22100,
        name: 'Glen Rock Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22100_1_1.PNG',
        status: 1,
    },
    {
        id: 22111,
        name: 'ProMedica Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22111_1_1.PNG',
        status: 1,
    },
    {
        id: 22115,
        name: 'Crystal Lake Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22115_1_1.PNG',
        status: 1,
    },
    {
        id: 22121,
        name: 'Old Fort Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22121_1_1.PNG',
        status: 1,
    },
    {
        id: 22125,
        name: 'Vermillion Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22125_1_1.PNG',
        status: 1,
    },
    {
        id: 22141,
        name: 'Dexta Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22141_1_1.PNG',
        status: 1,
    },
    {
        id: 22148,
        name: "People's Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22148_1_1.PNG',
        status: 1,
    },
    {
        id: 22149,
        name: 'Discovery Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22149_1_1.PNG',
        status: 1,
    },
    {
        id: 22151,
        name: 'ATLANTIC FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22151_1_1.PNG',
        status: 1,
    },
    {
        id: 22157,
        name: 'First Farmers & Merchants',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22158,
        name: 'Co-op CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22158_1_1.PNG',
        status: 1,
    },
    {
        id: 22159,
        name: 'Polish American Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22159_1_1.PNG',
        status: 1,
    },
    {
        id: 22170,
        name: 'BC Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22170_1_1.PNG',
        status: 1,
    },
    {
        id: 22195,
        name: 'Rio Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22195_1_1.PNG',
        status: 1,
    },
    {
        id: 22198,
        name: 'First State Bank of Middlebury',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22198_1_1.PNG',
        status: 1,
    },
    {
        id: 22204,
        name: 'First Minnesota Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22204_1_2.SVG',
        status: 1,
    },
    {
        id: 22205,
        name: 'State Savings Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22205_1_1.PNG',
        status: 1,
    },
    {
        id: 22220,
        name: 'American Investors Bank and Mortgage',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22220_1_1.PNG',
        status: 1,
    },
    {
        id: 22221,
        name: 'Bank of Lexington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22221_1_1.PNG',
        status: 1,
    },
    {
        id: 22236,
        name: 'Liberty Bay',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22236_1_1.PNG',
        status: 1,
    },
    {
        id: 22242,
        name: 'Co-op Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22245,
        name: 'Wasatch Peaks Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22245_1_1.PNG',
        status: 1,
    },
    {
        id: 22251,
        name: 'First Eagle Bank (E-Corp Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22251_1_1.PNG',
        status: 1,
    },
    {
        id: 22255,
        name: 'Chain Bridge Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22255_1_1.PNG',
        status: 1,
    },
    {
        id: 22256,
        name: 'Western States Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22256_1_1.PNG',
        status: 1,
    },
    {
        id: 22261,
        name: 'Liberty Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22277,
        name: 'Metairie Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22277_1_1.PNG',
        status: 1,
    },
    {
        id: 22279,
        name: 'Jonesburg State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22279_1_1.PNG',
        status: 1,
    },
    {
        id: 22281,
        name: 'BeaconCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22281_1_1.PNG',
        status: 1,
    },
    {
        id: 22284,
        name: 'ILWU Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22284_1_1.PNG',
        status: 1,
    },
    {
        id: 22288,
        name: 'Lisle Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22288_1_1.PNG',
        status: 1,
    },
    {
        id: 22295,
        name: 'MidSouth Community FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22295_1_1.PNG',
        status: 1,
    },
    {
        id: 22298,
        name: 'United Bay Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22298_1_1.PNG',
        status: 1,
    },
    {
        id: 22299,
        name: 'First National Bank of Groton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22299_1_1.PNG',
        status: 1,
    },
    {
        id: 22313,
        name: 'Platte Valley Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22313_1_1.PNG',
        status: 1,
    },
    {
        id: 22315,
        name: 'Farmers State Bank(FSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22315_1_1.PNG',
        status: 1,
    },
    {
        id: 22318,
        name: 'First Bank(MO - Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22318_1_1.PNG',
        status: 1,
    },
    {
        id: 22326,
        name: 'Andigo Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22326_1_1.PNG',
        status: 1,
    },
    {
        id: 22327,
        name: 'Wheaton Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22327_1_1.PNG',
        status: 1,
    },
    {
        id: 22330,
        name: 'Wintrust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22330_1_1.PNG',
        status: 1,
    },
    {
        id: 22335,
        name: 'WinTrust Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22335_1_1.PNG',
        status: 1,
    },
    {
        id: 22336,
        name: 'Wheaton Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22336_1_1.PNG',
        status: 1,
    },
    {
        id: 22338,
        name: 'Lake Forest Bank & Trust (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22338_1_1.PNG',
        status: 1,
    },
    {
        id: 22339,
        name: 'Northbrook Bank & Trust- (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22339_1_1.PNG',
        status: 1,
    },
    {
        id: 22342,
        name: 'ChoiceOne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22342_1_1.PNG',
        status: 1,
    },
    {
        id: 22343,
        name: 'Pacific Crest Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22343_1_1.PNG',
        status: 1,
    },
    {
        id: 22344,
        name: 'Klamath Public Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22344_1_1.PNG',
        status: 1,
    },
    {
        id: 22345,
        name: 'First Federal Bank of Wisconsin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22345_1_1.PNG',
        status: 1,
    },
    {
        id: 22361,
        name: 'PyraMaxBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22361_1_1.PNG',
        status: 1,
    },
    {
        id: 22371,
        name: 'First Commercial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22371_1_1.PNG',
        status: 1,
    },
    {
        id: 22378,
        name: 'AMG National Trust bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22378_1_1.PNG',
        status: 1,
    },
    {
        id: 22385,
        name: 'Frankenmuth CreditUnion',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22385_1_1.PNG',
        status: 1,
    },
    {
        id: 22390,
        name: 'Popular Community Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22390_1_1.PNG',
        status: 1,
    },
    {
        id: 22391,
        name: 'MemberFocus Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22391_1_1.PNG',
        status: 1,
    },
    {
        id: 22415,
        name: 'Wrentham Cooperative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22415_1_1.PNG',
        status: 1,
    },
    {
        id: 22417,
        name: 'Cincinnati Interagency Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22417_1_1.PNG',
        status: 1,
    },
    {
        id: 22420,
        name: 'Dowell Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22420_1_1.PNG',
        status: 1,
    },
    {
        id: 22424,
        name: 'First National Bank of Oneida',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22424_1_1.PNG',
        status: 1,
    },
    {
        id: 22427,
        name: 'American State Bank (Texas)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22427_1_1.PNG',
        status: 1,
    },
    {
        id: 22430,
        name: 'Merrit Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22430_1_1.PNG',
        status: 1,
    },
    {
        id: 22434,
        name: 'Miami Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22434_1_1.PNG',
        status: 1,
    },
    {
        id: 22435,
        name: 'Country Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22435_1_1.PNG',
        status: 1,
    },
    {
        id: 22438,
        name: 'The Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22438_1_1.PNG',
        status: 1,
    },
    {
        id: 22448,
        name: 'ELGA Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22448_1_1.PNG',
        status: 1,
    },
    {
        id: 22450,
        name: 'First Citizen Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22450_1_1.PNG',
        status: 1,
    },
    {
        id: 22455,
        name: 'Volunteer Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22455_1_1.PNG',
        status: 'Beta',
    },
    {
        id: 22459,
        name: 'TopLine Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22459_1_1.PNG',
        status: 1,
    },
    {
        id: 22469,
        name: 'Chime',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22469_1_1.PNG',
        status: 1,
    },
    {
        id: 22478,
        name: 'Bank of Zachary',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22478_1_1.PNG',
        status: 1,
    },
    {
        id: 22494,
        name: 'Signet Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22494_1_1.PNG',
        status: 1,
    },
    {
        id: 22497,
        name: "Prince George's Community Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22497_1_1.PNG',
        status: 1,
    },
    {
        id: 22504,
        name: 'Farmers & Merchants Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22504_1_1.PNG',
        status: 1,
    },
    {
        id: 22505,
        name: 'New Buffalo Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22505_1_1.PNG',
        status: 1,
    },
    {
        id: 22508,
        name: 'Algonquin Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22508_1_1.PNG',
        status: 1,
    },
    {
        id: 22511,
        name: 'MemoryBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22511_1_1.PNG',
        status: 1,
    },
    {
        id: 22512,
        name: 'Houston Metropolitan Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22512_1_1.PNG',
        status: 1,
    },
    {
        id: 22513,
        name: 'Bank Iowa',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22513_1_1.PNG',
        status: 1,
    },
    {
        id: 22519,
        name: 'First American Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22519_1_1.PNG',
        status: 1,
    },
    {
        id: 22524,
        name: 'AAC Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22524_1_1.PNG',
        status: 1,
    },
    {
        id: 22526,
        name: 'Reliant Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22526_1_1.PNG',
        status: 1,
    },
    {
        id: 22528,
        name: 'Public Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22528_1_1.PNG',
        status: 1,
    },
    {
        id: 22529,
        name: 'Wesleyan Investment Foundation',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22529_1_1.PNG',
        status: 1,
    },
    {
        id: 22535,
        name: 'Sterling Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22535_1_1.PNG',
        status: 1,
    },
    {
        id: 22536,
        name: 'Fairmont Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22536_1_1.PNG',
        status: 1,
    },
    {
        id: 22537,
        name: 'North State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22537_1_2.SVG',
        status: 1,
    },
    {
        id: 22538,
        name: 'First National Bank of Steeleville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22538_1_1.PNG',
        status: 1,
    },
    {
        id: 22539,
        name: 'Associated Healthcare Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22539_1_1.PNG',
        status: 1,
    },
    {
        id: 22542,
        name: 'Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22542_1_1.PNG',
        status: 1,
    },
    {
        id: 22549,
        name: 'Select Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22549_1_1.PNG',
        status: 1,
    },
    {
        id: 22565,
        name: 'TCF Bank Digital Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22565_1_1.PNG',
        status: 1,
    },
    {
        id: 22570,
        name: 'Alliance Bank FDIC',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22570_1_1.PNG',
        status: 1,
    },
    {
        id: 22578,
        name: 'Horizon Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22578_1_1.PNG',
        status: 1,
    },
    {
        id: 22585,
        name: 'Itasca Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22585_1_1.PNG',
        status: 1,
    },
    {
        id: 22587,
        name: 'Pilot Grove Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22587_1_1.PNG',
        status: 1,
    },
    {
        id: 22590,
        name: 'Western Bank (South West)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22590_1_1.PNG',
        status: 1,
    },
    {
        id: 22591,
        name: 'SeaComm Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22591_1_1.PNG',
        status: 1,
    },
    {
        id: 22593,
        name: 'The Heritage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22593_1_1.PNG',
        status: 1,
    },
    {
        id: 22595,
        name: 'BB Americas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22595_1_1.PNG',
        status: 1,
    },
    {
        id: 22600,
        name: 'Landmark Bank (Business - MO, OK, TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22600_1_1.PNG',
        status: 1,
    },
    {
        id: 22610,
        name: 'Thumb National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22610_1_1.PNG',
        status: 1,
    },
    {
        id: 22612,
        name: 'Baltimore County Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22612_1_1.PNG',
        status: 1,
    },
    {
        id: 22613,
        name: 'Merrimack County Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22613_1_1.PNG',
        status: 1,
    },
    {
        id: 22615,
        name: 'Washington Gas Light Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22615_1_1.PNG',
        status: 1,
    },
    {
        id: 22616,
        name: 'QCR Holdings Inc.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22616_1_1.PNG',
        status: 1,
    },
    {
        id: 22617,
        name: '1st MidAmerica Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22617_1_1.PNG',
        status: 1,
    },
    {
        id: 22624,
        name: 'Bank of Bridger',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22625,
        name: 'Bank of Central Florida (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22625_1_1.PNG',
        status: 1,
    },
    {
        id: 22633,
        name: 'First Citizens National (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22633_1_1.PNG',
        status: 1,
    },
    {
        id: 22636,
        name: 'US Federal 1364 Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22636_1_1.PNG',
        status: 1,
    },
    {
        id: 22646,
        name: 'Kellogg Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22646_1_1.PNG',
        status: 1,
    },
    {
        id: 22651,
        name: 'Business First Bank - b1b',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22651_1_1.PNG',
        status: 1,
    },
    {
        id: 22654,
        name: 'Allied Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22654_1_1.PNG',
        status: 1,
    },
    {
        id: 22657,
        name: 'AurGroup Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22657_1_1.PNG',
        status: 1,
    },
    {
        id: 22658,
        name: 'First Peoples Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22658_1_1.PNG',
        status: 1,
    },
    {
        id: 22660,
        name: 'Empower Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22660_1_1.PNG',
        status: 1,
    },
    {
        id: 22663,
        name: 'Sterling Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22663_1_1.PNG',
        status: 1,
    },
    {
        id: 22670,
        name: 'Coastal Carolina National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22670_1_1.PNG',
        status: 1,
    },
    {
        id: 22671,
        name: 'Total Choice Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22671_1_1.PNG',
        status: 1,
    },
    {
        id: 22676,
        name: 'First Federal Savings Bank (Evansville, IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22676_1_1.PNG',
        status: 1,
    },
    {
        id: 22677,
        name: 'Hudson River Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22677_1_1.PNG',
        status: 1,
    },
    {
        id: 22679,
        name: 'Plainscapital Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22679_1_1.PNG',
        status: 1,
    },
    {
        id: 22680,
        name: 'First Security Bank of Nevada',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22680_1_1.PNG',
        status: 1,
    },
    {
        id: 22681,
        name: 'Coastal States Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22681_1_2.SVG',
        status: 1,
    },
    {
        id: 22686,
        name: 'North Central Area Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22686_1_1.PNG',
        status: 1,
    },
    {
        id: 22690,
        name: 'Diamond Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22690_1_1.PNG',
        status: 1,
    },
    {
        id: 22694,
        name: 'Dominion Energy Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22694_1_1.PNG',
        status: 1,
    },
    {
        id: 22697,
        name: 'Sperry Associates Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22697_1_1.PNG',
        status: 1,
    },
    {
        id: 22704,
        name: 'Altana Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22704_1_1.PNG',
        status: 1,
    },
    {
        id: 22708,
        name: 'Bank of Deerfield',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22708_1_1.PNG',
        status: 1,
    },
    {
        id: 22709,
        name: 'Templeton Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22709_1_1.PNG',
        status: 1,
    },
    {
        id: 22710,
        name: 'Bank of the Rockies',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22710_1_1.PNG',
        status: 1,
    },
    {
        id: 22711,
        name: 'Centreville Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22711_1_1.PNG',
        status: 1,
    },
    {
        id: 22713,
        name: 'Community Powered Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22713_1_1.PNG',
        status: 1,
    },
    {
        id: 22716,
        name: 'Novation CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22716_1_1.PNG',
        status: 1,
    },
    {
        id: 22717,
        name: 'HomeTown Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22717_1_1.PNG',
        status: 1,
    },
    {
        id: 22721,
        name: 'Community Bank of Elmhurst (Consumer)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22721_1_1.PNG',
        status: 1,
    },
    {
        id: 22723,
        name: 'Flint Area School Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22723_1_1.PNG',
        status: 1,
    },
    {
        id: 22724,
        name: 'Independence Bank (RI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22724_1_1.PNG',
        status: 1,
    },
    {
        id: 22728,
        name: 'Astera Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22728_1_1.PNG',
        status: 1,
    },
    {
        id: 22731,
        name: 'PriorityOne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22731_1_1.PNG',
        status: 1,
    },
    {
        id: 22732,
        name: 'Bay Area Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22732_1_1.PNG',
        status: 1,
    },
    {
        id: 22739,
        name: 'Fox Valley Savings Bank​',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22739_1_1.PNG',
        status: 1,
    },
    {
        id: 22740,
        name: 'Security National Bank of South Dakota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22740_1_1.PNG',
        status: 1,
    },
    {
        id: 22746,
        name: 'River Valley Credit Union (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22746_1_1.PNG',
        status: 1,
    },
    {
        id: 22751,
        name: 'Peninsula Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22751_1_1.PNG',
        status: 1,
    },
    {
        id: 22752,
        name: 'Credit Union of Richmond',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22752_1_1.PNG',
        status: 1,
    },
    {
        id: 22753,
        name: 'Farmers State Bank Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22753_1_1.PNG',
        status: 1,
    },
    {
        id: 22754,
        name: 'Charter West',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22754_1_1.PNG',
        status: 1,
    },
    {
        id: 22755,
        name: 'Aflac Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22755_1_1.PNG',
        status: 1,
    },
    {
        id: 22761,
        name: 'Sunnyside Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22761_1_1.PNG',
        status: 1,
    },
    {
        id: 22763,
        name: 'Canals & Trails Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22763_1_1.PNG',
        status: 1,
    },
    {
        id: 22767,
        name: 'Eclipse Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22767_1_1.PNG',
        status: 1,
    },
    {
        id: 22769,
        name: 'Citizens Bank of Edmond',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22769_1_1.PNG',
        status: 1,
    },
    {
        id: 22772,
        name: 'Bridgewater Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22772_1_1.PNG',
        status: 1,
    },
    {
        id: 22778,
        name: 'SaviBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22778_1_1.PNG',
        status: 1,
    },
    {
        id: 22779,
        name: 'Partners Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22779_1_1.PNG',
        status: 1,
    },
    {
        id: 22780,
        name: 'Hawaii Central',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22780_1_1.PNG',
        status: 1,
    },
    {
        id: 22792,
        name: 'Pine Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22792_1_1.PNG',
        status: 1,
    },
    {
        id: 22795,
        name: 'Marion County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22795_1_1.PNG',
        status: 1,
    },
    {
        id: 22797,
        name: 'First National Bank in Staunton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22797_1_1.PNG',
        status: 1,
    },
    {
        id: 22803,
        name: 'Middletown Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22803_1_1.PNG',
        status: 1,
    },
    {
        id: 22806,
        name: 'The First State Bank of Rosemount',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22806_1_1.PNG',
        status: 1,
    },
    {
        id: 22813,
        name: 'Bay Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22813_1_1.PNG',
        status: 1,
    },
    {
        id: 22819,
        name: 'Reelfoot Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22819_1_1.PNG',
        status: 1,
    },
    {
        id: 22820,
        name: 'BankCherokee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22820_1_1.PNG',
        status: 1,
    },
    {
        id: 22821,
        name: 'Clarkston State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22821_1_1.PNG',
        status: 1,
    },
    {
        id: 22824,
        name: 'Apollo Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22824_1_1.PNG',
        status: 1,
    },
    {
        id: 22825,
        name: 'Edison National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22825_1_1.PNG',
        status: 1,
    },
    {
        id: 22826,
        name: 'Avista CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22826_1_1.PNG',
        status: 1,
    },
    {
        id: 22830,
        name: 'Falcon National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22830_1_1.PNG',
        status: 1,
    },
    {
        id: 22833,
        name: 'First Bank of the Palm Beaches',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22833_1_1.PNG',
        status: 1,
    },
    {
        id: 22835,
        name: 'First Minnetonka City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22835_1_1.PNG',
        status: 1,
    },
    {
        id: 22836,
        name: 'Hatboro Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22836_1_1.PNG',
        status: 1,
    },
    {
        id: 22837,
        name: 'Cass Commercial (Ebanking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22839,
        name: 'Hebron Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22839_1_1.PNG',
        status: 1,
    },
    {
        id: 22840,
        name: 'Safe Harbor Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22840_1_1.PNG',
        status: 1,
    },
    {
        id: 22841,
        name: 'Bank of St. Croix',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22841_1_1.PNG',
        status: 1,
    },
    {
        id: 22842,
        name: 'Broward HealthCare Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22842_1_1.PNG',
        status: 1,
    },
    {
        id: 22845,
        name: 'Maspeth Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22845_1_1.PNG',
        status: 1,
    },
    {
        id: 22850,
        name: 'Mississippi FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22850_1_1.PNG',
        status: 1,
    },
    {
        id: 22854,
        name: 'GPO Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22855,
        name: 'Darden Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22855_1_1.PNG',
        status: 1,
    },
    {
        id: 22857,
        name: 'Citizens Independent Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22857_1_1.PNG',
        status: 1,
    },
    {
        id: 22861,
        name: 'Cortland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22866,
        name: 'DNB First',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22866_1_2.SVG',
        status: 1,
    },
    {
        id: 22867,
        name: 'Tri-State Bank of Memphis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22867_1_1.PNG',
        status: 1,
    },
    {
        id: 22868,
        name: 'Central Maine Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22868_1_1.PNG',
        status: 1,
    },
    {
        id: 22869,
        name: 'The Peoples Bank Biloxi MS',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22869_1_1.PNG',
        status: 1,
    },
    {
        id: 22870,
        name: 'Genesee Regional Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 22892,
        name: 'Qualstar Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22892_1_2.SVG',
        status: 1,
    },
    {
        id: 22897,
        name: 'MEMBERS1st Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22897_1_1.PNG',
        status: 1,
    },
    {
        id: 22902,
        name: 'Robertson Banking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22902_1_1.PNG',
        status: 1,
    },
    {
        id: 22903,
        name: 'Secured Advantage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22903_1_1.PNG',
        status: 1,
    },
    {
        id: 22908,
        name: 'DNB First (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22908_1_2.SVG',
        status: 1,
    },
    {
        id: 22910,
        name: 'Timberline Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22910_1_1.PNG',
        status: 1,
    },
    {
        id: 22914,
        name: 'Star USA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22914_1_1.PNG',
        status: 1,
    },
    {
        id: 22917,
        name: 'Jefferson Parish',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22917_1_1.PNG',
        status: 1,
    },
    {
        id: 22918,
        name: 'The First State Bank of Fountain',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22918_1_1.PNG',
        status: 1,
    },
    {
        id: 22920,
        name: 'Cornerstone Bank (Eureka Springs)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22920_1_1.PNG',
        status: 1,
    },
    {
        id: 22921,
        name: 'Twin Rivers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22921_1_1.PNG',
        status: 1,
    },
    {
        id: 22924,
        name: 'Bank of Clovis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22924_1_1.PNG',
        status: 1,
    },
    {
        id: 22933,
        name: 'Carlinville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22933_1_1.PNG',
        status: 1,
    },
    {
        id: 22934,
        name: 'First National Bank of Muscatine',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22934_1_1.PNG',
        status: 1,
    },
    {
        id: 22937,
        name: 'First Newton National Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22937_1_1.PNG',
        status: 1,
    },
    {
        id: 22939,
        name: 'Homeland Credit Union (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22939_1_1.PNG',
        status: 1,
    },
    {
        id: 22945,
        name: 'Family First Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22945_1_1.PNG',
        status: 1,
    },
    {
        id: 22951,
        name: 'Jefferson Bank and Trust (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22951_1_1.PNG',
        status: 1,
    },
    {
        id: 22955,
        name: 'Kish Bank - Personal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22955_1_1.PNG',
        status: 1,
    },
    {
        id: 22961,
        name: 'Ticonderoga Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22961_1_1.PNG',
        status: 1,
    },
    {
        id: 22963,
        name: 'Mechanics Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22963_1_1.PNG',
        status: 1,
    },
    {
        id: 22965,
        name: 'Oregon Pacific Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22965_1_1.PNG',
        status: 1,
    },
    {
        id: 22966,
        name: 'Miners & Merchants Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22966_1_1.PNG',
        status: 1,
    },
    {
        id: 22967,
        name: 'Earthmover CU Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22967_1_1.PNG',
        status: 1,
    },
    {
        id: 22970,
        name: 'Rabun County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22970_1_1.PNG',
        status: 1,
    },
    {
        id: 22971,
        name: 'Newton Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22971_1_1.PNG',
        status: 1,
    },
    {
        id: 22974,
        name: 'River City Bank (Louisville, KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22974_1_1.PNG',
        status: 1,
    },
    {
        id: 22975,
        name: 'South Louisiana Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22975_1_1.PNG',
        status: 1,
    },
    {
        id: 22976,
        name: 'Spring Hill State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22976_1_1.PNG',
        status: 1,
    },
    {
        id: 22977,
        name: 'Bank of Cadiz & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22977_1_1.PNG',
        status: 1,
    },
    {
        id: 22982,
        name: 'VisionBank of Iowa',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22982_1_1.PNG',
        status: 1,
    },
    {
        id: 22984,
        name: 'Animas Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22984_1_1.PNG',
        status: 1,
    },
    {
        id: 22986,
        name: 'First National Bank Of Pandora',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22986_1_1.PNG',
        status: 1,
    },
    {
        id: 22994,
        name: 'Security First Bank of North Dakota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22994_1_1.PNG',
        status: 1,
    },
    {
        id: 22995,
        name: 'Bank of Lake Mills',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22995_1_1.PNG',
        status: 1,
    },
    {
        id: 22996,
        name: '1st Ed Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_22996_1_1.PNG',
        status: 1,
    },
    {
        id: 22997,
        name: 'New York Times Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 23004,
        name: 'Hiawatha Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23004_1_1.PNG',
        status: 1,
    },
    {
        id: 23006,
        name: 'The Peoples State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23006_1_1.PNG',
        status: 1,
    },
    {
        id: 23019,
        name: 'Peoples State Bank (Prairie du Chien,WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23019_1_1.PNG',
        status: 1,
    },
    {
        id: 23028,
        name: 'SouthStar Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23028_1_1.PNG',
        status: 1,
    },
    {
        id: 23033,
        name: 'Preferred Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23033_1_1.PNG',
        status: 1,
    },
    {
        id: 23035,
        name: 'Putnam County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23035_1_1.PNG',
        status: 1,
    },
    {
        id: 23044,
        name: 'FNB Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23044_1_1.PNG',
        status: 1,
    },
    {
        id: 23047,
        name: 'Waterford Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23047_1_1.PNG',
        status: 1,
    },
    {
        id: 23048,
        name: 'Legacy National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23048_1_1.PNG',
        status: 1,
    },
    {
        id: 23051,
        name: 'Capitol Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23051_1_1.PNG',
        status: 1,
    },
    {
        id: 23054,
        name: 'WyHy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23054_1_1.PNG',
        status: 1,
    },
    {
        id: 23057,
        name: 'Chesapeake Bank of Maryland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23057_1_1.PNG',
        status: 1,
    },
    {
        id: 23058,
        name: 'St.Lawrence Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23058_1_1.PNG',
        status: 1,
    },
    {
        id: 23060,
        name: 'Century Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23060_1_1.PNG',
        status: 1,
    },
    {
        id: 23063,
        name: 'Luana Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 23067,
        name: 'First Bank New Jersey',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23067_1_1.PNG',
        status: 1,
    },
    {
        id: 23070,
        name: 'Azura Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23070_1_1.PNG',
        status: 1,
    },
    {
        id: 23076,
        name: 'Dean Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23076_1_1.PNG',
        status: 1,
    },
    {
        id: 23079,
        name: 'Kemba Roanoke Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23079_1_1.PNG',
        status: 1,
    },
    {
        id: 23085,
        name: 'First Federal Bank of Kansas (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23085_1_1.PNG',
        status: 1,
    },
    {
        id: 23088,
        name: 'South Atlantic Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23088_1_1.PNG',
        status: 1,
    },
    {
        id: 23103,
        name: 'Bank of Utica - Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23103_1_1.PNG',
        status: 1,
    },
    {
        id: 23104,
        name: 'Lake Chem Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23104_1_1.PNG',
        status: 1,
    },
    {
        id: 23111,
        name: 'Farmers Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23111_1_1.PNG',
        status: 1,
    },
    {
        id: 23112,
        name: 'Metro City Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23112_1_1.PNG',
        status: 1,
    },
    {
        id: 23117,
        name: 'Voyage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23117_1_1.PNG',
        status: 1,
    },
    {
        id: 23125,
        name: 'Lenco Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23125_1_1.PNG',
        status: 1,
    },
    {
        id: 23126,
        name: 'Titan Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23126_1_1.PNG',
        status: 1,
    },
    {
        id: 23127,
        name: 'Bankcda',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23127_1_2.SVG',
        status: 1,
    },
    {
        id: 23137,
        name: 'The First National Bank (Lawrence)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23137_1_1.PNG',
        status: 1,
    },
    {
        id: 23143,
        name: 'Kern Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23143_1_1.PNG',
        status: 1,
    },
    {
        id: 23147,
        name: 'Connect Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 23148,
        name: 'Alliance bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23148_1_1.PNG',
        status: 1,
    },
    {
        id: 23149,
        name: 'Corn City State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23149_1_1.PNG',
        status: 1,
    },
    {
        id: 23151,
        name: 'First National Bank (SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23151_1_1.PNG',
        status: 1,
    },
    {
        id: 23153,
        name: 'New Cumberland Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23153_1_1.PNG',
        status: 1,
    },
    {
        id: 23155,
        name: 'Border State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23155_1_1.PNG',
        status: 1,
    },
    {
        id: 23160,
        name: 'Secny Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23160_1_1.PNG',
        status: 1,
    },
    {
        id: 23163,
        name: 'IH Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23163_1_1.PNG',
        status: 1,
    },
    {
        id: 23164,
        name: 'Tomahawk Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23164_1_1.PNG',
        status: 1,
    },
    {
        id: 23165,
        name: 'Members Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23165_1_1.PNG',
        status: 1,
    },
    {
        id: 23167,
        name: 'Northwestern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23167_1_1.PNG',
        status: 1,
    },
    {
        id: 23168,
        name: 'Clarion Country Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23168_1_1.PNG',
        status: 1,
    },
    {
        id: 23169,
        name: 'Napoleon State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23169_1_1.PNG',
        status: 1,
    },
    {
        id: 23175,
        name: 'Sawyer Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23175_1_1.PNG',
        status: 1,
    },
    {
        id: 23176,
        name: 'Prestige Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23176_1_1.PNG',
        status: 1,
    },
    {
        id: 23179,
        name: 'Team One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 23181,
        name: 'American Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23181_1_2.SVG',
        status: 1,
    },
    {
        id: 23188,
        name: 'Citizens First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23188_1_1.PNG',
        status: 1,
    },
    {
        id: 23208,
        name: 'PremierBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23208_1_1.PNG',
        status: 1,
    },
    {
        id: 23229,
        name: 'YNB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23229_1_1.PNG',
        status: 1,
    },
    {
        id: 23232,
        name: 'Kohler Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23232_1_1.PNG',
        status: 1,
    },
    {
        id: 23234,
        name: 'John Marshall Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23234_1_1.PNG',
        status: 1,
    },
    {
        id: 23264,
        name: 'Oconee Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23264_1_1.PNG',
        status: 1,
    },
    {
        id: 23271,
        name: 'KEmba CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23271_1_1.PNG',
        status: 1,
    },
    {
        id: 23272,
        name: 'Mid-Missouri Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23272_1_1.PNG',
        status: 1,
    },
    {
        id: 23276,
        name: 'Byron Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23276_1_1.PNG',
        status: 1,
    },
    {
        id: 23279,
        name: 'Farmers State Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23279_1_2.SVG',
        status: 1,
    },
    {
        id: 23281,
        name: 'Dannemora Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23281_1_1.PNG',
        status: 1,
    },
    {
        id: 23286,
        name: 'Farmers Bank (Jasmine County)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23286_1_1.PNG',
        status: 1,
    },
    {
        id: 23290,
        name: 'Gold Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23290_1_1.PNG',
        status: 1,
    },
    {
        id: 23293,
        name: 'South Division Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23293_1_1.PNG',
        status: 1,
    },
    {
        id: 23294,
        name: 'Iowa State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23294_1_1.PNG',
        status: 1,
    },
    {
        id: 23298,
        name: 'Citizens Bank N.A.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23298_1_1.PNG',
        status: 1,
    },
    {
        id: 23300,
        name: 'The Milford Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23300_1_1.PNG',
        status: 1,
    },
    {
        id: 23301,
        name: 'North Shore Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23301_1_1.PNG',
        status: 1,
    },
    {
        id: 23304,
        name: 'Southern States Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23304_1_1.PNG',
        status: 1,
    },
    {
        id: 23305,
        name: 'Elevations Credit Union (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23305_1_1.PNG',
        status: 1,
    },
    {
        id: 23308,
        name: 'Community State Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23308_1_1.PNG',
        status: 1,
    },
    {
        id: 23310,
        name: 'Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23310_1_1.PNG',
        status: 1,
    },
    {
        id: 23313,
        name: 'Food Industries Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23313_1_1.PNG',
        status: 1,
    },
    {
        id: 23315,
        name: 'Five County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23315_1_1.PNG',
        status: 1,
    },
    {
        id: 23316,
        name: 'Jackson County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23316_1_1.PNG',
        status: 1,
    },
    {
        id: 23317,
        name: 'Jefferson County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23317_1_1.PNG',
        status: 1,
    },
    {
        id: 23318,
        name: 'Heartland Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23318_1_1.PNG',
        status: 1,
    },
    {
        id: 23320,
        name: 'SouthPoint Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23320_1_1.PNG',
        status: 1,
    },
    {
        id: 23321,
        name: 'Western heritage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23321_1_1.PNG',
        status: 1,
    },
    {
        id: 23323,
        name: 'Blissfield State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23323_1_1.PNG',
        status: 1,
    },
    {
        id: 23324,
        name: 'Signature Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23324_1_1.PNG',
        status: 1,
    },
    {
        id: 23326,
        name: 'Texas Telcom Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23326_1_1.PNG',
        status: 1,
    },
    {
        id: 23331,
        name: 'Grand Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23331_1_1.PNG',
        status: 1,
    },
    {
        id: 23332,
        name: 'The Citizens Bank Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23332_1_1.PNG',
        status: 1,
    },
    {
        id: 23333,
        name: 'Unity One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23333_1_1.PNG',
        status: 1,
    },
    {
        id: 23334,
        name: 'Granite Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23334_1_1.PNG',
        status: 1,
    },
    {
        id: 23335,
        name: 'Southwest Capital Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23335_1_1.PNG',
        status: 1,
    },
    {
        id: 23347,
        name: 'Diablo Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23347_1_1.PNG',
        status: 1,
    },
    {
        id: 23352,
        name: 'The Peoples Bank Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23352_1_1.PNG',
        status: 1,
    },
    {
        id: 23362,
        name: 'Settlers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23362_1_1.PNG',
        status: 1,
    },
    {
        id: 23369,
        name: 'CrossRoads Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23369_1_1.PNG',
        status: 1,
    },
    {
        id: 23392,
        name: 'State Bank of Arcadia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23392_1_1.PNG',
        status: 1,
    },
    {
        id: 23400,
        name: 'Owen County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23400_1_1.PNG',
        status: 1,
    },
    {
        id: 23427,
        name: 'Liberty Bank For Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23427_1_1.PNG',
        status: 1,
    },
    {
        id: 23447,
        name: 'First Southern Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23447_1_1.PNG',
        status: 1,
    },
    {
        id: 23450,
        name: 'First National Bank - Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23450_1_1.PNG',
        status: 1,
    },
    {
        id: 23457,
        name: 'Community State Bank of Orbisonia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23457_1_1.PNG',
        status: 1,
    },
    {
        id: 23489,
        name: 'Midcoast FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23489_1_2.SVG',
        status: 1,
    },
    {
        id: 23490,
        name: 'Transcend Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23490_1_2.SVG',
        status: 1,
    },
    {
        id: 23513,
        name: 'Community Bank of the Chesapeake (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23513_1_1.PNG',
        status: 1,
    },
    {
        id: 23534,
        name: 'First Flight Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23534_1_1.PNG',
        status: 1,
    },
    {
        id: 23535,
        name: 'Bank Of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23535_1_1.PNG',
        status: 1,
    },
    {
        id: 23538,
        name: 'Industrial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23538_1_1.PNG',
        status: 1,
    },
    {
        id: 23544,
        name: 'Community Bank Mankato',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23544_1_1.PNG',
        status: 1,
    },
    {
        id: 23545,
        name: 'Settlers Bank (Ohio)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23545_1_1.PNG',
        status: 1,
    },
    {
        id: 23550,
        name: 'Fusion eBanking',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23550_1_1.PNG',
        status: 1,
    },
    {
        id: 23551,
        name: 'Mediapolis Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 23552,
        name: 'Dearborn Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23552_1_1.PNG',
        status: 1,
    },
    {
        id: 23560,
        name: 'Security State Bank (WA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23560_1_1.PNG',
        status: 1,
    },
    {
        id: 23561,
        name: 'First Peoples Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23561_1_1.PNG',
        status: 1,
    },
    {
        id: 23564,
        name: 'Superior National Bank And Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23564_1_1.PNG',
        status: 1,
    },
    {
        id: 23565,
        name: 'First Trust Bank of Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23565_1_1.PNG',
        status: 1,
    },
    {
        id: 23566,
        name: 'First National Bank of Port Lavaca TX',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23566_1_1.PNG',
        status: 1,
    },
    {
        id: 23586,
        name: 'Service 1 FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23586_1_1.PNG',
        status: 1,
    },
    {
        id: 23592,
        name: 'State Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23592_1_1.PNG',
        status: 1,
    },
    {
        id: 23595,
        name: 'Bank of Labor',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23595_1_1.PNG',
        status: 1,
    },
    {
        id: 23616,
        name: 'United Bank of Kasasa',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23616_1_1.PNG',
        status: 1,
    },
    {
        id: 23617,
        name: 'Aurora Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23617_1_1.PNG',
        status: 1,
    },
    {
        id: 23620,
        name: 'Haskell National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23620_1_1.PNG',
        status: 1,
    },
    {
        id: 23622,
        name: 'People Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23622_1_1.PNG',
        status: 1,
    },
    {
        id: 23625,
        name: 'Coastal Carolina National Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23625_1_1.PNG',
        status: 1,
    },
    {
        id: 23650,
        name: 'UT Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23650_1_1.PNG',
        status: 1,
    },
    {
        id: 23653,
        name: 'Community Service Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23653_1_1.PNG',
        status: 1,
    },
    {
        id: 23656,
        name: 'Madison County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23656_1_1.PNG',
        status: 1,
    },
    {
        id: 23659,
        name: 'International Finance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23659_1_1.PNG',
        status: 1,
    },
    {
        id: 23666,
        name: 'Happy State Bank and Trust Company (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23666_1_1.PNG',
        status: 1,
    },
    {
        id: 23670,
        name: 'Kearney Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23670_1_1.PNG',
        status: 1,
    },
    {
        id: 23671,
        name: 'The Bank Of Landisburg',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23671_1_1.PNG',
        status: 1,
    },
    {
        id: 23674,
        name: 'Park Bank (Business - Madison, WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23674_1_1.PNG',
        status: 1,
    },
    {
        id: 23700,
        name: 'Commonwealth Business Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23700_1_1.PNG',
        status: 1,
    },
    {
        id: 23713,
        name: 'Altamaha Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23713_1_1.PNG',
        status: 1,
    },
    {
        id: 23724,
        name: 'Bitter Root Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23724_1_1.PNG',
        status: 1,
    },
    {
        id: 23732,
        name: 'Union Savings Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23732_1_1.PNG',
        status: 1,
    },
    {
        id: 23766,
        name: 'Northwest Christian Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23766_1_1.PNG',
        status: 1,
    },
    {
        id: 23787,
        name: 'Quaint Oak Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23787_1_1.PNG',
        status: 1,
    },
    {
        id: 23789,
        name: 'First Resource Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23789_1_1.PNG',
        status: 1,
    },
    {
        id: 23800,
        name: 'Bank of the Islands',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23800_1_1.PNG',
        status: 1,
    },
    {
        id: 23815,
        name: 'Casco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23815_1_1.PNG',
        status: 1,
    },
    {
        id: 23816,
        name: 'Luther Burbank Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23816_1_2.SVG',
        status: 1,
    },
    {
        id: 23817,
        name: 'Century Bank of the Ozarks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23817_1_1.PNG',
        status: 1,
    },
    {
        id: 23820,
        name: 'Watertown Savings Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23820_1_1.PNG',
        status: 1,
    },
    {
        id: 23826,
        name: 'Wayne Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23826_1_1.PNG',
        status: 1,
    },
    {
        id: 23827,
        name: 'City Credit Union Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23827_1_1.PNG',
        status: 1,
    },
    {
        id: 23853,
        name: 'Lincoln National Bank (LNB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23853_1_1.PNG',
        status: 1,
    },
    {
        id: 23861,
        name: 'Cenla Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23861_1_1.PNG',
        status: 1,
    },
    {
        id: 23883,
        name: 'Points West Community Bank (NE, WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23883_1_1.PNG',
        status: 1,
    },
    {
        id: 23886,
        name: 'Summit State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23886_1_1.PNG',
        status: 1,
    },
    {
        id: 23893,
        name: 'Availa Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23893_1_1.PNG',
        status: 1,
    },
    {
        id: 23903,
        name: 'East Texas Professional',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23903_1_1.PNG',
        status: 1,
    },
    {
        id: 23910,
        name: 'Hamilton Horizons Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23910_1_1.PNG',
        status: 1,
    },
    {
        id: 23913,
        name: 'Stanley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23913_1_1.PNG',
        status: 1,
    },
    {
        id: 23915,
        name: 'Grundy Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23915_1_1.PNG',
        status: 1,
    },
    {
        id: 23916,
        name: 'Signature Bank of Georgia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23916_1_1.PNG',
        status: 1,
    },
    {
        id: 23922,
        name: 'Bank of Sun Prairie',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23922_1_1.PNG',
        status: 1,
    },
    {
        id: 23924,
        name: 'Liberty Bank Minnesota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23924_1_1.PNG',
        status: 1,
    },
    {
        id: 23930,
        name: 'McFarland State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23930_1_1.PNG',
        status: 1,
    },
    {
        id: 23931,
        name: 'Community 1st Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 23932,
        name: 'Professional Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23932_1_1.PNG',
        status: 1,
    },
    {
        id: 23942,
        name: 'LG&E Co. Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23942_1_1.PNG',
        status: 1,
    },
    {
        id: 23943,
        name: 'Bank Of Utah',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23943_1_1.PNG',
        status: 1,
    },
    {
        id: 23944,
        name: 'Kerndt Brothers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23944_1_1.PNG',
        status: 1,
    },
    {
        id: 23959,
        name: "People's Bank of Gambier",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23959_1_1.PNG',
        status: 1,
    },
    {
        id: 23960,
        name: 'N.A.E. Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23960_1_1.PNG',
        status: 1,
    },
    {
        id: 23969,
        name: 'Medina County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23969_1_1.PNG',
        status: 1,
    },
    {
        id: 23970,
        name: 'Freedom Bank (Petersburg)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23970_1_1.PNG',
        status: 1,
    },
    {
        id: 23971,
        name: 'Southern Independent Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23971_1_1.PNG',
        status: 1,
    },
    {
        id: 23982,
        name: 'Brainerd Savings and Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23982_1_1.PNG',
        status: 1,
    },
    {
        id: 23983,
        name: 'First Bank of Virginia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23983_1_1.PNG',
        status: 1,
    },
    {
        id: 23984,
        name: 'First National Bank Money Connection',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23984_1_1.PNG',
        status: 1,
    },
    {
        id: 23991,
        name: 'Grant County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_23991_1_1.PNG',
        status: 1,
    },
    {
        id: 24009,
        name: "People's Community Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24009_1_1.PNG',
        status: 1,
    },
    {
        id: 24010,
        name: 'RBC Royal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24010_1_2.SVG',
        status: 1,
    },
    {
        id: 24011,
        name: 'Farmers State Bank - FSB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24011_1_1.PNG',
        status: 1,
    },
    {
        id: 24021,
        name: 'Sargent County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24021_1_1.PNG',
        status: 1,
    },
    {
        id: 24034,
        name: 'Farmers & Merchants State Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24034_1_1.PNG',
        status: 1,
    },
    {
        id: 24038,
        name: 'The State Bank - Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24038_1_1.PNG',
        status: 1,
    },
    {
        id: 24040,
        name: 'VeraBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24040_1_2.SVG',
        status: 1,
    },
    {
        id: 24041,
        name: 'TEXAR Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24041_1_1.PNG',
        status: 1,
    },
    {
        id: 24075,
        name: 'Lake Region Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24075_1_1.PNG',
        status: 1,
    },
    {
        id: 24081,
        name: 'Sunrise Bank (Florida)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24084,
        name: 'The Citizens Bank (Batesville)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24084_1_2.SVG',
        status: 1,
    },
    {
        id: 24087,
        name: 'Farmers State Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24087_1_1.PNG',
        status: 1,
    },
    {
        id: 24092,
        name: 'F&M Bank Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24092_1_1.PNG',
        status: 1,
    },
    {
        id: 24108,
        name: "Bank of O'Fallon",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24110,
        name: 'Lutheran Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24110_1_1.PNG',
        status: 1,
    },
    {
        id: 24111,
        name: 'Bank of Zumbrota',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24114,
        name: 'Bank of Washington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24114_1_1.PNG',
        status: 1,
    },
    {
        id: 24120,
        name: 'Texas Bank Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24120_1_1.PNG',
        status: 1,
    },
    {
        id: 24125,
        name: 'castroville state bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24125_1_1.PNG',
        status: 1,
    },
    {
        id: 24156,
        name: 'Citizens and Farmers Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24156_1_1.PNG',
        status: 1,
    },
    {
        id: 24157,
        name: 'Young American Financial Education Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24157_1_1.PNG',
        status: 1,
    },
    {
        id: 24159,
        name: 'State Street Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24159_1_1.PNG',
        status: 1,
    },
    {
        id: 24162,
        name: 'McKesson Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24162_1_1.PNG',
        status: 1,
    },
    {
        id: 24164,
        name: 'Mutual 1st Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24164_1_1.PNG',
        status: 1,
    },
    {
        id: 24165,
        name: 'Security Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24165_1_1.PNG',
        status: 1,
    },
    {
        id: 24169,
        name: 'Centricity Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24169_1_1.PNG',
        status: 1,
    },
    {
        id: 24173,
        name: 'BankVista',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24173_1_1.PNG',
        status: 1,
    },
    {
        id: 24174,
        name: 'Community Resource Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24174_1_1.PNG',
        status: 1,
    },
    {
        id: 24177,
        name: 'Kearny Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24177_1_1.PNG',
        status: 1,
    },
    {
        id: 24185,
        name: 'Hocking Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24185_1_1.PNG',
        status: 1,
    },
    {
        id: 24202,
        name: 'FNBC Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24214,
        name: 'Harford Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24214_1_1.PNG',
        status: 1,
    },
    {
        id: 24219,
        name: 'Fortress Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24219_1_2.SVG',
        status: 1,
    },
    {
        id: 24226,
        name: 'Land of Lincoln Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24226_1_1.PNG',
        status: 1,
    },
    {
        id: 24234,
        name: 'The Bank of Carbondale',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24234_1_1.PNG',
        status: 1,
    },
    {
        id: 24240,
        name: 'Stafford Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24240_1_1.PNG',
        status: 1,
    },
    {
        id: 24257,
        name: 'TAPCO Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24257_1_1.PNG',
        status: 1,
    },
    {
        id: 24293,
        name: '1st Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24293_1_1.PNG',
        status: 1,
    },
    {
        id: 24294,
        name: 'Rochester and Monroe County Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24297,
        name: 'Security State Bank (Kansas)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24297_1_1.PNG',
        status: 1,
    },
    {
        id: 24300,
        name: 'KSW Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24300_1_1.PNG',
        status: 1,
    },
    {
        id: 24305,
        name: 'Oxford University Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24305_1_1.PNG',
        status: 1,
    },
    {
        id: 24333,
        name: 'Badger Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24333_1_1.PNG',
        status: 1,
    },
    {
        id: 24334,
        name: 'First Community Bank (AR & MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24334_1_1.PNG',
        status: 1,
    },
    {
        id: 24337,
        name: 'The Bank of New Glarus',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24337_1_1.PNG',
        status: 1,
    },
    {
        id: 24340,
        name: 'Wells Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24340_1_1.PNG',
        status: 1,
    },
    {
        id: 24341,
        name: 'Southern Heritage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24341_1_1.PNG',
        status: 1,
    },
    {
        id: 24342,
        name: 'Citizens Bank of Americus',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24342_1_1.PNG',
        status: 1,
    },
    {
        id: 24344,
        name: 'Gulf Coast Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24344_1_1.PNG',
        status: 1,
    },
    {
        id: 24345,
        name: 'Farmers Bank (Greenwood)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24345_1_1.PNG',
        status: 1,
    },
    {
        id: 24347,
        name: 'Green Belt Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24347_1_1.PNG',
        status: 1,
    },
    {
        id: 24355,
        name: 'Oculina Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24355_1_1.PNG',
        status: 1,
    },
    {
        id: 24359,
        name: 'Valley Credit union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24359_1_1.PNG',
        status: 1,
    },
    {
        id: 24360,
        name: 'Woodlands National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24364,
        name: 'New Era Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24364_1_1.PNG',
        status: 1,
    },
    {
        id: 24365,
        name: 'Janesville State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24365_1_1.PNG',
        status: 1,
    },
    {
        id: 24367,
        name: 'Exchange Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24367_1_1.PNG',
        status: 1,
    },
    {
        id: 24376,
        name: 'Firefighters Credit Union - Wisconsin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24378,
        name: 'East Idaho Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24378_1_1.PNG',
        status: 1,
    },
    {
        id: 24379,
        name: 'South Story Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24379_1_1.PNG',
        status: 1,
    },
    {
        id: 24383,
        name: 'Palmetto Health Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24389,
        name: 'Mid Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24389_1_1.PNG',
        status: 1,
    },
    {
        id: 24396,
        name: 'First Southern State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24396_1_1.PNG',
        status: 1,
    },
    {
        id: 24397,
        name: 'Security Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24397_1_1.PNG',
        status: 1,
    },
    {
        id: 24424,
        name: 'Pinal County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24424_1_1.PNG',
        status: 1,
    },
    {
        id: 24426,
        name: 'Alabama One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24452,
        name: 'Holcomb Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24452_1_1.PNG',
        status: 1,
    },
    {
        id: 24472,
        name: 'Lone Star State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24472_1_1.PNG',
        status: 1,
    },
    {
        id: 24483,
        name: 'Citizens State Bank (Cadott, WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24483_1_1.PNG',
        status: 1,
    },
    {
        id: 24484,
        name: 'Mid-Illini Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24484_1_1.PNG',
        status: 1,
    },
    {
        id: 24488,
        name: 'Highland State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24488_1_1.PNG',
        status: 1,
    },
    {
        id: 24493,
        name: 'South Bay Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24493_1_2.SVG',
        status: 1,
    },
    {
        id: 24497,
        name: 'Valliance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24497_1_1.PNG',
        status: 1,
    },
    {
        id: 24503,
        name: 'First state bank of Uvalde',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24516,
        name: 'The Maries County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24516_1_2.SVG',
        status: 1,
    },
    {
        id: 24524,
        name: 'Utah State University Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24524_1_1.PNG',
        status: 1,
    },
    {
        id: 24528,
        name: 'Clackamas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24534,
        name: 'Emery Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24534_1_1.PNG',
        status: 1,
    },
    {
        id: 24541,
        name: 'Cornerstone Bank KC',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24541_1_1.PNG',
        status: 1,
    },
    {
        id: 24542,
        name: 'Auto Owners Associates Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24542_1_1.PNG',
        status: 1,
    },
    {
        id: 24545,
        name: 'First American State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24545_1_1.PNG',
        status: 1,
    },
    {
        id: 24548,
        name: 'First Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24555,
        name: 'American Bank of Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24555_1_1.PNG',
        status: 1,
    },
    {
        id: 24556,
        name: 'First National Bank Louisburg',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24557,
        name: 'Frontier Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24557_1_1.PNG',
        status: 1,
    },
    {
        id: 24560,
        name: 'Bank Star',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24560_1_1.PNG',
        status: 1,
    },
    {
        id: 24563,
        name: 'Cove Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24563_1_1.PNG',
        status: 1,
    },
    {
        id: 24565,
        name: 'Arbor Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24569,
        name: 'Chippewa Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24569_1_1.PNG',
        status: 1,
    },
    {
        id: 24576,
        name: 'National Bank of Waupun',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24576_1_1.PNG',
        status: 1,
    },
    {
        id: 24586,
        name: 'Lakeview Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24586_1_1.PNG',
        status: 1,
    },
    {
        id: 24588,
        name: 'Fremont Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24588_1_1.PNG',
        status: 1,
    },
    {
        id: 24592,
        name: 'Palisades Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24592_1_1.PNG',
        status: 1,
    },
    {
        id: 24596,
        name: 'Mohave State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24598,
        name: 'Pearland State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24598_1_1.PNG',
        status: 1,
    },
    {
        id: 24606,
        name: 'Riverways Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24606_1_1.PNG',
        status: 1,
    },
    {
        id: 24608,
        name: 'Northwest',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24608_1_1.PNG',
        status: 1,
    },
    {
        id: 24615,
        name: 'Members Trust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24615_1_1.PNG',
        status: 1,
    },
    {
        id: 24618,
        name: 'Community Bank of Raymore',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24618_1_1.PNG',
        status: 1,
    },
    {
        id: 24623,
        name: 'Standard Bank PaSB',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24623_1_1.PNG',
        status: 1,
    },
    {
        id: 24640,
        name: 'River Valley Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24640_1_1.PNG',
        status: 1,
    },
    {
        id: 24644,
        name: 'Security State Bank of Aitkin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24644_1_1.PNG',
        status: 1,
    },
    {
        id: 24647,
        name: 'The Citizens State Bank of Marysville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24647_1_1.PNG',
        status: 1,
    },
    {
        id: 24652,
        name: 'Wauna Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24652_1_1.PNG',
        status: 1,
    },
    {
        id: 24654,
        name: 'Union Square Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24654_1_1.PNG',
        status: 1,
    },
    {
        id: 24657,
        name: 'Lake Area Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24657_1_1.PNG',
        status: 1,
    },
    {
        id: 24661,
        name: 'Winslow Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24661_1_1.PNG',
        status: 1,
    },
    {
        id: 24683,
        name: 'Bank3',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24683_1_1.PNG',
        status: 1,
    },
    {
        id: 24686,
        name: 'Gulf Coast Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24693,
        name: 'Poppy Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24693_1_2.SVG',
        status: 1,
    },
    {
        id: 24717,
        name: 'East River Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24724,
        name: 'BancCentral',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24724_1_1.PNG',
        status: 1,
    },
    {
        id: 24725,
        name: 'Bank of Wisconsin Dells',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24725_1_1.PNG',
        status: 1,
    },
    {
        id: 24727,
        name: 'Daniels-Sheridan Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24727_1_1.PNG',
        status: 1,
    },
    {
        id: 24730,
        name: 'Mid-Minnesota Federal CU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24730_1_2.SVG',
        status: 1,
    },
    {
        id: 24738,
        name: 'OBee Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24738_1_1.PNG',
        status: 1,
    },
    {
        id: 24740,
        name: 'Silvergate Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24740_1_1.PNG',
        status: 1,
    },
    {
        id: 24743,
        name: 'Minnesota Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24743_1_2.SVG',
        status: 1,
    },
    {
        id: 24745,
        name: 'Finger Lakes Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24745_1_2.SVG',
        status: 1,
    },
    {
        id: 24746,
        name: 'First Bank (Wichita Falls, TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24746_1_1.PNG',
        status: 1,
    },
    {
        id: 24752,
        name: 'Superior Choice Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24752_1_1.PNG',
        status: 1,
    },
    {
        id: 24766,
        name: 'SunMark Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24766_1_1.PNG',
        status: 1,
    },
    {
        id: 24771,
        name: 'Meadows Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24771_1_1.PNG',
        status: 1,
    },
    {
        id: 24773,
        name: 'MVB Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24773_1_1.PNG',
        status: 1,
    },
    {
        id: 24777,
        name: 'Department of Labor Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24777_1_1.PNG',
        status: 1,
    },
    {
        id: 24786,
        name: 'Energy Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24786_1_1.PNG',
        status: 1,
    },
    {
        id: 24792,
        name: 'Fortis Private Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24795,
        name: 'Vision Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24795_1_1.PNG',
        status: 1,
    },
    {
        id: 24797,
        name: 'United Community Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24797_1_1.PNG',
        status: 1,
    },
    {
        id: 24802,
        name: 'Commercial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24802_1_1.PNG',
        status: 1,
    },
    {
        id: 24809,
        name: 'Heritage Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24822,
        name: 'Seneca Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24822_1_1.PNG',
        status: 1,
    },
    {
        id: 24826,
        name: 'Security National Bank (NE) (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24826_1_1.PNG',
        status: 1,
    },
    {
        id: 24828,
        name: 'Azlo',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24828_1_2.SVG',
        status: 1,
    },
    {
        id: 24837,
        name: 'Financial One Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24837_1_1.PNG',
        status: 1,
    },
    {
        id: 24847,
        name: 'Open Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24860,
        name: 'Legends Bank-Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24861,
        name: 'Corner Stone Bank Virginia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24861_1_1.PNG',
        status: 1,
    },
    {
        id: 24866,
        name: 'MCNB Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24866_1_1.PNG',
        status: 1,
    },
    {
        id: 24879,
        name: 'CES Credit Union (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24879_1_1.PNG',
        status: 1,
    },
    {
        id: 24880,
        name: 'PriorityOne Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24880_1_1.PNG',
        status: 1,
    },
    {
        id: 24881,
        name: 'Commercial Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24881_1_1.PNG',
        status: 1,
    },
    {
        id: 24882,
        name: 'Pentucket Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24882_1_1.PNG',
        status: 1,
    },
    {
        id: 24884,
        name: 'Benchmark Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24884_1_1.PNG',
        status: 1,
    },
    {
        id: 24885,
        name: 'BlackRidge Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24885_1_1.PNG',
        status: 1,
    },
    {
        id: 24886,
        name: 'First Commercial Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24886_1_1.PNG',
        status: 1,
    },
    {
        id: 24887,
        name: 'Summit National Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24887_1_2.SVG',
        status: 1,
    },
    {
        id: 24890,
        name: 'Bangor Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24890_1_1.PNG',
        status: 1,
    },
    {
        id: 24892,
        name: 'First Security Bank - Sleepy Eye',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24892_1_1.PNG',
        status: 1,
    },
    {
        id: 24893,
        name: 'Santa Fe Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24895,
        name: 'Pacific Premier Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24895_1_1.PNG',
        status: 1,
    },
    {
        id: 24903,
        name: 'Lake Trust Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24914,
        name: 'Agfed Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24914_1_1.PNG',
        status: 1,
    },
    {
        id: 24916,
        name: 'Grandview Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24916_1_1.PNG',
        status: 1,
    },
    {
        id: 24917,
        name: 'Eagle Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24917_1_1.PNG',
        status: 1,
    },
    {
        id: 24920,
        name: 'Reliance State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24925,
        name: 'Pegasus Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24925_1_1.PNG',
        status: 1,
    },
    {
        id: 24932,
        name: 'Marshall Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24932_1_1.PNG',
        status: 1,
    },
    {
        id: 24933,
        name: 'Highlands Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24938,
        name: 'Banc of California (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24938_1_1.PNG',
        status: 1,
    },
    {
        id: 24944,
        name: 'Timberland Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24948,
        name: 'Partners Bank Wisconsin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24948_1_1.PNG',
        status: 1,
    },
    {
        id: 24958,
        name: 'First Alliance Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24958_1_1.PNG',
        status: 1,
    },
    {
        id: 24959,
        name: 'LA Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24960,
        name: 'Freedom Bank of Southern Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24961,
        name: 'Simply Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24961_1_1.PNG',
        status: 1,
    },
    {
        id: 24978,
        name: 'Astra Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24978_1_2.SVG',
        status: 1,
    },
    {
        id: 24981,
        name: 'Financial Trust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24981_1_1.PNG',
        status: 1,
    },
    {
        id: 24982,
        name: 'FNB Fontanelle',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24982_1_1.PNG',
        status: 1,
    },
    {
        id: 24986,
        name: 'B-MS Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 24988,
        name: 'Pinnacle Credit Unon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24988_1_1.PNG',
        status: 1,
    },
    {
        id: 24989,
        name: 'Androscoggin Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24989_1_1.PNG',
        status: 1,
    },
    {
        id: 24994,
        name: 'Guaranty Bank & Trust (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24994_1_1.PNG',
        status: 1,
    },
    {
        id: 24997,
        name: 'The First Citizens National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_24997_1_1.PNG',
        status: 1,
    },
    {
        id: 25001,
        name: 'Silicon Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25014,
        name: 'Calcom Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25014_1_1.PNG',
        status: 1,
    },
    {
        id: 25020,
        name: 'Valley Star Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25020_1_1.PNG',
        status: 1,
    },
    {
        id: 25021,
        name: 'F&M Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25021_1_1.PNG',
        status: 1,
    },
    {
        id: 25022,
        name: 'FAST Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25022_1_1.PNG',
        status: 1,
    },
    {
        id: 25034,
        name: 'Great Basin Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25034_1_1.PNG',
        status: 1,
    },
    {
        id: 25038,
        name: 'Touchstone Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25038_1_1.PNG',
        status: 1,
    },
    {
        id: 25041,
        name: 'McClain Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25041_1_1.PNG',
        status: 1,
    },
    {
        id: 25063,
        name: 'Bank Of Winnfield',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25063_1_1.PNG',
        status: 1,
    },
    {
        id: 25066,
        name: 'Centennial Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25068,
        name: 'Texas Republic Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25070,
        name: 'Park View Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25073,
        name: 'Pinnacle Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25076,
        name: 'First Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25085,
        name: 'Ameri State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25085_1_1.PNG',
        status: 1,
    },
    {
        id: 25090,
        name: 'Pittsburgh Firefighters Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25090_1_1.PNG',
        status: 1,
    },
    {
        id: 25096,
        name: 'New First National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25096_1_1.PNG',
        status: 1,
    },
    {
        id: 25100,
        name: 'Cornerstone Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25100_1_1.PNG',
        status: 1,
    },
    {
        id: 25114,
        name: 'First Bank of Manhattan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25117,
        name: 'Affiliated Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25117_1_1.PNG',
        status: 1,
    },
    {
        id: 25126,
        name: 'Hilltop National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25132,
        name: 'Idaho First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25132_1_2.SVG',
        status: 1,
    },
    {
        id: 25133,
        name: 'Ramsey National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25133_1_1.PNG',
        status: 1,
    },
    {
        id: 25158,
        name: 'Central Valley Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25158_1_1.PNG',
        status: 1,
    },
    {
        id: 25169,
        name: 'First Northeast Bank of Nebraska',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25171,
        name: 'Solera National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25171_1_2.SVG',
        status: 1,
    },
    {
        id: 25177,
        name: 'Kerr County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25178,
        name: 'Buckeye State Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25178_1_1.PNG',
        status: 1,
    },
    {
        id: 25183,
        name: 'Nizari Progressive Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25183_1_1.PNG',
        status: 1,
    },
    {
        id: 25193,
        name: 'Commerce Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25193_1_1.PNG',
        status: 1,
    },
    {
        id: 25196,
        name: 'CF Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25196_1_1.PNG',
        status: 1,
    },
    {
        id: 25204,
        name: 'West Town Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25204_1_1.PNG',
        status: 1,
    },
    {
        id: 25212,
        name: 'Glasford Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25212_1_1.PNG',
        status: 1,
    },
    {
        id: 25217,
        name: 'Sabine Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25217_1_1.PNG',
        status: 1,
    },
    {
        id: 25227,
        name: 'Huron National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25227_1_1.PNG',
        status: 1,
    },
    {
        id: 25244,
        name: 'Great Erie Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25244_1_1.PNG',
        status: 1,
    },
    {
        id: 25248,
        name: 'Community West Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25248_1_1.PNG',
        status: 1,
    },
    {
        id: 25256,
        name: 'Dundee Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25256_1_1.PNG',
        status: 1,
    },
    {
        id: 25258,
        name: 'Bank of Desoto',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25258_1_1.PNG',
        status: 1,
    },
    {
        id: 25259,
        name: 'Sanibel Captiva Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25271,
        name: 'Laredo Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25272,
        name: 'Glendale Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25272_1_1.PNG',
        status: 1,
    },
    {
        id: 25274,
        name: 'North East Missouri State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25277,
        name: 'Heart of Louisiana Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25279,
        name: 'Huron Valley State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25279_1_2.SVG',
        status: 1,
    },
    {
        id: 25283,
        name: 'Labette Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25296,
        name: 'Tucson Old Pueblo Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25296_1_1.PNG',
        status: 1,
    },
    {
        id: 25300,
        name: 'Louisiana Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25300_1_1.PNG',
        status: 1,
    },
    {
        id: 25318,
        name: 'North Valley Bank Thornton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25318_1_2.SVG',
        status: 1,
    },
    {
        id: 25319,
        name: 'Star Choice Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25319_1_1.PNG',
        status: 1,
    },
    {
        id: 25321,
        name: 'First Capital Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25321_1_1.PNG',
        status: 1,
    },
    {
        id: 25322,
        name: 'Carolina Foothills FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25322_1_1.PNG',
        status: 1,
    },
    {
        id: 25325,
        name: 'The Pataskala Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25325_1_1.PNG',
        status: 1,
    },
    {
        id: 25327,
        name: 'Tucoemas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25327_1_1.PNG',
        status: 1,
    },
    {
        id: 25328,
        name: 'State Bank of Toulon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25328_1_1.PNG',
        status: 1,
    },
    {
        id: 25329,
        name: 'Powernet Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25329_1_1.PNG',
        status: 1,
    },
    {
        id: 25331,
        name: 'National United',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25333,
        name: 'Inland Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25333_1_2.SVG',
        status: 1,
    },
    {
        id: 25336,
        name: 'Putnam County Bank (Hurricane)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25338,
        name: 'Tri-County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25342,
        name: 'NC Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25347,
        name: 'Lee Bank (MA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25352,
        name: 'Ontario-Montclair School Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25355,
        name: 'First National Bank of Trenton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25358,
        name: 'Portage County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25361,
        name: 'The Missouri Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25362,
        name: 'Burling Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25363,
        name: 'Community Bank Lexington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25364,
        name: 'Andover Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25366,
        name: 'Community Bank & Trust (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25367,
        name: 'Texas National Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25373,
        name: 'Red Wing Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25376,
        name: 'Encompass Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25377,
        name: 'Northeast Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25378,
        name: 'The Neffs National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25380,
        name: 'American National Bank of Mt. Pleasant',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25381,
        name: 'Lisbon Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25388,
        name: 'First Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25389,
        name: 'Everence Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25391,
        name: 'International Bank of Chicago',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25392,
        name: 'Round Top State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25395,
        name: 'Tongass Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25395_1_1.PNG',
        status: 1,
    },
    {
        id: 25396,
        name: 'Kent County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25397,
        name: 'Northern Hills Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25398,
        name: 'Serve Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25399,
        name: 'Service 1 Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25400,
        name: 'Allegius Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25404,
        name: 'Franklin Savings Bank (NH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25405,
        name: 'Mesquite Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25425,
        name: 'Desert Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25427,
        name: 'First National Bank of Gilmer',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25437,
        name: 'Advantage Bank (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25440,
        name: 'Connection Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25451,
        name: 'North Brookfield Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25451_1_1.PNG',
        status: 1,
    },
    {
        id: 25456,
        name: 'Circle Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25467,
        name: 'Wilmington Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25479,
        name: 'First Communtiy Bank Montana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25483,
        name: 'F&M Bank (Manchester, IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25497,
        name: 'One Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25503,
        name: 'First National Bank of Decatur County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25506,
        name: 'Noble Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25508,
        name: 'Texoma Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25509,
        name: 'White Eagle Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25510,
        name: 'United Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25520,
        name: 'Citizens State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25537,
        name: 'Pennian Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25539,
        name: 'Commercial Bank Michigan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25576,
        name: 'Coastal Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25577,
        name: 'Century National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25582,
        name: 'First National Bank (Marlow - OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25586,
        name: 'First Bank MS',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25588,
        name: 'First Liberty National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25589,
        name: 'Starion Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25591,
        name: 'Midstates Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25597,
        name: 'Metrum Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25600,
        name: 'Bank of luxemburg',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25603,
        name: 'Members Advantage Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25605,
        name: 'Bruning State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25616,
        name: 'Exchange State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25617,
        name: 'Harvester Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25631,
        name: 'Park City Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25634,
        name: 'First National Bank (Personal - CO, SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_25634_1_1.PNG',
        status: 1,
    },
    {
        id: 25637,
        name: 'North Valley Bank Ohio',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25643,
        name: 'Level One Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25649,
        name: 'Baylor Health Care System Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25660,
        name: 'Allegan Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25661,
        name: 'Biddeford Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25669,
        name: 'Insouth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25670,
        name: 'Northern State Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25671,
        name: 'Penobscot County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25685,
        name: 'Villa Grove State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25686,
        name: 'Life Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25700,
        name: 'The Bennington State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25728,
        name: 'Notre Dame Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25733,
        name: 'Concho Educators Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25737,
        name: 'Transwest Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25740,
        name: 'SUN Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25747,
        name: 'Patriots Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25751,
        name: 'ANECA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25752,
        name: 'Needham Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25765,
        name: 'URW Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25772,
        name: 'PAHO/WHO Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25793,
        name: 'First Bank of Highland Park',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25799,
        name: 'Thrive Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25801,
        name: 'Texas Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25807,
        name: 'Latitude 32 credit union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25808,
        name: 'CIBM Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25809,
        name: 'Merchants Commercial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25834,
        name: 'Citizens State Bank - Corrigan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25835,
        name: 'Santa Ana Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25840,
        name: 'Police Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25841,
        name: 'CNB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25844,
        name: 'Western Alliance Bancorporation',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25845,
        name: 'Citizens State Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25848,
        name: 'Central Missouri Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25851,
        name: 'GEMC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25855,
        name: 'Western Sun Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25879,
        name: 'CAMC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25881,
        name: 'Firstar Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25882,
        name: 'Greenwood Municipal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25888,
        name: 'First National Bank of Russel Springs',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25889,
        name: 'Bank of York',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25890,
        name: 'The First Bank of Greenwich',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25897,
        name: 'Auburn University Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25903,
        name: 'Cross River Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25917,
        name: 'Monroe Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25924,
        name: 'Prospect Ban (Business - BankWise)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25946,
        name: 'Toyota Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25994,
        name: 'Members Exchange Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25995,
        name: 'Malaga Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 25999,
        name: 'Ponce de Leon Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26000,
        name: 'First Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26014,
        name: 'Mt. McKinley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26023,
        name: 'Silverado Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26025,
        name: 'Canvas Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26033,
        name: 'First Piedmont',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26034,
        name: 'Valley Communities Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26036,
        name: 'Four Corners Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26040,
        name: 'Enterprise Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26045,
        name: 'Arlington Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26050,
        name: 'Quantum Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26053,
        name: 'Columbus Metro Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26054,
        name: 'Vio Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26056,
        name: 'First National Bank of Anson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26068,
        name: 'Coastal Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26072,
        name: 'Merchants National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26089,
        name: 'Table Rock Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26095,
        name: 'Lifetime Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26099,
        name: 'Buffalo Service Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26178,
        name: 'First National Bank of River Falls',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26184,
        name: 'Bank of Austin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26187,
        name: '5 Point Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26188,
        name: 'Northwoods Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26190,
        name: 'Utica District Telephone Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26210,
        name: 'Covington CountyBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26250,
        name: 'Postal Family Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26268,
        name: 'Citizens 1st Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26272,
        name: 'ARC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26279,
        name: 'WIT FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26288,
        name: 'River City Bank Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26292,
        name: 'Iowa State Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_26292_1_1.PNG',
        status: 1,
    },
    {
        id: 26298,
        name: 'Affinity C U',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26308,
        name: 'Citizens Bank MO',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26309,
        name: 'NBC Oklahoma',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26319,
        name: 'Members choice credit union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26324,
        name: 'American State bank & trust company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26333,
        name: 'TruStar Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26338,
        name: 'Andrew Johnson Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26353,
        name: 'Frontier Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26358,
        name: 'NBCoxsackie',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26361,
        name: 'First National Bank of Burleson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26364,
        name: 'First Bank of Owasso',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26374,
        name: 'Complex Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26375,
        name: 'Minden Exchange Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26392,
        name: 'Gold Coast Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26395,
        name: 'Northrim Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26415,
        name: 'Community Bank of Cameron',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26416,
        name: 'North County Credit Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26418,
        name: 'Security State bank (SSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26436,
        name: 'West Central Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26447,
        name: 'Self Reliance New York Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26448,
        name: 'AllCom Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26450,
        name: 'Pony Express Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26462,
        name: 'Covenant Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26466,
        name: 'PB&T Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26468,
        name: 'Bank of Western Oklahoma',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_26468_1_1.PNG',
        status: 1,
    },
    {
        id: 26472,
        name: 'Cypress Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26473,
        name: 'Central State Bank Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26476,
        name: "New Horizon's Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26492,
        name: 'North Jersey Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26496,
        name: 'Peoples Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26504,
        name: 'IBEW and United Workers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26505,
        name: 'Century Heritage Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26506,
        name: 'The First State Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26507,
        name: 'San Antonio Citizen Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26510,
        name: 'Family Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26512,
        name: 'Media City Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26513,
        name: 'Las Colinas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26516,
        name: 'First National Bank of Tennessee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26524,
        name: 'Oklahoma Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26531,
        name: 'Bank5 Connect',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26534,
        name: 'Greensboro Municipal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26535,
        name: 'Ocala Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26539,
        name: 'Mohave Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26552,
        name: 'Financial Plus Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26553,
        name: 'Heritage Bank (Business - KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26556,
        name: 'Gardiner Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26558,
        name: 'Farmers State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26560,
        name: 'Durden Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26566,
        name: 'Cheney Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26569,
        name: 'Sugar River Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26586,
        name: 'Noah Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26593,
        name: 'First National Bank of Bellville (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26594,
        name: 'First National Bank of Eastern Arkansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26597,
        name: 'Sunrise Family Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26599,
        name: 'Endeavor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26601,
        name: 'Security Bank USA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26615,
        name: 'Diversified Members Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26616,
        name: 'Bank of Clarendon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26622,
        name: 'First National Bank of Michigan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26624,
        name: 'First National Bank of Sycamore',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26626,
        name: 'Poca Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26629,
        name: 'Frontier Bank of Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26632,
        name: 'Axos Bank - Business',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_26632_1_1.PNG',
        status: 1,
    },
    {
        id: 26644,
        name: 'West Union Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26645,
        name: 'First National Bank of Lindsay',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26647,
        name: 'County National Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26653,
        name: 'Bellco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26668,
        name: 'Radius Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_26668_1_1.PNG',
        status: 1,
    },
    {
        id: 26669,
        name: 'Commerce State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26673,
        name: 'The Southern Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26680,
        name: 'Bank of Erath',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26696,
        name: 'NXT Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26703,
        name: 'First Family Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26713,
        name: 'Ouachita Valley Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26715,
        name: 'State Bank of Bement',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26717,
        name: 'Republic Bank (Small Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26735,
        name: 'Popular Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26737,
        name: 'First Liberty National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26738,
        name: 'Iron Workers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26741,
        name: 'F&M Bank (Oklahoma)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26743,
        name: 'Destinations Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26749,
        name: 'Carolina Alliance Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26751,
        name: 'Home Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26753,
        name: 'Washington State Bank (WSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26754,
        name: 'InRoad Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26758,
        name: 'First State Bank of Malta',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26761,
        name: 'Malvern National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26762,
        name: 'Municipal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26763,
        name: 'Shamrock Foods Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26767,
        name: 'Key Bank Business Institutions',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26777,
        name: 'New Peoples Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26788,
        name: 'State National Bank in West',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26790,
        name: 'Valley National Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26795,
        name: 'GN Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26804,
        name: 'Security First National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26805,
        name: 'Garrett State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26815,
        name: 'Northwest Municipal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26816,
        name: 'Beverly Hills City Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26819,
        name: 'Torrington Municipal & Teachers Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26820,
        name: 'Federal Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26841,
        name: 'Olympia Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26844,
        name: 'Security First Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26855,
        name: 'First Carolina Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26866,
        name: 'Farmers and Merchants Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26890,
        name: 'Alliant Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26895,
        name: 'Naveo Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26902,
        name: 'Farmers & Merchants National Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26908,
        name: 'Freedom Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26909,
        name: 'Riverland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26932,
        name: 'Level One Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26948,
        name: 'Casey County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26952,
        name: 'Monona State Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 26991,
        name: 'FreedomBank Montana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27004,
        name: 'Columbus Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27005,
        name: 'Prime Meridian Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27006,
        name: 'Electro Savings Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27007,
        name: 'Pleasants County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27010,
        name: 'Citizens Bank of Morgantown',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27018,
        name: 'First National Bank of Waterloo',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27081,
        name: 'Community Credit Union of Lynn',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27103,
        name: 'Element Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27113,
        name: 'GrandSouth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27140,
        name: 'Pennian Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_27140_1_1.PNG',
        status: 1,
    },
    {
        id: 27266,
        name: 'First & Peoples Bank and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27300,
        name: 'First Home Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27316,
        name: 'Sandhills Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27318,
        name: 'Telco Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27336,
        name: 'American Heritage National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27338,
        name: 'River City Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27349,
        name: 'Community Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27356,
        name: 'Dairy State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27357,
        name: 'Alerus Financial (Online Business Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27369,
        name: 'Peoples Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27376,
        name: 'Select Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27389,
        name: 'Oregon State Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_27389_1_1.PNG',
        status: 1,
    },
    {
        id: 27394,
        name: 'Mid Oregon Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27395,
        name: 'Savoy Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27396,
        name: 'Walters Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27397,
        name: 'New Sunwest Corporate',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27398,
        name: 'Citizens State Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27419,
        name: 'DeWitt Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27423,
        name: 'Spokane Media Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27425,
        name: 'Jim Thorpe Neighborhood Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27428,
        name: 'The Bank of Hemet',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27431,
        name: 'TBK Buisness',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27433,
        name: 'US Postal Service Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27434,
        name: 'School Employees Lorain County Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27456,
        name: 'Riverside Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27475,
        name: 'Tahquamenon Are Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27476,
        name: 'UBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27478,
        name: 'ProBenefits (new portal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27480,
        name: 'Auburn Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27487,
        name: 'Truecore Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27541,
        name: 'Oklahoma State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27564,
        name: 'Unity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27588,
        name: 'Queenstown Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27597,
        name: 'Merchant and Planters Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27611,
        name: 'Manasquan Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27613,
        name: 'The First National Bank of Jeanerette',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27615,
        name: 'BankNorth',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27629,
        name: 'Peoples Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27635,
        name: 'E-Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27638,
        name: 'Central Bank of Midwest (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27647,
        name: 'Leader Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27649,
        name: 'Paradise Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27650,
        name: 'Jeff Davis Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27657,
        name: 'Bank Michigan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27664,
        name: 'Meadowland Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27665,
        name: 'Opus Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27667,
        name: 'United Cumberland Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27668,
        name: 'McFarland State Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27688,
        name: 'Heartland Credit Union (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27690,
        name: 'Collegedale Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27711,
        name: 'Atlantic Union-Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_27711_1_1.PNG',
        status: 1,
    },
    {
        id: 27720,
        name: 'Pioneer Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27722,
        name: 'Lake Michigan Credit Union (Business - FL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27728,
        name: 'Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27744,
        name: 'Ever & Green Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27749,
        name: 'Washtenaw Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27754,
        name: 'The University National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27757,
        name: 'The Fidelity Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27758,
        name: 'Coast line Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27761,
        name: 'Riverview Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27767,
        name: 'Flagler Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27771,
        name: 'TexHillCo School Employees Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27781,
        name: "People's Bank Of Seneca",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27791,
        name: 'Red River Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27795,
        name: 'WSSC Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27811,
        name: 'Farmers & Merchants Bank CA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27815,
        name: 'CommStar Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27833,
        name: 'ABD. Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27841,
        name: 'First State Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27863,
        name: 'PrimeSouth Bank (P)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_27863_1_1.PNG',
        status: 1,
    },
    {
        id: 27874,
        name: 'Foxboro Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27875,
        name: 'Madison Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27878,
        name: 'Meridian Mutual Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27879,
        name: 'Center National Bank (Hometown Banking)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27904,
        name: 'Dieterich Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27905,
        name: 'Guadalupe Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27915,
        name: 'Citizens Community Bank (Personal)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27917,
        name: 'Commercial Bank & Trust of PA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27926,
        name: 'CornerStone State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27957,
        name: 'Ufirst Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27961,
        name: 'Valley Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27965,
        name: 'Terrabank N.A.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27966,
        name: 'BBVA (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 27986,
        name: 'U Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28003,
        name: 'City National Bank (ePartner)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28011,
        name: 'Seacoast Commerce Bank (Treasury Management)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28016,
        name: 'First State Bank St. Charles (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28018,
        name: 'Crosspoint Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28023,
        name: 'Denison State Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28027,
        name: 'Harbor Pointe Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28035,
        name: 'MidCoast Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28045,
        name: 'First Farmers',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28046,
        name: 'Citizens Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28058,
        name: 'Luso Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28060,
        name: 'Warren Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28061,
        name: 'Mission Investment Fund',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28067,
        name: 'MidCountry Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28071,
        name: 'Premier Community Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28087,
        name: 'TrailWest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28110,
        name: 'Cross First Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28118,
        name: 'San Luis Valley Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28119,
        name: 'The Bank of San Jacinto County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28121,
        name: 'Zia Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28123,
        name: 'Capital Bank Maryland (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28128,
        name: 'Florida Business Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28134,
        name: 'Springs Valley Bank & Trust Company (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28135,
        name: 'American State Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28136,
        name: 'Community State Bank -IA',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28141,
        name: 'Industry State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28144,
        name: 'Bank of Houston (N.A)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28147,
        name: 'First Southern Bank (FSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28152,
        name: 'The Local Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28172,
        name: 'Bank Of Grand Lake',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28189,
        name: 'Industrial Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28191,
        name: 'Shoreline Hometown Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28193,
        name: 'FMBank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28205,
        name: 'Severn Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28220,
        name: 'First National Bank of Clarksdale',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28223,
        name: 'Maple Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28225,
        name: 'Common Wealth Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28229,
        name: 'First Western',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28230,
        name: 'Princeville State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28238,
        name: 'Pilot bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28258,
        name: 'Heritage Bank of Commerce (Small Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28310,
        name: 'KS Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28334,
        name: 'Provident State Bank (PSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28344,
        name: 'Bank of New York (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28351,
        name: 'Pine Island Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28353,
        name: 'Spring Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28354,
        name: 'The Chasewood Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28357,
        name: 'Capital Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28358,
        name: 'Valley Bank of Kalispell',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28360,
        name: 'Citizens Savings Bank (CSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28361,
        name: 'Western Bank of Artesia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28362,
        name: 'Maine Solutions Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28363,
        name: 'First Citizens National Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28364,
        name: 'Federal Employees West Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28365,
        name: 'Country Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28366,
        name: 'Citizens Bank of Newburg',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28367,
        name: 'Citizens Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28368,
        name: 'Southern Bank of Tennessee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28369,
        name: 'WNB Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28370,
        name: 'First Bank of Alabama',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28371,
        name: 'The First Bank of Okarche',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28372,
        name: 'Farmers State Bank & Trust Co. (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28373,
        name: 'Kendall State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28374,
        name: 'The Citizens Bank of Winfield',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28375,
        name: 'Western Bank of Clovis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28376,
        name: 'First Bank of Muleshoe',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28384,
        name: 'Fairfield National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28385,
        name: 'First Tri-County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28387,
        name: 'Bank of New England',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28388,
        name: 'Bank of South Texas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28389,
        name: 'Commerce Bank (IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28390,
        name: 'Leaders Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28391,
        name: 'Armor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28392,
        name: 'United Bank of Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28393,
        name: 'University Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28395,
        name: 'Farmers & Merchants Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28396,
        name: 'Farmers & Merchants Bank of Colby',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28397,
        name: 'Winter Park National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28398,
        name: 'The Citizens National Bank of Quitman',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28399,
        name: 'First Bank of Ohio',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28400,
        name: 'Citizens National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28407,
        name: 'Carter Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28414,
        name: 'Community Bank of Snyder',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28415,
        name: 'First Central State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28419,
        name: 'Merit Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28430,
        name: 'First Federal Savings of Middletown',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28431,
        name: 'Farmers Trust & Savings Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28432,
        name: 'Washington Savings Bank (PA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28433,
        name: 'Verus Bank of Commerce',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28434,
        name: 'edU Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28435,
        name: 'First Federal Savings Bank of Mascoutah',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28436,
        name: 'Heritage State Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28437,
        name: 'First Federal Savings Bank of Angola',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28438,
        name: 'Mainstreet Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28438_1_1.PNG',
        status: 1,
    },
    {
        id: 28439,
        name: 'Horizon Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28440,
        name: 'First National Bank and Trust (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28441,
        name: 'First National Bank of Bottineau',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28442,
        name: 'Citizens National Bank of Albion',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28444,
        name: 'Citizens National Bank Crosbyton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28445,
        name: 'Bank of Mount Hope',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28447,
        name: 'Access Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28448,
        name: 'TPNB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28449,
        name: 'First State Bank and Trust Company, Inc.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28450,
        name: 'Members Choice Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28451,
        name: 'Independence State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28453,
        name: 'Peoples Exchange Bank of Monroe County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28454,
        name: 'Utah Independent Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28456,
        name: 'First National Bank in Ord',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28457,
        name: 'First National Bank in Tigerton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28458,
        name: 'First National Bank of Alvin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28459,
        name: 'First National Bank Cortez',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28460,
        name: 'First National Bank (WV)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28461,
        name: 'Valley Credit Union (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28462,
        name: 'Jonesboro State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28464,
        name: 'First National Bank of Brookfield',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28465,
        name: 'First National Bank of Aspermont',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28467,
        name: 'Heartland Tri-State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28468,
        name: 'First National Bank of Dublin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28470,
        name: 'First Bank of Hampton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28471,
        name: 'First National Bank of Frederick',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28473,
        name: 'First National Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28474,
        name: 'The First National Bank of Hebbronville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28475,
        name: 'First National Bank of McIntosh',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28476,
        name: 'First National Bank of Trinity',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28477,
        name: 'The First National Bank of Dozier',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28478,
        name: 'First National Bank of Primghar',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28479,
        name: 'The First National Bank of Eldorado',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28480,
        name: 'The First National Bank in Cimarron',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28481,
        name: 'The First National Bank of Fairfax',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28482,
        name: 'The First National Bank of Peterstown',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28483,
        name: 'Cary Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28484,
        name: 'The Missouri Bank II',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28485,
        name: 'Security Savings Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28486,
        name: 'AVB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28487,
        name: 'Security Home Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28488,
        name: 'Spur Security Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28489,
        name: 'Alton Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28490,
        name: 'Bank of Bourbonnais',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28491,
        name: 'Bank of Chestnut',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28492,
        name: 'The Hardin County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28498,
        name: 'Campbell County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28499,
        name: 'Central Bancorp',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28500,
        name: 'Chester National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28501,
        name: 'Clare Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28502,
        name: 'First Kansas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28503,
        name: 'Golden Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28504,
        name: 'Hillsboro Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28505,
        name: 'First State Bank (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28506,
        name: 'KeySavings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28507,
        name: 'Idaho Trust Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28508,
        name: 'Lincoln 1st Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28509,
        name: 'Infinity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28510,
        name: 'Impact Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28511,
        name: 'Mason Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28512,
        name: 'Ozark Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28513,
        name: 'One South Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28514,
        name: 'The Bank of Protection',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28515,
        name: 'The Tilden Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28516,
        name: 'American Commerce Bank N.A.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28517,
        name: 'Wenona State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28518,
        name: 'Spring Bank (NY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28528,
        name: 'Western Dakota Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28534,
        name: 'State Bank of Wapello',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28535,
        name: 'State Bank of Canton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28536,
        name: 'State Bank of Burrton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28538,
        name: 'State Bank of Cherry',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28539,
        name: '1st Bank in Hominy',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28540,
        name: 'The First & Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28541,
        name: 'State Bank of Wheaton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28542,
        name: 'State Bank of Bern',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28543,
        name: 'Gogebic Range Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28544,
        name: '1st Security Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28545,
        name: '1st Community Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28545_1_1.PNG',
        status: 1,
    },
    {
        id: 28546,
        name: 'Central Bank & Trust Co. (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28547,
        name: '1st State Bank of Mason City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28548,
        name: '1st State Bank of Purdy',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28550,
        name: '21st Century Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28551,
        name: 'Advance Financial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28552,
        name: '1st State Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28554,
        name: 'AdvantagePlus of Indiana Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28555,
        name: 'Alden State Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28556,
        name: 'Abington Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28558,
        name: 'Alliance Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28559,
        name: 'AllNations Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28560,
        name: 'Amerasia Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28561,
        name: 'American Bank and Trust Company (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28562,
        name: 'American Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28563,
        name: 'American Exchange Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28565,
        name: 'American Equity Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28567,
        name: 'American Plus Bank, N.A.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28568,
        name: 'Anahuac National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28569,
        name: 'American Trust and Savings Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28570,
        name: 'Amistad Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28571,
        name: 'Amerifirst Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28573,
        name: 'Angelina Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28574,
        name: 'The Apple River State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28575,
        name: 'Andes State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28578,
        name: 'Aroostook Savings & Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28579,
        name: 'Asian Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28580,
        name: 'Ashton State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28581,
        name: 'Athol Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28582,
        name: 'Bank of Baker',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28583,
        name: 'Bank of Anguilla',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28585,
        name: 'Prairie Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28586,
        name: 'Bank of Billings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28587,
        name: 'Bank of Brookhaven',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28588,
        name: 'Bank of Clarkson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28589,
        name: 'Bank of Cave City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28590,
        name: 'Bank of Columbia',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28591,
        name: 'Bank of Commerce (BC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28592,
        name: 'Bank of Commerce and Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28593,
        name: 'Bank of Crockett',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28594,
        name: 'Bank of Dade',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28595,
        name: 'Bank of Cushing',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28596,
        name: 'Bank of Denton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28599,
        name: 'Bank of Edison',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28600,
        name: 'Bank of Eufaula',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28602,
        name: 'Bank of Evergreen',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28603,
        name: 'Bank of Greeleyville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28604,
        name: 'Bank of Gravette',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28604_1_1.PNG',
        status: 1,
    },
    {
        id: 28605,
        name: 'Bank of Gleason',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28606,
        name: 'Bank of Holland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28608,
        name: 'Bank of Hazelton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28609,
        name: 'Bank of Hamilton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28610,
        name: 'Citizens Bank (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28613,
        name: 'CITIZENS State Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28614,
        name: 'Citizens State Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28615,
        name: 'Citizens State Bank of Roseau',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28616,
        name: 'The Home Folks City National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28617,
        name: 'City & Police Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28618,
        name: 'Classic Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28619,
        name: 'Clay County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28620,
        name: 'Clean Energy Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28621,
        name: 'Coast 2 Coast Financial Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28622,
        name: 'Clatsop Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28623,
        name: 'Colfax Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28624,
        name: 'Colonial Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28624_1_1.PNG',
        status: 1,
    },
    {
        id: 28626,
        name: 'Commercial Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28627,
        name: 'Citizens State Bank (Anton,TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28628,
        name: 'City National Bank San Saba, TX',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28629,
        name: 'Commercial Bank of Mott',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28630,
        name: 'Commercial State Bank (SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28631,
        name: 'Commercial Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28632,
        name: 'Commonwealth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28633,
        name: 'Commercial Bank Nuckolls County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28634,
        name: 'Commodore Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28635,
        name: 'Community Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28636,
        name: 'Community Bank Delaware',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28637,
        name: 'Community Bank of Louisiana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28638,
        name: 'Community Banks of Shelby County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28639,
        name: 'Community Bank of Fitzgerald',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28640,
        name: 'Community Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28641,
        name: 'Community Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28642,
        name: 'Community First Bank, N.A',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28643,
        name: 'Community Savings (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28643_1_1.PNG',
        status: 1,
    },
    {
        id: 28644,
        name: 'Community National Bank in Monmouth',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28645,
        name: 'Community Neighbor Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28646,
        name: 'Community Savings Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28647,
        name: 'Community Spirit Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28651,
        name: 'Community State Bank of Canton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28652,
        name: 'Conneaut Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28653,
        name: 'Compass Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28654,
        name: 'Community State Bank (CSB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28655,
        name: 'Community State Bank of Rock Falls',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28656,
        name: 'Coosa Pines Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28657,
        name: 'Connections Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28658,
        name: 'Corn Growers State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28659,
        name: 'County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28660,
        name: 'County Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28662,
        name: 'Cross County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28666,
        name: 'Cullman Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28667,
        name: 'Crowell State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28669,
        name: 'Custer Federal State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28670,
        name: 'Denver Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28671,
        name: 'Dearborn Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28672,
        name: 'Desjardins Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28673,
        name: 'Desco Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28674,
        name: 'Eagle Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28675,
        name: 'Donley County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28676,
        name: 'Eagle One Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28677,
        name: 'Elkton Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28678,
        name: 'Eastern CT Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28678_1_1.PNG',
        status: 1,
    },
    {
        id: 28679,
        name: 'Elk State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28680,
        name: 'Embassy National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28681,
        name: 'Everett Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28681_1_1.PNG',
        status: 1,
    },
    {
        id: 28682,
        name: 'Escambia County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28684,
        name: 'Espeeco Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28685,
        name: 'F&M Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28686,
        name: 'Exchange Bank of Northeast Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28687,
        name: 'F&M Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28688,
        name: 'Fairview Savings and Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28689,
        name: 'F&M Bank and Trust Company (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28690,
        name: 'Family Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28691,
        name: 'Farmers & Merchants Bank and Trust (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28692,
        name: 'Farmers & Merchants Bank - Berlin (WI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28693,
        name: 'Farmers & Merchants Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28694,
        name: 'Van Wert Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28696,
        name: 'Veritas Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28697,
        name: 'Walker State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28698,
        name: 'Wahoo State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28699,
        name: 'Wake Forest Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28700,
        name: 'Washita State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28703,
        name: 'Washington State Bank (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28705,
        name: 'Washita Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28706,
        name: 'Waycross Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28709,
        name: 'Wayne Westland Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28710,
        name: 'West Plains Savings and Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28711,
        name: 'Westport Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28712,
        name: 'Western Bank of Wolf Point',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28713,
        name: 'West Michigan Community Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28714,
        name: 'Whiting Refinery Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28715,
        name: 'Wichita Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28716,
        name: 'Wilcox County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28720,
        name: 'WCF Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28721,
        name: 'Welch State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28723,
        name: 'Kirkwood Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28724,
        name: 'Worthington Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28725,
        name: 'Yorktown Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28726,
        name: 'Common Trust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28727,
        name: 'Zapata National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28728,
        name: 'Farmers & Merchants State Bank of New York Mills',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28729,
        name: 'Farmers & Stockmens Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28730,
        name: 'Farmers and Mechanics Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28731,
        name: 'Farmers and Miners Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28732,
        name: 'Farmers Bank & Trust (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28733,
        name: 'Farmers Bank (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28734,
        name: 'Farmers Bank of Northern Missouri',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28736,
        name: 'Farmers and Merchants Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28737,
        name: 'Farmers and Merchants Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28738,
        name: 'Farmers Savings Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28740,
        name: 'Farmers National Bank (FNB)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28741,
        name: 'Gateway First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28742,
        name: 'Farmers Savings Bank & Trust (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28743,
        name: 'Farmers Security Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28744,
        name: 'Farmers State Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28745,
        name: 'Farmers State Bank & Trust Co. (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28746,
        name: 'Farmers State Bank (WA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28747,
        name: 'Farmers State Bank (Iowa)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28748,
        name: 'Farmers State Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28749,
        name: 'Farmers State Bank of Blue Mound',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28750,
        name: 'The Farmers State Bank of Aliceville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28751,
        name: 'Farmers State Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28752,
        name: 'Farmers State Bank (MI)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28753,
        name: 'Farmers State Bank - FSB (SD)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28754,
        name: 'Heritage Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28755,
        name: 'Farmers State Bank of Western Illinois',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28756,
        name: 'Fayette County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28757,
        name: 'FedFinancial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28758,
        name: 'Fidelity Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28759,
        name: 'Fidelity Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28762,
        name: 'Fidelity Federal Savings & Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28763,
        name: 'Fidelity State Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28766,
        name: 'FNB Farmers National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28767,
        name: 'First American International Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28768,
        name: 'First Bank and Trust Company (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28769,
        name: 'First Bank Blue Earth',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28770,
        name: 'First Capital Bank (SC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28771,
        name: 'First Bank of Linden',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28772,
        name: 'First Citizens Bank of Butte',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28773,
        name: 'First Community Bank and Trust (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28774,
        name: 'First Federal Savings & Loan of San Rafael',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28775,
        name: 'First Community Bank (VA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28776,
        name: 'First Farmers and Commercial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28777,
        name: 'First Federal of Van Wert',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28788,
        name: 'First General Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28789,
        name: 'First Federal Savings Bank of Washington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28790,
        name: 'First Independence Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28791,
        name: 'First Federal Savings of Valdosta',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28792,
        name: 'First Financial Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28794,
        name: 'First Federal of Greene County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28795,
        name: 'First Mutual Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28796,
        name: 'First Jackson Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28797,
        name: 'First Independent Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28798,
        name: 'First Missouri Bank of SEMO',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28799,
        name: 'First National Bank & Trust (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28800,
        name: 'First National Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28801,
        name: 'FNB First National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28804,
        name: 'First National Bank (KY,KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28806,
        name: 'First National Bank at Darlington',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28811,
        name: 'First National Bank New Bremen',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28812,
        name: 'First National Bank at Paris (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28813,
        name: '1st National Bank and Trust (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28814,
        name: 'First National Bank of Giddings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28816,
        name: 'First Northern Bank of Wyoming',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28817,
        name: 'First National Bank of Kansas',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28819,
        name: 'First National Bank of Kemp',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28820,
        name: 'The First National Bank of Lacon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28821,
        name: 'First National Bank of Manchester (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28823,
        name: 'First National Bank of Raymond (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28824,
        name: 'First National Bank of South Padre Island',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28825,
        name: 'First National Bank of St. Ignace',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28826,
        name: 'First National Bank of Stigler',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28827,
        name: 'First National Community Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28828,
        name: 'First Neodesha Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28829,
        name: 'First Nebraska Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28830,
        name: 'First Nations Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28831,
        name: 'First Palmetto Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28832,
        name: 'First Secure Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28833,
        name: 'First Pioneer National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28834,
        name: 'First Sentinel Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28835,
        name: 'First Southern Bank (MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28836,
        name: 'First State Bank Plus',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28837,
        name: 'First State Bank Belmond',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28838,
        name: 'FSB First State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28839,
        name: 'First State Bank Ness City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28840,
        name: 'First State Bank of Ashby',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28841,
        name: 'First State Bank - FSB (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28845,
        name: 'First State Bank of Burnet',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28848,
        name: 'KS State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28849,
        name: 'Kress National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28851,
        name: 'KV Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28853,
        name: 'Lake City Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28854,
        name: 'Ladysmith Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28859,
        name: 'Freedom Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28860,
        name: 'Legacy Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28862,
        name: 'Lee Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28863,
        name: 'Legacy Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28864,
        name: 'LeRoy Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28864_1_1.PNG',
        status: 1,
    },
    {
        id: 28865,
        name: 'LG&W Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28866,
        name: 'Link Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28868,
        name: 'Logan Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28869,
        name: 'Lyon County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28884,
        name: 'Magnolia State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28886,
        name: 'Connected Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28889,
        name: 'Malvern Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28892,
        name: 'Marathon Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28893,
        name: 'Marion County Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28894,
        name: 'The Marion National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28895,
        name: 'Marshall County State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28896,
        name: 'Mattel Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28897,
        name: 'McClave State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28899,
        name: 'Grasslands Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28900,
        name: 'McHenry Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28901,
        name: 'McIntosh County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28902,
        name: 'Meade County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28903,
        name: 'MECE Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28904,
        name: 'Melvin Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28905,
        name: 'Merchants & Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28906,
        name: 'Mercer Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28907,
        name: 'Metz Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28908,
        name: 'Merchants & Farmers Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28909,
        name: 'Merchants and Farmers Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28910,
        name: 'Middlesex Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28911,
        name: 'Millbury National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28911_1_1.PNG',
        status: 1,
    },
    {
        id: 28912,
        name: 'Milton Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28913,
        name: 'Millennial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28915,
        name: 'Mission Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28916,
        name: 'Mission National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28917,
        name: "Modesto's First Federal Credit Union",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28918,
        name: 'Mississippi River Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28919,
        name: 'Monmouth Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28920,
        name: 'Monroe Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28921,
        name: 'Morganton Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28922,
        name: 'Monroe County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28951,
        name: 'Nebraska State Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28952,
        name: 'Native American Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28955,
        name: 'Newport Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28956,
        name: 'Normangee State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28957,
        name: 'PromiseOne Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28958,
        name: 'North Cambridge Co-operative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_28958_1_1.PNG',
        status: 1,
    },
    {
        id: 28959,
        name: 'North County Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28960,
        name: 'North Georgia National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28961,
        name: 'Northeastern Operating Engineers FCU',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28962,
        name: 'Nelsonville Home & Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28964,
        name: 'Northern Kentucky Educators Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28965,
        name: 'Oak View National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28966,
        name: 'Oklahoma Heritage Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28967,
        name: "O'Bannon Bank",
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28968,
        name: 'Ohio Educational Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28969,
        name: 'Olpe State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28970,
        name: 'Oklahoma Educators Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28971,
        name: 'NorthStar Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28973,
        name: 'One Source Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28974,
        name: 'New Omni Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28976,
        name: 'Orland Park Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28977,
        name: 'Ormsby State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28979,
        name: 'Owensboro Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28980,
        name: 'Owingsville Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28981,
        name: 'Sewickley Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28982,
        name: 'Ozarks Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28983,
        name: 'Shell Lake State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28984,
        name: 'Shelby State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28986,
        name: 'Pacific Alliance Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28987,
        name: 'OSB Oklahoma State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28988,
        name: 'Sidney Federal Savings and Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28989,
        name: 'Sherwood State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28990,
        name: 'Sidney State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28991,
        name: 'Sierra Pacific Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28992,
        name: 'Silex Banking Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28993,
        name: 'Slovenian Savings and Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28994,
        name: 'Somerset Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28995,
        name: 'Somerville Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28996,
        name: 'Sooner State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28997,
        name: 'South Lafourche Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 28998,
        name: 'South Central Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29000,
        name: 'SouthFirst Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29001,
        name: 'Southwest Montana Community Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29002,
        name: 'Spencerport Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29002_1_1.PNG',
        status: 1,
    },
    {
        id: 29003,
        name: 'Spencer County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29004,
        name: 'Southwest National Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29006,
        name: 'Stanton State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29007,
        name: 'State Bank (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29009,
        name: 'State Bank of Graymont',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29010,
        name: 'State Bank of Cerro Gordo',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29011,
        name: 'State Bank of Lake Park',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29012,
        name: 'State Bank of Industry',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29013,
        name: 'State Bank of Herscher',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29014,
        name: 'State Bank of New Richland',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29015,
        name: 'State Bank of Odell',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29016,
        name: 'State Bank of Pearl City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29017,
        name: 'Stockmens Bank (MT)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29018,
        name: 'State Bank of Waterloo',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29019,
        name: 'Stockmans Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29020,
        name: 'State Nebraska Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29021,
        name: 'State Bank of Taunton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29022,
        name: 'Streator Home Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29023,
        name: 'Success Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29024,
        name: 'Strasburg State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29025,
        name: 'SunSouth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29026,
        name: 'SUMA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29027,
        name: 'Stroud National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29028,
        name: 'Systematic Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29029,
        name: 'Summit Bank (CA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29030,
        name: 'Table Grove State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29031,
        name: 'Sweet Water State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29032,
        name: 'Tarboro Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29033,
        name: 'Technicolor Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29033_1_1.PNG',
        status: 1,
    },
    {
        id: 29035,
        name: 'Tecumseh Federal Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29036,
        name: 'Texas Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29037,
        name: 'Texas Hill Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29041,
        name: 'Mechanics Savings Bank (ME)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29043,
        name: 'The Antwerp Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29044,
        name: 'The American Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29045,
        name: 'The Apple Creek Banking Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29046,
        name: 'The Bank of Fincastle',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29047,
        name: 'The Bank of Halls',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29048,
        name: 'The Bank of Jackson',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29049,
        name: 'The Bank of Kaukauna',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29050,
        name: 'The Bank of Kremlin',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29051,
        name: 'The Bank of Lafayette',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29052,
        name: 'The Bank of Marion',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29062,
        name: 'The Citizens Bank (KY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29063,
        name: 'The Bank of Tioga',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29064,
        name: 'The Citizens Bank of Clovis',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29067,
        name: 'The Citizens Bank of Edina',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29068,
        name: 'The Claxton Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29090,
        name: 'The Covington Savings and Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29093,
        name: 'The Cornerstone Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29094,
        name: 'The Edon State Bank Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29095,
        name: 'The Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29096,
        name: 'The Fairmount State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29097,
        name: 'The Farmers Bank (NC)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29098,
        name: 'The Farmers Bank of Appomattox',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29099,
        name: 'The Farmers State Bank (OH)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29100,
        name: 'The Farmers Bank of Mt. Pulaski',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29101,
        name: 'The Farmers and Mechanics Bank (IL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29102,
        name: 'The Farmers State Bank of Oakley',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29104,
        name: 'The First National Bank of Ely',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29105,
        name: 'First National Bank(AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29106,
        name: 'The Farmers State Bank (CO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29108,
        name: 'First State Bank of Shelby',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29109,
        name: 'The First National Bank of Hope',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29111,
        name: 'The First State Bank of Pond Creek',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29112,
        name: 'The Gerber State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29113,
        name: 'The Home Trust & Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29115,
        name: 'The Jefferson Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29116,
        name: 'The Killbuck Savings Bank Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29117,
        name: 'The Idabel National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29118,
        name: 'The Marblehead Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29119,
        name: 'The Liberty Savings Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29120,
        name: 'The Morris County National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29121,
        name: 'Hopeton State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29122,
        name: 'First State Bank of Abernathy',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29123,
        name: 'The National Bank of Adams County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29124,
        name: 'The National Bank of Malvern',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29125,
        name: 'The Northern State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29127,
        name: 'The Ottoville Bank Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29128,
        name: 'The Peoples Savings Bank Urbana Ohio',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29130,
        name: 'The Peoples Bank of Red Level',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29131,
        name: 'Peoples State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29134,
        name: 'Port Austin State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29135,
        name: 'The Miners State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29136,
        name: 'The Potter State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29137,
        name: 'The Tampa State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29138,
        name: 'The State Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29139,
        name: 'The Walton State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29141,
        name: 'The Twin Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29142,
        name: 'The Watkins Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29143,
        name: 'Three Rivers Bank of Montana',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29144,
        name: 'Titonka Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29145,
        name: 'Town Country National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29146,
        name: 'Trabaja Coop',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29147,
        name: 'Tricentury Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29149,
        name: 'Traders & Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29151,
        name: 'Trust Texas Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29152,
        name: 'Turtle Mountain State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29153,
        name: 'Lamesa National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29154,
        name: 'Marion Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29155,
        name: 'Mountain Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29158,
        name: 'Mutual Savings Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29160,
        name: 'Mutual Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29161,
        name: 'TNB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29162,
        name: 'Universal City Studios Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29163,
        name: 'New Foundation Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29164,
        name: 'Ukrainian Selfreliance Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29165,
        name: 'Union Bank of Sparta',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29165_1_1.PNG',
        status: 1,
    },
    {
        id: 29166,
        name: 'United Pacific Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29167,
        name: 'Union State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29168,
        name: 'United Midwest Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29169,
        name: 'Universal Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29171,
        name: 'United Mississippi Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29173,
        name: 'Montezuma State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29175,
        name: 'United Bank & Trust (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29175_1_1.PNG',
        status: 1,
    },
    {
        id: 29176,
        name: 'United Community Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29177,
        name: 'United Citizens Bank of Southern Kentucky',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29178,
        name: 'River Falls State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29180,
        name: 'Bank of the Panhandle',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29181,
        name: 'Grand Timber Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29182,
        name: 'Bank of Richmondville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29183,
        name: 'Sequoia Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29184,
        name: 'Black River Country Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29185,
        name: 'GBC International Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29186,
        name: 'Haddon Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29188,
        name: 'HNB First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29189,
        name: 'Pacific West Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29190,
        name: 'Best Hometown Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29191,
        name: 'Security State Bank (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29192,
        name: 'Bank of Monticello',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29193,
        name: 'First United Bank (ND)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29195,
        name: 'Bank of Waynesboro',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29200,
        name: 'Grundy National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29201,
        name: 'Citizens Bank (Byhalia, MS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29202,
        name: 'PacTrans Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29206,
        name: 'Roanoke Rapids Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29207,
        name: 'Rockland Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29208,
        name: 'Richland County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29209,
        name: 'Sabattus Regional Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29210,
        name: 'Security State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29211,
        name: 'Sebasticook Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29212,
        name: 'Peoples State Bank of Plainview',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29214,
        name: 'Scenic Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29215,
        name: 'Heritage South Community Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29216,
        name: 'HEB Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29217,
        name: 'Progressive Ozark Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29218,
        name: 'Putnam Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29218_1_1.PNG',
        status: 1,
    },
    {
        id: 29219,
        name: 'Citizens Bank & Trust Company (MN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29220,
        name: 'Jonestown Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29222,
        name: 'Patriot Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29223,
        name: 'Kingston National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29224,
        name: 'Pennsville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29225,
        name: 'Camino Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29226,
        name: 'Roundbank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29227,
        name: 'Peoples Bank of Kankakee County',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29228,
        name: 'The Grant County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29229,
        name: 'Jewett City Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29229_1_1.PNG',
        status: 1,
    },
    {
        id: 29233,
        name: 'Heritage Community Bank (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29234,
        name: 'InFirst Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29244,
        name: 'The Bank of Monroe',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29254,
        name: 'Peoples Bank of Middle Tennessee',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29261,
        name: 'Genoa Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29264,
        name: 'PBK Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29269,
        name: 'Katahdin Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29270,
        name: 'Sage Capital Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29271,
        name: 'Home State Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29272,
        name: 'Kennebec Federal Savings',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29273,
        name: 'Woodford State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29274,
        name: 'Johnson State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29275,
        name: 'Chicago Municipal Employees Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29276,
        name: 'Capital Area Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29277,
        name: 'Santa Barbara County Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29279,
        name: 'Bank of Millbrook',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29300,
        name: 'Bank of Whittier',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29301,
        name: 'BankStar Financial',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29302,
        name: 'Bank of Turtle Lake',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29303,
        name: 'Bar Harbor Savings & Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29303_1_1.PNG',
        status: 1,
    },
    {
        id: 29304,
        name: 'Bashas Associates Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29309,
        name: 'Bank of Ripley & Bank of Tipton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29310,
        name: 'Beacon Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29311,
        name: 'The Baxter State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29312,
        name: 'The Bath State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29313,
        name: 'Beauregard Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29314,
        name: 'Beartooth Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29315,
        name: 'Bank of St. Francisville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29316,
        name: 'Big Horn Federal',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29319,
        name: 'Brazos National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29320,
        name: 'Breda Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29321,
        name: 'Buckley State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29322,
        name: 'Bridgeton Onized Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29323,
        name: 'Buffalo Grove Bank and Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29324,
        name: 'Canton Co-operative Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29325,
        name: 'Cambridge State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29326,
        name: 'Calhoun Banks',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29327,
        name: 'California Lithuanian Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29328,
        name: 'Carroll Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29329,
        name: 'California Pacific Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29331,
        name: 'Catskill Hudson Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29332,
        name: 'CenBank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29333,
        name: 'Central Bank & Trust (WY)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29335,
        name: 'CFD Banking Services',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29336,
        name: 'Changing Seasons Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29337,
        name: 'Cheyenne State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29338,
        name: 'Butte State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29339,
        name: 'Citizens Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29340,
        name: 'Citizens National Bank (TX)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29341,
        name: 'First State Bank of Newcastle',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29342,
        name: 'First State Bank of Campbell Hill',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29343,
        name: 'Flora Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29344,
        name: 'Citizens Bank of Eldon & Versailles',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29346,
        name: 'First State Bank of Swanville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29347,
        name: 'First State Bank of Graham',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29348,
        name: 'The Florist Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29349,
        name: 'C3bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29350,
        name: 'Focus First Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29350_1_1.PNG',
        status: 1,
    },
    {
        id: 29352,
        name: 'Fort Lee Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29353,
        name: 'Freedom Bank (NJ)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29353_1_1.PNG',
        status: 1,
    },
    {
        id: 29354,
        name: 'Forreston State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29356,
        name: 'Freehold Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29357,
        name: 'Garden Plain State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29358,
        name: 'Gates Banking & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29359,
        name: 'Gehant Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29361,
        name: 'Golden Valley Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29362,
        name: 'G.W. Jones Exchange Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29363,
        name: 'Gateway Bank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29365,
        name: 'Citizens Federal Savings and Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29366,
        name: 'First Midwest Bank (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29378,
        name: 'Grand Rivers Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29379,
        name: 'Great Nations Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29381,
        name: 'Greenville Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29382,
        name: 'HAB Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29385,
        name: 'Headwaters State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29386,
        name: 'Haverhill Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29386_1_1.PNG',
        status: 1,
    },
    {
        id: 29387,
        name: 'Henderson Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29389,
        name: 'Heritage Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29390,
        name: 'Heritage Bank (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29391,
        name: 'Hiawatha National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29392,
        name: 'Heritage South Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29393,
        name: 'Guaranty Bank & Trust Company (LA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29395,
        name: 'Hoffman Estates Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29397,
        name: 'Home Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29398,
        name: 'Home Savings Bank (KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29400,
        name: 'Home Pride Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29401,
        name: 'Home State Bank- HSB (IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29417,
        name: 'Harwood State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29427,
        name: 'Homewood Federal Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29428,
        name: 'Hoosier Heartland State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29429,
        name: 'Hoyne Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29434,
        name: 'IN Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29436,
        name: 'Peoples Bank (Coldwater, KS)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29438,
        name: 'KIT Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29440,
        name: 'Kentucky Farmers Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29441,
        name: 'Jackson Federal Savings & Loan Association',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29442,
        name: 'Iroquois Farmers State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29443,
        name: 'Merchants & Farmers Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29445,
        name: 'National Bank of New York City',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29446,
        name: 'Bank of Vici',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29447,
        name: 'Houston Highway Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29448,
        name: 'IDB Global Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29449,
        name: 'Jolt Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29450,
        name: 'Inez Deposit Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29456,
        name: 'Patriot Community Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29457,
        name: 'PennCrest Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29458,
        name: 'Pauls Valley National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29460,
        name: 'People First Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29461,
        name: 'Peoples Bank & Trust',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29462,
        name: 'Northwest Bank (Business)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29464,
        name: 'Pacific National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29465,
        name: 'Peoples Bank & Trust Company (TN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29471,
        name: 'Peoples Bank of Deer Lodge',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29472,
        name: 'Peoples Bank of Greensboro',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29473,
        name: 'Peoples State Bank of Colfax',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29474,
        name: 'Peoples Bank of Lebanon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29475,
        name: 'Peoples State Bank of Velva',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29477,
        name: 'Pickens Savings and Loan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29478,
        name: 'Peoples Trust & Savings Bank (IN)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29480,
        name: 'Plattsmouth State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29482,
        name: 'Prime Bank (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29483,
        name: 'Providence Bank (GA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29485,
        name: 'Railroad & Industrial Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29486,
        name: 'Quoin Financial Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29488,
        name: 'Reliance Bank Redefining Service',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29491,
        name: 'Reliant Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29494,
        name: 'Rio Grande Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29495,
        name: 'River Wind Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29497,
        name: 'Riverfall Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29498,
        name: 'River Works Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29500,
        name: 'Sanborn Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29501,
        name: 'Schuyler Savings Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29502,
        name: 'Salyersville National Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29503,
        name: 'Security Federal Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29504,
        name: 'Rolette State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29507,
        name: 'Security State Bank of Kenyon',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29508,
        name: 'Security State Bank of Fergus Falls',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29510,
        name: 'Seiling State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29511,
        name: 'Sevier County Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29512,
        name: 'Security State Bank (Algona, IA)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29514,
        name: 'Town & Country Bank (NE)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29515,
        name: 'RiverBank (AR)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29527,
        name: 'Pathfinder Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29528,
        name: 'First State Bank of Paint Rock',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29550,
        name: 'Bank of Laverne',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29551,
        name: 'Bank of Lake Village',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29552,
        name: 'SAG-AFTRA Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_29552_1_2.SVG',
        status: 1,
    },
    {
        id: 29553,
        name: 'Bank of Morton',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29554,
        name: 'Bank of New Madrid',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29555,
        name: 'Bank of Newman Grove',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29556,
        name: 'Bank of Prague',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29557,
        name: 'Basile State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29558,
        name: 'Bryant State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29559,
        name: 'Burton State Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29560,
        name: 'Caldwell Bank & Trust Co.',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29561,
        name: 'Catlin Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29562,
        name: 'Ceresco Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29563,
        name: 'Citizens Bank (Texas)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29565,
        name: 'Bank of Monticello (MO)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29566,
        name: 'First State Bank of Harvey',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29567,
        name: 'First State Bank of Hemphill',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29568,
        name: 'First State Bank of Hordville',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29569,
        name: 'First State Bank of Ryan',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29570,
        name: 'First State Bank of the South',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29571,
        name: 'Secur Trust Federal Credit Union',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29572,
        name: 'Hodge Bank & Trust Company',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29573,
        name: 'FirstState Bank (AL)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29574,
        name: 'Jackson Parish Bank',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
    {
        id: 29577,
        name: 'Peoples Bank & Trust Co. (OK)',
        logo: 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG',
        status: 1,
    },
];
