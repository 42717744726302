import React from 'react';
import { Grid } from '@material-ui/core';
import { TooltipProps } from 'recharts';
import PieChartLegend from './PieChartLegend';
import SimplePieChart from './SimplePieChart';

export type ValueType = number | string | Array<number | string>;
export type NameType = number | string;

//eslint-disable-next-line
export interface SimplePieChartProps<T extends object> {
    nameKey: keyof T & string;
    dataKey: keyof T & string;
    data: T[];
    tooltip?: React.FC<TooltipProps<ValueType, NameType>>;
}

export interface PieChartLegendProps<T> {
    heading: string;
    nameKey: keyof T & string;
    legends: T[];
}

//eslint-disable-next-line
export interface PieChartProps<T extends object, TLegend> {
    chart: SimplePieChartProps<T>;
    legend: PieChartLegendProps<TLegend>;
}

//eslint-disable-next-line
const PieChartWrapper = <T extends object, TLegend>({
    chart,
    legend,
}: PieChartProps<T, TLegend>) => (
    <Grid container alignItems="center" spacing={5}>
        <Grid item md={8} xs={12}>
            <SimplePieChart {...chart} />
        </Grid>
        <Grid item md={4} xs={12}>
            <PieChartLegend {...legend} />
        </Grid>
    </Grid>
);

export default PieChartWrapper;
