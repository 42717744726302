import {
    axiosClient,
    ApiResponse,
    Donee,
    makeApiRequest,
} from '@givelify/givelify-ui';
import {
    getCurrentDonneApiEndpoint,
    getDenominationsApiEndpoint,
    getDoneeOnboardingApiEndpoint,
    getDoneeUpdateApiEndpoint,
} from '../../consts/endpoints';
import { TaxIdInfoUpdateRequest } from '../../requests/donee/TaxIdInfoUpdateRequest';
import { GetDenominationsResponse } from '../../responses/denomination/GetDenominationsResponse';
import { DoneePatchResponse } from '../../responses/donee/DoneePatchResponse';
import { toFormData } from '../../utils';

export const getDoneeRequest = async (): Promise<ApiResponse<Donee>> => {
    const url = getCurrentDonneApiEndpoint();
    const httpRequest = axiosClient.get(url);
    return await makeApiRequest<Donee>(httpRequest);
};

export const updateTaxIdInfoRequest = async (
    doneeId: number,
    request: TaxIdInfoUpdateRequest,
): Promise<ApiResponse<DoneePatchResponse>> => {
    const url = getDoneeUpdateApiEndpoint(doneeId);
    const formData = toFormData(request);
    formData.append('_method', 'PATCH');
    const httpRequest = axiosClient.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return await makeApiRequest<DoneePatchResponse>(httpRequest);
};

export const getOnboardingRequest = async (
    doneeId: number,
): Promise<ApiResponse<Donee>> => {
    const url = getDoneeOnboardingApiEndpoint(doneeId);
    const httpRequest = axiosClient.get(url);
    return await makeApiRequest<Donee>(httpRequest);
};

export const getDenominationsRequest = async (): Promise<
    ApiResponse<GetDenominationsResponse>
> => {
    const url = getDenominationsApiEndpoint();
    const httpRequest = axiosClient.get(url);
    return await makeApiRequest<GetDenominationsResponse>(httpRequest);
};
