import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifySwitchStyleProps = GivelifyBoxMarginProps;

export const switchStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifySwitchStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifySwitchStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifySwitchStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifySwitchStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        switchWithBeforeAfterText: {
            display: 'flex',
            alignItems: 'center',
            '&:focus': {
                outline: 'none',
            },
        },
        textBefore: {
            marginRight: 12,
        },
        textAfter: {
            marginLeft: 12,
        },
        thickSwitchContainer: {
            position: 'relative',
            width: 69,
            height: 28,
            '&:focus': {
                outline: 'none',
            },
        },
        thickSwitchInput: {
            opacity: 0.3,
            width: 0,
            height: 0,
            padding: 0,
            margin: 0,
            cursor: 'pointer',
            '&:checked + $thickSwitchTrack': {
                backgroundColor: theme.gas.palette.primary,
                '& $thickSwitchThumb': {
                    transform: 'translateX(41px)',
                },
                '& $thickSwitchLabelOn': {
                    opacity: 1,
                },
                '& $thickSwitchLabelOff': {
                    opacity: 0,
                },
            },
            '&:focus': {
                outline: 'none',
            },
        },
        thickSwitchTrack: {
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            width: 69,
            height: 28,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#c6c8cc',
            borderRadius: 14,
            WebkitTransition: '.2s',
            transition: '.2s',
        },
        thickSwitchThumb: {
            width: 24,
            height: 24,
            marginLeft: 2,
            backgroundColor: theme.gas.palette.background.primary,
            borderRadius: '50%',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.233829)',
            zIndex: 2,
            WebkitTransition: '.2s',
            transition: '.2s',
        },
        thickSwitchLabelOn: {
            opacity: 0,
            color: theme.gas.palette.label.contrast,
            position: 'absolute',
            width: 44,
            right: 25,
            textAlign: 'center',
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none',
            WebkitTransition: '.15s',
            transition: '.15s',
        },
        thickSwitchLabelOff: {
            opacity: 1,
            color: theme.gas.palette.label.primary,
            position: 'absolute',
            width: 44,
            left: 25,
            textAlign: 'center',
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none',
            WebkitTransition: '.15s',
            transition: '.15s',
        },
    }),
);
