import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DirectDepositSvg } from '../../assets/rc-assets/direct-deposit.svg';
import { I18N_NAMESPACE } from '../../consts';
import { RequiredCard } from './RequiredCard';
import { RequiredCardVariantProps } from './RequiredCard';
import { specialCardStyles } from './specialCardStyles';

export const DirectDepositCard: React.FC<RequiredCardVariantProps> = (
    props,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = React.useMemo(
        () => ({
            title: t('requiredInfo.directDeposit.title'),
            description: t('requiredInfo.directDeposit.description'),
            actionButtonText: t('requiredInfo.directDeposit.buttonText'),
            pendingCaption: t('requiredInfo.cardMessages.pendingCaption'),
            pendingDescription: t(
                'requiredInfo.cardMessages.pendingDescription',
            ),
            warningCaption: t('requiredInfo.cardMessages.warningCaption'),
            warningDescription: t(
                'requiredInfo.cardMessages.warningDescription',
            ),
            retryCaption: t('requiredInfo.cardMessages.retryCaption'),
            retryDescription: t('requiredInfo.cardMessages.retryDescription'),
            successCaption: t('requiredInfo.cardMessages.successCaption'),
            successDescription: t(
                'requiredInfo.cardMessages.successDescription',
            ),
            retryButtonText: t('requiredInfo.cardMessages.reviewInfo'),
        }),
        [t],
    );
    const { rcDepBadge } = specialCardStyles();
    const badge = (
        <SvgIcon
            className={rcDepBadge}
            component={DirectDepositSvg}
            viewBox="0 0 72 67"
        />
    );
    return (
        <RequiredCard
            actionButtonText={copy.actionButtonText}
            badge={badge}
            className={props.className}
            description={copy.description}
            onClick={props.onClick}
            pendingCaption={copy.pendingCaption}
            pendingDescription={copy.pendingDescription}
            retryButtonText={copy.retryButtonText}
            retryCaption={copy.retryCaption}
            retryDescription={copy.retryDescription}
            state={props.state}
            successCaption={copy.successCaption}
            successDescription={copy.successDescription}
            testId="directDepositCard"
            title={copy.title}
            warningCaption={copy.warningCaption}
            warningDescription={copy.warningDescription}
        />
    );
};
