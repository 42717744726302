import {
    requestInit,
    requestStart,
    requestSuccess,
    requestError,
} from '@givelify/givelify-ui';
// TODO: rename after everything is changed to new package
import { requestInit as newRequestInit } from '@givelify/utils';
import { Reducer } from 'redux';
import {
    SET_DONORS_CURRENT_LOADING,
    SET_DONORS_CURRENT_SUCCESS,
    SET_DONORS_CURRENT_FAILED,
} from './current/types';
import {
    SET_DONORS_PREVIOUS_LOADING,
    SET_DONORS_PREVIOUS_SUCCESS,
    SET_DONORS_PREVIOUS_FAILED,
} from './previous/types';
import { DonorsState, DonorsActions, SET_DONORS } from './types';

const initialState: DonorsState = {
    current: requestInit(),
    previous: requestInit(),
    donors: newRequestInit(),
};

const DonorsReducer: Reducer<DonorsState, DonorsActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case SET_DONORS_CURRENT_LOADING:
            return { ...state, current: requestStart() };
        case SET_DONORS_PREVIOUS_LOADING:
            return { ...state, previous: requestStart() };
        case SET_DONORS_CURRENT_SUCCESS:
            return { ...state, current: requestSuccess(action.data) };
        case SET_DONORS_PREVIOUS_SUCCESS:
            return { ...state, previous: requestSuccess(action.data) };
        case SET_DONORS_CURRENT_FAILED:
            return { ...state, current: requestError(action.error) };
        case SET_DONORS_PREVIOUS_FAILED:
            return { ...state, previous: requestError(action.error) };
        case SET_DONORS:
            return { ...state, donors: action.data };
        default:
            return state;
    }
};

export default DonorsReducer;
