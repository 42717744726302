import React from 'react';
import { OnboardingContainer } from '../OnboardingContainer';
import { OnboardingProps } from '../OnboardingProps';

export const OnboardingV1: React.FC<OnboardingProps> = React.memo((props) => {
    if (props.donee.onboarding.hasCompleted) return null;

    return <OnboardingContainer {...props} />;
});

OnboardingV1.displayName = 'OnboardingV1';
