import { makeStyles, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles';

const GAP = 24;

const rowStyles: CreateCSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: GAP,
    '& > div': {
        flex: 1,
    },
};

export default makeStyles((theme: Theme) => ({
    grid: {
        display: 'flex',
        gap: GAP,
        flexDirection: 'column',
    },
    cardsWrapper: {
        ...rowStyles,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    pieChartsWrapper: {
        ...rowStyles,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));
