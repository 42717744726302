import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyIcon,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { isZipCode } from 'utils/validateZipCode';
import { City, LocationSelected } from '../types';
import { getCities } from './service';
import useStyles from './styles';

export interface GivelifyLocationAutosuggestProps {
    value: LocationSelected;
    onChange: (location: LocationSelected) => void;
}

const renderOption = (c: City) => c?.suggest;

const GivelifyLocationAutosuggest: React.FC<GivelifyLocationAutosuggestProps> = ({
    value,
    onChange,
}) => {
    const {
        autoSuggestStyles,
        inputBaseRoot,
        input,
        icon,
        notchedOutline,
    } = useStyles();

    const [options, setOptions] = useState<City[]>([]);

    const [fetchCitiesResponse, makeFetchCitiesRequest] = useApiRequest<
        City[]
    >();

    useEffect(() => {
        if (fetchCitiesResponse.type !== 'REQUEST_SUCCESS') return;
        setOptions(fetchCitiesResponse.response);
    }, [fetchCitiesResponse]);

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY:
            'pages.settings.campuses.addCampusModal.searchForm.locationAutocomplete',
    });

    const { placeholder } = useMemo(
        () => ({
            placeholder: scopedTranslate('placeholder'),
        }),
        [scopedTranslate],
    );

    return (
        <Autocomplete
            clearOnEscape
            freeSolo
            closeIcon={null}
            fullWidth={true}
            getOptionLabel={renderOption}
            inputValue={value?.userTypedValue || ''}
            onChange={(_, value) => {
                if (typeof value === 'string') {
                    onChange({
                        userTypedValue: value,
                        city: '',
                        state: '',
                        zip: '',
                    });
                } else {
                    onChange({
                        city: value.city,
                        state: value.state,
                        userTypedValue: value.suggest,
                        zip: '',
                    });
                }
            }}
            options={options}
            popupIcon={null}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <GivelifyIcon
                                className={icon}
                                variant="location-pin"
                            />
                        ),
                        classes: {
                            root: inputBaseRoot,
                            input,
                            notchedOutline,
                        },
                    }}
                    className={autoSuggestStyles}
                    fullWidth={true}
                    onChange={e => {
                        const { value } = e.target;
                        if (value.length > 1) {
                            makeFetchCitiesRequest(getCities(e.target.value));
                        }
                        onChange({
                            userTypedValue: value,
                            city: '',
                            state: '',
                            zip: isZipCode(value) ? value : '',
                        });
                    }}
                    placeholder={placeholder}
                    type="text"
                    variant="outlined"
                />
            )}
            renderOption={renderOption}
        />
    );
};

export default GivelifyLocationAutosuggest;
