import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const integrationStyle = makeStyles((theme: Theme) => {
    return createStyles({
        paperStyle: {
            padding: 0,
        },
        chooseIntegration: {
            color: '#4a4a4a !important',
            marginBottom: theme.spacing(3),
        },
        card: {
            borderRadius: 11,
            minWidth: 248,
            margin: theme.spacing(0, 3, 4, 0),
            '&:nth-child(4n)': {
                marginRight: 0,
                [theme.breakpoints.down('md')]: {
                    marginRight: theme.spacing(3),
                },
            },
        },
        cardName: {
            marginBottom: theme.spacing(2),
        },
        thirdParty: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        openThirdPartyBtn: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        thirdPartyModal: {
            padding: theme.spacing(0, 8, 6),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 4),
            },
        },
        thirdPartyInfo: {
            paddingTop: theme.spacing(4),
        },
        listThirdParty: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(5),
            '& .integration-name': {
                marginTop: theme.spacing(4),
            },
        },
        integrationContent: {
            marginTop: theme.spacing(2),
        },
        frequency: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
        frequencyDate: {
            fontWeight: 700,
        },
        frequencyText: {
            display: 'inline',
        },
        reportContent: {
            marginTop: 31,
            overflow: 'initial !important',
        },
        numberCount: {
            top: 3,
            right: '-33px',
            position: 'absolute',
            background: '#FF1612',
            fontSize: 14,
            padding: '1px 5px',
            borderRadius: '25px',
            color: '#fff',
            lineHeight: '16px',
        },
    });
});

export const matchRowStyle = makeStyles((theme: Theme) => {
    return createStyles({
        matchHeader: {
            height: '54px',
            borderBottom: '1px solid #cacaca',
            fontWeight: 700,
            flexWrap: 'nowrap',
            alignItems: 'center',
            '& $matchHeaderItem': {
                borderBottom: 'none',
            },
        },
        matchHeaderItem: {
            padding: theme.spacing(0, 0, 0, 3),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: 14,
            fontWeight: 800,
            height: '100%',
            borderBottom: '1px solid ' + GivelifyColorPalette.neutralPlatinum,
        },
        headerItemRight: {
            lineHeight: '20px',
        },
        integrationInputRight: {
            position: 'relative',
            paddingLeft: theme.spacing(2),
            '& .MuiTextField-root': {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: theme.spacing(2, 3, 2, 0),
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'flex-start',
                },
            },
        },
        matchingFooter: {
            padding: theme.spacing(3, 5),
        },
        matchingButtonContent: {
            textAlign: 'right',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                marginTop: theme.spacing(2),
            },
        },
        matchRowContent: {
            flexWrap: 'nowrap',
            borderBottom: '1px solid #cacaca',
            minHeight: 64,
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            },
        },
        donorNameContent: {
            display: 'flex',
            alignItems: 'center',
        },
        envelopeName: {
            padding: theme.spacing(0, 3),
            alignSelf: 'stretch',
            borderRight: '1px solid #cacaca',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(3),
            },
        },
        integrationInput: {
            '& .MuiInputBase-input': {
                height: 48,
                padding: theme.spacing(3),
                boxSizing: 'border-box',
                width: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 'none',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(2),
                padding: theme.spacing(0, 0, 2),
                borderLeft: 0,
            },
        },
        integrationNewInput: {
            display: 'flex',
            alignItems: 'center',
            height: 48,
            color: theme.palette.primary.main,
        },
        hintText: {
            color: '#5b5b5b',
            position: 'absolute',
            top: 16,
            right: 16,
            [theme.breakpoints.down('sm')]: {
                top: 24,
            },
        },
        matchItem: {
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid #e3e3e3',
            padding: theme.spacing(0, 3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 3),
                borderBottom: '1px solid #e3e3e3',
            },
        },
        lastMatchItem: {
            [theme.breakpoints.down('sm')]: {
                borderBottom: '0',
            },
            [theme.breakpoints.down('xs')]: {
                borderBottom: '1px solid #e3e3e3',
            },
        },
        matchingSuccess: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        successImg: {
            marginRight: theme.spacing(2),
        },
        emailContent: {
            width: '100%',
            wordWrap: 'break-word',
        },
        matchEnvelopeRowContent: {
            alignItems: 'stretch',
            flexWrap: 'nowrap',
            borderBottom: '1px solid #cacaca',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            },
        },
        matchEnvelopeItem: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRight: '1px solid #e3e3e3',
            padding: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #e3e3e3',
            },
        },
        matchEnvelopeButton: {
            '& button': {
                padding: theme.spacing(3, 4),
                minWidth: 0,
            },
        },
        selectFund: {
            '& .MuiInputBase-input': {
                border: 0,
            },
            '& fieldset': {
                border: 0,
            },
        },
        labelWrapper: {
            minHeight: 64,
            display: 'flex',
            alignItems: 'center',
        },
        matchButton: {
            '& span': { textAlign: 'left' },
        },
    });
});

export const selectIntegrationTypeStyle = makeStyles((theme: Theme) => {
    return createStyles({
        selectIntegrationContent: {
            marginTop: theme.spacing(4),
        },
        selectTypeContent: {
            marginTop: theme.spacing(2),
        },
        groupRadio: {
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
        typeItem: {
            marginLeft: 0,
            '& .MuiButtonBase-root': {
                display: 'none',
            },
            '& .MuiFormControlLabel-label': {
                border: '1px solid transparent',
                borderRadius: 10,
                padding: theme.spacing(3, 5),
                boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
                fontSize: 18,
                lineHeight: '22px',
                fontWeight: 700,
                [theme.breakpoints.down('md')]: {
                    paddingLeft: '12px',
                    paddingRight: '12px',
                },
            },
            '& .Mui-checked + .MuiFormControlLabel-label': {
                border: '1px solid ' + theme.palette.primary.main,
            },
            '&:last-child': {
                marginRight: 0,
            },
        },
    });
});
