import React, { useMemo, useState } from 'react';
import { GivelifyIcon } from '@givelify/givelify-ui';
import { GivelifyButton } from '@givelify/givelify-ui';
import {
    Box,
    Dialog,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFilterModalStyle } from './styles';

interface SelectOptionProps {
    value: string;
    label: string;
}

interface MobileFilterSelectProps {
    headerText: string;
    options: SelectOptionProps[];
    onChange: (selectedItem: SelectOptionProps) => void;
    value: string;
}

const MobileFilterSelect: React.FC<MobileFilterSelectProps> = ({
    headerText,
    options,
    onChange,
    value,
}) => {
    const classes = useFilterModalStyle();

    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            apply: t(`labels.apply`),
        }),
        [t],
    );

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [valueInternal, setValueInternal] = useState(value);

    const selectedItem = useMemo(
        () => options.find(o => o.value === valueInternal),
        [options, valueInternal],
    );

    const onChangeInternal = (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string,
    ) => {
        setValueInternal(value);
    };

    const applyFilters = () => {
        onChange(selectedItem);
        handleClose();
    };

    const SortOrderItem = ({ option }: { option: SelectOptionProps }) => (
        <FormControlLabel
            className={classes.checkmarkRadio}
            control={
                <Radio
                    checkedIcon={
                        <GivelifyIcon
                            className={classes.checkIcon}
                            color="#52545B"
                            variant="check"
                        />
                    }
                    icon={
                        <GivelifyIcon
                            className={classes.checkIcon}
                            color="transparent"
                            variant="check"
                        />
                    }
                />
            }
            label={option.label}
            value={option.value}
        />
    );

    return (
        <>
            <IconButton className={classes.iconButton} onClick={handleOpen}>
                <GivelifyIcon variant="sort" />
                <div className={classes.buttonText}>{selectedItem.label}</div>
            </IconButton>
            <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                onClose={handleClose}
                open={open}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingTop={2}
                >
                    <h3 className={classes.title}>{headerText}</h3>
                    <GivelifyButton
                        bold
                        name="apply"
                        onClick={applyFilters}
                        text={copy.apply}
                        variant="ghost"
                    />
                </Box>
                <hr className={classes.divider} />
                <RadioGroup
                    className={classes.radioGroup}
                    onChange={onChangeInternal}
                    value={valueInternal}
                >
                    {options.map((option, i) => (
                        <SortOrderItem key={i} option={option} />
                    ))}
                </RadioGroup>
            </Dialog>
        </>
    );
};

export default MobileFilterSelect;
