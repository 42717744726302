import React, { useMemo } from 'react';
import { IntegrationTypes } from 'pages/integrations/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { PaginatedResponse } from 'types/paginationTypes';
import MatchingInfiniteLoader from '../common/MatchingInfiniteLoader';
import DonorMatchingRow, {
    DonorMatchingProps,
} from './components/DonorMatchingRow';

interface OwnProps {
    integration: IntegrationTypes;
    onSuccess: () => void;
    url: (pageNumber: number) => string;
    reportId?: number;
    isReadOnly: boolean;
}

const DonorMatchingInfiniteLoader: React.FC<OwnProps> = ({
    integration,
    onSuccess,
    url,
    reportId,
    isReadOnly,
}) => {
    const { t } = useTranslation();
    const doneeId = useSelector(
        (state: AppState) => state.Donee.campuses[0].id,
    );
    const { errorMatchDonor, noMatchDonor } = useMemo(
        () => ({
            errorMatchDonor: t('error.errorMatchDonors'),
            noMatchDonor: t('error.noMatchDonors'),
        }),
        [t],
    );

    return (
        <MatchingInfiniteLoader
            integration={integration}
            loadErrorText={errorMatchDonor}
            noItemsText={noMatchDonor}
            renderData={(dataSet: PaginatedResponse<DonorMatchingProps>) =>
                dataSet.data.map((r, i) => {
                    return (
                        <DonorMatchingRow
                            key={i}
                            {...r}
                            doneeId={doneeId}
                            integration={integration}
                            isReadOnly={isReadOnly}
                            onSuccess={onSuccess}
                            reportId={reportId}
                        />
                    );
                })
            }
            url={url}
        />
    );
};

export default React.memo(DonorMatchingInfiniteLoader);
