import { createStyles, makeStyles } from '@material-ui/core';

export const specialCardStyles = makeStyles(() =>
    createStyles({
        rcDepBadge: { width: 72, height: 67, marginBottom: 27 },
        rcTaxBadge: { width: 60, height: 67, marginBottom: 27 },
        rcAdminBadge: { width: 56, height: 67, marginBottom: 27 },
        rcView: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        rcCard: { margin: 8 },
        rcTitle: {
            textAlign: 'center',
            marginBottom: 46,
        },
    }),
);
