import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const quickGiveStyle = makeStyles((theme: Theme) => {
    return createStyles({
        quickGiveContainer: {
            display: 'flex',
            marginTop: 40,
        },
        quickGiveEditorSide: {
            maxWidth: 600,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginBottom: 75,
            },
        },
        quickGivePreviewSide: {
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        quickGiveEditorContainer: { marginTop: 24 },
        quickGiveEditorDivider: {
            marginTop: 32,
            marginBottom: 35,
        },
        quickGiveOptionsContainer: {
            marginTop: 32,
            padding: 24,
            borderRadius: 10,
            border: '1px solid #AAAAAA',
            background: '#FFFFFF',
            '&:hover': {
                borderColor: (hasFullAccess) =>
                    hasFullAccess ? theme.palette.primary.main : 'unset',
                cursor: (hasFullAccess) =>
                    hasFullAccess ? 'pointer' : 'unset',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '24px 16px',
            },
        },
        quickGiveOptionsContainerSelected: {
            marginTop: 32,
            padding: 24,
            borderRadius: 10,
            border: '1px solid',
            borderColor: theme.palette.primary.main,
            background: '#FFFFFF',
            '&:hover': {
                cursor: (hasFullAccess) =>
                    hasFullAccess ? 'pointer' : 'unset',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '24px 16px',
            },
        },
        quickGiveOptionsTitle: {
            lineHeight: '20px',
            marginTop: 4,
            marginBottom: 4,
        },
        quickGiveOptionsCaption: {
            lineHeight: '22px',
            marginTop: 4,
            marginBottom: 4,
        },
        quickGiveOptionsHolder: {
            marginTop: 20,
            marginBottom: 20,
            justifyContent: 'space-between',
        },
        quickGiveOption: {
            height: 69,
            width: '100%',
            borderRadius: 10,
            border: '1px solid #AAAAAA',
            background: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            boxShadow: 'none',
        },
        quickGiveOptionCheck: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        },
    });
});
