import React from 'react';
import { integrationStyle } from '../../components/style';
import { IntegrationTypes } from '../../types';
import { matchSelect } from '../../utils/integrationFormatFilterUtils';
import { AutoIntegrationSyncLoader } from './AutoIntegrationSyncLoader';
import AutoIntegrationTabsContent from './AutoIntegrationTabsContent';
import CommonTabsContent from './CommonTabsContent';

interface IntegrationTabsContentProps {
    currentTab: number;
    integration: IntegrationTypes;
    integrationNoEnvelope?: boolean;
    integrationNoDonor: boolean;
    goToFirstTab: () => void;
    goToTab: (tab: number) => void;
    defaultMatchStatus?: matchSelect;
    buildCustomReportClick: () => void;
    integrationSync: string;
    closeSync: boolean;
    handleCloseSync: () => void;
}

const IntegrationTabsContent: React.FC<IntegrationTabsContentProps> = (
    props,
) => {
    const classes = integrationStyle();

    const showAutoIntegrationContent = [
        'CCB',
        'F1',
        'PLANNING_CENTER',
        'F1GO',
    ].includes(props.integration.integrationType);

    if (
        showAutoIntegrationContent &&
        props.integration.integrationType === 'PLANNING_CENTER' &&
        props.integrationSync !== 'INITIAL_SETUP_COMPLETED'
    ) {
        return <AutoIntegrationSyncLoader />;
    }

    return (
        <div className={classes.integrationContent}>
            {showAutoIntegrationContent ? (
                <AutoIntegrationTabsContent
                    closeSync={props.closeSync}
                    currentTab={props.currentTab}
                    defaultMatchStatus={props.defaultMatchStatus}
                    goToFirstTab={props.goToFirstTab}
                    goToTab={props.goToTab}
                    handleCloseSync={props.handleCloseSync}
                    integration={props.integration}
                    integrationSync={props.integrationSync}
                />
            ) : (
                <CommonTabsContent
                    buildCustomReportClick={props.buildCustomReportClick}
                    currentTab={props.currentTab}
                    goToFirstTab={props.goToFirstTab}
                    integration={props.integration}
                    integrationNoDonor={props.integrationNoDonor}
                    integrationNoEnvelope={props.integrationNoEnvelope}
                />
            )}
        </div>
    );
};

export default IntegrationTabsContent;
