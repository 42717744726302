import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import {
    GivelifyStepsCircleStyles,
    GivelifyStepsLineStyles,
    GivelifyStepsStyles,
} from './styles';

export interface GivelifyStepsProps extends GivelifyBoxMarginProps {
    steps: number;
    current?: number;
    className?: string;
    variant: 'vertical' | 'horizontal';
    onClick?: (index: number) => void;
}

export interface GivelifyStepProps {
    value: number;
    done: boolean;
    variant: 'vertical' | 'horizontal';
    active?: boolean;
    onClick?: () => void;
}

export function GivelifyStepCircle(props: GivelifyStepProps) {
    const { done, value, active = false, onClick } = props;
    const {
        stOutDefault,
        stInDefault,
        stInActive,
        stOutActive,
    } = GivelifyStepsCircleStyles();

    const outClassName = mergeClassNames(stOutDefault, active && stOutActive);
    const inClassName = mergeClassNames(stInDefault, done && stInActive);
    return (
        <div key={`gvl-step-${value}`} className={outClassName}>
            <button
                className={inClassName}
                onClick={onClick}
                tabIndex={-1}
                disabled={!done || active}
            >
                {value}
            </button>
        </div>
    );
}

export function GivelifyStepKnot(props: GivelifyStepProps) {
    const { value, active = false, variant } = props;
    const { stLineDefault, stLineActive } = GivelifyStepsLineStyles({
        variant,
    });
    const knotClassName = mergeClassNames(
        stLineDefault,
        active && stLineActive,
    );
    return <div key={`gvl-step-kn-${value}`} className={knotClassName}></div>;
}

export function GivelifySteps(props: GivelifyStepsProps) {
    const {
        steps,
        current: passedCurrent = -1,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        className,
        variant,
        onClick,
    } = props;
    const current = passedCurrent - 1;
    if (steps <= 0) return null;
    const stepComps = [];
    let knotCount = 0;
    for (let index = 0; index < steps; index++) {
        if (index !== 0) {
            stepComps.push(
                GivelifyStepKnot({
                    value: knotCount,
                    done: index <= current,
                    variant,
                }),
            );
            knotCount++;
        }
        stepComps.push(
            GivelifyStepCircle({
                value: index + 1,
                done: index <= current,
                active: index === current,
                variant,
                onClick: onClick
                    ? () => {
                          onClick(index);
                      }
                    : undefined,
            }),
        );
        if (index + 1 < steps) {
            stepComps.push(
                GivelifyStepKnot({
                    value: knotCount,
                    done: index <= current,
                    variant,
                }),
            );
            knotCount++;
        }
    }

    const { boxMargin, stRoot } = GivelifyStepsStyles({
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        variant,
        childCount: 3 * steps - 2,
    });
    const rootClassName = mergeClassNames(boxMargin, stRoot, className);
    return <div className={rootClassName}>{stepComps.map(item => item)}</div>;
}
