import React, { useMemo } from 'react';
import { GivelifyButton, AvatarIcon, ImageIcon } from '@givelify/ui';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../consts';

export interface ImageUploadButtonProps {
    id: string;
    isImage: boolean;
    onUploadClick: () => unknown;
    isLoading: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: 24,
    },
}));

interface ImageUploadButtonRenderProps extends ImageUploadButtonProps {
    isAvatar: boolean;
    addText: string;
    replaceText: string;
}

const ImageUploadButtonRender: React.FC<ImageUploadButtonRenderProps> = ({
    id,
    isImage,
    onUploadClick,
    isAvatar,
    addText,
    replaceText,
    isLoading,
}) => {
    const { icon } = useStyles();

    return (
        <GivelifyButton
            classes={{
                startIcon: icon,
            }}
            id={id}
            isLoading={isLoading}
            onClick={onUploadClick}
            startIcon={isAvatar ? <AvatarIcon /> : <ImageIcon />}
            text={isImage ? replaceText : addText}
            variant="ghost"
        />
    );
};

export const CustomizeProfileBanner: React.FC<ImageUploadButtonProps> =
    React.memo((props) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const { addBannerText, replaceBannerText } = useMemo(
            () => ({
                addBannerText: t(
                    'customizeProfile.bannerImageSection.addBannerText',
                ),
                replaceBannerText: t(
                    'customizeProfile.bannerImageSection.replaceBannerText',
                ),
            }),
            [t],
        );
        return (
            <ImageUploadButtonRender
                {...props}
                addText={addBannerText}
                isAvatar={false}
                replaceText={replaceBannerText}
            />
        );
    });

export const CustomizeProfileFaithLeader: React.FC<ImageUploadButtonProps> =
    React.memo((props) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const { addPhotoText, replacePhotoText } = useMemo(
            () => ({
                addPhotoText: t(
                    'customizeProfile.bannerImageSection.addPhotoText',
                ),
                replacePhotoText: t(
                    'customizeProfile.bannerImageSection.replacePhotoText',
                ),
            }),
            [t],
        );
        return (
            <ImageUploadButtonRender
                {...props}
                addText={addPhotoText}
                id={props.id}
                isAvatar={true}
                replaceText={replacePhotoText}
            />
        );
    });

export const CustomizeProfileOrganizationLogo: React.FC<ImageUploadButtonProps> =
    React.memo((props) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const { addLogoText, replaceLogoText } = useMemo(
            () => ({
                addLogoText: t(
                    'customizeProfile.bannerImageSection.addLogoText',
                ),
                replaceLogoText: t(
                    'customizeProfile.bannerImageSection.replaceLogoText',
                ),
            }),
            [t],
        );
        return (
            <ImageUploadButtonRender
                {...props}
                addText={addLogoText}
                isAvatar={false}
                replaceText={replaceLogoText}
            />
        );
    });
