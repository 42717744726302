import React from 'react';
import {
    Autocomplete,
    FilterOptionsState,
    createFilterOptions,
} from '@mui/material';
import { SelectIcon } from '../assets';
import { StyledTextField } from './style';
import { AutocompleteOption, GivelifyAutocompleteProps } from './types';

const ListItem: React.FC<
    React.HTMLAttributes<HTMLLIElement> & { label: string }
> = ({ label, ...props }) => {
    return (
        <li {...props} style={{ padding: '16px' }}>
            {label}
        </li>
    );
};

const filter = createFilterOptions<AutocompleteOption>();

export const GivelifyAutocomplete: React.FC<GivelifyAutocompleteProps> = ({
    allowCustomInput,
    maxLength = 255,
    ...props
}) => {
    const filterOptions = (
        options: AutocompleteOption[],
        params: FilterOptionsState<AutocompleteOption>,
    ) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) =>
            typeof option === 'string'
                ? inputValue === option
                : inputValue === option.label,
        );
        if (
            inputValue.length >= 2 &&
            inputValue.length <= maxLength &&
            !isExisting &&
            allowCustomInput
        ) {
            filtered.push({
                value: '0',
                label: inputValue,
            });
        }
        return filtered;
    };

    return (
        <Autocomplete
            {...props}
            clearOnBlur={allowCustomInput}
            filterOptions={filterOptions}
            // MUI Autocomplete component requires weird properties in order to enable `freeSolo` setting
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            freeSolo={allowCustomInput}
            getOptionKey={(option) =>
                typeof option === 'string' ? option : option.value
            }
            options={props.options}
            popupIcon={<SelectIcon />}
            renderInput={(renderParams) => (
                <StyledTextField
                    {...renderParams}
                    InputLabelProps={{
                        shrink: !!renderParams.inputProps.value,
                    }}
                    label={props.label}
                    maxLength={maxLength}
                    size="large"
                    state={props.state}
                    value={props.value?.label}
                />
            )}
            renderOption={(renderOptionProps, option) => {
                return (
                    <ListItem
                        key={option.value}
                        label={option.label}
                        {...renderOptionProps}
                    />
                );
            }}
            selectOnFocus={allowCustomInput}
            value={props.value}
        />
    );
};
