import React, { useEffect } from 'react';
import { GivelifyTextDivider, GivelifyLoading } from '@givelify/givelify-ui';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useInvokeApi } from '../../../api/useInvokeApi';
import Label from '../../../components/Label';
import Link from '../../../components/Label/Link';
import { GiveNowButtonTypes } from '../../../types';
import { I18N_NAMESPACE } from '../../../types/const';
import { GIVELIFY_BUTTON_PUBLIC_PAGE_Loaded } from '../../../utils/cleverTapEvents';
import { pushEvent } from '../../../utils/cleverTapUtils';
import { decodeDoneeId } from '../../../utils/donee';
import { useTranslations } from '../../../utils/translations';
import useCustomModal from '../Modals/useCustomModal';
import useInstructModal from '../Modals/useInstructModal';
import { ButtonPreview } from './ButtonPreview';
import Header from './Header';
import { usePageStyle } from './style';

type GetGivingLinkResponse = {
    givingLink: string;
    givingLinkShort: string;
};

export const GivelifyButtonPublicPage: React.FC = () => {
    const cs = usePageStyle();
    const navigate = useNavigate();
    const [givingLink, setGivingLink] = React.useState('');
    const { encodedDoneeId } = useParams<{ encodedDoneeId: string }>();
    const [doneeId, setDoneeId] = React.useState<number>();

    const { givelifyButton: gb } = useTranslations('onlineGiving', {
        givelifyButton: [
            'title',
            'publicPageDesc',
            'forDevs',
            'preview',
            'lightGive',
            'primaryGive',
            'darkGive',
            'needHelp',
            'copyCode',
        ],
    });

    React.useEffect(() => {
        pushEvent(GIVELIFY_BUTTON_PUBLIC_PAGE_Loaded);
    }, []);

    const [requestState, makeRequest] = useInvokeApi<GetGivingLinkResponse>({
        isPublicApi: true,
    });

    React.useEffect(() => {
        if (doneeId) {
            makeRequest('GET', `/donees/${doneeId}/giving-link`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doneeId]);

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            setGivingLink(requestState.data.givingLinkShort);
        }
    }, [requestState]);

    React.useEffect(() => {
        try {
            const doneeId = decodeDoneeId(encodedDoneeId || '');
            setDoneeId(doneeId);
        } catch (e) {
            navigate('/not-found');
        }
    }, [encodedDoneeId, navigate]);

    const [openCustomModal, CustomModal] = useCustomModal();
    const [openInstructModal, InstructModal] = useInstructModal();

    if (!givingLink) return <GivelifyLoading type="linear" />;

    return (
        <>
            <Header />
            <CustomModal />
            <InstructModal givingLink={givingLink} />
            <div className={cs.ppContainer}>
                <Label
                    className={cs.ppHeading}
                    data-testid="for-devs-title"
                    variant="heading1"
                >
                    {gb.forDevs}
                </Label>
                <div className={cs.container}>
                    <div>
                        <Label variant="heading4">{gb.title}</Label>
                        <Label className={cs.givelifyBtnDesc} variant="body1">
                            <Trans
                                i18nKey="onlineGiving.givelifyButton.desc"
                                ns={I18N_NAMESPACE}
                            >
                                Copy and paste the code into your HTML code. If
                                you use a website builder like Wix or
                                Squarespace
                                <Link onClick={openInstructModal}>
                                    click here
                                </Link>
                                for instructions.
                            </Trans>
                        </Label>
                        <Link onClick={openCustomModal}>{gb.needHelp}</Link>
                    </div>

                    {givingLink
                        ? GiveNowButtonTypes.map((type) => (
                              <div key={type} data-testid={`${type}-button`}>
                                  <GivelifyTextDivider
                                      className={cs.divider}
                                      text=""
                                  />
                                  <ButtonPreview
                                      givingLink={givingLink}
                                      type={type}
                                  />
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </>
    );
};
