import React, { useMemo } from 'react';
import {
    FacebookLoading,
    GivelifyLabel,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName } from '@givelify/utils';
import { Grid } from '@material-ui/core';
import { Donor } from '../../../../../../../types/donorTypes';
import { addPhoneNumberFormatting } from '../../../../../../editProfile/components/EditProfileForm';
import { unMatchDonorTabStyle } from '../style';

interface DonorCurrentInfoProps {
    donor: Donor;
    isLoading: boolean;
}

const Loading: JSX.Element = (
    <FacebookLoading
        borderRadius={0}
        height={68}
        marginBottom={24}
        width="100%"
    />
);

const AutoIntegrationDonorCurrentInfo: React.FC<DonorCurrentInfoProps> = ({
    donor,
    isLoading,
}) => {
    const classes = unMatchDonorTabStyle();
    const { t } = useAdvancedTranslation();

    const { noInfo } = useMemo(
        () => ({
            noInfo: t('f1DonorPage.noDonorInfo'),
        }),
        [t],
    );

    if (isLoading) {
        return Loading;
    }

    if (donor.id === 0) {
        return (
            <Grid container alignItems="center" className={classes.donorInfo}>
                <GivelifyLabel text={noInfo} />
            </Grid>
        );
    }

    return (
        <Grid container alignItems="center" className={classes.donorInfo}>
            <Grid item className={classes.rowItem} md={1} xs={2}>
                <GivelifyAvatar
                    color="eucalyptus"
                    size="small"
                    src={donor?.picture}
                    text={getInitialsFromName(donor.name)}
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={5}>
                <GivelifyLabel
                    bold
                    className={classes.textOverflow}
                    text={donor?.name}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={3} xs={5}>
                <GivelifyLabel
                    className={classes.textOverflow}
                    text={donor?.email}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={6}>
                <GivelifyLabel
                    text={addPhoneNumberFormatting(donor?.phone)}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} xs={6}>
                <GivelifyLabel
                    className={classes.donorAddress}
                    text={donor?.address}
                    variant="body2"
                />
            </Grid>
            <Grid item className={classes.rowItem} md={2} />
        </Grid>
    );
};

export default AutoIntegrationDonorCurrentInfo;
