import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgIntegrations = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>integrations</title>
        <path
            clipRule="evenodd"
            d="M3 8.99902C3 5.68531 5.68629 2.99902 9 2.99902C11.7966 2.99902 14.1463 4.91233 14.8116 7.50135C12.1801 7.56624 9.88486 8.98655 8.59767 11.0905C8.59613 11.093 8.59461 11.0955 8.59311 11.098C7.93016 12.1845 7.53581 13.4528 7.50232 14.8106C4.9133 14.1453 3 11.7956 3 8.99902ZM9.43706 12.7467C9.15522 13.4422 9 14.2025 9 14.999C9.79655 14.999 10.5568 14.8438 11.2523 14.562L9.43706 12.7467ZM12.6063 13.7947C13.0562 13.4559 13.4569 13.0552 13.7957 12.6053L11.3937 10.2033C10.9438 10.5421 10.5432 10.9428 10.2043 11.3927L12.6063 13.7947ZM7.62564 16.3734C4.13988 15.7278 1.5 12.6717 1.5 8.99902C1.5 4.85689 4.85786 1.49902 9 1.49902C12.6727 1.49902 15.7288 4.1389 16.3744 7.62467C19.8601 8.27021 22.5 11.3263 22.5 14.999C22.5 19.1412 19.1421 22.499 15 22.499C11.3273 22.499 8.27119 19.8591 7.62564 16.3734ZM16.4977 9.18745C19.0867 9.85271 21 12.2024 21 14.999C21 18.3127 18.3137 20.999 15 20.999C12.2034 20.999 9.85369 19.0857 9.18843 16.4967C13.1809 16.3983 16.3992 13.1799 16.4977 9.18745ZM14.563 11.2512L12.7478 9.43606C13.4432 9.15423 14.2035 8.99902 15 8.99902C15 9.79554 14.8448 10.5558 14.563 11.2512Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const IntegrationsIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgIntegrations}
        fontSize={fontSize}
    />
);
