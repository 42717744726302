import { useCallback } from 'react';
import { axiosClient } from '@givelify/givelify-ui';
import { Method, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useConnectorState } from '../provider/Connector';
import { I18N_NAMESPACE } from '../types/const';
import {
  useRequestState,
  requestError,
  requestStart,
  requestSuccess,
  handleErrorMessages,
  ErrorResponse,
} from './useRequestState';

interface Props<Response> {
  isPublicApi?: boolean;
  onSuccess?: (T: Response) => void;
  onError?: (T: ErrorResponse) => void;
}

/**
 * Hook returns a function that can be used to make requests at a later time
 * (Such as in response to user interactions)
 *
 * @param {string} url - endpoint for the request
 * @param {Method} method - Http request method
 * @param {T} data
 */
export const useInvokeApi = <Response, Request = unknown>({
  isPublicApi,
  onSuccess,
  onError,
}: Props<Response>) => {
  const { t } = useTranslation(I18N_NAMESPACE);
  const { accessToken, reduxActions } = useConnectorState(isPublicApi);
  const [requestState, setRequestState] = useRequestState<Response>();

  const makeRequest = useCallback(
    (method: Method, url: string, data?: Request) => {
      setRequestState(requestStart());
      axiosClient(url, {
        method,
        data,
        headers: {
          Authorization: accessToken,
        },
      })
        .then((result: AxiosResponse<Response>) => {
          setRequestState(requestSuccess(result.data));
          if (onSuccess) onSuccess(result.data);
        })
        .catch(e => {
          if (e.response && e.response.status === 401) {
            if (accessToken) {
              const { logout, removeAccessToken } = reduxActions;
              if (removeAccessToken) removeAccessToken();
              if (logout) logout();
            }
          }

          const errorResponse = handleErrorMessages(e, t);
          setRequestState(requestError(errorResponse));
          if (onError) onError(errorResponse);
        });
    },
    [setRequestState, t, accessToken, reduxActions, onSuccess, onError]
  );
  return [requestState, makeRequest] as const;
};
