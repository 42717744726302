import React from 'react';
import { useTranslation } from 'react-i18next';
import OverviewPaper from '../components/OverviewPaper';
import TotalDonations from './TotalDonations';
import TotalDonors from './TotalDonors';

const CardsGrid: React.FC = () => {
    const { t } = useTranslation();
    const copy = {
        totalDonationsTitle: t('dashboard.totalDonations.title'),
        totalDonorsTitle: t('dashboard.totalDonors.title'),
        averageDonationsTitle: t('dashboard.averageDonation.title'),
    };
    return (
        <>
            <OverviewPaper title={copy.totalDonationsTitle}>
                <TotalDonations />
            </OverviewPaper>

            <OverviewPaper title={copy.totalDonorsTitle}>
                <TotalDonors />
            </OverviewPaper>
        </>
    );
};

export default CardsGrid;
