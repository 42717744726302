import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgByDonorFill = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={42}
        width={35}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 35 42"
    >
        <path
            d="M17.2265 20.4474C11.7894 20.4474 7.38279 15.9944 7.38279 10.5001C7.38279 5.00571 11.7894 0.552734 17.2265 0.552734C22.6637 0.552734 27.0702 5.00571 27.0702 10.5001C27.0702 15.9944 22.6637 20.4474 17.2265 20.4474ZM24.1171 22.9343C29.8234 22.9343 34.453 27.6126 34.453 33.379V36.6119C34.453 38.6713 32.7996 40.3421 30.7616 40.3421H3.6914C1.65344 40.3421 0 38.6713 0 36.6119V33.379C0 27.6126 4.62964 22.9343 10.3359 22.9343H11.6202C13.3352 23.727 15.227 24.1777 17.2265 24.1777C19.226 24.1777 21.1256 23.727 22.8329 22.9343H24.1171Z"
            fill="current"
        />
    </svg>
);

export const ByDonorFillIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgByDonorFill}
        fontSize={fontSize}
    />
);
