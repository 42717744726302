import { Collapse, styled } from '@mui/material';

export const DonationSummaryResultWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));

export const DonationSummaryCollapse = styled(Collapse)({
    width: '100%',
});
