import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDataExports = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={43}
        width={38}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 38 43"
    >
        <path
            d="M34.3226 0C36.1 0 38 1.88128 38 3.58333V39.4167C38 41.1187 36.1 43 34.3226 43H3.67742C1.90003 43 0 41.1187 0 39.4167V3.58333C0 1.88128 1.90003 0 3.67742 0H34.3226ZM9.80645 37.0278V33.4444C9.80645 32.8473 9.3161 32.25 8.58064 32.25H6.12903C5.39358 32.25 4.90323 32.8473 4.90323 33.4444V37.0278C4.90323 37.625 5.39358 38.2222 6.12903 38.2222H8.58064C9.3161 38.2222 9.80645 37.625 9.80645 37.0278ZM9.80645 26.2778V22.6944C9.80645 22.0973 9.3161 21.5 8.58064 21.5H6.12903C5.39358 21.5 4.90323 22.0973 4.90323 22.6944V26.2778C4.90323 26.875 5.39358 27.4722 6.12903 27.4722H8.58064C9.3161 27.4722 9.80645 26.875 9.80645 26.2778ZM20.8387 37.0278V33.4444C20.8387 32.8473 20.3484 32.25 19.6129 32.25H17.1613C16.4258 32.25 15.9355 32.8473 15.9355 33.4444V37.0278C15.9355 37.625 16.4258 38.2222 17.1613 38.2222H19.6129C20.3484 38.2222 20.8387 37.625 20.8387 37.0278ZM20.8387 26.2778V22.6944C20.8387 22.0973 20.3484 21.5 19.6129 21.5H17.1613C16.4258 21.5 15.9355 22.0973 15.9355 22.6944V26.2778C15.9355 26.875 16.4258 27.4722 17.1613 27.4722H19.6129C20.3484 27.4722 20.8387 26.875 20.8387 26.2778ZM31.871 37.0278V22.6944C31.871 22.0574 31.3806 21.5 30.6452 21.5H28.1935C27.4581 21.5 26.9677 22.0574 26.9677 22.6944V37.0278C26.9677 37.6648 27.4581 38.2222 28.1935 38.2222H30.6452C31.3806 38.2222 31.871 37.6648 31.871 37.0278ZM31.871 14.3333V5.97222C31.871 5.31531 31.3316 4.77778 30.6452 4.77778H6.12903C5.44261 4.77778 4.90323 5.31531 4.90323 5.97222V14.3333C4.90323 14.9902 5.44261 15.5278 6.12903 15.5278H30.6452C31.3316 15.5278 31.871 14.9902 31.871 14.3333Z"
            fill="current"
        />
    </svg>
);

export const DataExportsIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDataExports}
        fontSize={fontSize}
    />
);
