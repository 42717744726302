import React, { useEffect, useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyNotification,
    GivelifyTextField,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import * as yup from 'yup';
import { useCCBSettingStyles } from '../../../IntegrationSettings/F1GoAndCCBIntegrationSettings';
import { CCBCredentialUpdate } from '../../types';
import { updateCcbCredential } from '../automatedIntegrationRequest';

interface UpdateCCBFormProps {
    defaultUrl: string;
    defaultName: string;
    loading: boolean;
    isReadOnly: boolean;
}

interface FormData {
    url: string;
    username: string;
    ccbPassword: string;
}

const UpdateCCBForm: React.FC<UpdateCCBFormProps> = ({
    defaultUrl,
    defaultName,
    loading,
    isReadOnly,
}) => {
    const { userDoneeId } = useSelector((state: AppState) => ({
        userDoneeId: state.User.user.doneeId,
    }));
    const classes = useCCBSettingStyles();

    const TRANSLATION_KEY = 'pages.integrations.settings.ccb';
    const { t, scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: TRANSLATION_KEY,
    });
    const text = useMemo(
        () => ({
            updateCredentials: scopedTranslate('updateCredentials'),
            url: scopedTranslate('url'),
            username: scopedTranslate('username'),
            password: scopedTranslate('password'),
            updateSuccessful: scopedTranslate('updateSuccessful'),
            urlRequired: t(
                'pages.integrations.content.setup-dialog.ccb.screen2.urlRequired',
            ),
            usernameRequired: t(
                'pages.integrations.content.setup-dialog.ccb.screen2.usernameRequired',
            ),
            passwordRequired: t(
                'pages.integrations.content.setup-dialog.ccb.screen2.passwordRequired',
            ),
        }),
        [t, scopedTranslate],
    );

    const form = useForm<FormData>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true,
        resolver: yupResolver(
            yup.object<Record<keyof FormData, yup.AnySchema>>({
                url: yup.string().required(text.urlRequired),
                username: yup.string().required(text.usernameRequired),
                ccbPassword: yup.string().required(text.passwordRequired),
            }),
        ),
        defaultValues: useMemo(
            () => ({
                url: defaultUrl,
                username: defaultName,
                ccbPassword: '',
            }),
            [defaultUrl, defaultName],
        ),
    });
    const { errors, control } = form;

    const [requestUpdateCredential, makeRequestUpdateCredential] =
        useApiRequest();
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const handleSubmit = () => {
        const formValues = form.getValues();
        setMessage('');
        const data: CCBCredentialUpdate = {
            credentials: {
                url: formValues.url,
                username: formValues.username,
                password: formValues.ccbPassword,
            },
        };
        makeRequestUpdateCredential(updateCcbCredential(userDoneeId, data));
        setBtnLoading(true);
    };
    useEffect(() => {
        if (requestUpdateCredential.type === 'REQUEST_SUCCESS') {
            setBtnLoading(false);
        } else if (requestUpdateCredential.type === 'REQUEST_ERROR') {
            setBtnLoading(false);
            setMessage(requestUpdateCredential.error.message);
        }
    }, [requestUpdateCredential]);
    const [pass, setPass] = useState<string>('');
    const changePass = (value) => setPass(value);

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <GivelifyNotification
                className={classes.errorNotification}
                show={requestUpdateCredential.type === 'REQUEST_SUCCESS'}
                text={text.updateSuccessful}
                variant="success"
            />
            <GivelifyNotification
                className={classes.errorNotification}
                show={requestUpdateCredential.type === 'REQUEST_ERROR'}
                text={message}
                variant="error"
            />
            <Controller
                control={control}
                name="url"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        ariaLabel="url"
                        defaultValue={defaultUrl}
                        id="url"
                        inputRef={ref}
                        label={text.url}
                        leftHelperText={errors.url?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        state={errors.url ? 'error' : 'normal'}
                        value={value}
                    />
                )}
            />
            <Controller
                control={control}
                name="username"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        ariaLabel="username"
                        defaultValue={defaultName}
                        id="username"
                        inputRef={ref}
                        label={text.username}
                        leftHelperText={errors.username?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                        }}
                        state={errors.username ? 'error' : 'normal'}
                        value={value}
                    />
                )}
            />
            <Controller
                control={control}
                name="ccbPassword"
                render={({ name, onBlur, value, onChange, ref }) => (
                    <GivelifyTextField
                        fakeFocus
                        ariaLabel="ccbPassword"
                        className={classes.passwordField}
                        classes={{
                            labelFocusedRoot: 'labelFocusedRoot',
                            labelFocused: 'labelFocused',
                        }}
                        id="ccbPassword"
                        inputRef={ref}
                        label={text.password}
                        leftHelperText={errors.ccbPassword?.message}
                        marginBottom={32}
                        name={name}
                        onBlur={onBlur}
                        onChange={(value) => {
                            onChange(value);
                            changePass(value);
                        }}
                        placeholder="********"
                        state={errors.ccbPassword ? 'error' : 'normal'}
                        type="password"
                        value={value}
                    />
                )}
            />
            <Box className={classes.buttonWrapper}>
                <GivelifyButton
                    disabled={
                        isReadOnly ||
                        Object.keys(form.errors).length > 0 ||
                        btnLoading ||
                        loading ||
                        pass.length === 0
                    }
                    isLoading={btnLoading}
                    name="submit"
                    onClick={handleSubmit}
                    size="xLarge"
                    text={text.updateCredentials}
                    type="submit"
                    variant="primary"
                />
            </Box>
        </form>
    );
};

export default UpdateCCBForm;
