import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { requestInit, useApiRequest } from '@givelify/givelify-ui';
import { useTranslation } from 'react-i18next';
import { DirectDepositInfo } from '../@types/assets/onboarding';
import { directDepositAPI } from '../api/requests/api';
import { I18N_NAMESPACE } from '../consts';
import { FullWidthModalOnboarding } from '../modal';
import { DirectDepositContent } from './components/DirectDepositContent';
import { DirectDepositManualEntryRef } from './components/directDepositManualEntry/DirectDepositManualEntry';

interface DirectDepositPageProps {
    doneeId: number;
    data: DirectDepositInfo;
    isOpen: boolean;
    onSubmit: (d: DirectDepositInfo) => unknown;
    onCancel: () => unknown;
    showYodlee: boolean;
}

export const DirectDepositPage: React.FC<DirectDepositPageProps> = ({
    doneeId,
    data,
    isOpen,
    onCancel,
    onSubmit,
    showYodlee,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const directDepositManualEntryRef =
        useRef<DirectDepositManualEntryRef>(null);
    const [manualEntry, setManualEntry] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(false);
    const [requestState, makeRequest, setRequestState] =
        useApiRequest<unknown>();

    const {
        headingText,
        safeAndSecureText,
        defaultModalRetryText,
        errorMessage,
    } = useMemo(
        () => ({
            headingText: t('directDeposit.heading'),
            safeAndSecureText: t('directDeposit.safeAndSecure'),
            defaultModalRetryText: t('labels.defaultModalRetryText'),
            errorMessage: t('errors.generic'),
        }),
        [t],
    );

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            onCancel();
            onSubmit({ ...data, status: 'in_progress' });
        }
        //eslint-disable-next-line
    }, [requestState.type, onCancel, onSubmit]);

    const handleSave = useCallback(() => {
        const data = directDepositManualEntryRef.current?.submit();
        if (data) {
            onSubmit(data);
            makeRequest(directDepositAPI(doneeId, data));
        }
    }, [directDepositManualEntryRef, onSubmit, doneeId, makeRequest]);

    const closeManualEntry = () => {
        setRequestState(requestInit());
        setManualEntry(false);
    };

    return (
        <FullWidthModalOnboarding
            useBackArrow
            errorMessage={
                requestState.type === 'REQUEST_ERROR'
                    ? requestState.error.message || errorMessage
                    : undefined
            }
            footer={
                !showYodlee ||
                manualEntry ||
                data.status === 'require_information'
                    ? {
                          onCancel,
                          onSubmit: handleSave,
                          isLoading: requestState.type === 'REQUEST_START',
                          disableSubmit: !isValid,
                      }
                    : undefined
            }
            heading={headingText}
            lockText={safeAndSecureText}
            name="Direct Deposit Modal"
            onClose={manualEntry ? closeManualEntry : onCancel}
            open={isOpen}
            retryMessage={
                data.status === 'require_information'
                    ? data.retryMessage || defaultModalRetryText
                    : undefined
            }
            steps={undefined}
            testId="directDepositModal"
        >
            <DirectDepositContent
                data={data}
                directDepositManualEntryRef={directDepositManualEntryRef}
                doneeId={doneeId}
                manualEntry={
                    manualEntry || data.status === 'require_information'
                }
                onSubmit={onSubmit}
                setManualEntry={setManualEntry}
                setValid={setValid}
                showYodlee={showYodlee}
            />
        </FullWidthModalOnboarding>
    );
};
