export const encodeDoneeId = (doneeId: number): string => {
    return btoa(`${doneeId}`);
};

export const decodeDoneeId = (encodedString: string): number => {
    const decodedId = Number(atob(encodedString));

    if (!isNaN(decodedId)) return decodedId;
    throw new Error('Encoded donee id is not valid');
};
