import React from 'react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import ChangePasswordModal from './ChangePasswordModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            '& .margin-bottom': {
                display: 'none',
                marginBottom: theme.spacing(4),
            },
            '& .label': {
                display: 'inline-block',
            },
            '& .label:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
            '& svg': {
                marginRight: theme.spacing(2),
            },
            '& svg path': {
                fill: theme.palette.primary.main,
            },
        },
        changePassword: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
);

type EditProfileActionsProps = {
    disabled: boolean;
};

const EditProfileActions: React.FC<EditProfileActionsProps> = ({
    disabled,
}) => {
    const { wrapper, changePassword } = useStyles();
    return (
        <div className={wrapper}>
            <div className={changePassword}>
                <FontAwesomeIcon icon={faLock} />
                <ChangePasswordModal disabled={disabled} />
            </div>
        </div>
    );
};

export default EditProfileActions;
