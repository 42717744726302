import React from 'react';
import {
    DesignTokens,
    GivelifyFormDropDown,
    GivelifyFormTextField,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { STATE_DROPDOWN_OPTIONS } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryRepAddressForm } from '../../types';
import { PrimaryRepEditorFooter } from '../editorFooter';
import { NameFields, InputsBox, Note } from '../styles';

export type AddressStepFormRef = {
    isEditing: () => boolean;
};

export type AddressStepFormProps = {
    onCancelClick: () => void;
    onBackClick: (data: PrimaryRepAddressForm) => void;
};

export const AddressStepForm = React.forwardRef<
    AddressStepFormRef,
    AddressStepFormProps
>(({ onCancelClick, onBackClick: onBack }, ref) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            placeholder1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step2.placeholder1',
            ),
            placeholder2: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step2.placeholder2',
            ),
            placeholder3: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step2.placeholder3',
            ),
            placeholder4: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step2.placeholder4',
            ),
            placeholder5: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step2.placeholder5',
            ),
            note: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.note',
            ),
        }),
        [t],
    );
    const {
        formState: { isValid, isDirty },
        getValues,
    } = useFormContext<PrimaryRepAddressForm>();
    React.useImperativeHandle(ref, () => ({
        isEditing: () => isDirty,
    }));
    const onBackClick = React.useCallback(() => {
        onBack(getValues());
    }, [getValues, onBack]);
    return (
        <>
            <InputsBox>
                <GivelifyFormTextField<PrimaryRepAddressForm>
                    fullWidth
                    aria-label="Street editor"
                    data-testid="admin-form-street"
                    id="admin-form-street"
                    label={copy.placeholder1}
                    name="street"
                    placeholder={copy.placeholder1}
                    size="large"
                />
                <GivelifyFormTextField<PrimaryRepAddressForm>
                    fullWidth
                    aria-label="City editor"
                    data-testid="admin-form-city"
                    id="admin-form-city"
                    label={copy.placeholder2}
                    name="city"
                    placeholder={copy.placeholder2}
                    size="large"
                />
                <NameFields>
                    <GivelifyFormDropDown<PrimaryRepAddressForm>
                        aria-label="State editor"
                        data-testid="admin-form-state"
                        id="admin-form-state"
                        label={copy.placeholder3}
                        name="state"
                        options={STATE_DROPDOWN_OPTIONS}
                        placeholder={copy.placeholder3}
                        shape="input"
                        size="medium"
                        variant="default"
                        width="fullwidth"
                    />
                    <GivelifyFormTextField<PrimaryRepAddressForm>
                        fullWidth
                        aria-label="Zip editor"
                        data-testid="admin-form-zip"
                        id="admin-form-zip"
                        label={copy.placeholder4}
                        name="zip"
                        placeholder={copy.placeholder4}
                        size="large"
                    />
                </NameFields>
                <GivelifyFormTextField<PrimaryRepAddressForm>
                    fullWidth
                    aria-label="Phone number editor"
                    data-testid="admin-form-phone"
                    id="admin-form-phone"
                    label={copy.placeholder5}
                    maskOptions={{ mask: '(000) 000-0000' }}
                    name="phoneNumber"
                    placeholder={copy.placeholder5}
                    size="large"
                />
            </InputsBox>
            <Note>
                <GivelifyIcon fontSize={28} variant="lock-check" />
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    variant="body2"
                >
                    {copy.note}
                </GivelifyLabel>
            </Note>
            <PrimaryRepEditorFooter
                showBackButton
                canSubmit={isValid}
                onBackClick={onBackClick}
                onCancelClick={onCancelClick}
            />
        </>
    );
});
