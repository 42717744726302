import { useFilterParams } from 'api/hooks';
import { MESSAGE_FILTER_VALUE, messageFilterOptions } from './types';

const paramNames = [
    'no_messages',
    'replied_messages',
    'un_replied_messages',
] as const;

export const useMessageFilter = (defaultState?: MESSAGE_FILTER_VALUE[]) => {
    const [filter, setFilter] = useFilterParams<
        MESSAGE_FILTER_VALUE[],
        typeof paramNames
    >(
        defaultState ? defaultState : messageFilterOptions,
        paramNames,
        (state, paramName) => {
            switch (paramName) {
                case 'no_messages':
                    return state.includes(MESSAGE_FILTER_VALUE.NO_MESSAGES)
                        ? '1'
                        : '0';
                case 'replied_messages':
                    return state.includes(MESSAGE_FILTER_VALUE.REPLIED)
                        ? '1'
                        : '0';
                case 'un_replied_messages':
                    return state.includes(MESSAGE_FILTER_VALUE.UN_REPLIED)
                        ? '1'
                        : '0';
                default:
                    return '';
            }
        },
        (values) => {
            const selectedParams: MESSAGE_FILTER_VALUE[] = [];
            if (values['no_messages'] && values['no_messages'] === '1') {
                selectedParams.push(MESSAGE_FILTER_VALUE.NO_MESSAGES);
            }
            if (
                values['replied_messages'] &&
                values['replied_messages'] === '1'
            ) {
                selectedParams.push(MESSAGE_FILTER_VALUE.REPLIED);
            }
            if (
                values['un_replied_messages'] &&
                values['un_replied_messages'] === '1'
            ) {
                selectedParams.push(MESSAGE_FILTER_VALUE.UN_REPLIED);
            }
            return selectedParams;
        },
    );
    return [filter, setFilter] as const;
};
