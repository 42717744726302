import { styled } from '@mui/material';
import { GivelifyButton, GivelifyPaper } from '../';
import SinglePollsBg from '../assets/images/SinglePollsBg.svg';
import { DesignTokens } from '../specify';

export const PollWrapper = styled(GivelifyPaper, {
    shouldForwardProp: (prop) => prop !== 'showBgImg',
})<{
    showBgImg: boolean;
}>(({ showBgImg }) => ({
    padding: '24px 24px 24px 72px',
    backgroundImage: showBgImg ? `url(${SinglePollsBg})` : '',
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundColor: DesignTokens.color.globalGuidingViolet100,
}));

export const PollHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
}));
export const CollapseWrapper = styled('div')({
    marginTop: '12px',
});

export const RightInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
}));
export const IconWrapper = styled('div')({
    position: 'absolute',
    left: 0,
    transform: 'translateX(calc(-100% - 16px))',
});
export const CollapseButton = styled(GivelifyButton)({
    background: 'none',
    padding: 0,
    minWidth: 'auto',
});
export const QuestionsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    gap: '16px',
    marginTop: '16px',
});
export const AnswersWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    gap: '16px',
    marginTop: '16px',
});
export const QuestionButton = styled(GivelifyButton)({
    background: DesignTokens.color.textWhite,
    boxShadow: DesignTokens.shadow.shadowMediumHigh,
});

export const Divider = styled('div')({
    height: '32px',
    width: '1px',
    background: DesignTokens.color.dividerPrimaryLight,
});

export const AnswerText = styled('div')({
    overflow: 'hidden',
    borderRadius: '24px',
    height: '40px',
    backgroundColor: DesignTokens.color.textWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    position: 'relative',
    border: `1px solid ${DesignTokens.color.globalGuidingViolet300}`,
    gap: '8px',
});

export const AnswerTextWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    position: 'relative',
    zIndex: 2,
});

export const PercentWrapper = styled('div')({
    zIndex: 2,
});

export const FillLine = styled('div', {
    shouldForwardProp: (prop) => prop !== 'fillPercent',
})<{ fillPercent: number }>(({ fillPercent }) => ({
    width: `${fillPercent}%`,
    backgroundColor: DesignTokens.color.globalGuidingViolet200,
    position: 'absolute',
    transition: 'width 1s ease',
    left: 0,
    top: 0,
    height: '100%',
    borderRadius: '24px 0 0 24px',
    zIndex: 1,
}));
