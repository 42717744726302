import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>help</title>
        <path
            clipRule="evenodd"
            d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 7.5a2.25 2.25 0 0 1 1.078 4.225c-.414.227-.855.539-1.2.942-.35.407-.628.94-.628 1.583a.75.75 0 0 0 1.5 0c0-.186.077-.387.266-.607.192-.223.47-.43.783-.602A3.75 3.75 0 0 0 12 6a3.75 3.75 0 0 0-3.75 3.75.75.75 0 0 0 1.5 0A2.25 2.25 0 0 1 12 7.5ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const HelpIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgHelp} fontSize={fontSize} />
);
