import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { webConfig } from 'webConfig';

export const useRecaptcha = (action: 'signin' | 'recover_password') => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const getRecaptchaToken = React.useCallback(async () => {
        if (webConfig.isProd) {
            if (!executeRecaptcha) return null;
            const token = await executeRecaptcha(action);
            return token;
        } else {
            return 'qa-recaptcha-token';
        }
    }, [action, executeRecaptcha]);
    return { getRecaptchaToken };
};
