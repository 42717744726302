import { ImageWithDimensions } from '@givelify/onboarding';
import { UpdateContactInfo, UserInfo } from 'types/userTypes';
import {
    SET_ACCESS_TOKEN,
    SET_USER,
    SET_EMAIL,
    SetAccessToken,
    SetUser,
    UserState,
    SetUserEmail,
    SetUserAvatar,
    SET_USER_AVATAR,
    HIDE_CONTINUE_ON_MOBILE_SCREEN,
    UserInfoRequest,
    UserPermissions,
    ContactInfoRequest,
} from './types';

export const setAccessToken = (
    accessToken: UserState['accessToken'],
): SetAccessToken => ({
    type: SET_ACCESS_TOKEN,
    accessToken,
});

export const setUserToLocalStorage = (user: UserState['user']) => {
    if (user) {
        localStorage.setItem(SET_USER, JSON.stringify(user));
    } else {
        localStorage.removeItem(SET_USER);
    }
};

export const setUserAvatar = (avatar: ImageWithDimensions): SetUserAvatar => ({
    type: SET_USER_AVATAR,
    avatar,
});

export const setUser = (user: UserState['user']): SetUser => {
    setUserToLocalStorage(user);
    return {
        type: SET_USER,
        user,
    };
};

export const setUserEmail = (email: UserState['email']): SetUserEmail => {
    if (email) {
        localStorage.setItem(SET_EMAIL, email);
    } else {
        localStorage.removeItem(SET_EMAIL);
    }

    return {
        type: SET_EMAIL,
        email,
    };
};

export const setHideContinueOnMobileScreen = () => ({
    type: HIDE_CONTINUE_ON_MOBILE_SCREEN,
});

/* eslint-disable-next-line */
export const mapUserInfoToRequest = (
    data: UserInfo,
    isEmailSame: boolean,
): UserInfoRequest => ({
    email: isEmailSame ? undefined : data.email,
    first_name: data.firstName,
    last_name: data.lastName,
    title: data.title || undefined,
    role_id: UserPermissions[data.role],
});

export const mapContactInfoToRequest = (
    data: UpdateContactInfo
): ContactInfoRequest => ({
    first_name: data.firstName,
    last_name: data.lastName,
    title: data.title,
    email: data.email,
    is_contact_info_confirmation: true
});
