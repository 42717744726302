import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';

interface Props {
    /**
     * i18n namespace, when omitted, the default namespace specified in the i18n config will be used.
     */
    namespace?: string;
    /**
     * TRANSLATION_KEY prefix, this can be omitted
     */
    TRANSLATION_KEY?: string;
    /**
     * Donee type: 'church' | 'nonprofit'. Defaults to 'church'
     */
    doneeType?: 'church' | 'nonprofit';
}

export const useAdvancedTranslation = ({
    namespace,
    TRANSLATION_KEY,
    doneeType = 'church',
}: Props = {}) => {
    const { t, ...rest } = useTranslation(namespace);

    const at = useCallback(
        (key: Parameters<TFunction>[0], ...props) => {
            return t(`${key}.${doneeType}`, ...props);
        },
        [t, doneeType],
    );

    const scopedTranslate = useCallback(
        (key: string, args = undefined) =>
            t(TRANSLATION_KEY ? `${TRANSLATION_KEY}.${key}` : key, args),
        [t, TRANSLATION_KEY],
    );

    const scopedATranslate = useCallback(
        (key: string, args = undefined) =>
            at(TRANSLATION_KEY ? `${TRANSLATION_KEY}.${key}` : key, args),
        [at, TRANSLATION_KEY],
    );

    return useMemo(
        () => ({
            ...rest,
            t,
            at,
            scopedTranslate,
            scopedATranslate,
        }),
        [t, at, scopedTranslate, scopedATranslate, rest],
    );
}
