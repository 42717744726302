import { DesignTokens } from '@givelify/ui';
import { styled } from '@mui/material';
import { VerificationCardState } from './VerificationCard';

export const StyledVerificationCard = styled('div', {
    shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant?: VerificationCardState }>(({ theme, variant }) => ({
    maxWidth: '880px',
    padding: '24px',
    borderRadius: '10px',
    background: '#fff',
    boxShadow: DesignTokens.shadow.shadowMediumHigh,
    color: variant,

    [theme.breakpoints.down('mobile')]: {
        padding: '16px',
    },

    ...(variant === 'require_information' && {
        border: `1px solid ${DesignTokens.color.borderComponentWarning}`,
    }),

    '& .head-wrapper': {
        display: 'flex',
    },

    '& .heading': {
        fontSize: '24px',
        fontWeight: 700,
        color: DesignTokens.color.textPrimary,
        lineHeight: '32px',
        [theme.breakpoints.down('tablet')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.down('mobile')]: {
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 800
        },
    },

    '& .divider': {
        display: 'inline-block',
        border: `1px solid ${DesignTokens.color.dividerPrimaryDark}`,
        margin: '0 16px',
    },

    '& .status-wrapper': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        lineHeight: '32px',
        color: DesignTokens.color.iconPrimary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '12px',
            lineHeight: '26px',
        },
    },

    '& .status': {
        fontSize: '18px',
        fontWeight: 700,

        [theme.breakpoints.down('tablet')]: {
            fontSize: '14px',
        },
    },

    '& .status-value': {
        '&.in_progress': {
            color: DesignTokens.color.textInfoDefault,
        },
        '&.verified': {
            color: DesignTokens.color.textSuccessDefault,
        },
        '&.require_information': {
            color: DesignTokens.color.textWarningDefault,
        },
    },

    '& .reminderIcon': {
        marginRight: '8px',
        fontSize: '24px',
        lineHeight: '32px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: '18px',
            lineHeight: '24px',
        },
    },

    '& .description': {
        display: 'block',
        maxWidth: '460px',
        fontSize: '14px',
        fontWeight: 600,
        color: DesignTokens.color.textSecondary,
        marginTop: '8px',
        marginBottom: '32px',

        [theme.breakpoints.down('tablet')]: {
            fontSize: '12px',
            maxWidth: '400px',
        },
    },

    '& .iconSection': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '40px',

        '& .cardIcon': {
            fontSize: '72px',
        },
    },

    '& .iconWrapper': {
        position: 'relative',

        '& .statusIcon': {
            fontSize: '38px',
            position: 'absolute',
            left: '-18px',
            bottom: '-12px',
        },
    },

    '& .warning-description': {
        fontSize: '16px',
        color: DesignTokens.color.textWarningDefault,

        [theme.breakpoints.down('tablet')]: {
            fontSize: '12px',
        },
    },

    '& .action-needed-warning': {
        fontSize: '32px',
        marginRight: '8px',

        [theme.breakpoints.down('tablet')]: {
            fontSize: '28px',
        },
    },

    '& .verification-action': {
        minWidth: '256px',
    },

    '& .submitted-on': {
        display: 'inline-block',
        fontSize: '12px',
        fontWeight: 600,
        padding: '4px 12px',
        borderRadius: '4px',
        background: DesignTokens.color.globalNeutral100,
        color: DesignTokens.color.textSecondary,
    },

    '& .mobile-card-icon-wrapper': {
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
    },

    '& .mobile-card-icon': {
        fontSize: '48px',
        marginRight: '16px',
    },
}));
