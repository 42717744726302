import { BUTTON_IDENTIFIER, INPUT_IDENTIFIER } from './trackingConstants';

export const DATEPICKER_DAY_CLICK = `<Day selection>_${BUTTON_IDENTIFIER}`;

export const DATEPICKER_PREV_MONTH_CLICK = `<Previous month>_${BUTTON_IDENTIFIER}`;

export const DATEPICKER_NEXT_MONTH_CLICK = `<Next month>_${BUTTON_IDENTIFIER}`;

export const DATEPICKER_YEAR_SELECTION_CLICK = `<Year selection>_${BUTTON_IDENTIFIER}`;

export const DATEPICKER_DATE_PRESET_ITEM_CLICK = `<Date preset item>_${BUTTON_IDENTIFIER}`;

export const DATEPICKER_START_DATE_CLICK = `<Start date>_${INPUT_IDENTIFIER}`;

export const DATEPICKER_END_DATE_CLICK = `<End date>_${INPUT_IDENTIFIER}`;
