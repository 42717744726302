import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';

type GivelifyMobilePreviewStyleProps = GivelifyBoxMarginProps & {
    scale?: number;
};

export const GivelifyMobilePreviewStyle = makeStyles((theme: Theme) =>
    createStyles({
        boxMargin: {
            marginLeft: (props: GivelifyMobilePreviewStyleProps) =>
                props.marginLeft ? props.marginLeft : props.margin,
            marginTop: (props: GivelifyMobilePreviewStyleProps) =>
                props.marginTop ? props.marginTop : props.margin,
            marginRight: (props: GivelifyMobilePreviewStyleProps) =>
                props.marginRight ? props.marginRight : props.margin,
            marginBottom: (props: GivelifyMobilePreviewStyleProps) =>
                props.marginBottom ? props.marginBottom : props.margin,
        },
        mobilePreviewBase: {
            boxSizing: 'border-box',
            width: 407,
            height: 699,
            overflow: 'hidden',
            backgroundColor: 'rgba(228, 227, 232, 0.5)',
            position: 'relative',
            display: 'inline-block',
            textAlign: 'left',
            borderRadius: 50,
            boxShadow: theme.gas.elevation.high,
            paddingTop: 14.47,
            paddingBottom: 17.53,
            paddingLeft: 16,
            paddingRight: 16,
            transform: (props: GivelifyMobilePreviewStyleProps) =>
                props.scale ? `scale(${props.scale})` : 'initial',
            zIndex: 1,
        },
        mobilePreviewContent: {
            height: 667,
            backgroundColor: theme.gas.palette.background.primary,
            overflow: 'hidden',
            borderRadius: 50,
            position: 'relative',
        },
    }),
);
