import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    isFailed,
    isLoading,
    isSucceeded,
    GivelifyLabelStyles,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { mergeClassNames, useTrackingContext } from '@givelify/utils';
import { makeStyles, Popper, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { DONATIONS_PAGE_CLICK_SEND_MESSAGE_SBMT } from 'utils/clevertapEvents';
import LoadingBar from '../../../../components/system/LoadingBar';
import { useInvokeApi } from '../../../../hooks/useInvokeApi';
import { AppState } from '../../../../store';
import {
    MessengerTextFieldProps,
    ENTER_KEY_CODE,
    replies,
} from '../../utils/messengerTextFieldUtils';
import { messengerStyles } from '../style';

const MAX_LENGTH = 255;

const useStyles = makeStyles((theme: Theme) => ({
    primary: {
        color: theme.colors.primaryDarkGray,
    },
    hint: {
        marginTop: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    popper: {
        maxWidth: 400,
    },
}));

const getTextWidth = (text: string, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
};

const MessengerTextField: React.FC<MessengerTextFieldProps> = ({
    donationNoteId,
    onPressEnter,
}) => {
    const { primary, hint, popper } = useStyles();
    const { small } = GivelifyLabelStyles({});
    const { trackEvent } = useTrackingContext();
    const TRANSLATION_KEY = 'donationsActivity.messages';
    const { t, scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: TRANSLATION_KEY,
    });
    const copy = useMemo(
        () => ({
            sendMessage: scopedTranslate('sendMessage'),
            enter: scopedTranslate('enter'),
            toSend: scopedTranslate('toSend'),
            shiftEnter: scopedTranslate('shiftEnter'),
            addNem: scopedTranslate('addNew'),
            postFailed: t('error.messagePostFailed'),
        }),
        [t, scopedTranslate],
    );

    const repliesTranslated = useMemo(() => replies.map((r) => t(r)), [t]);

    const userId = useSelector((state: AppState) => state.User.user.id);
    const { quickReplyStyles, listReply, replyItem } = messengerStyles();

    const [value, setValue] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>('');

    const [requestState, makeRequest] = useInvokeApi();

    useEffect(() => {
        if (isSucceeded(requestState)) {
            onPressEnter(inputValue);
            setValue(null);
            setInputValue('');
        }
    }, [requestState, onPressEnter, inputValue]);

    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            !e.shiftKey &&
            e.which === ENTER_KEY_CODE &&
            inputValue.length > 0
        ) {
            e.preventDefault();
            trackEvent(DONATIONS_PAGE_CLICK_SEND_MESSAGE_SBMT);
            makeRequest('PATCH', `notes/${donationNoteId}`, {
                reply: inputValue,
                user_id: userId,
            });
        }
    };

    const onChange = (_, option: string) => setValue(option);
    const onInputChange = (_, option: string) => setInputValue(option || '');
    const renderOption = (option: string) => (
        <div className={replyItem}>{option}</div>
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const width = useMemo(() => {
        if (!inputRef.current) return 0;

        const messageInput = inputRef.current.querySelector(
            '[data-messageinput="true"]',
        );
        const fontStyle = getComputedStyle(messageInput).font;
        const res = getTextWidth(inputValue, fontStyle);

        return res;
    }, [inputValue]);

    const Input = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            multiline
            className="text-field"
            fullWidth={false}
            inputProps={{
                ...params.inputProps,
                style: {
                    width,
                },
                maxLength: MAX_LENGTH,
                'data-messageinput': true,
            }}
            maxRows={4}
            minRows={1}
            name={'123'}
            onKeyPress={onKeyPress}
            placeholder={copy.sendMessage}
            variant="outlined"
        />
    );
    const classes = {
        listbox: listReply,
    };

    return (
        <>
            <Autocomplete
                ref={inputRef}
                autoComplete
                freeSolo
                PopperComponent={(props) => (
                    <Popper
                        {...props}
                        className={popper}
                        placement="bottom-start"
                    />
                )}
                className={quickReplyStyles}
                classes={classes}
                disableClearable={inputValue.length === 0}
                inputValue={inputValue}
                onChange={onChange}
                onInputChange={onInputChange}
                options={repliesTranslated}
                renderInput={Input}
                renderOption={renderOption}
                value={value}
            />
            {isLoading(requestState) && <LoadingBar show={true} />}
            {isFailed(requestState) && (
                <span className="error">{copy.postFailed}</span>
            )}
            <div className={mergeClassNames(small, hint)}>
                <div>
                    <span className={primary}>{copy.enter}</span>
                    {` ${copy.toSend} `}
                    <span className={primary}>{copy.shiftEnter}</span>
                    {` ${copy.addNem}`}
                </div>
                <div
                    className={primary}
                >{`${inputValue.length}/${MAX_LENGTH}`}</div>
            </div>
        </>
    );
};

export default MessengerTextField;
