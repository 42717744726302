import React, { ButtonHTMLAttributes } from 'react';
import { mergeClassNames } from '@givelify/utils';
import { styled } from '@mui/material';
import { SelectIcon } from '../assets';
import { DesignTokens } from '../specify';

export type GivelifyCapsuleProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    text?: string;
    active?: boolean;
    width?: 'auto' | 'fullwidth';
    variant?: 'default' | 'input';
};

const CapsuleRoot = styled('button', {
    shouldForwardProp: prop =>
        prop !== 'width' && prop !== 'active' && prop !== 'input',
})<{
    width?: 'auto' | 'fullwidth';
    active?: boolean;
    variant?: 'default' | 'input';
}>(({ width, theme, active, disabled, variant }) => ({
    width: width === 'fullwidth' ? '100%' : 'auto',
    boxSizing: 'border-box',
    padding: theme.spacing(1.5, 2.5, 1.5, 3),
    borderRadius: variant === 'default' ? theme.spacing(3) : theme.spacing(0.5),
    borderColor: DesignTokens.border.borderComponentDefault.color,
    borderStyle: DesignTokens.border.borderComponentDefault.style,
    borderWidth: DesignTokens.border.borderComponentDefault.width,
    backgroundColor: DesignTokens.color.globalNeutral50,
    cursor: 'pointer',
    color: DesignTokens.color.textPrimary,
    fontSize: DesignTokens.textStyle.globalComponentInput.font.size,
    lineHeight: `${DesignTokens.textStyle.globalComponentInput.font.lineHeight}px`,
    fontWeight: DesignTokens.textStyle.globalComponentInput.font.weight,
    boxShadow: '0 0 0 1px transparent',
    transition: 'box-shadow 0.2s ease',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: theme.spacing(1.5),
    '&:hover': {
        borderColor: DesignTokens.border.borderComponentFocused.color,
        boxShadow: `0 0 0 1px ${DesignTokens.border.borderComponentFocused.color}`,
        '.gv-cap-icn': {
            color: DesignTokens.color.textPrimary,
        },
    },
    '&:focus': {
        borderColor: DesignTokens.border.borderComponentFocused.color,
        boxShadow: `0 0 0 1px ${DesignTokens.border.borderComponentFocused.color}`,
        '.gv-cap-icn': {
            color: DesignTokens.color.textPrimary,
        },
    },
    '&:active': {
        borderColor: DesignTokens.border.borderComponentFocused.color,
        color: DesignTokens.color.textAccentHover,
        boxShadow: '0 0 0 1px transparent',
        '.gv-cap-icn': {
            color: DesignTokens.color.textPrimary,
        },
    },
    '& .gv-cap-active': {
        borderColor: DesignTokens.border.borderComponentFocused.color,
        color: DesignTokens.color.textAccentHover,
        boxShadow: '0 0 0 1px transparent',
        '.gv-cap-icn': {
            color: DesignTokens.color.textPrimary,
        },
    },
    '& .gv-cap-icn': {
        marginLeft: 'auto',
        fontSize: DesignTokens.measurement.iconDefaultSize,
        transition: 'transform 0.2s ease',
    },
    '& .gv-cap-icn-active': {
        transform: 'rotate(180deg)',
    },
    ...(active && {
        borderColor: DesignTokens.border.borderComponentFocused.color,
        color: DesignTokens.color.textAccentHover,
        boxShadow: '0 0 0 1px transparent',
        '& .gv-cap-icn': {
            marginLeft: 'auto',
            fontSize: DesignTokens.measurement.iconDefaultSize,
            transition: 'transform 0.2s ease',
            color: DesignTokens.color.textPrimary,
        },
    }),
    ...(disabled && {
        borderColor: DesignTokens.border.borderComponentDisabled.color,
        color: DesignTokens.color.textDisabled,
        boxShadow: '0 0 0 1px transparent',
        pointerEvents: 'none',
        '& .gv-cap-icn': {
            marginLeft: 'auto',
            fontSize: DesignTokens.measurement.iconDefaultSize,
            transition: 'transform 0.2s ease',
            color: DesignTokens.color.textDisabled,
        },
        '&:hover': {
            borderColor: DesignTokens.border.borderComponentDisabled.color,
            color: DesignTokens.color.textDisabled,
            '.gv-cap-icn': {
                color: DesignTokens.color.textDisabled,
            },
        },
        '&:focus': {
            borderColor: DesignTokens.border.borderComponentDisabled.color,
            color: DesignTokens.color.textDisabled,
            '.gv-cap-icn': {
                color: DesignTokens.color.textDisabled,
            },
        },
    }),
}));

export const GivelifyCapsule = React.forwardRef<
    HTMLButtonElement,
    GivelifyCapsuleProps
>(({ text, active, width = 'auto', variant = 'default', ...props }, ref) => {
    return (
        <CapsuleRoot
            {...props}
            ref={ref}
            active={active}
            variant={variant}
            width={width}
        >
            <span className="gv-cap-txt">{text}</span>
            <SelectIcon
                className={mergeClassNames(
                    'gv-cap-icn',
                    active && 'gv-cap-icn-active',
                )}
                fontSize="inherit"
            />
        </CapsuleRoot>
    );
});
