import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDonations = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>donations</title>
        <path
            clipRule="evenodd"
            d="M3.32144 12.0003C3.32144 7.20731 7.20697 3.32178 12 3.32178C16.7931 3.32178 20.6786 7.20731 20.6786 12.0003C20.6786 16.7934 16.7931 20.6789 12 20.6789C7.20697 20.6789 3.32144 16.7934 3.32144 12.0003ZM12 1.82178C6.37854 1.82178 1.82144 6.37888 1.82144 12.0003C1.82144 17.6218 6.37854 22.1789 12 22.1789C17.6215 22.1789 22.1786 17.6218 22.1786 12.0003C22.1786 6.37888 17.6215 1.82178 12 1.82178ZM12 15.8573L11.1897 17.1195L11.1873 17.118L11.1833 17.1154L11.1707 17.1073L11.1279 17.0793C11.0917 17.0555 11.0406 17.0216 10.9767 16.9785C10.8489 16.8922 10.6692 16.7685 10.4547 16.6143C10.0283 16.3077 9.45266 15.8721 8.87071 15.3641C8.29638 14.8627 7.674 14.2546 7.18343 13.5997C6.72472 12.9874 6.21432 12.117 6.21432 11.1259C6.21432 10.1241 6.60978 9.26801 7.30254 8.68734C7.95661 8.13912 8.76208 7.92871 9.48004 7.92871C10.6963 7.92871 11.5172 8.58206 12.0002 9.02492C12.4868 8.58016 13.3126 7.92871 14.52 7.92871C15.238 7.92871 16.0435 8.13912 16.6975 8.68734C17.3903 9.26801 17.7858 10.1241 17.7858 11.1259C17.7858 12.117 17.2754 12.9874 16.8167 13.5997C16.3261 14.2546 15.7037 14.8627 15.1294 15.3641C14.5474 15.8721 13.9718 16.3077 13.5453 16.6143C13.3309 16.7685 13.1512 16.8922 13.0234 16.9785C12.9595 17.0216 12.9083 17.0555 12.8722 17.0793L12.8293 17.1073L12.8168 17.1154L12.8128 17.118L12.8114 17.1189C12.8112 17.119 12.8104 17.1195 12 15.8573ZM12 15.8573C11.1897 17.1195 11.1899 17.1197 11.1897 17.1195L12 17.6398L12.8104 17.1195L12 15.8573ZM12 15.8573C12 15.8573 7.71432 13.1059 7.71432 11.1259C7.71432 9.95157 8.59718 9.42871 9.48004 9.42871C10.0917 9.42871 10.5302 9.72568 10.9269 10.0778C10.9971 10.1402 11.066 10.2042 11.1343 10.2687C11.5886 10.6973 12 11.1259 12 11.1259C12 11.1259 12.4115 10.6973 12.8658 10.2687C12.934 10.2043 13.003 10.1403 13.0735 10.0781C13.4719 9.72581 13.9155 9.42871 14.52 9.42871C15.4029 9.42871 16.2858 9.95157 16.2858 11.1259C16.2858 13.1059 12 15.8573 12 15.8573Z"
            fill="current"
            fillRule="evenodd"
        />
    </svg>
);

export const DonationsIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDonations}
        fontSize={fontSize}
    />
);
