import React from 'react';

import { GivelifyLabel } from '@givelify/givelify-ui';
import { GivelifyButton, GivelifyButtonProps } from '@givelify/givelify-ui';
import { Grid } from '@material-ui/core';

import Page from '../../components/Page';
import withDownloadable, {
    WithDownloadableProps,
} from '../../components/withDownloadable';
import Config from '../../config';
import { useConnectorState } from '../../provider/Connector';

import { useTranslations } from '../../utils/translations';
import { useStyle } from './style';

const DownloadableButton = withDownloadable<
    GivelifyButtonProps & WithDownloadableProps
>(GivelifyButton);

const PDF_FILENAME = 'Snap_to_Give_Guide.pdf';

const getQrCodeUrl = (doneeId: number, qrCode: string) => {
    return `${Config.fileServiceBaseUrl}/donee/${doneeId}/qrcode_download?file=${qrCode}`;
};

function SnapToGive() {
    const { donee, snapGiveQRCodes } = useConnectorState();
    const cs = useStyle();
    const downloadAnchorRef = React.useRef<HTMLAnchorElement>(null);

    const copy = useTranslations('snapGive', [
        'title',
        'description',
        'description2',
        'downloadNow',
        'needHelp',
        'helpInstruction',
        'downloadHelp',
    ]);

    // fileurl="https://file-service.qa.givelify.com/donee/478/qrcode_download?file=dee73326-bee3-4db2-b0ac-636e08074591.png"
    const qrCode = snapGiveQRCodes[0]?.qrFilenameOriginal;

    const handleDownloadClick = () => {
        if (downloadAnchorRef?.current) downloadAnchorRef.current.click();
    };

    return (
        <Page useDivider addHelpNote={false} title="Snap-to-Give">
            <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                    <GivelifyLabel text={copy.title} variant="heading1" />
                    <GivelifyLabel
                        marginBottom={36}
                        marginTop={36}
                        text={copy.description}
                        variant="body2"
                    />
                    <GivelifyLabel
                        marginBottom={62}
                        marginTop={36}
                        text={copy.description2}
                        variant="body2"
                    />
                    <GivelifyButton
                        className={cs.downloadNow}
                        data-testid="snap-to-give-download"
                        disabled={!qrCode}
                        name="downloadQrCode"
                        onClick={handleDownloadClick}
                        size="large"
                        text={copy.downloadNow}
                        variant="primary"
                    />
                    <a
                        ref={downloadAnchorRef}
                        className={cs.hidden}
                        data-testid="snap-to-give-download-link"
                        download="Snap2Give_QRcode"
                        href={getQrCodeUrl(donee ? donee.id : 1, qrCode)}
                    >
                        Download
                    </a>
                </Grid>
                <Grid item className={cs.qrCodeBox} md={6} sm={12} xs={12}>
                    {qrCode ? (
                        <img
                            alt="demo"
                            data-testid="snap-to-give-qr-code"
                            src={getQrCodeUrl(donee ? donee.id : 1, qrCode)}
                            width={280}
                        />
                    ) : null}
                </Grid>
            </Grid>
            <div className={cs.helpContainer}>
                <GivelifyLabel text={copy.needHelp} variant="heading2" />
                <div className={cs.helpButtonBox}>
                    <GivelifyLabel
                        text={copy.helpInstruction}
                        variant="body2"
                    />
                    <DownloadableButton
                        className={cs.downloadHelp}
                        data-testid="snap-to-give-download-help"
                        extension="pdf"
                        filename="Snap_to_Give_Guide"
                        fileurl={`${Config.imageBaseUrl}/${PDF_FILENAME}`}
                        name="downloadInstructionsPDF"
                        size="xLarge"
                        text={copy.downloadHelp}
                        variant="secondary"
                    />
                </div>
            </div>
        </Page>
    );
}

export default SnapToGive;
