import React, { useEffect, useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyColorPalette,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { useAdvancedTranslation } from 'utils/i18n';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';
import useStyles from './styles';

interface HideEnvelopeModalContentProps {
    onCloseModal: () => void;
    envelopeId: number;
}

const HideEnvelopeModalContent: React.FC<HideEnvelopeModalContentProps> = ({
    onCloseModal,
    envelopeId,
}) => {
    const {
        hideEnvelope,
        hideEnvelopeRequestState: state,
        activeEnvelopes,
    } = useEnvelopesContext();

    const { name } = useMemo(
        () => activeEnvelopes.find(e => e.id === envelopeId),
        [activeEnvelopes, envelopeId],
    );

    const { t, at } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            askToHide: at(
                'pages.settings.envelopes2.active-envelopes-tab.askToHide',
            ),
            askDesc: at(
                'pages.settings.envelopes2.active-envelopes-tab.askDesc',
            ),
            askUndo: at(
                'pages.settings.envelopes2.active-envelopes-tab.askUndo',
            ),
            cancel: t('labels.cancel'),
            hideEnvelope: at(
                'pages.settings.envelopes2.active-envelopes-tab.hideEnvelope',
            ),
        }),
        [t, at],
    );
    const {
        modalTitle,
        switchImage,
        switchArrowImg,
        switchContent,
        switchTitle,
        switchBottom,
        hideEnvelopeBtn,
        cancelBtn,
    } = useStyles();

    const inProgress = state === 'REQUEST_START';

    const onOk = () => {
        hideEnvelope(envelopeId);
    };

    useEffect(() => {
        if (state === 'REQUEST_SUCCESS') {
            onCloseModal();
        }
    }, [state, onCloseModal]);

    return (
        <>
            <div className={modalTitle}>
                <GivelifyLabel fontWeight={800} text={name} />
            </div>
            <div className={switchImage}>
                <GivelifyIcon size={64} variant="active-envelope" />
                <GivelifyIcon
                    className={switchArrowImg}
                    variant="switch-arrow"
                />
                <GivelifyIcon size={64} variant="inactive-envelope" />
            </div>
            <div className={switchContent}>
                <GivelifyLabel
                    className={switchTitle}
                    text={copy.askToHide}
                    variant="heading3"
                />
                <GivelifyLabel
                    color={GivelifyColorPalette.navigationTextDefault}
                    text={copy.askDesc}
                />
                <GivelifyLabel
                    color={GivelifyColorPalette.navigationTextDefault}
                    text={copy.askUndo}
                />
            </div>
            <div className={switchBottom}>
                <GivelifyButton
                    className={cancelBtn}
                    disabled={inProgress}
                    marginRight={16}
                    onClick={onCloseModal}
                    size="large"
                    text={copy.cancel}
                    variant="secondary"
                    width={268}
                />
                <GivelifyButton
                    className={hideEnvelopeBtn}
                    disabled={inProgress}
                    isLoading={inProgress}
                    onClick={onOk}
                    size="large"
                    text={copy.hideEnvelope}
                    variant="primary"
                    width={268}
                />
            </div>
        </>
    );
};

export default HideEnvelopeModalContent;
