import React from 'react';
import { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyBox,
    GivelifyBoxMarginProps,
} from '@givelify/givelify-ui';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProgressCoverSmallSvg } from '../../assets/progress-circle-cover-sm.svg';
import { ReactComponent as ProgressCoverSvg } from '../../assets/progress-circle-cover.svg';
import ProgressSmallSvg from '../../assets/progress-circle-small.svg';
import ProgressBigSvg from '../../assets/progress-circle.svg';
import { I18N_NAMESPACE } from '../../consts';
import { progressBadgeStyles } from './progressBadgeStyles';

interface ProgressBadgeProps extends GivelifyBoxMarginProps {
    value: number;
    size?: 'normal' | 'small';
    v2?: boolean;
}

export const ProgressBadge: React.FC<ProgressBadgeProps> = (props) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            completed: t('onboardingCards.completeToRecieve.completed'),
            submitted: t('onboardingCards.completeToRecieve.v2.submitted'),
        }),
        [t],
    );
    const { value, size = 'normal', v2, ...baseProps } = props;
    const {
        circle,
        circleCover,
        circleCoverSm,
        progressValue,
        progressDescription,
    } = progressBadgeStyles(baseProps);
    return (
        <GivelifyBox
            {...baseProps}
            alignItems="center"
            backgroundColor="#FFFFFF"
            borderRadius="50%"
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
            display="flex"
            height={size === 'normal' ? 180 : 140}
            justifyContent="center"
            overflow="hidden"
            position="relative"
            width={size === 'normal' ? 180 : 140}
            zIndex={10}
        >
            <GivelifyBox
                alignItems="center"
                display="flex"
                height={size === 'normal' ? 160 : 125}
                justifyContent="center"
                left={size === 'normal' ? 10 : 7.5}
                position="absolute"
                top={size === 'normal' ? 10 : 7.5}
                width={size === 'normal' ? 160 : 125}
                zIndex={20}
            >
                <img
                    alt="progress"
                    className={circle}
                    src={size === 'normal' ? ProgressBigSvg : ProgressSmallSvg}
                    title="progress gradient"
                />
            </GivelifyBox>
            <GivelifyBox
                alignItems="center"
                display="flex"
                height={size === 'normal' ? 160 : 125}
                justifyContent="center"
                left={size === 'normal' ? 10 : 7.5}
                position="absolute"
                top={size === 'normal' ? 10 : 7.5}
                width={size === 'normal' ? 160 : 125}
                zIndex={25}
            >
                <SvgIcon
                    className={size === 'normal' ? circleCover : circleCoverSm}
                    component={
                        size === 'normal'
                            ? ProgressCoverSvg
                            : ProgressCoverSmallSvg
                    }
                    strokeDasharray={480}
                    strokeDashoffset={-(value / 100) * 480 + 960}
                    viewBox={size === 'normal' ? '0 0 160 160' : '0 0 125 125'}
                />
            </GivelifyBox>
            <GivelifyBox
                alignItems="center"
                display="flex"
                flexDirection="column"
                height={140}
                justifyContent="center"
                width={140}
                zIndex={30}
            >
                <GivelifyLabel
                    className={progressValue}
                    id="complete-percent"
                    text={`${value}%`}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={progressDescription}
                    color="neutralGrey"
                    marginTop={6}
                    text={v2 ? copy.submitted : copy.completed}
                    variant="body1"
                />
            </GivelifyBox>
        </GivelifyBox>
    );
};
