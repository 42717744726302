import React from 'react';
import { GivelifyPillProps } from '@givelify/ui';
import { FilterPillsWrapper, StyledPill } from './styles';

type FilterPillsComponentProps = {
    filters: GivelifyPillProps[];
};

export const FilterPillsComponent: React.FC<FilterPillsComponentProps> = ({
    filters,
}) => {
    return filters && filters.length > 0 ? (
        <FilterPillsWrapper data-testid="filter-pills-wrapper">
            {filters.map((filter) => (
                <StyledPill {...filter} key={filter.dataTestId} />
            ))}
        </FilterPillsWrapper>
    ) : null;
};

export const FilterPills = React.memo(FilterPillsComponent);
