import * as React from 'react';
import { useMemo } from 'react';
import { GivelifyLabel, GivelifyRadio } from '@givelify/givelify-ui';
import { ErrorText } from 'components/ErrorText';
import { ReceiptMobilePreview } from 'components/mobilePreviews/ReceiptMobilePreview';
import SaveCancelButtons from 'pages/settings/components/SaveCancelButtons';
import { useAdvancedTranslation } from 'utils/i18n';
import { appProfileStyle } from './appProfileStyle';

interface ReceiptTabPageProps {
    isOn?: boolean;
    doneeName?: string;
    onCancel: () => void;
    onSave: () => void;
    onChange: (isOn: boolean) => void;
    saving?: boolean;
    error?: string;
    dirty: boolean;
    disableActions?: boolean;
}

const ReceiptTabPage: React.FunctionComponent<ReceiptTabPageProps> = props => {
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            on: t('labels.on'),
            off: t('labels.off'),
            descriptionShort: at(
                'pages.settings.app-profile.customize-receipt-tab.description-short',
            ),
            descriptionLong: at(
                'pages.settings.app-profile.customize-receipt-tab.description-long',
            ),
        }),
        [t, at],
    );
    const {
        profileContainer,
        profileEditorSide,
        profilePreviewSide,
        profileEditorContainer,
        profileEditorFlex,
    } = appProfileStyle();
    return (
        <div className={profileContainer}>
            <div className={profileEditorSide}>
                <GivelifyLabel
                    wrap
                    text={copy.descriptionShort}
                    variant="heading5"
                />
                <GivelifyLabel
                    wrap
                    marginTop={8}
                    text={copy.descriptionLong}
                    variant="body1"
                />
                <div className={profileEditorContainer}>
                    <div className={profileEditorFlex}>
                        <GivelifyRadio
                            ariaLabel={copy.on}
                            checked={props.isOn}
                            disabled={props.disableActions}
                            label={copy.on}
                            labelPosition="after"
                            marginRight={24}
                            name={copy.on}
                            onChange={() => {
                                if (props.onChange) {
                                    props.onChange(true);
                                }
                            }}
                            variant="primary"
                        />
                        <GivelifyRadio
                            ariaLabel={copy.off}
                            checked={!props.isOn}
                            disabled={props.disableActions}
                            label={copy.off}
                            labelPosition="after"
                            marginLeft={24}
                            name={copy.off}
                            onChange={() => {
                                if (props.onChange) {
                                    props.onChange(false);
                                }
                            }}
                            variant="primary"
                        />
                    </div>
                </div>
                {props.error ? <ErrorText text={props.error} /> : null}
                {props.disableActions ? null : (
                    <SaveCancelButtons
                        cancelDisabled={props.saving}
                        onCancel={props.onCancel}
                        onSave={props.onSave}
                        saving={props.saving}
                        submitDisabled={props.saving || !props.dirty}
                    />
                )}
            </div>
            <div className={profilePreviewSide}>
                <ReceiptMobilePreview
                    doneeName={props.doneeName}
                    isOn={props.isOn}
                />
            </div>
        </div>
    );
};

export default ReceiptTabPage;
