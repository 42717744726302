import React from 'react';
import {
    GivelifyFormTextField,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { PrimaryRepInfoForm, primaryRepManualEvents } from '../types';
import { HideButton } from './styles';

type SocialNumberInputProps = {
    replace?: boolean;
    clearSsn: () => void;
};

export const SocialNumberInput: React.FC<SocialNumberInputProps> = ({
    replace,
    clearSsn,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            placeholder: t(
                'pages.settings.bank-info.customize-rep-tab.editor.step1.placeholder6',
            ),
            hide: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.hide',
            ),
            show: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.show',
            ),
        }),
        [t],
    );
    const [isSnVisible, setIsSnVisible] = React.useState(false);
    const [isReplaceMode, setIsReplaceMode] = React.useState(replace);
    const { trackEvent } = useTrackingContext();
    const onFocus = React.useCallback(() => {
        clearSsn();
        setIsReplaceMode(false);
    }, [clearSsn]);
    const onToggle = React.useCallback(() => {
        setIsSnVisible(!isSnVisible);
        trackEvent(
            isSnVisible
                ? primaryRepManualEvents.hideClick
                : primaryRepManualEvents.showClick,
        );
    }, [isSnVisible, trackEvent]);
    return (
        <GivelifyFormTextField<PrimaryRepInfoForm>
            fullWidth
            InputProps={
                isReplaceMode
                    ? undefined
                    : {
                          endAdornment: (
                              <HideButton onClick={onToggle}>
                                  <GivelifyIcon
                                      className="hide-icon"
                                      fontSize={24}
                                      variant={
                                          isSnVisible
                                              ? 'password-hidden'
                                              : 'password-visible'
                                      }
                                  />
                                  <GivelifyLabel variant="caption1">
                                      {isSnVisible ? copy.hide : copy.show}
                                  </GivelifyLabel>
                              </HideButton>
                          ),
                      }
            }
            aria-label="Security number editor"
            autoComplete="off"
            id="admin-form-ssn"
            label={copy.placeholder}
            maskOptions={
                isReplaceMode
                    ? {
                          mask: 'XXX-XX-0000',
                          definitions: {
                              X: {
                                  mask: 'a',
                                  displayChar: '*',
                              },
                          },
                          overwrite: 'shift',
                      }
                    : {
                          mask: 'XXX-XX-0000',
                          definitions: {
                              X: {
                                  mask: '0',
                                  displayChar: isSnVisible ? undefined : '*',
                              },
                          },
                          overwrite: 'shift',
                      }
            }
            name="ssn"
            onFocus={isReplaceMode ? onFocus : undefined}
            placeholder={copy.placeholder}
            size="large"
        />
    );
};
